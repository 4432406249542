// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : list section header sort filter
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :22 Nov 23
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from "react";
import classes from "./listDropdown.module.css";
import { Form } from "react-bootstrap";

const ListDropdown = (props) => {
  return (
    <div className={`${classes.sort_content} ${props.isSearchDropdown ? classes.sort_content_search : null}`}>
      {props.showCustomTextSearch && <p>{props.showCustomTextSearch}</p>}
      <Form className={classes.input_group}>
        <Form.Group className={classes.formGroup}>
          <Form.Control
            className={classes.input_control}
            as="select"
            onChange={props.changeFilter}
            value={props.value}
            style={{ pointerEvents: props.loading ? "none" : "auto", cursor: "pointer" }}
          >
            {props.isSearchDropdown ? (
              <>
                <option value="1">Relevancy - High to Low</option>
                <option value="2">Relevancy - Low to High</option>
                <option value="3">Newest</option>
                <option value="4">Oldest</option>
              </>
            ) : (
              <>
                <option value="1">Newest</option>
                <option value="2">Oldest</option>
              </>
            )}
          </Form.Control>
        </Form.Group>
      </Form>
    </div>
  );
};

export default ListDropdown;
