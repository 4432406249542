/*
================================================================
	Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Customer Services Routes 
----------------------------------------------------------------
	Creation Details
	Date Created				: 07/07/2021
	Author						: Sandeep K. Sharma
================================================================
*/
import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import AddService from "../app/views/services/component/add";
import ServiceListing from "../app/views/services";

const SERVICES = ["Customer:FULL"];

const servicesRoutes = () => (
  <Suspense
    fallback={
      <div className="text-center">
        <h3>Loading...</h3>
      </div>
    }
  >
    <Switch>
      <AuthLayout
        exact
        path="/services/add"
        component={AddService}
        pageHeading="GenNx CMS - Service Requests"
        accessRights={{
          moduleName: "Service Requests",
          parentModuleName: "Client Custom Services",
          moduleAttribute: [...SERVICES],
          interfaceName: "Basic",
        }}
      />
      <AuthLayout
        exact
        path="/services"
        component={ServiceListing}
        pageHeading="GenNx CMS - Service Requests"
        accessRights={{
          moduleName: "Service Requests",
          parentModuleName: "Client Custom Services",
          moduleAttribute: [...SERVICES],
          interfaceName: "Listing",
        }}
      />
      <AuthLayout
        exact
        path="/services/:serviceId/edit"
        component={AddService}
        pageHeading="GenNx CMS - Service Requests"
        accessRights={{
          moduleName: "Service Requests",
          parentModuleName: "Client Custom Services",
          moduleAttribute: [...SERVICES],
          interfaceName: "Basic",
        }}
      />
    </Switch>
  </Suspense>
);

export default servicesRoutes;
