import React, { useEffect, useState, useCallback } from "react";
import { JsonToTable } from "react-json-to-table";
import moment from "moment";
import lodash, { debounce } from "lodash";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import { enableRipple } from "@syncfusion/ej2-base";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { swap, getProductVariant, getLoggedInUser, isAdmin, accessRightActionCheck } from "../../../utilities";
import CommonDataGrid from "../../components/dataGrid";
import { fetchReportVendor, fetchVendorActivityLogById } from "../../../middleware/services/reportApi";
import { fetchProductAll } from "../../../middleware/services/productApi";
import { fetchUser } from "../../../middleware/services/userApi";
import { formatDateGMTtoIST } from "../../../utilities/parseDateWithTimeZone";
import { showPopup } from "../../../middleware/actions/popupAction";
import { actionError } from "../../../middleware/actions/utilityAction";
import "./style.scss";
import ReportHelp from "./reportHelp";
import { COMMON_ACCESS_ACTION } from "../../../constants";

enableRipple(true);
// Vendor Activity Report component
const VendorDataReport = (props) => {
  const { accessRights: aR } = props;
  const accessPermission = true;
  const productVariant = getProductVariant();
  const loggedInUser = getLoggedInUser();
  const [users, setUsers] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [isUpdated, setIsUpdated] = useState(undefined);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [startDate, setStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionSearch = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SEARCH);

  // specify breadcrumb here
  const breadCrumbLinks = [{ linkUrl: "/report", linkName: aR.moduleName, linkActive: true }];

  const initialState = {
    selUsers: [],
  };
  const fnStartDate = () => {
    let date = new Date();
    let date2 = new Date();
    date.setDate(date.getDate() - 7);
    const lastDate = date2
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    date2.setDate(date2.getDate());
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");

    setStartDate(formattedDate);
    setEndDate(lastDate);
    setDateRange([date, date2]);
    localStorage.setItem("dateRange1", [date, date2]);
  };
  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
  });
  const dispatch = useDispatch();
  const fetchExternalUsers = async (params = {}) => {
    let defaultFilter = [];

    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    if (!isAdmin() && !accessPermission) defaultFilter.push(["userId", "in", [loggedInUser._id]]);
    if (!isAdmin() && accessPermission && loggedInUser.user_type === "External") {
      const parameters = {};
      parameters.filters = [...defaultFilter, ["_id", "eq", loggedInUser._id]];
      parameters.fields = ["userVendorId"];
      const response = await fetchUser(parameters);
      const data = response?.data?.data;
      if (data && data[0] && data[0]?.userVendorId) {
        defaultFilter.push(["userVendorId", "in", [data[0].userVendorId]]);
      }
    }
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];
    return fetchUser(params);
  };
  const UsersDropDown = (props) => {
    const [value, setValue] = useState([]);
    const [users, setUsers] = useState([]);
    useEffect(() => {
      let name = props.name;
      setValue(props.formik.values[name]);
    }, [props.formik.values, props.name]);
    // debounce search
    const searchUsers = useCallback(
      debounce(async (value) => {
        if (value.length > 1) {
          let filters = [];
          const productVariant = getProductVariant();
          if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
          if (!isAdmin() && accessPermission && loggedInUser.user_type === "External") {
            const parameters = {};
            parameters.filters = [["_id", "eq", loggedInUser._id]];
            parameters.fields = ["userVendorId"];
            const resp = await fetchUser(parameters);
            const data = resp?.data?.data;
            if (data && data[0] && data[0]?.userVendorId) {
              filters.push(["userVendorId", "in", [data[0].userVendorId]]);
            }
          }
          filters.push(["userType", "eq", "External"]);
          let val = value.split(" ");
          filters.push(["userFname", "cn", val[0].trim()]);
          let fields = { fields: ["id", "userFname", "userLname"] };
          const response = await fetchUser({ filters: filters, ...fields });
          if (response.data && response.data.data) {
            setUsers(response.data.data);
          }
        }
      }, 300),
      []
    );

    return (
      <CreatableSelect
        {...props}
        isValidNewOption={() => false}
        noOptionsMessage={() => <div>Please type at least 2 characters</div>}
        options={users.map((option) => {
          return { label: `${option.userFname} ${option.userLname}`, value: option.id };
        })}
        value={value}
        onChange={(selectedOption) => {
          selectedOption ? setValue({ ...value, id: selectedOption }) : setValue([]);
          selectedOption
            ? setSelectedUser(
                selectedOption.map((v) => {
                  return v.value;
                })
              )
            : setSelectedUser(undefined);
          const selectedType = selectedOption ? selectedOption : [];
          setValue(selectedType);
          props.formik.setFieldValue("selUsers", selectedType);
        }}
        onInputChange={(inputValue) => {
          inputValue && searchUsers(inputValue);
        }}
      />
    );
  };

  // specify column names for column fields in datGrid here
  const columnNames = {
    Vendor: "userVendorName",
    User: "user",
    Email: "userEmail",
    "Allocated Company": "allocatedCompanies",
    "Delivered-Total Company": "delivedTotalCompanies",
    "Delivered Updated Companies": "delivedUpdatedCompanies",
    "Accepted Total Company": "companyAcceptedTotal",
    "Accepted With Rework Company": "companyAcceptWithRework",
    "Accepted Without Rework Company": "companyAcceptWithoutRework",
    "SentBack Company": "companySendBack",
    "Rejected Company": "companyRejected",
    "Added Company": "add",
    "Deleted Company": "delete",
    "Total Pending to be delivered Company": "companyPendingToDeliver",
    "Pending in Fresh Company": "companyPendingInFresh",
    "Pending in Rework Company": "companyPendingInRework",
    "Effectiveness % Company": "companyEffectivenessPer",
    Allocated: "allocatedProducts",
    "Delivered-Total": "delivedTotalProduct",
    "Delivered-Updated": "delivedUpdatedProduct",
    "Accepted Total": "productAcceptedTotal",
    "Accept With Rework": "productAcceptWithRework",
    "Accept Without Rework": "productAcceptWithoutRework",
    productSendBack: "productSendBack",
    Rejected: "productRejected",
    Added: "productAdded",
    Deleted: "productDeleted",
    "Taxonomy Tagging": "productTaxonomy",
    "Total Pending to be delivered": "productPendingToDeliver",
    "Pending in Fresh": "productPendingInFresh",
    "Pending in Rework": "productPendingInRework",
    "Effectiveness %": "productEffectivenessPer",

    // "Companies Reworked": "rework",x
    // "Companies Processed": "processing",
    // "Product Processed": "productProcessed",
    "Products Reworked": "productReworked",
    "Total QC-ed": "totQc",
    Accepted: "qcAccepted",
    "Sent Back": "qcRework",
    "QC Rejected": "qcReject",
    "Total Pending to be QC-ed(Till date)": "totPendingToBeQc",
    "Total published (Out of QC-ed)": "totPublished",
    "Total pending to be published (Out of QC-ed) (Till date)": "totPendingToBePublished",
    "Total Published": "totCompanyPublished",
    "Total Products Published": "totProductPublished",

    //"Product Allocated": "qcAllocatedProducts",
    "Total Product QC-ed": "totProductQc",
    "Product Accepted": "qcProductAccepted",
    "Product Sent Back": "qcProductRework",
    "Product QC Rejected": "qcProductReject",
    "Total Product Pending to be QC-ed(Till date)": "totProductPendingToBeQc",
    "Total Product published (Out of QC-ed)": "totProductPublished",
    "Total Product pending to be published (Out of QC-ed) (Till date)": "totProductPendingToBePublished",

    Fresh: "newsFresh",
    Published: "newsPublished",
  };

  const userTemplate = (value) => {
    return <div title={`${value.Email}`}>{value.User}</div>;
  };

  const recordsTemplate = (value) => {
    const a = value[`${value.column.field}`];
    if (a) {
      if (a.length && typeof a === "object") {
        return (
          <span
            className="span-link-color"
            onClick={() => {
              launchPopup(a, value.column.field, value.User);
            }}
          >
            {a.length}
          </span>
        );
      } else if (typeof a === "number") {
        return <div>{a}</div>;
      } else {
        return <div>-</div>;
      }
    }
  };

  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean
  const columnFields = [
    {
      field: "Vendor",
      headerText: "Vendor Name",
      type: "String",
      allowFiltering: false,
      textAlign: "Left",
      headerTextAlign: "Center",
      showInColumnChooser: false,
      width: "150px",
    },
    {
      field: "User",
      template: userTemplate,
      headerText: "User Name",
      type: "String",
      allowFiltering: false,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "140px",
    },
    {
      textAlign: "Center",
      headerText: "Company Profiling",
      columns: [
        {
          field: "Allocated Company",
          headerText: "Allocated",
          type: "String",
          allowFiltering: false,
          allowSorting: false,
          template: recordsTemplate,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "77px",
        },
        {
          field: "Delivered",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          textAlign: "Center",
          headerTextAlign: "Center",
          columns: [
            {
              field: "Delivered-Total Company",
              headerText: "Total",
              type: "String",
              allowFiltering: false,
              allowSorting: false,
              template: recordsTemplate,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "55px",
            },
            {
              field: "Delivered Updated Companies",
              headerText: "Updated",
              type: "String",
              allowFiltering: false,
              allowSorting: false,
              template: recordsTemplate,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "72px",
            },
          ],
        },
        {
          field: "Accepted",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          textAlign: "Center",
          headerTextAlign: "Center",
          columns: [
            {
              field: "Accepted Total Company",
              headerText: "Total",
              type: "String",
              template: recordsTemplate,
              allowSorting: false,
              allowFiltering: false,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "55px",
            },
            {
              field: "Accepted With Rework Company",
              headerText: "With Rework",
              type: "String",
              template: recordsTemplate,
              allowSorting: false,
              allowFiltering: false,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "68px",
            },
            {
              field: "Accepted Without Rework Company",
              headerText: "Without Rework",
              type: "String",
              allowSorting: false,
              template: recordsTemplate,
              allowFiltering: false,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "70px",
            },
          ],
        },
        {
          field: "SentBack Company",
          headerText: "Sent Back",
          type: "String",
          template: recordsTemplate,
          allowFiltering: false,
          allowSorting: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "55px",
        },
        {
          field: "Rejected Company",
          headerText: "Rejected",
          type: "String",
          template: recordsTemplate,
          allowSorting: false,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "74px",
        },
        {
          field: "Added Company",
          headerText: "Added",
          type: "String",
          template: recordsTemplate,
          allowSorting: false,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "65px",
        },
        {
          field: "Deleted Company",
          headerText: "Deleted",
          type: "String",
          template: recordsTemplate,
          allowFiltering: false,
          allowSorting: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "67px",
        },
        {
          field: "Delivery Pending",
          type: "String",
          allowFiltering: false,
          allowSorting: false,
          template: recordsTemplate,
          textAlign: "Center",
          headerTextAlign: "Center",
          columns: [
            {
              field: "Total Pending to be delivered Company",
              headerText: "Total",
              type: "String",
              template: recordsTemplate,
              allowFiltering: false,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "55px",
              allowSorting: false,
            },
            {
              field: "Pending in Fresh Company",
              headerText: "Fresh",
              type: "String",
              template: recordsTemplate,
              allowSorting: false,
              allowFiltering: false,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "57px",
            },
            {
              field: "Pending in Rework Company",
              headerText: "Rework",
              type: "String",
              template: recordsTemplate,
              allowSorting: false,
              allowFiltering: false,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "68px",
            },
          ],
        },
        {
          field: "Effectiveness % Company",
          headerText: "Effectiveness %",
          type: "String",
          template: recordsTemplate,
          allowSorting: false,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "102px",
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Product Profiling",
      columns: [
        {
          field: "Allocated",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          textAlign: "Center",
          allowSorting: false,
          headerTextAlign: "Center",
          width: "78px",
        },
        {
          field: "Delivered",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          textAlign: "Center",
          headerTextAlign: "Center",
          columns: [
            {
              field: "Delivered-Total",
              headerText: "Total",
              type: "String",
              allowFiltering: false,
              allowSorting: false,
              template: recordsTemplate,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "55px",
            },
            {
              field: "Delivered-Updated",
              headerText: "Updated",
              type: "String",
              allowFiltering: false,
              allowSorting: false,
              template: recordsTemplate,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "72px",
            },
          ],
        },
        {
          field: "Accepted",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          textAlign: "Center",
          headerTextAlign: "Center",
          columns: [
            {
              field: "Accepted Total",
              headerText: "Total",
              type: "String",
              allowFiltering: false,
              allowSorting: false,
              template: recordsTemplate,
              textAlign: "Center",
              headerTextAlign: "Center",
              idth: "55px",
            },
            {
              field: "Accept With Rework",
              headerText: "With Rework",
              type: "String",
              allowFiltering: false,
              allowSorting: false,
              template: recordsTemplate,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "68px",
            },
            {
              field: "Accept Without Rework",
              headerText: "Without Rework",
              type: "String",
              allowFiltering: false,
              allowSorting: false,
              template: recordsTemplate,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "70px",
            },
          ],
        },
        {
          field: "productSendBack",
          headerText: "Sent Back",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          allowSorting: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "55px",
        },
        {
          field: "Rejected",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          textAlign: "Center",
          allowSorting: false,
          headerTextAlign: "Center",
          width: "74px",
        },
        {
          field: "Added",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          textAlign: "Center",
          allowSorting: false,
          headerTextAlign: "Center",
          width: "65px",
        },
        {
          field: "Deleted",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          textAlign: "Center",
          allowSorting: false,
          headerTextAlign: "Center",
          width: "67px",
        },
        {
          field: "Taxonomy Tagging",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          textAlign: "Center",
          allowSorting: false,
          headerTextAlign: "Center",
          width: "150px",
        },
        {
          field: "Delivery Pending",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          textAlign: "Center",
          headerTextAlign: "Top",
          columns: [
            {
              field: "Total Pending to be delivered",
              headerText: "Total",
              type: "String",
              allowFiltering: false,
              allowSorting: false,
              template: recordsTemplate,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "55px",
            },
            {
              field: "Pending in Fresh",
              headerText: "Fresh",
              type: "String",
              allowFiltering: false,
              allowSorting: false,
              template: recordsTemplate,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "57px",
            },
            {
              field: "Pending in Rework",
              headerText: "Rework",
              type: "String",
              allowFiltering: false,
              allowSorting: false,
              template: recordsTemplate,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "70px",
            },
          ],
        },
        {
          field: "Effectiveness %",
          type: "String",
          allowFiltering: false,
          template: recordsTemplate,
          allowSorting: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "102px",
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Company QC",
      columns: [
        {
          textAlign: "Center",
          headerText: "QC-ed",
          columns: [
            {
              field: "Total QC-ed",
              headerText: "Total",
              type: "String",
              template: recordsTemplate,
              allowSorting: false,
              allowFiltering: false,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "55px",
            },
            {
              field: "Accepted",
              type: "String",
              template: recordsTemplate,
              allowFiltering: false,
              textAlign: "Center",
              allowSorting: false,
              headerTextAlign: "Center",
              width: "78px",
            },
            {
              field: "Sent Back",
              type: "String",
              template: recordsTemplate,
              allowFiltering: false,
              textAlign: "Center",
              allowSorting: false,
              headerTextAlign: "Center",
              width: "55px",
            },
            {
              field: "QC Rejected",
              headerText: "Rejected",
              type: "String",
              template: recordsTemplate,
              allowFiltering: false,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "75px",
              allowSorting: false,
            },
          ],
        },
        {
          field: "Total Pending to be QC-ed(Till date)",
          headerText: "Pending",
          type: "String",
          template: recordsTemplate,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "72px",
          allowSorting: false,
        },
        {
          field: "Total Published",
          headerText: "Published",
          type: "String",
          template: recordsTemplate,
          allowSorting: false,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "82px",
        },
        // {
        //     field: "Total published (Out of QC-ed)", headerText:"Total published (Out of QC-ed)", type: "String", template: recordsTemplate, allowFiltering: false,
        //     textAlign: "Center", headerTextAlign: "Center", width: "82px"
        // },
        {
          field: "Total pending to be published (Out of QC-ed) (Till date)",
          headerText: "Not Published",
          type: "String",
          allowSorting: false,
          template: recordsTemplate,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "82px",
        },
      ],
    },

    {
      textAlign: "Center",
      headerText: "Product QC",
      columns: [
        {
          textAlign: "Center",
          headerText: "QC-ed",
          columns: [
            {
              field: "Total Product QC-ed",
              headerText: "Total",
              type: "String",
              template: recordsTemplate,
              allowSorting: false,
              allowFiltering: false,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "55px",
            },
            {
              field: "Product Accepted",
              headerText: "Accepted",
              type: "String",
              template: recordsTemplate,
              allowFiltering: false,
              textAlign: "Center",
              allowSorting: false,
              headerTextAlign: "Center",
              width: "78px",
            },
            {
              field: "Product Sent Back",
              headerText: "Sent Back",
              type: "String",
              template: recordsTemplate,
              allowFiltering: false,
              textAlign: "Center",
              allowSorting: false,
              headerTextAlign: "Center",
              width: "55px",
            },
            {
              field: "Product QC Rejected",
              headerText: "Rejected",
              type: "String",
              template: recordsTemplate,
              allowFiltering: false,
              textAlign: "Center",
              headerTextAlign: "Center",
              width: "75px",
              allowSorting: false,
            },
          ],
        },
        {
          field: "Total Product Pending to be QC-ed(Till date)",
          headerText: "Pending",
          type: "String",
          template: recordsTemplate,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "72px",
          allowSorting: false,
        },
        {
          field: "Total Product published (Out of QC-ed)",
          headerText: "Published",
          type: "String",
          template: recordsTemplate,
          allowSorting: false,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "82px",
        },
        {
          field: "Total Product pending to be published (Out of QC-ed) (Till date)",
          headerText: "Not Published",
          type: "String",
          allowSorting: false,
          template: recordsTemplate,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
          width: "82px",
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "News",
      columns: [
        {
          field: "Fresh",
          width: "60px",
          template: recordsTemplate,
          allowSorting: false,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
        },
        {
          field: "Published",
          width: "90px",
          template: recordsTemplate,
          allowSorting: false,
          allowFiltering: false,
          textAlign: "Center",
          headerTextAlign: "Center",
        },
      ],
    },
  ];

  // formatRowData function required to format rowData for dataGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = {};
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "-";
        }
      });
      if (rD.delivedUpdatedCompanies) {
        newRD["Delivered-Total Company"] =
          rD["delivedUpdatedCompanies"] && rD["delivedUpdatedCompanies"] !== "" ? rD["delivedUpdatedCompanies"] : "-";
        newRD["Delivered Updated Companies"] =
          rD["delivedUpdatedCompanies"] && rD["delivedUpdatedCompanies"] !== "" ? rD["delivedUpdatedCompanies"] : "-";
      }
      if (rD.delivedUpdatedProduct) {
        newRD["Delivered-Total"] = rD["delivedUpdatedProduct"] && rD["delivedUpdatedProduct"] !== "" ? rD["delivedUpdatedProduct"] : "-";
        newRD["Delivered-Updated"] = rD["delivedUpdatedProduct"] && rD["delivedUpdatedProduct"] !== "" ? rD["delivedUpdatedProduct"] : "-";
      }
      if (rD.companyInQueue) {
        newRD.allocatedDtId = rD.companyInQueue.dtId ? rD.companyInQueue.dtId : [];
        newRD.allocatedTrendId = rD.companyInQueue.trendId ? rD.companyInQueue.trendId : [];
      }
      return newRD;
    });
    return formatedRowData;
  };

  const fetchExternalUsersReport = async (params = {}) => {
    let defaultFilter = [];

    if (!isAdmin() && !accessPermission) defaultFilter.push(["userId", "in", [loggedInUser._id]]);
    if (!isAdmin() && accessPermission && loggedInUser.user_type === "External") {
      const parameters = {};
      parameters.filters = [...defaultFilter, ["_id", "eq", loggedInUser._id]];
      parameters.fields = ["userVendorId"];
      const response = await fetchUser(parameters);
      const data = response?.data?.data;
      if (data && data[0] && data[0]?.userVendorId) {
        defaultFilter.push(["userVendorId", "in", [data[0].userVendorId]]);
      }
    }
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let filters = [];
    if (selectedUser) filters.push(["userId", "in", selectedUser]);
    if (dateRange) {
      let gte = { d: dateRange[0] };
      Date.prototype.toJSON = function () {
        return moment(this).format();
      };

      gte.d.toJSON = function () {
        return moment(this).format();
      };

      let lt = { d: moment(dateRange[1]).add(1, "day") };
      Date.prototype.toJSON = function () {
        return moment(this).format();
      };
      lt.d.toJSON = function () {
        return moment(this).format();
      };

      filters.push(["logDatetime", "gte", gte.d]);
      filters.push(["logDatetime", "lt", lt.d]);
    }
    if (selectedVendor) defaultFilter.push(["userVendorId", "eq", selectedVendor.value]);
    params.filters = [...filters, ...defaultFilter];
    return fetchReportVendor({ ...params });
  };

  const onQueryCellInfo = (args) => {
    if (args.column.field === "Action") {
      args.colSpan = 3;
    }
  };
  const handleReset = () => {
    window.location.reload();
    setSelectedUser(null);
    setSelectedVendor(null);
    setVendorList([]);
    formik.handleReset();
    localStorage.removeItem("dateRange1");
    setDateRange(null);
    fetchExternalUsersReport().then((res) => {
      if (res.data) setIsUpdated(Date.now());
    });
  };
  const handleSearch = () => {
    fetchExternalUsersReport().then((res) => {
      if (res.data) setIsUpdated(Date.now());
    });
  };
  const getpopupDtls = async (recordIds, columnName, user) => {
    let params = {};
    let defaultFilter = [];
    let filters = [];
    filters.push(["recordDetails.recordId", "in", recordIds]);
    switch (columnName) {
      case "Rejected Company":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "reject"]);
        break;
      case "Rejected":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "reject"]);
        break;
      case "Added Company":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordActionType", "eq", "add"]);
        break;
      case "Deleted Company":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordActionType", "eq", "delete"]);
        break;
      case "Companies Processed":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "processing"]);
        filters.push(["recordDetails.recordActionType", "eq", "finalSubmit"]);
        break;
      case "Companies Reworked":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "processing"]);
        filters.push(["recordDetails.recordActionType", "eq", "finalSubmit"]);
        filters.push(["recordDetails.recordQueueStatus", "eq", "PROCESSING_REWORK"]);
        break;
      case "Added":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordActionType", "eq", "add"]);
        break;
      case "Deleted":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordActionType", "eq", "delete"]);
        break;
      case "Added Product":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordActionType", "eq", "add"]);
        break;
      case "Product Processed":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "processing"]);
        filters.push(["recordDetails.recordActionType", "eq", "finalSubmit"]);
        break;
      case "Products Reworked":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "rework"]);
        filters.push(["recordDetails.recordQueueStatus", "eq", "PROCESSING_REWORK"]);
        break;
      case "Accepted":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "qcComplete"]);
        break;
      case "SentBack Company":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "rework"]);
        break;
      case "Sent Back":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "rework"]);
        break;
      case "productSendBack":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "rework"]);
        break;
      case "QC Rejected":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "in", ["rejectAndReallocate", "reject"]]);
        break;
      case "Total Published":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "publishing"]);
        filters.push(["recordDetails.recordActionType", "eq", "publishing"]);
        break;
      case "Product Accepted":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "qcComplete"]);
        break;
      case "Product Sent Back":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "rework"]);
        break;
      case "Product QC Rejected":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "in", ["rejectAndReallocate", "reject"]]);
        break;
      case "Total Products Published":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "publishing"]);
        filters.push(["recordDetails.recordActionType", "eq", "publishing"]);
        break;
      case "Fresh":
        filters.push(["recordType", "eq", "news"]);
        filters.push(["recordDetails.recordAction", "eq", "processing"]);
        filters.push(["recordDetails.recordActionType", "eq", "saveSubmit"]);
        break;
      case "Published":
        filters.push(["recordType", "eq", "news"]);
        filters.push(["recordDetails.recordAction", "eq", "publishing"]);
        filters.push(["recordDetails.recordActionType", "eq", "publishing"]);
        break;
      default:
        break;
    }
    if (dateRange) {
      const dt = localStorage.getItem("dateRange1").split(",");
      let gte = { d: new Date(dt[0]) };
      Date.prototype.toJSON = function () {
        return moment(this).format();
      };

      gte.d.toJSON = function () {
        return moment(this).format();
      };

      let lt = { d: moment(new Date(dt[1])).add(1, "day") };
      Date.prototype.toJSON = function () {
        return moment(this).format();
      };
      lt.d.toJSON = function () {
        return moment(this).format();
      };

      filters.push(["logDatetime", "gte", gte.d]);
      filters.push(["logDatetime", "lt", lt.d]);
    }
    params.fields = ["recordDetails.recordId", "recordDetails.recordName", "logDatetime", "recordType"];
    params.filters = [...filters, ...defaultFilter];
    params.sort = "logDatetime:desc";
    return await fetchVendorActivityLogById({ ...params });
  };

  const launchPopup = async (recordIds, columnName, user) => {
    const res = await getpopupDtls(recordIds, columnName, user);
    if (res.data && res.data.data && res.data.data.length) {
      let recordName = res.data.data[0].recordType;
      let productsDetails = {};
      if (recordName === "product") {
        let c = await fetchProductAll({ filters: [["_id", "in", recordIds]], limit: recordIds.length, fields: ["productCompany"] });
        if (c && c.data.totalCount) productsDetails = c.data.data;
      }
      const objData = res.data.data.map((r) => {
        if (r.recordDetails.recordName) {
          const obj = {};
          obj[`${recordName.charAt(0).toUpperCase() + recordName.substr(1).toLowerCase()}`] = r.recordDetails.recordName;
          if (r.recordType === "product" && productsDetails && productsDetails.length) {
            let c = productsDetails.filter((p) => p.id.toString() === r.recordDetails.recordId.toString());
            if (c && c.length) obj[`Company`] = c[0].productCompany.companyName;
          }
          obj[`Date-Time (IST)`] = formatDateGMTtoIST(r.logDatetime, "DD MMM YYYY HH:mm");
          return obj;
        }
      });

      Promise.allSettled(objData).then((results) => {
        let json = [];
        let errorArr = [];
        results.forEach((res) => {
          if (res.status === "fulfilled") {
            if (res.value) json.push(res.value);
          } else {
            if (res.value) errorArr.push(JSON.parse(res.value));
          }
        });
        dispatch(
          showPopup({
            width: `40%`,
            title: `<h2 class="text-secondary" style="font-weight: 700">Activity Details of ${user} (${columnName})</h2>`,
            okText: "OK",
            showCancelButton: false,
            content: () => <JsonToTable json={json} />,
          })
        );
      });
    }
  };

  useEffect(() => {
    fnStartDate();
    fetchExternalUsers()
      .then((res) => {
        res.data && setUsers(res.data.data);
      })
      .catch((err) => {
        console.log("err fetchUsers :: ", err);
      });
  }, []);

  useEffect(() => {
    setIsUpdated(Date.now());
  }, [dateRange]);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  const searchByVendorName = async (searchValue) => {
    if (searchValue.length > 1) {
      let filters = [];
      const productVariant = getProductVariant();
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["userType", "eq", "External"]);
      filters.push(["userVendorName", "cn", searchValue.trim()]);
      let fields = { fields: ["id", "userVendorName", "userVendorId"] };
      const response = await fetchUser({ filters: filters, ...fields })
        .then((res) => {
          res.data && res.data.data && setVendorList(lodash.uniqBy(res.data.data, "userVendorName"));
        })
        .catch((err) => {
          dispatch(actionError(err.data.message || "Something went wrong"));
        });
    }
  };

  return (
    <div className="p-3" style={{ maxHeight: "95vh" }}>
      <div className="col-md-12 border rounded-bottom nopadding p-0 bg-white">
        <div className="row ml-1">
          {loggedInUser.user_type !== "External" ? (
            <div className="col-md-3 mt-4">
              <CreatableSelect
                isValidNewOption={() => false}
                placeholder="Type min 2 chars to search vendor"
                noOptionsMessage={() => <div>Please type at least 2 characters</div>}
                options={vendorList.map((option) => {
                  return { label: option.userVendorName, value: option.userVendorId };
                })}
                value={selectedVendor}
                onChange={(selectedOption) => {
                  selectedOption ? setSelectedVendor(selectedOption) : setSelectedVendor(null);
                  console.log(selectedOption);
                }}
                onInputChange={(inputValue) => {
                  inputValue && searchByVendorName(inputValue);
                }}
                isSearchable
                isClearable
              />
            </div>
          ) : null}
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="classification"></label>
              <UsersDropDown
                formik={formik}
                id="selUsers"
                name="selUsers"
                placeholder="Type min 2 chars to search users"
                minMenuHeight={100}
                maxMenuHeight={150}
                menuPlacement="auto"
                isSearchable
                isMulti
              />
            </div>
          </div>
          <div className="col-md-2 mt-4">
            <DateRangePickerComponent
              placeholder="Date Range"
              cssClass="e-outline"
              floatLabelType="Never"
              openOnFocus
              showClearButton
              format="dd-MMM-yyyy"
              separator="to"
              // value={dateRange ? dateRange : ""}
              startDate={startDate}
              endDate={endDate}
              onChange={(e) => {
                if (e.value) setDateRange(e.value);
                localStorage.setItem("dateRange1", e.value);
              }}
            ></DateRangePickerComponent>
          </div>
          <div className="col-md-4 ">
            {accessActionSearch && (
              <button type="button" onClick={handleSearch} className="mt-4 search-btn px-4 btn btn-primary">
                Search
              </button>
            )}
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 mt-4 ml-2 ">
              Reset
            </button>
            <ReportHelp name="Vendor" />
          </div>
        </div>
      </div>
      <div className="gennx-grid-container gennx-grid-container-report">
        {dateRange && (
          <CommonDataGrid
            gridTitle="Vendor Activity Report"
            fetch={fetchExternalUsersReport}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            deleteId={isUpdated}
            isPaging={false}
            freezeColumn={2}
            showResetFilter={false}
            autoFitColumn={false}
            allowSelection={false}
            queryCellInfo={onQueryCellInfo}
            deleteMany={false}
            isReportGrid={true}
          />
        )}
      </div>
    </div>
  );
};

export default VendorDataReport;
