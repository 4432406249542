/**
 * @author: Pankaj Kulshreshtha
 * @date : 22/Jun/2021
 * File Description: Assessment Validation Schema
 */
import * as Yup from "yup";
import * as REGEX from "../../../config/regexValidation"
export const assessmentValidationSchema = {
    assessmentName: Yup.string().trim("Please enter assessment name").max(30, "Assessment name must be at most 30 characters").required("Please enter assessment name"),
    assessmentDuration: Yup.number().min(1, "Assessment duration must be greater than 0").nullable(true),
    assessmentProficiencyLevel: Yup.string().required("Please select assessment proficiency level").typeError("Please select assessment proficiency level"),
    functionalCompetancy: Yup.string().required("Please select functional competency").typeError("Please select functional competency"),
    assessmentDescription: Yup.string().trim("Please enter assessment description").min(10, "Assessment description must be at least 10 characters").required("Please enter assessment description"),
    assessmentLink: Yup.string().matches(REGEX.URL, 'Please enter valid external assessment link'),

};
export const trainingVideoValidationSchema = {
    videoTitle: Yup.string().trim("Please enter video title").max(30, "Video title must be at most 30 characters").required("Please enter video title"),
    videoFunctionalCompetency: Yup.string().required("Please select video functional competency").typeError("Please select video functional competency"),
    videoContent: Yup.string().trim("Please enter video content").min(10, "Video content must be at least 10 characters").required("Please enter video content"),
    videoLink: Yup.string().trim("Please enter video link").required("Please enter video link").matches(REGEX.URL, 'Please enter valid video link'),

};