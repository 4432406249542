/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Application Redux Action constants
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

// initial
export const INITIAL_STATE = "initial_state";
export const GET_INITIAL_STATE = "get_initial_state";

// utility
export const ACTION_START = "action_start";
export const ACTION_STOP = "action_stop";
export const ACTION_SUCCESS = "action_success";
export const ACTION_ERROR = "action_error";
export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";
export const SHOW_LOADER = "show_loader";
export const HIDE_LOADER = "hide_loader";

// auth
export const USER_SIGNUP = "signup_user";
export const USER_SIGNIN = "user_signin";
export const USER_SIGNOUT = "user_signout";
export const USER_TOKEN_SET = "user_token_set";

// user
export const USER_DATA = "user_data";
// export const COMPANY_DATA = "company_data";
// company
// export const COMPANY_DATA = "company_data";
export const COMPANY_OVERVIEW_UPDATE_STATUS = "company_overview_update_status";
export const SET_COMPANY_OVERVIEW_UPDATE_STATUS = "set_company_overview_update_status";
export const SET_COMPANY_SAVE_SUBMIT_CLICK_STATUS = "set_company_save_submit_click_status";
// news
export const NEWS_OVERVIEW_UPDATE_STATUS = "news_overview_update_status";
export const SET_NEWS_OVERVIEW_UPDATE_STATUS = "set_news_overview_update_status";
export const SET_NEWS_PUBLISH_CLICK_STATUS = "set_news_publish_click_status";

// deliverable
export const DELIVERABLE_OVERVIEW_UPDATE_STATUS = "deliverable_overview_update_status"
export const SET_DELIVERABLE_OVERVIEW_UPDATE_STATUS = "set_deliverable_overview_update_status"
export const SET_DELIVERABLE_PUBLISH_CLICK_STATUS = "set_deliverable_publish_click_status"
export const SET_DELIVERABLE_DETAILS = "set_deliverable_details"
export const SET_DELIVERABLE_SETTINGS_DETAILS = "set_deliverable_settings_details"

// breadcrumb
export const SET_BREAD_CRUMB = "set_bread_crumb";

// sidenav
export const SET_SIDEBAR_ACTIVE = "set_sidebar_active";

export const SET_SIDE_NAV_ACTIVE_LINK = "set_side_nav_active_link";
export const SET_SIDE_NAV_FORCED_ACTIVE_LINK = "set_side_nav_forced_active_link";
export const SET_SIDE_NAV_TREE_MENU_OPEN = "set_side_nav_tree_menu_open";
export const SET_SIDE_NAV_TREE_MENU_CLOSE = "set_side_nav_tree_menu_close";
export const SET_SIDE_NAV_OPENED_TREE_MENU_STATE = "set_side_nav_opened_tree_menu_state";
export const SET_SIDE_NAV_TREE_MENU_WITH_STATE = "set_side_nav_tree_menu_with_state";

// alertbox
export const SHOW_ALERT_BOX = "show_alert_box";
export const HIDE_ALERT_BOX = "hide_alert_box";
// Syncfusion popup dialouge
export const SHOW_POPUP = "show_popup";
export const HIDE_POPUP = "hide_popup";
// Message Box
export const SHOW_MESSAGE_BOX = "show_message_box";
export const HIDE_MESSAGE_BOX = "hide_message_box";


// State Maintain For Grid
export const SET_STATE = "set_state";
export const RESET_STATE="reset_state";
export const RECORD_VISITED = "record_visited";
export const RECORD_RESET = "record_reset";
export const SKIP_RECORDS= "skip_records";
export const SET_PAGE ="set_page";
export const ACTIVE_PAGE = "active_page";
export const SORT_RECORDS = "sort_records";
export const SET_FILTERS = "set_filters";

export const ROUTE_CHANGED = "route_changed";
export const ROUTE_UNCHANGED = "route_unchanged";

export const INTERNAL_GRID_PRESENT =  "internal_grid_present";
export const INTERNAL_GRID_VISITED = "internal_grid_visited";
export const INTERNAL_GRID_RECORD_UNVISITED = "internal_grid_record_unvisited";

export const INTERNAL_GRID_STATE = "internal_grid_state";
export const RESET_INTERNAL_GRID_STATE = "reset_internal_grid_state";

export const SET_INTERNAL_SKIP = "set_internal_skip";
export const RESET_INTERNAL_GRID = "reset_internal_grid";
export const SET_INTERNAL_FILTERS = "set_internal_filters";
export const SET_INTERNAL_SORT = "set_internal_sort";
export const SET_INTERNAL_ACTIVE_PAGE = "set_internal_active_page";
export const SET_INTERNAL_RECORDS_PER = "set_internal_records_per_page";
export const SET_TAB = "set_tab";

// notification
// export const NEWS_OVERVIEW_UPDATE_STATUS = "news_overview_update_status";
export const SET_NOTIFICATION_OVERVIEW_UPDATE_STATUS = "set_notification_overview_update_status";
export const SET_NOTIFICATION_PUBLISH_CLICK_STATUS = "set_notification_publish_click_status";

//news
export const SET_NEWS_BASIC_TAB = "set_news_basic_tab"

//search
export const SET_SEARCH_KEYWORDS = "set_search_keywords"
export const GET_TECHNOLOGY_LIST = "get_technology_list"
export const SET_FIRST_TREE_CHECKED_NODES = "set_first_tree_checked_nodes"
export const SET_SECOND_TREE_CHECKED_NODES ="set_second_tree_checked_nodes"
export const SET_ALL_SEARCH_COUNT ="set_all_search_count"
export const SET_FIXED_ALL_SEARCH_COUNT ="set_fixed_all_search_count"
export const SET_SEARCH_SORT ="set_search_sort"
export const SET_DATE_FILTER_VALUE ="set_date_filter_value"
export const SET_FROM_DATE ="set_from_date"
export const SET_TO_DATE ="set_to_date"
export const SET_CONTENT_TYPE_COUNT ="set_content_type_count"
export const SET_RESULT_COUNT = 'set_result_count';
export const SET_FIXED_CONTENT_TYPE_COUNT = 'set_fixed_content_type_count';
export const SET_CURRENT_QUERY = 'set_current_query';
export const SET_CURRENT_QUERY_NEW_SEARCH = 'set_current_query_new_search';
export const SET_CONTENT_TYPE_FILTER_SEARCH = 'set_content_type_filter_search';
export const SET_SEARCH_SELECTED_FILTER_NODES = 'set_search_selected_filter_nodes';
export const SET_TREND_FILTER_SEARCH = 'set_trend_filter_search';
export const GET_SECTORS_SEARCH = 'get_sectors_search';
export const SET_DATE_FILTER = 'set_date_filter';
export const TOGGLE_LOADING = "toggle_loading";
export const SET_SGF_IDS_SEARCH = "set_sgf_ids_search";
export const GET_CONTENT_TYPE_SEARCH = "get_content_type_search";
export const CLEAR_ALL_SEARCH_FILTER = "clear_all_search_filter";
export const GET_TRENDS_LIST = "get_trends_list";
export const GET_INDUSTRIES_LIST = "get_industries_list";
export const SET_STATUS_FILTER = "set_status_filter";