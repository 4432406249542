
/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 21/Jun/2021
 * File Description: Assessment Routes
 */
import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";

import AssessmentListing from "../app/views/assessment/index";
import Form from "../app/views/assessment/assessmentDetails"

// import SurveyRender from "../app/views/survey/component/render"

// import DefaultLayout from "../app/layout/defaultLayout";

const ASSESSMENT = ["Assessment Management:FULL"];

const ADD = ["Add:VIEW", "Add:FULL"];
const EDIT = ["Edit:VIEW", "Edit:FULL"];
const DELETE = ["Delete:VIEW", "Delete:FULL"];

const AssessmentRoutes = () => (
	<Switch>
		<AuthLayout exact path="/assessment" component={AssessmentListing} pageHeading="GenNx CMS - Assessment Management"
			accessRights={{
				moduleName: "Assessment", parentModuleName: "Outsourcing",
				moduleAttribute: [...ASSESSMENT,...ADD, ...EDIT, ...DELETE]
			}} />
		<AuthLayout exact path={"/assessment/:assessmentId/edit"} component={Form} pageHeading="GenNx CMS - Assessment Management"
			accessRights={{
				moduleName: "Assessment", parentModuleName: "Outsourcing",
				moduleAttribute: [...ASSESSMENT, ...EDIT]
			}} />

		<AuthLayout exact path="/assessment/add" component={Form} pageHeading="GenNx CMS - Assessment Management"
			accessRights={{
				moduleName: "Assessment", parentModuleName: "Outsourcing",
				moduleAttribute: [...ASSESSMENT, ...ADD]
			}} />

		{/* <DefaultLayout exact
			path={["/survey-render/:surveyShortName/:surveyAudienceCode", "/survey-render/:surveyShortName"]} component={SurveyRender} pageHeading="Survey" /> */}

	</Switch>
);

export default AssessmentRoutes;
