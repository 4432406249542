/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/*
================================================================
  Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
    File Description : Sync Fusion Grid Component with Server Side rendering, filtering & sort
----------------------------------------------------------------
  Creation Details
  Date Created				: 03/Jul/2020
  Author						: YOGESH N. GUPTA
================================================================
*/

import "./gridBootstrap.scss";
import React, { useEffect, useState, useRef } from "react";
import PropTypes, { object } from "prop-types";
import {
  // Grid, PageSettingsModel, DataStateChangeEventArgs,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Selection,
  Inject,
  Page,
  Sort,
  Filter,
  ColumnChooser,
  Toolbar,
  Edit,
  Resize,
  dataBound,
  RowDD,
  Freeze,
  beforeDataBound,

  //  EditSettingsModel, load
} from "@syncfusion/ej2-react-grids";
import { createElement } from "@syncfusion/ej2-base";
import { DateRangePicker } from "@syncfusion/ej2-react-calendars";
import { useSelector, useDispatch } from "react-redux";

import { DropdownList } from "react-widgets";
import * as CONFIG from "../../../config";
import Pagination from "../pagination";
import DataImport from "../dataImport";
import DataImportScreening from "../dataImportScreening";
import FilterTaxonomy from "../filterByTaxonomy";
import {
  resetMaintainedState,
  activePageInfo,
  setRecordsPerPage,
  skipRecords,
  setSortingData,
  unVisitRecord,
  setFilterParams,
  resetInternalGrid,
  internalGridUnvisited,
  setInternalFilters,
  setInternalSkip,
  setInternalActivePages,
  setInternalRecordsPerPage,
  setInternalSort,
  visitRecord,
  internalGridVisited,
} from "../../../middleware/actions/gridAction";
import { IoMdFunnel } from "react-icons/io";
import { ChipListComponent, ChipsDirective, ChipDirective } from "@syncfusion/ej2-react-buttons";

import classes from "./SelectedFilterSection.module.css";
import {
  resetRedux,
  setFilteredTaxonomy,
  setSelectedTechnology,
  setSelectedTrendTaxonomy,
  setSelectedMediaTaxonomy,
  setSelectedBOTaxonomy,
  setSelectedKia,
  setSelectedValueChain,
  setParentNodeName,
} from "../../../middleware/actions/taxonomyFilterAction";
import { getDateFromMongoDate, getProductVariant, isAdmin } from "../../../utilities";

const isDate = (str) => {
  str = str.toString();
  let param = str.split(" ");
  return param[0].length == 3 && param[1].length == 3 && param[2].length == 2 && param[3].length == 4;
};
const getDateFromMongoDBDate = (date) => {
  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  let newDate = new Date(date);
  newDate = `${newDate.getFullYear()}-${months[newDate.getMonth()]}-${
    newDate.getDate().toString().length == 1 ? "0" + newDate.getDate() : newDate.getDate()
  }`;
  return newDate;
};

const CommonDataGrid = (props) => {
  const sideNavActiveState = useSelector((state) => state.sideNavState.active);
  const dispatch = useDispatch();
  const gridState = useSelector((state) => state.gridState);
  const filterCommonState = useSelector((state) => state.taxonomyFilterState);
  const { isChildGrid } = props;

  const {
    autoFitColumnName = [],
    showColumnChooser = true,
    autoFitColumn = false,
    deleteId = undefined,
    filterValue = undefined,
    fetch,
    deleteOne,
    deleteMany = undefined,
    columnNames = {},
    columnFields = [],
    formatRowData,
    uniqueVal,
    showLoader = () => {
      return;
    },

    uploadRight = false,
    uploadRightScreeningRejection = false,
    importPatent = false,
    importAccessAudience = false,
    exportAccessAudience = false,
    exportRight = false,
    exportScreeningRight = false,
    deleteRight = false,
    queryCellInfo = () => {
      return;
    },
    hideLoader = () => {
      return;
    },
    isExport = undefined,
    taxonomyFiltering = undefined,
    isImport = undefined,
    getMultiSelectedRows = undefined,
    getMultiDeSelectedRows = undefined,
    allowRowDragAndDrop = false,
    isPaging = true,
    showResetFilter = true,
    freezeColumn = 0,
    freezeRow = 0,
    allowPagination = true,
    onRowDrop = undefined,
    sequenceChangeId = undefined,
    hideId = undefined,
    unhideId = undefined,
    objectiveTaxonomyRight = false,
    objectiveTaxonomyTag = () => null,
    gridTitle = "Records",
    addNew,
    isPublish = undefined,
    publishMany = false,
    addNewRight = false,
    allocateRight = false,
    deallocateRight = false,
    companyAllocate,
    companyDeallocate,
    allowFiltering = true,
    showFilterByTaxonomy = false,
    taxonomyBulkTagging = () => null,
    trendBulkTagging = () => null,
    mediaBulkTagging = () => null,
    getGridRef = undefined,
    dataUpload = false,
  } = props;

  const gridRef = useRef();
  const [show, setShow] = useState(false);
  const [showScreening, setShowScreening] = useState(false);
  const [showFilterTaxonomy, setShowFilterTaxonomy] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [columnFieldsToShow, setColumnFieldsToShow] = useState([...columnFields]);
  const [totalCount, setTotalCount] = useState(0);

  const [clearAll, setClearAll] = useState(false);
  const [filters, setFilters] = useState([]);
  const [fields, setFields] = useState([]);
  const [sort, setSort] = useState("");
  const [skip, setSkip] = useState(0);
  const [slectedId, setSelectedId] = useState(props?.clearFilterByTaxonomy ? [] : filterCommonState.checkedNodes);
  const [reRender, setReRender] = useState(false);
  const [showRemainingFilters, setShowRemainingFilters] = useState(false);
  let limit = isChildGrid ? gridState.internalGridState.recordsPerPage : gridState.recordsPerPage;

  const [pageSize, setPageSize] = useState(
    allowPagination ? (isChildGrid ? gridState.internalGridState.recordsPerPage : gridState.recordsPerPage) : 50000 || 2
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [fetchNext, setFetchNext] = useState(false);

  const PAGE_SIZE_RANGE = CONFIG.PAGE_SIZE_RANGE || [10, 15, 50, 100];
  const [selectedNode, setSelectedNode] = useState([]);
  const [storeCheckedNode, setStoreCheckedNode] = useState([]);
  const [showTaxonomyBulk, setShowTaxonomyBulk] = useState(false);
  const [enableBulkTaxonomy, setEnableBulkTaxonomy] = useState(false);
  const productVariant = getProductVariant();
  const FILTER_SETTINGS = { type: "Menu" };
  const [myRole, setMyRole] = useState(true);
  /*
  ====================================== Toolbars Settings====================================
  */
  // Reset filter
  const resetFilter = {
    text: "Reset Filters",
    cssClass: showResetFilter ? "" : "e-hidden",
    prefixIcon: "e-custom-icons e-filternone",
    id: "resetFilters",
  };

  // Import company and their products
  const importExcel = { text: "Import Excel", cssClass: uploadRight ? "" : "e-hidden", prefixIcon: "fas fa-upload", id: "uploadExcel" };

  // Import survey audience users
  const importSurveyAudience = {
    text: "Import Audience",
    cssClass: importAccessAudience ? "" : "e-hidden",
    prefixIcon: "fas fa-upload",
    id: "importAudience",
  };

  // Import company which do you want to upload for reject technology/trend
  const importScreeningRejection = {
    text: "Import for Rejection",
    cssClass: uploadRightScreeningRejection ? "" : "e-hidden",
    prefixIcon: "fas fa-upload",
    id: "uploadRejection",
  };

  // Export survey audience users
  const exportSurveyAudience = {
    text: "Export Audience",
    cssClass: exportAccessAudience ? "" : "e-hidden",
    prefixIcon: "fas fa-download",
    id: "exportAudience",
  };

  // Export company and their products
  const exportData = { text: "Export ", cssClass: exportRight ? "" : "e-hidden", prefixIcon: "fas fa-download", id: "dataExport" };

  // Export company which do you want to download for reject technology/trend
  const exportScreeningData = {
    text: "Export",
    cssClass: exportScreeningRight ? "" : "e-hidden",
    prefixIcon: "fas fa-download",
    id: "dataScreeningExport",
  };

  // Delete selected records or multiple delete
  const deleteToolbar = { text: "Delete ", cssClass: deleteRight ? "" : "e-hidden", prefixIcon: "fas fa-trash", id: "deleteRecords" };
  // Objective taxonomy toolbar

  const objectiveTaxonomy = {
    text: "Objective Taxonomy ",
    cssClass: objectiveTaxonomyRight ? "" : "e-hidden",
    prefixIcon: "fas fa-tag",
    id: "objectiveTaxonomy",
  };

  const toolbarOptions = [
    showColumnChooser ? "ColumnChooser" : "",
    resetFilter,
    // import
    importExcel,
    importScreeningRejection,
    importSurveyAudience,
    // Export
    exportData,
    exportScreeningData,
    exportSurveyAudience,
    deleteToolbar,
    objectiveTaxonomy,
  ];
  /*
  ====================================== End Toolbar Settings ====================================
  */

  const SELECTION_SETTINGS = {
    persistSelection: false,
    type: "Multiple",
    checkboxOnly: true,
  };

  const OPERATORS = {
    equal: "eq",
    notequal: "neq",
    notexist: "nex",
    contains: "cn",
    startswith: "stw",
    endswith: "enw",
    greaterthan: "gt",
    greaterthanorequal: "gte",
    lessthan: "lt",
    lessthanorequal: "lte",
  };

  const SORT_ORDER = {
    Ascending: "asc",
    Descending: "desc",
  };

  if (getGridRef) {
    getGridRef(gridRef);
  }

  /**
   * @description grid filter Icon and Sort Icon placement according the width of the cell
   */
  const setIconAlignment = () => {
    // var gridinstances =  (document.querySelector(".e-grid")[0]).ej2_instances[0];
    const gridinstances = document.querySelector(".e-grid");
    const instancesNew = gridinstances && gridinstances.ej2_instances[0];
    const headercells = instancesNew && instancesNew.element.querySelectorAll(".e-headercell");

    for (let i = 0; i < headercells?.length; i++) {
      if (headercells[i].querySelector(".e-headertext")) {
        var headertextwidth = headercells[i].querySelector(".e-headertext").getBoundingClientRect().width;
        var headercelldivwidth = headercells[i].querySelector(".e-headercelldiv").getBoundingClientRect().width;
        // var calx = parseInt((headercelldivwidth / headertextwidth + (headercelldivwidth - headertextwidth - 50)));
        let rightSortFiltermargin = 0;
        let distance = 10;
        let rightFilterMargin = 0;
        let sortIconWidth = 16;
        let headercellPaddingRight = 10;
        let headercellPaddingLeft = 10;
        if (headercells[i].classList.contains("e-leftalign") == true) {
          rightSortFiltermargin = headercelldivwidth - headertextwidth - (distance * 2 + headercellPaddingRight + headercellPaddingLeft);
          rightFilterMargin = headercelldivwidth - headertextwidth - (sortIconWidth + distance * 2 + headercellPaddingLeft + headercellPaddingLeft);
        }
        if (headercells[i].classList.contains("e-centeralign") == true) {
          rightSortFiltermargin = (headercelldivwidth - headertextwidth) / 2 - (sortIconWidth + distance);
          rightFilterMargin = (headercelldivwidth - headertextwidth) / 2 - (15 + sortIconWidth + distance);
        }
        if (headercells[i].classList.contains("e-rightalign") == true) {
          rightSortFiltermargin = headercelldivwidth - headertextwidth - (sortIconWidth + distance);
          rightFilterMargin = 2;
        }
        // change the filtericon margin

        if (headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter")) {
          // change the filter icon position
          if (headercells[i].classList.contains("e-rightalign") == true) {
            headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter").style.margin = "-21px " + 10 + "px" + " -18px 0px";
          } else if (headercells[i].classList.contains("e-leftalign") == true) {
            headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter").style.margin = "-21px " + rightFilterMargin + "px" + " -18px 0px";
          } else {
            headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter").style.margin = "-21px " + rightFilterMargin + "px" + " -18px 0px";
          }
          headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter").style.padding = "6px 0px 6px 0px";
          headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter").style.display = "block";
        }

        // change the sorticon margin
        if (headercells[i].querySelector(".e-sortfilterdiv.e-icons")) {
          // change the Sort icon position
          if (headercells[i].classList.contains("e-rightalign") == true) {
            headercells[i].querySelector(".e-sortfilterdiv.e-icons").style.margin = "-23px " + rightSortFiltermargin + "px" + " -15px 0px";
          } else if (headercells[i].classList.contains("e-leftalign") == true) {
            headercells[i].querySelector(".e-sortfilterdiv.e-icons").style.margin = "-23px " + rightSortFiltermargin + "px" + " -15px 0px";
          } else {
            headercells[i].querySelector(".e-sortfilterdiv.e-icons").style.margin = "-23px " + rightSortFiltermargin + "px" + " -15px 0px";
          }
        }
        headercells[i].querySelector(".e-sortfilterdiv.e-icons").style.display = "block";
      }
    }
    // document.querySelector(".e-filtermenudiv").style.display = "block"
  };

  const beforeGridDataBound = (e) => {
    e.query.queries[0].e.pageSize = e.count;
    e.result = rowData;
    return e.result;
  };

  const dataBound = () => {
    if (gridRef.current?.frozenRightCount) {
      if (gridRef.current.currentViewData.length === 0) {
        var frozencontent = gridRef.current.element.querySelector(".e-frozencontent .e-emptyrow td");
        // var frozenborder = gridRef.current.element.querySelector(".e-grid .e-frozencontent.e-frozen-right-content > .e-table");
        frozencontent.innerText = "";
        // frozenborder?.style?.borderLeft = "none";
      } else {
        // var frozenborder = gridRef.current.element.querySelector(".e-grid .e-frozencontent.e-frozen-right-content > .e-table");

        var movablescrollbar = gridRef.current.element.querySelector(".e-movablescrollbar");
        // frozenborder?.style?.borderLeft = "1px solid #dee2e6";
        movablescrollbar.style.display = "";
      }

      // setTimeout(() => {
      var movableBody = document.querySelector(".e-movablecontent");
      if (movableBody) {
        var heightToAdd = 0;
        for (let n = 0; n < gridRef.current?.currentViewData?.length; n++) {
          var movableTr = document.querySelector(`.e-movablecontent tbody tr:nth-child(${n})`);
          var frozenTr = document.querySelector(`.e-frozencontent tbody tr:nth-child(${n})`);
          if (movableTr?.offsetHeight && frozenTr?.offsetHeight) {
            if (movableTr?.offsetHeight !== frozenTr?.offsetHeight) {
              heightToAdd += movableTr?.offsetHeight - frozenTr?.offsetHeight;
              frozenTr.style.height = movableTr?.offsetHeight + "px";
            }
          }
        }

        movableBody.style.height = movableBody?.offsetHeight + heightToAdd + "px";
      }

      // }, 100);
    } else {
      var emptyRowTD = gridRef?.current?.element?.querySelector(".e-gridcontent .e-content .e-emptyrow td");

      if (emptyRowTD) emptyRowTD.style.display = "none";
    }

    autoFitColumn && gridRef.current.autoFitColumns([...autoFitColumnName]);

    if (deleteMany) {
      const btn = document.querySelector("#deleteRecords");
      btn.disabled = true;
    }
    setTimeout(() => {
      setIconAlignment();
    }, 500);
    if (!isChildGrid) {
      clearLocalGrid();
    }
  };

  const getColumnName = (colName) => (columnNames[colName] ? columnNames[colName] : colName);

  /**
   * @description grid row checkbox selection function
   */
  const rowSelected = (e) => {
    if (gridRef) {
      /** Get the selected records. */
      const selectedrecords = gridRef.current.getSelectedRecords();
      const ids = selectedrecords.map((data) => {
        return data.id;
      });
      if (getMultiSelectedRows) getMultiSelectedRows(ids);

      if (deleteMany) {
        const btn = document.querySelector("#deleteRecords");
        if (ids.length > 0) {
          btn.disabled = false;
        } else {
          btn.disabled = true;
        }
      }
    }
  };
  /**
   * @description grid row checkbox selection / de-selection function
   */
  const rowDeSelected = (e) => {
    if (gridRef?.current) {
      /** Get the selected / de-selected records. */
      const selectedrecords = gridRef.current.getSelectedRecords();
      const ids = selectedrecords.map((data) => {
        return data.id;
      });
      if (getMultiDeSelectedRows) {
        getMultiDeSelectedRows(ids);
      }
      // to change the state of delete Button
      if (deleteMany) {
        const btn = document.querySelector("#deleteRecords");
        if (ids.length > 0) {
          btn.disabled = false;
        } else {
          btn.disabled = true;
        }
      }
    }
  };

  /**
   * @description set filtering for grid
   * @param {String} action
   * @param {Object} filterObject
   * @param {Array} columns
   */
  const setFiltering = (action = undefined, filterObject = undefined, columns = []) => {
    let newFilters = [];
    switch (action) {
      case "filter":
        columns.map((c) => {
          if (
            !isNaN(Date.parse(c.properties?.value)) &&
            c.properties.type === "date" &&
            (getColumnName(c.properties.field) !== "publishDate" ||
              getColumnName(c.properties.field) !== "companyCreatedDate" ||
              getColumnName(c.properties.field) !== "companyUpdatedDate")
          ) {
            c.properties.value = getDateFromMongoDBDate(c.properties.value);
          }
          if (getColumnName(c.properties?.field) === "companyCreatedDate" && c.properties.type === "date" && window.customFilter) {
            newFilters.push([getColumnName("companyCreatedDate"), "lte", window["endDate"] ? getDateFromMongoDBDate(window["endDate"]) : ""]);
          }
          if (getColumnName(c.properties?.field) === "companyUpdatedDate" && c.properties.type === "date" && window.customFilter) {
            newFilters.push([getColumnName("companyUpdatedDate"), "lte", window["endDate"] ? getDateFromMongoDBDate(window["endDate"]) : ""]);
          }
          if (
            (getColumnName(c.properties?.field) === "newsPublishedDate" ||
              getColumnName(c.properties?.field) === "deliverablePublishedDate" ||
              getColumnName(c.properties?.field) === "productVariant.lastPublishedDate") &&
            c.properties.type === "date" &&
            window.customFilter
          ) {
            newFilters.push([getColumnName("publishDate"), "lte", window["endDate"] ? getDateFromMongoDBDate(window["endDate"]) : ""]);
          }
          if (c.properties.value === "-") {
            newFilters.push([getColumnName(c.properties.field), "eq", ""]);
            return;
          } else if (c.properties.value === " ") {
            newFilters.push([getColumnName(c.properties.field), "nex", ""]);
            return;
          }
          //filter for News Type (Insights marketing article)
          if (getColumnName(c.properties.field) === "newsType" && c.properties.value === "YES") {
            newFilters.push([getColumnName("newsMarketingArticle"), OPERATORS[c.properties.operator], c.properties.value ? c.properties.value : ""]);
            return;
          }
          if (getColumnName(c.properties.field) === "newsType" && props.productVariant === "Insider") {
            newFilters.push([getColumnName(c.properties.field), OPERATORS[c.properties.operator], c.properties.value ? c.properties.value : ""]);
            newFilters.push([getColumnName("newsMarketingArticle"), OPERATORS[c.properties.operator], "NO"]);
            return;
          }
          newFilters.push([getColumnName(c.properties.field), OPERATORS[c.properties.operator], c.properties.value ? c.properties.value : ""]);
          return;
        });
        setSkip(0);
        dispatch(skipRecords(0));
        if (gridState.recordVisited) dispatch(unVisitRecord());
        if (gridState.internalGridState) dispatch(internalGridUnvisited());
        setFilters(newFilters);
        if (isChildGrid) {
          dispatch(setInternalFilters(newFilters));
          dispatch(setInternalActivePages(1));
        } else {
          dispatch(setFilterParams(newFilters));
          dispatch(activePageInfo(1));
        }
        break;
      case "clearFilter":
        if (gridState.filtersParams) {
          newFilters = gridState.filtersParams.filter((pF) => {
            //clear filter for News Type (Insights marketing article)
            if (pF[0] === "newsMarketingArticle") {
              return "newsType" !== getColumnName(filterObject?.properties && filterObject?.properties?.field);
            }
            return pF[0] !== getColumnName(filterObject?.properties && filterObject?.properties?.field);
          });
        } else {
          newFilters = filters.filter((pF) => {
            return pF[0] !== getColumnName(filterObject?.properties?.field);
          });
        }
        setSkip(0);
        dispatch(skipRecords(0));
        dispatch(setFilterParams(newFilters));
        setFilters(newFilters);
        props.clearFilterValue && props.clearFilterValue();
        gridRef.current.clearRowSelection(); //Uncheck all rows selection
        dispatch(activePageInfo(1));
        break;
      default:
        break;
    }
  };

  /**
   * @description grid action complete event
   */
  const actionComplete = (e) => {
    if (rowData) {
      setIconAlignment();
    }
    switch (e.requestType) {
      case "filtering":
        setFiltering(e.action, e.currentFilterObject, e.columns);
        break;
      case "filterafteropen":
        if (
          [
            "Technology",
            "Trend",
            "Industry",
            "Client Users",
            "Screening Mode",
            "Relevancy",
            "Relevancy Type",
            "Notification Type",
            "Communication Mode",
            "newsType",
            "Status",
            "Visibility",
            "Ticket Category",
          ].includes(e.columnName)
        ) {
          e.filterModel.dlgObj.element.children[0].firstElementChild.children[0].style.display = "none";
        }
        break;
      case "columnstate":
        dataBound();
        break;
      default:
        break;
    }
  };

  /**
   * @description grid action begin event
   */
  const actionBegin = (e) => {
    switch (e.requestType) {
      case "sorting":
        setSorting(e.columnName, e.direction);
        break;
      default:
        break;
    }
  };

  const customFilter = {
    ui: {
      create: (args) => {
        let flValInput = createElement("input", { className: "flm-input" });
        args.target.appendChild(flValInput);
        let columnName = args?.column?.field || "";
        let fdate = [];
        gridRef.current.filterSettings.columns.forEach((col) => {
          if (col.field === "publishDate" || col.field === "companyCreatedDate" || col.field === "companyUpdatedDate") {
            fdate.push(window["startDate"]);
            fdate.push(window["endDate"]);
          }
        });
        const daterangepicker = new DateRangePicker({
          placeholder: "Choose Date Range",
          max: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          change: function (e) {
            if (e !== undefined && e.value) {
              let grid = gridRef.current;
              window["startDate"] = e.value[0];
              window["endDate"] = e.value[1];
              window["columnName"] = columnName;
              window["customFilter"] = true;
              if (columnName === "companyCreatedDate") grid.filterByColumn("companyCreatedDate", "greaterthanorequal", e.value[0]);
              if (columnName === "companyUpdatedDate") grid.filterByColumn("companyUpdatedDate", "greaterthanorequal", e.value[0]);
              if (columnName === "publishDate") grid.filterByColumn("publishDate", "greaterthanorequal", e.value[0]);
            }
          },
          allowEdit: false,
          format: "dd-MMM-yyyy",
        });
        if (fdate.length > 0 && columnName === window.columnName) {
          daterangepicker.startDate = fdate[0];
          daterangepicker.endDate = fdate[1];
        }
        daterangepicker.appendTo(flValInput);
        if (daterangepicker.inputElement.value) {
          if (columnName === window.columnName) {
            daterangepicker.inputElement.value = `${getDateFromMongoDate(fdate[0])} to ${getDateFromMongoDate(fdate[1])}`;
          } else {
            daterangepicker.inputElement.value = "";
          }
        }
      },
      read: (args) => {
        args.fltrObj.filterByColumn(args.column.field, args.operator, this.dropInstance.value);
      },

      write: (args) => {},
    },
  };

  const clearLocalGrid = () => {
    const arr = []; // Array to hold the keys
    // Iterate over localStorage and insert the keys that meet the condition into arr
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 8) == "gridgrid") {
        arr.push(localStorage.key(i));
      }
    }
    for (let i = 0; i < arr.length; i++) {
      localStorage.removeItem(arr[i]);
    }
  };

  /**
   * @description set sorting for grid
   * @param {String} sortColumn
   * @param {String} sortOrder
   */
  const setSorting = (sortColumn = undefined, sortOrder = undefined) => {
    setSkip(0);
    isChildGrid ? dispatch(setInternalSkip(0)) : dispatch(skipRecords(0));
    if (sortColumn && sortOrder) {
      let newSort = "";
      if (sort === "") {
        newSort = `${getColumnName(sortColumn)}:${SORT_ORDER[sortOrder]}`;
      } else {
        let str = sort;
        if (str.includes(getColumnName(sortColumn))) {
          const strArr = [];
          str.split(",").forEach((s) => {
            if (!s.includes(getColumnName(sortColumn))) strArr.push(s);
          });
          str = strArr.join(",");
        }
        newSort = `${str !== "" ? `${str},` : ""}${getColumnName(sortColumn)}:${SORT_ORDER[sortOrder]}`;
      }
      if (isChildGrid) {
        dispatch(setInternalSort(newSort));
        setSort(newSort);
        dispatch(setInternalActivePages(1));
      } else {
        dispatch(setSortingData(newSort));
        setSort(newSort);
        dispatch(activePageInfo(1));
      }
    } else {
      let data = undefined;
      dispatch(setSortingData(data));
      setSort("");
    }
  };

  /**
   * @description pagination onchange handler
   * @param {Number} pageNum
   */
  const handlePageChange = (pageNumber) => {
    let pageNum = parseInt(pageNumber.selected) + 1;
    if (isChildGrid) {
      dispatch(setInternalSkip((pageNum - 1) * gridState.internalGridState.recordsPerPage));
    } else {
      if (gridState.recordVisited) {
        dispatch(skipRecords((pageNum - 1) * gridState.recordsPerPage));
      }
    }

    if (pageSize === limit) {
      setCurrentPage(1);
      setFetchNext(true);
    } else {
      let currPage = parseInt(((pageNum * pageSize) % limit) / pageSize),
        skipPage = parseInt((pageNum * pageSize) / limit) * limit;
      if (currPage === 0) {
        currPage = pageSize;
        setFetchNext(true);
        if (skip === skipPage) setSkip(skipPage - limit);
      }
      setCurrentPage(currPage);
      if (currPage % pageSize !== 0) {
        if (skip !== skipPage) {
          setFetchNext(true);
          setSkip(skipPage);
        }
      }
    }
    if (isChildGrid) {
      setActivePage(pageNum);
      dispatch(setInternalActivePages(pageNum));
      setSkip(parseInt((pageNum - 1) * limit));
    } else {
      setActivePage(pageNum);
      dispatch(activePageInfo(pageNum));
      setSkip(parseInt((pageNum - 1) * limit));
    }
    gridRef.current.scrollModule.previousValues.top = 0;
    gridRef.current.clearRowSelection();
  };

  /**
   * @description Grid toolbar action click handler
   * @param {any} args
   */
  const clickHandler = (args) => {
    const selectedrecords = gridRef.current.getSelectedRecords();
    const ids = selectedrecords.map((data) => {
      return data.id;
    });
    switch (args.target.id) {
      case "clearFilter":
        setShowFilterTaxonomy(false);
        if (isChildGrid) {
          dispatch(resetInternalGrid());
        } else {
          dispatch(resetMaintainedState());
        }
        setSkip(0);
        setFilters([]);
        props.clearFilterValue && props.clearFilterValue();
        setSort("");
        setClearAll(true);
        setActivePage(1);
        gridRef.current.clearFiltering();
        gridRef.current.clearSorting();
        break;
      // Import
      case "uploadExcel":
        setShowRemainingFilters(false);
        setShowFilterTaxonomy(false);
        setShow(true);
        break;
      case "uploadRejection":
        setShowScreening(true);
        break;
      case "importAudience":
        isImport(true);
        break;
      // Export
      case "exportAudience":
        isExport(true, ids);
        break;
      case "dataExport":
        setShowFilterTaxonomy(false);
        isExport(true, ids);
        break;
      case "dataScreeningExport":
        isExport(true, ids);
        break;
      case "deleteRecords":
        setShowFilterTaxonomy(false);
        deleteMany(true);
        break;
      case "objectiveTaxonomy":
        setShowFilterTaxonomy(false);
        objectiveTaxonomyTag();
        break;
      case "importPatent":
        isImport(true);
        setShowFilterTaxonomy(false);
        break;
      case "openColumnChooser":
        setShowRemainingFilters(false);
        setShowFilterTaxonomy(false);
        sideNavActiveState
          ? gridRef.current.columnChooserModule.openColumnChooser(args.clientX - 248 - 260, 0)
          : gridRef.current.columnChooserModule.openColumnChooser(args.clientX - 248, 0);
        break;
      case "addNew":
        isChildGrid ? dispatch(internalGridVisited()) : dispatch(visitRecord());
        addNew();
        break;
      case "publishMany":
        isPublish(true);
        break;
      // break;
      case "companyAllocate":
        companyAllocate();
        break;
      case "companyDeallocate":
        companyDeallocate();
        break;
      case "taxonomyFilter":
        // props.setCssClass("treeClass")
        // props.setListClass("select-lists")
        setShowRemainingFilters(false);
        setShowFilterTaxonomy(true);
        setEnableBulkTaxonomy(false);
        props?.handleTaxonomyTabName && props.handleTaxonomyTabName(false);
        props?.setShowTaxonomyBulk && props.setShowTaxonomyBulk(false);

        break;
      case "taxonomyBulkTagging":
        setShowTaxonomyBulk(true);
        setShowFilterTaxonomy(false);
        taxonomyBulkTagging();
        setEnableBulkTaxonomy(true);
        props.handleTaxonomyTabName(true);
        break;
      default:
        break;
    }
  };

  const cleanUpFilters = () => {
    dispatch(resetMaintainedState());
    setSkip(0);
    setFilters([]);
    setSort("");
    setClearAll(true);
    setActivePage(1);
    gridRef.current.clearFiltering();
    gridRef.current.clearSorting();
  };

  useEffect(() => {
    if (gridState.isRouteChanged && !gridState.recordVisited) {
      const clear = document.querySelector("#clearFilter");
      const content = document.getElementsByClassName("e-headertext:last-of-type");
      setTimeout(() => {
        clear && content && clear.click() && cleanUpFilters();
      }, 0);
    }
  }, []);
  useEffect(dataBound, [sideNavActiveState]);

  /**
   * @description fetch grid data for next iteration (next page using pagination)
   */
  const fetchNextGridData = () => {
    if (skip || (skip === 0 && fetchNext)) {
      const params = {};
      if (filters.length) params.filters = filters;
      if (fields.length) params.fields = fields;
      if (sort !== "") params.sort = sort;
      if (skip) params.skip = skip;
      if (limit) params.limit = limit;
      showLoader();
      fetch(params)
        .then((res) => {
          setRowData(formatRowData(res.data.data));
          setFetchNext(false);
          hideLoader();
        })
        .catch(() => {
          hideLoader();
        });
    }
  };
  useEffect(fetchNextGridData, [
    isChildGrid
      ? gridState.internalGridState.recordVisited
        ? gridState.internalGridState.skipRecords
        : skip
      : gridState.recordVisited
      ? gridState.skipRecords
      : skip,
  ]);

  /**
   * @description fetch grid data for first time, also when filters & sort changes
   */
  const fetchGridData = () => {
    if (skip === 0 || clearAll) {
      const params = {};
      if (filters.length) params.filters = filters;
      if (fields.length) params.fields = fields;
      if (sort !== "") params.sort = sort;
      if (limit) params.limit = limit;
      showLoader();

      fetch(params)
        .then(async (res) => {
          setTotalCount(res.data.totalCount);
          setRowData(formatRowData(res.data.data));
          // const uniqueField = await DataUtil.distinct(res.data.data, "productCompletionStatus");
          // if (uniqueField) uniqueVal(uniqueField);
          gridRef.current.clearRowSelection();
          setCurrentPage(1);
          setActivePage(1);
          setFetchNext(false);
          setSkip(0);
          if (res.data.data.length === 0) {
            showNoRecordFound(true);
          } else {
            showNoRecordFound(false);
          }
          hideLoader();
        })
        .catch(() => {
          hideLoader();
        });
    }
  };
  const arr = filterValue
    ? [filters, fields, sort, limit, clearAll, ...filterValue, slectedId, reRender, myRole]
    : [filters, fields, sort, limit, clearAll, slectedId, reRender, myRole];
  useEffect(fetchGridData, arr);
  /**
   * @description reload grid
   */
  const reloadGridData = () => {
    if (deleteId || hideId || unhideId || sequenceChangeId) {
      gridRef.current.clearRowSelection(); //Uncheck all rows selection
      const params = {};
      if (filters.length) params.filters = filters;
      if (fields.length) params.fields = fields;
      if (sort !== "") params.sort = sort;
      if (skip) params.skip = skip;
      if (limit) params.limit = limit;
      showLoader();
      fetch(params)
        .then((res) => {
          if (currentPage !== 1) {
            if (parseInt((totalCount - 1) % pageSize) === 0) {
              setCurrentPage(currentPage - 1);
              setActivePage(activePage - 1);
            }
          }
          setTotalCount(res.data.totalCount);
          setRowData(formatRowData(res.data.data));
          hideLoader();
          if (res.data.data.length === 0) {
            showNoRecordFound(true);
          } else {
            showNoRecordFound(false);
          }
        })
        .catch(() => {
          hideLoader();
        });
    }
  };

  // Function to show hide No Record Found Text
  const showNoRecordFound = (showNoRecordText) => {
    setTimeout(() => {
      if (showNoRecordText) {
        if (gridRef.current.frozenRightCount) {
          let movablecontent = gridRef.current.element.querySelector(".e-gridcontent .e-content .e-movablecontent td");
          if (movablecontent) {
            movablecontent.setAttribute("colspan", gridRef.current.getMovableColumns().length);
            movablecontent.style.textAlign = "center";
            movablecontent.innerText = "No Records Found";

            var movablescrollbar = gridRef.current.element.querySelector(".e-movablescrollbar");
            movablescrollbar.style.display = "none";
          }
        } else {
          var emptyRow = gridRef.current.element.querySelector(".e-gridcontent .e-content .e-emptyrow");
          if (emptyRow) {
            var emptyRowTD = gridRef.current.element.querySelector(".e-gridcontent .e-content .e-emptyrow td");
            emptyRow.style.height = "106px";
            emptyRowTD.style.display = "";
            emptyRowTD.style.textAlign = "center";
            emptyRowTD.innerText = "No Records Found";
          }
        }
      }
    }, 200);
  };

  useEffect(reloadGridData, [deleteId]);

  useEffect(reloadGridData, [hideId]);

  useEffect(reloadGridData, [unhideId]);

  useEffect(reloadGridData, [sequenceChangeId]);

  const getPageCalculation = (totalRecords, data) => {
    return totalRecords >= data.recordsPerPage ? (
      <span className="paginate-font">
        {`${1 + (data.activePage - 1) * data.recordsPerPage} - ${
          data.recordsPerPage * data.activePage < totalRecords ? data.recordsPerPage * data.activePage : totalRecords
        } of ${totalRecords}`}{" "}
      </span>
    ) : (
      <span className="paginate-font">{`${1 + (data.activePage - 1) * data.recordsPerPage} - ${totalRecords} of ${totalRecords}`} </span>
    );
  };

  const handleClose = () => {
    setShowRemainingFilters(!showRemainingFilters);
  };
  /*
    @Description : handle removed taxonomy from filtering 
  */
  const handleDeletedTaxonomy = (e) => {
    setShowRemainingFilters(false);
    let deletedTaxonomyId = [e.element.dataset.value];

    let taxonomyIds = slectedId;
    for (let i = 0; i < taxonomyIds.length; i++) {
      let obj = taxonomyIds[i];
      if (deletedTaxonomyId.indexOf(obj.id) !== -1) {
        taxonomyIds.splice(i, 1);
      }
    }
    let data = Object.keys(props.filterData);
    let taxonomyObject = {};
    for (let j = 0; j < data.length; j++) {
      let keyValue = data[j];
      let value = props.filterData[keyValue];
      if (value.indexOf(deletedTaxonomyId[0]) !== -1) {
        value.splice(value.indexOf(deletedTaxonomyId[0]), 1);
      }
      taxonomyObject[keyValue] = value;
    }
    setSelectedId([]);
    // setReRender(!reRender)
    setSelectedId(taxonomyIds);
    let mediaTaxonomyCheck = filterCommonState.selectedMediaTaxonomy.includes(e.element.dataset.value);
    let trendTaxonomyCheck = filterCommonState.selectedTrendTaxonomy.includes(e.element.dataset.value);
    let technologyTaxonomyCheck = filterCommonState.selectedTechnology.includes(e.element.dataset.value);
    let bOTaxonomyCheck = filterCommonState.selectedBOTaxonomy.includes(e.element.dataset.value);
    let valueChainTaxonomyCheck = filterCommonState.selectedValueChain.includes(e.element.dataset.value);
    let kiaTaxonomyCheck = filterCommonState.selectedKia.includes(e.element.dataset.value);
    let parentTaxonomyCheck = filterCommonState.parentNodeName.includes(e.element.dataset.value);

    let id = e.element.dataset.value;
    if (technologyTaxonomyCheck) {
      technologyTaxonomyCheck = filterCommonState.selectedTechnology;
      technologyTaxonomyCheck.splice(technologyTaxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedTechnology(technologyTaxonomyCheck));
      return;
    }
    if (trendTaxonomyCheck) {
      trendTaxonomyCheck = filterCommonState.selectedTrendTaxonomy;
      trendTaxonomyCheck.splice(trendTaxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedTrendTaxonomy(trendTaxonomyCheck));
      return;
    }
    if (mediaTaxonomyCheck) {
      mediaTaxonomyCheck = filterCommonState.selectedMediaTaxonomy;
      mediaTaxonomyCheck.splice(mediaTaxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedMediaTaxonomy(mediaTaxonomyCheck));
      return;
    }
    if (bOTaxonomyCheck) {
      bOTaxonomyCheck = filterCommonState.selectedBOTaxonomy;
      bOTaxonomyCheck.splice(bOTaxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedBOTaxonomy(bOTaxonomyCheck));
      return;
    }
    if (kiaTaxonomyCheck) {
      kiaTaxonomyCheck = filterCommonState.selectedKia;
      kiaTaxonomyCheck.splice(kiaTaxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedKia(kiaTaxonomyCheck));
      return;
    }
    if (valueChainTaxonomyCheck) {
      valueChainTaxonomyCheck = filterCommonState.selectedValueChain;
      valueChainTaxonomyCheck.splice(valueChainTaxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedValueChain(valueChainTaxonomyCheck));
      return;
    }
    if (parentTaxonomyCheck) {
      parentTaxonomyCheck = filterCommonState.parentNodeName;
      parentTaxonomyCheck.splice(parentTaxonomyCheck.indexOf(id), 1);
      dispatch(setParentNodeName(parentTaxonomyCheck));
      return;
    }
    // props.setFilterData(slectedId)
  };
  let stringLength = 0;
  let count = 0;
  let internalgrid = undefined;
  if (props?.isChildGrid) {
    internalgrid = isChildGrid;
  } else {
    internalgrid = true;
  }

  // ** hide filter Menu
  useEffect(() => {
    const handleGetFilterMenu = () => {
      const filterMenu = document.querySelector("div.e-flmenu-maindiv > div.e-flm_optrdiv");
      const filterdate = document.querySelector("div.e-flmenu-maindiv > div.e-flmenu-valuediv > span.e-date-range-wrapper");
      const filterBtn = document.querySelector("div.e-footer-content > button.e-control.e-btn.e-lib.e-flmenu-okbtn.e-primary.e-flat");
      if (filterdate) {
        filterMenu.style.display = "none";
        filterBtn.style.display = "none";
      }
    };
    document.addEventListener("click", handleGetFilterMenu, false);
    return () => {
      document.removeEventListener("click", handleGetFilterMenu, false);
    };
  }, [document, props]);

  return (
    <>
      <div className="gridPanel">
        <nav className="navbar navbar-dark bg-dark rounded-top ">
          <div className="form-inline my-2 my-lg-0  " style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div>
              {addNewRight ? (
                <button className="btn btn-outline-white  active mr-sm-2 btn-sm  " id="addNew" onClick={(e) => clickHandler(e)}>
                  <i className="fas fa-plus pr-1"></i>Add new
                </button>
              ) : null}
              {publishMany ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm action-button " id="publishMany" onClick={(e) => clickHandler(e)}>
                  <i className="fa fa-share pr-1"></i>Publish
                </button>
              ) : null}

              {uploadRightScreeningRejection ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm action-button " id="uploadRejection" onClick={(e) => clickHandler(e)}>
                  <i className="fa fa-upload pr-1"></i>Import for Rejection
                </button>
              ) : null}

              {uploadRight ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm action-button " id="uploadExcel" onClick={(e) => clickHandler(e)}>
                  <i className="fa fa-upload pr-1"></i>Import
                </button>
              ) : null}

              {exportRight ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm action-button " id="dataExport" onClick={(e) => clickHandler(e)}>
                  <i className="fas fa-download pr-1"></i>Export
                </button>
              ) : null}
              {exportScreeningRight ? (
                <button
                  className="btn btn-outline-white active mr-sm-2 btn-sm action-button "
                  id="dataScreeningExport"
                  onClick={(e) => clickHandler(e)}
                >
                  <i className="fas fa-download pr-1"></i>Export
                </button>
              ) : null}
              {allocateRight ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm action-button " id="companyAllocate" onClick={(e) => clickHandler(e)}>
                  <i className="fas fa-user-check pr-1"></i>Allocate
                </button>
              ) : null}
              {deallocateRight ? (
                <button
                  className="btn btn-outline-white active mr-sm-2 btn-sm action-button "
                  id="companyDeallocate"
                  onClick={(e) => clickHandler(e)}
                >
                  <i className="fas fa-user-minus pr-1"></i>De-Allocate
                </button>
              ) : null}
              {deleteMany ? (
                <button className=" btn btn-outline-white active mr-sm-2 btn-sm delete-button" id="deleteRecords" onClick={(e) => clickHandler(e)}>
                  <i className="far fa-trash-alt pr-1"></i>Delete
                </button>
              ) : null}
            </div>
            <div>
              {props.myRole ? (
                <div className={classes.grid_my_role}>
                  <span for="role" className={classes.grid_my_role_label}>
                    My Role{" "}
                  </span>
                  <select
                    name="role"
                    id="role"
                    className={classes.grid_my_role_dropdown}
                    defaultValue={"all"}
                    onChange={(e) => {
                      setMyRole(!myRole);
                      props.setRoleValue(e.target.value);
                    }}
                  >
                    <option value="deliverableTaskWriters" className={classes.grid_my_role_custom_option}>
                      Writer
                    </option>
                    <option value="deliverableTaskReviewers" className={classes.grid_my_role_custom_option}>
                      Reviewer
                    </option>
                    <option value="deliverableTaskPublishers" className={classes.grid_my_role_custom_option}>
                      Publisher
                    </option>
                    <option value="all" className={classes.grid_my_role_custom_option}>
                      All
                    </option>
                  </select>
                </div>
              ) : null}
              {props.accessPermissionBulk ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm btn-dark" id="taxonomyBulkTagging" onClick={(e) => clickHandler(e)}>
                  <i className="fas fa-tag pr-1"></i>Taxonomy Bulk Tagging
                </button>
              ) : null}
              {showFilterByTaxonomy ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm btn-dark" id="taxonomyFilter" onClick={(e) => clickHandler(e)}>
                  <i className=" pr-1">
                    <IoMdFunnel />
                  </i>
                  Filter by Taxonomy
                </button>
              ) : null}
              {/* {objectiveTaxonomyRight ?
								<button className="btn btn-outline-white active mr-sm-2 btn-sm btn-dark" id="objectiveTaxonomy" onClick={(e) => clickHandler(e)}>
									<i className="fas fa-tag pr-1"></i>Objective Taxonomy
								</button>
								: null} */}
              {showResetFilter ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm  btn-dark " id="clearFilter" onClick={(e) => clickHandler(e)}>
                  <i className=" pr-1">
                    <IoMdFunnel />
                  </i>
                  Reset Filters
                </button>
              ) : null}
              {importPatent ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm btn-dark" id="importPatent" onClick={(e) => clickHandler(e)}>
                  <i className="fa fa-upload pr-1"></i>Import Patent
                </button>
              ) : null}

              {dataUpload ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm btn-dark" id="importPatent" onClick={(e) => clickHandler(e)}>
                  <i className="fa fa-upload pr-1"></i>Data Upload
                </button>
              ) : null}

              {importAccessAudience ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm  btn-dark" id="importAudience" onClick={(e) => clickHandler(e)}>
                  <i className="fa fa-upload pr-1"></i>Import Audience
                </button>
              ) : null}
              {exportAccessAudience ? (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm btn-dark" id="exportAudience" onClick={(e) => clickHandler(e)}>
                  <i className="fas fa-download pr-1"></i>Export Audience
                </button>
              ) : null}

              <button className="btn btn-outline-white active  btn-sm btn btn-dark" id="openColumnChooser" onClick={(e) => clickHandler(e)}>
                <i className="fa fa-columns pr-1"></i>Column Chooser
              </button>
            </div>
          </div>
        </nav>
      </div>
      {slectedId?.length > 0 && (
        <div className="grid-taxonomy-filter">
          <div className="grid-selected-taxonomy">
            <span>Selected Taxonomy :</span>
          </div>

          <div style={{ display: "flex" }}>
            {slectedId.slice(0, 4).map((value) => {
              stringLength = stringLength + value.name?.length;
              // setLength(stringLength)
              return (
                <>
                  {slectedId?.slice(0, 4) && (
                    <ChipListComponent
                      title={value.name}
                      delete={(e) => {
                        handleDeletedTaxonomy(e);
                      }}
                      id="chip-avatar"
                      enableDelete={true}
                      cssClass="custom-chip1"
                    >
                      <ChipsDirective>
                        <ChipDirective
                          text={value?.name?.length > 50 ? value?.name.slice(0, 50) + "..." : value?.name}
                          value={value.id}
                          cssClass="custom-chip"
                        />
                      </ChipsDirective>
                    </ChipListComponent>
                  )}
                </>
              );
            })}
          </div>
          {slectedId?.length > 4 && (
            <div>
              <div className={classes.select_list}>
                <div onClick={handleClose} className={classes.dotSection}>
                  <div className={classes.filterCountPlus}>+{slectedId.length - 4}</div>
                  <div className={classes.dotMainDiv}>
                    <span className={classes.select_dots}></span>
                    <span className={classes.select_dots}></span>
                    <span className={classes.select_dots}></span>
                  </div>
                </div>
                {showRemainingFilters && (
                  <div className={classes.select_list_content} id="select_list">
                    <div className={classes.extraFilters}>
                      {slectedId.slice(4).map((ele, i) => {
                        return (
                          <>
                            {
                              <ChipListComponent
                                title={ele.name}
                                delete={(e) => {
                                  handleDeletedTaxonomy(e);
                                }}
                                id="chip-avatar"
                                enableDelete={true}
                                cssClass="custom-chip1"
                              >
                                <ChipsDirective>
                                  <ChipDirective
                                    text={ele?.name?.length > 40 ? ele?.name.slice(0, 40) + "..." : ele?.name}
                                    value={ele.id}
                                    cssClass="custom-chip"
                                  />
                                </ChipsDirective>
                              </ChipListComponent>
                            }
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div style={{ width: "90px" }}>
            <button
              className="grid-clear-all"
              onClick={() => {
                setReRender(!reRender);
                props.setFilterData([]);
                setSelectedId([]);
                setSelectedNode([]);
                dispatch(resetRedux());
              }}
            >
              Clear All
            </button>
          </div>
        </div>
      )}

      {rowData && (
        <div className={pageSize > 10 ? `e-grid-scrollbar ${internalgrid == true && slectedId?.length > 0 && "grid-filter"}` : ""}>
          <GridComponent
            ref={gridRef}
            // toolbarClick={clickHandler}
            enablePersistence={true}
            dataSource={rowData}
            dataBound={dataBound}
            beforeDataBound={beforeGridDataBound}
            allowTextWrap
            allowPaging
            pageSettings={{
              pageSize,
              currentPage,
            }}
            // frozenRows={freezeRow}
            // frozenColumns={freezeColumn}
            height={"100%"}
            // width={"100%"}
            filterSettings={FILTER_SETTINGS}
            allowSorting={true}
            allowMultiSorting={false}
            allowFiltering={allowFiltering}
            allowSelection
            gridLines="Horizontal"
            selectionSettings={SELECTION_SETTINGS}
            rowDrop={onRowDrop}
            rowSelected={rowSelected}
            rowDeselected={rowDeSelected}
            actionComplete={actionComplete}
            actionBegin={actionBegin}
            showColumnChooser={true}
            queryCellInfo={queryCellInfo}
            allowRowDragAndDrop={allowRowDragAndDrop}
            delayUpdate={true}
          >
            <ColumnsDirective>
              {!props.isReportGrid
                ? columnFieldsToShow?.map((cN, i) => (
                    <ColumnDirective
                      key={"cD" + i}
                      field={cN?.field}
                      headerText={cN?.headerText ? cN?.headerText : cN?.field}
                      defaultValue=""
                      type={cN?.type}
                      filterTemplate={cN?.filterTemplate}
                      textAlign={cN?.textAlign}
                      headerTextAlign={cN?.headerTextAlign}
                      width={cN?.width}
                      visible={cN?.visible}
                      filter={cN?.useRange ? customFilter : { ...cN?.filter }}
                      template={cN?.template}
                      showInColumnChooser={cN?.showInColumnChooser || cN?.showInColumnChooser === undefined ? true : false}
                      allowSorting={cN?.allowSorting || cN?.allowSorting === undefined ? true : false}
                      allowFiltering={cN?.allowFiltering || cN?.allowFiltering === undefined ? true : false}
                      freeze={cN?.freeze}
                      isPrimaryKey={cN?.isPrimaryKey}
                    />
                  ))
                : columnFieldsToShow.map((cN, i) => <ColumnDirective key={"cD" + i} {...cN} />)}
            </ColumnsDirective>

            {/* <ColumnsDirective >
							{Object.keys(columnNames).map((cN, i) =>
								<ColumnDirective key={"cD" + i} field={cN} headerText={cN}
									defaultValue="" />
							)}
						</ColumnsDirective> */}

            <Inject
              services={[
                // Resize, //uncomment if you want to auto size column and resize column
                Resize,
                Freeze,
                Edit,
                Selection,
                Sort,
                Page,
                Filter,
                Toolbar,
                RowDD,
                ColumnChooser,
              ]}
            />
          </GridComponent>
        </div>
      )}

      {rowData && totalCount > 0 && allowPagination ? (
        <div className=" py-2  pr-3 bg-white border-left border-right border-bottom paginate-box ">
          <div className="d-flex fluid-container justify-content-end ">
            <div>
              <label className="pt-1 pr-2">
                <span className="paginate-font"> Rows Per Page :</span>
              </label>
              <DropdownList
                className="custom-dropdownlist"
                style={{ width: "80px", display: "inline-block", fontSize: "14px" }}
                data={[...PAGE_SIZE_RANGE]}
                onChange={(value) => {
                  setCurrentPage(1);
                  setActivePage(1);
                  setFetchNext(true);
                  setSkip(0);
                  if (isChildGrid) {
                    dispatch(setInternalActivePages(1));
                    dispatch(setInternalRecordsPerPage(value));
                    dispatch(setInternalSkip(0));
                    setPageSize(value);
                  } else {
                    dispatch(activePageInfo(1));
                    dispatch(setRecordsPerPage(value));
                    dispatch(skipRecords(0));
                    setPageSize(value);
                  }
                  gridRef.current.clearRowSelection();
                }}
                defaultValue={pageSize}
                dropUp
                // 	disabled={[...PAGE_SIZE_RANGE.filter((pSR) => pSR > limit)]}
              />
              <label className="pt-1 pr-3 pl-3">
                {isChildGrid ? getPageCalculation(totalCount, gridState.internalGridState) : getPageCalculation(totalCount, gridState)}
              </label>
              <Pagination
                onChange={handlePageChange}
                pageRange={
                  isChildGrid ? Math.ceil(totalCount / gridState.internalGridState.recordsPerPage) : Math.ceil(totalCount / gridState.recordsPerPage)
                }
                activePage={isChildGrid ? gridState.internalGridState.activePage : gridState.activePage}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {show && (
        <DataImport
          closeHandler={() => {
            setShow(false);
          }}
          show={show}
        />
      )}
      {showScreening && (
        <DataImportScreening
          closeHandler={() => {
            setShowScreening(false);
          }}
          show={showScreening}
        />
      )}

      {showFilterTaxonomy && (
        <FilterTaxonomy
          handleSubmit={(val, data) => {
            setSelectedId([]);
            setSelectedNode(val);
            props.setFilterData(data);
            // dispatch(setMaintainCheckedNodes(val))
            dispatch(setFilteredTaxonomy(data));
            // // setSelectedId(filterCommonState.checkedNodes && filterCommonState.checkedNodes.length ? filterCommonState.checkedNodes : val)
            setSelectedId(val);
            setShowFilterTaxonomy(false);
          }}
          showFilterByMedia={props?.showFilterByMedia}
          moduleName={props?.moduleName}
          closeHandler={() => {
            setShowFilterTaxonomy(false);
          }}
          setFilterData={props.setFilterData}
          checked={props.filterData}
          selectedId={slectedId}
          show={showFilterTaxonomy}
          boTagging={props.boTagging}
          customDialogBoxClass={props.customDialogBoxClass}
          customArrowClass={props.customArrowClass}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          cssClass={props.cssClass}
          setCssClass={props.setCssClass}
          tabName={props.tabName}
          setSelectedId={setSelectedId}
          customSearch={isChildGrid == true ? props.customSearch : "customSearch"}
          internalGridCss={props?.internalGridCss ? props.internalGridCss : "select-lists"}
        />
      )}
      {props.showTaxonomyBulk && (
        <FilterTaxonomy
          handleSubmit={(selectedIndex) => {
            props.handleSubmit(selectedIndex, fetchGridData);
          }}
          moduleName={props?.moduleName}
          closeHandler={() => {
            props.setShowTaxonomyBulk(false);
          }}
          selectedId={slectedId}
          show={showTaxonomyBulk}
          boTagging={props.boTagging}
          customDialogBoxClass={props.customDialogBoxClass}
          customArrowClass={props.bulkTaggingArrowTop}
          checked={props.checked}
          enableBulkTaxonomy={enableBulkTaxonomy}
          tabName={props.tabName}
          cssClass={props.cssClass}
          listClass={props.listClass}
          fetchTaxonomyTagging={props.fetchTaxonomyTagging}
          handleTaxonomyTagging={props.handleTaxonomyTagging}
          setBulkCheckedNode={props.setBulkCheckedNode}
          resetAllTaxonnomy={props.resetAllTaxonnomy}
          valueChainData={props.valueChainData}
          customSearch={props?.customSearch ? props.customSearch : "customSearch"}
        />
      )}
    </>
  );
};

CommonDataGrid.propTypes = {
  fetch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  deleteOne: PropTypes.func,
  deleteMany: PropTypes.func,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  columnNames: PropTypes.object.isRequired,
  columnFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  formatRowData: PropTypes.func.isRequired,
  getMultiSelectedRows: PropTypes.func,
  getMultiDeSelectedRows: PropTypes.func,
  addNew: PropTypes.func,
  publishMany: PropTypes.func,
};

CommonDataGrid.defaultProps = {
  fetch: () => {},
  isFetching: false,
  deleteOne: () => {},
  deleteMany: () => {},
  showLoader: () => {},
  hideLoader: () => {},
  columnNames: {},
  columnFields: [{}],
  formatRowData: () => {},
  getMultiSelectedRows: () => {},
  getMultiDeSelectedRows: () => {},
};

export default CommonDataGrid;
