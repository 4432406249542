/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Qc rejected remark allow adding remark for Reject and RejectAllocate 
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 25/Jun/2020 
    Author						: Anand Kumar
================================================================ 
*/

import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { TextArea } from "./rejectFormField";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialState = {
  rejectRemark: "",
};

const RemarkModal = (props) => {
  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    validationSchema: Yup.object().shape({
      rejectRemark: Yup.string().required("Please enter remark"),
    }),
  });
  const handleSave = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        props.clicked(formik.values);
        formik.handleReset();
      }
    });
  };
  return (
    <Modal show={props.showModal} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <div className="modal-header bg-secondary text-white">
        <h5 className="modal-title" id="exampleModalLabel">
          Add Reject Remark
        </h5>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => {
            props.clicked();
            formik.handleReset();
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group required">
              <label htmlFor="address1">Enter Reason for Reject</label>
              <TextArea formik={formik} type="text" name="rejectRemark" className="form-control" id="rejectRemark" placeholder="Enter reason" />
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-dark cy-btn"
          onClick={() => {
            props.clicked();
            formik.handleReset();
          }}
        >
          Cancel
        </button>
        <button type="button" className="btn btn-primary cy-btn" onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default RemarkModal;
RemarkModal.propTypes = {
  companyId: PropTypes.string.isRequired,
};
