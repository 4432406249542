/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  People Overview component - Add | Edit Overview
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 14/Apr/2021
    Author						: Sandeep K Sharma
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { saveOverview, fetchSinglePeople, updateOverview, fetchPeopleImageUrl } from "../../../../../middleware/services/peopleApi";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import OverviewForm from "./form";
import { getProductVariant } from "../../../../../utilities";

const PeopleOverview = (props) => {
  const peopleId = props.peopleId;
  const dispatch = useDispatch();
  const history = useHistory();

  /*
        @Description : Default value for people overview form
    */
  const [peopleInformation, setPeopleInformation] = useState({
    peopleFirstName: "",
    peopleLastName: "",
    peopleContactEmail: "",
    peopleContactMobileNo: "",
    peopleContactTelNo: "",
    peopleSocialTwitter: "",
    peopleSocialLinkedIn: "",
    peopleSocialFacebook: "",
    peopleAddressLine: "",
    peopleAreaName: "",
    peopleCity: "",
    peopleState: "",
    peopleRegion: "",
    peopleLocationType: "",
  });
  const [peopleImage, setPeopleImageSrc] = useState(undefined);
  const [stepCompleted, setStepCompelted] = useState(false);

  /*
        @Description : Fetch people data on edit
        1. set people data to respective fields
        2. Set image path for sending to child component
    */
  useEffect(() => {
    if (peopleId !== "") {
      setStepCompelted(true);
      dispatch(showLoader());
      try {
        const response = fetchSinglePeople(peopleId);
        response
          .then((response) => {
            dispatch(hideLoader());
            if (response.status == 200) {
              let peopleData = {
                peopleFirstName: response.data.data.peopleFirstName,
                peopleLastName: response.data.data.peopleLastName,
                peopleContactEmail: response.data.data.peopleContactEmail,
                peopleContactMobileNo: response.data.data.peopleContactMobileNo ? response.data.data.peopleContactMobileNo : "",
                peopleContactTelNo: response.data.data.peopleContactTelNo ? response.data.data.peopleContactTelNo : "",
                peopleSocialTwitter: response.data.data.peopleSocial?.twitter ? response.data.data.peopleSocial.twitter : "",
                peopleSocialLinkedIn: response.data.data.peopleSocial?.linkedin ? response.data.data.peopleSocial.linkedin : "",
                peopleSocialFacebook: response.data.data.peopleSocial?.facebook ? response.data.data.peopleSocial.facebook : "",
                peopleAddressLine: response.data.data.peopleContactAddress?.peopleAddressLine
                  ? response.data.data.peopleContactAddress.peopleAddressLine
                  : "",
                peopleAreaName: response.data.data.peopleContactAddress?.peopleAreaName ? response.data.data.peopleContactAddress.peopleAreaName : "",
                peopleCountry: response.data.data.peopleContactAddress?.peopleCountry ? response.data.data.peopleContactAddress.peopleCountry : "",
                peopleCity: response.data.data.peopleContactAddress?.peopleCity ? response.data.data.peopleContactAddress.peopleCity : "",
                peopleState: response.data.data.peopleContactAddress?.peopleState ? response.data.data.peopleContactAddress.peopleState : "",
                peopleRegion: response.data.data.peopleContactAddress?.peopleRegion ? response.data.data.peopleContactAddress.peopleRegion : "",
                peopleLocationType: response.data.data.peopleContactAddress?.peopleLocationType
                  ? response.data.data.peopleContactAddress.peopleLocationType
                  : "",
              };
              if (response.data.data.peopleProfileImage && response.data.data.peopleProfileImage !== "") {
                setPeopleImageSrc(fetchPeopleImageUrl(response.data.data.peopleProfileImage));
              }
              setPeopleInformation(peopleData);
            } else {
              dispatch(actionError("Something Went Wrong...!"));
            }
          })
          .catch((e) => {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
          });
      } catch (e) {
        dispatch(hideLoader());
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  }, []);
  /*
       @Description : Save overview details
   */
  const handleSaveOverview = async (saveOverviewData) => {
    dispatch(showLoader());
    try {
      let response = "";
      if (peopleId == "") {
        const productVariant = getProductVariant();
        if (productVariant) saveOverviewData.append("productVariant", JSON.stringify([{ productName: productVariant, isLive: "NO" }]));
        response = saveOverview(saveOverviewData);
      } else {
        response = updateOverview(peopleId, saveOverviewData);
      }
      response
        .then((response) => {
          dispatch(hideLoader());
          if (response.status === 200) {
            if (peopleId == "") {
              dispatch(actionSuccess("Basic Details Saved Successfully"));
              window.location = `/people/${response.data.data.id}/edit`;
            } else {
              dispatch(actionSuccess("Basic Details Updated Successfully"));
              history.push(`/people/${peopleId}/edit`);
            }
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e.data.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  return (
    <OverviewForm
      peopleInformation={peopleInformation}
      handleSaveOverview={handleSaveOverview}
      peopleImage={peopleImage}
      stepCompleted={stepCompleted}
      {...props}
    />
  );
};

export default PeopleOverview;
