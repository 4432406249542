/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : sgf List Saga (Redux Middleware)
----------------------------------------------------------------
	Creation Details
	Date Created				: 29/Nov/2023
	Author						  : Lawrence Anthony
================================================================
*/

import { takeLatest, call, put, all } from "redux-saga/effects";
import { toggleLoading, setSgfIdsSearch, getSectorsSearch } from "../../middleware/actions/searchAction"; // Import your Redux actions here
import { getSgfData } from "../../middleware/services/searchServiceApi"; // Import your API function here
import { getObjectiveSgfTag, fetchTaxonomyTrend, fetchConfig } from "../../middleware/services/cmsApi";

// Function to recursively modify label property in nested arrays
function modifyLabels(obj) {
  if (obj.children) {
    obj.children = obj.children.map((child) => ({
      ...child,
      label: `${child.label} (0)`,
    }));
    obj.children.forEach(modifyLabels);
  }
}

function* getSgfTypeSearchSaga(action) {
  try {
    // yield put(toggleLoading(true));

    const finalList = [];
    const apiCalls = [];
    const trends = [];
    const sgfIds = [];
    let userSubscriptions = [];
    let industrySequence = ["Food & Nutrition", "Energy", "Mobility", "Personal Care", "Industrial"];

    let industrySequenceV2 = yield call(fetchConfig, {
      fields: ["configValue"],
      filters: [["config_name", "eq", "insiderv2-filter-sequence"]],
    });

    if (industrySequenceV2.data.data && industrySequenceV2.data.data[0]?.configValue) industrySequence = industrySequenceV2.data.data[0]?.configValue;

    let allIndustries = yield call(fetchTaxonomyTrend, {
      fields: ["id", "trendName"],
      filters: [["trendParentId", "eq", 0]],
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
      sort: "trendName:asc",
    });

    let productIndustryIds = allIndustries.data.data.map((item) => item.id);

    let allTrends = yield call(getObjectiveSgfTag, {
      filters: [["sector.sectorId", "in", productIndustryIds]],
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
      sort: "trendName:asc",
    });

    let allTrendsArray = allTrends.data.data;

    // Iterate through each object in allTrendsArray
    allTrendsArray.forEach((trendObject) => {
      // Check if the trendObject has the sector property
      if (trendObject.sector && trendObject.sector.length > 0) {
        // Get the sectorName from the sector array
        const sectorName = trendObject.sector[0].sectorName;
        const sgfName = trendObject.sgfName;
        const sgfId = trendObject.id;

        // Check if the userSubscription already exists for the sector
        let userSubscription = userSubscriptions.find((subscription) => subscription.subscriptionProductType === sectorName);

        // If userSubscription does not exist, create a new one
        if (!userSubscription) {
          userSubscription = {
            subscriptionProductType: sectorName,
            subscriptionProduct: [], // Initialize an empty array for subscriptionProduct
          };

          // Push the new userSubscription to the userSubscriptions array
          userSubscriptions.push(userSubscription);
        }

        // Iterate through each trend in the trend array
        if (trendObject && trendObject.trend) {
          trendObject.trend.forEach((trend) => {
            // Create the product object with specified mappings
            const productObject = {
              productId: trend.trendId,
              productName: trend.trendName,
              productIndustryId: trendObject.sector[0].sectorId,
              productIndustry: sectorName,
              productSgfId: sgfId,
              productSgfName: sgfName,
            };

            // Add the product object to the subscriptionProduct array
            userSubscription.subscriptionProduct.push(productObject);
          });
        }
      }
    });

    yield all(
      userSubscriptions.map(async (ele) => {
        sgfIds.push(ele.subscriptionProduct.map((res) => res.productSgfId));
        const products = ele.subscriptionProduct.map((res) => (res.productSgfId ? res.productSgfId : res.productId));
        trends.push(ele.subscriptionProduct.map((res) => res.productId));
        const productIndustryId = [...new Set(ele.subscriptionProduct.map((res) => res.productIndustryId))];
        finalList.push({
          id: productIndustryId[0],
          value: ">" + productIndustryId[0] + ">" + ele.subscriptionProductType + ">industry",
          label: ele.subscriptionProductType,
        });
        apiCalls.push({
          fn: getSgfData,
          args: [products], // Pass arguments as an array
        });
      })
    );

    // yield put(
    //   setSgfIdsSearch({
    //     sgfIdsSearch: [...new Set(sgfIds.flat().filter((e) => e))],
    //   })
    // );

    const results = yield all(apiCalls.map(({ fn, args }) => call(fn, ...args))); // Destructure the apiCall

    results.forEach((list, index) => {
      const arr = list.data.data.map((ele) => {
        if (ele.trend) {
          const filteredChildren = ele.trend
            // .filter(
            //   (res) =>
            //     (res.trendIsHidden === "NO" || res.trendIsHidden === undefined) && (res.trendIsDisabled === "YES" || trends.includes(res.trendId))
            // )
            .map((item) => ({
              id: item.trendId,
              value: ele.id + ">" + item.trendId + ">" + item.trendName + ">trend",
              label: item.trendDisplayName ? item.trendDisplayName : item.trendName,
              disabled: item.trendIsDisabled === "YES" ? true : false,
              children: null,
            }));
          return {
            id: ele.id,
            value: ">" + ele.id + ">" + ele.sgfName + ">sgf",
            label: ele.sgfName,
            disabled: ele.sgfIsDisabled === "YES" ? true : false,
            children: filteredChildren.length ? filteredChildren : null,
          };
        }
        return {
          id: ele.id,
          value: ">" + ele.id + ">" + ele.sgfName + ">sgf",
          label: ele.sgfName,
        };
      });

      finalList[index].children = arr;
    });

    // Loop through the array and modify the label property
    const modifiedList = finalList.map((obj) => ({
      ...obj,
      label: `${obj.label} (0)`,
      industry: obj.label,
    }));

    modifiedList.forEach((obj) => modifyLabels(obj));

    let modifiedListAsPerIndustrySequence = modifiedList.sort((a, b) => {
      const indexA = industrySequence.indexOf(a.industry);
      const indexB = industrySequence.indexOf(b.industry);
      return indexA - indexB;
    });

    yield put(
      getSectorsSearch({
        sgfListSearch: modifiedListAsPerIndustrySequence,
      })
    );

    // yield put(toggleLoading(false));
  } catch (error) {
    // Handle errors if needed
    console.error("error>>>", error);
    // yield put(toggleLoading(false));
  }
}

function* watchGetSgfTypeSearch() {
  yield takeLatest("GET_SGF_TYPE_SEARCH", getSgfTypeSearchSaga);
}

export default watchGetSgfTypeSearch;
