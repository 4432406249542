import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import React from "react";
import HelpdeskListing from "../app/views/helpdesk";
import Add from "../app/views/helpdesk/add";
const BASIC = ["Technical Help:VIEW", "Technical Help:FULL"];

const helpdeskRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/helpdesk-settings"
      component={HelpdeskListing}
      pageHeading="GenNx CMS - Technical Help"
      accessRights={{
        moduleName: "Technical Help",
        parentModuleName: "",
        moduleAttribute: [...BASIC],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/helpdesk-settings/add"
      component={Add}
      pageHeading="GenNx CMS - Technical Help"
      accessRights={{
        moduleName: "Technical Help",
        parentModuleName: "",
        moduleAttribute: [...BASIC],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/helpdesk-settings/:categoryId/edit"
      component={Add}
      pageHeading="GenNx CMS - Technical Help"
      accessRights={{
        moduleName: "Technical Help",
        parentModuleName: "",
        moduleAttribute: [...BASIC],
        interfaceName: "Basic",
      }}
    />
  </Switch>
  // </Suspense>
);

export default helpdeskRoutes;
