/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Authorization API (fetch & save)
----------------------------------------------------------------
	Creation Details
	Date Created				: 03/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { AUTHORIZATION_API as API } from "../../config/apiConfig";

export const fetchAuthorization = (userId) => {
	return API({
		url: `/authorization/${userId}`,
		method: "GET"
	});
};

export const saveAuthorization = (userId, payload) => {
	return API({
		url: `/authorization/${userId}`,
		method: "POST",
		data: payload
	});
};
