/*
================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Taxonomy Filter State
----------------------------------------------------------------
    Creation Details
    Date Created				: 19/Aug/2022
    Author						: Suraj Yadav
================================================================
*/
const TAXONOMY_FILTER_STATE = {
    selectedTechnology: [],
    selectedKia: [],
    selectedValueChain: [],
    selectedBOTaxonomy: [],
    selectedMediaTaxonomy: [],
    selectedTrendTaxonomy: [],
    checkedNodes: [],
    parentNodeName: [],
    taxonomyNodes: [],
    filteredTaxonomy: []
};

export default (state = TAXONOMY_FILTER_STATE, action) => {
    switch (action.type) {

        // Maintain selected taxonomy from by technology tab 
        case 'MAINTAIN_SELECTED_TECHNOLOGY': {
            let selectedNodes = [...state.selectedTechnology, ...action.payload]
            let selectedNodesArray = selectedNodes.filter((c, index) => { return selectedNodes.indexOf(c) === index; });
            return { ...state, selectedTechnology: selectedNodesArray };
        }

        // Maintain selected taxonomy from by Key Impact Area tab 
        case 'MAINTAIN_SELECTED_KIA': {
            let selectedNodes = [...state.selectedKia, ...action.payload]
            let selectedNodesArray = selectedNodes.filter((c, index) => { return selectedNodes.indexOf(c) === index; });
            return { ...state, selectedKia: selectedNodesArray };
        }

        // Maintain selected taxonomy from by value chain tab 
        case 'MAINTAIN_SELECTED_VALUE_CHAIN': {
            let selectedNodes = [...state.selectedValueChain, ...action.payload]
            let selectedNodesArray = selectedNodes.filter((c, index) => { return selectedNodes.indexOf(c) === index; });
            return { ...state, selectedValueChain: selectedNodesArray };
        }
        // Maintain selected taxonomy from by value chain tab 
        case 'MAINTAIN_SELECTED_MEDIA_TAXONOMY': {
            let selectedNodes = [...state.selectedMediaTaxonomy, ...action.payload]
            let selectedNodesArray = selectedNodes.filter((c, index) => { return selectedNodes.indexOf(c) === index; });
            return { ...state, selectedMediaTaxonomy: selectedNodesArray };
        }
        // Maintain selected taxonomy from by value chain tab 
        case 'MAINTAIN_SELECTED_BUSINESS_OBJECTIVE': {
            let selectedNodes = [...state.selectedBOTaxonomy, ...action.payload]
            let selectedNodesArray = selectedNodes.filter((c, index) => { return selectedNodes.indexOf(c) === index; });
            return { ...state, selectedBOTaxonomy: selectedNodesArray };
        }
        // Maintain selected taxonomy from by value chain tab 
        case 'MAINTAIN_SELECTED_TREND': {
            let selectedNodes = [...state.selectedTrendTaxonomy, ...action.payload]
            let selectedNodesArray = selectedNodes.filter((c, index) => { return selectedNodes.indexOf(c) === index; });
            return { ...state, selectedTrendTaxonomy: selectedNodesArray };
        }
        // Maintain checked nodes 
        case 'MAINTAIN_CHECKED_NODES': {
            let selectedNodes = [...state.checkedNodes, ...action.payload]
            let selectedNodesArray = selectedNodes.filter((c, index) => { return selectedNodes.indexOf(c) === index; });
            return { ...state, checkedNodes: selectedNodesArray };
            // return { ...state, checkedNodes: action.payload };
        }

        // Maientain checked parent node 
        case 'MAINTAIN_CHECKED_PARENT_NODES': {
            return { ...state, parentNodeName: action.payload };
        }
        // Maientain checked taxonomy node 
        case 'MAINTAIN_CHECKED_TAXONOMY_NODES': {
            return { ...state, taxonomyNodes: action.payload };
        }
        // Maientain filtered taxonomy 
        case 'MAINTAIN_FILTERED_TAXONOMY': {
            return { ...state, filteredTaxonomy: action.payload };
        }
        //Reset redux
        case 'RESET_REDUX': {
            return {
                selectedTechnology: [],
                selectedKia: [],
                selectedValueChain: [],
                selectedBOTaxonomy: [],
                selectedMediaTaxonomy: [],
                checkedNodes: [],
                parentNodeName: [],
                selectedTrendTaxonomy: [],
                taxonomyNodes: [],
                filteredTaxonomy: []

            }
        }

        default:
            return state;
    }
};

