import React, { useState } from "react";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Modal } from "react-bootstrap";

const ViewBTBModal = (props) => {
  const oldData = props.btbs;
  const [btbs, setBtbs] = useState(props.btbs);
  const filterList = (e) => {
    const updatedList = btbs.filter((item) => {
      return item["btbId"].search(e.target.value) !== -1;
    });
    e.target.value.length ? setBtbs(updatedList) : setBtbs(oldData);
  };

  return (
    <Modal
      show={props.show}
      backdrop="static"
      className="modal fade xl"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <div className="modal-header bg-secondary text-white">
        <h5 className="modal-title" id="exampleModalLabel">
          Select Specific BTB
        </h5>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => {
            props.closeHandler();
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="mt-2 ml-4 mr-4 border-bottom border-secondary">
        <div className="form-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <input className="form-control col-md-6" type="text" placeholder="Search by BTB Ids..." onChange={filterList} />
        </div>
      </div>
      <Modal.Body>
        {btbs && btbs.length > 0 && (
          <div className="table-responsive pt-2">
            <table className="normal-table table table-bordered" style={{ fontSize: ".8rem" }}>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th style={{ width: "70px" }}>BTB ID</th>
                  <th>Group Name</th>
                  <th>Company Name</th>
                  <th>Project Name</th>
                  <th>Primary Contact</th>

                  <th>Work Type</th>
                </tr>
              </thead>
              <tbody>
                {btbs &&
                  btbs.map((v, i) => {
                    return (
                      <tr
                        key={`select-btb-${i}`} // style={{backgroundColor:(i+1)%2!==0?"#fff6f7":""}}
                      >
                        <td className="overflow">
                          <div className="pr-3 pb-3" style={{ marginTop: "-10px" }}>
                            <RadioButtonComponent
                              id={`select-btb-${i}`}
                              name="btb-id"
                              onClick={(e) => {
                                props.setBTBId(e.target.value);
                                props.closeHandler();
                              }}
                              value={v.btbId}
                            />
                          </div>
                        </td>
                        <td>{v.btbId}</td>
                        <td>{v.groupName}</td>
                        <td>{v.companyName}</td>
                        <td>{v.projectName}</td>
                        <td>{v.primaryContact}</td>
                        <td>{v.workType}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
        {btbs && btbs.length === 0 && (
          <>
            <h6 className="pl-2">No data found for searched BTB Id</h6>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-dark cy-btn mr-3"
          onClick={() => {
            props.closeHandler();
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary cy-btn"
          onClick={() => {
            props.closeHandler();
          }}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default ViewBTBModal;
