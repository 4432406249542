/**
 * @author: Anand Kumar
 * @date : 08-Aug-2020
 * File Description: Company Validation Schema
 */
import * as Yup from "yup";
import * as REGEX from "../../../config/regexValidation";
import { COMPANY_MAX_IMG_SIZE } from "../../../config";

export const addressValidationSchema = {
  address1: Yup.string().required("Please enter address"),
  countryName: Yup.string().required("Please select country name").typeError("Please select country name"),
  type: Yup.string().required("Please select address type"),
};

export const fundingValidationSchema = {
  fundingName: Yup.string().required("Please enter transaction name"),
  fundingAmount: Yup.lazy((val) =>
    val === "NA"
      ? Yup.string().required("Enter NA")
      : Yup.number().typeError("Funding amount should be NA or number").required("Please enter funding amount")
  ),

  fundingTypeRound: Yup.string().required("Please select funding type"),
  fundingDate: Yup.date().typeError("Please select transaction date").nullable().default(null).required("Please select transaction date"),
  fundingInvestors: Yup.array().required("Please select funding investors"),
};

export const revenueValidationSchema = {
  revenueYear: Yup.string().required("Please enter revenue year").matches(REGEX.YEAR, "Please enter valid 4 digit year"),
  revenueAmount: Yup.lazy((val) =>
    val === "NA"
      ? Yup.string().required("Enter NA")
      : Yup.number().typeError("Revenue amount should be NA or number").required("Please enter revenue amount")
  ),
  // revenueCurrency: Yup.string().required("Please select revenue currency"),
  revenueSourceUrl: Yup.string().required("Please enter valid url").matches(REGEX.URL, "Please enter valid URL"),
};

export const investmentValidationSchema = {
  fundingName: Yup.string().required("Please enter transaction name"),
  fundingAmount: Yup.lazy((val) =>
    val === "NA"
      ? Yup.string().required("Enter NA")
      : Yup.number().typeError("Funding amount should be NA or number").required("Please enter funding amount")
  ),
  fundingTypeRound: Yup.string().required("Please select funding type"),
  fundingDate: Yup.date().typeError("Please select transaction date").nullable().default(null).required("Please select transaction date"),
  companyInvestment: Yup.string().required("Please select funding company").nullable(),
};

export const advancedValidationSchema = {
  companyAbout: Yup.string().required("Please enter about the company"),
  companyWhatsUnique: Yup.string().max(200, "Please enter whats unique of maximum 200 characters"),
};

// company Video Validation
export const videoValidationSchema = {
  videoTitle: Yup.string().min(2).required("Please enter video title"),
  videoDescription: Yup.string(),
  videoSource: Yup.string().typeError("Please select video source").required("Please select video source"),
  videoId: Yup.string().min(5).required("Please enter video id"),
  videoThumbnail: Yup.mixed().required("Please upload video thumbnail"),
  videoDurationInMinute: Yup.number().required("Please enter video duration").min(1, "Video duration must be greater than 0"),
};
// company Image Validation
export const imageValidationSchema = {
  imageFile: Yup.mixed()
    .required("Please upload image")
    .test("imageFile", "File size is more than 5 MB", (value) => {
      return !value || (value && value[0].size <= COMPANY_MAX_IMG_SIZE);
    }),
};
// Company upload Excel Doc Validation
export const uploadDocValidationSchema = {
  uploadFor: Yup.string().required("Please select upload for"),
  selIndustry: Yup.string().required("Please select industry"),
  documentFile: Yup.mixed().required("Please upload document"),
};
// Screening rejection Company upload Excel Doc Validation
export const screeningUploadDocValidationSchema = {
  documentFile: Yup.mixed().required("Please upload document"),
};
// Company Document Brochure Validation
export const addDocumentValidationSchema = {
  documentTitle: Yup.string().required("Please enter document name"),
  documentDescription: Yup.string().min(10, "Description must be at least 10 characters").required("Please enter document description"),
  documentFile: Yup.mixed().required("Please upload document"),
};
// Company Edit Document Brochure Validation
export const editDocumentValidationSchema = {
  documentTitle: Yup.string().required("Please enter document name"),
  documentDescription: Yup.string().min(10, "Description must be at least 10 characters").required("Please enter document description"),
};
