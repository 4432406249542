/** External Dependencies */
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
`;

const StyledSpinnerWrapper = styled.div`
  background: #ffffff88;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 11111;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  user-select: none;

  label {
    color: #ffffff;
  }
`;

// const StyledSpinner = styled.div`
//   display: inline-block;
//   position: relative;
//   width: 80px;
//   height: 80px;

//   :before {
//     content: ' ';
//     display: block;
//     border-radius: 50%;
//     width: 0;
//     height: 0;
//     margin: 8px;
//     box-sizing: border-box;
//     border: 32px solid #fff;
//     border-color: #fff transparent #fff transparent;
//     animation: ${spin} 1.2s infinite;
//   }
// `;

const StyledSpinner = styled.div`
  border: 4px solid #f94f5e;
  border-top: 4px transparent solid;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { StyledSpinnerWrapper, StyledSpinner };
