// /*
// ================================================================
// 	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
// 	  File Description : Common Side Nav Component
// ----------------------------------------------------------------
// 	Creation Details
// 	Date Created				: 25/Jun/2020
// 	Author						: YOGESH N. GUPTA
// ================================================================
// */

import "./index.scss";
import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarActive } from "../../../middleware/actions/sideNavAction";
import { getAuthorization, getProductVariant } from "../../../utilities";
import { GiHamburgerMenu } from "react-icons/gi";
import { MenuComponent, SidebarComponent } from "@syncfusion/ej2-react-navigations";
import { resetMaintainedState, routeChanged, routeUnchanged } from "../../../middleware/actions/gridAction";
import handshake from "./images/handshake.svg";
import serviceicon from "./images/service-icon.svg";
import settingicon from "./images/setting-icon.svg";
import filechartcolumn from "./images/file-chart-column-icon.svg";
import filecontent from "./images/file-content-icon.svg";
import building from "./images/building-icon.svg";
import usergroup from "./images/user-group-icon.svg";
import { actionError } from "middleware/actions/utilityAction";
import {
  NOTIFICATION_MANAGEMENT_URL,
  SUBSCRIPTION_MANAGEMENT_URL,
  PLATFORM_PERSONALIZATION_URL,
  CUSTOM_PROJECTS_URL,
  TAXONOMY_MANAGEMENT_URL,
} from "../../../constants";

const SidebarUI = (props) => {
  const sideBarRef = useRef();
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  let data = [];
  const active = useSelector((state) => state.sideNavState.active);
  const productVariant = getProductVariant();
  const buttonStyle = () => ({
    backgroundColor: hover ? "black" : "black",
    marginLeft: "25px",
    paddingRight: "25px",
    minWidth: "200px",
    marginRight: "15px",
    fontWeight: "normal",
    paddingTop: "12px",
  });

  const hideSeparatorList = ["Client Activity Report", "CMS Activity Report"];

  const handleNotificationRedirect = () => {
    const variant = getProductVariant();
    const enterpriseToken = localStorage.getItem("enterprise");
    const userId = localStorage.getItem("enterpriseUserId");
    const refreshToken = localStorage.getItem("refresh-token");
    const clientName = import.meta.env.VITE_ENTERPRISE_CLIENT_NAME;
    if (!variant) return history.push("/selector");
    if (!userId || !enterpriseToken) return history.push("/login");
    if (enterpriseToken === "undefined" || !enterpriseToken || typeof enterpriseToken !== "string")
      return dispatch(actionError("Unable to access Notification"));

    window.open(
      NOTIFICATION_MANAGEMENT_URL +
        "/autologin?variant=" +
        variant +
        "&loginUserId=" +
        userId +
        "&clientName=" +
        clientName +
        "&token=" +
        enterpriseToken +
        "&refreshToken=" +
        refreshToken
    );
  };

  const handleSubscriptionRedirect = () => {
    const enterpriseToken = localStorage.getItem("enterprise");
    const userId = localStorage.getItem("enterpriseUserId");
    const refreshToken = localStorage.getItem("refresh-token");
    const clientName = import.meta.env.VITE_ENTERPRISE_CLIENT_NAME;
    if (!userId || !enterpriseToken) return history.push("/login");
    if (enterpriseToken === "undefined" || !enterpriseToken || typeof enterpriseToken !== "string")
      return dispatch(actionError("Unable to access Subscription"));

    window.open(
      SUBSCRIPTION_MANAGEMENT_URL +
        "/auto-login?loginUserId=" +
        userId +
        "&clientName=" +
        clientName +
        "&token=" +
        enterpriseToken +
        "&refreshToken=" +
        refreshToken
    );
  };
  const handlePersonalizationRedirect = () => {
    const enterpriseToken = localStorage.getItem("enterprise");
    const userId = localStorage.getItem("enterpriseUserId");
    const refreshToken = localStorage.getItem("refresh-token");
    const clientName = import.meta.env.VITE_ENTERPRISE_CLIENT_NAME;
    if (!userId || !enterpriseToken) return history.push("/login");
    if (enterpriseToken === "undefined" || !enterpriseToken || typeof enterpriseToken !== "string")
      return dispatch(actionError("Unable to access platform personalization."));

    window.open(
      PLATFORM_PERSONALIZATION_URL +
        "/auto-login?loginUserId=" +
        userId +
        "&clientName=" +
        clientName +
        "&token=" +
        enterpriseToken +
        "&refreshToken=" +
        refreshToken
    );
  };
  const handleCustomProjectsRedirect = () => {
    const variant = getProductVariant();
    const enterpriseToken = localStorage.getItem("enterprise");
    const userId = localStorage.getItem("enterpriseUserId");
    const refreshToken = localStorage.getItem("refresh-token");
    const clientName = import.meta.env.VITE_ENTERPRISE_CLIENT_NAME;
    if (!variant) return history.push("/selector");
    if (!userId || !enterpriseToken) return history.push("/login");
    if (enterpriseToken === "undefined" || !enterpriseToken || typeof enterpriseToken !== "string")
      return dispatch(actionError("Unable to access custom projects."));

    window.open(
      CUSTOM_PROJECTS_URL +
        "/auto-login?productVariant=" +
        variant +
        "&loginUserId=" +
        userId +
        "&clientName=" +
        clientName +
        "&token=" +
        enterpriseToken +
        "&refreshToken=" +
        refreshToken
    );
  };
  const handleTaxonomyManagerRedirect = () => {
    const enterpriseToken = localStorage.getItem("enterprise");
    const userId = localStorage.getItem("enterpriseUserId");
    const refreshToken = localStorage.getItem("refresh-token");
    const clientName = import.meta.env.VITE_ENTERPRISE_CLIENT_NAME;
    if (!userId || !enterpriseToken) return history.push("/login");
    if (enterpriseToken === "undefined" || !enterpriseToken || typeof enterpriseToken !== "string")
      return dispatch(actionError("Unable to access enterprise taxonomy manager."));

    window.open(
      TAXONOMY_MANAGEMENT_URL +
        "/auto-login?loginUserId=" +
        userId +
        "&clientName=" +
        clientName +
        "&token=" +
        enterpriseToken +
        "&refreshToken=" +
        refreshToken
    );
  };

  const fields = {
    text: ["sectionName", "menuName"],
    children: ["menuChildren"],
  };

  const menuData = getAuthorization();
  let menudata = menuData.dynamicMenu.map((menu) => {
    if (menu.icon === "building") {
      menu.icon = building;
    }
    if (menu.icon === "filecontent") {
      menu.icon = filecontent;
    }
    if (menu.icon === "serviceicon") {
      menu.icon = serviceicon;
    }
    if (menu.icon === "usergroup") {
      menu.icon = usergroup;
    }
    if (menu.icon === "settingicon") {
      menu.icon = settingicon;
    }
    if (menu.icon === "handshake") {
      menu.icon = handshake;
    }
    if (menu.icon === "filechartcolumn") {
      menu.icon = filechartcolumn;
    }

    return menu;
  });
  data = menudata;

  const openClick = () => {
    dispatch(setSidebarActive(!active));
    dispatch(routeUnchanged());
  };
  const onCreate = () => {
    sideBarRef.current.hide();
    dispatch(setSidebarActive(false));
  };
  const onClose = () => {
    dispatch(setSidebarActive(false));
  };
  const menuTemplate = (data) => {
    if (data.menuLevel === 0) {
      return (
        <div>
          <img className={`menu_icon`} src={data.icon}></img>
          <span className="menu_list">{data.sectionName}</span>
        </div>
      );
    }
    if (data.menuLevel === 1) {
      return (
        <>
          {data.subMenu && (
            <div className="menu-div">
              {!active && (
                <div style={{ marginLeft: "10px", lineHeight: "46px", marginRight: "15px" }}>
                  <span>{data.menuName}</span>
                  <hr style={{ maxWidth: "668px", minWidth: "173px" }} />
                </div>
              )}
              <div className="row">
                {data.subMenu &&
                  data.subMenu
                    .filter((m) => (productVariant !== "Insider" ? m.menuName !== "Custom Projects" && m.menuName !== "Personalized Views" : m))
                    .map((item) => {
                      return (
                        <div key={item.menuName}>
                          {item.subMenu?.length > 0 ? (
                            <div style={buttonStyle(hover)} onPointerOver={() => setHover(true)} onPointerOut={() => setHover(false)}>
                              {" "}
                              <span>
                                {" "}
                                {item.menuName} <hr className="hr-style" />{" "}
                              </span>
                            </div>
                          ) : (
                            <div className="single-menu">
                              <Link
                                to={item.menuLink}
                                // to={`${
                                //   item.menuName === "Notification" ||
                                //   item.menuName === "Subscriptions" ||
                                //   item.menuName === "Personalized Views" ||
                                //   item.menuName === "Custom Projects" ||
                                //   item.menuName === "Enterprise Taxonomy"
                                //     ? "#"
                                //     : item.menuLink
                                // }`}
                                onClick={(e) => {
                                  if (item.menuName === "Notification") {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    return handleNotificationRedirect();
                                  }
                                  if (item.menuName === "Subscriptions") {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    return handleSubscriptionRedirect();
                                  }
                                  if (item.menuName === "Personalized Views") {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    return handlePersonalizationRedirect();
                                  }
                                  if (item.menuName === "Custom Projects") {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    return handleCustomProjectsRedirect();
                                  }
                                  if (item.menuName === "Enterprise Taxonomy") {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    return handleTaxonomyManagerRedirect();
                                  }
                                  dispatch(resetMaintainedState());
                                  dispatch(routeChanged());
                                }}
                              >
                                <span style={{ paddingLeft: "4px" }}>
                                  {" "}
                                  {item.menuName} <hr className="hr-style" />
                                </span>
                              </Link>
                            </div>
                          )}

                          <div className={hideSeparatorList.includes(item.menuName) ? "hide-separator flex-list" : "flex-list"}>
                            {item.subMenu &&
                              item.subMenu.map((menu_item) => {
                                return (
                                  <div className="list-items" key={menu_item.menuName}>
                                    <Link
                                      to={`${menu_item.menuLink}`}
                                      onClick={() => {
                                        dispatch(resetMaintainedState());
                                        dispatch(routeChanged());
                                      }}
                                    >
                                      {menu_item.menuName}{" "}
                                    </Link>
                                  </div>
                                );
                                // }
                              })}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          )}
        </>
      );
    }
  };
  return (
    <SidebarComponent
      id="sidebar-menu"
      ref={sideBarRef}
      isOpen={active}
      enableDock={true}
      // mediaQuery={mediaQuery}
      dockSize={"54px"}
      width={"194px"}
      closeOnDocumentClick={true}
      close={onClose}
      created={onCreate}
    >
      <div className="title-with-burger title-with-burger-row">
        <div className="hamburger ">
          <GiHamburgerMenu size={24} onClick={openClick} />
        </div>
        {active && <span>GenNX CMS</span>}
      </div>
      {data?.length > 0 && (
        <MenuComponent
          cssClass="menu-z-index"
          items={data}
          orientation="Vertical"
          //cssClass='dock-menu'
          template={menuTemplate}
          fields={fields}
        ></MenuComponent>
      )}
    </SidebarComponent>
  );
};

export default SidebarUI;
