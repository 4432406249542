/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Alert Box Actions (show / hide)
----------------------------------------------------------------
	Creation Details
	Date Created				: 09/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { SHOW_ALERT_BOX, HIDE_ALERT_BOX } from "../../constants/actionConstants";

const defaultCallback = () => {
	return;
};
const defaultMessage = () =>{
	return "Are you sure, you want to continue?"	
};

export const showAlertBox = ({
	title = "Confirm",
	okText = "Yes",
	okCallback = defaultCallback,
	cancelText = "No",
	cancelCallback = defaultCallback,
	content = "Are you sure, you want to continue?",
	data = undefined,
	hideCancelButton = false
}) => ({
	type: SHOW_ALERT_BOX,
	payload: { title, okText, okCallback, cancelText, cancelCallback, content, data, hideCancelButton }
});

export const hideAlertBox = () => ({
	type: HIDE_ALERT_BOX,
});