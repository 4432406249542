/**
 * @fileoverview This file contains multi select dropdown component.
 * @date 04/Jul/2023
 * @author Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
 */
import React, { memo } from "react";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";

let ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MultiSelectDropDown = (props) => {
  const {
    id,
    data,
    caption,
    value,
    label,
    setter,
    fullWidth = true,
    width = "100%",
    disabled,
    style,
    required,
    menuItemsCss,
    menuItemsSx,
    menuPaperHeight,
    renderValue,
    size = "small",
  } = props;

  if (menuPaperHeight) ITEM_HEIGHT = menuPaperHeight;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (setter) setter(typeof value === "string" ? value?.split(",") : value);
  };

  return (
    <div>
      <FormControl sx={{ width: "100%" }} size={size}>
        <InputLabel sx={{ fontSize: "14px" }} id={`${id}-multiple-checkbox-label`} required={required}>
          {label}
        </InputLabel>
        <Select
          labelId={`${id}-multiple-checkbox-label`}
          id={`${id}-multiple-checkbox`}
          multiple
          value={value}
          fullWidth={fullWidth}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={
            renderValue
              ? renderValue
              : (selected) =>
                  selected
                    ?.map((s) => data?.find((d) => d?.value === s)?.label)
                    .filter((str) => str !== undefined)
                    .join(", ")
          }
          MenuProps={MenuProps}
          style={style}
          sx={{ width }}
          disabled={disabled}
          required={required}
        >
          {data?.map((op, index) => (
            <MenuItem
              key={op?.value}
              value={op?.value}
              style={menuItemsCss}
              sx={{
                width: "100%",
                borderBottom: index !== data?.length - 1 ? "2px solid rgba(209,213,219,0.3)" : "none",
                fontWeight: "500",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "#EBEAEA",
                },
                ...menuItemsSx,
              }}
            >
              <Checkbox checked={value?.indexOf(op?.value) > -1} />
              <ListItemText
                primary={op[caption]}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default memo(MultiSelectDropDown);
