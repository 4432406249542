/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Grid Top Toolbar Component
----------------------------------------------------------------
	Creation Details
	Date Created				: 07/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
const GridTopToolBar = (props) => {
	const { add = undefined, textButton = undefined } = props;
	const history = useHistory();
	return (
		<div className="mata-content">
			<div className="container-fluid">
				<ul>
					{/* <li> <i class="fas fa-search" aria-hidden="true"></i> </li>
					<li> <i class="fas fa-history" aria-hidden="true"></i> </li>
				<li> <i class="fas fa-link"></i> </li> */}
					{add ?
						<li className="btn btn-dark cy-btn " onClick={() => {
							if (add.addCallback) add.addCallback();
							else add && add.route && history.push(add.route, add.state ? add.state : {});
						}}
							data-tip={add && add.toolTip ? add.toolTip : ""}
						>
							<span className="text-white pt-1">{add && add.text || "Add New"}</span>
						</li>
						: null}
					{textButton ?
						<li className="bg-secondary cursor-pointer text-white " onClick={() => {
							if (textButton.addCallback) textButton.addCallback();
							else textButton && textButton.route && history.push(textButton.route, textButton.state ? textButton.state : {});
						}} data-tip={textButton && textButton.toolTip ? textButton.toolTip : ""}>
							{textButton.text}
						</li>
						: null}
				</ul>
			</div>
		</div>
	);
};

export default GridTopToolBar;

GridTopToolBar.propTypes = {
	add: PropTypes.object,
	textButton: PropTypes.object,
};
