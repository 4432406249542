// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : Industry filter component for site search results page
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :21 Nov 2023
// Author:Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import "react-checkbox-tree/lib/react-checkbox-tree.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import PinkButton from "../../pinkButton/PinkButton";
import classes from "./IndustryFilter.module.css";
import CheckboxTree from "react-checkbox-tree";
import { useDispatch, useSelector } from "react-redux";
import {
  setFirstTreeCheckedNodes,
  setSecondTreeCheckedNodes,
  setSearchSelectedFilterNodes,
  setTrendFilterSearch,
  getSectorsSearch,
} from "../../../../middleware/actions/searchAction";
// import { uiAction } from '../../../../middleware/store/ui-slice';
// import { companyAction } from '../../../../middleware/store/company-slice';
// import { keyReportAction } from '../../../../middleware/store/keyReports-slice';
// import { newsAction } from '../../../../middleware/store/news-slice';
import { useLocation } from "react-router-dom";
import { getTrendsTaxonomy } from "../../../../middleware/services/searchServiceApi";
import EmptyData from "../../emptyData/EmptyData";
import { IoSearch } from "react-icons/io5";
import "./styles.css";
import { MdClose } from "react-icons/md";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const tempIcons = {
  check: <img src="/assets/check.svg" alt="checkbox" className="checkTree" />,
  uncheck: <img src="/assets/uncheck.svg" alt="checkbox" className="checkTree" />,
  halfCheck: <img src="/assets/half-check.svg" alt="checkbox" className="checkTree" />,
  expandClose: <span className="rct-icon rct-icon-expand-close" />,
  expandOpen: <span className="rct-icon rct-icon-expand-open" />,
};

export default function IndustryFilter({ anchorEl, setAnchorEl, setActiveIndex }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const containerRef = useRef(null);

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [expandedFirstSgf, setExpandedFirstSgf] = useState([]);
  const [expandedSecondSgf, setExpandedSecondSgf] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [firstNodes, setFirstNodes] = useState([]);
  const [secondNodes, setSecondNodes] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [modifiedSgfList, setModifiedSgfList] = useState([]);

  let sgfList = useSelector((state) => state["searchState"]["sgfListSearch"]);

  const disabledSgf = useMemo(() => {
    const temp = [];
    if (!sgfList || sgfList.length === 0) return [];
    sgfList.forEach((ind) => {
      ind.children.forEach((sgf) => {
        if (sgf.disabled) {
          temp.push(sgf);
        }
      });
    });
    return temp;
  }, [modifiedSgfList]);

  const [activeKeyIndex, setActiveKeyIndex] = useState(sgfList[0]);

  let allSearchCount = useSelector((state) => state["searchState"]["allSearchCount"]);

  const selectedSearchFilters = useSelector((state) => state["searchState"]["selectedSearchFilters"]);

  const firstTreeCheckedNodes = useSelector((state) => state["searchState"]["firstTreeCheckedNodes"]);

  const secondTreeCheckedNodes = useSelector((state) => state["searchState"]["secondTreeCheckedNodes"]);

  useEffect(() => {
    if (modifiedSgfList?.length) {
      const splittingPoint = Math.ceil(modifiedSgfList[0].children.length / 2);
      setFirstNodes(modifiedSgfList[0].children.slice(0, splittingPoint));
      setSecondNodes(modifiedSgfList[0].children.slice(splittingPoint));

      let treeNodeDiv = document.getElementById("sgf");
      let olElement = treeNodeDiv.querySelector("ol");
      if (olElement) {
        let liElements = olElement.querySelectorAll("li");
        liElements[0].style.backgroundColor = "#dbdbdd";
      }
    }
  }, [modifiedSgfList]);

  const coords = useMemo(() => {
    return anchorEl?.getBoundingClientRect();
  }, [anchorEl]);

  const getTrendIds = (checkedNode, targetNode, sgfList) => {
    if (targetNode.treeDepth > 2) {
      return checkedNode;
    } else {
      let checkedTree = getCheckedObj(checkedNode, sgfList);
      return getTrendValues(checkedTree);
    }
  };

  const getCheckedObj = (checkedNode, sgfList) => {
    let result = [];
    sgfList.forEach(function (a, i) {
      if (!a.disabled) {
        if (checkedNode.includes(a.value)) result.push(a);
        if (Array.isArray(a.children)) {
          result = result.concat(getCheckedObj(checkedNode, a.children));
        }
      }
    });
    return result;
  };

  const getTrendValues = (dataList) => {
    return dataList.flatMap((item) => {
      if (item.value.includes("trend") && !item.disabled) {
        return item.value;
      } else if (item?.children && item?.children.length) {
        return getTrendValues(item.children);
      }
      return [];
    });
  };

  // const resetFilter = () => {
  //   dispatch(
  //     uiAction.updateUrlParams({
  //       id: '',
  //       page: '',
  //       sort: '',
  //     }),
  //   );
  //   if (location.pathname.toString().includes('/company-profile/')) {
  //     dispatch(companyAction.resetParams(''));
  //   }

  //   if (location.pathname.toString().includes('/news-and-commentary/')) {
  //     dispatch(newsAction.resetParams(''));
  //   }

  //   if (location.pathname.toString().includes('/key-reports/')) {
  //     dispatch(keyReportAction.resetParams(''));
  //   }
  // };

  // show the child nodes of filter
  const onExpandClick = async (targetNode) => {
    if (targetNode.children && targetNode.children.length === 0 && targetNode.treeDepth === 1) {
      // dispatch(uiAction.toggleLoading(true));
      let array = JSON.parse(JSON.stringify(modifiedSgfList));
      let targetId = targetNode.value.split(">")[1];

      let trends = await getTrendsTaxonomy(targetId);
      array.forEach((a) => {
        a.children.forEach((b) => {
          b.children.forEach((c) => {
            if (c.value === targetNode.value) {
              c["children"] = trends;
            }
            return false;
          });
        });
      });

      dispatch(
        getSectorsSearch({
          sgfListSearch: array,
        })
      );
      // dispatch(uiAction.toggleLoading(false));
    }
  };

  const handleExpandClick = () => {
    if (isExpanded) {
      setExpandedFirstSgf([]);
      setExpandedSecondSgf([]);
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
      let firstTreeNodes = extractValues(firstNodes);
      let secondTreeNodes = extractValues(secondNodes);
      setExpandedFirstSgf(firstTreeNodes);
      setExpandedSecondSgf(secondTreeNodes);
    }
  };

  const handleSelectAllClick = () => {
    let trendValues = [];
    let firstNodeSelected = extractValues(firstNodes);
    let secondNodeSelected = extractValues(secondNodes);
    if (selectAll) {
      let firstTreeSelected = Array.from(new Set([...firstNodeSelected, ...firstTreeCheckedNodes]));
      let secondTreeSelected = Array.from(new Set([...secondNodeSelected, ...secondTreeCheckedNodes]));
      let checked = Array.from(new Set([...firstTreeSelected, ...secondTreeSelected]));
      dispatch(setFirstTreeCheckedNodes(firstTreeSelected));
      dispatch(setSecondTreeCheckedNodes(secondTreeSelected));
      dispatch(setSearchSelectedFilterNodes(checked));
      trendValues = getTrendIds(checked, 0, modifiedSgfList);
      dispatch(setTrendFilterSearch({ checked, trendValues }));
      // resetFilter();
      setSelectAll(false);
    } else {
      setSelectAll(true);
      let allTrends = selectedSearchFilters.selectedSgf.filter((n) => !firstNodeSelected.includes(n)).filter((c) => !secondNodeSelected.includes(c));
      dispatch(setFirstTreeCheckedNodes(firstTreeCheckedNodes.filter((n) => !firstNodeSelected.includes(n))));
      dispatch(setSecondTreeCheckedNodes(secondTreeCheckedNodes.filter((n) => !secondNodeSelected.includes(n))));
      dispatch(setSearchSelectedFilterNodes(allTrends));
      let trendValues = getTrendIds(allTrends, 0, modifiedSgfList);
      dispatch(setTrendFilterSearch({ checked: allTrends, trendValues: trendValues }));
      // resetFilter();
    }
  };

  const extractValues = (nodes) => {
    let nodeSelected = [];
    nodes.forEach((e) => {
      if (!e.disabled) {
        nodeSelected.push(e.value.toString());
        if (e.children) {
          nodeSelected = nodeSelected.concat(extractValues(e.children));
        }
      }
    });
    return nodeSelected;
  };

  const filterNodes = (filtered, node) => {
    const children = (node?.children || []).reduce(filterNodes, []);
    if (node.label.toLowerCase().indexOf(filterText.toLowerCase()) > -1 || children?.length) {
      if (node?.children) {
        filtered.push({ ...node, children });
      } else {
        filtered.push(node);
      }
    }
    return filtered;
  };

  const onFilterChange = (e) => {
    setFilterText(e.target.value);
  };
  useEffect(() => {
    let firstN;
    let secondN;
    const splittingPoint = Math.ceil(activeKeyIndex?.children?.length / 2);
    firstN = activeKeyIndex?.children?.slice(0, splittingPoint);
    secondN = activeKeyIndex?.children?.slice(splittingPoint);
    if (filterText == "") {
      setFirstNodes(firstN);
      setSecondNodes(secondN);
    } else {
      firstN = firstN.reduce(filterNodes, []);
      secondN = secondN.reduce(filterNodes, []);
      setFirstNodes(firstN);
      setSecondNodes(secondN.reduce(filterNodes, []));
    }
  }, [filterText]);

  function addPrefixToLabels(data, IdCount) {
    const newData = [];

    for (const item of data) {
      let newItem = { ...item }; // Create a new object with the same properties
      if (item.id in IdCount) {
        const entityCount = `${IdCount[item.id]}`;
        newItem.label = item.label.replace("0", entityCount);
      } else {
        newItem.disabled = true;
      }
      if (item?.children) {
        newItem.children = addPrefixToLabels(item.children, IdCount);
      }
      newData.push(newItem);
    }

    return newData;
  }

  const highlightBorder = (e) => {
    let treeNodeDiv = document.getElementById("sgf");
    let olElement = treeNodeDiv.querySelector("ol");
    if (olElement) {
      let liElements = olElement.querySelectorAll("li");
      liElements.forEach((li, index) => {
        if (index === e.index) {
          li.style.backgroundColor = "#dbdbdd";
        } else li.style.backgroundColor = "unset";
      });
    }
  };

  useEffect(() => {
    if (Object.entries(allSearchCount)?.length) {
      let sgfListWithCount;

      sgfListWithCount = addPrefixToLabels(sgfList, allSearchCount);
      setModifiedSgfList(sgfListWithCount);
      setActiveKeyIndex(sgfListWithCount[0]);
    }
  }, [allSearchCount]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        const loaderNode = document.getElementsByClassName("mainLoader");
        if (ref.current && !ref.current.contains(event.target) && loaderNode?.length !== 1) {
          setAnchorEl(null);
          setTimeout(() => {
            setActiveIndex(null);
          }, 10);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const findChildrenDisabled = (childrenNodes) => {
    if (childrenNodes) return childrenNodes.filter((c) => c.disabled);
  };

  useOutsideAlerter(containerRef);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.1 },
        transform: `translateX(${coords?.right - coords.left - 378}px) translateY(${coords?.y - coords.top + 35}px)`,
      }}
      exit={{ opacity: 0 }}
      style={{
        position: "absolute",
        zIndex: 1,
        left: coords.left,
        top: coords?.top,
        transform: `translateX(${coords?.right - coords.left - 378}px) translateY(${coords?.y - coords.top + 35}px)`,
        // translateY: coords?.top + coords?.height,
        width: "80%",
        minHeight: "200px",
        height: "fit-content",
        maxHeight: `calc(100% - ${coords.top}px - 10px)`,
        backgroundColor: "white",
        boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.25)",
        transition: "all 0.3s ease",
        display: "flex",
        flexDirection: "row",
      }}
      ref={containerRef}
    >
      <div
        style={{
          width: "20%",
          borderRight: "1px solid #dbdbdd",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid #dbdbdd",
          }}
          className="popup-header"
        ></div>
        <CheckboxTree
          checkModel="all"
          style={{
            backgroundColor: "#eeeeee",
          }}
          // expandDisabled={true}
          id="sgf"
          // nativeCheckboxes={false}
          // onlyLeafCheckboxes={true}
          optimisticToggle={false}
          onClick={(e) => {
            setFilterText("");
            setSelectAll(true);
            setIsExpanded(false);
            setExpandedFirstSgf([]);
            setExpandedSecondSgf([]);
            highlightBorder(e);
            setActiveKeyIndex(e);
            const splittingPoint = Math.ceil(e.children.length / 2);
            setFirstNodes(e.children.slice(0, splittingPoint));
            setSecondNodes(e.children.slice(splittingPoint));
          }}
          nodes={modifiedSgfList}
          showNodeIcon={false}
          checked={selectedSearchFilters.selectedSgf}
          onCheck={(checked, targetNode) => {
            highlightBorder(targetNode);
            setFilterText("");
            let filteredChecked = checked.filter((c) => !disabledSgf.some((d) => c.includes(d.label)));
            const splittingPoint = Math.ceil(targetNode.children.length / 2);
            const firstTreeNodesSplit = targetNode.children.slice(0, splittingPoint);
            const secondTreeNodesSplit = targetNode.children.slice(splittingPoint);
            setFirstNodes(firstTreeNodesSplit);
            setSecondNodes(secondTreeNodesSplit);
            if (!targetNode.checked) {
              let removeChildren = extractValues(targetNode.children);
              dispatch(
                setFirstTreeCheckedNodes(firstTreeCheckedNodes.filter((n) => !removeChildren.includes(n)).filter((n) => n !== targetNode.value))
              );
              dispatch(
                setSecondTreeCheckedNodes(secondTreeCheckedNodes.filter((n) => !removeChildren.includes(n)).filter((n) => n !== targetNode.value))
              );
              dispatch(
                setSearchSelectedFilterNodes(
                  selectedSearchFilters.selectedSgf.filter((n) => !removeChildren.includes(n)).filter((n) => n !== targetNode.value)
                )
              );
              // let trendValues = getTrendIds(filteredChecked, targetNode, sgfList);
              return dispatch(setTrendFilterSearch({ checked: filteredChecked, trendValues: [] }));
            } else {
              let isChildrenDisabled = findChildrenDisabled(targetNode.children);
              if (isChildrenDisabled?.length > 0) {
                filteredChecked = filteredChecked.filter((i) => i !== targetNode.value);
              }
              targetNode.children.map((e) => {
                let isChildrenDisabledInner = findChildrenDisabled(e.children);
                if (isChildrenDisabledInner?.length > 0) {
                  filteredChecked = filteredChecked.filter((i) => i !== e.value);
                }
              });
              let trendValues = getTrendIds(filteredChecked, targetNode, sgfList);
              dispatch(setTrendFilterSearch({ checked: filteredChecked, trendValues: trendValues }));
              let firstTreeNode = extractValues(firstTreeNodesSplit);
              let secondTreeNode = extractValues(secondTreeNodesSplit);
              dispatch(setFirstTreeCheckedNodes(Array.from(new Set([...firstTreeNode, ...firstTreeCheckedNodes]))));
              dispatch(setSecondTreeCheckedNodes(Array.from(new Set([...secondTreeNode, ...secondTreeCheckedNodes]))));
              dispatch(setSearchSelectedFilterNodes(filteredChecked));
            }

            // resetFilter();
          }}
          onExpand={(expanded, targetNode) => {
            setSelectAll(true);
            setIsExpanded(false);
            setExpandedFirstSgf([]);
            setExpandedSecondSgf([]);
            setFilterText("");
            highlightBorder(targetNode);
            setActiveKeyIndex(targetNode);
            const splittingPoint = Math.ceil(targetNode.children.length / 2);
            setFirstNodes(targetNode.children.slice(0, splittingPoint));
            setSecondNodes(targetNode.children.slice(splittingPoint));
          }}
          icons={{
            check: <img src="/assets/check.svg" alt="checkbox" className="checkTree" style={{ marginTop: "8px" }} />,
            uncheck: <img src="/assets/uncheck.svg" alt="checkbox" className="checkTree" style={{ marginTop: "8px" }} />,
            halfCheck: <img src="/assets/half-check.svg" alt="checkbox" className="checkTree" style={{ marginTop: "8px" }} />,
            expandClose: (
              <span
                className="e-icons interaction e-icon-collapsible"
                style={{
                  color: "#000",
                  fontSize: "15px",
                }}
              />
            ),
            expandOpen: (
              <span
                className="e-icons interaction e-icon-expandable"
                style={{
                  color: "#000",
                  fontSize: "15px",
                }}
              />
            ),
          }}
        />
      </div>
      <div style={{ width: "80%" }}>
        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #dbdbdd",
            padding: "10px 20px",
          }}
          className="popup-header"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
              border: "1px solid #dbdbdd",
              padding: "0px 10px",
              width: "fit-content",
            }}
          >
            <IoSearch fontSize="1.3em" fill="#D6DBDE" />
            <input
              className="filter-text"
              placeholder="Search"
              type="text"
              value={filterText}
              onChange={onFilterChange}
              style={{
                border: "none",
                height: "30px",
                fontSize: 15,
                color: "#D6DBDE !important",
                borderRadius: "3px",
                background: "transparent",
                width: "120px",
                // marginLeft: -50,
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <PinkButton onClick={handleSelectAllClick} className={classes.pinkButton}>
              {selectAll ? "Select All" : "Deselect All"}
            </PinkButton>
            <PinkButton onClick={handleExpandClick} className={classes.pinkButton}>
              {isExpanded ? "Collapse All" : "Expand All"}
            </PinkButton>
            <MdClose onClick={() => setAnchorEl(null)} style={{ cursor: "pointer", marginLeft: "10px" }} fontSize="60px" />
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            // gridTemplateRows: "repeat(2, 1fr)",
            height: "calc(100% - 50px)",
            maxHeight: "calc(100% - 50px)",
            overflowY: "auto",
          }}
        >
          {firstNodes?.length ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                // border: "1px solid #dbdbdd",
                padding: "10px",
              }}
            >
              <CheckboxTree
                checkModel="all"
                nodes={firstNodes}
                showNodeIcon={false}
                checked={selectedSearchFilters.selectedSgf}
                expanded={expandedFirstSgf}
                // noCascade={true}
                optimisticToggle={false}
                onCheck={(checked, targetNode) => {
                  let trendValues = Array.from(new Set([...checked, ...firstTreeCheckedNodes, ...secondTreeCheckedNodes]));
                  if (targetNode.checked) {
                    let isChildrenDisabled = targetNode?.children ? findChildrenDisabled(targetNode.children) : [];
                    if (isChildrenDisabled?.length > 0) {
                      trendValues = trendValues.filter((i) => i !== targetNode.value);
                    }
                    dispatch(setFirstTreeCheckedNodes(Array.from(new Set([...checked, ...firstTreeCheckedNodes]))));
                    dispatch(setSearchSelectedFilterNodes(trendValues));
                  }
                  if (!targetNode.checked && !targetNode.children) {
                    trendValues = selectedSearchFilters.selectedSgf
                      .filter((n) => n !== targetNode.value)
                      .filter((p) => p !== targetNode.parent.value);
                    dispatch(
                      setFirstTreeCheckedNodes(
                        firstTreeCheckedNodes.filter((n) => n !== targetNode.value).filter((p) => p !== targetNode.parent.value)
                      )
                    );
                    dispatch(setSearchSelectedFilterNodes(trendValues));
                  }
                  if (!targetNode.checked && targetNode.children) {
                    let removeChildren = extractValues(targetNode.children);
                    trendValues = selectedSearchFilters.selectedSgf
                      .filter((n) => !removeChildren.includes(n) && n !== targetNode.value)
                      .filter((p) => p !== targetNode.parent.value);
                    dispatch(setFirstTreeCheckedNodes(firstTreeCheckedNodes.filter((n) => !removeChildren.includes(n) && n !== targetNode.value)));
                    dispatch(setSearchSelectedFilterNodes(trendValues));
                  }
                  let getvalues = getTrendIds(trendValues, targetNode, sgfList);
                  dispatch(setTrendFilterSearch({ checked: trendValues, trendValues: getvalues }));

                  // resetFilter();
                }}
                onExpand={(expanded, targetNode) => {
                  setExpandedFirstSgf(expanded);
                  // onExpandClick(targetNode);
                }}
                icons={{
                  check: <img src="/assets/check.svg" alt="checkbox" className="checkTree" />,
                  uncheck: <img src="/assets/uncheck.svg" alt="checkbox" className="checkTree" />,
                  halfCheck: <img src="/assets/half-check.svg" alt="checkbox" className="checkTree" />,
                  expandClose: (
                    <span
                      className="e-icons interaction e-icon-collapsible"
                      style={{
                        color: "#000",
                        fontSize: "15px",
                      }}
                    />
                  ),
                  expandOpen: <FontAwesomeIcon icon="chevron-down" size="lg" />,
                  expandAll: <span className="rct-icon rct-icon-expand-all" />,
                  collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                }}
              />
            </div>
          ) : null}
          {secondNodes?.length ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                // border: "1px solid #dbdbdd",
                padding: "10px",
              }}
            >
              <CheckboxTree
                checkModel="all"
                nodes={secondNodes}
                showNodeIcon={false}
                checked={selectedSearchFilters.selectedSgf}
                expanded={expandedSecondSgf}
                // noCascade={true}
                optimisticToggle={false}
                onCheck={(checked, targetNode) => {
                  let trendValues = Array.from(new Set([...checked, ...secondTreeCheckedNodes, ...firstTreeCheckedNodes]));

                  if (targetNode.checked) {
                    let isChildrenDisabled = targetNode?.children ? findChildrenDisabled(targetNode.children) : [];
                    if (isChildrenDisabled?.length > 0) {
                      trendValues = trendValues.filter((i) => i !== targetNode.value);
                    }
                    dispatch(setSecondTreeCheckedNodes(Array.from(new Set([...checked, ...secondTreeCheckedNodes]))));
                    dispatch(setSearchSelectedFilterNodes(trendValues));
                  }
                  if (!targetNode.checked && !targetNode.children) {
                    trendValues = selectedSearchFilters.selectedSgf
                      .filter((n) => n !== targetNode.value)
                      .filter((p) => p !== targetNode.parent.value);
                    dispatch(
                      setSecondTreeCheckedNodes(
                        secondTreeCheckedNodes.filter((n) => n !== targetNode.value).filter((p) => p !== targetNode.parent.value)
                      )
                    );
                    dispatch(setSearchSelectedFilterNodes(trendValues));
                  }
                  if (!targetNode.checked && targetNode.children) {
                    let removeChildren = extractValues(targetNode.children);
                    trendValues = selectedSearchFilters.selectedSgf
                      .filter((n) => !removeChildren.includes(n) && n !== targetNode.value)
                      .filter((p) => p !== targetNode.parent.value);
                    dispatch(
                      setSecondTreeCheckedNodes(
                        secondTreeCheckedNodes
                          .filter((n) => !removeChildren.includes(n) && n !== targetNode.value)
                          .filter((p) => p !== targetNode.parent.value)
                      )
                    );
                    dispatch(setSearchSelectedFilterNodes(trendValues));
                  }

                  let getvalues = getTrendIds(trendValues, targetNode, sgfList);
                  dispatch(setTrendFilterSearch({ checked: trendValues, trendValues: getvalues }));

                  // resetFilter();
                }}
                onExpand={(expanded, targetNode) => {
                  setExpandedSecondSgf(expanded);
                  // onExpandClick(targetNode);
                }}
                icons={{
                  check: <img src="/assets/check.svg" alt="checkbox" className="checkTree" />,
                  uncheck: <img src="/assets/uncheck.svg" alt="checkbox" className="checkTree" />,
                  halfCheck: <img src="/assets/half-check.svg" alt="checkbox" className="checkTree" />,
                  expandClose: (
                    <span
                      className="e-icons interaction e-icon-collapsible"
                      style={{
                        color: "#000",
                        fontSize: "15px",
                      }}
                    />
                  ),
                  expandOpen: <FontAwesomeIcon icon="chevron-down" size="lg" />,
                }}
              />
            </div>
          ) : null}
          {!firstNodes?.length && !secondNodes?.length ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                margin: "auto calc(50%)",
              }}
            >
              <EmptyData msg="Your search did not match any results. Please try different keywords to see the relevant content." />
            </div>
          ) : null}
        </div>
      </div>
    </motion.div>
  );
}
