import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  TextField,
  Typography,
  Grid,
  Box,
  IconButton,
  InputBase,
  Select,
  FormControl,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  InputLabel,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import SidebarModal from "../sidebar-modal";
import "./index.css";
import CommonDateTagSection from "../CommonDateTagSection/CommonDateTagSection";
import ViewMoreLink from "../ViewMoreLink";
import MagnifyingImage from "assets/images/magnifying-image.png";
import EmptyData from "assets/images/empty-data.svg?react";
import RefreshIcon from "assets/images/refresh.svg?react";
import GridPagination from "../pagination";
import {
  NEWS_COUNT,
  NEWS_SORT,
  CONTENT_SORT,
  NEWS_CONTENT_TYPE_TEXT,
  SAVE_AND_PUBLISH_TEXT,
  CANCEL_BUTTON_TEXT,
  WIDGET_SORT_CLEAR,
  WIDGET_SORT_TEXT,
  NEWS_COUNT_TEXT,
  NO_DATA,
  WIDGET_CONTENT_RIGHT_SIDE_MESSAGE,
  WIDGET_CONTENT_LEFT_SIDE_HEADER_TEXT,
  WIDGET_CONTENT_RIGHT_SIDE_HEADER_TEXT,
  WIDGET_FILTER,
  PREVIOUS_BUTTON_TEXT,
  PINNED_CONTENT_MAX_LIMIT,
} from "../../../constants/widget-constants";
import PinnedContent from "../PinnedContent";
import { fetchConfig, fetchWidgetList } from "middleware/services/cmsApi";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { actionError, hideLoader, showLoader } from "middleware/actions/utilityAction";
import { setPreviewData, setRightSideWidgetList } from "middleware/actions/customizableWidgetActions";
import { useRenderComponent } from "hooks/useRenderComponent";
import DraggableList from "../customizableDragAndDrop";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { theme } from "src/theme";
import MultiselectDropdown from "../multiselectDropdown";
import { FaArrowRight } from "react-icons/fa";
import { IoInformation } from "react-icons/io5";
import { FaCircleInfo } from "react-icons/fa6";
import { getFilteredAndSortedValues, getFirstPublishedDate, getUniqueTags } from "utils";

const newsCountDropdown = () =>
  NEWS_COUNT.map((value) => (
    <MenuItem key={value} value={value}>
      {value}
    </MenuItem>
  ));
const newsSortDropdown = () =>
  NEWS_SORT.map((sortObject) => (
    <MenuItem key={sortObject?.key} value={sortObject?.key}>
      {sortObject?.value}
    </MenuItem>
  ));
const contentSortDropdown = () =>
  CONTENT_SORT.map((sortObject) => (
    <MenuItem key={sortObject?.key} value={sortObject?.key}>
      {sortObject?.value}
    </MenuItem>
  ));

const CustomizableWidgetSidebar = (props) => {
  const {
    formik,
    contentTypeDataSource,
    deliverableTypeDataSource,
    searchBoxRef,
    searchWidgets,
    handleCheckboxChange,
    widgetLeftList,
    widgetRightList,
    onClose,
    widgetData,
    selectedWidgetId,
    newsContentCuration,
    handleNewsContentCurationChange,
    onDragEnd,
    handleDelete,
    widgetName,
    handleSaveWidget,
    widgetIndustryId,
    handleArrowClick,
    setSelectedTheme,
    selectedTheme,
    splitConfig,
    isEditWidget,
    newsTabValue,
    handleNewsTabChange,
    newlyAddedWidgetList,
    recentlyUpdatedWidgetList,
    onDragEndKeyPlayer,
    isNewsCuration,
    newsCount,
    newsCurateSortValue,
    handleNewsCurateSortValue,
    setIsNewsCuration,
    setNewsCount,
    resolution,
    handlePreviousClick,
  } = props;
  const dispatch = useDispatch();
  const selectReadFlag = formik?.values?.widgetConfigName !== "featured_content" ? WIDGET_FILTER[formik?.values?.widgetConfigName]?.readOnly : false;

  const [sortContent, setSortContent] = React.useState("desc");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const rightWidgetList = useSelector((state) => state?.customizableWidgetState?.rightWidgetList);
  const { selectedWidgetData } = useSelector((state) => state?.customizableWidgetState);
  const previewData = useSelector((state) => state.customizableWidgetState.previewContent);
  const renderComponent = useRenderComponent();

  // ** Set widget name
  const selectedWidget = useMemo(() => widgetData[0]?.configValue?.find((d) => d.value === selectedWidgetId), [widgetData, selectedWidgetId]);

  const [paramFilters, setParamFilters] = useState([]);
  const [maxContentLimit, setMaxContentLimit] = useState(0);
  const [toolTipMessage, setToolTipMessage] = useState("");

  const {
    data: allWidgetList,
    refetch: refetchWidgetList,
    isFetching: fetchingWidgets,
  } = useQuery({
    queryKey: [
      "all-widget-data",
      sortContent,
      widgetIndustryId,
      page,
      limit,
      formik?.values?.widgetContentType,
      formik?.values?.widgetDeliverableType,
      paramFilters,
    ],
    queryFn: () => {
      let params = {
        sort: `productVariant.firstPublishedDate:${sortContent}`,
        filters: [
          ["industry_tag.trend_id", "eq", widgetIndustryId],
          ["productVariant.isLive", "eq", "YES"],
          ["productVariant.productName", "eq", "Insider"],
        ],
        skip: page === 1 ? 0 : (page - 1) * 10,
        limit: limit || 10,
      };
      if (paramFilters?.length > 0) params.filters.push(paramFilters);
      if (formik?.values?.widgetContentType?.length > 0) params.filters.push(["subContentType", "in", formik?.values?.widgetContentType]);
      if (formik?.values?.widgetDeliverableType?.length > 0) params.filters.push(["contentType", "in", formik?.values?.widgetDeliverableType]);
      return fetchWidgetList(params);
    },
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    enabled: !!formik?.values?.widgetContentType?.length > 0 || !!formik?.values?.widgetDeliverableType?.length > 0,
  });

  const { data: widgetThemeData, isFetching: fetchingThemeData } = useQuery({
    queryKey: ["widget-theme-data"],
    queryFn: () => {
      let params = {};
      params.filters = [["configName", "eq", "widget_theme"]];
      return fetchConfig(params);
    },
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    enabled: true,
  });

  const { data: newsWidgetList, isFetching: curationNewsWidgetsFetching } = useQuery({
    queryKey: ["news-curation-widget-data", widgetIndustryId, newsCount, widgetIndustryId, isNewsCuration],
    queryFn: () => {
      let params = {
        filters: [
          ["industryTag.trendId", "eq", widgetIndustryId],
          ["productVariant.isLive", "eq", "YES"],
          ["contentType", "in", WIDGET_FILTER["news"].contentType],
          ["subContentType", "in", WIDGET_FILTER["news"].deliverableType],
          ["productVariant.productName", "eq", "Insider"],
        ],
        skip: 0,
        limit: newsCount || 10,
        sort: "productVariant.firstPublishedDate:desc",
      };

      return fetchWidgetList(params);
    },
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    enabled: !!(newsContentCuration === "automatic" && newsCount && isNewsCuration),
  });
  //** Set theme name dropdown for Pinned content
  const colorThemeDropdown = useMemo(() => {
    formik.setValues({
      ...formik.values,
      isMerged: splitConfig?.[widgetName]?.[resolution]?.[formik?.values?.widgetUniqueName]?.isMerged ? "YES" : "NO",
    });
    if (!widgetThemeData?.data?.data[0]) return [];
    if (formik.values.widgetTheme.themeColor === "") {
      formik.setValues({
        ...formik.values,
        widgetTheme: {
          themeColor: widgetThemeData?.data?.data[0]?.configValue[0]?.themeColor,
          themeName: widgetThemeData?.data?.data[0]?.configValue[0]?.themeName,
        },
      });
    }
    return widgetThemeData?.data?.data[0]?.configValue.map((theme) => ({ label: theme?.themeName, value: theme?.themeColor })) || [];
  }, [widgetThemeData?.data?.data]);

  //** Set available content for widget
  const leftWidgetList = useMemo(() => {
    if (!allWidgetList?.data?.data?.length) return [];

    let list = [];
    if (formik.values?.widgetConfigName === "key_players" || formik.values?.widgetConfigName === "key_players_2")
      list = [...newlyAddedWidgetList, ...recentlyUpdatedWidgetList];
    else list = rightWidgetList;
    if (list?.length) {
      const newList = allWidgetList?.data?.data.map((widget) => {
        const isDisabled = list.some((rightWidget) => rightWidget.id == widget.id);
        return { ...widget, isDisabled };
      });
      return newList;
    }

    return allWidgetList?.data?.data.filter((content) => content) || [];
  }, [allWidgetList?.data, rightWidgetList, newlyAddedWidgetList, recentlyUpdatedWidgetList, formik?.values]);

  const handleNewsCountChange = (event) => {
    setNewsCount(event.target.value);
    setIsNewsCuration(true);
  };

  const handleSortContentChange = (event) => {
    setSortContent(event.target.value);
  };

  //** Handle theme name change
  const handleChangeTheme = (event) => {
    const selectedColorTheme = colorThemeDropdown.find((color) => color?.value === event?.target?.value);
    formik.setValues({
      ...formik.values,
      widgetTheme: {
        themeColor: selectedColorTheme?.value,
        themeName: selectedColorTheme?.label,
      },
    });
    setSelectedTheme(event.target.value);
  };

  //** Handle View More Link URL
  const handleHTMLTagChange = (newValue) => {
    formik.setValues({
      ...formik.values,
      widgetDashboardContentLink: newValue,
    });
  };

  const handleSearch = useCallback(() => {
    if (!searchText.trim()) return dispatch(actionError("Please enter search."));
    setParamFilters(["title", "cn", searchText.trim()]);
  }, [searchText]);

  useEffect(() => {
    if (!fetchingWidgets && !curationNewsWidgetsFetching) {
      dispatch(hideLoader());
    } else {
      dispatch(showLoader());
    }
    // Cleanup function
    return () => {
      dispatch(hideLoader());
    };
  }, [fetchingWidgets, curationNewsWidgetsFetching, dispatch]);

  useEffect(() => {
    if (paramFilters?.length > 0 || formik?.values?.widgetContentType?.length > 0 || formik?.values?.widgetDeliverableType?.length > 0) setPage(1);
  }, [paramFilters, formik?.values?.widgetContentType, formik?.values?.widgetDeliverableType]);

  useMemo(() => {
    if (newsContentCuration === "automatic" && formik?.values?.widgetConfigName === "news" && isNewsCuration) {
      if (!newsWidgetList?.data?.data?.length) return dispatch(setRightSideWidgetList([]));
      setIsNewsCuration(false);
      return dispatch(setRightSideWidgetList(newsWidgetList?.data?.data));
    }
    return;
  }, [newsWidgetList?.data, newsContentCuration, formik?.values, isNewsCuration]);

  useEffect(() => {
    if (!rightWidgetList?.length) dispatch(setPreviewData([]));
  }, [rightWidgetList]);

  const { contentTypeDropDownValues, deliveryTypeDropdownValues } = useMemo(() => {
    let filteredContentTypeValues = contentTypeDataSource;
    let filteredDeliveryTypeValues = deliverableTypeDataSource;

    if (formik?.values?.widgetConfigName === "featured_content") {
      filteredContentTypeValues = getFilteredAndSortedValues(contentTypeDataSource, WIDGET_FILTER["featured_content"]?.contentType || []);
      filteredDeliveryTypeValues = getFilteredAndSortedValues(deliverableTypeDataSource, WIDGET_FILTER["featured_content"]?.deliverableType || []);
    } else if (widgetName === "pinned") {
      filteredContentTypeValues = getFilteredAndSortedValues(contentTypeDataSource, WIDGET_FILTER["pinned"]?.contentType || []);
      filteredDeliveryTypeValues = getFilteredAndSortedValues(deliverableTypeDataSource, WIDGET_FILTER["pinned"]?.deliverableType || []);
    }
    return {
      contentTypeDropDownValues: filteredContentTypeValues,
      deliveryTypeDropdownValues: filteredDeliveryTypeValues,
    };
  }, [contentTypeDataSource, formik?.values?.widgetConfigName, widgetName, deliverableTypeDataSource]);

  useEffect(
    () => {
      const widget = widgetData[0]?.configValue?.find((d) => d?.name === formik?.values?.widgetConfigName);
      if (widget || widgetName !== "pinned") {
        setMaxContentLimit(widget?.contentMaxLimit);
        if (widget?.contentMaxLimit > 1) {
          setToolTipMessage(`Maximum of ${widget?.contentMaxLimit} contents can be added`);
        } else if (widget?.contentMaxLimit == 1) {
          setToolTipMessage(`Maximum of ${widget?.contentMaxLimit} content can be added`);
        }
      } else {
        if (widgetName === "pinned") {
          setMaxContentLimit(PINNED_CONTENT_MAX_LIMIT);
          if (PINNED_CONTENT_MAX_LIMIT > 1) {
            setToolTipMessage(`Maximum of ${PINNED_CONTENT_MAX_LIMIT} contents can be added`);
          } else if (PINNED_CONTENT_MAX_LIMIT == 1) {
            setToolTipMessage(`Maximum of ${PINNED_CONTENT_MAX_LIMIT} content can be added`);
          }
        }
      }
    },
    [widgetData[0]?.configValue, formik?.values?.widgetConfigName],
    widgetName
  );
  return (
    <SidebarModal
      style={{ marginTop: "65px", width: "100%" }}
      className="widget-sidebar-wrapper"
      backDrop={true}
      closeIcon={<div onClick={onClose}>X</div>}
      footer={
        <div className="action-button-container">
          <div>
            {!isEditWidget && (
              <button type="button" className="btn btn-outline-dark" style={{ fontSize: "14px" }} onClick={handlePreviousClick}>
                {PREVIOUS_BUTTON_TEXT}
              </button>
            )}
          </div>
          <div>
            <button
              type="button"
              className="btn btn-outline-dark"
              style={{ fontSize: "14px", paddingLeft: "16px", paddingRight: "16px" }}
              onClick={onClose}
            >
              {CANCEL_BUTTON_TEXT}
            </button>
            <button
              type="button"
              className="btn btn-primary ml-2"
              disabled={!!rightWidgetList?.length < 1}
              style={{ fontSize: "14px", paddingLeft: "16px", paddingRight: "16px" }}
              onClick={handleSaveWidget}
            >
              {SAVE_AND_PUBLISH_TEXT}
            </button>
          </div>
        </div>
      }
    >
      <ThemeProvider theme={theme}>
        <Box className="sidebar-container custom-widget-sidebar">
          <Box className="sidebar-header">
            <Box className="sidebar-header-add-widget">{isEditWidget ? "Edit" : "Add"} Widget</Box>
            <Box className="sidebar-header-widget-box">
              <Box className="sidebar-header-widget-name-label">Widget Name : </Box>
              <Box className="sidebar-header-widget-name-value">{" " + formik.values.widgetName}</Box>
            </Box>
          </Box>
          <Box className="sidebar-subtitle-content">
            <Box className="setting-wrapper">
              <Box className="setting-title">Settings</Box>
            </Box>
            <Box className="preview-wrapper">
              <Box className="preview-box">
                <Box className="preview-title">Preview of Widget</Box>
                <Box
                  className={rightWidgetList.length > 0 ? "cursor-pointer sidebar-preview-refresh-button" : "sidebar-preview-refresh-button"}
                  onClick={() => {
                    if (
                      (formik?.values?.widgetConfigName === "key_players" || formik?.values?.widgetConfigName === "key_players_2") &&
                      widgetName !== "pinned"
                    ) {
                      dispatch(setPreviewData([...newlyAddedWidgetList, ...recentlyUpdatedWidgetList]));
                      return;
                    }
                    dispatch(setPreviewData(rightWidgetList));
                  }}
                >
                  <Box component="img" src={rightWidgetList.length > 0 ? "assets/refresh-icon-active.svg" : RefreshIcon} />
                  <Box className={rightWidgetList.length > 0 ? "sidebar-preview-refresh-label-active" : "sidebar-preview-refresh-label"}>Refresh</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="sidebar-content-container">
            <Box className="sidebar-content">
              <Box className="left-setting-box">
                <Box
                  className={
                    formik?.values?.widgetConfigName === "news"
                      ? "left-setting-widget-display-box left-setting-news-widget-display-box"
                      : "left-setting-widget-display-box"
                  }
                >
                  <Box
                    sx={{
                      minWidth: "383px",
                      maxWidth: "45%",
                      "@media (max-width: 1024px)": {
                        minWidth: "100%",
                      },
                    }}
                  >
                    <TextField
                      id="widgetDisplayName"
                      name="widgetDisplayName"
                      label="Widget Display Name"
                      value={formik?.values?.widgetDisplayName}
                      onChange={(e) => formik.setValues({ ...formik.values, widgetDisplayName: e.target.value })}
                      sx={{
                        "& .MuiInputBase-root": {
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          fontSize: "14px",
                        },
                        "& .MuiInputBase-input": {
                          height: "44px !important",
                        },
                        "& .MuiInputLabel-root": {
                          top: "-5px",
                          fontSize: "14px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0px",
                          fontSize: "14px",
                        },
                        width: "383px",
                        marginBottom: "4px",
                        "@media (max-width: 1024px)": {
                          width: "100%",
                        },
                      }}
                      fullWidth
                      // margin="normal"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media (max-width: 1024px)": {
                          float: "right",
                        },
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#F94F5E",
                          fontSize: "12px !important",
                          lineHeight: "1 !important",
                          fontFamily: "inherit !important",
                          width: "225px",
                          "@media (max-width: 1024px)": {
                            display: "none",
                          },
                        }}
                      >
                        {(formik?.touched["widgetDisplayName"] === true && formik?.errors["widgetDisplayName"]) || ""}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                        style={{ fontWeight: 700, float: "right" }}
                      >
                        Character Limit: <span style={{ fontWeight: 200 }}>{formik?.values?.widgetDisplayName?.length || 0}/25</span>
                      </Typography>
                    </Box>
                  </Box>
                  {formik?.values?.widgetConfigName === "news" && (
                    <Box
                      sx={{
                        width: "55%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormLabel component="legend" sx={{ margin: 0, color: "black !important", fontSize: 14 }}>
                          {NEWS_CONTENT_TYPE_TEXT}
                        </FormLabel>
                        <RadioGroup
                          aria-label="content-curation"
                          name="content-curation"
                          value={newsContentCuration}
                          onChange={handleNewsContentCurationChange}
                        >
                          <FormControlLabel
                            value="automatic"
                            sx={{
                              marginTop: 0,
                              marginLeft: "-11px",
                              marginBottom: 0,
                              marginRight: 0,
                              fontSize: "14px !important",
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px !important",
                              },
                              heigh: 20,
                            }}
                            control={<Radio />}
                            label="Automatic"
                          />
                          <FormControlLabel
                            sx={{
                              marginTop: 0,
                              marginLeft: "-11px",
                              marginBottom: 0,
                              marginRight: 0,
                              fontSize: "14px !important",
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px !important",
                              },
                              height: 20,
                            }}
                            value="manual"
                            control={<Radio />}
                            label="Manual"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  )}
                </Box>
                {newsContentCuration === "manual" && (
                  <Box
                    className={
                      formik?.values?.widgetConfigName === "news"
                        ? "left-setting-news-lower-section left-setting-lower-section"
                        : "left-setting-lower-section"
                    }
                  >
                    <Box className="left-setting-filter-section">
                      <Box className="left-setting-filter">
                        <MultiselectDropdown
                          id="content-type"
                          label="Filter By Content Type"
                          value={formik?.values?.widgetContentType || []}
                          menuPaperHeight={44}
                          setter={(w) => {
                            const combined = new Set(w);
                            formik.setValues({ ...formik.values, widgetContentType: [...combined] });
                          }}
                          data={contentTypeDropDownValues || []}
                          caption="label"
                          fullWidth
                          menuItemsCss={{
                            padding: "20px 0px",
                            height: "20px",
                            borderBottom: "none",
                            fontStyle: "normal",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            color: "#000",
                          }}
                          disabled={selectReadFlag}
                        />
                      </Box>
                      <Box className="left-setting-filter">
                        <MultiselectDropdown
                          id="deliverable-type"
                          label="Filter By Deliverable Type"
                          value={formik?.values?.widgetDeliverableType || []}
                          menuPaperHeight={44}
                          setter={(w) => {
                            const combined = new Set(w);
                            formik.setValues({ ...formik.values, widgetDeliverableType: [...combined] });
                          }}
                          data={deliveryTypeDropdownValues || []}
                          caption="label"
                          fullWidth
                          menuItemsCss={{
                            padding: "20px 0px",
                            height: "20px",
                            borderBottom: "none",
                            fontStyle: "normal",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            color: "#000",
                          }}
                          disabled={selectReadFlag}
                        />
                      </Box>
                    </Box>
                    <Box className="left-search-filter-container">
                      <Box className="left-search-filter-box">
                        <InputBase
                          className="left-search-input-base"
                          placeholder="Search by Name"
                          inputProps={{ "aria-label": "Filter by Name" }}
                          value={searchText}
                          onChange={(e) => setSearchText(e?.target?.value)}
                          sx={{ fontSize: "14px" }}
                        />
                        <IconButton type="button" className="left-search-button" aria-label="search" onClick={handleSearch}>
                          <Box component="img" src={MagnifyingImage}></Box>
                        </IconButton>
                        <Typography
                          component="span"
                          className={searchText?.length > 0 ? "left-search-clear-text-active left-search-clear-text" : "left-search-clear-text"}
                          onClick={() => {
                            setSearchText("");
                            setParamFilters([]);
                          }}
                        >
                          {WIDGET_SORT_CLEAR}
                        </Typography>
                      </Box>
                      <Box className="left-sort-box">
                        <Typography component="span" className="left-sort-label">
                          {WIDGET_SORT_TEXT}
                        </Typography>
                        <FormControl className="left-sort-select-container">
                          <Select
                            value={sortContent}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            className="left-sort-select"
                            onChange={handleSortContentChange}
                            sx={{ height: 35, fontSize: "14px" }}
                          >
                            {contentSortDropdown()}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className="left-available-content-section">
                      <Box className="left-available-content-heading">{WIDGET_CONTENT_LEFT_SIDE_HEADER_TEXT}</Box>
                    </Box>
                    {leftWidgetList.length > 0 ? (
                      <Box className="left-available-content-box">
                        {leftWidgetList?.map((item, index) => (
                          <div className={`FilterList mainFilterDiv`} key={item.id + Math.random()} id={`list${item.id}`}>
                            <div style={{ display: "flex", maxHeight: "120px" }}>
                              <div className={"draggable-content-card"}>
                                <div className={"title_top"}>
                                  {item?.signalIndicator && (
                                    <Box className="disruptive-signals-percentage-box">
                                      <Typography
                                        className="disruptive-signals-percentage"
                                        style={{ backgroundColor: `${item?.signalIndicatorColor}` }}
                                      >
                                        {item?.signalIndicator}
                                      </Typography>
                                    </Box>
                                  )}
                                  <p className={item?.signalIndicator ? "widget-title" : "itemTitle"}>{item.title}</p>
                                </div>
                                {formik?.values?.widgetConfigName !== "signals" && (
                                  <CommonDateTagSection
                                    date={getFirstPublishedDate(item) || ""}
                                    label={item.subContentType}
                                    tags={getUniqueTags(item?.industryTag)}
                                    readTime={item?.readTime}
                                    showReadTime={true}
                                    subType={item.contentType}
                                    showSubType={false}
                                  />
                                )}
                                {formik?.values?.widgetConfigName === "signals" && (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "#4B4B55",
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      WebkitLineClamp: 2, // Number of lines to clamp
                                      textOverflow: "ellipsis",
                                      lineHeight: "15.18px !important",
                                      margin: "8px 0px !important",
                                    }}
                                  >
                                    {item?.infoText}
                                  </Typography>
                                )}
                              </div>
                              {formik?.values?.widgetConfigName === "signals" && item?.signalTooltip && (
                                <Tooltip
                                  title={item?.signalTooltip}
                                  placement="bottom-end"
                                  arrow
                                  sx={{
                                    backgroundColor: "transparent",
                                  }}
                                  className="custom-widget-tooltip"
                                  id="custom-widget-tooltip"
                                >
                                  <div className="signal-info-container">
                                    <IoInformation height={24} width={24} color="#4B4B55" />
                                  </div>
                                </Tooltip>
                              )}
                              <div
                                style={{
                                  cursor: (widgetName === "pinned" && rightWidgetList.length === 1) || item?.isDisabled ? "default" : "pointer",
                                  opacity: (widgetName === "pinned" && rightWidgetList.length === 1) || item?.isDisabled ? "0.25" : 1,
                                }}
                                className="draggable-content-card-arrow"
                                onClick={() => {
                                  if ((widgetName === "pinned" && rightWidgetList.length === 1) || item?.isDisabled) return;
                                  handleArrowClick(item, "Add", maxContentLimit);
                                }}
                              >
                                <FaArrowRight size={30} style={{ height: "16px", width: "16px", color: "#4B4B55 !important" }} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </Box>
                    ) : (
                      <Box className="right-no-data-message-container">
                        <Box className="right-selected-empty-box">
                          <Box component="img" src={EmptyData}></Box>
                          <Typography variant="body2" className="right-selected-empty-text">
                            {NO_DATA}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {leftWidgetList.length > 0 && (
                      <Box className="left-available-pagination-box">
                        <GridPagination
                          activePage={page}
                          itemsCountPerPage={10}
                          pageRange={Math.ceil(allWidgetList?.data?.totalCount / 10)}
                          pageRangeDisplayed={3}
                          onChange={(pageNumber) => setPage(parseInt(pageNumber.selected) + 1)}
                          hideFirstLastPages={true}
                          prevPageText={<img src="/assets/pagination-arrow.svg" alt="prevIcon" />}
                          nextPageText={<img src="/assets/pagination-arrow.svg" alt="nextIcon" />}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Box className="right-setting-box">
                {formik?.values?.widgetConfigName === "news" && newsContentCuration === "automatic" && (
                  <Box className="right-setting-news-sort-box">
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Grid container alignItems="center" spacing={2} sx={{ marginBottom: "8px" }}>
                        <Grid item>
                          <Typography variant="body2">{NEWS_COUNT_TEXT}</Typography>
                        </Grid>
                        <Grid item>
                          <FormControl variant="outlined" sx={{ minWidth: 150 }}>
                            <Select value={newsCount} onChange={handleNewsCountChange} displayEmpty sx={{ height: 35, width: 80, fontSize: 14 }}>
                              {newsCountDropdown()}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
                <Box className="right-view-more-box">
                  {widgetName === "pinned" ? (
                    <FormControl variant="outlined" sx={{ minWidth: "207px" }}>
                      <InputLabel id="color-theme-selection">Theme Name</InputLabel>
                      <Select
                        labelId="color-theme-selection"
                        value={String(formik?.values?.widgetTheme?.themeColor)}
                        label="Theme Name"
                        onChange={handleChangeTheme}
                        displayEmpty
                        sx={{ height: 35 }}
                      >
                        {colorThemeDropdown.map((theme) => (
                          <MenuItem key={theme?.value} value={theme?.value} name={theme?.label}>
                            {theme?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <ViewMoreLink
                      mode="edit"
                      htmlCode={formik?.values?.widgetDashboardContentLink}
                      onChange={handleHTMLTagChange}
                      defaultCode={formik?.values?.widgetDefaultDashboardContentLink}
                    />
                  )}
                </Box>
                {newsContentCuration === "manual" && (
                  <Box sx={{ height: "55px" }}>
                    <Box className="right-selected-box">
                      <Box className="right-selected-heading">{WIDGET_CONTENT_RIGHT_SIDE_HEADER_TEXT}</Box>
                      <Tooltip title={toolTipMessage} arrow>
                        <Box className="right-selected-tooltip">
                          <FaCircleInfo height={16} width={16} color="#4B4B55" />
                        </Box>
                      </Tooltip>
                      <Box className="right-selected-count">{rightWidgetList?.length || 0}</Box>
                    </Box>
                  </Box>
                )}
                {formik?.values?.widgetConfigName !== "key_players" && formik?.values?.widgetConfigName !== "key_players_2"
                  ? newsContentCuration === "manual" && (
                      <Box sx={{ height: "calc(100% - 248px)", overflowX: "hidden", overflowY: "auto" }}>
                        {rightWidgetList?.length > 0 ? (
                          <DraggableList
                            onDragEnd={onDragEnd}
                            widgetList={rightWidgetList}
                            handleArrowClick={handleArrowClick}
                            showTooltip={formik?.values?.widgetConfigName === "signals"}
                          />
                        ) : (
                          <Box className="right-selected-empty-container">
                            <Box className="right-selected-empty-box">
                              <Box component="img" src={EmptyData}></Box>
                              <Typography variant="body2" className="right-selected-empty-text">
                                {NO_DATA}
                              </Typography>
                              <Typography variant="body2" className="right-selected-empty-text">
                                {WIDGET_CONTENT_RIGHT_SIDE_MESSAGE}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )
                  : newsContentCuration === "manual" && (
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={newsTabValue}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList onChange={handleNewsTabChange} aria-label="lab API tabs example">
                              <Tab label="Newly Added" value="1" sx={{ textTransform: "capitalize", fontFamily: "Arial, Helvetica, sans-serif" }} />
                              <Tab
                                label="Recently Updated"
                                value="2"
                                sx={{ textTransform: "capitalize", fontFamily: "Arial, Helvetica, sans-serif" }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel value="1" className="custom-widget-tabs">
                            {newlyAddedWidgetList?.length > 0 ? (
                              <DraggableList
                                onDragEnd={onDragEndKeyPlayer}
                                widgetList={newlyAddedWidgetList || []}
                                handleArrowClick={handleArrowClick}
                              />
                            ) : (
                              <Box className="right-selected-empty-container">
                                <Box className="right-selected-empty-box">
                                  <Box component="img" src={EmptyData}></Box>
                                  <Typography variant="body2" className="right-selected-empty-text">
                                    {NO_DATA}
                                  </Typography>
                                  <Typography variant="body2" className="right-selected-empty-text">
                                    {WIDGET_CONTENT_RIGHT_SIDE_MESSAGE}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </TabPanel>
                          <TabPanel value="2" className="custom-widget-tabs">
                            {recentlyUpdatedWidgetList?.length > 0 ? (
                              <DraggableList
                                onDragEnd={onDragEndKeyPlayer}
                                widgetList={recentlyUpdatedWidgetList || []}
                                handleArrowClick={handleArrowClick}
                              />
                            ) : (
                              <Box className="right-selected-empty-container">
                                <Box className="right-selected-empty-box">
                                  <Box component="img" src={EmptyData}></Box>
                                  <Typography variant="body2" className="right-selected-empty-text">
                                    {NO_DATA}
                                  </Typography>
                                  <Typography variant="body2" className="right-selected-empty-text">
                                    {WIDGET_CONTENT_RIGHT_SIDE_MESSAGE}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </TabPanel>
                        </TabContext>
                      </Box>
                    )}
              </Box>
            </Box>
            <Box
              className={
                splitConfig?.[widgetName]?.[resolution]?.[formik?.values?.widgetUniqueName]?.isMerged ? "preview-content-merge" : "preview-content"
              }
              style={{ overflowY: splitConfig[formik?.values?.widgetUniqueName]?.isMerged ? "auto" : "hidden" }}
            >
              {widgetName !== "pinned" && formik?.values?.widgetConfigName && (
                <div className={"dashboard-preview-wrapper"}>
                  {renderComponent(formik?.values?.widgetConfigName, "dashboard", {
                    data: previewData,
                    widgetData: {
                      widgetDisplayName: formik?.values?.widgetDisplayName,
                      widgetDashboardContentLink: formik?.values?.widgetDashboardContentLink,
                    },
                    hideHeader: true,
                  })}
                </div>
              )}
              {widgetName === "pinned" && previewData?.length > 0 && (
                <div
                  className={
                    splitConfig?.[widgetName]?.[resolution]?.[formik?.values?.widgetUniqueName]?.isMerged
                      ? "pinned-content-merged-wrapper"
                      : "pinned-content-wrapper"
                  }
                >
                  <PinnedContent
                    isMerge={splitConfig?.[widgetName]?.[resolution]?.[formik?.values?.widgetUniqueName]?.isMerged}
                    data={previewData}
                    selectedTheme={formik?.values?.widgetTheme?.themeColor}
                    widgetThemeData={widgetThemeData?.data?.data}
                    hideHeader={true}
                  />
                </div>
              )}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </SidebarModal>
  );
};

export default CustomizableWidgetSidebar;
