import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { swap, getLoggedInUser, isAdmin, getProductVariant, fetchTechTrendData } from "../../../../utilities";
import CommonDataGrid from "../../../components/dataGrid";
import { StringFilter, DropDownFilter } from "../../../components/dataGrid/fliters";
import {
  fetchCompany,
  deleteCompany,
  deleteManyCompany,
  saveCompanyTaxonomyTags,
  saveValueChainTags,
  saveCompanyKiaTags,
  saveCompanyTrendTags,
  saveCompanyBusinessObjectiveTaxonomy,
} from "../../../../middleware/services/companyApi";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import ThreeDotMenu from "../../../components/threeDotMenu";
import { setMaintainedState, visitRecord } from "../../../../middleware/actions/gridAction";
import { COMMON_ACCESS_ACTION, PRODUCT_VARIANT } from "../../../../constants";
import { resetRedux, setFilteredTaxonomy } from "../../../../middleware/actions/taxonomyFilterAction";
import {
  setInitialCheckedKiaNodes,
  setInitialCheckedObjectiveNodes,
  setInitialCheckedSgfNodes,
  setInitialCheckedTechnologyNodes,
  setInitialCheckedTrendNodes,
  setInitialCheckedValueChainNodes,
  setKiaCheckedNodes,
  setKiaUnCheckedNodes,
  setObjectiveTaxonomyCheckedNodes,
  setObjectiveTaxonomyUnCheckedNodes,
  setResetRedux,
  setTaxonomyCheckedNodes,
  setTaxonomyUnCheckedNodes,
  setTransformingCheckedNodes,
  setTransformingUnCheckedNodes,
  setTrendCheckedNodes,
  setTrendUnCheckedNodes,
  setValueChainCheckedNodes,
  setValueChainUnCheckedNodes,
} from "../../../../middleware/actions/taxonomyTaggingAction";

const QUEUE_STATUS = {
  QC_FRESH: "QC - Fresh",
  QC_REJECTED: "QC - Rejected",
  QC_ACCEPTED: "QC - Complete",
  PUBLISHED: "Published",
};
// company dashboard component
const CompanyDataQC = (props) => {
  const { accessRights: aR } = props;
  const [techTrendData, setTechTrendData] = useState(undefined);
  const productVariant = getProductVariant();
  const breadCrumbLinks = [{ linkUrl: "/data-qc", linkName: aR.moduleName, linkActive: true }];
  const [deleteId, setDeleteId] = useState(undefined);
  const history = useHistory();
  const loggedInUser = getLoggedInUser();
  const dispatch = useDispatch();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const gridState = useSelector((state) => state.gridState);
  const taxonomyFilterRedux = useSelector((state) => state.taxonomyFilterState);
  const [filterData, setFilterData] = useState(taxonomyFilterRedux?.filteredTaxonomy ? taxonomyFilterRedux.filteredTaxonomy : []);
  const [taxonomyTaggingTabName, setTaxonomyTaggingTabName] = useState(false);
  const [initalState, setInitialState] = useState([]);
  const [bulkCheck, setBulkCheck] = useState([]);
  const [showTaxonomyBulk, setShowTaxonomyBulk] = useState(false);
  const [cssClass, setCssClass] = useState("treeClass");
  const [listClass, setListClass] = useState("select-lists");
  const [taxonomyState, setTaxonomyState] = useState([]);
  const [taxonomyTransformationState, setTaxonomyTransformationState] = useState([]);
  const companyTaxonomy = useSelector((state) => state.taxonomyTaggingState);
  const [valueChainData, setValueChainData] = useState([]);

  /* Common access */
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionFilterByTaxonomy = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.FILTER_BY_TAXONOMY);
  const accessActionTaxonomyBulkTagging = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.TAXONOMY_BULK_TAGGING);

  // Reset Reux
  useEffect(() => {
    const locationPath = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];
    if (locationPath != `/data-qc/company`) {
      setFilterData([]);
      dispatch(setFilteredTaxonomy([]));
      dispatch(resetRedux());
      localStorage.setItem("locationPath", window.location.pathname);
    }
  }, []);

  //fetch records
  const fetchQcRecord = (params = {}) => {
    let isExist = [];
    let defaultFilter = [];
    let { filters = [] } = params;
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters) {
      filters = params.filters.map((item) => {
        const columnName = swap(QUEUE_STATUS);
        const tech = techTrendData.filter((f) => {
          return ["companyInQueue.trendId", "companyInQueue.dtId"].includes(item[0]) && f.text === item[2];
        });
        if (productVariant === "Insider") {
          return item[0] === "companyInQueue.queueStatus"
            ? [item[0], item[1], columnName[`${item[2]}`]]
            : item[0] === "companyInQueue.trendId"
            ? [item[0], "in", [tech[0].id]]
            : item;
        } else {
          return item[0] === "companyInQueue.queueStatus"
            ? [item[0], item[1], columnName[`${item[2]}`]]
            : item[0] === "companyInQueue.dtId"
            ? [item[0], "in", [tech[0].id]]
            : item;
        }
      });
      isExist = params.filters.filter((item) => {
        return item[0] === "companyInQueue.queueStatus";
      });
    }

    if (!isExist.length) {
      defaultFilter = isAdmin()
        ? [["companyInQueue.queueStatus", "in", ["PUBLISHED", "QC_FRESH", "QC_REJECTED", "QC_ACCEPTED"]]]
        : [
            ["companyInQueue.queueStatus", "in", ["PUBLISHED", "QC_FRESH", "QC_REJECTED", "QC_ACCEPTED"]],
            "and",
            ["companyInQueue.allocatedUserId", "eq", loggedInUser._id],
          ];
    } else {
      defaultFilter = !isAdmin() ? [["companyInQueue.allocatedUserId", "eq", loggedInUser._id]] : [];
    }

    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...filters, ...defaultFilter];
    localStorage.removeItem("selectedCompanies");
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchCompany(params);
    }
    return fetchCompany(params);
  };

  // console.log('current logged in User', loggedInUser)
  // specify column names for column fields in datGrid here
  const columnNames = {
    id: "id",
    Name: "companyName",
    Website: "companyWebsite",
    // Technology: "companyTechnologyTags~dtParentId:0,dtName",
    "Country (HQ)": "companyLocation~type:Headquarter,countryName",
    Founded: "companyFoundedYear",
    "Employee Size": "companyEmpSize",
    "Queue Status": "companyInQueue.queueStatus",
    Startup: "companyIsStartup",
    // "Is Live": "companyIsLive",
  };
  if (productVariant) columnNames["Is Live"] = `productVariant~productName:${productVariant},isLive`;
  else columnNames["Is Live"] = "productVariant.isLive";

  if (productVariant && productVariant === "Insider") columnNames["Trend"] = "companyInQueue.trendId";
  else columnNames["Technology"] = "companyInQueue.dtId";

  const companyNameTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(`/data-qc/company/${value.id}/edit#basic`, { companyId: value.id, companyName: value.Name });
          dispatch(visitRecord());
        }}
      >
        {value.Name}
      </span>
    );
  };

  /* 
        @Description : Template Component for action column
    */
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? { Edit: () => history.push(`/data-qc/company/${value.id}/edit#basic`, { companyId: value.id, companyName: value.Name }) }
          : {})}
        {...(accessActionDelete
          ? {
              Delete: async () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteCompany(value.id)
                        .then((response) => {
                          if (deleteId) setDeleteId(undefined);
                          setDeleteId(true);
                          dispatch(actionSuccess("Company deleted successfully"));
                          let filters = [];

                          if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
                          fetchCompany({ filters: filters }).then((company) => {
                            formatRowData(company.data.data);
                          });
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Fail to delete company"));
                        });
                    },
                  })
                );
              },
            }
          : {})}
      />
    );
  };
  const companyWebsiteTemplate = (value) => {
    return (
      <a
        href={value.Website ? (value.Website.startsWith("http") ? value.Website : "http://" + value.Website) : null}
        target="_blank"
        data-tip="Open Website"
      >
        {value.Website}
        {value.Website ? <i className="fas fa-external-link-alt pl-2"></i> : null}
      </a>
    );
  };
  // Dropdown Filter template
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Startup":
        sourceData = [
          { id: "-", text: "Blanks" },
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Is Live":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Queue Status":
        sourceData = Object.keys(QUEUE_STATUS)
          .map((key) => {
            if (!["SCREENING_FRESH", "SCREENING_REJECTED", "SCREENING_HOLD"].includes(key)) {
              return { id: QUEUE_STATUS[key], text: QUEUE_STATUS[key] };
            }
          })
          .filter((item) => {
            return item !== undefined;
          });
        break;
      case "Employee Size":
        sourceData = [
          { id: "-", text: "Blanks" },
          { id: "1 - 10", text: "1 to 10" },
          { id: "11 - 50", text: "11 to 50" },
          { id: "51 - 100", text: "51 to 100" },
          { id: "101 - 500", text: "101 to 500" },
          { id: "501 - 1000", text: "501 to 1000" },
          { id: "1001 - 5000", text: "1001 to 5000" },
          { id: "5001 - 10000", text: "5001 to 10000" },
          { id: "10000+", text: "10000+" },
        ];
        break;
      case "Technology":
        sourceData = techTrendData;
        break;
      case "Trend":
        sourceData = techTrendData;
        break;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean
  const columnFields = [
    { field: "id", type: "string", visible: false, allowFiltering: false, showInColumnChooser: false, isPrimaryKey: true },
    {
      field: "Name",
      type: "String",
      template: companyNameTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 160,
    },
    {
      field: "Website",
      type: "String",
      template: companyWebsiteTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 170,
    },
    {
      field: `${productVariant && productVariant === "Insider" ? "Trend" : "Technology"}`,
      type: "String",
      filter: { operator: "contains" },
      filterTemplate: ItemFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 200,
    },
    { field: "Country (HQ)", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 150 },
    { field: "Founded", type: "String", filterTemplate: StringFilter, textAlign: "Center", headerTextAlign: "Center", width: 150 },
    { field: "Employee Size", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 170 },
    { field: "Startup", type: "String", filterTemplate: ItemFilter, textAlign: "Center", headerTextAlign: "Center", width: 140 },
    { field: "Is Live", type: "String", filterTemplate: ItemFilter, textAlign: "Center", headerTextAlign: "Center", width: 120 },
    {
      field: "Queue Status",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 170,
    },
  ];

  if (accessActionEdit || accessActionDelete) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      allowSorting: false,
      freeze: "Right",
    });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
      });
      if (productVariant && productVariant === "Insider") {
        if (rD.companyInQueue.trendId && rD.companyInQueue.trendId.length) {
          const ids = rD.companyInQueue.trendId;

          const trendData = techTrendData.filter((item) => {
            return ids.includes(item.id);
          });
          newRD.Trend = trendData
            .map((item) => {
              return item.text;
            })
            .filter((trend, index, self) => trend != "" && self.indexOf(trend) === index)
            .join(", ");
        } else newRD.Trend = "";
      } else {
        if (rD.companyInQueue.dtId && rD.companyInQueue.dtId.length) {
          const ids = rD.companyInQueue.dtId;

          const techData = techTrendData.filter((item) => {
            return ids.includes(item.id);
          });
          newRD.Technology = techData
            .map((item) => {
              return item.text;
            })
            .filter((tech, index, self) => tech != "" && self.indexOf(tech) === index)
            .join(", ");
        } else newRD.Technology = "";
      }
      if (rD.companyLocation && rD.companyLocation.length) {
        const hq = rD.companyLocation.filter((nD) => nD.type === "Headquarter");
        newRD["Country (HQ)"] = hq.length ? hq[0].countryName : "";
      } else {
        newRD["Country (HQ)"] = "";
      }
      newRD["Queue Status"] = QUEUE_STATUS[rD.companyInQueue.queueStatus] || "";

      if (rD.productVariant?.length) {
        if (productVariant) {
          const rDPV = rD.productVariant.find((pV) => pV.productName === productVariant);
          newRD["Is Live"] = rDPV?.isLive ? rDPV.isLive : "NO";
        } else newRD["Is Live"] = rD.productVariant[0].isLive ? rD.productVariant[0].isLive : "NO";
      } else newRD["Is Live"] = "NO";
      return newRD;
    });
    return formatedRowData;
  };
  const fetchTechData = async () => {
    setTechTrendData(await fetchTechTrendData(productVariant));
  };

  useEffect(() => {}, [formatRowData]);
  useEffect(() => {
    localStorage.removeItem("selectedCompanies");
    if (!techTrendData) fetchTechData();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
      localStorage.removeItem("selectedCompanies");
    };
  }, [dispatch]);

  const deleteManyFunc = (flag) => {
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    if (flag) {
      if (selectedCompanies.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteManyCompany({ companyIds: selectedCompanies })
                .then((response) => {
                  if (response) {
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    const params = {};
                    params.limit = 100;

                    if (productVariant) params.filters = [["productVariant.productName", "eq", productVariant]];
                    fetchCompany(params).then((company) => {
                      formatRowData(company.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete company"));
                });
            },
            content: "Are you sure to delete, selected record?",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };
  let tabNames;
  if (productVariant == PRODUCT_VARIANT.INSIDER) {
    tabNames = taxonomyTaggingTabName ? ["Tag Industry"] : ["By Industry"];
  } else {
    tabNames = taxonomyTaggingTabName
      ? ["Tag Technology", "Tag Key Impact Area", "Tag Value Chain", "Tag Business Objective"]
      : ["By Technology", "By Key Impact Area", "By Value Chain", "By Business Objective"];
  }

  /* 
    @Description : Bulk tagging technology tab
    */
  const handleSubmitTechnology = async (fetchGridData) => {
    let payload = {
      companyIds: initalState,
      taxonomyTagging: companyTaxonomy.taxonomyCheckedNodes,
      taxonomyUnTagging: companyTaxonomy.taxonomyUnCheckedNodes,
    };

    if (companyTaxonomy?.initialTechnologyCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              dispatch(showLoader());
              await saveCompanyTaxonomyTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  dispatch(hideLoader());
                  setShowTaxonomyBulk(false);
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(setResetRedux());
                  dispatch(
                    actionError(
                      "You can not remove taxonomy from the company as there are products exits in this company. You need to remove taxonomy to product."
                    )
                  );
                  setShowTaxonomyBulk(false);
                });
            },
            okText: "Submit",
            cancelText: "Cancel",
            content: "Are you sure you want to update the tagging?",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something went wrong...!"));
      }
    }
  };

  const fetchTechnologyTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    if (selectedCompanies.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedCompanies],
      ];
      params.fields = ["companyTechnologyTags"];
      const contentTaxonomy = fetchCompany(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.companyTechnologyTags.length > 0 &&
              value.companyTechnologyTags.map((v) => {
                checkNodes.push(v.dtId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });
            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedCompanies.length == d[1]) {
                commonIds.push(d[0]);
              }
            });
            setBulkCheck([...companyTaxonomy.taxonomyCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTaxonomyCheckedNodes([...companyTaxonomy.taxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const fetchValuchainData = (data, selectedCompanies) => {
    if (data.length) {
      let valueChainIds = [];
      let commanIds = [];
      let taxonomyTee = [];
      data.map((value) => {
        value.companyIndustryTag.length > 0 &&
          value.companyIndustryTag.map((item) => {
            if (!valueChainIds.includes(item.industryId)) {
              let taxonomyTeeData = {};
              taxonomyTeeData["id"] = item.industryId;
              taxonomyTeeData["name"] = item.industryName;
              taxonomyTee.push(taxonomyTeeData);
            }
            valueChainIds.push(item.industryId);
          });
      });
      let count = _.countBy(valueChainIds);
      let countarray = Object.entries(count);
      countarray.map((d) => {
        if (selectedCompanies.length == d[1]) {
          commanIds.push(d[0]);
        }
      });
      let valueChainData = _.filter(taxonomyTee, function (element) {
        return _.find(commanIds, function (name) {
          return element.id === name;
        });
      });
      setValueChainData(valueChainData);
    }
  };

  const taxonomyBulkTagging = async () => {
    dispatch(visitRecord());
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    if (selectedCompanies.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedCompanies],
      ];
      params.fields = ["companyIndustryTag"];
      const companyIsLive = await fetchCompany(params);
      dispatch(hideLoader());
      if (companyIsLive) {
        dispatch(showLoader());
        setInitialState(selectedCompanies);
        setShowTaxonomyBulk(true);
        companyIsLive?.data?.data && fetchValuchainData(companyIsLive.data.data, selectedCompanies);
      }
    } else {
      dispatch(actionError("Please select company."));
    }
  };

  const addTaxonomyCheckedNodes = (id) => {
    let taxonomyCheck = companyTaxonomy.taxonomyCheckedNodes;
    let taxonomyUnCheck = companyTaxonomy.taxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = companyTaxonomy.initialTechnologyCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTechnologyNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTechnologyNodes([...companyTaxonomy.initialTechnologyCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTaxonomyUnCheckedNodes([...companyTaxonomy.taxonomyUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTaxonomyCheckedNodes([...companyTaxonomy.taxonomyCheckedNodes, id]));
  };

  /* 
        @Description : Bulk tagging for kia
    */
  const handleSubmitKia = async (fetchGridData) => {
    let payload = {
      companyIds: initalState,
      taxonomyTagging: companyTaxonomy.kiaCheckedNodes,
      taxonomyUnTagging: companyTaxonomy.kiaUnCheckedNodes,
    };

    try {
      dispatch(
        showAlertBox({
          okCallback: () => {
            process.nextTick(() => {
              dispatch(showLoader());
              saveCompanyKiaTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  setShowTaxonomyBulk(false);
                  dispatch(setResetRedux());
                  dispatch(
                    actionError(
                      "The taxonomy of the company cannot be removed since it includes products. Please remove the product's taxonomy first."
                    )
                  );
                });
            });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    } catch (error) {
      dispatch(actionError("Something went wrong...!"));
    }
  };

  const fetchKiaTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    if (selectedCompanies.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedCompanies],
      ];
      params.fields = ["companyKiaTags"];
      const contentTaxonomy = fetchCompany(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.companyKiaTags.length > 0 &&
              value.companyKiaTags.map((v) => {
                checkNodes.push(v.kiaId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedCompanies.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            commonIds.map((element) => {
              value.companyKiaTags.length > 0 &&
                value.companyKiaTags.map((v) => {
                  if (v.kiaId === element) {
                    commonIds.push(v.kiaIndustryId);
                  }
                });
            });
            commonIds = [...new Set(commonIds)];
            setBulkCheck([...companyTaxonomy.kiaCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setKiaCheckedNodes([...companyTaxonomy.kiaCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addKiaCheckedNodes = (id) => {
    let taxonomyCheck = companyTaxonomy.kiaCheckedNodes;
    let taxonomyUnCheck = companyTaxonomy.kiaUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = companyTaxonomy.initialKiaCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedKiaNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedKiaNodes([...companyTaxonomy.initialKiaCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setKiaCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setKiaUnCheckedNodes([...companyTaxonomy.kiaUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setKiaUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setKiaCheckedNodes([...companyTaxonomy.kiaCheckedNodes, id]));
  };
  /* 
        @Description : Bulk tagging Value chain
    */
  const handleSubmitValueChain = async (fetchGridData) => {
    let payload = {
      companyIds: initalState,
      taxonomyTagging: companyTaxonomy.valueChainCheckedNodes,
      taxonomyUnTagging: companyTaxonomy.valueChainUnCheckedNodes,
      transformingTechnologiesIds: companyTaxonomy.transformingCheckedNodes,
      transformingUnCheckedTechnologiesIds: companyTaxonomy.transformingUnCheckedNodes,
    };

    if (companyTaxonomy?.initialValueChainCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      try {
        dispatch(
          showAlertBox({
            okCallback: () => {
              process.nextTick(() => {
                dispatch(showLoader());
                saveValueChainTags(payload)
                  .then((result) => {
                    dispatch(actionSuccess("Taxonomy updated successfully"));
                    dispatch(setResetRedux());
                    setShowTaxonomyBulk(false);
                    dispatch(hideLoader());
                    fetchGridData();
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    setShowTaxonomyBulk(false);
                    dispatch(setResetRedux());
                    dispatch(
                      actionError(
                        "The taxonomy of the company cannot be removed since it includes products. Please remove the product's taxonomy first."
                      )
                    );
                  });
              });
            },
            okText: "Submit",
            cancelText: "Cancel",
            content: "Are you sure you want to update the tagging?",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something went wrong...!"));
      }
    }
  };

  const fetchValueChainTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    if (selectedCompanies.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedCompanies],
      ];
      params.fields = ["companyIndustryValuechainTags"];
      const contentTaxonomy = fetchCompany(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let checkNodes2 = [];
          let count;
          let count2;
          res.data.data.map((value) => {
            value.companyIndustryValuechainTags.length > 0 &&
              value.companyIndustryValuechainTags.map((v) => {
                checkNodes.push(v.valueChainId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }

                // transformingTechnologies id
                v.transformingTechnologies &&
                  v.transformingTechnologies.map((ele) => {
                    checkNodes2.push(ele.techId);
                    count2 = {};
                    for (const element of checkNodes2) {
                      if (count2[element]) {
                        count2[element] += 1;
                      } else {
                        count2[element] = 1;
                      }
                    }
                  });
              });
            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedCompanies.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            let transformingtechnology = count2 && Object.entries(count2);
            let transformingtechnologyCommanId = [];
            transformingtechnology &&
              transformingtechnology.map((d) => {
                if (selectedCompanies.length == d[1]) {
                  transformingtechnologyCommanId.push(d[0]);
                }
              });

            setBulkCheck([
              ...companyTaxonomy.valueChainCheckedNodes,
              ...commonIds,
              ...companyTaxonomy.transformingCheckedNodes,
              ...transformingtechnologyCommanId,
            ]);
            dispatch(setValueChainCheckedNodes([...companyTaxonomy.valueChainCheckedNodes, ...commonIds]));
            setTaxonomyState([...commonIds]);
            setTaxonomyTransformationState([...transformingtechnologyCommanId]);
            dispatch(setTransformingCheckedNodes([...companyTaxonomy.transformingCheckedNodes, ...transformingtechnologyCommanId]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addValueChainCheckedNodes = (id) => {
    let taxonomyCheck = companyTaxonomy.valueChainCheckedNodes;
    let taxonomyUnCheck = companyTaxonomy.valueChainUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = companyTaxonomy.initialValueChainCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedValueChainNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedValueChainNodes([...companyTaxonomy.initialValueChainCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setValueChainCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setValueChainUnCheckedNodes([...companyTaxonomy.valueChainUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setValueChainUnCheckedNodes(taxonomyUnCheck));
    }

    dispatch(setValueChainCheckedNodes([...companyTaxonomy.valueChainCheckedNodes, id]));
  };

  const addValueChainTransformingCheckedNodes = (id) => {
    let taxonomyCheck = companyTaxonomy.transformingCheckedNodes;
    let taxonomyUnCheck = companyTaxonomy.transformingUnCheckedNodes;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTransformingCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTransformingUnCheckedNodes([...companyTaxonomy.transformingUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTransformingUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTransformingCheckedNodes([...companyTaxonomy.transformingCheckedNodes, id]));
  };

  /* 
        @Description : Bulk tagging trend sgf - Insider
    */
  const handleSubmitTrend = async (fetchGridData) => {
    let payload = {
      companyIds: initalState,
      taxonomyTagging: companyTaxonomy.trendCheckedNodes,
      taxonomyUnTagging: companyTaxonomy.trendUnCheckedNodes,
      sgfIds: companyTaxonomy.initialSgfCheckedNodes,
    };

    if (companyTaxonomy?.initialTrendCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              dispatch(showLoader());
              await saveCompanyTrendTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  setShowTaxonomyBulk(false);
                  dispatch(setResetRedux());
                  dispatch(
                    actionError(
                      "You can not remove taxonomy from the company as there are products exits in this company. You need to remove taxonomy to product."
                    )
                  );
                });
            },
            okText: "Submit",
            cancelText: "Cancel",
            content: "Are you sure you want to update the tagging?",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something went wrong...!"));
      }
    }
  };

  const fetchTrendTagging = () => {
    setCssClass("treeClass trend");
    setListClass("select-lists bulktagging");
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    if (selectedCompanies.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedCompanies],
      ];
      params.fields = ["companyIndustryTrendsTags"];
      const contentTaxonomy = fetchCompany(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.companyIndustryTrendsTags.length > 0 &&
              value.companyIndustryTrendsTags.map((v) => {
                checkNodes.push(v.trendId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });
            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedCompanies.length == d[1]) {
                commonIds.push(d[0]);
              }
            });
            setBulkCheck([...companyTaxonomy.trendCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTrendCheckedNodes([...companyTaxonomy.trendCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const trendBulkTagging = async () => {
    dispatch(visitRecord());
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    if (selectedCompanies.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedCompanies],
      ];
      const companyIsLive = await fetchCompany(params);
      dispatch(hideLoader());
      if (companyIsLive) {
        dispatch(showLoader());
        setInitialState(selectedCompanies);
        setShowTaxonomyBulk(true);
      }
    } else {
      dispatch(actionError("Please select company."));
    }
  };

  const addTrendCheckedNodes = (taxonomyId) => {
    let id = taxonomyId.split("-")[1];
    let sgfId = taxonomyId.split("-")[0];
    let taxonomyCheck = companyTaxonomy.trendCheckedNodes;
    let taxonomyUnCheck = companyTaxonomy.trendUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = companyTaxonomy.initialTrendCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTrendNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTrendNodes([...companyTaxonomy.initialTrendCheckedNodes, id]));

    // set checked sgf node
    dispatch(setInitialCheckedSgfNodes([...companyTaxonomy.initialSgfCheckedNodes, sgfId]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTrendCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTrendUnCheckedNodes([...companyTaxonomy.trendUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTrendUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTrendCheckedNodes([...companyTaxonomy.trendCheckedNodes, id]));
  };

  /* 
    @Description : Bulk tagging for business objective
    */
  const handleSubmitObjective = async (fetchGridData) => {
    let taxonomy = {
      companyIds: initalState,
      companyObjectiveTaxonomy: companyTaxonomy.objectiveTaxonomyCheckedNodes,
      companyUnCheckObjectiveTaxonomy: companyTaxonomy.objectiveTaxonomyUnCheckedNodes,
    };

    if (companyTaxonomy?.initialObjectiveCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      try {
        dispatch(
          showAlertBox({
            okCallback: () => {
              process.nextTick(() => {
                dispatch(showLoader());
                saveCompanyBusinessObjectiveTaxonomy(taxonomy)
                  .then((result) => {
                    dispatch(actionSuccess("Taxonomy updated successfully"));
                    dispatch(setResetRedux());
                    setShowTaxonomyBulk(false);
                    dispatch(hideLoader());
                    fetchGridData();
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    dispatch(setResetRedux());
                    setShowTaxonomyBulk(true);
                    dispatch(actionError("Please select company"));
                  });
              });
            },
            okText: "Submit",
            cancelText: "Cancel",
            content: "Are you sure you want to update the tagging?",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something went wrong...!"));
      }
    }
  };

  const fetchObjectiveTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    if (selectedCompanies?.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedCompanies],
      ];
      params.fields = ["companyObjectiveTaxonomy"];
      const contentTaxonomy = fetchCompany(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.companyObjectiveTaxonomy.length > 0 &&
              value.companyObjectiveTaxonomy.map((v) => {
                checkNodes.push(v.taxonomyId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedCompanies.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            setBulkCheck([...companyTaxonomy.objectiveTaxonomyCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setObjectiveTaxonomyCheckedNodes([...companyTaxonomy.objectiveTaxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addObjectiveCheckedNodes = (id) => {
    let taxonomyCheck = companyTaxonomy.objectiveTaxonomyCheckedNodes;
    let taxonomyUnCheck = companyTaxonomy.objectiveTaxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = companyTaxonomy.initialObjectiveCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedObjectiveNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedObjectiveNodes([...companyTaxonomy.initialObjectiveCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setObjectiveTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setObjectiveTaxonomyUnCheckedNodes([...companyTaxonomy.objectiveTaxonomyUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setObjectiveTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setObjectiveTaxonomyCheckedNodes([...companyTaxonomy.objectiveTaxonomyCheckedNodes, id]));
  };
  // handle save for taxonomy bulk tagging
  const handleSave = (selectedIndex, fetchGridData) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        handleSubmitTechnology(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitKia(fetchGridData);
      }
      if (selectedIndex == 2) {
        handleSubmitValueChain(fetchGridData);
      }
      if (selectedIndex == 3) {
        handleSubmitObjective(fetchGridData);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        handleSubmitTrend(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitObjective(fetchGridData);
      }
    }
  };

  const handleTaxonomyTagging = (args, selectedIndex) => {
    let valueId = args?.data[0].id;
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        addTaxonomyCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addKiaCheckedNodes(valueId);
      }
      if (selectedIndex == 2) {
        handleValueChainValidation(args);
      }
      if (selectedIndex == 3) {
        addObjectiveCheckedNodes(valueId);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        addTrendCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addObjectiveCheckedNodes(valueId);
      }
    }
  };

  const handleValueChainValidation = (args) => {
    if (args?.data[0]?.parentID == null) {
      addValueChainCheckedNodes(args?.data[0]?.id);
    }
    if (args?.data[0]?.parentID !== null) {
      addValueChainTransformingCheckedNodes(args?.data[0]?.id);
    }
  };

  // fetch TaxonomyTagging data for taxonomy bluk tagging
  const fetchTaxonomyTagging = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        fetchTechnologyTagging();
      }
      if (selectedIndex == 1) {
        fetchKiaTagging();
      }
      if (selectedIndex == 2) {
        fetchValueChainTagging();
      }
      if (selectedIndex == 3) {
        fetchObjectiveTagging();
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        fetchTrendTagging();
      }
      if (selectedIndex == 1) {
        fetchObjectiveTagging();
      }
    }
  };

  const setBulkCheckedNode = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      selectedIndex == 0 && setBulkCheck([...companyTaxonomy.taxonomyCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...companyTaxonomy.kiaCheckedNodes]);
      selectedIndex == 2 && setBulkCheck([...companyTaxonomy.valueChainCheckedNodes, ...companyTaxonomy.transformingCheckedNodes]);
      selectedIndex == 3 && setBulkCheck([...companyTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      selectedIndex == 0 && setBulkCheck([...companyTaxonomy.trendCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...companyTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
  };

  const resetAllTaxonnomy = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        dispatch(setTaxonomyCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setKiaCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 2) {
        dispatch(setValueChainCheckedNodes([...taxonomyState]));
        dispatch(setTransformingCheckedNodes([...taxonomyTransformationState]));
      }
      if (selectedIndex == 3) {
        dispatch(setObjectiveTaxonomyCheckedNodes([...taxonomyState]));
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        dispatch(setTrendCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setObjectiveTaxonomyCheckedNodes([...taxonomyState]));
      }
    }
    setBulkCheck([...taxonomyState]);
  };
  const locationPathName = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];
  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        {techTrendData && (
          <CommonDataGrid
            gridTitle="Company Data QC Records"
            fetch={fetchQcRecord}
            deleteOne={deleteCompany}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            deleteId={deleteId}
            getMultiSelectedRows={(data) => {
              localStorage.setItem("selectedCompanies", JSON.stringify([...data]));
            }}
            getMultiDeSelectedRows={(data) => {
              localStorage.setItem("selectedCompanies", JSON.stringify([...data]));
            }}
            deleteRight={true}
            deleteMany={accessActionDelete ? deleteManyFunc : null}
            addNew={() => {
              history.push("/data-qc/company/add#basic");
            }}
            showFilterByTaxonomy={productVariant !== PRODUCT_VARIANT.CHEERSIN ? true : false && accessActionFilterByTaxonomy}
            // moduleName={moduleName}
            boTagging={true}
            tabName={tabNames}
            handleTaxonomyTabName={setTaxonomyTaggingTabName}
            customDialogBoxClass="dialoagBoxClass"
            customArrowClass="arrow-top"
            setFilterData={setFilterData}
            filterData={filterData}
            //bulk tagging
            checked={bulkCheck}
            setBulkCheck={setBulkCheck}
            showTaxonomyBulk={showTaxonomyBulk}
            setShowTaxonomyBulk={setShowTaxonomyBulk}
            taxonomyBulkTagging={taxonomyBulkTagging}
            trendBulkTagging={trendBulkTagging}
            cssClass={cssClass}
            setCssClass={setCssClass}
            listClass={listClass}
            setListClass={setListClass}
            bulkTaggingArrowTop="bulkTaggingArrowTop"
            handleSubmit={handleSave}
            handleTaxonomyTagging={handleTaxonomyTagging}
            fetchTaxonomyTagging={fetchTaxonomyTagging}
            setBulkCheckedNode={setBulkCheckedNode}
            resetAllTaxonnomy={resetAllTaxonnomy}
            accessPermissionBulk={accessActionTaxonomyBulkTagging}
            clearFilterByTaxonomy={locationPathName == "/data-qc/company" ? true : false}
            valueChainData={valueChainData}
          />
        )}
      </div>
    </div>
  );
};

export default CompanyDataQC;
