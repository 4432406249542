/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : User Routes (Client, External, Internal, Vendor)
----------------------------------------------------------------
	Creation Details
	Date Created				: 30/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import ManageTemplates from "../app/views/deliverables/manageTemplates";
const AddPage = React.lazy(() => import("../app/views/deliverables/manageTemplates/add"));

const ADD = ["Add:FULL"];
const DELETE = ["Delete:FULL"];
const EDIT = ["Edit:FULL"];

const templateRoutes = () => (
  <Suspense fallback={<div className="text-center">Loading...</div>}>
    <Switch>
      <AuthLayout
        exact
        path="/template"
        component={ManageTemplates}
        pageHeading="GenNx CMS - Deliverables - Manage Templates"
        accessRights={{
          moduleName: "Manage Templates",
          parentModuleName: "Deliverables & Templates",
          moduleAttribute: [...ADD, ...DELETE, ...EDIT],
          interfaceName: "Listing",
        }}
      />
      <AuthLayout
        exact
        path="/template/add/:templateId"
        component={AddPage}
        pageHeading="GenNx CMS - Deliverables - Manage Templates"
        accessRights={{
          moduleName: "Manage Templates",
          parentModuleName: "Deliverables & Templates",
          moduleAttribute: [...ADD, ...DELETE, ...EDIT],
          interfaceName: "Basic",
        }}
      />
      <AuthLayout
        exact
        path="/template/edit/:templateId"
        component={AddPage}
        pageHeading="GenNx CMS - Deliverables - Manage Templates"
        accessRights={{
          moduleName: "Manage Templates",
          parentModuleName: "Deliverables & Templates",
          moduleAttribute: [...ADD, ...DELETE, ...EDIT],
          interfaceName: "Basic",
        }}
      />
    </Switch>
  </Suspense>
);

export default templateRoutes;
