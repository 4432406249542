import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonDataGrid from "../../components/dataGrid";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { fetchCustomForms, deleteCustomForm, fetchConfig, updateCustomForms } from "../../../middleware/services/cmsApi";
import { swap, getLoggedInUser, getProductVariant } from "../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import ThreeDotMenu from "../../components/threeDotMenu";
import { setMaintainedState, visitRecord } from "../../../middleware/actions/gridAction";
import { COMMON_ACCESS_ACTION } from "../../../constants";

const CustomForms = (props) => {
  const { accessRights: aR } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const productVariant = getProductVariant();
  const loggedInUser = getLoggedInUser();
  const [deleteId, setDeleteId] = useState(undefined);
  const [formPlaceholder, setPlaceholder] = useState([]);

  /* Common access */
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  const gridState = useSelector((state) => state.gridState);
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const breadCrumbLinks = [{ linkUrl: "/custom-forms", linkName: aR.moduleName, linkActive: true }];
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/custom-forms"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  const updateVisibilty = async (formId, payload) => {
    await updateCustomForms(formId, payload)
      .then((res) => {
        if (res) {
          setDeleteId(new Date());
          dispatch(actionSuccess("Is Active updated successfully"));
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
    setDeleteId(new Date());
  };

  const visibiltyTemplate = (value) => {
    return (
      <div className="text-center">
        <label className="custom-switch3">
          <input
            type="checkbox"
            value={value["Is Active"]}
            checked={value["Is Active"] == "YES" ? true : false}
            onChange={(e) => {
              let payload = {
                formIsActive: value["Is Active"] == "YES" ? "NO" : "YES",
                formCreatedBy: loggedInUser._id,
              };
              updateVisibilty(value.id, payload);
            }}
          />
          <div>
            <span className="on">Yes</span>
            <span className="off">No</span>
          </div>
          <i></i>
        </label>
      </div>
    );
  };
  /* 
        @Description : Template Component for action column
    */
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                history.push(`/custom-forms/${value.id}/basic-details#basic`);
                dispatch(visitRecord());
              },
            }
          : {})}
        {...(accessActionDelete
          ? {
              Delete: () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteCustomForm(value.id)
                        .then((response) => {
                          if (response) {
                            dispatch(actionSuccess("Form deleted successfully"));
                            const params = {};
                            params.limit = 100;
                            if (productVariant)
                              params.filters = [
                                ["productVariant.productName", "eq", productVariant],
                                ["formIsDeleted", "eq", "NO"],
                              ];
                            fetchCustomForms(params).then((form) => {
                              formatRowData(form?.data?.data || []);
                            });
                            setDeleteId(new Date());
                          } else {
                            dispatch(actionError("Something went wrong"));
                          }
                        })
                        .catch((err) => {
                          dispatch(actionError(err?.data?.message || "Failed to delete form"));
                        });
                    },
                    content: "Are you sure, you want to delete?",
                    okText: "Delete",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
      />
    );
  };

  const customFormTitleTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(`/custom-forms/${value.id}/basic-details#basic`);
          dispatch(visitRecord());
        }}
      >
        {value.Title}
      </span>
    );
  };

  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Form Type":
        sourceData = [
          { id: "sbo", text: "Business Objective" },
          { id: "registration", text: "Registration" },
          { id: "Survey", text: "Survey" },
          { id: "Expert Consultation", text: "Expert Consultation" },
          { id: "Startup Connect Request", text: "Startup Connect Request" },
          { id: "Custom Company Profile", text: "Custom Company Profile" },
          { id: "Startup Identification", text: "Startup Identification" },
        ];
        break;
      case "Is Active":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Is Live":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
    }
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  const columnNames = {
    id: "id",
    Title: "formTitle",
    // "Form Description": "formDescription",
    "Form Type": "formType",
    "Is Active": "formIsActive",
    "Is Live": "productVariant~isLive",
    "Form Placeholder": "formPlaceholder~placeholderName",
  };
  const columnFields = [
    {
      field: "id",
      type: "string",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "Title",
      type: "String",
      template: customFormTitleTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      showInColumnChooser: false,
      width: 350,
    },
    { field: "Form Placeholder", type: "String", filterTemplate: StringFilter, textAlign: "Left", width: 200 },
    { field: "Form Type", type: "String", filterTemplate: ItemFilter, filter: { operator: "equal" }, textAlign: "Left", width: 250 },
    {
      field: "Is Live",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 110,
    },
    {
      field: "Is Active",
      type: "String",
      template: visibiltyTemplate,
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      headerTextAlign: "Left",
      width: 120,
    },
  ];

  if (accessActionEdit || accessActionDelete) {
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      filterTemplate: StringFilter,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
        if (rD.formType) {
          newRD["Form Type"] = rD.formType;
        }
        if (rD.formPlaceholder) {
          newRD["Form Placeholder"] =
            rD.formPlaceholder && rD.formPlaceholder.length
              ? rD.formPlaceholder
                  .map((item) => {
                    return item.placeholderName;
                  })
                  .filter((item, index, self) => item != "" && self.indexOf(item) === index)
                  .join(", ")
              : "";
        }
        if (rD.productVariant?.length) {
          if (productVariant) {
            const rDPV = rD.productVariant.find((pV) => pV.productName === productVariant);
            newRD["Is Live"] = rDPV?.isLive ? rDPV.isLive : "NO";
          } else newRD["Is Live"] = rD.productVariant[0].isLive ? rD.productVariant[0].isLive : "NO";
        }
        return newRD;
      });
      return newRD;
    });
    return formatedRowData;
  };

  const getCustomForms = (params = {}) => {
    params.sort = "_id:desc";
    let defaultFilter = [["formIsDeleted", "eq", "NO"]];
    let fields = ["formIsActive", "formIsDeleted", "formPlaceholder", "formTitle", "formType", "productVariant", "formPlaceholder"];
    let { filters = [] } = params;

    if (filters) {
      filters = filters.map((item) => {
        if (item[0] === "formType") {
          if (item[2] === "sbo") {
            return [item[0], item[1], "sbo"];
          } else if (item[2] === "registration") {
            return [item[0], item[1], "registration"];
          } else if (item[2] === "Survey") {
            return [item[0], item[1], "Survey"];
          } else if (item[2] === "Expert Consultation") {
            return [item[0], item[1], "Expert Consultation"];
          } else if (item[2] === "Startup Connect Request") {
            return [item[0], item[1], "Startup Connect Request"];
          } else if (item[2] === "Custom Company Profile") {
            return [item[0], item[1], "Custom Company Profile"];
          } else if (item[2] === "Startup Identification") {
            return [item[0], item[1], "Startup Identification"];
          }
        } else {
          return item;
        }
      });
    }
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...filters, ...defaultFilter];
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    params.fields = fields;
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchCustomForms(params);
    }
    return fetchCustomForms(params);
  };
  const getPlaceHolder = () => {
    let filters = productVariant === "WhatNext" ? [["config_name", "eq", "WhatNext-Setting"]] : [["config_name", "eq", "Insider-Setting"]];
    const params = {
      filters,
      fields: ["configValue"],
      limit: 1000,
    };
    fetchConfig(params)
      .then((res) => {
        let data = res?.data?.data || [];

        setPlaceholder(data[0].configValue.boFormPlaceholder || []);
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
  };

  useEffect(() => {}, [formatRowData]);
  useEffect(() => {
    getPlaceHolder();
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        <CommonDataGrid
          gridTitle="Custom Forms"
          fetch={getCustomForms}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          deleteMany={false}
          addNewRight={accessActionListingAddNew}
          addNew={() => {
            history.push("/custom-forms/basic-details#basic");
          }}
          clearFilterByTaxonomy={true}
        />
      </div>
    </div>
  );
};

export default CustomForms;
