/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  User Work Experiance Popup Component for Add/Edit
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 07/Jul/2021 
    Author						: Pankaj Kulshreshtha
================================================================ 
*/
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import { experianceValidationSchema } from "../../../../helper/validationSchema/externalUserValidationSchema";
import { Modal } from "react-bootstrap";
import { saveExperience, fetchSingleExperience, updateExperience } from "../../../../../middleware/services/userApi";
import { fetchCompany } from "../../../../../middleware/services/companyApi";
import { TextField, MultiSelect, AutoCompleteDropdown, RadioGroup, DatePicker } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { getProductVariant } from "../../../../../utilities";

const initialFormValues = {
  workCompanyName: "",
  workRole: "",
  workStartDate: "",
  workEndDate: "",
  workDesignation: "",
  workCurrentCompany: "",
};
const AddExp = (props) => {
  const dispatch = useDispatch();
  const [roleData, setRoleData] = useState([]);
  const [exp, setExp] = useState({});
  const [companyId, setCompanyId] = useState("");

  const productVariant = getProductVariant();
  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(experianceValidationSchema),
  });
  const searchCompany = useCallback(
    debounce(async (e) => {
      let filters = [];
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["companyName", "cn", e.text]);
      let fields = { fields: ["companyId", "companyName"] };
      const response = e.text.length > 1 && (await fetchCompany({ filters: filters, ...fields, sort: "companyName:asc", limit: 10 }));
      if (response.data && response.data.data) {
        const resData = response.data.data.map((v) => {
          return { label: v.companyName, value: v.id };
        });
        if (resData) {
          e.updateData(resData);
        } else {
          e.updateData([]);
        }
      }
    }, 300),
    []
  );

  const addExperience = () => {
    //Submit Form and send data to API
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const { workCompanyName, workRole, workDesignation, workStartDate, workEndDate, workCurrentCompany } = formik.values;
        payload.workCompanyName = workCompanyName;
        payload.workRole = workRole;
        payload.workDesignation = workDesignation;
        payload.workStartDate = workStartDate;
        payload.workCurrentCompany = workCurrentCompany === "Yes" ? "YES" : "NO";
        payload.workEndDate = workCurrentCompany === "Yes" ? "Present" : workEndDate;

        if (workCurrentCompany === "Yes") {
          payload.workEndDate = "Present";
        }
        if (companyId) {
          payload.workCompanyId = companyId;
        }

        if (props.expId !== "") {
          updateExperience(props.userId, props.expId, payload)
            .then((response) => {
              props.closeModal();
              dispatch(actionSuccess("Work experience updated successfully"));
              props.formatRowData(props.userId);
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Someting went wrong"));
            });
        } else {
          saveExperience(props.userId, payload)
            .then((response) => {
              props.closeModal();
              dispatch(actionSuccess("Work experience added successfully"));
              props.formatRowData(props.userId);
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Someting went wrong"));
            });
        }
      }
    });
  };
  useEffect(() => {
    if (props.userId === "") {
      props.closeModal();
      dispatch(actionError("Please add basic details first"));
    }
    const role_data = ["Owner", "Board of Director", "Inventor", "Management"];
    setRoleData(role_data);
    if (props.expId !== "") {
      dispatch(showLoader());
      fetchSingleExperience(props.userId, props.expId).then((response) => {
        dispatch(hideLoader());
        if (response.status === 200) {
          var experience = response.data.data;
          experience && setExp(experience);
          formik.setValues({
            workCompanyName: experience.workCompanyName,
            workRole: experience.workRole,
            workStartDate: experience.workStartDate,
            workEndDate: experience.workEndDate,
            workDesignation: experience.workDesignation,
            workCurrentCompany: experience.workCurrentCompany === "YES" ? "Yes" : "No",
          });
        }
      });
    }
  }, [props.showModal]);

  const handleCompanyData = (data) => {
    data && setCompanyId(data.itemData.value);
  };

  return (
    <Modal show={props.showModal} backdrop="static" className="modal fade lg people-mgmt" aria-labelledby="contained-modal-title-vcenter" centered>
      <form id="addExp" method="post">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {props.expId ? "Update" : "Add"} Experience
          </h5>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <AutoCompleteDropdown
                formik={formik}
                id="workCompanyName"
                name="workCompanyName"
                placeholder="Company Name *"
                value={exp.workCompanyName}
                fields={{ value: "label", id: "value" }}
                filtering={searchCompany}
                select={handleCompanyData}
                allowFiltering={true}
                // noRecordsTemplate={() => <div>No data found</div>}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <MultiSelect
                formik={formik}
                id="workRole"
                name="workRole"
                floatLabelType="Auto"
                mode="Box"
                placeholder="Work Role *"
                cssClass="e-outline"
                allowCustomValue={false}
                dataSource={roleData}
                value={exp.workRole}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <TextField formik={formik} id="workDesignation" name="workDesignation" placeholder="Designation *" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <RadioGroup
                formik={formik}
                type="radio"
                optionArr={["Yes", "No"]}
                properties={{
                  placeholder: "Current Company?",
                  name: "workCurrentCompany",
                  label: "Current Company?",
                }}
              />
              {/* <RadioButtonComponent
                                    label="Yes"
                                    value="YES"
                                    name="workCurrentCompany"
                                    checked={exp.workCurrentCompany === "YES"}
                                    onClick={handleCheckbox}
                                />
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <RadioButtonComponent
                                    label="No"
                                    value="NO"
                                    checked={exp.workCurrentCompany === "NO" || props.expId === ""}
                                    name="workCurrentCompany"
                                    onClick={handleCheckbox}
                                /> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <DatePicker
                  formik={formik}
                  properties={{
                    id: "workStartDate",
                    format: "MMM yyyy",
                    name: "workStartDate",
                    placeholder: "Start Date *",
                    allowEdit: false,
                    openOnFocus: true,
                    max: new Date(),
                    start: "Decade",
                    depth: "Year",
                    value: exp.workStartDate,
                  }}
                  // change={handleDateChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <DatePicker
                  formik={formik}
                  properties={{
                    id: "workEndDate",
                    format: "MMM yyyy",
                    name: "workEndDate",
                    placeholder: `End Date${formik.values.workCurrentCompany === "Yes" ? "" : " *"}`,
                    allowEdit: false,
                    openOnFocus: true,
                    min: new Date(formik.values.workStartDate),
                    start: "Decade",
                    depth: "Year",
                    value: exp.workEndDate,
                    view: "string",
                  }}
                  // change={handleDateChange}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-dark cy-btn mr-3"
            onClick={() => {
              props.closeModal();
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary cy-btn" onClick={addExperience}>
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddExp;
