/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : Presentation API
----------------------------------------------------------------
	Creation Details
	Date Created				: 21/Nov/2023
	Author						: Suraj Yadav
================================================================
*/

import { USER_API as API } from "../../config/apiConfig";

// get presentation category
export const fetchPresentationCategory = (userId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/user/${userId}/presentation/category`,
    method: "GET",
    params: params,
  });
};

// get presentation
export const fetchPresentation = (userId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/user/${userId}/presentation`,
    method: "GET",
    params: params,
  });
};
