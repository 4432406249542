import React, { useEffect, useState, useRef } from "react";
import { accessRightActionCheck, accessRightInterfaceCheck, getLoggedInUser, getProductVariant } from "../../../../utilities";
import { useDispatch, useSelector } from "react-redux";
import BasicDetails from "./basic";
import Audience from "./audience";
import SurveyDesign from "./design";
import Invitation from "./invitation";
import Acknowledgement from "./acknowledgement";
import Taxonomy from "./tagging";
import Tabs from "../../../components/tabsComponent";
import SURVEY_CONSTANT from "../../survey/constants";
import moment from "moment";
import { addNewSurvey, saveSurveyKiaTaxonomy, updateSurvey } from "../../../../middleware/services/surveyApi";
import { PRODUCT_VARIANT } from "../../../../constants";
import { actionError, actionSuccess, hideLoader, showLoader } from "../../../../middleware/actions/utilityAction";
import { useHistory } from "react-router-dom";
import { resetRedux } from "../../../../middleware/actions/surveyAction";

const TABS_NAME = ["Basic", "Design", "Audience", "Invitation", "Acknowledgement", "Taxonomy"];

const SurveyNav = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const tabRef = useRef();
  const loggedInUser = getLoggedInUser();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const surveyCommonState = useSelector((state) => state.surveyState);
  const taxonomyCommonStateTree = useSelector((state) => state.treeState);
  const [changesSaved, setChangesSaved] = useState(false);
  const [tabErrors, setTabErrors] = useState({
    basic: false,
    design: false,
    audience: false,
    invitation: false,
    acknowledgement: false,
  });
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  const history = useHistory();
  const productVariant = getProductVariant();
  const surveyId = props.computedMatch?.params?.surveyId;
  const hashArr = {
    "#basic": "Basic",
    "#design": "Design",
    "#audience": "Audience",
    "#invitation": "Invitation",
    "#acknowledgement": "Acknowledgement",
    "#taxonomy": "Taxonomy",
  };
  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (surveyId) {
      if (selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.enableTab(4, false);
      tabRef.current.enableTab(5, false);
      tabRef.current.select(0);
    }
  };

  const newRecord = () => {
    tabRef.current.enableTab(1, true);
    tabRef.current.enableTab(2, true);
    tabRef.current.enableTab(3, true);
    tabRef.current.enableTab(4, true);
    tabRef.current.enableTab(5, true);
    tabRef.current.select(0);
  };
  const today = moment();

  const handleCommonSave = async (id) => {
    setChangesSaved(false);
    dispatch(showLoader());
    try {
      let invitationDetail = surveyCommonState?.invitationDetails ? surveyCommonState.invitationDetails : {};
      if (!invitationDetail.senderMail && !invitationDetail.senderName && !invitationDetail.mailSubject) {
        invitationDetail = {};
      }
      let acknowledgementDetail = surveyCommonState?.acknowledgementDetails ? surveyCommonState.acknowledgementDetails : {};
      if (!acknowledgementDetail.senderMail && !acknowledgementDetail.senderName && !acknowledgementDetail.mailSubject) {
        acknowledgementDetail = {};
      }
      let basicDetails = surveyCommonState?.basicDetails ? surveyCommonState.basicDetails : {};
      let taxonomyDetail = taxonomyCommonStateTree?.industryCheckedNodes ? taxonomyCommonStateTree.industryCheckedNodes : {};
      let surveyDesign = surveyCommonState?.designDetails ? surveyCommonState.designDetails : {};

      // basic save function
      let payload = {};
      if (tabErrors.basic) return dispatch(actionError("Please fix all the errors in Basic Tab!"));
      if (tabErrors.invitation && Object.keys(invitationDetail).length > 0)
        return dispatch(actionError("Please fix all the errors in Invitation Tab!"));
      if (tabErrors.acknowledgement && Object.keys(acknowledgementDetail).length > 0)
        return dispatch(actionError("Please fix all the errors in Acknowledgement Tab!"));
      if (
        surveyCommonState?.basicDetails?.surveyStatus?.value === "Closed" &&
        moment().diff(moment(surveyCommonState?.basicDetails?.surveyEndDate), "days") > 0
      )
        return dispatch(actionError("Survey is already in Closed state!"));

      if (Object.keys(basicDetails).length !== 0) {
        if (!surveyId && moment(basicDetails.surveyStartDate).isBefore(today, "day"))
          return dispatch(actionError("Survey Start Date Cannot be behind today!"));
        if (!surveyId && moment(basicDetails.surveyEndDate).isBefore(today, "day")) return dispatch(actionError("End Date Cannot be behind today!"));
        if (moment(basicDetails.surveyEndDate).isBefore(moment(basicDetails.surveyStartDate), "day"))
          return dispatch(actionError("Survey's end date Cannot be behind start date!"));
        if (!basicDetails.surveyStartDate) return dispatch(actionError("Please select schedule start date!"));
        if (!basicDetails.surveyEndDate && basicDetails.surveyType === "One Time") return dispatch(actionError("Please select schedule end date!"));
        payload.surveyTitle = basicDetails.surveyTitle;
        payload.surveyType = basicDetails.surveyType.value;
        payload.surveyShortName = basicDetails.surveyShortName;
        payload.surveyUrl = basicDetails.surveyUrl;
        let startDate = new Date(basicDetails.surveyStartDate);
        let surveyStartDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
        payload.surveyStartDate = surveyStartDate;
        if ([SURVEY_CONSTANT.SURVEY_TYPE.ONETIME, SURVEY_CONSTANT.SURVEY_TYPE.NPS_SURVEY].includes(basicDetails.surveyType.value)) {
          let endDateConvert = new Date(basicDetails.surveyEndDate);
          let endDate = `${endDateConvert.getFullYear()}-${endDateConvert.getMonth() + 1}-${endDateConvert.getDate()}`;
          payload.surveyEndDate = endDate;
        }
        if (productVariant === PRODUCT_VARIANT.WHATNEXT && basicDetails.surveyType.value === SURVEY_CONSTANT.SURVEY_TYPE.ONGOING) {
          payload.surveyFrequency = basicDetails.surveyFrequency;
          // In Which year survey will be attempt
          payload.surveyYear = basicDetails.surveyYear;
          // In Which quarter survey will be attempt
          payload.surveyQuarter = basicDetails.surveyQuarter;
        }

        payload.surveyIsMasked = basicDetails.maskedSurveyResponse;
        payload.surveyIsTemplate = basicDetails.saveAsTemplate;

        payload.surveyAudience = basicDetails.surveyAudience.value;
        // payload.surveyConfirmationMessage", (basicDetails.surveyAudience.value));
        if (basicDetails.surveyStatus === SURVEY_CONSTANT.SURVEY_STATUS.CLOSED) {
          payload.surveyStatus = basicDetails.surveyStatus;
        }
        payload.surveyCreatedBy = {
          userId: loggedInUser._id,
          userFname: loggedInUser.user_name,
          userLname: loggedInUser.user_lname,
        };
        if (productVariant === PRODUCT_VARIANT.CHEERSIN && basicDetails.surveyType.value === SURVEY_CONSTANT.SURVEY_TYPE.NPS_SURVEY) {
          payload.surveyReleaseArtifact = basicDetails.surveyReleaseArtifact;
          payload.teamName = basicDetails.teamName;
          payload.npsSurveyType = basicDetails.npsSurveyType;
        }
        if (surveyId) {
          delete payload.surveyCreatedBy;

          payload.surveyModifiedBy = {
            userId: loggedInUser._id,
            userFname: loggedInUser.user_name,
            userLname: loggedInUser.user_lname,
          };

          if (basicDetails.surveyStatus === SURVEY_CONSTANT.SURVEY_STATUS.CLOSED) {
            payload.surveyStatus = basicDetails.surveyStatus;
          }
        } else {
          if (basicDetails.createSurveyFrom.value !== "blank") payload.surveyReferenceId = basicDetails.existingTemplate;
          payload.productVariant = [{ productName: productVariant, isLive: "NO" }];
        }
      }
      // design save function
      if (surveyId && Object.keys(surveyDesign).length !== 0) {
        payload.surveyForm = surveyDesign;
        payload.surveyModifiedBy = {
          userId: loggedInUser._id,
          userFname: loggedInUser.user_name,
          userLname: loggedInUser.user_lname,
        };
      }

      // invitation save function
      if (surveyId && Object.keys(invitationDetail).length !== 0) {
        payload.surveyModifiedBy = {
          userId: loggedInUser._id,
          userFname: loggedInUser.user_name,
          userLname: loggedInUser.user_lname,
        };
        const { senderMail, senderName, mailSubject, includeMailSignature, invitationMailBody, email_reply } = invitationDetail;
        const email_cc = invitationDetail.email_cc2;
        const email_bcc = invitationDetail.email_bcc2;
        payload.surveyInvitationMailTemplate = {
          senderMail: senderMail,
          senderName: senderName,
          mailSubject: mailSubject,
          includeMailSignature: includeMailSignature,
          mailBody: invitationMailBody,
          email_cc: email_cc.map((email) => {
            return email.value;
          }),
          email_bcc: email_bcc.map((email) => {
            return email.value;
          }),
          email_reply: email_reply,
        };
      }
      // acknowledgement save function
      if (surveyId && Object.keys(acknowledgementDetail).length !== 0) {
        payload.surveyModifiedBy = {
          userId: loggedInUser._id,
          userFname: loggedInUser.user_fname,
          userLname: loggedInUser.user_lname,
        };
        const { senderMail, senderName, mailSubject, includeMailSignature, mailBody, email_reply } = acknowledgementDetail;
        const email_cc = acknowledgementDetail.email_cc2 || acknowledgementDetail.email_cc;

        const email_bcc = acknowledgementDetail.email_bcc2 || acknowledgementDetail.email_bcc;
        payload.surveyAcknowledgementMailTemplate = {
          senderMail: senderMail,
          senderName: senderName,
          mailSubject: mailSubject,
          includeMailSignature: includeMailSignature,
          mailBody: mailBody,
          email_cc: email_cc.map((email) => {
            return email.value;
          }),
          email_bcc: email_bcc.map((email) => {
            return email.value;
          }),
          email_reply: email_reply,
        };
      }

      // taxonomy save function
      if (surveyId && taxonomyDetail.length > 0) {
        const response = saveSurveyKiaTaxonomy(surveyId, {
          taxonomyIds: taxonomyDetail,
        });
        response
          .then(() => {
            dispatch(hideLoader());
            dispatch(actionSuccess("KIA Updated Successfully"));
          })
          .catch(() => {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
          });
      }
      if (surveyId) {
        updateSurvey(surveyId, payload)
          .then(() => {
            dispatch(actionSuccess("Survey details updated successfully"));
            dispatch(hideLoader());
          })
          .catch((err) => {
            dispatch(
              actionError(err?.data?.message || "Unable to process your request. Please contact system administrator (product.support@cheersin.com)")
            );
          });
      } else {
        addNewSurvey(payload)
          .then((res1) => {
            if (res1) {
              dispatch(actionSuccess("Survey basic details saved successfully"));
              res1.data.data.id &&
                history.push({
                  pathname: `/survey/${res1.data.data.id}/basic-details`,
                  hash: "basic",
                  state: { surveyId: res1.data.data.id },
                });
              window.location.reload();
              res1.data.data.id && newRecord();
            } else {
              dispatch(actionError("Unable to process your request. Please contact system administrator (product.support@cheersin.com)"));
            }
          })
          .catch((err) => {
            dispatch(
              actionError(err?.data?.message || "Unable to process your request. Please contact system administrator (product.support@cheersin.com)")
            );
          });
      }
      setChangesSaved(true);
    } catch (err) {
      setChangesSaved(false);
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };

  useEffect(() => {
    return () => dispatch(resetRedux());
  }, []);

  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, TABS_NAME);
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex]);

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
      <Tabs
        tabsName={productVariant != "Insider" ? TABS_NAME : ["Basic", "Design", "Audience", "Invitation", "Acknowledgement"]}
        tabRef={tabRef}
        created={created}
        handleClick={() => {
          setSelectedIndex(tabRef.current.selectedItem);
        }}
        cssClass="newHeaderTabCss"
      >
        <div key="surveyDetail">
          <BasicDetails
            handleCommonSave={handleCommonSave}
            surveyId={surveyId}
            selectedIndex={selectedIndex}
            setCurrentTab={(selectedIndex) => {
              tabRef.current.select(selectedIndex);
              setSelectedIndex(selectedIndex);
              setSelectedIndex(selectedIndex);
            }}
            newRecord={newRecord}
            hashArr={hashArr}
            tabsName={TABS_NAME}
            moduleName={aR.moduleName}
            interfaceActionAccess={interfaceActionAccess}
            interfaceAccess={interfaceAccess}
          />
        </div>
        <div key="surveyDesign">
          {selectedIndex === 1 && surveyId && (
            <SurveyDesign
              handleCommonSave={handleCommonSave}
              surveyId={surveyId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              newRecord={newRecord}
              hashArr={hashArr}
              tabsName={TABS_NAME}
              moduleName={aR.moduleName}
              interfaceActionAccess={interfaceActionAccess}
              interfaceAccess={interfaceAccess}
            />
          )}
        </div>
        <div key="surveyAudience">
          {selectedIndex === 2 && surveyId && (
            <Audience
              handleCommonSave={handleCommonSave}
              accessRights={props.accessRights}
              surveyId={surveyId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              newRecord={newRecord}
              hashArr={hashArr}
              tabsName={TABS_NAME}
              moduleName={aR.moduleName}
              interfaceActionAccess={interfaceActionAccess}
              interfaceAccess={interfaceAccess}
            />
          )}
        </div>
        <div key="surveyInvitation">
          {selectedIndex === 3 && surveyId && (
            <Invitation
              setTabErrors={setTabErrors}
              handleCommonSave={handleCommonSave}
              surveyId={surveyId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              newRecord={newRecord}
              hashArr={hashArr}
              tabsName={TABS_NAME}
              moduleName={aR.moduleName}
              interfaceActionAccess={interfaceActionAccess}
              interfaceAccess={interfaceAccess}
            />
          )}
        </div>
        <div key="surveyAcknowledgement">
          {selectedIndex === 4 && surveyId && (
            <Acknowledgement
              setTabErrors={setTabErrors}
              handleCommonSave={handleCommonSave}
              surveyId={surveyId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              newRecord={newRecord}
              hashArr={hashArr}
              tabsName={TABS_NAME}
              moduleName={aR.moduleName}
              interfaceActionAccess={interfaceActionAccess}
              interfaceAccess={interfaceAccess}
            />
          )}
        </div>
        <div key="surveyTaxonomy">
          {selectedIndex === 5 && surveyId && (
            <Taxonomy
              handleCommonSave={handleCommonSave}
              surveyId={surveyId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              newRecord={newRecord}
              hashArr={hashArr}
              tabsName={TABS_NAME}
              moduleName={aR.moduleName}
              interfaceActionAccess={interfaceActionAccess}
              interfaceAccess={interfaceAccess}
              {...props}
            />
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default SurveyNav;
