/*
================================================================
	Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : User Analytics Report API
----------------------------------------------------------------
	Creation Details
	Date Created				: 20/Feb/2024
	Author						: Lawrence Anthony
================================================================
*/

import { REPORT_API as API } from "../../config/apiConfig";

// fetch Reports Internal User
export const fetchReportInternalUser = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/internal/activity-tracker",
    method: "GET",
    params: params,
  });
};

// fetch Reports by record Ids
export const fetchActivityLog = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/internal/activity-tracker-details",
    method: "GET",
    params: params,
  });
};
// fetch Reports Vendor
export const fetchReportVendor = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/vendor/activity-tracker",
    method: "GET",
    params: params,
  });
};

// vendor report details by
export const fetchVendorActivityLogById = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/vendor/activity-tracker-details",
    method: "GET",
    params: params,
  });
};

// fetch Reports Client
export const fetchReportClient = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/client/activity-tracker",
    method: "GET",
    params: params,
  });
};

// fetch Keyword Search Reports
export const fetchKeywordSearchReport = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/keyword-search",
    method: "GET",
    params: params,
  });
};

// fetch Keyword Search Reports
export const fetchKeywordDetailReport = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/keyword-search/details",
    method: "GET",
    params: params,
  });
};

// fetch user analytics publication status reports
export const fetchReportPublicationStatus = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/user-analytics/publication-status",
    method: "GET",
    params: params,
  });
};

// fetch Reports Content
export const fetchReportContent = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/client/content-activity-tracker",
    method: "GET",
    params: params,
  });
};

// fetch Reports Internal User
export const fetchclientDetailsReport = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/client-details",
    method: "GET",
    params: params,
  });
};
// fetch Reports Internal User
export const fetchclientDetailsUsers = (params = {}) => {
  let { filters = undefined, fields = undefined, isGnxUserDetails = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  params.isGnxUserDetails = isGnxUserDetails;
  return API({
    url: "/reports/client-details/user",
    method: "GET",
    params: params,
  });
};
// fetch Reports Internal User
export const fetchGroupList = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/client-details/group",
    method: "GET",
    params: params,
  });
};

// fetch ask your analyst details
export const fetchAnalystQuery = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/analyst-query",
    method: "GET",
    params: params,
  });
};

// fetch listing og business objective report
export const fetchBusinessObjectiveReport = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/objective-details",
    method: "GET",
    params: params,
  });
};

// fetch single business objective Reports
export const fetchSingleBusinessObjective = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/objective-listing-details",
    method: "GET",
    params: params,
  });
};
// fetch User Trafic Report
export const fetchUserTraficReport = (params = {}) => {
  let { filters = undefined, analysisPeriodFilter = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (analysisPeriodFilter) params.analysisPeriodFilter = JSON.stringify(analysisPeriodFilter);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/user-trafic",
    method: "GET",
    params: params,
  });
};
// fetch User Trafic Report
export const fetchTraficUsers = (params = {}) => {
  let { filters = undefined, analysisPeriodFilter = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (analysisPeriodFilter) params.analysisPeriodFilter = JSON.stringify(analysisPeriodFilter);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/user-trafic/user",
    method: "GET",
    params: params,
  });
};

export const fetchSingleAnalystQuery = (queryId, params = {}) => {
  let { fields = undefined } = params;

  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/reports/analyst-query/${queryId}`,
    method: "GET",
    params: params,
  });
};

// fetch content type analysis Reports
export const fetchContentTypeReport = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/content-type",
    method: "GET",
    params: params,
  });
};

// fetch content type analysis Reports
export const fetchContentTypeReportDetails = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/reports/content-type/details",
    method: "GET",
    params: params,
  });
};

// fetch ask your analyst details
export const exportAnalystQuery = (params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);

  return API({
    url: "/reports/analyst-query/export",
    method: "GET",
    params: params,
  });
};
