/*
================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Content Block remark Component
----------------------------------------------------------------
    Creation Details
    Date Created				: 02/May/2022
    Author						: Suraj Yadav
================================================================
*/
import React, { useEffect, useState } from "react";
import RemarkDisposition from "./remarkDisposition";
import RemarkReply from "./remarkReply";
import { FaUser } from "react-icons/fa";
import { MdExpandMore, MdExpandLess, MdKeyboardArrowRight } from "react-icons/md";
import "./index.scss";
import { enableRipple } from "@syncfusion/ej2-base";
import { getLoggedInUser } from "../../../utilities";
import { fetchContentBlockRemark, updateRemark } from "../../../middleware/services/contentBlockApi";
import { fetchSingleDeliverable } from "../../../middleware/services/deliverablesApi";
import { getDateFromMongoDate } from "../../../utilities/index";
import { TextField } from "../../components/CustomFormElements/syncFusionFormFields";
import ThreeDotMenu from "../../components/threeDotMenu";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ENTITY_TYPE } from "../../../constants";
import ActionButton from "./actionButtonns";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";
enableRipple(true);

const Remark = (props) => {
  const { setPageId, setReadMode } = props;
  const dispatch = useDispatch();
  let dispositionList = {
    "Name of Block": false,
    "Type of Block": false,
    "Applicable Platfom": false,
    "Usage Type": false,
    "Font Size": false,
    "Font Color": false,
    "Font Style": false,
  };
  let showRemarkStatus = ["Resolved"];
  let blockStatus = ["In Progress", "Rejected", ""];
  let hideThreeDots = ["Approved", "Published", "Submitted"];
  const [viewDispositionSection, setViewDispositionSection] = useState(props.showDisposition ? props.showDisposition : false);
  const [updateRemarkData, setUpdateRemarkData] = useState("");
  const [showAllReply, setShowAllReply] = useState(false);
  const [activeRemark, setActiveRemark] = useState();
  const [selectedDisposition, setSelectedDisposition] = useState(dispositionList);
  const [remarkId, setRemarkId] = useState("");
  const [singleRemark, setSingleRemark] = useState();
  const [checkedBox, setCheckedBox] = useState([]);
  const [showRemark, setShowRemark] = useState(false);
  const [showActiveRemarkKey, setShowActiveRemarkKey] = useState();
  const [open, setOpen] = useState();
  const [showReportRemark, setShowReportRemark] = useState();
  const [showReportKey, setShowReportKey] = useState();
  const [keyy, setKeyy] = useState();
  const [viewOption, setViewOption] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [customHeight, setCustomHeight] = useState(props.titleHeight);
  const [hideAccordion, setHideAccordion] = useState(true);
  const [viewActiveKey, setViewActiveKey] = useState();
  const [getRemarkReply, setGetRemarkReply] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [deliverableTitle, setDeliverableTitle] = useState("");
  const [pageTitle, setPageTitle] = useState(props.pageTitle);
  const [selectedDispositions, setSelectedDispositions] = useState();
  const remarkAccess = props.remarkEntityType === ENTITY_TYPE.DELIVERABLE ? props.contentTabAccess : true;
  const [loadingState, setLoadingState] = useState(false);

  let loggedInUser = getLoggedInUser();
  const formik = useFormik({
    initialValues: {
      remarkComment: "",
    },
    validationSchema: Yup.object().shape({
      remarkComment: Yup.string()
        .min(1, "Please enter valid name")
        .max(500, "Please enter within 500 characters.")
        .matches(/[^\s]([a-zA-Z])/, "Please enter valid name")
        .required("Please enter comment"),
    }),
  });
  /*
    Description : Fetch remark function
    @params: remarkEntityId (deliverable or contentBlock Id)
    params: remarkId 
  */
  const loadRemarks = async (remarkEntityId, storePageId) => {
    dispatch(showLoader());
    await fetchContentBlockRemark(remarkEntityId).then((e) => {
      setUpdateRemarkData(e.data.data);
      let fetchRemark = e.data.data.filter((data) => {
        if (data.remarkReportChildId) return data.remarkReportChildId == storePageId;
        else return data.remarkReportId == storePageId;
      });
      props.remarkEntityType === ENTITY_TYPE.DELIVERABLE && !props.fetchRemark ? setUpdateRemarkData(fetchRemark) : setUpdateRemarkData(e.data.data);
    });
    dispatch(hideLoader());
    setLoadingState(true);
  };

  /*
        Description : Fetch single Deliverable function
    */
  const fetchDeliverable = () => {
    fetchSingleDeliverable(props.remarkEntityId).then((e) => {
      if (e.data.data) {
        setDeliverableTitle(e.data.data.deliverableName);
      }
    });
  };

  /*
        Description : View three dot function
    */
  const showThreeDot = () => {
    if (hideThreeDots.includes(props.contentBlockStatus || props.deliverableContentStatus) && props.creatorAccess && props.reviewerAccess === false) {
      return setViewOption(false);
    } else {
      return setViewOption(true);
    }
  };

  /*
        Description : Get Report remark count
    */
  let reportRemarkCount = [];
  reportRemarkCount =
    updateRemarkData &&
    updateRemarkData.filter((response) => {
      return response.remarkReportId === props.remarkEntityId;
    });

  /*
        Description : Hide Resolve option function
    */
  const hideResolveButton = () => {
    if (
      (blockStatus.includes(props.contentBlockStatus) || blockStatus.includes(props.deliverableContentStatus)) &&
      props.creatorAccess &&
      props.reviewerAccess === false
    ) {
      return setHideButton(true);
    } else if (props.creatorAccess && props.reviewerAccess) {
      return setHideButton(true);
    } else {
      return setHideButton(false);
    }
  };
  useEffect(() => {
    loadRemarks(props.remarkEntityId, props.storePageId);
    hideResolveButton();
    showThreeDot();
  }, []);

  let reportRemark = [];
  reportRemark =
    updateRemarkData.length > 0
      ? updateRemarkData.filter((data) => {
          if (props.remarkEntityId === data.remarkReportId) {
            fetchDeliverable();
            return data;
          }
        })
      : "";
  let pageData = [];
  /*
        Description : Get page and blocks remark function
    */
  const getPageDetails = () => {
    let handleRemarkData = updateRemarkData.length > 0 && updateRemarkData;
    let remarks = [];
    props.allPages.map((pageOrBlockData) => {
      remarks = [];
      handleRemarkData &&
        handleRemarkData.map((r) => {
          if (r.remarkReportId === pageOrBlockData.id) {
            remarks.push(r);
          }
        });
      if (remarks.length) {
        if (!pageOrBlockData.pageParentId) {
          const checkPageExist = pageData.filter((res) => res.id === pageOrBlockData.id);
          if (checkPageExist.length === 0) {
            pageData.push({
              id: pageOrBlockData.id,
              pageTitle: pageOrBlockData.pageTitle,
              pageSeqNo: pageOrBlockData.pageSeqNo,
              count: remarks.length,
              remarks: remarks,
              block: [],
            });
          } else {
            pageData.map((record) => {
              if (record.id === pageOrBlockData.id) record.remarks = remarks;
              return record;
            });
          }
        } else {
          //- check page id is present in pageData
          let index;
          pageData.map((p, key) => {
            if (p.id === pageOrBlockData.pageParentId) {
              index = key;
            }
          });
          if (index >= 0 && pageData.length) {
            let block = {
              id: pageOrBlockData.id,
              pageTitle: pageOrBlockData.pageTitle,
              remark: remarks,
            };
            pageData[index].block.push(block);
            pageData[index].count = pageData[index].remarks.length + pageData[index].block.length;
          } else {
            let pageRecord = props.allPages.filter((p) => {
              return p.id === pageOrBlockData.pageParentId;
            });
            let block = [
              {
                id: pageOrBlockData.id,
                pageTitle: pageOrBlockData.pageTitle,
                remark: remarks,
              },
            ];
            pageRecord.length > 0 &&
              pageData.push({
                id: pageRecord[0]?.id,
                pageTitle: pageRecord[0]?.pageTitle,
                pageSeqNo: pageRecord[0]?.pageSeqNo,
                count: block.length,
                remarks: [],
                block: block,
              });
          }
        }
      }
    });
  };
  props.remarkEntityType === ENTITY_TYPE.DELIVERABLE && getPageDetails();

  return (
    <div id="remark_section" className="content-block-remark-sidebar h-100">
      <div className="h-100">
        {props.reviewerAccess && (showComment ? showComment : !props.fetchRemark) && (
          <div>
            {props.remarkEntityType === ENTITY_TYPE.DELIVERABLE && props.blockTitle ? (
              <span className="pageTitle" title={props.pageTitle}>
                {props.pageTitle}{" "}
              </span>
            ) : (
              props.remarkEntityType === ENTITY_TYPE.DELIVERABLE && (
                <span className="pageTitle" title={pageTitle}>
                  {pageTitle}
                </span>
              )
            )}
            {/* comment section for adding remark */}
            <div className="customHeight" style={{ marginTop: props.remarkEntityType === ENTITY_TYPE.DELIVERABLE && "-11px" }}>
              <TextField
                {...props}
                formik={formik}
                multiline={true}
                numberCount={true}
                maxLength={500}
                rows={2}
                name="remarkComment"
                className="remark-input"
                id="remarkComment"
                placeholder="Make a comment *"
                value={formik.values.remarkComment}
              />
            </div>
            {/* Add disposition button */}
            {viewDispositionSection === false && (
              <div style={{ borderBottom: "1px solid #DEDEDE", height: "39px" }}>
                <a
                  className={formik.values.remarkComment.length ? "add-link-btn  add-link-btn-enabled" : "add-link-btn"}
                  onClick={() => {
                    if (formik.values.remarkComment.length) {
                      setRemarkId("");
                      setViewDispositionSection(true);
                    }
                  }}
                >
                  + Add Disposition
                </a>
              </div>
            )}
          </div>
        )}
        {viewDispositionSection && (
          <div id="dispodition_header">
            <span className="dispositions">Select Disposition</span>
          </div>
        )}
        {
          <div id="disposition_section" className={!viewDispositionSection ? props.remarkCss : props.dispositionCss}>
            {/* view remarkDisposition */}
            {viewDispositionSection === true && (
              <RemarkDisposition
                {...props}
                checkedBox={checkedBox}
                setCheckedBox={setCheckedBox}
                dispositionList={dispositionList}
                remarkId={remarkId}
                formik={formik}
                remarkComment={formik.values.remarkComment}
                selectedDisposition={selectedDisposition}
                setSelectedDisposition={setSelectedDisposition}
                loadRemarks={loadRemarks}
                setViewDispositionSection={setViewDispositionSection}
                setShowComment={setShowComment}
                customHeight={customHeight}
                setHideAccordion={setHideAccordion}
                setSelectedDispositions={setSelectedDispositions}
              />
            )}
            {/* Show No remark available for content block */}
            {props.reviewerAccess === false && updateRemarkData.length < 1 && props.remarkEntityType === ENTITY_TYPE.CONTENT_BLOCK && (
              <div className="remark-message" style={{ width: "300px" }}>
                No remarks available.
              </div>
            )}
            {/* Show No remark available for deliverable */}
            {loadingState &&
              reportRemark.length < 1 &&
              pageData.length < 1 &&
              viewDispositionSection === false &&
              props.remarkEntityType === ENTITY_TYPE.DELIVERABLE && (
                <div className="remark-message" style={{ width: "300px" }}>
                  No remarks available.
                </div>
              )}
            <div>
              {/* load deliverable remark */}
              {props.fetchRemark &&
                reportRemark.length > 0 &&
                reportRemark.map((item, activeKeyValue) => {
                  let saveRemark = item;
                  let remarkStatus = item.remarkStatus;
                  let date = getDateFromMongoDate(item.remarkDate);
                  return (
                    <React.Fragment>
                      {props.remarkEntityId == item.remarkReportId && (
                        <div>
                          {hideAccordion && (
                            <div className={`head-accordion ${remarkStatus}-border-color`}>
                              <div
                                className="head-accordion-title head-accordion-title-remark"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  props.fetchContentBlock && props.fetchContentBlock();
                                  props.setLoadContentBuilder(false);
                                  setReadMode(true);
                                }}
                              >
                                {deliverableTitle} ({reportRemarkCount.length})
                              </div>
                              {showReportRemark && showReportKey === activeKeyValue ? (
                                <MdExpandLess
                                  color="#707070"
                                  cursor="pointer"
                                  size={26}
                                  className="head-accordion-arrow"
                                  onClick={() => {
                                    setShowReportRemark(false);
                                    setShowReportKey(activeKeyValue);
                                  }}
                                />
                              ) : (
                                <MdExpandMore
                                  color="#707070"
                                  cursor="pointer"
                                  size={26}
                                  className="head-accordion-arrow"
                                  onClick={() => {
                                    setShowReportRemark(true);
                                    setShowReportKey(activeKeyValue);
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {showReportRemark && showReportKey === activeKeyValue && (
                            <div className={remarkStatus}>
                              <div style={{ background: "#FBFBFB " }}>
                                <div className="row">
                                  <div className="col-md-1">
                                    <FaUser
                                      size={26}
                                      style={{ width: "auto", minWidth: "34px", minHeight: "26px", marginTop: "5px" }}
                                      color="#A1A1A1"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <div style={{ marginLeft: "5px" }}>
                                      <div className="remark-user-name" title={`${item.remarkByUserFname} ${item.remarkByUserLname}`}>
                                        {item.remarkByUserFname}&nbsp;{item.remarkByUserLname}
                                      </div>
                                      <div className="datedata">{date}</div>
                                      <br />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <span className={`${remarkStatus}-btn`}>{remarkStatus}</span>
                                    <div style={{ right: "0px", position: "absolute", top: "0px" }}>
                                      {viewOption &&
                                        remarkStatus !== "Closed" &&
                                        ((props.reviewerAccess && remarkStatus !== "Closed") || (props.creatorAccess && remarkStatus == "Open")) &&
                                        remarkAccess && (
                                          <ThreeDotMenu
                                            {...(props.reviewerAccess && remarkStatus != "Closed"
                                              ? {
                                                  Edit: () => {
                                                    setShowReportRemark(true);
                                                    setShowComment(true);
                                                    setRemarkId(item.id);
                                                    setViewDispositionSection(true);
                                                    setPageTitle(deliverableTitle);
                                                    setCustomHeight(true);
                                                    setHideAccordion(false);
                                                    formik.setValues({ remarkComment: item.remarkComment });
                                                    let disposition = selectedDisposition;
                                                    item.remarkDispositions.map((res) => {
                                                      disposition[res.dispositionName] = true;
                                                      checkedBox.push({
                                                        dispositionId: res.dispositionId,
                                                        dispositionName: res.dispositionName,
                                                        dispositionCategory: res?.dispositionCategory || "Standard",
                                                      });
                                                    });
                                                    setSelectedDisposition(disposition);
                                                  },
                                                }
                                              : {})}
                                            {...(props.reviewerAccess &&
                                              showRemarkStatus.includes(remarkStatus) && {
                                                Open: async () => {
                                                  let payload = {
                                                    remarkStatus: "Open",
                                                    remarkByUserId: loggedInUser._id,
                                                  };
                                                  await updateRemark(item.id, payload);
                                                  props.reviewerAccess && props.creatorAccess && setHideButton(true);
                                                  loadRemarks(props.remarkEntityId);
                                                },
                                              })}
                                            {...(props.reviewerAccess && {
                                              Close: async () => {
                                                let payload = {
                                                  remarkStatus: "Closed",
                                                  remarkByUserId: loggedInUser._id,
                                                };
                                                await updateRemark(item.id, payload);
                                                loadRemarks(props.remarkEntityId);
                                              },
                                            })}
                                            {...(hideButton && remarkStatus == "Open"
                                              ? {
                                                  Resolve: async () => {
                                                    let payload = {
                                                      remarkStatus: "Resolved",
                                                      remarkByUserId: loggedInUser._id,
                                                    };
                                                    await updateRemark(item.id, payload);
                                                    props.reviewerAccess && props.creatorAccess && setHideButton(!hideButton);
                                                    loadRemarks(props.remarkEntityId);
                                                  },
                                                }
                                              : {})}
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="sel-dis">
                                  {item.remarkDispositions.map((data) => {
                                    return (
                                      <ul className="mb-0">
                                        <li>{data.dispositionName}</li>
                                      </ul>
                                    );
                                  })}
                                </div>
                                <div className="sel-dis-1">
                                  <span className="text-trim">{item.remarkComment}</span>
                                </div>
                                {/* load remarkReply component after each remark block  */}
                                <div style={{ marginBottom: "10px" }}>
                                  {activeRemark === activeKeyValue && showAllReply === true ? (
                                    <MdExpandMore
                                      color="#707070"
                                      cursor="pointer"
                                      size={24}
                                      onClick={() => {
                                        setShowAllReply(false);
                                        setActiveRemark(activeKeyValue);
                                      }}
                                    />
                                  ) : (
                                    item.remarkReply.length > 0 && (
                                      <MdKeyboardArrowRight
                                        color="#707070"
                                        size={24}
                                        cursor="pointer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSingleRemark(saveRemark);
                                          setShowAllReply(true);
                                          setActiveRemark(activeKeyValue);
                                        }}
                                      />
                                    )
                                  )}
                                  <a
                                    className="reply-btn"
                                    onClick={() => {
                                      if (item.remarkReply.length < 1) {
                                        setSingleRemark(saveRemark);
                                        setShowAllReply(true);
                                      }
                                      setActiveRemark(activeKeyValue);
                                    }}
                                  >
                                    {item.remarkReply.length > 0 && item.remarkReply.length}&nbsp;&nbsp;Reply
                                  </a>
                                  {activeRemark === activeKeyValue && showAllReply === true && (
                                    <RemarkReply
                                      {...props}
                                      remarkStatus={remarkStatus}
                                      remarkId={item.id}
                                      updateRemarkData={updateRemarkData}
                                      activeRemark={activeRemark}
                                      singleRemark={singleRemark}
                                      handleSingleRemark={(val) => setSingleRemark(val)}
                                      handleUpdateRemarkData={(val) => setUpdateRemarkData(val)}
                                      loadRemarks={loadRemarks}
                                      // handleActiveRemark={(val)=>setActiveRemark(val)}
                                      handleShowAllReply={(val) => setShowAllReply(val)}
                                      remarkAccess={remarkAccess}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}

              {/* load page & block remark for deliverable*/}
              {props.fetchRemark &&
                pageData.length > 0 &&
                pageData
                  .sort((a, b) => a.pageSeqNo - b.pageSeqNo)
                  .map((val, key) => {
                    let remarkStatus = val.remarkStatus;
                    return (
                      <React.Fragment>
                        <div>
                          {hideAccordion && (
                            <div className={`head-accordion ${remarkStatus}-border-color`}>
                              <div className="head-accordion-title">
                                {val.pageSeqNo ? (
                                  <div className="sub-title">
                                    <span className="accordain-number-border">{val.pageSeqNo}</span>
                                    <span
                                      className="report-title"
                                      style={{ cursor: "pointer" }}
                                      title={val.pageTitle}
                                      onClick={() => {
                                        props.setSaveDeliverable && props.setSaveDeliverable(false);
                                        props.preserveContentHtml && props.preserveContentHtml("");
                                        sessionStorage.removeItem("pageeId");
                                        props.fetchAllBlockHtml && props.fetchAllBlockHtml(val);
                                        props.setLoadContentBuilder(false);
                                        setReadMode(true);
                                      }}
                                    >
                                      &nbsp;&nbsp;{val.pageTitle}&nbsp;&nbsp;
                                    </span>
                                    <span className="remark-count" style={{ color: "#272729" }}>
                                      (&nbsp;{val.count}&nbsp;)
                                    </span>
                                  </div>
                                ) : (
                                  `${val.pageTitle}`
                                )}
                              </div>
                              {open && keyy === key ? (
                                <MdExpandLess
                                  color="#707070"
                                  cursor="pointer"
                                  size={26}
                                  className="head-accordion-arrow"
                                  onClick={() => {
                                    setOpen(false);
                                    setKeyy(key);
                                  }}
                                />
                              ) : (
                                <MdExpandMore
                                  color="#707070"
                                  cursor="pointer"
                                  size={26}
                                  className="head-accordion-arrow"
                                  onClick={() => {
                                    setOpen(true);
                                    setKeyy(key);
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {open && keyy === key && (
                            <div>
                              {/* load page remark */}
                              <div>
                                {val.remarks.map((item, keey) => {
                                  let saveRemark = item;
                                  let remarkStatus = item.remarkStatus;
                                  let date = getDateFromMongoDate(item.remarkDate);

                                  return (
                                    <div>
                                      <div className={remarkStatus} style={{ background: "#FBFBFB " }}>
                                        <div className="row">
                                          <div className="col-md-1">
                                            <FaUser
                                              size={26}
                                              style={{ width: "auto", minWidth: "34px", minHeight: "26px", marginTop: "5px" }}
                                              color="#A1A1A1"
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <div style={{ marginLeft: "5px" }}>
                                              <div className="remark-user-name" title={`${item.remarkByUserFname} ${item.remarkByUserLname}`}>
                                                {item.remarkByUserFname}&nbsp;{item.remarkByUserLname}
                                              </div>
                                              <div className="datedata">{date}</div>
                                              <br />
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <span className={`${remarkStatus}-btn`}>{remarkStatus}</span>
                                            <div style={{ right: "0px", position: "absolute", top: "0px" }}>
                                              {viewOption &&
                                                remarkStatus !== "Closed" &&
                                                ((props.reviewerAccess && remarkStatus !== "Closed") ||
                                                  (props.creatorAccess && remarkStatus == "Open")) &&
                                                remarkAccess && (
                                                  <ThreeDotMenu
                                                    {...(props.reviewerAccess && remarkStatus != "Closed"
                                                      ? {
                                                          Edit: () => {
                                                            setPageTitle(val.pageTitle);
                                                            setOpen(true);
                                                            setShowComment(true);
                                                            setRemarkId(item.id);
                                                            setViewDispositionSection(true);
                                                            // props.setHeight("49.4vh")
                                                            setCustomHeight(true);
                                                            setHideAccordion(false);
                                                            formik.setValues({ remarkComment: item.remarkComment });
                                                            let disposition = selectedDisposition;
                                                            item.remarkDispositions.map((res) => {
                                                              disposition[res.dispositionName] = true;
                                                              checkedBox.push({
                                                                dispositionId: res.dispositionId,
                                                                dispositionName: res.dispositionName,
                                                                dispositionCategory: res?.dispositionCategory || "Standard",
                                                              });
                                                            });
                                                            setSelectedDisposition(disposition);
                                                          },
                                                        }
                                                      : {})}
                                                    {...(props.reviewerAccess &&
                                                      showRemarkStatus.includes(remarkStatus) && {
                                                        Open: async () => {
                                                          let payload = {
                                                            remarkStatus: "Open",
                                                            remarkByUserId: loggedInUser._id,
                                                          };
                                                          await updateRemark(item.id, payload);
                                                          props.reviewerAccess && props.creatorAccess && setHideButton(true);
                                                          loadRemarks(props.remarkEntityId, props.storePageId);
                                                        },
                                                      })}
                                                    {...(props.reviewerAccess && {
                                                      Close: async () => {
                                                        let payload = {
                                                          remarkStatus: "Closed",
                                                          remarkByUserId: loggedInUser._id,
                                                        };
                                                        await updateRemark(item.id, payload);
                                                        loadRemarks(props.remarkEntityId, props.storePageId);
                                                      },
                                                    })}
                                                    {...(hideButton && remarkStatus == "Open"
                                                      ? {
                                                          Resolve: async () => {
                                                            let payload = {
                                                              remarkStatus: "Resolved",
                                                              remarkByUserId: loggedInUser._id,
                                                            };
                                                            await updateRemark(item.id, payload);
                                                            props.reviewerAccess && props.creatorAccess && setHideButton(!hideButton);

                                                            // props.creatorAccess && setViewOption(false)
                                                            loadRemarks(props.remarkEntityId, props.storePageId);
                                                          },
                                                        }
                                                      : {})}
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="sel-dis">
                                          {item.remarkDispositions.map((data) => {
                                            return (
                                              <ul className="mb-0">
                                                <li>{data.dispositionName}</li>
                                              </ul>
                                            );
                                          })}
                                        </div>
                                        <div className="sel-dis-1">
                                          <span className="text-trim">{item.remarkComment}</span>
                                        </div>

                                        {/* load remarkReply component after each remark block  */}
                                        <div style={{ marginBottom: "10px" }}>
                                          {activeRemark === item.id && showAllReply === true ? (
                                            <MdExpandMore
                                              color="#707070"
                                              cursor="pointer"
                                              size={24}
                                              onClick={() => {
                                                setShowAllReply(false);
                                                setActiveRemark(item.id);
                                              }}
                                            />
                                          ) : (
                                            item.remarkReply.length > 0 && (
                                              <MdKeyboardArrowRight
                                                color="#707070"
                                                size={24}
                                                cursor="pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setSingleRemark(saveRemark);
                                                  setShowAllReply(true);
                                                  setActiveRemark(item.id);
                                                }}
                                              />
                                            )
                                          )}
                                          <a
                                            className="reply-btn"
                                            onClick={() => {
                                              if (item.remarkReply.length < 1) {
                                                setSingleRemark(saveRemark);
                                                setShowAllReply(true);
                                              }
                                              setActiveRemark(item.id);
                                            }}
                                          >
                                            {item.remarkReply.length > 0 && item.remarkReply.length}&nbsp;&nbsp;Reply
                                          </a>
                                          {activeRemark === item.id && showAllReply === true && (
                                            <RemarkReply
                                              {...props}
                                              remarkStatus={remarkStatus}
                                              remarkId={item.id}
                                              updateRemarkData={updateRemarkData}
                                              activeRemark={activeRemark}
                                              singleRemark={singleRemark}
                                              handleSingleRemark={(respnose) => setSingleRemark(respnose)}
                                              handleUpdateRemarkData={(respnose) => setUpdateRemarkData(respnose)}
                                              loadRemarks={loadRemarks}
                                              // pageId={val.id}
                                              open={open}
                                              setOpen={setOpen}
                                              // getRemarkData={getRemarkData}
                                              // handleActiveRemark={(respnose)=>setActiveRemark(respnose)}
                                              handleShowAllReply={(respnose) => setShowAllReply(respnose)}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>

                              {/* load block remark */}
                              <div>
                                {val.block.map((value, keyval) => {
                                  let reportRemarkStatus = value.remarkStatus;

                                  return (
                                    <>
                                      {hideAccordion && (
                                        <div className={`${reportRemarkStatus}-border-color`} style={{ background: "#FBFBFB" }}>
                                          <div className="child-accordion">
                                            <div
                                              className="remark-page-title"
                                              title={value.pageTitle}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                props.fetchSinglePageContent(props.remarkEntityId, value.id);
                                                props.setShowSave(true);
                                                props.setLoadContentBuilder(true);
                                                setPageId(value.id);
                                                setReadMode(false);
                                              }}
                                            >
                                              {value.pageTitle}
                                            </div>
                                            {showRemark && showActiveRemarkKey === keyval ? (
                                              <MdExpandLess
                                                color="#707070"
                                                cursor="pointer"
                                                size={26}
                                                style={{ margin: "9px 0px 0px 13px" }}
                                                onClick={() => {
                                                  setShowRemark(false);
                                                  setShowActiveRemarkKey(keyval);
                                                }}
                                              />
                                            ) : (
                                              <MdExpandMore
                                                color="#707070"
                                                cursor="pointer"
                                                size={26}
                                                style={{ margin: "9px 0px 0px 13px" }}
                                                onClick={() => {
                                                  // getBlockRemark(value.pageId)
                                                  setShowRemark(true);
                                                  setShowActiveRemarkKey(keyval);
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      {value.remark.map((response, _keyValue) => {
                                        let saveReportRemark = response;
                                        let reportRemarkStatus = response.remarkStatus;
                                        let reportDate = getDateFromMongoDate(response.remarkDate);

                                        return (
                                          <div>
                                            {showRemark && showActiveRemarkKey === keyval && (
                                              <div className={reportRemarkStatus} style={{ background: "#FBFBFB " }}>
                                                <div className="row">
                                                  <div className="col-md-1">
                                                    <FaUser
                                                      size={26}
                                                      style={{ width: "auto", minWidth: "34px", minHeight: "26px", marginTop: "5px" }}
                                                      color="#A1A1A1"
                                                    />
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div style={{ marginLeft: "5px" }}>
                                                      <div
                                                        className="remark-user-name"
                                                        title={`${response.remarkByUserFname} ${response.remarkByUserLname}`}
                                                      >
                                                        {response.remarkByUserFname}&nbsp;{response.remarkByUserLname}
                                                      </div>
                                                      <div className="datedata">{reportDate}</div>
                                                      <br />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <span className={`${reportRemarkStatus}-btn`}>{reportRemarkStatus}</span>
                                                    <div style={{ right: "0px", position: "absolute", top: "0px" }}>
                                                      {viewOption &&
                                                        reportRemarkStatus !== "Closed" &&
                                                        ((props.reviewerAccess && reportRemarkStatus !== "Closed") ||
                                                          (props.creatorAccess && reportRemarkStatus == "Open")) &&
                                                        remarkAccess && (
                                                          <ThreeDotMenu
                                                            {...(props.reviewerAccess && reportRemarkStatus != "Closed"
                                                              ? {
                                                                  Edit: () => {
                                                                    setShowRemark(true);
                                                                    setShowComment(true);
                                                                    setRemarkId(response.id);
                                                                    setViewDispositionSection(true);
                                                                    setPageTitle(value.pageTitle);
                                                                    // props.setHeight("49.4vh")
                                                                    setCustomHeight(true);
                                                                    setHideAccordion(false);
                                                                    formik.setValues({ remarkComment: response.remarkComment });
                                                                    let disposition = selectedDisposition;
                                                                    response.remarkDispositions.map((res) => {
                                                                      disposition[res.dispositionName] = true;
                                                                      checkedBox.push({
                                                                        dispositionId: res.dispositionId,
                                                                        dispositionName: res.dispositionName,
                                                                        dispositionCategory: res?.dispositionCategory || "Standard",
                                                                      });
                                                                    });

                                                                    setSelectedDisposition(disposition);
                                                                  },
                                                                }
                                                              : {})}
                                                            {...(props.reviewerAccess &&
                                                              showRemarkStatus.includes(reportRemarkStatus) && {
                                                                Open: async () => {
                                                                  let payload = {
                                                                    remarkStatus: "Open",
                                                                    remarkByUserId: loggedInUser._id,
                                                                  };
                                                                  await updateRemark(response.id, payload);
                                                                  props.reviewerAccess && props.creatorAccess && setHideButton(true);
                                                                  loadRemarks(props.remarkEntityId);
                                                                },
                                                              })}
                                                            {...(props.reviewerAccess && {
                                                              Close: async () => {
                                                                let payload = {
                                                                  remarkStatus: "Closed",
                                                                  remarkByUserId: loggedInUser._id,
                                                                };
                                                                await updateRemark(response.id, payload);
                                                                loadRemarks(props.remarkEntityId);
                                                              },
                                                            })}
                                                            {...(hideButton && reportRemarkStatus == "Open"
                                                              ? {
                                                                  Resolve: async () => {
                                                                    let payload = {
                                                                      remarkStatus: "Resolved",
                                                                      remarkByUserId: loggedInUser._id,
                                                                    };
                                                                    await updateRemark(response.id, payload);
                                                                    props.reviewerAccess && props.creatorAccess && setHideButton(!hideButton);
                                                                    loadRemarks(props.remarkEntityId);
                                                                  },
                                                                }
                                                              : {})}
                                                          />
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="sel-dis">
                                                  {response.remarkDispositions.map((data) => {
                                                    return (
                                                      <ul className="mb-0">
                                                        <li>{data.dispositionName}</li>
                                                      </ul>
                                                    );
                                                  })}
                                                </div>
                                                <div className="sel-dis-1">
                                                  <span className="text-trim">{response.remarkComment}</span>
                                                </div>

                                                {/* load remarkReply component after each remark block  */}
                                                <div style={{ marginBottom: "10px" }}>
                                                  {viewActiveKey === response.id && getRemarkReply === true ? (
                                                    <MdExpandMore
                                                      color="#707070"
                                                      cursor="pointer"
                                                      size={24}
                                                      onClick={() => {
                                                        setGetRemarkReply(false);
                                                        setViewActiveKey(response.id);
                                                      }}
                                                    />
                                                  ) : (
                                                    response.remarkReply.length > 0 && (
                                                      <MdKeyboardArrowRight
                                                        color="#707070"
                                                        size={24}
                                                        cursor="pointer"
                                                        onClick={() => {
                                                          setSingleRemark(saveReportRemark);
                                                          setGetRemarkReply(true);
                                                          setViewActiveKey(response.id);
                                                        }}
                                                      />
                                                    )
                                                  )}
                                                  <a
                                                    className="reply-btn"
                                                    onClick={() => {
                                                      if (response.remarkReply.length < 1) {
                                                        setSingleRemark(saveReportRemark);
                                                        setGetRemarkReply(true);
                                                      }
                                                      setViewActiveKey(response.id);
                                                    }}
                                                  >
                                                    {response.remarkReply.length > 0 && response.remarkReply.length}&nbsp;&nbsp;Reply
                                                  </a>
                                                  {viewActiveKey === response.id && getRemarkReply === true && (
                                                    <RemarkReply
                                                      {...props}
                                                      remarkStatus={reportRemarkStatus}
                                                      remarkId={response.id}
                                                      updateRemarkData={updateRemarkData}
                                                      activeRemark={viewActiveKey}
                                                      singleRemark={singleRemark}
                                                      handleSingleRemark={(resp) => setSingleRemark(resp)}
                                                      handleUpdateRemarkData={(resp) => setUpdateRemarkData(resp)}
                                                      loadRemarks={loadRemarks}
                                                      handleShowAllReply={(resp) => setGetRemarkReply(resp)}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}

              {/* load content block remark */}
              {!props.fetchRemark && updateRemarkData
                ? updateRemarkData.map((item, key) => {
                    let saveRemark = item;
                    let remarkStatus = item.remarkStatus;
                    let date = getDateFromMongoDate(item.remarkDate);
                    return (
                      <React.Fragment>
                        {
                          <div className={remarkStatus}>
                            <div className="row">
                              <div className="col-md-1">
                                <FaUser size={26} className="remark-user-icon" color="#A1A1A1" />
                              </div>
                              <div className="col-md-6">
                                <div style={{ marginLeft: "5px" }}>
                                  <div className="remark-user-name" title={`${item.remarkByUserFname} ${item.remarkByUserLname}`}>
                                    {item.remarkByUserFname}&nbsp;{item.remarkByUserLname}
                                  </div>
                                  <div className="datedata">{date}</div>
                                  <br />
                                </div>
                              </div>
                              <div className="col-md-4 ">
                                <span className={`${remarkStatus}-btn`}>{remarkStatus}</span>
                                <div style={{ right: "0px", position: "absolute", top: "0px" }}>
                                  {viewOption &&
                                    remarkStatus !== "Closed" &&
                                    ((props.reviewerAccess && remarkStatus !== "Closed") || (props.creatorAccess && remarkStatus == "Open")) && (
                                      <ThreeDotMenu
                                        {...(props.reviewerAccess && remarkStatus != "Closed"
                                          ? {
                                              Edit: () => {
                                                setRemarkId(item.id);
                                                setViewDispositionSection(true);
                                                formik.setValues({ remarkComment: item.remarkComment });
                                                let disposition = selectedDisposition;
                                                item.remarkDispositions.map((res) => {
                                                  disposition[res.dispositionName] = true;
                                                  checkedBox.push({
                                                    dispositionId: res.dispositionId,
                                                    dispositionName: res.dispositionName,
                                                    dispositionCategory: res?.dispositionCategory || "Standard",
                                                  });
                                                });
                                                setSelectedDisposition(disposition);
                                              },
                                            }
                                          : {})}
                                        {...(props.reviewerAccess && showRemarkStatus.includes(remarkStatus)
                                          ? {
                                              Open: async () => {
                                                let payload = {
                                                  remarkStatus: "Open",
                                                  remarkByUserId: loggedInUser._id,
                                                };
                                                await updateRemark(item.id, payload);
                                                props.reviewerAccess && props.creatorAccess && setHideButton(true);
                                                loadRemarks(props.remarkEntityId);
                                              },
                                            }
                                          : {})}
                                        {...(props.reviewerAccess && {
                                          Close: async () => {
                                            let payload = {
                                              remarkStatus: "Closed",
                                              remarkByUserId: loggedInUser._id,
                                            };
                                            await updateRemark(item.id, payload);
                                            loadRemarks(props.remarkEntityId);
                                          },
                                        })}
                                        {...(hideButton && remarkStatus == "Open"
                                          ? {
                                              Resolve: async () => {
                                                let payload = {
                                                  remarkStatus: "Resolved",
                                                  remarkByUserId: loggedInUser._id,
                                                };
                                                await updateRemark(item.id, payload);
                                                // props.creatorAccess && props.reviewerAccess === false && setViewOption(false)
                                                props.reviewerAccess && props.creatorAccess && setHideButton(!hideButton);
                                                loadRemarks(props.remarkEntityId);
                                              },
                                            }
                                          : {})}
                                      />
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="sel-dis">
                              {item.remarkDispositions.map((data) => {
                                return (
                                  <ul className="mb-0">
                                    <li>{data.dispositionName}</li>
                                  </ul>
                                );
                              })}
                            </div>
                            <div className="sel-dis-1">
                              <span className="text-trim">{item.remarkComment}</span>
                            </div>

                            {/* load remarkReply component after each remark block  */}
                            <div style={{ marginBottom: "10px" }}>
                              {activeRemark === item.id && showAllReply === true ? (
                                <MdExpandMore
                                  color="#707070"
                                  cursor="pointer"
                                  size={24}
                                  onClick={() => {
                                    setShowAllReply(false);
                                    setActiveRemark(key);
                                  }}
                                />
                              ) : (
                                item.remarkReply.length > 0 && (
                                  <MdKeyboardArrowRight
                                    color="#707070"
                                    size={24}
                                    cursor="pointer"
                                    onClick={() => {
                                      setSingleRemark(saveRemark);
                                      setShowAllReply(true);
                                      setActiveRemark(item.id);
                                    }}
                                  />
                                )
                              )}
                              <a
                                className="reply-btn"
                                onClick={() => {
                                  if (item.remarkReply.length < 1) {
                                    setSingleRemark(saveRemark);
                                    setShowAllReply(true);
                                  }
                                  setActiveRemark(item.id);
                                }}
                              >
                                {item.remarkReply.length > 0 && item.remarkReply.length}&nbsp;&nbsp;Reply
                              </a>
                              {activeRemark === item.id && showAllReply === true && (
                                <RemarkReply
                                  {...props}
                                  remarkStatus={remarkStatus}
                                  remarkId={item.id}
                                  updateRemarkData={updateRemarkData}
                                  activeRemark={activeRemark}
                                  singleRemark={singleRemark}
                                  handleSingleRemark={(val) => setSingleRemark(val)}
                                  handleUpdateRemarkData={(val) => setUpdateRemarkData(val)}
                                  loadRemarks={loadRemarks}
                                  handleShowAllReply={(val) => setShowAllReply(val)}
                                />
                              )}
                            </div>
                          </div>
                        }
                      </React.Fragment>
                    );
                  })
                : ""}
            </div>
          </div>
        }
        {viewDispositionSection && (
          <ActionButton
            {...props}
            checkedBox={checkedBox}
            setCheckedBox={setCheckedBox}
            dispositionList={dispositionList}
            remarkId={remarkId}
            formik={formik}
            remarkComment={formik.values.remarkComment}
            selectedDisposition={selectedDisposition}
            setSelectedDisposition={setSelectedDisposition}
            loadRemarks={loadRemarks}
            setViewDispositionSection={setViewDispositionSection}
            setShowComment={setShowComment}
            customHeight={customHeight}
            setHideAccordion={setHideAccordion}
            setSelectedDispositions={setSelectedDispositions}
            selectedDispositions={selectedDispositions}
          />
        )}
      </div>
    </div>
  );
};
export default Remark;
