/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : Footer bar for deliverable Add | Edit Page
 ---------------------------------------------------------------------------------
	Creation Details
	Date Created				: 21/Aug/2020
	Author						: Aditya Tijare
================================================================
*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataPublishingAction from "../../../../dataPublishing/deliverable/actionButton";
import { accessRightActionCheck } from "../../../../../../utilities";
import { COMMON_ACCESS_ACTION, DELIVERABLE_TYPE } from "../../../../../../constants";

const FooterBar = (props) => {
  const { deliverableContentStatus, selectedIndex } = props;
  const [cancelRedirectLink, setCancelRedirectLink] = useState("/deliverable");
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  useEffect(() => {
    if (props.pageId && props.activeStepper == "#content") {
      setCancelRedirectLink(`/deliverable/${props.deliverableId}/edit#content`);
    } else {
      setCancelRedirectLink(`/deliverable`);
    }
  }, [props.pageId]);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = Object.values(props?.hashArr)[props?.selectedIndex];
    let actionAccess = accessRightActionCheck(props?.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [props.selectedIndex]);

  let actionAccessSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  let actionAccessApprove = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.APPROVE);
  let actionAccessReject = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.REJECT);
  let actionAccessSubmit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SUBMIT);
  let actionAccessPublish = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.PUBLISH);

  return (
    <div className="form-submit-box" style={{ padding: "10px 49px 10px 20px" }}>
      <div className="container-fluid nopadding">
        <div className="row">
          <div className="float-left col-md-4 text-left pl-0">
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="span-link mr-3">
                <button
                  type="button"
                  className="cy-prev-btn btn btn-outline-dark pl-4 pr-4 cy-btn "
                  onClick={() => {
                    props.deliverableType === DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE
                      ? (props.selectedIndex === undefined && props.setCurrentTab(2)) ||
                        (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1))
                      : props.deliverableDocuments
                      ? (props.selectedIndex === undefined && props.setCurrentTab(2)) ||
                        (props.selectedIndex <= 4 && props.setCurrentTab(props.selectedIndex - 4))
                      : (props.selectedIndex === undefined && props.setCurrentTab(2)) ||
                        (props.selectedIndex < 5 && props.setCurrentTab(props.selectedIndex - 1));
                  }}
                >
                  Previous
                </button>
              </span>
            ) : null}

            {props.deliverableType === DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE && props.deliverableDocuments ? null : props.deliverableId !== "" &&
              props.selectedIndex > -1 &&
              props.selectedIndex < 4 ? (
              <span className="span-link">
                {props.deliverableType === DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE && props.selectedIndex === 1 ? null : (
                  <button
                    type="button"
                    className="cy-next-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                    onClick={() => {
                      props.deliverableType === DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE
                        ? props.selectedIndex < 1 && props.setCurrentTab(props.selectedIndex + 1)
                        : props.deliverableDocuments
                        ? props.selectedIndex < 1 && props.setCurrentTab(props.selectedIndex + 4)
                        : props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                    }}
                  >
                    Next
                  </button>
                )}
              </span>
            ) : null}
          </div>
          <div className="float-right col-md-8 text-right " style={{ paddingRight: "5px" }}>
            <Link to={cancelRedirectLink}>
              <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 ">
                Cancel
              </button>
            </Link>
            <button type="button" className="btn btn-primary pl-4 pr-4 mr-3" style={{ display: "none" }} id="btn-save-as-template">
              Save As Template
            </button>
            <span id="span-save-as-template"></span>
            {(props.showSave && [2].indexOf(selectedIndex) != -1 && props.contentTabAccess) ||
            (((actionAccessSave && ["In Progress", "Rejected", "Published", "Approved", "Submitted", ""].includes(deliverableContentStatus)) ||
              actionAccessApprove) &&
              [0, 2].indexOf(selectedIndex) != -1 &&
              props.showSave) ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 my-1 ml-3"
                id="dev-deliverable-save"
                onClick={() => {
                  props.handleSave(true);
                }}
              >
                Save
              </button>
            ) : null}
            {actionAccessSave && [].indexOf(selectedIndex) != -1 && props.showSave ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 my-1 ml-3"
                id="dev-deliverable-save"
                onClick={() => {
                  props.handleSave(true);
                }}
              >
                Save
              </button>
            ) : null}
            {((actionAccessSubmit && ["In Progress", "Rejected", ""].includes(deliverableContentStatus)) || actionAccessApprove) &&
            [2].indexOf(selectedIndex) != -1 &&
            props.contentTabAccess ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 my-1 ml-3"
                id="dev-deliverable-save"
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                Submit
              </button>
            ) : null}
            {props.contentTabAccess && actionAccessReject && [2].indexOf(selectedIndex) != -1 ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 my-1 ml-3"
                id="dev-deliverable-save"
                onClick={() => {
                  props.handleReject();
                }}
              >
                Reject
              </button>
            ) : null}
            {props.contentTabAccess && actionAccessApprove && [2].indexOf(selectedIndex) != -1 ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 my-1 ml-3 "
                id="dev-deliverable-save"
                onClick={() => {
                  props.handleApprove();
                }}
              >
                Approve
              </button>
            ) : null}
            {actionAccessPublish || [0, 2, 4].indexOf(selectedIndex) != -1 ? (
              <DataPublishingAction deliverableId={props.deliverableId} {...props} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBar;
