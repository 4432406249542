/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Application Sagas (Redux Middleware)
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import technologySaga from "./technologySaga"
import trendsSaga from "./trendsSaga"
import watchGetSgfTypeSearch from "./sgfSaga"
import watchGetContentTypeSearch from "./contentTypeSaga"
import industrySaga from "./industrySaga"

export default function* rootSaga(getState) {
	yield all([
		...authSaga,
		...technologySaga,
		...trendsSaga,
		...industrySaga,
		watchGetSgfTypeSearch(),
		watchGetContentTypeSearch(),
	]);
}
