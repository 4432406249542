/**
 * @fileoverview This file contains common top search box component.
 * @date 22/Nov/2023
 * @author Lawrence Anthony
 * Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
 */

import React, { useState, useEffect, useRef, useCallback, MutableRefObject } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./topSearchBox.module.css";
import { fetchAutoSuggestion } from "../../../middleware/services/searchServiceApi";
import { components } from "react-select";
import Creatable from "react-select/creatable";

import parse from "react-html-parser";
import { setKeywords } from "../../../middleware/actions/searchAction";
import { useQuery } from "@tanstack/react-query";
import { GridLoaderComponent } from "../loaders";

/**
 * @returns {JSX.Element}
 */
const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <img src="/assets/close_select_btn.svg" alt="close-icon" />
    </components.MultiValueRemove>
  );
};

/**
 * @returns {JSX.Element}
 * @param { MultiValueGenericProps<ColourOption>} props
 */
const MultiValueLabel = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      <p className="small text-dark" style={{ paddingTop: "2px", fontWeight: "bold" }}>
        {props?.data?.value}
      </p>
    </components.MultiValueLabel>
  );
};

/**
 * @returns {JSX.Element}
 * @param { MultiValueGenericProps<ColourOption>} props
 */
const MultiValueContainer = (props) => {
  return (
    <div className="border border-bottom-2 border-gray-300">
      <components.MultiValueContainer {...props}></components.MultiValueContainer>
    </div>
  );
};

// common search used in header, bookmark listing page
const TopSearchBox = (props) => {
  /**
   * @description: searchRef is used to get the reference of the search box
   */
  const searchRef = useRef();
  const dispatch = useDispatch();

  const selectedOption = useSelector((state) => state["searchState"]["keywords"]);

  /**
   * @type {[{label:string; value:string}[], React.Dispatch<React.SetStateAction<{label:string; value:string}[]>>]}
   */
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState("");
  const trendIds = useSelector((state) => state["searchState"]["subscribeSgfs"]);
  const productIndustryIds = useSelector((state) => state["searchState"]["productIndustryIds"]);
  const sgfIds = useSelector((state) => state["searchState"]["sgfIdsSearch"]);

  /**
   * @description This is the search response from solr which is array of terms
   * @type {{data: string[]}}
   */
  const {
    data: searchReponse,
    isLoading: isSearching,
    isFetching: isSearchFetching,
  } = useQuery({
    queryKey: ["searchResults", searchText],
    queryFn: () => fetchAutoSuggestion(searchText, trendIds, sgfIds, productIndustryIds),
    suspense: false,
    enabled: searchText?.length >= 3,
  });

  /**
   *
   * @param {string} text
   * @returns {void}
   */
  const searchKeyword = async (text) => {
    if (text?.length < 3) {
      setDataSource([]);
      return setSearchText(text);
    }
    return setSearchText(text);
  };

  /**
   * @description This function is used to format the label of the search box
   * @param {string} label
   * @returns {JSX.Element}
   */
  const formattedLabel = useCallback(
    (label) => {
      if (searchText?.length < 3)
        return (
          <p style={{ fontSize: "16px", maxHeight: "20px" }} className="text-center">
            Please type at least 3 characters
          </p>
        );
      if (label.includes(`Create "`) && isSearchFetching) return <GridLoaderComponent size={10} style={{ height: "100px" }} />;

      if (label.includes(`Create "`) && dataSource.length === 0)
        return (
          <p style={{ fontSize: "16px", maxHeight: "20px" }} className="text-center">
            No match found
          </p>
        );
      else if (label.includes(`Create "`) && dataSource.length) return <div style={{ marginBottom: "-20px", paddingBottom: "-20px" }}></div>;
      return (
        <p className="small text-dark" style={{ height: "10px" }}>
          {parse(label)}
        </p>
      );
    },
    [dataSource, isSearchFetching, searchText]
  );

  /**
   *
   * @param {{label:string; value:string}[]} selectedOption
   * @returns {void}
   */
  const handleChange = (selectedOption) => {
    dispatch(setKeywords(selectedOption));
    setDataSource([]);
    if (selectedOption?.length) props.onTextChange(selectedOption.map((item) => item.value).join(" | "));
    else props.onTextChange([]);
  };

  /**
   * @param {OptionProps<ColourOption>} props
   * @returns {JSX.Element}
   */
  const Option = useCallback(
    (props) => {
      if (props?.value?.length > 2 && props.label?.includes(`Create "`) && dataSource?.length > 0) return null;
      if (props?.value?.length > 2 && props.label?.includes(`Create "`) && !isSearchFetching && dataSource?.length === 0)
        return (
          <p style={{ fontSize: "16px", maxHeight: "20px" }} className="text-center">
            No match found
          </p>
        );
      return <components.Option {...props} />;
    },
    [dataSource, isSearchFetching]
  );

  function containsSpecialCharacters(inputString) {
    // Define a regular expression pattern that matches any characters
    // that are not in the set of allowed characters.
    const allowedCharacters = /^[a-zA-Z0-9\s]+$/; // This allows letters, numbers, and spaces.

    // Test if the input string contains any characters that are not allowed.
    return !allowedCharacters.test(inputString);
  }

  useEffect(() => {
    if (!searchReponse) return;
    if (searchReponse?.length === 0) return setDataSource([]);

    let searchRegex;

    if (containsSpecialCharacters(searchText)) {
      searchRegex = searchText;
    } else {
      searchRegex = new RegExp(searchText, "gi");
    }

    // ** First we map to convert bold lletters
    const modifiedResponse =
      searchReponse
        ?.map((term) => {
          if (term.toLowerCase().includes(searchText.toLowerCase())) return term.replace(searchRegex, `<strong>$&</strong>`);
        })
        .filter(Boolean) || [];
    if (!modifiedResponse || modifiedResponse?.length === 0) return setDataSource([]);

    // ** this is to convert string[] of search response to select options
    const resData = modifiedResponse.map((term) => {
      const bTagContent = term.match(/<strong>(.*?)<\/strong>/);
      const words = term.split(" ");
      if (words.length >= 25 || term.length >= 200) {
        // Take the first three words
        const beginning = words.slice(0, 3).join(" ");

        // Take the last three words
        const end = words.slice(-3).join(" ");

        // Concatenate the parts with ellipses
        if (bTagContent) return { value: term.replace(/<\/?[^>]+(>|$)/g, ""), label: `${beginning} ... <strong>${bTagContent}</strong> ... ${end}` };
        else return { value: term.replace(/<\/?[^>]+(>|$)/g, ""), label: `${beginning} ... ${end}` };
      } else {
        return { value: term.replace(/<\/?[^>]+(>|$)/g, ""), label: term };
      }
    });
    if (!resData || resData?.length === 0) return setDataSource([]);

    resData.sort((value) => {
      const textValue = value.value.toLowerCase();

      if (textValue === searchText.toLowerCase()) {
        return -1;
      }
    });

    setDataSource(resData);
  }, [searchReponse, searchText]);

  // set initial width of search box
  useEffect(() => {
    if (selectedOption?.length) props.onTextChange(selectedOption.map((item) => item.value).join(" | "));
  }, []);

  return (
    <div className={classes.searchBoxMainDivTop}>
      <Creatable
        value={selectedOption}
        ref={searchRef}
        options={dataSource || []}
        components={{ MultiValueRemove, MultiValueLabel, Option }}
        isMulti
        isSearchable
        formatOptionLabel={(op) => formattedLabel(op.label)}
        onInputChange={(e) => {
          searchKeyword(e);
        }}
        onChange={handleChange}
        theme={{
          colors: {
            primary: "#f94f5e",
            primary75: "rgba(0, 0, 0, 0.75)",
            primary50: "rgba(0, 0, 0, 0.5)",
            primary25: "rgba(0, 0, 0, 0.05)",
            neutral90: "rgba(38,38,38,0.9)",
            neutral80: "rgba(38,38,38,0.8)",
            neutral70: "rgba(38,38,38,0.7)",
            neutral60: "rgba(38,38,38,0.6)",
            neutral50: "rgba(38,38,38,0.5)",
            neutral40: "rgba(38,38,38,0.4)",
            neutral30: "rgba(38,38,38,0.3)",
            neutral20: "rgba(38,38,38,0.2)",
            neutral10: "rgba(38,38,38,0.1)",
            neutral5: "rgba(38,38,38,0.05)",
            neutral0: "rgba(38,38,38,0)",
            danger: "#DE350B",
            dangerLight: "#FF3434",
          },
        }}
        createOptionPosition="first"
        className="site-search-input-box w-100"
        classNamePrefix="select"
        styles={{
          container: (provided) => ({
            ...provided,
            width: "100%",
            zIndex: 1000,
          }),
          control: (provided) => ({
            ...provided,
            width: "100%",
            backgroundColor: "white",
            maxHeight: "40px",
            zIndex: 1000,
          }),
          valueContainer: (provided) => ({
            ...provided,
            maxHeight: "35px",
            overflow: "auto",
            padding: "0px 8px",
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
            boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.06)",
            borderRadius: "0.375rem", // equivalent to 'rounded-md'
            marginTop: "-6px",
            fontSize: "16px",
            minHeight: "60px",
          }),
          multiValue: (provided) => ({
            ...provided,
            maxHeight: "30px",
            margin: "0px 2px 0px 0px",
            border: "1px solid #d2d6dc",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            display: "none",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
        }}
        placeholder="Enter Keyword e.g. Mobility Cloud"
        noOptionsMessage={() => {
          const message =
            searchRef?.current?.inputRef?.value?.trim() && searchRef?.current?.inputRef?.value?.trim() !== "" && dataSource?.length === 0
              ? "No match found"
              : "Please type at least 3 characters";
          return <>{message}</>;
        }}
        allowCreateWhileLoading={false}
        autoFocus={false}
      />
      <div
        className={classes.searchIcon}
        onClick={() => {
          props.onClick();
        }}
      >
        <img src="/assets/search.svg" alt="searchIcon" />
      </div>
    </div>
  );
};

export default TopSearchBox;
