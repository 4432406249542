/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Work Experiance Listing Page
	 - Use common grid component for list
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 07/Jul/2021 
	Author						: Pankaj Kulshreshtha
================================================================ 
*/
import React, { useEffect, useState } from "react";
import Accordion from "../../../../components/accordion";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { fetchExperience, deleteExperience } from "../../../../../middleware/services/userApi";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../../../middleware/actions/utilityAction";
import AddExpModel from "./add";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import ThreeDotMenu from "../../../../components/threeDotMenu";

export default (props) => {
  const userId = props.userId;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [expData, setExpData] = useState([]);
  const [stepCompleted, setStepCompleted] = useState(false);
  const [expId, setExpId] = useState("");

  let accessActionAddExperience = props?.accessActionAddExperience;
  let accessActionEditExperience = props?.accessActionEditExperience;
  let accessActionDeleteExperience = props?.accessActionDeleteExperience;

  const closeModal = () => {
    setExpId("");
    setShowModal(false);
  };
  /*
		@Description : BreadCrub Creation
	*/
  const breadCrumbLinks = [{ linkUrl: "/user", linkName: props?.moduleName || "External User", linkActive: true }];

  /* 
		@Description : Use for setting breadcrumb values
	*/

  useEffect(() => {
    if (!showModal) {
      if (userId !== "") {
        formatRowData(userId);
      }
      localStorage.removeItem("selectedUser");
      dispatch(setBreadCrumb(breadCrumbLinks));
      return () => {
        dispatch(setBreadCrumb());
        localStorage.removeItem("selectedUser");
      };
    }
  }, []);

  // formatRowData function
  const formatRowData = async (userId) => {
    dispatch(showLoader());
    try {
      let formatedRowData = [];
      const response = await fetchExperience(userId);
      if (response.status == 200) {
        dispatch(hideLoader());
        var userData = response.data.data;
        let experiences = userData.userWorkExperience;
        if (experiences.length > 0) {
          setStepCompleted(true);
          experiences.forEach((exp) => {
            let data = {};
            data["expId"] = exp.id;
            data["CompanyName"] = exp.workCompanyName;
            data["Designation"] = exp.workDesignation;
            data["From"] = exp.workStartDate;
            data["To"] = exp.workEndDate;
            data["Role"] = Array.isArray(exp.workRole) ? exp.workRole.join(", ") : exp.workRole;
            formatedRowData.push(data);
          });
        }
      }
      setExpData(formatedRowData);
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  const removeExp = (expId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          deleteExperience(userId, expId)
            .then((response) => {
              dispatch(actionSuccess("Work experience deleted successfully"));
              formatRowData(userId);
            })
            .catch((err) => {
              dispatch(actionError(err.data?.message || "Fail to delete Experiecnce"));
            });
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to delete?",
        title: "dialogAlertCssWarning",
      })
    );
  };

  return (
    <>
      {userId && (
        <Accordion
          key={`accord-3`}
          heading="Work Experience"
          step={3}
          stepCompleted={expData && expData.length ? true : false}
          activeState={!stepCompleted}
        >
          {showModal && <AddExpModel showModal={showModal} closeModal={closeModal} userId={userId} expId={expId} formatRowData={formatRowData} />}
          <div className="row">
            {expData.map(function (x, i) {
              return (
                <React.Fragment key={`experience-${i}`}>
                  <div className="col-md-4 pb-4 pt-3" style={{ position: "relative" }}>
                    <div style={{ display: `flex`, flexDirection: `column` }}>
                      <div className="e-card e-card-horizontal" style={{ overflow: "unset" }}>
                        <div className="e-card-stacked">
                          <div style={{ position: "absolute", top: "5px", right: "0" }}>
                            {accessActionEditExperience || accessActionDeleteExperience ? (
                              <ThreeDotMenu
                                Edit={
                                  accessActionEditExperience
                                    ? () => {
                                        showModal && setShowModal(false);
                                        setShowModal(true);
                                        setExpId(x["expId"]);
                                      }
                                    : undefined
                                }
                                Delete={
                                  accessActionDeleteExperience
                                    ? () => {
                                        removeExp(x["expId"]);
                                      }
                                    : undefined
                                }
                              />
                            ) : null}
                          </div>
                          <div className="e-card-header"></div>
                          <div className="row pl-4" style={{ position: "relative", top: "-10px" }}>
                            <div className="col-md-12">
                              <small className="text-muted">Company Name</small>
                            </div>
                            <div className="col-md-12 ">
                              <span style={{ fontFamily: "Roboto" }} className="font-bold-500">
                                {x["CompanyName"]}
                              </span>
                            </div>
                            <div className="col-md-12">
                              <small className="text-muted">Role</small>
                            </div>
                            <div className="col-md-12">
                              <span style={{ fontFamily: "Roboto" }} className="font-bold-500">
                                {x["Role"]}
                              </span>
                            </div>
                            <div className="col-md-12">
                              <small className="text-muted">Designation</small>
                            </div>
                            <div className="col-md-12">
                              <span style={{ fontFamily: "Roboto" }} className="font-bold-500">
                                {x["Designation"]}
                              </span>
                            </div>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-3">
                                  <small className="text-muted">Start Date</small>
                                </div>
                                <div className="col-md-6">
                                  <small className="text-muted">End Date</small>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6">
                                  <span style={{ fontFamily: "Roboto" }} className="font-bold-500">
                                    {new Date(x["From"]).toLocaleString("en-us", { month: "short", year: "numeric" })}
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <span style={{ fontFamily: "Roboto" }} className="font-bold-500">
                                    {x["To"] === "Present" ? x["To"] : new Date(x["To"]).toLocaleString("en-us", { month: "short", year: "numeric" })}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className="p-2 ml-2 border-0">
            {accessActionAddExperience && (
              <a className="add-link" onClick={() => setShowModal(true)}>
                {" "}
                + Add Experience
              </a>
            )}
          </div>
        </Accordion>
      )}
    </>
  );
};
