import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import CustomizableWidgets from "../app/views/customizableWidgets";

const CustomizableWidgetsRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/customizable-widgets"
      component={CustomizableWidgets}
      pageHeading="GenNx CMS - Customizable Dashboard"
      accessRights={{
        moduleName: "Customizable Widgets",
        parentModuleName: "Customizable Widgets",
        interfaceName: "Listing",
      }}
    />
  </Switch>
);

export default CustomizableWidgetsRoutes;
