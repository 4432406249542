import { DELIVERABLE_API as API } from "../../config/apiConfig";
import { DELIVERABLE_URL } from "../../config";

export function fetchDeliverableType(params) {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/deliverable/deliverableType",
    method: "GET",
    params: params,
  });
}

export function fetchDeliverables(params) {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/deliverable/all",
    method: "GET",
    params: params,
  });
}

export function fetchSingleDeliverable(deliverableId, params = "") {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/deliverable/all/${deliverableId}`,
    method: "GET",
    params: params,
  });
}

export function fetchDeliverableTaxonomy(deliverableId) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/technology`,
    method: "GET",
  });
}

export function fetchDeliverableValueChain(deliverableId) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/industry-value-chain`,
    method: "GET",
  });
}

export function fetchDeliverableTrend(deliverableId) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/trend`,
    method: "GET",
  });
}

export function saveOverView(payload) {
  return API({
    url: "/deliverable/all",
    method: "POST",
    data: payload,
  });
}

export function updateOverView(deliverableId, payload) {
  return API({
    url: `/deliverable/all/${deliverableId}`,
    method: "PUT",
    data: payload,
  });
}

export function saveDeliverableTaxonomy(deliverableId, payload) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/technology`,
    method: "POST",
    data: payload,
  });
}

export function saveDeliverableTrend(deliverableId, payload) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/trend`,
    method: "POST",
    data: payload,
  });
}

export function deleteDeliverableTrend(deliverableId, trendId) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/trend/${trendId}`,
    method: "DELETE",
  });
}

export function saveDeliverableValueChain(deliverableId, payload) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/industry-value-chain`,
    method: "POST",
    data: payload,
  });
}

export function deleteDeliverableValueChain(deliverableId, trendId) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/industry-value-chain/${trendId}`,
    method: "DELETE",
  });
}

export function deleteDeliverableTaxonomy(deliverableId, taxonomyId) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/technology/${taxonomyId}`,
    method: "DELETE",
  });
}

export function updateDeliverable(deliverableId, payload) {
  return API({
    url: `/deliverable/all/${deliverableId}`,
    method: "PUT",
    data: payload,
  });
}

export function deleteDeliverable(deliverableId) {
  return API({
    url: `/deliverable/${deliverableId}`,
    method: "DELETE",
  });
}

export function deleteManyDeliverable(params, payload) {
  const { filters } = params;
  if (filters) params.filters = JSON.stringfiy(filters);

  return API({
    url: "/deliverable",
    method: "DELETE",
    params: params,
    data: payload,
  });
}

export function fetchPages(deliverableId, params) {
  const { filters, fields } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);

  return API({
    url: `/deliverable/${deliverableId}/page`,
    method: "GET",
    params: params,
  });
}

export function singlePage(deliverableId, pageId) {
  return API({
    url: `/deliverable/${deliverableId}/page/${pageId}`,
    method: "GET",
  });
}

// Update single page
export function updatePage(deliverableId, pageId, payload) {
  return API({
    url: `/deliverable/${deliverableId}/page/${pageId}`,
    method: "PUT",
    data: payload,
  });
}
// Delete single page
export function deletePage(deliverableId, pageId) {
  return API({
    url: `/deliverable/${deliverableId}/page/${pageId}`,
    method: "DELETE",
  });
}
export function fetchDeliverableImageUrl(fileName) {
  return `${DELIVERABLE_URL}/deliverable/image/${fileName}`;
}

export function fetchDeliverablePages(filename) {
  return `${DELIVERABLE_URL}/deliverable/pages/${filename}`;
}

export function savePage(deliverableId, payload) {
  return API({
    url: `/deliverable/${deliverableId}/page`,
    method: "POST",
    data: payload,
  });
}

export function fetchSinglePageContent(deliverableId, fileName) {
  return API({
    url: `/deliverable/${deliverableId}/file/${fileName}/${fileName}`,
    method: "GET",
  });
}

export const updatePageContent = (deliverableId, pageId, payload) => {
  return API({
    url: `/deliverable/${deliverableId}/page/${pageId}`,
    method: "PUT",
    data: payload,
  });
};

export function fetchSinglePage(deliverableId, pageId) {
  return API({
    url: `/deliverable/${deliverableId}/page/${pageId}`,
    method: "GET",
  });
}

export const fetchSingleBlock = (blockId, fileName) => {
  return API({
    url: `/block/content-block/${blockId}/${fileName}`,
    method: "GET",
  });
};

export const saveContentBlock = (payload) => {
  return API({
    url: `/block/content-block`,
    method: "POST",
    data: payload,
  });
};

export const fetchAllBlock = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/block/content-block",
    method: "GET",
    params: params,
  });
};

export const updateBlockContent = (blockId, payload) => {
  return API({
    url: `/block/content-block/${blockId}`,
    method: "PUT",
    data: payload,
  });
};

// tag objective
export const saveDeliverableObjective = (payload) => {
  return API({
    url: "/deliverable/objective",
    method: "POST",
    data: payload,
  });
};

//tag objective taxonomy
export const saveDeliverableObjectiveTaxonomy = (payload) => {
  return API({
    url: "/deliverable/objective/taxonomy",
    method: "POST",
    data: payload,
  });
};

export const fetchDeliverableObjectiveTaxonomy = (objectiveId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/deliverable/objective/taxonomy/${objectiveId}`,
    method: "GET",
    params: params,
  });
};

export const deleteDeliverableObjective = (objectiveId, payload) => {
  return API({
    url: `/deliverable/objective/${objectiveId}`,
    method: "DELETE",
    data: payload,
  });
};

export const fetchDeliverableDocument = (deliverableId, actualFileName, fileName) => {
  return `${DELIVERABLE_URL}/deliverable/${deliverableId}/file/${actualFileName}/${fileName}`;
};

//KIA Tagging APis

// Fetch KIA Tagging
export const fetchDeliverableKia = (deliverableId) => {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/kia`,
    method: "GET",
  });
};
// Save KIA tags
export const saveDeliverableKiaTaxonomy = (deliverableId, payload) => {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/kia`,
    method: "POST",
    data: payload,
  });
};
// Delete Kia Te
export const deleteDeliverableKia = (deliverableId, payload) => {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/kia`,
    method: "DELETE",
    data: payload,
  });
};

export const saveDeliverableContent = (deliverableId, payload) => {
  return API({
    url: `/deliverable/${deliverableId}/contentblock`,
    method: "PUT",
    data: payload,
  });
};
export const updateStatusSubmit = (deliverableId, payload) => {
  return API({
    url: `/deliverable/${deliverableId}/submit`,
    method: "PUT",
    data: payload,
  });
};

export const updateStatusReject = (deliverableId, payload) => {
  return API({
    url: `/deliverable/${deliverableId}/reject`,
    method: "PUT",
    data: payload,
  });
};
export const updateStatusApprove = (deliverableId, payload) => {
  return API({
    url: `/deliverable/${deliverableId}/approve`,
    method: "PUT",
    data: payload,
  });
};
//Update setting
export const updateDeliverableSetting = (deliverableId, payload) => {
  return API({
    url: `/deliverable/${deliverableId}/settings`,
    method: "PUT",
    data: payload,
  });
};
//Get setting
export const fetchDeliverableSetting = (deliverableId) => {
  return API({
    url: `/deliverable/${deliverableId}/settings`,
    method: "GET",
  });
};
//get all tasks of a deliverable
export function fetchDeliverableTasks(deliverableId, params) {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/deliverable/${deliverableId}/task`,
    method: "GET",
    params: params,
  });
}
// get single task of a deliverable
export function fetchSingleTask(deliverableId, taskId, params) {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/deliverable/${deliverableId}/task/${taskId}`,
    method: "GET",
    params: params,
  });
}
// save task of a deliverable
export function saveDeliverableTask(deliverableId, payload) {
  return API({
    url: `/deliverable/${deliverableId}/task`,
    method: "POST",
    data: payload,
  });
}
// update task of a deliverable
export function updateDeliverableTask(deliverableId, taskId, payload) {
  return API({
    url: `/deliverable/${deliverableId}/task/${taskId}`,
    method: "PUT",
    data: payload,
  });
}
// delete task of a deliverable
export function deleteDeliverableTask(deliverableId, taskId) {
  return API({
    url: `/deliverable/${deliverableId}/task/${taskId}`,
    method: "DELETE",
  });
}

export function deleteManyDeliverableTrend(deliverableId, taxonomyIds = []) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/trend`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
}

export function deleteManyDeliverableTechnology(deliverableId, taxonomyIds = []) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/technology`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
}

export function deleteManyDeliverableValueChain(deliverableId, payload) {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/industry-value-chain`,
    method: "DELETE",
    data: payload,
  });
}
// Taxonomy bulk tagging
export const saveDeliverableTaxonomyTags = (payload) => {
  return API({
    url: `/deliverable/taxonomy/technology`,
    method: "PUT",
    data: payload,
  });
};

// update  kia Tagging
export const saveDeliverableKiaTags = (payload) => {
  return API({
    url: `/deliverable/taxonomy/kia`,
    method: "PUT",
    data: payload,
  });
};

// update  value chain Tagging
export const saveDeliverableValueChainTags = (payload) => {
  return API({
    url: `/deliverable/taxonomy/industry-value-chain`,
    method: "PUT",
    data: payload,
  });
};

// update  trend Tagging
export const saveDeliverableTrendTags = (payload) => {
  return API({
    url: `/deliverable/taxonomy/trend`,
    method: "PUT",
    data: payload,
  });
};

//tag objective taxonomy
export const saveDeliverableBusinessObjectiveTaxonomy = (payload) => {
  return API({
    url: "/deliverable/objective/taxonomy",
    method: "PUT",
    data: payload,
  });
};

// add seo meta tags
export async function saveDeliverableSeoMetatags(deliverableId, payload) {
  return API({
    url: `/deliverable/${deliverableId}/meta-tags`,
    method: "PUT",
    data: payload,
  });
}

/*
  Description : Add multiple blocks
*/
export async function saveManyPage(deliverableId, payload) {
  return API({
    url: `/deliverable/${deliverableId}/pages`,
    method: "POST",
    data: payload,
  });
}

// fetch Content Tags
export const fetchContentTags = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/deliverable/content-tags",
    method: "GET",
    params: params,
  });
};

// fetch Content Tags
export const fetchTaxonomyTags = (deliverableId) => {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/tags`,
    method: "GET",
  });
};

export function updateContentTags(deliverableId, payload) {
  return API({
    url: `/deliverable/all/${deliverableId}/tags`,
    method: "PUT",
    data: payload,
  });
}

//   add or update sequence of display to taxonomy
export const saveDeliverableTrendSequence = (deliverableId, payload) => {
  return API({
    url: `/deliverable/${deliverableId}/taxonomy/trend/sort-order`,
    method: "PUT",
    data: payload,
  });
};

export const uploadPowerbiFile = (payload) => {
  return API({
    headers: { "content-type": "multipart/form-data" },
    url: "/deliverable/powerbi/upload",
    method: "POST",
    data: payload,
  });
};

export const removePowerbiFile = (payload) => {
  return API({
    headers: { "content-type": "multipart/form-data" },
    url: "/deliverable/powerbi/remove-uploaded-file",
    method: "POST",
    data: payload,
  });
};

export const downloadPowerbiFile = (fileName, orgFileName) => {
  return API({
    url: `/deliverable/powerbi/download/${fileName}/${orgFileName}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getPages = (keyReportId) => {
  return API({
    url: `/deliverable/${keyReportId}/page?sort=pageSeqNo:asc`,
    method: "GET",
  })
    .then((res) => {
      let parents = res.data.data.filter((res) => !res.pageParentId);
      let finalList = parents.map((ele) => {
        let children = res.data.data.filter((res) => res.pageParentId === ele.id);
        return Object.assign(ele, { pages: children });
      });
      return finalList;
    })
    .catch((err) => {
      throw err;
    });
};
