import React from 'react';
import { Tooltip, tooltipClasses, styled } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#4B4B55' || theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#4B4B55' || theme.palette.common.black,
  },
}));

export default CustomTooltip;
