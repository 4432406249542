/*
================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Dynamic & Modular Sync Fusion Alert Box
----------------------------------------------------------------
    Creation Details
    Date Created				: 09/Aug/2020
    Author						: YOGESH N. GUPTA
================================================================
*/

import "./index.scss";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { hideAlertBox } from "../../../middleware/actions/alertBoxAction";

const defaultCallback = () => {
    return;
};

const AlertBox = (props) => {
    const dispatch = useDispatch();
    const { showAlert = false, title = "Confirm", okText = "Yes", okCallback = defaultCallback, cancelText = "No",
        cancelCallback = defaultCallback, content = "Are you sure, you want to continue?", children = <></>, data = undefined, hideCancelButton } = props;

    let buttons = [];
    if (!hideCancelButton) {
        buttons.push({
            buttonModel: { content: cancelText, cssClass: "e-outline cancel-btn" },
            "click": () => {
                cancelCallback(data);
                closeDialog();
            }
        })
    }

    buttons.push({
        buttonModel: { content: okText, cssClass: "e-flat e-outline ok-btn", isPrimary: false },
        "click": () => {
            okCallback(data);
            closeDialog();
        }
    })

    const closeDialog = () => {
        // setShowDialig(false);
        dispatch(hideAlertBox());
    };
    return (<DialogComponent isModal={true} width='300px' target='#root' visible={showAlert} close={() => closeDialog()}
        allowDragging={true} buttons={buttons} zIndex='999' animationSettings={{ delay: 200, effect: 'FadeZoom', duration: 400 }}
        content={content} cssClass={title}
    >
        {children}
    </DialogComponent>);
};

export default AlertBox;

AlertBox.propTypes = {
    showAlert: PropTypes.bool,
    title: PropTypes.string,
    okText: PropTypes.string,
    okCallback: PropTypes.func,
    cancelText: PropTypes.string,
    cancelCallback: PropTypes.func,
    content: PropTypes.string,
    children: PropTypes.node,
    data: PropTypes.any,
    hideButton: PropTypes.bool,
};
