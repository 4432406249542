/**
 * @fileDescription Product validation schema using Yup validation 
 * @author Pankaj Kulshreshtha | Cheers Interactive
 * @date 03/Aug/2020 *
 */
import * as Yup from "yup";
import * as REGEX from "../../../config/regexValidation"
import { PRODUCT_MAX_IMG_SIZE } from "../../../config"
// Product Overview Validation
export const productValidationSchema = {
	productSourceURL: Yup.string()
		// .required('Please enter Product Source URL')
		// .matches(/^(https|http):\/\/[^\s$.?#].[^\s]*\s*?$/, 'Please enter valid Product Source URL'),   
		.url("Please enter valid product source URL"),
	productName: Yup.string()
		.required("Please enter product name"),
	yearLaunched: Yup.string().matches(REGEX.YEAR, "Please enter valid 4 digit year"),
	productDescription: Yup.string()
	.required("Please enter about the product"),

};
// Product Value Proposition Validation
export const valuePropValidationSchema = {
	timeReduction: Yup.string()
		.required("Please enter time reduction"),

};
// Product Document Brochure Validation
export const addDocumentValidationSchema = {
	documentTitle: Yup.string()
		.required("Please enter document name"),
	documentDescription: Yup.string().min(10,"Description must be at least 10 characters")
		.required("Please enter document description"),
	documentFile: Yup.mixed()
		.required("Please upload document"),

};
// Product Edit Document Brochure Validation
export const editDocumentValidationSchema = {
	documentTitle: Yup.string()
		.required("Please enter document name"),
	documentDescription: Yup.string().min(10,"Description must be at least 10 characters")
		.required("Please enter document description"),

};
// Product Video Validation
export const videoValidationSchema = {
	videoTitle: Yup.string().min(2)
		.required("Please enter video title"),
	videoDescription: Yup.string(),
	videoSource: Yup.string().typeError("Please select video source").required("Please select video source"),
	videoId: Yup.string().min(5)
		.required("Please enter video id"),
	videoThumbnail: Yup.mixed().required("Please upload video thumbnail"),
	videoDurationInMinute: Yup.number().required("Please enter video duration")
		.min(1, "Video duration must be greater than 0"),

};
// Product document Brochure Validation
export const imageValidationSchema = {
	imageFile: Yup.mixed()
		.required("Please upload image")
		.test('imageFile', 'File size is more than 5 MB', (value) => !value || (value && value[0].size <= PRODUCT_MAX_IMG_SIZE)),

};

export const techSpecificationSchema = {
	specificationName: Yup.string().typeError("Please enter parameter name").min(2)
		.required("Please enter parameter name"),
	specificationUnit: Yup.string().min(2)
		.required("Please enter unit of measurement"),
	minValue: Yup.number().required(""),
	maxValue: Yup.number().required("Please enter parameter max value"),
	singleValue: Yup.string().required("Please enter parameter value")
};


// Product Collaboration Validation
export const collaborationValidationSchema = {
	collaborationCompany: Yup.array().required("Please select atleast one organization"),
	collaborationType: Yup.string().required("Please select nature of relationship"),
	collaborationDate: Yup.string().matches(REGEX.YEAR, "Please enter valid 4 digit year").required("Please enter year of engagement"),
	collobarationAttachment: Yup.mixed(),
	collaborationDescription: Yup.string()
};
