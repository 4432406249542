/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company State
----------------------------------------------------------------
	Creation Details
	Date Created				: 14/Oct/2020
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import {
	SET_DELIVERABLE_OVERVIEW_UPDATE_STATUS,
	SET_DELIVERABLE_PUBLISH_CLICK_STATUS,
	SET_DELIVERABLE_DETAILS,
	SET_DELIVERABLE_SETTINGS_DETAILS
} from "../../constants/actionConstants";

const DELIVERABLE_STATE = {
	deliverableDetails: {},
	settingsDetails: {},
	contentDetails: {},
	updateStatus: false,
	clickStatus: false,
};

export default (state = DELIVERABLE_STATE, action) => {
	switch (action.type) {
		case SET_DELIVERABLE_OVERVIEW_UPDATE_STATUS: {
			return { ...state, updateStatus: action.payload.updateStatus };
		}
		case SET_DELIVERABLE_PUBLISH_CLICK_STATUS: {
			return { ...state, clickStatus: action.payload.clickStatus };
		}
		// Maintain deliverable details
		case SET_DELIVERABLE_DETAILS: {
			return { ...state, deliverableDetails: action.payload };
		}
		// Maintain deliverable settings details
		case SET_DELIVERABLE_SETTINGS_DETAILS: {
			return { ...state, settingsDetails: action.payload };
		}
		default:
			return state;
	}
};
