/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  People Education Popup Component for Add/Edit
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 27/Apr/2021
    Author						: Sandeep Sharma
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import { Modal } from "react-bootstrap";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { saveExperience, fetchSingleExperience, updateExperience } from "../../../../../middleware/services/peopleApi";
import { TextBoxComponent, FormValidator } from "@syncfusion/ej2-react-inputs";
import { CompanySelectBox } from "../../customFormFields";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";

const AddExp = (props) => {
  const dispatch = useDispatch();

  const [roleData, setRoleData] = useState([]);
  const [exp, setExp] = useState({});
  const [enableDate, setEnableDate] = useState(true);
  const [companyId, setCompanyId] = useState("");
  const [endDateMin, setMinEndDate] = useState();

  const addExperience = () => {
    const options = {
      // add the rules for validation
      rules: {
        workCompanyName: {
          required: [true, "Company name is required"],
        },
        workRole: {
          required: [true, "Work role is required"],
        },
        workStartDate: {
          required: [true, "Start Date is required"],
        },
        workDesignation: {
          required: [true, "Designation is required"],
        },
      },
    };
    let formObject = new FormValidator("#addExp", options);
    const form = document.forms.addExp;

    if (enableDate) {
      formObject.addRules("workEndDate", { required: [true, "End Date is required"] });
    }

    //Submit Form and send data to API
    if (formObject.validate()) {
      const formData = new FormData(form);
      if (!enableDate) {
        formData.delete("workEndDate");
        formData.append("workEndDate", "Present");
      }
      if (companyId != "") {
        formData.append("workCompanyId", companyId);
      }
      if (props.expId !== "") {
        updateExperience(props.peopleId, props.expId, formData)
          .then((response) => {
            props.closeModal();
            dispatch(actionSuccess("Work experience updated successfully"));
            props.formatRowData(props.peopleId);
          })
          .catch((err) => {
            dispatch(actionError(err.data.message || "Someting went wrong"));
          });
      } else {
        saveExperience(props.peopleId, formData)
          .then((response) => {
            props.closeModal();
            dispatch(actionSuccess("Work experience added successfully"));
            props.formatRowData(props.peopleId);
          })
          .catch((err) => {
            dispatch(actionError(err.data.message || "Someting went wrong"));
          });
      }
    }
  };
  useEffect(() => {
    if (props.peopleId === "") {
      props.closeModal();
      dispatch(actionError("Please add basic details first"));
    }
    const role_data = ["Owner", "Board of Director", "Inventor", "Management"];
    setRoleData(role_data);
    if (props.expId !== "") {
      dispatch(showLoader());
      fetchSingleExperience(props.peopleId, props.expId).then((response) => {
        dispatch(hideLoader());
        if (response.status == 200) {
          let experience = response.data.data;
          experience && setExp(experience);
        }
      });
    }
  }, [props.showModal]);
  const handleCheckbox = (args) => {
    if (args.target.value === "YES") {
      setEnableDate(false);
    } else {
      setEnableDate(true);
      document.getElementById("workEndDate").value = "";
    }
  };
  const handleCompanyData = (itemdata) => {
    setCompanyId(itemdata.id);
  };
  const handleDateChange = (args) => {
    setMinEndDate(new Date(args.value));
  };

  return (
    <Modal show={props.showModal} backdrop="static" className="modal fade lg people-mgmt" aria-labelledby="contained-modal-title-vcenter" centered>
      <form id="addExp" method="post">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Add Experience
          </h5>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <CompanySelectBox
                  id="workCompanyName"
                  name="workCompanyName"
                  placeholder="Company Name *"
                  disabled={false}
                  data-msg-containerid="companyNameError"
                  value={exp.workCompanyName}
                  handleGetData={handleCompanyData}
                  allowCustom={true}
                />
                <div id="companyNameError" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <MultiSelectComponent
                  id="workRole"
                  name="workRole"
                  floatLabelType="Auto"
                  mode="Box"
                  placeholder="Work Role *"
                  cssClass="e-outline"
                  allowCustomValue={false}
                  data-msg-containerid="workRoleError"
                  dataSource={roleData}
                  value={exp.workRole}
                />
                <div id="workRoleError" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <TextBoxComponent
                  id="workDesignation"
                  name="workDesignation"
                  placeholder="Designation *"
                  cssClass="e-outline"
                  data-msg-containerid="designationError"
                  floatLabelType="Auto"
                  disabled={false}
                  value={exp.workDesignation}
                />
                <div id="designationError" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="peopleRegion">Current Company?</label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <RadioButtonComponent
                  label="Yes"
                  value="YES"
                  name="workCurrentCompany"
                  checked={exp.workCurrentCompany === "YES"}
                  onClick={handleCheckbox}
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <RadioButtonComponent
                  label="No"
                  value="NO"
                  checked={exp.workCurrentCompany === "NO" || props.expId === ""}
                  name="workCurrentCompany"
                  onClick={handleCheckbox}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <DatePickerComponent
                  id="workStartDate"
                  format="MMM yyyy"
                  name="workStartDate"
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="Start Date *"
                  allowEdit={false}
                  openOnFocus={true}
                  max={new Date()}
                  start="Decade"
                  depth="Year"
                  data-msg-containerid="workStartDateError"
                  value={exp.workStartDate}
                  change={handleDateChange}
                />
                <div id="workStartDateError" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <DatePickerComponent
                  id="workEndDate"
                  format="MMM yyyy"
                  name="workEndDate"
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="End Date *"
                  allowEdit={false}
                  openOnFocus={true}
                  min={endDateMin}
                  start="Decade"
                  depth="Year"
                  disabled={false}
                  data-msg-containerid="workEndDateError"
                  value={exp.workEndDate}
                  enabled={enableDate}
                  view="string"
                />
                <div id="workEndDateError" />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-dark cy-btn mr-3"
            onClick={() => {
              props.closeModal();
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary cy-btn" onClick={addExperience}>
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddExp;
