/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News Overview Validation - Add | Edit Overview
 ---------------------------------------------------------------------------------
    Creation Details
    Date Created				: 20/Aug/2020
    Author						: Aditya Tijare
================================================================
*/
import { DELIVERABLE_TYPE, CONTENT_TYPE } from "../../../../../../constants";
import * as Yup from "yup";

export const deliverableSettingValidationSchema = {
  pdfText: Yup.string().when("exportAsPdf", {
    is: true,
    then: Yup.string().required("Please enter the watermark text.").trim().max(100, "Please enter maximum 100 characters."),
    otherwise: Yup.string().nullable(),
  }),

  pdfFontFamily: Yup.string().when("exportAsPdf", {
    is: true,
    then: Yup.string().required("Please select font family.").typeError("Please select font family."),
    otherwise: Yup.string().nullable(),
  }),

  pdfFontSize: Yup.number().when("exportAsPdf", {
    is: true,
    then: Yup.number().required("Please enter font size.").typeError("Please enter font size."),
    otherwise: Yup.number().nullable(),
  }),

  pdfColor: Yup.string().when("exportAsPdf", {
    is: true,
    then: Yup.string().required("Please select a color.").typeError("Please select a color."),
    otherwise: Yup.string().nullable(),
  }),

  pdfOpacity: Yup.number().when("exportAsPdf", {
    is: true,
    then: Yup.number().required("Please enter watermark opacity.").typeError("Please enter watermark opacity."),
    otherwise: Yup.number().nullable(),
  }),

  pdfTopPosition: Yup.number().when("exportAsPdf", {
    is: true,
    then: Yup.number().required("Please enter watermark top position.").typeError("Please enter watermark top position."),
    otherwise: Yup.number().nullable(),
  }),

  pdfLeftPosition: Yup.number().when("exportAsPdf", {
    is: true,
    then: Yup.number().required("Please enter watermark left position.").typeError("Please enter watermark left position."),
    otherwise: Yup.number().nullable(),
  }),

  pdfRotate: Yup.number().when("exportAsPdf", {
    is: true,
    then: Yup.number().required("Please select watermark angle.").typeError("Please select watermark angle."),
    otherwise: Yup.number().nullable(),
  }),

  excelText: Yup.string().when("exportAsExcel", {
    is: true,
    then: Yup.string().required("Please enter the watermark text.").max(100, "Please enter maximum 100 characters."),
    otherwise: Yup.string().nullable(),
  }),

  excelFontFamily: Yup.string().when("exportAsExcel", {
    is: true,
    then: Yup.string().required("Please select font family.").typeError("Please select font family."),
    otherwise: Yup.string().nullable(),
  }),

  excelFontSize: Yup.number().when("exportAsExcel", {
    is: true,
    then: Yup.number().required("Please enter font size.").typeError("Please enter font size."),
    otherwise: Yup.number().nullable(),
  }),

  excelColor: Yup.string().when("exportAsExcel", {
    is: true,
    then: Yup.string().required("Please select a color.").typeError("Please select a color."),
    otherwise: Yup.string().nullable(),
  }),

  excelOpacity: Yup.number().when("exportAsExcel", {
    is: true,
    then: Yup.number().required("Please enter watermark opacity.").typeError("Please enter watermark opacity."),
    otherwise: Yup.number().nullable(),
  }),

  excelTopPosition: Yup.number().when("exportAsExcel", {
    is: true,
    then: Yup.number().required("Please enter watermark top position.").typeError("Please enter watermark top position."),
    otherwise: Yup.number().nullable(),
  }),

  excelLeftPosition: Yup.number().when("exportAsExcel", {
    is: true,
    then: Yup.number().required("Please enter watermark left position.").typeError("Please enter watermark left position."),
    otherwise: Yup.number().nullable(),
  }),

  excelRotate: Yup.number().when("exportAsExcel", {
    is: true,
    then: Yup.number().required("Please select watermark angle.").typeError("Please select watermark angle."),
    otherwise: Yup.number().nullable(),
  }),

  powerPointText: Yup.string().when("exportAsPpt", {
    is: true,
    then: Yup.string().required("Please enter the watermark text.").max(100, "Please enter maximum 100 characters."),
    otherwise: Yup.string().nullable(),
  }),

  powerPointFontFamily: Yup.string().when("exportAsPpt", {
    is: true,
    then: Yup.string().required("Please select font family.").typeError("Please select font family."),
    otherwise: Yup.string().nullable(),
  }),

  powerPointFontSize: Yup.number().when("exportAsPpt", {
    is: true,
    then: Yup.number().required("Please enter font size.").typeError("Please enter font size."),
    otherwise: Yup.number().nullable(),
  }),

  powerPointColor: Yup.string().when("exportAsPpt", {
    is: true,
    then: Yup.string().required("Please select a color.").typeError("Please select a color."),
    otherwise: Yup.string().nullable(),
  }),

  powerPointOpacity: Yup.number().when("exportAsPpt", {
    is: true,
    then: Yup.number().required("Please enter watermark opacity.").typeError("Please enter watermark opacity."),
    otherwise: Yup.number().nullable(),
  }),

  powerPointTopPosition: Yup.number().when("exportAsPpt", {
    is: true,
    then: Yup.number().required("Please enter watermark top position.").typeError("Please enter watermark top position."),
    otherwise: Yup.number().nullable(),
  }),

  powerPointLeftPosition: Yup.number().when("exportAsPpt", {
    is: true,
    then: Yup.number().required("Please enter watermark left position.").typeError("Please enter watermark left position."),
    otherwise: Yup.number().nullable(),
  }),

  powerPointRotate: Yup.number().when("exportAsPpt", {
    is: true,
    then: Yup.number().required("Please select watermark angle.").typeError("Please select watermark angle."),
    otherwise: Yup.number().nullable(),
  }),

  sectionText: Yup.string().when("exportASection", {
    is: true,
    then: Yup.string().required("Please enter the watermark text.").max(100, "Please enter maximum 100 characters."),
    otherwise: Yup.string().nullable(),
  }),

  sectionFontFamily: Yup.string().when("exportASection", {
    is: true,
    then: Yup.string().required("Please select font family.").typeError("Please select font family."),
    otherwise: Yup.string().nullable(),
  }),

  sectionFontSize: Yup.number().when("exportASection", {
    is: true,
    then: Yup.number().required("Please enter font size.").typeError("Please enter font size."),
    otherwise: Yup.number().nullable(),
  }),

  sectionColor: Yup.string().when("exportASection", {
    is: true,
    then: Yup.string().required("Please select a color.").typeError("Please select a color."),
    otherwise: Yup.string().nullable(),
  }),

  sectionOpacity: Yup.number().when("exportASection", {
    is: true,
    then: Yup.number().required("Please enter watermark opacity.").typeError("Please enter watermark opacity."),
    otherwise: Yup.number().nullable(),
  }),

  sectionTopPosition: Yup.number().when("exportASection", {
    is: true,
    then: Yup.number().required("Please enter watermark top position.").typeError("Please enter watermark top position."),
    otherwise: Yup.number().nullable(),
  }),

  sectionLeftPosition: Yup.number().when("exportASection", {
    is: true,
    then: Yup.number().required("Please enter watermark left position.").typeError("Please enter watermark left position."),
    otherwise: Yup.number().nullable(),
  }),

  sectionRotate: Yup.number().when("exportASection", {
    is: true,
    then: Yup.number().required("Please select watermark angle.").typeError("Please select watermark angle."),
    otherwise: Yup.number().nullable(),
  }),

  chartsText: Yup.string().when("exportAsChart", {
    is: true,
    then: Yup.string().required("Please enter the watermark text.").max(100, "Please enter maximum 100 characters."),
    otherwise: Yup.string().nullable(),
  }),

  chartsFontFamily: Yup.string().when("exportAsChart", {
    is: true,
    then: Yup.string().required("Please select font family.").typeError("Please select font family."),
    otherwise: Yup.string().nullable(),
  }),

  chartsFontSize: Yup.number().when("exportAsChart", {
    is: true,
    then: Yup.number().required("Please enter font size.").typeError("Please enter font size."),
    otherwise: Yup.number().nullable(),
  }),

  chartsColor: Yup.string().when("exportAsChart", {
    is: true,
    then: Yup.string().required("Please select a color.").typeError("Please select a color."),
    otherwise: Yup.string().nullable(),
  }),

  chartsOpacity: Yup.number().when("exportAsChart", {
    is: true,
    then: Yup.number().required("Please enter watermark opacity.").typeError("Please enter watermark opacity."),
    otherwise: Yup.number().nullable(),
  }),

  chartsTopPosition: Yup.number().when("exportAsChart", {
    is: true,
    then: Yup.number().required("Please enter watermark top position.").typeError("Please enter watermark top position."),
    otherwise: Yup.number().nullable(),
  }),

  chartsLeftPosition: Yup.number().when("exportAsChart", {
    is: true,
    then: Yup.number().required("Please enter watermark left position.").typeError("Please enter watermark left position."),
    otherwise: Yup.number().nullable(),
  }),

  chartsRotate: Yup.number().when("exportAsChart", {
    is: true,
    then: Yup.number().required("Please select watermark angle.").typeError("Please select watermark angle."),
    otherwise: Yup.number().nullable(),
  }),
};
