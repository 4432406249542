// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : Common Info Icon
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :6 Jun 22
// Author:Umesh
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from "react";
import classes from "./InfoIcon.module.css";

// Info icon used in most of the components
const InfoIcon = (props) => {
  return (
    <>
      <div className={`${classes.infoImg} ${props.isSiteSearchInfo ? classes.isSiteSearchInfo : ""}`} style={{ height: "25px" }}>
        <img src={"/assets/info.svg"} alt="info" />
        <div className={classes.info_content}>
          <p
            dangerouslySetInnerHTML={{
              __html: props.infoText,
            }}
          />
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default InfoIcon;
