/**
 * @author: Lawrence Anthony Kumar
 * @date : 20-Feb-2023
 * File Description: Image Editor specification Modal handle content block image edit
 */

import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import "./fileAttachmentEditModal.css";
import { TextField } from "app/components/CustomFormElements/syncFusionFormFields";
import * as Yup from "yup";
import { FiUpload } from "react-icons/fi";
import CKEditor from "react-ckeditor-component";
import DragAndDrop from "app/components/DragAndDrop";
import { BO_MAXIMUM_FILE_SIZE } from "../../../../../constants";
import FileThumbnail from "app/components/Thumbnail/FileThumbnail";
import moment from "moment";
import { updateFileAttachment } from "middleware/services/contentBlockApi";
import { actionSuccess } from "middleware/actions/utilityAction";
import { actionError, hideLoader, showLoader } from "middleware/actions/utilityAction";
import { useDispatch } from "react-redux";

const FileEditorModal = (props) => {
  const { isOpen, onClose, fileModalData, setFileModalData } = props;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [existingAttachmentFiles, setExistingAttachmentFiles] = useState([]);
  const [toBeDeletedFilesId, setToBeDeletedFilesId] = useState([]);
  const [linkText, setLinkText] = useState("");
  const dispatch = useDispatch();

  const objectId = useMemo(() => fileModalData?.id, [fileModalData]);

  const initialValues = {
    linkText: "",
    attachmentName: "",
    attachmentDescription: "",
  };

  const handleSubmit = async () => {
    try {
      const errors = await formik.validateForm();
      if ([...selectedFiles, ...existingAttachmentFiles].length === 0) {
        errors.file = "Attachment file is required";
      }
      if (Object.keys(errors).length) {
        const touchedFields = Object.keys(errors).reduce((acc, field) => {
          acc[field] = true;
          return acc;
        }, {});
        formik.setTouched(touchedFields, false);
        formik.setErrors(errors);
        return;
      }
      const formData = new FormData();
      Object.keys(formik.values).forEach((key) => {
        formData.append(key, formik.values[key]);
      });
      formData.append("attachmentModifiedUserId", window?._cb?.userId);
      formData.append("attachmentDeleteIds", JSON.stringify(toBeDeletedFilesId));
      Array.from(selectedFiles).forEach((file) => {
        formData.append("fileAttachments", file); // Append each file to the 'files' key
      });
      dispatch(showLoader());
      const res = await updateFileAttachment(objectId, formData);
      dispatch(actionSuccess("File Attachment updated successfully"));
      dispatch(hideLoader());
      const modal = document.querySelector("#my-file-modal");
      if (modal) modal.style.left = "-100%";
      setFileModalData({});
      onClose();
    } catch (error) {
      dispatch(hideLoader());
      dispatch(actionError(err?.data?.message || "Unable to update file attachment"));
    }
  };

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    attachmentName: Yup.string()
      .trim()
      .required("Please enter attachment name")
      .max(50, "Please enter attachment name of maximum 50 characters")
      .test("is-not-only-numbers", "Title should not contain only numbers.", (value) => !/^[0-9]+$/.test(value))
      .test("is-not-only-special-chars", "Title should not contain only special characters.", (value) => !/^[^a-zA-Z0-9]+$/.test(value)),
    attachmentDescription: Yup.string()
      .required("Please enter attachment Description.")
      .test("plainTextMaxLength", function (value) {
        const { createError } = this;
        const plainText = value
          ? value
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/&nbsp;/g, " ")
              .trim()
          : "";
        if (plainText.length === 0) {
          return createError({ message: "Please enter attachment Description." });
        }
        if (plainText.length > 100) {
          return createError({ message: "Description must be less than or equal to 100 characters." });
        }
        return true;
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  useEffect(() => {
    if (formik.values.attachmentDescription) sessionStorage.setItem("content-design", formik.values.attachmentDescription);
  }, [formik.values.attachmentDescription]);

  useEffect(() => {
    if (!fileModalData || !fileModalData.attachmentDocs) return;
    formik.setValues({ attachmentDescription: fileModalData.attachmentDescription, attachmentName: fileModalData?.attachmentName });
    setLinkText(fileModalData?.textLink);
    setExistingAttachmentFiles(fileModalData.attachmentDocs);
  }, [fileModalData]);

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    // setContent(newContent);
    formik.setValues({ ...formik.values, attachmentDescription: newContent });
  };

  return (
    <>
      {/* {imgSrc && */}(
      <Modal
        show={isOpen}
        backdrop="static"
        className="modal fade md modal-z-index file-editor-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header bg-none text-dark file-editor-header">
          <h5 className="modal-title file-editor-title" id="exampleModalLabel">
            Edit Attached Document
          </h5>
          <span onClick={onClose} style={{ cursor: "pointer" }}>
            <img src="/assets/cross.svg" />
          </span>
        </div>
        <Modal.Body className="file-editor-body">
          <p className="text-link col-md-12">
            Link Text: <span>{fileModalData?.textLink}</span>
          </p>
          <div className="col-md-12">
            <TextField
              formik={formik}
              placeholder="Attachment Name *"
              name="attachmentName"
              id="attachmentName"
              numberCount={true}
              maxLength={50}
              hideInitial={true}
              characterLimitText={true}
            />
          </div>
          <div className="col-md-12 my-4">
            <TextField
              formik={formik}
              multiline={true}
              numberCount={true}
              maxLength={100}
              rows={8}
              name="attachmentDescription"
              className="form-control"
              id="attachmentDescription"
              placeholder="Attachment Description *"
              characterLimitText={true}
              hideInitial={true}
              value={formik.values.attachmentDescription}
            />
          </div>
          <p style={{ color: "rgb(107 114 128 / 1)", fontSize: "14px", height: "fit-content", margin: 0, paddingLeft: "16px" }}>
            Upload Reference Document(s) *
          </p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", padding: "0px 16px", margin: 0, marginBottom: "15px" }}>
              <DragAndDrop
                acceptedFilesFormat=".pdf, .txt, .doc, .docx, .xls, .xlsx, .pptx, .csv"
                maxUploadFiles={2}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                multipleUpload={true}
                maxFileSize={BO_MAXIMUM_FILE_SIZE}
                objectKey={true}
                existingFiles={existingAttachmentFiles}
                handleDocument={(objectKey, files) => {
                  let mergedFiles = [...existingAttachmentFiles, ...files];
                  if (mergedFiles.length > 2) return dispatch(actionError(`You can upload maximum 2 files.`));
                  return setSelectedFiles([...files]);
                }}
                content={
                  <div className="dnd-btn">
                    <div className="pr-[10px] ">
                      <FiUpload size="20px" />
                    </div>

                    <button type="button" className="inside-btn">
                      Drag and Drop
                      <strong> or Browse</strong>
                    </button>
                  </div>
                }
              />
              <div className="text-danger" role="alert">
                <small>{formik.errors["file"] || ""}</small>
              </div>
              <div style={{ marginTop: "5px" }}>
                <p className="note-text" style={{ fontWeight: "bold", margin: 0 }}>
                  Note :
                </p>
                <ul className="note-text" style={{ marginLeft: "17px", marginTop: "5px" }}>
                  <li className="note-text">Accepts only pdf, txt, doc, docx, xls, xlxs, pptx, csv files.</li>
                  <li className="note-text">User can upload a maximum of 2 files.</li>
                  <li className="note-text">Maximum file size allowed for upload: 5 MB</li>
                </ul>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "8px",
                marginLeft: "32px",
                maxHeight: "170px",
                overflow: "auto",
                flexGrow: 1,
              }}
            >
              {[...existingAttachmentFiles, ...selectedFiles]?.map((f, i) => (
                <div
                  className="w-[264px] h-[51px] flex justify-between items-center flex-row gap-1 border border-gray-100 px-2 py-2 rounded-md"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "5px",
                    border: "1px solid #e5e7eb",
                    borderRadius: "5px",
                    padding: "5px 5px",
                    width: "264px",
                    height: "51px",
                  }}
                  key={f?.name || f?.docFileName}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: "8px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                  >
                    <div className="preview-file-icon">
                      <FileThumbnail fileType={f?.name?.split(".")?.pop() || f?.docFileName?.split(".")?.pop()} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", fontSize: "12px", color: "#939399" }}>
                      <p
                        className=" text-sm"
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          fontSize: "12px",
                          color: "#000000",
                          maxWidth: "180px",
                          marginBottom: 0,
                        }}
                      >
                        {f.name || f?.docName}
                      </p>
                      {moment(f?.docCreatedDate).format("DD MMM YYYY")}
                    </div>
                  </div>
                  <div
                    className="file-download-icon"
                    onClick={() => {
                      console.log(selectedFiles, "File deleted", f);
                      setSelectedFiles((prev) => prev.filter((file) => file.name !== f?.name));
                      if (objectId && f?.docFileName) {
                        setToBeDeletedFilesId((prev) => [...prev, f?.id]);

                        return setExistingAttachmentFiles((prev) => prev.filter((file) => file.docFileName !== f?.docFileName));
                      }
                    }}
                  >
                    <img src="/assets/delete.svg" alt="Delete Icon" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="file-editor-footer">
          <button type="button" onClick={onClose} className="btn btn-outline-dark editor-btn pl-2 pr-2">
            Cancel
          </button>
          <button type="button" onClick={handleSubmit} className="btn btn-primary editor-btn pl-2 pr-2 ml-2 my-1">
            Save
          </button>
        </Modal.Footer>
      </Modal>
      ){/* } */}
    </>
  );
};

export default FileEditorModal;
