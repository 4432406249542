import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProductVariant } from "../../../../../utilities";
import {
  fetchNewsCategorization,
  fetchSingleNews,
} from "../../../../../middleware/services/newsApi";
import {
  showLoader,
  hideLoader,
  actionError,
} from "../../../../../middleware/actions/utilityAction";
import SortableTree from "../../../../components/treeView/sortableTree";

const Categorization = (props) => {
  const [cat, setCat] = useState([]);
  const [checkedCat, setCheckedCat] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [reRender, setReRender] = useState(false);
  const productVariant = getProductVariant();
  const newsId = props.newsId;
  const [treeCollapse, setTreeCollapse] = useState(true);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [showExpandAll, setShowExpandAll] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    getAllCat();
  }, []);
  let checkedNodes = [];
  const getAllCat = () => {
    if (newsId != "") {
      dispatch(showLoader());
      const response = fetchSingleNews(newsId, { fields: ["newsCategory"] });
      response
        .then((response) => {
          dispatch(hideLoader());
          if (response.status == 200) {
            if (response.data.data.newsCategory) {
              if (Object.values(response.data.data.newsCategory).length) {
                const selectedDtData = [];
                Object.values(response.data.data.newsCategory).forEach(
                  (selectedDt) => {
                    selectedDtData.push(selectedDt.catId);
                  }
                );
                checkedNodes = selectedDtData;
                setCheckedCat(selectedDtData);
              }
            }
            fetchNewsCatWithProductVariant();
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    } else {
      fetchNewsCatWithProductVariant();
    }
  };
  const getCat = (params) => {
    try {
      const response = fetchNewsCategorization(params);
      response
        .then((response) => {
          dispatch(hideLoader());
          if (response.status == 200) {
            setCat(response.data.data);
            const treeJsonData = buildTreeJson(response.data.data);
            treeJsonData.map((v) => {
              if (v?.children?.length > 0) setShowExpandAll(true);
            });
            setTreeData(treeJsonData);
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };

  const fetchNewsCatWithProductVariant = (params = {}) => {
    let defaultFilter = [];
    if (productVariant)
      defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];
    return getCat(params);
  };
  const handleSaveBridge = (selectedTech = "", checkedNonDt) => {
    let result = [];
    let pushIds = [];
    if (checkedNonDt.length) {
      cat.forEach((catValue) => {
        if (
          checkedNonDt.includes(catValue.id) &&
          !pushIds.includes(catValue.id)
        ) {
          if (
            catValue.catParentId != 0 &&
            !pushIds.includes(catValue.catParentId)
          ) {
            const catParent = cat.filter(
              (catFilter) => catFilter.id == catValue.catParentId
            );
            pushIds.push(catParent[0].id);
            result.push({
              catId: catParent[0].id,
              catName: catParent[0].catName,
              catParentId: catParent[0].catParentId,
              catRoot: catParent[0].catRoot,
            });
          }
          pushIds.push(catValue.id);
          result.push({
            catId: catValue.id,
            catName: catValue.catName,
            catParentId: catValue.catParentId,
            catRoot: catValue.catRoot,
          });
        }
      });
    }
    props.handleSaveCategorization(selectedTech, result);
  };

  const buildTreeJson = (data, parentId = 0) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.catName;
      if (element?.catParentId) element["parentId"] = element?.catParentId;
      element["level"] = element.catLevel + 1;
      if (element["catRoot"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        } else {
          if (checkedNodes?.includes(element.id)) {
            element["isChecked"] = true;
          }
        }
        result.push(element);
      }
    });
    return result;
  };
  useEffect(() => {
    handleSaveBridge("", selectedNodes);
  }, [selectedNodes]);

  return (
    <div>
      <SortableTree
        treeData={treeData}
        treeCollapse={treeCollapse}
        checked={checkedCat}
        onClick={(e) => {
          let checkedNodeId = e.map((v) => v.id);
          setSelectedNodes(checkedNodeId);
        }}
        parentId={"parentId"}
        hieghtClass="news-category-taxonomy-tree-container-wrapper"
        autoCheck={true}
      />
      {showExpandAll && (
        <div>
          {treeCollapse ? (
            <div className="cursor-pointer expand-collapse-btn">
              <span
                className="btn  px-3 pt-1 pb-1 taxonomyCustom"
                onClick={() => setTreeCollapse(false)}
              >
                Expand All
              </span>
            </div>
          ) : (
            <div className="cursor-pointer expand-collapse-btn">
              <span
                className="btn  px-3 pt-1 pb-1 taxonomyCustom"
                onClick={() => setTreeCollapse(true)}
              >
                Collapse All
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Categorization;
