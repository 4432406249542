import { DELIVERABLE_API as API } from "../../config/apiConfig";
import { DELIVERABLE_URL } from "../../config";


export async function fetchPages(deliverableId, params={}) {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/deliverable/${deliverableId}/page`,
		method: "GET",
		params: params
	});
}

export async function fetchSinglePage(entityName, entityId, pageId) {
	return API({
		url: `/${entityName}/${entityId}/page/${pageId}`,
		method: "GET"
	});
}

export const editSinglePage = async (params, payload) => {
	console.log("Payload", JSON.stringify(payload, null, 3));
	return API({
		url: `/deliverable/${params?.deliverableId}/page/${params?.pageId}`,
		method: "PUT",
		data: payload
	});
};

export const updateIndex = async (deliverableId, payload) => {
	// const formData = new FormData();
	// formData.append("fromIndex", payload.fromIndex);
	// formData.append("dropIndex", payload.dropIndex);
	// formData.append("data", payload.data);
	// formData.append("pageCreatedBy", payload.pageCreatedBy);
	return API({
		url: `/deliverable/${deliverableId}/page/indexes`,
		method: "PUT",
		data: payload
	});
};

export async function fetchPageById(pageId,params={}) {
	let { fields = undefined } = params;
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/deliverable/page/${pageId}`,
		method: "GET",
		params: params
	});
}
