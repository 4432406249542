/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News Overview component - Add | Edit Overview
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { saveObjective, fetchSingleObjective, updateObjective } from "../../../../../../middleware/services/businessObjectiveApi";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import OverviewForm from "./form";
import { getProductVariant, getLoggedInUser } from "../../../../../../utilities";

const NewsOverview = (props) => {
  const objectiveId = props.objectiveId;
  const dispatch = useDispatch();
  const loginUser = getLoggedInUser();
  /*
        @Description : Default value for news overview form
    */
  const [objectiveInformation, setObjectiveInformation] = useState({
    objectiveCoverageStartDate: "",
    objectiveCoverageEndDate: "",
    objectiveStatus: "",
    objectiveSendUpdates: "",
    objectiveVisibleToPartner: "",
    displayName: "",
    withPermissionText: "",
    objectiveTitleVisible: "",
  });
  const [objectiveSetting, setObjectiveSetting] = useState({});
  /*
        @Description : Fetch news data on edit
        1. set news data to respective fields
        2. Set image path for sending to child component
    */
  useEffect(() => {
    if (objectiveId != "") {
      dispatch(showLoader());
      try {
        const response = fetchSingleObjective(objectiveId);
        response
          .then((response) => {
            dispatch(hideLoader());
            if (response.status == 200) {
              let objectiveStatus = "";
              if (response.data.data.objectiveStatus == "under_discussion") {
                objectiveStatus = "In Progress";
              } else if (response.data.data.objectiveStatus == "active") {
                objectiveStatus = "Under Review";
              } else if (response.data.data.objectiveStatus == "closed") {
                objectiveStatus = "Complete";
              } else {
                objectiveStatus = response.data.data.objectiveStatus.charAt(0).toUpperCase() + response.data.data.objectiveStatus.slice(1);
              }

              let objectiveData = {
                objectiveStatus: {
                  value: response.data.data.objectiveStatus,
                  label: objectiveStatus,
                },
                objectiveCoverageStartDate: response.data.data.objectiveCoverageStartFrom,
                objectiveCoverageEndDate: response.data.data.objectiveCoverageEnd,
                objectiveSendUpdates: response.data.data.objectiveSendUpdates ? response.data.data.objectiveSendUpdates : "",
                objectiveVisibleToPartner: response.data.data.objectiveVisibleToPartner ? response.data.data.objectiveVisibleToPartner : "",
                objectiveTitleVisible: response.data.data.objectiveTitleVisible ? response.data.data.objectiveTitleVisible.value : "",
                displayName: response.data.data.objectiveTitleVisible ? response.data.data.objectiveTitleVisible.displayName : "",
                withPermissionText: response.data.data.objectiveTitleVisible ? response.data.data.objectiveTitleVisible.withPermissionText : "",
              };

              setObjectiveInformation(objectiveData);
            } else {
              dispatch(actionError("Something Went Wrong...!"));
            }
          })
          .catch((e) => {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
          });
      } catch (e) {
        dispatch(hideLoader());
        dispatch(actionError("Something Went Wrong...!"));
      } finally {
        dispatch(hideLoader());
      }
    }
  }, []);
  /*
       @Description : Save overview details
   */
  const handleSaveOverview = async (saveOverviewData, callback) => {
    dispatch(showLoader());

    try {
      let response = "";
      if (objectiveId == "") {
        const productVariant = getProductVariant();
        if (productVariant) saveOverviewData.append("productVariant", JSON.stringify([{ productName: productVariant, isLive: "NO" }]));
        saveOverviewData.append("objectiveCreatedBy", loginUser["_id"]);
        response = saveObjective(saveOverviewData);
      } else {
        response = updateObjective(objectiveId, saveOverviewData);
      }
      response
        .then((response) => {
          if (callback) {
            callback();
          }
          dispatch(hideLoader());

          dispatch(actionSuccess("Business Objective Content Tab Saved Successfully"));
          if (objectiveId == "") {
            props.handleRedirectOnAddObjective(response.data.data.id);
          }
        })
        .catch((e) => {
          if (callback) {
            callback();
          }
          dispatch(hideLoader());
          if (e.data.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    } finally {
      dispatch(hideLoader());
    }
  };
  return (
    <div className="gennx-content-wrapper padding-bottom-100i  pt-3" style={{ width: "99.5%", overflow: "hidden" }}>
      <div
        className="pt-3 mx-2 gennx-content-wrapper padding-bottom-150i"
        style={{
          padding: "1%",
          marginTop: "1%",
          "background-color": "#ffffff",
          width: "99.1%",
          "border-radius": "5px",
        }}
      >
        <OverviewForm
          objectiveInformation={objectiveInformation}
          handleSaveOverview={handleSaveOverview}
          objectiveSetting={objectiveSetting}
          {...props}
        />
      </div>
    </div>
  );
};

export default NewsOverview;
