/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News form field options
 ---------------------------------------------------------------------------------
    Creation Details
    Date Created				: 10/Nov/2021
    Author						: Lawrence Anthony
================================================================
*/

export const NEWS_OPTIONS = [{
    label: "News",
    value: "News",
},
{
    label: "Article",
    value: "Article",
}
];

export const NEWS_OPTIONS_INSIDER = [{
    label: "Industry Developments",
    value: "Industry Developments",
},
{
    label: "Articles",
    value: "Articles",
},
{
    label: "Press Releases",
    value: "Press Releases",
},
{
    label: "News",
    value: "News",
},
];