/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Main Tree Component
     1. Search Functionality : if isSearchable props true
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React, { Component } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import "./index.scss";
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdChevronRight,
    MdKeyboardArrowDown,
    MdAddBox,
    MdIndeterminateCheckBox,
    MdFolder,
    MdFolderOpen,
    MdInsertDriveFile
} from "react-icons/md";

class WidgetTree extends Component {
    /*
        @Description : Default State
        Checked :checked value array
        Expanded :expanded node array
    */
    constructor(props) {
        super(props)
        this.state = {
            checked: [...props.checked || []],
            expanded: [...props.checked || []],
            filterText: '',
            nodesFiltered: [...props.treeData],
            nodes: [...props.treeData],
            setFilterCheckNodes: props.setFilterCheckNodes,
            // setFilterCheckNodes: [...props.setFilterCheckNodes],
            // filterCheckNodes: [...props.filterCheckNodes]
        };
        this.onExpand = this.onExpand.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.filterTree = this.filterTree.bind(this);
        this.filterNodes = this.filterNodes.bind(this);
        this.onCheck = this.onCheck.bind(this);

    }

    /*
        @Description : Use for triggering save props
    */

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.saveTreeData != this.props.saveTreeData) {
            if (this.props.saveTreeData) {
                this.props.handleSave(this.props.technology, this.state.checked, prevProps.selectedTab);
            }

            // // Media
            // if (Object.prototype.toString.call(this.state.setFilterCheckNodes) == '[object Function]') {
            //     this.state.setFilterCheckNodes(this.state.checked)
            // }


            return false;
        }
    }

    onExpand(expanded) {
        this.setState({ expanded });
    }


    onFilterChange(e) {
        this.setState({ filterText: e.target.value }, this.filterTree);
    }

    /*
        @Description : Reset nodes back to unfiltered state if filter not set
    */
    filterTree() {

        if (!this.state.filterText) {
            this.setState((prevState) => ({
                nodesFiltered: prevState.nodes,
            }));

            return;
        }

        const nodesFiltered = (prevState) => (
            { nodesFiltered: prevState.nodes.reduce(this.filterNodes, []) }
        );

        this.setState(nodesFiltered);
    }

    /*
        @Description : Add custom filter node method to prevent auto check on filter
    */
    filterNodes(filtered, node) {
        const { filterText } = this.state;
        const children = (node.children || []).reduce(this.filterNodes, []);

        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 ||
            // Or a children has a matching node
            children.length
        ) {
            filtered.push({ ...node, ...children.length && { children } });
        }

        return filtered;
    }
    /*
        @Description : Add custom on check function for overwriting on cascade
    */
    onCheck(checked, targetNode) {
        // this.props.flag && this.setState({ checked: [] })
        if (this.props.treeType == 'valueChain') {
            if (targetNode.checked) {
                if (targetNode.parent.value) {
                    this.setState({ checked: [...checked, targetNode.parent.value] })
                } else {
                    this.setState({ checked })
                }
            } else {
                if (targetNode.children) {
                    const childrenIds = targetNode.children.map((child) => child.value);
                    this.setState({ checked: [...checked.filter((check) => !childrenIds.includes(check)), targetNode.parent.value] })
                } else {
                    this.setState({ checked })
                }

            }


        } else {
            let nodes = this.state.nodesFiltered
            let ParentIds = []
            checked.forEach( ele => {
                let tempParents=[]
                let tempParentIds=[]
                let findDeep = function(data, id) {
                    return data.some(function(e) {
                        if(e.id == id) {
                            return true;
                        }
                        else if(e.children) {
                            tempParents.push(e)

                            return findDeep(e.children, id)
                        }
                    })
                  }
                findDeep(nodes, ele)

                tempParents.forEach(e => {
                    tempParentIds.push(e.id)
                })
                tempParentIds.push(ele)

                //Running loop 5 times to eliminate all parent nodes whose child nodes are not selected one by one
                for(let i=0;i<5;i++){
                    tempParents.forEach(ele=>{
                        let removeNode=true
                        if(ele.children){
                            ele.children.forEach(e => {
                                if (tempParentIds.includes(e.id)) {
                                    removeNode=false
                                }
                            })
                            if (removeNode) tempParentIds = tempParentIds.filter(function(e) { return e !== ele.id })
                            }
                        })
                }
                
                ParentIds=[...ParentIds,...tempParentIds]
                }
            )

            checked =[...checked,...ParentIds]
            this.setState({ checked })
        }

    }


    render() {
        const icons = {
            check: <MdCheckBox className="rct-icon rct-icon-check" />,
            uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
            halfCheck: (
                <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
            ),
            expandClose: (
                <MdChevronRight className="rct-icon rct-icon-expand-close" />
            ),
            expandOpen: (
                <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
            ),
            expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
            collapseAll: (
                <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
            ),
            parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
            parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
        };
        return (
            <div className="tree-container">
                {this.props.isSearchable ?
                    (<div className={`col-4 pl-0 pb-0 ${this.props.showSearch && "viewSearchBar"}`} >
                        <input
                            style={{ height: this.props.showSearch && "31px" }}
                            className="filter tagging form-control"
                            placeholder="Search"
                            type="text"
                            value={this.state.filterText}
                            onChange={this.onFilterChange}
                        />
                    </div>) : null
                }
                {
                    this.props.isCollaborationTaxonomy ?
                        <div className={`custom-collaboration-taxonomy-tree-container`}>
                            <CheckboxTree
                                nodes={this.state.nodesFiltered}
                                checked={this.state.checked}
                                expanded={this.state.expanded}

                                onExpand={expanded => this.setState({ expanded })}
                                icons={icons}
                                showCheckbox={this.props.showCheckbox}
                                showNodeIcon={this.props.showNodeIcon}
                                expandDisabled={false}
                                showExpandAll={this.props.showExpandAll}
                                onClick={this.props?.onClick || (() => { return; })}
                                noCascade={this.props.noCascade || false}
                            />
                        </div>
                        :
                        <div className={`custom-taxonomy-tree-container${this.props.isSearchable && " custom-is-searchable "}${this.props.showSearch && ""}`} style={{ height: this.props.showSearch && '42vh', width: this.props.showSearch && "30vw" }}>
                            <CheckboxTree
                                disabled={this.props.disabled}
                                nodes={this.state.nodesFiltered}
                                checked={this.state.checked}
                                expanded={this.state.expanded}
                                onCheck={(checked) => {
                                    this.props.showSearch && this.props.handleSubmit(checked)
                                    this.setState({ checked })
                                }}
                                onExpand={expanded => this.setState({ expanded })}
                                icons={icons}
                                showCheckbox={this.props.showCheckbox}
                                showNodeIcon={this.props.showNodeIcon}
                                expandDisabled={false}
                                showExpandAll={this.props.showExpandAll}
                                onClick={this.props?.onClick || (() => { return; })}
                                noCascade={this.props.noCascade || false}
                            />
                        </div>

                }
            </div >
        );
    }
}
export default WidgetTree;
