import React, { useState, useEffect, memo } from "react";
import { Box, Typography } from "@mui/material";
import { HTML_TAG_HEADER_TEXT, EDITOR_RESTORE_TEXT } from "../../../constants/widget-constants";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
const EditMode = memo(({ widgetHTMLCode, onChange, defaultCode }) => {
  const [htmlCode, sethtmlCode] = useState(widgetHTMLCode);
  const handleRestoreDefault = () => {
    // Set the formik value to the defaultCode and replace with your actual default content
    sethtmlCode(defaultCode);
  };
  const handleChange = (newValue) => {
    sethtmlCode(newValue);
  }
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      onChange(htmlCode);
    }, 200);
    return () => clearTimeout(delayInputTimeoutId);
  }, [htmlCode]);

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="body2" gutterBottom sx={{ color: "black  !important" }}>
        {HTML_TAG_HEADER_TEXT}
      </Typography>
      <AceEditor
        value={htmlCode ? htmlCode : ""}
        mode="javascript"
        theme="tomorrow"
        onChange={handleChange}
        name="UNIQUE_ID_OF_DIV"
        editorProps={{ $blockScrolling: true }}
        style={{ width: "100%", height: "100px", border: "1px solid #ced4da", borderRadius: "4px" }}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          useWorker: false
        }}
        wrapEnabled={true}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
      />
      <Box className="restore-default-container">
        <Box className="restore-default" onClick={handleRestoreDefault}>
          {EDITOR_RESTORE_TEXT}
        </Box>
      </Box>
    </Box>
  );
});

const ReadMode = ({ htmlCode }) => {
  return <div className="widgetDashboardContentLink" dangerouslySetInnerHTML={{ __html: htmlCode || "" }}></div>;
};

const ViewMoreLink = ({ mode, htmlCode, onChange, defaultCode }) => {
  return (
    <div className="link-container">{mode === "read" ? <ReadMode htmlCode={htmlCode} /> : <EditMode widgetHTMLCode={htmlCode} onChange={onChange} defaultCode={defaultCode} />}</div>
  );
};

export default ViewMoreLink;
