/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : KIA All tab Page 
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/June/2021
    Author						: Sandeep K. Sharma
================================================================
*/

import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Overview from "../component/overview";
import IndustryApplications from "../component/industryApplications";
import ExponentialTechnologies from "../component/exponentialTechnologies";
import CurrentChallenges from "../component/currentChallenges";
import CustomKia from "../component/customKia";
import ExpertComment from "../component/expertComment";
import ActionButtons from "./actionButtons";
import "../index.scss";
import Tabs from "../../../components/tabsComponent";
import { setActiveTabs, resetInternalGrid } from "../../../../middleware/actions/gridAction";
import { accessRightActionCheck, accessRightInterfaceCheck } from "../../../../utilities";

const AddKeyImpactArea = (props) => {
  const { accessRights: aR } = props;
  const tabRef = useRef();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [saveKiaOverView, setSaveOverView] = useState(false);
  const [saveCustomBrief, setSaveCustomBrief] = useState(false);
  const dispatch = useDispatch();
  const kiaId = props.computedMatch?.params?.kiaId || "";
  const TABS_NAME = ["Overview", "Industry Applications", "Exponential Technologies", "Current Challenges", "Impact Area Brief", "Expert Comment"];
  const hashArr = {
    "#overview": "Overview",
    "#industry": "Industry Applications",
    "#exponential": "Exponential Technologies",
    "#current": "Current Challenges",
    "#impact": "Impact Area Brief",
    "#comment": "Expert Comment",
  };
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (!kiaId) {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.enableTab(4, false);
      tabRef.current.enableTab(5, false);
      tabRef.current.select(0);
    }
  };
  /**
   * @description function used for add KIA  and custom Brief
   *
   */
  const handleSave = (save) => {
    setSaveOverView(save);
  };

  const handleSaveBrief = (save) => {
    setSaveCustomBrief(save);
  };

  const newRecord = () => {
    tabRef.current.enableTab(1, true);
    tabRef.current.enableTab(2, true);
    tabRef.current.enableTab(3, true);
    tabRef.current.enableTab(4, true);
    tabRef.current.enableTab(5, true);
    tabRef.current.select(0);
  };
  const tabSelected = useSelector((state) => state.gridState.internalGridState.tabSelected);

  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, TABS_NAME);
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex]);

  return (
    <>
      <div style={{ background: "#f2f2f2", overflow: "hidden" }} className="gennx-envelope">
        <div style={{ paddingTop: "0%", margin: "0" }}>
          <div>
            <Tabs
              tabsName={TABS_NAME}
              tabRef={tabRef}
              created={created}
              handleClick={() => {
                setSelectedIndex(tabRef.current.selectedItem);
                if (tabSelected != tabRef.current.selectedItem) {
                  dispatch(resetInternalGrid());
                }
                dispatch(setActiveTabs(tabRef.current.selectedItem));
              }}
              cssClass="newHeaderTabCss"
            >
              <div>
                <div key="Overview">
                  <Overview
                    kiaId={kiaId}
                    saveKiaOverView={saveKiaOverView}
                    selectedIndex={selectedIndex}
                    setCurrentTab={(selectedIndex) => {
                      tabRef.current.select(selectedIndex);
                      setSelectedIndex(selectedIndex);
                    }}
                    {...props}
                    handleSave={handleSave}
                    newRecord={newRecord}
                    hashArr={hashArr}
                    tabRef={tabRef}
                    interfaceActionAccess={interfaceActionAccess}
                    interfaceAccess={interfaceAccess}
                  />
                </div>
              </div>
              <div key="industry">
                {selectedIndex === 1 && kiaId && (
                  <IndustryApplications
                    kiaId={kiaId}
                    selectedIndex={selectedIndex}
                    setCurrentTab={(selectedIndex) => {
                      tabRef.current.select(selectedIndex);
                      setSelectedIndex(selectedIndex);
                    }}
                    {...props}
                    newRecord={newRecord}
                    hashArr={hashArr}
                    interfaceActionAccess={interfaceActionAccess}
                    interfaceAccess={interfaceAccess}
                  />
                )}
              </div>
              <div key="exponential">
                {selectedIndex === 2 && kiaId && (
                  <ExponentialTechnologies
                    kiaId={kiaId}
                    selectedIndex={selectedIndex}
                    setCurrentTab={(selectedIndex) => {
                      tabRef.current.select(selectedIndex);
                      setSelectedIndex(selectedIndex);
                    }}
                    {...props}
                    newRecord={newRecord}
                    hashArr={hashArr}
                    interfaceActionAccess={interfaceActionAccess}
                    interfaceAccess={interfaceAccess}
                  />
                )}
              </div>
              <div key="challenge">
                {selectedIndex === 3 && kiaId && (
                  <CurrentChallenges
                    kiaId={kiaId}
                    selectedIndex={selectedIndex}
                    setCurrentTab={(selectedIndex) => {
                      tabRef.current.select(selectedIndex);
                      setSelectedIndex(selectedIndex);
                    }}
                    {...props}
                    newRecord={newRecord}
                    hashArr={hashArr}
                    interfaceActionAccess={interfaceActionAccess}
                    interfaceAccess={interfaceAccess}
                  />
                )}
              </div>
              <div key="customkia">
                {selectedIndex === 4 && kiaId && (
                  <CustomKia
                    kiaId={kiaId}
                    selectedIndex={selectedIndex}
                    setCurrentTab={(selectedIndex) => {
                      tabRef.current.select(selectedIndex);
                      setSelectedIndex(selectedIndex);
                    }}
                    {...props}
                    saveCustomBrief={saveCustomBrief}
                    handleSaveBrief={handleSaveBrief}
                    newRecord={newRecord}
                    hashArr={hashArr}
                    interfaceActionAccess={interfaceActionAccess}
                    interfaceAccess={interfaceAccess}
                  />
                )}
              </div>
              <div key="kiaExpertComment">
                {selectedIndex === 5 && kiaId && (
                  <ExpertComment
                    kiaId={kiaId}
                    selectedIndex={selectedIndex}
                    setCurrentTab={(selectedIndex) => {
                      tabRef.current.select(selectedIndex);
                      setSelectedIndex(selectedIndex);
                    }}
                    {...props}
                    newRecord={newRecord}
                    hashArr={hashArr}
                    interfaceActionAccess={interfaceActionAccess}
                    interfaceAccess={interfaceAccess}
                  />
                )}
              </div>
            </Tabs>
          </div>
        </div>
      </div>
      <ActionButtons
        selectedIndex={selectedIndex}
        setCurrentTab={(selectedIndex) => {
          tabRef.current.select(selectedIndex);
          setSelectedIndex(selectedIndex);
        }}
        accessRights={props.accessRights}
        kiaId={kiaId ? kiaId : null}
        handleSave={() => handleSave(true)}
        showPublished={kiaId && selectedIndex === 0 ? true : false}
        showSave={selectedIndex === 0 ? true : false}
        interfaceActionAccess={interfaceActionAccess}
      />
    </>
  );
};
export default AddKeyImpactArea;
