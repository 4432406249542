/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 14/May/2021
 * File Description: Add/Edit Video Details
 */

import React, { useState, useEffect } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { getProductVariant } from "../../../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { getHosts, addHost, editHost, deleteHost, fetchImageUrl, fetchSingleVideo } from "../../../../../middleware/services/videoApi";
import _ from "underscore";
import { useFormik } from "formik";
import { fetchExternalUser } from "../../../../../middleware/services/userApi";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";
import PopupModal from "./hostingModal";
import "../css/custom.scss";

const HostedBy = (props) => {
  const dispatch = useDispatch();
  const videoId = props.videoId || "";
  const [videoExpertData, setVideoExpertData] = useState([]);
  const [videoExpertDataSource, setVideoExpertDataSource] = useState([]);
  const [checked, setChecked] = useState(undefined);
  const [hostData, setHostData] = useState(null);
  const [hostId, setHostId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const productVariant = getProductVariant();
  const [editData, setEditData] = useState(null);

  const breadCrumbLinks = [
    { linkUrl: "/video", linkName: "Video", linkActive: false },
    { linkUrl: videoId ? `/video/${videoId}/edit` : "/video", linkName: (editData && editData?.videoTitle) || "Add New", linkActive: false },
    {
      linkUrl: `/video/ ${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  let actionAccessAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_HOST);
  let actionAccessEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_HOST);
  let actionAccessDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_HOST);

  useEffect(() => {
    if (videoId) {
      dispatch(showLoader());
      !editData && fetchVideoDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/video"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, editData, props.selectedIndex]);
  const fetchVideoDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "videoTitle",
      "videoContent",
      "videos",
      "videoProfileImage",
      "videoStatus",
      "videoType",
      "videoDuration",
      "videoAccessType",
      "videoUser",
      "videoRegistrationLink",
      "timezone",
      "date",
      "time",
      "videoCommercialImpactTime",
      "videoAreaOfImpact",
      "videoSpecificTechnology",
      "videoTypeOfOrganizationAcademic",
      "videoTypeOfOrganizationResearch",
      "videoTypeOfOrganizationCompany",
      "videoCustomTags",
      "videoExpert",
      "productVariant",
      "surveyAudience",
      "videoIndustryTag",
      "videoIndustryTrendsTags",
      "videoTimezones",
      "videoSource",
      "videoSourceUrl",
      "videoSourceEndUrl",
    ];

    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    fetchSingleVideo(videoId, params).then((res) => {
      let data = res.data.data || {};
      setEditData(data);
      if (data?.videoExpert) {
        const userData = data?.videoExpert?.map((v) => {
          return v.userId;
        });
        userData && userData.length && searchUserByIds(userData);
      }
      data.videoAccessType && setChecked(data.videoAccessType === "Generic" ? false : true);
      formik.setValues({
        videoTitle: data.videoTitle,
        videoDescription: data.videoContent,
        vimeoVideoId: data.videos.videoId,
        videoThumbnail: data.videos.videoThumbnail || "",
        videoSource: data.videos.videoSource,
        status: data.videoStatus,
        videoType: data.videoType,
        commercialImpact: data.videoCommercialImpactTime,
        videoAreaOfImpact: data.videoAreaOfImpact,
        specificTechnology: data.videoSpecificTechnology || [],
        typeOfOraganizationAcademic: data.videoTypeOfOrganizationAcademic || [],
        typeOfOraganizationResearch: data.videoTypeOfOrganizationResearch || [],
        typeOfOraganizationCompany: data.videoTypeOfOrganizationCompany || [],
        customTag: data.videoCustomTags || [],
        videoDurationInMinute: data.videoDuration,
        restrictContentForClient: data.videoAccessType && data.videoAccessType === "Generic" ? "NO" : "YES",
        videoRegistrationLink: data.videoRegistrationLink || "",
        whatNextVideoSource: data.videoSource || "",
        whatNextSourceUrl: data.videoSourceUrl || "",
        whatNextSourceEmbedUrl: data.videoSourceEmbedUrl || "",

        timezone1: (data.videoTimezones && data.videoTimezones[0]) || {},
        timezone2: (data.videoTimezones && data.videoTimezones[1]) || {},
        timezone3: (data.videoTimezones && data.videoTimezones[2]) || {},
      });
      if (productVariant === "Insider") {
        formik.setFieldValue("clientUsers", data.videoUser && _.pluck(data.videoUser, "userId"));
        // formik.setFieldValue("selIndustry", (data?.videoIndustryTag && data?.videoIndustryTag[0]?.industryId) || "");
        // formik.setFieldValue("selTrends", data?.videoIndustryTrendsTags && _.pluck(data?.videoIndustryTrendsTags, "trendId"));
      }

      dispatch(hideLoader());
    });
  };
  const searchUserByIds = async (ids) => {
    const response = await fetchExternalUser({
      fields: ["userFname", "userLname", "userEmail"],
      filters: [["_id", "in", ids]],
      sort: "userFname:asc",
      limit: 10,
    });
    if (response?.data && response?.data?.data) {
      setVideoExpertData(response?.data?.data);
      const resData = response.data.data.map((v) => {
        return { label: `${v.userFname} ${v.userLname} (${v.userEmail})`, value: v.id };
      });
      if (resData) {
        setVideoExpertDataSource(resData);
        formik.setFieldValue(
          "videoExpert",
          resData.map((v) => {
            return v.value;
          })
        );
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    // initialValues: { ...initialState },
    // validationSchema: Yup.object().shape(videoDetailsValidationSchema)
  });

  const saveHost = async (payload) => {
    const formData = new FormData();
    const socialLink = payload.peopleSocialLink;
    delete payload.peopleSocialLink;
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    socialLink && formData.append("peopleSocialLink", JSON.stringify(socialLink));
    await addHost(videoId, formData)
      .then((res) => {
        if (res) {
          dispatch(actionSuccess("Host saved successfully"));
          fetchAllHosts();
          console.log("Host id ", res.data.data.id);
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "something went wrong"));
      });
  };
  const updateHost = async (hostId, payload) => {
    const formData = new FormData();
    const socialLink = payload.peopleSocialLink;
    delete payload.peopleSocialLink;
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    socialLink && formData.append("peopleSocialLink", JSON.stringify(socialLink));
    await editHost(videoId, formData, hostId)
      .then((res) => {
        if (res) {
          dispatch(actionSuccess("Host updated successfully"));
          fetchAllHosts();
          console.log("Host id ", res.data.data.id);
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "something went wrong"));
      });
  };

  const fetchAllHosts = () => {
    let params = {};
    let defaultFilter = [];
    let fields = ["peopleFname", "peopleLname", "peopleCompany", "peopleDesignation", "peopleSocialLink", "profileImage"];
    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    getHosts(videoId, params).then((res) => {
      let data = res.data.data || {};
      data && setHostData(data);
      dispatch(hideLoader());
    });
  };
  useEffect(() => {
    if (videoId) {
      dispatch(showLoader());
      if (!hostData) fetchAllHosts();
      hostData && dispatch(hideLoader());
    }
    dispatch(setSideNavForcedActiveLink("/video"));
  }, [dispatch, hostData]);

  return (
    <>
      <div className="gennx-content-wrapper padding-bottom-100i pt-3" style={{ width: "99.5%" }}>
        <div className="mx-2">
          {actionAccessAdd && hostData && hostData.videoHostedBy.length > 0 && (
            <div className=" pr-3 pb-5 mb-2">
              <ButtonComponent
                cssClass="btn btn-dark text-capitalize float-right"
                style={{ "margin-right": "-0.8em", height: "40px" }}
                onClick={() => {
                  setHostId(null);
                  setIsOpen(true);
                }}
              >
                Add New Host
              </ButtonComponent>
            </div>
          )}
          <div className="row">
            {hostData &&
              hostData.videoHostedBy.length > 0 &&
              hostData.videoHostedBy.map((h, i) => {
                return (
                  <React.Fragment key={`hostlist-${i}`}>
                    <div className="col-md-3 pb-4">
                      <div style={{ display: `flex`, flexDirection: `row` }}>
                        <div className="host-e-card e-card e-card-horizontal " style={{ overflow: "unset", height: "235px", position: "relative" }}>
                          <div style={{ position: "absolute", right: "1px" }}>
                            {actionAccessEdit || actionAccessDelete ? (
                              <ThreeDotMenu
                                Edit={
                                  actionAccessEdit
                                    ? () => {
                                        setHostId(h.id);
                                        setIsOpen(true);
                                      }
                                    : undefined
                                }
                                Delete={
                                  actionAccessDelete
                                    ? () => {
                                        dispatch(
                                          showAlertBox({
                                            okCallback: async () => {
                                              deleteHost(videoId, h.id)
                                                .then((res) => {
                                                  if (res) {
                                                    dispatch(actionSuccess("Host deleted successfully"));
                                                    fetchAllHosts();
                                                  } else {
                                                    dispatch(actionError("Something went wrong"));
                                                  }
                                                })
                                                .catch((err) => {
                                                  dispatch(actionError(err?.data?.message || "Failed to delete video"));
                                                });
                                            },
                                            content: "Are you sure you want to delete?",
                                            okText: "Delete",
                                            cancelText: "Cancel",
                                            title: "dialogAlertCssWarning",
                                          })
                                        );
                                      }
                                    : undefined
                                }
                              />
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="text-center">
                              {videoId && !h.profileImage && <i className="fa-5x text-muted fas fa-user-circle pt-2"></i>}
                              {h.id && h.profileImage && (
                                <img
                                  src={fetchImageUrl(h.profileImage && h.profileImage.imgFileName)}
                                  alt="Sample"
                                  style={{ height: `76px`, borderRadius: "35px", width: "120px" }}
                                  className="mt-2"
                                />
                              )}
                              <div className="e-card-stacked">
                                <div className="videoHostCss e-card-header">
                                  <div className="e-card-header-caption font-weight-bold">
                                    {`${h.peopleFname} ${h.peopleLname}`}

                                    <div className="e-card-content">
                                      {h.peopleDesignation}
                                      <br />
                                      <br />
                                      {h.peopleCompany}
                                    </div>
                                    <div className="e-card-actions center">
                                      {h.peopleSocialLink.linkedIn && (
                                        <Link to={h.peopleSocialLink.linkedIn} target="_blank" className="e-card-btn" title="LinkedIn">
                                          <span className="linkedin svg_icons"></span>
                                        </Link>
                                      )}
                                      {h.peopleSocialLink.facebook && (
                                        <Link to={h.peopleSocialLink.facebook} target="_blank" className="e-card-btn" title="Facebook">
                                          <span className="facebook svg_icons"></span>
                                        </Link>
                                      )}
                                      {h.peopleSocialLink.twitter && (
                                        <Link to={h.peopleSocialLink.twitter} target="_blank" className="e-card-btn" title="Tweets">
                                          <span className="twitter svg_icons"></span>
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>

          {hostData && hostData.videoHostedBy.length === 0 && (
            <div className="e-content pt-4">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="text-muted text-center">You don't have any items here.</h5>
                    <div className="text-center">
                      {actionAccessAdd && (
                        <ButtonComponent cssClass="btn btn-dark text-capitalize" style={{ height: "40px" }} onClick={() => setIsOpen(true)}>
                          Add New Host
                        </ButtonComponent>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <PopupModal
          onSaveData={saveHost}
          onUpdateData={updateHost}
          hostId={hostId}
          productVariant={productVariant}
          closeHandler={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
          videoId={videoId}
          popupTitle={hostId ? "Edit Host" : "Add Host"}
        />
      )}
    </>
  );
};

export default HostedBy;
