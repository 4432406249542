/*================================================================
‘Copyright © 2024, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Subscription Listing Page
   - Use common grid component for list
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 25/03/2022 
  Author						: Prashant Wankhade
================================================================ 
*/
import React, { useEffect } from "react";
import { showLoader, hideLoader, actionError } from "../../../middleware/actions/utilityAction";
import { SUBSCRIPTION_MANAGEMENT_URL } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

export default () => {
  // ** HOOKS **
  const history = useHistory();
  const dispatch = useDispatch();

  // ** UTILS **
  const checkEligiblity = async () => {
    const enterpriseToken = localStorage.getItem("enterprise");
    const userId = localStorage.getItem("enterpriseUserId");
    const refreshToken = localStorage.getItem("refresh-token");
    const clientName = import.meta.env.VITE_ENTERPRISE_CLIENT_NAME;
    if (!userId || !enterpriseToken) return history.push("/login");
    if (enterpriseToken === "undefined" || !enterpriseToken || typeof enterpriseToken !== "string")
      return dispatch(actionError("Unable to access Subscription"));
    dispatch(showLoader());
    redirectToSubscriptionManagement(userId, clientName, enterpriseToken, refreshToken);
    setTimeout(() => dispatch(hideLoader()), 5000);
  };

  const redirectToSubscriptionManagement = (e, c, t, r) =>
    window.open(SUBSCRIPTION_MANAGEMENT_URL + "/auto-login?loginUserId=" + e + "&clientName=" + c + "&token=" + t + "&refreshToken=" + r);

  // ** EFFECTS **
  useEffect(() => {
    checkEligiblity();
  }, []);

  return (
    <div
      className="gennx-content-wrapper content-wrapper px-3"
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", flexDirection: "column" }}
    >
      <h4>Subscription Management is now a standalone app.</h4>
      <h6>Please wait while we redirect and log you in.</h6>

      <button onClick={checkEligiblity}>Click here if you are not redirected.</button>
    </div>
  );
};
