/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  News Listing Page
	 - Use common grid component for list
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 20/Aug/2020 
	Author						: Aditya Tijare
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CommonDataGrid from "../../components/dataGrid";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { actionSuccess, actionError } from "../../../middleware/actions/utilityAction";
import ThreeDotMenu from "../../components/threeDotMenu/index";
import { getProductVariant, fetchTechTrendData, getDateFromMongoDate } from "../../../utilities";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import { fetchPatent, deletePatent, deleteManyPatent } from "../../../middleware/services/patentApi";
import { dataPublishingPatent, dataPublishManyPatent } from "../../../middleware/services/cmsApi";
import AddAudienceModal from "./component/modals/index";
import { resetRedux } from "../../../middleware/actions/taxonomyFilterAction";
import { COMMON_ACCESS_ACTION } from "../../../constants";

export default (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState(undefined);
  const [show, setShow] = useState(false);
  const [openAs, setOpenAs] = useState(undefined);
  const [techTrendData, setTechTrendData] = useState(undefined);
  const productVariant = getProductVariant();
  const [patentId, setpatentId] = useState(undefined);

  const breadCrumbLinks = [{ linkUrl: "/patent", linkName: aR.moduleName, linkActive: true }];

  const fetchTechData = async () => {
    setTechTrendData(await fetchTechTrendData(productVariant));
  };

  /* Common access */
  const accessActionPublish = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.PUBLISH);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionImportPatent = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.IMPORT_PATENT);

  useEffect(() => {
    localStorage.removeItem("selectedPatent");
    if (!techTrendData) fetchTechData();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
      localStorage.removeItem("selectedPatent");
    };
  }, [dispatch]);

  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionPublish
          ? {
              Publish: () => {
                publishPatentMenu(value.id);
              },
            }
          : {})}
        {...(accessActionDelete
          ? {
              Delete: () => {
                deletePatentMenu(value.id);
              },
            }
          : {})}
      />
    );
  };

  const publishPatentMenu = (patentId) => {
    dispatch(
      showAlertBox({
        okCallback: () => {
          dataPublishingPatent(patentId, { platform: productVariant })
            .then((response) => {
              if (response.data.data) {
                dispatch(actionSuccess("Selected record(s) published successfully"));
                if (deleteId) setDeleteId(undefined);
                setDeleteId(true);
              }
            })
            .catch((err) => {
              dispatch(actionError(err.data?.message || "Failed to publish people"));
            });
        },
        content: "Are you sure you want to publish selected record?",
        okText: "Publish",
        cancelText: "Cancel",
        title: "dialogAlertCss",
      })
    );
  };

  const deletePatentMenu = (patentId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          deletePatent(patentId)
            .then((response) => {
              dispatch(actionSuccess("Patent deleted successfully"));
              setDeleteId(patentId);
            })
            .catch((err) => {
              dispatch(actionError(err.data?.message || "Fail to delete news"));
            });
        },
        content: "Are you sure you want to delete selected record?",
        okText: "Delete",
        cancelText: "Cancel",
        title: "dialogAlertCssWarning",
      })
    );
  };

  const deleteManyFunc = (flag) => {
    const selectedPatent = localStorage.getItem("selectedPatent") ? JSON.parse(localStorage.getItem("selectedPatent")) : [];
    if (flag) {
      if (selectedPatent.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteManyPatent({ patentIds: selectedPatent })
                .then((response) => {
                  if (response.data.data) {
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    const params = {};
                    params.limit = 100;
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete Patent"));
                });
            },
            content: "Are you sure you want to delete selected records?",
            okText: "Delete",
            cancelText: "Cancel",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };

  const publishManyFunc = (flag) => {
    const selectedPatent = localStorage.getItem("selectedPatent") ? JSON.parse(localStorage.getItem("selectedPatent")) : [];
    if (flag) {
      if (selectedPatent.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              dataPublishManyPatent({ patentIds: selectedPatent, platform: productVariant })
                .then((response) => {
                  if (response.data.data) {
                    dispatch(actionSuccess("Selected record(s) published successfully"));
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    const params = {};
                    params.limit = 100;
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to published patent"));
                });
            },
            content: "Are you sure you want to publish selected records?",
            okText: "Publish",
            cancelText: "Cancel",
            title: "dialogAlertCss",
          })
        );
      } else {
        dispatch(actionError("No record selected for publish"));
      }
    }
  };

  const patentDateTemplate = (value) => {
    switch (value.column.field) {
      case "Publication Date":
        return !isNaN(Date.parse(value["Publication Date"])) ? <span>{getDateFromMongoDate(value["Publication Date"])}</span> : "";
      case "Create Date":
        return !isNaN(Date.parse(value["Create Date"])) ? <span>{getDateFromMongoDate(value["Create Date"])}</span> : "";
      case "Application Date":
        return !isNaN(Date.parse(value["Application Date"])) ? <span>{getDateFromMongoDate(value["Application Date"])}</span> : "";
      default:
        return "";
    }
  };

  const patentNumberTemplate = (value) => {
    return (
      <span className="span-link" onClick={() => ViewPatentData(value.id)}>
        {value["Patent Number"]}
      </span>
    );
  };

  const patentAssigneeTemplate = (value) => {
    return <span>{value["Patent Assignee"]}</span>;
  };

  const patentCountryTemplate = (value) => {
    return <span>{value["Country Code"]}</span>;
  };

  const patentStatusTemplate = (value) => {
    return <span>{value["Publication Status"]}</span>;
  };

  const patentKindCodeTemplate = (value) => {
    return <span>{value["Kind Code"]}</span>;
  };

  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Is Live":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Technology":
        sourceData = techTrendData;
        break;
      case "Trend":
        sourceData = techTrendData;
        break;
      default:
        break;
    }
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const patentBatchIdTemplate = (value) => {
    return <span className="">{value["Batch Number"]}</span>;
  };

  const ViewPatentData = (flag) => {
    setpatentId(flag);
    if (flag) {
      setShow(false);
      setTimeout(() => {
        setShow(show ? false : true);
      }, 50);
      setOpenAs("view");
      console.log("show", show);
    }
  };

  const columnNames = {
    id: "id",
    "Patent Number": "patentNumber",
    "Patent Assignee": "patentAssignee.companyName",
    "Publication Date": "patentPublicationDate",
    "Application Date": "patentApplicationDate",
    "Country Code": "patentCountryCode",
    "Publication Status": "patentStatus",
    "Kind Code": "patentKindCode",
    "Is Live": "patentIsLive",
    "Batch Number": "batchId",
    "Published Date": "patentPublishedDate",
  };
  if (productVariant) columnNames["Is Live"] = `productVariant~productName:${productVariant},isLive`;
  else columnNames["Is Live"] = "productVariant.isLive";

  if (productVariant && productVariant === "Insider") columnNames["Trend"] = "patentIndustryTrendsTags.trendId";
  else columnNames["Technology"] = "patentTechnologyTags.dtId";
  const columnFields = [
    { field: "id", type: "string", visible: false, allowFiltering: false, showInColumnChooser: false, isPrimaryKey: true },
    {
      field: "Patent Number",
      type: "String",
      template: patentNumberTemplate,
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 170,
    },
    {
      field: "Patent Assignee",
      width: 170,
      type: "String",
      template: patentAssigneeTemplate,
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      textAlign: "Left",
      headerTextAlign: "Left",
    },
    { field: "Publication Date", width: 190, textAlign: "Center", type: "Date", template: patentDateTemplate, headerTextAlign: "Center" },
    { field: "Application Date", width: 200, textAlign: "Center", type: "Date", template: patentDateTemplate, headerTextAlign: "Center" },
    {
      field: "Country Code",
      type: "String",
      width: 150,
      textAlign: "Left",
      template: patentCountryTemplate,
      filterTemplate: StringFilter,
      headerTextAlign: "Left",
    },
    {
      field: "Publication Status",
      type: "String",
      width: 200,
      textAlign: "Left",
      template: patentStatusTemplate,
      filterTemplate: StringFilter,
      headerTextAlign: "Left",
    },
    {
      field: "Kind Code",
      type: "String",
      width: 130,
      textAlign: "Left",
      headerTextAlign: "Left",
      template: patentKindCodeTemplate,
      filterTemplate: StringFilter,
    },
    {
      field: "Is Live",
      type: "String",
      width: 150,
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      headerTextAlign: "Center",
    },
    {
      field: "Batch Number",
      type: "String",
      width: 150,
      template: patentBatchIdTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
    },
    { field: "Published Date", width: 190, headerTextAlign: "Center", textAlign: "Center", type: "Date", template: patentDateTemplate },
  ];

  if (accessActionPublish || accessActionDelete) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Center",
      headerTextAlign: "Center",
    });
    columnFields.push({
      field: "Action",
      type: "String",
      width: 65,
      template: actionTemplate,
      textAlign: "Right",
      headerTextAlign: "Center",
      allowFiltering: false,
      showInColumnChooser: false,
      allowSorting: false,
      freeze: "Right",
    });
  }
  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];

    rowData &&
      rowData.forEach((patentData) => {
        let data = {};
        //	data.test = 1;
        data.id = patentData.id;
        data["Patent Title"] = patentData.patentTitle;
        data["Patent Number"] = patentData.patentNumber;
        data["Country Code"] = patentData.patentCountryCode;
        data["Patent Assignee"] = patentData.patentAssignee
          .map((company) => {
            if (company.companyName != "") {
              return company.companyName;
            }
            return "";
          })
          .filter((company) => company != "")
          .join(" | ");

        data["Inventors"] = patentData.patentInventors
          .map((people) => {
            if (people.peopleFname != "") {
              return people.peopleFname;
            }
            return "";
          })
          .filter((people) => people != "")
          .join(", ");

        data["Publication Date"] = new Date(patentData.patentPublicationDate);
        data["Application Date"] = new Date(patentData.patentApplicationDate);
        data["Kind Code"] = patentData.patentKindCode;
        data["Batch Number"] = patentData.batchId;
        data["Publication Status"] = patentData.patentStatus;
        data["Published Date"] = new Date(patentData.patentPublishedDate);
        if (patentData.productVariant?.length) {
          if (productVariant) {
            const rDPV = patentData.productVariant.find((pV) => pV.productName === productVariant);
            data["Is Live"] = rDPV?.isLive ? rDPV.isLive : "NO";
          } else data["Is Live"] = patentData.productVariant[0].isLive ? patentData.productVariant[0].isLive : "NO";
        } else data["Is Live"] = "NO";
        formatedRowData.push(data);
      });
    return formatedRowData;
  };

  const fetchPatentWithProductVariant = (params = {}) => {
    let defaultFilter = [];
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let { filters = [] } = params;
    if (filters) {
      filters = filters.map((item) => {
        const tech = techTrendData.filter((f) => {
          return ["patentIndustryTrendsTags.trendId", "patentTechnologyTags.dtId"].includes(item[0]) && f.text === item[2];
        });
        if (productVariant === "Insider") {
          return item[0] === "patentIndustryTrendsTags.trendId" ? [item[0], "in", [tech[0].id]] : item;
        } else {
          return item[0] === "patentTechnologyTags.dtId" ? [item[0], "in", [tech[0].id]] : item;
        }
      });
    }
    params.filters = [...filters, ...defaultFilter];
    if (!params.sort) {
      params.sort = "patentCreatedDate:desc";
    }

    return fetchPatent(params);
  };

  const importData = (flag) => {
    if (flag) {
      if (show) setShow(false);
      setShow(true);
      setOpenAs("upload");
    }
  };

  // Reset Redux for filter by taxonomy
  useEffect(() => {
    if (window.location.pathname == `/patent`) {
      dispatch(resetRedux());
    }
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        <CommonDataGrid
          gridTitle="Patent Records"
          fetch={fetchPatentWithProductVariant}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          importPatent={accessActionImportPatent}
          isImport={importData}
          deleteId={deleteId}
          getMultiSelectedRows={(data) => {
            localStorage.setItem("selectedPatent", JSON.stringify([...data]));
          }}
          getMultiDeSelectedRows={(data) => {
            localStorage.setItem("selectedPatent", JSON.stringify([...data]));
          }}
          deleteMany={accessActionDelete ? deleteManyFunc : null}
          publishMany={accessActionPublish ? publishManyFunc : null}
          isPublish={accessActionPublish ? publishManyFunc : null}
          addNew={() => {
            if (show) setShow(false);
            setOpenAs("save");
            setTimeout(() => {
              setShow(true);
            }, 100);
          }}
          clearFilterByTaxonomy={true}
        />
        {show && (
          <AddAudienceModal
            closeHandler={() => {
              setShow(false);
            }}
            show={show}
            gridReload={() => {
              setDeleteId(new Date());
              setShow(false);
            }}
            openAs={openAs}
            patentId={patentId}
          />
        )}
      </div>
    </div>
  );
};
