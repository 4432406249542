import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { deleteWidgetContent, fetchWidget, updateWidgetContent } from "middleware/services/customizableWidgetApi";
import { PINNED_CONTENT } from "constants/widget-constants";
import { combineIndustriesWithWidgets, filterIndustryFromTrends, filterIndustryIds } from "utils";
import { COMMON_ACCESS_ACTION, CONTENT_TYPE, PRODUCT_VARIANT } from "../../../../../constants";
import { Autocomplete, Button, Checkbox, TextField } from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import "./index.scss";
import { useDispatch } from "react-redux";
import { getLoggedInUser } from "utilities";
import { actionError, actionSuccess, hideLoader, showLoader } from "middleware/actions/utilityAction";
import { fetchSingleVideo, updateVideo } from "middleware/services/videoApi";

const Setting = (props) => {
  const dispatch = useDispatch();
  let user = getLoggedInUser();
  const [selectedIds, setSelectedIds] = useState([]);
  const [addToWidget, setAddToWidget] = useState(false);
  const [buzzingStory, setBuzzingStory] = useState("");
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null); // Create a ref for the container
  const [videoDetails, setVideoDetails] = useState({});

  const accessActionWidgetSave = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionNewsletterSave = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);

  const { data: videoData, isFetching: videoFetching } = useQuery({
    queryKey: ["video-data"],
    queryFn: () => fetchSingleVideo(props.videoId),
    enabled: Boolean(props.videoId),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    setVideoDetails(videoData?.data?.data);
  }, [videoData?.data?.data]);

  const taggedIndustries = useMemo(() => filterIndustryFromTrends(videoDetails?.videoIndustryTrendsTags), [videoDetails?.videoIndustryTrendsTags]);

  const taggedIndustriesIds = useMemo(() => filterIndustryIds(videoDetails?.videoIndustryTrendsTags), [videoDetails?.videoIndustryTrendsTags]);

  const { data: widgetUniqueData, isFetching: widgetUniqueFetching } = useQuery({
    queryKey: ["widget-unique", taggedIndustriesIds],
    queryFn: () => {
      let params = {};
      params.filters = [
        ["widgetIndustryId", "in", taggedIndustriesIds],
        ["widgetContentType", "eq", CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS],
        ["widgetName", "neq", PINNED_CONTENT],
        ["productVariant.productName", "eq", PRODUCT_VARIANT.INSIDER],
        ["widgetActive", "eq", "YES"],
      ];
      params.sort = "widgetDisplayName:asc";
      return fetchWidget(params);
    },
    enabled: taggedIndustriesIds?.length > 0 && props?.productVariant[0]?.productName === PRODUCT_VARIANT.INSIDER,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  let options = useMemo(
    () => combineIndustriesWithWidgets(taggedIndustries, widgetUniqueData?.data?.data),
    [taggedIndustries, widgetUniqueData?.data?.data]
  );
  const groupedOptions = useMemo(() => options?.flatMap(({ group, items }) => items.map(({ id, label }) => ({ id, group, label }))), [options]);

  // Find selected options based on IDs
  const selectedOptions = groupedOptions?.filter((option) => selectedIds?.includes(option.id));

  // Check if an ID is selected
  const isChecked = (id) => selectedIds?.includes(id);

  const { isFetching: widgetAttachedDataFetching } = useQuery({
    queryKey: ["widget-attached-data", taggedIndustriesIds, props?.videoId],
    queryFn: () => {
      let params = {};
      params.filters = [
        ["widgetIndustryId", "in", taggedIndustriesIds],
        ["widgetContent.widgetContentId", "eq", props?.videoId],
        ["widgetName", "neq", PINNED_CONTENT],
        ["productVariant.productName", "eq", PRODUCT_VARIANT.INSIDER],
        ["widgetActive", "eq", "YES"],
      ];
      params.sort = "widgetDisplayName:asc";
      return fetchWidget(params);
    },
    enabled: !!props?.videoId && taggedIndustriesIds?.length > 0 && props?.productVariant[0]?.productName === PRODUCT_VARIANT.INSIDER,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    onSuccess: (response) => {
      if (!response?.data?.data?.length) return;
      const widgetIds = response?.data?.data?.map((widget) => widget.id);
      setSelectedIds([...selectedIds, ...widgetIds]);
    },
  });

  const handleSavewidgetSettings = async () => {
    if (!addToWidget) {
      if (videoDetails?.productVariant[0]?.isLive !== "YES") return dispatch(actionError("Content is not yet published."));
      dispatch(showLoader());
      let payload = {
        widgetContentId: props?.videoId,
        widgetModifiedByUserId: user?._id,
        widgetModifiedByUserName: user?.user_name,
      };
      await deleteWidgetContent(payload)
        .then((response) => {
          dispatch(actionSuccess("Widget setting saved successfully."));
          setSelectedIds([]);
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e?.data?.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    } else {
      if (videoDetails?.productVariant[0]?.isLive !== "YES") return dispatch(actionError("Content is not yet published."));
      if (!selectedIds?.length) return dispatch(actionError("Please select widget(s)."));
      dispatch(showLoader());
      let payload = {
        widgetIds: selectedIds,
        widgetContent: {
          widgetContentId: props?.videoId,
          widgetContentOrder: 1,
          widgetContentType: "Video",
        },
        widgetModifiedByUserId: user?._id,
        widgetModifiedByUserName: user?.user_name,
      };
      await updateWidgetContent(payload)
        .then((response) => {
          dispatch(actionSuccess("Widget setting saved successfully."));
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e?.data?.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    }
  };

  const handleSaveNewsletterSettings = () => {
    let videoIsBuzzingStory = buzzingStory === true ? "YES" : "NO";

    let overviewData = {
      videoIsBuzzingStory: videoIsBuzzingStory,
    };

    let saveOverviewData = new FormData();
    for (let uKey in overviewData) {
      if (typeof overviewData[uKey] !== "object") {
        if (overviewData[uKey]) saveOverviewData.append(uKey, overviewData[uKey]);
      } else {
        if (overviewData[uKey]) saveOverviewData.append(uKey, JSON.stringify(overviewData[uKey]));
      }
    }

    dispatch(showLoader());
    try {
      saveOverviewData.append("videoCreatedByUserId", user?._id);
      saveOverviewData.append("videoCreatedByUserName", user?.user_fname + " " + user?.user_lname);

      updateVideo(props?.videoId, saveOverviewData)
        .then((response) => {
          dispatch(actionSuccess("Newsletter setting saved successfully."));
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e?.data?.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    } catch (e) {
      dispatch(hideLoader());
      if (e?.data?.message) {
        dispatch(actionError(e.data.message));
      } else {
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  };

  useEffect(() => {
    setBuzzingStory(videoDetails?.videoIsBuzzingStory === "YES" ? true : false);
  }, [videoDetails]);

  useEffect(() => {
    if (selectedIds?.length) setAddToWidget(true);
  }, [selectedIds]);

  // Function to handle scroll events within the container
  const handleScroll = () => {
    setOpen(false);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (videoFetching || widgetUniqueFetching || widgetAttachedDataFetching) dispatch(showLoader());
    else dispatch(hideLoader());
  }, [videoFetching || widgetUniqueFetching, widgetAttachedDataFetching]);

  return (
    <div ref={containerRef} className="gennx-content-wrapper padding-bottom-150i pt-3" style={{ width: "99.5%" }}>
      {props?.productVariant[0]?.productName === PRODUCT_VARIANT.INSIDER && (
        <>
          <div className="design-color mt-3 ml-2" style={{ width: "99%" }}>
            <span className="header-name"> Widget Setting</span>
          </div>
          <div className="mx-2 bg-white" style={{ width: "99%" }}>
            <div
              className="mx-2 pt-4"
              style={{
                height: "68px",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <div className="ml-2 mr-1">
                <label>Add to Widget</label>
                <label className="custom-switch">
                  <input
                    id="newsAddToWidgets"
                    name="newsAddToWidgets"
                    type="checkbox"
                    value={addToWidget ? "YES" : "NO"}
                    checked={addToWidget}
                    onChange={(e) => {
                      setAddToWidget(!addToWidget);
                    }}
                  />
                  <div>
                    <span className="on">YES</span>
                    <span className="off">NO</span>
                  </div>
                  <i></i>
                </label>
              </div>
              {addToWidget && (
                <Autocomplete
                  multiple
                  // limitTags={2}
                  options={groupedOptions}
                  open={open} // Control the open state
                  onOpen={() => setOpen(true)} // Set to true when opened
                  onClose={() => setOpen(false)} // Set to false when closed
                  groupBy={(option) => option.group}
                  getOptionLabel={(option) => option.label}
                  disableCloseOnSelect
                  freeSolo={false} // Disable manual typing
                  renderInput={(params) => {
                    const maxDisplayedItems = 2; // Control how many items to display
                    const remainingCount = selectedOptions.length - maxDisplayedItems;

                    return (
                      <TextField
                        {...params}
                        label="Select Widgets"
                        variant="outlined"
                        required
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              {remainingCount > 0 && (
                                <span style={{ fontSize: "12px", color: "#000000", position: "absolute", right: "60px" }}>
                                  +{remainingCount} more
                                </span>
                              )}
                            </>
                          ),
                        }}
                        // Add `onKeyDown` to prevent typing
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "44px", // Ensure the input's height remains fixed
                            overflow: "hidden", // Prevent overflow
                            display: "flex", // Ensure chips are aligned correctly
                            alignItems: "center", // Center items vertically
                          },
                          "& .MuiOutlinedInput-root": {
                            height: "50px", // Ensure the root's height is consistent
                            overflow: "hidden", // Prevent overflow
                            flexWrap: "nowrap",
                            paddingRight: "0px",
                            "& .MuiAutocomplete-tag": {
                              display: "none", // Hide chips to control the number of visible chips
                            },
                            "& .MuiAutocomplete-tag:nth-of-type(-n+2)": {
                              display: "inline-flex", // Show only the first two chips
                            },
                          },
                        }}
                      />
                    );
                  }}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        fontSize: "12px",
                        padding: "0px 0px 0px 26px",
                        margin: "0px",
                        display: "flex",
                        gap: "8px",
                        height: "36px",
                        alignItems: "center",
                        backgroundColor: isChecked(option.id) ? "transparent" : "transparent",
                        color: "black",
                        fontWeight: "400",
                      }}
                    >
                      <Checkbox
                        icon={<CheckBoxOutlineBlank />}
                        checkedIcon={<CheckBox />}
                        checked={isChecked(option.id)}
                        sx={{
                          width: "16px",
                          height: "16px",
                          color: "#DBDBDD",
                        }}
                      />
                      {option.label}
                    </li>
                  )}
                  value={selectedOptions}
                  onChange={(_, newValue) => {
                    // Update selected IDs based on selected options
                    const newSelectedIds = newValue.map((option) => option.id);
                    setSelectedIds(newSelectedIds);
                  }}
                  sx={{
                    width: "400px",
                    height: "44px", // Fixed height for the container
                    "& .MuiOutlinedInput-root": {
                      height: "100%", // Ensure the height is consistent
                      "&.Mui-focused": {
                        "& fieldset": {
                          borderColor: "#f94f5e",
                        },
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#f94f5e",
                      },
                    },
                    "& .MuiButtonBase-root.MuiChip-root": {
                      maxWidth: "137px",
                      height: "26px",
                      fontSize: "12px",
                      color: "black",
                      backgroundColor: "#F2F2F2",
                      border: "1px solid #DBDBDD",
                      borderRadius: "4px",
                    },
                    "& .MuiChip-deleteIcon": {
                      fontSize: "16px !important",
                      color: "black !important",
                      margin: "-1px 4px 0px -6px !important",
                    },
                    "& .MuiChip-label": {
                      paddingLeft: "4px !important",
                      paddingRight: "10px !important",
                    },
                  }}
                />
              )}
            </div>
            <div className="mt-1 mx-2 mb-4">
              <span className="ml-2" style={{ fontSize: "14px", fontWeight: "bold" }}>
                Note :{" "}
              </span>
              <ul className="pb-2" style={{ marginLeft: "32px" }}>
                <li style={{ fontSize: "14px" }}>Content must be published before adding to widget.</li>
              </ul>
            </div>
            {accessActionWidgetSave && (
              <div style={{ display: "flex", justifyContent: "end", borderTop: "1px solid #DBDBDD" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#f94f5e",
                    "&:disabled": {
                      opacity: "0.5 !important ",
                      backgroundColor: "#DBDBDD",
                      color: "#FFFFFF",
                    },
                    textTransform: "inherit !important",
                    "&:hover": {
                      backgroundColor: "#f94f5e",
                    },
                  }}
                  className="pl-4 pr-4 my-2 mr-3"
                  onClick={() => handleSavewidgetSettings()}
                  // disabled={!selectedIds?.length}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
          <div className="design-color mt-3 ml-2" style={{ width: "99%" }}>
            <span className="header-name"> Newsletter Setting</span>
          </div>
          <div className="mx-2 bg-white" style={{ width: "99%" }}>
            <div
              className="mx-2"
              style={{
                height: "80px",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <div className="ml-2 mr-1">
                <label>Is Buzzing Story?</label>
                <label className="custom-switch">
                  <input
                    id="videoIsBuzzingStory"
                    name="videoIsBuzzingStory"
                    type="checkbox"
                    value={buzzingStory ? "YES" : "NO"}
                    checked={buzzingStory}
                    onChange={(e) => {
                      setBuzzingStory(!buzzingStory);
                    }}
                  />
                  <div>
                    <span className="on">YES</span>
                    <span className="off">NO</span>
                  </div>
                  <i></i>
                </label>
              </div>
            </div>
            {accessActionNewsletterSave && (
              <div style={{ display: "flex", justifyContent: "end", borderTop: "1px solid #DBDBDD" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#f94f5e",
                    "&:disabled": {
                      opacity: "0.5 !important ",
                      backgroundColor: "#DBDBDD",
                      color: "#FFFFFF",
                    },
                    textTransform: "inherit !important",
                    "&:hover": {
                      backgroundColor: "#f94f5e",
                    },
                  }}
                  className="pl-4 pr-4 my-2 mr-3"
                  onClick={() => handleSaveNewsletterSettings()}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Setting;
