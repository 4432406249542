/** External Dependencies */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Menu from "@scaleflex/ui/core/menu";
import Custom from "@scaleflex/icons/custom";

/** Internal Dependencies */
import { SET_CROP, SET_RESIZE, ZOOM_CANVAS, SET_FEEDBACK } from "../../../actions";
import { useStore } from "../../../hooks";
import { StyledToolsBarItemButtonLabel } from "../../ToolsBar/ToolsBar.styled";
import { DEFAULT_ZOOM_FACTOR, TOOLS_IDS, FEEDBACK_STATUSES } from "../../../utils/constants";
import toPrecisedFloat from "../../../utils/toPrecisedFloat";
import getZoomFitFactor from "../../../utils/getZoomFitFactor";
import { StyledOpenMenuButton } from "./Crop.styled";
import { DEFAULT_CROP_PRESETS } from "./Crop.constants";
import CropPresetGroupsList from "./CropPresetGroupsFolder";
import CropPresetItem from "./CropPresetItem";
import getNumber from "app/components/react-filerobot-image-editor/utils/getNumber";

const PREFIX_ICONS_DIMENS = { height: 16, width: 16 };

const CropPresetsOption = ({ anchorEl, onClose }) => {
  const {
    dispatch,
    t,
    adjustments: { crop, crop: { ratio: currentRatio, ratioTitleKey, ratioFolderKey, isFlippedX, isFlippedY } = {} } = {},
    resize = {},
    shownImageDimensions,
    config,
    originalImage,
  } = useStore();
  const cropConfig = config[TOOLS_IDS.CROP];
  const { imageContainer } = config;

  const allPresets = useMemo(() => {
    const { presetsItems = [], presetsFolders = [] } = cropConfig;
    return [...presetsFolders, ...DEFAULT_CROP_PRESETS, ...presetsItems];
  }, [cropConfig]);

  const saveCrop = ({ width, height, x, y }, noHistory, titleKey) => {
    const newWidth = getNumber(width, shownImageDimensions.width, originalImage.width);
    const newHeight = getNumber(height, shownImageDimensions.height, originalImage.height);
    // if (newWidth > shownImageDimensions.width || newHeight > shownImageDimensions.height) return;
    const newCrop = {
      x: isFlippedX ? shownImageDimensions.width - x - newWidth : x,
      y: isFlippedY ? shownImageDimensions.height - y - newHeight : y,
      width: newWidth,
      height: newHeight,
      ratioTitleKey: titleKey,
    };

    const isOldCropBiggerThanResize = crop.width >= resize.width && crop.height >= resize.height;
    if (resize.width && resize.height && (newWidth < resize.width || newHeight < resize.height) && isOldCropBiggerThanResize) {
      dispatch({
        type: SET_FEEDBACK,
        payload: {
          feedback: {
            message: t("cropSizeLowerThanResizedWarning"),
            status: FEEDBACK_STATUSES.WARNING,
          },
        },
      });
    }
    dispatch({
      type: SET_RESIZE,
      payload: {
        width: null,
        height: null,
        ratioUnlocked: false,
      },
    });
    dispatch({
      type: SET_CROP,
      payload: {
        ...crop,
        ...newCrop,
        dismissHistory: noHistory,
      },
    });
  };

  const handleCrop = (e, newCropRatio, cropProps) => {
    e.stopPropagation();
    if (cropProps?.customCropSize) {
      saveCrop(
        {
          width: cropProps.width ?? originalImage.width,
          height: cropProps.height ?? originalImage.height,
          x: 0,
          y: 0,
        },
        false,
        cropProps.ratioTitleKey
      );
    } else {
      changeCropRatio(newCropRatio, cropProps);
    }
    onClose();
  };

  const changeCropRatio = (newCropRatio, cropProps) => {
    const newCrop = {
      ratio: newCropRatio,
      ratioTitleKey: cropProps.ratioTitleKey,
      ratioGroupKey: cropProps.ratioGroupKey,
      ratioFolderKey: cropProps.ratioFolderKey,
    };
    dispatch({
      type: SET_RESIZE,
      payload: {
        width: null,
        height: null,
        ratioUnlocked: false,
      },
    });
    dispatch({
      type: SET_CROP,
      payload: newCrop,
    });

    if (cropConfig.autoResize) {
      dispatch({
        type: SET_RESIZE,
        payload: {
          width: cropProps.width,
          height: cropProps.height,
          manualChangeDisabled: cropProps.disableManualResize,
        },
      });
      dispatch({
        type: ZOOM_CANVAS,
        payload: {
          factor:
            cropProps.width > shownImageDimensions.width || cropProps.height > shownImageDimensions.height
              ? getZoomFitFactor(shownImageDimensions, cropProps)
              : DEFAULT_ZOOM_FACTOR,
        },
      });
    }
  };

  const renderPreset = ({ titleKey, descriptionKey, ratio, width, height, groups, icon: Icon, disableManualResize, customCropSize = false }) =>
    groups ? (
      <CropPresetGroupsList
        key={titleKey}
        titleKey={titleKey}
        groups={groups}
        Icon={Icon}
        onItemSelect={handleCrop}
        prefixIconDimensions={PREFIX_ICONS_DIMENS}
        t={t}
        disableManualResize={disableManualResize}
      />
    ) : (
      <CropPresetItem
        key={ratio}
        ratio={ratio ?? toPrecisedFloat(width / height)}
        titleKey={`${titleKey} ${width === imageContainer?.width && height === imageContainer.height ? "- Recommended" : ""}`}
        customCropSize={customCropSize}
        t={t}
        description={t(descriptionKey)}
        Icon={Icon || Custom}
        isActive={currentRatio === (ratio ?? toPrecisedFloat(width / height)) && !ratioFolderKey}
        width={width}
        height={height}
        onClick={handleCrop}
        disableManualResize={disableManualResize}
        disableList={width > originalImage.width || height > originalImage.height ? true : false}
      />
    );

  const toolTitleKey = ratioTitleKey || "cropTool";

  return (
    <>
      <StyledToolsBarItemButtonLabel className="FIE_crop-tool-label FIE_selected-crop-preset-label">{t(toolTitleKey)}</StyledToolsBarItemButtonLabel>
      <StyledOpenMenuButton className="FIE_crop-presets-opener-button" color="link" size="lg">
        {/* BOTTOM ARROW HTML CODE : TOP ARROW HTML CODE */}
        {anchorEl ? <>&#9652;</> : <>&#9662;</>}
      </StyledOpenMenuButton>
      <Menu
        className="FIE_crop-presets-menu"
        anchorEl={anchorEl}
        enableOverlay={false}
        onClose={onClose}
        open={Boolean(anchorEl)}
        position="top"
        style={{ maxHeight: "200px", overflow: "auto" }}
      >
        {allPresets.map((record) =>
          (record.width <= imageContainer.width && record.height <= imageContainer.height) || record?.ratio ? renderPreset(record) : null
        )}
      </Menu>
    </>
  );
};

CropPresetsOption.defaultProps = {
  anchorEl: null,
};

CropPresetsOption.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(HTMLElement),
};

export default CropPresetsOption;
