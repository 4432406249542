/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  News Add | Edit Page
	 Summary : Render Utility component is responsible for rendering respective component 
	 [e.g Overview | Content Management | Tagging]
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 21/Aug/2020 
	Author						: Aditya Tijare
================================================================ 
*/
import React, { useState, Suspense, useEffect, useRef } from "react";
import Tabs from "../../../components/tabsComponent";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import RenderUtility from "./component/utilities/renderUtility";
import FooterBar from "./component/utilities/footerBar";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { fetchSingleObjective } from "../../../../middleware/services/businessObjectiveApi";
import { accessRightActionCheck, accessRightInterfaceCheck } from "../../../../utilities";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";

/*
	@Description : Stepper Labels for news Add | Edit Page
*/

const Add = (props) => {
  const { accessRights: aR } = props;
  const tabRef = useRef();
  const history = useHistory();
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const objectiveId = props.computedMatch?.params?.objectiveId || "";
  const [saveObjective, setSaveObjective] = useState(false);
  const [saveObjectiveContent, setSaveObjectiveContent] = useState(false);
  const [objectiveTitle, setObjectiveTitle] = useState("");
  const isPublishClicked = false;
  const TABS_NAME = ["Basic", "Content", "Objective Taxonomy"];
  const hashArr = { "#basic": "Basic", "#content": "Content", "#taxonomy": "Objective Taxonomy" };

  /*
		@Description : Define breadcrumb for Add | Edit Objective
	*/
  const breadCrumbLinks = [
    { linkUrl: "/business-objective/customer", linkName: aR.moduleName, linkActive: false },

    {
      linkUrl: objectiveId ? `/business-objective/customer/${objectiveId}/edit#basic` : `/business-objective/customer/add#basic`,
      linkName: objectiveId ? `${objectiveTitle}` : "Add New",
      linkActive: false,
    },
    {
      linkUrl: `/business-objective/customer/${objectiveId}/edit${Object.keys(hashArr)[selectedIndex]}`,
      linkName: Object.values(hashArr)[selectedIndex],
      linkActive: true,
    },
  ];

  const dispatch = useDispatch();

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (objectiveId !== "") {
      if (selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.select(0);
    }
  };
  /*
		@Description : Set active link on sidebar for objective
	*/

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/business-objective/customer"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, selectedIndex, props.location.hash, objectiveTitle]);
  /*
		@Description : Retrieve respective objective data 
	*/
  useEffect(() => {
    if (isPublishClicked) {
      handleSave(true);
      handleSaveContent(true);
    }
    if (objectiveId !== "") {
      const response = fetchSingleObjective(objectiveId, {
        fields: ["objectiveTitle"],
      });
      response
        .then((response) => {
          if (response.status === 200) {
            response.data && setObjectiveTitle(response.data.data.objectiveTitle);
          }
        })
        .catch((e) => {});
    }
  }, [objectiveId, isPublishClicked]);

  /*
		@Description : Change saveObjective state for trigger objective save event
		State Change trigger from footer component
	*/
  const handleSave = (objectiveSaveState) => {
    setSaveObjective(objectiveSaveState);
  };
  /*
		@Description : Change saveObjectiveContent state for trigger objective save event
		State Change trigger from footer component
	*/
  const handleSaveContent = (objectiveSaveState) => {
    setSaveObjectiveContent(objectiveSaveState);
  };
  /*
		@Description : Redirect to edit page after adding objective overview (Applicable only for add new objective)
	*/
  const handleRedirectOnAddObjective = (addObjectiveId) => {
    newRecord();
    history.push(`/business-objective/customer/${addObjectiveId}/edit#basic`);
  };
  const newRecord = () => {
    tabRef.current.enableTab(1, true);
    tabRef.current.enableTab(2, true);
    tabRef.current.select(0);
  };

  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, TABS_NAME);
  /* Interface functionality wise access*/
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  return (
    <>
      <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelop">
        <Tabs
          tabsName={TABS_NAME}
          tabRef={tabRef}
          created={created}
          handleClick={() => {
            setSelectedIndex(tabRef.current.selectedItem);
          }}
          cssClass="newHeaderTabCss"
        >
          <Suspense
            fallback={
              <div className="text-center">
                <h3>Loading...</h3>
              </div>
            }
          >
            <RenderUtility
              newRecord={newRecord}
              activeStepper={Object.keys(hashArr)[selectedIndex]}
              objectiveId={objectiveId}
              handleSave={handleSave}
              handleSaveContent={handleSaveContent}
              saveObjective={saveObjective}
              saveObjectiveContent={saveObjectiveContent}
              handleRedirectOnAddObjective={handleRedirectOnAddObjective}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              hashArr={hashArr}
              accessRightInterfaceRights={accessRightInterfaceRights}
              interfaceActionAccess={interfaceActionAccess}
            />
          </Suspense>
        </Tabs>
      </div>
      <FooterBar
        selectedIndex={selectedIndex}
        setCurrentTab={(selectedIndex) => {
          tabRef.current.select(selectedIndex);
          setSelectedIndex(selectedIndex);
        }}
        handleSave={handleSave}
        handleSaveContent={handleSaveContent}
        objectiveId={objectiveId}
        activeStepper={Object.keys(hashArr)[selectedIndex]}
        tabsName={TABS_NAME}
        moduleName={aR.moduleName}
        hashArr={hashArr}
      />
    </>
  );
};

export default Add;
