/**
 * @author: Anand Kumar
 * @date : 04/Jan/2021
 * File Description:Custom Form Basic Detials Page
 */

import React, { useEffect } from "react";
import { MultiSelect } from "../../components/CustomFormElements/syncFusionFormFields";

const BusinessObjectiveBasicDetails = (props) => {
  const formik = props.formik;

  useEffect(() => {
    if (props.editData) {
      let editData;
      if (props.editData?.formPlaceholder) {
        editData =
          props.editData.formPlaceholder &&
          props.editData.formPlaceholder.map((v) => {
            return v.placeholderId;
          });
      }
      formik.setFieldValue("formPlaceholder", editData);
    }
  }, [props.editData]);

  return (
    <div className="col-md-4">
      {props?.placeHolderData && (
        <MultiSelect
          formik={formik}
          mode="Box"
          sortOrder="Acending"
          required="required"
          placeholder="Select Placeholder *"
          id="formPlaceholder"
          name="formPlaceholder"
          value={formik.values["formPlaceholder"] && formik.values["formPlaceholder"]}
          allowCustomValue={false}
          fields={{ text: "placeholderDispalyName", value: "placeholderId" }}
          dataSource={props.placeHolderData}
        />
      )}
    </div>
  );
};

export default BusinessObjectiveBasicDetails;
