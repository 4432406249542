/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Vendor Profile
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { TextField, MultiSelect } from "../../../components/CustomFormElements/syncFusionFormFields";
import { actionStart, actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import Accordion from "../../../components/accordion";
import { addVendorValidationSchema, editVendorValidationSchema } from "../../../helper/validationSchema/vendorValidationSchema";
import { addVendor, updateVendor, fetchSingleVendor, fetchVendorProfileImageUrl, fetchTeam } from "../../../../middleware/services/cmsApi";
import userImage from "../../../../assets/images/user.jpg";
import { getLoggedInUser, isAdmin, getProductVariant, accessRightActionCheck } from "../../../../utilities";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

const initialValues = {
  vendorName: "",
  vendorEmail: "",
  vendorWebsite: "",
  vendorContactPersonFname: "",
  vendorContactPersonLname: "",
  vendorContactPersonPhone: "",
  vendorPassword: "",
  vendorConfirmPassword: "",
  vendorActive: undefined,
};
const VendorProfile = (props) => {
  const { accessRights: aR } = props;
  const accessPermission = true;
  const history = useHistory();
  const editUser = props.path === "/vendor/add" ? false : true;
  const userTokenDetails = getLoggedInUser();
  const isMultipleProductVariant = userTokenDetails.product_variant?.length > 1 ? true : false;
  const vendorId = editUser ? props.computedMatch?.params?.vendorId : undefined;
  const [imageError, setImageError] = useState("");
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionEditProfileImage = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT_PROFILE_IMAGE);
  const accessActionDeleteProfileImage = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE_PROFILE_IMAGE);

  // specify breadcrumb here
  const breadCrumbLinksAddVendor = [
    { linkUrl: "/vendor", linkName: aR.moduleName, linkActive: false },
    { linkUrl: "/vendor/add", linkName: "Add Vendor", linkActive: true },
  ];
  const breadCrumbLinksEditVendor = [
    { linkUrl: "/vendor", linkName: aR.moduleName, linkActive: false },
    { linkUrl: `/vendor/${vendorId}/edit#profile`, linkName: "Edit Vendor", linkActive: true },
  ];

  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [existingUser, setExistingUser] = useState({});
  const [teams, setTeams] = useState([]);
  const [productVariant, setProductVariant] = useState(undefined);
  const [vendorProfileImgSrc, setVendorProfileImgSrc] = useState(undefined);

  if (isAdmin() || isMultipleProductVariant) {
    initialValues.productVariant = "";
    editVendorValidationSchema.productVariant = Yup.array().min(1, "Product Variant field must have at least 1 item");
    addVendorValidationSchema.productVariant = Yup.array()
      .min(1, "Product Variant field must have at least 1 item")
      .required("Product variant is required");
  } else {
    initialValues.productVariant = [getProductVariant()];
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editUser
      ? Yup.object().shape({
          ...editVendorValidationSchema,
          productVariant: Yup.array().min(1, "Product Variant field must have at least 1 item"),
        })
      : Yup.object().shape({
          ...addVendorValidationSchema,
          productVariant: Yup.array().min(1, "Product Variant field must have at least 1 item").required("Product variant is required"),
        }),
  });

  /**
   * @description fetch single vendor detail
   * @param {String} vendorId
   */
  const fetchVendorDetail = (vendorId) => {
    dispatch(showLoader());
    fetchSingleVendor(vendorId)
      .then((res) => {
        const userDetail = { ...res.data.data };
        if (userDetail.vendorProfileImage && userDetail.vendorProfileImage !== "") {
          setVendorProfileImgSrc(fetchVendorProfileImageUrl(userDetail.vendorProfileImage));
          setIsDefaultImage(false);
        } else {
          setIsDefaultImage(true);
        }
        setExistingUser(userDetail);

        formik.setValues({
          // ...userDetail,
          vendorName: userDetail?.vendorName || "",
          vendorEmail: userDetail?.vendorEmail || "",
          vendorWebsite: userDetail?.vendorWebsite || "",
          vendorContactPersonFname: userDetail?.vendorContactPersonFname || "",
          vendorContactPersonLname: userDetail?.vendorContactPersonLname || "",
          vendorContactPersonPhone: userDetail?.vendorContactPersonPhone || "",
        });

        formik.setFieldValue(
          "productVariant",
          userDetail?.productVariant?.map((v) => {
            return v.productName;
          })
        );

        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Vendor Details Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch teams for vendor product variant tagging (whatNext, Insider)
   */
  const fetchTeamsForVendor = async () => {
    if (isAdmin()) {
      const res = await fetchTeam({ sort: "teamName:asc" });
      const { data = [] } = res.data;
      setTeams([...data.map((d) => ({ productName: d.teamName }))]);
    } else if (isMultipleProductVariant) {
      setTeams([...userTokenDetails.product_variant.map((p) => ({ productName: p.product_name }))]);
    }
  };

  /**
   * @description add new vendor
   * @param {Object} vendorData
   */
  const addNewVendor = (vendorData) => {
    dispatch(actionStart());
    addVendor(vendorData)
      .then((res) => {
        setUser({});

        setProductVariant(undefined);
        setVendorProfileImgSrc(undefined);
        vendorProfileImgRef.current.setAttribute("src", userImage);
        dispatch(actionSuccess("Vendor profile has been added"));
        history.push(`/vendor/${res.data.data.id}/edit#profile`);
        window.location.reload();
      })
      .catch((err) => {
        let errMsg = err?.data?.message
          ? err?.data?.message === 'payload: "vendorEmail" must be a valid email'
            ? "Please enter a valid email"
            : err?.data?.message
          : "Add new vendor failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description update existing vendor
   * @param {Object} vendorData
   */
  const updateExistingVendor = (vendorData) => {
    dispatch(actionStart());
    updateVendor(vendorId, vendorData)
      .then((res) => {
        setUser({});
        dispatch(actionSuccess("Vendor profile has been updated"));
      })
      .catch((err) => {
        let errMsg = err?.data?.message
          ? err?.data?.message === 'payload: "userEmail" must be a valid email'
            ? "Please enter valid email"
            : err?.data?.message
          : "Vendor profile updation failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description save vendor (new / existing) using form submit
   */
  const saveVendor = () => {
    const userDetail = { ...formik.values };
    if (!editUser && !isAdmin() && !isMultipleProductVariant) userDetail.productVariant = [{ productName: getProductVariant() }];
    formik.setValues({ ...userDetail });
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        const user = { ...formik.values };
        let saveVendorData = new FormData();
        for (let uKey in user) {
          if (user[uKey] && user[uKey] !== "") saveVendorData.append(uKey, user[uKey]);
        }
        saveVendorData.delete("vendorConfirmPassword");
        saveVendorData.delete("vendorProfileImage");

        if (
          (!editUser && _.isEmpty(user)) ||
          (editUser &&
            _.isEmpty(user) &&
            vendorProfileImgInputRef.current.files.length === 0 &&
            (!existingUser.vendorProfileImage ||
              (existingUser.vendorProfileImage && existingUser.vendorProfileImage !== "" && vendorProfileImgSrc !== undefined)))
        ) {
          return true;
        }
        if (user?.productVariant) {
          let productVariantTags = user?.productVariant?.map((v) => {
            return { productName: v };
          });

          saveVendorData.set("productVariant", JSON.stringify(productVariantTags));
        }

        if (vendorProfileImgInputRef.current.files.length) {
          saveVendorData.append("vendorProfileImage", vendorProfileImgInputRef.current.files[0]);
        } else {
          saveVendorData.append("vendorProfileImage", "");
        }
        if (user.vendorName === "") return dispatch(actionError("Vendor Name can not be empty!"));
        dispatch(
          showAlertBox({
            okCallback: editUser ? updateExistingVendor : addNewVendor,
            data: saveVendorData,
            okText: editUser ? "Update" : "Add",
            cancelText: "Cancel",
            content: editUser ? "Are you sure, you want to update vendor details?" : "Are you sure, you want to add vendor details?",
            title: "dialogAlertCss",
          })
        );
      }
    });
  };

  /**
   * @param {File} imageFile
   * @description read & set image for vendor profile
   */
  const readProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      vendorProfileImgRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    const breadCrumbLinks = editUser ? [...breadCrumbLinksEditVendor] : [...breadCrumbLinksAddVendor];
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/vendor"));
    editUser && fetchVendorDetail(vendorId);
    fetchTeamsForVendor();
    return () => {
      dispatch(setBreadCrumb());
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      const emailInput = document.querySelector("#vendorEmail1");
      emailInput.style.display = "none";
    }, 100);
  }, []);

  const vendorProfileImgInputRef = useRef(null);
  const vendorProfileImgRef = useRef(null);

  useEffect(() => {
    let actionAccess = accessRightActionCheck(aR.moduleName, aR.interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <div className="gennx-content-wrapper padding-bottom-150i">
      <div className="mx-2 p-2">
        <Accordion heading="Basic Details" step={1} stepCompleted={formik.values.vendorName ? true : false} activeState={true}>
          <div className="container-fluid mb-3 pl-0">
            <div className="pt-3">
              <div className="row">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-4">
                      <input type="email" id="vendorEmail1" name="vendorEmail" />
                      <TextField formik={formik} type="text" id="vendorName" name="vendorName" placeholder="Vendor Name *" />
                    </div>
                    <div className="col-md-4">
                      <TextField formik={formik} type="text" id="vendorEmail" placeholder="Email" name="vendorEmail" />
                    </div>
                    <div className="col-md-4">
                      <TextField formik={formik} type="text" id="vendorWebsite" name="vendorWebsite" placeholder="Website" />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        formik={formik}
                        type="text"
                        id="vendorContactPersonFname"
                        name="vendorContactPersonFname"
                        placeholder="Contact Person First Name"
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        formik={formik}
                        type="text"
                        id="vendorContactPersonLname"
                        name="vendorContactPersonLname"
                        placeholder="Contact Person Last Name"
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        formik={formik}
                        type="text"
                        id="vendorContactPersonPhone"
                        placeholder="Contact Person Phone"
                        name="vendorContactPersonPhone"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-1">
                      {editUser && (
                        <label className="mt-2" style={{ fontSize: "95%" }}>
                          Vendor Status
                        </label>
                      )}
                    </div>
                    <div className="col-md-2">
                      {editUser ? (
                        typeof existingUser.vendorActive !== "undefined" ? (
                          existingUser.vendorActive ? (
                            <blockquote className="active">Active</blockquote>
                          ) : (
                            <blockquote className="inactive">Inactive </blockquote>
                          )
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-2" style={{ top: "-30px", position: "relative" }}>
                  <div className="image-holder mb-1">
                    <img src={vendorProfileImgSrc || userImage} alt="User" ref={vendorProfileImgRef} width="150" height="150" />
                    <div className="image-action" style={{ right: "122px" }}>
                      {accessActionDeleteProfileImage && (
                        <span
                          onClick={() => {
                            dispatch(
                              showAlertBox({
                                content: "Are you sure, you want to delete?",
                                okText: "Delete",
                                cancelText: "Cancel",
                                title: "dialogAlertCssWarning",
                                okCallback: async () => {
                                  dispatch(actionSuccess("Image deleted successfully"));
                                  if (vendorProfileImgInputRef.current.files.length > 0) {
                                    vendorProfileImgRef.current.setAttribute("src", vendorProfileImgSrc || userImage);
                                    vendorProfileImgInputRef.current.value = "";
                                    setImageError("");
                                    setIsDefaultImage(true);
                                  } else {
                                    setVendorProfileImgSrc(undefined);
                                    setIsDefaultImage(true);
                                    setImageError("");
                                    vendorProfileImgRef.current.setAttribute("src", userImage);
                                  }
                                },
                              })
                            );
                          }}
                        >
                          <i className={`fa fa-trash mr-1 ${isDefaultImage ? "d-none" : undefined}`} aria-hidden="true"></i>
                        </span>
                      )}
                      {accessActionEditProfileImage && (
                        <span onClick={() => vendorProfileImgInputRef.current.click()}>
                          <i className="fa fa-pencil-alt " aria-hidden="true"></i>
                        </span>
                      )}
                    </div>
                  </div>

                  <input
                    type="file"
                    className="d-none"
                    accept=".jpg, .png"
                    id="vendorProfileImgSrc"
                    name="vendorProfileImgSrc"
                    ref={vendorProfileImgInputRef}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        if (
                          (e.target.files[0].type == "image/png" ||
                            e.target.files[0].type == "image/jpg" ||
                            e.target.files[0].type == "image/jpeg") &&
                          e.target.files[0].size < 512000
                        ) {
                          setImageError("");
                          setIsDefaultImage(false);

                          readProfileImage(e.target.files[0]);
                        } else {
                          setImageError("Only JPG, PNG files are allowed. Max file size should be 500 Kb.");
                        }
                      } else {
                        setImageError("");
                      }
                    }}
                  />
                  <div
                    className="d-flex align-item-center justify-content-center text-danger"
                    style={{ display: imageError ? "block" : "none" }}
                    role="alert"
                  >
                    <small>{imageError}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion>
        <Accordion
          heading="Change Password"
          step={2}
          stepCompleted={editUser || formik.values.vendorPassword ? true : false}
          forcedActiveState={formik.errors.vendorPassword || formik.errors.userConfirmPassword ? true : false}
          activeState={!editUser}
        >
          <div className="row p-3">
            <div className="col-md-4">
              <TextField
                formik={formik}
                type="password"
                id="vendorPassword"
                placeholder={`Enter New Password${editUser ? " " : " *"}`}
                name="vendorPassword"
              />
            </div>
            <div className="col-md-4">
              <TextField
                formik={formik}
                type="password"
                id="vendorConfirmPassword"
                placeholder={`Confirm New Password${editUser ? " " : " *"}`}
                name="vendorConfirmPassword"
              />
            </div>
          </div>
        </Accordion>
        <Accordion
          heading="Product Variant"
          step={3}
          stepCompleted={formik.values.productVariant && formik.values.productVariant.length ? true : false}
          forcedActiveState={formik.errors.productVariant ? true : false}
          activeState={!editUser}
        >
          <div className="row p-3">
            <div className={`${isAdmin() || isMultipleProductVariant ? "col-md-4" : "d-none"}`}>
              {teams.length > 0 && (
                <MultiSelect
                  formik={formik}
                  mode="CheckBox"
                  sortOrder="Acending"
                  showSelectAll={true}
                  // selectAllText="Select All"
                  showClearButton={false}
                  //enableRtl={true}
                  required="required"
                  placeholder="Product Variant *"
                  id="productVariant"
                  name="productVariant"
                  value={formik.values.productVariant}
                  allowCustomValue={false}
                  fields={{ text: "productName", value: "productName" }}
                  dataSource={teams}
                  cssClass="multi-without-parent e-outline customCss"
                />
              )}
            </div>
          </div>
        </Accordion>
      </div>
      <div className="form-submit-box ml-3">
        <div className="container-fluid">
          <div className="row">
            <div className="float-right col-md-12 text-right">
              <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3 " onClick={() => history.push("/vendor")}>
                Cancel
              </button>
              {accessActionSave ? (
                (existingUser.vendorActive && editUser) || !editUser ? (
                  <button type="button" onClick={saveVendor} className="btn btn-primary cy-btn pl-3 pr-3 ml-3 ">
                    {editUser ? "Update" : "Save"}
                  </button>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorProfile;
