import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import Product from "../app/views/product";
import ProductForm from "../app/views/product/component";

const OVERVIEW = ["Overview:VIEW", "Overview:FULL"];
const TECHNICAL_SPECIFICATION = ["Technical Specification:VIEW", "Technical Specification:FULL"];
const VALUE_PROPOSITION = ["Value Proposition:VIEW", "Value Proposition:FULL"];
const LICENSING = ["Licensing/Co-Development:VIEW", "Licensing/Co-Development:FULL"];
const TAXONOMY = ["Taxonomy:VIEW", "Taxonomy:FULL"];

const productRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/product"
      component={Product}
      pageHeading="GenNx CMS - Products & Technology"
      accessRights={{
        moduleName: "Products & Technology",
        parentModuleName: "",
        moduleAttribute: [...OVERVIEW, ...TECHNICAL_SPECIFICATION, ...VALUE_PROPOSITION, ...LICENSING, ...TAXONOMY],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/product/:productId/edit"
      component={ProductForm}
      pageHeading="GenNx CMS - Products & Technology"
      accessRights={{
        moduleName: "Products & Technology",
        parentModuleName: "",
        moduleAttribute: [...OVERVIEW, ...TECHNICAL_SPECIFICATION, ...VALUE_PROPOSITION, ...LICENSING, ...TAXONOMY],
        interfaceName: "Overview",
      }}
    />
  </Switch>
);

export default productRoutes;
