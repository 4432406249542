/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 26/Mar/2021
 * File Description: Survey Audience Modal Audience user upload or upload internal user
 */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { getProductVariant } from "../../../../../utilities";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { surveyAudienceExternalUserValidationSchema, surveyAudienceInternalUserValidationSchema } from "../validationSchema/index";
import {
  fetchSinglePatent,
  fetchImportTemplateFileUrl,
  uploadTemplateData,
  fetchImportErrorFile,
} from "../../../../../middleware/services/patentApi";

const initialState = {
  clientUsers: null,
  documentFile: "",
  documentFileName: "",
};
const AddPatentModal = (props) => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const [patentInfo, setPatentInfo] = useState("");

  let today = new Date();

  let date = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate();

  let time = today.getHours() + "" + today.getMinutes();

  const batchId = date + "-" + time;
  const productVariant = getProductVariant();
  let url = "";
  const formik = useFormik({
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(
      props.openAs && props.openAs === "upload" ? surveyAudienceExternalUserValidationSchema : surveyAudienceInternalUserValidationSchema
    ),
  });

  const handleUploadTemplate = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        const formData = new FormData();
        formData.append("docFile", formik.values.documentFile[0]);

        formData.append("platform", productVariant);

        const uploadresponse = uploadTemplateData(batchId, formData)
          .then((res) => {
            if (res.data.data.fileName != "") {
              formik.setErrors(res.data.data);
            } else {
              dispatch(actionSuccess("Patent uploaded successfully"));
              props.gridReload();
            }
          })
          .catch((err) => {
            dispatch(actionError(err?.data?.message || "Something went wrong"));
          });
      }
    });
  };

  const UploadComponent = (props) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      // accept: "image/*",
      accept: ".xls,.xlsx",
      maxFiles: 1, //comment this line if upload multiple File
      onDrop: (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length) {
          props.formik.setValues({ ...props.formik.values, documentFile: acceptedFiles });
        }
      },
    });

    return (
      <>
        <div className="form-group bg-light p-3 border-dotted mb-1" {...getRootProps()}>
          <input {...getInputProps()} />
          <button type="button" className="btn btn-dark">
            UPLOAD DATA TEMPLATE
          </button>
        </div>
        <small>
          Only xls, xlxs files are allowed.
          <a style={{ float: "right" }} href={fetchImportTemplateFileUrl()} download>
            {" "}
            <i className="fas fa-download" data-tip="Download" /> Download Template
          </a>
        </small>

        <div className="text-danger" style={{ display: props.formik.touched.documentFile === true ? "block" : "none" }} role="alert">
          <small>{props.formik.touched.documentFile === true ? props.formik.errors.documentFile || "" : ""}</small>
        </div>
      </>
    );
  };

  /*
            @Description : Retrieve respective news data 
        */
  useEffect(() => {
    props.show ? setShow(true) : setShow(false);
    if (props.openAs === "view" && props.show) {
      const response = fetchSinglePatent(props.patentId, {
        fields: [],
      });
      response
        .then((response) => {
          if (response.status == 200) {
            setPatentInfo(response.data.data);
          }
        })
        .catch((e) => {});
    }
  }, [props.show == true]);

  return (
    <Modal size={props.openAs === "upload" ? "" : "lg"} show={show} onHide={() => props.closeHandler()} scrollable centered>
      <Modal.Header closeButton className="bg-none text-dark">
        <Modal.Title>{props.openAs === "upload" ? "Import Patent" : "Patent Details"}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <>
          {props.openAs === "upload" && (
            <div className="row">
              <div className="col-md-12">
                <form>
                  <label className="font-weight-bold"> Batch Number: {batchId}</label>
                  <a href={url} className="error_link"></a>
                  <UploadComponent editDocumentData={props.editDocumentData} formik={formik} />
                  {/* {console.log(formik.values.documentFile, 'YYYY', props.editDocumentData)} */}
                  {formik.values.documentFile &&
                    formik.values.documentFile.map((file, i) => (
                      <li key={i}>
                        {`File:${file.name}
												`}{" "}
                      </li>
                    ))}
                </form>
              </div>
            </div>
          )}
          {props.openAs === "view" && (
            <div className="row">
              <div className="col-md-12">
                <div class="row  mr-1">
                  <label className="col-sm-4 font-weight-bold">Patent Number:</label>
                  <div className="col-sm-8">{patentInfo["patentNumber"]}</div>
                </div>
              </div>
              <div className="col-md-12">
                <div class="row  mr-1">
                  <label className="col-sm-4 font-weight-bold">Patent Title:</label>
                  <div className="col-sm-8">{patentInfo["patentTitle"]}</div>
                </div>
              </div>
              <div className="col-md-12">
                <div class="row  mr-1">
                  <label className="col-sm-4 font-weight-bold">Publication Date:</label>
                  <div className="col-sm-8">
                    {!isNaN(Date.parse(patentInfo["patentPublicationDate"])) ? getDateFromMongoDate(patentInfo["patentPublicationDate"]) : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div class="row  mr-1">
                  <label className="col-sm-4 font-weight-bold">Application Date:</label>
                  <div className="col-sm-8">
                    {!isNaN(Date.parse(patentInfo["patentApplicationDate"])) ? getDateFromMongoDate(patentInfo["patentApplicationDate"]) : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div class="row  mr-1">
                  <label className="col-sm-4 font-weight-bold">Application Number:</label>
                  <div className="col-sm-8">{patentInfo["patentApplicationNo"]}</div>
                </div>
              </div>
              <div className="col-md-12">
                <div class="row  mr-1">
                  <label className="col-sm-4 font-weight-bold">Priority Date:</label>
                  <div className="col-sm-8">
                    {!isNaN(Date.parse(patentInfo["patentPriorityDate"])) ? getDateFromMongoDate(patentInfo["patentPriorityDate"]) : ""}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div class="row  mr-1">
                  <label className="col-sm-4 font-weight-bold">Priority Number:</label>
                  <div className="col-sm-8">{patentInfo["patentPriorityNumber"]}</div>
                </div>
              </div>
              {typeof patentInfo["patentIpcClasses"] != "undefined" && (
                <>
                  <div className="col-md-12">
                    <div class="row  mr-1">
                      <label className="col-sm-4 font-weight-bold">IPC Classes:</label>
                      <div className="col-sm-8">{patentInfo["patentIpcClasses"].join(" | ")}</div>
                    </div>
                  </div>
                </>
              )}
              {typeof patentInfo["patentAssignee"] != "undefined" && (
                <>
                  <div className="col-md-12">
                    <div class="row  mr-1">
                      <label className="col-sm-4 font-weight-bold">Assignee:</label>
                      <div className="col-sm-8">
                        {patentInfo["patentAssignee"]
                          .map((company) => {
                            if (company.companyName != "") {
                              return company.companyName;
                            }
                            return "";
                          })
                          .filter((company) => company != "")
                          .join(" | ")}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {typeof patentInfo["patentInventors"] != "undefined" && (
                <div className="col-md-12">
                  <div class="row  mr-1">
                    <label className="col-sm-4 font-weight-bold">Inventors:</label>
                    <div className="col-sm-8">
                      {/*  {console.log("patentInventors", patentInfo['patentInventors'])} */}
                      {patentInfo["patentInventors"]
                        .map((people) => {
                          if (people.peopleFname != "") {
                            return people.peopleFname;
                          }
                          return "";
                        })
                        .filter((people) => people != "")
                        .join(" | ")}
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <div class="row mr-1">
                  <label className="col-sm-4 font-weight-bold">Abstract:</label>
                  <div className="col-sm-8">{patentInfo["patentAbstract"]}</div>
                </div>
              </div>
              <div className="col-md-12">
                <div class="row mr-1">
                  <label className="col-sm-4 font-weight-bold">Claims:</label>
                  <div className="col-sm-8 text-break">{patentInfo["patentClaims"]}</div>
                </div>
              </div>
              {/*   <div className="col-md-12">
                            <div class="row" >
                                <label className="col-sm-4 font-weight-bold">Description:</label>
                                <div className="col-sm-8">
                                    {patentInfo['patentPriorityNo']}
                                </div>
                            </div>
                        </div> */}
              <div className="col-md-12">
                <div class="row mr-1">
                  <label className="col-sm-4 font-weight-bold ">legal Status:</label>
                  <div className="col-sm-8 text-break">{patentInfo["legalStatus"]}</div>
                </div>
              </div>
            </div>
          )}
        </>
      </Modal.Body>
      {props.openAs === "upload" && (
        <Modal.Footer>
          <div className="col-12">
            <div className="float-right">
              {["success", "error"].includes(formik.errors.status) && formik.errors.fileName && (
                <a href={fetchImportErrorFile(formik.errors.fileName)} className="btn btn-danger  mr-2">
                  Download Report
                </a>
              )}
              <button type="button" onClick={() => props.closeHandler()} className="btn btn-outline-dark cy-btn mr-3">
                Cancel
              </button>

              <button
                type="button"
                onClick={handleUploadTemplate}
                // disabled={disabled}
                className="btn btn-primary cy-btn"
              >
                {props.openAs === "upload" ? "Upload" : "Save"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export const getDateFromMongoDate = (date) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let newDate = new Date(date);
  newDate = `${newDate.getDate()}-${months[newDate.getMonth()]}-${newDate.getFullYear()}`;
  return newDate;
};
export default AddPatentModal;

AddPatentModal.propTypes = {
  //surveyId: PropTypes.string.isRequired,
  openAs: PropTypes.string,
  // formik: PropTypes.object.isRequired,
};
