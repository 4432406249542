/*
================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Company State
----------------------------------------------------------------
    Creation Details
    Date Created				: 20/Jan/2022
    Author						: SARVESH SAMANT
================================================================
*/

import {
    SET_STATE,
    ACTIVE_PAGE,
    RECORD_VISITED,
    RECORD_RESET,
    RESET_STATE,
    SKIP_RECORDS,
    SET_PAGE,
    SORT_RECORDS,
    SET_FILTERS,
    ROUTE_CHANGED,
    ROUTE_UNCHANGED,
    INTERNAL_GRID_PRESENT,
    INTERNAL_GRID_STATE,
    INTERNAL_GRID_VISITED,
    INTERNAL_GRID_RECORD_UNVISITED,
    SET_INTERNAL_SKIP,
    RESET_INTERNAL_GRID_STATE,
    SET_INTERNAL_FILTERS,
    SET_INTERNAL_ACTIVE_PAGE,
    SET_INTERNAL_SORT,
    SET_INTERNAL_RECORDS_PER,
    SET_TAB
} from "../../constants/actionConstants";
const initialState = {
    recordVisited: false,// onclick action for a record
    filtersParams: [],// is to maintain Filters
    skipRecords: 0,// is for skip in url
    sortRecords: "", //
    recordsPerPage: 20, // maintain page,
    activePage: 1,
    checkboxIds: [],
    isRouteChanged: false,
    internalGridPresent: false,
    internalGridState: {
        tabSelected: 0, // for travelling through the tabs
        recordVisited: false,// onclick action for a record
        filtersParams: [],// is to maintain Filters
        skipRecords: 0,// is for skip in url
        sortRecords: "", //
        recordsPerPage: 20, // maintain page,
        activePage: 1,
    }
}

export default (state = initialState, action) => {
    const { payload } = action
    switch (action.type) {
        case SET_STATE: {
            return {
                ...state,
                filtersParams: payload.filter,
                skipRecords: payload.skip,
                sortRecords: payload.sort
            };
        }
        case RECORD_VISITED:
            return {
                ...state,
                recordVisited: true
            };
        case RESET_STATE:
            return {
                ...state,
                recordVisited: false,
                filtersParams: [],
                skipRecords: 0,
                activePage: 1,
                sortRecords: "",
                checkboxIds: [],
            };
        case RECORD_RESET:
            return {
                ...state,
                recordVisited: false
            };
        case SKIP_RECORDS:
            return {
                ...state,
                skipRecords: payload
            };
        case SET_PAGE:
            return {
                ...state,
                recordsPerPage: payload
            }
        case ACTIVE_PAGE:
            return {
                ...state,
                activePage: payload
            }
        case SET_FILTERS:
            return {
                ...state,
                filtersParams: payload
            }
        case SORT_RECORDS:
            return {
                ...state,
                sortRecords: payload
            }

        case ROUTE_CHANGED: return {
            ...state,
            isRouteChanged: true
        }
        case ROUTE_UNCHANGED: return {
            ...state,
            isRouteChanged: false
        }
        // Below Code is maintained for internal grids (eg product in company)
        case INTERNAL_GRID_PRESENT: return {
            ...state,
            internalGridPresent: payload
        }

        case INTERNAL_GRID_STATE:  return {
                ...state,
                internalGridState: {
                    ...state.internalGridState,
                    filtersParams: payload.filter,
                    skipRecords: payload.skip,
                    sortRecords: payload.sort
                }
            }

        case INTERNAL_GRID_VISITED:
            return {
                ...state,
                internalGridState: {
                    ...state.internalGridState,
                    recordVisited: true
                }
            }
        case INTERNAL_GRID_RECORD_UNVISITED: return {
            ...state,
            internalGridState: {
                ...state.internalGridState,
                recordVisited: false
            }

        }
        case SET_INTERNAL_SKIP: return {
            ...state,
            internalGridState: {
                ...state.internalGridState,
                skipRecords: payload
            }
        }
        case RESET_INTERNAL_GRID_STATE: return {
            ...state,
            internalGridState: {
                filtersParams: [],// is to maintain Filters
                skipRecords: 0,// is for skip in url
                sortRecords: "", //
                recordsPerPage: 20, // maintain page,
                activePage: 1,
            }
        }
        case SET_INTERNAL_FILTERS: return {
            ...state,
            internalGridState: {
                ...state.internalGridState,
                filtersParams: payload
            }
        }
        case SET_INTERNAL_ACTIVE_PAGE: return {
            ...state,
            internalGridState: {
                ...state.internalGridState,
                activePage:payload
            }
        }
        case SET_INTERNAL_SORT: return {
            ...state,
            internalGridState: {
                ...state.internalGridState,
                sortRecords:payload
            }
        }
        case SET_INTERNAL_RECORDS_PER: return {
            ...state,
            internalGridState: {
                ...state.internalGridState,
                recordsPerPage:payload
            }
        }
        case SET_TAB:
            return {
                ...state,
                internalGridState: {
                    ...state.internalGridState,
                    tabSelected:payload
                }
            }
        default:
            return state;
    }
};
