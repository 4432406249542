/*================================================================
‘Copyright © 2024, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Visualization Library
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created			: 19/July/2024
  Author						: Riddhesh Desai
================================================================ 
*/
import React, { useEffect } from "react";
import { showLoader, hideLoader, actionError } from "../../../middleware/actions/utilityAction";
import { VISUALIZATION_LIBRARY_URL } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProductVariant } from "../../../utilities";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToDataModelLibrary = (variant, userId, clientName, enterpriseToken, refreshToken) =>
    window.open(
      VISUALIZATION_LIBRARY_URL +
        "/auto-login?productVariant=" +
        variant +
        "&loginUserId=" +
        userId +
        "&clientName=" +
        clientName +
        "&token=" +
        enterpriseToken +
        "&refreshToken=" +
        refreshToken
    );

  const checkEligiblity = async () => {
    const variant = getProductVariant();
    const enterpriseToken = localStorage.getItem("enterprise");
    const userId = localStorage.getItem("enterpriseUserId");
    const refreshToken = localStorage.getItem("refresh-token");
    const clientName = import.meta.env.VITE_ENTERPRISE_CLIENT_NAME;
    if (!variant) return history.push("/selector");
    if (!userId || !enterpriseToken) return history.push("/login");
    if (enterpriseToken === "undefined" || !enterpriseToken || typeof enterpriseToken !== "string")
      return dispatch(actionError("Unable to access data model library"));
    dispatch(showLoader());
    redirectToDataModelLibrary(variant, userId, clientName, enterpriseToken, refreshToken);
    setTimeout(() => dispatch(hideLoader()), 5000);
  };

  useEffect(() => {
    checkEligiblity();
  }, []);

  return (
    <div
      className="gennx-content-wrapper content-wrapper px-3"
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", flexDirection: "column" }}
    >
      <h4>Visualization Library is now a standalone app.</h4>
      <h6>Please wait while we redirect and log you in.</h6>

      <button onClick={checkEligiblity}>Click here if you are not redirected.</button>
    </div>
  );
};
