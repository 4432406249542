/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Survey KIA Tagging Component 
	 Summary : - Import KeyImpactAreaTagging tree view from common component
	 - Save selected KIA data
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 29/June/2021
	Author						: Aditya Tijare
================================================================ 
*/
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import KeyImpactAreaTagging from "../../../../../components/treeView/keyImpactAreaTagging";
import { actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";
import { fetchSurveyKia, saveSurveyKiaTaxonomy, deleteSurveyKia } from "../../../../../../middleware/services/surveyApi";
import ActionButtons from "../../actionButtons";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const TaxomonyUI = (props) => {
  const dispatch = useDispatch();
  const surveyId = props.surveyId;
  const [saveTreeData, setSaveTreeData] = useState(false);
  const [reRender, setReRender] = useState(false);

  /* Common access */
  const accessActionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  /*
	@param selectedIndustry : Selected Industry to save their respective kia
	@param kiaIds : Selected KiaIds
	@param callback : use for re-render tree component
	
	@description : Save kia for respective industry
				1.Delete all kia for selected industry
				2.Save all kia for selected industry
	*/
  const saveKiaTagging = (selectedIndustry, kiaIds, tab, callback) => {
    if (selectedIndustry) {
      deleteSurveyKia(surveyId, { industryId: selectedIndustry })
        .then(() => {
          if (kiaIds.length > 0) {
            try {
              const response = saveSurveyKiaTaxonomy(surveyId, { taxonomyIds: kiaIds });
              response
                .then((response) => {
                  callback();
                  dispatch(actionSuccess("KIA Updated Successfully"));
                  handleSaveKiaTag();
                })
                .catch((e) => {
                  dispatch(actionError("Something Went Wrong...1!"));
                  handleSaveKiaTag();
                });
            } catch (e) {
              dispatch(actionError("Something Went Wrong...2!"));
              handleSaveKiaTag();
            }
          }
        })
        .catch((e) => {
          dispatch(actionError("Something Went Wrong...3!"));
          handleSaveKiaTag();
        });
    }
  };
  const handleSaveKiaTag = () => {
    if (saveTreeData) {
      setSaveTreeData(false);
    } else {
      setSaveTreeData(true);
    }
  };

  const deleteKia = (checkedTrends, callback) => {
    let payload = { taxonomyIds: checkedTrends };
    deleteSurveyKia(surveyId, payload)
      .then(() => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };
  return (
    <>
      <KeyImpactAreaTagging
        selectedtree={true}
        contentId={surveyId}
        fetchKiaTagging={fetchSurveyKia}
        contentType="survey"
        saveTreeData={saveTreeData}
        // handleSave={props.handleSave}
        reRender={reRender}
        // kiaRender={kiaRender}
        isDelete={true}
        setSaveTreeData={setSaveTreeData}
        deleteKia={deleteKia}
        selectedTab={1}
        handleSaveKIA={saveKiaTagging}
        accessPermissionTaxonomy={accessActionAdd}
        accessPermissionTaxonomyDelete={accessActionDelete}
      />

      <ActionButtons
        accessRights={props.accessRights}
        selectedIndex={props.selectedIndex}
        setCurrentTab={(selectedIndex) => {
          selectedIndex && props.setCurrentTab(selectedIndex);
        }}
        surveyId={surveyId ? surveyId : null}
        handleSave={handleSaveKiaTag}
        tabsName={props.tabsName}
        moduleName={props.moduleName}
        hashArr={props.hashArr}
      />
    </>
  );
};

export default TaxomonyUI;
