/**
 * @author: Pankaj
 * @date : 31-Aug-2020
 * File Description: Customize Multi Stepper
 */
import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { fetchImportErrorFileUrl } from "../../../middleware/services/companyApi";

const MultiStepper = (props) => {
  const [isActive, setIsActive] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    props.show ? setShow(true) : setShow(false);
  }, [props.show, isActive, props.formik.values.screeningData]);
  return (
    <>
      {" "}
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          props.closeHandler();
        }}
        scrollable
        centered
      >
        <Modal.Header closeButton className="bg-secondary text-white">
          <Modal.Title>Upload Data</Modal.Title>
        </Modal.Header>
        <div className="mt-2 ml-4 mr-4 ">
          <div className="stepwizard">
            <div className="container">
              <div className="wiz-steps">
                <ul className="steps">
                  {props.steps.map((item, i) => (
                    <Fragment key={"st" + i}>
                      <NavLink
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          if (!item.linkDisabled) {
                            setIsActive(item.step);
                          }
                        }}
                        className={` ${
                          item.linkDisabled ? " cursor-no-drop " : " "
                        }`}
                        style={{ background: "none" }}
                      >
                        <li
                          key={"st" + item.id}
                          className={`${
                            isActive === item.step
                              ? "active"
                              : !isActive && item.step === 1
                              ? "active"
                              : ""
                          } st-one`}
                        >
                          <span className="count">{item.step}</span>
                          <span className="text">{item.name}</span>
                        </li>
                      </NavLink>
                    </Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="col-6">
                            {Object.keys(industry).length > 0 ? <DropdownList
                                className=" custom-dropdownlist "
                                data={[...Object.keys(industry)]}
                                textField="trendName"
                                valueField="id"
                                value={selectedIndustry || Object.keys(industry)[0]}
                                onChange={(value) => { setSelectedIndustry(value) }}
                            /> : null}
                        </div> */}
        </div>
        <Modal.Body>
          <div className="row ">
            <div className="col-md-12 p-3">
              {isActive
                ? props.steps[isActive - 1].component
                : props.steps[0].component}
              {/* <Component customProps="customProps check test"/> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12">
            <div className="col-3 float-left">
              {(isActive === 3 || isActive === 2) && (
                <NavLink
                  to="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsActive(isActive - 1);
                  }}
                  className="btn btn-primary cy-btn"
                >
                  Previous
                </NavLink>
              )}
            </div>
            <div className="float-right">
              {["success", "error"].includes(props.formik.errors.status) &&
                props.formik.errors.fileName && (
                  <a
                    href={fetchImportErrorFileUrl(props.formik.errors.fileName)}
                    className="btn btn-primary  mr-3"
                    style={{ height: "40px" }}
                  >
                    Download Report
                  </a>
                )}
              <button
                type="button"
                onClick={props.closeHandler}
                className="btn btn-outline-dark cy-btn mr-3"
              >
                Cancel
              </button>
              {(!isActive || isActive === 1 || isActive === 2) && (
                <NavLink
                  to="/"
                  onClick={async (e) => {
                    e.preventDefault(); //setIsActive(isActive + 1);
                    if (!isActive || isActive === 1) {
                      props.steps[0].handleSubmitDocument();
                      if (props.formik.values.documentFile[0]) {
                        if (
                          (props.formik.values.uploadFor === "Trend" &&
                            props.formik.values.selIndustry !== "") ||
                          props.formik.values.uploadFor !== "Trend"
                        ) {
                          setIsActive(2);
                        }
                      }
                    } else {
                      const res = await props.steps[1].handleSubmit();
                      if (res) {
                        setIsActive(isActive + 1);
                      }
                    }
                  }}
                  className="btn btn-primary cy-btn"
                >
                  Next
                </NavLink>
              )}
              {isActive === 3 && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    props.steps[2].handleFinalSubmit();
                  }}
                  // disabled={disabled}
                  className="btn btn-primary cy-btn"
                >
                  Finish
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default MultiStepper;

MultiStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  formik: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
};
