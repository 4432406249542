/**
 * @fileoverview This file contains common loader component.
 * @date 22/Nov/2023
 * @author Lawrence Anthony 
 * Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
 */

import React from 'react';
import GridLoader from 'react-spinners/GridLoader';

export default function Loader() {
  return (
    <div className="d-flex h-100 min-height-400 w-100 min-width-200 flex-column align-items-center justify-content-center align-self-center bg-transparent p-4">
      <GridLoaderComponent size={15} />
    </div>
  );
}

export const GridLoaderComponent = (props) => (
  <div className={`min-height-200 h-100 w-100 d-flex flex-grow justify-content-center align-items-center ${props.className}`}>
    <GridLoader size={props.size || 15} margin={2} color={'#f4505f'} loading={true} />
  </div>
);
