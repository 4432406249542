/**
 * @author: Anand Kumar
 * @date : 03-Aug-2020
 * File Description:All company component integrations Basic, Advanced, Taxonomy and and Product
 */

import React, { useEffect, useState, useRef } from "react";
import BasicUI from "../../company/component/basic";
import AdvancedUI from "../../company/component/advanced";
import CollaborationUI from "../../company/component/collaboration";
import Tabs from "../../../components/tabsComponent";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import TaxonomyUI from "../../company/component/tagging";
import Product from "../../product";
import { fetchSingleCompany } from "../../../../middleware/services/companyApi";
import { accessRightActionCheck, accessRightInterfaceCheck } from "../../../../utilities";
import AccessDenied from "../../../components/accessDenied";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { setClickSaveSubmitState, setOverviewUpdateStatus } from "../../../../middleware/actions/companyAction";

const CompanyUI = (props) => {
  const { accessRights: aR } = props;
  const companyId = props.computedMatch?.params?.companyId || "";
  const [companyName, setCompanyName] = useState("");
  const [basicFetched, setBasicFetched] = useState(false);
  const [advancedFetched, setAdvancedFetched] = useState(false);
  const tabRef = useRef();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  const TABS_NAME = ["Basic", "Advanced", "Products & Technologies", "Collaboration", "Taxonomy"];

  const getCompanyName = async () => {
    let fields = { fields: ["id", "companyName"] };
    const response = await fetchSingleCompany(companyId, { ...fields });
    setCompanyName(response.data.data.companyName);
  };
  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (companyId !== "") {
      if (selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.enableTab(4, false);
      tabRef.current.select(0);
    }
  };
  useEffect(() => {
    if (companyId) {
      getCompanyName();
    }
  }, [companyId]);
  const hashArr = {
    "#basic": "Basic",
    "#advanced": "Advanced",
    "#product": "Product & Technologies",
    "#collaboration": "Collaboration",
    "#taxonomy": "Taxonomy",
  };
  const breadCrumbLinks = [
    { linkUrl: "/processing/company", linkName: "Processing", linkActive: false },
    {
      linkUrl: companyId ? `/processing/company/${companyId}/edit#basic` : "/processing/company/add#basic",
      linkName: `${companyName ? companyName : "Add New"}`,
      linkActive: false,
    },
    {
      linkUrl: `/processing/company/${companyId}/edit${Object.keys(hashArr)[selectedIndex]}`,
      linkName: Object.values(hashArr)[selectedIndex],
      linkActive: true,
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/processing/company"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, selectedIndex, companyName, props.location.hash]);

  /* Interface access  */
  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, TABS_NAME);

  /* Interface functionality wise access  */
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex]);

  const renderComponent = () => {
    if (companyId) {
      // set Redux state clickStatus, updateStatus for company to false
      dispatch(setClickSaveSubmitState());
      dispatch(setOverviewUpdateStatus());

      return (
        <>
          {interfaceAccess ? (
            <div key="companyOverview">
              <BasicUI
                companyId={companyId}
                {...props}
                selectedIndex={selectedIndex}
                basicFetched={basicFetched}
                setBasicFetched={setBasicFetched}
                setCurrentTab={(selectedIndex) => {
                  tabRef.current.select(selectedIndex);
                  setSelectedIndex(selectedIndex);
                }}
                hashArr={hashArr}
                interfaceActionAccess={interfaceActionAccess}
              />
            </div>
          ) : (
            <AccessDenied />
          )}
          {interfaceAccess ? (
            <div key="companyAdvanced">
              {selectedIndex === 1 && (
                <AdvancedUI
                  companyId={companyId}
                  advancedFetched={advancedFetched}
                  setAdvancedFetched={setAdvancedFetched}
                  {...props}
                  selectedIndex={selectedIndex}
                  setCurrentTab={(selectedIndex) => {
                    tabRef.current.select(selectedIndex);
                    setSelectedIndex(selectedIndex);
                  }}
                  hashArr={hashArr}
                  interfaceActionAccess={interfaceActionAccess}
                />
              )}
            </div>
          ) : (
            <AccessDenied />
          )}
          {interfaceAccess ? (
            <div key="companyProducts">
              {selectedIndex === 2 && (
                <Product
                  companyId={companyId}
                  {...props}
                  selectedIndex={selectedIndex}
                  setCurrentTab={(selectedIndex) => {
                    tabRef.current.select(selectedIndex);
                    setSelectedIndex(selectedIndex);
                  }}
                  interfaceActionAccessProduct={interfaceActionAccess}
                />
              )}
            </div>
          ) : (
            <AccessDenied />
          )}
          {interfaceAccess ? (
            <div key="companyCollaboration">
              {selectedIndex === 3 && (
                <CollaborationUI
                  companyId={companyId}
                  {...props}
                  selectedIndex={selectedIndex}
                  setCurrentTab={(selectedIndex) => {
                    tabRef.current.select(selectedIndex);
                    setSelectedIndex(selectedIndex);
                  }}
                  interfaceActionAccessCollaboration={interfaceActionAccess}
                  hashArr={hashArr}
                />
              )}
            </div>
          ) : (
            <AccessDenied />
          )}
          {interfaceAccess ? (
            <div key="companyTaxonomy">
              {selectedIndex === 4 && (
                <TaxonomyUI
                  companyId={companyId}
                  {...props}
                  selectedIndex={selectedIndex}
                  setCurrentTab={(selectedIndex) => {
                    tabRef.current.select(selectedIndex);
                    setSelectedIndex(selectedIndex);
                  }}
                  hashArr={hashArr}
                  interfaceActionAccess={interfaceActionAccess}
                />
              )}
            </div>
          ) : (
            <AccessDenied />
          )}
        </>
      );
    } else {
      return (
        <>
          {interfaceAccess ? (
            <div key="companyOverview">
              <BasicUI
                companyId={companyId}
                selectedIndex={selectedIndex}
                setCurrentTab={(selectedIndex) => {
                  tabRef.current.select(selectedIndex);
                  setSelectedIndex(selectedIndex);
                }}
                hashArr={hashArr}
                interfaceActionAccess={interfaceActionAccess}
              />
            </div>
          ) : (
            <AccessDenied />
          )}
        </>
      );
    }
  };

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
      <Tabs
        tabsName={TABS_NAME}
        tabRef={tabRef}
        created={created}
        handleClick={() => {
          setSelectedIndex(tabRef.current.selectedItem);
        }}
        cssClass="newHeaderTabCss"
      >
        {renderComponent()}
      </Tabs>
    </div>
  );
};

export default CompanyUI;
