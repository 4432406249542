import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { swap, getLoggedInUser, accessRightActionCheck, getProductVariant, fetchTechTrendData } from "../../../../utilities";
import CommonDataGrid from "../../../components/dataGrid";
import { StringFilter, DropDownFilter } from "../../../components/dataGrid/fliters";
import { fetchCompany } from "../../../../middleware/services/companyApi";
import { allocateCompanyManual, deAllocateCompanyManual } from "../../../../middleware/services/cmsApi";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { actionStart, actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import AllocationModal from "./allocationModal";
import { COMMON_ACCESS_ACTION, QUEUE_STATUS } from "../../../../constants";

// company dashboard component
const CompanyDataProcessing = (props) => {
  const { accessRights: aR } = props;
  // specify breadcrumb here
  const breadCrumbLinks = [{ linkUrl: "/allocation", linkName: aR.moduleName, linkActive: true }];
  const productVariant = getProductVariant();
  const allocationBtnRef = useRef(null);
  const loggedInUser = getLoggedInUser();
  const dispatch = useDispatch();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const [modalForceClose, setModalForceClose] = useState(false);
  const [updateId, setUpdateId] = useState(undefined);
  const [techTrendData, setTechTrendData] = useState(undefined);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);
  /* Common Access permissions */
  const accessActionAllocation = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ALLOCATION);
  const accessActionDeallocation = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DEALLOCATION);
  const TAB_NAMES = ["Listing"];
  // specify column names for column fields in datGrid here
  const columnNames = {
    id: "id",
    Name: "companyName",
    Website: "companyWebsite",
    // Technology: "companyTechnologyTags~dtParentId:0,dtName",
    "Country (HQ)": "companyLocation~type:Headquarter,countryName",
    // "Is Live": "companyIsLive",
    "Queue Status": "companyInQueue.queueStatus",
    "Allocated User": "companyInQueue.allocatedUserName",
    "Allocated Vendor": "companyInQueue.allocateVendorName",

    // "Employee Size": "companyEmpSize",
    // "Startup": "companyIsStartup",
  };
  if (productVariant) columnNames["Is Live"] = `productVariant~productName:${productVariant},isLive`;
  else columnNames["Is Live"] = "productVariant.isLive";

  if (productVariant && productVariant === "Insider") columnNames["Trend"] = "companyInQueue.trendId";
  else columnNames["Technology"] = "companyInQueue.dtId";

  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean

  const companyWebsiteTemplate = (value) => {
    return (
      <a
        href={value.Website ? (value?.Website.startsWith("http") ? value?.Website : "http://" + value?.Website) : null}
        target="_blank"
        data-tip="Open Website"
      >
        {value.Website}
        {value.Website ? <i className="fas fa-external-link-alt pl-2"></i> : null}
      </a>
    );
  };

  // Dropdown Filter template
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Startup":
        sourceData = [
          { id: "-", text: "Blanks" },
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Is Live":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Queue Status":
        sourceData = Object.keys(QUEUE_STATUS)
          .map((key) => {
            if (!["SCREENING_FRESH", "SCREENING_REJECTED", "SCREENING_HOLD"].includes(key)) {
              return { id: QUEUE_STATUS[key], text: QUEUE_STATUS[key] };
            }
          })
          .filter((item) => {
            return item !== undefined;
          });
        break;
      case "Employee Size":
        sourceData = [
          { id: "-", text: "Blanks" },
          { id: "1 - 10", text: "1 to 10" },
          { id: "11 - 50", text: "11 to 50" },
          { id: "51 - 100", text: "51 to 100" },
          { id: "101 - 500", text: "101 to 500" },
          { id: "501 - 1000", text: "501 to 1000" },
          { id: "1001 - 5000", text: "1001 to 5000" },
          { id: "5001 - 10000", text: "5001 to 10000" },
          { id: "10000+", text: "10000+" },
        ];
        break;
      case "Technology":
        sourceData = techTrendData;
        break;
      case "Trend":
        sourceData = techTrendData;
        break;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  const columnFields = [
    // { field: "Name", type: "String", template: companyNameTemplate, textAlign: "Left", headerTextAlign: "Left" },
    { field: "id", type: "string", visible: false, allowFiltering: false, showInColumnChooser: false, isPrimaryKey: true },
    { field: "Name", type: "String", filterTemplate: StringFilter, textAlign: "Left", showInColumnChooser: false },
    { field: "Website", type: "String", template: companyWebsiteTemplate, filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left" },
    {
      field: `${productVariant && productVariant === "Insider" ? "Trend" : "Technology"}`,
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "contains" },
      textAlign: "Left",
      headerTextAlign: "Left",
    },
    { field: "Country (HQ)", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 170 },
    { field: "Is Live", type: "String", filterTemplate: ItemFilter, textAlign: "Center", headerTextAlign: "Center", width: 120 },
    {
      field: "Queue Status",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 150,
    },
    { field: "Allocated User", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 170 },
    { field: "Allocated Vendor", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 170 },

    // { field: "Employee Size", type: "String", textAlign: "Center", headerTextAlign: "Center", width: 150 },
    // { field: "Startup", type: "String", textAlign: "Center", headerTextAlign: "Center", width: 100 },
    // { field: "Action", type: "String", template: actionTemplate, allowFiltering: false, textAlign: "Center", headerTextAlign: "Center", width: 100 }
  ];

  if (TAB_NAMES.includes("Listing")) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false, textAlign: "Center" });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
      });
      if (productVariant && productVariant === "Insider") {
        if (rD.companyInQueue.trendId && rD.companyInQueue.trendId.length) {
          const ids = rD.companyInQueue.trendId;

          const trendData = techTrendData.filter((item) => {
            return ids.includes(item.id);
          });
          newRD.Trend = trendData
            .map((item) => {
              return item.text;
            })
            .filter((trend, index, self) => trend != "" && self.indexOf(trend) === index)
            .join(", ");
        } else newRD.Trend = "";
      } else {
        if (rD.companyInQueue.dtId && rD.companyInQueue.dtId.length) {
          const ids = rD.companyInQueue.dtId;

          const techData = techTrendData.filter((item) => {
            return ids.includes(item.id);
          });
          newRD.Technology = techData
            .map((item) => {
              return item.text;
            })
            .filter((tech, index, self) => tech != "" && self.indexOf(tech) === index)
            .join(", ");
        } else newRD.Technology = "";
      }
      if (rD.companyLocation && rD.companyLocation.length) {
        const hq = rD.companyLocation.filter((nD) => nD.type === "Headquarter");
        newRD["Country (HQ)"] = hq.length ? hq[0].countryName : "";
      } else {
        newRD["Country (HQ)"] = "";
      }
      if (rD.companyInQueue && rD.companyInQueue.queueStatus) {
        newRD["Queue Status"] = QUEUE_STATUS[rD.companyInQueue.queueStatus];
      } else {
        newRD["Queue Status"] = "";
      }
      newRD["Allocated User"] = rD.companyInQueue.allocatedUserName ? rD.companyInQueue.allocatedUserName : "";
      newRD["Allocated Vendor"] = rD.companyInQueue.allocateVendorName ? rD.companyInQueue.allocateVendorName : "";
      // newRD["Is Live"] = (!rD.companyIsLive) ? 'NO' : rD.companyIsLive;
      if (rD.productVariant?.length) {
        if (productVariant) {
          const rDPV = rD.productVariant.find((pV) => pV.productName === productVariant);
          newRD["Is Live"] = rDPV?.isLive ? rDPV.isLive : "NO";
        } else newRD["Is Live"] = rD.productVariant[0].isLive ? rD.productVariant[0].isLive : "NO";
      } else newRD["Is Live"] = "NO";
      return newRD;
    });
    return formatedRowData;
  };

  const saveManualAllocation = (userId, allocationType, selectedCompanies) => {
    dispatch(actionStart());
    allocateCompanyManual(userId, {
      companyIds: selectedCompanies,
      allocationQueue: allocationType,
      productVariant: productVariant,
    })
      .then((res) => {
        dispatch(actionSuccess("Companies allocated successfully"));
        if (modalForceClose) setModalForceClose(false);
        setModalForceClose(true);
        setUpdateId(selectedCompanies[0]);
      })
      .catch((err) => {
        let errMsg = err.data.message ? err.data.message : "Companies allocation failed";
        dispatch(actionError(errMsg));
      });
  };

  const fetchCompanyForAllocation = (params = {}) => {
    let isExist = [];
    let defaultFilter = [];
    let { filters = [] } = params;
    if (filters) {
      filters = filters.map((item) => {
        const columnName = swap(QUEUE_STATUS);
        const tech = techTrendData.filter((f) => {
          return ["companyInQueue.trendId", "companyInQueue.dtId"].includes(item[0]) && f.text === item[2];
        });
        if (productVariant === "Insider") {
          return item[0] === "companyInQueue.queueStatus"
            ? [item[0], item[1], columnName[`${item[2]}`]]
            : item[0] === "companyInQueue.trendId"
            ? [item[0], "in", [tech[0].id]]
            : item;
        } else {
          return item[0] === "companyInQueue.queueStatus"
            ? [item[0], item[1], columnName[`${item[2]}`]]
            : item[0] === "companyInQueue.dtId"
            ? [item[0], "in", [tech[0].id]]
            : item;
        }
      });

      isExist = filters.filter((item) => {
        return item[0] === "companyInQueue.queueStatus";
      });
    }
    if (isExist.length == 0) {
      defaultFilter.push([
        "companyInQueue.queueStatus",
        "in",
        ["ALLOCATION", "PROCESSING_FRESH", "PROCESSING_REWORK", "QC_ALLOCATION", "QC_FRESH", "QC_REJECTED", "QC_ACCEPTED", "PUBLISHED"],
      ]);
    }

    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...filters, ...defaultFilter];
    localStorage.removeItem("selectedCompanies");
    return fetchCompany({ ...params });
  };

  const getMultiSelectedRows = (data) => {
    localStorage.setItem("selectedCompanies", JSON.stringify([...data]));
  };

  const getMultiDeSelectedRows = (data) => {
    localStorage.setItem("selectedCompanies", JSON.stringify([...data]));
  };

  const modalStateReset = () => {
    // setModalInitialfetch(false);
    // setModalForceClose(false);
  };

  const allocationBtnCallBack = () => {
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    if (selectedCompanies.length) {
      allocationBtnRef.current.click();
    } else {
      dispatch(actionError("Select atleast one Company"));
    }
  };

  const handleDeallocation = () => {
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    if (selectedCompanies.length) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            dispatch(actionStart());
            deAllocateCompanyManual(loggedInUser._id, { companyIds: selectedCompanies })
              .then((res) => {
                dispatch(actionSuccess("Companies de-allocated successfully"));
                setUpdateId(new Date());
              })
              .catch((err) => {
                let errMsg = err.data.message ? err.data.message : "Companies de-allocation failed";
                dispatch(actionError(errMsg));
              });
          },
          okText: "De-Allocate",
          cancelText: "Cancel",
          content: "Are you sure you want to De-Allocate ?",
          title: "dialogAlertCssWarning",
        })
      );
    } else {
      dispatch(actionError("Select atleast one Company"));
    }
  };

  const allocationCallBack = (data) => {
    const selectedCompanies = localStorage.getItem("selectedCompanies") ? JSON.parse(localStorage.getItem("selectedCompanies")) : [];
    saveManualAllocation(data.userId, data.allocationType, selectedCompanies);
  };
  const fetchTechData = async () => {
    setTechTrendData(await fetchTechTrendData(productVariant));
  };

  useEffect(() => {
    localStorage.removeItem("selectedCompanies");
    if (!techTrendData) fetchTechData();
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/allocation/company"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
      localStorage.removeItem("selectedCompanies");
    };
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      {accessActionAllocation ? (
        <div>
          <AllocationModal
            allocationModalId="id_103"
            initialFetch={true}
            modalForceClose={modalForceClose}
            callbackFunc={allocationCallBack}
            modalStateReset={modalStateReset}
          />
        </div>
      ) : null}
      <div className="gennx-grid-container">
        {techTrendData && (
          <CommonDataGrid
            gridTitle="Company Data Allocation Records"
            fetch={fetchCompanyForAllocation}
            deleteOne={false}
            deleteMany={false}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            getMultiSelectedRows={getMultiSelectedRows}
            getMultiDeSelectedRows={getMultiDeSelectedRows}
            deleteId={updateId}
            allocateRight={accessActionAllocation}
            companyAllocate={allocationBtnCallBack}
            deallocateRight={accessActionDeallocation}
            companyDeallocate={handleDeallocation}
            clearFilterByTaxonomy={true}
          />
        )}
        <input
          type="hidden"
          value={setTimeout(() => {
            localStorage.removeItem("selectedCompanies");
          }, 200)}
        />
      </div>
      <div className="d-none">
        <button type="button" className="btn btn-secondary btn-sm pl-3 pr-3" ref={allocationBtnRef} data-toggle="modal" data-target="#id_103">
          Allocation
        </button>
      </div>
    </div>
  );
};

export default CompanyDataProcessing;
