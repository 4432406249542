/**
 * @author: Pankaj Kulshreshtha
 * @date : 17/Mar/2021
 * File Description: Survey module validation schema
 */
import * as Yup from "yup";
const shortUrlPattern = /^[a-z0-9_-]*$/;
export const surveyBasicValidationSchema = {
  // pVariant: Yup.string().required("Product variant should not empty"),
  createSurveyFrom: Yup.object().required("Please choose an option"),
  existingTemplate: Yup.string().when("createSurveyFrom", {
    is: (obj) => {
      return obj === undefined || (obj && obj.value === "existing template");
    },
    then: Yup.string().required("Please select survey template").nullable(),
    // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
  }),
  surveyType: Yup.object().required("Please select survey type"),
  surveyFrequency: Yup.string().when(["surveyType", "pVariant"], {
    is: (surveyType, pVariant) => {
      return surveyType === undefined || (surveyType && surveyType.value === "Ongoing" && pVariant && pVariant === "WhatNext");
    },
    then: Yup.string().typeError("Please select survey frequency").required("Please select survey frequency"),
    otherwise: Yup.string().nullable(),
  }),

  surveyYear: Yup.string().when(["surveyType", "pVariant"], {
    is: (surveyType, pVariant) => {
      return surveyType === undefined || (surveyType && surveyType.value === "Ongoing" && pVariant && pVariant === "WhatNext");
    },
    then: Yup.string().typeError("Please select survey year").required("Please select survey year").nullable(),
  }),
  surveyQuarter: Yup.string().when(["surveyType", "pVariant"], {
    is: (surveyType, pVariant) => {
      return surveyType === undefined || (surveyType && surveyType.value === "Ongoing" && pVariant && pVariant === "WhatNext");
    },
    then: Yup.string().typeError("Please select survey quarter").required("Please select survey quarter").nullable(),
  }),
  surveyTitle: Yup.string()
    .trim("Please enter survey title")
    .required("Please enter survey title")
    .max(150, "Survey title must be at most 150 characters"),
  surveyShortName: Yup.string()
    .matches(shortUrlPattern, "Only characters in lowercase, numbers and special characters '-','_' are allowed")
    .required("Please enter survey short name")
    .max(30, "Short name must be at most 30 characters"),
  surveyStartDate: Yup.date().typeError("Please select survey start date").required("Please enter survey start date"),
  surveyEndDate: Yup.date().when("surveyType", {
    is: (obj) => {
      return (obj && obj.value === "One Time") || (obj && obj.value === "NPS Survey");
    },
    then: Yup.date()
      .required("Please select survey end date")
      .typeError("Please select survey end date")
      .min(Yup.ref("surveyStartDate"), "Survey end date shouldn't be before the start date"),
    otherwise: Yup.date().nullable(),
  }),
  surveyAudience: Yup.string().required("Please select survey target audience"),
  surveyReleaseArtifact: Yup.string().when("surveyType", {
    is: (obj) => {
      return obj && obj.value === "NPS Survey";
    },
    then: Yup.string().required("Please enter release artifact id (multiple with , separated)"),
  }),
  teamName: Yup.string().when("surveyType", {
    is: (obj) => {
      return obj && obj.value === "NPS Survey";
    },
    then: Yup.string().required("Please select team name").typeError("Please select team nam"),
    otherwise: Yup.string().nullable(),
  }),
  npsSurveyType: Yup.string().when("surveyType", {
    is: (obj) => {
      return obj && obj.value === "NPS Survey";
    },
    then: Yup.string().required("Please select nps survey type").typeError("Please select nps survey type"),
    otherwise: Yup.string().nullable(),
  }),
  // surveyReleaseArtifact: Yup.number().required("Please enter release artifact number").typeError("Please enter numeric value"),
  //     otherwise: Yup.number().nullable(),

  // summary: Yup.string().trim("Please enter survey summary").required("Please enter survey summary").max(150, "Survey summary must be at most 500 characters"),
  // surveyConfirmationMsg: Yup.string().trim("Please enter survey summary").required("Please enter survey summary").max(150, "Survey summary must be at most 500 characters"),
};

export const surveyAudienceExternalUserValidationSchema = {
  documentFile: Yup.mixed().required("Please upload data template"),
};
export const surveyAudienceInternalUserValidationSchema = {
  clientUsers: Yup.string().required("Please select client user").typeError("Please select client user"),
};
// validation schema for Survey Invitation

export const surveyInvitationValidationSchema = {
  senderMail: Yup.string()
    .matches(/.+@(futurebridge|cognition-solutions|cheersin|whatnextglobal)\.com$/, "Please enter valid email.")
    .required("Please enter sender's email."),
  senderName: Yup.string()
    .matches(/^[a-zA-Z\s-]+$/i, "Only alphanumeric characters are allowed")
    .required("Please enter sender's name"),
  mailSubject: Yup.string().required("Please enter email subject."),
  invitationMailBody: Yup.string().required("Please enter email content."),
  email_cc2: Yup.array()
    .transform(function (value) {
      let emailArray = [];
      if (value) {
        emailArray = value.map((email) => {
          return email.value;
        });
      }

      return emailArray;
    })
    .of(Yup.string().email(({ value }) => ` ${value} is not a valid email `)),

  email_bcc2: Yup.array()
    .transform(function (value) {
      let emailArray = [];
      if (value) {
        emailArray = value.map((email) => {
          return email.value;
        });
      }

      return emailArray;
    })
    .of(Yup.string().email(({ value }) => ` ${value} is not a valid email `)),

  email_reply: Yup.string().email("Please enter valid email"),
};

// validation schema for Survey Acknowledgement()
export const surveyAcknowledgementValidationSchema = {
  senderMail: Yup.string()
    .matches(/.+@(futurebridge|cognition-solutions|cheersin|whatnextglobal)\.com$/, "Please enter valid email.")
    .required("Please enter sender's email."),
  senderName: Yup.string()
    .matches(/^[a-zA-Z\s-]+$/i, "Only alphanumeric characters are allowed")
    .required("Please enter sender's name"),
  mailSubject: Yup.string().required("Please enter email subject."),
  mailBody: Yup.string().required("Please enter email content."),
  email_cc2: Yup.array()
    .transform(function (value) {
      let emailArray = [];
      if (value) {
        emailArray = value.map((email) => {
          return email.value;
        });
      }

      return emailArray;
    })
    .of(Yup.string().email(({ value }) => ` ${value} is not a valid email `)),

  email_bcc2: Yup.array()
    .transform(function (value) {
      let emailArray = [];
      if (value) {
        emailArray = value.map((email) => {
          return email.value;
        });
      }

      return emailArray;
    })
    .of(Yup.string().email(({ value }) => ` ${value} is not a valid email `)),

  email_reply: Yup.string().email("Please enter valid email"),
};
