/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Move briefcase modal
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  addNewBriefcaseFolder,
  updateBriefcaseMove,
  getCategoryBriefcase,
  getAllBriefcaseCategoryList,
} from "../../../middleware/services/myBriefcase";
import ModalDropdownInput from "../ModalDropdownInput/ModalDropdownInput";
import ModalCancelSaveButtons from "../ModalCancelSaveButtons/ModalCancelSaveButtons";
import ModalBriefcaseNewFolder from "../ModalBriefCaseNewFolder/ModalBriefcaseNewFolder";
import BriefPopUp from "../briefPopup";
import classes from "./moveBriefcase.module.css";
import { getAllBriefcaseCategory, updateBriefcaseCategoryId, updateNewFolderId } from "../../../middleware/actions/myBriefcaseAction";
import { getLoggedInUser } from "../../../utilities";
import { actionError, actionSuccess, hideLoader, showLoader } from "../../../middleware/actions/utilityAction";

const MoveToBriefcase = ({
  showBriefcaseModal,
  hideBriefcaseModal,
  setShow,
  selectedBriefcase,
  briefcaseCategoryId,
  setSelectedDoc,
  getCategoryAllBriefcase,
  getBriefcaseFolderList,
}) => {
  const dispatch = useDispatch();

  const [folderId, setFolderId] = useState("");
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [folderExistError, setFolderExistError] = useState(false);
  const [showConfirmMoveBriefcase, setShowConfirmMoveBriefcase] = useState(false);
  const [existingFolders, setExistingFolders] = useState([]);
  const productVariant = localStorage.getItem("productVariant");
  const user = getLoggedInUser();

  let allBriefcaseCategoryListing = useSelector((state) => state["myBriefcaseState"]["allBriefcaseCategoryListing"]);
  const newFolderId = useSelector((state) => state["myBriefcaseState"]["newFolderId"]);

  // show new folder modal
  const handleShowNewFolderModal = () => {
    setShowNewFolderModal(true);
    setShowError(false);
  };

  // close new folder modal
  const handleCloseNewFolderModal = () => {
    setFolderExistError(false);
    setShowNewFolderModal(false);
    setShowError(false);
    setFolderId("");
    setFolderName("");
  };

  // close add to briefcase modal
  const handleCloseBriefcaseModal = () => {
    setShowError(false);
    setFolderId("");
    hideBriefcaseModal();
    dispatch(updateNewFolderId(""));
  };

  // check briefcase folder exist or not
  const checkFolderExist = (obj) => obj.toLowerCase() === folderName.trim().toLowerCase();

  // create new briefcase folder
  const createFolder = () => {
    const regex = /^[!@#$%^&*()_-]+$/;
    if (Object.getOwnPropertyNames(user).length !== 0 && folderName.trim() !== "" && !/^\s/.test(folderName) && !regex.test(folderName)) {
      if (existingFolders.some(checkFolderExist)) {
        setFolderExistError(true);
      } else {
        setShowError(false);
        setFolderExistError(false);
        addNewBriefcaseFolder(user._id, folderName.trim(), productVariant)
          .then((res) => {
            dispatch(updateNewFolderId(res.data.id));
            getBriefcaseFolderList();
            getAllCategory();
            dispatch(updateBriefcaseCategoryId(res.data.id));
          })
          .catch((error) => {
            if (error.response?.status === 409) {
              dispatch(actionError(`Folder is already exist with name ${folderName}`));
            } else {
              console.log("error >>>", error);
            }
          });
        setFolderName("");
        handleCloseNewFolderModal();
        setShow(false);
      }
    } else {
      setShowError(true);
    }
  };

  const getAllCategory = () => {
    getAllBriefcaseCategoryList(user?._id, productVariant)
      .then((res) => {
        dispatch(
          getAllBriefcaseCategory({
            res,
            totalCount: res.totalCount,
          })
        );
      })
      .catch((error) => {
        console.log("error  category listing >>>", error);
      });
  };

  // submit "add briefcase" request
  const onSaveClick = async () => {
    if (user?._id && folderId) {
      if (selectedBriefcase && selectedBriefcase?.length > 0) {
        checkMoveDuplicateBriefcase(folderId);
      }
    } else {
      setShowError(true);
    }
  };

  const checkMoveDuplicateBriefcase = async (briefcaseFolderId) => {
    let categoryBriefcase = await getCategoryBriefcase(user?._id, briefcaseFolderId);
    let isExists = [];
    if (categoryBriefcase?.length > 0) {
      selectedBriefcase.forEach((item) => {
        categoryBriefcase.forEach((e) => {
          if (!item?.presentationEntityPageId && !e?.presentationEntityPageId) {
            if (e?.presentationEntityId?.toString() == item?.presentationEntityId?.toString()) {
              isExists.push(e.id);
            }
          } else if (
            e.presentationEntityId?.toString() == item.presentationEntityId?.toString() &&
            e?.presentationEntityPageId?.toString() == item?.presentationEntityPageId?.toString()
          ) {
            isExists.push(e.id);
          }
        });
      });
    }
    if (isExists?.length === 0) {
      newFolderMoveBriefcase(briefcaseFolderId);
    } else {
      setShowConfirmMoveBriefcase(true);
    }
  };

  // submit "move briefcase" request
  const newFolderMoveBriefcase = async (briefcaseFolderId) => {
    if (user?._id && briefcaseFolderId) {
      dispatch(showLoader());
      let list = selectedBriefcase.map((res) => res.id);
      await updateBriefcaseMove(user?._id, briefcaseFolderId, list)
        .then((res) => {
          dispatch(actionSuccess("Briefcase(s) moved successfully."));
          getCategoryAllBriefcase(briefcaseCategoryId, `presentationSeqNo:asc`);
          getBriefcaseFolderList();
          dispatch(updateBriefcaseCategoryId(briefcaseCategoryId));
          dispatch(hideLoader());
        })
        .catch((error) => {
          dispatch(actionError(error.response.data.message));
          dispatch(hideLoader());
        });
      setShowConfirmMoveBriefcase(false);
      handleCloseBriefcaseModal();
      setSelectedDoc([]);
    } else {
      setShowError(true);
    }
  };

  // add new added folder to existing folder list
  useEffect(() => {
    let folderArray = [];
    if (allBriefcaseCategoryListing.length !== 0) {
      allBriefcaseCategoryListing.forEach(function (item) {
        folderArray.push(item.categoryName);
      });
    }
    setExistingFolders(folderArray);
  }, [allBriefcaseCategoryListing]);

  // set newaly added folder id in state
  useEffect(() => {
    if (newFolderId !== "") {
      setFolderId(newFolderId);
      if (selectedBriefcase && selectedBriefcase?.length > 0) {
        newFolderMoveBriefcase(newFolderId);
      }
    }
  }, [newFolderId]);

  return (
    <div>
      {showNewFolderModal ? (
        <ModalBriefcaseNewFolder
          modalTitle="New Folder"
          show={showNewFolderModal}
          onHide={handleCloseNewFolderModal}
          folderName={folderName}
          setFolderName={setFolderName}
          folderExistError={folderExistError}
          setFolderExistError={setFolderExistError}
          showError={showError}
          createFolder={createFolder}
        />
      ) : (
        <Modal
          dialogClassName={classes.modalMain}
          contentClassName={classes.modalContent}
          show={showBriefcaseModal}
          onHide={handleCloseBriefcaseModal}
          centered
          backdrop="static"
        >
          <Modal.Header className={classes.modalHeader}>
            <Modal.Title className={classes.modalTitle}>Move To Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body className={classes.modalBody}>
            <ModalDropdownInput
              onAddFolderClick={handleShowNewFolderModal}
              categoryList={
                briefcaseCategoryId ? allBriefcaseCategoryListing.filter((item) => item.id !== briefcaseCategoryId) : allBriefcaseCategoryListing
              }
              showError={showError}
              setFolderID={setFolderId}
              newFolderId={newFolderId}
              addSuccessfulBriefcase={onSaveClick}
              setShowBriefcase={setShow}
            />
          </Modal.Body>
          <Modal.Footer className={classes.modalFooter}>
            <ModalCancelSaveButtons cancelButtonClick={handleCloseBriefcaseModal} saveButtonText={"Move"} saveButtonClick={onSaveClick} />
          </Modal.Footer>
        </Modal>
      )}
      {showConfirmMoveBriefcase && (
        <BriefPopUp
          open={showConfirmMoveBriefcase}
          containerStyle={{ height: "fit-content", maxWidth: "32rem", paddingTop: "0", paddingBottom: "0" }}
          content={
            <div style={{ width: "100%", margin: "1rem 0 1rem 1.5rem" }}>
              <button
                type="button"
                className="btn btn-primary ml-3 mr-3"
                style={{ fontSize: "14px", paddingLeft: "24px", paddingRight: "24px", float: "right" }}
                onClick={() => {
                  newFolderMoveBriefcase(folderId);
                  setShowConfirmMoveBriefcase(false);
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-outline-dark"
                style={{ fontSize: "14px", paddingLeft: "24px", paddingRight: "24px", float: "right" }}
                onClick={() => {
                  setShowConfirmMoveBriefcase(false);
                }}
              >
                No
              </button>
            </div>
          }
          customHeader={
            <div style={{ width: "100%", borderTop: "5px solid #f94f5e" }}>
              <div style={{ paddingTop: "20px", paddingLeft: "0.75rem", paddingRight: "0.75rem" }}>
                <p style={{ fontSize: "0.875rem", color: "black" }}>
                  {selectedBriefcase?.length > 1
                    ? "You are moving multiple briefcases to the selected folder. Please note the duplicate briefcases (if any) on the target folder will be replaced automatically. Are you sure you want to continue?"
                    : "The content already exists in your selected briefcase folder. Are you sure you want to overwrite the existing briefcase?"}
                </p>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default MoveToBriefcase;
