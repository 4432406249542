import React, { useEffect, useMemo, useState } from "react";
import NewContentCard from "../NewContentCard";
import WidgetBox from "../widget-box";
import MergedCard from "../mergedCard";
import { useSelector } from "react-redux";
import "./index.css";
import WidgetHeader from "../WidgetHeader";
import { GridLoaderComponent } from "../loaders";

const PinnedContent = (props) => {
  const {
    data,
    widgetData,
    handleAddClick,
    selectedTheme,
    widgetThemeData,
    name,
    text,
    hideHeader = false,
    onDeleteClick,
    onSettingClick,
    isMerge,
    widgetName,
  } = props;

  const combinedWidgetData = useSelector((state) => state.customizableWidgetState.combinedWidgetData);

  const [isLoading, setIsLoading] = useState(true);

  const pinnedContentCardData = useMemo(() => {
    if (!widgetData || !combinedWidgetData) return [];
    const ids = widgetData?.widgetContent?.map((widget) => widget?.widgetContentId) || [];
    return combinedWidgetData.filter((content) => ids.includes(content?.id));
  }, [widgetData, combinedWidgetData]);

  const widgetTheme = useMemo(() => {
    if (widgetData?.widgetTheme) {
      return {
        themeColor: widgetData?.widgetTheme?.themeColor,
        themeName: widgetData?.widgetTheme?.themeName,
      };
    }
    if (widgetThemeData?.length > 0 && selectedTheme) {
      return widgetThemeData[0]?.configValue.find((configVal) => String(configVal?.themeColor) === String(selectedTheme));
    }
  }, [selectedTheme, widgetThemeData, widgetData]);

  const textColor = useMemo(() => {
    if (!widgetTheme) return;
    const isDarkTheme = widgetTheme?.themeName === "Dark" || widgetTheme?.themeName === "On Thumbnail";
    return isDarkTheme ? "white" : "black";
  }, [widgetTheme]);

  const pinnedCardData = useMemo(() => {
    const source = data?.[0] || pinnedContentCardData?.[0];

    if (source) {
      return {
        ...source,
        item: source,
        label: source.subContentType,
        ribbon: source.contentType,
      };
    }

    return {};
  }, [data, pinnedContentCardData]);

  useEffect(() => {
    if (pinnedCardData?.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [pinnedCardData]);

  if (isLoading) {
    return <GridLoaderComponent size={15} />;
  }

  return (
    <div className="w-full h-full">
      {!hideHeader && pinnedCardData && Object.keys(pinnedCardData).length > 0 && (
        <div className="w-full" style={{ height: "32px" }}>
          <WidgetHeader onSettingClick={onSettingClick} onDeleteClick={onDeleteClick} {...props} />
        </div>
      )}
      <div className={pinnedCardData && Object.keys(pinnedCardData).length > 0 ? "pinned-card-container" : "pinned-card-empty-container"}>
        {pinnedCardData && Object.keys(pinnedCardData).length > 0 ? (
          <div className="h-full w-full">
            {!isMerge ? (
              <NewContentCard
                bgStyle={widgetTheme?.themeColor || "white"}
                textStyle={textColor}
                item={pinnedCardData?.item}
                industryTag={pinnedCardData?.industryTag}
                // label={pinnedCardData?.label}
                title={pinnedCardData?.title}
                profileImage={pinnedCardData?.profileImage}
                infoText={pinnedCardData?.infoText}
                date={pinnedCardData?.date}
                ribbon={pinnedCardData?.ribbon || ""}
                readTime={pinnedCardData?.readTime}
                entityType={pinnedCardData?.entityType}
                darkBg={textColor == "black" ? true : false}
                thumbnailImage={widgetTheme?.themeName === "On Thumbnail" ? true : false}
              />
            ) : (
              <MergedCard
                bgStyle={widgetTheme?.themeColor || "white"}
                textStyle={textColor}
                item={pinnedCardData?.item}
                industryTag={pinnedCardData?.industryTag}
                // label={pinnedCardData?.label}
                title={pinnedCardData?.title}
                profileImage={pinnedCardData?.profileImage}
                infoText={pinnedCardData?.infoText}
                date={pinnedCardData?.date}
                ribbon={pinnedCardData?.ribbon}
                readTime={pinnedCardData?.readTime}
                entityType={pinnedCardData?.entityType}
                darkBg={textColor == "black" ? true : false}
                thumbnailImage={widgetTheme?.themeName === "On Thumbnail" ? true : false}
              />
            )}
          </div>
        ) : (
          <div className="add-pinned-content-button">
            <WidgetBox handleAddClick={() => handleAddClick(name, widgetName)} text={text} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PinnedContent;
