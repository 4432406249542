/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Trend taxonomy Tree Component
     Require Props :
        handleSave : Save Tree Data
        loadOtherTagInfo : Switch between trend and tree tagging
        saveTreeData : Save Technology taxonomy data
        contentId : Content Id [news id | company id]
        fetchContentData : Fetch content taxonomy
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import noTaxonomyImage from "../../../../assets/images/no-taxonomy.svg";
import { DropdownList } from "react-widgets";
import { getProductVariant } from "../../../../utilities";
import { getArrayCount } from "../../../../utilities/index";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { fetchObjective, fetchObjectiveTaxonomy } from "../../../../middleware/services/businessObjectiveApi";
import { showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import PropTypes from "prop-types";
import TreeUI from "../treeUI";
const Taxonomy = (props) => {
  const dispatch = useDispatch();

  const [objectiveData, setObjectiveData] = useState([]);
  const [selectedObjective, setSelectedObjective] = useState(undefined);
  const [taxonomy, setTaxonomy] = useState([]);
  const [checked, setChecked] = useState([]);

  const [selectedObjectiveTaxonomy, setSelectedObjectiveTaxonomy] = useState([]);
  const [reRender, setReRender] = useState(false);
  let contentTaggingData = [];

  const productVariant = getProductVariant();
  /*
        @Description : Use only for avoiding trend change mechanism on save trend
    */
  // const [selectedObjective,setSelectedObjective] = useState('');
  /*
       @Description : Fetch taxonomy tagging for respective content 
       1. fetchContentTagging received from parent component as a props
   */
  const fetchContentTagging = (objectiveId) => {
    setReRender(false);
    setSelectedObjectiveTaxonomy([]);
    dispatch(showLoader());
    let params = {};
    params.filters = [["_id", "in", props.contentIds]];
    const contentTaxonomy = props.fetchContentTagging(objectiveId, params);
    contentTaxonomy
      .then((res) => {
        dispatch(hideLoader());
        const { data = [] } = res.data;

        if (data.length) {
          contentTaggingData = data;
          const taggedData = [];
          let uniqueIds = [];

          contentTaggingData.forEach((tax) => {
            tax.objectiveTaxonomy.forEach((obj) => {
              // tax.companyObjectiveTaxonomy.forEach((obj) => {
              uniqueIds.push(obj.taxonomyId);
            });
          });
          const countOfArr = getArrayCount(uniqueIds);
          let commonTagging = [];

          countOfArr.forEach((ele) => {
            if (ele.count == props.contentIds.length) {
              commonTagging.push(ele.value);
            }
          });
          let uniqueDataIds = [];

          contentTaggingData.forEach((tax) => {
            tax.objectiveTaxonomy.forEach((obj) => {
              // tax.companyObjectiveTaxonomy.forEach((obj) => {
              if (!uniqueDataIds.includes(obj.taxonomyId) && commonTagging.includes(obj.taxonomyId)) {
                taggedData.push(obj);
                uniqueDataIds.push(obj.taxonomyId);
              }
            });
          });

          const treeData = buildSelectedTreeJson([...taggedData], 0);
          setSelectedObjectiveTaxonomy(treeData);

          const checkNode = [];

          taggedData.forEach((ele) => {
            checkNode.push(ele.taxonomyId);
          });
          setChecked(checkNode);
        }
        setReRender(true);
      })
      .catch((err) => {
        setReRender(true);
        dispatch(hideLoader());
        console.error("err fetchProductTaxonomy :: ", err);
      });
  };

  /*
        @Description : Fetch all trend 
    */
  const _fetchObjective = (taggingData) => {
    dispatch(showLoader());
    fetchObjective({
      fields: ["id", "objectiveTitle"],
      filters: [
        ["productVariant.isLive", "eq", "YES"],
        ["objectiveIsDeleted", "eq", "NO"],
        ["productVariant.productName", "eq", productVariant],
      ],
      sort: "objectiveTitle:asc",
    })
      .then((res) => {
        dispatch(hideLoader());
        const data = [...res.data.data];
        setObjectiveData(data);
        setSelectedObjective(data[0]);
        _fetchObjectiveTaxonomy(data[0].id, taggingData);
        // fetchContentTagging(data[0].id)
        // const selectedNodeData = buildSelectedTreeJson(contentTaggingData);
        // setTagging(selectedNodeData)
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err _fetchObjective :: ", err);
      });
  };
  /*
        @Description : Fetch all taxonomy for selected trend
        @param 
        trendSubRoot : Trend Id
        taggingData : Content taxonomy tagged data [ Use for mark as default check ]
    */
  const _fetchObjectiveTaxonomy = (objectiveId, taggingData = []) => {
    // setSelectedObjective('');
    dispatch(showLoader());

    fetchObjectiveTaxonomy(objectiveId, {
      filters: [["objectiveId", "eq", objectiveId]],
    })
      .then((res) => {
        dispatch(hideLoader());
        let { data = [] } = res.data;
        let checkNode = [];
        if (data.length) {
          const parentId = data.filter((tax) => tax.taxonomyParentId == 0);
          const treeJosnArray = buildTreeJson(data, 0);

          setTaxonomy(treeJosnArray);
          fetchContentTagging(objectiveId);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchTaxonomy :: ", err);
      });
  };
  /*
        @Description : Build tree data
        @param : 
        data : Trend taxonomy data
        parentId : objective id
    */
  const buildTreeJson = (data, parentId) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.taxonomyName;
      if (element["taxonomyParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };
  /*
       @Description : Build tree data for tagged values
       @param : 
       data : taxonomy data
       parentId :industry id
   */
  const buildSelectedTreeJson = (data, parentId = 0) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.taxonomyId;
      element["label"] = element.taxonomyName;
      element["showCheckbox"] = false;
      if (element["taxonomyParentId"] == parentId) {
        const children = buildSelectedTreeJson(data, element["taxonomyId"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };
  /*
      @Description : Make bridge to send callback
      @param : 
      selectedTech : selected objective
      checkValues : Checked taxonomy
  */

  const handleSaveBridge = (selectedObjectiveId, checkValues) => {
    setSelectedObjective(selectedObjectiveId);
    props.handleSave(selectedObjectiveId, checkValues, _fetchObjectiveTaxonomy);
  };

  const handleDelete = () => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          props.handleDelete(selectedObjective, _fetchObjectiveTaxonomy);
        },
        content: "Do you want to remove taxonomy tagging?",
      })
    );
  };
  /*
        @Description : Render Selected Industry Tree
    */

  useEffect(() => {
    _fetchObjective();
  }, []);
  return (
    <div key="taxonomyTab">
      <div
        className="row"
        style={{
          height: "70vh",
          marginLeft: "0px",
          marginRight: "0px",
          "padding-right": "0.7em",
        }}
      >
        <div
          className="col-md-6"
          style={{
            background: "#FFFFFF",
            border: "1px solid lightgrey",
            "border-radius": "4px",
          }}
        >
          <div className="rounded-top pl-1" style={{ "padding-top": "2.2vh", "padding-bottom": "7px" }}>
            <h6 className="font-weight-bold">Objective Taxonomy Tagging</h6>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div style={{ "padding-top": "1em" }}>
                {selectedObjectiveTaxonomy.length > 0 && reRender ? (
                  <TreeUI
                    treeData={selectedObjectiveTaxonomy}
                    showNodeIcon={false}
                    expanded={checked}
                    isSearchable={false}
                    // reRender={reRender}
                  />
                ) : (
                  <div className="no-taxonomy-div">
                    <img src={noTaxonomyImage} alt="No Tagging" width="250" height="250" />
                    <div class="info-container">
                      <p>
                        Select tags to create <span>Objective Taxonomy</span>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-6"
          style={{
            background: "#FFFFFF",
            border: "1px solid lightgrey",
            "border-radius": "4px",
          }}
        >
          <div
            className="row"
            style={{
              "justify-content": "left",
              "padding-left": "0.4em",
              "padding-bottom": "0.5em",
            }}
          >
            <div className="rounded-top pl-2" style={{ "padding-top": "2.2vh" }}>
              <h6 className="font-weight-bold">Select Objective:</h6>
            </div>
            <div className="col-4 pt-2" style={{ "margin-left": "1em" }}>
              {/* <div className="col-6"> */}
              {objectiveData.length > 0 ? (
                <DropdownList
                  className=" custom-dropdownlist "
                  data={[...objectiveData]}
                  filter
                  textField="objectiveTitle"
                  valueField="id"
                  value={selectedObjective}
                  // || setSelectedObjective(objectiveData[0].id)}
                  onChange={(value) => {
                    setReRender(false);
                    setSelectedObjective(value);
                    _fetchObjectiveTaxonomy(value.id);
                  }}
                />
              ) : null}
            </div>
            <div className="col-4 pt-2">
              <button className="btn btn-primary pr-2" onClick={handleDelete}>
                Delete Taxonomy
              </button>
            </div>
          </div>
          <div className="row" style={{ "padding-top": "1.5em" }}>
            <div className="col-md-12">
              {taxonomy.length > 0 ? (
                <TreeUI
                  treeData={taxonomy}
                  showNodeIcon={false}
                  handleSave={handleSaveBridge}
                  saveTreeData={props.saveTreeData}
                  technology={selectedObjective}
                  checked={checked}
                  expanded={checked}
                  isSearchable={true}
                  noCascade={true}
                  // reRender={reRender}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Taxonomy;
Taxonomy.propTypes = {
  fetchContentTagging: PropTypes.func.isRequired,
  loadOtherTagInfo: PropTypes.func,
  handleSave: PropTypes.func.isRequired,
  saveTreeData: PropTypes.bool,
  contentId: PropTypes.string.isRequired,
};
