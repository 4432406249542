/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : KIA  Exponential Technology Listing Page 
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/June/2021
    Author						: Sandeep K. Sharma
================================================================
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CommonDataGrid from "../../../../components/dataGrid";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import {
  getExponentialTechnologies,
  deleteSingleExponentialTechnology,
  deleteSelectedExponentialTechnologies,
} from "../../../../../middleware/services/kiaApi";
import { decodeHTML, getDateFromMongoDate } from "../../../../../utilities";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { StringFilter } from "../../../../components/dataGrid/fliters";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import AddExponentialTechModel from "./add";
import _ from "underscore";
import { fetchSingleKia } from "../../../../../middleware/services/kiaApi";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";
import AccessDenied from "../../../../components/accessDenied";
import "../../../kia/index.scss";

const ExponentialTechnologyListing = (props) => {
  const { accessRights: aR } = props;
  const [deleteId, setDeleteId] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [expTechId, setExpTechId] = useState("");
  const [editData, setEditData] = useState(null);
  const kiaId = props.kiaId;
  const dispatch = useDispatch();

  /* Common access */
  const accessActionAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  const breadCrumbLinks = [
    { linkUrl: "/kia", linkName: aR.moduleName, linkActive: false },
    { linkUrl: kiaId ? `/kia/${kiaId}/edit` : "//kia/add", linkName: (editData && editData?.kiaName) || "", linkActive: false },
    {
      linkUrl: `/kia/ ${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  const closeModal = () => {
    setExpTechId("");
    setShowModal(false);
  };
  /*
       @Description : Fetch Single KIA Details
   */
  const fetchKiaDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "kiaName",
      "kiaDescription",
      "kiaCurrentResearchStartups",
      "customKia",
      "kiaStatus",
      "kiaNextUpdateDate",
      "kiaClientUsers",
      "kiaIndustryTag",
      "kiaScore",
      "kiaExpert",
      "kiaExpertNetworkStrength",
      "kiaImpactAreaSurveyContributors",
      "kiaClientExpertEngagements",
    ];
    params.fields = fields;
    if (props.productVariant) defaultFilter.push(["productVariant.productName", "eq", props.productVariant]);
    params.filters = [...defaultFilter];

    fetchSingleKia(kiaId, params).then((res) => {
      let data = res.data.data || {};
      if (data) {
        setTimeout(() => {
          setEditData(data);
        }, 200);
      }
    });
  };

  useEffect(() => {
    if (kiaId) {
      !editData && fetchKiaDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/kia"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, props.kiaId, props.selectedIndex, editData, props.location.hash]);

  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        Edit={
          accessActionEdit
            ? () => {
                showModal && setShowModal(false);
                setShowModal(true);
                setExpTechId(value.id);
              }
            : undefined
        }
        Delete={
          accessActionDelete
            ? () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteSingleExponentialTechnology(value.id)
                        .then((response) => {
                          if (deleteId) setDeleteId(undefined);
                          setDeleteId(new Date());
                          dispatch(actionSuccess("Exponential Technology deleted successfully"));
                          fetchExponentialTechnologies().then((v) => {
                            formatRowData(v.data.data);
                          });
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Failed to delete Exponential Technology"));
                        });
                    },
                    okText: "Delete",
                    cancelText: "Cancel",
                    content: "Are you sure you want to delete?",
                    title: "dialogAlertCssWarning",
                  })
                );
              }
            : undefined
        }
      />
    );
  };

  const deleteManyFunc = (flag) => {
    const selectedExpTech = localStorage.getItem("selectedExpTech") ? JSON.parse(localStorage.getItem("selectedExpTech")) : [];
    if (flag) {
      if (selectedExpTech.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteSelectedExponentialTechnologies({ expTechIds: selectedExpTech })
                .then((response) => {
                  if (response) {
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    fetchExponentialTechnologies().then((v) => {
                      formatRowData(v.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete video"));
                });
            },
            okText: "Delete",
            cancelText: "Cancel",
            content: "Are you sure you want to delete?",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };

  /* 
        @Description : Date Template 
    */
  const dateTemplate = (value) => {
    const column = value.column.field;
    return ["Published Date", "Created Date"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
      <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
    ) : (
      ""
    );
  };

  /*
       @Description : Template for Description 
    */
  const DescriptionTemplate = (value) => {
    let desc = decodeHTML(value["Description"]);
    if (desc) {
      return <span>{desc.substr(0, 20) + "\u2026"}</span>;
    }
    return null;
  };

  const columnNames = {
    id: "id",
    Technology: "technologyName",
    "Sub-Technology": "subTechnology",
    Description: "technologyDescription",
    "Potential Time Scale": "technologyPotentialTimescale",
    "Created Date": "technologyCreatedDate",
  };

  const columnFields = [
    {
      field: "id",
      type: "String",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    { field: "Technology", type: "String", filterTemplate: StringFilter, textAlign: "Left", showInColumnChooser: false },
    { field: "Sub-Technology", type: "String", filterTemplate: StringFilter, textAlign: "Left", showInColumnChooser: false },
    {
      field: "Description",
      template: DescriptionTemplate,
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Left",
      showInColumnChooser: false,
    },
    { field: "Potential Time Scale", type: "String", filterTemplate: StringFilter, textAlign: "Center", width: 220, showInColumnChooser: false },
    {
      field: "Created Date",
      template: dateTemplate,
      type: "Date",
      textAlign: "Center",
      width: 170,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
  ];
  if (accessActionEdit || accessActionDelete) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false, textAlign: "Center" });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      filterTemplate: StringFilter,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }
  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    rowData.forEach((rD) => {
      let data = {};
      data["id"] = rD.id;
      data["Technology"] = rD.technologyName;
      data["Sub-Technology"] = _.pluck(rD.subTechnology, "subTechnologyName").join(", ");
      data["Description"] = rD.technologyDescription;
      data["Potential Time Scale"] = rD.technologyPotentialTimescale;
      data["Created Date"] = new Date(rD.technologyCreatedDate);
      formatedRowData.push(data);
    });
    return formatedRowData;
  };

  const fetchExponentialTechnologies = () => {
    const params = {};
    params.limit = 100;
    params.fields = ["technologyName", "subTechnology", "technologyDescription", "technologyPotentialTimescale", "technologyCreatedDate"];
    params.filters = [];
    return getExponentialTechnologies(props.kiaId, params);
  };
  return (
    <>
      {props?.interfaceAccess ? (
        <>
          {showModal && (
            <AddExponentialTechModel
              showModal={showModal}
              closeModal={closeModal}
              kiaId={props.kiaId}
              expTechId={expTechId}
              setDeleteId={setDeleteId}
            />
          )}
          <div className="gennx-content-wrapper content-wrapper pt-3 mx-2 pr-2">
            <div className="gennx-grid-container-custom">
              <CommonDataGrid
                gridTitle="Exponential Technologies"
                fetch={fetchExponentialTechnologies}
                columnNames={columnNames}
                columnFields={columnFields}
                formatRowData={formatRowData}
                deleteId={deleteId}
                getMultiSelectedRows={(data) => {
                  localStorage.setItem("selectedExpTech", JSON.stringify([...data]));
                }}
                getMultiDeSelectedRows={(data) => {
                  localStorage.setItem("selectedExpTech", JSON.stringify([...data]));
                }}
                addNew={() => setShowModal(true)}
                addNewRight={accessActionAddNew}
                deleteRight={accessActionDelete}
                deleteMany={accessActionDelete ? deleteManyFunc : false}
                isChildGrid={true}
              />
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default ExponentialTechnologyListing;
