/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Company tagging integrations and API wiring up 
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 08/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/

import React from "react";
import Taxonomy from "./taxonomy";
import Trend from "./trend";
import { getProductVariant } from "../../../../../utilities";

const Tagging = (props) => {
  const companyId = props.companyId;
  const loadOtherTagInfo = (info) => {};
  if (companyId == "") {
    return null;
  }
  const productVariant = getProductVariant();
  if (!productVariant || productVariant == "") return null;
  return (
    <div className="gennx-content-wrapper content-wrapper" style={{ background: "#f2f2f2", overflow: "scroll" }}>
      {productVariant == "Insider" ? (
        <Trend
          {...props}
          loadOtherTagInfo={loadOtherTagInfo}
          selectedIndex={props.selectedIndex}
          setCurrentTab={(selectedIndex) => {
            props.setCurrentTab(selectedIndex);
          }}
        />
      ) : (
        <Taxonomy
          {...props}
          loadOtherTagInfo={loadOtherTagInfo}
          selectedIndex={props.selectedIndex}
          setCurrentTab={(selectedIndex) => {
            props.setCurrentTab(selectedIndex);
          }}
        />
      )}
    </div>
  );
};
export default Tagging;
