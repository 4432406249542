/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : CMS API with Access Rights Core Modules, Taxonomy Master (Technology, Trend, Value Chain),
							Data Allocation, Vendors
----------------------------------------------------------------
	Creation Details
	Date Created				: 05/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
	Change History				: #1
	Changed By					: Anand Kumar
	Changed Date				: 06/Aug/2020
	Change Description			: tech specification api
================================================================
	Change History				: #2
	Changed By					: Anand Kumar
	Changed Date				: 14/Aug/2020
	Change Description			: added data QC apis
================================================================
	Change History				: #3
	Changed By					: YOGESH N. GUPTA
	Changed Date				: 03/Sep/2020
	Change Description			: Team Routes (cms api)
================================================================
	Change History				: #4
	Changed By					: YOGESH N. GUPTA
	Changed Date				: 17/Sep/2020
	Change Description			: Announcement Routes (cms api)
================================================================
Change History				: #5
	Changed By					: PANKAJ KULSHRESHTHA
	Changed Date				: 03/Dec/2020
	Change Description			: Reports Routes (cms api)
================================================================
Change History				: #6
	Changed By					: SANDEEP SHARMA
	Changed Date				: 12/May/2021
	Change Description			: People Routes (cms api)
================================================================
*/

import { CMS_API as API } from "../../config/apiConfig";
import { CMS_API_URL } from "../../config";

// fetch core modules (master access rights)
export const fetchCoreModules = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/core/module",
    method: "GET",
    params: params,
  });
};

// all taxonomy Media
export const fetchTaxonomyMedia = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/taxonomy/media",
    method: "GET",
    params: params,
  });
};

// save taxonomy media
export const saveTaxonomyMedia = (payload) => {
  return API({
    url: "/taxonomy/media",
    method: "POST",
    data: payload,
  });
};

// update taxonomy media
export const updateTaxonomyMedia = (taxonomyId, payload) => {
  return API({
    url: `/taxonomy/media/${taxonomyId}`,
    method: "PUT",
    data: payload,
  });
};

export const updateTaxonomies = (params = {}, payload) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/taxonomy/media`,
    method: "PUT",
    data: payload,
    params: params,
  });
};

export const updateTaxonomiesTrend = (params = {}, payload) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/taxonomy/trend`,
    method: "PUT",
    data: payload,
    params: params,
  });
};

export const updateTaxonomiesTechnology = (params = {}, payload) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/taxonomy/technology`,
    method: "PUT",
    data: payload,
    params: params,
  });
};

export const updateTaxonomiesValueChain = (params = {}, payload) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/taxonomy/industry-value-chain`,
    method: "PUT",
    data: payload,
    params: params,
  });
};

// delete taxonomy media
export const deleteTaxonomyMedia = (taxonomyIds) => {
  return API({
    url: `/taxonomy/media`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};
// all taxonomy technology
export const fetchTaxonomyTechnology = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/taxonomy/technology",
    method: "GET",
    params: params,
  });
};

// save taxonomy technology
export const saveTaxonomyTechnology = (payload) => {
  return API({
    url: "/taxonomy/technology",
    method: "POST",
    data: payload,
  });
};

// update taxonomy technology
export const updateTaxonomyTechnology = (taxonomyId, payload) => {
  return API({
    url: `/taxonomy/technology/${taxonomyId}`,
    method: "PUT",
    data: payload,
  });
};

// delete taxonomy technology
export const deleteTaxonomyTechnology = (taxonomyIds) => {
  return API({
    url: `/taxonomy/technology`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};

// all taxonomy trend for internship
export const fetchTaxonomyTrendIntern = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/outsourcing/trend",
    method: "GET",
    params: params,
  });
};

// all taxonomy technology for Internship User
export const fetchTaxonomyTechnologyForIntern = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/outsourcing/technology",
    method: "GET",
    params: params,
  });
};

export const fetchCountryForOutSourcing = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/outsourcing/country",
    method: "GET",
    params: params,
  });
};

// generate email otp
export const generateOtpForOutSourcing = (payload) => {
  // outsourcing/generate-otp
  return API({
    url: "/outsourcing/generate-otp",
    method: "POST",
    data: payload,
  });
};

// /outsourcing/validate-opt
export const validateOtpForOutSourcing = (payload) => {
  return API({
    url: "/outsourcing/validate-otp",
    method: "POST",
    data: payload,
  });
};

// outsourcing add a user
export const addOutSourcingUser = (payload) => {
  return API({
    url: "outsourcing/user",
    method: "POST",
    data: payload,
  });
};

// outsourcing regenerate otp
export const regenerateOtpForOutSourcing = (payload) => {
  return API({
    url: "outsourcing/regenerate-otp",
    method: "POST",
    data: payload,
  });
};
// Fetch registration form data
export const getJsonDataForRegistratonForm = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/outsourcing/form",
    method: "GET",
    params: params,
  });
};
// Fetch WhatNext logo File Url
export const fetchWhatNextLogo = () => {
  return `${CMS_API_URL}/outsourcing/logo/whatnext`;
};
// Fetch Insider logo File Url
export const fetchInsiderLogo = () => {
  return `${CMS_API_URL}/outsourcing/logo/insider`;
};
// Fetch Outsourcing Terms & Condition Files
export const fetchOutsourcingTncFileUrl = (fileName) => {
  return `${CMS_API_URL}/outsourcing/tnc/${fileName}`;
};
// all taxonomy trend
export const fetchTaxonomyTrend = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/taxonomy/trend",
    method: "GET",
    params: params,
  });
};

// save taxonomy trend
export const saveTaxonomyTrend = (payload) => {
  return API({
    url: "/taxonomy/trend",
    method: "POST",
    data: payload,
  });
};

// update taxonomy trend
export const updateTaxonomyTrend = (trendId, payload) => {
  return API({
    url: `/taxonomy/trend/${trendId}`,
    method: "PUT",
    data: payload,
  });
};

// delete taxonomy trend
export const deleteTaxonomyTrend = (taxonomyIds) => {
  return API({
    url: `/taxonomy/trend`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};

// fetch taxonomy industry
export const fetchTaxonomyIndustry = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/taxonomy/industry",
    method: "GET",
    params: params,
  });
};
// fetch WhatNext Industry
export const fetchIndustryExpert = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/outsourcing/industry",
    method: "GET",
    params: params,
  });
};

// fetch taxonomy value chain
export const fetchTaxonomyValueChain = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/taxonomy/industry-value-chain",
    method: "GET",
    params: params,
  });
};

// save taxonomy value chain
export const saveTaxonomyValueChain = (payload = {}) => {
  return API({
    url: "/taxonomy/industry-value-chain",
    method: "POST",
    data: payload,
  });
};

// update taxonomy value chain
export const updateTaxonomyValueChain = (valueChainId, payload = {}) => {
  return API({
    url: `/taxonomy/industry-value-chain/${valueChainId}`,
    method: "PUT",
    data: payload,
  });
};

// delete taxonomy value chain
export const deleteTaxonomyValueChain = (payload = {}) => {
  return API({
    url: `/taxonomy/industry-value-chain`,
    method: "DELETE",
    data: payload,
  });
};

// Data Allocation company Auto
export const dataAllocationCompany = (userId, payload = {}) => {
  return API({
    url: `/allocation/company/${userId}`,
    method: "POST",
    data: payload,
  });
};

// save manual allocation
export const allocateCompanyManual = (userId, payload = {}) => {
  return API({
    url: `/allocation/company/manual/${userId}`,
    method: "POST",
    data: payload,
  });
};

// Deallocate companies manually
export const deAllocateCompanyManual = (userId, payload = {}) => {
  return API({
    url: `/deallocation/company/manual/${userId}`,
    method: "POST",
    data: payload,
  });
};

// save manual allocation
export const allocateCompany = (userId, payload = {}) => {
  return API({
    url: `/allocation/company/${userId}`,
    method: "POST",
    data: payload,
  });
};

// fetch all vendor
export const fetchVendor = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/vendor",
    method: "GET",
    params: params,
  });
};

// fetch single vendor
export const fetchSingleVendor = (vendorId, params = {}) => {
  let { fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/vendor/${vendorId}`,
    method: "GET",
    params: params,
  });
};

// fetch vendor profile image
export const fetchVendorProfileImage = (fileName) => {
  return API({
    url: `/vendor/image/${fileName}`,
    method: "GET",
  });
};

// fetch vendor profile image
export const fetchVendorProfileImageUrl = (fileName) => {
  return `${CMS_API_URL}/vendor/image/${fileName}`;
};

// add vendor
export const addVendor = (payload) => {
  return API({
    headers: { "content-type": "multipart/form-data" },
    url: "/vendor",
    method: "POST",
    data: payload,
  });
};

// update vendor
export const updateVendor = (vendorId, payload) => {
  return API({
    headers: { "content-type": "multipart/form-data" },
    url: `/vendor/${vendorId}`,
    method: "PUT",
    data: payload,
  });
};

// delete vendor
export const deleteVendor = (vendorId) => {
  return API({
    url: `/vendor/${vendorId}`,
    method: "DELETE",
  });
};

// delete many vendor
export const deleteManyVendor = (payload, params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/vendor",
    method: "DELETE",
    params: params,
    data: payload,
  });
};

// fetch teams
export const fetchTeam = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/team",
    method: "GET",
    params: params,
  });
};

// fetch single team
export const fetchSingleTeam = (teamId, params = {}) => {
  let { fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/team/${teamId}`,
    method: "GET",
    params: params,
  });
};

// fetch all announcement
export const fetchAnnouncement = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/announcement",
    method: "GET",
    params: params,
  });
};

// fetch single announcement
export const fetchSingleAnnouncement = (announcementId, params = {}) => {
  let { fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/announcement/${announcementId}`,
    method: "GET",
    params: params,
  });
};

// add announcement
export const addAnnouncement = (payload) => {
  return API({
    headers: { "content-type": "multipart/form-data" },
    url: "/announcement",
    method: "POST",
    data: payload,
  });
};

// update announcement
export const updateAnnouncement = (announcementId, payload) => {
  return API({
    headers: { "content-type": "multipart/form-data" },
    url: `/announcement/${announcementId}`,
    method: "PUT",
    data: payload,
  });
};

// get announcement document
export const getAnnouncementDocumentUrl = (file, fileName) => {
  return `${CMS_API_URL}/announcement/document/${file}/${fileName}`;
};

// delete announcement document
export const deleteAnnouncementDocument = (announcementId, fileName) => {
  return API({
    url: `/announcement/${announcementId}/document/${fileName}`,
    method: "DELETE",
  });
};

// delete announcement
export const deleteAnnouncement = (announcementId) => {
  return API({
    url: `/announcement/${announcementId}`,
    method: "DELETE",
  });
};

// delete many announcement
export const deleteManyAnnouncement = (params = {}, payload) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/announcement",
    method: "DELETE",
    params: params,
    data: payload,
  });
};

// Data Screening save to specific company
export const ScreeningSaveActions = (companyId, actionType, payload) => {
  return API({
    url: `screening/company/${actionType}/${companyId}`,
    method: "PUT",
    data: payload,
  });
};
// Data Processing save to specific company
export const processingSave = (companyId, tab, payload) => {
  return API({
    url: `processing/company/${tab}/save/${companyId}`,
    method: "PUT",
    data: payload,
  });
};
// Data Processing submit to specific company
export const processingSubmit = (companyId, tab, payload) => {
  return API({
    url: `processing/company/${tab}/save-submit/${companyId}`,
    method: "PUT",
    data: payload,
  });
};

// Data Processing final submit to specific company
export const processingFinalSubmit = (companyId, payload) => {
  return API({
    url: `processing/company/final-submit/${companyId}`,
    method: "PUT",
    data: payload,
  });
};

// Data Processing submit to specific Product
export const processingSubmitProduct = (productId, tab, payload) => {
  return API({
    url: `processing/product/${tab}/save-submit/${productId}`,
    method: "PUT",
    data: payload,
  });
};

// Data Processing final submit to specific product
export const processingFinalSubmitProduct = (productId, payload) => {
  return API({
    url: `processing/product/final-submit/${productId}`,
    method: "PUT",
    data: payload,
  });
};

// fetch tech specifications
export const fetchTechSpecification = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/tech-specification",
    method: "GET",
    params: params,
  });
};

// fetch a single product specification
export const fetchSingleTechSPecification = (specificationId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/tech-specification/${specificationId}`,
    method: "GET",
    params: params,
  });
};

// fetch countries
export const fetchCountry = (params) => {
  let { filters = undefined, fields = undefined, sort } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  if (sort) params.sort = JSON.stringify(sort);
  return API({
    url: "/core/country",
    method: "GET",
    params: params,
  });
};

// company data QC completed
export const qcComplete = (companyId, payload = {}) => {
  return API({
    url: `qc/company/complete/${companyId}`,
    method: "POST",
    data: payload,
  });
};

// company data QC rejected
export const qcReject = (companyId, payload) => {
  return API({
    url: `qc/company/reject/${companyId}`,
    method: "POST",
    data: payload,
  });
};

// company data QC rejected
export const qcSave = (companyId, payload) => {
  return API({
    url: `qc/company/save/${companyId}`,
    method: "POST",
    data: payload,
  });
};

// company data QC send-back
export const qcSendBack = (companyId, payload = {}) => {
  return API({
    url: `qc/company/send-back/${companyId}`,
    method: "POST",
    data: payload,
  });
};

// company data QC reject-reallocate
export const qcRejectReallocation = (companyId, payload) => {
  return API({
    url: `qc/company/reject-reallocate/${companyId}`,
    method: "POST",
    data: payload,
  });
};

// company data QC get remarks
export const qcgetRemark = (companyId) => {
  return API({
    url: `qc/company/remark/${companyId}`,
    method: "GET",
  });
};

// company data QC save remark
export const qcRemarkSave = (companyId, payload) => {
  return API({
    url: `qc/company/remark/${companyId}`,
    method: "POST",
    data: payload,
  });
};
//  data QC get remarks list
export const getRemarkList = (params = {}) => {
  let { filters = undefined, fields = undefined, sort } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/remark`,
    method: "GET",
    params: params,
  });
};
/***************************Product QC********************************************************** */
export const qcProductComplete = (productId, payload = {}) => {
  return API({
    url: `qc/product/complete/${productId}`,
    method: "POST",
    data: payload,
  });
};

// product data QC rejected
export const qcProductReject = (productId, payload) => {
  return API({
    url: `qc/product/reject/${productId}`,
    method: "POST",
    data: payload,
  });
};

// product data QC send-back
export const qcProductSendBack = (productId, payload = {}) => {
  return API({
    url: `qc/product/send-back/${productId}`,
    method: "POST",
    data: payload,
  });
};

// product data QC reject-reallocate
export const qcProductRejectReallocation = (productId, payload) => {
  return API({
    url: `qc/product/reject-reallocate/${productId}`,
    method: "POST",
    data: payload,
  });
};

// product data QC get remarks
export const qcGetProductRemark = (productId) => {
  return API({
    url: `qc/product/remark/${productId}`,
    method: "GET",
  });
};

// save product remark
export const qcProductRemarkSave = (productId, payload) => {
  return API({
    url: `qc/product/remark/${productId}`,
    method: "POST",
    data: payload,
  });
};
/*******************data publicing************************************************** */
// // publish company
export const dataPublishingCompany = (companyId, payload = {}) => {
  return API({
    url: `/publish/company/${companyId}`,
    method: "POST",
    data: payload,
  });
};
// publish product
export const dataPublishingProduct = (productId, payload = {}) => {
  return API({
    url: `/publish/product/${productId}`,
    method: "POST",
    data: payload,
  });
};
// publish news
export const dataPublishingNews = (newsId, payload = {}) => {
  return API({
    url: `/publish/news/${newsId}`,
    method: "POST",
    data: payload,
  });
};

// publish deliverable
export const dataPublishingDeliverable = (deliverableId, payload = {}) => {
  return API({
    url: `/publish/deliverable/${deliverableId}`,
    method: "POST",
    data: payload,
  });
};

// get legal status
export const fetchLegalStatus = (params = {}) => {
  let { filters = undefined, fields = undefined, sort } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  if (sort) params.sort = JSON.stringify(sort);
  return API({
    url: `/core/legal-status`,
    method: "GET",
    params: params,
  });
};

// fetch product subscription
export const fetchProductSubscription = (params = {}) => {
  let { filters = undefined, fields = undefined, sort, limit } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  if (sort) params.sort = JSON.stringify(sort);
  if (limit) params.limit = JSON.stringify(limit);
  return API({
    url: "/subscriptionProduct/products",
    method: "GET",
    params: params,
  });
};

export const fetchObjective = (productVariant) => {
  return API({
    url: "/core/objective",
    method: "GET",
    params: productVariant,
  });
};

// fetch custom forms
export const fetchCustomForms = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/form",
    method: "GET",
    params: params,
  });
};

export const fetchCustomFormById = (formId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/form/${formId}`,
    method: "GET",
    params: params,
  });
};
// save custom form

export const saveCustomForms = (payload) => {
  return API({
    url: "/form",
    method: "POST",
    data: payload,
  });
};

export const updateCustomForms = (formId, payload) => {
  return API({
    url: `/form/${formId}`,
    method: "PUT",
    data: payload,
  });
};

export const fetchClientUser = (productVariant) => {
  return API({
    url: "/core/client-user",
    method: "GET",
    params: productVariant,
  });
};

export const fetchCmsUser = (productVariant) => {
  return API({
    url: "/core/cms-user",
    method: "GET",
    params: productVariant,
  });
};

export const fetchProgramManager = (productVariant) => {
  return API({
    url: "/core/program-manager",
    method: "GET",
    params: productVariant,
  });
};
export const deleteCustomForm = (formId, payload) => {
  return API({
    url: `/form/${formId}`,
    method: "DELETE",
    data: payload,
  });
};

export const publishCustomForm = (formId, payload) => {
  return API({
    url: `/form/publish/${formId}`,
    method: "PUT",
    data: payload,
  });
};

// Fetch Languages
export const fetchLanguages = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/core/language",
    method: "GET",
    params: params,
  });
};
// Fetch Functional Experties
export const fetchFunctionalExpertiseData = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/core/functional-expertise",
    method: "GET",
    params: params,
  });
};
// Fetch Educational Field
export const fetchEducationalFieldData = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/core/educational-field",
    method: "GET",
    params: params,
  });
};

// publish people
export const dataPublishingPeople = (peopleId, payload = {}) => {
  return API({
    url: `/publish/people/${peopleId}`,
    method: "POST",
    data: payload,
  });
};

// publish Patent
export const dataPublishingPatent = (patentId, payload = {}) => {
  return API({
    url: `/publish/patent/${patentId}`,
    method: "POST",
    data: payload,
  });
};

// publish Patent
export const dataPublishManyPatent = (payload = {}) => {
  return API({
    url: `/publish/patents`,
    method: "POST",
    data: payload,
  });
};
// Publish Video
export const publishVideo = (videoId, payload) => {
  return API({
    url: `/publish/video/${videoId}`,
    method: "POST",
    data: payload,
  });
};
// Publish Kia
export const publishKia = (kiaId, payload) => {
  return API({
    url: `/publish/kia/${kiaId}`,
    method: "POST",
    data: payload,
  });
};
// Bulk Publish Patent
/* export const dataPublishManyPatent = (payload, params = {}) => {
	let { filters = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: "/publish/patent/",
		method: "POST",
		params: params,
		data: payload
	});
}; */

// fetch assessment
export const fetchAssessment = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/assessment",
    method: "GET",
    params: params,
  });
};
// fetch assessment by Id
export const fetchAssessmentById = (assessmentId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/assessment/${assessmentId}`,
    method: "GET",
    params: params,
  });
};
// save assessment form

export const saveAssessment = (payload) => {
  return API({
    url: "/assessment",
    method: "POST",
    data: payload,
  });
};

export const updateAssessment = (assessmentId, payload) => {
  return API({
    url: `/assessment/${assessmentId}`,
    method: "PUT",
    data: payload,
  });
};

// fetch Custom Tags
export const fetchEvaluationParams = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/assessment-eval-params",
    method: "GET",
    params: params,
  });
};

// delete assessment
export const deleteAssessment = (assessmentId) => {
  return API({
    url: `/assessment/${assessmentId}`,
    method: "DELETE",
  });
};

// delete many assessment
export const deleteManyAssessment = (params = {}, payload) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/assessment",
    method: "DELETE",
    params: params,
    data: payload,
  });
};

// fetch video
export const fetchVideo = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/training-video",
    method: "GET",
    params: params,
  });
};
// fetch video by Id
export const fetchVideoById = (videoId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/training-video/${videoId}`,
    method: "GET",
    params: params,
  });
};
// save video form

export const saveVideo = (payload) => {
  return API({
    url: "/training-video",
    method: "POST",
    data: payload,
  });
};

export const updateVideo = (videoId, payload) => {
  return API({
    url: `/training-video/${videoId}`,
    method: "PUT",
    data: payload,
  });
};

// delete video
export const deleteVideo = (videoId) => {
  return API({
    url: `/training-video/${videoId}`,
    method: "DELETE",
  });
};

// delete many video
export const deleteManyVideo = (params = {}, payload) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/training-video",
    method: "DELETE",
    params: params,
    data: payload,
  });
};

export const getObjectiveSgfTag = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/common-access/sgf`,
    method: "GET",
    params: params,
  });
};

export const updateTaxonomyIndustry = (industryId, payload) => {
  return API({
    url: `/taxonomy/industry/${industryId}`,
    method: "PUT",
    data: payload,
  });
};

// fetch genx config
export const fetchFontFamily = (params = {}) => {
  let { filters = undefined, fields = undefined, sort } = params;
  return API({
    url: `core/font-family`,
    method: "GET",
    params: params,
  });
};

//Fetch Content Types
export const getContentType = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/common-access/content-type`,
    method: "GET",
    params: params,
  });
};

//Add content Type
export const saveDeliverableType = (payload) => {
  return API({
    url: `/common-access/content-type`,
    method: "POST",
    data: payload,
  });
};

// fetch Content Tags
export const fetchContentTags = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/common-access/content-tags",
    method: "GET",
    params: params,
  });
};

// add Content Tags
export const addContentTags = (payload) => {
  return API({
    url: "/common-access/content-tags",
    method: "POST",
    data: payload,
  });
};

export const updateConfigData = (payload) => {
  return API({
    url: `core/config`,
    method: "PUT",
    data: payload,
  });
};
// fetch genx config
export const fetchConfig = (params = {}) => {
  let { filters = undefined, fields = undefined, sort } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  if (sort) params.sort = JSON.stringify(sort);
  return API({
    url: `core/config`,
    method: "GET",
    params: params,
  });
};

// fetch all widget data
export const fetchWidgetList = (params = {}) => {
  let { filters = undefined, fields = undefined, sort, limit } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  if (sort) params.sort = sort;
  if (limit) params.limit = JSON.stringify(limit);
  return API({
    url: "/core/content/all",
    method: "GET",
    params: params,
  });
};

export const getCombinedData = (params = {}) => {
  let { filters = undefined, fields = undefined, sort } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  if (sort) params.sort = JSON.stringify(sort);
  return API({
    url: "/core/content/all",
    method: "GET",
    params: params,
  });
};
