/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  News Listing Page
	 - Use common grid component for list
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 20/Aug/2020 
	Author						: Aditya Tijare
================================================================ 
*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProductVariant, fetchTechTrendData, getDateFromMongoDate } from "../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import CommonDataGrid from "../../components/dataGrid";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import {
  fetchNews,
  deleteNews,
  downloadFileUrl,
  exportJsonData,
  saveNewsTaxonomyTags,
  saveNewsKiaTags,
  saveValueChainKiaTags,
  saveNewsTrendTags,
  saveNewsMediaTags,
  saveNewsBusinessObjectiveTaxonomy,
} from "../../../middleware/services/newsApi";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { COMMON_ACCESS_ACTION, PRODUCT_VARIANT } from "../../../constants";
import ThreeDotMenu from "../../components/threeDotMenu";
import { setMaintainedState, visitRecord } from "../../../middleware/actions/gridAction";
import { resetRedux } from "../../../middleware/actions/taxonomyFilterAction";
import _ from "underscore";
import {
  setInitialCheckedKiaNodes,
  setInitialCheckedMediaNodes,
  setInitialCheckedObjectiveNodes,
  setInitialCheckedTechnologyNodes,
  setInitialCheckedTrendNodes,
  setInitialCheckedValueChainNodes,
  setKiaCheckedNodes,
  setKiaUnCheckedNodes,
  setMediaCheckedNodes,
  setMediaUnCheckedNodes,
  setObjectiveTaxonomyCheckedNodes,
  setObjectiveTaxonomyUnCheckedNodes,
  setResetRedux,
  setTaxonomyCheckedNodes,
  setTaxonomyUnCheckedNodes,
  setTransformingCheckedNodes,
  setTransformingUnCheckedNodes,
  setTrendCheckedNodes,
  setTrendUnCheckedNodes,
  setValueChainCheckedNodes,
  setValueChainUnCheckedNodes,
  setInitialCheckedSgfNodes,
} from "../../../middleware/actions/taxonomyTaggingAction";

export default (props) => {
  const { accessRights: aR } = props;
  const productVariant = getProductVariant();
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteId, setDeleteId] = useState(undefined);
  const [techTrendData, setTechTrendData] = useState(undefined);
  const taxonomyFilterRedux = useSelector((state) => state.taxonomyFilterState);
  const [filterData, setFilterData] = useState(taxonomyFilterRedux?.filteredTaxonomy ? taxonomyFilterRedux.filteredTaxonomy : []);
  const gridState = useSelector((state) => state.gridState);
  const [showTaxonomyBulk, setShowTaxonomyBulk] = useState(false);
  const [initalState, setInitialState] = useState([]);
  const [bulkCheck, setBulkCheck] = useState([]);
  const [valueChainData, setValueChainData] = useState([]);
  const [cssClass, setCssClass] = useState("treeClass");
  const [listClass, setListClass] = useState("select-lists");
  const [taxonomyState, setTaxonomyState] = useState([]);
  const [taxonomyTransformationState, setTaxonomyTransformationState] = useState([]);
  const [taxonomyTaggingTabName, setTaxonomyTaggingTabName] = useState(false);

  let moduleName = props.accessRights?.moduleName;
  const newsTaxonomy = useSelector((state) => state.taxonomyTaggingState);

  /* Common access */
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionExport = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EXPORT);
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionFilterByTaxonomy = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.FILTER_BY_TAXONOMY);
  const accessActionTaxonomyBulkTagging = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.TAXONOMY_BULK_TAGGING);

  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  /*
		@Description : List of news columns
	*/
  const newsCol = {
    id: "id",
    "News Title": "newsTitle",
    // "Technology": "newsTechnologyTags~dtParentId:0,dtName",
    // "Trend": "newsIndustryTrendsTags.trendName",
    newsType: "newsType",
    newsMarketingArticle: "newsMarketingArticle",
    publishDate: "newsPublishedDate",
    "Create Date": "newsCreatedDate",
    "Is Live": "newsIsLive",
  };
  if (productVariant) newsCol["Is Live"] = `productVariant~productName:${productVariant},isLive`;
  else newsCol["Is Live"] = "productVariant.isLive";

  if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) newsCol["Trend"] = "newsIndustryTrendsTags.trendId";
  else if (productVariant && productVariant === PRODUCT_VARIANT.WHATNEXT) newsCol["Technology"] = "newsTechnologyTags.dtId";
  else if (productVariant && productVariant === PRODUCT_VARIANT.COGNITION) newsCol["Industry"] = "newsTaxonomyMedia.taxonomyId";

  if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) newsCol["Buzzing Story"] = "newsIsBuzzingStory";

  const breadCrumbLinks = [{ linkUrl: "/news", linkName: aR.moduleName, linkActive: true }];
  // Reset Redux
  useEffect(() => {
    const locationPath = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];
    if (locationPath != `/news`) {
      dispatch(resetRedux());
      setFilterData([]);
      localStorage.setItem("locationPath", window.location.pathname);
    }
  }, []);

  const fetchTechData = async () => {
    setTechTrendData(await fetchTechTrendData(productVariant));
  };
  /* 
		@Description : Use for setting breadcrumb values
	*/

  useEffect(() => {
    localStorage.removeItem("selectedNews");
    if (!techTrendData) fetchTechData();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
      localStorage.removeItem("selectedNews");
    };
  }, [dispatch]);

  /* 
		@Description : Template Component for action column
	*/
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                dispatch(visitRecord());
                history.push(`/news/${value.id}/edit#basic`);
              },
            }
          : {})}
        {...(accessActionDelete
          ? {
              Delete: async () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteNews(value.id)
                        .then((response) => {
                          dispatch(actionSuccess("News deleted successfully"));
                          setDeleteId(value.id);
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Fail to delete news"));
                        });
                    },
                    okText: "Delete",
                    cancelText: "Cancel",
                    content: "Are you sure you want to delete ?",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
      />
    );
  };
  /* 
		@Description : Template Component for news title
	*/
  const newsTitleTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          dispatch(visitRecord());
          history.push(`/news/${value.id}/edit#basic`);
        }}
      >
        {value["News Title"]}
      </span>
    );
  };
  /* 
		@Description : Template Component for news type Insights
	*/
  const newsTypeTemplate = (value) => {
    return <span className="span-link">{value["newsType"]}</span>;
  };
  /* 
		@Description : Template Component for Date col
	*/
  const newsDateTemplate = (value) => {
    const column = value.column.field;
    return ["Create Date", "publishDate"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
      <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
    ) : (
      ""
    );
  };

  // Dropdown Filter template
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Is Live":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "newsType":
        if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) {
          sourceData = [
            { id: "News", text: "News" },
            { id: "Articles", text: "Articles" },
            { id: "YES", text: "Insights (Marketing Article)" },
          ];
        } else {
          sourceData = [
            { id: "News", text: "News" },
            { id: "Article", text: "Article" },
          ];
        }
        break;
      case "Technology":
      case "Trend":
      case "Industry":
        sourceData = techTrendData;
        break;
      case "Buzzing Story":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  /* 
		@Description : Sending CcolumnFields to Grid
	*/
  let columnFields = [
    {
      field: "id",
      type: "String",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "News Title",
      type: "String",
      template: newsTitleTemplate,
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      textAlign: "Left",
      width: 350,
    },
  ];
  if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) {
    columnFields = [
      ...columnFields,
      {
        field: "Trend",
        type: "String",
        filterTemplate: ItemFilter,
        filter: { operator: "contains" },
        textAlign: "Left",
        width: 250,
      },
    ];
  } else if (productVariant && productVariant === PRODUCT_VARIANT.WHATNEXT) {
    columnFields = [
      ...columnFields,
      {
        field: "Technology",
        type: "String",
        filterTemplate: ItemFilter,
        filter: { operator: "contains" },
        textAlign: "Left",
        width: 250,
      },
    ];
  } else if (productVariant && productVariant === PRODUCT_VARIANT.COGNITION) {
    columnFields = [
      ...columnFields,
      {
        field: "Industry",
        type: "String",
        filterTemplate: ItemFilter,
        filter: { operator: "contains" },
        textAlign: "Left",
        width: 250,
      },
    ];
  }
  columnFields = [
    ...columnFields,
    {
      field: "Create Date",
      type: "Date",
      template: newsDateTemplate,
      width: 160,
      textAlign: "Center",
    },
    {
      field: "newsType",
      headerText: "News Type",
      type: "String",
      template: newsTypeTemplate,
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Left",
      width: 170,
    },
    {
      field: "publishDate",
      headerText: "Published Date",
      type: "Date",
      template: newsDateTemplate,
      width: 165,
      textAlign: "Center",
      useRange: true,
    },
    {
      field: "Is Live",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 125,
    },
    productVariant &&
      productVariant === PRODUCT_VARIANT.INSIDER && {
        field: "Buzzing Story",
        type: "String",
        filterTemplate: ItemFilter,
        filter: { operator: "equal" },
        textAlign: "Left",
        width: 150,
      },
  ];

  /* 
		@Description : Check permission for access column
	*/

  if (accessActionEdit || accessActionDelete) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Left",
    });
    columnFields.push({
      field: "Action",
      type: "String",
      width: 65,
      template: actionTemplate,
      allowFiltering: false,
      allowSorting: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];

    rowData.forEach((newsData) => {
      let data = {};

      data.id = newsData.id;
      data["News Title"] = newsData.newsTitle;
      if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) {
        data.Trend = newsData.newsIndustryTrendsTags
          .map((trend) => {
            if (trend.trendParentId != 0 && trend.trendRoot != 0 && trend.trendSubRoot == 0 && !trend.trendIsSgf) {
              return trend.trendName;
            }
            return "";
          })
          .filter((trend) => trend != "")
          .join(", ");
      } else if (productVariant && productVariant === PRODUCT_VARIANT.WHATNEXT) {
        data.Technology = newsData.newsTechnologyTags
          .map((tech) => {
            if (tech.dtParentId == 0 && tech.dtRoot == 0) {
              return tech.dtName;
            }
            return "";
          })
          .filter((tech) => tech != "")
          .join(", ");
      } else if (productVariant && productVariant === PRODUCT_VARIANT.COGNITION) {
        data.Industry = newsData.newsTaxonomyMedia
          .map((item) => {
            if (item.taxonomyParentId == 0 && item.taxonomyRoot == 0) {
              return item.taxonomyName;
            }
            return "";
          })
          .filter((item) => item != "")
          .join(", ");
      }
      data["Create Date"] = new Date(newsData.newsCreatedDate);
      data["publishDate"] = (newsData.newsPublishedDate && new Date(newsData.newsPublishedDate)) || "";
      data["newsType"] = newsData.newsMarketingArticle === "YES" ? "Insights (Marketing Article)" : newsData.newsType;
      data["newsMarketingArticle"] = newsData.newsMarketingArticle && newsData.newsMarketingArticle;
      if (newsData.productVariant?.length) {
        if (productVariant) {
          const rDPV = newsData.productVariant.find((pV) => pV.productName === productVariant);
          data["Is Live"] = rDPV?.isLive ? rDPV.isLive : "NO";
        } else data["Is Live"] = newsData.productVariant[0].isLive ? newsData.productVariant[0].isLive : "NO";
      } else data["Is Live"] = "NO";

      if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) {
        data["Buzzing Story"] = newsData?.newsIsBuzzingStory ? newsData.newsIsBuzzingStory : "NO";
      }
      formatedRowData.push(data);
    });
    return formatedRowData;
  };

  const fetchNewsWithProductVariant = (params = {}) => {
    let defaultFilter = [];
    let { filters = [] } = params;
    if (!params.sort) {
      params.sort = "newsPublishedDate:desc";
    }
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let userCompetencyIndustry = localStorage.getItem("userCompetencyIndustry") !== "null" ? localStorage.getItem("userCompetencyIndustry") : "null";
    let trendFiltering = false;
    if (params.filters) {
      filters = params.filters.map((item) => {
        /**True-false is trend filter is selected */
        if (item.includes("newsIndustryTrendsTags.trendId")) trendFiltering = item.includes("newsIndustryTrendsTags.trendId");
        /** */
        const tech = techTrendData.filter((f) => {
          return (
            ["newsIndustryTrendsTags.trendId", "newsTechnologyTags.dtId", "newsTaxonomyMedia.taxonomyId"].includes(item[0]) && f.text === item[2]
          );
        });
        if (productVariant === PRODUCT_VARIANT.INSIDER) {
          return item[0] === "newsIndustryTrendsTags.trendId" ? [item[0], "in", [tech[0].id]] : item;
        } else if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
          return item[0] === "newsTechnologyTags.dtId" ? [item[0], "in", [tech[0].id]] : item;
        } else if (productVariant === PRODUCT_VARIANT.COGNITION) {
          return item[0] === "newsTaxonomyMedia.taxonomyId" ? [item[0], "in", [tech[0].id]] : item;
        }
      });
    }
    if (!trendFiltering && userCompetencyIndustry !== "null" && userCompetencyIndustry !== undefined) {
      userCompetencyIndustry = JSON.parse(userCompetencyIndustry);
      const industryId = userCompetencyIndustry?.map((obj) => obj.id);
      productVariant === PRODUCT_VARIANT.INSIDER &&
        userCompetencyIndustry?.length > 0 &&
        defaultFilter.push(["newsIndustryTrendsTags.trendId", "in", industryId]);
    }
    params.filters = [...filters, ...defaultFilter];
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchNews(params);
    }
    if (Object.keys(filterData).length > 0) {
      let technologyFilter = Object.keys(filterData?.technology)?.length > 0 ? ["newsTechnologyTags.dtId", "in", filterData.technology] : [];
      let kiaFilter = Object.keys(filterData?.kia)?.length > 0 ? ["newsKiaTags.kiaId", "in", filterData.kia] : [];
      let valueChainFilter =
        Object.keys(filterData?.valueChain)?.length > 0 ? ["newsIndustryValuechainTags.valueChainId", "in", filterData.valueChain] : [];
      let mediaFilter = Object.keys(filterData?.media)?.length > 0 ? ["newsTaxonomyMedia.taxonomyId", "in", filterData.media] : [];
      let boTaxonomy = filterData?.boTaxonomy?.length > 0 ? ["newsObjectiveTaxonomy.taxonomyId", "in", filterData.boTaxonomy] : [];
      let businessObjective =
        filterData?.businessObjective?.length > 0 ? ["newsBusinessObjective.objectiveId", "in", filterData.businessObjective] : [];
      let trendfilter = filterData?.trend?.length > 0 ? ["newsIndustryTrendsTags.trendId", "in", filterData.trend] : [];

      params.filters = [...filters, ...defaultFilter];
      technologyFilter.length > 0 && params.filters.push(technologyFilter);
      kiaFilter.length > 0 && params.filters.push(kiaFilter);
      valueChainFilter.length > 0 && params.filters.push(valueChainFilter);
      mediaFilter.length > 0 && params.filters.push(mediaFilter);
      businessObjective.length > 0 && params.filters.push(businessObjective);
      boTaxonomy.length > 0 && params.filters.push(boTaxonomy);
      trendfilter.length > 0 && params.filters.push(trendfilter);
    }

    return fetchNews(params);
  };
  // Export News data
  const exportData = async (flag, ids) => {
    if (flag) {
      const payload = {
        reqIds: JSON.stringify(ids),
        productVariant: productVariant,
      };

      await exportJsonData(payload)
        .then((res) => {
          const link = document.createElement("a");
          link.href = downloadFileUrl(`${res.data.data}`);
          link.download = `${res.data.data}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    }
  };

  const objectiveTaxonomyTag = async () => {
    dispatch(visitRecord());
    const selectedNews = localStorage.getItem("selectedNews") ? JSON.parse(localStorage.getItem("selectedNews")) : [];
    if (selectedNews.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedNews],
      ];
      params.fields = ["productVariant"];
      dispatch(showLoader());
      const companyIsLive = await fetchNews(params);
      dispatch(hideLoader());
      if (companyIsLive.data) {
        let nonLiveComp = companyIsLive.data.data.filter((comp) => {
          let isLIveComp = true;
          comp.productVariant.forEach((prod) => {
            if (prod.productName == productVariant && prod.isLive == "YES") {
              isLIveComp = false;
            }
          });
          return isLIveComp;
        });
        if (nonLiveComp.length) {
          dispatch(actionError("Please select only live news."));
        } else {
          history.push(`/business-objective/taxonomy/news`, {
            newsIds: selectedNews,
            entityName: "news",
          });
        }
      }
    } else {
      dispatch(actionError("Please select news."));
    }
  };

  /* 
		@Description : Bulk tagging technology tab
	*/
  const handleSubmitTechnology = async (fetchGridData) => {
    let payload = {
      newsIds: initalState,
      taxonomyTagging: newsTaxonomy.taxonomyCheckedNodes,
      taxonomyUnTagging: newsTaxonomy.taxonomyUnCheckedNodes,
    };

    if (newsTaxonomy?.initialTechnologyCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(showLoader());
                saveNewsTaxonomyTags(payload)
                  .then((result) => {
                    dispatch(actionSuccess("Taxonomy updated successfully"));
                    dispatch(setResetRedux());
                    dispatch(hideLoader());
                    setShowTaxonomyBulk(false);
                    fetchGridData();
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    dispatch(actionError("Please select news"));
                  });
              });
            },
            okText: "Submit",
            cancelText: "Cancel",
            content: "Are you sure you want to update the tagging?",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  };

  const fetchTechnologyTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedNewss = localStorage.getItem("selectedNews") ? JSON.parse(localStorage.getItem("selectedNews")) : [];
    if (selectedNewss.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedNewss],
      ];
      params.fields = ["newsTechnologyTags"];
      const contentTaxonomy = fetchNews(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.newsTechnologyTags.length > 0 &&
              value.newsTechnologyTags.map((v) => {
                checkNodes.push(v.dtId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });
            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedNewss.length == d[1]) {
                commonIds.push(d[0]);
              }
            });
            setBulkCheck([...newsTaxonomy.taxonomyCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTaxonomyCheckedNodes([...newsTaxonomy.taxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const fetchValuchainData = (data, selectedNews) => {
    if (data.length) {
      let valueChainIds = [];
      let commanIds = [];
      let taxonomyTee = [];
      data.map((value) => {
        value.newsIndustryTag.length > 0 &&
          value.newsIndustryTag.map((item) => {
            if (!valueChainIds.includes(item.industryId)) {
              let taxonomyTeeData = {};
              taxonomyTeeData["id"] = item.industryId;
              taxonomyTeeData["name"] = item.industryName;
              taxonomyTee.push(taxonomyTeeData);
            }
            valueChainIds.push(item.industryId);
          });
      });
      let count = _.countBy(valueChainIds);
      let countarray = Object.entries(count);
      countarray.map((d) => {
        if (selectedNews.length == d[1]) {
          commanIds.push(d[0]);
        }
      });
      let valueChainData = _.filter(taxonomyTee, function (element) {
        return _.find(commanIds, function (name) {
          return element.id === name;
        });
      });
      setValueChainData(valueChainData);
    }
  };

  const taxonomyBulkTagging = async () => {
    dispatch(visitRecord());
    const selectedNews = localStorage.getItem("selectedNews") ? JSON.parse(localStorage.getItem("selectedNews")) : [];
    if (selectedNews.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedNews],
      ];
      params.fields = ["newsIndustryTag"];
      const companyIsLive = await fetchNews(params);
      dispatch(hideLoader());
      if (companyIsLive) {
        dispatch(showLoader());
        setInitialState(selectedNews);
        setShowTaxonomyBulk(true);
        companyIsLive?.data?.data && fetchValuchainData(companyIsLive.data.data, selectedNews);
      }
    } else {
      dispatch(actionError("Please select news."));
    }
  };

  const addTaxonomyCheckedNodes = (id) => {
    let taxonomyCheck = newsTaxonomy.taxonomyCheckedNodes;
    let taxonomyUnCheck = newsTaxonomy.taxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = newsTaxonomy.initialTechnologyCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTechnologyNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTechnologyNodes([...newsTaxonomy.initialTechnologyCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTaxonomyUnCheckedNodes([...newsTaxonomy.taxonomyUnCheckedNodes, id]));
      }
      return;
    }

    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTaxonomyCheckedNodes([...newsTaxonomy.taxonomyCheckedNodes, id]));
  };

  /* 
		@Description : Bulk tagging for kia
	*/
  const handleSubmitKia = async (fetchGridData) => {
    let payload = {
      newsIds: initalState,
      taxonomyTagging: newsTaxonomy.kiaCheckedNodes,
      taxonomyUnTagging: newsTaxonomy.kiaUnCheckedNodes,
    };

    if (newsTaxonomy?.initialKiaCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      try {
        dispatch(
          showAlertBox({
            okCallback: () => {
              process.nextTick(() => {
                dispatch(showLoader());
                saveNewsKiaTags(payload)
                  .then((result) => {
                    dispatch(actionSuccess("Taxonomy updated successfully"));
                    dispatch(setResetRedux());
                    setShowTaxonomyBulk(false);
                    dispatch(hideLoader());
                    fetchGridData();
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    dispatch(actionError("Please select news"));
                  });
              });
            },
            okText: "Submit",
            cancelText: "Cancel",
            content: "Are you sure you want to update the tagging?",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  };

  const fetchKiaTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedNewss = localStorage.getItem("selectedNews") ? JSON.parse(localStorage.getItem("selectedNews")) : [];
    if (selectedNewss.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedNewss],
      ];
      params.fields = ["newsKiaTags"];
      const contentTaxonomy = fetchNews(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.newsKiaTags.length > 0 &&
              value.newsKiaTags.map((v) => {
                checkNodes.push(v.kiaId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedNewss.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            commonIds.map((element) => {
              value.newsKiaTags.length > 0 &&
                value.newsKiaTags.map((v) => {
                  if (v.kiaId === element) {
                    commonIds.push(v.kiaIndustryId);
                  }
                });
            });
            commonIds = [...new Set(commonIds)];
            setBulkCheck([...newsTaxonomy.kiaCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setKiaCheckedNodes([...newsTaxonomy.kiaCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addKiaCheckedNodes = (id) => {
    let taxonomyCheck = newsTaxonomy.kiaCheckedNodes;
    let taxonomyUnCheck = newsTaxonomy.kiaUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = newsTaxonomy.initialKiaCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedKiaNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedKiaNodes([...newsTaxonomy.initialKiaCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setKiaCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setKiaUnCheckedNodes([...newsTaxonomy.kiaUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setKiaUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setKiaCheckedNodes([...newsTaxonomy.kiaCheckedNodes, id]));
  };

  /* 
		@Description : Bulk tagging Value chain
	*/
  const handleSubmitValueChain = async (fetchGridData) => {
    let payload = {
      newsIds: initalState,
      taxonomyTagging: newsTaxonomy.valueChainCheckedNodes,
      taxonomyUnTagging: newsTaxonomy.valueChainUnCheckedNodes,
      transformingTechnologiesIds: newsTaxonomy.transformingCheckedNodes,
      transformingUnCheckedTechnologiesIds: newsTaxonomy.transformingUnCheckedNodes,
    };

    if (newsTaxonomy?.initialValueChainCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      try {
        dispatch(
          showAlertBox({
            okCallback: () => {
              process.nextTick(() => {
                dispatch(showLoader());
                saveValueChainKiaTags(payload)
                  .then((result) => {
                    dispatch(actionSuccess("Taxonomy updated successfully"));
                    dispatch(setResetRedux());
                    setShowTaxonomyBulk(false);
                    dispatch(hideLoader());
                    fetchGridData();
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    dispatch(actionError("Please select news"));
                  });
              });
            },
            okText: "Submit",
            cancelText: "Cancel",
            content: "Are you sure you want to update the tagging?",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  };

  const fetchValueChainTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedNewss = localStorage.getItem("selectedNews") ? JSON.parse(localStorage.getItem("selectedNews")) : [];
    if (selectedNewss.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedNewss],
      ];
      params.fields = ["newsIndustryValuechainTags"];
      const contentTaxonomy = fetchNews(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let checkNodes2 = [];
          let count;
          let count2;
          res.data.data.map((value) => {
            value.newsIndustryValuechainTags.length > 0 &&
              value.newsIndustryValuechainTags.map((v) => {
                checkNodes.push(v.valueChainId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }

                // transformingTechnologies id
                v.transformingTechnologies &&
                  v.transformingTechnologies.map((ele) => {
                    checkNodes2.push(ele.techId);
                    count2 = {};
                    for (const element of checkNodes2) {
                      if (count2[element]) {
                        count2[element] += 1;
                      } else {
                        count2[element] = 1;
                      }
                    }
                  });
              });
            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedNewss.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            let transformingtechnology = count2 && Object.entries(count2);
            let transformingtechnologyCommanId = [];
            transformingtechnology &&
              transformingtechnology.map((d) => {
                if (selectedNewss.length == d[1]) {
                  transformingtechnologyCommanId.push(d[0]);
                }
              });

            setBulkCheck([
              ...newsTaxonomy.valueChainCheckedNodes,
              ...commonIds,
              ...newsTaxonomy.transformingCheckedNodes,
              ...transformingtechnologyCommanId,
            ]);
            dispatch(setValueChainCheckedNodes([...newsTaxonomy.valueChainCheckedNodes, ...commonIds]));
            setTaxonomyState([...commonIds]);
            setTaxonomyTransformationState([...transformingtechnologyCommanId]);
            dispatch(setTransformingCheckedNodes([...newsTaxonomy.transformingCheckedNodes, ...transformingtechnologyCommanId]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addValueChainCheckedNodes = (id) => {
    let taxonomyCheck = newsTaxonomy.valueChainCheckedNodes;
    let taxonomyUnCheck = newsTaxonomy.valueChainUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = newsTaxonomy.initialValueChainCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedValueChainNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedValueChainNodes([...newsTaxonomy.initialValueChainCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setValueChainCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setValueChainUnCheckedNodes([...newsTaxonomy.valueChainUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setValueChainUnCheckedNodes(taxonomyUnCheck));
    }

    dispatch(setValueChainCheckedNodes([...newsTaxonomy.valueChainCheckedNodes, id]));
  };

  const addValueChainTransformingCheckedNodes = (id) => {
    let taxonomyCheck = newsTaxonomy.transformingCheckedNodes;
    let taxonomyUnCheck = newsTaxonomy.transformingUnCheckedNodes;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTransformingCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTransformingUnCheckedNodes([...newsTaxonomy.transformingUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTransformingUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTransformingCheckedNodes([...newsTaxonomy.transformingCheckedNodes, id]));
  };

  /* 
		@Description : Bulk tagging trend sgf - Insider
	*/
  const handleSubmitTrend = async (fetchGridData) => {
    let payload = {
      newsIds: initalState,
      taxonomyTagging: newsTaxonomy.trendCheckedNodes,
      taxonomyUnTagging: newsTaxonomy.trendUnCheckedNodes,
      sgfIds: newsTaxonomy.initialSgfCheckedNodes,
    };

    if (newsTaxonomy?.initialTrendCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(showLoader());
                saveNewsTrendTags(payload)
                  .then((result) => {
                    dispatch(actionSuccess("Taxonomy updated successfully"));
                    dispatch(setResetRedux());
                    setShowTaxonomyBulk(false);
                    dispatch(hideLoader());
                    fetchGridData();
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    dispatch(actionError("Please select news"));
                  });
              });
            },
            okText: "Submit",
            cancelText: "Cancel",
            content: "Are you sure you want to update the tagging?",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  };

  const fetchTrendTagging = () => {
    setCssClass("treeClass trend");
    setListClass("select-lists bulktagging");
    const selectedNewss = localStorage.getItem("selectedNews") ? JSON.parse(localStorage.getItem("selectedNews")) : [];
    if (selectedNewss.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedNewss],
      ];
      params.fields = ["newsIndustryTrendsTags"];
      const contentTaxonomy = fetchNews(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.newsIndustryTrendsTags.length > 0 &&
              value.newsIndustryTrendsTags.map((v) => {
                checkNodes.push(v.trendId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });
            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedNewss.length == d[1]) {
                commonIds.push(d[0]);
              }
            });
            setBulkCheck([...newsTaxonomy.trendCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTrendCheckedNodes([...newsTaxonomy.trendCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addTrendCheckedNodes = (taxonomyId) => {
    let id = taxonomyId.split("-")[1];
    let sgfId = taxonomyId.split("-")[0];
    let taxonomyCheck = newsTaxonomy.trendCheckedNodes;
    let taxonomyUnCheck = newsTaxonomy.trendUnCheckedNodes;
    // for initial nodes
    let initialCheckedNodes = newsTaxonomy.initialTrendCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTrendNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTrendNodes([...newsTaxonomy.initialTrendCheckedNodes, id]));

    // set checked sgf node
    dispatch(setInitialCheckedSgfNodes([...newsTaxonomy.initialSgfCheckedNodes, sgfId]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTrendCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTrendUnCheckedNodes([...newsTaxonomy.trendUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTrendUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTrendCheckedNodes([...newsTaxonomy.trendCheckedNodes, id]));
  };

  /* 
		@Description : Bulk tagging media 
	*/
  const handleSubmitMedia = async (fetchGridData) => {
    let payload = {
      newsIds: initalState,
      taxonomyTagging: newsTaxonomy.mediaCheckedNodes,
      taxonomyUnTagging: newsTaxonomy.mediaUnCheckedNodes,
    };

    if (newsTaxonomy?.initialMediaCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(showLoader());
                saveNewsMediaTags(payload)
                  .then((result) => {
                    dispatch(actionSuccess("Taxonomy updated successfully"));
                    dispatch(setResetRedux());
                    setShowTaxonomyBulk(false);
                    dispatch(hideLoader());
                    fetchGridData();
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    dispatch(actionError("Please select news"));
                  });
              });
            },
            okText: "Submit",
            cancelText: "Cancel",
            content: "Are you sure you want to update the tagging?",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  };

  const fetchMediaTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedNewss = localStorage.getItem("selectedNews") ? JSON.parse(localStorage.getItem("selectedNews")) : [];
    if (selectedNewss.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedNewss],
      ];
      params.fields = ["newsTaxonomyMedia"];
      const contentTaxonomy = fetchNews(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.newsTaxonomyMedia.length > 0 &&
              value.newsTaxonomyMedia.map((v) => {
                checkNodes.push(v.taxonomyId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });
            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedNewss.length == d[1]) {
                commonIds.push(d[0]);
              }
            });
            setBulkCheck([...newsTaxonomy.mediaCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setMediaCheckedNodes([...newsTaxonomy.mediaCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addMediaCheckedNodes = (id) => {
    let taxonomyCheck = newsTaxonomy.mediaCheckedNodes;
    let taxonomyUnCheck = newsTaxonomy.mediaUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = newsTaxonomy.initialMediaCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedMediaNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedMediaNodes([...newsTaxonomy.initialMediaCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setMediaCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setMediaUnCheckedNodes([...newsTaxonomy.mediaUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setMediaUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setMediaCheckedNodes([...newsTaxonomy.mediaCheckedNodes, id]));
  };

  /* 
		@Description : Bulk tagging for business objective
	*/
  const handleSubmitObjective = async (fetchGridData) => {
    let taxonomy = {
      newsIds: initalState,
      newsObjectiveTaxonomy: newsTaxonomy.objectiveTaxonomyCheckedNodes,
      newsUnCheckObjectiveTaxonomy: newsTaxonomy.objectiveTaxonomyUnCheckedNodes,
    };

    if (newsTaxonomy?.initialObjectiveCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      try {
        dispatch(
          showAlertBox({
            okCallback: () => {
              process.nextTick(() => {
                dispatch(showLoader());
                saveNewsBusinessObjectiveTaxonomy(taxonomy)
                  .then((result) => {
                    dispatch(actionSuccess("Taxonomy updated successfully"));
                    dispatch(setResetRedux());
                    setShowTaxonomyBulk(false);
                    dispatch(hideLoader());
                    fetchGridData();
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    dispatch(actionError("Please select news"));
                  });
              });
            },
            okText: "Submit",
            cancelText: "Cancel",
            content: "Are you sure you want to update the tagging?",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  };

  const fetchObjectiveTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedNewss = localStorage.getItem("selectedNews") ? JSON.parse(localStorage.getItem("selectedNews")) : [];
    if (selectedNewss.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedNewss],
      ];
      params.fields = ["newsObjectiveTaxonomy"];
      const contentTaxonomy = fetchNews(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.newsObjectiveTaxonomy?.length > 0 &&
              value.newsObjectiveTaxonomy.map((v) => {
                checkNodes.push(v.taxonomyId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedNewss.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            setBulkCheck([...newsTaxonomy.objectiveTaxonomyCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setObjectiveTaxonomyCheckedNodes([...newsTaxonomy.objectiveTaxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addObjectiveCheckedNodes = (id) => {
    let taxonomyCheck = newsTaxonomy.objectiveTaxonomyCheckedNodes;
    let taxonomyUnCheck = newsTaxonomy.objectiveTaxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = newsTaxonomy.initialObjectiveCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedObjectiveNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedObjectiveNodes([...newsTaxonomy.initialObjectiveCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setObjectiveTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setObjectiveTaxonomyUnCheckedNodes([...newsTaxonomy.objectiveTaxonomyUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setObjectiveTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setObjectiveTaxonomyCheckedNodes([...newsTaxonomy.objectiveTaxonomyCheckedNodes, id]));
  };
  //
  let tabNames;
  if (productVariant == PRODUCT_VARIANT.INSIDER) {
    tabNames = taxonomyTaggingTabName ? ["Tag Industry"] : ["By Industry"];
  } else if (productVariant == PRODUCT_VARIANT.COGNITION) {
    tabNames = taxonomyTaggingTabName ? ["Tag Media", "Tag Business Objective"] : ["By Media", "By Business Objective"];
  } else {
    tabNames = taxonomyTaggingTabName
      ? ["Tag Technology", "Tag Key Impact Area", "Tag Value Chain", "Tag Business Objective"]
      : ["By Technology", "By Key Impact Area", "By Value Chain", "By Business Objective"];
  }

  // handle save for taxonomy bulk tagging
  const handleSave = (selectedIndex, fetchGridData) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        handleSubmitTechnology(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitKia(fetchGridData);
      }
      if (selectedIndex == 2) {
        handleSubmitValueChain(fetchGridData);
      }
      if (selectedIndex == 3) {
        handleSubmitObjective(fetchGridData);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        handleSubmitTrend(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitObjective(fetchGridData);
      }
    }
    if (productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        handleSubmitMedia(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitObjective(fetchGridData);
      }
    }
  };

  const handleTaxonomyTagging = (args, selectedIndex) => {
    let valueId = args?.data[0].id;
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        addTaxonomyCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addKiaCheckedNodes(valueId);
      }
      if (selectedIndex == 2) {
        handleValueChainValidation(args);
      }
      if (selectedIndex == 3) {
        addObjectiveCheckedNodes(valueId);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        addTrendCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addObjectiveCheckedNodes(valueId);
      }
    }
    if (productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        addMediaCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addObjectiveCheckedNodes(valueId);
      }
    }
  };

  const handleValueChainValidation = (args) => {
    if (args?.data[0]?.parentID == null) {
      addValueChainCheckedNodes(args?.data[0]?.id);
    }
    if (args?.data[0]?.parentID !== null) {
      addValueChainTransformingCheckedNodes(args?.data[0]?.id);
    }
  };

  // fetch TaxonomyTagging data for taxonomy bluk tagging
  const fetchTaxonomyTagging = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        fetchTechnologyTagging();
      }
      if (selectedIndex == 1) {
        fetchKiaTagging();
      }
      if (selectedIndex == 2) {
        fetchValueChainTagging();
      }
      if (selectedIndex == 3) {
        fetchObjectiveTagging();
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        fetchTrendTagging();
      }
      if (selectedIndex == 1) {
        fetchObjectiveTagging();
      }
    }
    if (productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        fetchMediaTagging();
      }
      if (selectedIndex == 1) {
        fetchObjectiveTagging();
      }
    }
  };

  const setBulkCheckedNode = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      selectedIndex == 0 && setBulkCheck([...newsTaxonomy.taxonomyCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...newsTaxonomy.kiaCheckedNodes]);
      selectedIndex == 2 && setBulkCheck([...newsTaxonomy.valueChainCheckedNodes, ...newsTaxonomy.transformingCheckedNodes]);
      selectedIndex == 3 && setBulkCheck([...newsTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      selectedIndex == 0 && setBulkCheck([...newsTaxonomy.trendCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...newsTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
    if (productVariant === PRODUCT_VARIANT.COGNITION) {
      selectedIndex == 0 && setBulkCheck([...newsTaxonomy.mediaCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...newsTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
  };

  const resetAllTaxonnomy = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        dispatch(setTaxonomyCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setKiaCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 2) {
        dispatch(setValueChainCheckedNodes([...taxonomyState]));
        dispatch(setTransformingCheckedNodes([...taxonomyTransformationState]));
      }
      if (selectedIndex == 3) {
        dispatch(setObjectiveTaxonomyCheckedNodes([...taxonomyState]));
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER || productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        dispatch(setTrendCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setObjectiveTaxonomyCheckedNodes([...taxonomyState]));
      }
    }
    setBulkCheck([...taxonomyState]);
  };

  return (
    <div className="gennx-content-wrapper content-wrapper  px-3">
      <div className="gennx-grid-container">
        {techTrendData && (
          <CommonDataGrid
            gridTitle="News"
            fetch={fetchNewsWithProductVariant}
            deleteOne={() => null}
            deleteMany={false}
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            columnNames={newsCol}
            columnFields={columnFields}
            formatRowData={formatRowData}
            deleteId={deleteId}
            getMultiSelectedRows={(data) => {
              localStorage.setItem("selectedNews", JSON.stringify([...data]));
            }}
            getMultiDeSelectedRows={(data) => {
              localStorage.setItem("selectedNews", JSON.stringify([...data]));
            }}
            isExport={exportData}
            productVariant={productVariant}
            exportRight={accessActionExport}
            objectiveTaxonomyRight={accessActionTaxonomyBulkTagging}
            objectiveTaxonomyTag={objectiveTaxonomyTag}
            addNewRight={accessActionListingAddNew}
            addNew={() => history.push(`news/add#basic`)}
            setFilterData={setFilterData}
            filterData={filterData}
            moduleName={moduleName}
            headerText="By Media"
            showFilterByMedia={(productVariant == PRODUCT_VARIANT.COGNITION ? true : false) && accessActionFilterByTaxonomy}
            showFilterByTaxonomy={(productVariant !== PRODUCT_VARIANT.CHEERSIN ? true : false) && accessActionFilterByTaxonomy}
            boTagging={true}
            //Bulk tagging
            showTaxonomyBulk={showTaxonomyBulk}
            setShowTaxonomyBulk={setShowTaxonomyBulk}
            setBulkCheck={setBulkCheck}
            checked={bulkCheck}
            taxonomyBulkTagging={taxonomyBulkTagging}
            setCssClass={setCssClass}
            setListClass={setListClass}
            cssClass={cssClass}
            listClass={listClass}
            tabName={tabNames}
            handleTaxonomyTabName={setTaxonomyTaggingTabName}
            customDialogBoxClass="dialoagBoxClass"
            customArrowClass="arrow-top"
            bulkTaggingArrowTop="bulkTaggingArrowTop"
            handleSubmit={handleSave}
            handleTaxonomyTagging={handleTaxonomyTagging}
            fetchTaxonomyTagging={fetchTaxonomyTagging}
            fetchObjectiveTagging={fetchObjectiveTagging}
            setBulkCheckedNode={setBulkCheckedNode}
            resetAllTaxonnomy={resetAllTaxonnomy}
            valueChainData={valueChainData}
            accessPermissionBulk={accessActionTaxonomyBulkTagging}
          />
        )}
      </div>
    </div>
  );
};
