import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import CustomerBusinessObjective from "../app/views/businessObjective/customerBusinessObjective";
import AddCustomerBusinessObjective from "../app/views/businessObjective/customerBusinessObjective/add";
import BusinessObjectiveTaxonomy from "../app/views/businessObjective/boTaxonomy";
import PartnerBusinessObjective from "../app/views/businessObjective/partnerBusinessObjective";

const OVERVIEW = ["Overview:NO", "Overview:VIEW", "Overview:FULL"];
const CONTENT = ["Content:NO", "Content:VIEW", "Content:FULL"];
const TAXONOMY = ["Taxonomy:NO", "Taxonomy:VIEW", "Taxonomy:FULL"];

const BusinessObjectiveRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/business-objective/customer"
      component={CustomerBusinessObjective}
      pageHeading="GenNx CMS - Business Objective"
      accessRights={{
        moduleName: "Business Objective",
        parentModuleName: "",
        moduleAttribute: [...OVERVIEW, ...CONTENT, ...TAXONOMY],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/business-objective/customer/add"
      component={AddCustomerBusinessObjective}
      pageHeading="GenNx CMS - Business Objective"
      accessRights={{
        moduleName: "Business Objective",
        parentModuleName: "",
        moduleAttribute: [...OVERVIEW, ...CONTENT, ...TAXONOMY],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/business-objective/customer/:objectiveId/edit"
      component={AddCustomerBusinessObjective}
      pageHeading="GenNx CMS - Business Objective"
      accessRights={{
        moduleName: "Business Objective",
        parentModuleName: "",
        moduleAttribute: [...OVERVIEW, ...CONTENT, ...TAXONOMY],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/business-objective/taxonomy/:entityId"
      component={BusinessObjectiveTaxonomy}
      pageHeading="GenNx CMS - Business Objective - Taxonomy"
      accessRights={{
        moduleName: "Business Objective",
        parentModuleName: "",
        moduleAttribute: [...OVERVIEW, ...CONTENT, ...TAXONOMY],
        interfaceName: "Basic",
      }}
    />
    {/* <AuthLayout exact path="/business-objective/partner" component={PartnerBusinessObjective} pageHeading="GenNx CMS - Business Objective"
            accessRights={{
                moduleName: "Business Objective", parentModuleName: "",
                moduleAttribute: [...OVERVIEW, ...TECHNICAL_SPECIFICATION, ...VALUE_PROPOSITION, ...LICENSING, ...TAXONOMY],
                interfaceName: "Listing"
            }} /> */}
  </Switch>
);

export default BusinessObjectiveRoutes;
