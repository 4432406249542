/*================================================================
‘Copyright © 2024, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  Video Tagging Component 
     Summary : - Parent video tagging component 
               - Inherit taxonomy and trend component as a child 
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 12/June/2024 
    Author						: Satya Prakash Mall
================================================================ 
*/
import React from "react";
import Taxonomy from "./taxonomy";
import Trend from "./trend";
import { getProductVariant } from "../../../../../utilities";
import { PRODUCT_VARIANT } from "../../../../../constants";

const Tagging = (props) => {
  const videoId = props.videoId;
  const loadOtherTagInfo = (info) => {
    console.log(info);
    // setLoadTaggingInfo(info)
  };
  if (videoId == "") {
    return null;
  }
  const productVariant = getProductVariant();
  if (!productVariant || productVariant == "") return null;
  return (
    <div className="gennx-content-wrapper" style={{ overflowY: "scroll", width: "100%" }}>
      {productVariant === PRODUCT_VARIANT.INSIDER && <Trend {...props} loadOtherTagInfo={loadOtherTagInfo} />}
      {productVariant === PRODUCT_VARIANT.WHATNEXT && <Taxonomy {...props} loadOtherTagInfo={loadOtherTagInfo} />}
    </div>
  );
};
export default Tagging;
