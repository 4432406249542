import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { hideLoader } from "../../../../../middleware/actions/utilityAction";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "../formFields";
import { addHostValidationSchema } from "../validationSchema";
import { fetchImageUrl, getSingleHost } from "../../../../../middleware/services/videoApi";

const PopupModal = (props) => {
  const [saveBtnText, setSaveBtnText] = useState("Save");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [editData, setEditData] = useState(null);
  const [imageError, setImageError] = useState("");
  const profileImageRef = useRef(null);
  const profileImageInputRef = useRef(null);
  const [profileImage, setProfileImage] = useState(undefined);
  const readHostImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      profileImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };
  const initialFormValues = {
    firstName: "",
    lastName: "",
    company: "",
    designation: "",
    twitter: "",
    facebook: "",
    linkedIn: "",
    profileImage: "",
  };

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(addHostValidationSchema),
  });

  const dispatch = useDispatch();

  const handleReset = () => {
    formik.resetForm();
  };
  const saveDocumentData = async () => {
    const { values } = formik;
    const hostData = {
      peopleFname: values.firstName,
      peopleLname: values.lastName,
      peopleCompany: values.company,
      peopleDesignation: values.designation,
      peopleSocialLink: { twitter: values.twitter, facebook: values.facebook, linkedIn: values.linkedIn },
      peopleProfileImage: values.profileImage,
    };

    const response = (await editData) ? props.onUpdateData(props.hostId, hostData) : props.onSaveData(hostData);
    setSaveBtnText("SAVING...");
    setBtnDisabled(true);
    response.then((result) => {
      setSaveBtnText("SAVE");
      setBtnDisabled(false);
      !editData && handleReset();
    });
  };

  const handleSubmitDocument = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        saveDocumentData();
      }
    });
  };
  const fetchSingleHost = () => {
    let params = {};
    let defaultFilter = [];
    let fields = ["peopleFname", "peopleLname", "peopleCompany", "peopleDesignation", "peopleSocialLink", "profileImage"];
    params.fields = fields;
    if (props.productVariant) defaultFilter.push(["productVariant.productName", "eq", props.productVariant]);
    params.filters = [...defaultFilter];
    getSingleHost(props.videoId, props.hostId, params).then((res) => {
      let data = res.data.data || {};
      setEditData(data);
      data &&
        formik.setValues({
          firstName: data.videoHostedBy.peopleFname,
          lastName: data.videoHostedBy.peopleLname,
          company: data.videoHostedBy.peopleCompany,
          designation: data.videoHostedBy.peopleDesignation,
          twitter: data.videoHostedBy.peopleSocialLink.twitter,
          facebook: data.videoHostedBy.peopleSocialLink.facebook,
          linkedIn: data.videoHostedBy.peopleSocialLink.linkedIn,
          profileImage: data.videoHostedBy.profileImage && data.videoHostedBy.profileImage.imgFileName,
        });
      dispatch(hideLoader());
    });
  };

  useEffect(() => {
    props.hostId && fetchSingleHost();
  }, [props.hostId]);

  return (
    <Modal
      size="lg"
      show={props.isOpen}
      onHide={() => {
        props.closeHandler();
      }}
      centered
    >
      <Modal.Header className="" style={{ borderBottom: "none" }}>
        <Modal.Title>{props.popupTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <TextField formik={formik} placeholder="First Name *" id="firstName" name="firstName" />
          </div>
          <div className="col-md-6">
            <TextField formik={formik} placeholder="Last Name *" id="lastName" name="lastName" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextField formik={formik} placeholder="Company *" id="company *" name="company" />
          </div>
          <div className="col-md-6">
            <TextField formik={formik} placeholder="Designation *" id="designation" name="designation" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pb-2">
            <h6>Your External Links</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextField formik={formik} placeholder="Twitter" id="twitter" name="twitter" />
          </div>
          <div className="col-md-6">
            <TextField formik={formik} placeholder="Facebook" id="facebook" name="facebook" />
          </div>
          <div className="col-md-6">
            <TextField formik={formik} placeholder="LinkedIn" id="linkedIn" name="linkedIn" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="image-holder">
              <div className="d-flex align-item-left justify-content-left">
                {formik.values.profileImage && (
                  <img
                    style={{ borderRadius: "100px" }}
                    src={fetchImageUrl(formik.values.profileImage)}
                    alt="Host"
                    width="78"
                    height="78"
                    ref={profileImageRef}
                  />
                )}
                {formik.values.profileImage && (
                  <span
                    onClick={() => {
                      if (profileImageInputRef.current.files.length > 0) {
                        formik.setValues({ ...formik.values, profileImage: "" });
                        profileImageRef.current.setAttribute("src", null);
                        profileImageInputRef.current.value = "";
                      } else {
                        formik.setValues({ ...formik.values, profileImage: "" });
                        profileImageRef.current.setAttribute("src", "");
                        profileImageInputRef.current.value = "";
                      }
                    }}
                  >
                    <i className={`fa fa-trash text-muted mr-1 ${!formik.values.profileImage ? "d-none" : undefined}`} aria-hidden="true" />
                  </span>
                )}
                {!formik.values.profileImage && <i className="fa-5x text-muted fas fa-user-circle"></i>}
                {
                  <div className="align-item-left m-3">
                    <button
                      type="button"
                      onClick={() => profileImageInputRef.current.click()}
                      className="pl-0 e-btn e-link text-capitalize text-danger"
                    >
                      Upload Profile Picture
                    </button>
                    <br />
                    <small>(Image size should be less than 50 KB)</small>
                  </div>
                }
              </div>
              <div className="image-action mr-4"></div>
              <input
                type="file"
                className="d-none"
                accept=".jpg, .png"
                id="profileImage"
                name="profileImage"
                ref={profileImageInputRef}
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    if (
                      (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/jpeg") &&
                      e.target.files[0].size < 51200
                    ) {
                      setImageError("");
                      setProfileImage(e.target.files[0].name);
                      readHostImage(e.target.files[0]);
                      if (profileImageInputRef.current.files.length)
                        formik.setValues({ ...formik.values, profileImage: profileImageInputRef.current.files[0] });
                    } else {
                      setProfileImage("");
                      setImageError("Only JPG, PNG files are allowed. Max file size should be 50 KB.");
                    }
                  }
                }}
              />
              <div
                className="d-flex align-item-center justify-content-center text-danger"
                style={{ display: imageError ? "block" : "none" }}
                role="alert"
              >
                <small>{imageError}</small>
              </div>
            </div>
          </div>
        </div>

        <Modal.Footer style={{ borderTop: "none", "margin-right": "-16px" }}>
          <button type="button" onClick={() => props.closeHandler()} className="btn btn-outline-dark cy-btn mr-3">
            Cancel
          </button>
          <button type="button" onClick={handleSubmitDocument} disabled={btnDisabled} className="btn btn-primary cy-btn">
            {saveBtnText}
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default PopupModal;
PopupModal.propTypes = {
  videoId: PropTypes.string,
  onSaveData: PropTypes.func.isRequired,
  onUpdateData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
