/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase States
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import { myBriefcaseCategoryParams } from "../../constants/initialFields";

const MY_BRIEFCASE_STATE = {
  allBriefcase: [],
  allBriefcaseCategoryListing: [],
  categoryListing: {
    list: [],
    totalCount: 0,
    params: myBriefcaseCategoryParams,
  },
  categoryId: "",
  categoryBriefcaseListing: {
    list: [],
    totalCount: 0,
  },
  newFolderId: "",
};

export default (state = MY_BRIEFCASE_STATE, action) => {
  switch (action.type) {
    case "SET_ALL_BRIEFCASE_CATEGORY": {
      return { ...state, allBriefcaseCategoryListing: action.payload.res.data };
    }
    case "SET_CATEGORY_LISTING": {
      if (action.payload.res.data && action.payload.res.data.length !== 0) {
        if (state.categoryId === "") {
          state.categoryId = action.payload.res.data[0].id;
        }
      } else {
        state.categoryListing.list = [];
        state.categoryId = "";
      }
      state.categoryListing.list = action.payload.res.data;
      state.categoryListing.totalCount = action.payload.res.totalCount;
      return state;
    }
    case "DELETE_CATEGORY": {
      state.categoryListing.list = state.categoryListing.list.filter((category) => category.id !== action.payload);
      state.categoryListing.totalCount = state.categoryListing.totalCount - 1;
      return state;
    }
    case "SET_BRIEFCASE_CATEGORY_ID": {
      return { ...state, categoryId: action.payload };
    }
    case "SET_BRIEFCASE_COUNT": {
      let existingList = state.categoryListing.list;
      const editedList = existingList.map((item) => {
        if (item.id === state.categoryId) {
          return { ...item, presentationCount: action.payload };
        } else {
          return item;
        }
      });
      state.categoryListing.list = editedList;
      return state;
    }
    case "SET_CATEGORY_SKIP_COUNT": {
      let categoryParams = Object.assign({}, state.categoryListing.params, {
        skip: (action.payload - 1) * 10,
        page: action.payload,
      });
      state.categoryListing.params = categoryParams;
      return state;
    }
    case "SET_CATEGORY_FILTER": {
      let categoryParams = Object.assign({}, state.categoryListing.params, {
        sort: action.payload,
        skip: 0,
        page: 1,
      });
      state.categoryListing.params = categoryParams;
      state.categoryId = "";
      return state;
    }
    case "SET_CATEGORY_BRIEFCASE_LISTING": {
      return { ...state, categoryBriefcaseListing: action.payload };
    }
    case "SET_NEW_FOLDER_ID": {
      return { ...state, newFolderId: action.payload };
    }
    case "RESET_NEW_FOLDER_ID": {
      return { ...state, newFolderId: action.payload };
    }
    case "SET_NEW_FOLDER": {
      let existingList = state.categoryListing.list;
      if (!existingList.includes(action.payload)) {
        const newList = [...state.categoryListing.list, action.payload];
        state.categoryListing.list = newList;
      }
      return state;
    }
    case "RENAME_FOLDER": {
      let existingList = state.categoryListing.list;
      let existingList2 = state.allBriefcaseCategoryListing;
      const editedList = existingList.map((item) => {
        if (item.id === action.payload.id) {
          const obj = Object.assign({}, item);
          obj["categoryName"] = action.payload.name;
          return obj;
        } else {
          return item;
        }
      });
      const editedList2 = existingList2.map((item) => {
        if (item.id === action.payload.id) {
          const obj = Object.assign({}, item);
          obj["categoryName"] = action.payload.name;
          return obj;
        } else {
          return item;
        }
      });
      state.categoryListing.list = editedList;
      state.allBriefcaseCategoryListing = editedList2;
      return state;
    }
    case "RESET_BRIEFCASE_LISTING": {
      state.categoryBriefcaseListing.list = [];
      return state;
    }
    case "RESET_PARAMS": {
      state.categoryListing.params = myBriefcaseCategoryParams;
      return state;
    }
    case "RESET_STATE": {
      return {
        allBriefcase: [],
        allBriefcaseCategoryListing: [],
        categoryListing: {
          list: [],
          totalCount: 0,
          params: myBriefcaseCategoryParams,
        },
        categoryId: "",
        categoryBriefcaseListing: {
          list: [],
          totalCount: 0,
        },
        newFolderId: "",
      };
    }

    /* Common save for product module ends */
    default:
      return state;
  }
};
