import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonDataGrid from "../../../components/dataGrid";
import { getProductVariant, getDateFromMongoDate } from "../../../../utilities";
import { showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { fetchObjective } from "../../../../middleware/services/businessObjectiveApi";
import { StringFilter, DropDownFilter } from "../../../components/dataGrid/fliters";
import ThreeDotMenu from "../../../components/threeDotMenu";
import { setMaintainedState, visitRecord } from "../../../../middleware/actions/gridAction";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

const CustomerBusinessObjective = (props) => {
  const { accessRights: aR } = props;
  const [deleteId, setDeleteId] = useState(undefined);
  const productVariant = getProductVariant();
  const dispatch = useDispatch();
  const history = useHistory();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const gridState = useSelector((state) => state.gridState);
  const breadCrumbLinks = [
    {
      linkUrl: "/business-objective/customer",
      linkName: aR.moduleName,
      linkActive: true,
    },
  ];

  const accessPermissionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessPermissionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => dispatch(setBreadCrumb());
  }, [dispatch]);

  const objectiveCol = {
    id: "id",
    "Objective Title": "objectiveTitle",
    "Objective Status": "objectiveStatus",
    "Publish Date": "productVariant.lastPublishedDate",
    "Create Date": "objectiveCreatedDate",
    "Is Live": "productVariant.isLive",
  };

  const objectiveTitleTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(`/business-objective/customer/${value.id}/edit#basic`);
          dispatch(visitRecord());
        }}
      >
        {value["Objective Title"]}
      </span>
    );
  };
  /* 
		@Description : Template Component for action column
	*/
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessPermissionEdit
          ? {
              Edit: () => {
                history.push(`/business-objective/customer/${value.id}/edit#basic`);
                dispatch(visitRecord());
              },
            }
          : {})}
      />
    );
  };

  const objectiveCreateDateTemplate = (value) => {
    return !isNaN(Date.parse(value["Create Date"])) ? <span>{getDateFromMongoDate(value["Create Date"])}</span> : "";
  };

  const objectivePublishDateTemplate = (value) => {
    return !isNaN(Date.parse(value["Publish Date"])) ? <span>{getDateFromMongoDate(value["Publish Date"])}</span> : "";
  };

  // Dropdown Filter template
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Objective Status":
        sourceData = [
          { id: "Draft", text: "Draft" },
          { id: "Active", text: "Under Review" },
          { id: "Scoping Phase", text: "In Progress" },
          { id: "Closed", text: "Complete" },
          { id: "Abeyance", text: "Abeyance" },
        ];
        break;
      case "Is Live":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const columnFields = [
    {
      field: "id",
      type: "String",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "Objective Title",
      type: "String",
      template: objectiveTitleTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      showInColumnChooser: false,
    },
    {
      field: "Objective Status",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Left",
      width: 200,
    },
    {
      field: "Create Date",
      headerText: "Created Date",
      type: "Date",
      template: objectiveCreateDateTemplate,
      width: 200,
      textAlign: "Center",
    },
    {
      field: "Publish Date",
      headerText: "Published Date",
      type: "Date",
      template: objectivePublishDateTemplate,
      width: 200,
      textAlign: "Center",
    },
    {
      field: "Is Live",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      width: 120,
    },
  ];

  if (accessPermissionEdit) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Center",
    });
    columnFields.push({
      field: "Action",
      type: "String",
      width: 65,
      template: actionTemplate,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      allowSorting: false,
      freeze: "Right",
    });
  }

  const formatRowData = (rowData) => {
    let formatedRowData = [];
    rowData.forEach((objectiveData) => {
      let data = {};
      data.id = objectiveData.id;
      data["Objective Title"] = objectiveData.objectiveTitle;
      let objectiveStatus = "";
      if (objectiveData.objectiveStatus == "under_discussion") {
        objectiveStatus = "In Progress";
      } else if (objectiveData.objectiveStatus == "active") {
        objectiveStatus = "Under Review";
      } else if (objectiveData.objectiveStatus == "closed") {
        objectiveStatus = "Complete";
      } else if (objectiveData.objectiveStatus == "draft") {
        objectiveStatus = "Draft";
      } else {
        objectiveStatus = objectiveData.objectiveStatus.charAt(0).toUpperCase() + objectiveData.objectiveStatus.slice(1);
      }
      data["Objective Status"] = objectiveStatus;
      data["Create Date"] = objectiveData.objectiveCreatedDate ? new Date(objectiveData.objectiveCreatedDate) : "";
      data["Publish Date"] = !objectiveData.productVariant[0].lastPublishedDate ? "" : new Date(objectiveData.productVariant[0].lastPublishedDate);
      data["Is Live"] = !objectiveData.productVariant[0].isLive ? "NO" : objectiveData.productVariant[0].isLive;
      formatedRowData.push(data);
    });
    return formatedRowData;
  };
  const rtnValue = (key) => {
    switch (key) {
      case "Abeyance":
        return "abeyance";
      case "Active":
        return "active";
      case "Closed":
        return "closed";
      case "Scoping Phase":
        return "under_discussion";
      case "Draft":
        return "draft";
      default:
        break;
    }
  };

  const fetchObjectiveWithProductVariant = (params = {}) => {
    params.sort = "_id:desc";
    params.fields = ["objectiveTitle", "objectiveStatus", "objectiveCreatedDate", "productVariant"];
    let defaultFilter = [];
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let { filters = [] } = params;
    if (filters) {
      filters = filters.map((item) => {
        if (item[0] === "objectiveStatus") {
          return ["objectiveStatus", item[1], rtnValue(item[2])];
        } else {
          return item;
        }
      });
    }
    params.filters = [...filters, ...defaultFilter];
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }

    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchObjective(params);
    }
    return fetchObjective(params);
  };

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        <CommonDataGrid
          gridTitle="Customer - Business Objective"
          fetch={fetchObjectiveWithProductVariant}
          deleteOne={false}
          deleteMany={false}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          columnNames={objectiveCol}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          addNewRight={accessPermissionAdd}
          addNew={() => {
            history.push("customer/add#basic");
          }}
          clearFilterByTaxonomy={true}
        />
      </div>
    </div>
  );
};

export default CustomerBusinessObjective;
