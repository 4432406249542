/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
    File Description : Service Validation Schema
----------------------------------------------------------------
    Creation Details
    Date Created				: 08/July/2021
    Author						: Sandeep K. Sharma
================================================================
*/
import * as Yup from "yup";
export const basicValidationSchema = {
    serviceSubject: Yup.string().required("Please enter subject").max(150, "Subject must be at most 150 characters"),
    serviceType: Yup.string().required("Please select service type"),
    serviceForm: Yup.string().required("Please select service form").nullable(),
    serviceConductedDate: Yup.date().nullable(),
    serviceCompletedDate: Yup.date().nullable().min(Yup.ref('serviceConductedDate'), "Service completed date should be greater than conducted date"),
    otherwise: Yup.date().nullable()
};

export const addServiceDocumentValidationSchema = {
    docTitle: Yup.string().required("Please enter document title").max(150, "Title must be at most 150 characters"),
    docType: Yup.string().required("Please select document type"),
    documentFile: Yup.mixed().required("Please upload document"),

};
export const editServiceDocumentValidationSchema = {
    docTitle: Yup.string().required("Please enter document title").max(150, "Title must be at most 150 characters"),
    docType: Yup.string().required("Please select document type"),
};
export const serviceVideoValidationSchema = {
    videoTitle: Yup.string().required("Please enter video title").max(150, "Yitle must be at most 150 characters"),
    videoType: Yup.string().required("Please select video type"),
    videoUrl: Yup.string().required("Please enter valid video URL"),
    //videoUrl: Yup.string().matches(/^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$)/, 'Please enter valid Youtube or Vimeo Video URL'),
};

export const addServiceSurveyValidationSchema = {
    surveyTitle: Yup.string().required("Please select survey")

}