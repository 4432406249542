/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  People Listing Page
	 - Use common grid component for list
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 13/Apr/2021 
	Author						: Sandeep K Sharma
================================================================ 
*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProductVariant, getDateFromMongoDate } from "../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import CommonDataGrid from "../../components/dataGrid";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { fetchPeople, deletePeople, deleteManyPeople } from "../../../middleware/services/peopleApi";
import { dataPublishingPeople } from "../../../middleware/services/cmsApi";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import ThreeDotMenu from "../../components/threeDotMenu";
import "./index.scss";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import { visitRecord, setMaintainedState } from "../../../middleware/actions/gridAction";
import { resetRedux } from "../../../middleware/actions/taxonomyFilterAction";
import { COMMON_ACCESS_ACTION } from "../../../constants";

export default (props) => {
  const { accessRights: aR } = props;
  const productVariant = getProductVariant();
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteId, setDeleteId] = useState(undefined);

  const gridState = useSelector((state) => state.gridState);
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  /*
		@Description : List of People columns
	*/
  const peopleCol = {
    "First Name": "peopleFirstName",
    "Last Name": "peopleLastName",
    "Email ID": "peopleContactEmail",
    "Current Company": "peopleWorkExperience~workCompanyName",
    "Modified Date": "peopleUpdatedDate",
    "Published Date": "peoplePublishedDate",
    Published: "productVariant~isLive",
  };
  const breadCrumbLinks = [{ linkUrl: "/people", linkName: aR.moduleName, linkActive: true }];
  /* Common access */
  const accessActionAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionPublish = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.PUBLISH);

  /* 
		@Description : Use for setting breadcrumb values
	*/

  useEffect(() => {
    localStorage.removeItem("selectedPeople");
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
      localStorage.removeItem("selectedPeople");
    };
  }, [dispatch]);

  /* 
		@Description : delete Single People on click on 3dot menu
	*/
  const deletePeopleMenu = (peopleId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          deletePeople(peopleId)
            .then((response) => {
              dispatch(actionSuccess("People deleted successfully"));
              setDeleteId(peopleId);
            })
            .catch((err) => {
              dispatch(actionError(err.data?.message || "Fail to delete news"));
            });
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to delete ?",
        title: "dialogAlertCssWarning",
      })
    );
  };
  /* 
		@Description : delete many People on checkbox select
	*/
  const deleteManyFunc = (flag) => {
    const selectedPeople = localStorage.getItem("selectedPeople") ? JSON.parse(localStorage.getItem("selectedPeople")) : [];
    if (flag) {
      if (selectedPeople.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteManyPeople({ peopleIds: selectedPeople })
                .then((response) => {
                  if (response.data.data) {
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    const params = {};
                    params.limit = 100;
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete people"));
                });
            },
            okText: "Delete",
            cancelText: "Cancel",
            content: "Are you sure you want to delete ?",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };
  /* 
		@Description : Edit Single People on click on 3dot menu
	*/
  const editPeopleMenu = (peopleId) => {
    dispatch(visitRecord());
    history.push(`/people/${peopleId}/edit`);
  };

  /* 
		@Description : Publish  People function on 3dot menu
	*/
  const publishPeopleMenu = (peopleId) => {
    dispatch(
      showAlertBox({
        okCallback: () => {
          dataPublishingPeople(peopleId, { platform: productVariant })
            .then((response) => {
              if (response.data.data) {
                dispatch(actionSuccess("Selected record(s) published successfully"));
                if (deleteId) setDeleteId(undefined);
                setDeleteId(true);
              }
            })
            .catch((err) => {
              dispatch(actionError(err.data?.message || "Failed to publish people"));
            });
        },
        okText: "Publish",
        cancelText: "Cancel",
        content: "Are you sure you want to publish ?",
        title: "dialogAlertCss",
      })
    );
  };
  /* 
		@Description : Template Component for action column
	*/
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                editPeopleMenu(value?.id);
              },
            }
          : {})}
        {...(accessActionPublish
          ? {
              Publish: () => {
                publishPeopleMenu(value?.id);
              },
            }
          : {})}
        {...(accessActionDelete
          ? {
              Delete: () => {
                deletePeopleMenu(value?.id);
              },
            }
          : {})}
      />
    );
  };
  /* 
		@Description : Template Component for action column
	*/
  const peoplePublishTemplate = (value) => {
    return <>{value.Published === "YES" ? <span>YES</span> : <span>NO</span>}</>;
  };
  /* 
		@Description : Template Component for people title
	*/
  const peopleFirstNameTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(`/people/${value.id}/edit`);
          dispatch(visitRecord());
        }}
      >
        {value["First Name"]}
      </span>
    );
  };
  /* 
		@Description : Template Component for Date col
	*/
  const peopleDateTemplate = (value) => {
    switch (value.column.field) {
      case "Modified Date":
        return !isNaN(Date.parse(value["Modified Date"])) ? <span>{getDateFromMongoDate(value["Modified Date"])}</span> : "";
      case "Published Date":
        return !isNaN(Date.parse(value["Published Date"])) ? <span>{getDateFromMongoDate(value["Published Date"])}</span> : "";
      default:
        return "";
    }
  };
  // Dropdown Filter for Publish
  const publishFilter = (value) => {
    let sourceData = [
      { id: "YES", text: "YES" },
      { id: "NO", text: "NO" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  /* 
		@Description : Sending CcolumnFields to Grid
	*/
  const columnFields = [
    { field: "id", type: "string", visible: false, allowFiltering: false, showInColumnChooser: false, isPrimaryKey: true },
    {
      field: "First Name",
      type: "String",
      template: peopleFirstNameTemplate,
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      textAlign: "Left",
      width: 150,
    },
    { field: "Last Name", type: "String", filterTemplate: StringFilter, textAlign: "Left", width: 150 },
    { field: "Email ID", type: "String", showInColumnChooser: false, textAlign: "Left", width: 250 },
    { field: "Current Company", type: "String", width: 200, textAlign: "Left" },
    { field: "Modified Date", type: "Date", template: peopleDateTemplate, width: 180, textAlign: "Center" },
    { field: "Published Date", type: "Date", template: peopleDateTemplate, width: 190, textAlign: "Center" },
    {
      field: "Published",
      type: "String",
      width: 150,
      template: peoplePublishTemplate,
      filterTemplate: publishFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      headerTextAlign: "Center",
    },
  ];
  /* 
		@Description : Check permission for access column
	*/
  if (accessActionEdit || accessActionPublish || accessActionDelete) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      allowFiltering: false,
      showInColumnChooser: false,
      headerTextAlign: "Center",
      textAlign: "Left",
    });
    columnFields.push({
      field: "Action",
      type: "String",
      width: 65,
      template: actionTemplate,
      textAlign: "Right",
      headerTextAlign: "Center",
      allowFiltering: false,
      showInColumnChooser: false,
      allowSorting: false,
      freeze: "Right",
    });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    rowData.forEach((peopleData) => {
      let data = {};
      data["id"] = peopleData.id;
      data["First Name"] = peopleData.peopleFirstName;
      data["Last Name"] = peopleData.peopleLastName;
      data["Email ID"] = peopleData.peopleContactEmail;
      if (peopleData.peopleWorkExperience.length > 0) {
        let currentCompany = peopleData.peopleWorkExperience.filter(function (c) {
          return c.workEndDate === "PRESENT";
        });
        if (currentCompany.length > 0) {
          data["Current Company"] = currentCompany[0].workCompanyName;
        } else {
          data["Current Company"] = peopleData.peopleWorkExperience[0].workCompanyName;
        }
      } else {
        data["Current Company"] = "";
      }
      data["Modified Date"] = new Date(peopleData.peopleUpdatedDate);
      data["Published Date"] = new Date(peopleData.peoplePublishedDate);
      data["Published"] = peopleData.productVariant[0].isLive ? peopleData.productVariant[0].isLive : "NO";
      formatedRowData.push(data);
    });
    return formatedRowData;
  };
  /* 
		@Description : Fetch People data 
	*/
  const fetchPeopleWithProductVariant = (params = {}) => {
    let defaultFilter = [];
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];
    if (!params.sort) {
      params.sort = "peopleCreatedDate:desc";
    }
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchPeople(params);
    }
    return fetchPeople(params);
  };

  // Reset Redux for filter by taxonomy
  useEffect(() => {
    if (window.location.pathname == `/people`) {
      dispatch(resetRedux());
    }
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        <CommonDataGrid
          gridTitle="People Records"
          fetch={fetchPeopleWithProductVariant}
          deleteOne={() => null}
          deleteMany={accessActionDelete ? deleteManyFunc : null}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          columnNames={peopleCol}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          getMultiSelectedRows={(data) => {
            localStorage.setItem("selectedPeople", JSON.stringify([...data]));
          }}
          getMultiDeSelectedRows={(data) => {
            localStorage.setItem("selectedPeople", JSON.stringify([...data]));
          }}
          addNewRight={accessActionAddNew}
          addNew={() => history.push(`/people/add`)}
          clearFilterByTaxonomy={true}
        />
      </div>
    </div>
  );
};
