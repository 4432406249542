/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 15/Mar/2021
 * File Description: Survey Form Action Button
 */

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { accessRightActionCheck, getLoggedInUser } from "../../../../utilities";
import { actionSuccess, actionError } from "../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { publishSurvey, getSurveyAnalytics } from "../../../../middleware/services/surveyApi";
import ExportAnalytics from "./exportAnalytics/exportAnalytics";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

const ActionButtons = (props) => {
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = getLoggedInUser();
  const surveyId = props.surveyId || "";
  const { setHasUnsavedChanges, hasUnsavedChanges, setShouldRefetch } = props;

  const publish = async () => {
    const surveyId = props.surveyId;
    await publishSurvey(surveyId, {
      surveyPublishedBy: { userId: loggedInUser._id, userFname: loggedInUser.user_name, userLname: loggedInUser.user_lname },
    })
      .then((res) => {
        if (res) {
          document.querySelector(".btn-publish").style.display = "none";
          dispatch(actionSuccess("Survey has been published successfully"));
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        let errMessage = "Survey can not be published. ";
        const errMessageArr = err?.data?.message ? err.data.message.split(",") : ["Something went wrong"];
        errMessage += errMessageArr
          .map((value) => {
            return value;
          })
          .join("");

        dispatch(actionError(errMessage));
      });
  };
  const clearSessionStorage = () => {
    try {
      setHasUnsavedChanges(false);
      setShouldRefetch(true);
      // Clear History data if changes are saved.
      if (surveyId) return sessionStorage.removeItem(`${surveyId}`);
      return sessionStorage.removeItem("temp-survey-design");
    } catch (e) {
      console.error(e);
    }
  };

  let actionAccessSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  let actionAccessExportResponse = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EXPORT_RESPONSE);
  let actionAccessPublish = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.PUBLISH);
  let actionAccessSendInvitation = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SEND_INVITATION);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = Object.values(props?.hashArr)[props?.selectedIndex];
    let actionAccess = accessRightActionCheck(props?.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [props?.selectedIndex]);

  return (
    <div className="form-submit-box">
      <div className="container-fluid nopadding">
        <div className="row">
          <div className="float-left col-md-4 text-left">
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="pl-4" style={{ marginLeft: "-2.7em" }}>
                <span className="span-link">
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                    onClick={() => {
                      (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                        (props.selectedIndex < 7 && props.setCurrentTab(props.selectedIndex - 1));
                    }}
                  >
                    Previous
                  </button>
                </span>
              </span>
            ) : null}

            {surveyId !== "" && props.selectedIndex > -1 && props.selectedIndex < 5 ? (
              <span className="pl-4" style={{ marginLeft: props.selectedIndex === 0 ? "-3em" : "0" }}>
                <span className="span-link">
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                    onClick={() => {
                      props.selectedIndex < 6 && props.setCurrentTab(props.selectedIndex + 1);
                    }}
                  >
                    Next
                  </button>
                </span>
              </span>
            ) : null}
          </div>
          <div className="float-right col-md-8 text-right">
            {props.downloadAnalytics && props.surveyStatus && actionAccessExportResponse ? (
              <button
                className="btn btn-outline-dark cy-btn pl-0 pt-0 pr-0 pb-0 "
                onClick={() => {
                  let params = {};
                  let fields = ["surveySerialNo", "surveyShortName", "surveyTitle", "surveyForm", "userSurveyResponse"];
                  params.fields = fields;
                  getSurveyAnalytics(surveyId, params).then((res) => {
                    let data = res.data.data || {};
                    if (data && data.surveyResponse.length > 0) {
                      ExportAnalytics(data, props.shortName, props.isDraft);
                      dispatch(actionSuccess("File is being downloaded"));
                    } else {
                      dispatch(actionError("There is no response for the survey"));
                    }
                  });
                }}
              >
                Export Response
              </button>
            ) : (
              ""
            )}
            {hasUnsavedChanges && (
              <button onClick={clearSessionStorage} type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 ml-3 ">
                Discard
              </button>
            )}
            <Link to={"/survey" || history.location.pathname}>
              <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 ml-3 ">
                Cancel
              </button>
            </Link>
            {props.handleSendInvitation && actionAccessSendInvitation ? (
              <button type="button" className="btn btn-primary cy-btn ml-3" onClick={props.handleSendInvitation}>
                Send Invitation
              </button>
            ) : null}
            {props.handleSave && props.selectedIndex != 5 && actionAccessSave ? (
              <button type="button" className="btn btn-primary cy-btn pl-4 pr-4  ml-3" id="dev-objective-save" onClick={props.handleSave}>
                Save
              </button>
            ) : null}

            {props.showPublished && actionAccessPublish ? (
              <button
                type="button"
                className="btn btn-publish btn-primary cy-btn pl-4 pr-4 ml-3 "
                onClick={() => {
                  dispatch(
                    showAlertBox({
                      okCallback: async () => {
                        await publish();
                      },
                      content: "Publishing Survey will remove responses recorded in draft stage. Are you sure, you want to publish survey?",
                      okText: "Publish",
                      cancelText: "Cancel",
                      title: "dialogAlertCss",
                    })
                  );
                }}
              >
                Publish
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
