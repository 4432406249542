/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company video  UI for  Listing, add and delete and API wiring up
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 08/Aug/2020 
	Author						: Anand Kumar
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { fetchVideo, deleteVideo } from "../../../../../middleware/services/companyApi";
import Accordion from "../../../../components/accordion";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { useDispatch } from "react-redux";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import VideoModal from "./videoModal";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const VideoComponent = (props) => {
  /* Common access */
  const accessPermissionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_COMPANY_VIDEOS);
  const accessPermissionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_VIDEOS);
  const accessPermissionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_VIDEOS);

  const [editVideoId, setEditVideoId] = useState(null);
  const [editDocumentData, setEditDocumentData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const dispatch = useDispatch();

  const getCompanyVideos = async () => {
    const response = await fetchVideo(props.companyId).catch((err) => {
      dispatch(actionError(err.data.message || "Something went wrong"));
    });
    setVideoList(response.data?.data?.companyVideos || []);
  };
  useEffect(() => {
    getCompanyVideos();
  }, []);

  const handleDeleteVideo = async (props, id) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await deleteVideo(props.companyId, id).catch((err) => {
            dispatch(actionError(err.data.message || "Something went wrong"));
          });
          dispatch(actionSuccess("Video deleted successfully"));
          getCompanyVideos();
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to Delete ?",
        title: "dialogAlertCssWarning",
      })
    );
  };

  return (
    <Accordion heading="Videos" stepCompleted={videoList && videoList.length > 0 ? true : false} step={7}>
      <div className="row px-3">
        <div className="table-responsive pt-2">
          <table className="normal-table table table-bordered">
            <thead className="internal-grid">
              <tr>
                <th>Title</th>
                <th>Description</th>
                {/* <th className="text-center">View</th> */}
                <th className="text-center internal-grid-action">Action</th>
              </tr>
            </thead>
            <tbody>
              {/* {documentListing} */}
              {videoList.map((item, i) => {
                return (
                  <React.Fragment key={`vid-${i}`}>
                    <tr>
                      <td>{item.videoTitle}</td>
                      <td>
                        {item.videoDescription.replace(/(<([^>]+)>|&nbsp;)/gi, " ")}
                        {/* <div className="overflow dangerous-inner-html" dangerouslySetInnerHTML={{ __html: item.videoDescription }} /> */}
                        {/* {decodeHTML(item.videoDescription || "")} */}
                      </td>
                      {/* <td className="text-center"><a target="_blank" href={videoSource === "Vimeo"?`https://vimeo.com/${videoId}`:
								(videoSource === "YouTube"?`https://www.youtube.com/watch?v=${videoId}`:"")} data-tip="view"
								><i className="fas fa-eye" data-tip="Watch" /></a>
									<ReactTooltip />
								</td> */}
                      <td className="text-center">
                        {accessPermissionEdit || accessPermissionDelete ? (
                          <ThreeDotMenu
                            rowID={item.id}
                            Edit={
                              accessPermissionEdit
                                ? () => {
                                    setEditVideoId(item.id);
                                    setShowModal(!showModal);
                                    setEditDocumentData(true);
                                  }
                                : undefined
                            }
                            Delete={
                              accessPermissionDelete
                                ? () => {
                                    handleDeleteVideo(props, item.id);
                                  }
                                : undefined
                            }
                          />
                        ) : null}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <div className="text-right">
            {accessPermissionAdd ? (
              <button
                type="button"
                className="btn btn-dark cy-btn"
                onClick={() => {
                  setEditVideoId(null);
                  setShowModal(!showModal);
                  setEditDocumentData(false);
                }}
              >
                Add New
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <VideoModal
        editDocumentData={editDocumentData}
        editVideoId={editVideoId}
        companyId={props.companyId}
        showModal={showModal}
        closeHandler={() => {
          setShowModal(false);
          getCompanyVideos();
        }}
      />
    </Accordion>
  );
};
export default VideoComponent;
