/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Sorting Dropdown
   Summary : Sorting Dropdown
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React from "react";
import classes from "./sortingDropdown.module.css";
import { Form } from "react-bootstrap";

const SortDropdown = (props) => {
  return (
    <div className={classes.sort_content}>
      <Form className={classes.input_group}>
        <Form.Group className={classes.formGroup}>
          <Form.Control className={classes.input_control} as="select" onChange={props.changeFilter} value={props.value} style={{ cursor: "pointer" }}>
            <option value="Newest">Newest</option>
            <option value="Oldest">Oldest</option>
            <option value="Ascending">Ascending</option>
            <option value="Descending">Descending</option>
          </Form.Control>
        </Form.Group>
      </Form>
    </div>
  );
};

export default SortDropdown;
