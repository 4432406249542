import SidebarModal from "../sidebar-modal";
import React, { memo, useEffect } from "react";
import { WidgetCard } from "../widgetCard";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";
import "./index.css";
import {
  ADD_WIDGETS,
  CANCEL_BUTTON_TEXT,
  NO_WIDGET_FOUND,
  PREVIOUS_BUTTON_TEXT,
  SAVE_AND_NEXT_BUTTON_TEXT,
  SIDEBAR_WIDGET_TITLE,
} from "../../../constants/widget-constants";

const AddWidgetSideBarModal = (props) => {
  const { setter, handleSaveClick, handleCardClick, widgetData, isSearchFetching, selectedWidgetId, widgetStatus } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isSearchFetching) {
      dispatch(hideLoader());
    } else {
      dispatch(showLoader());
    }
    // Cleanup function
    return () => {
      dispatch(hideLoader());
    };
  }, [isSearchFetching, dispatch]);

  return (
    <SidebarModal
      style={{ marginTop: "63px", width: "100%", color: "black" }}
      backDrop={true}
      header={ADD_WIDGETS}
      footer={
        <div className="action-button-container">
          <div>
          </div>
          <div >
            <button
              type="button"
              className="btn btn-outline-dark"
              style={{ fontSize: "14px" , paddingLeft: "16px", paddingRight: "16px" }}
              onClick={() => {
                setter(false);
                handleCardClick(null);
              }}
            >
              {CANCEL_BUTTON_TEXT}
            </button>
            <button
              type="button"
              className="btn btn-primary ml-3"
              style={{ fontSize: "14px", paddingLeft: "16px", paddingRight: "16px" }}
              onClick={() => {
                setter(false);
                handleSaveClick();
              }}
              disabled={!selectedWidgetId}
            >
              {SAVE_AND_NEXT_BUTTON_TEXT}
            </button>
          </div>
        </div>
      }
      className="add-widget-modal"
      childrenClassName={"add-widget-sidebar-modal"}
    >
      <div className="px-4 pb-4">
        {widgetData?.data?.data?.[0]?.configValue?.length > 0 && (
          <>
            <p className="sidebar-widget-header">{SIDEBAR_WIDGET_TITLE}</p>
            <div className="widget-grid">
              {widgetData?.data?.data?.[0]?.configValue?.map(
                (widget) =>
                  !widget?.disabled && (
                    <WidgetCard
                      title={widget?.displayName}
                      key={widget?.name}
                      description={widget?.description}
                      icon={widget?.name}
                      selectedWidgetId={selectedWidgetId}
                      id={widget?.value}
                      handleCardClick={() => handleCardClick(widget)}
                      isDisable={widgetStatus[widget?.name]}
                    />
                  )
              )}
            </div>
          </>
        )}
        {!widgetData?.data?.data?.[0]?.configValue?.length && <div className="m-auto">{NO_WIDGET_FOUND}</div>}
      </div>
    </SidebarModal>
  );
};

export default memo(AddWidgetSideBarModal);
