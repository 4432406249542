// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : search
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :20 Nov 23
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

// import { saveSearchListParamsMobile } from '../../constants/initialFields';
import moment from "moment";

import {
  SET_SEARCH_KEYWORDS,
  GET_TECHNOLOGY_LIST,
  SET_FIRST_TREE_CHECKED_NODES,
  SET_SECOND_TREE_CHECKED_NODES,
  SET_ALL_SEARCH_COUNT,
  SET_FIXED_ALL_SEARCH_COUNT,
  SET_SEARCH_SORT,
  SET_DATE_FILTER_VALUE,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_CONTENT_TYPE_COUNT,
  SET_RESULT_COUNT,
  SET_FIXED_CONTENT_TYPE_COUNT,
  SET_CURRENT_QUERY,
  SET_CURRENT_QUERY_NEW_SEARCH,
  SET_CONTENT_TYPE_FILTER_SEARCH,
  SET_SEARCH_SELECTED_FILTER_NODES,
  SET_TREND_FILTER_SEARCH,
  GET_SECTORS_SEARCH,
  SET_DATE_FILTER,
  TOGGLE_LOADING,
  SET_SGF_IDS_SEARCH,
  GET_CONTENT_TYPE_SEARCH,
  CLEAR_ALL_SEARCH_FILTER,
  GET_TRENDS_LIST,
  GET_INDUSTRIES_LIST,
  SET_STATUS_FILTER,
} from "../../constants/actionConstants";

// modify existing filter
const modifiedArray = (type, array) => {
  return array.map((item, i) => {
    let obj = item.split(">");
    return {
      type: type ? type : obj[3],
      value: obj[1],
      title: obj[2],
    };
  });
};

// initial state for search related data
const SEARCH_STATE = {
  saveSearchList: [],
  totalCount: 0,

  technologyList: [],

  saveSearchListMobile: {
    list: [],
    totalCount: 0,
    // params: saveSearchListParamsMobile,
  },

  // checked nodes from first and second tree from filter
  firstTreeCheckedNodes: [],
  secondTreeCheckedNodes: [],
  allSearchCount: {},
  contentTypeCount: {},

  myNotebookActiveTab: "objectives",

  sgfList: [],
  sgfIdsSearch: [],

  subscribeSgfs: [],

  productIndustryIds: [],

  // selected filters
  selectedFilters: {
    selectedContentType: [],
    selectedSgf: [],
  },

  // filter array values
  searchFilters: {
    contentTypeFilters: [],
    sgfFilters: [],
    productIndustryIds: [],
  },

  fixedAllSearchCount: {},

  searchSort: "score:desc",
  fromDate: undefined,
  toDate: undefined,
  dateFilterValue: "All",
  statusFilter: "All",

  keywords: [],

  fixedContentTypeCount: {},
  currentQuery: "",
  resultCount: 0,
  currentQueryNewSearch: "",

  // all modified filters
  allFilters: [],
  initialFilter: false,

  // selected Search filters
  selectedSearchFilters: {
    selectedContentType: [],
    selectedSgf: [],
  },

  // side filter list
  contentTypeList: [],
  contentTypeListSearch: [],
  sgfListSearch: [],

  userSubscriptions: [],

  loadingStatus: true,
};

export default (state = SEARCH_STATE, action) => {
  switch (action.type) {
    case SET_SEARCH_KEYWORDS: {
      return { ...state, keywords: action.payload };
    }
    case GET_TECHNOLOGY_LIST: {
      return { ...state, technologyList: action.payload };
    }
    case GET_CONTENT_TYPE_SEARCH: {
      return { ...state, contentTypeListSearch: action.payload };
    }
    case SET_FIRST_TREE_CHECKED_NODES: {
      return { ...state, firstTreeCheckedNodes: action.payload };
    }
    case SET_SECOND_TREE_CHECKED_NODES: {
      return { ...state, secondTreeCheckedNodes: action.payload };
    }
    case SET_ALL_SEARCH_COUNT: {
      return { ...state, allSearchCount: action.payload };
    }
    case SET_FIXED_ALL_SEARCH_COUNT: {
      return { ...state, fixedAllSearchCount: action.payload };
    }
    case SET_SEARCH_SORT: {
      return { ...state, searchSort: action.payload };
    }
    case SET_DATE_FILTER: {
      if (Object.keys(action.payload).length) {
        let fromDate, toDate;

        if (action.payload.filterValue !== "Custom") {
          const today = () => moment().format("YYYY-MM-DD");
          const subtractDay = (days) => moment().subtract(days, "days").format("YYYY-MM-DD");
          switch (action.payload.filterValue) {
            case "All":
              fromDate = undefined;
              toDate = undefined;
              break;
            case "Today":
              fromDate = today();
              toDate = today();
              break;
            case "Yesterday":
              fromDate = subtractDay(1);
              toDate = subtractDay(1);
              break;
            case "Past 7 days":
              fromDate = subtractDay(6);
              toDate = today();
              break;
            case "Past 15 days":
              fromDate = subtractDay(14);
              toDate = today();
              break;
            case "Past 30 days":
              fromDate = subtractDay(29);
              toDate = today();
              break;
            case "Past 90 days":
              fromDate = subtractDay(89);
              toDate = today();
              break;
            case "Past 180 days":
              fromDate = subtractDay(179);
              toDate = today();
              break;
            default:
              break;
          }
        }

        state.dateFilterValue = action.payload.filterValue;
        state.fromDate = fromDate;
        state.toDate = toDate;
      } else {
        state.fromDate = undefined;
        state.toDate = undefined;
      }
      return state;
    }
    case SET_DATE_FILTER_VALUE: {
      return { ...state, dateFilterValue: action.payload };
    }
    case SET_FROM_DATE: {
      return { ...state, fromDate: action.payload };
    }
    case SET_TO_DATE: {
      return { ...state, toDate: action.payload };
    }
    case SET_CONTENT_TYPE_COUNT: {
      return { ...state, contentTypeCount: action.payload };
    }
    case SET_RESULT_COUNT: {
      return { ...state, resultCount: action.payload };
    }
    case SET_FIXED_CONTENT_TYPE_COUNT: {
      return { ...state, fixedContentTypeCount: action.payload };
    }
    case SET_CURRENT_QUERY: {
      return { ...state, currentQuery: action.payload };
    }
    case SET_CURRENT_QUERY_NEW_SEARCH: {
      return { ...state, currentQueryNewSearch: action.payload };
    }
    case GET_SECTORS_SEARCH: {
      return { ...state, sgfListSearch: action.payload.sgfListSearch };
    }
    case SET_TREND_FILTER_SEARCH: {
      const { trendValues } = action.payload;

      if (trendValues.length) {
        let selectedTrendList = modifiedArray("", trendValues);
        selectedTrendList = selectedTrendList.map((res) => res.value);

        return {
          ...state,
          searchFilters: {
            ...state.searchFilters,
            sgfFilters: [...new Set(selectedTrendList)],
          },
        };
      } else {
        return {
          ...state,
          searchFilters: {
            ...state.searchFilters,
            sgfFilters: state.subscribeSgfs,
          },
        };
      }
    }
    case SET_CONTENT_TYPE_FILTER_SEARCH: {
      return {
        ...state,
        selectedSearchFilters: {
          ...state.selectedSearchFilters,
          selectedContentType: action.payload,
        },
        searchFilters: {
          ...state.searchFilters,
          contentTypeFilters: action.payload.length ? modifiedArray("content", action.payload).map((res) => res.title) : [],
        },
      };
    }
    case SET_SEARCH_SELECTED_FILTER_NODES: {
      return {
        ...state,
        selectedSearchFilters: {
          ...state.selectedSearchFilters,
          selectedSgf: action.payload,
        },
      };
    }
    case TOGGLE_LOADING: {
      return { ...state, loadingStatus: action.payload };
    }
    case SET_SGF_IDS_SEARCH: {
      return { ...state, sgfIdsSearch: action.payload.sgfIdsSearch };
    }
    case CLEAR_ALL_SEARCH_FILTER: {
      return {
        ...state,
        selectedSearchFilters: {
          selectedContentType: [],
          selectedSgf: [],
        },
        searchFilters: {
          contentTypeFilters: [],
          sgfFilters: [],
          productIndustryIds: [],
        },
      };
    }
    case GET_TRENDS_LIST: {
      return { ...state, subscribeSgfs: action.payload.subscribeSgfs, sgfIdsSearch: action.payload.sgfIdsSearch };
    }
    case GET_INDUSTRIES_LIST: {
      return { ...state, productIndustryIds: action.payload.productIndustryIds };
    }
    case SET_STATUS_FILTER: {
      return { ...state, statusFilter: action.payload };
    }
    default:
      return state;
  }
};
