import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import { setBreadCrumb } from "middleware/actions/breadCrumbAction";
import { setSideNavForcedActiveLink } from "middleware/actions/sideNavAction";
import { Dropdown } from "../../components/CustomFormElements/syncFusionFormFields";
import { fetchTaxonomyTrend } from "middleware/services/cmsApi";
import { useDropzone } from "react-dropzone";
import { actionError, actionSuccess, hideLoader, showLoader } from "middleware/actions/utilityAction";
import { fetchTemplateFileUrl, saveIndustry } from "middleware/services/uploadDataApi";
import { COMMON_ACCESS_ACTION } from "../../../constants";
import { accessRightActionCheck } from "../../../utilities";

export default function ImportData(props) {
  const { accessRights: aR } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [industry, setIndustry] = useState([]);

  const [uploadDataInformation, setUploadDataInformation] = useState({
    IndustryType: "",
    documentFile: "",
  });
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);

  const formik = useFormik({ enableReinitialize: true, initialValues: uploadDataInformation });
  const breadCrumbLinks = [
    { linkUrl: "/data-upload", linkName: aR.moduleName, linkActive: false },
    {
      linkName: "Add New",
      linkActive: true,
    },
  ];

  const [errors, setErrors] = useState({
    file: "",
    IndustryType: "",
  });

  const fetchTrendData = () => {
    let filters = [];
    filters = [["trendParentId", "eq", 0]];
    dispatch(showLoader());

    fetchTaxonomyTrend({
      filters: filters,
      fields: ["id", "trendName", "trendParentId", "trendParentName"],
      sort: "trendName:asc",
      limit: 2000,
    })
      .then((res) => {
        if (res.status === 200) {
          setIndustry(res.data.data);
        } else {
          console.error("err fetchTrend :: ");
        }
      })
      .catch((err) => {
        console.error("err fetchTrend :: ", err);
      });
    dispatch(hideLoader());
  };

  const handleSubmit = async (values) => {
    try {
      if (formik.values.IndustryType.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          IndustryType: "Please select industry type.",
        }));
        return;
      }

      if (!formik.values.documentFile) {
        setErrors((prev) => ({
          ...prev,
          file: "File is required.",
        }));
        return;
      }

      const perfectMatch = industry.find((item) => item.trendName == formik.values.IndustryType);

      const saveData = new FormData();
      saveData.append("file", formik.values.documentFile[0]);
      saveData.append("industry_name", formik.values.IndustryType);
      saveData.append("industry_id", perfectMatch.id);

      dispatch(showLoader());
      await saveIndustry(saveData);
      dispatch(actionSuccess("Data Uploaded Successfully."));
      history.push(`/data-upload`);
      dispatch(hideLoader());
    } catch (error) {
      console.log("err fetchTrend ::", error);
      dispatch(actionError(error.data.error));
    }
  };

  useEffect(() => {
    fetchTrendData();
  }, []);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/data-upload"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch]);

  /* Interface actions */
  useEffect(() => {
    let actionAccess = accessRightActionCheck(aR.moduleName, aR.interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  const UploadComponent = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      // accept: "image/*",
      accept: { acceptedFilesFormat: [".xls,.xlsx"] },
      maxFiles: 1, //comment this line if upload multiple File
      onDrop: (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length) {
          formik.setValues({
            ...formik.values,
            documentFile: acceptedFiles,
          });
          setErrors({
            ...errors,
            file: "",
          });
        }
      },
    });

    const handleDownload = async () => {
      try {
        const templateInfo = fetchTemplateFileUrl();

        const response = await fetch(templateInfo.url, {
          headers: templateInfo.headers,
        });

        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          // Create a temporary link element
          const link = document.createElement("a");
          link.href = url;
          link.download = "signal-data-upload-template.xlsx";

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        } else {
          console.error("Error downloading file:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    return (
      <>
        <div className="form-group bg-light p-3 border-dotted mb-1" {...getRootProps()}>
          <input {...getInputProps()} />
          <button type="button" className="btn btn-dark">
            Upload Data Template
          </button>{" "}
          or drop file here
        </div>
        <small>
          Note: Only <b>.xls, .xlsx</b> files are allowed.
          <a style={{ float: "right", cursor: "pointer" }} onClick={(e) => handleDownload()} download>
            <i className="fas fa-download" data-tip="Download" /> Download Template
          </a>
        </small>

        <div
          className="text-danger"
          style={{
            display: formik.touched.documentFile === true ? "block" : "none",
            backgroundColor: "red",
          }}
          role="alert"
        >
          <small>{formik.touched.documentFile === true ? formik.errors.documentFile || "" : ""}</small>
        </div>
      </>
    );
  };

  return (
    <div className="p-3" style={{ maxHeight: "95vh" }}>
      <div className="col-md-12 border rounded-bottom nopadding p-0 bg-white" style={{ height: "85vh" }}>
        <div style={{ marginTop: "21px" }}>
          <form>
            <div className="row ">
              <div className="col-md-4" style={{ marginLeft: "21px" }}>
                <Dropdown
                  formik={formik}
                  placeholder="Industry Type *"
                  enabled={true}
                  id="IndustryType"
                  name="IndustryType"
                  value={formik.values.IndustryType}
                  fields={{ text: "trendName", value: "trendName" }}
                  dataSource={industry}
                  required={true}
                  onChange={() => {
                    return setErrors({
                      ...errors,
                      IndustryType: "",
                    });
                  }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "21px", marginTop: "-20px", marginBottom: "20px" }}>
              {errors.IndustryType && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.IndustryType}
                </span>
              )}
            </div>
            <div className="row ">
              <div className="col-md-4 " style={{ marginLeft: "21px" }}>
                <div className="row">
                  <div className="col-md-12">
                    <UploadComponent formik={formik} />
                    {formik.values.documentFile &&
                      formik.values.documentFile.map((f, i) => (
                        <li key={i}>{`File:${f.name.length > 50 ? f.name.substring(0, 30) + "..." + f.name.slice(-4) : f.name}`}</li>
                      ))}
                  </div>
                  <div style={{ marginLeft: "21px" }}>
                    {errors.file && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {errors.file}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="form-submit-box">
              <div className="container-fluid nopadding">
                <div className="float-right col-md-8 text-right pr-1">
                  <Link to="/data-upload">
                    <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                      Cancel
                    </button>
                  </Link>

                  {accessActionSave && (
                    <button type="button" className="btn btn-primary cy-btn pl-4 pr-4 " id="dev-news-save" onClick={handleSubmit}>
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
