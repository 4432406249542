/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Application Initial State
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { GET_INITIAL_STATE } from "../../constants/actionConstants";

const INITIAL_STATE = {
	initialState: "GenNx",
};

export default function (state = INITIAL_STATE, action = { payload: {}, type: GET_INITIAL_STATE }) {
	switch (action.type) {
		case GET_INITIAL_STATE: {
			return { ...state };
		}
		default:
			return state;
	}
}
