/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  Render Utility component is responsible for rendering respective component 
     [e.g Properties | Design | Settings | Taxonomy ]
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 12/April/2022 
    Author						: Amrutesh Devadas
================================================================ 
*/
import React from "react";
import Properties from "../properties";
import Design from "../design";
import Settings from "../settings";
import Tagging from "../tagging";
import AccessDenied from "app/components/accessDenied";

const RenderUtility = (props) => {
  let interfaceAccess = props.accessRightInterfaceRights.includes(Object.values(props.hashArr)[props.selectedIndex]);

  return (
    <>
      <div key="contentBlockProperties">
        {interfaceAccess ? <Properties {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} /> : <AccessDenied />}
      </div>

      <div key="contentBlockDesign">
        {props.selectedIndex === 1 && interfaceAccess ? (
          <Design {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
      <div key="contentBlockSettings">
        {props.selectedIndex === 2 && interfaceAccess ? (
          <Settings {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
      <div key="contentBlockSettings">
        {props.selectedIndex === 3 && interfaceAccess ? (
          <Tagging {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
    </>
  );
};

export default RenderUtility;
