/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  People Listing Page
	 - Use common grid component for list
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 21/Apr/2021 
	Author						: Sandeep K Sharma
================================================================ 
*/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { fetchExperience, deleteExperience } from "../../../../../middleware/services/peopleApi";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../../../middleware/actions/utilityAction";
import AddExpModel from "./add";
import Accordion from "../../../../components/accordion";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";

export default (props) => {
  const peopleId = props.peopleId;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [expData, setExpData] = useState([]);
  const [stepCompleted, setStepCompleted] = useState(false);
  const [expId, setExpId] = useState("");

  const closeModal = () => {
    setExpId("");
    setShowModal(false);
  };
  /*
		@Description : BreadCrub Creation
	*/
  const breadCrumbLinks = [{ linkUrl: "/people", linkName: props?.moduleName || "People", linkActive: true }];

  /* 
		@Description : Use for setting breadcrumb values
	*/

  useEffect(() => {
    if (!showModal) {
      if (peopleId !== "") {
        formatRowData(peopleId);
      }
      localStorage.removeItem("selectedPeople");
      dispatch(setBreadCrumb(breadCrumbLinks));
      return () => {
        dispatch(setBreadCrumb());
        localStorage.removeItem("selectedPeople");
      };
    }
  }, []);

  // formatRowData function
  const formatRowData = async (peopleId) => {
    dispatch(showLoader());
    try {
      let formatedRowData = [];
      const response = await fetchExperience(peopleId);
      if (response.status == 200) {
        dispatch(hideLoader());
        var peopleData = response.data.data;
        let experiences = peopleData.peopleWorkExperience;
        if (experiences.length > 0) {
          setStepCompleted(true);
          experiences.forEach((exp) => {
            let data = {};
            data["expId"] = exp.id;
            data["CompanyName"] = exp.workCompanyName;
            data["Designation"] = exp.workDesignation;
            data["From"] = exp.workStartDate;
            data["To"] = exp.workEndDate;
            data["Role"] = Array.isArray(exp.workRole) ? exp.workRole.join(", ") : exp.workRole;
            formatedRowData.push(data);
          });
        }
      }
      setExpData(formatedRowData);
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  const removeExp = (expId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          deleteExperience(peopleId, expId)
            .then((response) => {
              dispatch(actionSuccess("Work experience deleted successfully"));
              formatRowData(peopleId);
            })
            .catch((err) => {
              dispatch(actionError(err.data?.message || "Fail to delete Experiecnce"));
            });
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Do you want to delete this item?",
        title: "dialogAlertCssWarning",
      })
    );
  };

  return (
    <>
      {showModal && <AddExpModel showModal={showModal} closeModal={closeModal} peopleId={peopleId} expId={expId} formatRowData={formatRowData} />}
      <Accordion heading="Work Experience" step={2} stepCompleted={expData && expData.length ? true : false} disable={true}>
        <div className="card border-0" style={{ width: "100%" }}>
          <ul className="list-group list-group-flush">
            {expData.map(function (x, i) {
              return (
                <li key={i} className="list-group-item">
                  <div className="col-md-12 pt-2">
                    <small className="text-muted">Company Name</small>
                    {props?.accessActionDeleteExperience ? (
                      <a
                        className="btn btn-light cy-btn mr-3 float-right"
                        role="button"
                        onClick={() => {
                          removeExp(x["expId"]);
                        }}
                      >
                        Delete
                      </a>
                    ) : null}
                    {props?.accessActionEditExperience ? (
                      <a
                        className="btn btn-light cy-btn mr-3 float-right"
                        role="button"
                        onClick={() => {
                          showModal && setShowModal(false);
                          setShowModal(true);
                          setExpId(x["expId"]);
                        }}
                      >
                        Edit
                      </a>
                    ) : null}
                  </div>
                  <div className="col-md-12 ">
                    <span className="font-bold-500">{x["CompanyName"]}</span>
                  </div>
                  <div className="col-md-12 pt-2">
                    <small className="text-muted">Role</small>
                  </div>
                  <div className="col-md-12">
                    <span className="font-bold-500">{x["Role"]}</span>
                  </div>
                  <div className="col-md-12 pt-2">
                    <small className="text-muted">Designation</small>
                  </div>
                  <div className="col-md-12">
                    <span className="font-bold-500">{x["Designation"]}</span>
                  </div>
                  <div className="col-md-6 pt-2">
                    <div className="row">
                      <div className="col-md-3 pt-2">
                        <small className="text-muted">Start Date</small>
                      </div>
                      <div className="col-md-3 pt-2">
                        <small className="text-muted">End Date</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-3">
                        <span className="font-bold-500">{x["From"]}</span>
                      </div>
                      <div className="col-md-3">
                        <span className="font-bold-500">{x["To"]}</span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {props?.accessActionAddExperience ? (
          <div className="p-2 ml-2 border-0">
            <a className="add-link" onClick={() => setShowModal(true)}>
              {" "}
              + Add Experience
            </a>
          </div>
        ) : null}
      </Accordion>
    </>
  );
};
