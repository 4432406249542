/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Taxonomy and API wiring up 
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 18/Aug/2020 
	Author						: Aditya Tijare
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TreeView from "../../../../../components/treeView/taxonomy";
import { getLoggedInUser, getProductVariant } from "../../../../../../utilities";
import { actionSuccess, showLoader, hideLoader, actionError, showMessage } from "../../../../../../middleware/actions/utilityAction";
import { processingSubmit } from "../../../../../../middleware/services/cmsApi";
import {
  fetchCompanyTaxonomy,
  saveCompanyTaxonomy,
  deleteCompanyTaxonomy,
  fetchSingleCompany,
  saveCompanyValueChain,
  fetchCompanyKia,
  deleteCompanyKia,
  saveCompanyKiaTaxonomy,
  deleteManyCompanyTechnology,
  deleteManyCompanyValueChain,
} from "../../../../../../middleware/services/companyApi";
import { fetchProductBasic } from "../../../../../../middleware/services/productApi";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import "../../../../../components/popup/index.scss";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const TaxomonyUI = (props) => {
  const [clickSaveSubmit, setClickSaveSubmit] = useState(false);
  const [updateClick, setUpdateClick] = useState(false);
  /* Common access */
  const accessActionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionSubmit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SUBMIT);

  const history = useHistory();
  const dispatch = useDispatch();
  const companyId = props.companyId;
  const loggedInUser = getLoggedInUser();
  const [saveTreeData, setSaveTreeData] = useState(false);
  const [saveTreeDataAction, setSaveTreeDataAction] = useState(false);
  const [companyProducts, setCompanyProducts] = useState([]);
  const cancelPath = props.path === "/processing/company/:companyId/edit" ? "/processing/company" : "/company";
  const [submitStatus, setSubmitStatus] = useState(false);

  const handleSubmit = (selectedTech, checkValues, tab, callback, kiaFlag) => {
    if (cancelPath === "/processing/company" || props.path === "/company/:companyId/edit" || props.path === "/data-qc/company/:companyId/edit") {
      if (companyProducts?.length) {
        //submitting logic
        saveTaxonomyProcessing(selectedTech, tab);
      } else {
        if (checkValues.length || Object.keys(checkValues).length) {
          //save and submit logic
          if (tab == "technology") {
            saveTaxonomy(selectedTech, checkValues, callback);
          }
          if (tab == "kia") {
            saveKiaTagging(selectedTech, checkValues, tab, callback);
          }
          if (tab == "valueChain") {
            handleSaveValueChain(selectedTech, checkValues, callback);
          }
        } else {
          dispatch(showMessage("Please select Taxonomy...!"));
          setSubmitStatus(false);
        }
      }
    }
  };

  const saveTaxonomy = (selectedTech, taxonomyIds, callback) => {
    if (selectedTech) {
      deleteCompanyTaxonomy(companyId, selectedTech)
        .then(() => {
          if (taxonomyIds.length > 0) {
            try {
              const response = saveCompanyTaxonomy(companyId, taxonomyIds);
              response
                .then((response) => {
                  callback();
                  dispatch(actionSuccess("Taxonomy Updated Successfully"));
                  if (submitStatus) {
                    setTimeout(() => {
                      saveTaxonomyProcessing(selectedTech, "technology");
                    }, [2000]);
                  }
                })
                .catch((e) => {
                  dispatch(actionError("Something Went Wrong...!"));
                  setSubmitStatus(false);
                });
            } catch (e) {
              dispatch(actionError("Something Went Wrong...!"));
              setSubmitStatus(false);
            }
          } else {
            dispatch(actionError("Please select taxonomy"));
            setSaveTreeData(false);
            setSaveTreeDataAction(false);
            setClickSaveSubmit(false);
            setSubmitStatus(false);
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    }
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
  };

  const saveTaxonomyProcessing = (selectedTech, tab) => {
    const payload = {
      userId: loggedInUser._id,
      dtId: selectedTech,
    };
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await processingSubmit(companyId, tab, payload)
            .then((response) => {
              dispatch(actionSuccess(`Company successfully Saved & Submitted`));
            })
            .catch((err) => {
              // console.log(err.data.message)
              dispatch(actionError(err.data.message || "Something went wrong"));
              setSubmitStatus(false);
            });
        },
        okText: "Submit",
        cancelText: "Cancel",
        content: "Are you sure you want to submit ?",
        title: "dialogAlertCss",
      })
    );
    setClickSaveSubmit(false);
    setUpdateClick(false);
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
    setSubmitStatus(false);
  };
  /*
	@param selectedIndustry : Selected Industry to save their respective kia
	@param kiaIds : Selected KiaIds
	@param callback : use for re-render tree component
	
	@description : Save kia for respective industry
				1.Delete all kia for selected industry
				2.Save all kia for selected industry
	*/
  const saveKiaTagging = (selectedIndustry, kiaIds, tab, callback) => {
    if (selectedIndustry) {
      dispatch(showLoader());
      deleteCompanyKia(companyId, { industryId: selectedIndustry })
        .then(() => {
          dispatch(hideLoader());
          if (kiaIds.length > 0) {
            dispatch(showLoader());
            try {
              const response = saveCompanyKiaTaxonomy(companyId, {
                taxonomyIds: kiaIds,
              });
              response
                .then((response) => {
                  callback();
                  if (submitStatus) {
                    setTimeout(() => {
                      saveTaxonomyProcessing(selectedIndustry, "kia");
                    }, [2000]);
                  }
                  dispatch(hideLoader());
                  dispatch(actionSuccess("KIA Updated Successfully"));
                })
                .catch((e) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...!"));
                  setSubmitStatus(false);
                });
            } catch (e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
              setSubmitStatus(false);
            }
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    }
    setClickSaveSubmit(false);
    setUpdateClick(false);
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
  };
  const handleSaveValueChain = (selectedTech, checkValues, callback1, callback2) => {
    dispatch(showLoader());
    const response = saveCompanyValueChain(companyId, { ...checkValues });
    response
      .then((response) => {
        dispatch(hideLoader());
        callback1();
        // callback2();
        if (submitStatus) {
          setTimeout(() => {
            saveTaxonomyProcessing(selectedTech, "valueChain");
          }, [2000]);
        }
        dispatch(actionSuccess("Value Chain Updated Successfully"));
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(actionError("Something Went Wrong...!"));
        setSubmitStatus(false);
      });
    setClickSaveSubmit(false);
    setUpdateClick(false);
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
  };
  const fetchTagging = () => {
    fetchCompanyTaxonomy(companyId).then((res) => {
      // console.log(res.data, "tree", props.loadOtherTagInfo, "tagging info");
    });
  };
  const fetchProductBasicWithCompany = () => {
    let defaultFilter = [["productCompany.companyId", "eq", props.companyId]];
    const productVariant = getProductVariant();
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let params = {};
    params.fields = ["productCompany", "productName"];
    params.filters = [...defaultFilter];
    fetchProductBasic({ ...params })
      .then((res) => {
        if (res) {
          const { data = [] } = res.data;
          setCompanyProducts([...data]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteTechnology = (checkedTrends, callback) => {
    deleteManyCompanyTechnology(companyId, checkedTrends)
      .then((res) => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };
  const deleteKia = (checkedTrends, callback) => {
    let payload = { taxonomyIds: checkedTrends };
    deleteCompanyKia(companyId, payload)
      .then(() => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  const deleteValueChain = (payload, callback) => {
    deleteManyCompanyValueChain(companyId, payload)
      .then(() => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  useEffect(() => {
    fetchTagging();
    fetchProductBasicWithCompany();
  }, []);

  return (
    <div style={{ backgroundColor: "#f2f2f2", overflow: "hidden" }}>
      <TreeView
        handleSave={saveTaxonomy}
        saveTreeData={saveTreeData}
        contentId={companyId}
        fetchContentTagging={fetchCompanyTaxonomy}
        fetchContentData={fetchSingleCompany}
        loadOtherTagInfo={props.loadOtherTagInfo}
        fetchKiaTagging={fetchCompanyKia}
        handleSaveKIA={saveKiaTagging}
        handleSaveValueChain={handleSaveValueChain}
        contentType="company"
        deleteTechnology={deleteTechnology}
        isDelete={companyProducts?.length ? false : true}
        setSaveTreeData={setSaveTreeData}
        deleteKia={deleteKia}
        deleteValueChain={deleteValueChain}
        accessPermissionTaxonomyDelete={accessActionDelete}
        accessPermissionTaxonomy={accessActionAdd}
        handleSubmit={handleSubmit}
        submitStatus={submitStatus}
        setSubmitStatus={setSubmitStatus}
      />
      <div className="form-submit-box" style={{ padding: "10px 18px 10px 20px" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="float-left col-md-6 text-left">
              {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                <span className="pl-4" style={{ marginLeft: "-3em" }}>
                  <span className="span-link">
                    <button
                      type="button"
                      className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                      onClick={() => {
                        (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                          (props.selectedIndex < 5 && props.setCurrentTab(props.selectedIndex - 1));
                      }}
                    >
                      Previous
                    </button>
                  </span>
                </span>
              ) : null}
            </div>
            <div className="float-right col-md-6 text-right">
              <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3 mr-3" onClick={() => history.push(`${cancelPath}`)}>
                Cancel
              </button>
              {accessActionSubmit ? (
                <>
                  {cancelPath === "/processing/company" ? (
                    <button
                      type="button"
                      className="btn btn-primary cy-btn pl-2 pr-2"
                      onClick={() => {
                        if (companyProducts.length < 1) {
                          clickSaveSubmit ? setClickSaveSubmit(false) : setClickSaveSubmit(true);
                        } else {
                          setSaveTreeDataAction("saveAndSubmit");
                        }
                      }}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary cy-btn pl-2 pr-2"
                      onClick={() => {
                        setSubmitStatus(true);
                      }}
                    >
                      Submit
                    </button>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxomonyUI;
