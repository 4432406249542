/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company State
----------------------------------------------------------------
	Creation Details
	Date Created				: 14/Oct/2020
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import {
	SET_NEWS_BASIC_TAB,
	SET_NEWS_OVERVIEW_UPDATE_STATUS,
	SET_NEWS_PUBLISH_CLICK_STATUS
} from "../../constants/actionConstants";

const NEWS_STATE = {
	updateStatus: false,
	clickStatus: false,
	taxonomyCheckedNodes: [],
	taxonomyUnCheckedNodes: [],
	kiaCheckedNodes: [],
	kiaUnCheckedNodes: [],
	valueChainCheckedNodes: [],
	valueChainUnCheckedNodes: [],
	basicTabState: {}
};

export default (state = NEWS_STATE, action) => {
	switch (action.type) {
		case SET_NEWS_OVERVIEW_UPDATE_STATUS: {
			return { ...state, updateStatus: action.payload.updateStatus };
		}
		case SET_NEWS_PUBLISH_CLICK_STATUS: {
			return { ...state, clickStatus: action.payload.clickStatus };
		}
		case 'SET_NEWS_TAXONOMY_CHECKED_NODES': {
			return { ...state, taxonomyCheckedNodes: action.payload };
		}
		case 'SET_NEWS_TAXONOMY_UNCHECKED_NODES': {
			return { ...state, taxonomyUnCheckedNodes: action.payload };
		}
		case 'SET_NEWS_KIA_CHECKED_NODES': {
			return { ...state, kiaCheckedNodes: action.payload };
		}
		case 'SET_NEWS_KIA_UNCHECKED_NODES': {
			return { ...state, kiaUnCheckedNodes: action.payload };
		}
		case 'SET_NEWS_VALUE_CHAIN_CHECKED_NODES': {
			return { ...state, valueChainCheckedNodes: action.payload };
		}
		case 'SET_NEWS_VALUE_CHAIN_UNCHECKED_NODES': {
			return { ...state, valueChainUnCheckedNodes: action.payload };
		}
		case 'SET_RESET_REDUX': {
			return {
				taxonomyCheckedNodes: [],
				taxonomyUnCheckedNodes: [],
				kiaCheckedNodes: [],
				kiaUnCheckedNodes: [],
				valueChainCheckedNodes: [],
				valueChainUnCheckedNodes: [],
			};
		}
		case SET_NEWS_BASIC_TAB: {
			return { ...state, basicTabState: action.payload };
		}
		default:
			return state;
	}
};
