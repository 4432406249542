/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : KIA Listing Page 
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/June/2021
    Author						: Sandeep K. Sharma
================================================================
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CommonDataGrid from "../../../../components/dataGrid";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import {
  getCurrentChallenges,
  deleteSingleCurrentChallenge,
  deleteSelectedCurrentChallenge,
  fetchSingleKia,
} from "../../../../../middleware/services/kiaApi";
import { decodeHTML, getDateFromMongoDate } from "../../../../../utilities";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { StringFilter } from "../../../../components/dataGrid/fliters";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import AddCurrentChallengeModel from "./add";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";
import AccessDenied from "../../../../components/accessDenied";
import "../../../kia/index.scss";

const CurrentChallengeListing = (props) => {
  const { accessRights: aR } = props;
  const [deleteId, setDeleteId] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [chgId, setChgId] = useState("");
  const [editData, setEditData] = useState(null);
  const kiaId = props.kiaId;

  /* Common access */
  const accessActionAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  const breadCrumbLinks = [
    { linkUrl: "/kia", linkName: aR.moduleName, linkActive: false },
    { linkUrl: kiaId ? `/kia/${kiaId}/edit` : "//kia/add", linkName: (editData && editData?.kiaName) || "", linkActive: false },
    {
      linkUrl: `/kia/ ${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];
  const closeModal = () => {
    setChgId("");
    setShowModal(false);
  };
  /*
       @Description : Fetch Single KIA Details
   */
  const fetchKiaDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "kiaName",
      "kiaDescription",
      "kiaCurrentResearchStartups",
      "customKia",
      "kiaStatus",
      "kiaNextUpdateDate",
      "kiaClientUsers",
      "kiaIndustryTag",
      "kiaScore",
      "kiaExpert",
      "kiaExpertNetworkStrength",
      "kiaImpactAreaSurveyContributors",
      "kiaClientExpertEngagements",
    ];
    params.fields = fields;
    if (props.productVariant) defaultFilter.push(["productVariant.productName", "eq", props.productVariant]);
    params.filters = [...defaultFilter];

    fetchSingleKia(kiaId, params).then((res) => {
      let data = res.data.data || {};
      if (data) {
        setTimeout(() => {
          setEditData(data);
        }, 200);
      }
    });
  };
  useEffect(() => {
    if (kiaId) {
      !editData && fetchKiaDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/kia"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, props.kiaId, props.selectedIndex, editData, props.location.hash]);

  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        Edit={
          accessActionEdit
            ? () => {
                showModal && setShowModal(false);
                setShowModal(true);
                setChgId(value.id);
              }
            : undefined
        }
        Delete={
          accessActionDelete
            ? () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteSingleCurrentChallenge(value.id)
                        .then((response) => {
                          if (deleteId) setDeleteId(undefined);
                          setDeleteId(new Date());
                          dispatch(actionSuccess("Current Challenge deleted successfully"));
                          fetchCurrentChallenges().then((v) => {
                            formatRowData(v.data.data);
                          });
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Failed to delete video"));
                        });
                    },
                    okText: "Delete",
                    cancelText: "Cancel",
                    content: "Are you sure you want to delete?",
                    title: "dialogAlertCssWarning",
                  })
                );
              }
            : undefined
        }
      />
    );
  };

  const deleteManyFunc = (flag) => {
    const selectedCurrentChallenges = localStorage.getItem("selectedCurrentChallenges")
      ? JSON.parse(localStorage.getItem("selectedCurrentChallenges"))
      : [];
    if (flag) {
      if (selectedCurrentChallenges.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteSelectedCurrentChallenge({ chgIds: selectedCurrentChallenges })
                .then((response) => {
                  if (response) {
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    fetchCurrentChallenges().then((v) => {
                      formatRowData(v.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete Current Challenges"));
                });
            },
            okText: "Delete",
            cancelText: "Cancel",
            content: "Are you sure you want to delete?",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };

  /* 
        @Description : Date Template 
    */
  const dateTemplate = (value) => {
    const column = value.column.field;
    return ["Published Date", "Created Date"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
      <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
    ) : (
      ""
    );
  };

  /* 
        @Description : Remove HTML Tags 
    */

  const removeHtmlTagsAndEntities = (text) => {
    // Create a temporary DOM element to decode HTML entities
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;

    // Use the textContent property to get the decoded text
    const decodedText = tempElement.textContent || tempElement.innerText || "";

    // Remove any remaining HTML tags
    return decodedText.replace(/<\/?[^>]+(>|$)/g, "");
  };

  /*
         @Description : Template for Description 
      */
  const DescriptionTemplate = (value) => {
    let colDescValue =
      value.column.field === "Potential Benefit"
        ? value["Potential Benefit"]
        : value.column.field === "Challenge"
        ? value["Challenge"]
        : value["Description"];
    let desc = decodeHTML(colDescValue);
    return <span>{desc.substr(0, 150) + "\u2026"}</span>;
  };

  const columnNames = {
    id: "id",
    Challenge: "challenge",
    "Challenge Level": "challengeLevel",
    Description: "challangeDescription",
    "Potential Benefit": "challangePotentialBenefit",
    "Time Scale": "challengeTimescale",
    "Created Date": "challangeCreatedDate",
  };

  const columnFields = [
    {
      field: "id",
      type: "String",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    { field: "Challenge", type: "String", filterTemplate: StringFilter, textAlign: "Left", showInColumnChooser: false },
    { field: "Challenge Level", type: "String", filter: { operator: "equal" }, textAlign: "Left", showInColumnChooser: true },
    // { field: "Description",template:DescriptionTemplate, type: "String",  filter: { operator: "equal" }, textAlign: "Left", width: 110, headerTextAlign: "Left", showInColumnChooser: true },
    // { field: "Potential Benefit",template:DescriptionTemplate, type: "String", filter: { operator: "equal" }, textAlign: "Left", width: 110, headerTextAlign: "Left", showInColumnChooser: true },
    {
      field: "Time Scale",
      type: "String",
      filter: { operator: "equal" },
      textAlign: "Center",
      width: 190,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
    {
      field: "Created Date",
      template: dateTemplate,
      type: "Date",
      textAlign: "Center",
      width: 170,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
  ];
  if (accessActionEdit || accessActionDelete) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false, textAlign: "Center" });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      filterTemplate: StringFilter,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }
  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    rowData.forEach((rD) => {
      let data = {};
      data["id"] = rD.id;
      data["Challenge"] = removeHtmlTagsAndEntities(rD.challenge);
      data["Challenge Level"] = rD.challengeLevel;
      data["Description"] = rD.challangeDescription;
      data["Potential Benefit"] = rD.challangePotentialBenefit;
      data["Time Scale"] = rD.challengeTimescale;
      data["Created Date"] = new Date(rD.challengeCreatedDate);
      formatedRowData.push(data);
    });
    return formatedRowData;
  };

  const fetchCurrentChallenges = () => {
    const params = {};
    params.limit = 100;
    params.fields = [
      "challenge",
      "challengeLevel",
      "challangeDescription",
      "challangePotentialBenefit",
      "challengeTimescale",
      "challengeCreatedDate",
    ];
    params.filters = [];
    return getCurrentChallenges(props.kiaId, params);
  };
  return (
    <>
      {props?.interfaceAccess ? (
        <>
          {showModal && (
            <AddCurrentChallengeModel showModal={showModal} closeModal={closeModal} kiaId={props.kiaId} chgId={chgId} setDeleteId={setDeleteId} />
          )}
          <div className="gennx-content-wrapper content-wrapper pt-3 mx-2 pr-2 ">
            <div className="gennx-grid-container-custom">
              <CommonDataGrid
                gridTitle="Current Challenges"
                fetch={fetchCurrentChallenges}
                columnNames={columnNames}
                columnFields={columnFields}
                formatRowData={formatRowData}
                deleteId={deleteId}
                getMultiSelectedRows={(data) => {
                  localStorage.setItem("selectedCurrentChallenges", JSON.stringify([...data]));
                }}
                getMultiDeSelectedRows={(data) => {
                  localStorage.setItem("selectedCurrentChallenges", JSON.stringify([...data]));
                }}
                addNew={() => setShowModal(true)}
                addNewRight={accessActionAddNew}
                deleteRight={accessActionDelete}
                deleteMany={accessActionDelete ? deleteManyFunc : false}
                isChildGrid={true}
              />
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default CurrentChallengeListing;
