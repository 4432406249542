/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  People Education Popup Component for Add/Edit
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 27/Apr/2021
    Author						: Sandeep Sharma
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { educationValidationSchema } from "../../../../helper/validationSchema/externalUserValidationSchema";
import { TextField, Dropdown, MultiSelect } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { fetchEducationalFieldData } from "../../../../../middleware/services/cmsApi";
import { saveEducation, updateEducation, fetchSingleEducation } from "../../../../../middleware/services/userApi";

const initialFormValues = {
  eduInstituteName: "",
  eduQualification: "",
  eduPassingYear: "",
  eduField: "",
};
const AddEducation = (props) => {
  const dispatch = useDispatch();
  const [passingYearData, setPassingYearData] = useState([]);
  const [edu, setEdu] = useState({});
  const [educationalFields, setEducationalFields] = useState([]);

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(educationValidationSchema),
  });
  /**
   * @description Fetch education field
   */
  const fetchEducationalField = () => {
    fetchEducationalFieldData({ sort: "field:asc" })
      .then((res) => {
        const { data = [] } = res.data;
        setEducationalFields(data.map((d) => d.field));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Educational Field Failed";
        dispatch(actionError(errMsg));
      });
  };
  //Function for validate form and save Education
  const addEducation = () => {
    //Submit Form and send data to API
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          // dispatch(actionError(res[field]));
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const { eduInstituteName, eduQualification, eduPassingYear, eduField } = formik.values;
        payload.eduInstituteName = eduInstituteName;
        payload.eduQualification = eduQualification;
        payload.eduPassingYear = eduPassingYear;
        payload.eduField = eduField;

        if (props.eduId) {
          updateEducation(props.userId, props.eduId, payload)
            .then((response) => {
              if (response.status === 200) {
                props.closeModal();
                dispatch(actionSuccess("Education details updated successfully"));
                props.formatRowData(props.userId);
              } else {
                dispatch(actionError(response.data.message || "Someting went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Someting went wrong"));
            });
        } else {
          saveEducation(props.userId, payload)
            .then((response) => {
              if (response.status === 200) {
                props.closeModal();
                dispatch(actionSuccess("Education details added successfully"));
                props.formatRowData(props.userId);
              } else {
                dispatch(actionError(response.data.message || "Someting went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Someting went wrong"));
            });
        }
      }
    });
  };
  useEffect(() => {
    fetchEducationalField();
    if (props.userId === "") {
      props.closeModal();
      dispatch(actionError("Please add basic details first"));
    }
    const year = new Date().getFullYear();
    const years = Array.from(new Array(50), (val, index) => year - index);
    setPassingYearData(years);
    if (props.eduId !== "") {
      const response = fetchSingleEducation(props.userId, props.eduId);
      response.then((response) => {
        if (response.status === 200) {
          var education = response.data.data;
          formik.setValues({
            eduInstituteName: education.eduInstituteName,
            eduQualification: education.eduQualification,
            eduPassingYear: education.eduPassingYear,
            eduField: education.eduField,
          });
          setEdu(education);
        }
      });
    }
  }, [props.showModal]);

  return (
    <Modal show={props.showModal} backdrop="static" className="modal fade lg people-mgmt" aria-labelledby="contained-modal-title-vcenter" centered>
      <form id="addEducation" method="post">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {props.eduId ? "Update" : "Add"} Education
          </h5>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <TextField
                formik={formik}
                id="eduInstituteName"
                name="eduInstituteName"
                placeholder="University /Institute Name *"
                value={edu.eduInstituteName}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextField formik={formik} id="eduQualification" name="eduQualification" placeholder="Qualification *" value={edu.eduQualification} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {educationalFields.length > 0 && (
                <MultiSelect
                  formik={formik}
                  mode="Box"
                  sortOrder="Acending"
                  showSelectAll={true}
                  // selectAllText="Select All"
                  showClearButton={true}
                  // enableRtl={true}
                  placeholder="Educational Field/Stream *"
                  id="eduField"
                  name="eduField"
                  value={formik.values.eduField}
                  allowCustomValue={false}
                  // fields={{ text: "field", value: "id" }}
                  dataSource={educationalFields}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Dropdown
                formik={formik}
                id="eduPassingYear"
                name="eduPassingYear"
                dataSource={passingYearData}
                placeholder="Passing Out Year *"
                value={props.eduId !== "" ? parseInt(formik.values.eduPassingYear) : ""}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-dark cy-btn mr-3"
            onClick={() => {
              props.closeModal();
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary cy-btn" onClick={addEducation}>
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddEducation;
