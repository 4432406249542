/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  People overview - Add | Edit Overview
     1. Import all people overview element from customFormFields
     2. Used Formik and Yup Library for validation
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 14/Apr/2021
    Author						: Sandeep K Sharma
================================================================ 
*/

import React, { useState, useEffect, useRef } from "react";
import * as REGEX from "../../../../../config/regexValidation";
import peopleImage from "../../../../../assets/images/user.jpg";
import Accordion from "../../../../components/accordion";
import { TextBoxComponent, FormValidator } from "@syncfusion/ej2-react-inputs";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { CountrySelectBox } from "../../customFormFields";
import { useHistory } from "react-router-dom";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";

const OverviewForm = (props) => {
  const [peopleImageSrc, setPeopleImageSrc] = useState(undefined);
  const peopleDefaultImageRef = useRef(null);
  const peopleImageRef = useRef(null);
  const [imageError, setImageError] = useState("");
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const history = useHistory();

  /*
       @Description : Set default Image 
    */
  useEffect(() => {
    setPeopleImageSrc(props.peopleImage);
    if (props.peopleImage) {
      setIsDefaultImage(false);
    }
  }, [props.peopleImage, isDefaultImage]);

  /*
        @Description : Save People data if everything is valid
    */
  const savePeople = () => {
    const options = {
      // add the rules for validation
      rules: {
        peopleFirstName: {
          required: [true, "First Name is required"],
        },
        peopleLastName: {
          required: [true, "Last Name is required"],
        },
        peopleContactEmail: {
          required: [true, "Email ID is required"],
          email: [true, "Email ID is invalid"],
        },
        peopleContactMobileNo: {
          number: [true, "Mobile No. is invalid"],
        },
        peopleContactTelNo: {
          number: [true, "Telephone No. is invalid"],
        },
        peopleSocialLinkedIn: {
          regex: [REGEX.LINKEDIN, "Only valid LinkedIn profile URL are allowed"],
        },
        peopleSocialFacebook: {
          regex: [REGEX.FACEBOOK, "Only valid Facebook profile URL are allowed"],
        },
        peopleSocialTwitter: {
          regex: [REGEX.TWITTER, "Only valid Twitter profile URL are allowed"],
        },
      },
    };
    var formObject = new FormValidator("#addPeople", options);
    //Submit Form and send data to API
    if (formObject.validate()) {
      const form = document.forms.addPeople;
      const formData = new FormData(form);
      formData.delete("peopleProfileImage");
      if (peopleDefaultImageRef.current.files.length) {
        formData.append("peopleProfileImage", peopleDefaultImageRef.current.files[0]);
      } else if (props.peopleId != "" && peopleImageSrc == undefined) {
        formData.append("peopleProfileImage", "");
      }
      props.handleSaveOverview(formData);
    }
  };

  /*
        @Description : Read People image
    */
  const readProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      peopleImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  return (
    <Accordion heading="Basic Details" step={1} stepCompleted={props.stepCompleted} activeState={true}>
      <form id="addPeople" method="post">
        <div className="p-2">
          <div className="col-md-8 pt-4">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleFirstName"
                    name="peopleFirstName"
                    placeholder="First Name *"
                    cssClass="e-outline"
                    data-msg-containerid="firstNameError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleFirstName}
                  />
                  <div id="firstNameError" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleLastName"
                    name="peopleLastName"
                    placeholder="Last Name *"
                    cssClass="e-outline"
                    data-msg-containerid="lastNameError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleLastName}
                  />
                  <div id="lastNameError" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="people-image-holder mb-1">
                  <img width="150" height="150" src={peopleImageSrc || peopleImage} alt="People Image" ref={peopleImageRef} />
                  <div className="image-action" style={{ top: 0, display: "block" }}>
                    <span onClick={() => peopleDefaultImageRef.current.click()}>
                      <i className="fa fa-pencil-alt " aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="text-danger" style={{ display: imageError ? "block" : "none" }} role="alert">
                    <small>{imageError}</small>
                  </div>
                </div>
                <input
                  type="file"
                  className="d-none"
                  accept=".jpg, .png"
                  id="peopleProfileImage"
                  name="peopleProfileImage"
                  ref={peopleDefaultImageRef}
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      if (
                        (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/jpeg") &&
                        e.target.files[0].size <= 1024000
                      ) {
                        readProfileImage(e.target.files[0]);
                        peopleImageRef.current.setAttribute("src", e.target.files[0]);
                        setImageError("");
                        setIsDefaultImage(false);
                      } else {
                        if (e.target.files[0].size >= 1024000) {
                          setImageError(` Max file size should
                                                            be 1000 Kb. Uploaded file size is ${(e.target.files[0].size / 1024).toFixed(2)} Kb`);
                        } else {
                          setImageError(`Only JPG, PNG files are allowed.`);
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleContactEmail"
                    name="peopleContactEmail"
                    placeholder="Email ID *"
                    cssClass="e-outline"
                    data-msg-containerid="emailIdError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleContactEmail}
                  />
                  <div id="emailIdError" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleContactMobileNo"
                    name="peopleContactMobileNo"
                    placeholder="Mobile "
                    cssClass="e-outline"
                    data-msg-containerid="mobileError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleContactMobileNo}
                  />
                  <div id="mobileError" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleContactTelNo"
                    name="peopleContactTelNo"
                    placeholder="Telephone no. "
                    cssClass="e-outline"
                    data-msg-containerid="telError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleContactTelNo}
                  />
                  <div id="telError" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleSocialTwitter"
                    name="peopleSocialTwitter"
                    placeholder="Twitter"
                    cssClass="e-outline"
                    data-msg-containerid="twitterError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleSocialTwitter}
                  />
                  <div id="twitterError" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleSocialLinkedIn"
                    name="peopleSocialLinkedIn"
                    placeholder="LinkedIn"
                    cssClass="e-outline"
                    data-msg-containerid="linkedinError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleSocialLinkedIn}
                  />
                  <div id="linkedinError" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleSocialFacebook"
                    name="peopleSocialFacebook"
                    placeholder="Facebook"
                    cssClass="e-outline"
                    data-msg-containerid="facebookError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleSocialFacebook}
                  />
                  <div id="facebookError" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2">
          <div className="col-md-8">
            <label style={{ fontWeight: 600 }}>Address Details</label>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <CountrySelectBox id="peopleCountry" name="peopleCountry" placeholder="Country" value={props.peopleInformation.peopleCountry} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleState"
                    name="peopleState"
                    placeholder="State"
                    cssClass="e-outline"
                    data-msg-containerid="stateError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleState}
                  />
                  <div id="stateError" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleCity"
                    name="peopleCity"
                    placeholder="City"
                    cssClass="e-outline"
                    data-msg-containerid="cityError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleCity}
                  />
                  <div id="cityError" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleAreaName"
                    name="peopleAreaName"
                    placeholder="Area"
                    cssClass="e-outline"
                    data-msg-containerid="areaError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleAreaName}
                  />
                  <div id="areaError" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleRegion"
                    name="peopleRegion"
                    placeholder="Region"
                    cssClass="e-outline"
                    data-msg-containerid="regionError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleRegion}
                  />
                  <div id="regionError" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <TextBoxComponent
                    id="peopleAddressLine"
                    name="peopleAddressLine"
                    placeholder="Address Line"
                    cssClass="e-outline"
                    data-msg-containerid="addressError"
                    floatLabelType="Auto"
                    value={props.peopleInformation.peopleAddressLine}
                  />
                  <div id="addressError" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <RadioButtonComponent
                    label="Home"
                    value="home"
                    checked={props.peopleInformation.peopleLocationType === "home"}
                    name="peopleLocationType"
                  />
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <RadioButtonComponent
                    label="Office"
                    value="office"
                    checked={props.peopleInformation.peopleLocationType === "office"}
                    name="peopleLocationType"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group float-right" style={{ "margin-right": "0.8em" }}>
                  <button
                    type="button"
                    className="btn btn-outline-dark cy-btn mr-3"
                    onClick={() => {
                      history.push(`/people`);
                    }}
                  >
                    Cancel
                  </button>
                  {props?.accessActionSave ? (
                    <button type="button" className="btn btn-primary cy-btn mr-3" onClick={savePeople}>
                      &nbsp;&nbsp;Save&nbsp;&nbsp;
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Accordion>
  );
};

export default OverviewForm;
