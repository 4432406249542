import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import DataQcList from "../app/views/dataQc/company";
import ProductForm from "../app/views/product/component";
import CompanyTabs from "../app/views/dataQc/company/companyTabs";

const REMARK = ["Remark:VIEW", "Remark:FULL"];
const QC_COMPLETE = ["Quality Check:VIEW", "Quality Check:FULL"];
const SEND_BACK = ["Send Back:VIEW", "Send Back:FULL"];
const REJECT = ["Reject:VIEW", "Reject:FULL"];

const companyRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/data-qc/company/:companyId/edit"
      component={CompanyTabs}
      pageHeading="GenNx CMS - Company - Quality Check"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...REMARK, ...QC_COMPLETE, ...SEND_BACK, ...REJECT],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/data-qc/company"
      component={DataQcList}
      pageHeading="GenNx CMS - Company - Quality Check"
      accessRights={{
        moduleName: "Quality Check",
        parentModuleName: "Company",
        moduleAttribute: [...REMARK, ...QC_COMPLETE, ...SEND_BACK, ...REJECT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/data-qc/company/:companyId/product/:productId/edit"
      component={ProductForm}
      pageHeading="GenNx CMS - Product - Quality Check"
      accessRights={{
        moduleName: "Products & Technology",
        parentModuleName: "Company",
        moduleAttribute: [...REMARK, ...QC_COMPLETE, ...SEND_BACK, ...REJECT],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/data-qc/company/:companyId/product/add"
      component={ProductForm}
      pageHeading="GenNx CMS - Product - Quality Check"
      accessRights={{
        moduleName: "Products & Technology",
        parentModuleName: "Company",
        moduleAttribute: [...REMARK, ...QC_COMPLETE, ...SEND_BACK, ...REJECT],
        interfaceName: "Basic",
      }}
    />

    <AuthLayout
      exact
      path="/data-qc/company/add"
      component={CompanyTabs}
      pageHeading="GenNx CMS - Company"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...REMARK, ...QC_COMPLETE, ...SEND_BACK, ...REJECT],
        interfaceName: "Basic",
      }}
    />
  </Switch>
);

export default companyRoutes;
