/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Service Requests Listing Page 
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/June/2021
    Author						: Sandeep K. Sharma
================================================================
*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonDataGrid from "../../components/dataGrid";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { fetchServices, deleteSingleService, updateService, publishService } from "../../../middleware/services/servicesApi";
import { swap, getProductVariant, getDateFromMongoDate, getLoggedInUser, fetchClientUsers } from "../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import ThreeDotMenu from "../../components/threeDotMenu";
import "./index.scss";
import { SERVICE_TYPE_OPTIONS } from "../services/component/formFieldOptions";
import { setMaintainedState, visitRecord } from "../../../middleware/actions/gridAction";
import { COMMON_ACCESS_ACTION } from "../../../constants";

const ServicesListing = (props) => {
  const { accessRights: aR } = props;
  const [deleteId, setDeleteId] = useState(undefined);
  const [usersData, setUsersData] = useState(undefined);
  const history = useHistory();
  const dispatch = useDispatch();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const productVariant = getProductVariant();
  const loggedInUser = getLoggedInUser();
  const gridState = useSelector((state) => state.gridState);

  /* Common access */
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionPublish = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.PUBLISH);

  /*
       @Description : BreadCumb Defination
   */
  const breadCrumbLinks = [{ linkUrl: "/services", linkName: aR.moduleName, linkActive: true }];
  const fetchUsersData = async () => {
    setUsersData(await fetchClientUsers());
  };

  /*
       @Description : Three Dot Menu Action
   */
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                history.push(`/services/${value.id}/edit`, { serviceId: value.id });
                dispatch(visitRecord());
              },
            }
          : {})}
        {...(accessActionDelete
          ? {
              Delete: () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteSingleService(value.id)
                        .then((response) => {
                          if (deleteId) setDeleteId(undefined);
                          setDeleteId(new Date());
                          dispatch(actionSuccess("Service deleted successfully"));
                          const params = {};
                          params.limit = 100;
                          if (productVariant) params.filters = [["productVariant.productName", "eq", productVariant]];
                          fetchServices(params).then((v) => {
                            formatRowData(v.data.data);
                          });
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Failed to delete video"));
                        });
                    },
                    okText: "Delete",
                    cancelText: "Cancel",
                    content: "Are you sure you want to delete ?",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
        {...(accessActionPublish
          ? {
              Publish: () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      let payload = {};
                      payload.servicePublishedBy = { userId: loggedInUser._id, userFname: loggedInUser.userFname, userLname: loggedInUser.userLname };
                      publishService(value.id, payload)
                        .then((response) => {
                          if (deleteId) setDeleteId(undefined);
                          setDeleteId(new Date());
                          dispatch(actionSuccess("Service Published successfully"));
                          const params = {};
                          params.limit = 100;
                          if (productVariant)
                            params.filters = [
                              ["productVariant.productName", "eq", productVariant],
                              ["serviceIsDelete", "eq", "NO"],
                            ];
                          fetchServices(params).then((v) => {
                            formatRowData(v.data.data);
                          });
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Failed to delete video"));
                        });
                    },
                    okText: "Publish",
                    cancelText: "Cancel",
                    content: "Are you sure you want to publish ?",
                    title: "dialogAlertCss",
                  })
                );
              },
            }
          : {})}
      />
    );
  };

  /*
       @Description : Template for Kia Name
   */
  const serviceUniqueTitleTemplate = (value) => {
    return (
      (
        <span
          className="span-link"
          onClick={() => {
            history.push(`/services/${value.id}/edit`);
            dispatch(visitRecord());
          }}
        >
          {value["Unique No"]}
        </span>
      ) || <span>{value["Unique No"]}</span>
    );
  };

  /* 
        @Description : Date Template 
    */
  const dateTemplate = (value) => {
    const column = value.column.field;
    return ["Published Date", "Created Date"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
      <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
    ) : (
      ""
    );
  };

  /* 
        @Description : Filters Template 
    */
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Published":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Service Type":
        sourceData = SERVICE_TYPE_OPTIONS;
        break;
      case "Status":
        sourceData = [
          { id: "Under Review", text: "Under Review" },
          { id: "Complete", text: "Complete" },
          { id: "Draft", text: "Draft" },
          { id: "In Progress", text: "In Progress" },
        ];
        break;
      case "Active":
        sourceData = [
          { id: "Yes", text: "Yes" },
          { id: "No", text: "No" },
        ];
        break;
      case "Client Users":
        sourceData = usersData;
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  /* 
        @Description : Template  for Yes No Button
    */

  const updateVisibilty = async (serviceId, payload) => {
    await updateService(serviceId, payload)
      .then((res) => {
        if (res) {
          setDeleteId(new Date());
          payload.serviceIsActive === "Yes"
            ? dispatch(actionSuccess("Customer Service is activated"))
            : dispatch(actionSuccess("Customer Service is de-activated"));
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
    setDeleteId(new Date());
  };

  const statusTemplate = (value) => {
    return (
      <div className="text-center" style={{ marginLeft: "30px" }}>
        <label className="custom-switch3">
          <input
            type="checkbox"
            value={value["Active"]}
            checked={value["Active"] == "Yes" ? true : false}
            onChange={(e) => {
              let payload = {
                serviceIsActive: value["Active"] == "Yes" ? "No" : "Yes",
              };
              updateVisibilty(value.id, payload);
            }}
          />
          <div>
            <span className="on">Yes</span>
            <span className="off">No</span>
          </div>
          <i></i>
        </label>
      </div>
    );
  };

  const columnNames = {
    id: "id",
    "Unique No": "serviceUniqueCode",
    "Service Subject": "serviceSubject",
    "Service Status": "serviceStatus",
    "Client Users": "serviceUsers.userId",
    "Created Date": "serviceCreatedDate",
    "Published Date": "servicePublishedDate",
    Status: "serviceStatus",
    Active: "serviceIsActive",
    Published: "serviceIsPublished",
    "Service Type": "serviceType",
  };

  const columnFields = [
    {
      field: "id",
      type: "string",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "Unique No",
      type: "String",
      template: serviceUniqueTitleTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      width: 150,
      headerTextAlign: "Left",
      showInColumnChooser: false,
    },
    {
      field: "Service Subject",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      disableHtmlEncode: false,
      width: 250,
    },
    {
      field: "Service Type",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: 140,
    },
    {
      field: "Client Users",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "contains" },
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: 180,
    },
    {
      field: "Status",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      width: 120,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
    {
      field: "Active",
      type: "String",
      template: statusTemplate,
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      headerTextAlign: "Center",
      textAlign: "Center",
      width: 140,
      showInColumnChooser: true,
    },
    {
      field: "Created Date",
      template: dateTemplate,
      type: "Date",
      textAlign: "Center",
      width: 175,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
    { field: "Published Date", type: "Date", template: dateTemplate, width: 175, headerTextAlign: "Center", textAlign: "Center" },
    {
      field: "Published",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 150,
    },
  ];
  if (accessActionEdit || accessActionDelete || accessActionPublish) {
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      filterTemplate: StringFilter,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }
  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
        return newRD;
      });

      if (rD?.serviceUsers) {
        const ids = rD?.serviceUsers.map((u) => {
          return u.userId;
        });

        const userData = usersData.filter((item) => {
          return ids.includes(item.id);
        });
        newRD["Client Users"] = userData
          .map((item) => {
            return item.text;
          })
          .filter((t, index, self) => t !== "" && self.indexOf(t) === index)
          .join(", ");
      } else {
        newRD["Client Users"] = "";
      }

      newRD["Status"] = rD.serviceStatus;
      newRD["Published"] = rD.serviceIsPublished;
      newRD["Unique No"] = rD.serviceUniqueCode;
      newRD["Active"] = rD.serviceIsActive;
      newRD["Created Date"] = new Date(rD.serviceCreatedDate);
      newRD["Published Date"] = new Date(rD.servicePublishedDate);
      return newRD;
    });
    return formatedRowData;
  };

  const getCustomerServices = (params = {}) => {
    let defaultFilter = [["productVariant.productName", "eq", productVariant]];
    // let fields = ["serviceUniqueCode", "serviceSubject", "serviceType", "serviceUsers", "serviceStatus", "serviceCreatedDate", "servicePublishedDate", "serviceIsActive", "serviceIsPublished"]
    let { filters = [] } = params;
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (!params.sort) params.sort = "serviceCreatedDate:desc";
    if (params.filters) {
      filters = params.filters.map((item) => {
        const tech = usersData.filter((f) => {
          return item[0] === "serviceUsers.userId" && f.text === item[2];
        });
        return item[0] === "serviceUsers.userId" ? [item[0], "in", [tech[0].id]] : item;
      });
    }
    params.filters = [...filters, ...defaultFilter];

    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchServices(params);
    }
    // params.fields = fields
    return fetchServices(params);
  };

  /*
       @Description : Load Navigation and Breadcrub on page load
    */
  useEffect(() => {
    if (!usersData) fetchUsersData();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        {usersData && (
          <CommonDataGrid
            gridTitle="Customer Services"
            fetch={getCustomerServices}
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            deleteId={deleteId}
            addNew={() => history.push(`/services/add`)}
            addNewRight={accessActionListingAddNew}
            deleteMany={false}
            clearFilterByTaxonomy={true}
          />
        )}
      </div>
    </div>
  );
};

export default ServicesListing;
