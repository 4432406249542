/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Address Listing, edit, add and delete API wirirng up 
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 27/Jul/2020 
	Author						: Anand Kumar
--------------------------------------------------------------------------------- 
	Change History #1 
	Date Modified : 12/Oct/2021
	Changed By : Sai
	Change Description : imported customformelements from syncFusionFormFields
	Ticket Ref Number  : #6049
	Reason for Change  : changing components to syncfusion react
	Updated Code       : fetch countries 
================================================================ 

*/

import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { Modal } from "react-bootstrap";
import { fetchAddress, deleteAddress, saveAddress, editAddress } from "../../../../../middleware/services/companyApi";
import Accordion from "../../../../components/accordion";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addressValidationSchema } from "../../../../helper/validationSchema/companyValidationSchema";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import { TextField, Dropdown, AutoCompleteDropdown } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { fetchCountry } from "../../../../../middleware/services/cmsApi";
import { addressType } from "../../constants";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const initialState = {
  address1: "",
  area: "",
  cityName: "",
  stateName: "",
  countryName: "",
  postCode: "",
  boardLineNumber: "",
  fax: "",
  type: "",
};

const Address = (props) => {
  /* Common access */
  const accessPermissionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_ADDRESS);
  const accessPermissionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_ADDRESS);
  const accessPermissionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_ADDRESS);

  const dispatch = useDispatch();
  const companyId = props.companyId;
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    if (companyId != "") {
      handleFetchAddress();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    validationSchema: Yup.object().shape(addressValidationSchema),
  });

  const handleFetchAddress = async () => {
    const response = await fetchAddress(companyId);
    response && response.data && setLocations(response.data.data);
    setLoading(false);
  };
  // delete a address handler
  const handleDeleteAddress = async (addressId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await deleteAddress(companyId, addressId)
            .then(() => {
              dispatch(actionSuccess("Address deleted successfully"));
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Something went wrong"));
            });
          await handleFetchAddress();
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to Delete ?",
        title: "dialogAlertCssWarning",
      })
    );
  };

  // save a address
  const handleSaveAddress = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        let countryNameError = "Please enter country";
        formik.setFormikState({
          ...formik,
          touched: touched,
          errors: {
            ...res,
            countryName: countryNameError,
          },
        });
      } else {
        saveAddress(companyId, formik.values)
          .then((response) => {
            setShowModal(!showModal);
            formik.handleReset();
            dispatch(actionSuccess("Address added successfully"));
            handleFetchAddress();
          })
          .catch((err) => {
            dispatch(actionError(err.data.message || "Something went wrong"));
          });
      }
    });
  };

  // handleEdit address
  const handleEditAddress = async () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        let countryNameError = "Please enter country";
        formik.setFormikState({
          ...formik,
          touched: touched,
          errors: {
            ...res,
            countryName: countryNameError,
          },
        });
      } else {
        const { address1, area, boardLineNumber, cityName, countryName, fax, id, postCode, stateName, type } = formik.values;
        const payload = {
          address1,
          area,
          boardLineNumber,
          cityName,
          countryName,
          fax,
          postCode,
          stateName,
          type,
        };
        if (typeof payload.fax === "number") payload.fax = payload.fax.toString();
        if (typeof payload.postCode === "number") payload.postCode = payload.postCode.toString();
        if (typeof payload.boardLineNumber === "number") payload.boardLineNumber = payload.boardLineNumber.toString();
        editAddress(companyId, id, payload)
          .then((response) => {
            setShowModal(!showModal);
            formik.handleReset();
            dispatch(actionSuccess("Address updated successfully"));
            handleFetchAddress();
            setEdit(false);
          })
          .catch((err) => {
            dispatch(actionError(err.data.message || "Something went wrong"));
          });
      }
    });
  };

  /**
	  /**
	   * @description fetch countries to tag with user
	   */

  const searchCountry = useCallback(
    debounce(async (e) => {
      let filters = [];
      filters.push(["countryName", "cn", e.text]);
      const response = e.text.length > 1 && (await fetchCountry({ filters: filters, fields: ["countryName"], sort: "countryName:asc", limit: 10 }));
      if (response.data && response.data.data) {
        const resData = response.data.data.map((v) => {
          return v.countryName;
        });
        if (resData) {
          e.updateData(resData);
        } else {
          e.updateData([]);
        }
      }
    }, 300),
    []
  );

  return (
    <>
      <Accordion heading="Address" stepCompleted={locations?.companyLocation && locations?.companyLocation.length > 0 ? true : false} step={2}>
        <div className="pt-2">
          <table className="normal-table cy-tbl-address table table-bordered">
            <thead className="internal-grid">
              <tr>
                <th>Address</th>
                <th>Area</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Zip Code</th>
                <th>Phone</th>
                <th>Fax</th>
                <th>Type</th>
                <th className="text-center internal-grid-action">Action</th>
              </tr>
            </thead>
            <tbody>
              {locations.companyLocation &&
                locations.companyLocation.length > 0 &&
                locations.companyLocation.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="overflow">{item.address1}</td>
                      <td>{item.area || "--"}</td>
                      <td>{item.cityName || "--"}</td>
                      <td>{item.stateName || "--"}</td>
                      <td>{item.countryName || "--"}</td>
                      <td>{item.postCode || "--"}</td>
                      <td>{item.boardLineNumber || "--"}</td>
                      <td>{item.fax || "--"}</td>
                      <td>{item.type || "--"}</td>
                      <td className="text-center">
                        {accessPermissionEdit || accessPermissionDelete ? (
                          <ThreeDotMenu
                            rowID={item.id}
                            Edit={
                              accessPermissionEdit
                                ? () => {
                                    setEdit(true);
                                    formik.setValues(item);
                                    setShowModal(!showModal);
                                  }
                                : undefined
                            }
                            Delete={
                              accessPermissionDelete
                                ? () => {
                                    handleDeleteAddress(item.id);
                                  }
                                : undefined
                            }
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="text-right">
            {accessPermissionAdd ? (
              <button type="button" className="btn btn-dark cy-btn" onClick={() => setShowModal(!showModal)}>
                Add New
              </button>
            ) : null}
          </div>
        </div>
      </Accordion>
      {/* add address1 modal  */}
      <Modal show={showModal} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-header bg-none text-dark">
          <h5 className="modal-title" id="exampleModalLabel">
            {isEdit ? "Edit Location" : "Add Location"}
          </h5>
          <button type="button" className="close" aria-label="Close" onClick={() => setShowModal(!showModal)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group required">
                <TextField
                  formik={formik}
                  type="text"
                  multiline={true}
                  numberCount={true}
                  maxLength={1000}
                  rows={5}
                  name="address1"
                  className="form-control"
                  id="address1"
                  placeholder="Enter Address *"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <TextField formik={formik} type="text" className="form-control" placeholder="Enter area" id="area" name="area" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <TextField formik={formik} type="text" className="form-control" id="city" placeholder="Enter city" name="cityName" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <TextField formik={formik} type="text" className="form-control" id="state" placeholder="Enter state" name="stateName" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group required">
                <AutoCompleteDropdown
                  formik={formik}
                  placeholder="Country *"
                  id="countryName"
                  name="countryName"
                  autocomplete="off"
                  // value={formik.values.country}
                  allowFiltering={true}
                  filtering={searchCountry}
                  autofill={true}
                  allowCustom={isEdit && formik.values.countryName ? true : false}
                  filterType="StartsWith"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <TextField formik={formik} type="text" className="form-control" id="zip" placeholder="Enter zip" name="postCode" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <TextField formik={formik} type="text" className="form-control" id="phone" placeholder="Enter phone" name="boardLineNumber" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <TextField formik={formik} type="text" className="form-control" id="fax" placeholder="Enter fax" name="fax" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group required">
                <div className=" ">
                  <Dropdown
                    formik={formik}
                    sortOrder="Acending"
                    placeholder="Select address type *"
                    id="type"
                    name="type"
                    fields={{ text: "label", value: "value" }}
                    dataSource={addressType}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-dark cy-btn mr-3"
            onClick={() => {
              formik.handleReset();
              setShowModal(!showModal);
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary cy-btn" onClick={isEdit ? handleEditAddress : handleSaveAddress}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Address;
