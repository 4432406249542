/* eslint-disable max-lines-per-function */
import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { fetchProductBasic } from "../../../../middleware/services/productApi";
import { fetchCompany } from "../../../../middleware/services/companyApi";
import { fetchObjective } from "../../../../middleware/services/cmsApi";
import { getProductVariant } from "../../../../utilities";
import { fetchNews } from "../../../../middleware/services/newsApi";
import { DELIVERABLE_TYPE } from "../../../../constants";

export const TextField = (props) => {
  return (
    <>
      <input
        {...props}
        className={props.formik.touched[props.name] && props.formik.errors[props.name] ? "text-input form-control" : "text-input form-control"}
        autoComplete="off"
        {...props.formik.getFieldProps(props.name)}
        {...props.properties}
      />
      {props.formik.touched[props.name] && props.formik.errors[props.name] ? (
        <div className="col-md-12 pl-0 text-danger small">{props.formik.errors[props.name]}</div>
      ) : null}
    </>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
};

export const DeliverableTypeSingleSelect = (props) => {
  const [deliverableTypes, setDeliverableTypes] = useState([
    {
      deliverableTypeName: "Trend Deep Dive",
      deliverableTypeValue: "Trend Deep Dive",
      productVariant: "Insider",
    },
    {
      deliverableTypeName: "Technology Deep Dive",
      deliverableTypeValue: "Technology Deep Dive",
      productVariant: "Insider",
    },
    {
      deliverableTypeName: "Executive Lens",
      deliverableTypeValue: "Executive Lens",
      productVariant: "Insider",
    },
    {
      deliverableTypeName: "Technology & Solutions",
      deliverableTypeValue: "Technology & Solutions",
      productVariant: "Insider",
    },
    {
      deliverableTypeName: "Benchmarking",
      deliverableTypeValue: "Benchmarking",
      productVariant: "Insider",
    },
    {
      deliverableTypeName: "Bulletin",
      deliverableTypeValue: "Bulletin",
      productVariant: "Insider",
    },
    {
      deliverableTypeName: "Pulse",
      deliverableTypeValue: "Pulse",
      productVariant: "Insider",
    },
    {
      deliverableTypeName: "Patent Watch",
      deliverableTypeValue: "Patent Watch",
      productVariant: "Insider",
    },
    {
      deliverableTypeName: "Research Watch",
      deliverableTypeValue: "Research Watch",
      productVariant: "Insider",
    },
    {
      deliverableTypeName: DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE,
      deliverableTypeValue: DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE,
      productVariant: "Insider",
    },
    {
      deliverableTypeName: "Trend Overview",
      deliverableTypeValue: "Trend Overview",
      productVariant: "Insider",
    },
    {
      deliverableTypeName: "WhatNext Perspective",
      deliverableTypeValue: "WhatNext Perspective",
      productVariant: "WhatNext",
    },
    {
      deliverableTypeName: DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE,
      deliverableTypeValue: DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE,
      productVariant: "WhatNext",
    },
    {
      deliverableTypeName: "Quarterly Report",
      deliverableTypeValue: "Quarterly Report",
      productVariant: "WhatNext",
    },
  ]);

  const productVariant = getProductVariant();
  const type = deliverableTypes.filter((deliverable) => deliverable.productVariant === productVariant);

  const searchDeliverableTypes = useCallback(() => {
    setDeliverableTypes(type);
  }, [type]);

  useEffect(() => {
    searchDeliverableTypes();
  }, []);

  return (
    <>
      <Select
        noOptionsMessage={() => <div>Please type at least 3 characters</div>}
        placeholder="Please select deliverable type"
        minMenuHeight={100}
        maxMenuHeight={150}
        menuPlacement="auto"
        options={deliverableTypes.map((typesData) => {
          return { label: typesData.deliverableTypeName, value: typesData.deliverableTypeValue };
        })}
        value={deliverableTypes}
        {...props.formik.getFieldProps(props.name)}
        onChange={(selectedOption) => {
          // selectedOption['__isNew__'] ? null :
          props.formik.setFieldValue(props.name, selectedOption);
        }}
        {...props.properties}
      />
      {props.formik.touched[props.name] && props.formik.errors[props.name] ? (
        <div className="col-md-12 pl-0 text-danger small">{props.formik.errors[props.name]}</div>
      ) : null}
    </>
  );
};

DeliverableTypeSingleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
};

export const NewsMultiSelect = (props) => {
  const [news, setNews] = useState([]);
  const searchNews = useCallback(
    debounce(async (value) => {
      if (value.length > 2) {
        let filters = [];
        const productVariant = getProductVariant();
        if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
        filters.push(["newsTitle", "cn", value]);
        let fields = { fields: ["newsID", "newsTitle"] };
        const response = await fetchNews({ filters: filters, ...fields });
        if (response.data && response.data.data) {
          setNews(response.data.data);
        }
      }
    }, 500),
    []
  );
  return (
    <>
      <Select
        name={props.name}
        noOptionsMessage={() => <div>Please type at least 3 characters</div>}
        placeholder="Please type at least 3 characters to search news"
        minMenuHeight={100}
        maxMenuHeight={150}
        menuPlacement="auto"
        isSearchable
        isClearable
        isMulti
        options={news.map((newsData) => {
          return { label: newsData.newsTitle, value: newsData.id };
        })}
        value={news}
        {...props.formik.getFieldProps(props.name)}
        onChange={(selectedOption) => {
          // selectedOption['__isNew__'] ? null :
          props.formik.setFieldValue(props.name, selectedOption);
          setNews([]);
        }}
        {...props.properties}
        onInputChange={(inputValue) => {
          inputValue && searchNews(inputValue);
        }}
        {...props.properties}
      />
      {props.formik.touched[props.name] && props.formik.errors[props.name] ? (
        <div className="col-md-12 pl-0 text-danger small">{props.formik.errors[props.name]}</div>
      ) : null}
    </>
  );
};

NewsMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
};

export const ProductMultiSelect = (props) => {
  const [product, setProduct] = useState([]);
  // debounce search
  const searchProducts = useCallback(
    debounce(async (value) => {
      if (value.length > 2) {
        let filters = [];
        const productVariant = getProductVariant();
        if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
        filters.push(["productName", "cn", value]);
        let fields = { fields: ["productId", "productName"] };
        const response = await fetchProductBasic({ filters: filters, ...fields });
        if (response.data && response.data.data) {
          setProduct(response.data.data);
        }
      }
    }, 500),
    []
  );
  return (
    <>
      <Select
        name={props.name}
        noOptionsMessage={() => <div>Please type at least 3 characters</div>}
        placeholder="Please type min 3 characters to search product"
        minMenuHeight={100}
        maxMenuHeight={150}
        menuPlacement="auto"
        isSearchable
        isClearable
        isMulti
        options={product.map((productData) => {
          return { label: productData.productName, value: productData.id };
        })}
        value={product}
        {...props.formik.getFieldProps(props.name)}
        onChange={(selectedOption) => {
          // selectedOption['__isNew__'] ? null :
          props.formik.setFieldValue(props.name, selectedOption);
          setProduct([]);
        }}
        {...props.properties}
        onInputChange={(inputValue) => {
          inputValue && searchProducts(inputValue);
        }}
        {...props.properties}
      />
      {props.formik.touched[props.name] && props.formik.errors[props.name] ? (
        <div className="col-md-12 pl-0 text-danger small">{props.formik.errors[props.name]}</div>
      ) : null}
    </>
  );
};
ProductMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
};

export const CompanyMultiSelect = (props) => {
  const [company, setCompany] = useState([]);
  // debounce search
  const searchPeople = useCallback(
    debounce(async (value) => {
      if (value.length > 2) {
        let filters = [];
        const productVariant = getProductVariant();
        if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
        filters.push(["companyName", "cn", value]);
        let fields = { fields: ["companyId", "companyName"] };
        const response = await fetchCompany({ filters: filters, ...fields });
        if (response.data && response.data.data) {
          setCompany(response.data.data);
        }
      }
    }, 500),
    []
  );
  return (
    <>
      <CreatableSelect
        name={props.name}
        placeholder="Please type at least 3 characters to search company"
        noOptionsMessage={() => <div>Please type at least 3 characters</div>}
        minMenuHeight={100}
        maxMenuHeight={150}
        menuPlacement={props.properties.menuPlacement ? props.properties.menuPlacement : "auto"}
        isSearchable
        isClearable
        isMulti
        options={company.map((companyData) => {
          return { label: companyData.companyName, value: companyData.id };
        })}
        value={company}
        {...props.formik.getFieldProps(props.name)}
        onChange={(selectedOption) => {
          props.formik.setFieldValue(props.name, selectedOption);
          setCompany([]);
        }}
        {...props.properties}
        onInputChange={(inputValue) => {
          inputValue && searchPeople(inputValue);
        }}
        {...props.properties}
      />
      {props.formik.touched[props.name] && props.formik.errors[props.name] ? (
        <div className="col-md-12 pl-0 text-danger small">{props.formik.errors[props.name]}</div>
      ) : null}
    </>
  );
};
CompanyMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
};

export const ObjectiveSingleSelect = (props) => {
  const [objective, setObjective] = useState([]);

  const productVariant = getProductVariant();
  const type = objective.filter((deliverable) => deliverable.productVariant === productVariant);

  const searchObjective = async function () {
    const response = await fetchObjective({ productVariant: productVariant });
    if (response.data && response.data) {
      const objectiveData = response.data.objectiveData.data.map((typesData) => {
        return { value: typesData._id["$id"], label: typesData["objective_title"] };
      });
      setObjective(objectiveData);
    }
  };

  useEffect(() => {
    searchObjective();
  }, []);
  return (
    <>
      <Select
        noOptionsMessage={() => <div>Please type to search</div>}
        placeholder="Select Objective"
        minMenuHeight={100}
        maxMenuHeight={150}
        menuPlacement="auto"
        options={objective}
        value={objective}
        {...props.formik.getFieldProps(props.name)}
        onChange={(selectedOption) => {
          // selectedOption['__isNew__'] ? null :
          props.formik.setFieldValue(props.name, selectedOption);
        }}
        {...props.properties}
      />
      {props.formik.touched[props.name] && props.formik.errors[props.name] ? (
        <div className="col-md-12 pl-0 text-danger small">{props.formik.errors[props.name]}</div>
      ) : null}
    </>
  );
};

ObjectiveSingleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
};
