/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description :  Company overview tab validations
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 25/Jul/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import * as Yup from "yup";
import * as REGEX from "../../../../config/regexValidation";
import { COLLABORATION_TYPES } from "../constants";
export const companyOverviewValidationSchema = {
  companyName: Yup.string()
    .required("Please enter company name")
    .max(125, "Company name can not be more than 125 characters")
    .matches(REGEX.COMPANY, "Please enter valid company name"),
  companyFoundedYear: Yup.string().matches(REGEX.YEAR, "Please enter valid Founded Year"), //Years from 1000 to 2999
  companySocialTwitter: Yup.string().matches(REGEX.TWITTER, "Please enter valid Twitter handel URL"),
  companySocialFacebook: Yup.string().matches(REGEX.FACEBOOK, "Please enter valid Facebook page URL"),
  companySocialLinkedIn: Yup.string().matches(REGEX.LINKEDIN, "Please enter valid LinkedIn page URL"),
  companyEmail: Yup.string().email("Please enter valid email"),
  companyWebsite: Yup.string().required("Please enter company website").matches(REGEX.URL, "Please enter valid company website URL"),
  companyPublishedDate: Yup.string().required("Please select the publish date").nullable(),
};

export const collaborationValidationSchema = {
  collaborationName: Yup.string().max(150, "Collaboration name can not be more than 150 characters").required("Please enter collaboration name"),
  collaborationType: Yup.string().required("Please select collaboration type"),
  collaborationDealValue: Yup.string()
    .typeError("Please enter NA, Undisclosed or any numbers only")
    .matches(/(^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$|^NA$|^Undisclosed$)/, "Please enter NA, Undisclosed or any numbers only")
    .required("Please enter deal value"),
  collaborationTechnologyTags: Yup.string().when(["pVariant", "collaborationIndustryTags"], {
    is: (pVariant, collaborationTechnologyTags) => {
      return (pVariant && pVariant === "WhatNext") || (collaborationTechnologyTags && collaborationTechnologyTags === "");
    },
    then: Yup.string().required("Please select technolgy").typeError("Please select technolgy"),
    otherwise: Yup.string().nullable(),
    // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
  }),
  // collaborationIndustryTags: Yup.string().typeError('Please select industry').required('Please select industry'),
  collaborationIndustryTags: Yup.string().when(["pVariant"], {
    is: (pVariant) => {
      return pVariant && pVariant === "WhatNext";
    },
    then: Yup.string().required("Please select industry").typeError("Please select industry"),
    otherwise: Yup.string().nullable(),
    // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
  }),
  collaborationTrendIndustryTags: Yup.string().nullable(),
  collaborationIndustryTrendsTags: Yup.string().when(["pVariant", "collaborationIndustryTags"], {
    is: (pVariant, collaborationTrendIndustryTags) => {
      return (pVariant && pVariant === "Insider") || (collaborationTrendIndustryTags && collaborationTrendIndustryTags === "");
    },
    // then: Yup.string().required("Please select trend").typeError("Please select trend"),
    then: Yup.string().typeError("Please select trend"),
    otherwise: Yup.string().nullable(),
    // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
  }),
  collaborationSourceUrl: Yup.string().matches(REGEX.URL, "Please enter valid collaboration source URL"),
  collaborationDescription: Yup.string()
    .trim("Please enter collaboration description")
    .min(10, "Collaboration description must be at least 10 characters")
    .max(500, "Collaboration description cannot be more than 500 characters")
    .required("Please enter collaboration description"),
  fundingTypeRound: Yup.string().when(["collaborationType"], {
    is: (collaborationType) => {
      return collaborationType && collaborationType === COLLABORATION_TYPES.FUNDING_INVESTMENT;
    },
    then: Yup.string().required("Please select funding type"),
    otherwise: Yup.string().nullable(),
  }),
  collaborationPartnerSource: Yup.string().required("Please enter atleast one company"),
  collaborationPartnerTarget: Yup.string().required("Please enter atleast one company"),
};
