/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : User Routes (Client, External, Internal, Vendor)
----------------------------------------------------------------
	Creation Details
	Date Created				: 30/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import MyProfile from "../app/views/user/externalUser/myProfile";
import MyProfileRegistrationDtls from "../app/views/user/externalUser/myProfileRegistrationDtls";
import ClientUser from "../app/views/user/clientUser";
import ClientUserProfile from "../app/views/user/clientUser/userProfile";
import ClientUserSubscriptionListing from "../app/views/user/clientUser/subscription";
import ClientUserSubscription from "../app/views/user/clientUser/addSubscription";
import ClientUserBilling from "../app/views/user/clientUser/billing";
import ExternalUser from "../app/views/user/externalUser";
import ExternalUserProfile from "../app/views/user/externalUser/userProfile";
import ExternalUserRegistrationDtls from "../app/views/user/externalUser/registrationDtls";
import ExternalUserAccess from "../app/views/user/externalUser/accessRights";
import ExternalUserAssessment from "../app/views/user/externalUser/assessment";
import InternalUser from "../app/views/user/internalUser";
import InternalUserProfile from "../app/views/user/internalUser/userProfile";
import InternalUserAccess from "../app/views/user/internalUser/accessRights";
import Vendor from "../app/views/user/vendor";
import VendorProfile from "../app/views/user/vendor/vendorProfile";
import VendorUser from "../app/views/user/vendor/vendorUser";

const PROFILE = ["Profile:VIEW", "Profile:FULL"];
const MY_PROFILE = ["My Profile:VIEW", "My Profile:FULL"];
const REGISTRATION_DETAILS = ["Registration:VIEW", "Registration:FULL"];
const ASSESSMENT = ["Assessment:VIEW", "Assessment:FULL"];
const SUBSCRIPTION = ["Subscription:VIEW", "Subscription:FULL"];
const BILLING = ["Billing:VIEW", "Billing:FULL"];
const ACCESS_RIGHTS = ["Access Rights:VIEW", "Access Rights:FULL"];
const USER_MANAGEMENT = ["User Management:VIEW", "User Management:FULL"];

const userRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/client-user"
      component={ClientUser}
      pageHeading="GenNx CMS - Client Users"
      accessRights={{
        moduleName: "Client Users",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE, ...SUBSCRIPTION, ...BILLING],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/client-user/add"
      component={ClientUserProfile}
      pageHeading="GenNx CMS - Client Users"
      accessRights={{
        moduleName: "Client Users",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/client-user/:userId/edit"
      component={ClientUserProfile}
      pageHeading="GenNx CMS - Client Users"
      accessRights={{
        moduleName: "Client Users",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE, ...SUBSCRIPTION, ...BILLING],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/client-user/:userId/edit/subscription"
      component={ClientUserSubscriptionListing}
      pageHeading="GenNx CMS - Client Users"
      accessRights={{
        moduleName: "Client Users",
        parentModuleName: "User Management",
        moduleAttribute: [...SUBSCRIPTION],
        interfaceName: "Relationship",
      }}
    />
    <AuthLayout
      exact
      path="/client-user/:userId/subscription/add"
      component={ClientUserSubscription}
      pageHeading="GenNx CMS - Client Users"
      accessRights={{
        moduleName: "Client Users",
        parentModuleName: "User Management",
        moduleAttribute: [...SUBSCRIPTION],
        interfaceName: "Relationship",
      }}
    />
    <AuthLayout
      exact
      path="/client-user/:userId/subscription/:subscriptionId/edit"
      component={ClientUserSubscription}
      pageHeading="GenNx CMS - Client Users"
      accessRights={{
        moduleName: "Client Users",
        parentModuleName: "User Management",
        moduleAttribute: [...SUBSCRIPTION],
        interfaceName: "Relationship",
      }}
    />
    <AuthLayout
      exact
      path="/client-user/:userId/edit/billing"
      component={ClientUserBilling}
      pageHeading="GenNx CMS - Client Users"
      accessRights={{
        moduleName: "Client Users",
        parentModuleName: "User Management",
        moduleAttribute: [...BILLING],
        interfaceName: "Relationship",
      }}
    />

    <AuthLayout
      exact
      path="/external-user"
      component={ExternalUser}
      pageHeading="GenNx CMS - External CMS Users"
      accessRights={{
        moduleName: "External CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE, ...ACCESS_RIGHTS],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/external-user/add"
      component={ExternalUserProfile}
      pageHeading="GenNx CMS - External CMS Users"
      accessRights={{
        moduleName: "External CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/external-user/:userId/edit"
      component={ExternalUserProfile}
      pageHeading="GenNx CMS - External CMS Users"
      accessRights={{
        moduleName: "External CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE, ...ACCESS_RIGHTS, ...REGISTRATION_DETAILS, ...ASSESSMENT],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/external-user/:userId/edit/registration-detail"
      component={ExternalUserRegistrationDtls}
      pageHeading="GenNx CMS - External CMS Users"
      accessRights={{
        moduleName: "External CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...REGISTRATION_DETAILS],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/my-profile/:userId/edit"
      component={MyProfile}
      pageHeading="GenNx CMS - My Profile"
      accessRights={{
        moduleName: "External CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...MY_PROFILE],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/my-profile/:userId/edit/registration-detail"
      component={MyProfileRegistrationDtls}
      pageHeading="GenNx CMS - My Profile"
      accessRights={{
        moduleName: "External CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...REGISTRATION_DETAILS],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/external-user/:userId/edit"
      component={ExternalUserProfile}
      pageHeading="GenNx CMS - External CMS Users"
      accessRights={{
        moduleName: "External CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/external-user/:userId/edit/registration-detail"
      component={ExternalUserRegistrationDtls}
      pageHeading="GenNx CMS - External CMS Users"
      accessRights={{
        moduleName: "External CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...REGISTRATION_DETAILS],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/external-user/:userId/edit/access"
      component={ExternalUserAccess}
      pageHeading="GenNx CMS - External CMS Users"
      accessRights={{
        moduleName: "External CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...ACCESS_RIGHTS],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/external-user/:userId/edit/assessment"
      component={ExternalUserAssessment}
      pageHeading="GenNx CMS - External CMS Users"
      accessRights={{
        moduleName: "External CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...ASSESSMENT],
        interfaceName: "User Profile",
      }}
    />

    <AuthLayout
      exact
      path="/internal-user"
      component={InternalUser}
      pageHeading="GenNx CMS - CMS Users"
      accessRights={{
        moduleName: "CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE, ...ACCESS_RIGHTS],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/internal-user/add"
      component={InternalUserProfile}
      pageHeading="GenNx CMS - CMS Users"
      accessRights={{
        moduleName: "CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/internal-user/:userId/edit"
      component={InternalUserProfile}
      pageHeading="GenNx CMS - CMS Users"
      accessRights={{
        moduleName: "CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE, ...ACCESS_RIGHTS],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/internal-user/:userId/edit/access"
      component={InternalUserAccess}
      pageHeading="GenNx CMS - CMS Users"
      accessRights={{
        moduleName: "CMS Users",
        parentModuleName: "User Management",
        moduleAttribute: [...ACCESS_RIGHTS],
        interfaceName: "User Profile",
      }}
    />

    <AuthLayout
      exact
      path="/vendor"
      component={Vendor}
      pageHeading="GenNx CMS - Vendor"
      accessRights={{
        moduleName: "Vendor",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE, ...USER_MANAGEMENT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/vendor/add"
      component={VendorProfile}
      pageHeading="GenNx CMS - Vendor"
      accessRights={{
        moduleName: "Vendor",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE],
        interfaceName: "User Profile",
      }}
    />
    <AuthLayout
      exact
      path="/vendor/:vendorId/edit"
      component={VendorProfile}
      pageHeading="GenNx CMS - Vendor"
      accessRights={{
        moduleName: "Vendor",
        parentModuleName: "User Management",
        moduleAttribute: [...PROFILE],
        interfaceName: "User Profile",
      }}
    />
    {/* <AuthLayout exact path="/vendor/:vendorId/user" component={VendorUser} pageHeading="GenNx CMS - Vendor"
			accessRights={{
				moduleName: "Vendor", parentModuleName: "User Management",
				moduleAttribute: [...USER_MANAGEMENT],
        interfaceName: "User Profile",
			}} /> */}
  </Switch>
);

export default userRoutes;
