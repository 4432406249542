/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  People Education Popup Component for Add/Edit
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 27/Apr/2021
    Author						: Sandeep Sharma
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { Modal } from "react-bootstrap";
import { TextBoxComponent, FormValidator } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { saveEducation, updateEducation, fetchSingleEducation } from "../../../../../middleware/services/peopleApi";

const AddEducation = (props) => {
  const dispatch = useDispatch();
  const [passingYearData, setPassingYearData] = useState([]);
  const [edu, setEdu] = useState({});

  //Function for validate form and save Education
  const addEducation = () => {
    const options = {
      // add the rules for validation
      rules: {
        eduInstituteName: {
          required: [true, "University / Institute name is required"],
        },
        eduQualification: {
          required: [true, "Qualification is required"],
        },
        eduPassingYear: {
          required: [true, "Select passing out year"],
        },
      },
    };
    var formObject = new FormValidator("#addEducation", options);
    //Submit Form and send data to API
    if (formObject.validate()) {
      const form = document.forms.addEducation;
      const formData = new FormData(form);
      if (props.eduId) {
        updateEducation(props.peopleId, props.eduId, formData)
          .then((response) => {
            if (response.status == 200) {
              props.closeModal();
              dispatch(actionSuccess("Education details updated successfully"));
              props.formatRowData(props.peopleId);
            } else {
              dispatch(actionError(response.data.message || "Someting went wrong"));
            }
          })
          .catch((err) => {
            dispatch(actionError(err.data.message || "Someting went wrong"));
          });
      } else {
        saveEducation(props.peopleId, formData)
          .then((response) => {
            if (response.status == 200) {
              props.closeModal();
              dispatch(actionSuccess("Education details added successfully"));
              props.formatRowData(props.peopleId);
            } else {
              dispatch(actionError(response.data.message || "Someting went wrong"));
            }
          })
          .catch((err) => {
            dispatch(actionError(err.data.message || "Someting went wrong"));
          });
      }
    }
  };
  useEffect(() => {
    if (props.peopleId === "") {
      props.closeModal();
      dispatch(actionError("Please add basic details first"));
    }
    const year = new Date().getFullYear();
    const years = Array.from(new Array(50), (val, index) => year - index);
    setPassingYearData(years);
    if (props.eduId !== "") {
      const response = fetchSingleEducation(props.peopleId, props.eduId);
      response.then((response) => {
        if (response.status == 200) {
          var education = response.data.data;
          setEdu(education);
        }
      });
    }
  }, [props.showModal]);

  return (
    <Modal show={props.showModal} backdrop="static" className="modal fade lg people-mgmt" aria-labelledby="contained-modal-title-vcenter" centered>
      <form id="addEducation" method="post">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Add Education
          </h5>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <TextBoxComponent
                  id="eduInstituteName"
                  name="eduInstituteName"
                  placeholder="University /Institute Name *"
                  cssClass="e-outline"
                  data-msg-containerid="universityError"
                  floatLabelType="Auto"
                  value={edu.eduInstituteName}
                />
                <div id="universityError" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <TextBoxComponent
                  id="eduQualification"
                  name="eduQualification"
                  placeholder="Qualification *"
                  cssClass="e-outline"
                  data-msg-containerid="qualificationError"
                  floatLabelType="Auto"
                  value={edu.eduQualification}
                />
                <div id="qualificationError" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <DropDownListComponent
                  id="eduPassingYear"
                  name="eduPassingYear"
                  cssClass="e-outline"
                  dataSource={passingYearData}
                  placeholder="Passing Out Year *"
                  floatLabelType="Auto"
                  data-msg-containerid="passingError"
                  value={props.eduId !== "" && edu.eduPassingYear ? parseInt(edu.eduPassingYear) : undefined}
                />
                <div id="passingError" />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-dark cy-btn mr-3"
            onClick={() => {
              props.closeModal();
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary cy-btn" onClick={addEducation}>
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddEducation;
