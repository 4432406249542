/*
================================================================
	Copyright © 2024, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Custom Projects Routes 
----------------------------------------------------------------
	Creation Details
	Date Created				: 24/May/2024
	Author						: Satya Prakash Mall
================================================================
*/
import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import CustomProjectsListing from "../app/views/customProjects";

const PROPERTIES = ["Properties:VIEW", "Properties:FULL"];

const customProjectsRoutes = () => (
  <Suspense
    fallback={
      <div className="text-center">
        <h3>Loading...</h3>
      </div>
    }
  >
    <Switch>
      <AuthLayout
        exact
        path="/custom-projects"
        component={CustomProjectsListing}
        pageHeading="GenNx CMS - Custom Projects"
        accessRights={{
          moduleName: "Custom Projects",
          parentModuleName: "",
          moduleAttribute: [...PROPERTIES],
          interfaceName: "Listing",
        }}
      />
    </Switch>
  </Suspense>
);

export default customProjectsRoutes;
