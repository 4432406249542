/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Taxonomy Routes (Technology, Trend, ValueChain)
----------------------------------------------------------------
	Creation Details
	Date Created				: 24/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";

import TaxonomyTechnology from "../app/views/taxonomy/technology";
import TaxonomyTrend from "../app/views/taxonomy/trend";
import TaxonomyIndustryValueChain from "../app/views/taxonomy/industryValueChain";
import TaxonomyMedia from "../app/views/taxonomy/media";

const ADD = ["Add:FULL"];
const DELETE = ["Delete:FULL"];
const EDIT = ["Edit:FULL"];

const taxonomyRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/taxonomy/technology"
      component={TaxonomyTechnology}
      pageHeading="GenNx CMS - Taxonomy - Technology"
      accessRights={{
        moduleName: "Technology",
        parentModuleName: "Taxonomy",
        moduleAttribute: [...ADD, ...DELETE, ...EDIT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/taxonomy/trend"
      component={TaxonomyTrend}
      pageHeading="GenNx CMS - Taxonomy - Trend"
      accessRights={{
        moduleName: "Trend",
        parentModuleName: "Taxonomy",
        moduleAttribute: [...ADD, ...DELETE, ...EDIT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/taxonomy/industry-value-chain"
      component={TaxonomyIndustryValueChain}
      pageHeading="GenNx CMS - Taxonomy - Value Chain"
      accessRights={{
        moduleName: "Value Chain",
        parentModuleName: "Taxonomy",
        moduleAttribute: [...ADD, ...DELETE, ...EDIT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/taxonomy/media"
      component={TaxonomyMedia}
      pageHeading="GenNx CMS - Taxonomy - Media"
      accessRights={{
        moduleName: "Media",
        parentModuleName: "Taxonomy",
        moduleAttribute: [...ADD, ...DELETE, ...EDIT],
        interfaceName: "Listing",
      }}
    />
  </Switch>
);

export default taxonomyRoutes;
