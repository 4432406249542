import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { accessRightActionCheck, getLoggedInUser } from "../../../../utilities";
import { processingSubmit, processingFinalSubmit, dataAllocationCompany } from "../../../../middleware/services/cmsApi";
import { actionSuccess, actionError } from "../../../../middleware/actions/utilityAction";
import { setClickSaveSubmitState, setOverviewUpdateStatus } from "../../../../middleware/actions/companyAction";
import { useDispatch, useSelector } from "react-redux";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import RemarkList from "./remarkListCompany";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

const ProcessingActions = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = getLoggedInUser();
  const updateStatus = useSelector((state) => state.companyState.updateStatus);
  const clickStatus = useSelector((state) => state.companyState.clickStatus);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionSaveSubmit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE_SUBMIT);
  const accessActionFinalSubmit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.FINAL_SUBMIT);

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Basic";
    let actionAccess = accessRightActionCheck("Processing", interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  useEffect(() => {
    if (!updateStatus) return;
    dispatch(
      showAlertBox({
        okCallback: async () => {
          processingSubmit(props.companyId, props.tab, { userId: loggedInUser._id })
            .then((response) => {
              dispatch(actionSuccess(`Company successfully Saved & Submitted`));
              dispatch(setClickSaveSubmitState());
              dispatch(setOverviewUpdateStatus());
            })
            .catch((err) => {
              console.log(err.data.message);
              dispatch(actionError(err.data.message || "Something went wrong"));
            });
        },
        okText: "Submit",
        cancelText: "Cancel",
        content: "Are you sure you want to submit ?",
        title: "dialogAlertCss",
      })
    );
  }, [updateStatus, props.tab, props.companyId]);

  return (
    <div className="form-submit-box">
      <div className="container-fluid nopadding">
        <div className="row">
          <div className="float-LEFT col-md-4 text-left">
            {props.selectedIndex > -1 ? (
              <span className="pl-4" style={{ marginLeft: "-3em" }}>
                <span className="span-link">
                  <button
                    type="button"
                    className="cy-next-btn btn btn-outline-dark cy-btn pl-4 pr-4"
                    onClick={() => {
                      props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                    }}
                  >
                    Next
                  </button>
                </span>
              </span>
            ) : null}
          </div>

          <div className="float-right col-md-8 text-right">
            <Link to="/processing/company">
              <button
                type="button"
                className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3"
                onClick={() => {
                  history.push("/processing/company");
                }}
              >
                Cancel
              </button>
            </Link>
            {(accessActionFinalSubmit || accessActionSaveSubmit) && <RemarkList companyId={props.companyId} />}
            {accessActionFinalSubmit ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 mr-3"
                onClick={() => {
                  dispatch(
                    showAlertBox({
                      okCallback: async () => {
                        processingFinalSubmit(props.companyId, { userId: loggedInUser._id })
                          .then((response) => {
                            dispatch(actionSuccess("Company successfully Final Submited."));
                            dataAllocationCompany(loggedInUser._id);
                            history.push("/processing/company");
                          })
                          .catch((err) => {
                            console.log(err.data.message);
                            dispatch(actionError(err.data.message || "Something went wrong"));
                          });
                      },
                      okText: "Submit",
                      cancelText: "Cancel",
                      content: "Are you sure you want to submit ?",
                      title: "dialogAlertCss",
                    })
                  );
                }}
              >
                Final Submit
              </button>
            ) : null}
            {accessActionSaveSubmit ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-2 pr-2 "
                onClick={() => {
                  if (clickStatus) dispatch(setClickSaveSubmitState());
                  if (updateStatus) dispatch(setOverviewUpdateStatus());
                  dispatch(setClickSaveSubmitState(true));
                }}
              >
                Save & Submit
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessingActions;
