import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
const AddPage = (props) => {
    const [showModal, setShowModal] = useState(false);
    const deliverableId = props.deliverableId
    const [pageName, setPageName] = useState('');
    const [error , setError] = useState("")
    const history = useHistory();
	const addPage = () =>{
        if (pageName.trim() == '') {
            setError('Please Enter Template Name.');
        } else {
            history.push(`/template/add/${pageName}`)
        }
		
	}
    useEffect(()=>{
        setShowModal(props.showModal);
    },[props.showModal])
    return <>
        <Modal
            show={showModal}
            backdrop="static"
            className="modal fade lg"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="modal-header bg-none text-dark">
                <h5 className="modal-title" id="exampleModalLabel">Add Template</h5>
            </div>
            <Modal.Body>
                <div className="col-12">
                    <TextBoxComponent 
                        cssClass="e-outline"
                        placeholder="Enter Template Name"
                        value={pageName} 
                        onChange={(e) => {
                            setError('')
                            setPageName(e.target.value)
                            }}
                        floatLabelType="Auto" 
                    />
                    
                </div>
                <div className="col-12 error">
                    <label className="danger danger-text">{error}</label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-dark cy-btn mr-3"
                    onClick={() => {
                        props.closeModal()
                        setShowModal(false)
                    }}
                >
                    Cancel
					</button>
                <button type="button" className="btn btn-primary cy-btn"
                    onClick={addPage}
                >Proceed</button>
            </Modal.Footer>
        </Modal>
    </>
}

export default AddPage