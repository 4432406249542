import { put, call, takeEvery } from "redux-saga/effects";
import { fetchPages } from "../services/pagesApi";

const SHOW_LOADING = "gennx-cms/pages.duck/SHOW_LOADING";
const HIDE_LOADING = "gennx-cms/pages.duck/HIDE_LOADING";

const GET_ALL_PAGES = "gennx-cms/pages.duck/GET_ALL_PAGES";
const GET_ALL_PAGES_SUCCESS = "gennx-cms/pages.duck/GET_ALL_PAGES_SUCCESS";
const GET_ALL_PAGES_FAILURE = "gennx-cms/pages.duck/GET_ALL_PAGES_FAILURE";


const pageDuckState = {
	pages: null,
	loading: false,
	exception: null
};

export default function pages(state={...pageDuckState}, action) {
	switch (action.type) {
		case SHOW_LOADING:
			return {
				...state,
				loading: true
			};
		case HIDE_LOADING:
			return {
				...state,
				loading: false
			};
		case GET_ALL_PAGES_SUCCESS:
			return {
				...state,
				pages: action.pages
			};
		case GET_ALL_PAGES_FAILURE:
			return {
				...state,
				error: action.error
			};
		default:
			return state;
	}
}

function startLoading() {
	return {
		type: SHOW_LOADING
	};
}

function stopLoading() {
	return {
		type: HIDE_LOADING
	};
}

function getAllPagesAction(params) {
	return {
		type: GET_ALL_PAGES,
		params: JSON.stringify(params)
	};
}

export function getAllPages(dispatch, params="") {
	dispatch(startLoading());
	dispatch(getAllPagesAction(params));
	dispatch(stopLoading());
}

function* handleGetAllPagesActions({ params }) {
	try {
		const { entityName, entityId } = params;
		const response = yield call(fetchPages, entityName, entityId);
		console.log("Response", response.data.data);
		yield put({
			type: GET_ALL_PAGES_SUCCESS,
			pages: response.data.data
		});
	} catch (exception) {
		console.log("Exception", exception);
		yield put({
			type: GET_ALL_PAGES_FAILURE,
			exception: exception
		});
	}
}

export function* getAllPagesActions() {
	yield takeEvery(GET_ALL_PAGES, handleGetAllPagesActions);
}