/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Modular Accordion Component
----------------------------------------------------------------
	Creation Details
	Date Created				: 29/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import "./index.scss";
import React, { useState } from "react";
import PropTypes from "prop-types";

const AccordionUI = (props) => {
	const { heading = "", children = <></>, required = false, activeState = false, forcedActiveState = false,showStep=true, step="|" , stepCompleted=false} = props;
	const [active, setActive] = useState(activeState);
	return (
		<div key={`acc-${step}`} className={` panel panel-default disabled`}>
			<div className={` panel-heading ${forcedActiveState || active ? " active " : " "}`} style={{padding:!showStep?"4px 0 4px 0":""}} role="tab">
					<i className={`fa accordion-chevron-icon ${forcedActiveState || active ? " fa-chevron-up " : " fa-chevron-down "}`}></i>
				<h4 className="panel-title">
					<div className={` custom-panel-title-action ${forcedActiveState || active ? " collapsed " : " "} ${forcedActiveState ? " cursor-no-drop " : " "} ${!stepCompleted ? "stepDisableTitle " : " "}`}
						role="button" onClick={() => setActive(!active)}>						
							{showStep && <span className={` ${step>0 ? "step-number " : " " } ${!stepCompleted && step>0 ? "stepDisable " : " "}`}>{step}</span>} {heading}<span className={` text-danger ${required ? " " : " d-none "}`}>*</span>
					</div>
				</h4>
			</div>
			<div className={` panel-collapse ${forcedActiveState || active ? " collapsed " : " collapse "}`} style={{border: "1px solid #DBDBDD"}} role="tabpanel">
				<div className="panel-body" style={{borderRadius:"4px"}}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default AccordionUI;

AccordionUI.propTypes = {
	heading: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	required: PropTypes.bool,
	activeState: PropTypes.bool,
	forcedActiveState: PropTypes.bool,
	stepCompleted:PropTypes.bool,
	step:PropTypes.number
};
