import React, { useState, useRef, useEffect } from "react";
import CustomFormBasicDetails from "./customFormBasicDetails";
import FormDesigner from "./formDesigner";
import Tabs from "../../components/tabsComponent";
import { accessRightActionCheck, accessRightInterfaceCheck } from "../../../utilities";

const CustomForm = (props) => {
  const { accessRights: aR } = props;
  const formId = props.computedMatch?.params?.formId;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabRef = useRef();
  const TABS_NAME = ["Basic", "Design Form"];
  const hashArr = { "#basic": "Basic", "#advanced": "Design Form" };
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (formId) {
      if (selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.select(0);
    }
  };

  const newRecord = () => {
    tabRef.current.enableTab(1, true);
    tabRef.current.select(0);
  };

  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, TABS_NAME);
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex]);

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
      <Tabs
        tabsName={TABS_NAME}
        tabRef={tabRef}
        created={created}
        handleClick={() => {
          setSelectedIndex(tabRef.current.selectedItem);
        }}
        cssClass="newHeaderTabCss"
      >
        <div key="CustomFormBasicDetails">
          {selectedIndex === 0 && (
            <CustomFormBasicDetails
              formId={formId}
              {...props}
              selectedIndex={selectedIndex}
              hashArr={hashArr}
              newRecord={newRecord}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              moduleName={aR.moduleName}
              interfaceAccess={interfaceAccess}
              interfaceActionAccess={interfaceActionAccess}
            />
          )}
        </div>
        <div key="FormDesigner">
          {selectedIndex === 1 && formId && (
            <FormDesigner
              formId={formId}
              {...props}
              selectedIndex={selectedIndex}
              hashArr={hashArr}
              newRecord={newRecord}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              moduleName={aR.moduleName}
              interfaceAccess={interfaceAccess}
              interfaceActionAccess={interfaceActionAccess}
            />
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default CustomForm;
