/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Modal Dropdown Input
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React, { useState, useEffect } from "react";
import classes from "./ModalDropdownInput.module.css";
import { DropdownButton, Dropdown } from "react-bootstrap";

// Dropdown input used in modals
const ModalDropdownInput = (props) => {
  const [folderValue, setFolderValue] = useState("");

  // get value of selected folder
  const selectFolder = (key, e) => {
    props.setFolderID(key);
    setFolderValue(e.target.innerText);
  };

  // set new added folder dinamically
  useEffect(() => {
    if (props.newFolderId !== "") {
      const newFolderName = props.categoryList.filter((x) => x.id === props.newFolderId);
      if (newFolderName.length !== 0) {
        setFolderValue(newFolderName[0].categoryName);
      }
    }
  }, [props.categoryList, props.newFolderId]);

  return (
    <div>
      <div className={classes.input_group}>
        <label>Folder</label>

        <DropdownButton
          title={folderValue ? <div className={classes.folderValue}>{folderValue}</div> : "Select Folder"}
          className={`${classes.selectFolder} selectFolderMenu`}
          onSelect={selectFolder}
        >
          <div className={classes.folderDiv}>
            {props?.categoryList &&
              props?.categoryList
                .slice()
                .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
                .map((category) => (
                  <Dropdown.Item value={category.id} eventKey={category.id} key={category.id + Math.random()} className={classes.dropdownItem}>
                    <img src="/assets/folder.svg" alt="" />
                    <div className={classes.folderNameDropdown}>{category.categoryName}</div>
                  </Dropdown.Item>
                ))}
          </div>
          <Dropdown.Divider />
          <Dropdown.Item onClick={props.onAddFolderClick} className={classes.addNewFolder}>
            <img src="/assets/add-folder.svg" alt="" />
            Add New Folder
          </Dropdown.Item>
        </DropdownButton>
      </div>

      {folderValue === "" && props.showError && <p style={{ fontSize: "0.75rem", color: "#f94f5e", marginBottom: "0px" }}>Please select a folder.</p>}
    </div>
  );
};

export default ModalDropdownInput;
