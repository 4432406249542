import "../index.scss";
import React, { useState, useEffect } from "react";
import SortableTree from "../../../components/treeView/sortableTree";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { actionSuccess, showLoader, hideLoader, actionError, actionStart, showMessage } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import {
  fetchTaxonomyMedia,
  saveTaxonomyMedia,
  updateTaxonomyMedia,
  deleteTaxonomyMedia,
  updateTaxonomies,
} from "../../../../middleware/services/cmsApi";

import Ascending from "../../../../assets/images/ascending.svg?react";
import Descending from "../../../../assets/images/descending.svg?react";
import Custom from "../../../../assets/images/custom.svg?react";
import SideBar from "./SideBar";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { COMMON_ACCESS_ACTION } from "../../../../constants";
import { accessRightActionCheck } from "../../../../utilities";

const breadCrumbLinks = [];
const TaxomonyUI = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();

  const [media, setMedia] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(undefined);
  const [taxonomy, setTaxonomy] = useState([]);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(undefined);
  const [reRender, setReRender] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [treeCollapse, setTreeCollapse] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const treeSort = [
    { id: 1, value: "ASC" },
    { id: 2, value: "DESC" },
    { id: 3, value: "CUSTOM" },
  ];
  const [sort, setSort] = useState("");
  const [treeSorting, setTreeSorting] = useState("");
  const [allowDragAndDrop, setAllowDragAndDrop] = useState(false);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [changeOrder, setChangeOrder] = useState(false);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionAdd = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionEdit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);

  const saveUpdatedTaxonomy = async (data) => {
    let result = [];
    data.forEach((item) => {
      let resultObj = {
        id: item.id,
        taxonomy_sort_order: item.taxonomySortOrder,
      };
      result.push(resultObj);
    });
    let params = {
      filters: [],
    };
    params.filters.push(["taxonomyRoot", "eq", selectedMedia]);
    let payload = {
      taxonomies: result,
    };
    dispatch(showLoader());
    await updateTaxonomies(params, payload)
      .then((res) => {
        setChangeOrder(false);
        if (treeSorting != "CUSTOM") fetchTaxonomy(selectedMedia);
        dispatch(actionSuccess("updated successfully"));

        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(actionError("Something went wrong!"));
      });
  };
  /**
   * @description build json for tree
   * @param {Array} data
   * @param {String} parentId
   */
  const buildTreeJson = (data, parentId) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.taxonomyName;
      element["taxonomyDisplayname"] = element.taxonomyDisplayname;
      element["showCheckbox"] = false;
      element["level"] = element.taxonomyLevel;
      if (element["taxonomyParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };

  /**
   * @description fetch taxonomy technology
   */
  const fetchMedia = () => {
    fetchTaxonomyMedia({ filters: [["taxonomyParentId", "eq", 0]], sort: "taxonomySortOrder:asc", limit: 2000 })
      .then((res) => {
        let { data = [] } = res.data;
        setMedia(data);
        fetchTaxonomy(data[0].id);
        setSelectedMedia(data[0].id);
        // setSelectedTaxonomy({ label: data[0].taxonomyName, value: data[0].id })
        setTreeSorting(data[0]?.taxonomySortSeq);
        if (data[0]?.taxonomySortSeq === "ASC") {
          setSort(1);
        }
        if (data[0]?.taxonomySortSeq === "DESC") {
          setSort(2);
        }
        if (data[0]?.taxonomySortSeq === "CUSTOM") {
          setSort(3);
        }
      })
      .catch((err) => {
        console.log("err fetchMedia :: ", err);
      });
  };

  /**
   * @description fetch taxonomy technology by selected taxonomyRoot
   * @param {String} taxonomyRoot
   */
  const fetchTaxonomy = (taxonomyRoot) => {
    dispatch(showLoader());
    setSelectedTaxonomy(undefined);
    if (taxonomyRoot) {
      fetchTaxonomyMedia({
        fields: ["id", "taxonomyName", "taxonomyParentId", "taxonomyDisplayName", "taxonomySortOrder", "taxonomyLevel", "taxonomyParentName"],
        filters: [["taxonomyRoot", "eq", taxonomyRoot]],
        limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
        sort: "taxonomySortOrder:asc",
      })
        .then((res) => {
          dispatch(hideLoader());
          let { data = [] } = res.data;
          if (data?.length) {
            const treeJosnArray = buildTreeJson(data, taxonomyRoot);
            setTaxonomy(treeJosnArray);
            setTreeCollapse(true);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          setTaxonomy([]);
          setReRender(true);
          console.log("err fetchTaxonomy :: ", err);
        });
    }
  };

  /**
   * @description function to open add new technology modal
   */
  const openMediaAddModal = () => {
    if (selectedTaxonomy == undefined || selectedTaxonomy?.length < 2) {
      setReRender(false);
      setShow(true);
      setShowInstruction(false);
      setShowAddEdit(true);
      setShowAdd(true);
      setEdit(false);
    } else dispatch(showMessage("Please select single node."));
  };

  /**
   * @description function to open edit technology modal
   */
  const openTechnologyEditModal = () => {
    if (selectedTaxonomy?.length == 1) {
      setReRender(false);
      setShow(true);
      setShowInstruction(false);
      setShowAddEdit(true);
      setEdit(true);
      setShowAdd(false);
    } else dispatch(showMessage("Please select single node."));
  };

  /**
   * @description tree node click handler
   * @param {String} dV
   */
  const handleClick = (dV) => {
    if (dV.length > 0) {
      setSelectedTaxonomy(dV);
      setShowAddEdit(true);
      setShowAdd(false);
      setEdit(false);
    } else {
      setSelectedTaxonomy(undefined);
      setShowAddEdit(false);
      setShowInstruction(true);
    }
  };

  /**
   * @description save new media
   * @param {String} taxonomyName
   * @param {String} taxonomyDisplayname
   */
  const handleSubmitSaveTaxonomy = (taxonomyName, taxonomyDisplayname) => {
    if (taxonomyName && taxonomyName !== "") {
      dispatch(actionStart());
      let payload = {
        taxonomyParentId: selectedTaxonomy ? selectedTaxonomy[0]?.id : selectedMedia || media[0]?.id,
        taxonomyName: taxonomyName,
        sortSeq: treeSorting,
      };
      if (taxonomyDisplayname) payload["taxonomyDisplayName"] = taxonomyDisplayname;
      saveTaxonomyMedia(payload)
        .then((res) => {
          dispatch(actionSuccess("Taxonomy added successfully"));
          fetchTaxonomy(selectedMedia || media[0].id);
          setShow(true);
          setReRender(true);
          setShowAddEdit(false);
          setShowAdd(false);
          setEdit(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          err?.data?.message ? dispatch(actionError(`${err?.data?.message}`)) : dispatch(actionError(`Add taxonomy failed`));
        });
    }
  };

  /**
   * @description update existing media
   * @param {String} taxonomyName
   * @param {String} taxonomyDisplayname
   */
  const handleSubmitUpdateTaxonomy = (taxonomyName = undefined, taxonomyDisplayname = undefined) => {
    if ((taxonomyName && taxonomyName !== "") || (taxonomyDisplayname && taxonomyDisplayname !== "")) {
      if (selectedTaxonomy === 0) {
        dispatch(actionError("Can not edit parent node"));
        return true;
      }
      dispatch(actionStart());
      let payload = {};
      if (taxonomyName && taxonomyName !== "") payload.taxonomyName = taxonomyName;
      if (taxonomyDisplayname && taxonomyDisplayname !== "") payload.taxonomyDisplayName = taxonomyDisplayname;
      payload.sortSeq = treeSorting;
      updateTaxonomyMedia(selectedTaxonomy[0].id, payload)
        .then((res) => {
          dispatch(actionSuccess("Taxonomy updated successfully"));
          fetchTaxonomy(selectedMedia || media[0].id);
          setReRender(true);
          setEdit(false);
          setShowAddEdit(false);
          setShowAdd(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          err?.data?.message ? dispatch(actionError(`${err?.data?.message}`)) : dispatch(actionError("Update taxonomy failed"));
        });
    }
  };

  /**
   * @description delete technology
   */
  const deleteTaxonomy = () => {
    process.nextTick(() => {
      let taxonomyIds = selectedTaxonomy.map((v) => {
        return v.id;
      });
      dispatch(actionStart());
      deleteTaxonomyMedia(taxonomyIds)
        .then((_res) => {
          dispatch(actionSuccess("Taxonomy deleted successfully"));
          fetchTaxonomy(selectedMedia || media[0].id);
          setReRender(true);
          setEdit(false);
          setShowAddEdit(false);
          setShowAdd(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          let errMsg = err?.data?.message ? err?.data?.message : "Delete trend failed";
          if (err?.data?.statusCode == 451) {
            let string1 = errMsg.split("-");
            dispatch(actionError(`Following nodes can not be deleted, nodes are tagged in ${string1[0]} - ${string1[1]}`));
          } else {
            dispatch(actionError(errMsg));
          }
        });
    });
  };

  const handleSequenceTaxonomy = (taxonomyId, payload) => {
    dispatch(showLoader());
    updateTaxonomyMedia(taxonomyId, payload)
      .then((res) => {
        if (media?.length) {
          let updated = media.findIndex((item) => {
            return item.id === taxonomyId;
          });
          media[updated].taxonomySortSeq = payload?.taxonomySortSeq;
        }
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(actionError("something went wrong"));
      });
  };

  /**
   * @description function to open delete technology alert box
   */
  const deleteTechnology = () => {
    if (selectedTaxonomy) {
      setReRender(false);
      dispatch(
        showAlertBox({
          okCallback: deleteTaxonomy,
          okText: "Delete",
          cancelText: "Cancel",
          content: "Do you want to delete media ? ",
          title: "dialogAlertCssWarning",
        })
      );
    } else dispatch(actionError("Select Taxonomy"));
  };

  useEffect(() => {
    fetchMedia();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  useEffect(() => {
    if (changeOrder) {
      if (treeSorting === "ASC") {
        setSort(1);
      }
      if (treeSorting === "DESC") {
        setSort(2);
      }
      if (treeSorting === "CUSTOM") {
        setSort(3);
      }
    }
  }, [changeOrder]);

  function handleSorting(sortObject) {
    selectedMedia && handleSequenceTaxonomy(selectedMedia, { taxonomySortSeq: sortObject.value });
    if (sortObject.value === "CUSTOM") {
      setAllowDragAndDrop(true);
    }
  }

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <div className="pt-2 pb-0" style={{ overflow: "hidden" }}>
      <div className="col-12 bg-white">
        <div className="row taxonomyHeader">
          {/* <div className="row pt-2 " > */}
          <div className="col-md-4 pt-2" style={{ minWidth: "250px", maxWidth: "300px" }}>
            {media.length > 0 ? (
              <DropDownListComponent
                value={selectedMedia || media[0].id}
                change={(value) => {
                  setSelectedMedia(value.itemData?.id);
                  fetchTaxonomy(value.itemData?.id);
                  setTreeSorting(value.itemData?.taxonomySortSeq);
                  // setSelectedTaxonomy({ label: value.taxonomyName, value: value.id })
                  let typeSort = treeSort.find((item) => {
                    return value?.itemData?.taxonomySortSeq === item?.value;
                  });
                  typeSort && setSort(typeSort.id);
                  if (value.itemData?.taxonomySortSeq === "CUSTOM") setAllowDragAndDrop(true);
                }}
                cssClass={"customCss e-outline"}
                floatLabelType="Auto"
                popupHeight="250px"
                dataSource={[...media]}
                fields={{ text: "taxonomyName", value: "id" }}
                placeholder="Select Taxonomy"
              />
            ) : null}
          </div>
          {treeSorting ? (
            <div className="pt-2 pr-0" style={{ width: "fitContent", display: "inline-block" }}>
              <div className="pt-2">
                <span
                  className="ml-2 ordering"
                  onClick={() => {
                    setTreeSorting("ASC");
                    handleSorting({ id: 1, value: "ASC" });
                    setChangeOrder(true);
                    setAllowDragAndDrop(false);
                  }}
                  style={treeSorting == "ASC" ? { color: "#F94F5E" } : { color: "black" }}
                >
                  <Ascending fill={treeSorting == "ASC" ? "#F94F5E" : "#939399"} />
                  Ascending
                </span>
                <span
                  className="ml-2 ordering"
                  onClick={() => {
                    setTreeSorting("DESC");
                    handleSorting({ id: 2, value: "DESC" });
                    setChangeOrder(true);
                    setAllowDragAndDrop(false);
                  }}
                  style={treeSorting == "DESC" ? { color: "#F94F5E" } : { color: "black" }}
                >
                  <Descending fill={treeSorting == "DESC" ? "#F94F5E" : "#939399"} />
                  Descending
                </span>
                <span
                  className="ml-2 ordering"
                  style={treeSorting == "CUSTOM" ? { color: "#F94F5E" } : { color: "black" }}
                  onClick={() => {
                    setTreeSorting("CUSTOM");
                    handleSorting({ id: 3, value: "CUSTOM" });
                    setChangeOrder(true);
                    setAllowDragAndDrop(true);
                  }}
                >
                  <Custom fill={treeSorting == "CUSTOM" ? "#F94F5E" : "#939399"} />
                  Custom Order
                </span>
              </div>
            </div>
          ) : null}
          <div className="pr-0 pl-0 ml-auto mr-2" style={{ maxWidth: "fitContent" }}>
            <div className="mata-content p-0" style={{ height: "50px", background: "#FAFAFA" }}>
              <ul>
                {accessActionAdd && (
                  <li className="cursor-pointer">
                    <span className="btn btn-primary pr-3 pt-1 pb-1" onClick={() => openMediaAddModal()}>
                      Add
                    </span>
                  </li>
                )}
                {accessActionEdit && (
                  <li className="cursor-pointer nopointer">
                    <span
                      className={`btn pl-3 pt-1 pb-1  
											 ${selectedTaxonomy ? "taxonomyCustom" : "taxonomyCustomDisable disabled"}`}
                      onClick={() => openTechnologyEditModal()}
                    >
                      Edit
                    </span>
                  </li>
                )}
                {accessActionDelete && (
                  <li className="cursor-pointer nopointer">
                    <span
                      className={`btn pl-3 pt-1 pb-1  
											 ${selectedTaxonomy ? "taxonomyCustom" : "disabled taxonomyCustomDisable"}`}
                      onClick={() => deleteTechnology()}
                    >
                      Delete
                    </span>
                  </li>
                )}
                {treeCollapse ? (
                  <li className="cursor-pointer">
                    <span className="btn  pl-3 pt-1 pb-1 taxonomyCustom" onClick={() => setTreeCollapse(false)}>
                      Expand All
                    </span>
                  </li>
                ) : (
                  <li className="cursor-pointer">
                    <span className="btn  pl-3 pt-1 pb-1 taxonomyCustom" onClick={() => setTreeCollapse(true)}>
                      Collapse All
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 p-0 bg-white">
        <div className="p-0 pt-3 treeScroll">
          {taxonomy.length > 0 ? (
            <div>
              <SortableTree
                treeData={taxonomy}
                onClick={handleClick}
                saveUpdatedTaxonomy={saveUpdatedTaxonomy}
                treeCollapse={treeCollapse}
                sort={treeSorting}
                allowDragAndDrop={allowDragAndDrop}
                setTaxonomy={setTaxonomy}
                selectedMedia={selectedMedia}
                reRender={reRender}
                setReRender={setReRender}
                changeOrder={changeOrder}
                parentId={"taxonomyParentId"}
              />
            </div>
          ) : null}
        </div>
        <div className="ml-auto">
          <div className="taxonomySidebar">
            <span
              className={`${showInstruction ? "invertedText-Color" : "invertedText"}`}
              onClick={() => {
                setToggle(true);
                setShow(true);
                setShowInstruction(true);
                setShowAddEdit(false);
              }}
            >
              {" "}
              Instructions
            </span>
            {showAddEdit && (
              <span
                className={`${showAddEdit ? "invertedText-Color" : "invertedText"}`}
                onClick={() => {
                  setToggle(true);
                  setShow(true);
                  setShowAddEdit(true);
                  setShowInstruction(false);
                }}
              >
                {" "}
                Options
              </span>
            )}
          </div>
        </div>
      </div>
      {showAdd && !showInstruction && (
        <SideBar
          toggle={toggle}
          show={show}
          setShow={setShow}
          showAddEdit={showAddEdit}
          handleSubmit={handleSubmitSaveTaxonomy}
          selectedNode={selectedTaxonomy}
          setShowAddEdit={setShowAddEdit}
          setShowInstruction={setShowInstruction}
        />
      )}
      {edit && !showInstruction && (
        <SideBar
          toggle={toggle}
          show={show}
          setShow={setShow}
          showAddEdit={showAddEdit}
          selectedTaxonomy={selectedTaxonomy}
          handleSubmit={handleSubmitUpdateTaxonomy}
          selectedNode={selectedTaxonomy}
          setShowAddEdit={setShowAddEdit}
          setShowInstruction={setShowInstruction}
        />
      )}
      {showInstruction && (
        <SideBar
          toggle={toggle}
          show={show}
          setShow={setShow}
          showInstruction={showInstruction}
          setShowAddEdit={setShowAddEdit}
          setShowInstruction={setShowInstruction}
        />
      )}
    </div>
  );
};

export default TaxomonyUI;
