import { put, call, takeLatest } from "redux-saga/effects";
import { getContentTypesSearch } from "../../middleware/actions/searchAction"; // Import your Redux actions here
import http from "../../middleware/services/http-common"; // Import your HTTP library here
import { CMS_API_URL } from "../../config";
import { CONTENT_TYPE } from "../../constants";

// Worker Saga
function* fetchContentTypeSearch() {
  try {
    const res = yield call(http.get, `${CMS_API_URL}/common-access/content-type`, {
      params: {
        filters: JSON.stringify([["contentTypeProductVariant", "eq", "InsiderV2"]]),
        sort: "id:asc",
      },
    });

    if (res.status === 200 && res.data.data) {
      const desiredOrder = [
        CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH,
        CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS,
        CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES,
        CONTENT_TYPE.DEVELOPMENTS_NEWS,
      ];
      // Sort the array based on the desired order
      const orderList = desiredOrder.map((contentType) => res.data.data.find((item) => item.contentType === contentType));
      let list = orderList.map((ele) => ({
        value: ">" + ele.id + ">" + ele.contentType,
        contentType: ele.contentType,
        label: `${ele.contentType} (0)`,
      }));
      yield put(getContentTypesSearch(list));
      // dispatch(uiAction.toggleLoading(false));
    }
  } catch (error) {
    // Handle errors if needed
    console.error(error);
  }
}

// Watcher Saga
function* watchGetContentTypeSearch() {
  yield takeLatest("GET_CONTENT_TYPE_SEARCH", fetchContentTypeSearch);
}

export default watchGetContentTypeSearch;
