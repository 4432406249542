import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import ReportInternalUser from "../app/views/dataReport/internal-user";
import ReportVendor from "../app/views/dataReport/vendor";
import PublicationStatus from "../app/views/dataReport/publicationStatus";
import ReportClient from "../app/views/dataReport/client";
import ReportContent from "../app/views/dataReport/content";
import clientDetails from "../app/views/dataReport/clientDetails";
import AnalystQueryReport from "app/views/dataReport/analystQueryReport";
import UserTrafic from "../app/views/dataReport/userTrafic";
import BusinessObjectiveReport from "../app/views/dataReport/businessObjectiveReport";
import ContentTypeReport from "app/views/dataReport/contentReportBasic";
import KeywordSearch from "app/views/dataReport/keywordSearch";

const ACCESS_RIGHTS_INTERNAL = ["Internal User:VIEW", "Internal User:FULL"];
const ACCESS_RIGHTS_VENDOR = ["Vendor:VIEW", "Vendor:FULL"];
const ACCESS_RIGHTS_CLIENT = ["Client User:FULL", "FULL"];

const reportRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/reports/internal-user"
      component={ReportInternalUser}
      pageHeading="GenNx CMS - CMS User Activity Report"
      accessRights={{
        moduleName: "CMS User Activity Report",
        parentModuleName: "Reports",
        moduleAttribute: [...ACCESS_RIGHTS_INTERNAL],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/reports/vendor"
      component={ReportVendor}
      pageHeading="GenNx CMS - Vendor Activity Report"
      accessRights={{
        moduleName: "Vendor Activity Report",
        parentModuleName: "Reports",
        moduleAttribute: [...ACCESS_RIGHTS_VENDOR],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/reports/client"
      component={ReportContent}
      pageHeading="GenNx CMS - Client Activity Report"
      accessRights={{
        moduleName: "Client Activity Report",
        parentModuleName: "Reports",
        moduleAttribute: [...ACCESS_RIGHTS_CLIENT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/reports/user-details"
      component={clientDetails}
      pageHeading="GenNx CMS - User Details Report"
      accessRights={{
        moduleName: "User Details Report",
        parentModuleName: "Reports",
        moduleAttribute: [...ACCESS_RIGHTS_CLIENT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/reports/publication-status"
      component={PublicationStatus}
      pageHeading="GenNx CMS - Publication Status Report"
      accessRights={{
        moduleName: "Publication Status Report",
        parentModuleName: "Reports",
        moduleAttribute: [...ACCESS_RIGHTS_CLIENT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/reports/ask-your-analyst"
      component={AnalystQueryReport}
      pageHeading="GenNx CMS - Ask your Analyst Report"
      accessRights={{
        moduleName: "Ask your Analyst Report",
        parentModuleName: "Reports",
        moduleAttribute: [...ACCESS_RIGHTS_CLIENT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/reports/business-objective"
      component={BusinessObjectiveReport}
      pageHeading="GenNx CMS - Business Objective Report"
      accessRights={{
        moduleName: "Business Objective Report",
        parentModuleName: "Reports",
        moduleAttribute: [...ACCESS_RIGHTS_CLIENT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/reports/user-traffic"
      component={UserTrafic}
      pageHeading="GenNx CMS - User Traffic Report"
      accessRights={{
        moduleName: "User Traffic Report",
        parentModuleName: "Reports",
        moduleAttribute: [...ACCESS_RIGHTS_CLIENT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/reports/content-type-analysis"
      component={ContentTypeReport}
      pageHeading="GenNx CMS - Content Type Analysis Report"
      accessRights={{
        moduleName: "Content Type Wise Analysis Report",
        parentModuleName: "Reports",
        moduleAttribute: [...ACCESS_RIGHTS_CLIENT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/reports/keyword-search"
      component={KeywordSearch}
      pageHeading="GenNx CMS - Keyword Search Report"
      accessRights={{
        moduleName: "Keyword Search Report",
        parentModuleName: "Reports",
        moduleAttribute: [...ACCESS_RIGHTS_CLIENT],
        interfaceName: "Listing",
      }}
    />
  </Switch>
  // </Suspense>
);

export default reportRoutes;
