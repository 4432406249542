// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description :Common date, label & tags section reused in most of the pages
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :11 Oct 2023
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from "react";
import moment from "moment";
import classes from "./CommonDateTagSection.module.css";

// pair of date and tags used in most of the components
const CommonDateTagSection = ({
  date,
  label,
  tags,
  sgfFilters,
  location,
  readTime,
  showReadTime,
  ribbon,
  highlighting,
  subType,
  showSubType,
  textStyle = "black",
  tagLength = 3,
}) => {
  return (
    <div className={classes.recent_content_card} style={{ color: textStyle, width: "100%" }}>
      <div className={`${classes.date_summary} mobile_date_summary`}>
        <span className={classes.card_cal}>{moment(date).format("DD MMM YYYY")}</span>
        {showReadTime && readTime ? (
          <span className={`${classes.read_time}`}>
            <img src={textStyle == "white" ? "/assets/clock-white.svg" : "/assets/clock.svg"} alt="clockIcon"></img>
            {readTime} min
          </span>
        ) : null}
        <span className={`${classes.summary} summary`} title={label} style={{ color: textStyle }}>
          {label}
        </span>
        {showSubType && subType && (
          <span className={`${classes.summary} summary`} style={{ color: textStyle }} title={subType}>
            {subType}
          </span>
        )}
        <span className={`${classes.summary} summary`} style={{ color: textStyle }} title={ribbon}>
          {ribbon}
        </span>
      </div>

      {tags && tags?.length > 0 ? (
        <div className={classes.des_icon}>
          <img src={textStyle == "white" ? "/assets/tag-white.svg" : "/assets/tag.svg"} alt="tag" />
          <ul>
            {tags?.map((tag, i) => {
              if (i < tagLength) {
                return <li key={tag.trendId + i + Math.random()}>{tag.trendName}</li>;
              }
            })}
          </ul>
        </div>
      ) : (
        <div className={classes.des_icon}></div>
      )}
    </div>
  );
};

export default CommonDateTagSection;
