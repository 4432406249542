/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description            :  Company Advanced info UI for  Listing, edit, add and delete and API wirirng up 
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 05/Aug/2020 
	Author						: Anand Kumar 
================================================================ 
*/

import React, { useEffect } from "react";
import Accordion from "../../../../components/accordion";
import { fetchSingleCompanyAdvanced } from "../../../../../middleware/services/companyApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextEditor } from "../../../../../app/components/CustomFormElements/syncFusionFormFields";
import { advancedValidationSchema } from "../../../../helper/validationSchema/companyValidationSchema";
import { actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { useDispatch, useSelector } from "react-redux";
import ActionButtons from "./actionButtons";
import { ckEditorMinimalConfig } from "../../../../../config/ckEditorConfig";
import { setAdvanceTabDetails } from "../../../../../middleware/actions/companyAction";
import { TextField } from "../../../../components/CustomFormElements/syncFusionFormFields";

const initialState = {
  companyAbout: "",
  companyWhatsUnique: "",
  companyBusinessModel: "",
  companyFuturePlans: "",
};
const AdvancedUI = (props) => {
  const companyId = props.companyId;
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    validationSchema: Yup.object().shape(advancedValidationSchema),
  });
  const commonState = useSelector((state) => state.companyState);
  const commonAdvance = commonState?.advanceDetails;

  const getCompanyDetails = () => {
    dispatch(showLoader());
    const params = {
      fields: ["companyAbout", "companyWhatsUnique", "companyBusinessModel", "companyFuturePlans"],
    };
    fetchSingleCompanyAdvanced(companyId, params)
      .then((response) => {
        props.setAdvancedFetched(true);
        formik.setValues({
          companyAbout: props.advancedFetched ? commonAdvance?.companyAbout : response.data.data.companyAbout,
          companyBusinessModel: props.advancedFetched ? commonAdvance?.companyBusinessModel : response.data.data.companyBusinessModel,
          companyWhatsUnique: props.advancedFetched ? commonAdvance?.companyWhatsUnique : response.data.data.companyWhatsUnique,
          companyFuturePlans: props.advancedFetched ? commonAdvance?.companyFuturePlans : response.data.data.companyFuturePlans,
          id: commonAdvance?.id && commonAdvance?.id.length ? commonAdvance?.id : response.data.data.id,
        });
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(actionError(err.data?.message || "Fails to get company advanced info"));
      });
  };
  useEffect(() => {
    companyId && getCompanyDetails();
  }, []);

  useEffect(() => {
    dispatch(setAdvanceTabDetails(formik.values));
  }, [formik.values]);

  return (
    <div className="gennx-content-wrapper padding-bottom-100i pt-3" style={{ width: "99.5%" }}>
      <div className="mt-0 mx-2">
        <Accordion
          heading="About The Company"
          stepCompleted={formik.values.companyAbout && formik.values.companyAbout.length > 0 ? true : false}
          step={1}
          required={true}
        >
          <div className="mt-2">
            <TextEditor
              formik={formik}
              properties={{
                id: "companyAbout",
                name: "companyAbout",
                activeClass: "editor",
                label: "",
                config: {
                  ...ckEditorMinimalConfig,
                  editorplaceholder: "About The Company",
                  height: companyId ? "200px" : "206px",
                },
              }}
            />
          </div>
        </Accordion>

        <Accordion
          heading="Company What's Unique/Tagline"
          stepCompleted={formik.values.companyWhatsUnique && formik.values.companyWhatsUnique.length > 0 ? true : false}
          step={2}
        >
          <div className="mt-4">
            <TextField
              style={{ height: "100px" }}
              formik={formik}
              multiline={true}
              numberCount={true}
              rows={5}
              maxLength={200}
              name="companyWhatsUnique"
              className="form-control"
              id="companyWhatsUnique"
              placeholder="Company What's Unique/Tagline"
              characterLimitText={true}
              hideInitial={true}
              value={formik.values.companyWhatsUnique}
            />
          </div>
        </Accordion>
        <Accordion
          heading="Company Business Model"
          stepCompleted={formik.values.companyBusinessModel && formik.values.companyBusinessModel.length > 0 ? true : false}
          step={3}
        >
          <div className="mt-2">
            <TextEditor
              formik={formik}
              properties={{
                id: "companyBusinessModel",
                name: "companyBusinessModel",
                activeClass: "editor",
                label: "",
                config: {
                  ...ckEditorMinimalConfig,
                  editorplaceholder: "Company Business Model",
                  height: companyId ? "200px" : "206px",
                },
              }}
            />
          </div>
        </Accordion>
        <Accordion
          heading="Future Plans"
          stepCompleted={formik.values.companyFuturePlans && formik.values.companyFuturePlans.length > 0 ? true : false}
          step={4}
        >
          <div className="mt-2">
            <TextEditor
              formik={formik}
              properties={{
                id: "companyFuturePlans",
                name: "companyFuturePlans",
                activeClass: "editor",
                label: "",
                config: {
                  ...ckEditorMinimalConfig,
                  editorplaceholder: "Future Plans",
                  height: companyId ? "200px" : "206px",
                },
              }}
            />
          </div>
        </Accordion>
      </div>
      <ActionButtons
        accessRights={props.accessRights}
        selectedIndex={props.selectedIndex}
        nextPath={companyId ? `/company/${companyId}/edit#basic` : true}
        previousPath={`/company/${companyId}/edit#basic`}
        handleCommonSave={props.handleCommonSave}
        setCurrentTab={(selectedIndex) => {
          props.setCurrentTab(selectedIndex);
        }}
        back={props.path === "/processing/company/:companyId/edit" ? "/processing/company" : "/company"}
        companyId={companyId}
        formik={formik}
        hashArr={props.hashArr}
      />
    </div>
  );
};

export default AdvancedUI;
