// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : Pink color button used in homepage & bookmarks
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :9 Jun 22
// Author:Umesh
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React, { Fragment } from 'react';
import classes from './PinkButton.module.css';

const PinkButton = (props) => {
  return (
    <Fragment>
      <button onClick={props.onClick} className={`${classes.pinkBtn} ${props.className}`} type="button">
        {props.children}
      </button>
    </Fragment>
  );
};

export default PinkButton;
