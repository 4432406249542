/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Signal Data Upload Listing Page
	 - Use common grid component for list
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 07/12/2023
	Author						: Prashant Wankhade
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CommonDataGrid from "../../components/dataGrid";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { accessRightActionCheck, getDateFromMongoDate } from "../../../utilities";
import { DropDownFilter, StringFilter } from "../../components/dataGrid/fliters";
import { fetchAllLogs } from "../../../middleware/services/uploadDataApi";
import { useHistory } from "react-router-dom";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";
import { fetchTaxonomyTrend } from "middleware/services/cmsApi";
import { setMaintainedState } from "middleware/actions/gridAction";
import gridState from "middleware/reducers/gridState";
import { COMMON_ACCESS_ACTION } from "../../../constants";

export default (props) => {
  const history = useHistory();
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const breadCrumbLinks = [{ linkUrl: "/data-upload", linkName: "Data Upload", linkActive: true }];
  const [uploadedIndustry, setUploadedIndustry] = useState([]);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessDataUpload = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DATA_UPLOAD);

  useEffect(() => {
    localStorage.removeItem("selectedPatent");
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
      localStorage.removeItem("selectedPatent");
    };
  }, [dispatch]);

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  const patentBatchIdTemplate = (value) => {
    return <span className="">{value["Batch Number"]}</span>;
  };

  const fetchTrendData = () => {
    let filters = [];
    filters = [["trendParentId", "eq", 0]];
    dispatch(showLoader());

    fetchTaxonomyTrend({
      filters: filters,
      fields: ["id", "trendName", "trendParentId", "trendParentName"],
      sort: "trendName:asc",
      limit: 2000,
    })
      .then((res) => {
        if (res.status === 200) {
          setUploadedIndustry(
            res.data.data.map((d) => {
              return { id: d.trendName, text: d.trendName };
            })
          );
        } else {
          console.error("err fetchTrend :: ");
        }
      })
      .catch((err) => {
        console.error("err fetchTrend :: ", err);
      });
    dispatch(hideLoader());
  };

  const newsDateTemplate = (value) => {
    const column = value.column.field.trim();
    return ["Upload Date"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
      <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
    ) : (
      ""
    );
  };

  const statusTemplate = (props) => {
    return <span>{props?.Status.replace(/_/g, " ")}</span>;
  };

  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Status":
        sourceData = [
          { id: "COMPLETED", text: "COMPLETED" },
          // { id: "IN_PROGRESS", text: "IN PROGRESS" },
          { id: "FAILED", text: "FAILED" },
        ];
        break;
      case "Industry":
        sourceData = uploadedIndustry;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const columnNames = {
    id: "id",
    "Batch Number": "data_file_id",
    "File Name": "data_file_uploaded.data_file_original_name",
    Industry: "data_industry_name",
    "Upload By": "data_file_uploaded_by.user_name",
    "Upload Date": "data_file_uploaded_on",
    Status: "data_file_upload_status",
  };
  const columnFields = [
    { field: "id", type: "string", visible: false, allowFiltering: false, showInColumnChooser: false, isPrimaryKey: true },
    {
      field: "Batch Number",
      type: "String",
      template: patentBatchIdTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 250,
      filter: { operator: "equal" },
    },
    {
      field: "File Name",
      type: "String",
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      textAlign: "Left",
      headerTextAlign: "Left",
    },
    {
      field: "Industry",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      showInColumnChooser: false,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 250,
    },
    {
      field: "Upload By",
      width: 200,
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      textAlign: "Left",
      headerTextAlign: "Left",
      filter: { operator: "equal" },
    },
    { field: "Upload Date", width: 200, textAlign: "Center", type: "Date", template: newsDateTemplate, headerTextAlign: "Center" },
    {
      field: "Status",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 150,
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      template: statusTemplate,
    },
  ];

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];

    rowData &&
      rowData.forEach((uploadData) => {
        let fullName = uploadData.data_file_uploaded_by;
        const fileUploadedBy = `${fullName.user_fname} ${fullName.user_lname}`;
        let data = {};
        data.id = uploadData._id;
        data["Batch Number"] = uploadData.data_file_id;
        data["File Name"] = uploadData.data_file_uploaded.data_file_original_name;
        data["Industry"] = uploadData.data_industry_name;
        data["Upload By"] = fileUploadedBy;
        data["Upload Date"] = uploadData.data_file_uploaded_on;
        data["Status"] = uploadData.data_file_upload_status;

        formatedRowData.push(data);
      });
    return formatedRowData;
  };

  const fetchPatentWithProductVariant = async (params = {}) => {
    if (!params.sort) {
      params.sort = "data_file_uploaded_on:desc";
    }

    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }

    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    return fetchAllLogs(params);
  };

  useEffect(() => {
    fetchTrendData();
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        {uploadedIndustry?.length > 0 && (
          <CommonDataGrid
            gridTitle="Signal Data Upload"
            fetch={fetchPatentWithProductVariant}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            // importPatent={accessPermissionImport}
            isImport={() => history.push(`/data-upload/add`)}
            clearFilterByTaxonomy={true}
            deleteMany={false}
            allowPagination={true}
            dataUpload={accessDataUpload}
          />
        )}
      </div>
    </div>
  );
};
