import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import PatentListing from "../app/views/patent";

const PATENT = ["Patent:VIEW", "Patent:FULL"];
const patentRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/patent"
      component={PatentListing}
      pageHeading="GenNx CMS - Patent"
      accessRights={{
        moduleName: "Patent",
        parentModuleName: "",
        moduleAttribute: [...PATENT],
        interfaceName: "Listing",
      }}
    />
  </Switch>
);

export default patentRoutes;
