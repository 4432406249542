/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase New Folder Modal
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React from "react";
import { Modal } from "react-bootstrap";
import ModalTextInput from "../ModalTextInput/ModalTextInput";
import ModalCancelSaveButtons from "../ModalCancelSaveButtons/ModalCancelSaveButtons";
import classes from "./ModalBriefcaseNewFolder.module.css";

const ModalBriefcaseNewFolder = (props) => {
  // check input contains only special characters or not
  const regex = /^[!@#$%^&*()_-]+$/;
  return (
    <div>
      <Modal
        className="CentredModal"
        dialogClassName={classes.modalMain}
        contentClassName={classes.modalContent}
        show={props.show}
        onHide={props.onHide}
        centered
        backdrop="static"
      >
        <Modal.Header className={classes.modalHeader}>
          <Modal.Title className={classes.modalTitle}>{props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modalBody}>
          <ModalTextInput
            label="Folder Name"
            placeholderText="Enter Name"
            folderName={props.folderName}
            setFolderName={props.setFolderName}
            createFolder={props.createFolder}
            setFolderExistError={props.setFolderExistError}
          />

          {/^\s/.test(props.folderName) && <p className={classes.errorText}>Folder name should not be start with space.</p>}

          {regex.test(props.folderName) && props.showError && (
            <p className={classes.errorText}>Folder name should not contain only special characters.</p>
          )}

          {props.showError && props.folderName === "" && <p className={classes.errorText}>Enter folder name.</p>}

          {props.folderExistError && <p className={classes.errorText}>Folder is already exists.</p>}
        </Modal.Body>

        <Modal.Footer className={classes.modalFooter}>
          <ModalCancelSaveButtons cancelButtonClick={props.onHide} saveButtonText="Save" saveButtonClick={props.createFolder} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalBriefcaseNewFolder;
