/**
 * @fileDescription Data Import Screening Rejection
 * @author Pankaj Kulshreshtha | Cheers Interactive
 * @date 14/Jan/2021 *
 */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  actionStart,
  actionSuccess,
  actionError,
  showMessage,
  hideMessage,
  showLoader,
  hideLoader,
} from "../../../middleware/actions/utilityAction";
import PropTypes from "prop-types";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { Dropdown } from "../CustomFormElements/ProductInformation";
import { screeningUploadDocValidationSchema } from "../../helper/validationSchema/companyValidationSchema";
import { getLoggedInUser, getProductVariant } from "../../../utilities";
import {
  fetchScreeningTemplateFileUrl,
  uploadScreeningTemplateData,
  fetchImportErrorFileUrl,
} from "../../../middleware/services/companyApi";

import _ from "lodash";
import "./style.scss";
import MultiStep from "./multiStep";

const DocumentModal = (props) => {
  // React states for screening
  const [dts, setDts] = useState(false);
  const [errorMsg, setErrorMsg] = useState(``);
  // React states for File Upload
  const [oldData, setOldData] = useState(null);

  const productVariant = getProductVariant();
  const loggedData = getLoggedInUser();
  const userId = loggedData._id;

  const UploadComponent = (props) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      // accept: "image/*",
      accept: ".xls,.xlsx",
      maxFiles: 1, //comment this line if upload multiple File
      onDrop: (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length) {
          props.formik.setValues({
            ...props.formik.values,
            documentFile: acceptedFiles,
          });
        }
      },
    });
    return (
      <>
        <div
          className="form-group bg-light p-3 border-dotted mb-1"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <button type="button" className="btn btn-dark">
            UPLOAD DATA TEMPLATE
          </button>{" "}
          Or drop file here
        </div>
        <small>Only xls, xlxs files are allowed.</small>

        <div
          className="text-danger"
          style={{
            display:
              props.formik.touched.documentFile === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>
            {props.formik.touched.documentFile === true
              ? props.formik.errors.documentFile || ""
              : ""}
          </small>
        </div>
      </>
    );
  };

  const initialFormValues = {
    documentFileName: "",
  };

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(screeningUploadDocValidationSchema),
  });

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, []);

  const onSaveData = async (data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await uploadScreeningTemplateData(formData)
      .then((result) => {
        if (result.status === 200) {
          const { status, msg, fileName = undefined } = result.data.data;
          if (status === "error") {
            dispatch(actionError(msg));
            return result.data.data;
          } else {
            dispatch(actionSuccess("Data uploaded successfully"));
            return result.data.data;
          }
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err ? err.data.message : "Something went wrong"));
        return { status: "failed" };
      });
    return response;
  };

  const handleFinalSubmit = async () => {
    // if (!(checkedScreening.length || checkedProcessing.length) && formik.values.uploadFor) {
    // 	dispatch(actionError(errorMsg));
    // }
    const payload = {};
    const { values } = formik;
    const uploadData = {
      docFile: values.documentFile[0],
    };

    await onSaveData(uploadData)
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            formik.setErrors(res);
            dispatch(actionSuccess(res.msg));
          } else {
            console.error("ERRR", res);
            // formik.setFormikState({ ...formik, touched: true, errors: res });
            formik.setErrors(res);
          }
        }
      })
      .catch((err) => {
        dispatch(actionError(err));
        console.error("QRRR", err);
      });
  };
  const handleSubmitDocument = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        handleFinalSubmit();
      }
    });
  };
  const StepOne = (props) => {
    // const valueArr =  productVariant && productVariant === "Insider" ? ["Trend"]: ["Technology"];
    // const valueArr = ["Technology", "Trend"];
    if (formik.values.uploadFor === "")
      formik.setValues({
        ...formik.values,
        uploadFor:
          productVariant && productVariant === "Insider"
            ? "Trend"
            : "Technology",
      });
    return (
      <>
        <form>
          <div className="row">
            <div className="col-md-12 pr-4 pb-2">
              <a
                style={{ float: "right" }}
                href={fetchScreeningTemplateFileUrl()}
                download
              >
                {" "}
                <i className="fas fa-download" data-tip="Download" />
                Download Template
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <UploadComponent
                editDocumentData={props.editDocumentData}
                formik={formik}
              />
              {formik.values.documentFile &&
                formik.values.documentFile.map((file, i) => (
                  <li key={i}>
                    {`File:${file.name}
												`}{" "}
                  </li>
                ))}
            </div>
          </div>
        </form>
      </>
    );
  };

  const steps = [
    {
      id: 1,
      step: 1,
      name: "Upload",
      handleSubmitDocument: handleSubmitDocument,
      handleFinalSubmit: handleFinalSubmit,
      component: <StepOne />,
    },
  ];

  return (
    <>
      <MultiStep
        show={props.show}
        closeHandler={props.closeHandler}
        formik={formik}
        showNavigation={true}
        steps={steps}
      />
    </>
  );
};

export default DocumentModal;
DocumentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
};
