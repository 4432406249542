/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Content Block Technology Tagging Component 
	 Summary : - Import Technology tree view from common component
	 - Save selected technology data
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 01/July/2022 
	Author						  : Lawrence Anthony
================================================================ 
*/
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TreeView from "../../../../../components/treeView/taxonomy";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import {
  saveContentBlockTaxonomy,
  deleteContentBlockTaxonomy,
  fetchSingleContentBlock,
  saveContentBlockValueChain,
  fetchContentBlockTaxonomy,
  fetchContentBlockKia,
  deleteContentBlockKia,
  saveContentBlockKiaTaxonomy,
  deleteManyContentBlockTechnology,
  deleteManyContentBlockValueChain,
} from "../../../../../../middleware/services/contentBlockApi";

const TaxomonyUI = (props) => {
  const dispatch = useDispatch();
  const contentBlockId = props.contentBlockId;
  /*
	@Description : If saveContentBlock Sate is true then will trigger technology save event.
	Save content block state change trigger from footerBar Component
	*/
  useEffect(() => {
    if (props.saveContentBlock) {
      props.handleSave(false);
    }
  }, [props.saveContentBlock]);
  /*
	@param selectedIndustry : Selected Industry to save their respective kia
	@param kiaIds : Selected KiaIds
	@param callback : use for re-render tree component
	
	@description : Save kia for respective industry
				1.Delete all kia for selected industry
				2.Save all kia for selected industry
	*/
  const saveKiaTagging = (selectedIndustry, kiaIds, tab, callback) => {
    if (selectedIndustry) {
      dispatch(showLoader());
      deleteContentBlockKia(contentBlockId, { industryId: selectedIndustry })
        .then(() => {
          dispatch(hideLoader());
          if (kiaIds.length > 0) {
            dispatch(showLoader());
            try {
              const response = saveContentBlockKiaTaxonomy(contentBlockId, {
                taxonomyIds: kiaIds,
              });
              response
                .then((response) => {
                  callback();
                  dispatch(hideLoader());
                  dispatch(actionSuccess("KIA Updated Successfully"));
                })
                .catch((e) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...1!"));
                });
            } catch (e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...2!"));
            }
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...3!"));
        });
    }
  };

  /*
	/*
	@param selectedTech : Selected technology to save their respective taxonomy
	@param taxonomyIds : Selected taxonomy
	@param callback : use for re-render tree component
	
	@description : Save taxonomy for respective technology
				1.Delete all taxonomy for selected technology
				2.Save all taxonomy for selected technology
	*/
  const saveTaxonomy = (selectedTech, taxonomyIds, callback) => {
    if (selectedTech) {
      dispatch(showLoader());
      deleteContentBlockTaxonomy(contentBlockId, selectedTech)
        .then(() => {
          dispatch(hideLoader());
          if (taxonomyIds.length > 0) {
            dispatch(showLoader());
            try {
              const response = saveContentBlockTaxonomy(contentBlockId, {
                taxonomyIds: taxonomyIds,
              });
              response
                .then((response) => {
                  dispatch(hideLoader());
                  dispatch(actionSuccess("Taxonomy Updated Successfully"));
                  callback();
                })
                .catch((e) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...!"));
                });
            } catch (e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            }
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    }
  };
  /*
	@param selectedTech : Selected technology to save their respective taxonomy
	@param checkValues : Selected value chain
	@param callback : use for re-render tree component
	
	@description : Save value chain for respective industry
	*/
  const handleSaveValueChain = (selectedTech, checkValues, callback1, callback2) => {
    dispatch(showLoader());
    const response = saveContentBlockValueChain(contentBlockId, {
      ...checkValues,
    });
    response
      .then((response) => {
        dispatch(hideLoader());
        callback1();

        dispatch(actionSuccess("Value Chain Updated Successfully"));
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  const deleteTechnology = (checkedTrends, callback) => {
    deleteManyContentBlockTechnology(contentBlockId, checkedTrends)
      .then((res) => {
        callback();

        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  const deleteKia = (checkedTrends, callback) => {
    let payload = { taxonomyIds: checkedTrends };
    deleteContentBlockKia(contentBlockId, payload)
      .then(() => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  const deleteValueChain = (payload, callback) => {
    deleteManyContentBlockValueChain(contentBlockId, payload)
      .then(() => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  return (
    <TreeView
      handleSave={saveTaxonomy}
      saveTreeData={props.saveContentBlock}
      contentId={contentBlockId}
      fetchContentTagging={fetchContentBlockTaxonomy}
      fetchContentData={fetchSingleContentBlock}
      fetchKiaTagging={fetchContentBlockKia}
      loadOtherTagInfo={props.loadOtherTagInfo}
      handleSaveKIA={saveKiaTagging}
      handleSaveValueChain={handleSaveValueChain}
      contentType="contentBlock"
      deleteTechnology={deleteTechnology}
      isDelete={true}
      setSaveTreeData={props.setSaveContentBlockFlag}
      deleteKia={deleteKia}
      deleteValueChain={deleteValueChain}
      accessPermissionTaxonomy={props?.accessPermissionTaxonomy}
      accessPermissionTaxonomyDelete={props?.accessPermissionTaxonomyDelete}
    />
  );
};

export default TaxomonyUI;
