/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : User Validation Schema
----------------------------------------------------------------
	Creation Details
	Date Created				: 03/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import * as Yup from "yup";
import * as REGEX from "../../../config/regexValidation"
export const userValidationSchema = {
	userFname: Yup.string().min(2, "First name must be minimum 2 characters").required("First name is required"),
	userLname: Yup.string().min(2, "Last name must be minimum 2 characters").required("Last name is required"),
	userEmail: Yup.string().typeError("Please enter valid email").email("Please enter valid email").required("Email is required"),
	userPassword: Yup.string().required("New password is required").min(6, "New password must be minimum 6 characters").max(18, "New password must be at most 18 characters"),
	// .test("userPassword", "Must be minimum 6 characters", userPassword => userPassword && userPassword.length < 6)
	// .test("userPassword", "Must be maximum 18 characters", userPassword => userPassword && userPassword.length > 18),
	userConfirmPassword: Yup.string().oneOf([Yup.ref("userPassword"), null], "Your password and confirm password do not match").required("Confirm new password is required"),
	// .test("userConfirmPassword", "Must be minimum 6 characters", userConfirmPassword => userConfirmPassword && userConfirmPassword.length < 6)
	// .test("userConfirmPassword", "Must be maximum 18 characters", userConfirmPassword => userConfirmPassword && userConfirmPassword.length > 18),
	userDesignation: Yup.string(),
	userMobile: Yup.string().matches(REGEX.PHONE, 'Please enter valid mobile number').min(5, 'Mobile number must be between 5 to 15 digits').max(15, 'Mobile number must be between 5 to 15 digits'),
	userLoginId: Yup.string(),
	//userCompany: Yup.string().typeError("Please enter company name").required("Please enter company name"),
	userActivationDate: Yup.date().max(new Date(), "User activation date must be less than or equal to present date").typeError("Please select user activation date"),
	userEmpCode: Yup.string().min(2, "Employee code must be minimum 2 characters").notOneOf([""]),
};



export const outSourcingUserValidationSchema = {
	showOTP: Yup.boolean(),
	userFname: Yup.string().matches(REGEX.ALPHABATES, 'special character, number and double space not allowed').min(2, "First name must be minimum 2 characters").required("Please enter first name"),
	userLname: Yup.string().matches(REGEX.ALPHABATES, 'special character,number and double space not allowed').min(2, "Last name must be minimum 2 characters").required("Please enter last name"),
	userEmail: Yup.string().typeError("Please enter valid email").email("Please enter valid email").required("Please enter email"),
	userLinkedinUrl: Yup.string().matches(REGEX.LINKEDIN, "Please enter valid LinkedIn URL"),
	userMobile: Yup.string().matches(REGEX.PHONE, 'Please enter valid mobile number').min(5, 'Numbers allowed between 5 to 15 digits').max(15, 'Numbers allowed between 5 to 15 digits').required("Please enter mobile number"),
	institutionName: Yup.string(),
	educationYear: Yup.string(),
	cityName: Yup.string(),
	country: Yup.string().required("Please select a country"),
	address1: Yup.string(),
	address2: Yup.string(),
	postCode: Yup.string(),
	technologyInterest: Yup.array().required("Please select technology interest").nullable(),
	industryInterest: Yup.array().required("Please select industry interest").nullable(),
	equipedWithLaptop: Yup.string().oneOf(["yes", "no"], 'Field must be checked').required("Please choose a option"),
	userEmailOTP: Yup.string().when('showOTP', {
		is: true,
		then: Yup.string().required("Please enter email OTP").min(5, "Please enter 5 digits OTP").max(5, "Please enter 5 digits OTP"),
		otherwise: Yup.string().nullable().oneOf([null, undefined], 'Null'),
	}),
	userMobileOTP: Yup.string().when('showMobileOtp', {
		is: true,
		then: Yup.string().required("Please enter mobile OTP").min(5, "Please enter 5 digits OTP").max(5, "Please enter 5 digits OTP"),
		otherwise: Yup.string().nullable().oneOf([null, undefined], 'Null'),
	}),
	userTncAccepected: Yup.bool().oneOf([true], "Please accept Terms & Conditions").required("Please accept Terms & Conditions")
};
export const registrationValidationSchema = {
	showOTP: Yup.boolean(),
	userEmailOTP: Yup.string().when('showOTP', {
		is: true,
		then: Yup.string().required("Please enter email OTP").min(5, "Please enter 5 digits OTP").max(5, "Please enter 5 digits OTP"),
		otherwise: Yup.string().nullable().oneOf([null, undefined], 'Null'),
	}),
	userMobileOTP: Yup.string().when('showMobileOtp', {
		is: true,
		then: Yup.string().required("Please enter mobile OTP").min(5, "Please enter 5 digits OTP").max(5, "Please enter 5 digits OTP"),
		otherwise: Yup.string().nullable().oneOf([null, undefined], 'Null'),
	}),
	userTncAccepected: Yup.bool().oneOf([true], "Please accept Terms & Conditions").required("Please accept Terms & Conditions")
};
export const expertUserValidationSchema = {
	showOTP: Yup.boolean(),
	userFname: Yup.string().matches(REGEX.ALPHABATES, 'special character, number and double space not allowed').min(2, "First name must be minimum 2 characters").required("Please enter first name"),
	userLname: Yup.string().matches(REGEX.ALPHABATES, 'special character,number and double space not allowed').min(2, "Last name must be minimum 2 characters").required("Please enter last name"),
	userEmail: Yup.string().typeError("Please enter valid email").email("Please enter valid email").required("Please enter email"),
	userLinkedinUrl: Yup.string().matches(REGEX.LINKEDIN, "Please enter valid LinkedIn URL"),
	userMobile: Yup.string().matches(REGEX.PHONE, 'Please enter valid mobile number').min(5, 'Mobile number must be between 5 to 15 digits').max(15, 'Mobile number must be between 5 to 15 digits').required("Please enter mobile number"),
	institutionName: Yup.string(),
	educationYear: Yup.string(),
	cityName: Yup.string(),
	country: Yup.string().required("Please select a country"),
	address1: Yup.string(),
	address2: Yup.string(),
	postCode: Yup.string(),
	technologyInterest: Yup.array().required("Please select technology interest").nullable(),
	industryInterest: Yup.array().required("Please select industry interest").nullable(),
	userEmailOTP: Yup.string().when('showOTP', {
		is: true,
		then: Yup.string().required("Please enter email OTP").min(5, "Please enter 5 digits OTP").max(5, "Please enter 5 digits OTP"),
		otherwise: Yup.string().nullable().oneOf([null, undefined], 'Null'),
	}),
	userMobileOTP: Yup.string().when('showMobileOtp', {
		is: true,
		then: Yup.string().required("Please enter mobile OTP").min(5, "Please enter 5 digits OTP").max(5, "Please enter 5 digits OTP"),
		otherwise: Yup.string().nullable().oneOf([null, undefined], 'Null'),
	}),
	userTncAccepected: Yup.bool().oneOf([true], "Please accept Terms & Conditions").required("Please accept Terms & Conditions")
};

export const addUserValidationSchema = {
	...userValidationSchema
};

export const editUserValidationSchema = {
	...userValidationSchema,
	userFname: Yup.string().min(2, "First name must be minimum 2 characters").required("First name is required").notOneOf([""]),
	userLname: Yup.string().min(2, "Last name must be minimum 2 characters").required("Last name is required").notOneOf([""]),
	userEmail: Yup.string().typeError("Please enter valid email").email("Please enter valid email").required("Email is required"),
	userPassword: Yup.string().min(6, "New password must be minimum 6 characters").max(18, "New password must be at most 18 characters"),
	// .test("userPassword", "Must be minimum 6 characters", userPassword => userPassword && userPassword.length < 6)
	// .test("userPassword", "Must be maximum 18 characters", userPassword => userPassword && userPassword.length > 18),
	// userConfirmPassword: Yup.string().oneOf([Yup.ref("userPassword"), null], "Passwords must match"),
	userConfirmPassword: Yup.string().oneOf([Yup.ref("userPassword"), null], "New password and confirm password do not match").when("userPassword", {
		is: (val) => val !== undefined,
		then: Yup.string().required("Confirm new password is required"),
		// otherwise: yup.string().notRequired()
	})
	// .test("userConfirmPassword", "Must be minimum 6 characters", userConfirmPassword => userConfirmPassword && userConfirmPassword.length < 6)
	// .test("userConfirmPassword", "Must be maximum 18 characters", userConfirmPassword => userConfirmPassword && userConfirmPassword.length > 18)
};

export const clientUserValidationSchema = {
	// userSalutation: Yup.string().required("Please select salutation"),
	userFname: Yup.string().min(2, "First name must be minimum 2 characters").required("Please enter first name"),
	userMname: Yup.string(),
	userLname: Yup.string().min(2, "Last name must be minimum 2 characters").required("Please enter last name"),
	userEmail: Yup.string().typeError("Please enter valid email").email("Please enter valid email").required("Please enter email"),
	userAltEmail1: Yup.string().typeError("Please enter valid alternate email").email("Please enter valid alternate email"),
	linkedin: Yup.string().matches(REGEX.LINKEDIN, "Please enter valid LinkedIn URL"),
	facebook: Yup.string().matches(REGEX.FACEBOOK, "Please enter valid Facebook URL"),
	twitter: Yup.string().matches(REGEX.TWITTER, "Please enter valid Twitter URL"),
	// userPassword: Yup.string().required("New Password is required").min(6, "New password must be minimum 6 characters").max(18, "New password must be at most 18 characters"),
	// userConfirmPassword: Yup.string().oneOf([Yup.ref("userPassword"), null], "Your password and confirm password do not match").required("Confirm New Password is required"),
	userDesignation: Yup.string(),
	userMobile: Yup.string().matches(REGEX.PHONE, 'Please enter valid mobile number').min(5, 'Mobile number must be between 5 to 15 digits').max(15, 'Mobile number must be between 5 to 15 digits'),
	// userMobile: Yup.number().typeError("Please enter valid mobile number").required("Please enter mobile number"),
	userLoginId: Yup.string(),
	userCompany: Yup.string().typeError("Please enter company name").required("Please enter company name"),
	userActivationDate: Yup.date().max(new Date(), "User activation date must be less than or equal to present date").typeError("Please select user activation date"),
	country: Yup.string().required("Please select country").typeError("Please select country"),
	addressType: Yup.string().required("Please select address type"),

};

export const addClientUserValidationSchema = {
	...clientUserValidationSchema
};
export const editClientUserValidationSchema = {
	...clientUserValidationSchema,
	userFname: Yup.string().min(2, "First name must be minimum 2 characters").required("Please enter first name").notOneOf([""]),
	userLname: Yup.string().min(2, "Last name must be minimum 2 characters").required("Please enter last name").notOneOf([""]),
	userEmail: Yup.string().typeError("Please enter valid email").email("Please enter valid email").required("Please enter email"),
	userAltEmail1: Yup.string().typeError("Please enter valid alternate email").email("Please enter valid alternate email"),
	// userPassword: Yup.string().min(6, "New password must be minimum 6 characters").max(18, "New password must be at most 18 characters"),
	// userConfirmPassword: Yup.string().oneOf([Yup.ref("userPassword"), null], "New password and confirm password do not match").when("userPassword", {
	// 	is: (val) => val !== undefined,
	// 	then: Yup.string().required("Confirm New Password is required"),
	// 	// otherwise: yup.string().notRequired()
	// })
};
