/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Utility Component for Loader, Toast Notification, Alert Box, React Tooltip
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import "./index.scss";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showSpinner, hideSpinner } from "@syncfusion/ej2-popups";
import ReactTooltip from "react-tooltip";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { TOAST_TIME_OUT } from "../../../config/index";
import { hideMessage } from "../../../middleware/actions/utilityAction";
import AlertBox from "../alertBox";
import Popup from "../popup";
import successSvg from "../../../assets/images/success-check.svg";
import errorSvg from "../../../assets/images/error.svg";
import warningSvg from "../../../assets/images/warning.svg";
import infoSvg from "../../../assets/images/information.svg";

const defaultCallback = () => {
  return;
};

const Utility = () => {
  const dispatch = useDispatch();
  const toastRef = useRef(null);
  const toastReff = useRef(null);
  const utilityState = useSelector((state) => state.utilityState);
  const { loading = false, error = "", message = "", toast = "" } = utilityState;
  if (loading) showSpinner(document.getElementById("root"));
  else hideSpinner(document.getElementById("root"));
  const toastTimeOut = "10000";
  const offSetLeft = toastRef?.current?.element?.offsetLeft - 330;
  const offSetTop = toastRef?.current?.element?.offsetTop + 70;
  let animation;

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          let ele1 = document.querySelector(".e-control.e-toast");
          if (document.querySelector(".hide-toast-message")) {
            ele1.classList.remove("hide-toast-message");
          }
          // toastRef.current.hide()
          if (toastRef.current.toastCollection?.length > 0) {
            if (event.target.nodeName != "BUTTON") {
              const list = document.querySelector(".e-control.e-toast");
              if (list.hasChildNodes()) {
                for (let i = 0; i <= list.children.length; i++) {
                  if (list.children[i]) list.children[i].remove();
                }
              }
            }
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(toastReff);

  /** Animation effect */
  function valueChange(value) {
    const progressElements = toastRef?.current?.element?.querySelector(".e-toast-progress");
    if (!progressElements) return;
    if (value?.content?.name === "success") progressElements.style.backgroundColor = "#0CB963";
    if (value?.content?.name === "error") progressElements.style.backgroundColor = "#FF3434";
    if (value?.content?.name === "warning") progressElements.style.backgroundColor = "#FDD141";
    if (value?.content?.name === "information") progressElements.style.backgroundColor = "#2961FB";
  }

  // const showNotification = ({ type = "", content = "", timeOut = toastTimeOut, position = { X: offSetLeft, Y: offSetTop } }) => {
  const showNotification = ({ type = "", content = "", timeOut = toastTimeOut, position = { X: "Right", Y: "Top" } }) => {
    const success = () => {
      return (
        <div style={{ display: "flex" }}>
          <img src={successSvg} style={{ paddingRight: "11px" }}></img>
          {content}
        </div>
      );
    };
    const error = () => {
      return (
        <div style={{ display: "flex" }}>
          <img src={errorSvg} style={{ paddingRight: "11px" }}></img>
          {content}
        </div>
      );
    };
    const warning = () => {
      return (
        <div style={{ display: "flex" }}>
          <img src={warningSvg} style={{ paddingRight: "11px" }}></img>
          {content}
        </div>
      );
    };
    const information = () => {
      return (
        <div style={{ display: "flex" }}>
          <img src={infoSvg} style={{ paddingRight: "11px" }}></img>
          {content}
        </div>
      );
    };

    const toasts = [
      { content: warning, cssClass: "customCssWarning", timeOut: timeOut, position: position },
      { content: success, cssClass: "customCssSuccess", timeOut: timeOut, position: position },
      { content: error, cssClass: "customCssError", timeOut: timeOut, position: position },
      { content: information, cssClass: "customCssInfo", timeOut: timeOut, position: position },
      // { title: "Error !", content: content, cssClass: "e-toast-danger", icon: "e-error toast-icons", timeOut: timeOut, position: position },
    ];
    switch (type) {
      case "warning":
        toastRef.current.show(toasts[0]);
        // valueChange(toasts[0]);
        break;
      case "success":
        toastRef.current.show(toasts[1]);
        // valueChange(toasts[1]);
        break;
      case "error":
        toastRef.current.show(toasts[2]);
        // valueChange(toasts[2]);
        break;
      case "information":
        toastRef.current.show(toasts[3]);
        // valueChange(toasts[3]);
        break;
      default:
        toastRef.current.show(toasts[3]);
        // valueChange(toasts[3]);
        break;
    }
    dispatch(hideMessage());
  };
  if (error !== "" || toast !== "" || message !== "") {
    const toastType = error !== "" ? "error" : toast !== "" ? toast : message !== "" ? "information" : "";
    const toastContent = error !== "" ? error : message !== "" ? message : "";
    if (toastType !== "" && toastContent !== "") showNotification({ type: toastType, content: toastContent });
  }
  const alertBoxState = useSelector((state) => state.alertBoxState);
  const AlertBoxComponent = () => {
    const {
      showAlert = false,
      title = "Confirm",
      okText = "Yes",
      okCallback = defaultCallback,
      cancelText = "No",
      cancelCallback = defaultCallback,
      content = "Are you sure, you want to continue?",
      data = undefined,
      hideCancelButton,
    } = alertBoxState;
    return showAlert ? (
      <AlertBox
        showAlert={showAlert}
        title={title}
        okText={okText}
        okCallback={okCallback}
        cancelText={cancelText}
        cancelCallback={cancelCallback}
        content={content}
        data={data}
        hideCancelButton={hideCancelButton}
      />
    ) : null;
  };

  const popupState = useSelector((state) => state.popupState);
  const PopupComponent = () => {
    const {
      width = "300px",
      showPopup = false,
      title = "Popup Title Here",
      okText = "Yes",
      okCallback = defaultCallback,
      showCancelButton = true,
      cancelText = "No",
      cancelCallback = defaultCallback,
      content = "You can put your content here. Content may be string or html.",
      data = undefined,
    } = popupState;

    return showPopup ? (
      <Popup
        width={width}
        showPopup={showPopup}
        title={title}
        okText={okText}
        okCallback={okCallback}
        showCancelButton={showCancelButton}
        cancelText={cancelText}
        cancelCallback={cancelCallback}
        content={content}
        data={data}
      />
    ) : null;
  };

  return (
    <>
      <ReactTooltip
        getContent={() => {
          return null;
        }}
      />

      {
        <div ref={toastReff}>
          <ToastComponent
            showCloseButton={true} // close icon on toaster
            id="toast_target"
            ref={toastRef}
            animation={animation}
            showProgressBar="true"
          />
        </div>
      }
      <AlertBoxComponent />
      <PopupComponent />
    </>
  );
};

export default Utility;
