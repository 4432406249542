/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  People API
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 16/Apr/2021
    Author						: Sandeep K Sharma
================================================================ 
*/

import { PEOPLE_API as API } from "../../config/apiConfig";
import { PEOPLE_URL } from "../../config";


// all People
export const fetchPeople = (params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: "/people",
		method: "GET",
		params: params
	});
};


// Fetch Single People
export const fetchSinglePeople = (peopleId,params='') => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/people/${peopleId}`,
		method: "GET",
		params: params
	});
};

//delete People
export const deleteManyPeople = (payload, params = {}) => {
	let { filters = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: "/people",
		method: "DELETE",
		params: params,
		data: payload
	});
};


// save People (Overview) Info
export const saveOverview = (payload) => {
	return API({
		url: `/people`,
		method: "POST",
		data: payload
	});
};

// update Prople (Overview) Info
export const updateOverview = (peopleId,payload) => {
	return API({
		url: `/people/${peopleId}`,
		method: "PUT",
		data: payload
	});
};

// Delete People
export const deletePeople = (peopleId) => {
	return API({
		url: `/people/${peopleId}`,
		method: "DELETE"
	});
};

export const fetchPeopleImageUrl = (fileName) => {
	return `${PEOPLE_URL}/people/image/${fileName}`;
};

// Get People Experiences
export const fetchExperience = (peopleId) => {
	return API({
		url: `/people/${peopleId}/experience`,
		method: "GET",
	});
};

// Get People Single Education
export const fetchSingleExperience = (peopleId,expId) => {
	return API({
		url: `/people/${peopleId}/experience/${expId}`,
		method: "GET",
	});
};


// save People Experience
export const saveExperience = (peopleId,payload) => {
	return API({
		url: `/people/${peopleId}/experience`,
		method: "POST",
		data: payload
	});
};

// update Prople Experience
export const updateExperience = (peopleId,expId,payload) => {
	return API({
		url: `/people/${peopleId}/experience/${expId}`,
		method: "PUT",
		data: payload
	});
};

//Delete People Experience
export const deleteExperience = (peopleId,expId) => {
	return API({
		url: `/people/${peopleId}/experience/${expId}`,
		method: "DELETE",
	});
};
// Get People Education
export const fetchEducation = (peopleId) => {
	return API({
		url: `/people/${peopleId}/education`,
		method: "GET",
	});
};

// Get People Single Education
export const fetchSingleEducation = (peopleId,eduId) => {
	return API({
		url: `/people/${peopleId}/education/${eduId}`,
		method: "GET",
	});
};

//Delete People Experience
export const deleteEducation = (peopleId,expId) => {
	return API({
		url: `/people/${peopleId}/education/${expId}`,
		method: "DELETE",
	});
};


// save People Education
export const saveEducation= (peopleId,payload) => {
	return API({
		url: `/people/${peopleId}/education`,
		method: "POST",
		data: payload
	});
};

// update people Education
export const updateEducation = (peopleId,expId,payload) => {
	return API({
		url: `/people/${peopleId}/education/${expId}`,
		method: "PUT",
		data: payload
	});
};

