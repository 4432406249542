/*
================================================================
	Copyright © 2024, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Visualization Library
----------------------------------------------------------------
	Creation Details
	Date Created				: 24/May/2024
	Author						: Riddhesh Desai
================================================================
*/
import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import visulizationLibraryListing from "../app/views/visulizationLibrary";

const PROPERTIES = ["Properties:VIEW", "Properties:FULL"];

const visulizationLibraryRoutes = () => (
  <Suspense
    fallback={
      <div className="text-center">
        <h3>Loading...</h3>
      </div>
    }
  >
    <Switch>
      <AuthLayout
        exact
        path="/visualization-ibrary"
        component={visulizationLibraryListing}
        pageHeading="GenNx CMS - Visualization Library"
        accessRights={{
          moduleName: "Visualization Library",
          parentModuleName: "",
          moduleAttribute: [...PROPERTIES],
          interfaceName: "Listing",
        }}
      />
    </Switch>
  </Suspense>
);

export default visulizationLibraryRoutes;
