import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import AddNews from "../app/views/news/add";
import NewsListing from "../app/views/news";

const PROFILE = ["Profile:VIEW", "Profile:FULL"];
const CONTENT = ["Content:VIEW", "Content:FULL"];
const TAXONOMY = ["Taxonomy:VIEW", "Taxonomy:FULL"];
const newsRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/news/add"
      component={AddNews}
      pageHeading="GenNx CMS - News"
      accessRights={{
        moduleName: "News",
        parentModuleName: "",
        moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/news"
      component={NewsListing}
      pageHeading="GenNx CMS - News"
      accessRights={{
        moduleName: "News",
        parentModuleName: "",
        moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/news/:newsId/edit"
      component={AddNews}
      pageHeading="GenNx CMS - News"
      accessRights={{
        moduleName: "News",
        parentModuleName: "",
        moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY],
        interfaceName: "Basic",
      }}
    />
  </Switch>
  // </Suspense>
);

export default newsRoutes;
