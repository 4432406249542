/**
 * File Description: Custom Form Constant declarations
 * Author: Pankaj Kulshreshtha
 * Date: 10/Jun/2021
 */
export default {
    PROFICIENCY_LEVEL_OPTIONS: [{
        label: "Beginner",
        value: "Beginner"
    },
    {
        label: "Intermediate",
        value: "Intermediate"
    },
    {
        label: "Expert",
        value: "Expert"
    }
    ],
    
};
