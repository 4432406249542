/*
================================================================
    Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : product State
----------------------------------------------------------------
    Creation Details
    Date Created				: 14/09/2022
    Author						: PRASHANT WANKHADE
================================================================
*/

const VIDEO_STATE = {
    basicInformation: {}
};

export default (state = VIDEO_STATE, action) => {
    switch (action.type) {
        case 'MAINTAIN_BASIC_TAB': {
            return { ...state, basicInformation: action.payload };
        }
            
        default:
            return state;
    }
};
