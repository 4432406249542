/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description            :  Company Advanced info Action Buttons
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 05/Aug/2020 
    Author						: Anand Kumar 
================================================================ 
*/

import React from "react";
import { Link, useHistory } from "react-router-dom";

const ActionButtons = (props) => {
  const history = useHistory();

  return (
    <div className="form-submit-box">
      <div className="container-fluid nopadding">
        <div className="row">
          <div className="float-LEFT col-md-6 text-left">
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="pl-4" style={{ marginLeft: "-3em" }}>
                <span className="span-link">
                  <button
                    type="button"
                    className="cy-prev-btn btn btn-outline-dark pl-4 pr-4 cy-btn "
                    onClick={() => {
                      (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                        (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1));
                    }}
                  >
                    Previous
                  </button>
                </span>
              </span>
            ) : null}
          </div>
          <div className="float-right col-md-6 text-right">
            <Link to={"/custom-forms" || history.location.pathname}>
              <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                Cancel
              </button>
            </Link>
            {props?.accessActionSave && (
              <button type="button" className="btn btn-primary cy-btn pl-4 pr-4 " id="dev-objective-save" onClick={props.handleSave}>
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
