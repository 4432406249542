/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Action buttons 
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/June/2021
    Author						: Sandeep K. Sharma
================================================================
 */

import React from "react";
import { Link, useHistory } from "react-router-dom";
import { getProductVariant } from "../../../../utilities";
import { actionSuccess, actionError } from "../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { publishKia } from "../../../../middleware/services/kiaApi";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

const ActionButtons = (props) => {
  const dispatch = useDispatch();
  const kiaId = props.kiaId || "";
  const history = useHistory();
  const productVariant = getProductVariant();
  const publish = async () => {
    const kiaId = props.kiaId;
    await publishKia(kiaId, { platform: productVariant })
      .then((res) => {
        if (res) {
          dispatch(actionSuccess("Kia has been published successfully"));
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        let errMessage = "Kia cannot published : <br>";
        const errMessageArr = err?.data?.message ? err.data.message.split(",") : ["Something went wrong"];
        errMessage += errMessageArr
          .map((value, index) => {
            return ++index + "." + value + "<br>";
          })
          .join("");
        dispatch(actionError(errMessage));
      });
  };

  /* Common access */
  const accessActionSave = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionPublish = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.PUBLISH);

  return (
    <div className="form-submit-box">
      <div className="container-fluid nopadding">
        <div className="row">
          <div className="float-left col-md-6 text-left">
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="pl-4" style={{ marginLeft: "-2.5em" }}>
                <span
                  // to={props.nextPath}
                  className="span-link"
                >
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                    onClick={() => {
                      (props.selectedIndex === undefined && props.setCurrentTab(4)) ||
                        (props.selectedIndex < 6 && props.setCurrentTab(props.selectedIndex - 1));
                    }}
                    // disabled={props.nextPath ? false : true}
                  >
                    Previous
                  </button>
                </span>
              </span>
            ) : null}

            {kiaId !== "" && props.selectedIndex > -1 && props.selectedIndex < 5 ? (
              <span className="pl-4" style={{ marginLeft: props.selectedIndex === 0 ? "-2.9em" : "0" }}>
                <span
                  // to={props.nextPath}
                  className="span-link"
                >
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                    onClick={() => {
                      props.selectedIndex < 5 && props.setCurrentTab(props.selectedIndex + 1);
                    }}
                    // disabled={props.nextPath ? false : true}
                  >
                    Next
                  </button>
                </span>
              </span>
            ) : null}
          </div>
          <div className="float-right col-md-6 text-right">
            <Link to={"/kia" || history.location.pathname}>
              <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 ">
                Cancel
              </button>
            </Link>
            {accessActionSave && props.handleSave && props.showSave ? (
              <button type="button" className="btn btn-primary cy-btn pl-4 pr-4 ml-3  " id="dev-objective-save" onClick={props.handleSave}>
                Save
              </button>
            ) : null}
            {accessActionSave && props.handleSaveBrief && props.showSaveBrief ? (
              <button type="button" className="btn btn-primary cy-btn pl-4 pr-4 ml-3  " id="dev-objective-save" onClick={props.handleSaveBrief}>
                Save
              </button>
            ) : null}

            {accessActionPublish && props.showPublished ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 ml-3 "
                onClick={() => {
                  dispatch(
                    showAlertBox({
                      okCallback: async () => {
                        await publish();
                      },
                      content: "Are you sure you want to publish Key Impact Area?",
                      okText: "Publish",
                      cancelText: "Cancel",
                      title: "dialogAlertCss",
                    })
                  );
                }}
              >
                Publish
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
