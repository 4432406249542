/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Service Tab Page 
----------------------------------------------------------------
    Creation Details
    Date Created				: 07/July/2021
    Author						: Sandeep K. Sharma
================================================================
*/

import React, { useState, useRef, useEffect } from "react";
import Basic from "../component/basic";
import Deliverables from "../component/deliverables";
import ServiceDetails from "../component/serviceDetails";
import Tabs from "../../../components/tabsComponent";
import ActionButtons from "./actionButtons";
import "../index.scss";
import { accessRightActionCheck, accessRightInterfaceCheck } from "../../../../utilities";

const AddService = (props) => {
  const { accessRights: aR } = props;
  const tabRef = useRef();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [saveBasic, setSaveBasic] = useState(false);
  const serviceId = props.computedMatch?.params?.serviceId || "";
  const [saveForm, setSaveForm] = useState(false);
  const TABS_NAME = ["Basic", "Service Details", "Deliverables"];
  const hashArr = { "#basic": "Basic", "#service": "Service Details", "#deliverables": "Deliverables" };
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /**
   * @description function used for disable tabs on add KIA
   */

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (!serviceId) {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.select(0);
    }
  };

  /**
   * @description function used for add KIA  and custom Brief
   *
   */
  const handleSave = (save) => {
    setSaveBasic(save);
  };

  const handleSaveForm = (save) => {
    setSaveForm(save);
  };
  const newRecord = () => {
    tabRef.current.enableTab(1, true);
    tabRef.current.enableTab(2, true);
    tabRef.current.select(0);
  };

  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, TABS_NAME);
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex]);

  return (
    <>
      <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
        <div>
          <Tabs
            tabsName={TABS_NAME}
            tabRef={tabRef}
            created={created}
            handleClick={() => {
              setSelectedIndex(tabRef.current.selectedItem);
            }}
            cssClass="newHeaderTabCss"
          >
            <div key="basic">
              <Basic
                serviceId={serviceId}
                saveBasic={saveBasic}
                selectedIndex={selectedIndex}
                handleSave={handleSave}
                tabRef={tabRef}
                newRecord={newRecord}
                setCurrentTab={(selectedIndex) => {
                  tabRef.current.select(selectedIndex);
                  setSelectedIndex(selectedIndex);
                }}
                hashArr={hashArr}
                moduleName={aR.moduleName}
                interfaceAccess={interfaceAccess}
                interfaceActionAccess={interfaceActionAccess}
              />
            </div>
            <div key="serviceDetails" className="gennx-content-wrapper ">
              {selectedIndex === 1 && serviceId && (
                <ServiceDetails
                  saveForm={saveForm}
                  handleSaveForm={handleSaveForm}
                  serviceId={serviceId}
                  newRecord={newRecord}
                  selectedIndex={selectedIndex}
                  setCurrentTab={(selectedIndex) => {
                    tabRef.current.select(selectedIndex);
                    setSelectedIndex(selectedIndex);
                  }}
                  hashArr={hashArr}
                  moduleName={aR.moduleName}
                  interfaceAccess={interfaceAccess}
                  interfaceActionAccess={interfaceActionAccess}
                />
              )}
            </div>
            <div key="deliverables">
              {selectedIndex === 2 && serviceId && (
                <Deliverables
                  serviceId={serviceId}
                  selectedIndex={selectedIndex}
                  setCurrentTab={(selectedIndex) => {
                    tabRef.current.select(selectedIndex);
                    setSelectedIndex(selectedIndex);
                  }}
                  hashArr={hashArr}
                  moduleName={aR.moduleName}
                  interfaceAccess={interfaceAccess}
                  interfaceActionAccess={interfaceActionAccess}
                />
              )}
            </div>
          </Tabs>
        </div>
      </div>
      <ActionButtons
        selectedIndex={selectedIndex}
        setCurrentTab={(selectedIndex) => {
          tabRef.current.select(selectedIndex);
          setSelectedIndex(selectedIndex);
        }}
        accessRights={props.accessRights}
        serviceId={serviceId ? serviceId : null}
        handleSave={() => handleSave(true)}
        handleSaveForm={() => handleSaveForm(true)}
        showPublished={serviceId && selectedIndex === 0 ? true : false}
        showSave={selectedIndex === 0 ? true : false}
        showSaveForm={selectedIndex === 1 ? true : false}
        interfaceActionAccess={interfaceActionAccess}
      />
    </>
  );
};

export default AddService;
