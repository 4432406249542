export const myBriefcaseCategoryParams = {
  queries: [],
  skip: 0,
  limit: 10,
  page: 1,
  sort: `categoryCreatedDate:desc`,
};

export const briefcaseItemsParams = {
  queries: [],
  skip: 0,
  limit: 100,
  page: 1,
  sort: `presentationCreatedDate:desc`,
};

export const collaborationParams = {
  queries: [],
  limit: 100,
  sort: `presentationCreatedDate:desc`,
  fields: [
    // "collaborationName",
    // "collaborationDate",
    // "collaborationDealValue",
    // "collaborationTechnologyPurpose",
    // "collaborationDescription",
    // "collaborationPartners",
    // "companyId",
  ],
};
