/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Side Nav Actions (Sidebar Active, Forced Active, TreeMenu with open / close state)
----------------------------------------------------------------
	Creation Details
	Date Created				: 03/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import {
	SET_SIDEBAR_ACTIVE, SET_SIDE_NAV_ACTIVE_LINK, SET_SIDE_NAV_FORCED_ACTIVE_LINK, SET_SIDE_NAV_TREE_MENU_OPEN,
	SET_SIDE_NAV_TREE_MENU_CLOSE, SET_SIDE_NAV_OPENED_TREE_MENU_STATE, SET_SIDE_NAV_TREE_MENU_WITH_STATE
} from "../../constants/actionConstants";

export const setSidebarActive = (payload = true) => ({
	type: SET_SIDEBAR_ACTIVE,
	payload,
});

export const setSideNavActiveLink = (payload = "") => ({
	type: SET_SIDE_NAV_ACTIVE_LINK,
	payload,
});

export const setSideNavForcedActiveLink = (payload = "") => ({
	type: SET_SIDE_NAV_FORCED_ACTIVE_LINK,
	payload,
});

export const setSideNavTreeMenuOpen = (payload = { treeMenu: "noTreeMenu", open: false }) => ({
	type: SET_SIDE_NAV_TREE_MENU_OPEN,
	payload,
});

export const setSideNavTreeMenuClose = (payload = {}) => ({
	type: SET_SIDE_NAV_TREE_MENU_CLOSE,
	payload,
});

export const setSideNavOpenedTreeMenuState = (payload = {}) => ({
	type: SET_SIDE_NAV_OPENED_TREE_MENU_STATE,
	payload,
});

export const setSideNavTreeMenuWithState = (payload = {}) => ({
	type: SET_SIDE_NAV_TREE_MENU_WITH_STATE,
	payload,
});
