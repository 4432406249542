import React from "react";
import { fetchOutsourcingTncFileUrl } from "../../../middleware/services/cmsApi";
export const termsAndConditions = () => {
    return (<div>
        <ul>
            <li>
                All knowledge and information of Company, including but not limited to its confidential and proprietary information, not already available to the public; or information which under circumstances surrounding its disclosure ought to be treated as confidential (<b>“Confidential Information”</b>); which Company discloses to the Service Provider, or which Service Provider acquires, has acquired, or will acquire in the course of this engagement; shall be treated by the Service Provider as strictly confidential. Service Provider shall not, either directly or indirectly, use, disclose, or make accessible to anyone the Confidential Information for any purpose whatsoever, except with prior express written consent of Company.
    </li>
            <li>Service Provider shall not 	take for itself opportunities that are discovered through the use of Confidential Information and / or use Confidential Information for its personal gain and / or for competing with Company.</li>
            <li>The Service Provider shall undertake appropriate quality control measures to ensure accuracy of the Deliverables.</li>
            <li>Company may, at its sole discretion, reject any Deliverables, partly or in full, if it believes that the Deliverables fail to meet the required specifications.</li>
            <li>Service Charges shall not be payable for rejected Deliverables.</li>
            <li>Unless otherwise expressly agreed by Company in writing, Service Provider shall not subcontract the performance of any of its obligations under this engagement, whether partially or in full.</li>
            <li>Service Provider shall not use Company’s name, brand name, trademark or logo at any time, for any reason whatsoever, or make any direct or implied reference to Company, without the prior written consent of Company.</li>
            <li>Company may terminate this engagement at any time, without any reason whatsoever, upon providing a prior written notice of fifteen (15) days to the Service Provider. As its sole liability towards the Service Provider, Company shall pay the Service Provider for all work satisfactorily performed and accepted by CIPL till the date of receipt of notice of termination as specified herein. Parties agree that such acceptance shall be at the sole discretion of Company.</li>
            <li>Ownership in any or all Company Materials and Deliverables, and all intellectual rights embodied therein, shall at all times remain the property of Company.</li>
            <li>All Deliverables, and any work performed by the Service Provider for Company shall be considered a "Work Made for Hire", and shall be owned by and for the express benefit of Company. In the event that such work or Deliverable does not qualify as a Work Made for Hire, Service Provider agrees to and does hereby assign to Company all of Service Provider's rights, title, and interests in such work product including, but not limited to, all copyrights and other proprietary rights.</li>
            <li>Service Provider agrees to indemnify and hold Company harmless from and against any and all liabilities, damages, losses, expenses, claims, demands, suits, fines or judgments (including but not limited to, attorneys' fees, costs and expenses incidental thereto) which may be suffered by, accrued against, be charged to or recoverable from Company, arising out of or in any way connected with the performance of its obligations under this engagement, or the breach of its obligations under this engagement by the Service Provider.</li>
            <li>Service Provider understands that the Company uses digital tools and / or technologies extensively for all activities and / or business requirements (including but not limited to conducting internal / external meetings, measuring productivity, deploying business tools, etc.) and hereby agrees to comply with all necessary and applicable protocols and policies laid down by the Company for the same. </li>
            <li>Service Provider hereby represents and warrants that he / she / its representative/s:
    <ol type="i">
                    <li>understand/s that the Company may arrange for various onsite, offsite, online, digital and / or any other form of recreational activities, learning activities, team-building activities, training programs, sports activities, regular community activities, and other programs and activities of a similar nature, including but not limited to parties, picnics, outings, retreats, training programs, seminars and webinars (<b>“Engagement Programs”</b>) which the Service Provider / Service Provider’s representative/s may attend, either alone or along with his / her family members / friends / relatives, as the case may be, and as directed by the Company;</li>
                    <li>understand/s and agrees that all Engagement Programs are conducted by the Company for the welfare of the Service Provider, its representative/s and other personnel, and Company is not liable in any way whatsoever (whether directly, indirectly, partly, fully, knowingly or unknowingly) for any injuries, including but not limited to personal injury, bodily injury (whether partly or in full), accidents, death, injury to property of any nature; damages, including but not limited to damage to personal and real property; losses; claims; expenses; demands; suits, fines or judgments;   whether direct or indirect, and in any form whatsoever, arising out of or in any way related to the Service Provider’s and / or Service Provider’s representative/s and / or his / her family members’ / friends’ / relatives’ attendance / non-attendance of any or all of the Engagement Programs; </li>
                    <li><ol className="olStyle" type="a"><li>himself / herself on behalf of his / her family members / friends / relatives; and / or</li>
                        <li>himself / herself along with the Service Provider entity, both jointly and severally; hereby irrevocably agrees to indemnify and hold harmless the Company from and against any or all liabilities, injuries, including but not limited to personal injury, bodily injury (whether partly or in full), accidents, death, injury to property of any nature; damages, including but not limited to damage to personal and real property; losses; claims; expenses; demands, suits, fines or judgments  (including but not limited to attorneys’ fees, costs and expenses incidental thereto) which may be suffered by the Service Provider / its representative/s and / or accrued against, be charged to or recoverable from the Company, and / or arising out of or in any way related to the Service Provider / its representative/s and / or Service Provider’s representative’s family members’ / friends’ / relatives’ attendance / non-attendance of the Engagement Programs.</li>
                    </ol>  </li>
                </ol>
            </li>
            <li>Service Provider acknowledges the Company’s <a href={fetchOutsourcingTncFileUrl('Global Code of Conduct_Third Parties.pdf')}  download className="btn-link">Global Code of Conduct</a> and <a href={fetchOutsourcingTncFileUrl('Global InfoSec Policy.pdf')}  download className="btn-link">Global InfoSec Policy</a> (<b>“Global Policies”</b>), and hereby represents that it shall, at all times abide by the said Global Policies, and all other policies applicable to the personnel of the Company, and conduct itself / himself/ herself in accordance with ethical business practices. The Global Policies are hereby incorporated by reference, and shall form an integral part of, and be read in conjunction with this Agreement.</li>
            <li>Service Charges shall be separately agreed, and shall be inclusive of all applicable taxes.</li>
            <li>Other than Service Charges, no other payments or reimbursements of any kind shall be payable to the Service Provider.</li>
            <li>Service Provider shall send accurate invoices to Company in accordance with the agreed milestones.</li>
            <li>Each invoice shall contain the details of the Services, the number of days worked upon by the Service Provider, the rate for calculation of Service Charges, the actual Service Charges, and any other specifications as agreed by the Parties.</li>
            <li>Company shall pay accurate and undisputed invoices within 30 days of receipt of invoice or date of an accurate  invoice, whichever is later.</li>
            <li>Payments shall be made through cheque or via bank wire transfers. Bank Wire Transfer charges, if any, shall be borne by the Service Provider.</li>
        </ul>
    </div>)
}