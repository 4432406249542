import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { enableRipple } from "@syncfusion/ej2-base";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { getProductVariant, isAdmin } from "../../../utilities";
import { fetchReportPublicationStatus } from "../../../middleware/services/reportApi";
import "./style.scss";
import { Dropdown, MultiSelect } from "../../components/CustomFormElements/syncFusionFormFields";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";
import TreeDataGridNew from "app/components/treeDataGridNew";
import { getContentType, fetchTaxonomyTrend } from "middleware/services/cmsApi";
import { ColumnDirective, ColumnsDirective } from "@syncfusion/ej2-react-grids";
import * as CONSTANTS from "../../../constants/index";
import { presets } from "utils";

// specify breadcrumb here
const breadCrumbLinks = [{ linkUrl: "/report", linkName: "Report", linkActive: true }];
enableRipple(true);
// Publication Status Report component
const PublicationStatusReport = (props) => {
  const industryRef = useRef();
  const contentTypeRef = useRef();
  const { accessRights: aR } = props;
  const accessPermission = true;
  const productVariant = getProductVariant();
  const [dateRange, setDateRange] = useState(null);
  const [isUpdated, setIsUpdated] = useState(undefined);
  const [startDate, setStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [gridRef, setGridRef] = useState(null);
  const [industryArray, setIndustryArray] = useState(["Food & Nutrition", "Energy", "Mobility", "Personal Care", "Industrial"]);
  const [contentTypeDataSource, setContentTypeDataSource] = useState([
    "Insights, Reports & Research",
    "Profiles - Companies & Technologies",
    "Dashboard",
    "Webinars, Interviews & Podcasts",
    "Developments & News",
    "Signals",
  ]);
  const [allIndustries, setAllIndustries] = useState([]);

  let userCompetencyIndustry =
    localStorage.getItem("userCompetencyIndustry") !== "null" ? JSON.parse(localStorage.getItem("userCompetencyIndustry")) : [];

  const industrySource = useMemo(() => {
    if (isAdmin()) {
      const filterArray = industryArray.map((industry) => ({ label: industry.trendName, value: industry.trendName }));
      filterArray.unshift({ label: "All", value: "All" });
      return filterArray;
    }
    const uniqueIndustryNames = new Set();
    const industryList = userCompetencyIndustry
      .filter((industry) => {
        const industryName = industry.industryName;
        return !uniqueIndustryNames.has(industryName) && uniqueIndustryNames.add(industryName);
      })
      .map(({ industryName }) => ({ label: industryName, value: industryName }));
    industryList.unshift({ label: "All", value: "All" });
    return industryList;
  }, [userCompetencyIndustry, industryArray]);

  const fetchContentType = async () => {
    let filters = [];
    filters.push(["contentTypeProductVariant", "eq", "InsiderV2"]);
    const response = await getContentType({ filters: filters });
    if (response?.data && response?.data?.data) {
      const resData = response.data.data.map((v) => {
        return { label: v.contentType, value: v.contentType };
      });
      if (resData) {
        let data = resData.filter((item) => typeof item == "object");
        data.sort((a, b) => a.value.localeCompare(b.value));
        setContentTypeDataSource(data);
      } else {
        setContentTypeDataSource([]);
      }
    }
  };

  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const initialState = {
    reportIndustry: "All",
    reportContentType: [
      "Insights, Reports & Research",
      "Profiles - Companies & Technologies",
      "Dashboard",
      "Webinars, Interviews & Podcasts",
      "Developments & News",
      "Signals",
    ],
  };

  //** handle date range
  const fnStartDate = () => {
    let dateFrom = new Date();
    let dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - CONSTANTS.ANALYTICS_REPORTS_DEFAULT_DATE_RANGE);
    const dateStart = dateFrom
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    dateTo.setDate(dateTo.getDate());
    const dateEnd = dateTo
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    setStartDate(new Date(dateStart));
    setEndDate(new Date(dateEnd));
    setDateRange([dateFrom, dateTo]);
    return [dateFrom, dateTo];
  };

  const gridState = useSelector((state) => state.gridState);
  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
  });
  const dispatch = useDispatch();
  // specify column names for column fields in datGrid here
  const columnNames = {
    Industry: "industry",
    "Content Type": "contentType",
    Published: "published",
    Approved: "approved",
    "In Progress": "inProgress",
    Submitted: "submitted",
    Rejected: "rejected",
  };

  const recordsTemplate = (value) => {
    const a = value[`${value.column.field}`];
    return <span className={value.contentType === "" ? "span-link-weight" : ""}>{a}</span>;
  };

  const columnfields = [
    {
      field: "industry",
      headerText: "Industry",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "140px",
      showInColumnChooser: false,
      allowFiltering: false,
      allowSorting: false,
      visible: true,
    },
    {
      field: "contentType",
      headerText: "Content Type",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "140px",
      showInColumnChooser: false,
      allowFiltering: false,
      allowSorting: false,
      visible: true,
    },
    {
      field: "published",
      headerText: "Published",
      type: "Number",
      allowSorting: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: "60px",
      visible: true,
      template: recordsTemplate,
    },
    {
      field: "approved",
      headerText: "Approved",
      type: "Number",
      allowSorting: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: "60px",
      visible: true,
      template: recordsTemplate,
    },
    {
      field: "inProgress",
      headerText: "In Progress",
      type: "Number",
      allowSorting: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: "60px",
      visible: true,
      template: recordsTemplate,
    },
    {
      field: "submitted",
      headerText: "Submitted",
      type: "Number",
      allowSorting: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: "60px",
      visible: true,
      template: recordsTemplate,
    },
    {
      field: "rejected",
      headerText: "Rejected",
      type: "Number",
      allowSorting: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: "60px",
      visible: true,
      template: recordsTemplate,
    },
  ];

  const fetchReportPublicationStatusReport = async (reportDateRange, params = {}) => {
    try {
      showLoaderGrid();
      let filters = [];
      params.productVariant = productVariant;

      filters.push(["contentType", "eq", formik.values.reportContentType]);

      filters.push([
        "industryName",
        "eq",
        formik.values.reportIndustry !== "All"
          ? [formik.values.reportIndustry]
          : industrySource.filter((item) => item.label !== "All").map((item) => item.label),
      ]);
      if (gridState?.recordsPerPage) params.limit = gridState.recordsPerPage;

      if (params.sort) {
        params.sort = params.sort.split(",").pop();
      }

      const DateFilter = dateRange === null ? reportDateRange : dateRange;

      if (DateFilter) {
        let gte = { d: DateFilter[0] };
        Date.prototype.toJSON = function () {
          return moment(this).format();
        };
        gte.d.toJSON = function () {
          return moment(this).format();
        };

        let lt = { d: moment(DateFilter[1]) };
        lt.d.toJSON = function () {
          return moment(this).format();
        };

        filters.push(["startDate", "gte", gte.d]);
        filters.push(["startDate", "lt", lt.d]);
      }
      params.filters = [...filters];

      const response = await fetchReportPublicationStatus({ ...params });
      setRowData(response?.data.data);
      setTotalCount(response?.data.totalCount);
      dispatch(hideLoader());
    } catch (error) {
      hideLoaderGrid();
      console.error("Error fetching data:", error);
    }
  };

  const onQueryCellInfo = (args) => {
    // console.log(args.data, "args")
    if (args.column.field === "Action") {
      args.colSpan = 3;
    }
  };
  const handleReset = () => {
    window.location.reload();
  };

  const handleSearch = () => {
    fetchReportPublicationStatusReport().then((res) => {
      if (res?.data) setIsUpdated(Date.now());
    });
  };

  const rowDataBound = (args) => {
    if (args.data.level == 0) {
      args.row.childNodes[0].style.fontWeight = "600";
    }
    (args.row?.childNodes[0]).style.fontSize = "14px";
    (args.row?.childNodes[0]).style.textAlign = "left";
  };

  const fetchIndustry = useCallback(async () => {
    try {
      showLoaderGrid();
      let params = {
        fields: ["id", "trendName"],
        filters: [["trendParentId", "eq", 0]],
        sort: "trendName:asc",
      };
      const result = await fetchTaxonomyTrend(params);
      // let productIndustryIds = result.data.data.map((item) => item.id);
      setIndustryArray(result.data.data);
      setAllIndustries(result.data.data.map((item) => item.trendName));
      return result.data.data;
    } catch (error) {
      hideLoaderGrid();
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchIndustry();
      await fetchContentType();
      hideLoaderGrid();
    })();
  }, []);

  useEffect(() => {
    if (allIndustries?.length == 0) return;
    (async () => {
      const reportDateRange = fnStartDate();
      await fetchReportPublicationStatusReport(reportDateRange);
      hideLoaderGrid();
    })();
  }, [allIndustries]);

  useEffect(() => {
    setIsUpdated(Date.now());
  }, [dateRange]);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  const getGridRef = (gridRefData) => {
    setGridRef(gridRefData);
  };

  const handleCreated = () => {
    showLoaderGrid();
    if (gridRef?.current) {
      gridRef.current.grid.columnChooserSettings.operator = "contains";
    }
    hideLoaderGrid();
  };

  return (
    <div className="px-3 grid-bg-white">
      <div className="col-md-12 rounded-bottom nopadding p-0 bg-white">
        <div className="row">
          <div className="col-md-2 mt-3 grid-container-report-filters responsive-report-filters pr-0 publication-status-report-datepicker">
            <DateRangePickerComponent
              placeholder="Date Range"
              cssClass="e-outline analyst-query-datepicker"
              openOnFocus={true}
              showClearButton={false}
              format="dd MMM yyyy"
              separator="to"
              floatLabelType="Auto"
              // value={dateRange ? dateRange : ""}
              startDate={startDate}
              endDate={endDate}
              onChange={(e) => {
                if (e.value) setDateRange(e.value);
              }}
              autoComplete="off"
              max={new Date()}
              maxDays={CONSTANTS.PUBLICATION_STATUS_REPORT_DATE_RANGE_MAX}
              strictMode={true}
              presets={presets}
            ></DateRangePickerComponent>
          </div>
          <div className="col-md-2 mt-3 pr-0 ">
            <Dropdown
              formik={formik}
              placeholder="Industry"
              required="required"
              id="reportIndustry"
              name="reportIndustry"
              fields={{ text: "label", value: "value" }}
              value={formik.values.reportIndustry}
              dataSource={industrySource}
            />
          </div>
          {contentTypeDataSource?.length ? (
            <div className="col-md-2 mt-3 pr-0 publication-status-report-content-type-select">
              <MultiSelect
                formik={formik}
                ref={contentTypeRef}
                mode="CheckBox"
                sortOrder="Acending"
                showSelectAll={true}
                // selectAllText="Select All"
                showClearButton={false}
                enableRtl={true}
                required="required"
                placeholder="Content Type (multiple selections)"
                id="reportContentType"
                name="reportContentType"
                value={formik.values.reportContentType}
                allowCustomValue={false}
                fields={{ text: "label", value: "value" }}
                dataSource={contentTypeDataSource}
              />
            </div>
          ) : null}
          <div className="col-md-3 mt-3">
            <button type="button" onClick={handleSearch} className="search-btn px-4 btn btn-primary mr-2">
              Search
            </button>
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 ml-2 ">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="gennx-grid-client-detail-report analyst-query-report-grid col-md-8 ml-0 pl-0 publication-status-report">
        <TreeDataGridNew
          gridTitle="Client Activity Report"
          rowData={rowData}
          isPaging={false}
          showResetFilter={true}
          deleteMany={false}
          allowPagination={true}
          showExportButton={true}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          rowDataBound={rowDataBound}
          getGridRef={getGridRef}
          // created={handleCreated}
        >
          <ColumnsDirective>
            {columnfields.map((column, index) => (
              <ColumnDirective key={index} {...column} />
            ))}
          </ColumnsDirective>
        </TreeDataGridNew>
      </div>
    </div>
  );
};

export default PublicationStatusReport;
