/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Client User Listing
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import "./index.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import ThreeDotMenu from "../../../components/threeDotMenu";
import { actionSuccess, actionError } from "../../../../middleware/actions/utilityAction";
import { swap, getDateFromMongoDate } from "../../../../utilities";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import CommonDataGrid from "../../../components/dataGrid";
import { StringFilter } from "../../../components/dataGrid/fliters";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { fetchSingleUser, fetchUserSubscription, deleteUserSubscription, sendLoginCredentials } from "../../../../middleware/services/userApi";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

const Subscription = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const editUser = props.path === "/internal-user/add" ? false : true;
  const userId = editUser ? props.userId : undefined;
  const [deleteId, setDeletedId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [existingUser, setExistingUser] = useState({});
  const [userName, setUserName] = useState(undefined);

  /* Common access */
  const accessActionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionInactive = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.INACTIVE);
  const accessActionSendInvitation = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SEND_INVITATION);

  const sendLoginDetails = (value) => {
    sendLoginCredentials(userId, {
      notificationType: "Email",
      notificationTemplate: "login",
      productVariant: value["Product Variant"],
      industry: value["Product Name"][0],
      relationshipManager: value["Relationship Manager"],
      relationshipManagerEmail: value.relationshipManagerEmail,
    })
      .then((res) => {
        dispatch(actionSuccess("Invitation has been sent successfully"));
      })
      .catch((err) => {
        dispatch(actionError(err.data.message || "Send invitation failed"));
      });
  };
  const fetchUserDetail = (userId) => {
    fetchSingleUser(userId)
      .then((res) => {
        const userDetail = { ...res.data.data };
        setExistingUser(userDetail);
        setUserName(`${userDetail.userFname} ${userDetail.userLname}`);
      })
      .catch((err) => {
        let errMsg = err.data.message ? err.data.message : "Fetch User Details Failed";
        dispatch(actionError(errMsg));
      });
  };

  // specify column names for column fields in datGrid here
  const columnNames = {
    id: "id",
    "Product Name": "subscriptionProduct.productName",
    // "Subscription Type": "subscriptionType",
    // Validity: "",
    "Start Date": "subscriptionStartDate",
    "End Date": "subscriptionEndDate",
    Status: "subscriptionStatus",
    "Relationship Type": "subscriptionType",
    "Product Variant": "subscriptionProductVariant",
    "Relationship Manager": "relationshipManagerName",
    relationshipManagerEmail: "relationshipManagerEmail",
    "BTB Id": "btbId",
  };
  const relationshipTypeTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() =>
          history.push({
            pathname: `/client-user/${userId}/subscription/${value.id}/edit`,
            state: { userName: `${existingUser.userFname} ${existingUser.userLname}`, btbId: value["BTB Id"] },
          })
        }
      >
        {value["Relationship Type"]}
      </span>
    );
  };
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                setIsEdit(true);
                history.push({
                  pathname: `/client-user/${userId}/subscription/${value.id}/edit`,
                  state: { userName: `${existingUser.userFname} ${existingUser.userLname}`, btbId: value["BTB Id"] },
                });
              },
            }
          : {})}
        {...(value.Status === "Active" && accessActionInactive
          ? {
              Inactive: () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteUserSubscription(userId, value.id)
                        .then((response) => {
                          dispatch(actionSuccess("Relationship marked as inactive successfully"));
                          setDeletedId(new Date());
                          fetchUserSubscriptionDetail();
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Failed to mark subscription as inactive"));
                        });
                    },
                    data: value,
                    content: "Are you sure you want to mark relationship as inactive?",
                    okText: "Inactive",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
        {...(value.Status === "Active" && accessActionSendInvitation
          ? {
              "Send Invitation": () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      sendLoginDetails(value);
                    },
                    data: value,
                    content: "Are you sure, you want to send invitation?",
                    okText: "Inactive",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
      />
    );
  };
  const subscriptionStatusTemplate = (value) => {
    return <span>{value.Status === "Active" ? <span className="active m-0">Active</span> : <span className="inactive m-0">Inactive</span>}</span>;
  };
  /* 
	   @Description : Date Template 
   */
  const dateTemplate = (value) => {
    const column = value.column.field;
    return ["Start Date", "End Date"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
      <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
    ) : (
      ""
    );
  };

  const columnFields = [
    { field: "id", type: "string", visible: false, allowFiltering: false, showInColumnChooser: false, isPrimaryKey: true },
    {
      field: "Relationship Type",
      type: "String",
      textAlign: "Left",
      template: relationshipTypeTemplate,
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      width: 200,
    },
    { field: "Product Variant", type: "String", textAlign: "Left", filterTemplate: StringFilter, showInColumnChooser: false, width: 200 },
    { field: "Product Name", type: "String", textAlign: "Left", filterTemplate: StringFilter, showInColumnChooser: false, width: 200 },
    { field: "Relationship Manager", type: "String", textAlign: "Left", filterTemplate: StringFilter, showInColumnChooser: false, width: 200 },
    {
      field: "relationshipManagerEmail",
      type: "String",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Left",
      width: 200,
    },
    {
      field: "BTB Id",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      width: 110,
    },
    // { field: "Subscription Type", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left" },
    // { field: "Validity", type: "String", allowSorting: false, allowFiltering: false,  width: 120 },
    { field: "Start Date", type: "Date", textAlign: "Center", template: dateTemplate, width: 140 },
    { field: "End Date", type: "Date", textAlign: "Center", template: dateTemplate, width: 140 },
    { field: "Status", type: "String", textAlign: "Center", template: subscriptionStatusTemplate, filterTemplate: StringFilter, width: 120 },
  ];

  if (accessActionEdit || accessActionInactive || accessActionSendInvitation) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false, textAlign: "Center" });
    columnFields.push({
      field: "Action",
      type: "String",
      textAlign: "Right",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  /**
   * @description formatRowData function required to format rowData for datGrid
   * @param {Array} rowData
   */
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    const today = moment(new Date());
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
      });
      newRD["Product Name"] = rD?.subscriptionProduct.map((item) => {
        return item.productName;
      });
      // ?.productName ? rD?.subscriptionProduct?.productName : "";
      newRD["Validity"] =
        rD?.subscriptionEndDate && rD?.subscriptionEndDate !== "" ? moment(rD.subscriptionEndDate).diff(today, "days") + " Days" : "";
      newRD["Start Date"] = new Date(rD?.subscriptionStartDate);
      newRD["End Date"] = (rD?.subscriptionEndDate && new Date(rD?.subscriptionEndDate)) || "";
      return newRD;
    });
    return formatedRowData;
  };

  const fetchUserSubscriptionDetail = (params = {}) => {
    const defaultFilter = [
      ["userId", "eq", userId],
      ["subscriptionProductType", "eq", "Industry"],
    ];
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];
    return fetchUserSubscription(userId, { ...params });
  };

  const deleteSubscription = () => {
    console.log("deleteSubscription");
  };

  useEffect(() => {
    fetchUserDetail(userId);
    dispatch(setSideNavForcedActiveLink("/client-user"));
    return () => {
      dispatch(setBreadCrumb());
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch]);

  return (
    <div
      className={` content-wrapper ${
        props.userId ? "gennx-grid-container-client-relation mt-3 px-2 pl-2 pr-3" : "gennx-grid-container-client-relation mt-4 px-4"
      }`}
    >
      {existingUser.userFname && (
        <CommonDataGrid
          gridTitle="Relationship Records"
          fetch={fetchUserSubscriptionDetail}
          deleteOne={deleteSubscription}
          deleteMany={false}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          addNewRight={accessActionAdd}
          isChildGrid={true}
          addNew={() =>
            history.push({
              pathname: `/client-user/${userId}/subscription/add`,
              state: { userName: `${existingUser?.userFname} ${existingUser?.userLname}` },
            })
          }
        />
      )}

      <div className="mx-n4">
        <div className="form-submit-box" style={{ marginLeft: "1em" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="float-left col-md-6 text-left">
                {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                  <span className="pl-4" style={{ marginLeft: "-3em" }}>
                    <span className="span-link">
                      <button
                        type="button"
                        className="btn btn-outline-dark pl-4 pr-4 cy-btn "
                        onClick={() => {
                          (props.selectedIndex === undefined && props.setCurrentTab(2)) ||
                            (props.selectedIndex < 3 && props.setCurrentTab(props.selectedIndex - 1));
                        }}
                      >
                        Previous
                      </button>
                    </span>
                  </span>
                ) : null}
                {props.selectedIndex > -1 && props.selectedIndex < 1 ? (
                  <span className="pl-4">
                    <span className="span-link">
                      <button
                        type="button"
                        className="cy-col-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                        onClick={() => {
                          props.selectedIndex < 3 && props.setCurrentTab(props.selectedIndex + 1);
                        }}
                      >
                        Next
                      </button>
                    </span>
                  </span>
                ) : null}
              </div>
              <div className="float-right col-md-6 text-right ">
                <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3 " onClick={() => history.push(`/client-user`)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
