// import * as React from "react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../pagination";
import { DropdownList } from "react-widgets";
import * as CONFIG from "../../../config";
import { activePageInfo, setRecordsPerPage, skipRecords } from "middleware/actions/gridAction";

const NewPagination = (props) => {
  const dispatch = useDispatch();
  const { rowData, allowPagination = false, totalCount = 0, setRowData, hideLoader, showLoader, fetch, setTotalCount } = props;
  const gridState = useSelector((state) => state.gridState);
  const PAGE_SIZE_RANGE = CONFIG.PAGE_SIZE_RANGE || [10, 15, 50, 100];

  let limit = gridState.recordsPerPage;
  const [pageSize, setPageSize] = useState(allowPagination ? 20 : 50000 || 2);
  const [fetchNext, setFetchNext] = useState(false);

  const filters = [];
  const fields = [];
  const sort = "";
  const [skip, setSkip] = useState(0);

  const getPageCalculation = (totalRecords, data) => {
    return totalRecords >= data.recordsPerPage ? (
      <span className="paginate-font">
        {`${1 + (data.activePage - 1) * data.recordsPerPage} - ${
          data.recordsPerPage * data.activePage < totalRecords ? data.recordsPerPage * data.activePage : totalRecords
        } of ${totalRecords}`}{" "}
      </span>
    ) : (
      <span className="paginate-font">{`${1 + (data.activePage - 1) * data.recordsPerPage} - ${totalRecords} of ${totalRecords}`} </span>
    );
  };

  /**
   * @description pagination onchange handler
   * @param {Number} pageNum
   */
  const handlePageChange = (pageNumber) => {
    let pageNum = parseInt(pageNumber.selected) + 1;

    if (gridState.recordVisited) {
      dispatch(skipRecords((pageNum - 1) * gridState.recordsPerPage));
    }

    if (pageSize === limit) {
      setFetchNext(true);
    } else {
      let currPage = parseInt(((pageNum * pageSize) % limit) / pageSize),
        skipPage = parseInt((pageNum * pageSize) / limit) * limit;
      if (currPage === 0) {
        currPage = pageSize;
        setFetchNext(true);
        if (skip === skipPage) {
          setSkip(skipPage - limit);
        }
      }
      if (currPage % pageSize !== 0) {
        if (skip !== skipPage) {
          setFetchNext(true);
          setSkip(skipPage);
        }
      }
    }

    dispatch(activePageInfo(pageNum));
    setSkip(parseInt((pageNum - 1) * limit));
  };

  const fetchNextGridData = () => {
    console.log({ skip, fetchNext }, skip || (skip === 0 && fetchNext));
    if (skip || (skip === 0 && fetchNext)) {
      const params = {};
      if (filters.length) params.filters = filters;
      if (fields.length) params.fields = fields;
      if (sort !== "") params.sort = sort;
      if (skip) params.skip = skip;
      if (limit) params.limit = limit;
      showLoader();
      fetch(null, params)
        .then((res) => {
          setRowData(res.data.data);
          setFetchNext(false);
          hideLoader();
        })
        .catch(() => {
          hideLoader();
        });
    }
  };

  const fetchGridData = () => {
    if (skip === 0) {
      const params = {};
      if (filters.length) params.filters = filters;
      if (fields.length) params.fields = fields;
      if (sort !== "") params.sort = sort;
      if (limit) params.limit = limit;
      showLoader();

      fetch(params)
        .then(async (res) => {
          setTotalCount(res.data.totalCount);
          setRowData(res.data.data);

          setFetchNext(false);
          setSkip(0);

          hideLoader();
        })
        .catch(() => {
          hideLoader();
        });
    }
  };

  useEffect(fetchGridData, [limit]);

  useEffect(fetchNextGridData, [gridState.recordVisited ? gridState.skipRecords : skip]);

  return (
    <>
      {rowData && totalCount > 0 && allowPagination ? (
        <div className=" py-2  pr-3 bg-white border-left border-right border-bottom paginate-box ">
          <div className="d-flex fluid-container justify-content-end ">
            <div>
              <label className="pt-1 pr-2">
                <span className="paginate-font"> Rows Per Page :</span>
              </label>
              <DropdownList
                className="custom-dropdownlist"
                style={{ width: "80px", display: "inline-block", fontSize: "14px" }}
                data={[...PAGE_SIZE_RANGE]}
                onChange={(value) => {
                  setFetchNext(true);
                  setSkip(0);
                  dispatch(activePageInfo(1));
                  dispatch(setRecordsPerPage(value));
                  dispatch(skipRecords(0));
                  setPageSize(value);
                }}
                defaultValue={pageSize}
                dropUp
              />
              <label className="pt-1 pr-3 pl-3">{getPageCalculation(totalCount, gridState)}</label>
              <Pagination
                onChange={handlePageChange}
                pageRange={Math.ceil(totalCount / gridState.recordsPerPage)}
                activePage={gridState.activePage}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default NewPagination;
