/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description :  Helpdesk Settings Listing Page
 ---------------------------------------------------------------------------------
	Creation Details
	Date Created				: 15/12/2023
	Author						: Prashant Wankhade
 ---------------------------------------------------------------------------------
*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { getProductVariant } from "../../../utilities";
import CommonDataGrid from "../../components/dataGrid";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { actionError, actionSuccess, hideLoader, showLoader } from "middleware/actions/utilityAction";
import ThreeDotMenu from "../../components/threeDotMenu";
import { setMaintainedState, visitRecord } from "../../../middleware/actions/gridAction";
import { deleteCategory, deleteManyCategory, fetchCategories, fetchCategoriesFromJitBit } from "middleware/services/helpdeskApi";
import { fetchConfig } from "middleware/services/cmsApi";
import { COMMON_ACCESS_ACTION } from "../../../constants";

const HelpdeskListing = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const productVariant = getProductVariant();
  const { accessRights: aR } = props;
  const breadCrumbLinks = [{ linkUrl: "/helpdesk-settings", linkName: aR.moduleName, linkActive: true }];
  const moduleName = props.accessRights?.moduleName;
  const hideLoaderGrid = () => dispatch(hideLoader());
  const showLoaderGrid = () => dispatch(showLoader());
  const gridState = useSelector((state) => state.gridState);
  const taxonomyFilterRedux = useSelector((state) => state.taxonomyFilterState);

  /* Common access */
  const accessActionAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  const [deleteId, setDeleteId] = useState(undefined);
  const [ticketCategory, setTicketCategory] = useState([]);

  const [filterData, setFilterData] = useState(taxonomyFilterRedux?.filteredTaxonomy ? taxonomyFilterRedux.filteredTaxonomy : []);
  const [applicationPlatform, setApplicationPlatform] = useState([]);

  /**Data source for ticket catory filter */
  const categoryFilter = ticketCategory.map(({ categoryId, name }) => ({ id: categoryId, text: name }));
  const sortedCategoryFilter = categoryFilter.slice().sort((a, b) => a.text.localeCompare(b.text));

  // specify column names for column fields in datGrid here
  const columnNames = {
    id: "id",
    "Issue Type": "categoryName",
    Visibility: "category_visibility",
    "Ticket Category": "category_id",
  };

  /**Three dot action template */
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        Edit={
          accessActionEdit
            ? () => {
                history.push(`/helpdesk-settings/${value.id}/edit#basic`, { categoryId: value.id, categoryName: value.Name });
                dispatch(visitRecord());
              }
            : undefined
        }
        Delete={
          accessActionDelete
            ? async () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      dispatch(showLoader());
                      await deleteCategory(value.id)
                        .then((response) => {
                          if (response?.status == 200) {
                            dispatch(actionSuccess("Category deleted successfully"));
                          }
                          if (deleteId) setDeleteId(undefined);
                          setDeleteId(true);
                          dispatch(hideLoader());
                        })
                        .catch((err) => {
                          console.error("Error in deleteCategory:", err);
                          dispatch(actionError(err.data?.message || "Failed to delete company"));
                        });
                    },
                    okText: "Delete",
                    cancelText: "Cancel",
                    content: "Please ensure no tickets has been created for the selected category/issue type. Are sure you want to delete ?",
                    title: "dialogAlertCssWarning",
                  })
                );
              }
            : undefined
        }
      />
    );
  };

  // Dropdown Filter template
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Ticket Category":
        sourceData = sortedCategoryFilter;
        break;
      case "Visibility":
        sourceData = [
          { id: "Everywhere", text: "Everywhere" },
          { id: "Login", text: "On Login Page" },
          { id: "Platform", text: "On Platform After Login" },
        ];
        break;

      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  /** Ticket Category template */
  const ticketCategoryTemplate = (value) => {
    const fieldValueTicketCategory = value?.Category_id;
    const matchTicketCategory = ticketCategory.find((obj) => String(obj.categoryId) == fieldValueTicketCategory);
    return <span>{matchTicketCategory?.name}</span>;
  };

  /** Visibility template */
  const visibilityTemplate = (value) => {
    const fieldValueVisibility = value?.Visibility;
    let visibileText = "";
    if (fieldValueVisibility === "Platform") visibileText = "On Platform After Login";
    if (fieldValueVisibility === "Login") visibileText = "On Login Page";
    if (fieldValueVisibility === "Everywhere") visibileText = "Everywhere";
    return <span>{visibileText}</span>;
  };

  const columnFields = [
    { field: "id", type: "String", visible: false, allowFiltering: false, showInColumnChooser: false, isPrimaryKey: true },
    {
      field: "Ticket Category",
      type: "String",
      template: ticketCategoryTemplate,
      filterTemplate: ItemFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      filter: { operator: "contains" },
      showInColumnChooser: false,
    },
    {
      field: "Issue Type",
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
    },
    {
      field: "Visibility",
      type: "String",
      filterTemplate: ItemFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      template: visibilityTemplate,
    },
  ];

  if (accessActionEdit || accessActionDelete) {
    columnFields.unshift({ field: null, type: "checkbox", width: 50, allowFiltering: false, showInColumnChooser: false, textAlign: "Right" });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  /** FormData */
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    rowData &&
      rowData
        // .filter((item) => item.categoryIsDeleted !== "YES")
        .forEach((f) => {
          let data = {};
          data.id = f.id;
          data["Category_id"] = f.categoryId;
          data["Issue Type"] = f.categoryName;
          data["Visibility"] = f.categoryVisibility;

          formatedRowData.push(data);
        });
    return formatedRowData;
  };

  /** Get call for categories */
  const fetchCategory = async (params = {}) => {
    if (!gridState.recordVisited) {
      params.filters =
        params.filters && params.filters.length > 0
          ? [["categoryIsDeleted", "eq", "NO"], ["categoryProductPlatform", "cn", productVariant], ...params.filters]
          : [
              ["categoryIsDeleted", "eq", "NO"],
              ["categoryProductPlatform", "cn", productVariant],
            ];
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }

    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    return fetchCategories(params);
  };

  /** Fetching the application platforms from Helpdesk_config databse (gnx_config) */
  const fetchConfigData = async () => {
    let params = {};
    params.filters = [["configName", "eq", "Helpdesk_config"]];
    try {
      dispatch(showLoader());
      await fetchConfig(params).then((res) => {
        if (res.status === 200) {
          let blockListArray = res.data.data[0].configValue;
          setApplicationPlatform(blockListArray);
          fetchJitBitCategories(blockListArray[0]?.sectionName);
        }
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(actionError(error?.data?.message));
    }
  };

  /** This function calls the jitbit for 3 times after late response from jitbit */
  async function callApiWithRetry(platform, params) {
    let attempts = 0;
    const maxAttempts = 3;

    while (attempts < maxAttempts) {
      try {
        const response = await fetchCategoriesFromJitBit(platform, params);
        if (response?.data) {
          return response?.data;
        }
      } catch (error) {
        console.error(`Error calling API: ${error}`);
      }
      attempts += 1;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    throw new Error(`Failed to call API after ${maxAttempts} attempts`);
  }

  /** Fetch categories from jitbit */
  const fetchJitBitCategories = async (platform) => {
    let params = {};
    try {
      dispatch(showLoader());
      const apiData = await callApiWithRetry(platform, params);
      if (!apiData?.data) {
        return dispatch(actionError("Unable to get ticket categories from Ticketing System. Please try again after sometime."));
      }
      // const res = await fetchCategoriesFromJitBit(platform, params);
      setTicketCategory(apiData?.data);
      dispatch(hideLoader());
    } catch (error) {
      console.error(error);
    }
  };

  /** Delete many functionality */
  const deleteManyFunc = (flag) => {
    const selectedCategories = localStorage.getItem("selectedCategories") ? JSON.parse(localStorage.getItem("selectedCategories")) : [];
    if (flag) {
      if (selectedCategories.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              dispatch(showLoader());
              deleteManyCategory({ categoryIds: selectedCategories })
                .then((response) => {
                  if (response) {
                    if (response?.status == 200) dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(hideLoader());
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete company"));
                });
            },
            content: "Please ensure no tickets has been created for the selected category/issue type. Are sure you want to delete?",
            okText: "Delete",
            cancelText: "Cancel",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };

  useEffect(() => {
    fetchConfigData();
  }, []);

  useEffect(() => {
    localStorage.removeItem("selectedCategories");
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
      localStorage.removeItem("selectedCategories");
    };
  }, [dispatch]);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3 ">
      {productVariant !== "Insider" && (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <b>OOPS!</b> you don't have any categories avaiable !
        </div>
      )}
      <div className="gennx-grid-container">
        {applicationPlatform?.length > 0 && ticketCategory?.length > 0 && productVariant === "Insider" && (
          <CommonDataGrid
            setFilterData={setFilterData}
            filterData={filterData}
            isChildGrid={false}
            fetch={fetchCategory}
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            deleteId={deleteId}
            getMultiSelectedRows={(data) => {
              localStorage.setItem("selectedCategories", JSON.stringify([...data]));
            }}
            getMultiDeSelectedRows={(data) => {
              localStorage.setItem("selectedCategories", JSON.stringify([...data]));
            }}
            deleteRight={accessActionDelete}
            deleteMany={accessActionDelete ? deleteManyFunc : false}
            addNewRight={accessActionAddNew}
            addNew={() => history.push(`/helpdesk-settings/add`)}
            moduleName={moduleName}
          />
        )}
      </div>
    </div>
  );
};

export default HelpdeskListing;
