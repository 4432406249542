/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Company Actions for different action status
----------------------------------------------------------------
	Creation Details
	Date Created				: 14/OCT/2020
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import {
	SET_DELIVERABLE_OVERVIEW_UPDATE_STATUS,
	SET_DELIVERABLE_PUBLISH_CLICK_STATUS,
	SET_DELIVERABLE_DETAILS,
	SET_DELIVERABLE_SETTINGS_DETAILS
} from "../../constants/actionConstants";

export const setOverviewUpdateStatus = (updateStatus = false) => ({
	type: SET_DELIVERABLE_OVERVIEW_UPDATE_STATUS,
	payload: {updateStatus: updateStatus},
});
export const setIsPublishClickedState = (clickStatus = false) => ({
	type: SET_DELIVERABLE_PUBLISH_CLICK_STATUS,
	payload: {clickStatus: clickStatus},
});

// Maintain deliverable details
export const setDeliverableDetails = (data) => ({
    type: SET_DELIVERABLE_DETAILS,
    payload: data,
});

// Maintain deliverable settings details
export const setDeliverableSettingDetails = (data) => ({
	type: SET_DELIVERABLE_SETTINGS_DETAILS,
	payload: data,
});

