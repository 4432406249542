/**
 * @fileoverview This file contains common sidebar modal datargid component.
 * @date 03/Mar/2023
 * @author Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
 */

import React from "react";
import "./index.css";

function SidebarModal(props) {
  const { header, footer, children, style, closeIcon, isDeliverableRelatedReport, className, childrenClassName, backDrop } = props;

  return (
    <div>
      <div className={`sidebar-modal ${className}`} style={style}>
        {header && (
          <div id="add-role" className="sidebar-modal-header">
            <p className="header-text">{header}</p>
            {closeIcon && <p className="cross-icon">{closeIcon}</p>}
          </div>
        )}
        <div id="input-area" className={`sidebar-children-container ${childrenClassName}`}>
          {children}
        </div>
        {footer && <div className={`sidebar-footer ${isDeliverableRelatedReport ? "sidebar-footer-related-report" : ""}`}>{footer}</div>}
      </div>
      {backDrop && <div className="sidebar-modal-backdrop"></div>}
    </div>
  );
}

export default SidebarModal;
