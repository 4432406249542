import React, { useState } from "react";
import { dataPublishingDeliverable } from "../../../../middleware/services/cmsApi";
import { actionSuccess, actionError } from "../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import { getProductVariant } from "../../../../utilities";
import { Modal } from "react-bootstrap";
import "../../../../assets/styles/type.css";

const DataPublicUI = (props) => {
  const dispatch = useDispatch();
  const deliverableId = props.deliverableId;
  const [showModal, setShowModal] = useState(false);

  const handlePublish = () => {
    const payload = new FormData();
    payload.append("platform", getProductVariant());
    setShowModal(!showModal);
    dataPublishingDeliverable(deliverableId, payload)
      .then((response) => {
        if (response) {
          props.fetchDeliverable(deliverableId);
          dispatch(actionSuccess("Deliverable published successfully"));
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err.data.message || "Something went wrong"));
      });
  };
  if (props.activeStepper != "#basic" || !deliverableId) {
    return null;
  }
  return (
    <>
      <button
        type="button"
        className="btn btn-primary cy-btn pl-4 pr-4 ml-3"
        onClick={() => {
          setShowModal(!showModal);
        }}
      >
        Publish
      </button>
      <Modal
        show={showModal}
        backdrop="static"
        className="modal fade lg"
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <div className="modal-header bg-secondary text-white">
				<h5 className="modal-title" id="exampleModalLabel">Publish Deliverable</h5>
				<button type="button" className="close" aria-label="Close" onClick={() => {
					setShowModal(!showModal);
				}}>
					<span aria-hidden="true">&times;</span>
				</button>
			</div> */}
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 p-4">Are you sure you want to publish the deliverable on live ?</div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn e-control customCssDeliverableButtonCancel" onClick={() => setShowModal(!showModal)}>
            Cancel
          </button>
          <button type="button" className="btn btn-primary customCssDeliverableButtonPublish" onClick={handlePublish}>
            Publish
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DataPublicUI;
