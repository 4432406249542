import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { enableRipple } from "@syncfusion/ej2-base";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { swap, getProductVariant, decodeHTML } from "../../../utilities";
import CommonDataGrid from "../../components/dataGrid";
import { fetchReportClient } from "../../../middleware/services/reportApi";
import "./style.scss";
import { showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import { JsonToTable } from "react-json-to-table";
import CloseIcon from "../../../assets/images/closeIcon.svg?react";
import { PRODUCT_VARIANT } from "../../../constants/index";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

enableRipple(true);
const Report = (props) => {
  const { accessRights: aR } = props;
  const productVariant = getProductVariant();
  const [dateRange, setDateRange] = useState(null);
  const [reloadGrid, setReloadGrid] = useState(0);
  const [startDate, setStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [sidePanelTableData, setSidePanelTableData] = useState({});
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const [gridRef, setGridRef] = useState(null);
  const sidebarRef = useRef();
  const breadCrumbLinks = [{ linkUrl: "/report", linkName: aR.moduleName || "Report", linkActive: true }];
  const initialState = {
    selUsers: [],
  };
  const fnStartDate = () => {
    let dateFrom = new Date();
    let dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - 60);
    const dateStart = dateFrom
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    dateTo.setDate(dateTo.getDate());
    const dateEnd = dateTo
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");

    setStartDate(new Date(dateStart));
    setEndDate(new Date(dateEnd));
    setDateRange([dateFrom, dateTo]);
  };
  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
  });
  const dispatch = useDispatch();

  const cellTemplate = (row) => {
    const cellValue = row[`${row.column.field}`];
    const detailsCellName = `${row.column.field} Details`;
    const cellDetails = row[`${detailsCellName}`];
    if (cellValue) {
      return (
        <span
          className="span-link-color"
          onClick={() => {
            openSidePanel(row.column.field, cellDetails);
          }}
        >
          {cellValue}
        </span>
      );
    } else {
      return <>-</>;
    }
  };
  const cellTooltipTemplate = (row) => {
    const cellValue = row[`${row.column.field}`];
    if (cellValue) {
      return <div>{renderToolTipComponent(cellValue, "name")}</div>;
    } else {
      return <>-</>;
    }
  };
  //show tooltip when characters is more than 100
  const truncateStyleDeliverableName = {
    textOverflow: "ellipsis",
    whiteSpace: "break-word",
    fontSize: "14px",
  };
  const renderToolTipComponent = (value, id) => {
    let colDescValue = value;
    if (typeof colDescValue === "string") {
      let desc = decodeHTML(colDescValue || "").replace(/(<([^>]+)>)/gi, "");
      if (value.length >= 100) {
        return (
          <TooltipComponent position="BottomCenter" content={value}>
            <div id={id} style={{ fontSize: "14px" }}>
              {desc.length > 100 ? desc.substring(0, 100).concat("...") : desc}
            </div>
          </TooltipComponent>
        );
      } else {
        return (
          <div id={id} style={truncateStyleDeliverableName}>
            {value}
          </div>
        );
      }
    } else {
      return (
        <div id={id} style={truncateStyleDeliverableName}>
          {value}
        </div>
      );
    }
  };
  const openSidePanel = (field, cellDetails) => {
    if (cellDetails) {
      setShowSidePanel(true);
      let tableData = cellDetails.map((dataRow) => {
        let obj = {};
        obj[field] = dataRow.name;
        return obj;
      });
      setSidePanelTableData(tableData);
    }
  };
  // specify column names for column fields in datGrid here
  let columnNames = {
    Name: "userName",
    Email: "userEmail",
    Company: "userCompany",
    // "BTB": "userBtb",
    "Relationship Manager": "userRmName",
    Subscription: "userSubscription",
    "Total Time Spent": "totalTimeSpent",
    "Total Visits Count": "totalVistsCount",
    "Days Since Last Log In": "daysSinceLastLogin",
    "Average Active Time Per Visit": "avgTimePerVists",
  };
  let columnFields = [
    {
      field: "Email",
      headerText: "Email",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 250,
    },
    {
      field: "Name",
      headerText: "Name",
      type: "String",
      textAlign: "Left",
      allowFiltering: false,
      allowSorting: false,
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 150,
      template: cellTooltipTemplate,
    },
    {
      field: "Company",
      headerText: "Company",
      type: "String",
      textAlign: "Left",
      allowFiltering: false,
      allowSorting: false,
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 150,
      template: cellTooltipTemplate,
    },
    // {
    //     field: "BTB", headerText: "BTB", type: "String", textAlign: "Center", allowFiltering: false, allowSorting: false,
    //     headerTextAlign: "Center", showInColumnChooser: false, width: 100, template: cellTooltipTemplate
    // },
    {
      field: "Relationship Manager",
      headerText: "Relationship Manager",
      type: "String",
      textAlign: "Left",
      allowFiltering: false,
      allowSorting: false,
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 250,
      template: cellTooltipTemplate,
    },
    {
      field: "Subscription",
      headerText: "Subscription",
      type: "String",
      textAlign: "Left",
      allowFiltering: false,
      allowSorting: false,
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 300,
      template: cellTooltipTemplate,
    },

    {
      field: "Total Visits Count",
      headerText: "Total Visits Count",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 150,
      template: cellTooltipTemplate,
    },
    {
      field: "Total Time Spent",
      headerText: "Total Time Spent",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 150,
      template: cellTooltipTemplate,
    },
    {
      field: "Average Active Time Per Visit",
      headerText: "Average Active Time Per Visit",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 150,
      template: cellTooltipTemplate,
    },

    {
      field: "Days Since Last Log In",
      headerText: "Days Since Last Log In",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 150,
      template: cellTooltipTemplate,
    },
  ];

  if (productVariant === PRODUCT_VARIANT.COGNITION) {
    columnNames = {
      ...columnNames,
      ...{
        "News Visits": "newsVisitCount",
        "News Visits Details": "newsVisited",
        "News Liked": "newsLikeCount",
        "News Liked Details": "newsLiked",
        "News Commented": "newsCommentCount",
        "News Commented Details": "newsComment",
        "News Shared": "newsShareCount",
        "News Shared Details": "newsShared",
        "News Marked Relevant": "newsRelevantCount",
        "News Bookmarked": "newsBookmarkCount",
        "News Bookmarked Details": "newsBookmar",
        "Site Searches": "searchCount",
        "Site Searches Details": "searchKeyword",
      },
    };

    columnFields = columnFields.concat([
      {
        field: "News Visits",
        headerText: "News Visits",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "News Visits Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "News Liked",
        headerText: "News Liked",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "News Liked Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "News Commented",
        headerText: "News Commented",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "News Commented Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "News Shared",
        headerText: "News Shared",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "News Shared Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "News Marked Relevant",
        headerText: "News Marked Relevant",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
      },

      {
        field: "News Bookmarked",
        headerText: "News Bookmarked",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "News Bookmarked Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "Site Searches",
        headerText: "Site Searches",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "Site Searches Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
    ]);
  }
  if (productVariant === PRODUCT_VARIANT.INSIDER) {
    columnNames = {
      ...columnNames,
      ...{
        "Question Your Peers Asking Visits": "questionYourPeersAskingViewCount",
        "Question Your Peers Asking Visits Details": "questionYourPeersAskingViewDetails",
        "Most Read Research Visits": "mostReadReseachViewCount",
        "Most Read Research Visits Details": "mostReadReseachViewDetails",
        "Top Signals Dashboard Visits": "topSignalViewCount",
        "Key Players Visits": "keyPlayersViewCount",
        "Key Players Visits Details": "keyPlayersViewDetails",
        "News Visits": "newsVisitCount",
        "News Visits Details": "newsVisited",
        "Average Time Spent On News Profile": "avgTimeNewsProfile",
        "Startup Visits": "startupVisitCount",
        "Startup Visits Details": "startupVisited",
        "Average Time Spent On Startup Profile": "avgTimeStartupProfile",
        "Reports Visits": "reportVisitCount",
        "Reports Visits Details": "reportVisited",
        "Average Time Spent On Report Profile": "avgTimeReportProfile",
        "Video Visits": "videoVisitCount",
        "Video Visits Details": "videoVisited",
        "Site Searches": "searchCount",
        "Site Searches Details": "searchKeyword",
      },
    };
    columnFields = columnFields.concat([
      {
        field: "Question Your Peers Asking Visits",
        headerText: "Question Your Peers Asking Visits",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "Question Your Peers Asking Visits Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "Most Read Research Visits",
        headerText: "Most Read Research Visits",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "Most Read Research Visits Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "Top Signals Dashboard Visits",
        headerText: "Top Signals Dashboard Visits",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTooltipTemplate,
      },
      {
        field: "Key Players Visits",
        headerText: "Key Players Visits",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "Key Players Visits Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "News Visits",
        headerText: "News Visits",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "News Visits Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "Average Time Spent On News Profile",
        headerText: "Average Time Spent On News Profile",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTooltipTemplate,
      },
      {
        field: "Startup Visits",
        headerText: "Startup Visits",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "Startup Visits Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "Average Time Spent On Startup Profile",
        headerText: "Average Time Spent On Startup Profile",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTooltipTemplate,
      },
      {
        field: "Reports Visits",
        headerText: "Reports Visits",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "Reports Visits Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },

      {
        field: "Average Time Spent On Report Profile",
        headerText: "Average Time Spent On Report Profile",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTooltipTemplate,
      },
      {
        field: "Video Visits",
        headerText: "Video Visits",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "Video Visits Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
      {
        field: "Site Searches",
        headerText: "Site Searches",
        type: "String",
        allowFiltering: false,
        textAlign: "Center",
        headerTextAlign: "Center",
        width: 150,
        template: cellTemplate,
      },
      {
        field: "Site Searches Details",
        type: "String",
        visible: false,
        allowFiltering: false,
        showInColumnChooser: false,
      },
    ]);
  }

  const created = () => {
    sidebarRef.current.element.style.visibility = "";
  };
  const closeDetailsPanel = () => {
    setShowSidePanel(false);
  };

  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean

  // formatRowData function required to format rowData for dataGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = {};
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "-";
        }
      });
      return newRD;
    });
    return formatedRowData;
  };

  const fetchReport = async (params = {}) => {
    params.productVariant = productVariant;
    if (params.sort) {
      params.sort = params.sort.split(",").pop();
    }
    if (dateRange) {
      let gte = { d: dateRange[0] };
      Date.prototype.toJSON = function () {
        return moment(this).format();
      };
      gte.d.toJSON = function () {
        return moment(this).format();
      };

      let lt = { d: moment(dateRange[1]) };
      lt.d.toJSON = function () {
        return moment(this).format();
      };

      params.dateRangeFrom = moment(gte.d).format("YYYY-MM-DD");
      params.dateRangeTo = moment(lt.d).format("YYYY-MM-DD");
    }

    return fetchReportClient(params);
  };
  const handleReset = () => {
    if (gridRef) {
      gridRef.current.clearFiltering();
      gridRef.current.clearSorting();
    }
    window.location.reload();
    formik.handleReset();
    setDateRange(null);
    fetchReport();
  };
  const getGridRef = (gridRef) => {
    setGridRef(gridRef);
  };
  const handleSearch = () => {
    setReloadGrid(new Date());
    fetchReport();
  };

  useEffect(() => {
    fnStartDate();
  }, []);
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);
  return (
    <div className="p-3" style={{ maxHeight: "95vh" }}>
      <div className="col-md-12 border rounded-bottom nopadding p-0 bg-white ">
        <div className="row ml-1">
          <div className="col-md-2 mt-4 grid-container-report-filters">
            <DateRangePickerComponent
              placeholder="Date Range"
              cssClass="e-outline"
              openOnFocus={true}
              showClearButton={false}
              format="dd MMM yyyy"
              separator="to"
              floatLabelType="Auto"
              startDate={startDate}
              endDate={endDate}
              onChange={(e) => {
                if (e.value) setDateRange(e.value);
              }}
              autoComplete="off"
              strictMode={true}
            ></DateRangePickerComponent>
          </div>
          <div className="col-md-4 ">
            <button type="button" onClick={handleSearch} className="mt-4 search-btn px-4 btn btn-primary">
              Search
            </button>
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 mt-4 ml-2 ">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="gennx-grid-container gennx-grid-container-report">
        {dateRange && (
          <CommonDataGrid
            gridTitle="Client Activity Report"
            fetch={fetchReport}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            isPaging={false}
            freezeColumn={2}
            showResetFilter={false}
            deleteMany={false}
            deleteId={reloadGrid}
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            getGridRef={getGridRef}
          />
        )}

        <SidebarComponent
          id="report-sidebar"
          ref={sidebarRef}
          width={150}
          type={"Over"}
          position="Right"
          created={created}
          animate={false}
          enableGestures={false}
          isOpen={showSidePanel}
          style={{ right: "17px !important" }}
        >
          <div className="col-12 p-0 bg-white">
            <div className="row m-0">
              <div className="roundedTop pl-4 pr-4 " style={{ height: "56px" }}>
                <span className="font-weight-bold" style={{ paddingTop: "2.2vh" }}>
                  Details
                  <span
                    className="closeIcon"
                    style={{ marginLeft: "295px !important" }}
                    onClick={() => {
                      closeDetailsPanel();
                    }}
                  >
                    <CloseIcon />
                  </span>
                </span>
              </div>
              <hr style={{ borderBottom: "1px solid #DBDBDD", width: "100%" }} />
            </div>
            <div className="col-md-12 mt-2 panel-taxonomy" style={{ lineHeight: "1.5rem", listStyleType: "decimal-leading-zero", fontSize: "14px" }}>
              <JsonToTable json={sidePanelTableData} />
            </div>
          </div>
        </SidebarComponent>
      </div>
    </div>
  );
};

export default Report;
