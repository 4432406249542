/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 15/Mar/2021
 * File Description: Survey Form Listing
 */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ThreeDotMenu from "../../components/threeDotMenu";
import CommonDataGrid from "../../components/dataGrid";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { fetchSurveys, deleteSingleSurvey, getSurveyAnalytics } from "../../../middleware/services/surveyApi";
import { swap, getProductVariant, getDateFromMongoDate } from "../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import SURVEY_CONSTANT from "./constants";
import ExportAnalytics from "./component/exportAnalytics/exportAnalytics";
import { visitRecord, setMaintainedState } from "../../../middleware/actions/gridAction";
import { COMMON_ACCESS_ACTION, PRODUCT_VARIANT } from "../../../constants";
import { setFilteredTaxonomy } from "../../../middleware/actions/taxonomyFilterAction";
import { resetRedux } from "../../../middleware/actions/surveyAction";

const SurveyListing = (props) => {
  const { accessRights: aR } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const productVariant = getProductVariant();
  let moduleName = props.accessRights?.parentModuleName;
  const [deleteId, setDeleteId] = useState(undefined);
  const taxonomyFilterRedux = useSelector((state) => state.taxonomyFilterState);
  const gridState = useSelector((state) => state.gridState);
  const [taxonomyTaggingTabName, setTaxonomyTaggingTabName] = useState(false);
  const [filterData, setFilterData] = useState(taxonomyFilterRedux?.filteredTaxonomy ? taxonomyFilterRedux.filteredTaxonomy : []);

  /* Common access */
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionExport = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EXPORT);
  const accessActionFilterByTaxonomy = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.FILTER_BY_TAXONOMY);

  const breadCrumbLinks = [{ linkUrl: "/survey", linkName: aR.moduleName, linkActive: true }];

  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  // Reset Redux
  useEffect(() => {
    const locationPath = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];
    if (locationPath != `/survey`) {
      dispatch(setFilteredTaxonomy([]));
      dispatch(resetRedux());
      setFilterData([]);
      localStorage.setItem("locationPath", window.location.pathname);
    }
  }, []);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/survey"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb(breadCrumbLinks));
    };
  }, [dispatch]);

  const columnNames = {
    id: "id",
    SerialNo: "surveySerialNo",
    "Short Name": "surveyShortName",
    Title: "surveyTitle",
    // "Created Date": "surveyCreatedDate",
    surveyStartDate: "surveyStartDate",
    surveyEndDate: "surveyEndDate",
    Status: "surveyStatus",
    Invitation: "surveyInvitationStatus",
    Responses: "surveyNoOfResponses",
    Type: "surveyType",
    Template: "surveyIsTemplate",
    Published: "surveyIsPublished",
    surveyReleaseArtifact: "surveyReleaseArtifact",
  };

  function exportSurveyAnalytics(value, shortName, surveyStatus) {
    let params = {};
    let fields = ["surveySerialNo", "surveyShortName", "surveyTitle", "surveyForm", "userSurveyResponse"];
    params.fields = fields;
    getSurveyAnalytics(value, params).then((res) => {
      let data = res.data.data || {};
      if (data && data.surveyResponse.length > 0) {
        let isDraft = surveyStatus === SURVEY_CONSTANT.SURVEY_STATUS.DRAFT ? true : false;
        ExportAnalytics(data, shortName, isDraft);
        dispatch(actionSuccess("File is being Downloaded"));
      } else {
        dispatch(actionError("There is no response for the survey"));
      }
    });
  }

  const actionTemplate = (value) => {
    const surveyStatus = value["Status"];
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit ? { Edit: () => history.push(`/survey/${value.id}/basic-details#basic`) } : {})}
        {...((surveyStatus === SURVEY_CONSTANT.SURVEY_STATUS.OPEN ||
          surveyStatus === SURVEY_CONSTANT.SURVEY_STATUS.CLOSED ||
          surveyStatus === SURVEY_CONSTANT.SURVEY_STATUS.DRAFT) &&
        accessActionExport
          ? {
              Export: () => {
                exportSurveyAnalytics(value.id, value["Short Name"], surveyStatus);
              },
            }
          : "")}
        {...(surveyStatus === SURVEY_CONSTANT.SURVEY_STATUS.DRAFT && accessActionDelete
          ? {
              Delete: () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteSingleSurvey(value.id)
                        .then((response) => {
                          if (response) {
                            dispatch(actionSuccess("Survey deleted successfully"));
                            const params = {};
                            params.limit = 100;
                            if (productVariant)
                              params.filters = [
                                ["productVariant.productName", "eq", productVariant],
                                ["surveyIsDeleted", "eq", "NO"],
                              ];
                            fetchSurveys(params).then((form) => {
                              formatRowData(form?.data?.data || []);
                            });
                            setDeleteId(new Date());
                          } else {
                            dispatch(actionError("Something went wrong"));
                          }
                        })
                        .catch((err) => {
                          dispatch(actionError(err?.data?.message || "Failed to delete survey"));
                        });
                    },
                    okText: "Delete",
                    cancelText: "Cancel",
                    content: "Are you sure you want to delete ?",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : "")}
      />
    );
  };

  const customFormTitleTemplate = (value) => {
    return (
      (
        <span
          className="span-link"
          onClick={() => {
            history.push(`/survey/${value.id}/basic-details#basic`);
            dispatch(visitRecord());
          }}
        >
          {value["SerialNo"]}
        </span>
      ) || <span>{value["SerialNo"]}</span>
    );
  };

  /* 
        @Description : Date Template 
    */
  const dateTemplate = (value) => {
    const column = value.column.field;
    return ["surveyStartDate", "surveyEndDate"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
      <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
    ) : (
      ""
    );
  };

  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Template":
      case "Published":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Status":
        sourceData = [
          { id: "Draft", text: "Draft" },
          { id: "Not Started", text: "Not Started" },
          { id: "Open", text: "Open" },
          { id: "Closed", text: "Closed" },
        ];
        break;
      case "Invitation":
        sourceData = [
          { id: "Not Started", text: "Not Started" },
          { id: "Sending...", text: "Sending..." },
          { id: "Sent", text: "Sent" },
        ];
        break;
      case "Type":
        sourceData = [
          { id: "Ongoing", text: "Ongoing" },
          { id: "One Time", text: "One Time" },
          { id: "NPS Survey", text: "NPS Survey" },
        ];
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  const columnFields = [
    {
      field: "id",
      type: "String",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "SerialNo",
      headerText: "Serial No.",
      type: "String",
      template: customFormTitleTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      showInColumnChooser: false,
      width: 140,
    },
    { field: "Short Name", headerText: "Survey Name", type: "String", filterTemplate: StringFilter, textAlign: "Left", width: 160 },
    { field: "Title", headerText: "Survey Title", type: "String", filterTemplate: StringFilter, textAlign: "Left", width: 150 },
    {
      field: "Type",
      headerText: "Survey Type",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Left",
      width: 150,
    },
    { field: "Responses", headerText: "Responses", type: "String", allowSorting: false, allowFiltering: false, width: 90, textAlign: "Left" },
    // { field: "Created Date", headerText: "Create Date", template: dateTemplate, type: "String", filterTemplate: StringFilter, textAlign: "Left", width: 120, headerTextAlign: "Left" },
    { field: "surveyStartDate", headerText: "Start Date", type: "Date", template: dateTemplate, textAlign: "Center", width: 150 },
    { field: "surveyEndDate", headerText: "End Date", type: "Date", template: dateTemplate, textAlign: "Center", width: 150 },
    { field: "Status", type: "String", filterTemplate: ItemFilter, filter: { operator: "equal" }, textAlign: "Left", width: 130 },
    { field: "Invitation", type: "String", filterTemplate: ItemFilter, filter: { operator: "equal" }, textAlign: "Left", width: 130 },
    {
      field: "Template",
      headerText: "Template",
      type: "String",
      filterTemplate: ItemFilter,
      textAlign: "Center",
      width: 140,
      showInColumnChooser: false,
    },
    { field: "Published", type: "String", filterTemplate: ItemFilter, textAlign: "Center", width: 160, showInColumnChooser: false },
  ];

  if (accessActionEdit || accessActionExport || accessActionDelete) {
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
        return newRD;
      });
      newRD["surveyStartDate"] = new Date(rD.surveyStartDate);
      newRD["surveyEndDate"] = new Date(rD.surveyEndDate);
      newRD["Responses"] =
        rD.surveyNoOfAudience && rD.surveyNoOfResponses ? rD.surveyNoOfResponses + " out of " + rD.surveyNoOfAudience : rD.surveyNoOfResponses;
      return newRD;
    });
    return formatedRowData;
  };

  const getSurveys = (params = {}) => {
    let defaultFilter = [["surveyIsDeleted", "eq", "NO"]];
    let fields = [
      "surveySerialNo",
      "surveyShortName",
      "surveyTitle",
      "surveyCreatedDate",
      "surveyStartDate",
      "surveyEndDate",
      "surveyStatus",
      "surveyType",
      "surveyNoOfResponses",
      "surveyNoOfAudience",
      "surveyIsTemplate",
      "surveyIsPublished",
      "surveyInvitationStatus",
      "surveyReleaseArtifact",
    ];
    let { filters = [] } = params;

    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    if (!params.sort) params.sort = "surveyCreatedDate:desc";
    params.filters = [...filters, ...defaultFilter];
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchSurveys(params);
    }
    params.fields = fields;

    if (Object.keys(filterData).length > 0) {
      let kiaFilter = [];

      kiaFilter = filterData?.kia?.length > 0 ? ["surveyKiaTags.kiaId", "in", filterData.kia] : [];

      params.filters = [...filters, ...defaultFilter];
      kiaFilter.length > 0 && params.filters.push(kiaFilter);
    }
    return fetchSurveys(params);
  };

  useEffect(() => {}, [formatRowData]);

  // Custom Tab names
  let tabNames;
  if (productVariant == PRODUCT_VARIANT.WHATNEXT) {
    tabNames = taxonomyTaggingTabName ? ["Tag Key Impact Area"] : ["By Key Impact Area"];
  }
  const locationPaths = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        <CommonDataGrid
          gridTitle="Survey Management"
          fetch={getSurveys}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          getMultiSelectedRows={(data) => {
            localStorage.setItem("selectedSurveys", JSON.stringify([...data]));
          }}
          getMultiDeSelectedRows={(data) => {
            localStorage.setItem("selectedSurveys", JSON.stringify([...data]));
          }}
          deleteMany={false}
          addNewRight={accessActionListingAddNew}
          addNew={() => history.push("/survey/basic-details#basic")}
          setFilterData={setFilterData}
          filterData={filterData}
          moduleName={moduleName}
          showFilterByTaxonomy={productVariant == PRODUCT_VARIANT.WHATNEXT ? true : false && accessActionFilterByTaxonomy}
          boTagging={false}
          tabName={tabNames}
          handleTaxonomyTabName={setTaxonomyTaggingTabName}
          customDialogBoxClass="dialoagBoxClass"
          customArrowClass="survey-arrow-top"
          clearFilterByTaxonomy={locationPaths == "/survey" ? true : false}
        />
      </div>
    </div>
  );
};

export default SurveyListing;
