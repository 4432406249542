import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonDataGrid from "../../components/dataGrid";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { fetchVideo, deleteVideo, updateVideo } from "../../../middleware/services/cmsApi";
import { swap } from "../../../utilities";
import { actionSuccess, actionError } from "../../../middleware/actions/utilityAction";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import ThreeDotMenu from "../../components/threeDotMenu";
import { setMaintainedState, visitRecord } from "../../../middleware/actions/gridAction";

const Video = (props) => {
  const [deleteId, setDeleteId] = useState(undefined);
  const history = useHistory();
  const dispatch = useDispatch();

  const accessPermissionAdd = true;
  const accessPermissionEdit = true;
  const accessPermissionDelete = true;
  const gridState = useSelector((state) => state.gridState);
  const breadCrumbLinks = [{ linkUrl: "/training-video", linkName: "Training Video", linkActive: true }];
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/training-video"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  const updateVisibilty = async (videoId, payload) => {
    await updateVideo(videoId, payload)
      .then((res) => {
        if (res) {
          setDeleteId(new Date());
          dispatch(actionSuccess("Is Active updated successfully"));
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
    setDeleteId(new Date());
  };

  const visibiltyTemplate = (value) => {
    return (
      <label className="custom-switch3">
        <input
          type="checkbox"
          value={value["Is Active"]}
          checked={value["Is Active"] == "Active" ? true : false}
          onChange={(e) => {
            let payload = {
              videoStatus: value["Is Active"] == "Active" ? "Inactive" : "Active",
            };
            updateVisibilty(value.id, payload);
          }}
        />

        <span className="on">Yes</span>
        <span className="off">No</span>

        <i></i>
      </label>
    );
  };
  /* 
        @Description : Template Component for action column
    */
  const actionTemplate = (value) => {
    let edit = false;
    if (accessPermissionEdit) {
      edit = () => {
        history.push(`/training-video/${value.id}/edit`);
        dispatch(visitRecord());
      };
    }
    let deleteFlag = false;
    if (accessPermissionDelete) {
      deleteFlag = () => {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              deleteVideo(value.id)
                .then((response) => {
                  if (response) {
                    dispatch(actionSuccess("Video deleted successfully"));
                    const params = {};
                    params.limit = 100;
                    fetchVideo(params).then((form) => {
                      formatRowData(form?.data?.data || []);
                    });
                    setDeleteId(new Date());
                  } else {
                    dispatch(actionError("Something went wrong"));
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err?.data?.message || "Failed to delete assessment"));
                });
            },
            content: "Are you sure, you want to delete?",
          })
        );
      };
    }
    return <>{(accessPermissionEdit || accessPermissionDelete) && <ThreeDotMenu rowID={value.id} Edit={edit} Delete={deleteFlag} />}</>;
  };

  const videoTitleTemplate = (value) => {
    return (
      <span
        className={`${accessPermissionEdit && "span-link"}`}
        onClick={() => {
          accessPermissionEdit && history.push(`/training-video/${value.id}/edit`);
          dispatch(visitRecord());
        }}
      >
        {value["Unique Code"]}
      </span>
    );
  };

  const ItemFilter = (value) => {
    let sourceData = [];
    if (value.column.field === "Is Active") {
      sourceData = [
        { id: "Active", text: "YES" },
        { id: "Inactive", text: "NO" },
      ];
    }
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const columnNames = {
    id: "id",
    "Unique Code": "videoUniqueCode",
    "Video Title": "videoTitle",
    "Video Link": "videoLink",
    "Functional Competency": "videoFunctionalCompetency",
    "Is Active": "videoStatus",
  };
  const columnFields = [
    {
      field: "id",
      type: "string",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "Unique Code",
      type: "String",
      template: videoTitleTemplate,
      filterTemplate: StringFilter,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 160,
    },
    { field: "Video Title", type: "String", filterTemplate: StringFilter, width: 1300, textAlign: "Left", showInColumnChooser: false },
    {
      field: "Is Active",
      type: "String",
      template: visibiltyTemplate,
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Left",
      width: 250,
    },
    {
      field: "Action",
      type: "String",
      template: actionTemplate,
      filterTemplate: StringFilter,
      allowSorting: false,
      allowFiltering: false,
      headerTextAlign: "Center",
      textAlign: "Right",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    },
  ];
  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }

        if (rD.videoFunctionalCompetency) {
          newRD["Functional Competency"] =
            rD.videoFunctionalCompetency && rD.videoFunctionalCompetency.length
              ? rD.videoFunctionalCompetency
                  .map((item) => {
                    return item;
                  })
                  .filter((item, index, self) => item != "" && self.indexOf(item) === index)
                  .join(", ")
              : "";
        }
        return newRD;
      });
      return newRD;
    });
    return formatedRowData;
  };
  const getVideo = (params = {}) => {
    params.sort = "_id:desc";
    let defaultFilter = [];

    let fields = ["videoTitle", "videoUniqueCode", "videoLink", "videoUniqueCode", "videoFunctionalCompetency", "videoStatus"];
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    params.fields = fields;
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchVideo(params);
    }
    return fetchVideo(params);
  };

  useEffect(() => {}, [formatRowData]);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        <CommonDataGrid
          gridTitle="Training Video"
          fetch={getVideo}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          // getMultiSelectedRows={(data) => { localStorage.setItem("selectedCompanies", JSON.stringify([...data])); }}
          // getMultiDeSelectedRows={(data) => { localStorage.setItem("selectedCompanies", JSON.stringify([...data])); }}
          // deleteRight={accessPermissionBasic}
          deleteMany={false}
          addNewRight={accessPermissionAdd}
          addNew={() => {
            history.push("/training-video/add");
          }}
        />
      </div>
    </div>
  );
};

export default Video;
