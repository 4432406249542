import React from "react";
import { Box, Tooltip } from "@mui/material";
import SettingsIcon from "assets/images/setting-icon.svg";
import GripDots from "assets/images/grip-dots.svg";
import DeleteIcon from "assets/images/delete-icon.svg";

const WidgetHeader = (props) => {
  const { name, onSettingClick, onDeleteClick, widgetData, dragHandleClass } = props;
  return (
    <div className="header-wrapper">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "32px",
          background: "#fff",
          padding: "0px 10px",
          alignItems: "center",
          borderBottom: "1px solid #DBDBDD",
        }}
      >
        <Box
          sx={{
            display: "flex",
            // width: "15%",
          }}
        >
          {/* {name === "dashboard" && <Tooltip title="Drag" arrow >
            <span className={`${dragHandleClass} cursor-pointer`}>
              <img src={GripDots} />
            </span>
          </Tooltip>} */}
        </Box>
        {widgetData && <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: "8px",
          }}
        >
          <Tooltip title="Remove" arrow>
            <span
              onClick={(e) => {
                e.stopPropagation();
                onDeleteClick(name, widgetData?.id, widgetData?.widgetUniqueName);
              }}

              className="cursor-pointer"
            >
              <img src={DeleteIcon} />
            </span>
          </Tooltip>
          <Tooltip title="Settings" arrow>
            <span
              onClick={(e) => {
                e.stopPropagation();
                onSettingClick(widgetData);
              }}
              className="cursor-pointer"
            >
              <img src={SettingsIcon} />
            </span>
          </Tooltip>
        </Box>}
      </Box>
    </div>
  );
};

export default WidgetHeader;
