/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Qc rejected remark form field validation
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 07/Aug/2020 
	Author						: Anand Kumar
================================================================ 
*/

import React, { useState, useEffect } from "react";

const TextArea = (props) => {
    const [value, setValue] = useState("");
    useEffect(() => {
        let name = props.name;
        setValue(props.formik.values[name]);
    }, [props.formik.values, props.name]);

    return <>
        <textarea
            className="form-control"
            autoComplete='off'
            {...props.formik.getFieldProps(...props.name)}
            value={value}
            {...props}
        />
        <div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
            <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
        </div>
    </>;
}

export { TextArea };