/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Trend UI and API wiring up 
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 08/Aug/2020 
	Author						: Aditya Tijare
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import TreeView from "../../../components/treeView/boTaxonomy";
import { actionSuccess, showLoader, hideLoader, actionError, showMessage } from "../../../../middleware/actions/utilityAction";
import { saveCompanyObjectiveTaxonomy, saveCompanyObjective, fetchCompanyObjectiveTaxonomy, deleteCompanyObjective } from "../../../../middleware/services/companyApi";
import { saveNewsObjectiveTaxonomy, saveNewsObjective, fetchNewsObjectiveTaxonomy, deleteNewsObjective } from "../../../../middleware/services/newsApi";
import { saveVideoObjectiveTaxonomy, saveVideoObjective, fetchVideoObjectiveTaxonomy, deleteVideoObjective } from "../../../../middleware/services/videoApi";
import { saveProductObjectiveTaxonomy, saveProductObjective, fetchProductObjectiveTaxonomy, deleteProductObjective } from "../../../../middleware/services/productApi";
import { saveDeliverableObjectiveTaxonomy, saveDeliverableObjective, fetchDeliverableObjectiveTaxonomy, deleteDeliverableObjective } from "../../../../middleware/services/deliverablesApi";

const TaxomonyUI = (props) => {
	const history = useHistory();
	const companyIds = history.location.state?.companyIds;
	const newsIds = history.location.state?.newsIds;
	const videoIds = history.location.state?.videoIds;
	const productIds = history.location.state?.productIds;
	const deliverableIds = history.location.state?.deliverableIds;
	const entityName = history.location.state?.entityName;
	let contentIds = '';
	let contentUrls = ""
	switch (entityName) {
		case 'company':
			contentIds = companyIds;
			contentUrls = "/company";
			break;
		case 'news':
			contentIds = newsIds;
			contentUrls = "/news";
			break;
		case 'video':
			contentIds = videoIds;
			contentUrls = "/video";
			break;
		case 'product':
			contentIds = productIds;
			contentUrls = "/product";
			break;
		case 'deliverable':
			contentIds = deliverableIds;
			contentUrls = "/deliverable";
			break;

	}
	if (!contentIds.length) {
		history.goBack()
	}
	const dispatch = useDispatch();
	const [saveTreeData, setSaveTreeData] = useState(false)
	const breadCrumbState = [
		{ linkUrl: contentUrls, linkName: entityName.charAt(0).toUpperCase() + entityName.slice(1), linkActive: false },
		{ linkUrl: "", linkName: "Business Objective - Taxonomy", linkActive: true }
	];

	useEffect(() => {
		dispatch(setBreadCrumb(breadCrumbState));
		return () => dispatch(setBreadCrumb());
	}, [dispatch]);
	const handleSave = (selectedObjective, checkValues, callback) => {
		saveObjectiveTaxonomy(selectedObjective, checkValues, callback)
		setSaveTreeData(false)
	}
	const saveObjectiveTaxonomy = (selectedObjective, taxonomyIds, callback) => {
		if (selectedObjective && taxonomyIds.length) {
			if (entityName === 'company') {
				_saveCompanyObjective(selectedObjective, taxonomyIds, callback);
			}
			if (entityName === 'news') {
				_saveNewsObjective(selectedObjective, taxonomyIds, callback);
			}
			if (entityName === 'video') {
				_saveVideoObjective(selectedObjective, taxonomyIds, callback);
			}
			if (entityName === 'product') {
				_saveProductObjective(selectedObjective, taxonomyIds, callback);
			}
			if (entityName === 'deliverable') {
				_saveDeliverableObjective(selectedObjective, taxonomyIds, callback);
			}
		}

	};

	const _saveCompanyObjective = (selectedObjective, taxonomyIds, callback) => {
		let delData = {
			companyIds: companyIds
		}
		dispatch(showLoader());
		deleteCompanyObjective(selectedObjective.id, delData).then((delRes) => {
			let data = {};
			data.companyIds = companyIds;
			data.companyBusinessObjective = [{
				"objectiveId": selectedObjective.id,
				"objectiveTitle": selectedObjective.objectiveTitle
			}];
			saveCompanyObjective(data).then((res) => {
				dispatch(hideLoader());
				if (res.data) {
					dispatch(showLoader());
					try {
						let taxonomyData = {};
						taxonomyData.companyIds = companyIds;
						taxonomyData.companyObjectiveTaxonomy = []
						taxonomyIds.forEach(tax => {
							taxonomyData.companyObjectiveTaxonomy.push({
								"taxonomyId": tax,
								"objectiveId": selectedObjective.id
							})
						});
						const response = saveCompanyObjectiveTaxonomy(taxonomyData);
						response.then((response) => {
							dispatch(hideLoader());
							dispatch(actionSuccess("Objective Taxonomy Tagged Successfully"));
							callback(selectedObjective.id);
						}).catch((e) => {
							dispatch(hideLoader());
							dispatch(actionError("Something Went Wrong...!"));
						})
					} catch (e) {
						dispatch(hideLoader());
						dispatch(actionError("Something Went Wrong...!"));
					}
				} else {
					dispatch(showMessage("Please select taxonomy"));
					setSaveTreeData(false);
				}
			}).catch((e) => {
				dispatch(hideLoader());
				dispatch(actionError("Something Went Wrong...!"));
			})
		}).catch((e) => {
			dispatch(hideLoader());
			dispatch(actionError("Something Went Wrong...!"));
		})

	}

	const _saveVideoObjective = (selectedObjective, taxonomyIds, callback) => {
		let delData = {
			videoIds: videoIds
		}
		dispatch(showLoader());
		deleteVideoObjective(selectedObjective.id, delData).then((delRes) => {
			let data = {};
			data.videoIds = videoIds;
			data.videoBusinessObjective = [{
				"objectiveId": selectedObjective.id,
				"objectiveTitle": selectedObjective.objectiveTitle
			}];
			saveVideoObjective(data).then((res) => {
				dispatch(hideLoader());
				if (res.data) {
					dispatch(showLoader());
					try {
						let taxonomyData = {};
						taxonomyData.videoIds = videoIds;
						taxonomyData.videoObjectiveTaxonomy = []
						taxonomyIds.forEach(tax => {
							taxonomyData.videoObjectiveTaxonomy.push({
								"taxonomyId": tax,
								"objectiveId": selectedObjective.id
							})
						});
						const response = saveVideoObjectiveTaxonomy(taxonomyData);
						response.then((response) => {
							dispatch(hideLoader());
							dispatch(actionSuccess("Objective Taxonomy Tagged Successfully"));
							callback(selectedObjective.id);
						}).catch((e) => {
							dispatch(hideLoader());
							dispatch(actionError("Something Went Wrong...!"));
						})
					} catch (e) {
						dispatch(hideLoader());
						dispatch(actionError("Something Went Wrong...!"));
					}
				} else {
					dispatch(showMessage("Please select taxonomy"));
					setSaveTreeData(false);
				}
			}).catch((e) => {
				dispatch(hideLoader());
				dispatch(actionError("Something Went Wrong...!"));
			})
		}).catch((e) => {
			dispatch(hideLoader());
			dispatch(actionError("Something Went Wrong...!"));
		})

	}
	const _saveNewsObjective = (selectedObjective, taxonomyIds, callback) => {
		let delData = {
			newsIds: newsIds
		}
		dispatch(showLoader());
		deleteNewsObjective(selectedObjective.id, delData).then((delRes) => {
			let data = {};
			data.newsIds = newsIds;
			data.newsBusinessObjective = [{
				"objectiveId": selectedObjective.id,
				"objectiveTitle": selectedObjective.objectiveTitle
			}];
			saveNewsObjective(data).then((res) => {
				dispatch(hideLoader());
				if (res.data) {
					dispatch(showLoader());
					try {
						let taxonomyData = {};
						taxonomyData.newsIds = newsIds;
						taxonomyData.newsObjectiveTaxonomy = []
						taxonomyIds.forEach(tax => {
							taxonomyData.newsObjectiveTaxonomy.push({
								"taxonomyId": tax,
								"objectiveId": selectedObjective.id
							})
						});
						const response = saveNewsObjectiveTaxonomy(taxonomyData);
						response.then((response) => {
							dispatch(hideLoader());
							dispatch(actionSuccess("Objective Taxonomy Tagged Successfully"));
							callback(selectedObjective.id);
						}).catch((e) => {
							dispatch(hideLoader());
							dispatch(actionError("Something Went Wrong...!"));
						})
					} catch (e) {
						dispatch(hideLoader());
						dispatch(actionError("Something Went Wrong...!"));
					}
				} else {
					dispatch(showMessage("Please select taxonomy"));
					setSaveTreeData(false);
				}
			}).catch((e) => {
				dispatch(hideLoader());
				dispatch(actionError("Something Went Wrong...!"));
			})
		}).catch((e) => {
			dispatch(hideLoader());
			dispatch(actionError("Something Went Wrong...!"));
		})

	}

	const _saveProductObjective = (selectedObjective, taxonomyIds, callback) => {
		let delData = {
			productIds: productIds
		}
		dispatch(showLoader());
		deleteProductObjective(selectedObjective.id, delData).then((delRes) => {
			let data = {};
			data.productIds = productIds;
			data.productBusinessObjective = [{
				"objectiveId": selectedObjective.id,
				"objectiveTitle": selectedObjective.objectiveTitle
			}];
			saveProductObjective(data).then((res) => {
				dispatch(hideLoader());
				if (res.data) {
					dispatch(showLoader());
					try {
						let taxonomyData = {};
						taxonomyData.productIds = productIds;
						taxonomyData.productObjectiveTaxonomy = []
						taxonomyIds.forEach(tax => {
							taxonomyData.productObjectiveTaxonomy.push({
								"taxonomyId": tax,
								"objectiveId": selectedObjective.id
							})
						});
						const response = saveProductObjectiveTaxonomy(taxonomyData);
						response.then((response) => {
							dispatch(hideLoader());
							dispatch(actionSuccess("Objective Taxonomy Tagged Successfully"));
							callback(selectedObjective.id);
						}).catch((e) => {
							dispatch(hideLoader());
							dispatch(actionError("Something Went Wrong...!"));
						})
					} catch (e) {
						dispatch(hideLoader());
						dispatch(actionError("Something Went Wrong...!"));
					}
				} else {
					dispatch(showMessage("Please select taxonomy"));
					setSaveTreeData(false);
				}
			}).catch((e) => {
				dispatch(hideLoader());
				dispatch(actionError("Something Went Wrong...!"));
			})
		}).catch((e) => {
			dispatch(hideLoader());
			dispatch(actionError("Something Went Wrong...!"));
		})

	}

	const _saveDeliverableObjective = (selectedObjective, taxonomyIds, callback) => {
		let delData = {
			deliverableIds: deliverableIds
		}
		dispatch(showLoader());
		deleteDeliverableObjective(selectedObjective.id, delData).then((delRes) => {
			let data = {};
			data.deliverableIds = deliverableIds;
			data.deliverableBusinessObjective = [{
				"objectiveId": selectedObjective.id,
				"objectiveTitle": selectedObjective.objectiveTitle
			}];
			saveDeliverableObjective(data).then((res) => {
				dispatch(hideLoader());
				if (res.data) {
					dispatch(showLoader());
					try {
						let taxonomyData = {};
						taxonomyData.deliverableIds = deliverableIds;
						taxonomyData.deliverableObjectiveTaxonomy = []
						taxonomyIds.forEach(tax => {
							taxonomyData.deliverableObjectiveTaxonomy.push({
								"taxonomyId": tax,
								"objectiveId": selectedObjective.id
							})
						});
						const response = saveDeliverableObjectiveTaxonomy(taxonomyData);
						response.then((response) => {
							dispatch(hideLoader());
							dispatch(actionSuccess("Objective Taxonomy Tagged Successfully"));
							callback(selectedObjective.id);
						}).catch((e) => {
							dispatch(hideLoader());
							dispatch(actionError("Something Went Wrong...!"));
						})
					} catch (e) {
						dispatch(hideLoader());
						dispatch(actionError("Something Went Wrong...!"));
					}
				} else {
					dispatch(showMessage("Please select taxonomy"));
					setSaveTreeData(false);
				}
			}).catch((e) => {
				dispatch(hideLoader());
				dispatch(actionError("Something Went Wrong...!"));
			})
		}).catch((e) => {
			dispatch(hideLoader());
			dispatch(actionError("Something Went Wrong...!"));
		})

	}

	const handleDelete = ({ id, objectiveTitle }, callback) => {
		if (id && companyIds) {
			if (entityName === 'company') {
				_deleteCompanyObjective(id, callback);
			}
		}
		if (id && newsIds) {
			if (entityName === 'news') {
				_deleteNewsObjective(id, callback);
			}
		}
		if (id && videoIds) {
			if (entityName === 'video') {
				_deleteVideoObjective(id, callback);
			}
		}
		if (id && productIds) {
			if (entityName === 'product') {
				_deleteProductObjective(id, callback);
			}
		}
		if (id && deliverableIds) {
			if (entityName === 'deliverable') {
				_deleteDeliverableObjective(id, callback);
			}
		}
	}

	const _deleteCompanyObjective = (id, callback) => {
		let data = {
			companyIds: companyIds
		}
		dispatch(showLoader());
		const response = deleteCompanyObjective(id, data);
		response.then((res) => {
			dispatch(hideLoader());
			dispatch(actionSuccess("Objective Taxonomy Deleted Successfully"));
			callback(id);
		}).catch((e) => {
			dispatch(hideLoader());
			dispatch(actionError("Something Went Wrong...!"));
		})
	}

	const _deleteVideoObjective = (id, callback) => {
		let data = {
			videoIds: videoIds
		}
		dispatch(showLoader());
		const response = deleteVideoObjective(id, data);
		response.then((res) => {
			dispatch(hideLoader());
			dispatch(actionSuccess("Objective Taxonomy Deleted Successfully"));
			callback(id);
		}).catch((e) => {
			dispatch(hideLoader());
			dispatch(actionError("Something Went Wrong...!"));
		})
	}

	const _deleteNewsObjective = (id, callback) => {
		let data = {
			newsIds: newsIds
		}
		dispatch(showLoader());
		const response = deleteNewsObjective(id, data);
		response.then((res) => {
			dispatch(hideLoader());
			dispatch(actionSuccess("Objective Taxonomy Deleted Successfully"));
			callback(id);
		}).catch((e) => {
			dispatch(hideLoader());
			dispatch(actionError("Something Went Wrong...!"));
		})
	}

	const _deleteProductObjective = (id, callback) => {
		let data = {
			productIds: productIds
		}
		dispatch(showLoader());
		const response = deleteProductObjective(id, data);
		response.then((res) => {
			dispatch(hideLoader());
			dispatch(actionSuccess("Objective Taxonomy Deleted Successfully"));
			callback(id);
		}).catch((e) => {
			dispatch(hideLoader());
			dispatch(actionError("Something Went Wrong...!"));
		})
	}

	const _deleteDeliverableObjective = (id, callback) => {
		let data = {
			deliverableIds: deliverableIds
		}
		dispatch(showLoader());
		const response = deleteDeliverableObjective(id, data);
		response.then((res) => {
			dispatch(hideLoader());
			dispatch(actionSuccess("Objective Taxonomy Deleted Successfully"));
			callback(id);
		}).catch((e) => {
			dispatch(hideLoader());
			dispatch(actionError("Something Went Wrong...!"));
		})
	}

	const fetchTaggingBridge = (objectiveId, params = {}) => {
		if (entityName === 'company') {
			return fetchCompanyObjectiveTaxonomy(objectiveId, params);
		}
		if (entityName === 'news') {
			return fetchNewsObjectiveTaxonomy(objectiveId, params);
		}
		if (entityName === 'video') {
			return fetchVideoObjectiveTaxonomy(objectiveId, params);
		}
		if (entityName === 'product') {
			return fetchProductObjectiveTaxonomy(objectiveId, params);
		}
		if (entityName === 'deliverable') {
			return fetchDeliverableObjectiveTaxonomy(objectiveId, params);
		}
	}

	return (
		<>

			<div className="gennx-content-wrapper content-wrapper p-4" style={{ overflowY: "scroll" }}>
				<TreeView
					handleSave={handleSave}
					handleDelete={handleDelete}
					contentIds={contentIds}
					saveTreeData={saveTreeData}
					fetchContentTagging={fetchTaggingBridge}
					loadOtherTagInfo={props.loadOtherTagInfo}
				/>
			</div>
			<div className="form-submit-box">
				<div className="container-fluid">
					<div className="row">

						<div className="float-right col-md-12 text-right">
							<button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3"
								onClick={() => history.goBack()}
							>Cancel</button>
							<button type="button" className="btn btn-primary cy-btn pl-3 pr-3 mr-2"
								onClick={() => setSaveTreeData(true)}
							>Save</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
};

export default TaxomonyUI;