import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ButtonGroup, DropdownButton, Dropdown } from "react-bootstrap";
import { qcProductComplete, qcProductReject, qcProductRejectReallocation, qcGetProductRemark } from "../../../../../middleware/services/cmsApi";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { accessRightActionCheck, getLoggedInUser } from "../../../../../utilities";
import RejectRemarkModal from "../../rejectRemark";
import RemarkUI from "./remarkModal";
import DataPublicUI from "../../../dataPublishing/product/actionButton";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

// Qc actions component
const ProductQcActions = (props) => {
  const loggedInUser = getLoggedInUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [rejectRemark, setRejectRemark] = useState(false);
  const [isCompleteReject, setCompleteReject] = useState(false);
  const [remarkCount, setRemarkCount] = useState([]);
  const [clickSaveSubmit, setClickSaveSubmit] = useState(false);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessPermissionRemark = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.REMARKS);
  const accessPermissionQCComplete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.QC_COMPLETE);
  const accessPermissionReject = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.REJECT);
  const accessPermissionDataPublishing = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.PUBLISH);
  const accessActionSubmit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SUBMIT);

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Basic";
    let actionAccess = accessRightActionCheck("Quality Check", interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  useEffect(() => {
    if (clickSaveSubmit) {
      props.handleSubmit();
      setClickSaveSubmit(false);
    }
  }, [clickSaveSubmit]);
  //reject a record
  const handleReject = (data) => {
    const payload = {
      userId: loggedInUser._id,
      rejectRemark: data.rejectRemark,
    };
    dispatch(
      showAlertBox({
        okCallback: () => {
          qcProductReject(props.productId, payload)
            .then((response) => {
              dispatch(actionSuccess("Qc record rejected completly successfully"));
              history.push("/data-qc/company");
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Something went wrong"));
            });
        },
      })
    );
  };

  const handleRejectAndReallocate = (data) => {
    const payload = {
      userId: loggedInUser._id,
      rejectRemark: data.rejectRemark,
    };
    dispatch(
      showAlertBox({
        okCallback: () => {
          qcProductRejectReallocation(props.productId, payload)
            .then((response) => {
              if (response) {
                dispatch(actionSuccess("Qc record rejected and reallocated successfully"));
                history.push("/data-qc/company");
              } else {
                dispatch(actionError("Something went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Something went wrong"));
            });
        },
      })
    );
  };

  // fetch previous added remark
  const fetcQcRemarkSpecificToProduct = () => {
    qcGetProductRemark(props.productId).then((response) => {
      setRemarkCount(response.data.data);
    });
  };

  useEffect(() => {
    fetcQcRemarkSpecificToProduct();
  }, []);

  return (
    <>
      <div className="form-submit-box" style={{ overflow: "inherit" }}>
        <div className="container-fluid nopadding">
          <div className="row">
            <div className="float-LEFT col-md-1 text-left">
              {props.selectedIndex > -1 ? (
                <span className="pl-4" style={{ marginLeft: "-3em" }}>
                  <span className="span-link">
                    <button
                      type="button"
                      className="btn btn-outline-dark cy-btn pl-4 pr-4 mt-1 mb-1"
                      onClick={() => {
                        props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                      }}
                    >
                      Next
                    </button>
                  </span>
                </span>
              ) : null}
            </div>
            {/*add remark */}
            <div className="float-right col-md-11 text-right">
              <Link to={`/data-qc/company/${props.companyId}/edit#product`} className="ml-2">
                <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                  Cancel
                </button>
              </Link>
              {accessPermissionRemark ? (
                <button
                  type="button"
                  className="btn btn-outline-danger cy-btn pl-4 pr-4 mr-3"
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                >
                  Remarks{remarkCount.length ? `(${remarkCount.length})` : null}
                </button>
              ) : null}

              {/*Qc complete */}
              {accessPermissionQCComplete ? (
                <button
                  type="button"
                  className="btn btn-outline-danger cy-btn pl-4 pr-4 mr-3"
                  onClick={() => {
                    let payload = {
                      userId: loggedInUser._id,
                    };
                    dispatch(
                      showAlertBox({
                        okCallback: () => {
                          qcProductComplete(props.productId, payload)
                            .then((response) => {
                              dispatch(actionSuccess("Qc completed successfully"));
                              history.push("/data-qc/company");
                            })
                            .catch((err) => {
                              dispatch(actionError(err.data.message || "Something went wrong"));
                            });
                        },
                      })
                    );
                  }}
                >
                  Qc Complete
                </button>
              ) : null}

              {/*Qc reject  complete*/}
              {accessPermissionReject ? (
                <ButtonGroup className="mr-3">
                  <DropdownButton title="Reject" id="bg-nested-dropdown">
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => {
                        setRejectRemark(!rejectRemark);
                        setCompleteReject(true);
                      }}
                    >
                      Reject Completely
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonGroup>
              ) : null}

              {accessActionSubmit ? (
                <button
                  type="button"
                  className="btn btn-primary cy-btn pl-2 pr-2 mt-1 mb-1"
                  onClick={() => {
                    if (clickSaveSubmit) setClickSaveSubmit(false);
                    setClickSaveSubmit(true);
                  }}
                >
                  Submit
                </button>
              ) : null}
              {accessPermissionDataPublishing ? <DataPublicUI productId={props.productId} {...history} /> : null}
            </div>
          </div>
        </div>
      </div>
      <RemarkUI
        productId={props.productId}
        userId={loggedInUser._id}
        showModal={showModal}
        remarks={remarkCount}
        clicked={() => {
          setShowModal(!showModal);
          fetcQcRemarkSpecificToProduct();
        }}
      />

      <RejectRemarkModal
        showModal={rejectRemark}
        clicked={(data) => {
          if (isCompleteReject && data) {
            handleReject(data);
          }
          if (!isCompleteReject && data) {
            handleRejectAndReallocate(data);
          }
          setRejectRemark(!rejectRemark);
        }}
      />
    </>
  );
};

export default ProductQcActions;
