/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Application Reducers (initial, utility, auth, breadCrumb, sideNav, alertBox)
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { combineReducers } from "redux";
import initialState from "./initialState";
import utilityState from "./utilityState";
import authState from "./authState";
import deliverableState from "./deliverableState";
import companyState from "./companyState";
import newsState from "./newsState";
import breadCrumbState from "./breadCrumbState";
import sideNavState from "./sideNavState";
import alertBoxState from "./alertBoxState";
import popupState from "./popupState";
import pagesState from "../ducks/pages.duck";
import gridState from "./gridState";
import contentBlockState from "./contentBlockState";
import treeState from "./treeState";
import taxonomyFilterState from "./taxonomyFilterState";
import productState from "./productState";
import taxonomyTaggingState from "./taxonomyTaggingState";
import businessObjectiveState from "./businessObjectiveState";
import surveyState from "./surveyState";
import videoState from "./videoState";
import searchState from "./searchState";
import myBriefcaseState from "./myBriefcaseState";
import customizableWidgetState from "./customizableWidgetState";

export default combineReducers({
  initialState,
  utilityState,
  authState,
  businessObjectiveState,
  companyState,
  deliverableState,
  newsState,
  breadCrumbState,
  sideNavState,
  alertBoxState,
  popupState,
  pagesState,
  gridState,
  contentBlockState,
  surveyState,
  treeState,
  taxonomyFilterState,
  productState,
  taxonomyTaggingState,
  videoState,
  searchState,
  myBriefcaseState,
  customizableWidgetState,
});
