import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Modal, ModalFooter } from "react-bootstrap";
import { actionSuccess, actionError, hideLoader } from "../../../../middleware/actions/utilityAction";
import { fetchTaxonomyTechnology, fetchTaxonomyTrend, ScreeningSaveActions } from "../../../../middleware/services/cmsApi";
import { getLoggedInUser } from "../../../../utilities";
import "./style.scss";

const ActionModal = (props) => {
  const { companyId, trendIds = [], dtIds = [], allocatedTrendIds = [], allocatedDtIds = [], rejectedDtIds = [] } = props.screeningModalValues;
  const [technology, setTechnology] = useState([]);
  const [oldTechnology, setTechnologyOld] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [oldTrendData, setOldTrendData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const dts = props.dts ? true : false;
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(props.dts ? [...trendIds] : [...dtIds]);
  const tech = props.dts ? "Trend" : "Technology";
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const loggedInUser = getLoggedInUser();
  const userId = loggedInUser._id;

  const handleSubmit = (data) => {
    if (checked.length) {
      const payload = {
        userId: userId,
      };
      if (props.dts) {
        if (allocatedTrendIds.length) {
          payload.trendId = [...checked, ...allocatedTrendIds];
        } else {
          payload.trendId = [...checked];
        }
      } else {
        if (allocatedDtIds.length) {
          payload.dtId = [...checked, ...allocatedDtIds];
        } else {
          payload.dtId = [...checked];
        }
      }
      ScreeningSaveActions(companyId, props.actionType, payload)
        .then((response) => {
          dispatch(actionSuccess(`Screening action ${props.actionType} done successfully`));
          props.saved(new Date().getTime());
          setIsOpen(false);
        })
        .catch((err) => {
          dispatch(actionError(err.data.message || "Something went wrong"));
        });
    } else {
      dispatch(actionError(errorMsg));
    }
  };
  const fetchData = async () => {
    if (dts) {
      await fetchTaxonomyTrend({ filters: [["trendRoot", "eq", 0]], fields: ["trendName"], sort: "trendName:asc" }).then((res) => {
        if (res.status === 200) {
          let { data = [] } = res.data;
          setIndustry(data);
        }
      });
      let filters = [];
      if (props.actionType === "hold" || props.actionType === "reject") {
        if (trendIds.length) {
          filters = [["isTrendNode", "eq", 1], "and", ["_id", "in", trendIds]];
        } else {
          dispatch(actionError(`Trend is not available for ${props.actionType}`));
          setIsOpen(false);
        }
      } else {
        filters = [["isTrendNode", "eq", 1]];
      }

      await fetchTaxonomyTrend({ filters: filters, fields: ["trendId", "trendName", "trendRoot"], sort: "trendName:asc", limit: 2000 })
        .then((res) => {
          if (res.status === 200) {
            let { data = [] } = res.data;
            setTrendData(data);
            setOldTrendData(data);
            dispatch(hideLoader());
          } else {
            console.log("err fetchTrend :: ");
          }
        })
        .catch((err) => {
          console.log("err fetchTrend :: ", err);
        });
    } else {
      let filters = [];
      if (props.actionType === "hold" || props.actionType === "reject") {
        if (dtIds.length) {
          filters = [["dtParentId", "eq", 0], "and", ["_id", "in", dtIds]];
        } else {
          dispatch(actionError(`Technology is not available for ${props.actionType}`));
          setIsOpen(false);
        }
      } else {
        filters = [["dtParentId", "eq", 0], "and", ["_id", "nin", rejectedDtIds]];
      }
      // dispatch(showLoader());
      await fetchTaxonomyTechnology({ filters: filters, fields: ["dtId", "dtName"], sort: "dtName:asc", limit: 2000 })
        .then((res) => {
          if (res.status === 200) {
            let { data = [] } = res.data;
            setTechnology(data);
            setTechnologyOld(data);
            dispatch(hideLoader());
          } else {
            console.log("err fetchTechnology :: ");
          }
        })
        .catch((err) => {
          console.log("err fetchTechnology :: ", err);
        });
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    props.isOpen ? setIsOpen(true) : setIsOpen(false);
    if (props.actionType === "select") {
      setErrorMsg(`Please select one or more ${tech}.`);
    } else if (props.actionType === "hold") {
      setErrorMsg(`Please select one or more ${tech} for hold.`);
    } else {
      setErrorMsg(`Please select one or more ${tech} for reject.`);
    }

    if (props.isOpen) fetchData();
  }, []);

  const filterList = (e) => {
    if (dts) {
      const updatedList = trendData.filter((item) => {
        return item.trendName.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
      });
      e.target.value.length ? setTrendData(updatedList) : setTrendData(oldTrendData);
    } else {
      const updatedList = technology.filter((item) => {
        return item.dtName.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
      });
      e.target.value.length ? setTechnology(updatedList) : setTechnology(oldTechnology);
    }
  };
  const TechDiv = () => {
    return technology.length
      ? technology.map(function (value) {
          let defaultChecked = false;
          if (props.actionType === "select") {
            defaultChecked =
              allocatedDtIds.length && allocatedDtIds.includes(value.id)
                ? true
                : checked.indexOf(value.id) != -1 || (dtIds.length && dtIds.includes(value.id))
                ? true
                : false;
          } else {
            defaultChecked =
              allocatedDtIds.length && allocatedDtIds.includes(value.id)
                ? true
                : checked.indexOf(value.id) != -1 && dtIds.length && dtIds.includes(value.id)
                ? true
                : false;
          }

          return (
            <li
              className={`border-0 list-group-item col-4 border-top py-1 ${
                allocatedDtIds.length && allocatedDtIds.includes(value.id) ? "disabled" : ""
              }`}
              key={value.dtName}
            >
              <input
                type="checkbox"
                className="mr-1"
                id={value.id}
                name={value.dtName}
                value={value.id}
                defaultChecked={defaultChecked}
                key={value.id}
                onChange={(e) => {
                  handleToggle(value.id);
                }}
              />
              {value.dtName}
              <br />
              {allocatedDtIds.length && allocatedDtIds.includes(value.id) ? (
                <span
                  style={{ fontSize: ".7rem" }}
                  className={`p-1 ${props.actionType === "select" ? "text-success" : props.actionType === "reject" ? "text-danger" : "text-primary"}`}
                >
                  (already {props.actionType === "reject" ? "rejected" : props.actionType === "select" ? "selected" : "hold"})
                </span>
              ) : (
                ""
              )}
            </li>
          );
        })
      : "";
  };

  const trendDiv = () => {
    return (
      industry.length > 0 &&
      industry.map((d) => {
        const filteredTrendData =
          trendData.length > 0 &&
          trendData.filter((v) => {
            return v.trendRoot.toString() === d.id.toString();
          });
        let t = filteredTrendData.length
          ? filteredTrendData.map(function (value) {
              return (
                <li
                  className={`border-0 list-group-item border-top py-1 ${
                    allocatedTrendIds.length && allocatedTrendIds.includes(value.id) ? " disabled" : ""
                  }`}
                  key={value.trendName}
                >
                  <input
                    type="checkbox"
                    className="mr-1"
                    id={value.id}
                    name={value.trendName}
                    value={value.id}
                    defaultChecked={trendIds.length && trendIds.includes(value.id) ? true : allocatedTrendIds.includes(value.id) ? true : false}
                    key={value.id}
                    onChange={() => {
                      handleToggle(value.id);
                    }}
                  />
                  {value.trendName}
                  <br />
                  {allocatedTrendIds.length && allocatedTrendIds.includes(value.id) ? (
                    <span style={{ fontSize: ".7rem" }} className="p-1 text-success">
                      (already selected)
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              );
            })
          : "";
        return (
          <>
            <h6>{d.trendName}</h6>
            <hr />
            {t}
            <br />
          </>
        );
      })
    );
  };

  return (
    <Modal
      size="lg"
      show={isOpen && (trendData.length || technology.length) ? true : false}
      onHide={handleClose}
      scrollable={true}
      // dialogClassName="modal-90h"
      centered
    >
      <Modal.Header closeButton className="bg-none text-dark" style={{ zIndex: "9" }}>
        <Modal.Title>
          {props.actionType === "select" ? "Select for Processing " : props.actionType === "reject" ? "Reject from Screening" : "Hold for Screening"}{" "}
          {`(${tech})`}{" "}
        </Modal.Title>
      </Modal.Header>
      <div className="mt-2 ml-4 mr-4 border-bottom border-secondary">
        <label>
          <span className="font-weight-bold text-danger">Note:</span> {errorMsg}
        </label>
        <div className="form-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <input className="form-control col-md-6" type="text" placeholder="Search..." onChange={filterList} />
        </div>
      </div>

      <Modal.Body style={{ height: `calc(100vh - 126px)` }}>
        <div className="col-12 container">
          <ul className={`list-unstyled ${industry.length > 0 ? "" : "row"}`}>
            {trendDiv()}
            <TechDiv />
          </ul>{" "}
        </div>
      </Modal.Body>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-outline-dark cy-btn"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Cancel
        </button>
        <button type="button" className="btn btn-primary cy-btn" onClick={handleSubmit}>
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ActionModal;
ActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  actionType: PropTypes.string,
  dts: PropTypes.number.isRequired,
  screeningModalValues: PropTypes.object.isRequired,
  saved: PropTypes.func.isRequired,
};
