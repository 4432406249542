import React, { useState, useEffect } from "react";
import { dataPublishingCompany }
    from "../../../../middleware/services/cmsApi";
import { fetchSingleCompany } from "../../../../middleware/services/companyApi";
import {
    actionStart, actionSuccess, actionError, showMessage,
} from "../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { getProductVariant } from "../../../../utilities";

const DataPublicUI = (props) => {
    const dispatch = useDispatch();
    const companyId = props.companyId;
    const [showModal, setShowModal] = useState(false);

    const handlePublish = (withProduct) => {
        const payload = {
            "publishProductToo": withProduct.toString(),
            "platform": getProductVariant()
        }
        setShowModal(!showModal)
        dataPublishingCompany(companyId, payload).then(response => {
            dispatch(actionSuccess("Company published successfully"));
        }).catch(err => {
            dispatch(actionError(err.data.message || "Something went wrong"));
        })
    }
    return (<>
        <button type="button"
            className="btn btn-primary cy-btn pl-4 pr-4 ml-3 mt-1 mb-1"
            onClick={async () => {
                if (props?.location?.pathname?.includes("/data-qc/")) {
                    const params = {};
                    params.fields = ["companyInQueue"]
                    const response = await fetchSingleCompany(companyId, params);
                    // console.log(response.data.data);
                    const data = response.data?.data;
                    if (data.companyInQueue.queueStatus == "QC_ACCEPTED") {
                        setShowModal(!showModal);
                    } else {
                        dispatch(actionError("Company must be QC complete before publishing"));
                    }
                } else {
                    setShowModal(!showModal);
                }
            }}
        >
            Publish
        </button>
        <Modal
            show={showModal}
            backdrop="static"
            className="modal fade lg"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <div className="modal-header bg-secondary text-white">
                <h5 className="modal-title" id="exampleModalLabel">Publish Company</h5>
                <button type="button" className="close" aria-label="Close" onClick={() => {
                    setShowModal(!showModal);
                }}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> */}
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 mb-3" style={{marginLeft: "24px !important"}}>
                        <b>Notes:</b><br />
                        - Fields required to Publish the Company .<br />
                        - Company Name can not be blank.<br />
                        - Company Country (Atleast one address).<br />
                        - Company description OR Company What's unique/tagline can not be blank. <br />
                        - Company Taxonomy can not be blank.<br />
                        Are you sure, you want to Publish the Company?
                    </div>
                </div>

            </Modal.Body>
            <div className="modal-footer">
                <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModal(!showModal)}>Cancel</button>
                <button type="button" className="btn btn-primary " onClick={() => { handlePublish(0) }}>Publish Only Company</button>
                <button type="button" className="btn btn-primary " onClick={() => { handlePublish(1) }}>Publish Company With Product</button>
            </div>
        </Modal>
    </>)
}

export default DataPublicUI;
