/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Helpdesk api - GET, POST, PUT, DELETE call
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 12/12/2023
	Author						: Prashant Wankhade
================================================================ 
*/
import { HELPDESK_API as API } from "../../config/apiConfig";
import { HELPDESK_URL } from "../../config";

/** Fetch helpdesk all categories */
export const fetchCategories = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/helpdesk/categories`,
    method: "GET",
    params: params,
  });
};

export const fetchCategoriesFromJitBit = (applicationName, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/helpdesk/category/${applicationName}`,
    method: "GET",
    params: params,
  });
};

export const saveCategory = (payload) => {
  return API({
    url: `/helpdesk/category`,
    method: "POST",
    data: payload,
  });
};

export const updateCategory = (payload, categoryId) => {
  return API({
    url: `/helpdesk/category/${categoryId}`,
    method: "PUT",
    data: payload,
  });
};

export const deleteCategory = (categoryId) => {
  return API({
    url: `/helpdesk/category/${categoryId}`,
    method: "DELETE",
  });
};

export const deleteManyCategory = (payload, params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/helpdesk/category",
    method: "DELETE",
    params: params,
    data: payload,
  });
};
