/**
 * Author Mr. Narenkumar Krishnan
 * Date  14/06/24
 * component to render all widgets as child
 */

//Importing required modules and components
import React, { useEffect, useRef, useState } from "react";
import AddCircle from "assets/images/add-circle.svg?react";
import "./index.css";
const WidgetBox = ({ handleAddClick, text }) => {
  // Start to use whenever component gets data
  // const [widgetName, setWidgetName] = useState('');
  return (
    <div className="add-widget-container">
      <div className="add-icon">
        <AddCircle
          height={"35px"}
          width={"35px"}
          cursor={"pointer"}
          onClick={async () => {
            handleAddClick();
          }}
          data-tip="Add Widget"
        />
      </div>
      <div className="add-text">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default WidgetBox;
