/**
 * @fileoverview This file contains common theme.
 * @date 03/Mar/2023
 * @author Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
 */

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#F94F5E' },
  },
  typography: {
    fontFamily: 'CircularTT-Book !important',
    fontWeightBold: 700,
    fontWeightRegular: 400,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          textTransform: 'none',
          borderRadius: '4px',
          backgroundColor: '#F94F5E',
        },
        contained: {
          color: 'white',
          textTransform: 'none',
          borderRadius: '4px',
          background: '#F94F5E',
          backgroundColor: '#F94F5E',
          boxShadow: 'none',
          '&.Mui-disabled': {
            backgroundColor: '#F94F5E66',
            color: 'white',
          },
          '&:hover': {
            backgroundColor: '#ed5361',
            color: 'white',
          },
          '&::before': {
            backgroundColor: '#F94F5E',
            color: 'white',
          },
        },
        outlined: {
          backgroundColor: 'white',
          color: '#F94F5E',
          borderColor: '#F94F5E',
          textTransform: 'none',
          borderRadius: '4px',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            textAlign: 'right',
          },
        },
      },
    },
  },
});

export { theme };
