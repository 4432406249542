/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Trend taxonomy Tree Component
     Require Props :
        handleSave : Save Tree Data
        loadOtherTagInfo : Switch between trend and tree tagging
        saveTreeData : Save Technology taxonomy data
        contentId : Content Id [news id | company id]
        fetchContentData : Fetch content taxonomy
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 08/June/2022 
    Author						: Lawrence Anthony
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTaxonomyTrend,
  getObjectiveSgfTag,
  fetchTaxonomyTechnology,
  fetchTaxonomyValueChain,
  fetchTaxonomyMedia,
} from "../../../../middleware/services/cmsApi";
import { fetchKias } from "../../../../middleware/services/kiaApi";
import { showLoader, hideLoader, actionError } from "../../../../middleware/actions/utilityAction";
import { PRODUCT_VARIANT } from "../../../../constants";
import { getProductVariant } from "../../../../utilities";
import { fetchObjectiveTaxonomy } from "../../../../middleware/services/businessObjectiveApi";
import SortableTree from "../sortableTree";

const Taxonomy = (props) => {
  const dispatch = useDispatch();
  const [taxonomy, setTaxonomy] = useState([]);
  const [checkedTaxonomy, setCheckedTaxonomy] = useState(props.storeCheckedId);
  const productVariant = getProductVariant();
  const taxonomyFilterRedux = useSelector((state) => state.taxonomyFilterState);
  /*
        @Description : Fetch all SGF taxonomy 
        id: trend id
    */
  const fetchSgfTagging = async (trendId) => {
    dispatch(showLoader());
    try {
      const response = getObjectiveSgfTag({
        filters: [["websiteIndustry.industryId", "eq", trendId]],
        limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
      });
      response
        .then(async (response) => {
          if (response.status == 200) {
            let trends = [];
            // if (props.moduleName != "Video") {
            const resp = fetchTaxonomyTrend({
              fields: ["id", "trendName", "trendParentId", "trendParentName", "trendLevel", "trendSubRoot"],
              filters: [["trendRoot", "eq", trendId]],
              limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
            });
            await resp.then((result) => {
              trends = result.data.data;
            });
            // } else {
            //   trends = [];
            // }
            if (response.data.data.length) {
              const treeJosnArray = buildTreeJson(response.data.data, trends);
              setTaxonomy(treeJosnArray);
            } else {
              const treeJosnArray = "No taxonomy available for trend";
              setTaxonomy(treeJosnArray);
            }

            dispatch(hideLoader());
          } else {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
        @Description : Build sgf tree data
        data : sgf taxonomy data  
        trends : trend taxonomy data
        parentId : trend id
    */
  const buildTreeJson = (sgfData, trends) => {
    let result = [];
    sgfData.map((sgf) => {
      dispatch(showLoader());
      let treeObject = {};
      treeObject["id"] = sgf.id;
      treeObject["label"] = sgf.sgfName;
      treeObject["value"] = sgf.id;
      treeObject["level"] = 2;

      if (props.enableBulkTaxonomy ? props.checked?.includes(sgf.id) : taxonomyFilterRedux.selectedTrendTaxonomy?.includes(sgf.id)) {
        treeObject["isChecked"] = true;
      }
      if (sgf?.trend?.length > 0) {
        let tempArr = [];
        sgf.trend.map((sgfTrend) => {
          let trendd = `${sgf.id}-${sgfTrend.trendId}`;
          let newTrends = JSON.parse(JSON.stringify(trends));
          let industryTrends = JSON.parse(
            JSON.stringify(
              newTrends
                .filter((t) => t.trendSubRoot == sgfTrend.trendId)
                .map((v) => {
                  v.id = `${sgf.id}-${v.id}`;
                  v.trendParentId = `${sgf.id}-${v.trendParentId}`;
                  return v;
                })
            )
          );

          let trendObj = {};
          trendObj["id"] = trendd;
          trendObj["label"] = sgfTrend.trendName;
          trendObj["level"] = 3;
          trendObj["parentId"] = sgf.id;

          if (
            props.enableBulkTaxonomy
              ? props.checked?.includes(trendd.split("-")[1])
              : taxonomyFilterRedux.selectedTrendTaxonomy?.includes(trendd.split("-")[1])
          ) {
            trendObj["isChecked"] = true;
          }
          // if it's not video module, show child nods as well
          // if (props.moduleName != "Video") {
          const children = buildTrendTaxonomyJson(industryTrends, trendd);
          if (children.length > 0) {
            trendObj["children"] = children;
          }
          // }
          tempArr.push(trendObj);
        });
        treeObject.children = tempArr;
      }
      result.push(treeObject);
    });
    dispatch(hideLoader());
    return result;
  };
  /*
       @Description : Build trend taxonomy tree data
       data :trend taxonomy data
       parentId : trend id
   */
  const buildTrendTaxonomyJson = (data, parentId) => {
    dispatch(showLoader());
    const result = [];
    data.forEach((element, k) => {
      element["id"] = `${element.id}`;
      element["label"] = element.trendName;
      element["level"] = parseInt(element.trendLevel) + 2;
      element["parentId"] = element.trendParentId;
      if (
        props.enableBulkTaxonomy
          ? props.checked?.includes(element.id.split("-")[1])
          : taxonomyFilterRedux.selectedTrendTaxonomy?.includes(element.id.split("-")[1])
      ) {
        element["isChecked"] = true;
      }
      if (element["trendParentId"] == parentId) {
        const children = buildTrendTaxonomyJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    dispatch(hideLoader());
    return result;
  };
  /*
        @Description : Fetch all Technology taxonomy 
        id: technology id
    */
  const fetchTechnology = (id) => {
    dispatch(showLoader());
    try {
      const response = fetchTaxonomyTechnology({
        fields: ["id", "dtName", "dtParentId", "dtParentName", "childrens", "dtChildrens", "dtRoot", "dtLevel"],
        filters: [["dtRoot", "eq", id]],
        limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
      });
      response
        .then((res) => {
          if (res.status == 200) {
            if (res.data.data.length) {
              const treeJosnArray = buildTechnologyJson(res.data.data, id);
              setTaxonomy(treeJosnArray);
            } else {
              const treeJosnArray = "No taxonomy available for technology";
              setTaxonomy(treeJosnArray);
            }

            dispatch(hideLoader());
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
        @Description : Build tree data
        data : technology taxonomy data
        parentId : technology id
    */
  const buildTechnologyJson = (data, parentId) => {
    dispatch(showLoader());
    const result = [];
    data.forEach((element) => {
      element["id"] = element.id;
      element["value"] = element.id;
      element["label"] = element.dtName;
      element["level"] = element.dtLevel;
      element["parentId"] = element.dtParentId;
      if (props.enableBulkTaxonomy ? props.checked?.includes(element.id) : taxonomyFilterRedux.selectedTechnology?.includes(element.id)) {
        element["isChecked"] = true;
      }
      if (element["dtParentId"] == parentId) {
        const children = buildTechnologyJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    dispatch(hideLoader());
    return result;
  };

  /*
       @Description : Fetch all Kia taxonomy 
       id: Kia id
   */
  const fetchKiaTagging = (id) => {
    dispatch(showLoader());
    try {
      const response = fetchKias({
        fields: ["kiaName", "kiaDescription"],
        filters: [["kiaIndustryTag.industryId", "eq", id]],
        limit: 1000,
      });
      response
        .then((res) => {
          if (res.status == 200) {
            if (res.data.data.length) {
              const treeJosnArray = buildKiaJson(res.data.data);
              setTaxonomy(treeJosnArray);
            } else {
              const treeJosnArray = "No taxonomy available for key impact area";
              setTaxonomy(treeJosnArray);
            }

            dispatch(hideLoader());
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
      @Description : Build tree data
      data : Kia taxonomy data
  */
  const buildKiaJson = (value) => {
    dispatch(showLoader());
    let result = [];
    value.filter((res, k) => {
      let taxonomyTeeData = {};
      taxonomyTeeData["id"] = res.id;
      taxonomyTeeData["label"] = res.kiaName;
      taxonomyTeeData["value"] = res.id;
      // taxonomyTeeData["children"] = []
      if (props.enableBulkTaxonomy ? props.checked?.includes(res.id) : taxonomyFilterRedux.selectedKia?.includes(res.id)) {
        taxonomyTeeData["isChecked"] = true;
      }
      result.push(taxonomyTeeData);
    });
    dispatch(hideLoader());
    return result;
  };

  /*
       @Description : Fetch all Value chain taxonomy 
       id: value chain id
   */
  const fetchValueChainTagging = (indusrtyId) => {
    dispatch(showLoader());
    try {
      const response = fetchTaxonomyValueChain({
        filters: [["industryId", "eq", indusrtyId]],
        limit: 1000,
      });
      response
        .then((res) => {
          if (res.status == 200) {
            if (res.data.data.length) {
              const treeJosnArray = buildValueChainJson(res.data.data);
              setTaxonomy(treeJosnArray);
            } else {
              const treeJosnArray = "No taxonomy available for value chain";
              setTaxonomy(treeJosnArray);
            }
            dispatch(hideLoader());
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
        @Description : Build tree data
        data : Value chain taxonomy data
    */
  const buildValueChainJson = (value) => {
    dispatch(showLoader());
    let result = [];
    value.filter((res, k) => {
      let taxonomyTeeData = {};
      taxonomyTeeData["id"] = res.id;
      taxonomyTeeData["label"] = res.valueChainName;
      taxonomyTeeData["value"] = res.id;
      taxonomyTeeData["children"] = [];
      taxonomyTeeData["level"] = 2;

      if (props.enableBulkTaxonomy ? props.checked?.includes(res.id) : taxonomyFilterRedux.selectedValueChain?.includes(res.id)) {
        taxonomyTeeData["isChecked"] = true;
      }
      let arry = [];
      if (res.transformingTechnologies.length > 0) {
        res.transformingTechnologies.map((v) => {
          let trendObj = {};
          trendObj["id"] = v.techId;
          trendObj["label"] = v.techName;
          trendObj["value"] = v.techId;
          trendObj["parentId"] = res.id;
          trendObj["level"] = 3;
          if (props.enableBulkTaxonomy ? props.checked?.includes(v.techId) : taxonomyFilterRedux.selectedValueChain?.includes(v.techId)) {
            trendObj["isChecked"] = true;
          }
          arry.push(trendObj);
          taxonomyTeeData.children.push(trendObj);
        });
        taxonomyTeeData.children = arry;
        result.push(taxonomyTeeData);
      } else {
        result.push(taxonomyTeeData);
      }
    });
    dispatch(hideLoader());
    return result;
  };
  /*
        @Description : Fetch all Media taxonomy 
        id: media id
    */
  const fetchMediaTaxonomy = (taxonomyRoot) => {
    dispatch(showLoader());
    fetchTaxonomyMedia({
      fields: ["id", "taxonomyName", "taxonomyParentId", "taxonomyLevel"],
      filters: [["taxonomyRoot", "eq", taxonomyRoot]],
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
    })
      .then((res) => {
        dispatch(hideLoader());
        let { data = [] } = res.data;
        if (data.length) {
          const treeJosnArray = buildMediaTreeView(data, taxonomyRoot);
          setTaxonomy(treeJosnArray);
        } else {
          const treeJosnArray = "No taxonomy available for media taxonomy";
          setTaxonomy(treeJosnArray);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchTaxonomy :: ", err);
      });
  };
  /*
       @Description : Build tree data
       data : Media taxonomy data
       parentId : taxonomy id
   */
  const buildMediaTreeView = (data, parentId) => {
    dispatch(showLoader());
    const result = [];
    data.forEach((element) => {
      element["id"] = element.id;
      element["value"] = element.id;
      element["label"] = element.taxonomyName;
      element["level"] = element.taxonomyLevel;
      element["parentId"] = element.taxonomyParentId;
      if (props.enableBulkTaxonomy ? props.checked?.includes(element.id) : taxonomyFilterRedux.selectedMediaTaxonomy?.includes(element.id)) {
        element["isChecked"] = true;
      }
      if (element["taxonomyParentId"] == parentId) {
        const children = buildMediaTreeView(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    dispatch(hideLoader());
    return result;
  };
  /*
        @Description : Fetch all Business objective taxonomy 
        id: Business objective id
    */
  const fetchBOTaxonomy = (objectiveId) => {
    dispatch(showLoader());

    fetchObjectiveTaxonomy(objectiveId, {
      filters: [["objectiveId", "eq", objectiveId]],
    })
      .then((res) => {
        dispatch(hideLoader());
        let { data = [] } = res.data;
        if (data.length) {
          const treeJosnArray = buildBOTreeJson(data, 0);
          setTaxonomy(treeJosnArray);
        } else {
          const treeJosnArray = "No taxonomy available for business objective taxonomy";
          setTaxonomy(treeJosnArray);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchTaxonomy :: ", err);
      });
  };
  /*
       @Description : Build tree data
       data : Business objective taxonomy data
       parentId : objective id
   */
  const buildBOTreeJson = (data, parentId) => {
    dispatch(showLoader());
    const result = [];
    data.forEach((element) => {
      let level = element.taxonomyLevel;
      element["id"] = element.id;
      element["value"] = element.id;
      element["label"] = element.taxonomyName;
      element["level"] = element.taxonomyLevel + 2;

      element["parentId"] = element.taxonomyParentId;

      if (props.enableBulkTaxonomy ? props.checked?.includes(element.id) : taxonomyFilterRedux.selectedBOTaxonomy?.includes(element.id)) {
        element["isChecked"] = true;
      }
      if (element["taxonomyParentId"] == parentId) {
        const children = buildBOTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    dispatch(hideLoader());
    return result;
  };

  useEffect(() => {
    let checkedItems = [];
    if (Object.keys(props.checked)?.length > 0) {
      if (
        props.checked?.boTaxonomy?.length > 0 ||
        props.checked?.media?.length > 0 ||
        props.checked?.technology?.length > 0 ||
        props.checked?.kia?.length > 0 ||
        props.checked?.valueChain?.length > 0 ||
        props.checked?.trend?.length > 0
      ) {
        checkedItems = [
          ...props.checked?.technology,
          ...props.checked?.kia,
          ...props.checked?.valueChain,
          ...props.checked?.media,
          ...props.checked?.businessObjective,
          ...props.checked?.boTaxonomy,
          ...props.checked?.trend,
        ];
      } else {
        checkedItems = props.checkedItem;
        // props.checked.map((d) => {
        //     if (d?.id) {
        //         return checkedItems.push(d.id)
        //     } else {
        //         checkedItems = props.checkedItem
        //     }
        // })
      }
      setCheckedTaxonomy(checkedItems);
    } else {
      setCheckedTaxonomy(props.checkedItem);
    }
    setTaxonomy([]);
    if (productVariant == PRODUCT_VARIANT.INSIDER) {
      props.selectedIndex == 0 && fetchSgfTagging(props.contentId);
      props.boTagging && props.selectedIndex == 1 && fetchBOTaxonomy(props.contentId);
    } else if (props.moduleName == "Video" && productVariant == PRODUCT_VARIANT.WHATNEXT) {
      props.selectedIndex == 0 && fetchTechnology(props.contentId);
      props.selectedIndex == 1 && fetchKiaTagging(props.contentId);
      props.selectedIndex == 2 && fetchBOTaxonomy(props.contentId);
    } else if (props.moduleName == "Survey" && productVariant == PRODUCT_VARIANT.WHATNEXT) {
      props.selectedIndex == 0 && fetchKiaTagging(props.contentId);
    } else if (props?.isMediaTaxonomy) {
      props.selectedIndex == 0 && fetchMediaTaxonomy(props.contentId);
      props.selectedIndex == 1 && fetchBOTaxonomy(props.contentId);
    } else {
      props.selectedIndex == 0 && fetchTechnology(props.contentId);
      (props.selectedIndex == 1 || props?.isKiaTaxonomy) && fetchKiaTagging(props.contentId);
      props.selectedIndex == 2 && fetchValueChainTagging(props.contentId);
      props.selectedIndex == 3 && fetchBOTaxonomy(props.contentId);
      // props?.isMediaTaxonomy && fetchMediaTaxonomy(props.contentId)
    }
  }, [props.contentId, props.checked, props.selectedIndex]);

  return (
    <div
      className="row"
      style={{
        marginLeft: "0.5em",
        marginRight: "0.3em",
        paddingRight: "0.7em",
        marginTop: "0.5em",
      }}
    >
      <div className="col-md-12 ">
        <div
          className="row"
          style={{
            justifyContent: "left",
            paddingLeft: "0.4em",
            paddingBottom: "0.5em",
          }}
        ></div>
        <div className="row">
          <div className="col-md-12">
            {typeof taxonomy !== "string" && taxonomy?.length > 0 ? (
              <SortableTree
                filterByTaxonomy={true}
                onClick={(e, args) => {
                  let parentId = e.map((v) => v.dtRoot);
                  props.setSelectedNodeId(parentId);
                  props.handleSubmit(args);
                  props.handleTaxonomyTagging(args);
                }}
                treeData={taxonomy}
                // checked={checkedTaxonomy}
                // parentId={parentId}
                parentId={"parentId"}
                allowTextWrap={true}
                cssClass={props.cssClass}
                hieghtClass="filter-taxonomy-tree-container-wrapper"
                customSearch={props.customSearch}
              />
            ) : (
              <div style={{ textAlign: "center" }}>{taxonomy}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Taxonomy;
