/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  All Form element for news overview - Add | Edit Overview
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 14/Apr/2021 
	Author						: Sandeep K Sharma
================================================================ 
*/
import React, { useState } from "react";
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { fetchCountry } from "../../../middleware/services/cmsApi";
import { fetchCompany } from "../../../middleware/services/companyApi";
import { getProductVariant } from "../../../utilities";

export const CountrySelectBox =   (props) => {
    const [errorEnter3charactor, setErrorEnter3charactor] = useState(false);
    const handleKeyDown = async (e) => {
      if (e.text.length > 2) {
        let filters = { filters: [["countryName", "cn", e.text]] };
        let fields = { fields: ["countryName", "id"] };
        let sort = { sort: { countryName: "asc" } }  
        const response = await fetchCountry({ ...filters, ...fields, ...sort });
        if (response.data && response.data.data) {
            const countriesOptions = response.data.data
            e.updateData(countriesOptions);
        }
      } else {
        setErrorEnter3charactor(true)
      }
    }
    const noRecordsTemplate = () => {
      return (
        errorEnter3charactor ?
          <div>Please enter minimum 3 characters</div>
          :
          <div>No record found</div>
      )
    }
    const setSelect = (e) => {
      props.handleGetData({ "id": props.id, "Data": e.itemData });
    }

  
    return (
      <>
        <AutoCompleteComponent
          cssClass="e-outline"
          floatLabelType="Auto"
          fields={{ value: 'countryName', id: 'id' }}
          popupHeight="250px"
          filtering={handleKeyDown.bind(this)}
          noRecordsTemplate={noRecordsTemplate}
          highlight={true}
          // select={setSelect.bind(this)}
          {...props}

        />
      </>
    );
  }
  export const CompanySelectBox =   (props) => {
    const [errorEnter3charactor, setErrorEnter3charactor] = useState(false);
    const handleKeyDown = async (e) => {
      if (e.text.length > 2) {
        const productVariant = getProductVariant();
        let filters = [];
        if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
        filters.push(["companyName", "cn", e.text]);
        let fields = { fields: ["companyId", "companyName"] };
        const response = await fetchCompany({ filters: filters, ...fields });
        if ( typeof(response) !== "undefined" && response.data && response.data.data) {
            const companyOptions = response.data.data
            e.updateData(companyOptions);
        }else{
          e.updateData([]);
        }
      } else {
        setErrorEnter3charactor(true)
      }
    }
    const noRecordsTemplate = () => {
      return (
        errorEnter3charactor ?
          <div>Please enter minimum 3 characters</div>
          :
          <div>No record found</div>
      )
    }
    const setSelect = (e) => {
      props.handleGetData(e.itemData)
    }

  
    return (
      <>
        <AutoCompleteComponent
          cssClass="e-outline"
          floatLabelType="Auto"
          fields={{ value: 'companyName', id: 'companyId' }}
          popupHeight="250px"
          filtering={handleKeyDown.bind(this)}
          noRecordsTemplate={noRecordsTemplate}
          highlight={true}
          select={setSelect.bind(this)}
          {...props}

        />
      </>
    );
  }




