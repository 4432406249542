/*
================================================================
	Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : Widget API
----------------------------------------------------------------
	Creation Details
	Date Created				: 11/Oct/2023
	Author						: SATYA PRAKASH MALL
================================================================
*/

import { WIDGET_API as API } from "../../config/apiConfig";

// GET all Widget
export const fetchWidget = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/widget",
    method: "GET",
    params: params,
  });
};

// GET Single Widget
export const fetchSingleWidget = (widgetId) => {
  return API({
    url: `/widget/${widgetId}`,
    method: "GET",
  });
};

// SAVE Single Widget
export const saveWidget = (payload) => {
  return API({
    url: `/widget`,
    method: "POST",
    data: payload,
  });
};

// UPDATE Single Widget
export const updateWidget = (widgetId, payload) => {
  return API({
    url: `/widget/${widgetId}`,
    method: "PUT",
    data: payload,
  });
};

// DELETE Single Widget
export const deleteWidget = (widgetId) => {
  return API({
    url: `/widget/${widgetId}`,
    method: "DELETE",
  });
};

// GET all Widget
export const fetchWidgetLayout = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/widget-layout",
    method: "GET",
    params: params,
  });
};

// SAVE Single Widget
export const saveSingleWidget = (payload) => {
  return API({
    url: `/widget`,
    method: "POST",
    data: payload,
  });
};

// SAVE Single Widget
export const updateSingleWidgetLayout = (widgetId, payload) => {
  return API({
    url: `/widget-layout/${widgetId}`,
    method: "PUT",
    data: payload,
  });
};

// UPDATE Widget content
export const updateWidgetContent = (payload) => {
  return API({
    url: `/widget/content`,
    method: "PUT",
    data: payload,
  });
};

// DELETE Widget content
export const deleteWidgetContent = (payload) => {
  return API({
    url: `/delete/widget/content`,
    method: "PUT",
    data: payload,
  });
};
