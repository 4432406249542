/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  Customer Services form field options
 ---------------------------------------------------------------------------------
    Creation Details
    Date Created				: 08/July/2021
    Author						: Sandeep Sharma
================================================================
*/
//Service Type  options
export const SERVICE_TYPE_OPTIONS = [
    {
        label: "Expert Consultation",
        value: "Expert Consultation",
        id: "Expert Consultation",
        text: "Expert Consultation"
    },
    {
        label: "Startup Connect Request",
        value: "Startup Connect Request",
        id: "Startup Connect Request",
        text: "Startup Connect Request",

    },
    {
        label: "Custom Company Profile",
        value: "Custom Company Profile",
        id: "Custom Company Profile",
        text: "Custom Company Profile"
    },
    {
        label: "Startup Identification",
        value: "Startup Identification",
        id: "Startup Identification",
        text: "Startup Identification"
    },
    {
        label: "Survey",
        value: "Survey",
        id: "Survey",
        text: "Survey"
    }
];
export const TIME_SCALE = [
    {
        label: "0-2 Years",
        value: "0-2 Years"
    },
    {
        label: "2-5 Years",
        value: "2-5 Years"
    },
    {
        label: "5-10 Years",
        value: "5-10 Years"
    },
    {
        label: "10+ Years",
        value: "10+ Years"
    },

];
//Service Type  Status   
export const SERVICE_STATUS_OPTIONS = [
    {
        label: "Draft",
        value: "Draft"
    },
    {
        label: "In Progress",
        value: "In Progress"
    },
    {
        label: "Under Review",
        value: "Under Review"
    },
    {
        label: "Complete",
        value: "Complete"
    },

];
//Document Types     
export const DOCUMENT_TYPE_OPTIONS = [
    {
        label: "Transcripts",
        value: "transcripts"
    },
    {
        label: "Document",
        value: "document"
    },

];

//Video Types     
export const VIDEO_TYPE_OPTIONS = [
    {
        label: "Vimeo",
        value: "Vimeo"
    },
    {
        label: "YouTube",
        value: "YouTube"
    },

];

