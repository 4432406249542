/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Company Overview Form Fields integrations and API wiring up 
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 08/Aug/2020 
    Author						: Aditya Tijare
--------------------------------------------------------------------------------- 
    Change History #1 
    Date Modified : 12/Oct/2021
    Changed By : Sai
    Change Description : imported customformelements from syncFusionFormFields
    Ticket Ref Number  : #6049
    Reason for Change  : changing components to syncfusion react
    Updated Code       : handleSaveOverview Function(Parentcompany & Accuqiredcompany)added fetch companies list & search companies list
================================================================ 
*/

import React, { useState, useEffect, useRef, useCallback } from "react";
import { debounce } from "lodash";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { companyOverviewValidationSchema } from "../../validation/companyValidationSchema";
import defaultCompanyLogo from "../../../../../assets/images/company.jpg";
import { fetchLegalStatus } from "../../../../../middleware/services/cmsApi";
import { TextField, Dropdown, AutoCompleteDropdown, DatePicker } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { DataSource } from "./dataSource";
import { getDateFromMongoDate, getLoggedInUser, getProductVariant, isAdmin } from "../../../../../utilities";
import { WORKINPROGESS_OPTIONS, IS_STARTUP, STATUS, COMMON_ACCESS_ACTION } from "../../../../../constants";
import { companyEmployeeSize } from "../../constants";
import { fetchCompany } from "../../../../../middleware/services/companyApi";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { setBasicDetailsCompany } from "../../../../../middleware/actions/companyAction";
import { COMPANY_LOGO_IMG_SIZE } from "config";

const Overview = (props) => {
  const [companyImageFileName, setCompanyImageFileName] = useState("");
  const [companyStatus, setCompanyStatus] = useState(props.companyInformation.companyStatus);
  const [companyLogo, setCompanyLogo] = useState(props.companyLogo);
  const companyImage = useRef(null);
  const companyImageTag = useRef(null);
  const companyId = props.companyId || "";
  const companySource = props.companySource;
  const [imageError, setImageError] = useState("");
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const clickedSaveSubmit = useSelector((state) => state.companyState.clickStatus);
  const dispatch = useDispatch();
  const displayNone =
    companyId == "" || !companySource.totalCount
      ? {
          display: "none",
        }
      : {};
  const productVariant = getProductVariant();
  // redux commonState
  const commonState = useSelector((state) => state.companyState);
  const CommonStateInfo = commonState?.basicDetails;
  /* Common access */
  const accessPermissionBasic = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SAVE_OVERVIEW);

  const formik = useFormik({
    initialValues: props.companyInformation,
    validationSchema: () =>
      Yup.lazy((values) => {
        if (values.companyStatus == "Acquired By") {
          companyOverviewValidationSchema.companyAcquiredBy = Yup.string().required("Please select company");
        } else if (typeof companyOverviewValidationSchema.companyAcquiredBy != "undefined") {
          delete companyOverviewValidationSchema.companyAcquiredBy;
        }
        return Yup.object().shape(companyOverviewValidationSchema);
      }),
  });
  const setSource = (elementName) => {
    const objectValues = ["companyParent"];
    document.querySelector(`input[name="${elementName}"]`).value &&
      document.querySelector(`input[name="${elementName}"]`).value !== "on" &&
      formik.setFieldValue(elementName, document.querySelector(`input[name="${elementName}"]`).value);
    if (document.querySelector(`input[name="${elementName}"]:checked`) != null) {
      if (objectValues.indexOf(elementName) != -1) {
        formik.setFieldValue(elementName, JSON.parse(document.querySelector(`input[name="${elementName}"]:checked`).value));
      } else {
        formik.setFieldValue(elementName, document.querySelector(`input[name="${elementName}"]:checked`).value);
      }
    }
  };

  useEffect(() => {
    formik.setValues(
      // props.companyInformation
      {
        companyWorkInProgress:
          CommonStateInfo?.companyWorkInProgress && CommonStateInfo?.companyWorkInProgress.length
            ? CommonStateInfo?.companyWorkInProgress
            : props.companyInformation.companyWorkInProgress,
        companyName:
          CommonStateInfo?.companyName && CommonStateInfo?.companyName.length ? CommonStateInfo?.companyName : props.companyInformation.companyName,
        companyWebsite:
          CommonStateInfo?.companyWebsite && CommonStateInfo?.companyWebsite.length
            ? CommonStateInfo?.companyWebsite
            : props.companyInformation.companyWebsite,
        companyEmail:
          CommonStateInfo?.companyEmail && CommonStateInfo?.companyEmail.length
            ? CommonStateInfo?.companyEmail
            : props.companyInformation.companyEmail,
        companySocialTwitter:
          CommonStateInfo?.companySocialTwitter && CommonStateInfo?.companySocialTwitter.length
            ? CommonStateInfo?.companySocialTwitter
            : props.companyInformation.companySocialTwitter,
        companySocialFacebook:
          CommonStateInfo?.companySocialFacebook && CommonStateInfo?.companySocialFacebook.length
            ? CommonStateInfo?.companySocialFacebook
            : props.companyInformation.companySocialFacebook,
        companySocialLinkedIn:
          CommonStateInfo?.companySocialLinkedIn && CommonStateInfo?.companySocialLinkedIn.length
            ? CommonStateInfo?.companySocialLinkedIn
            : props.companyInformation.companySocialLinkedIn,
        companyEmpSize:
          CommonStateInfo?.companyEmpSize && CommonStateInfo?.companyEmpSize.length
            ? CommonStateInfo?.companyEmpSize
            : props.companyInformation.companyEmpSize,
        companyFoundedYear:
          CommonStateInfo?.companyFoundedYear && CommonStateInfo?.companyFoundedYear.length
            ? CommonStateInfo?.companyFoundedYear
            : props.companyInformation.companyFoundedYear,
        companyIsStartup:
          CommonStateInfo?.companyIsStartup && CommonStateInfo?.companyIsStartup.length
            ? CommonStateInfo?.companyIsStartup
            : props.companyInformation.companyIsStartup,
        companyLegalStatus:
          CommonStateInfo?.companyLegalStatus && CommonStateInfo?.companyLegalStatus.length
            ? CommonStateInfo?.companyLegalStatus
            : props.companyInformation.companyLegalStatus,
        companyParent:
          CommonStateInfo?.companyParent && CommonStateInfo?.companyParent.length
            ? CommonStateInfo?.companyParent
            : props.companyInformation.companyParent,
        companyStatus:
          CommonStateInfo?.companyStatus && CommonStateInfo?.companyStatus.length
            ? CommonStateInfo?.companyStatus
            : props.companyInformation.companyStatus,
        companyAcquiredBy:
          CommonStateInfo?.companyAcquiredBy && CommonStateInfo?.companyAcquiredBy.length
            ? CommonStateInfo?.companyAcquiredBy
            : props.companyInformation.companyAcquiredBy,
        companyPaywallSetting: CommonStateInfo?.companyPaywallSetting
          ? CommonStateInfo?.companyPaywallSetting
          : props.companyInformation.companyPaywallSetting,
        companyPublishedDate: CommonStateInfo?.companyPublishedDate
          ? CommonStateInfo?.companyPublishedDate
          : props.companyInformation.companyPublishedDate,
      }
    );
  }, [props.companyInformation]);

  useEffect(() => {
    if (clickedSaveSubmit) {
      handleSaveOverview();
    }
  }, [clickedSaveSubmit]);
  useEffect(() => {
    setCompanyLogo(props.companyLogo);
    if (props.companyLogo) {
      setIsDefaultImage(false);
    }
  }, [props.companyLogo]);
  useEffect(() => {
    setCompanyStatus(props.companyInformation.companyStatus);
  }, [props.companyInformation.companyStatus]);
  // save a Overview
  const handleSaveOverview = async () => {
    // Do not delete need to discuss this further
    // Create a Date object from the provided string
    // let exactGMTDate = new Date(formik.values.companyPublishedDate);

    // // Check if the date has been adjusted to the previous day
    // if (exactGMTDate.getDate() === new Date().getDate()) {
    //   // If the date has been adjusted, add one day to it
    //   exactGMTDate.setDate(exactGMTDate.getDate() + 1);
    // }

    // // Extract the date part and time part
    // const datePart = exactGMTDate.toDateString();
    // const timePart = exactGMTDate.toTimeString();

    // // Create a new Date object by concatenating the date and time parts
    // const newDate = new Date(datePart + " " + timePart);

    // // Set the time components to UTC midnight
    // newDate.setUTCHours(0, 0, 0, 0);

    // // Convert to ISO 8601 format
    // const isoString = newDate.toISOString();
    if (Object.keys(commonState?.advanceDetails)?.length > 0) {
      if (!commonState?.advanceDetails?.companyAbout) return dispatch(actionError("Please Write Few things about the company!"));
    }
    let companyPaywallData = props.companyPaywallData;
    let companyPaywallProductPlatform = productVariant == "Cognition" ? "Signal V2" : `${productVariant} V2`;
    if (formik.values?.companyPaywallSetting == "YES") {
      const index = companyPaywallData && companyPaywallData?.findIndex((object) => object?.productPlatform === companyPaywallProductPlatform);
      if (index === -1) {
        companyPaywallData.push({
          productVariant: productVariant,
          productPlatform: companyPaywallProductPlatform,
          taxonomyTagging: [],
        });
      }
    } else {
      companyPaywallData = companyPaywallData && companyPaywallData?.filter((element) => element.productPlatform !== companyPaywallProductPlatform);
    }
    let overviewData = {
      companyName: formik.values.companyName,
      companyStatus:
        formik.values.companyStatus == "Acquired By"
          ? {
              value: formik.values.companyStatus,
              acquiredBy: {
                companyName:
                  (typeof formik.values.companyAcquiredBy === "string" && "") ||
                  formik.values.companyAcquiredBy ||
                  formik.values.companyAcquiredBy.label,
                companyId: (typeof formik.values.companyAcquiredBy === "string" && "") || formik.values.companyAcquiredByValue,
              },
            }
          : {
              value: formik.values.companyStatus,
            },
      companyLegalStatus: formik.values.companyLegalStatus,
      companyIsStartup: formik.values.companyIsStartup,
      companyFoundedYear: formik.values.companyFoundedYear,
      companyEmpSize: formik.values.companyEmpSize,
      companySocial: {
        twitter: formik.values.companySocialTwitter,
        facebook: formik.values.companySocialFacebook,
        linkedIn: formik.values.companySocialLinkedIn,
      },
      companyEmail: formik.values.companyEmail,
      companyWebsite: formik.values.companyWebsite,
      companyWorkInProgress: formik.values.companyWorkInProgress,
      companyPaywallSetting: companyPaywallData,
      // companyPublishedDate: isoString,
      companyPublishedDate: new Date(formik.values.companyPublishedDate).toUTCString(),
    };
    if (
      overviewData.companyStatus.value != "Active" &&
      overviewData.companyStatus.value != "Non-Operational" &&
      overviewData.companyStatus.value != ""
    ) {
      if (
        formik.values.companyAcquiredBy != formik.values.companyAcquiredByLabel ||
        formik.values.companyAcquiredByValue === undefined ||
        formik.values.companyAcquiredByValue === ""
      ) {
        delete overviewData.companyStatus.acquiredBy.companyId;
      }
    }
    if (formik.values.companyParent) {
      if (formik.values.companyParentLabel) {
        overviewData.companyParent =
          formik.values.companyParent != formik.values.companyParentLabel.label
            ? {
                companyName: formik.values.companyParent,
                // companyId: formik.values.companyParentValue.value
              }
            : {
                companyName: formik.values.companyParentLabel.label,
                companyId: formik.values.companyParentValue.value,
              };
      } else {
        overviewData.companyParent = {
          companyName: formik.values.companyParent,
        };
      }
    } else {
      overviewData.companyParent = {};
    }
    let saveOverviewData = new FormData();
    for (let uKey in overviewData) {
      if (typeof overviewData[uKey] != "object") {
        if (overviewData[uKey] && overviewData[uKey] !== "") saveOverviewData.append(uKey, overviewData[uKey]);
      } else {
        if (
          overviewData[uKey].acquiredBy &&
          (overviewData[uKey].acquiredBy?.companyId == overviewData[uKey].acquiredBy?.companyName ||
            overviewData[uKey].acquiredBy?.companyName.includes("Create"))
        ) {
          const value = {
            value: "Acquired By",
            acquiredBy: {
              companyName: overviewData[uKey].acquiredBy.companyId,
            },
          };
          saveOverviewData.append(uKey, JSON.stringify(value));
        } else {
          if (overviewData[uKey] && overviewData[uKey] !== "") saveOverviewData.append(uKey, JSON.stringify(overviewData[uKey]));
          if (uKey == "companyLegalStatus" && !overviewData[uKey]) saveOverviewData.append(uKey, "");
        }
      }
    }

    if (companyImage.current.files.length && typeof companyImage.current.files[0] != "undefined") {
      saveOverviewData.append("companyLogo", companyImage.current.files[0]);
    } else if (companyLogo == undefined) {
      saveOverviewData.append("companyLogo", "");
    }
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        const err =
          res &&
          Object.values(res).map((e) => {
            return e;
          });

        formik.setFormikState({ ...formik, touched: touched, errors: res });
        if (err) {
          console.error(err);
          dispatch(actionError("" + err.join(" . ")));
        }
      } else {
        const loggedInUser = getLoggedInUser();
        if (props.location.pathname == "/processing/company/add") {
          const workflowPayload = isAdmin()
            ? { allocationQueue: "PROCESSING_FRESH" }
            : {
                userId: loggedInUser._id,
                allocationQueue: "PROCESSING_FRESH",
              };
          saveOverviewData.append("workflow", JSON.stringify(workflowPayload));
        }
        if (props.location.pathname == "/data-qc/company/add") {
          const workflowPayload = isAdmin() ? { allocationQueue: "QC_FRESH" } : { userId: loggedInUser._id, allocationQueue: "QC_FRESH" };
          saveOverviewData.append("workflow", JSON.stringify(workflowPayload));
        }
        if (props.location.pathname == "/company/add") {
          const workflowPayload = isAdmin()
            ? { allocationQueue: "PROCESSING_FRESH" }
            : {
                userId: loggedInUser._id,
                allocationQueue: "PROCESSING_FRESH",
              };
          saveOverviewData.append("workflow", JSON.stringify(workflowPayload));
        }
        !imageError && props.handleSaveOverview(saveOverviewData);
      }
    });
  };

  const readCompanyImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      companyImageTag.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  /**
   * @description legal status option
   */

  const [legalStatusList, setLegalStatusList] = useState([]);
  const getLegalStatus = async () => {
    let sort = { sort: { legalStatus: "asc" } };
    const response = await fetchLegalStatus({ ...sort });
    const options = response.data?.data.map((item) => {
      return item.legalStatus;
    });
    setLegalStatusList(options);
  };
  useEffect(() => {
    getLegalStatus();
  }, []);

  /**
   * @description fetch companies list
   */
  const searchCompany = useCallback(
    debounce(async (e) => {
      let filters = [];
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["companyName", "cn", e.text], ["productVariant.isLive", "eq", "YES"]);
      let fields = { fields: ["companyName"] };
      e.text.length > 1 &&
        (await fetchCompany({
          filters: filters,
          ...fields,
          sort: "companyName:asc",
          limit: 10,
        }).then((response) => {
          if (response.data && response?.data?.data) {
            const resData = response.data.data.map((v) => {
              return { label: v.companyName, value: v.id };
            });
            if (resData) {
              e.updateData(resData);
            } else {
              e.updateData([]);
            }
          }
        }));
    }, 300),
    []
  );

  // UseEffect for redux
  useEffect(() => {
    dispatch(setBasicDetailsCompany(formik.values));
  }, [formik.values]);

  const handleImage = () => {
    let editImage = true;
    if (props.editData) {
      let overviewDataImage = new FormData();

      if (companyImage.current.files.length && typeof companyImage.current.files[0] != "undefined") {
        overviewDataImage.append("companyLogo", companyImage.current.files[0]);
      } else if (companyLogo == undefined) {
        overviewDataImage.append("companyLogo", "");
      }
      !imageError && props.handleSaveOverview(overviewDataImage, editImage);
    }
  };

  return (
    <div>
      <form>
        <table width="100%" className="mt-2" style={Object.keys(displayNone).length ? { width: "60%" } : {}}>
          <tbody>
            <tr>
              <th>&nbsp;</th>
              <th className="data-source-block" style={displayNone} colSpan={companySource.totalCount}>
                <div className="col-md-12 p-2 border bg-secondary text-white rounded-top text-center">Original Data From Sources</div>
              </th>
              <th className="data-source-block" style={displayNone} colSpan="1">
                &nbsp;
              </th>
              <th style={companyId == "" ? { width: "50%" } : {}}>
                <div className="col-md-12 p-2 border bg-secondary text-white rounded-top text-center">Company Basic Information</div>
              </th>
            </tr>

            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyWorkInProgress">Work in Progress</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companyWorkInProgress || "",
                      label: sourceInfo.companyWorkInProgress || "",
                      name: `companyWorkInProgress`,
                      id: `companyWorkInProgress_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource?.data?.companyWorkInProgress &&
                    companySource.data.length > 1 &&
                    document.querySelector(`input[name="companyWorkInProgress"]`) &&
                    document.querySelector(`input[name="companyWorkInProgress"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyWorkInProgress")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className="com-md-6 p-2 pt-3 pb-0">
                      <Dropdown
                        formik={formik}
                        sortOrder="Acending"
                        placeholder="Work in Progress"
                        id="companyWorkInProgress"
                        name="companyWorkInProgress"
                        fields={{ text: "label", value: "value" }}
                        dataSource={WORKINPROGESS_OPTIONS}
                        value={CommonStateInfo?.companyWorkInProgress}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyName">Company Name</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companyName,
                      label: sourceInfo.companyName,
                      name: `companyName`,
                      id: `companyName_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length >= 1 &&
                    document.querySelector(`input[name="companyName"]`) &&
                    document.querySelector(`input[name="companyName"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyName")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>

              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className=" p-2 pt-3 pb-0">
                      <TextField
                        formik={formik}
                        type="text"
                        id="companyName"
                        placeholder="Company Name *"
                        name="companyName"
                        numberCount={true}
                        maxLength={125}
                        characterLimitText={true}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyStatus">Status</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: typeof sourceInfo.companyStatus != "undefined" ? sourceInfo.companyStatus.value || "" : "",
                      label: typeof sourceInfo.companyStatus != "undefined" ? sourceInfo.companyStatus.value || "" : "",
                      name: `companyStatus`,
                      id: `companyStatus_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource?.data?.companyStatus &&
                    companySource.data.length >= 1 &&
                    document.querySelector(`input[name="companyStatus"]`) &&
                    document.querySelector(`input[name="companyStatus"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyStatus")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className="com-md-6">
                      <div className="form-group pl-2 pr-2 pt-3">
                        <Dropdown
                          formik={formik}
                          sortOrder="Acending"
                          placeholder="Company Status"
                          id="companyStatus"
                          name="companyStatus"
                          fields={{ text: "label", value: "value" }}
                          dataSource={STATUS}
                          allowFiltering={true}
                          value={CommonStateInfo?.companyStatus}
                        />
                      </div>
                    </div>
                    {formik.values.companyStatus && formik.values.companyStatus === "Acquired By" && (
                      <div className="form-group pl-2 pr-2 pt-3">
                        <AutoCompleteDropdown
                          formik={formik}
                          id="companyAcquiredBy"
                          name="companyAcquiredBy"
                          placeholder="Type min 3 chars to search company"
                          fields={{ value: "label", id: "value" }}
                          filtering={searchCompany}
                          select={(data) => {
                            data &&
                              data?.itemData &&
                              formik.setValues({
                                ...formik.values,
                                companyAcquiredByLabel: {
                                  label: data.itemData.label,
                                },
                                companyAcquiredByValue: {
                                  value: data.itemData.value,
                                },
                              });
                          }}
                          allowFiltering={true}
                          autofill={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyParent">Parent</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value:
                        typeof sourceInfo.companyParent != "undefined"
                          ? JSON.stringify({
                              label: sourceInfo.companyParent.companyName,
                              value: sourceInfo.companyParent.companyId,
                            })
                          : "",
                      label: typeof sourceInfo.companyParent != "undefined" ? sourceInfo.companyParent.companyName : "",
                      name: `companyParent`,
                      id: `companyParent_${index}`,
                    }}
                  />
                </td>
              ))}

              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length >= 1 &&
                    document.querySelector(`input[name="companyParent"]`) &&
                    document.querySelector(`input[name="companyParent"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyParent")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className=" p-2 pt-3 pb-0">
                      <AutoCompleteDropdown
                        formik={formik}
                        id="companyParent"
                        name="companyParent"
                        placeholder="Type min 3 chars to search company"
                        fields={{ value: "label", id: "value" }}
                        filtering={searchCompany}
                        select={(data) => {
                          // data && data?.itemData && formik.setFieldValue("companyParent", { label: data.itemData.label, value: data.itemData.value })
                          data &&
                            data?.itemData &&
                            formik.setValues({
                              ...formik.values,
                              companyParentLabel: {
                                label: data.itemData.label,
                              },
                              companyParentValue: {
                                value: data.itemData.value,
                              },
                            });
                        }}
                        allowFiltering={true}
                        autofill={true}
                        value={CommonStateInfo?.companyParent}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyLegalStatus">Legal Status</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companyLegalStatus || "",
                      label: sourceInfo.companyLegalStatus || "",
                      name: `companyLegalStatus`,
                      id: `companyLegalStatus_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length > 1 &&
                    document.querySelector(`input[name="companyLegalStatus"]`) &&
                    document.querySelector(`input[name="companyLegalStatus"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyLegalStatus")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className=" p-2 pt-3 pb-0">
                      {legalStatusList && legalStatusList.length > 0 && (
                        <Dropdown
                          formik={formik}
                          sortOrder="Acending"
                          placeholder="Select company legal status"
                          id="companyLegalStatus"
                          name="companyLegalStatus"
                          // fields={{ text: "label", value: "label" }}
                          dataSource={legalStatusList}
                          // options={legalStatusList}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyIsStartup">Is Startup</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companyIsStartup || "",
                      label: sourceInfo.companyIsStartup || "",
                      name: `companyIsStartup`,
                      id: `companyIsStartup_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length >= 1 &&
                    document.querySelector(`input[name="companyIsStartup"]`) &&
                    document.querySelector(`input[name="companyIsStartup"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyIsStartup")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className=" p-2 pt-3 pb-0">
                      <Dropdown
                        formik={formik}
                        sortOrder="Acending"
                        placeholder="Select company type"
                        id="companyIsStartup"
                        name="companyIsStartup"
                        fields={{ text: "label", value: "value" }}
                        dataSource={IS_STARTUP}
                        value={CommonStateInfo?.companyIsStartup}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyFoundedYear">Founded Year</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companyFoundedYear || "",
                      label: sourceInfo.companyFoundedYear || "",
                      name: `companyFoundedYear`,
                      id: `companyFounded_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data.map((sourceInfo, index) =>
                    sourceInfo.companyFoundedYear &&
                    companySource.data.length >= 1 &&
                    document.querySelector(`input[name="companyFoundedYear"]`) &&
                    document.querySelector(`input[name="companyFoundedYear"]`).value !== "" ? (
                      <button key={index} className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyFoundedYear")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className="p-2 pt-3 pb-0">
                      <TextField formik={formik} type="text" id="companyFoundedYear" placeholder="Founded Year" name="companyFoundedYear" />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyEmpSize">Employee Size</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companyEmpSize || "",
                      label: sourceInfo.companyEmpSize || "",
                      name: `companyEmpSize`,
                      id: `companyEmpSize_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource?.data?.companyEmpSize &&
                    companySource.data.length >= 1 &&
                    document.querySelector(`input[name="companyEmpSize"]`) &&
                    document.querySelector(`input[name="companyEmpSize"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyEmpSize")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className=" p-2 pt-3 pb-0">
                      <Dropdown
                        formik={formik}
                        sortOrder="Acending"
                        placeholder="Select employee size"
                        id="companyEmpSize"
                        name="companyEmpSize"
                        fields={{ text: "label", value: "value" }}
                        dataSource={companyEmployeeSize}
                        value={CommonStateInfo?.companyEmpSize}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyLinkedIn">LinkedIn</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companySocial.linkedIn || "",
                      label: sourceInfo.companySocial.linkedIn || "",
                      name: `companySocialLinkedIn`,
                      id: `companySocialLinkedIn_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length >= 1 &&
                    document.querySelector(`input[name="companySocialLinkedIn"]`) &&
                    document.querySelector(`input[name="companySocialLinkedIn"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companySocialLinkedIn")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className="com-md-6 p-2 pt-3 pb-0">
                      <TextField
                        formik={formik}
                        id="companySocialLinkedIn"
                        placeholder="Company LinkedIn Page URL"
                        name="companySocialLinkedIn"
                        type="url"
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyTwitter">Twitter</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companySocial.twitter || "",
                      label: sourceInfo.companySocial.twitter || "",
                      name: `companySocialTwitter`,
                      id: `companySocialTwitter_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length > 1 &&
                    document.querySelector(`input[name="companySocialTwitter"]`) &&
                    document.querySelector(`input[name="companySocialTwitter"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companySocialTwitter")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className="com-md-6 p-2 pt-3 pb-0">
                      <TextField
                        formik={formik}
                        id="companySocialTwitter"
                        placeholder="Company Twitter Handle URL"
                        name="companySocialTwitter"
                        type="url"
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyFacebook">Facebook</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companySocial.facebook || "",
                      label: sourceInfo.companySocial.facebook || "",
                      name: `companySocialFacebook`,
                      id: `companySocialFacebook_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length > 1 &&
                    document.querySelector(`input[name="companySocialFacebook"]`) &&
                    document.querySelector(`input[name="companySocialFacebook"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companySocialFacebook")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className="com-md-6 p-2 pt-3 pb-0">
                      <TextField
                        formik={formik}
                        id="companySocialFacebook"
                        placeholder="Company Facebook Page URL"
                        name="companySocialFacebook"
                        type="url"
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyEmail">Email</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companyEmail || "",
                      label: sourceInfo.companyEmail || "",
                      name: `companyEmail`,
                      id: `companyEmail_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length >= 1 &&
                    document.querySelector(`input[name="companyEmail"]`) &&
                    document.querySelector(`input[name="companyEmail"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyEmail")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className="com-md-6 p-2 pt-3 pb-0">
                      <TextField formik={formik} id="companyEmail" placeholder="Company Email" name="companyEmail" type="email" />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyWebsite">Website</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companyWebsite || "",
                      label: sourceInfo.companyWebsite || "",
                      name: `companyWebsite`,
                      id: `companyWebsite_${index}`,
                    }}
                  />
                </td>
              ))}

              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length > 0 &&
                    document.querySelector(`input[name="companyWebsite"]`) &&
                    document.querySelector(`input[name="companyWebsite"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyWebsite")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-11 d-flex align-items-center">
                    <div className="col-md-6 p-2 pt-3 pb-0 d-flex">
                      <TextField formik={formik} id="companyWebsite" placeholder="Company Website *" name="companyWebsite" type="text" />
                      <div className="col-md-1 pr-2 pb-2 pt-2">
                        <a
                          data-tip="Open Website"
                          href={
                            formik.values.companyWebsite
                              ? formik.values.companyWebsite.startsWith("http")
                                ? formik.values.companyWebsite
                                : "http://" + formik.values.companyWebsite
                              : null
                          }
                          target="_blank"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              {/* <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyPaywallSetting">Enable Paywall</label>
                  </div>
                </div>
              </td> */}
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.companyPaywallSetting || "",
                      label: sourceInfo.companyPaywallSetting || "",
                      name: `companyPaywallSetting`,
                      id: `companyPaywallSetting_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length >= 1 &&
                    document.querySelector(`input[name="companyPaywallSetting"]`) &&
                    document.querySelector(`input[name="companyPaywallSetting"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyPaywallSetting")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              {/* <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-12">
                    <div className=" p-2 pt-3 pb-0">
                      <Dropdown
                        formik={formik}
                        sortOrder="Acending"
                        placeholder="Enable Paywall"
                        id="companyPaywallSetting"
                        name="companyPaywallSetting"
                        fields={{ text: "label", value: "value" }}
                        dataSource={IS_STARTUP}
                        value={CommonStateInfo?.companyPaywallSetting}
                      />
                    </div>
                  </div>
                </div>
              </td> */}
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyLogo">Company Logo</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}></td>
              ))}

              <td width="90px" className="data-source" style={displayNone}></td>
              <td className="border border-secondary">
                <div className="image-holder mb-1 pt-1">
                  <div className="d-flex justify-content-center">
                    <img
                      src={companyLogo || defaultCompanyLogo}
                      alt="Company Logo"
                      width="200"
                      height="200"
                      ref={companyImageTag}
                      // style={{ position: "relative"}}
                    />
                    <div className="cy-image-action image-action" style={{ position: "", top: "10px", right: "320px" }}>
                      <i
                        className={`fa fa-trash mr-1 ${isDefaultImage ? "d-none" : ""}`}
                        style={{ position: "", top: "0px", right: "10px" }}
                        aria-hidden="true"
                        onClick={() => {
                          dispatch(
                            showAlertBox({
                              content: "Are you sure, you want to delete?",
                              okCallback: async () => {
                                dispatch(actionSuccess("Image deleted successfully"));
                                if (companyImage.current.files.length > 0) {
                                  setCompanyLogo(undefined);
                                  setIsDefaultImage(true);
                                  setImageError("");
                                  companyImage.current.value = "";
                                  companyImageTag.current.setAttribute("src", defaultCompanyLogo);
                                } else {
                                  setCompanyLogo(undefined);
                                  setIsDefaultImage(true);
                                  companyImage.current.value = "";
                                  companyImageTag.current.setAttribute("src", defaultCompanyLogo);
                                }
                              },
                              okText: "Delete",
                              cancelText: "Cancel",
                              title: "dialogAlertCssWarning",
                            })
                          );
                        }}
                      ></i>
                      <i
                        className="cy-logo_add fa fa-pencil-alt "
                        style={{ position: "", top: "0px", right: "30px" }}
                        aria-hidden="true"
                        onClick={() => companyImage.current.click()}
                      />
                    </div>
                  </div>

                  <input
                    type="file"
                    className="d-none"
                    accept=".jpg, .png, .jpeg"
                    id="companyLogo"
                    name="companyLogo"
                    ref={companyImage}
                    formik={formik}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        if (
                          (e.target.files[0].type == "image/png" ||
                            e.target.files[0].type == "image/jpg" ||
                            e.target.files[0].type == "image/jpeg") &&
                          e.target.files[0].size < COMPANY_LOGO_IMG_SIZE
                        ) {
                          setImageError("");
                          setIsDefaultImage(false);
                          setCompanyImageFileName(e.target.files[0].name);
                          readCompanyImage(e.target.files[0]);
                          handleImage();
                        } else {
                          setCompanyImageFileName("");
                          // companyImageTag.current.setAttribute("src", companyImage);
                          setImageError("File must be JPG/JPEG or PNG format and size must not exceed 10 MB.");
                        }
                      } else {
                        setCompanyImageFileName("");
                        setImageError("");
                        // companyImageTag.current.setAttribute("src", companyImage);
                      }
                    }}
                  />
                  <div
                    className="d-flex align-item-center justify-content-center text-danger"
                    style={{ display: imageError ? "block" : "none" }}
                    role="alert"
                  >
                    <small>{imageError}</small>
                  </div>
                </div>
              </td>
            </tr>
            {/* <!-- Body Row End --> */}
            {/* <!-- Body Row Start --> */}
            <tr>
              <td width="15%">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="companyPublishedDate">Publish Date</label>
                  </div>
                </div>
              </td>
              {companySource.data.map((sourceInfo, index) => (
                <td width="15%" className="border border-secondary data-source" key={index} style={displayNone}>
                  <DataSource
                    properties={{
                      dataSourceCount: companySource.totalCount,
                      value: sourceInfo.productVariant?.find((item) => item.productName === productVariant).lastPublishedDate || "",
                      label: sourceInfo.productVariant?.find((item) => item.productName === productVariant).lastPublishedDate || "",
                      name: `companyPublishedDate`,
                      id: `companyPublishedDate_${index}`,
                    }}
                  />
                </td>
              ))}
              <td width="90px" className="data-source" style={displayNone}>
                <div className="row ml-2">
                  {companySource.data &&
                    companySource.data.length >= 1 &&
                    document.querySelector(`input[name="companyPublishedDate"]`) &&
                    document.querySelector(`input[name="companyPublishedDate"]`).value !== "" && (
                      <button className="btn btn-outline-dark pl-4 pr-4" type="button" onClick={() => setSource("companyPublishedDate")}>
                        <i className="fas fa-lg fa-arrow-circle-right"></i>
                      </button>
                    )}
                </div>
              </td>
              <td className="border border-secondary">
                <div className="row">
                  <div className="col-md-4">
                    <div className=" p-2 pt-3 pb-0">
                      <DatePicker
                        formik={formik}
                        properties={{
                          placeholder: "Publish Date *",
                          id: "companyPublishedDate",
                          name: "companyPublishedDate",
                          format: "dd MMM yyyy",
                          allowEdit: false,
                          floatLabelType: "Auto",
                          openOnFocus: true,
                          max: getDateFromMongoDate(new Date()),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          {/* <!-- Body Row End --> */}
        </table>
      </form>
      <div className="clearfix"></div>
      <div className="hr"></div>
      <div className="text-right mt-5 mb-3">
        {accessPermissionBasic ? (
          <button type="button" className="btn btn-primary cy-btn" onClick={handleSaveOverview}>
            Save Overview
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Overview;
