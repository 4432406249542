/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Internal User Listing
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionStart, actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { swap, getProductVariant } from "../../../../utilities";
import CommonDataGrid from "../../../components/dataGrid";
import { StringFilter, BooleanFilter } from "../../../components/dataGrid/fliters";
import { fetchInternalUser, deleteUser, deleteManyUser } from "../../../../middleware/services/userApi";
import ThreeDotMenu from "../../../components/threeDotMenu";
import { setMaintainedState, visitRecord } from "../../../../middleware/actions/gridAction";
import { resetRedux } from "../../../../middleware/actions/taxonomyFilterAction";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

// specify column names for column fields in datGrid here
const columnNames = {
  id: "id",
  Email: "userEmail",
  "First Name": "userFname",
  "Last Name": "userLname",
  Status: "userActive",
  // "Last Login": "userLastLoginDate"
};

const userActiveTemplate = (value) => {
  return <>{value.Status ? <span>Active</span> : <span>Inactive</span>}</>;
};

/**
 * @description formatRowData function required to format rowData for datGrid
 * @param {Array} rowData
 */
const formatRowData = (rowData) => {
  let formatedRowData = [];
  const columnNamesValues = swap(columnNames);
  formatedRowData = rowData.map((rD) => {
    const newRD = {
      Id: rD.id,
      // isChecked: false
    };
    Object.keys(rD).forEach((rDK) => {
      if (columnNamesValues[rDK]) {
        newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
      }
    });
    return newRD;
  });
  return formatedRowData;
};

// specify breadcrumb here

// user dashboard component
const UserDashBoard = (props) => {
  const [deleteId, setDeleteId] = useState(undefined);
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const gridState = useSelector((state) => state.gridState);

  /* Common access */
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionInactive = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.INACTIVE);
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);

  const breadCrumbLinks = [{ linkUrl: "/internal-user", linkName: aR.moduleName, linkActive: true }];

  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const deleteSingleUser = (data) => {
    dispatch(actionStart());
    deleteUser(data.Id)
      .then((res) => {
        setDeleteId(data.Id);
        dispatch(actionSuccess("User Deleted"));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Delete User Failed";
        dispatch(actionError(errMsg));
      });
  };
  const userNameTemplate = (value) => {
    return (
      <span className="span-link" onClick={() => history.push(`/internal-user/${value.id}/edit#profile`)}>
        {value["First Name"]}
      </span>
    );
  };
  /* 
		@Description : Template Component for action column
	*/
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                history.push(`/internal-user/${value.id}/edit#profile`);
                dispatch(visitRecord());
              },
            }
          : {})}
        {...(value.Status && accessActionInactive
          ? {
              Inactive: () => {
                dispatch(
                  showAlertBox({
                    okCallback: deleteSingleUser,
                    data: value,
                    content: "Are you sure you want to mark user as Inactive?",
                    okText: "Inactive",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
      />
    );
  };
  /* 
		@Description : delete many User on checkbox select
	*/
  const deleteManyFunc = (flag) => {
    const selectedIntUsers = localStorage.getItem("selectedIntUsers") ? JSON.parse(localStorage.getItem("selectedIntUsers")) : [];
    if (flag) {
      if (selectedIntUsers.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteManyUser({ userIds: selectedIntUsers })
                .then((response) => {
                  if (response.data.data) {
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete users"));
                });
            },
            okText: "Delete",
            cancelText: "Cancel",
            content: "Are you sure you want to delete?",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };
  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean
  const columnFields = [
    // { field: "Id", visible: false, isPrimaryKey: true },
    { field: "First Name", type: "String", template: userNameTemplate, filterTemplate: StringFilter, showInColumnChooser: false, textAlign: "Left" },
    { field: "Last Name", type: "String", filterTemplate: StringFilter, textAlign: "Left" },
    { field: "Email", type: "String", filterTemplate: StringFilter, textAlign: "Left" },
    {
      field: "Status",
      type: "Boolean",
      template: userActiveTemplate,
      filterTemplate: BooleanFilter,
      width: 150,
      textAlign: "Left",
      headerTextAlign: "Left",
    },
    // { field: "Last Login", type: "dateTime", format: "yyyy-MM-dd hh:mm:ss a",
    // 	// format:{type: 'dateTime', skeleton: 'short'}
    // },
  ];
  if (accessActionEdit || accessActionInactive) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false, textAlign: "Center" });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  const fetchInternalUserWithProductVariant = (params = {}) => {
    let defaultFilter = [];
    const productVariant = getProductVariant();
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let { filters = [] } = params;
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    params.filters = [...filters, ...defaultFilter];
    return fetchInternalUser(params);
  };

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => dispatch(setBreadCrumb());
  }, [dispatch]);

  // Reset Redux for filter by taxonomy
  useEffect(() => {
    if (window.location.pathname == `/internal-user`) {
      dispatch(resetRedux());
    }
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        <CommonDataGrid
          gridTitle="User Management List"
          fetch={fetchInternalUserWithProductVariant}
          deleteOne={deleteSingleUser}
          deleteMany={accessActionDelete ? deleteManyFunc : null}
          getMultiSelectedRows={(data) => {
            localStorage.setItem("selectedIntUsers", JSON.stringify([...data]));
          }}
          getMultiDeSelectedRows={(data) => {
            localStorage.setItem("selectedIntUsers", JSON.stringify([...data]));
          }}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          addNewRight={accessActionListingAddNew}
          addNew={() => history.push(`/internal-user/add#profile`)}
          clearFilterByTaxonomy={true}
        />
      </div>
    </div>
  );
};

export default UserDashBoard;
