import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import Company from "../app/views/company";
import Product from "../app/views/product";
import ProductForm from "../app/views/product/component";
import Collaboration from "../app/views/company/component/collaboration";
import CollaborationForm from "../app/views/company/component/collaboration/collaborationDtls";
import CompanyBasic from "../app/views/company/component";
import DataProcessing from "../app/views/dataProcessing/company";
import DataScreeningCompany from "../app/views/dataScreening/company";
import DataAllocationCompany from "../app/views/dataAllocation/company";
import DataProcessingCompany from "../app/views/dataProcessing/company/companyTabs";
const BASIC = ["Basic:VIEW", "Basic:FULL"];
const ADVANCED = ["Advanced:VIEW", "Advanced:FULL"];
const PRODUCTS = ["Products & Technologies:VIEW", "Products & Technologies:FULL"];
const COLLABORATION = ["Collaboration:VIEW", "Collaboration:FULL"];
const TAXONOMY = ["Taxonomy:VIEW", "Taxonomy:FULL"];
const ALLOCATION = ["Allocation:VIEW", "Allocation:FULL"];
const OVERVIEW = ["Overview:VIEW", "Overview:FULL"];
const TECHNICAL_SPECIFICATION = ["Technical Specification:VIEW", "Technical Specification:FULL"];
const VALUE_PROPOSITION = ["Value Proposition:VIEW", "Value Proposition:FULL"];
const LICENSING = ["Licensing/Co-Development:VIEW", "Licensing/Co-Development:FULL"];
const SELECT = ["Select:VIEW", "Select:FULL"];
const REJECT = ["Reject:VIEW", "Reject:FULL"];
const HOLD = ["Hold:VIEW", "Hold:FULL"];
const IMPORT = ["Import:VIEW", "Import:FULL"];
const EXPORT = ["Export:VIEW", "Export:FULL"];
const PROCESSING = ["Processing:VIEW", "Processing:FULL"];
const companyRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/company"
      component={Company}
      pageHeading="GenNx CMS - Company"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...BASIC, ...ADVANCED, ...PRODUCTS, ...COLLABORATION, ...TAXONOMY],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/company/add"
      component={CompanyBasic}
      pageHeading="GenNx CMS - Company"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...BASIC],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/company/:companyId/edit"
      component={CompanyBasic}
      pageHeading="GenNx CMS - Company"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...BASIC, ...ADVANCED, ...PRODUCTS, ...COLLABORATION, ...TAXONOMY],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/company/:companyId/product/:productId/edit"
      component={ProductForm}
      pageHeading="GenNx CMS - Products & Technology"
      accessRights={{
        moduleName: "Products & Technology",
        parentModuleName: "Company",
        moduleAttribute: [...PRODUCTS],
        interfaceName: "Overview",
      }}
    />
    <AuthLayout
      exact
      path="/company/:companyId/product/add"
      component={ProductForm}
      pageHeading="GenNx CMS - Product - Processing"
      accessRights={{
        moduleName: "Products & Technology",
        parentModuleName: "Company",
        moduleAttribute: [...PRODUCTS],
        interfaceName: "Overview",
      }}
    />
    <AuthLayout
      exact
      path="/company/product"
      component={Product}
      pageHeading="GenNx CMS - Products & Technology"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...PRODUCTS],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/company/:companyId/collaboration/:collaborationId/edit"
      component={CollaborationForm}
      pageHeading="GenNx CMS - Collaboration"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...COLLABORATION],
        interfaceName: "Collaboration",
      }}
    />
    <AuthLayout
      exact
      path="/company/:companyId/collaboration/add"
      component={CollaborationForm}
      pageHeading="GenNx CMS - Collaboration"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...COLLABORATION],
        interfaceName: "Collaboration",
      }}
    />
    <AuthLayout
      exact
      path="/company/:companyId/collaboration"
      component={Collaboration}
      pageHeading="GenNx CMS - Collaboration"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...COLLABORATION],
        interfaceName: "Basic",
      }}
    />

    <AuthLayout
      exact
      path="/processing/company"
      component={DataProcessing}
      pageHeading="GenNx CMS - Company - Processing"
      accessRights={{
        moduleName: "Processing",
        parentModuleName: "Company",
        moduleAttribute: [...BASIC, ...ADVANCED, ...PRODUCTS, ...COLLABORATION, ...TAXONOMY, ...PROCESSING],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/processing/company/add"
      component={DataProcessingCompany}
      pageHeading="GenNx CMS - Company"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...BASIC, ...PROCESSING],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/processing/company/:companyId/edit"
      component={DataProcessingCompany}
      pageHeading="GenNx CMS - Company - Processing"
      accessRights={{
        moduleName: "Company",
        parentModuleName: "Company",
        moduleAttribute: [...BASIC, ...ADVANCED, ...COLLABORATION, ...TAXONOMY, ...PROCESSING],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/processing/company/:companyId/product"
      component={DataProcessing}
      pageHeading="GenNx CMS - Product - Processing"
      accessRights={{
        moduleName: "Processing",
        parentModuleName: "Company",
        moduleAttribute: [...PRODUCTS, ...PROCESSING],
        interfaceName: "Products & Technologies",
      }}
    />
    <AuthLayout
      exact
      path="/processing/company/:companyId/product/:productId/edit"
      component={ProductForm}
      pageHeading="GenNx CMS - Product - Processing"
      accessRights={{
        moduleName: "Products & Technology",
        parentModuleName: "Company",
        moduleAttribute: [...OVERVIEW, ...TECHNICAL_SPECIFICATION, ...VALUE_PROPOSITION, ...LICENSING, ...COLLABORATION, ...TAXONOMY],
        interfaceName: "Basic",
      }}
    />

    <AuthLayout
      exact
      path="/processing/company/:companyId/product/add"
      component={ProductForm}
      pageHeading="GenNx CMS - Product - Processing"
      accessRights={{
        moduleName: "Products & Technology",
        parentModuleName: "Company",
        moduleAttribute: [...OVERVIEW],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/data-screening/company"
      component={DataScreeningCompany}
      pageHeading="GenNx CMS - Company - Screening"
      accessRights={{
        moduleName: "Screening",
        parentModuleName: "Company",
        moduleAttribute: [...SELECT, ...REJECT, ...HOLD, ...IMPORT, ...EXPORT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/allocation/company"
      component={DataAllocationCompany}
      pageHeading="GenNx CMS - Company - Allocation"
      accessRights={{
        moduleName: "Allocation",
        parentModuleName: "Company",
        moduleAttribute: [...ALLOCATION],
        interfaceName: "Listing",
      }}
    />
  </Switch>
);

export default companyRoutes;
