import React, { useState, useEffect, useRef } from "react";
import { DropdownList } from "react-widgets";
import PropTypes from "prop-types";
import { fetchVendor } from "../../../../middleware/services/cmsApi";
import { fetchUser } from "../../../../middleware/services/userApi";
import { getProductVariant } from "../../../../utilities";

const AllocationModal = (props) => {
  const { callbackFunc = undefined } = props;
  const saveBtnText = useState("Save");
  const [vendors, setVendors] = useState([]);
  const [users, setUsers] = useState([]);
  const [allocationType, setAllocationType] = useState("PROCESSING_FRESH");
  const [selectedVendor, setSelectedVendor] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [errors, setErrors] = useState({});
  const modalRef = useRef(null);

  const fetchAllocationVendors = (initial = false) => {
    const productVariant = getProductVariant();
    let defaultFilter = [];
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    fetchVendor({ filters: [["vendorActive", "eq", 1], ...defaultFilter], limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;
        const vendorsDropdownValues = [{ id: "No Vendor", vendorName: "Select User" }];
        setVendors([...vendorsDropdownValues, ...data]);
        setSelectedVendor({ id: "No Vendor", vendorName: "Select User" });
        fetchAllocationUsers("No Vendor");
      })
      .catch((err) => {
        console.log("err fetchVendor :: ", err);
      });
  };
  const fetchAllocationUsers = (vendorId = undefined) => {
    let params = { limit: 2000 };
    if (vendorId) {
      const productVariant = getProductVariant();
      let defaultFilter = [];
      if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
      if (vendorId === "No Vendor") {
        params.filters = [["userVendorId", "nex", ""], ["userActive", "eq", 1], ...defaultFilter];
        params.fields = ["id", "userFname", "userLname", "userEmail", "userVendorId"];
      } else {
        params.filters = [["userVendorId", "eq", vendorId], ["userActive", "eq", 1], ...defaultFilter];
        params.fields = ["id", "userFname", "userLname", "userEmail", "userVendorId"];
      }
      console.log("allocationType in fetchAllocationUsers", allocationType);
      if (allocationType == "PROCESSING_FRESH")
        params.filters = [...params.filters, ["userAccess.parentModuleName", "eq", "Data Processing"], ["userAccess.moduleName", "eq", "Company"]];
      if (allocationType == "QC_FRESH")
        params.filters = [...params.filters, ["userAccess.parentModuleName", "eq", "Data Qc"], ["userAccess.moduleName", "eq", "Company"]];
      fetchUser({ ...params })
        .then((res) => {
          let { data = [] } = res.data;
          data.sort((a, b) => (a.userFname + " " + a.userLname > b.userFname + " " + b.userLname ? 1 : -1));
          setUsers([...data]);
        })
        .catch((err) => {
          console.log("err fetchUsers :: ", err);
        });
    }
  };

  const handleReset = () => {
    setAllocationType("PROCESSING_FRESH");
    setSelectedVendor(undefined);
    setSelectedUser(undefined);
    setErrors({});
  };

  const handleSubmit = () => {
    let errorFlag = false,
      errObj = {};
    if (!allocationType) {
      errorFlag = true;
      errObj.allocationType = "Please Select Allocation Type";
    }
    if (!selectedVendor) {
      errorFlag = true;
      errObj.selectedVendor = "Please Select a Vendor for User Allocation";
    }
    if (!selectedUser) {
      errorFlag = true;
      errObj.selectedUser = "Please Select a User to Allocate";
    }
    if (errorFlag) {
      setErrors({ ...errors, ...errObj });
      return false;
    }
    callbackFunc({ userId: selectedUser.id, allocationType: allocationType });
  };

  const radioCheckHandle = (type) => {
    setAllocationType(type);
    setErrors({ ...errors, allocationType: undefined });
  };

  const checkAllocationType = (type) => {
    let result = false;
    if (allocationType && allocationType === type) result = true;
    return result;
  };

  useEffect(() => {
    fetchAllocationVendors();
  }, [allocationType]);

  return (
    <div
      className="modal fade bd-example-modal-lg"
      id={props.allocationModalId}
      tabIndex={-1}
      role="dialog"
      aria-labelledby={props.allocationModalId}
      aria-hidden="true"
      data-backdrop="false"
    >
      <form>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content" style={{ height: "600px" }}>
            <div className="modal-header bg-none text-dark">
              <h5 className="modal-title" id="exampleModalLabel">
                Data Allocation
              </h5>
              <button ref={modalRef} onClick={handleReset} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="col-md-3 float-left">
                    <label className="form-check-label font-weight-bold cursor-pointer pl-2">
                      <input
                        defaultChecked={true}
                        type="radio"
                        className="form-check-input cursor-pointer"
                        name="allocationType"
                        value="PROCESSING_FRESH"
                        onChange={() => radioCheckHandle("PROCESSING_FRESH")}
                        checked={checkAllocationType("PROCESSING_FRESH")}
                      />
                      Processing
                    </label>
                  </div>
                  <div className="col-md-3 float-left">
                    <label className="form-check-label font-weight-bold cursor-pointer">
                      <input
                        type="radio"
                        className="form-check-input cursor-pointer"
                        name="allocationType"
                        value="QC_FRESH"
                        onChange={() => radioCheckHandle("QC_FRESH")}
                        checked={checkAllocationType("QC_FRESH")}
                      />
                      QC
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-danger form-input-alert" role="alert">
                    {errors?.allocationType ? errors?.allocationType : ""}
                  </div>
                </div>
                <div className="col-md-12 pt-3">
                  <div className="row mb-5">
                    <div className="col-6">
                      <DropdownList
                        placeholder="Select Vendor"
                        className="custom-dropdownlist"
                        filter={true}
                        data={[...vendors]}
                        value={selectedVendor}
                        onChange={(value) => {
                          setSelectedVendor(value);
                          fetchAllocationUsers(value.id);
                          setErrors({ ...errors, selectedVendor: undefined });
                        }}
                        textField="vendorName"
                      />
                      <div className="text-danger form-input-alert" role="alert">
                        {errors?.selectedVendor ? errors?.selectedVendor : ""}
                      </div>
                    </div>
                    <div className="col-6">
                      <DropdownList
                        placeholder="Select User"
                        className="custom-dropdownlist"
                        filter={true}
                        data={[...users]}
                        value={selectedUser}
                        onChange={(value) => {
                          setSelectedUser(value);
                          setErrors({ ...errors, selectedUser: undefined });
                        }}
                        textField={(item) => item && item.userFname + " " + item.userLname + " (" + item.userEmail + ")"}
                      />
                      <div className="text-danger form-input-alert" role="alert">
                        {errors?.selectedUser ? errors?.selectedUser : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={handleReset} className={`btn btn-outline-dark cy-btn mr-3`} data-dismiss="modal">
                Cancel
              </button>
              <button type="button" onClick={handleSubmit} className={`btn btn-primary cy-btn`}>
                {saveBtnText}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AllocationModal;

AllocationModal.propTypes = {
  allocationModalId: PropTypes.string.isRequired,
  initialFetch: PropTypes.bool,
  modalForceClose: PropTypes.bool,
  callbackFunc: PropTypes.func,
  modalStateReset: PropTypes.func,
};
