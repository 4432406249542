// import ReactTooltip from "react-tooltip";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionStart, actionSuccess, actionError, showMessage, hideMessage, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import PropTypes from "prop-types";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { Dropdown } from "../CustomFormElements/ProductInformation";
import { uploadDocValidationSchema } from "../../helper/validationSchema/companyValidationSchema";
import { getLoggedInUser, getProductVariant } from "../../../utilities";
import { fetchTaxonomyTechnology, fetchTaxonomyTrend } from "../../../middleware/services/cmsApi";
import { fetchImportTemplateFileUrl, uploadTemplateData, fetchImportErrorFileUrl } from "../../../middleware/services/companyApi";

import _ from "lodash";
import "./style.scss";
import MultiStep from "./multiStep";

const DocumentModal = (props) => {
  // React states for screening

  const [dts, setDts] = useState(false);
  const [linkDisabled2, setLinkDisabled2] = useState(true);
  const [linkDisabled3, setLinkDisabled3] = useState(true);
  const [checkedScreening, setCheckedScreening] = useState([]);
  const [checkedProcessing, setCheckedProcessing] = useState([]);
  const [technology, setTechnology] = useState([]);
  const [oldTechnology, setTechnologyOld] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [oldTrendData, setOldTrendData] = useState([]);
  const [trendDivData, setTrendDivData] = useState(undefined);
  const [techDivData, setTechDivData] = useState(undefined);
  const [techDivDataProcessing, setTechDivDataProcessing] = useState(undefined);
  const [trendDivDataProcessing, setTrendDivDataProcessing] = useState(undefined);
  const tech = props.dts ? "Trend" : "Technology";
  const [errorMsg, setErrorMsg] = useState(`Please select one or more ${tech}`);
  // React states for File Upload
  const [oldData, setOldData] = useState(null);

  const productVariant = getProductVariant();
  const loggedData = getLoggedInUser();
  const userId = loggedData._id;
  // Document Modal Form Components
  const UploadFor = (props) => {
    return (
      <div className="form-group required">
        <label htmlFor="Name">Upload For</label>
        <Dropdown {...props} />
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[props.properties.name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[props.properties.name] === true ? props.formik.errors[props.properties.name] || "" : ""}</small>
        </div>
      </div>
    );
  };

  const Industry = (props) => {
    return (
      <div className="form-group required">
        <label htmlFor="Name">Industry</label>
        <Dropdown {...props} />
        <div
          className="text-danger"
          style={{
            display: props.formik.touched[props.properties.name] === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched[props.properties.name] === true ? props.formik.errors[props.properties.name] || "" : ""}</small>
        </div>
      </div>
    );
  };

  const handleSubmit = () => {
    if (checkedScreening.length && formik.values.uploadFor) {
      const payload = {};
      if (formik.values.uploadFor === "Trend") {
        payload.trendId = [...checkedScreening];
      } else {
        payload.dtId = [...checkedScreening];
      }
      formik.setValues({ ...formik.values, screeningData: { ...payload } });
    }
    return true;
  };
  const UploadComponent = (props) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      // accept: "image/*",
      accept: ".xls,.xlsx",
      maxFiles: 1, //comment this line if upload multiple File
      onDrop: (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length) {
          setLinkDisabled2(false);
          setLinkDisabled3(false);
          props.formik.setValues({
            ...props.formik.values,
            documentFile: acceptedFiles,
          });
        }
      },
    });
    return (
      <>
        <div className="form-group bg-light p-3 border-dotted mb-1" {...getRootProps()}>
          <input {...getInputProps()} />
          <button type="button" className="btn btn-dark">
            Upload Data Template
          </button>{" "}
          or drop file here
        </div>
        <small>Only xls, xlxs files are allowed.</small>

        <div
          className="text-danger"
          style={{
            display: props.formik.touched.documentFile === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>{props.formik.touched.documentFile === true ? props.formik.errors.documentFile || "" : ""}</small>
        </div>
      </>
    );
  };

  const initialFormValues = {
    uploadFor: "",
    selIndustry: "",
    documentFile: "",
    documentFileName: "",
  };

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(uploadDocValidationSchema),
  });

  const dispatch = useDispatch();
  const fetchTrendData = () => {
    let filters = [];
    filters = [["isTrendNode", "eq", 1]];
    // dispatch(showLoader());
    if (formik.values.uploadFor === "Trend") {
      if (!oldTrendData.length) {
        fetchTaxonomyTrend({
          filters: filters,
          fields: ["trendId", "trendName", "trendParentName", "trendSubRoot"],
          sort: "trendName:asc",
          limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
        })
          .then((res) => {
            if (res.status === 200) {
              let { data = [] } = res.data;
              setTrendData(data);
              setOldTrendData(data);
              const industryData = {};
              data.forEach((item) => {
                if (item.trendSubRoot == 0) {
                  if (typeof industryData[item.trendParentName] == "undefined") industryData[item.trendParentName] = [];
                  industryData[item.trendParentName].push(item.id);
                }
              });
              setIndustry(industryData);
              // dispatch(hideLoader());
            } else {
              console.error("err fetchTrend :: ");
            }
            // fetchTaxonomy(data[0].id);
          })
          .catch((err) => {
            console.error("err fetchTrend :: ", err);
          });
      } else {
        trendDiv(oldTrendData);
      }
    }
  };

  const fetchTechnologyData = () => {
    let filters = [];
    filters = [["dtParentId", "eq", 0]];
    // dispatch(showLoader());
    if (formik.values.uploadFor === "Technology") {
      if (!oldTechnology.length) {
        fetchTaxonomyTechnology({
          filters: filters,
          fields: ["dtId", "dtName"],
          sort: "dtName:asc",
          limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
        })
          .then((res) => {
            if (res.status === 200) {
              let { data = [] } = res.data;
              setTechnology(data);
              setTechnologyOld(data);
              techDiv(data);
              techDivProcessing(data);
              // dispatch(hideLoader());
            } else {
              console.error("err fetchTechnology :: ");
            }
            // fetchTaxonomy(data[0].id);
          })
          .catch((err) => {
            console.error("err fetchTechnology :: ", err);
          });
      } else {
        techDiv(oldTechnology);
      }
    }
  };
  // const fetchTrendDataProcessing = () => {
  // 	if(formik.values.uploadFor === "Trend"){
  // 	trendDivProcessing(oldTrendData);
  // 	}
  // };

  const fetchTechnologyDataProcessing = () => {
    if (formik.values.uploadFor === "Technology") {
      techDivProcessing(oldTechnology);
    }
  };
  const techDiv = (data) => {
    const divData = data
      ? data.map(function (value) {
          return (
            <li
              // className={`list-item col-4 border-top py-2`}
              className={`border-top border-left-0 border-right-0 list-group-item
				 ${checkedProcessing.includes(value.id) ? "disabled" : ""}`}
              key={value.dtName}
            >
              <input
                type="checkbox"
                className="mr-1"
                id={value.id}
                name={value.dtName}
                value={value.id}
                defaultChecked={checkedScreening.indexOf(value.id) != -1 ? true : false}
                key={value.id}
                onChange={(e) => {
                  handleToggle(value.id);
                }}
              />
              {value.dtName}
              <br />
              {checkedProcessing.includes(value.id) ? (
                <span style={{ fontSize: ".7rem" }} className="p-1 text-danger">
                  (already selected for processing)
                </span>
              ) : (
                ""
              )}
            </li>
          );
        })
      : "";

    setTechDivData(divData);
  };

  const trendDiv = (data) => {
    const divData = data
      ? data.map(function (value) {
          if (formik.values.selIndustry === value.trendParentName) {
            return (
              <li
                className={`border-top border-left-0 border-right-0 list-group-item
				 ${checkedProcessing.includes(value.id) ? "disabled" : ""}`}
                key={value.trendName}
              >
                <input
                  type="checkbox"
                  className="mr-1"
                  id={value.id}
                  name={value.trendName}
                  value={value.id}
                  defaultChecked={checkedScreening.indexOf(value.id) != -1 ? true : false}
                  key={value.id}
                  onChange={() => {
                    handleToggle(value.id);
                  }}
                />
                {value.trendName}
                <br />
                {checkedProcessing.includes(value.id) ? (
                  <span style={{ fontSize: ".7rem" }} className="p-1 text-danger">
                    (already selected for processing)
                  </span>
                ) : (
                  ""
                )}
              </li>
            );
          }
        })
      : "";

    setTrendDivData(divData);
  };

  const techDivProcessing = (data) => {
    const divData = data
      ? data.map(function (value) {
          return (
            <li
              className={`border-top border-left-0 border-right-0 list-group-item
			 ${checkedScreening.includes(value.id) ? "disabled" : ""}`}
              key={value.dtName}
            >
              <input
                type="checkbox"
                className="mr-1"
                id={value.id}
                name={value.dtName}
                value={value.id}
                defaultChecked={checkedProcessing.indexOf(value.id) != -1 ? true : false}
                key={value.id}
                onChange={(e) => {
                  handleToggle(value.id, 3);
                }}
              />
              {value.dtName}
              <br />
              {checkedScreening.includes(value.id) ? (
                <span style={{ fontSize: ".7rem" }} className="p-1 text-danger">
                  (already selected for screening)
                </span>
              ) : (
                ""
              )}
            </li>
          );
        })
      : "";

    setTechDivDataProcessing(divData);
  };

  const trendDivProcessing = (data) => {
    const divData = data
      ? data.map(function (value) {
          if (formik.values.selIndustry === value.trendParentName) {
            return (
              <li
                className={`border-top border-left-0 border-right-0  list-group-item
				 ${checkedScreening.includes(value.id) ? "disabled" : ""}`}
                key={value.trendName}
              >
                <input
                  type="checkbox"
                  className="mr-1"
                  id={value.id}
                  name={value.trendName}
                  value={value.id}
                  defaultChecked={checkedProcessing.indexOf(value.id) != -1 ? true : false}
                  key={value.id}
                  onChange={() => {
                    handleToggle(value.id, 3);
                  }}
                />
                {value.trendName}
                <br />
                {checkedScreening.includes(value.id) ? (
                  <span style={{ fontSize: ".7rem" }} className="p-1 text-danger">
                    (already selected for screening)
                  </span>
                ) : (
                  ""
                )}
              </li>
            );
          }
        })
      : "";

    setTrendDivDataProcessing(divData);
  };
  const handleToggle = (value, step = 2) => {
    if (step === 3) {
      const currentIndex = checkedProcessing.indexOf(value);
      const newChecked = [...checkedProcessing];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setCheckedProcessing(newChecked);
    } else {
      const currentIndex = checkedScreening.indexOf(value);
      const newChecked = [...checkedScreening];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setCheckedScreening(newChecked);
    }
  };
  useEffect(() => {
    let isMounted = false;
    if (!isMounted) {
      fetchTrendData();
      fetchTechnologyData();
      // fetchTrendDataProcessing();
      fetchTechnologyDataProcessing();
    }
    if (formik.values.selIndustry !== "" && formik.values.uploadFor === "Trend") {
      trendDiv(oldTrendData);
      trendDivProcessing(oldTrendData);
    }
    return () => {
      dispatch(setSideNavForcedActiveLink());
      isMounted = true;
    };
  }, [checkedScreening, checkedProcessing, formik.values.uploadFor, formik.values.selIndustry]);

  const onSaveData = async (data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await uploadTemplateData(formData)
      .then((result) => {
        if (result.status === 200) {
          const { status, msg, fileName = undefined } = result.data.data;
          if (status === "error") {
            dispatch(actionError(msg));
            return result.data.data;
          } else {
            dispatch(actionSuccess("Data uploaded successfully"));
            return result.data.data;
          }
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err ? err.data.message : "Something went wrong"));
        return { status: "failed" };
      });
    return response;
  };

  const handleFinalSubmit = async () => {
    // if (!(checkedScreening.length || checkedProcessing.length) && formik.values.uploadFor) {
    // 	dispatch(actionError(errorMsg));
    // }
    const payload = {};
    if (formik.values.uploadFor) {
      if (formik.values.uploadFor === "Trend") {
        payload.trendId = [...checkedProcessing];
      } else {
        payload.dtId = [...checkedProcessing];
      }
    }
    const { values } = formik;

    const uploadData = {
      docFile: values.documentFile[0],
      screeningData: JSON.stringify(values.uploadFor === "Trend" ? { trendId: [...checkedScreening] } : { dtId: [...checkedScreening] }),
      processingData: JSON.stringify(payload),
      uploadFor: values.uploadFor,
    };
    if (!(checkedScreening.length || checkedProcessing.length)) {
      dispatch(actionError("Please select data for screening or processing on step 2 or 3"));
      return;
    }

    await onSaveData(uploadData)
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            formik.setErrors(res);
            dispatch(actionSuccess(res.msg));
          } else {
            console.error("ERRR", res);
            // formik.setFormikState({ ...formik, touched: true, errors: res });
            formik.setErrors(res);
          }
        }
      })
      .catch((err) => {
        dispatch(actionError(err));
        console.error("QRRR", err);
      });
  };
  const handleSubmitDocument = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      }
    });
  };
  const StepOne = (props) => {
    // const valueArr =  productVariant && productVariant === "Insider" ? ["Trend"]: ["Technology"];
    // const valueArr = ["Technology", "Trend"];
    if (formik.values.uploadFor === "")
      formik.setValues({
        ...formik.values,
        uploadFor: productVariant && productVariant === "Insider" ? "Trend" : "Technology",
      });
    return (
      <>
        <form>
          <div className="row">
            <div className="col-md-6">
              {productVariant && productVariant === "Insider" && Object.keys(industry).length > 0 && (
                // formik.values.uploadFor === "Trend" &&
                <Industry
                  formik={formik}
                  required="required"
                  valueArr={[...Object.keys(industry)]}
                  dropUp
                  properties={{
                    placeholder: "Select Industry",
                    id: "selIndustry",
                    name: "selIndustry",
                    filter: false,
                    dropUp: false,
                    // value:"Technology",
                  }}
                />
              )}
            </div>
            <div className="col-md-6 p-4">
              <a style={{ float: "right" }} href={fetchImportTemplateFileUrl()} download>
                {" "}
                <i className="fas fa-download" data-tip="Download" />
                Download Template
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <UploadComponent editDocumentData={props.editDocumentData} formik={formik} />
              {formik.values.documentFile &&
                formik.values.documentFile.map((file, i) => (
                  <li key={i}>
                    {`File:${file.name}
												`}{" "}
                  </li>
                ))}
            </div>
          </div>
        </form>
      </>
    );
  };

  const StepTwo = (props) => {
    const filterList = (e) => {
      if (props.dts) {
        const updatedList = trendData.filter((item) => {
          return item.trendName.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
        });
        e.target.value.length ? setTrendData(updatedList) : setTrendData(oldTrendData);
      } else {
        const updatedList = technology.filter((item) => {
          return item.dtName.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
        });
        e.target.value.length ? setTechnology(updatedList) : setTechnology(oldTechnology);
      }
    };
    return (
      <>
        <div className="row " style={{ display: "none" }}>
          <div className="col-md-12">
            {/* {<p className="text-secondary">Note: {errorMsg}</p>} */}
            <label>Note: {errorMsg}</label>
            <div className="form-group has-search">
              <span className="fa fa-search form-control-feedback"></span>
              <input className="form-control" type="text" placeholder="Search..." onChange={filterList} />
            </div>
          </div>
        </div>

        <div className="col-12 container">
          <ul className={`list-unstyled limheight${formik.values.uploadFor === "Trend" ? "-trend" : "-tech"}`}>
            {formik.values.uploadFor === "Trend" ? trendDivData : techDivData}
          </ul>
        </div>
      </>
    );
  };
  const StepThree = (props) => {
    const filterList = (e) => {
      if (props.dts) {
        const updatedList = trendData.filter((item) => {
          return item.trendName.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
        });
        e.target.value.length ? setTrendData(updatedList) : setTrendData(oldTrendData);
      } else {
        const updatedList = technology.filter((item) => {
          return item.dtName.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
        });
        e.target.value.length ? setTechnology(updatedList) : setTechnology(oldTechnology);
      }
    };
    return (
      <>
        <div className="row " style={{ display: "none" }}>
          <div className="col-md-12">
            {/* {<p className="text-secondary">Note: {errorMsg}</p>} */}
            <label>Note: {errorMsg}</label>
            <div className="form-group has-search">
              <span className="fa fa-search form-control-feedback"></span>
              <input className="form-control" type="text" placeholder="Search..." onChange={filterList} />
            </div>
          </div>
        </div>

        <div className="col-12 container">
          <ul className={`list-unstyled limheight${formik.values.uploadFor === "Trend" ? "-trend" : "-tech"}`}>
            {formik.values.uploadFor === "Trend" ? trendDivDataProcessing : techDivDataProcessing}
          </ul>
        </div>
      </>
    );
  };

  const steps = [
    {
      id: 1,
      step: 1,
      name: "Upload",
      handleSubmitDocument: handleSubmitDocument,
      component: <StepOne />,
      isLine: true,
      linkDisabled: false,
    },
    {
      id: 2,
      step: 2,
      name: "Screening",
      handleSubmit: handleSubmit,
      component: <StepTwo />,
      isLine: true,
      linkDisabled: linkDisabled2,
    },
    {
      id: 3,
      step: 3,
      name: "Processing",
      handleFinalSubmit: handleFinalSubmit,
      component: <StepThree />,
      isLine: true,
      linkDisabled: linkDisabled3,
    },
  ];

  return (
    <>
      <MultiStep show={props.show} closeHandler={props.closeHandler} formik={formik} showNavigation={true} steps={steps} />
    </>
  );
};

export default DocumentModal;
DocumentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
};
