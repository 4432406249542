/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  News Technology Tagging Component 
	 Summary : - Import Technology tree view from common component
	 - Save selected technology data
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 20/Aug/2020 
	Author						: Aditya Tijare
================================================================ 
*/
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TreeView from "../../../../../components/treeView/taxonomy";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import {
  saveNewsTaxonomy,
  deleteNewsTaxonomy,
  fetchSingleNews,
  saveNewsValueChain,
  fetchNewsTaxonomy,
  fetchNewsKia,
  deleteNewsKia,
  saveNewsKiaTaxonomy,
  deleteManyNewsTechnology,
  deleteManyNewsValueChain,
} from "../../../../../../middleware/services/newsApi";
const TaxomonyUI = (props) => {
  const dispatch = useDispatch();
  const newsId = props.newsId;

  const handleSave = (selectedTech, checkValues, tab, callback) => {
    if (tab === 0) {
      saveTaxonomy(selectedTech, checkValues, callback);
    }
  };

  /*
	@Description : If saveNews Sate is true then will trigger technology save event.
	Save news state change trigger from footerBar Component
	*/
  useEffect(() => {
    if (props.saveNews) {
      props.handleSave(false);
    }
  }, [props.saveNews]);
  /*
	@param selectedIndustry : Selected Industry to save their respective kia
	@param kiaIds : Selected KiaIds
	@param callback : use for re-render tree component
	
	@description : Save kia for respective industry
				1.Delete all kia for selected industry
				2.Save all kia for selected industry
	*/
  const saveKiaTagging = (selectedIndustry, kiaIds, tab, callback) => {
    if (selectedIndustry) {
      dispatch(showLoader());
      deleteNewsKia(newsId, { industryId: selectedIndustry })
        .then(() => {
          dispatch(hideLoader());
          if (kiaIds.length > 0) {
            dispatch(showLoader());
            try {
              const response = saveNewsKiaTaxonomy(newsId, { taxonomyIds: kiaIds });
              response
                .then((response) => {
                  dispatch(hideLoader());
                  callback();
                  // callback2(selectedIndustry)
                  dispatch(actionSuccess("KIA Updated Successfully"));
                })
                .catch((e) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...1!"));
                });
            } catch (e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...2!"));
            }
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...3!"));
        });
    }
  };
  /*
	/*
	@param selectedTech : Selected technology to save their respective taxonomy
	@param taxonomyIds : Selected taxonomy
	@param callback : use for re-render tree component
	
	@description : Save taxonomy for respective technology
				1.Delete all taxonomy for selected technology
				2.Save all taxonomy for selected technology
	*/
  const saveTaxonomy = (selectedTech, taxonomyIds, callback) => {
    if (selectedTech) {
      dispatch(showLoader());
      deleteNewsTaxonomy(newsId, selectedTech)
        .then(() => {
          dispatch(hideLoader());
          if (taxonomyIds.length > 0) {
            dispatch(showLoader());
            try {
              const response = saveNewsTaxonomy(newsId, { taxonomyIds: taxonomyIds });
              response
                .then((response) => {
                  dispatch(hideLoader());
                  dispatch(actionSuccess("Taxonomy Updated Successfully"));
                  callback();
                })
                .catch((e) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...!"));
                });
            } catch (e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            }
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    }
  };
  /*
	@param selectedTech : Selected technology to save their respective taxonomy
	@param checkValues : Selected value chain
	@param callback : use for re-render tree component
	
	@description : Save value chain for respective industry
	*/
  const handleSaveValueChain = (selectedTech, checkValues, callback1, callback2) => {
    dispatch(showLoader());
    const response = saveNewsValueChain(newsId, { ...checkValues });
    response
      .then((response) => {
        dispatch(hideLoader());
        callback1();
        dispatch(actionSuccess("Value Chain Updated Successfully"));
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  const deleteTechnology = (checkedTrends, callback) => {
    deleteManyNewsTechnology(newsId, checkedTrends)
      .then((res) => {
        callback();

        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };
  const deleteKia = (checkedTrends, callback, callback2, selectedTech) => {
    let payload = { taxonomyIds: checkedTrends };
    deleteNewsKia(newsId, payload)
      .then(() => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };
  const deleteValueChain = (payload, callback) => {
    deleteManyNewsValueChain(newsId, payload)
      .then(() => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  return (
    <TreeView
      handleSave={saveTaxonomy}
      saveTreeData={props.saveNews}
      contentId={newsId}
      fetchContentTagging={fetchNewsTaxonomy}
      fetchContentData={fetchSingleNews}
      fetchKiaTagging={fetchNewsKia}
      loadOtherTagInfo={props.loadOtherTagInfo}
      handleSaveKIA={saveKiaTagging}
      handleSaveValueChain={handleSaveValueChain}
      contentType="news"
      deleteTechnology={deleteTechnology}
      isDelete={true}
      setSaveTreeData={props.setSaveNews}
      deleteKia={deleteKia}
      deleteValueChain={deleteValueChain}
      accessPermissionTaxonomyDelete={props?.accessPermissionTaxonomyDelete}
      accessPermissionTaxonomy={props?.accessPermissionTaxonomy}
    />
  );
};

export default TaxomonyUI;
