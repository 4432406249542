/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 15/Mar/2021
 * File Description: Survey Form Action Button
 */

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getLoggedInUser, getProductVariant, accessRightActionCheck } from "../../../../utilities";
import { actionSuccess, actionError } from "../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { publishVideo } from "../../../../middleware/services/cmsApi";

const ActionButtons = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = getLoggedInUser();
  const productVariant = getProductVariant();
  const videoId = props.videoId || "";

  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = Object.values(props.hashArr)[props.selectedIndex];
    let actionAccess = accessRightActionCheck(props.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [props.selectedIndex]);

  let actionAccessSave = interfaceActionAccess.includes("Save");
  let actionAccessPublish = interfaceActionAccess.includes("Publish");

  const publish = async () => {
    const videoId = props.videoId;
    await await publishVideo(videoId, { userId: loggedInUser._id, platform: productVariant })
      .then((res) => {
        if (res) {
          dispatch(actionSuccess("Video has been published successfully."));
          props.fetchVideoDetails();
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        // let errMessage = "Video cannot published : <br>";
        // const errMessageArr = err?.data?.message ? err.data.message.split(",") : ["Something went wrong"];
        // errMessage += errMessageArr
        //   .map((value, index) => {
        //     return ++index + "." + value + "<br>";
        //   })
        //   .join("");
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
  };
  return (
    <div className="form-submit-box" style={{ padding: "10px 24px 10px 20px" }}>
      <div className="float-left col-md-4 text-left">
        {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
          <span className="pl-4" style={{ "margin-left": "-2.8em" }}>
            <span className="span-link">
              <button
                type="button"
                className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                onClick={() => {
                  (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                    (props.selectedIndex < 6 && props.setCurrentTab(props.selectedIndex - 1));
                }}
              >
                Previous
              </button>
            </span>
          </span>
        ) : null}

        {videoId !== "" && props.selectedIndex > -1 && props.selectedIndex < 5 ? (
          <span className="pl-4" style={{ "margin-left": props.selectedIndex === 0 ? "-2.9em" : "0" }}>
            <span className="span-link">
              <button
                type="button"
                className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                onClick={() => {
                  props.selectedIndex < 5 && props.setCurrentTab(props.selectedIndex + 1);
                }}
              >
                Next
              </button>
            </span>
          </span>
        ) : null}
      </div>
      <div className="container-fluid nopadding" style={{ "margin-left": "0.6em" }}>
        <div className="row">
          <div className="float-right col-md-12 text-right">
            <Link to={"/video" || history.location.pathname}>
              <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 ">
                Cancel
              </button>
            </Link>

            {actionAccessSave && props.handleSave && props.selectedIndex != 4 && props.selectedIndex != 5 ? (
              <button type="button" className="btn btn-primary cy-btn pl-4 pr-4  ml-3" id="dev-objective-save" onClick={props.handleSave}>
                Save
              </button>
            ) : null}

            {actionAccessPublish && props.showPublished ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 ml-3 "
                onClick={() => {
                  dispatch(
                    showAlertBox({
                      okCallback: async () => {
                        await publish();
                      },
                      okText: "Publish",
                      cancelText: "Cancel",
                      content: "Are you sure you want to publish video?",
                      title: "dialogAlertCss",
                    })
                  );
                }}
              >
                Publish
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
