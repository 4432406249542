export const leftPaneHtml = `
<div style="font-size:14px">
WhatNext would like to invite you to participate and contribute to a gated panel/community of experts advising CTO, Innovation and Future Strategy Functions of Fortune 500 companies globally.
<br/>
<br/>
<b>Our Gated “Community of Experts”</b> will help companies stay a step ahead of digital disruption. This gated community is curated and moderated by WhatNext, a digital transformation platform as a service that works with over 250 of the global Fortune 500 (e.g. Nissan Motors, Daimler, Mitsubishi, engie, Wacker, BASF) to connect them with exponential digital technology ecosystems for optimisation and automation of internal processes, new business models & product development, and business growth.
<br/>
<br/>
As part of the gated community, <b>you will participate in 10-15 minute surveys 2-3 times a year</b>. Any qualitative explanations provided by you to justify your ratings will be published with your photographs
<br/>
<br/>
<b>Why you should be a part of the WhatNext Expert Panel and Community…</b><br/>
<ul>
<li>   	Opportunity to do paid consultation and guide Fortune 500 companies through their digital transformation/Industry 4.0 Transformation journey? Consult and guide over 19,000 start-ups that are working on various disruptive applications using technologies such as AI, IOT, 3D Printing etc. </li>
<li>  	Reputation amongst Peers - You will be part of an exclusive global “Community of Experts” that will forecast and predict the potential influence of digital exponential technologies on industries</li>
<li>    Participate in or Conduct webinars and host / participate in webinars and panel discussions  on the WhatNext Platform - attended by senior executives from Industry</li>
<li>    Your opinion, aggregated with the opinion with other experts, will be accessible to industry players as input for their strategic and tactical decisions for digitalisation, industry transformation and disruption.</li>
<li>    As part of the gated community, you will also get exclusive access to insights gathered from WhatNext Research.</li>
</ul>

<div>
</div>`;