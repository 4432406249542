/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Utility State for Loader, Toast Notification, Messages
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import {
	ACTION_START, ACTION_STOP, ACTION_SUCCESS, ACTION_ERROR, SHOW_MESSAGE, HIDE_MESSAGE,
	SHOW_LOADER, HIDE_LOADER
} from "../../constants/actionConstants";

const DEFAULT_STATE = {
	error: "",
	loading: false,
	message: "",
	toast: "",
};

export default (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case ACTION_START: {
			return {
				...state, loading: true, error: "", message: "", toast: ""
			};
		}
		case ACTION_STOP: {
			return {
				...state, loading: false, error: "", message: "", toast: ""
			};
		}
		case ACTION_SUCCESS: {
			return {
				...state, loading: false, error: "", message: action.payload.message, toast: action.payload.toast
			};
		}
		case ACTION_ERROR: {
			return {
				...state, loading: false, error: action.payload.error, message: action.payload.message, toast: action.payload.toast
			};
		}
		case SHOW_MESSAGE: {
			return {
				...state, loading: false, error: "", message: action.payload.message, toast: action.payload.toast
			};
		}
		case HIDE_MESSAGE: {
			return {
				...state, loading: false, error: "", message: "", toast: ""
			};
		}
		case SHOW_LOADER: {
			return {
				...state, loading: true, error: "", message: "", toast: ""
			};
		}
		case HIDE_LOADER: {
			return {
				...state, loading: false, error: "", message: "", toast: ""
			};
		}
		default:
			return state;
	}
};
