/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News Content Add | Edit Page
     Summary : Use vvvebjs builder for news content editor
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 21/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React from "react";
import BasicContent from "./basic";
const Content = (props) => {
  return (
    <>
      <div
        className="gennx-content-wrapper padding-bottom-150i pt-3"
        style={{ width: "99.5%" }}
      >
        <div className="mx-2">
          {/* <ul className="nav nav-pills nav-justified">
                <li className="nav-item">
                    <span className="nav-link span-link active">Basic</span>
                </li>
                <li className="nav-item">
                    <span className="nav-link span-link">Advance</span>
                </li>
            </ul>
            <AdvanceEditor {...props} /> */}
          <BasicContent {...props} />
        </div>
      </div>
    </>
  );
};
export default Content;
