/**
 * @author: Anand Kumar
 * @date : 04/Jan/2021
 * File Description:Custom Form Basic Detials Page
 */

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TextField, Dropdown } from "../../components/CustomFormElements/syncFusionFormFields";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { getLoggedInUser, getProductVariant } from "../../../utilities";
import { actionSuccess, actionError, hideLoader } from "../../../middleware/actions/utilityAction";
import { businessObjectiveValidationSchema } from "../../helper/validationSchema/businessObjectiveValidationSchema";
import {
  saveCustomForms,
  fetchCustomForms,
  fetchCustomFormById,
  updateCustomForms,
  publishCustomForm,
  fetchConfig,
} from "../../../middleware/services/cmsApi";
import PlaceholderUI from "./placeHolders";
import FORM_CONSTANTS from "./constants";
import "./css/customForm.scss";
import AccessDenied from "../../components/accessDenied";
import { COMMON_ACCESS_ACTION } from "../../../constants";

const initialState = {
  formTitle: "",
  formUniqueName: "",
  formUrl: FORM_CONSTANTS.CUSTOM_FORM_REGISTRATION_DOMAIN,
  formDescription: "",
  formPlaceholder: [],
  formType: null,
};

const CustomFormBasicDetails = (props) => {
  const dispatch = useDispatch();
  const formId = props.formId || "";
  const [editData, setEditData] = useState(null);
  const [placeHolderData, setPlaceHolder] = useState([]);
  const history = useHistory();
  const productVariant = getProductVariant();
  const loggedInUser = getLoggedInUser();

  /* Common access */
  const accessActionSave = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionPublish = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.PUBLISH);

  const formik = useFormik({
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(businessObjectiveValidationSchema),
  });

  const breadCrumbLinks = [
    { linkUrl: "/custom-forms", linkName: props?.moduleName || "", linkActive: false },
    {
      linkUrl: formId ? `/custom-forms/${formId}/basic-details#basic` : "/custom-forms/basic-details#basic",
      linkName: editData?.formTitle || "Add New",
      linkActive: false,
    },
    {
      linkUrl: `/custom-forms/form-designer${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  const fetchBasicDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = ["formPlaceholder", "formTitle", "formUniqueName", "formUrl", "formType", "productVariant"];
    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    fetchCustomFormById(formId, params).then((res) => {
      let data = res.data.data || {};
      setEditData(data);
      formik.setValues({
        formTitle: data.formTitle || "",
        formUniqueName: data.formUniqueName || "",
        formUrl: data.formUrl || "",
        formType: data.formType || "survey",
      });

      dispatch(hideLoader());
    });
  };
  const publishForm = async () => {
    await publishCustomForm(formId)
      .then((res) => {
        if (res) {
          dispatch(actionSuccess("Form has been published successfully"));
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
  };

  const isExistUniqueName = (e) => {
    const value = e.value;
    value &&
      fetchCustomForms({ filters: [["formUniqueName ", "eq", value]], fields: ["formUniqueName"] }).then((res) => {
        if (res && res.status === 200) {
          if (res.data.totalCount) {
            formik.setFieldValue("formUniqueName", "", true);
            formik.setFieldError("formUniqueName", `Form unique name '${value}' already exists`);
            formik.setFieldTouched("formUniqueName", true);
            dispatch(actionError(`Form unique name <b>${value}</b> already exists`));
          } else {
            formik.setFieldValue("formUrl", `${FORM_CONSTANTS.CUSTOM_FORM_REGISTRATION_DOMAIN}/${value.trim().toLowerCase()}`, true);
          }
        }
      });
  };
  const getPlaceHolder = async () => {
    let filters = productVariant === "WhatNext" ? [["config_name", "eq", "WhatNext-Setting"]] : [["config_name", "eq", "Insider-Setting"]];
    const params = {
      filters,
      fields: ["configValue"],
      limit: 1000,
    };
    await fetchConfig(params)
      .then((res) => {
        let data = res?.data?.data || [];
        console.log("config", data[0].configValue.boFormPlaceholder);
        setPlaceHolder(data[0]?.configValue?.boFormPlaceholder || []);
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
  };

  useEffect(() => {
    getPlaceHolder();
    if (formId) {
      dispatch(hideLoader());
      if (!editData) fetchBasicDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/custom-forms"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, props.selectedIndex, editData, props.location.hash]);

  const saveBasicFormDetails = async () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const { formDescription, formTitle, formUniqueName, formUrl, formPlaceholder, formType } = formik.values;
        payload.formTitle = formTitle;
        payload.formUniqueName = formUniqueName.trim().toLowerCase();
        payload.formUrl = formUrl.trim().toLowerCase();
        payload.formType = formType;
        if (formPlaceholder && formPlaceholder.length) {
          payload.formPlaceholder = formPlaceholder.map((item) => {
            let data = placeHolderData.find((v) => v.placeholderId === item);
            return {
              placeholderName: data.placeholderDispalyName,
              placeholderId: data.placeholderId,
            };
          });
        } else {
          payload.formPlaceholder = [];
        }
        payload.formCreatedBy = loggedInUser._id;
        if (formDescription) {
          payload.formDescription = formDescription;
        }

        if (formId) {
          payload.productVariant = [{ productName: productVariant }];
          updateCustomForms(formId, payload)
            .then((res) => {
              dispatch(actionSuccess("Basic details updated successfully"));
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "Something went wrong"));
            });
        } else {
          payload.productVariant = [{ productName: productVariant, isLive: "NO" }];
          saveCustomForms(payload)
            .then((res) => {
              if (res) {
                dispatch(actionSuccess("Form Basic details saved successfully"));

                history.push({
                  pathname: `/custom-forms/${res.data.data.id}/form-designer`,
                  hash: "basic",
                  state: { formId: res.data.data.id },
                });
                props.newRecord();
              } else {
                dispatch(actionError("something went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "something went wrong"));
            });
        }
      }
    });
  };

  return (
    <>
      {props?.interfaceAccess ? (
        <div>
          <div className="gennx-content-wrapper pt-3" style={{ width: "99.5%", overflow: "hidden" }}>
            <div
              className="pt-3 mx-2 gennx-content-wrapper padding-bottom-150i"
              style={{ padding: "1%", marginTop: "1%", "background-color": "#ffffff", width: "99.1%", "border-radius": "5px" }}
            >
              <div id="content-box">
                <div className="container-fluid mb-3">
                  <div className="p-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <TextField formik={formik} type="text" id="formTitle" placeholder="Form Title *" name="formTitle" />
                          </div>
                          <div className="col-md-4">
                            {FORM_CONSTANTS.FORM_TYPE_OPTIONS && (
                              <Dropdown
                                formik={formik}
                                readonly={formId && formik.values.formType && formik.values.formType === "registration" ? true : false}
                                sortOrder="Acending"
                                // showSelectAll={true}
                                placeholder="Select Form Type *"
                                id="formType"
                                name="formType"
                                fields={{ text: "label", value: "value" }}
                                dataSource={FORM_CONSTANTS.FORM_TYPE_OPTIONS}
                              />
                            )}
                          </div>
                          {formik.values?.formType && formik.values?.formType === "sbo" ? (
                            <PlaceholderUI formik={formik} editData={editData} placeHolderData={placeHolderData} />
                          ) : null}

                          {formik.values.formType && formik.values.formType === "registration" && (
                            <div className="col-md-4 formUniqueName">
                              <TextField
                                formik={formik}
                                type="text"
                                id="formUniqueName"
                                name="formUniqueName"
                                placeholder="Form Unique Name *"
                                autoComplete="off"
                                readOnly={formId ? true : false}
                                blur={isExistUniqueName}
                                showClearButton={false}
                              />
                            </div>
                          )}

                          {formik.values.formType && formik.values.formType === "registration" && (
                            <div className="col-md-4 formUrl">
                              <TextField
                                formik={formik}
                                readOnly
                                type="text"
                                id="formUrl"
                                name="formUrl"
                                placeholder="Form URL *"
                                autoComplete="off"
                                showClearButton={false}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-submit-box">
            <div className="container-fluid nopadding">
              <div className="row">
                <div className="float-LEFT col-md-6 text-left">
                  .
                  {formId !== "" && props.selectedIndex > -1 ? (
                    <span className="pl-4" style={{ marginLeft: "-3.2em" }}>
                      <span className="span-link">
                        <button
                          type="button"
                          className="cy-next-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                          onClick={() => {
                            props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                          }}
                        >
                          Next
                        </button>
                      </span>
                    </span>
                  ) : null}
                </div>
                <div className="float-right col-md-6 text-right">
                  <Link to={"/custom-forms" || history.location.pathname}>
                    <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                      Cancel
                    </button>
                  </Link>
                  {accessActionSave && (
                    <button
                      type="button"
                      className="btn btn-primary cy-btn pl-4 pr-4 mr-3"
                      onClick={() => {
                        saveBasicFormDetails();
                      }}
                    >
                      {formId ? "Update" : "Save"}
                    </button>
                  )}
                  {formId && accessActionPublish ? (
                    <button
                      type="button"
                      className="btn btn-primary cy-btn pl-4 pr-4"
                      onClick={() => {
                        dispatch(
                          showAlertBox({
                            okCallback: async () => {
                              await publishForm();
                            },
                            content: "Are you sure you want to Publish?",
                            okText: "Publish",
                            cancelText: "Cancel",
                            title: "dialogAlertCss",
                          })
                        );
                      }}
                    >
                      Publish
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default CustomFormBasicDetails;
