/*
================================================================
    Copyright � 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Survey Action
----------------------------------------------------------------
    Creation Details
    Date Created				: 02/Dec/2022
    Author						: Ronak Jain
================================================================
*/

// Maintain basic details from the survey basic tab
export const setBasicDetails = (data) => ({
    type: "MAINTAIN_BASIC_DETAILS",
    payload: data,
  });
  
  // Maintain design details from the survey design tab
  export const setDesign = (data) => ({
    type: "MAINTAIN_DESIGN_DETAILS",
    payload: data,
  });
  
  // Maintain invitation details from the survey invitation tab
  export const setInvitation = (data) => ({
    type: "MAINTAIN_INVITATION_DETAILS",
    payload: data,
  });
  
  // Maintain acknowledgement details from the survey acknowledgement tab
  export const setAcknowledgement = (data) => ({
    type: "MAINTAIN_ACKNOWLEDGEMENT_DETAILS",
    payload: data,
  });
  
  // Maintain taxonomy details from the survey Taxonomy tab
  export const setTaxonomy = (data) => ({
    type: "MAINTAIN_TAXONOMY_DETAILS",
    payload: data,
  });
  export const updateSurveyTaxonomyId = (data) => ({
    type: "UPDATE_SURVEY_TAXONOMY_INDUSTRY",
    payload: data,
  });
  
  //Reset redux
  export const resetRedux = () => ({
    type: "RESET_REDUX",
  });
  