/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 25/Mar/2021
 * File Description: Survey Audience users Listing
 */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CommonDataGrid from "../../../../components/dataGrid";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import {
  getSurveyAudience,
  deleteSurveyAudience,
  getFileUrl,
  exportSurveyAudience,
  fetchSingleSurvey,
} from "../../../../../middleware/services/surveyApi";
import { swap, getDateFromMongoDate } from "../../../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { StringFilter } from "../../../../components/dataGrid/fliters";
import AddAudienceModal from "./modals";
import ActionButtons from "../actionButtons";
import ThreeDotMenu from "../../../../components/threeDotMenu/index";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";
import AccessDenied from "../../../../components/accessDenied";

const SurveyAudienceListing = (props) => {
  const [deleteId, setDeleteId] = useState(undefined);
  const [show, setShow] = useState(false);
  const [openAs, setOpenAs] = useState(undefined);
  const [gridFilters, setGridFilters] = useState([]);
  const [surveyData, setSurveyData] = useState(null);

  const accessPermissionAdd = props?.interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessPermissionDelete = props?.interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessPermissionImportAudience = props?.interfaceActionAccess.includes(COMMON_ACCESS_ACTION.IMPORT_AUDIENCE);
  const accessPermissionExportAudience = props?.interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EXPORT_AUDIENCE);

  const dispatch = useDispatch();
  const surveyId = props.surveyId;

  const breadCrumbLinks = [
    { linkUrl: "/survey", linkName: "Survey", linkActive: false },
    {
      linkUrl: surveyId ? `/survey/${surveyId}/basic-details#audience` : "/survey/basic-details#audience",
      linkName: (surveyData && surveyData.surveySerialNo) || "",
      linkActive: false,
    },
    {
      linkUrl: `/survey/${surveyId}/edit${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessPermissionDelete
          ? {
              Delete: () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteSurveyAudience(props.surveyId, {
                        surveyAudienceIds: [value.id],
                      })
                        .then((response) => {
                          if (response) {
                            dispatch(actionSuccess("Survey audience user(s) deleted successfully"));
                            const params = {};
                            params.limit = 100;
                            getSurveyAudience(props.surveyId, params).then((form) => {
                              formatRowData(form?.data?.data || []);
                            });
                            setDeleteId(new Date());
                          } else {
                            dispatch(actionError("Something went wrong"));
                          }
                        })
                        .catch((err) => {
                          dispatch(actionError(err?.data?.message || "Failed to delete survey"));
                        });
                    },
                    content: "Are you sure you want to delete ?",
                    okText: "Delete",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
      />
    );
  };

  const deleteManyFunc = (flag) => {
    const selectedSurveyAudience = localStorage.getItem("selectedSurveyAudience") ? JSON.parse(localStorage.getItem("selectedSurveyAudience")) : [];
    if (flag) {
      if (selectedSurveyAudience.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteSurveyAudience(props.surveyId, {
                surveyAudienceIds: selectedSurveyAudience,
              })
                .then((response) => {
                  if (response) {
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    const params = {};
                    params.limit = 100;
                    getSurveyAudience(props.surveyId, params).then((survey) => {
                      formatRowData(survey.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete company"));
                });
            },
            content: "Are you sure you want to delete ?",
            okText: "Delete",
            cancelText: "Cancel",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };
  const dateTemplate = (value) => {
    switch (value.column.field) {
      case "Survey Submit Date":
        return !isNaN(Date.parse(value["Survey Submit Date"])) ? <span>{getDateFromMongoDate(value["Survey Submit Date"])}</span> : "";
      case "Invitation Sent Date":
        return !isNaN(Date.parse(value["Invitation Sent Date"])) ? <span>{getDateFromMongoDate(value["Invitation Sent Date"])}</span> : "";
      default:
        return "";
    }
  };
  const columnNames = {
    id: "id",
    "First Name": "userFname",
    "Last Name": "userLname",
    Email: "userEmail",
    "Survey Submit Date": "userSurveyResponseDate",
    "Invitation Sent Date": "userSurveySendDate",
  };

  const columnFields = [
    {
      field: "id",
      type: "string",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "First Name",
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
    },
    {
      field: "Last Name",
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
    },
    {
      field: "Email",
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
    },
    {
      field: "Invitation Sent Date",
      headerText: "Last Invitation Sent Date",
      type: "Date",
      template: dateTemplate,
      width: 250,
      textAlign: "Center",
      headerTextAlign: "Center",
    },
    {
      field: "Survey Submit Date",
      headerText: "Survey Last Submit Date",
      type: "Date",
      template: dateTemplate,
      width: 220,
      textAlign: "Center",
      headerTextAlign: "Center",
    },
  ];

  if (accessPermissionDelete) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Center",
    });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 55,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }

        return newRD;
      });
      newRD["Survey Submit Date"] = rD.userSurveyResponseDate ? new Date(rD.userSurveyResponseDate) : "";
      newRD["Invitation Sent Date"] = rD.userSurveySendDate ? new Date(rD.userSurveySendDate) : "";

      return newRD;
    });
    return formatedRowData;
  };

  const getSurveyAudienceUsers = (params = {}) => {
    let fields = [
      "userFname",
      "userLname",
      "userEmail",
      "userSurveyResponseDate",
      "userSurveySendDate",
      "surveyFrequency",
      "surveyYear",
      "surveyQuarter",
    ];
    params.fields = fields;
    if (!params.sort) params.sort = "_id:desc";
    if (params.filters) {
      params.filters = [...params.filters, ["userFname", "ex", true]];
    } else {
      params.filters = [["userFname", "ex", true]];
    }
    params.filters && setGridFilters(JSON.stringify(params.filters));
    return getSurveyAudience(props.surveyId, params);
  };

  // Export Audience data
  const exportData = async (flag, ids) => {
    if (flag) {
      const payload = {
        reqIds: JSON.stringify(ids),
      };
      if (gridFilters) payload.filters = gridFilters;
      dispatch(showLoader());
      await exportSurveyAudience(props.surveyId, payload)
        .then((res) => {
          const link = document.createElement("a");
          link.href = getFileUrl(`${res.data.data}`);
          link.download = `${res.data.data}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          dispatch(hideLoader());
        })
        .catch((err) => {
          dispatch(actionError("Exporting error: Somthing wrong"));
          console.log("Error: ", err);
        });
    }
  };
  const importData = (flag) => {
    if (flag) {
      if (show) setShow(false);
      setShow(true);
      setOpenAs("upload");
    }
  };
  const fetchSurvey = () => {
    let params = {};
    let fields = ["surveySerialNo", "surveyShortName", "surveyTitle"];
    params.fields = fields;
    fetchSingleSurvey(surveyId, params).then((res) => {
      let data = res.data.data || {};

      setSurveyData(data);
    });
  };

  useEffect(() => {
    if (surveyId) {
      if (!surveyData) fetchSurvey();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/survey"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, surveyData, formatRowData, props.selectedIndex]);

  return (
    <>
      {props?.interfaceAccess ? (
        <div>
          <div className="content-wrapper2">
            <div className="gennx-grid-container-custom panel mx-2 pt-3" style={{ width: "99.5%" }}>
              <div style={{ width: "99.5%" }}>
                <CommonDataGrid
                  gridTitle="Survey Audience"
                  fetch={getSurveyAudienceUsers}
                  columnNames={columnNames}
                  columnFields={columnFields}
                  formatRowData={formatRowData}
                  importAccessAudience={accessPermissionImportAudience}
                  exportAccessAudience={accessPermissionExportAudience}
                  isExport={exportData}
                  isImport={importData}
                  deleteId={deleteId}
                  getMultiSelectedRows={(data) => {
                    localStorage.setItem("selectedSurveyAudience", JSON.stringify([...data]));
                  }}
                  getMultiDeSelectedRows={(data) => {
                    localStorage.setItem("selectedSurveyAudience", JSON.stringify([...data]));
                  }}
                  deleteRight={true}
                  deleteMany={accessPermissionDelete ? deleteManyFunc : null}
                  addNewRight={accessPermissionAdd}
                  addNew={() => {
                    if (show) setShow(false);

                    setOpenAs("save");

                    setTimeout(() => {
                      setShow(true);
                    }, 100);
                  }}
                  // deleteMany={() => { }}
                />
                {show && (
                  <AddAudienceModal
                    // onSaveData={onUploadData}
                    closeHandler={() => {
                      setShow(false);
                    }}
                    show={show}
                    gridReload={() => {
                      setDeleteId(new Date());
                      setShow(false);
                    }}
                    openAs={openAs}
                    surveyId={props.surveyId}
                  />
                )}
              </div>
            </div>
          </div>
          <ActionButtons
            accessRights={props.accessRights}
            selectedIndex={props.selectedIndex}
            setCurrentTab={(selectedIndex) => {
              props.setCurrentTab(selectedIndex);
            }}
            surveyId={surveyId}
            tabsName={props.tabsName}
            moduleName={props.moduleName}
            hashArr={props.hashArr}
          />
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default SurveyAudienceListing;
