/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Actions for different action status
----------------------------------------------------------------
	Creation Details
	Date Created				: 14/OCT/2020
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import {
	SET_COMPANY_OVERVIEW_UPDATE_STATUS,
	SET_COMPANY_SAVE_SUBMIT_CLICK_STATUS
} from "../../constants/actionConstants";

export const setOverviewUpdateStatus = (updateStatus = false) => ({
	type: SET_COMPANY_OVERVIEW_UPDATE_STATUS,
	payload: { updateStatus: updateStatus },
});
export const setClickSaveSubmitState = (clickStatus = false) => ({
	type: SET_COMPANY_SAVE_SUBMIT_CLICK_STATUS,
	payload: { clickStatus: clickStatus },
});
export const setTaxonomyCheckedNodes = (data) => ({
	type: 'SET_COMPANY_TAXONOMY_CHECKED_NODES',
	payload: data,
});

export const setTaxonomyUnCheckedNodes = (data) => ({
	type: 'SET_COMPANY_TAXONOMY_UNCHECKED_NODES',
	payload: data,
});

export const setKiaCheckedNodes = (data) => ({
	type: 'SET_COMPANY_KIA_CHECKED_NODES',
	payload: data,
});

export const setKiaUnCheckedNodes = (data) => ({
	type: 'SET_COMPANY_KIA_UNCHECKED_NODES',
	payload: data,
});

export const setResetRedux = () => ({
	type: 'SET_RESET_REDUX',
});

export const setBasicDetailsCompany = (data) => ({
	type: 'SET_BASIC_DETAILS_COMPANY',
	payload: data,
});

export const setAdvanceTabDetails = (data) => ({
	type: 'SET_ADVANCE_DETAILS',
	payload: data,
});

export const resetCompany = (data) => ({
	type: 'RESET_REDUX_COMPANY',
	payload: data,
});