


const TREE_STATE = {
    //Taxonomy initial variables
    technologyTree: [],
    contentTaggingTree: [],
    technologyAllData: [],
    technologyTaggingLeft: [],
    technologyCheckedNodes: [],
    flag: false,

    //KIA initial variables
    industryTree: [],
    contentKiaTaggingTree: [],
    IndustryAllData: [],
    industryTaggingLeft: [],
    industryCheckedNodes: [],
    kiaFlag: false,

    // Value chain initial variable 
    valueChainIndustryTree: [],
    valueChainContentTaggingTree: [],
    valueChainIndustryTaggingLeft: [],
    valueChainIndustryAllData: [],
    valueChainIndustryCheckedNodes: [],
    valueChainFlag: false,
    valueChainRightTree: [],
    valueChainTaggedData : [],

    // Trend
    trendIndustryTree: [],
    trendIndustryTaggingLeft: [],
    trendContentTaggingTree: [],
    trendIndustryCheckedNodes: [],
    trendIndustryAllData: [],
    trendIndustryNameNodes: [],
    trendFlag: false,

    // Media
    technologyMediaTree: [],
    contentMediaTaggingTree: [],
    technologyMediaAllData: [],
    technologyMediaTaggingLeft: [],
    technologyMediaCheckedNodes: [],
    mediaFlag: false
};

export default (state = TREE_STATE, action) => {
    switch (action.type) {

        /*
             @Description : Taxonomy states
        */
        //Maintain single technology
        case 'MAINTAIN_TECHNOLOGY_TREE': {
            return { ...state, technologyTree: action.payload };
        }

        //Maintain technology all data
        case 'MAINTAIN_TECHNOLOGY_ALL_DATA': {
            return { ...state, technologyAllData: action.payload };
        }

        //Maintain content tagging - right side nodes 
        case 'MAINTAIN_CONTENT_TAGGING_TREE': {
            return { ...state, contentTaggingTree: action.payload };
        }

        //Maintain technology tagging - left side nodes
        case 'MAINTAIN_TECHNOLOGY_TAGGING_LEFT': {
            return { ...state, technologyTaggingLeft: action.payload };
        }

        //Maintain checked nodes - right side nodes
        case 'MAINTAIN_TECHNOLOGY_CHECKED_NODES': {
            return { ...state, technologyCheckedNodes: action.payload };
        }

        //Maintain taxonomy flag
        case 'MAINTAIN_FLAG': {
            return { ...state, flag: action.payload };
        }

        /*
             @Description : KIA states
        */

        //Maintain single technology
        case 'MAINTAIN_INDUSTRY_TREE': {
            return { ...state, industryTree: action.payload };
        }

        //Maintain content tagging - right side nodes 
        case 'MAINTAIN_KIA_CONTENT_TAGGING_TREE': {
            return { ...state, contentKiaTaggingTree: action.payload };
        }

        //Maintain technology tagging - left side nodes
        case 'MAINTAIN_INDUSTRY_TAGGING_LEFT': {
            return { ...state, industryTaggingLeft: action.payload };
        }

        //Maintain Industry all data
        case 'MAINTAIN_INDUSTRY_ALL_DATA': {
            return { ...state, IndustryAllData: action.payload };
        }

        //Maintain checked nodes - right side nodes
        case 'MAINTAIN_INDUSTRY_CHECKED_NODES': {
            return { ...state, industryCheckedNodes: action.payload };
        }

        //Maintain kia flag
        case 'MAINTAIN_KIA_FLAG': {
            return { ...state, kiaFlag: action.payload };
        }

        /*
             @Description : Value Chain states
        */

        //Maintain single value chain Industry
        case 'MAINTAIN_VALUE_CHAIN_INDUSTRY_TREE': {
            return { ...state, valueChainIndustryTree: action.payload };
        }

        //Maintain value chain content tagging - right side nodes
        case 'MAINTAIN_VALUE_CHAIN_CONTENT_TAGGING_TREE': {
            return { ...state, valueChainContentTaggingTree: action.payload };
        }

        //Maintain value chain industry tagging - left side nodes
        case 'MAINTAIN_VALUE_CHAIN_INDUSTRY_TAGGING_LEFT': {
            return { ...state, valueChainIndustryTaggingLeft: action.payload };
        }

        //Maintain All value chain Industry Data
        case 'MAINTAIN_VALUE_CHAIN_INDUSTRY_ALL_DATA': {
            return { ...state, valueChainIndustryAllData: action.payload };
        }

        //Maintain value chain checked nodes - right side nodes
        case 'MAINTAIN_VALUE_CHAIN_INDUSTRY_CHECKED_NODES': {
            return { ...state, valueChainIndustryCheckedNodes: action.payload };
        }

        //Maintain value chain flag
        case 'MAINTAIN_VALUE_CHAIN_FLAG': {
            return { ...state, valueChainFlag: action.payload };
        }

        /*
             @Description : Trend states
        */

        //Maintain single trend Industry
        case 'MAINTAIN_TREND_INDUSTRY_TREE': {
            return { ...state, trendIndustryTree: action.payload };
        }

        //Maintain trendName - second name aside of select industry
        case 'MAINTAIN_TREND_NAME_NODES': {
            return { ...state, trendIndustryNameNodes: action.payload };
        }

        //Maintain trend content tagging - right side nodes
        case 'MAINTAIN_TREND_CONTENT_TAGGING_TREE': {
            return { ...state, trendContentTaggingTree: action.payload };
        }


        //Maintain trend industry tagging - left side nodes
        case 'MAINTAIN_TREND_INDUSTRY_TAGGING_LEFT': {
            return { ...state, trendIndustryTaggingLeft: action.payload };
        }

        //Maintain All value chain Industry Data
        case 'MAINTAIN_TREND_INDUSTRY_ALL_DATA': {
            return { ...state, trendIndustryAllData: action.payload };
        }

        //Maintain trend checked nodes - right side nodes
        case 'MAINTAIN_TREND_INDUSTRY_CHECKED_NODES': {
            return { ...state, trendIndustryCheckedNodes: action.payload };
        }

        //Maintain Trend flag
        case 'MAINTAIN_TREND_FLAG': {
            return { ...state, trendFlag: action.payload };
        }

        /*
             @Description : Media states
        */

        //Maintain single Media technology
        case 'MAINTAIN_MEDIA_TECHNOLOGY_TREE': {
            return { ...state, technologyMediaTree: action.payload };
        }

        //Maintain media technology all data
        case 'MAINTAIN_MEDIA_TECHNOLOGY_ALL_DATA': {
            return { ...state, technologyMediaAllData: action.payload };
        }

        //Maintain content media tagging - right side nodes 
        case 'MAINTAIN_CONTENT_MEDIA_TAGGING_TREE': {
            return { ...state, contentMediaTaggingTree: action.payload };
        }

        //Maintain media technology tagging - left side nodes
        case 'MAINTAIN_MEDIA_TECHNOLOGY_TAGGING_LEFT': {
            return { ...state, technologyMediaTaggingLeft: action.payload };
        }
        //Maintain media checked nodes - right side nodes
        case 'MAINTAIN_MEDIA_TECHNOLOGY_CHECKED_NODES': {
            return { ...state, technologyMediaCheckedNodes: action.payload };
        }

        //Maintain media taxonomy flag
        case 'MAINTAIN_MEDIA_FLAG': {
            return { ...state, mediaFlag: action.payload };
        }
            
        case 'MAINTAIN_VALUE_CHAIN_RIGHT_CHECKED_NODES': {
            return { ...state, valueChainRightTree: action.payload }
        }
        case 'MAINTAIN_VALUE_CHAIN_RIGHT_TAGGED_NODES': {
            return { ...state, valueChainTaggedData: action.payload }
            }


        //Reset the redux
        case 'RESET_REDUX': {
            return {
                technologyTree: [],
                contentTaggingTree: [],
                technologyAllData: [],
                technologyTaggingLeft: [],
                technologyCheckedNodes: [],
                flag: false,
                industryTree: [],
                contentKiaTaggingTree: [],
                IndustryAllData: [],
                industryTaggingLeft: [],
                industryCheckedNodes: [],
                kiaFlag: false,
                valueChainIndustryTree: [],
                valueChainContentTaggingTree: [],
                valueChainIndustryTaggingLeft: [],
                valueChainIndustryAllData: [],
                valueChainIndustryCheckedNodes: [],
                valueChainFlag: false,
                trendIndustryTree: [],
                trendIndustryTaggingLeft: [],
                trendContentTaggingTree: [],
                trendIndustryCheckedNodes: [],
                trendIndustryAllData: [],
                trendIndustryNameNodes: [],
                trendFlag: false,
                technologyMediaTree: [],
                contentMediaTaggingTree: [],
                technologyMediaAllData: [],
                technologyMediaTaggingLeft: [],
                technologyMediaCheckedNodes: [],
                mediaFlag: false,
                valueChainRightTree: [],
                valueChainTaggedData : []
            }
        }


        default:
            return state;
    }
};

