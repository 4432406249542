/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Contnet Block properties - Add | Edit properties
 ---------------------------------------------------------------------------------
  Creation Details
  Date Created				: 12/April/2022
  Author						  : Amrutesh Devadas 
================================================================
*/
import "./index.scss";
import React, { useEffect, useState, useRef } from "react";
import { TextField, Dropdown, MultiSelect, MultiSelectNew } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { useDispatch } from "react-redux";
import { actionSuccess, showLoader } from "../../../../../middleware/actions/utilityAction";
import deliverableImage from "../../../../../assets/images/contentBlockGrey.png";
import { getLoggedInUser } from "../../../../../utilities";
import { fetchConfig } from "../../../../../middleware/services/cmsApi";
import { CONTENT_BLOCK_THUMB_IMG_SIZE } from "config";

function Properties(props) {
  const dispatch = useDispatch();
  const {
    formik,
    whatNextDeliverableDataType,
    setWhatNextDeliverableDataType,
    insiderDeliverableDataType,
    setInsiderDeliverableDataType,
    setImageTypeError,
    imageTypeError,
  } = props;
  const contentThumbnailImageRef = useRef(null);
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const contentBlockId = props?.contentBlockId;
  const [imageBlob, setImageBlob] = useState(null);
  const [render, setRender] = useState(false);

  /*
    @Description : Fetch defalut thumbnail image
  */
  const getDefaultImage = () => {
    fetch(deliverableImage)
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((e) => {
        setImageBlob(e);
      });
  };

  /*
    @Description : Fetch all content blocks
  */
  const getCategoryBlock = async () => {
    let params = {};
    params.filters = [["configName", "eq", "content_builder_category"]];
    await fetchConfig(params).then((res) => {
      if (res.status === 200) {
        props.setCategoryBlock(res.data.data[0].configValue);
      }
    });
  };

  /*
    @Description : Fetch all deliverable types for whatnext and insider
  */
  const getDeliverableType = async () => {
    let params = {};
    params.filters = [["configName", "eq", "content_builder_deliverableType"]];
    await fetchConfig(params).then((res) => {
      if (res.status === 200) {
        if (res.data.data[0]) {
          setWhatNextDeliverableDataType(res.data.data[0].configValue.whatNextDeliverableDataType);
          setInsiderDeliverableDataType(res.data.data[0].configValue.insiderDeliverableDataType);
        }
      }
    });
  };

  useEffect(() => {
    console.log(" useEffect ");
    getDefaultImage();
    if (props.categoryBlock?.length === 0) {
      getCategoryBlock();
    }
    if (props.save) {
      // fetchContentBlockData()
      props.setSave(false);
      props.handleSave(false);
    } else {
      if (props.selectedIndex === 0 && !props.saveContentBlock) {
        fetchProperties();
      }
      props.handleSave(false);
    }
  }, [props.saveContentBlock]);

  /*
    @Description : Handle save function for properties tab
  */
  const fetchProperties = async () => {
    let userId = getLoggedInUser();
    let categoryId = undefined;

    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        const err =
          res &&
          Object.values(res).map((e) => {
            return e;
          });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let category = props.categoryBlock.find((category) => category.categoryName === formik.values.contentBlockType);
        categoryId = category["categoryId"];
        let contentData = {
          contentBlockName: formik.values.contentBlockName,
          contentBlockType: formik.values.contentBlockType,
          productVariant: formik.values.contentBlockVariant.map((v) => {
            return { product_name: v.label };
          }),
          contentBlockContentType: formik.values.contentBlockContentType.map((v) => {
            return v;
          }),
          contentBlockCategoryId: categoryId.categoryId,
        };

        let saveOverviewData = new FormData();
        for (let uKey in contentData) {
          if (typeof contentData[uKey] != "object") {
            if (contentData[uKey]) saveOverviewData.append(uKey, contentData[uKey]);
          } else {
            if (contentData[uKey]) saveOverviewData.append(uKey, JSON.stringify(contentData[uKey]));
          }
        }

        if (
          props.contentThumbnailDefaultImageRef.current.files.length &&
          (props.contentThumbnailDefaultImageRef.current.files[0].type === "image/jpeg" ||
            props.contentThumbnailDefaultImageRef.current.files[0].type === "image/png")
        ) {
          saveOverviewData.append("contentBlockThumbnail", props.contentThumbnailDefaultImageRef.current.files[0]);
          props.setImageError("");
        } else if (props.contentBlockId !== "" && props.contentThumbnailImageSrc === undefined) {
          saveOverviewData.append("contentBlockThumbnail", "");
        }

        if (contentBlockId) {
          if (props.imageDelete) {
            saveOverviewData.append("imageDelete", "yes");
          }
          saveOverviewData.append("contentBlockModifiedUserId", userId._id);
        } else {
          saveOverviewData.append("contentBlockCreatedUserId", userId._id);
          dispatch(showLoader());

          props.newRecord();
        }
      }
    });
  };

  useEffect(() => {
    if (whatNextDeliverableDataType?.length == 0 || insiderDeliverableDataType?.length == 0) getDeliverableType();
    let variant = formik.values.contentBlockVariant.map((v) => {
      return v.label;
    });
    if (variant.includes("WhatNext", "Insider") && variant.length > 1) {
      // setcontentBlockContentTypeArray([...new Set([...whatNextDeliverableDataType, ...insiderDeliverableDataType])])
      var ids = new Set(whatNextDeliverableDataType.map((d) => d.label));
      var merged = [...whatNextDeliverableDataType, ...insiderDeliverableDataType.filter((d) => !ids.has(d.label))];
      // setcontentBlockContentTypeArray([...whatNextDeliverableDataType, ...insiderDeliverableDataType])
      props.setcontentBlockContentTypeArray(merged);
    } else {
      variant.map((element) => {
        if (element === "WhatNext") {
          props.setcontentBlockContentTypeArray(whatNextDeliverableDataType);
        }
        if (element === "Insider") {
          props.setcontentBlockContentTypeArray(insiderDeliverableDataType);
        }
      });
    }
    if (formik.values?.contentBlockVariant?.length === 0 && render) {
      props.setcontentBlockContentTypeArray([]);
      formik.values.contentBlockContentType = [];
      formik.setFormikState({
        ...formik,
        touched: {
          contentBlockContentType: true,
          contentBlockVariant: true,
        },
        errors: {
          contentBlockContentType: "please select content type",
          contentBlockVariant: "please select variant/brand",
        },
      });
    }
    setRender(true);
  }, [formik.values.contentBlockVariant]);

  useEffect(() => {
    if (formik?.values?.contentBlockVariant) {
      let variant = formik.values.contentBlockVariant.map((v) => {
        return v.label;
      });
      if (variant.includes("WhatNext", "Insider") && variant.length > 1) {
        var ids = new Set(whatNextDeliverableDataType.map((d) => d.label));
        var merged = [...whatNextDeliverableDataType, ...insiderDeliverableDataType.filter((d) => !ids.has(d.label))];
        // setcontentBlockContentTypeArray([...whatNextDeliverableDataType, ...insiderDeliverableDataType])
        props.setcontentBlockContentTypeArray(merged);
      } else {
        variant.map((element) => {
          if (element === "WhatNext") {
            props.setcontentBlockContentTypeArray(whatNextDeliverableDataType);
          }
          if (element === "Insider") {
            props.setcontentBlockContentTypeArray(insiderDeliverableDataType);
          }
        });
      }
    }
  }, [whatNextDeliverableDataType, insiderDeliverableDataType]);

  /*
    @Description : Fetch thumbnail image
  */
  const readProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      contentThumbnailImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  return (
    <div className="gennx-content-wrapper padding-bottom-200i p-2 mt-3 bg-white" style={{ width: "98.5%" }}>
      {/* <form> */}
      <div className="row mx-1">
        <div className="mt-3 col-md-4">
          <TextField
            formik={formik}
            id="contentBlockName"
            name="contentBlockName"
            placeholder="Block Name *"
            required="required"
            value={formik.values.contentBlockName}
          />
        </div>
        <div className="mt-3  col-md-4 ">
          {props.categoryBlock?.length > 0 && (
            <Dropdown
              formik={formik}
              required="required"
              id="contentBlockType"
              name="contentBlockType"
              placeholder="Type of Block *"
              value={formik.values.contentBlockType}
              dataSource={props.categoryBlock.map((item) => {
                return item.categoryName;
              })}
              // noRecordsTemplate={() => <div>Please type to search</div>}
            />
          )}
        </div>
        <div className="mt-3  col-md-4">
          <MultiSelectNew
            formik={formik}
            mode="CheckBox"
            required="required"
            sortOrder="Acending"
            showSelectAll={false}
            id="contentBlockVariant"
            name="contentBlockVariant"
            placeholder="Variant/Brand *"
            allowCustomValue={true}
            value={
              formik.values?.contentBlockVariant?.length > 0 &&
              formik.values.contentBlockVariant.map((v) => {
                return v.label;
              })
            }
            fields={{ text: "label", value: "value" }}
            showDropDownIcon={true}
            dataSource={[
              {
                label: "WhatNext",
                value: "WhatNext",
              },
              {
                label: "Insider",
                value: "Insider",
              },
            ]}
            // noRecordsTemplate={() => <div>Please type to search</div>}
          />
        </div>
      </div>
      <div className="row mx-1">
        <div className="col-md-4">
          {props.contentBlockContentTypeArray?.length > 0 && (
            <MultiSelect
              formik={formik}
              mode="CheckBox"
              required="required"
              sortOrder="Acending"
              showSelectAll={true}
              placeholder="Content Type *"
              id="contentBlockContentType"
              name="contentBlockContentType"
              allowCustomValue={true}
              // allowFiltering={true}
              value={formik.values.contentBlockContentType}
              fields={{ text: "label", value: "value" }}
              dataSource={props.contentBlockContentTypeArray}
              cssClass="multi-without-parent e-outline customCss"
              // noRecordsTemplate={() => <div>Please type to search</div>}
              showClearButton={false}
              // enableRtl={true}
              // showDropDownIcon={true}
            />
          )}
        </div>
      </div>
      <div className="col-md-4 my-3 ml-1">
        <label htmlFor="contentBlockThumbnail">Attach Thumbnail</label>
        <div
          className="image-holder text-center"
          style={{
            width: "362px",
            padding: "0px",
            border: "2px dashed #707070",
          }}
        >
          {!contentThumbnailImageRef ? (
            <>
              <span style={{ color: "red" }}>Choose a file to import</span> <br />
              <span>or Drag and drop it here</span>
            </>
          ) : (
            ""
          )}
          <img
            width="351"
            height="204"
            src={props.contentThumbnailImageSrc || deliverableImage}
            alt="Content Thumbnail"
            ref={contentThumbnailImageRef}
          />
          <div className="image-action newsThumbnail_fa_icons" style={{ top: "5px" }}>
            <span
              onClick={() => {
                dispatch(
                  showAlertBox({
                    content: "Are you sure you want to delete?",
                    okText: "Delete",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                    okCallback: async () => {
                      dispatch(actionSuccess("Image deleted successfully"));
                      props.setShowTrash(false);
                      props.setImageError("");
                      props.setImageDelete(true);
                      contentThumbnailImageRef.current.setAttribute("src", deliverableImage);
                      props.contentThumbnailDefaultImageRef.current.value = "";
                    },
                  })
                );
              }}
            >
              <i className={`fa fa-trash mr-1 ${!props.showTrash ? "d-none" : undefined}`} aria-hidden="true" />
            </span>
            <span onClick={() => props.contentThumbnailDefaultImageRef.current.click()}>
              <i className="fa fa-pencil-alt" aria-hidden="true" />
            </span>
          </div>
        </div>
        <input
          type="file"
          className="d-none"
          accept=".jpg, .jpeg, .png"
          id="contentBlockThumbnail"
          name="contentBlockThumbnail"
          ref={props.contentThumbnailDefaultImageRef}
          onChange={(e) => {
            if (e.target.files.length > 0) {
              if (
                !["image/png", "image/jpg", "image/jpeg"].includes(e.target.files[0].type) ||
                e.target.files[0]?.size > CONTENT_BLOCK_THUMB_IMG_SIZE
              ) {
                setImageTypeError("File must be JPG/JPEG or PNG format and size must not exceed 10 MB.");
                props.contentThumbnailDefaultImageRef.current.value = "";
                return;
              } else if (
                (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/jpeg") &&
                e.target.files[0].size <= CONTENT_BLOCK_THUMB_IMG_SIZE
              ) {
                //1000kb == 1000000 byte
                readProfileImage(e.target.files[0]);
                contentThumbnailImageRef.current.setAttribute("src", e.target.files[0]);
                props.setImageError("");
                setImageTypeError("");
                props.setShowTrash(true);
                setIsDefaultImage(false);
                formik.setErrors({
                  ...formik.errors,
                  contentBlockThumbnail: "",
                });
              }
              // else {
              //   setImageError(`Max file size should be ${parseInt(import.meta.env.VITE_DELIVERABLE_FILE_SIZE) / 1024} KB while Uploaded file size is ${(e.target.files[0].size / (1024 * 1024)).toFixed(2)} MB`);
              // }
            }
          }}
        />
        <div className="row">
          <small>
            <div className="col-md-12 pt-2">
              <span className="font-weight-bold">Note : </span>
            </div>
            <div className="col-md-12">
              <li>Only JPG/JPEG or PNG files are allowed.</li>
            </div>
            <div className="col-md-12">
              <li>Max file size allowed is 10MB</li>
            </div>
          </small>
        </div>
        <div className="text-danger" style={{ display: props.imageError ? "block" : "none" }} role="alert">
          {props.imageError &&
            props.imageError.split("while").map((e) => (
              <small key={e.length}>
                {e} <br />{" "}
              </small>
            ))}
        </div>
        <div className="text-danger" style={{ display: imageTypeError ? "block" : "none" }} role="alert">
          {imageTypeError &&
            imageTypeError.split("while").map((e) => (
              <small key={e.length}>
                {e} <br />{" "}
              </small>
            ))}
        </div>
      </div>
      {/* </form> */}
    </div>
  );
}

export default Properties;
