import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { dataPublishingProduct }
    from "../../../../middleware/services/cmsApi";
import { fetchProductBasicById } from "../../../../middleware/services/productApi"
import {
    actionStart, actionSuccess, actionError, showMessage,
} from "../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { getProductVariant } from "../../../../utilities";
import "../../../../assets/styles/type.css"

const DataPublicUI = (props) => {
    const dispatch = useDispatch();
    const productId = props.productId;
    const [showModal, setShowModal] = useState(false);

    const handlePublish = () => {
        const payload = {
            "platform": getProductVariant()
        };
        setShowModal(!showModal)
        dataPublishingProduct(productId, payload).then(response => {
            dispatch(actionSuccess("Product published successfully"));
        }).catch(err => {
            dispatch(actionError(err.data.message || "Something went wrong"));
        })
    }
    return (<>
        <button type="button"
            className="btn btn-primary cy-btn pl-4 pr-4 ml-3"
            onClick={async () => {
                if (props?.location?.pathname?.includes("/data-qc/")) {
                    const params = {};
                    params.fields = ["productInQueue"]
                    const response = await fetchProductBasicById(productId, params);
                    // console.log(response.data.data);
                    const data = response.data?.data;
                    if (data.productInQueue.queueStatus == "QC_ACCEPTED") {
                        setShowModal(!showModal);
                    } else {
                        dispatch(actionError("Product must be QC complete before publishing"));
                    }
                } else {
                    setShowModal(!showModal);
                }
            }}
        >
            Publish
        </button>
        <Modal
            show={showModal}
            backdrop="static"
            className="modal fade lg"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <div className="modal-header bg-secondary text-white">
                <h5 className="modal-title" id="exampleModalLabel">Publish Product</h5>
                <button type="button" className="close" aria-label="Close" onClick={() => {
                    setShowModal(!showModal);
                }}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> */}
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 p-4">
                        <b>Notes:</b><br />
                        Please select only those products where following conditions are satisfied.<br />
                        - Company should be live.<br />
                        - Taxonomy and Overview status must be completed.<br />
                        Are you sure, you want to publish the product on live ?
                    </div>
                </div>

            </Modal.Body>
            <div className="modal-footer">
                <button type="button" className=" btn e-control e-btn e-flat customCssProductButtonCancel" onClick={() => setShowModal(!showModal)}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={handlePublish}>Publish</button>
            </div>
        </Modal>
    </>)
}

export default DataPublicUI;
