/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  People Education Component
	 Summary : - Display Listing of Education Qualification of People
			   - Add and Edit Education Links are availablein this file 
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 27/Apr/2021
	Author						: Sandeep Sharma
================================================================ 
*/
import React, { useState, useEffect } from "react";
import Accordion from "../../../../components/accordion";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import AddEducationModel from "./add";
import { fetchEducation, deleteEducation } from "../../../../../middleware/services/userApi";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import ThreeDotMenu from "../../../../components/threeDotMenu";

const Education = (props) => {
  const userId = props.userId;
  const [showModal, setShowModal] = useState(false);
  const [eduData, setEduData] = useState([]);
  const [stepCompleted, setStepCompleted] = useState(false);
  const dispatch = useDispatch();
  const [eduId, setEduId] = useState("");

  let accessActionAddEducation = props?.accessActionAddEducation;
  let accessActionEditEducation = props?.accessActionEditEducation;
  let accessActionDeleteEducation = props?.accessActionDeleteEducation;

  const closeModal = () => {
    setEduId("");
    setShowModal(false);
  };
  /*
		@Description : BreadCrub Creation
	*/
  const breadCrumbLinks = [{ linkUrl: "/user", linkName: props?.moduleName || "External User", linkActive: true }];

  /* 
		@Description : Use for setting breadcrumb values
	*/

  useEffect(() => {
    if (userId !== "") {
      formatRowData(userId);
    }
    localStorage.removeItem("selectedPeople");
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
      localStorage.removeItem("selectedPeople");
    };
  }, []);

  // formatRowData function required to format rowData for datGrid
  const formatRowData = async () => {
    dispatch(showLoader());
    try {
      let formatedRowData = [];
      const response = await fetchEducation(userId);
      if (response.status == 200) {
        dispatch(hideLoader());
        var userData = response.data.data;
        let educations = userData.userEducation;
        if (educations.length > 0) {
          setStepCompleted(true);
          educations.forEach((edu) => {
            let data = {};
            data["eduId"] = edu.id;
            data["eduInstituteName"] = edu.eduInstituteName;
            data["eduQualification"] = edu.eduQualification;
            data["eduPassingYear"] = edu.eduPassingYear;
            data["eduField"] = edu.eduField ? edu.eduField : [];
            formatedRowData.push(data);
          });
        }
      }
      setEduData(formatedRowData);
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  const removeEdu = (eduId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          deleteEducation(userId, eduId)
            .then((response) => {
              dispatch(actionSuccess("Education details deleted successfully"));
              formatRowData();
            })
            .catch((err) => {
              dispatch(actionError(err.data?.message || "Fail to delete Education"));
            });
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to delete?",
        title: "dialogAlertCssWarning",
      })
    );
  };

  return (
    <>
      {userId && (
        <Accordion
          heading="User Education Details"
          step={4}
          stepCompleted={eduData && eduData.length ? true : false}
          activeState={stepCompleted}
          required={false}
        >
          {showModal && (
            <AddEducationModel showModal={showModal} closeModal={closeModal} userId={userId} eduId={eduId} formatRowData={formatRowData} />
          )}
          <div className="row">
            {eduData &&
              eduData.map(function (x, i) {
                return (
                  <React.Fragment key={`edu-${i}`}>
                    <div className="col-md-4 pb-4 pt-3">
                      <div style={{ display: `flex`, flexDirection: `column` }}>
                        <div className="e-card e-card-horizontal" style={{ overflow: "unset" }}>
                          <div className="e-card-stacked">
                            <div style={{ position: "absolute", top: "5px", right: "0" }}>
                              {accessActionEditEducation || accessActionDeleteEducation ? (
                                <ThreeDotMenu
                                  Edit={
                                    accessActionEditEducation
                                      ? () => {
                                          showModal && setShowModal(false);
                                          setShowModal(true);
                                          setEduId(x["eduId"]);
                                        }
                                      : undefined
                                  }
                                  Delete={
                                    accessActionDeleteEducation
                                      ? () => {
                                          removeEdu(x["eduId"]);
                                        }
                                      : undefined
                                  }
                                />
                              ) : null}
                            </div>
                            <div className="e-card-header"></div>
                            <div className="row pl-4" style={{ position: "relative", top: "-10px" }}>
                              <div className="col-md-12">
                                <small className="text-muted">University</small>
                              </div>
                              <div className="col-md-12">
                                <span className="font-bold-500">{x["eduInstituteName"]}</span>
                              </div>
                              <div className="col-md-12">
                                <small className="text-muted">Qualification</small>
                              </div>
                              <div className="col-md-12">
                                <span className="font-bold-500"> {x["eduQualification"]}</span>
                              </div>
                              <div className="col-md-12">
                                <small className="text-muted">Passing Out Year</small>
                              </div>
                              <div className="col-md-12">
                                <span className="font-bold-500"> {x["eduPassingYear"]}</span>
                              </div>
                              <div className="col-md-12">
                                <small className="text-muted">Educational Field / Stream</small>
                              </div>
                              <div className="col-md-12">
                                <span className="font-bold-500"> {x["eduField"].join(", ")}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>

          <div className="p-2 ml-2 border-0">
            {accessActionAddEducation && (
              <a className="add-link" onClick={() => setShowModal(true)}>
                {" "}
                + Add Education
              </a>
            )}
          </div>
        </Accordion>
      )}
    </>
  );
};
export default Education;
