/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Revenue  info UI for  Listing, edit, add and delete and API wiring up
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 09/Sep/2021 
	Author						: Pankaj Kulshreshtha
================================================================ 
*/

import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { fetchRevenue, deleteRevenue, fetchSingleRevenue, saveRevenue, editRevenue } from "../../../../../middleware/services/companyApi";
import Accordion from "../../../../components/accordion";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { revenueValidationSchema } from "../../../../helper/validationSchema/companyValidationSchema";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import ReactTooltip from "react-tooltip";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const initialState = {
  revenueYear: "",
  revenueAmount: "",
  revenueSourceUrl: "",
};

const Revenue = (props) => {
  /* Common access */
  const accessPermissionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_REVENUE);
  const accessPermissionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_REVENUE);
  const accessPermissionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_REVENUE);

  const companyId = props.companyId;
  const dispatch = useDispatch();
  const [revenue, setRevenue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const revenueYearRef = useRef();
  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    validationSchema: Yup.object().shape(revenueValidationSchema),
  });

  useEffect(() => {
    handleFetchRevenue();
  }, []);

  const handleFetchRevenue = async () => {
    const response = await fetchRevenue(companyId, { sort: "revenueYear:asc" });
    if (response && response?.data?.data) {
      let data = response?.data?.data?.companyRevenue?.map((item) => {
        return {
          ...item,
        };
      });
      setRevenue(data);
    }
    setLoading(false);
  };
  // delete a revenue
  const handleDeleteRevenue = async (revenueId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await deleteRevenue(companyId, revenueId);
          dispatch(actionSuccess("Revenue deleted successfully"));
          await handleFetchRevenue();
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to Delete ?",
        title: "dialogAlertCssWarning",
      })
    );
  };

  // save a revenue
  const handleSaveRevenue = async () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        // console.log(res);
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let val = formik.values;
        const payload = {};
        payload.revenueYear = val.revenueYear;
        payload.revenueCurrency = "USD";
        payload.revenueAmount = val.revenueAmount == "NA" ? val.revenueAmount : +val.revenueAmount;
        payload.revenueSourceUrl = val.revenueSourceUrl;
        saveRevenue(companyId, payload)
          .then((response) => {
            setShowModal(!showModal);
            formik.handleReset();
            dispatch(actionSuccess("Revenue added successfully"));
            handleFetchRevenue();
          })
          .catch((err) => {
            dispatch(actionError(err.data.message || "Something went wrong"));
          });
      }
    });
  };

  // handleEdit revenue
  const handleEditRevenue = async () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        // console.log(res);
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let val = formik.values;
        const payload = {};
        payload.revenueYear = val.revenueYear;
        payload.revenueAmount = val.revenueAmount == "NA" ? val.revenueAmount : +val.revenueAmount;
        payload.revenueSourceUrl = val.revenueSourceUrl;
        const revenueId = val.id;
        delete val.id;
        editRevenue(companyId, revenueId, payload)
          .then((response) => {
            setEdit(false);
            setShowModal(!showModal);
            formik.handleReset();
            dispatch(actionSuccess("Revenue updated successfully"));
            handleFetchRevenue();
          })
          .catch((err) => {
            dispatch(actionError(err.data ? err.data.message : "Something went wrong"));
          });
      }
    });
  };

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <>
      <Accordion heading="Revenue" stepCompleted={revenue && revenue.length > 0 ? true : false} step={4}>
        <div className="cy-tbl-revenue table-responsive pt-2">
          <table className="normal-table table-bordered table">
            <thead>
              <tr>
                <th className="text-center" style={{ width: window.innerWidth < 1400 ? "20%" : "200px" }}>
                  Revenue Year
                </th>
                <th className="text-right" style={{ width: window.innerWidth < 1400 ? "20%" : "200px" }}>
                  Amount (Million USD)
                </th>
                <th>Revenue Source URL</th>
                <th className="text-center internal-grid-action">Action</th>
              </tr>
            </thead>
            <tbody>
              {revenue.length
                ? revenue.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="text-center">{item.revenueYear || "--"}</td>
                        <td className="text-right">{(typeof item.revenueAmount === "number" && `$${item.revenueAmount}`) || "--"}</td>
                        <td>{item.revenueSourceUrl}</td>
                        <td className="text-center">
                          {accessPermissionEdit || accessPermissionDelete ? (
                            <ThreeDotMenu
                              rowID={item.id}
                              Edit={
                                accessPermissionEdit
                                  ? () => {
                                      setEdit(true);
                                      fetchSingleRevenue(companyId, item.id).then((res) => {
                                        if (res && res?.data?.data?.companyRevenue) {
                                          const val = res?.data?.data?.companyRevenue;
                                          formik.setValues({ ...val });
                                        }
                                      });
                                      setShowModal(!showModal);
                                    }
                                  : undefined
                              }
                              Delete={
                                accessPermissionDelete
                                  ? () => {
                                      handleDeleteRevenue(item.id);
                                    }
                                  : undefined
                              }
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
          <div className="text-right">
            {accessPermissionAdd ? (
              <button
                type="button"
                className="btn btn-dark cy-btn"
                onClick={() => {
                  setEdit(false);
                  setShowModal(!showModal);
                }}
              >
                Add New
              </button>
            ) : null}
          </div>
        </div>
      </Accordion>
      <Modal show={showModal} backdrop="static" className="modal fade md" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-header bg-none text-dark">
          <h5 className="modal-title" id="exampleModalLabel">
            {isEdit ? "Edit Revenue" : "Add Revenue"}
          </h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              setShowModal(!showModal);
              formik.handleReset();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <TextField
                type="number"
                formik={formik}
                className="form-control"
                id="revenueYear"
                placeholder="Revenue Year *"
                name="revenueYear"
                ref={revenueYearRef}
              />
            </div>
            <div className="col-md-6" data-tip="Enter NA if Amount not available">
              <TextField
                formik={formik}
                className="form-control"
                id="revenueAmount"
                placeholder="Revenue Amount (Million USD) *"
                name="revenueAmount"
              />
            </div>
            <ReactTooltip />
            <div className="col-md-6">
              <TextField formik={formik} id="revenueSourceUrl" placeholder="Revenue Source URL *" name="revenueSourceUrl" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-dark cy-btn mr-3"
            onClick={() => {
              setShowModal(!showModal);
              formik.handleReset();
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary cy-btn" onClick={isEdit ? handleEditRevenue : handleSaveRevenue}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Revenue;
