/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  video Trend Tagging Component 
	 Summary : - Import Trend tree view from common component
	  - Save selected trend data
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 12/June/2024
	Author						: Satya Prakash Mall
================================================================ 
*/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TreeView from "../../../../../components/treeView/trend";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import {
  saveVideoTrend,
  deleteVideoTrend,
  fetchVideoTrend,
  deleteManyVideoTrend,
  fetchSingleVideo,
  updateContentTags,
  saveVideoTrendSequence,
} from "../../../../../../middleware/services/videoApi";

const TaxomonyUI = (props) => {
  const dispatch = useDispatch();
  const videoId = props.videoId;
  const [saveTreeData, setSaveTreeData] = useState(false);
  const handleSave = (selectedTech, checkValues, callback, sgfData, callback2) => {
    saveTrend(selectedTech, checkValues, callback, sgfData, callback2);
  };

  /*
	@param selectedTech : Selected trend to save their respective industry
	@param taxonomyIds : Selected taxonomy
	@param callback : use for re-render tree component

	@description : Save Video trend 
		1. Deleted all Video taxonomy for respecting Video trend
		2. Add Video taxonomy for respecting Video trend
	*/
  const saveTrend = (selectedTech, taxonomyIds, callback, sgfData, callback2) => {
    if (selectedTech) {
      dispatch(showLoader());
      deleteVideoTrend(videoId, selectedTech)
        .then(() => {
          dispatch(hideLoader());
          if (taxonomyIds.length > 0) {
            dispatch(showLoader());
            try {
              const response = saveVideoTrend(videoId, { taxonomyIds: taxonomyIds, sgfData: sgfData });
              response
                .then((_response) => {
                  callback();
                  setSaveTreeData(false);
                  dispatch(hideLoader());
                  dispatch(actionSuccess("Trend Tagged Successfully"));
                })
                .catch((_e) => {
                  setSaveTreeData(false);
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...!"));
                });
            } catch (_e) {
              setSaveTreeData(false);
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            }
          } else {
            setSaveTreeData(false);
            callback();
          }
        })
        .catch((_e) => {
          setSaveTreeData(false);
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    }
  };

  const deleteTrend = (checkedTrends, callback, callback2, selectedTech) => {
    deleteManyVideoTrend(videoId, checkedTrends)
      .then((res) => {
        callback();

        dispatch(actionSuccess("Taxonomy deleted successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  const handleSaveTagSequence = (sortedTrends, callback) => {
    saveVideoTrendSequence(videoId, sortedTrends)
      .then((res) => {
        callback();
        dispatch(actionSuccess("Taxonomy sequence added Successfully."));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  return (
    <TreeView
      handleSave={handleSave}
      saveTreeData={saveTreeData}
      contentId={videoId}
      fetchContentTagging={fetchVideoTrend}
      loadOtherTagInfo={props.loadOtherTagInfo}
      setSaveTreeData={setSaveTreeData}
      isDelete={true}
      deleteTrend={deleteTrend}
      accessPermissionTaxonomyDelete={props?.accessPermissionTaxonomyDelete}
      accessPermissionTaxonomy={props?.accessPermissionTaxonomy}
      accessPermissionAddTags={props?.accessActionTaxonomyAddTags}
      accessActionTaxonomyShowInProduct={props?.accessActionTaxonomyShowInProduct}
      fetchTags={fetchSingleVideo}
      saveTags={updateContentTags}
      contentModule={"Video"}
      handleSaveTagSequence={handleSaveTagSequence}
    />
  );
};

export default TaxomonyUI;
