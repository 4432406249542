/**
 * @author: Pankaj Kulshreshtha
 * @date : 14/May/2021
 * File Description: Video module validation schema
 */
import * as Yup from "yup";
import * as REGEX from "../../../../../config/regexValidation";
const shortUrlPattern = /^[a-z0-9-_]+$/;
export const videoDetailsValidationSchema = {
  // selIndustry: Yup.string().when("pVariant", {
  //   is: (obj) => {
  //     return obj && obj === "Insider";
  //   },
  //   then: Yup.string().required("Please select industry"),
  //   otherwise: Yup.string().nullable(),
  //   // .oneOf([null, undefined], 'Please select demo users'),
  // }),
  // selTrends: Yup.string().when(["pVariant", "selIndustry"], {
  //   is: (pVariant, selIndustry) => {
  //     return (pVariant && pVariant === "Insider") || (selIndustry && selIndustry === "");
  //   },
  //   then: Yup.string().required("Please select trend").typeError("Please select trend"),
  //   otherwise: Yup.string().nullable(),
  //   // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
  // }),
  videoType: Yup.string().required("Please select video type"),
  videoTitle: Yup.string()
    .trim("Please enter video title")
    .required("Please enter video title")
    .max(125, "Video title must be at most 125 characters"),
  videoSource: Yup.string().when("status", {
    is: (obj) => {
      return obj && ["Active", "Inactive"].includes(obj);
    },
    then: Yup.string().required("Please select video source"),
    otherwise: Yup.string().nullable(),
    // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
  }),
  vimeoVideoId: Yup.string().when("status", {
    is: (obj) => {
      return obj && obj !== "Upcoming";
    },
    then: Yup.string().trim("Please enter video id").required("Please enter video id").min(5, "Video id must be at least 5 characters"),
    otherwise: Yup.string().nullable(),
    // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
  }),
  videoThumbnail: Yup.mixed().required("Please upload video thumbnail"),
  videoDescription: Yup.string()
    .trim("Please enter video description")
    .required("Please enter video description")
    .min(10, "Video description must be at least 10 characters")
    .max(200, "Video description must be at most 200 characters"),
  videoDurationInMinute: Yup.number().required("Please enter video duration").min(1, "Video duration must be greater than 0").nullable(),
  restrictContentForClient: Yup.string().when("pVariant", {
    is: (obj) => {
      return obj && obj === "Insider";
    },
    then: Yup.string().required("Please set restrict insider content for client"),
    // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
  }),
  status: Yup.string().trim("Please select status").required("Please select status"),
  // clientUsers: Yup.string().when('restrictContentForClient', {
  //     is: obj => { return obj && obj === "YES" },
  //     then: Yup.string().required("Please select client user").typeError("Please select client user"),
  //     otherwise: Yup.string().nullable(),
  // }),
  videoRegistrationLink: Yup.string().when(["status", "videoType"], {
    is: (status, videoType) => {
      return status && status === "Upcoming" && videoType && videoType === "Webinar";
    },
    then: Yup.string().required("Please enter registration link for webinar").matches(REGEX.URL, "Please enter valid registration link for webinar"),
    // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
  }),
  timezone1_zone: Yup.string().required("Please select timezone").typeError("Please select timezone"),
  timezone1_date: Yup.date().required("Please enter timezone date").typeError("Please enter timezone date"),
  timezone1_time: Yup.string().required("Please enter timezone time").typeError("Please enter timezone time"),

  whatNextSourceUrl: Yup.string().matches(REGEX.URL, "Please enter valid website/source URL"),
  whatNextSourceEmbedUrl: Yup.string().matches(REGEX.URL, "Please enter valid URL to be embedded in the body"),
  clientUsers: Yup.string().when("restrictContentForClient", {
    is: (obj) => {
      return obj && obj === "YES";
    },
    then: Yup.string().required("Please select client user").typeError("Please select client user"),
    otherwise: Yup.string().nullable(),
  }),
};

export const addQuestionValidationSchema = {
  question: Yup.string().trim("Please enter question").required("Please enter question"),
  answer: Yup.string().trim("Please enter answer").required("Please enter answer").min(5).max(1000),
};
export const addDocumentValidationSchema = {
  documentTitle: Yup.string().trim("Please enter document title").required("Please enter document title"),
  documentFile: Yup.mixed().required("Please upload document"),
};
export const editDocumentValidationSchema = {
  documentTitle: Yup.string().trim("Please enter document title").required("Please enter document title"),
};
export const addHostValidationSchema = {
  firstName: Yup.string().trim("Please enter first name").required("Please enter first name"),
  lastName: Yup.string().trim("Please enter last name").required("Please enter last name"),
  company: Yup.string().trim("Please enter company").required("Please enter company"),
  designation: Yup.string().trim("Please enter designation").required("Please enter designation"),
  linkedIn: Yup.string().matches(REGEX.LINKEDIN, "Please enter valid LinkedIn URL"),
  twitter: Yup.string().matches(REGEX.TWITTER, "Please enter valid twitter URL"),
  facebook: Yup.string().matches(REGEX.FACEBOOK, "Please enter valid facebook URL"),
};
