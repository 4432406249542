/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 14/May/2021
 * File Description: Add/Edit Video Details
 */

import React, { useState, useEffect } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useDispatch } from "react-redux";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { getProductVariant } from "../../../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { getDocuments, addDocument, editDocument, deleteDocument, fetchDocUrl, fetchSingleVideo } from "../../../../../middleware/services/videoApi";
import PopupModal from "./documentModal";
import _ from "underscore";
import { useFormik } from "formik";
import { fetchExternalUser } from "../../../../../middleware/services/userApi";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";
import "./document.scss";

const RelatedDocuments = (props) => {
  const dispatch = useDispatch();
  const videoId = props.videoId || "";
  const [videoExpertData, setVideoExpertData] = useState([]);
  const [videoExpertDataSource, setVideoExpertDataSource] = useState([]);
  const [checked, setChecked] = useState(undefined);
  const [documentData, setDocumentData] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const productVariant = getProductVariant();

  const breadCrumbLinks = [
    { linkUrl: "/video", linkName: "Video", linkActive: false },
    { linkUrl: videoId ? `/video/${videoId}/edit` : "/video", linkName: (editData && editData?.videoTitle) || "Add New", linkActive: false },
    {
      linkUrl: `/video/ ${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  let actionAccessAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_DOCUMENT);
  let actionAccessEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_DOCUMENT);
  let actionAccessDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_DOCUMENT);

  useEffect(() => {
    if (videoId) {
      dispatch(showLoader());
      !editData && fetchVideoDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/video"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, editData, props.selectedIndex]);

  const fetchVideoDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "videoTitle",
      "videoContent",
      "videos",
      "videoProfileImage",
      "videoStatus",
      "videoType",
      "videoDuration",
      "videoAccessType",
      "videoUser",
      "videoRegistrationLink",
      "timezone",
      "date",
      "time",
      "videoCommercialImpactTime",
      "videoAreaOfImpact",
      "videoSpecificTechnology",
      "videoTypeOfOrganizationAcademic",
      "videoTypeOfOrganizationResearch",
      "videoTypeOfOrganizationCompany",
      "videoCustomTags",
      "videoExpert",
      "productVariant",
      "surveyAudience",
      "videoIndustryTag",
      "videoIndustryTrendsTags",
      "videoTimezones",
      "videoSource",
      "videoSourceUrl",
      "videoSourceEndUrl",
    ];

    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    fetchSingleVideo(videoId, params).then((res) => {
      let data = res.data.data || {};
      setEditData(data);
      // console.log(data.videoTimezones)
      if (data?.videoExpert) {
        const userData = data?.videoExpert?.map((v) => {
          return v.userId;
        });
        userData && userData.length && searchUserByIds(userData);
      }
      data.videoAccessType && setChecked(data.videoAccessType === "Generic" ? false : true);
      formik.setValues({
        videoTitle: data.videoTitle,
        videoDescription: data.videoContent,
        vimeoVideoId: data.videos.videoId,
        videoThumbnail: data.videos.videoThumbnail || "",
        videoSource: data.videos.videoSource,
        status: data.videoStatus,
        videoType: data.videoType,
        commercialImpact: data.videoCommercialImpactTime,
        videoAreaOfImpact: data.videoAreaOfImpact,
        specificTechnology: data.videoSpecificTechnology || [],
        typeOfOraganizationAcademic: data.videoTypeOfOrganizationAcademic || [],
        typeOfOraganizationResearch: data.videoTypeOfOrganizationResearch || [],
        typeOfOraganizationCompany: data.videoTypeOfOrganizationCompany || [],
        customTag: data.videoCustomTags || [],
        videoDurationInMinute: data.videoDuration,
        restrictContentForClient: data.videoAccessType && data.videoAccessType === "Generic" ? "NO" : "YES",
        videoRegistrationLink: data.videoRegistrationLink || "",
        whatNextVideoSource: data.videoSource || "",
        whatNextSourceUrl: data.videoSourceUrl || "",
        whatNextSourceEmbedUrl: data.videoSourceEmbedUrl || "",

        timezone1: (data.videoTimezones && data.videoTimezones[0]) || {},
        timezone2: (data.videoTimezones && data.videoTimezones[1]) || {},
        timezone3: (data.videoTimezones && data.videoTimezones[2]) || {},
      });
      if (productVariant === "Insider") {
        formik.setFieldValue("clientUsers", data.videoUser && _.pluck(data.videoUser, "userId"));
        // formik.setFieldValue("selIndustry", (data.videoIndustryTag && data.videoIndustryTag[0].industryId) || "");
        // formik.setFieldValue("selTrends", data.videoIndustryTrendsTags && _.pluck(data.videoIndustryTrendsTags, "trendId"));
      }

      dispatch(hideLoader());
    });
  };
  const searchUserByIds = async (ids) => {
    const response = await fetchExternalUser({
      fields: ["userFname", "userLname", "userEmail"],
      filters: [["_id", "in", ids]],
      sort: "userFname:asc",
      limit: 10,
    });
    if (response?.data && response?.data?.data) {
      setVideoExpertData(response?.data?.data);
      const resData = response.data.data.map((v) => {
        return { label: `${v.userFname} ${v.userLname} (${v.userEmail})`, value: v.id };
      });
      if (resData) {
        setVideoExpertDataSource(resData);
        formik.setFieldValue(
          "videoExpert",
          resData.map((v) => {
            return v.value;
          })
        );
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    // initialValues: { ...initialState },
    // validationSchema: Yup.object().shape(videoDetailsValidationSchema)
  });
  const saveDocument = async (payload) => {
    const formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    await addDocument(videoId, formData)
      .then((res) => {
        if (res) {
          dispatch(actionSuccess("Document saved successfully"));
          fetchAllDocuments();
          // console.log("Document id ", res.data.data.id);
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "something went wrong"));
      });
  };
  const updateDocument = async (documentId, payload) => {
    const formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    await editDocument(videoId, formData, documentId)
      .then((res) => {
        if (res) {
          dispatch(actionSuccess("Document updated successfully"));
          fetchAllDocuments();
          console.log("Document id ", res.data.data.id);
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "something went wrong"));
      });
  };

  const fetchAllDocuments = () => {
    let params = {};
    let defaultFilter = [];
    let fields = ["docTitle", "docFile"];
    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    getDocuments(videoId, params).then((res) => {
      let data = res.data.data || {};
      data && setDocumentData(data);
      dispatch(hideLoader());
    });
  };
  useEffect(() => {
    if (videoId) {
      dispatch(showLoader());
      if (!documentData) fetchAllDocuments();
      documentData && dispatch(hideLoader());
    }
    dispatch(setSideNavForcedActiveLink("/video"));
  }, [dispatch, documentData]);

  return (
    <>
      <div className="gennx-content-wrapper padding-bottom-100i pt-3" style={{ width: "99.5%" }}>
        <div className="mx-2">
          {actionAccessAdd && documentData && documentData.videoDocuments.length > 0 && (
            <div className=" pr-3 pb-5 mb-2">
              <ButtonComponent
                cssClass="btn btn-dark text-capitalize float-right"
                style={{ "margin-right": "-0.8em", height: "40px" }}
                onClick={() => {
                  setDocumentId(null);
                  setIsOpen(true);
                }}
              >
                Add New Document
              </ButtonComponent>
            </div>
          )}
          <div className="row">
            {documentData &&
              documentData.videoDocuments.length > 0 &&
              documentData.videoDocuments.map((d, i) => {
                return (
                  <React.Fragment key={`doclist-${i}`}>
                    <div className="col-md-4 pb-4 ">
                      <div style={{ display: `flex`, flexDirection: `column` }}>
                        <div className="e-card e-card-horizontal" style={{ overflow: "unset" }}>
                          <div className="col-md-2 pdf svg_icons"></div>
                          <div className="e-card-stacked">
                            <div className="videoDocumentCss e-card-header">
                              <div className="e-card-header-caption font-weight-bold">{d.docTitle}</div>
                              {actionAccessEdit || actionAccessDelete ? (
                                <ThreeDotMenu
                                  Edit={
                                    actionAccessEdit
                                      ? () => {
                                          setDocumentId(d.id);
                                          setIsOpen(true);
                                        }
                                      : undefined
                                  }
                                  Delete={
                                    actionAccessDelete
                                      ? () => {
                                          dispatch(
                                            showAlertBox({
                                              okCallback: async () => {
                                                deleteDocument(videoId, d.id)
                                                  .then((res) => {
                                                    if (res) {
                                                      dispatch(actionSuccess("Document deleted successfully"));
                                                      fetchAllDocuments();
                                                      console.log("Document id ", res.data.data.id);
                                                    } else {
                                                      dispatch(actionError("Something went wrong"));
                                                    }
                                                  })
                                                  .catch((err) => {
                                                    dispatch(actionError(err?.data?.message || "Failed to delete video"));
                                                  });
                                              },
                                              content: "Are you sure you want to delete?",
                                              okText: "Delete",
                                              cancelText: "Cancel",
                                              title: "dialogAlertCssWarning",
                                            })
                                          );
                                        }
                                      : undefined
                                  }
                                />
                              ) : null}
                            </div>
                            <div className="e-card-content">
                              <a className="e-link" href={fetchDocUrl(d.docFileName, d.docName)} download>
                                <i className="fas fa-download" data-tip="Download" /> {d.docName}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>

          {documentData && documentData.videoDocuments.length === 0 && (
            <div className="e-content pt-4">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="text-muted text-center">You don't have any items here.</h5>
                    <div className="text-center">
                      {actionAccessAdd && (
                        <ButtonComponent cssClass="btn btn-dark text-capitalize" style={{ height: "40px" }} onClick={() => setIsOpen(true)}>
                          Add New Document
                        </ButtonComponent>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <PopupModal
          onSaveData={saveDocument}
          onUpdateData={updateDocument}
          documentId={documentId}
          productVariant={productVariant}
          closeHandler={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
          videoId={videoId}
          popupTitle={documentId ? "Edit Document" : "Add Document"}
        />
      )}
    </>
  );
};

export default RelatedDocuments;
