import React, { useEffect } from "react";
import { dataPublishingNews } from "../../../../middleware/services/cmsApi";
import { actionStart, actionSuccess, actionError, showMessage } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setIsPublishClickedState, setOverviewUpdateStatus } from "../../../../middleware/actions/newsAction";
import { useDispatch, useSelector } from "react-redux";
import { getProductVariant } from "../../../../utilities";

const DataPublicUI = (props) => {
  const isNewsSaved = useSelector((state) => state.newsState.updateStatus);
  const clickStatus = useSelector((state) => state.newsState.clickStatus);
  const dispatch = useDispatch();
  const newsId = props.newsId;
  useEffect(() => {
    if (isNewsSaved) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            const payload = { platform: getProductVariant() };
            // console.log(payload, newsId);
            dataPublishingNews(newsId, payload)
              .then((response) => {
                response ? dispatch(actionSuccess("News published successfully")) : dispatch(actionError("Something went wrong"));
                props.fetchNews(newsId);
                dispatch(setOverviewUpdateStatus());
                dispatch(setIsPublishClickedState());
              })
              .catch((err) => {
                dispatch(actionError(err.data.message || "Something went wrong"));
              });
          },
          content: "Are you sure you want to publish the news on live ?",
          okText: "Publish",
          cancelText: "Cancel",
          title: "dialogAlertCss",
        })
      );
      dispatch(setOverviewUpdateStatus());
      dispatch(setIsPublishClickedState());
    }
  }, [isNewsSaved]);

  if (props.activeStepper != "#basic" || !newsId) {
    return null;
  }
  return (
    <>
      <button
        type="button"
        className="btn btn-primary cy-btn pl-4 pr-4 ml-3"
        onClick={() => {
          // console.log("TTTT", clickStatus);
          if (clickStatus) dispatch(setIsPublishClickedState());
          if (isNewsSaved) dispatch(setOverviewUpdateStatus());
          dispatch(setIsPublishClickedState(true));
        }}
      >
        Publish
      </button>
    </>
  );
};

export default DataPublicUI;
