/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : Key Impact Area API 
----------------------------------------------------------------
	Creation Details
	Date Created				: 09/Jun/2021
	Author						: Sandeep K Sharma
================================================================
*/

import { KIA_API as API } from "../../config/apiConfig";

// fetch Kia list
export const fetchKias = (params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: "/kia",
		method: "GET",
		params: params
	});
};

// fetch single kia details
export const fetchSingleKia = (kiaId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (fields) params.fields = JSON.stringify(fields);
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: `/kia/${kiaId}`,
		method: "GET",
		params: params
	});
};

// Add new Kia
export const addKia = (payload) => {
	return API({
		url: "/kia",
		method: "POST",
		data: payload
	});
};
// Edit existing Kia
export const updateKia = (kiaId, payload) => {
	return API({
		url: `/kia/${kiaId}`,
		method: "PUT",
		data: payload
	});
};
// save key comments to Kia
export const saveKeyComments = (kiaId, payload) => {
	return API({
		url: `/kia/${kiaId}/key-comments`,
		method: "PUT",
		data: payload
	});
};
// delete single key comments
export const deleteKeyComments = (kiaId, payload) => {
	return API({
		url: `/kia/${kiaId}/key-comments`,
		method: "DELETE",
		data: payload
	});
};
// delete single Kia
export const deleteSingleKia = (kiaId, payload) => {
	return API({
		url: `/kia/${kiaId}`,
		method: "DELETE",
		data: payload
	});
};
// delete selected Kias
export const deleteSelectedKias = (payload, params = {}) => {
	let { filters = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: "/kia",
		method: "DELETE",
		params: params,
		data: payload
	});
};
//publish Kia
export const publishKia = (kiaId,payload) => {
	return API({
		url: `/kia/${kiaId}/publish`,
		method: "PUT",
		data: payload
	});
};

// Get all Industry Applications
export const getIndustryApplications = (kiaId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/kia/${kiaId}/industry-application`,
		method: "GET",
		params: params
	});
};
// Get single IndustryApplications

export const getSingleIndustryApplications = (appId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (fields) params.fields = JSON.stringify(fields);
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: `/kia/industry-application/${appId}`,
		method: "GET",
		params: params
	});
};
// Add new IndustryApplications
export const addIndustryApplications = (kiaId, payload) => {
	return API({
		url: `/kia/${kiaId}/industry-application`,
		method: "POST",
		data: payload
	});
};
// Update IndustryApplications
export const updateIndustryApplications = (appId, payload) => {
	return API({
		url: `/kia/industry-application/${appId}`,
		method: "PUT",
		data: payload
	});
};
// Delete Industry Applications
export const deleteSingleIndustryApplication = (appId) => {
	return API({
		url: `/kia/industry-application/${appId}`,
		method: "DELETE"
	});
};
// Delete Multiple Industry Applications
export const deleteSelectedIndustryApplication = (payload, params = {}) => {
	let { filters = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: "/kia/industry-application",
		method: "DELETE",
		params: params,
		data: payload
	});
};

// Get all Exponential Technologies
export const getExponentialTechnologies = (kiaId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/kia/${kiaId}/exponential-technology`,
		method: "GET",
		params: params
	});
};
// Get single Exponential Technologies

export const getSingleExponentialTechnology = (expTechId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (fields) params.fields = JSON.stringify(fields);
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: `/kia/exponential-technology/${expTechId}`,
		method: "GET",
		params: params
	});
};
// Add new Exponential Technologies
export const addExponentialTechnologies = (kiaId, payload) => {
	return API({
		url: `/kia/${kiaId}/exponential-technology`,
		method: "POST",
		data: payload
	});
};
// Update Exponential Technologies
export const updateExponentialTechnology = (expTechId, payload) => {
	return API({
		url: `/kia/exponential-technology/${expTechId}`,
		method: "PUT",
		data: payload
	});
};
// Delete Exponential Technologies
export const deleteSingleExponentialTechnology = (appId) => {
	return API({
		url: `/kia/exponential-technology/${appId}`,
		method: "DELETE"
	});
};
// Delete Multiple Exponential Technologies
export const deleteSelectedExponentialTechnologies = (payload, params = {}) => {
	let { filters = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: "/kia/exponential-technology",
		method: "DELETE",
		params: params,
		data: payload
	});
};
// Get all Exponential Technologies
export const getCurrentChallenges = (kiaId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/kia/${kiaId}/current-challenge`,
		method: "GET",
		params: params
	});
};
// Get single Current Challenge

export const getSingleCurrentChallenge = (chgId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (fields) params.fields = JSON.stringify(fields);
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: `/kia/current-challenge/${chgId}`,
		method: "GET",
		params: params
	});
};
// Add new Current Challenge
export const addCurrentChallenge = (kiaId, payload) => {
	return API({
		url: `/kia/${kiaId}/current-challenge`,
		method: "POST",
		data: payload
	});
};
// Update Current Challenge
export const updateCurrentChallenge = (chgId, payload) => {
	return API({
		url: `/kia/current-challenge/${chgId}`,
		method: "PUT",
		data: payload
	});
};
// Delete Current Challenge
export const deleteSingleCurrentChallenge = (chgId) => {
	return API({
		url: `/kia/current-challenge/${chgId}`,
		method: "DELETE"
	});
};
// Delete Multiple Current Challenges
export const deleteSelectedCurrentChallenge = (payload, params = {}) => {
	let { filters = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: "/kia/current-challenge",
		method: "DELETE",
		params: params,
		data: payload
	});
};


