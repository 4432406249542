import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";

import EnterpriseTaxonomyListing from "../app/views/enterpriseTaxonomyManager";
const PROPERTIES = ["Properties:VIEW", "Properties:FULL"];
const EnterpriseTaxonomyRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/enterprise-taxonomy"
      component={EnterpriseTaxonomyListing}
      pageHeading="GenNx CMS - Enterprise Taxonomy"
      accessRights={{
        moduleName: "Enterprise Taxonomy",
        parentModuleName: "",
        moduleAttribute: [...PROPERTIES],
        interfaceName: "Listing",
      }}
    />
  </Switch>
);

export default EnterpriseTaxonomyRoutes;
