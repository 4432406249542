/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Key Impact Area taxonomy Component
     Require Props :
        handleSave : Save Tree Data
        contentId : Content Id [news id | company id]
        fetchContentData : Fetch content taxonomy
        handleSaveValueChain : Save Value chain data
        contentType : Content Type [News | Company | Product]
 ---------------------------------------------------------------------------------
    Creation Details
    Date Created				: 24/June/2021
    Author						: Sandeep Tijare
================================================================
*/
import React, { useState, useEffect } from "react";
import { DropdownList } from "react-widgets";
import { useDispatch } from "react-redux";
import { fetchTaxonomyTrendIntern } from "../../../../middleware/services/cmsApi";
import { fetchKias } from "../../../../middleware/services/kiaApi";
import {
  saveNewsTaxonomy,
  deleteNewsTaxonomy,
  fetchSingleNews,
  saveNewsValueChain,
  deleteNewsValueChain,
  fetchNewsTaxonomy,
  fetchNewsValueChain,
  fetchNewsKia,
  deleteNewsKia,
  saveNewsKiaTaxonomy,
} from "../../../../middleware/services/newsApi";
import noTaxonomyImage from "../../../../assets/images/no-taxonomy.svg";
import { showLoader, hideLoader, actionError, actionSuccess, showMessage } from "../../../../middleware/actions/utilityAction";
import TreeUI from "../treeUI";
import SortableTree from "../sortableTree";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { Modal } from "react-bootstrap";

const KeyImpactAreaTagging = (props) => {
  const dispatch = useDispatch();
  const [tagging, setTagging] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(undefined);
  const [treeData, setTreeData] = useState([]);
  const [checked, setChecked] = useState([]);
  const [contentTaggedIds, setContentTaggedIds] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [saveTreeData, setSaveTreeData] = useState(false);
  const [leftTreeCollapse, setLeftTreeCollapse] = useState(true);
  const [rightTreeCollapse, setRightTreeCollapse] = useState(true);
  const [leftTreeChecked, setLeftTreeChecked] = useState([]);
  const [rightTreeChecked, setRightTreeChecked] = useState([]);
  const [contentTaggingData, setContentTaggingData] = useState([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  let taggingData = [];

  /*
        @Description : Build tree data for tagged values
        @param : 
        data : taxonomy data
        parentId :technology id
    */
  const buildSelectedTreeJson = (data, industry) => {
    const result = [];
    industry.forEach((element) => {
      element["value"] = element.industryId;
      element["label"] = element.industryName;
      element["showCheckbox"] = false;
      element["id"] = element.industryId;
      element["level"] = 2;
    });

    data.forEach((element) => {
      element["value"] = element.kiaId;
      element["label"] = element.kiaName;
      element["showCheckbox"] = false;
      element["id"] = element.kiaId;
      // element['pare']
    });

    industry.forEach((element) => {
      element["children"] = [];
      data.forEach((element2) => {
        if (element2.kiaIndustryId == element.industryId) {
          element["children"].push(element2);
        }
      });
      if (element["children"].length != 0) {
        result.push(element);
      }
    });
    return result;
  };

  /*
        @Description : Fetch KIA tagging for respective content 
        1. fetchContentTagging received from parent component as a props
    */
  const fetchContentTagging = () => {
    dispatch(showLoader());
    const contentTaxonomy = props.fetchKiaTagging(props.contentId);
    contentTaxonomy
      .then((res) => {
        const { data = [] } = res.data;
        if (data.length) {
          setContentTaggingData(data);
          taggingData = data.map((values) => values.kiaId);
        }
        setContentTaggedIds(taggingData);
        if (!taggingData?.length) {
          fetchIndustries([]);
          setReRender(false);
        } else setReRender(true);
        setLeftTreeChecked([]);
        setRightTreeChecked(taggingData);
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchProductTaxonomy :: ", err);
      });
  };

  /**
   * @description fetch Industries for KIA Tagging
   */
  const fetchIndustries = (treeTaggedData) => {
    fetchTaxonomyTrendIntern({
      filters: [["isTrendNode", "eq", 1]],
      sort: "trendParentName:asc",
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
    })
      .then((res) => {
        const { data = [] } = res.data;
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.trendParentId] = {
            industryId: sTT.trendParentId,
            industryName: sTT.trendParentName,
          };
        });
        let industriesValues = [...Object.values(industryDropdownValues)];
        setIndustries(industriesValues);
        if (selectedIndustry) fetchTaxonomy(selectedIndustry, treeTaggedData.length ? contentTaggedIds : []);
        else fetchTaxonomy(industriesValues[0].industryId, treeTaggedData.length ? contentTaggedIds : []);
        let selectedNodeData = [];
        if (contentTaggingData) selectedNodeData = buildSelectedTreeJson(treeTaggedData, industriesValues);
        setTagging(selectedNodeData);
        setLeftTreeCollapse(true);
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Industry Failed";
        dispatch(actionError(errMsg));
      });
  };
  /*
      @Description : Load KIA Overview Details on Edit and 
    */
  useEffect(() => {
    fetchContentTagging();
  }, []);

  useEffect(() => {
    if (reRender && contentTaggedIds && contentTaggingData?.length) {
      fetchIndustries(contentTaggingData);
      setReRender(false);
    }
  }, [contentTaggedIds, reRender, contentTaggingData]);

  useEffect(() => {
    if (saveTreeData) {
      setSaveTreeData(false);
    }
  }, [saveTreeData]);

  // useEffect(() => {
  //     if (props.reRender) {
  //         fetchContentTagging();
  //         setTimeout(() => {
  //             fetchTaxonomy(selectedIndustry, contentTaggedIds);
  //         }, [200]);

  //     }
  // }, [props.reRender]);

  /*
       @Description : Fetch all taxonomy for selected Industry
       @param 
       dtRoot : KIA Id
       taggingData : Content taxonomy tagged data [ Use for mark as default check ]
   */
  const fetchTaxonomy = (industryId, taggedIds) => {
    // taggingData = contentTaggedIds || taggingData
    setReRender(false);
    dispatch(showLoader());
    fetchKias({
      fields: ["id", "kiaName"],
      filters: [["kiaIndustryTag.industryId", "eq", industryId]],
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
    })
      .then((res) => {
        let { data = [] } = res.data;
        let checkNode = [];
        if (data.length) {
          data.forEach((element) => {
            if (taggedIds.includes(element.id)) {
              checkNode.push(element.id);
              element["isChecked"] = true;
            }
          });
          const treeJosnArray = data
            .map((u) => {
              return {
                value: u.id,
                label: u.kiaName,
                id: u.id,
                className: "kia-tree",
                // level: 2,
                isChecked: u?.isChecked ? u.isChecked : false,
              };
            })
            .filter((v) => {
              return typeof v === "object";
            });

          setChecked(checkNode);
          setTreeData(treeJosnArray);
          setRightTreeChecked(checkNode);
          setRightTreeCollapse(true);
          dispatch(hideLoader());
        } else {
          setTreeData([]);
          dispatch(hideLoader());
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchKias :: ", err);
      });
  };
  const leftTreeOnChecked = (checkedLeft) => {
    var result = [];
    checkedLeft.forEach(function (a, i) {
      result.push(a.id);
      if (Array.isArray(a.children)) {
        result = result.concat(leftTreeOnChecked(a.children));
      }
    });
    setLeftTreeChecked(result);
    return result;
  };

  /*
    @Description : Make bridge to send callback
    @param : 
    selectedTech : selected Industry
    checkValues : Checked taxonomy
*/
  const handleSaveBridge = (selectedTech, checkValues) => {
    // props.kiaRender()
    props.handleSaveKIA(selectedTech, checkValues, props.selectedTab, fetchContentTagging);
  };

  const deleteTechnology = () => {
    if (props.isDelete) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            props.deleteKia([...new Set(leftTreeChecked)], fetchContentTagging);
          },
          okText: "Delete",
          cancelText: "Cancel",
          content: "Do you want to remove taxonomy tagging ? ",
          title: "dialogAlertCss",
        })
      );
    } else {
      setShowModalDelete(true);
    }
  };

  useEffect(() => {
    if (props.saveTreeData && props.selectedTab == 1) {
      handleSaveBridge(selectedIndustry, rightTreeChecked);
    }
  }, [props.saveTreeData]);

  useEffect(() => {
    if (props?.submitStatus && props.selectedTab == 1) {
      props.handleSubmit(selectedIndustry, rightTreeChecked, "kia", fetchContentTagging);
    }
  }, [props.submitStatus]);

  return (
    <div
      className="row"
      style={{
        height: "74vh",
        marginLeft: "0px",
        marginRight: "0px",
        paddingRight: "0.7em",
      }}
    >
      <div className="col-md-6 TaxonomyNewTabLeftContent">
        <div className="row" style={{ paddingTop: "2.2vh", paddingBottom: "7px" }}>
          <div className="">
            <h6 className="font-weight-bold headline">Key Impact Area Tagging</h6>
          </div>
          {tagging?.length > 0 ? (
            <div className="ml-auto">
              {props?.accessPermissionTaxonomyDelete && (
                <button
                  type="button"
                  className={`btn  px-3  mr-3 ${leftTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                  onClick={() => deleteTechnology()}
                >
                  Delete
                </button>
              )}
              {leftTreeCollapse ? (
                <span
                  className="expandButton btn px-3 mr-3"
                  style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                  onClick={() => setLeftTreeCollapse(false)}
                >
                  Expand All{" "}
                </span>
              ) : (
                <span
                  className="expandButton btn px-3 mr-3"
                  style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                  onClick={() => setLeftTreeCollapse(true)}
                >
                  Collapse All
                </span>
              )}
            </div>
          ) : null}
        </div>
        <div className="row m-0 p-0">
          <div className="col-md-12 m-0 p-0">
            <div style={{ paddingTop: "1em" }}>
              {tagging && tagging.length > 0 ? (
                <SortableTree
                  treeData={tagging}
                  parentId={"kiaIndustryId"}
                  treeCollapse={leftTreeCollapse}
                  onClick={leftTreeOnChecked}
                  hieghtClass={"custom-tagging-tree-container-wrapper"}
                />
              ) : (
                <div className="text-center">
                  <img src={noTaxonomyImage} alt="No Tagging" width="250" height="250" />
                  <p>
                    Select tags to create <span>Key Impact Area Taxonomy</span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 TaxonomyNewTabRightContent">
        <div className="row" style={{ justifyContent: "left", paddingLeft: "0.4em" }}>
          <div className="col-md-4" style={{ marginLeft: "1em", paddingTop: "2.2vh" }}>
            {industries.length > 0 ? (
              <DropDownListComponent
                value={selectedIndustry || setSelectedIndustry(industries[0].industryId)}
                change={(value) => {
                  setSelectedIndustry(value.itemData?.industryId);
                  fetchTaxonomy(value.value, contentTaggedIds);
                }}
                cssClass={"customCss e-outline"}
                floatLabelType="Auto"
                popupHeight="250px"
                dataSource={[...industries]}
                fields={{ text: "industryName", value: "industryId" }}
                placeholder="Select Industry"
              />
            ) : null}
          </div>
        </div>
        <div className="row" style={{ paddingTop: "1em" }}>
          <div className="col-md-12">
            {treeData?.length ? (
              <div className="float-right">
                {props?.accessPermissionTaxonomy && (
                  <button
                    type="button"
                    className={`btn  px-3  mr-3 ${rightTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                    onClick={() => {
                      if (props?.isDelete) {
                        props.setSaveTreeData("save");
                      } else {
                        setShowModalAdd(true);
                      }
                    }}
                  >
                    Add
                  </button>
                )}
              </div>
            ) : null}
            {treeData.length > 0 ? (
              <SortableTree
                treeData={treeData}
                // parentId={"dtParentId"}
                treeCollapse={rightTreeCollapse}
                onClick={(checkedRight) => {
                  let ids = checkedRight.map((item) => {
                    return item.id;
                  });
                  setRightTreeChecked(ids);
                }}
                hieghtClass={"custom-tagging-tree-container-wrapper"}
                noChild={true}
              />
            ) : null}
          </div>
        </div>
      </div>
      <Modal show={showModalAdd} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              Since this company has products, you can not add taxonomy on company level. You need to add taxonomy in product.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalAdd(!showModalAdd)}>
            Ok
          </button>
        </div>
      </Modal>

      <Modal show={showModalDelete} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              Since this company has products, you can not delete taxonomy from company. You need to delete taxonomy from product.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalDelete(!showModalDelete)}>
            Ok
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default KeyImpactAreaTagging;
