/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Render Utility component is responsible for rendering respective component
	 [e.g Overview | Content Management | Tagging]
 ---------------------------------------------------------------------------------
	Creation Details
	Date Created				: 21/Aug/2020
	Author						: Aditya Tijare
================================================================
*/
import React from "react";
import Overview from "../overview";
import Settings from "../settings";
import GridTask from "../task";
import AccessDenied from "app/components/accessDenied";
const Content = React.lazy(() => import("../content"));
const Editor = React.lazy(() => import("../editor"));
const Tagging = React.lazy(() => import("../tagging"));

const RenderUtility = (props) => {
  const { deliverableId = "", pageId = "", selectedIndex } = props;
  return (
    <>
      <div key="deliverableBasic">
        {props?.interfaceAccess ? <Overview {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} /> : <AccessDenied />}
      </div>
      <div key="gridTask" className="mt-4">
        {selectedIndex === 1 && props.interfaceAccess ? (
          <GridTask {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
      <div key="deliverableContent">
        {props?.interfaceAccess ? (
          <>
            {selectedIndex === 2 ? (
              <Editor
                {...props}
                deliverableId={deliverableId}
                pageId={pageId}
                selectedIndex={props.selectedIndex}
                setCurrentTab={props.setCurrentTab}
              />
            ) : (
              <Content {...props} deliverableId={deliverableId} pageId={pageId} />
            )}
          </>
        ) : (
          <AccessDenied />
        )}
      </div>
      <div key="deliverableTaxonomyt">
        {selectedIndex === 3 && props?.interfaceAccess ? (
          <Tagging {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
      <div key="deliverableSettings">
        {selectedIndex === 4 && props?.interfaceAccess ? (
          <Settings {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
    </>
  );
};

export default RenderUtility;
