/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Brief Popup
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import classes from "./briefPopup.module.css";

export default function BriefPopUp({ open, setOpen, content, customHeader, headerTitle, containerStyle }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className={classes.briefPopup_root_class} onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter={classes.briefPopup_enter_class}
          enterFrom={classes.briefPopup_leave_active_class}
          enterTo={classes.briefPopup_enter_active_class}
          leave={classes.briefPopup_leave_class}
          leaveFrom={classes.briefPopup_enter_active_class}
          leaveTo={classes.briefPopup_enter_active_class}
        >
          <div className={classes.briefPopup_overlay_class} />
        </Transition.Child>

        <div className={classes.briefPopup_fixed_class}>
          <div className={classes.briefPopup_flex_class}>
            <Transition.Child
              as={Fragment}
              enter={classes.briefPopup_enter_class}
              enterFrom={classes.briefPopup_transformation_class}
              enterTo={classes.briefPopup_active_transformation_class}
              leave={classes.briefPopup_leave_class}
              leaveFrom={classes.briefPopup_active_transformation_class}
              leaveTo={classes.briefPopup_transformation_class}
            >
              <Dialog.Panel className={classes.briefPopup_panel_class}>
                <div className={classes.briefPopup_container_class} style={containerStyle}>
                  {!customHeader ? (
                    <div className={classes.briefPopup_header_class}>
                      <p className={classes.briefPopup_title_class}>{headerTitle || ""}</p>
                      <button className={classes.briefPopup_close_button_class} onClick={() => setOpen(false)}>
                        Close
                      </button>
                    </div>
                  ) : (
                    <>{customHeader}</>
                  )}

                  {content}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
