/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Tabs Component Component
----------------------------------------------------------------
	Creation Details
	Date Created				: 24/Aug/2021
	Author						: Pankaj Kulshreshtha
================================================================
*/

import "./tabs.scss";
import React, { useEffect, useState } from "react";
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import PropTypes from "prop-types";

const TabsUI = (props) => {
  const [extraCss, setExtraCss] = useState("");
  const [persistance, setPersistance] = useState(true);
  const {
    tabsName = [],
    children = <></>,
    tabRef = null,
    created = () => {
      return false;
    },
    handleClick = () => {
      return false;
    },
    childTabCss = "",
    cssClass = "",
    tabHeaderCss = { background: "#FFF", display: props.display && "none" },
    selecting,
  } = props;
  useEffect(() => {
    if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      setPersistance(false);
    }
    if (cssClass === "childTabCss") setExtraCss(" childTabCss");
    else if (cssClass === "newTabCss") setExtraCss(" newTabCss");
    else if (cssClass === "newHeaderTabCss") setExtraCss(" newHeaderTabCss");
  }, [cssClass]);
  return (
    <>
      {tabsName && tabsName.length > 0 && (
        <TabComponent
          ref={tabRef}
          id="defaultTab"
          onClick={handleClick}
          // enablePersistence={persistance}
          selecting={(e) => {
            selecting && selecting();
            if (e.isSwiped) {
              e.cancel = true;
            }
          }}
          created={created}
        >
          <div className={`${cssClass ? cssClass : " customCss"} e-tab-header pl-2 pr-2`} style={tabHeaderCss}>
            {tabsName?.map((n, i) => {
              return <div key={`tab-${i}`}> {n} </div>;
            })}
          </div>
          {/* <div className={`${extraCss} e-content pt-3 pl-2`}>
				{children}
			</div> */}

          <div className={`${cssClass} e-content pl-2 pr-2`}>{children}</div>
        </TabComponent>
      )}
    </>
  );
};

export default TabsUI;

TabsUI.propTypes = {
  tabsName: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  tabRef: PropTypes.object.isRequired,
  created: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};
