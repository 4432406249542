/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Basic Page 
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 27/Jul/2020 
	Author						: Anand Kumar
================================================================ 
*/
import React from "react";
import Address from "./address";
import Fundings from "./fundings";
import Revenue from "./revenue";
import Investments from "./investments";
import Overview from "../overview/index";
import ImagesUI from "./images";
import VideosUI from "./videos";
import DocumentUI from "./overviewDocument";
import DataProcessingActions from "../../../dataProcessing/company/dataProcessingActions";
import DataQcActions from "../../../dataQc/company/dataQcActions";
import ActionButtons from "./actionButtons";
const BasicUI = (props) => {
  return (
    <>
      <div className="gennx-content-wrapper padding-bottom-100i pt-3" style={{ width: "99.5%", overflow: "hidden !important" }}>
        <div className="mt-0 mx-2">
          <Overview {...props} basicFetched={props.basicFetched} setBasicFetched={props.setBasicFetched} />
          {props.companyId != "" ? (
            <>
              <Address {...props} />
              <Fundings {...props} />
              <Revenue {...props} />
              <Investments {...props} />
              <ImagesUI {...props} />
              <VideosUI {...props} />
              <DocumentUI {...props} />
            </>
          ) : null}
        </div>
      </div>

      {props.path == "/processing/company/:companyId/edit" && (
        <DataProcessingActions
          tab="basic"
          companyId={props.companyId}
          accessRights={props.accessRights}
          selectedIndex={props.selectedIndex}
          setCurrentTab={(selectedIndex) => {
            props.setCurrentTab(selectedIndex);
          }}
        />
      )}
      {props.path == "/data-qc/company/:companyId/edit" && (
        <DataQcActions
          companyId={props.companyId}
          accessRights={props.accessRights}
          selectedIndex={props.selectedIndex}
          setCurrentTab={(selectedIndex) => {
            props.setCurrentTab(selectedIndex);
          }}
        />
      )}
      {props.path == "/company/:companyId/edit" && (
        <div className={props.companyId != "" ? "form-submit-box" : "form-submit-box d-none"}>
          <ActionButtons
            accessRights={props.accessRights}
            companyId={props.companyId}
            selectedIndex={props.selectedIndex}
            setCurrentTab={(selectedIndex) => {
              props.setCurrentTab(selectedIndex);
            }}
            hashArr={props.hashArr}
          />
        </div>
      )}
    </>
  );
};

export default BasicUI;
