/*
================================================================
	Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : KIA Routes 
----------------------------------------------------------------
	Creation Details
	Date Created				: 04/May/2021
	Author						: Sandeep K. Sharma
================================================================
*/
import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import contentBlockListing from "../app/views/contentBlock";
import contentBlockAdd from "../app/views/contentBlock/add";

const CONTENT_BLOCK = ["CONTENT BLOCK:FULL"];
const PROPERTIES = ["Properties:FULL"];
const DESIGN = ["Design:FULL"];
const SETTINGS = ["Settings:FULL"];

const CREATOR = ["Content Creator:FULL"];
const REVIWER = ["Content Reviewer:FULL"];
const PUBLISHER = ["Content Publisher:FULL"];

const contentBlockRoutes = () => (
  <Suspense
    fallback={
      <div className="text-center">
        <h3>Loading...</h3>
      </div>
    }
  >
    <Switch>
      <AuthLayout
        exact
        path="/content-block"
        component={contentBlockListing}
        pageHeading="GenNx CMS - Content Blocks Library"
        accessRights={{
          moduleName: "Content Blocks Library",
          parentModuleName: "Content Blocks Library",
          moduleAttribute: [...PROPERTIES, ...DESIGN, ...SETTINGS],
          interfaceName: "Listing",
        }}
      />
      <AuthLayout
        exact
        path="/content-block/add"
        component={contentBlockAdd}
        pageHeading="GenNx CMS - Content Blocks Library"
        accessRights={{
          moduleName: "Content Blocks Library",
          parentModuleName: "Content Blocks Library",
          moduleAttribute: [...PROPERTIES, ...DESIGN, ...SETTINGS],
          interfaceName: "Properties",
        }}
      />
      <AuthLayout
        exact
        path="/content-block/:contentBlockId/edit"
        component={contentBlockAdd}
        pageHeading="GenNx CMS - Content Blocks Library"
        accessRights={{
          moduleName: "Content Blocks Library",
          parentModuleName: "Content Blocks Library",
          moduleAttribute: [...PROPERTIES, ...DESIGN, ...SETTINGS],
          interfaceName: "Properties",
        }}
      />
    </Switch>
  </Suspense>
);

export default contentBlockRoutes;
