// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : Date filter component for site search results page
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :21 Nov 2023
// Author:Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React, { useMemo, useRef, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classes from "./dateFilterSearch.module.css";
import { setDateFilter, setDateFilterValue, setFromDate, setToDate } from "../../../../middleware/actions/searchAction";
import "./index.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";

const dateFilter = ["All", "Today", "Yesterday", "Past 7 days", "Past 15 days", "Past 30 days", "Past 90 days", "Past 180 days", "Custom"];

const CustomDatePicker = ({ defaultValue, dateOnClick, setAnchorEl, setActiveIndex, setDateRange }) => {
  const renderContent = () => {
    return (
      <DateRangePickerComponent
        placeholder="Choose a range"
        openOnFocus
        showClearButton
        format="dd-MMM-yyyy"
        separator="to"
        cssClass="e-outline"
        floatLabelType="Never"
        onChange={(e) => {
          if (e.value) setDateRange(e.value);
          setAnchorEl(null);
          setTimeout(() => {
            setActiveIndex(null);
          }, 10);
        }}
      />
    );
  };

  return renderContent();
};

export default function DateFilter({ anchorEl, setAnchorEl, setActiveIndex }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const containerRef = useRef(null);

  const filters = useSelector((state) => state["searchState"]["allFilters"]);

  const selectedDateFilter = useSelector((state) => state["searchState"]["dateFilterValue"]);

  const [dateRange, setDateRange] = useState(null);

  const coords = useMemo(() => {
    return anchorEl?.getBoundingClientRect();
  }, [anchorEl]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        const daterangepickerNode = document.getElementsByClassName("e-daterangepicker");
        const elements = Array.from(daterangepickerNode || []);
        const isVisible = elements?.find((el) => el.style.display === "block");
        if (ref.current && !ref.current.contains(event.target) && !elements) {
          setAnchorEl(null);
          setTimeout(() => {
            setActiveIndex(null);
          }, 10);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(containerRef);

  useEffect(() => {
    if (dateRange?.length) {
      let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
      let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

      if (startDate) dispatch(setFromDate(startDate));
      if (endDate) dispatch(setToDate(endDate));
    }
  }, [dateRange]);

  // set dates to filter data
  const setDateFilters = (value) => {
    if (value !== "Custom") {
      let obj = { filterValue: value, title: value };
      dispatch(setDateFilter(obj));
    } else {
      dispatch(setDateFilterValue(value));
    }
  };

  // add css class when click on custom date input
  const dateOnClick = () => {
    let path = location.pathname.split("/")[1];
    const dateRangeElement = document.querySelectorAll(".daterangepicker");
    let className;
    if (location.pathname !== "/ask-your-analyst") {
      if (location.pathname === "/") {
        if (dateRangeElement && filters.length === 0) {
          className = "homeDate";
        } else {
          className = "homeDateWithFilter";
        }
      } else if (["company-profile", "news-and-commentary", "key-reports"].includes(path)) {
        if (dateRangeElement) {
          className = "homeDateWithFilter";
        }
      }
    }
    dateRangeElement.forEach((element) => {
      element.classList.add("homeDateWithFilter");
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.1 },
        transform: `translateX(${coords?.right - coords.left - 134}px) translateY(${coords?.y - coords.top + 35}px)`,
      }}
      exit={{ opacity: 0 }}
      style={{
        position: "absolute",
        zIndex: 1,
        left: coords.left,
        top: coords?.top,
        transform: `translateX(${coords?.right - coords.left - 134}px) translateY(${coords?.y - coords.top + 35}px)`,
        width: "230px",
        height: "100%",
        backgroundColor: "white",
        boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.25)",
        transition: "all 0.3s ease",
      }}
      className="adjustZoomContainer"
      ref={containerRef}
    >
      <div key="inline-radio" className={`${classes.dateFilter}`} style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            alignItems: "center",
            padding: "15px 20px",
          }}
          className="popup-header-modal"
        >
          <div>Time Presets</div>
          <MdClose
            onClick={() => {
              setAnchorEl(null);
              setTimeout(() => {
                setActiveIndex(null);
              }, 10);
            }}
            style={{ cursor: "pointer" }}
            fontSize="20px"
          />
        </div>
        <div
          style={{
            // maxHeight: `calc(100vh - ${coords.top}px)`,
            // height: '100%',
            overflowY: "auto",
            padding: "15px 20px",
          }}
          className="adjustZoom"
        >
          {dateFilter.map((type, i) => {
            return (
              <Form.Check
                className="custom_radio"
                label={type}
                value={type}
                checked={type === selectedDateFilter}
                name={"date" + i}
                type="radio"
                key={`inline-radio-${i}`}
                id={`inline-radio-${i}`}
                onChange={(e) => {
                  console.log("e.currentTarget.value >>>", e.currentTarget.value);
                  setDateFilters(e.currentTarget.value);
                  if (e.currentTarget.value !== "Custom") {
                    setAnchorEl(null);
                    setTimeout(() => {
                      setActiveIndex(null);
                    }, 10);
                  }
                }}
              />
            );
          })}

          {selectedDateFilter === "Custom" && (
            <CustomDatePicker
              defaultValue={"Choose a range"}
              dateOnClick={dateOnClick}
              setAnchorEl={setAnchorEl}
              setActiveIndex={setActiveIndex}
              setDateRange={setDateRange}
            />
          )}
        </div>
      </div>
    </motion.div>
  );
}
