/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Technology List Saga (Redux Middleware)
----------------------------------------------------------------
	Creation Details
	Date Created				: 29/Nov/2023
	Author						  : Lawrence Anthony
================================================================
*/

import { all, takeLatest, call, put } from 'redux-saga/effects';
import { getTechnologyList } from "../../middleware/actions/searchAction";
import http from "../services/http-common";
import { CMS_API_URL } from "../../config";

function* fetchTechnologyListSaga() {
    try {
      const response = yield call(
        http.get,
        `${CMS_API_URL}/taxonomy/trend?fields=["id", "trendName", "trendParentId", "trendParentName", "trendSubRoot"]&limit=10000`
      );
  
      if (response.status !== 200) {
        throw new Error('Something went wrong!');
      }
  
      yield put(getTechnologyList(response.data.data));
    } catch (error) {
      console.error('Error fetching technology list:', error);
      // Handle errors here if needed
    }
  }

const technologySaga = [takeLatest('FETCH_TECHNOLOGY_LIST', fetchTechnologyListSaga)];

export default technologySaga;
