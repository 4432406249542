/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  News Technology Tagging Component 
	 Summary : - Import Technology tree view from common component
	 - Save selected technology data
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 20/Aug/2020 
	Author						: Aditya Tijare
================================================================ 
*/
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TreeView from "../../../../../components/treeView/media";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import {
  saveNewsTaxonomyMedia,
  deleteNewsTaxonomyMedia,
  fetchSingleNews,
  fetchNewsTaxonomyMedia,
  deleteManyNewsTaxonomyMedia,
} from "../../../../../../middleware/services/newsApi";

const TaxomonyUI = (props) => {
  const dispatch = useDispatch();
  const newsId = props.newsId;
  const handleSave = (selectedTech, checkValues, callback, callback2) => {
    saveTaxonomy(selectedTech, checkValues, callback, callback2);
  };
  /*
	@Description : If saveNews Sate is true then will trigger technology save event.
	Save news state change trigger from footerBar Component
	*/
  useEffect(() => {
    if (props.saveNews) {
      props.handleSave(false);
    }
  }, [props.saveNews]);

  /*
	@param selectedTech : Selected technology to save their respective taxonomy
	@param taxonomyIds : Selected taxonomy
	@param callback : use for re-render tree component
	
	@description : Save taxonomy for respective technology
				1.Delete all taxonomy for selected technology
				2.Save all taxonomy for selected technology
	*/
  const saveTaxonomy = (selectedTech, taxonomyIds, callback, callback2) => {
    if (selectedTech) {
      dispatch(showLoader());
      deleteNewsTaxonomyMedia(newsId, selectedTech)
        .then(() => {
          dispatch(hideLoader());
          if (taxonomyIds.length > 0) {
            dispatch(showLoader());
            try {
              saveNewsTaxonomyMedia(newsId, { taxonomyIds: taxonomyIds })
                .then((response) => {
                  dispatch(hideLoader());
                  dispatch(actionSuccess("Taxonomy Updated Successfully"));
                  callback();
                })
                .catch((e) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...!"));
                });
            } catch (e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            }
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    }
  };
  /*
	@param selectedTech : Selected technology to save their respective taxonomy
	@param checkValues : Selected value chain
	@param callback : use for re-render tree component
	
	@description : Save value chain for respective industry
	*/

  const deleteMedia = (checkedTrends, callback, callback2, selectedTech) => {
    deleteManyNewsTaxonomyMedia(newsId, checkedTrends)
      .then((res) => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  return (
    <TreeView
      handleSave={handleSave}
      saveTreeData={props.saveNews}
      contentId={newsId}
      fetchContentTagging={fetchNewsTaxonomyMedia}
      fetchContentData={fetchSingleNews}
      loadOtherTagInfo={props.loadOtherTagInfo}
      contentType="news"
      setSaveTreeData={props.setSaveNews}
      deleteMedia={deleteMedia}
      isDelete={true}
      accessPermissionTaxonomyDelete={props?.accessPermissionTaxonomyDelete}
      accessPermissionTaxonomy={props?.accessPermissionTaxonomy}
    />
  );
};

export default TaxomonyUI;
