/**
 * @author: Anand Kumar
 * @date : 06-Aug-2020
 * File Description: Product tech specification Listing,add,edit,delete UI and API wiring up
 */

import React, { useState, useEffect } from "react";
import Scrollbar from "smooth-scrollbar";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader, actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import {
  deleteProductSPecification,
  fetchProductSpecification,
  saveProductSpecification,
  updateProductSpecification,
} from "../../../../../middleware/services/productApi";
import TechSpecificationModal from "./techSpecificationModal";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

// product component
const TechSpecification = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const productId = props.productId;
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [specificationData, setSpecificationData] = useState([]);

  /* Common access */
  const accessPermissionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessPermissionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessPermissionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessPermissionSubmit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SUBMIT);

  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const handleFetchListing = async (params) => {
    const response = await fetchProductSpecification(productId, params).catch((err) => {
      hideLoader();
      dispatch(actionError(err.data.message || "Something went wrong"));
    });
    hideLoaderGrid();
    if (response) {
      setSpecificationData(response.data?.data || []);
    }
  };

  useState(() => {
    showLoaderGrid();
    if (productId) {
      handleFetchListing();
    }
  }, [productId]);

  const saveSpecification = (data) => {
    const specificationValue =
      data.specificationValueType == "NUMERIC" ? { min: data.singleValue || data.minValue || 0, max: data.maxValue } : { single: data.singleValue };
    const payload = {
      specificationId: data.specificationId,
      specificationName: data.specificationName,
      specificationUnit: data.specificationUnit,
      specificationValue: specificationValue,
    };

    saveProductSpecification(productId, payload)
      .then((response) => {
        dispatch(actionSuccess("Technical specification saved successfully"));
        setShowModal(!showModal);
        handleFetchListing();
      })
      .catch((err) => {
        console.log(err);
        dispatch(actionError(err.data.message || "Something went wrong"));
      });
  };
  const updateSpecification = (data) => {
    const specificationValue = data.maxValue
      ? { min: (data.singleValue && data.singleValue.toString()) || data.minValue.toString() || 0, max: data.maxValue.toString() }
      : { single: data.singleValue.toString() };
    const id = data.id;
    const payload = {
      specificationId: data.specificationId,
      specificationName: data.specificationName,
      specificationUnit: data.specificationUnit,
      specificationValue,
    };
    updateProductSpecification(productId, id, payload)
      .then((response) => {
        dispatch(actionSuccess("Technical specification updated successfully"));
        setShowModal(!showModal);
        handleFetchListing();
      })
      .catch((err) => {
        dispatch(actionError(err.data.message || "Something went wrong"));
      });
  };

  const handleDeleteSpecification = async (id) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await deleteProductSPecification(productId, id)
            .then(() => {
              dispatch(actionSuccess("Technical specification deleted successfully"));
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Something went wrong"));
            });
          await handleFetchListing();
        },
      })
    );
  };

  useEffect(() => {
    const options = {
      // damping:0.1,
      thumbMinSize: 20,
      alwaysShowTracks: true,
    };
    setTimeout(() => {
      let classExist = document.querySelector(".content-wrapper");

      classExist && Scrollbar.init(document.querySelector(".content-wrapper"), options);
    }, 2000);
  }, []);

  return (
    <div className="gennx-content-wrapper mt-3 " style={{ width: "99.5%" }}>
      <div className="mx-2 ">
        {/* <FormToolbar /> */}
        <div className="content-wrapper">
          {/* <div className="col-md-12 p-2 border bg-secondary text-white rounded-top">Technical Specification</div> */}
          <div className="col-md-12 border rounded-bottom nopadding p-0 bg-white">
            {/* <GridTopToolBar add={{ route: "#specification", toolTip: "Add New Product" }} /> */}
            {accessPermissionAdd ? (
              <div className="mata-content">
                <div className="container-fluid">
                  <ul>
                    <li
                      className=" btn btn-dark cy-btn cursor-pointer "
                      onClick={() => {
                        setEdit(false);
                        setShowModal(!showModal);
                      }}
                    >
                      <span className="text-white pt-1">Add New</span>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
            <div className="px-3">
              <div className="table-responsive mt-2 mb-1" style={{ height: window.innerWidth < 1500 ? "55vh" : "60vh" }}>
                <table className="normal-table table table-bordered table-internal-product">
                  <thead>
                    <tr>
                      <th>Parameter Name</th>
                      <th>Unit of Measurement</th>
                      <th>Parameter Value</th>
                      <th className="text-center internal-grid-action">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!specificationData.length && (
                      <tr>
                        <td colSpan="4" className="text-center text-secondary">
                          You have not added any Technical Specification information for this product.
                          <br />
                          Please click on "Add New" button to add.
                        </td>
                      </tr>
                    )}
                    {specificationData &&
                      specificationData?.map((item) => {
                        const { id, specificationName, specificationUnit, specificationValue } = item;

                        return (
                          <tr key={id}>
                            <td>{specificationName}</td>
                            <td>{specificationUnit || "--"}</td>
                            <td>
                              {specificationValue?.max
                                ? specificationValue?.min + ", " + specificationValue?.max
                                : specificationValue?.single || "--"}
                            </td>
                            <td className="text-center">
                              {accessPermissionEdit || accessPermissionDelete ? (
                                <ThreeDotMenu
                                  rowID={id}
                                  Edit={
                                    accessPermissionEdit
                                      ? () => {
                                          setEdit(true);
                                          setShowModal(!showModal);
                                          setEditData(item);
                                        }
                                      : undefined
                                  }
                                  Delete={
                                    accessPermissionDelete
                                      ? () => {
                                          handleDeleteSpecification(id);
                                        }
                                      : undefined
                                  }
                                />
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* -- Footer Button box -*/}
        <div className="form-submit-box">
          <div className="container-fluid nopadding">
            <div className="row">
              <div className="float-LEFT col-md-4 text-left">
                {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                  <span className="pl-4" style={{ marginLeft: "-3.5em" }}>
                    <span className="span-link">
                      <button
                        type="button"
                        className="cy-techSpec btn btn-outline-dark pl-4 pr-4 cy-btn "
                        onClick={() => {
                          (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                            (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1));
                        }}
                      >
                        Previous
                      </button>
                    </span>
                  </span>
                ) : null}

                {props.selectedIndex > -1 ? (
                  <span className="pl-4">
                    <span className="span-link">
                      <button
                        type="button"
                        className="cy-techSpec-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                        onClick={() => {
                          props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                        }}
                      >
                        Next
                      </button>
                    </span>
                  </span>
                ) : null}
              </div>
              {accessPermissionSubmit ? (
                <div className="float-right col-md-8 text-right">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(props.backPath, { companyId: props.companyId });
                    }}
                    className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary cy-btn pl-2 pr-2 mr-1"
                    onClick={() => {
                      dispatch(
                        showAlertBox({
                          okCallback: async () => {
                            props.onSaveProcessingData("tech_specificaion");
                          },
                          okText: "Submit",
                          cancelText: "Cancel",
                          content: "Are you sure you want to submit ?",
                          title: "dialogAlertCss",
                        })
                      );
                    }}
                  >
                    Submit
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* End footer Button box */}
      </div>
      {showModal && (
        <TechSpecificationModal
          showModal={showModal}
          setShowModal={() => {
            setShowModal(!showModal);
            setEdit(false);
          }}
          productId={productId}
          callBackFunc={(data) => {
            if (data.specificationUnits) {
              let val = data.specificationUnits.find((s) => s.value === data.specificationUnit);
              data.specificationUnit = val && val.label;
              isEdit ? updateSpecification(data) : saveSpecification(data);
            }
          }}
          isEdit={isEdit}
          editData={isEdit ? editData : null}
        />
      )}
    </div>
  );
};

export default TechSpecification;
