import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { getLandingPageRoute } from "../../../utilities";
import { fetchCoreModules } from "../../../middleware/services/cmsApi";
import Loader from "app/components/loaders";
import { useQuery } from "@tanstack/react-query";
import { AutoLoginGenNx } from "../../../middleware/services/authenticationApi";
import AccessDenied from "app/components/accessDenied";

const AutoLogin = () => {
  const history = useHistory();
  const search = window.location.search;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  // ** Get userId from params
  const token = useMemo(() => searchParams.get("token"), [searchParams]);
  const loginUserId = useMemo(() => searchParams.get("loginUserId"), [searchParams]);
  const refreshToken = useMemo(() => searchParams.get("refreshToken"), [searchParams]);
  const clientName = useMemo(() => searchParams.get("clientName"), [searchParams]);

  // ** Get users
  const { isFetching: isAutoLoginfetching } = useQuery({
    queryKey: ["userDetails", loginUserId, token],
    queryFn: () =>
      AutoLoginGenNx({
        userId: loginUserId,
        appId: import.meta.env.VITE_ENTERPRISE_APP_ID,
        clientName: import.meta.env.VITE_ENTERPRISE_CLIENT_NAME,
        autoLoginToken: token,
      }),
    enabled: loginUserId ? true : false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    suspense: false,
    onSuccess: (response) => {
      localStorage.setItem("userCompetencyIndustry", "null");
      if (response.data?.data) {
        if (response.data.data?.userCompetencyIndustry) {
          localStorage.setItem("userCompetencyIndustry", JSON.stringify(response.data.data?.userCompetencyIndustry));
        }
      }

      if (
        response.data.data &&
        typeof response.data.data.token !== "undefined" &&
        typeof response.data.data.sideBarMenuData !== "undefined" &&
        typeof response?.data?.data?.userAuthorization !== "undefined"
      ) {
        localStorage.setItem("token", response?.data?.data?.token);
        localStorage.setItem("refresh-token", refreshToken || "");
        localStorage.setItem("userEmail", response?.data?.data?.userEmail);
        localStorage.setItem("userFname", response?.data?.data?.userFname);
        localStorage.setItem("userLname", response?.data?.data?.userLname);
        localStorage.setItem("genxUserId", response?.data?.data?.genxUserId);
        localStorage.setItem("enterprise", response?.data?.data?.token);
        localStorage.setItem("enterpriseUserId", response?.data?.data?.userId);
        localStorage.setItem("userType", response?.data?.data?.userType || "");
        localStorage.setItem("genxUserProductVariant", JSON.stringify(response?.data?.data?.genxUserProductVariant));
        let accessData = {
          userAccess: response?.data?.data?.userAuthorization || [],
          dynamicMenu: response?.data?.data?.sideBarMenuData || [],
        };
        if (
          response?.data?.data?.userAuthorization &&
          typeof response?.data?.data?.userAuthorization !== "undefined" &&
          response?.data?.data?.sideBarMenuData &&
          typeof response?.data?.data?.sideBarMenuData !== "undefined"
        ) {
          const accessToken = window.btoa(JSON.stringify(accessData));
          localStorage.setItem("access-token", accessToken);
        }
        window.location.assign("/selector");
        const coreModules = fetchCoreModules({
          filters: [["moduleNavOrder", "ex", ""]],
          sort: "moduleNavOrder:asc",
          limit: 1000,
        });
        window.location.assign(getLandingPageRoute(accessData, coreModules.data.data) || "/company");
      }
    },
  });

  // ** Update access token
  // useEffect(() => {
  //   if (!loginUserId || !token) return history.push("/login");
  // }, [loginUserId, token]);

  if (isAutoLoginfetching)
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", paddingTop: "20%" }}>
        <Loader />
      </div>
    );

  return (
    <div
      className="gennx-content-wrapper content-wrapper px-3"
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}
    >
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "90vh" }}>
        <h4>User don't have permission to access this resource.</h4>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80px" }}>
          <AccessDenied />
        </div>
      </div>
    </div>
  );
};

export default AutoLogin;
