/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Vendor Listing
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionStart, actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { swap, getProductVariant } from "../../../../utilities";
import CommonDataGrid from "../../../components/dataGrid";
import { StringFilter, NumberFilter, BooleanFilter } from "../../../components/dataGrid/fliters";
import { fetchVendor, deleteVendor, deleteManyVendor } from "../../../../middleware/services/cmsApi";
import ThreeDotMenu from "../../../components/threeDotMenu";
import { setMaintainedState, visitRecord } from "../../../../middleware/actions/gridAction";
import { resetRedux } from "../../../../middleware/actions/taxonomyFilterAction";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

// specify column names for column fields in datGrid here
const columnNames = {
  id: "id",
  Name: "vendorName",
  Email: "vendorEmail",
  Website: "vendorWebsite",
  "Contact Person": "vendorContactPersonFname",
  "Contact Phone": "vendorContactPersonPhone",
  Status: "vendorActive",
};

/**
 * @description formatRowData function required to format rowData for datGrid
 * @param {Array} rowData
 */
const formatRowData = (rowData) => {
  let formatedRowData = [];
  const columnNamesValues = swap(columnNames);
  formatedRowData = rowData.map((rD) => {
    const newRD = { Id: rD.id };
    Object.keys(rD).forEach((rDK) => {
      if (columnNamesValues[rDK]) {
        newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
      }
    });
    newRD["Contact Person"] = (rD["vendorContactPersonFname"] || "") + " " + (rD["vendorContactPersonLname"] || "");
    return newRD;
  });
  return formatedRowData;
};

// vendor dashboard component
const VendorDashBoard = (props) => {
  const [deleteId, setDeleteId] = useState(undefined);
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const gridState = useSelector((state) => state.gridState);
  // specify breadcrumb here
  const breadCrumbLinks = [{ linkUrl: "/vendor", linkName: aR.moduleName, linkActive: true }];

  /* Common access */
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionInactive = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.INACTIVE);
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);

  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const deleteSingleVendor = (data) => {
    dispatch(actionStart());
    deleteVendor(data.Id)
      .then((res) => {
        setDeleteId(data.Id);
        dispatch(actionSuccess("Vendor Deleted"));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Delete Vendor Failed";
        dispatch(actionError(errMsg));
      });
  };
  const vendorNameTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(`/vendor/${value.id}/edit#profile`);
          dispatch(visitRecord());
        }}
      >
        {value.Name}
      </span>
    );
  };

  /* 
		@Description : Template Component for action column
	*/
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                history.push(`/vendor/${value.id}/edit#profile`);
                dispatch(visitRecord());
              },
            }
          : {})}
        {...(value.Status && accessActionInactive
          ? {
              Inactive: () => {
                dispatch(
                  showAlertBox({
                    okCallback: deleteSingleVendor,
                    data: value,
                    content: "Are you sure, you want to mark user as Inactive?",
                    okText: "Inactive",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
      />
    );
  };

  const vendorActiveTemplate = (value) => {
    return <>{value.Status ? <span>Active</span> : <span>Inactive</span>}</>;
  };
  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean
  const columnFields = [
    {
      field: "Name",
      type: "String",
      template: vendorNameTemplate,
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 200,
    },
    { field: "Email", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 200 },
    {
      field: "Website",
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 200,
      // , template: websiteTemplate
    },
    { field: "Contact Person", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 200 },
    { field: "Contact Phone", type: "String", filterTemplate: NumberFilter, textAlign: "Left", headerTextAlign: "Left", width: 200 },
    {
      field: "Status",
      type: "string",
      template: vendorActiveTemplate,
      filterTemplate: BooleanFilter,
      width: 150,
      textAlign: "Center",
      headerTextAlign: "Center",
    },
  ];
  if (accessActionEdit || accessActionInactive) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false, textAlign: "Center" });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  const fetchVendorWithProductVariant = (params = {}) => {
    let defaultFilter = [];
    const productVariant = getProductVariant();
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchVendor(params);
    }
    return fetchVendor(params);
  };
  const deleteManyFunc = (flag) => {
    const selectedVendors = localStorage.getItem("selectedVendors") ? JSON.parse(localStorage.getItem("selectedVendors")) : [];
    if (flag) {
      if (selectedVendors.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteManyVendor({ vendorIds: selectedVendors })
                .then((response) => {
                  if (response) {
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    fetchVendorWithProductVariant({}).then((v) => {
                      formatRowData(v.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete vendor"));
                });
            },
            content: "Are you sure to delete, selected record?",
            okText: "Delete",
            cancelText: "Cancel",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => dispatch(setBreadCrumb());
  }, [dispatch]);

  // Reset Redux for filter by taxonomy
  useEffect(() => {
    if (window.location.pathname == `/vendor`) {
      dispatch(resetRedux());
    }
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        <CommonDataGrid
          gridTitle="Vendor Management List"
          fetch={fetchVendorWithProductVariant}
          deleteOne={deleteVendor}
          deleteMany={accessActionDelete ? deleteManyFunc : null}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          addNew={() => history.push(`/vendor/add#profile`)}
          addNewRight={accessActionListingAddNew}
          getMultiSelectedRows={(data) => {
            localStorage.setItem("selectedVendors", JSON.stringify([...data]));
          }}
          getMultiDeSelectedRows={(data) => {
            localStorage.setItem("selectedVendors", JSON.stringify([...data]));
          }}
          clearFilterByTaxonomy={true}
        />
      </div>
    </div>
  );
};

export default VendorDashBoard;
