/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News Overview Validation - Add | Edit Overview
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import * as Yup from "yup";
import * as REGEX from "../../../../../config/regexValidation";
export const newsOverviewValidationSchema = {
  newsTitle: Yup.string()
    .min(3, "News title should be minimum 3 characters")
    .max(125, "News title can not be more than 125 characters")
    .required("Please enter the news title"),
  newsSourceURL: Yup.string().matches(
    REGEX.URL,
    "Please enter valid news source url"
  ),
  // contentData : Yup.string().required("News Content Could Not be Empty"),
  newsPublishedDate: Yup.string()
    .required("Please select the publish date")
    .nullable(),
};
// Error meaasge for file size
export const FileSize = "Total files size should not exceed 90 MB";
// Error meaasge for supported files
export const supportedFiles =
  " Only pdf, doc/docx, xls/xlsx, ppt/pptx files are allowed  to upload.";
// Error meaasge for maximum files
export const maxFiles = "You can upload maximum 10 files only.";
