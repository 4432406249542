// ‘Copyright ©2023, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : No data available component
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :4 Oct 2023
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from "react";

const EmptyData = ({ msg, minHeight }) => {
  const spinnerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    // height: "100%",
    minHeight: minHeight ? minHeight : '250px',
  };

  return <div style={spinnerStyle}>{msg ? msg : 'No data available'}</div>;
};

export default EmptyData;
