import * as Yup from "yup";
import * as REGEX from "../../../config/regexValidation"
export const vendorValidationSchema = {
    vendorName: Yup.string().min(2).required("Vendor name is required"),
    vendorEmail: Yup.string().typeError("Please enter valid email").email("Please enter valid email"),
    vendorWebsite: Yup.string().typeError("Please enter valid email")
        .matches(REGEX.URL, "Please enter valid website URL"),
    vendorContactPersonFname: Yup.string(),
    vendorContactPersonLname: Yup.string(),
    vendorContactPersonPhone: Yup.number().typeError("Please enter valid mobile number"),
    vendorPassword: Yup.string().min(6).max(18).required("New password is required"),
    vendorConfirmPassword: Yup.string().oneOf([Yup.ref("vendorPassword"), null], "Passwords must match").required("Confirm new password is required"),
};

export const addVendorValidationSchema = {
    ...vendorValidationSchema
};

export const editVendorValidationSchema = {
    ...vendorValidationSchema,
    vendorName: Yup.string().min(2).required("Vendor name is required").notOneOf([""]),
    vendorPassword: Yup.string().min(6).max(18),
    vendorConfirmPassword: Yup.string().oneOf([Yup.ref("vendorPassword"), null], "Passwords must match").when("vendorPassword", {
        is: (val) => val !== undefined,
        then: Yup.string().required("Confirm new password is required"),
    })
};
