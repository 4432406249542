/** External Dependencies */
import React, { useCallback, useMemo, useEffect } from "react";

/** Internal Dependencies */
import { SELECT_TAB, SET_ORIGINAL_IMAGE, SET_RESIZE, HIDE_LOADER, SHOW_LOADER, SET_CROP } from "../../actions";
import { useStore } from "../../hooks";
import TabItem from "./TabItem";
import { AVAILABLE_TABS } from "./Tabs.constants";
import { StyledTabs } from "./Tabs.styled";
import loadImage from "../../utils/loadImage";

const Tabs = () => {
  const {
    t,
    dispatch,
    tabId = null,
    designLayer,
    config: { tabsIds, defaultTabId, useCloudimage, getCurrentImgDataFnRef, onResize },
  } = useStore();

  const currentTabId = tabId || defaultTabId;

  const setNewOriginalImage = useCallback((newOriginalImage) => {
    dispatch({
      type: SET_ORIGINAL_IMAGE,
      payload: {
        originalImage: newOriginalImage,
      },
    });
  }, []);

  async function loadImageAndProcess(imageData, designState, newTabId) {
    return new Promise(async (resolve) => {
      dispatch({ type: SHOW_LOADER });
      const image = new Image();
      image.src = imageData.imageBase64;
      image.onload = async () => {
        // Now that the image has loaded, you can proceed with other operations
        const canvas = document.createElement("canvas");
        canvas.width = designState.resize.width;
        canvas.height = designState.resize.height;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        const blob = await new Promise((resolveBlob) => canvas.toBlob(resolveBlob, "image/png"));
        const url = URL.createObjectURL(blob);

        if (typeof url === "string") {
          try {
            const newImage = await loadImage(url, imageData.fullName);
            let img = newImage;
            canvas.remove();
            newImage.remove();
            onResize(img);
          } catch (error) {
            dispatch({ type: HIDE_LOADER });
          } finally {
            dispatch({ type: HIDE_LOADER });
            resolve(); // Resolve the outer promise to signal completion
          }
        }
      };
      image.remove();
      image.onerror = () => {
        dispatch({ type: HIDE_LOADER });
        resolve(); // Resolve the outer promise even in case of an error
      };
    });
  }

  const chosenTabs = useMemo(() => {
    let tabs = [];
    if (Object.keys(tabsIds).length > 0) {
      AVAILABLE_TABS.forEach((tab) => {
        const index = tabsIds.indexOf(tab.id);
        if (index !== -1) {
          tabs[index] = tab;
        }
      });
    } else {
      tabs = AVAILABLE_TABS;
    }

    return (tabs.length > 0 ? tabs : AVAILABLE_TABS).filter(({ hideFn }) => !hideFn || !hideFn({ useCloudimage }));
  }, [tabsIds]);

  const selectTab = async (newTabId) => {
    dispatch({ type: SHOW_LOADER });
    dispatch({
      type: SELECT_TAB,
      payload: {
        tabId: newTabId,
      },
    });
    if (designLayer !== undefined && newTabId == "Adjust"){
      const { imageData, designState } = getCurrentImgDataFnRef.current({}, false, false);
      let resizeValue = !designState.resize?.width || !designState.resize?.height;
      if (!resizeValue) {
        await loadImageAndProcess(imageData, designState, newTabId);
      }
    }
    dispatch({ type: HIDE_LOADER });
  };

  // If only 1 tab is needed then no need to have the tabs sidebar.
  if (chosenTabs.length === 1) {
    return null;
  }

  return (
    <StyledTabs className="FIE_tabs">
      {chosenTabs.map(({ id, labelKey, icon }) => (
        <TabItem key={id} id={id} label={t(labelKey)} Icon={icon} isSelected={currentTabId === id} onClick={selectTab} />
      ))}
    </StyledTabs>
  );
};

export default Tabs;
