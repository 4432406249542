/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Filter Dropdown Content Type
   Summary : Filter Content Type Dropdown
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 13/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React from "react";
import classes from "./filterContentTypeDropdown.module.css";
import { Form } from "react-bootstrap";
import { CONTENT_TYPE } from "../../../constants";

const FilterContentTypeDropdown = (props) => {
  return (
    <div className={classes.sort_content}>
      <Form className={classes.input_group}>
        <Form.Group className={classes.formGroup}>
          <Form.Control
            className={classes.input_control}
            as="select"
            onChange={props.changeFilter}
            value={props.value}
            style={{ cursor: "pointer" }}
            disabled={props.isDisabled}
          >
            <option value="All">All</option>
            <option value={CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH}>{CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH}</option>
            <option value={CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS}>{CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS}</option>
          </Form.Control>
        </Form.Group>
      </Form>
    </div>
  );
};

export default FilterContentTypeDropdown;
