/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	File Description : Common Header Component
----------------------------------------------------------------
	Creation Details
	Date Created				: 25/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import "./index.scss";
import React, { memo, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { userSignout } from "../../../middleware/actions/authAction";
import { getLoggedInUser, getProductVariant, accessRightCheck, isAdmin } from "../../../utilities";
import { generateSearchQuery } from "../../../middleware/services/searchServiceApi";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { fetchTrendsList, fetchIndustriesList } from "../../../middleware/actions/searchAction";
import TopSearchBox from "../topSearchBox/topSearchBox";
import { actionError } from "../../../middleware/actions/utilityAction";

const Header = (props) => {
  const { pageHeading } = props;

  const loggedInUser = getLoggedInUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const userType = localStorage.getItem("userType");

  const [isError, setIsError] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const input = inputValue || [];

  useEffect(() => {
    try {
      dispatch(fetchIndustriesList());
      dispatch(fetchTrendsList());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const searchHandler = () => {
    if (input.length === 0 || /^\s/.test(inputValue)) {
      setIsError(true);
    } else {
      const keywords = inputValue?.split(" | ");
      const buildQuery = generateSearchQuery(keywords);
      if (buildQuery.length > 1000) {
        setIsError(true);
        dispatch(actionError(`Search query is too long. Please reduce the number of keywords to continue the search.`));
      } else {
        setIsError(false);
        dispatch(setBreadCrumb());
        history.push(`/search-result?query=${encodeURIComponent(inputValue)}`);
        if (props.setSelectedFilter) {
          props.setSelectedFilter();
        }
      }
    }
  };

  // set search input value
  const onTextChange = (keywords) => {
    setInputValue(keywords);

    if (keywords.length !== 0) {
      setIsError(false);
    }
  };

  // on "Enter" button click search
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (input.length === 0 || /^\s/.test(inputValue)) {
        setIsError(true);
      } else {
        setIsError(false);
        history.push(`/search-result?query=${encodeURIComponent(inputValue)}`);
        if (props.setSelectedFilter) {
          props.setSelectedFilter();
        }
      }
    }
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{ padding: getProductVariant() == "Insider" && accessRightCheck("Site Search", "Listing") ? "" : "13px 10px" }}
    >
      <div className="container-fluid">
        {getProductVariant() == "Insider" && accessRightCheck("Site Search", "Listing") ? (
          <>
            <div className="col-md-2 page_title pl-0">
              <h3>{pageHeading}</h3>
            </div>
            <div className="col-md-8 page_title pl-0">
              <TopSearchBox
                placeholder='Enter Keyword e.g. "Mobility Cloud"'
                onTextChange={onTextChange}
                onClick={searchHandler}
                isError={isError}
                setIsError={setIsError}
                handleKeyDown={handleKeyDown}
                setInputValue={setInputValue}
                className="!w-full "
              />
            </div>
          </>
        ) : (
          <div className="col-md-10 page_title pl-0">
            <h3>{pageHeading}</h3>
          </div>
        )}
        <div>
          <h6 className="inset">{getProductVariant()}</h6>
        </div>
        <div className="col-md-1">
          <div className="user_info_section" style={{ marginLeft: "-26px" }}>
            <div className="user_info_detail d-inline-block">
              <div className=" dropdown ">
                <Link
                  to="#"
                  className="btn btn-secondary dropdown-toggle"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-user" style={{ textTransform: "capitalize" }} aria-hidden="true" /> Hello{" "}
                  {loggedInUser?.user_fname?.length > 10 ? loggedInUser?.user_fname.slice(0, 9) + ".." : loggedInUser?.user_fname}
                </Link>
                <div className=" dropdown-menu p-0 " aria-labelledby="dropdownMenuLink">
                  {(isAdmin() || !loggedInUser?.product_variant?.length || loggedInUser?.product_variant?.length > 1) && (
                    <Link
                      to={{
                        pathname: "/selector",
                      }}
                      className="dropdown-item"
                      style={{ padding: ".5rem .75rem" }}
                      title="Product Variant Selection"
                      onClick={() => localStorage.removeItem("productVariant")}
                    >
                      Product Variant
                    </Link>
                  )}

                  {userType === "External" ? (
                    <Link
                      to={`/my-profile/${loggedInUser?._id}/edit#profile`}
                      className="dropdown-item"
                      style={{ padding: ".5rem .75rem" }}
                      title="Product Variant Selection"
                    >
                      My Profile
                    </Link>
                  ) : null}
                  <Link to="#" className="dropdown-item border-top" style={{ padding: ".5rem .75rem" }} onClick={() => dispatch(userSignout({}))}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default memo(Header);

Header.propTypes = {
  pageHeading: PropTypes.string.isRequired,
};
