/**
 * Author Narenkumar Krishnan
 * Date  16-06-2024
 * custom hook to render the widgets dynamically
 */

import React from "react";
import { DASHBOARD_WIDGETS, PINNED_WIDGETS } from "../constants/widget-constants";
import { Suspense, useCallback } from "react";
import "./index.css";
import { GridLoaderComponent } from "app/components/loaders";

export const useRenderComponent = () => {
  return useCallback(
    (componentName, name, props) => {
      let widgetComponentName = null;
      if(props?.widgetData?.widgetConfigName){
        widgetComponentName = props?.widgetData?.widgetConfigName;
      } else if(props?.widgetData?.widgetUniqueName){
        widgetComponentName = props?.widgetData?.widgetUniqueName;
      } else {
        widgetComponentName = componentName;
      }
      // const Component = name === "dashboard" ? DASHBOARD_WIDGETS[componentName] : PINNED_WIDGETS[componentName];
      const Component = name === "dashboard" ? DASHBOARD_WIDGETS[widgetComponentName] : PINNED_WIDGETS[widgetComponentName];
      return (
        <Suspense fallback={<GridLoaderComponent size={15}/>}>
          {!Component ? <GridLoaderComponent size={15}/> : <div className={"widget-render-wrapper"}><Component {...props} /></div>}
        </Suspense>
      );
    },
    [DASHBOARD_WIDGETS, PINNED_WIDGETS]
  );
};
