/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 15/Mar/2021
 * File Description: Survey Form Basic Details
 *
 */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { surveyInvitationValidationSchema } from "../validationSchema/index";
import { fetchSingleSurvey, sendSurveyInvitation } from "../../../../../middleware/services/surveyApi";
import { CkEditor, Checkbox } from "../formFields";
import ActionButtons from "../actionButtons";
import SURVEY_CONSTANT from "../../constants";
import { TextField, MultiSelectNew } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { setInvitation } from "../../../../../middleware/actions/surveyAction";
import AccessDenied from "../../../../components/accessDenied";

const defaultMailContent =
  "Hi {FirstName} {LastName},  <br /><br />" +
  "We want to give our customers the best experience and in this regard, we would like to hear from you what do you think about our products. <br /><br />" +
  "Please fill up this {SurveyLink} and let us know if our team helped you find what you were looking for. <br /><br /> " +
  "Thank you, ";
const initialState = {
  senderMail: "",
  senderName: "",
  mailSubject: "",
  invitationMailBody: "",
  includeMailSignature: "YES",
  email_cc: [],
  email_cc2: [],
  email_bcc: [],
  email_bcc2: [],
  email_reply: "",
};
const Invitation = (props) => {
  const dispatch = useDispatch();
  const surveyId = props.surveyId || "";
  const [surveyData, setSurveyData] = useState(null);
  const surveyCommonState = useSelector((state) => state.surveyState);
  let invitationDetails = Object.keys(surveyCommonState?.invitationDetails).length > 0 ? surveyCommonState.invitationDetails : null;
  const formik = useFormik({
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(surveyInvitationValidationSchema),
  });

  const breadCrumbLinks = [
    { linkUrl: "/survey", linkName: "Survey", linkActive: false },
    {
      linkUrl: surveyId ? `/survey/${surveyId}/basic-details#invitation` : "/survey/basic-details#invitation",
      linkName: surveyData?.surveySerialNo,
      linkActive: false,
    },
    {
      linkUrl: `/survey/${surveyId}/edit${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];
  // save survey data
  const saveSurvey = async () => {
    formik.validateForm().then((res) => {
      if (res["email_cc2"]) {
        res["email_cc2"] = res["email_cc2"].join();
      }
      if (res["email_bcc2"]) {
        res["email_bcc2"] = res["email_bcc2"].join();
      }

      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });

        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        props.handleCommonSave(surveyId);
      }
    });
  };
  // fetch survey data
  const fetchSurvey = () => {
    let params = {};
    let fields = ["surveySerialNo", "surveyShortName", "surveyTitle", "surveyInvitationMailTemplate", "surveyInvitationStatus", "surveyStatus"];
    params.fields = fields;
    fetchSingleSurvey(surveyId, params).then((res) => {
      let data = res?.data?.data || {};
      setSurveyData(data);
      formik.setValues({
        senderMail: invitationDetails?.senderMail ? invitationDetails?.senderMail : data.surveyInvitationMailTemplate?.senderMail || "",
        senderName: invitationDetails?.senderName ? invitationDetails?.senderName : data.surveyInvitationMailTemplate?.senderName || "",
        mailSubject: invitationDetails?.mailSubject ? invitationDetails?.mailSubject : data.surveyInvitationMailTemplate?.mailSubject || "",
        invitationMailBody: invitationDetails?.invitationMailBody
          ? invitationDetails?.invitationMailBody
          : data.surveyInvitationMailTemplate?.mailBody.length > 0
          ? data.surveyInvitationMailTemplate.mailBody
          : defaultMailContent,
        includeMailSignature: invitationDetails?.includeMailSignature
          ? invitationDetails?.includeMailSignature
          : data.surveyInvitationMailTemplate?.includeMailSignature
          ? data.surveyInvitationMailTemplate.includeMailSignature
          : "YES",

        email_cc: invitationDetails?.email_cc2
          ? invitationDetails?.email_cc2.map((email) => {
              return email;
            })
          : data.surveyInvitationMailTemplate?.emailCc
          ? data.surveyInvitationMailTemplate.emailCc?.map((email) => {
              return { label: email, value: email };
            })
          : [],
        email_cc2: invitationDetails?.email_cc2
          ? invitationDetails?.email_cc2.map((email) => {
              return email;
            })
          : data.surveyInvitationMailTemplate?.emailCc
          ? data.surveyInvitationMailTemplate.emailCc?.map((email) => {
              return { label: email, value: email };
            })
          : [],
        email_bcc: invitationDetails?.email_bcc2
          ? invitationDetails?.email_bcc2.map((email) => {
              return email;
            })
          : data.surveyInvitationMailTemplate?.emailBcc
          ? data.surveyInvitationMailTemplate.emailBcc?.map((email) => {
              return { label: email, value: email };
            })
          : [],
        email_bcc2: invitationDetails?.email_bcc2
          ? invitationDetails?.email_bcc2.map((email) => {
              return email;
            })
          : data.surveyInvitationMailTemplate?.emailBcc
          ? data.surveyInvitationMailTemplate.emailBcc?.map((email) => {
              return { label: email, value: email };
            })
          : [],
        email_reply: invitationDetails?.email_reply
          ? invitationDetails?.email_reply
          : data.surveyInvitationMailTemplate?.emailReply
          ? data.surveyInvitationMailTemplate.emailReply
          : "",
      });
    });
  };

  // send survey invitation
  const surveyInvitation = async () => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await sendSurveyInvitation(surveyId, {})
            .then((res) => {
              if (res) {
                dispatch(actionSuccess("Survey invitation sending has been started successfully"));
              } else {
                dispatch(actionError("Unable to process your request. Please contact system administrator (product.support@cheersin.com)"));
              }
            })
            .catch((err) => {
              let errMessage = "Survey invitation can not be started. ";
              const errMessageArr = err?.data?.message
                ? err.data.message.split(",")
                : ["Unable to process your request. Please contact system administrator (product.support@cheersin.com)"];
              errMessage += errMessageArr
                .map((value, index) => {
                  return value;
                })
                .join("");

              dispatch(actionError(errMessage));
            });
        },
        content: "Are you sure, you want to start sending Survey Invitation?",
      })
    );
  };
  //  effect hook
  useEffect(() => {
    if (surveyId) {
      if (!surveyData) fetchSurvey();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/survey"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, surveyData, props.selectedIndex]);

  useEffect(() => {
    dispatch(setInvitation(formik.values));
  }, [formik.values]);

  useEffect(() => {
    props.setTabErrors((prev) => {
      return {
        ...prev,
        invitation: Object.keys(formik?.errors)?.length ? true : false,
      };
    });
  }, [formik.errors]);

  return (
    <>
      {props?.interfaceAccess ? (
        <div>
          <div className="pt-3 mx-2">
            <div className="gennx-content-wrapper padding-bottom-150i" style={{ width: "99.5%", overflow: "hidden" }}>
              <div style={{ background: "#FFFFFF", overflow: "hidden" }}>
                <div id="content-box">
                  <div className="container-fluid mb-3">
                    <div className="p-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4 mt-3">
                              <div className="mt-3">
                                <TextField
                                  formik={formik}
                                  required={true}
                                  id="senderMail"
                                  placeholder="Please enter sender's email *"
                                  name="senderMail"
                                />
                                <small>
                                  <span>
                                    <b>Note :-</b> Sender's email valid domains are <b>futurebridge.com</b>, <b>cognition-solutions.com</b>,{" "}
                                    <b>cheersin.com</b> and <b>whatnextglobal.com</b> eg. xyz@cheersin.com
                                  </span>
                                </small>
                              </div>
                              <div className="mt-3">
                                <TextField
                                  formik={formik}
                                  required={true}
                                  id="senderName"
                                  placeholder="Please enter sender's name *"
                                  name="senderName"
                                />
                              </div>
                              <TextField
                                formik={formik}
                                required={true}
                                id="mailSubject"
                                placeholder="Please enter email subject *"
                                name="mailSubject"
                              />

                              <MultiSelectNew
                                formik={formik}
                                mode="Box"
                                sortOrder="Acending"
                                showSelectAll={false}
                                placeholder="Email cc "
                                id="email_cc"
                                name="email_cc2"
                                allowCustomValue={true}
                                value={
                                  formik.values.email_cc &&
                                  formik.values.email_cc.map((v) => {
                                    return v.label;
                                  })
                                }
                                fields={{ text: "label", value: "value" }}
                                dataSource={formik.values.email_cc}
                                noRecordsTemplate={() => <div>Please Enter Members </div>}
                              />
                              <MultiSelectNew
                                formik={formik}
                                mode="Box"
                                sortOrder="Acending"
                                showSelectAll={false}
                                placeholder="Email bcc  "
                                id="email_bcc"
                                name="email_bcc2"
                                allowCustomValue={true}
                                value={
                                  formik.values.email_bcc &&
                                  formik.values.email_bcc.map((v) => {
                                    return v.label;
                                  })
                                }
                                fields={{ text: "label", value: "value" }}
                                dataSource={formik.values.email_bcc}
                                noRecordsTemplate={() => <div>Please Enter Members </div>}
                              />

                              <TextField formik={formik} id="email_reply" placeholder="Enter Reply to " name="email_reply" />
                            </div>
                            <div className="col-md-8">
                              <CkEditor
                                formik={formik}
                                defaultContent={defaultMailContent}
                                properties={{
                                  id: "invitationMailBody",
                                  activeClass: "invitationMailBody",
                                  name: "invitationMailBody",
                                  label: "Email Content",
                                  config: { height: "400px" },
                                }}
                              />
                              <Checkbox
                                formik={formik}
                                properties={{
                                  type: "checkbox",
                                  readOnly: false,
                                  id: "includeMailSignature",
                                  name: "includeMailSignature",
                                  label: "Include Email Signature",
                                }}
                              />
                              <small>
                                <div className="d-flex">
                                  <span className="font-weight-bold">Note : </span>
                                  <span className="pl-2">
                                    <li style={{ listStyleType: "none" }}>
                                      Mail content must contains these placeholder{" "}
                                      <b>
                                        {"{FirstName}"},{"{LastName}"}
                                      </b>{" "}
                                      & <b>{"{SurveyLink}"}</b>. Please do not remove these.
                                    </li>
                                  </span>
                                </div>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ActionButtons
            accessRights={props.accessRights}
            selectedIndex={props.selectedIndex}
            setCurrentTab={(selectedIndex) => {
              props.setCurrentTab(selectedIndex);
            }}
            surveyId={surveyId ? surveyId : null}
            handleSave={
              surveyData?.surveyInvitationStatus !== SURVEY_CONSTANT.SURVEY_INVITATION_STATUS.SENDING &&
              !["Closed"].includes(surveyData?.surveyStatus)
                ? saveSurvey
                : null
            }
            handleSendInvitation={
              surveyData?.surveyInvitationStatus !== SURVEY_CONSTANT.SURVEY_INVITATION_STATUS.SENDING &&
              !["Closed"].includes(surveyData?.surveyStatus)
                ? surveyInvitation
                : null
            }
            showPublished={surveyId && surveyData?.surveyStatus === SURVEY_CONSTANT.SURVEY_STATUS.DRAFT ? true : false}
            tabsName={props.tabsName}
            moduleName={props.moduleName}
            hashArr={props.hashArr}
          />
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default Invitation;
