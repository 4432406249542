/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News Overview component - Add | Edit Overview
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  saveObjective,
  fetchSingleObjective,
  updateObjective,
  uploadObjectiveImage,
} from "../../../../../../middleware/services/businessObjectiveApi";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import OverviewForm from "./form";
import { getProductVariant, getLoggedInUser } from "../../../../../../utilities";

const NewsOverview = (props) => {
  const objectiveId = props.objectiveId;
  const dispatch = useDispatch();
  const loginUser = getLoggedInUser();
  /*
        @Description : Default value for news overview form
    */
  const [objectiveInformation, setObjectiveInformation] = useState({
    objectiveTitle: "",
    objectiveCategory: "",
    industry: "",
    trend: "",
    technology: "",
    objectiveClientUsers: [],
    objectiveDemoUsers: [],
    objectiveProgramManagers: [],
    btbId: "",
    objectiveType: "",
    customForm: "",
    uniqueNo: "",
    objectiveCompletedDate: "",
    objectiveApplicationPlatform: [],
    objectiveIndustrySgfTaxonomy: [],
    objectiveProfileImage: "",

    // objectiveBtbId: '',
    // objectiveCoverageStartDate: '',
    // objectiveCoverageEndDate: '',
    // objectiveStatus: '',
  });
  const [imageData, setImageData] = useState(new FormData());
  /*
        @Description : Fetch news data on edit
        1. set news data to respective fields
        2. Set image path for sending to child component
    */
  useEffect(() => {
    if (objectiveId !== "") {
      dispatch(showLoader());
      try {
        const response = fetchSingleObjective(objectiveId);
        response
          .then((response) => {
            dispatch(hideLoader());
            if (response.status === 200) {
              let objectiveUser = {
                client: [],
                programManager: [],
                demoUser: [],
              };
              if (response.data.data.objectiveUsers) {
                if (response.data.data.objectiveUsers.length) {
                  response.data.data.objectiveUsers.forEach((user) => {
                    if (user.userType === "client") {
                      objectiveUser["client"].push({
                        value: user.userId,
                        label: user.userFName + " " + user.userLName,
                      });
                    }
                    if (user.userType === "program_managers") {
                      objectiveUser["programManager"].push({
                        value: user.userId,
                        label: user.userFName + " " + user.userLName,
                      });
                    }
                    if (user.userType === "demo") {
                      objectiveUser["demoUser"].push({
                        value: user.userId,
                        label: user.userFName + " " + user.userLName,
                      });
                    }
                  });
                }
              }

              let objectiveData = {
                objectiveTitle: response.data.data.objectiveTitle,
                uniqueNo: response.data.data.uniqueNo,
                objectiveCategory: {
                  label: response.data.data.objectiveCategory,
                  value: response.data.data.objectiveCategory,
                },
                btbId: response.data.data.objectiveBtbId,
                objectiveCompany: response.data.data.objectiveCompany,
                industry: response.data.data?.objectiveIndustry
                  ? {
                      label: response.data.data.objectiveIndustry.industryName,
                      value: response.data.data.objectiveIndustry.industryId,
                    }
                  : "",
                trend: response.data.data?.objectiveIndustryTrend
                  ? {
                      label: response.data.data.objectiveIndustryTrend.trendName,
                      value: response.data.data.objectiveIndustryTrend.trendId,
                    }
                  : "",
                technology: response.data.data?.objectiveTechnology
                  ? {
                      label: response.data.data.objectiveTechnology.dtName,
                      value: response.data.data.objectiveTechnology.dtId,
                    }
                  : "",
                objectiveClientUsers: objectiveUser.client.length ? objectiveUser.client : [],
                objectiveProgramManagers: objectiveUser.programManager.length ? objectiveUser.programManager : [],
                objectiveDemoUsers: objectiveUser.demoUser.length ? objectiveUser.demoUser : [],
                objectiveType: response.data.data?.objectiveType ? { value: response.data.data.objectiveType } : "",
                customForm: response.data.data?.objectiveForm ? { value: response.data.data.objectiveForm?.id } : "",
                objectiveCompletedDate: response.data.data.objectiveCompletedDate,
                objectiveApplicationPlatform:
                  response.data.data?.objectiveApplicationPlatform?.length > 0
                    ? response.data.data.objectiveApplicationPlatform.map((v) => {
                        return { label: v, value: v };
                      })
                    : [],
                objectiveIndustrySgfTaxonomy:
                  response.data.data?.objectiveIndustrySgfTaxonomy?.length > 0 ? response.data.data.objectiveIndustrySgfTaxonomy : [],
                objectiveProfileImage: response.data.data?.objectiveProfileImage?.length > 0 ? response.data.data.objectiveProfileImage : "",
              };
              setObjectiveInformation(objectiveData);
            } else {
              dispatch(actionError("Something Went Wrong...!"));
            }
          })
          .catch((e) => {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
          });
      } catch (e) {
        dispatch(hideLoader());
        dispatch(actionError("Something Went Wrong...!"));
      } finally {
        dispatch(hideLoader());
      }
    }
  }, [objectiveId, dispatch]);

  const handleUploadImage = async (id) => {
    if (!objectiveId) {
      imageData.append("objectiveId", id);
    }
    const responseObj = uploadObjectiveImage(imageData);
    responseObj
      .then((response) => {
        const data = response.data?.data;
        if (data) {
          console.log(data);
        }
      })
      .catch((e) => {
        dispatch(actionError("There some issue in file attachement."));
      });
  };

  /*
       @Description : Save overview details
   */
  const handleSaveOverview = async (saveOverviewData) => {
    dispatch(showLoader());
    try {
      let response = "";
      if (objectiveId === "") {
        const productVariant = getProductVariant();

        if (productVariant) saveOverviewData["productVariant"] = JSON.stringify([{ productName: productVariant, isLive: "NO" }]);
        saveOverviewData["objectiveCreatedBy"] = {
          user_id: loginUser["_id"],
          user_f_name: loginUser.user_name.split(" ")[0],
          user_l_name: loginUser.user_name.split(" ")[1],
          user_type: "program_managers",
        };
        response = saveObjective(saveOverviewData);
      } else {
        response = updateObjective(objectiveId, saveOverviewData);
      }
      response
        .then((response) => {
          dispatch(hideLoader());
          dispatch(actionSuccess("Business Objective Basic Tab Saved Successfully"));

          if (objectiveId === "" && imageData.has("objectiveProfileImage")) {
            handleUploadImage(response.data.data.id);
          }
          if ((objectiveId && imageData.has("objectiveProfileImage")) || imageData.has("imageDelete")) {
            handleUploadImage(objectiveId);
          }
          if (objectiveId === "") {
            props.handleRedirectOnAddObjective(response.data.data.id);
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e.data.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    } finally {
      dispatch(hideLoader());
    }
  };
  return (
    <div className="gennx-content-wrapper  pt-3" style={{ width: "99.5%", overflow: "hidden" }}>
      <div
        className="pt-3 mx-2 gennx-content-wrapper padding-bottom-150i"
        style={{
          padding: "1%",
          marginTop: "1%",
          backgroundColor: "#ffffff",
          width: "99.1%",
          borderRadius: "5px",
        }}
      >
        <OverviewForm
          objectiveInformation={objectiveInformation}
          handleSaveOverview={handleSaveOverview}
          setImageData={setImageData}
          imageData={imageData}
          {...props}
        />
      </div>
    </div>
  );
};

export default NewsOverview;
