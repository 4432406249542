/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Client User Billing Details
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { fetchSingleUser } from "../../../../middleware/services/userApi";

const Billing = (props) => {
  const { accessRights: aR } = props;
  const accessPermission = true;
  const history = useHistory();
  const editUser = props.path === "/internal-user/add" ? false : true;
  const userId = props.userId;
  const dispatch = useDispatch();
  const [existingUser, setExistingUser] = useState({});
  const fetchUserDetail = (userId) => {
    dispatch(showLoader());
    fetchSingleUser(userId)
      .then((res) => {
        const userDetail = { ...res.data.data };
        setExistingUser(userDetail);
        // specify breadcrumb here
        const breadCrumbLinks = [
          { linkUrl: "/client-user", linkName: aR.moduleName },
          { linkUrl: `/client-user/${userId}/edit#profile`, linkName: `${userDetail.userFname} ${userDetail.userLname}` },
          { linkUrl: `/client-user/${userId}/edit/billing#billing-details`, linkName: "Billing Details", linkActive: true },
        ];
        dispatch(setBreadCrumb(breadCrumbLinks));
        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err.data.message ? err.data.message : "Fetch User Details Failed";
        dispatch(actionError(errMsg));
      });
  };

  const resetBilling = () => {
    console.log("resetBilling");
  };

  const saveBilling = () => {
    console.log("saveBilling");
  };

  useEffect(() => {
    dispatch(setSideNavForcedActiveLink("/client-user"));
    editUser && fetchUserDetail(userId);
    return () => {
      dispatch(setBreadCrumb());
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch]);

  return (
    <>
      <div className="mx-2" id="content-box">
        <div className="col-md-12 p-4">
          <div className="wrapper center-block">Billing Details</div>
        </div>
      </div>
      <div className="form-submit-box">
        <div className="container-fluid">
          <div className="row">
            <div className="float-left col-md-6 text-left">
              {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                <span className="pl-4" style={{ "margin-left": "-3em" }}>
                  <span
                    // to={props.nextPath}
                    className="span-link"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                      onClick={() => {
                        (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                          (props.selectedIndex < 5 && props.setCurrentTab(props.selectedIndex - 1));
                      }}
                    >
                      Previous
                    </button>
                  </span>
                </span>
              ) : null}
            </div>
            <div className="float-right col-md-6 text-right ">
              <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3 " onClick={() => history.push(`/client-user`)}>
                Cancel
              </button>
              <div className="d-none">
                {accessPermission ? (
                  existingUser.userActive ? (
                    <>
                      <button type="submit" className="btn btn-primary cy-btn pl-3 pr-3 mr-3" onClick={() => resetBilling()}>
                        Reset
                      </button>
                      <button type="button" className="btn btn-primary cy-btn pl-3 pr-3 " onClick={() => saveBilling()}>
                        Save
                      </button>
                    </>
                  ) : null
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;
