/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Contnet Block settings - Add | Edit settings
 ---------------------------------------------------------------------------------
  Creation Details
  Date Created				: 04/Nov/2022
  Author						  : Suraj Yadav 
================================================================
*/
import "./index.scss";
import React from "react";
import { ToggleSwitchNew } from "../../../../components/CustomFormElements/syncFusionFormFields";

function Settings(props) {
  const { formik } = props;
  return (
    <>
      {props.selectedIndex === 2 && (
        <div
          className="gennx-content-wrapper padding-bottom-100i p-2 mt-3 mx-2 bg-white"
          style={{ width: "98.5%", height: "100vh" }}
        >
          <form>
            <div>
              <div
                className="row"
                style={{
                  paddingTop: "1%",
                  paddingBottom: "3%",
                  marginLeft: "11px",
                }}
              >
                <div className="my-3 ">
                  {/* Allow change font style */}
                  <ToggleSwitchNew
                    formik={formik}
                    required="required"
                    properties={{
                      id: "disableFontStyle",
                      name: "disableFontStyle",
                      label: "Disable font style",
                    }}
                  />
                </div>

                <div className="my-3">
                  <ToggleSwitchNew
                    formik={formik}
                    required="required"
                    properties={{
                      id: "disableFontColor",
                      name: "disableFontColor",
                      label: "Disable font color",
                    }}
                  />
                </div>

                <div className="my-3  ">
                  {/* Allow change font formatting */}
                  <ToggleSwitchNew
                    formik={formik}
                    required="required"
                    properties={{
                      id: "disableFontFormatting",
                      name: "disableFontFormatting",
                      label: "Disable font formatting",
                    }}
                  />
                </div>

                <div className="my-3  ">
                  {/* Lock all settings */}
                  <ToggleSwitchNew
                    formik={formik}
                    required="required"
                    properties={{
                      id: "disableExtraSettings",
                      name: "disableExtraSettings",
                      label: "Disable extra settings",
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default Settings;
