/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  All Form element for news overview - Add | Edit Overview
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React, { useState, useEffect, useRef } from "react";
import { TextField } from "../../../../../components/formElement/customFormElement/customFomElements"
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { getProductVariant } from "../../../../../../utilities";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import "./index.scss"
import Select from "react-select";
export const ObjectiveTextField = (props) => {
    return <TextField
        {...props}
    />
}

export const ObjectiveCategory = (props) => {
    const [objectiveTypes, setObjectiveTypes] = useState([
        {
            objectiveTypeName: "Client",
            objectiveTypeValue: "Client",
            productVariant: "Insider"
        },
        {
            objectiveTypeName: "Demo",
            objectiveTypeValue: "Demo",
            productVariant: "Insider"
        }
    ]);

    const productVariant = getProductVariant();
    const type = objectiveTypes.filter(objective => objective.productVariant === productVariant);

    const searchobjectiveTypes = () => {
        setObjectiveTypes(type);
    };

    useEffect(() => {
        searchobjectiveTypes();
    }, []);

    return (
        <>
            <Select
                noOptionsMessage={() => <div>Please type at least 3 characters</div>}
                placeholder="Select objective type"
                minMenuHeight={100}
                maxMenuHeight={150}
                menuPlacement="auto"
                options={objectiveTypes.map(typesData => {
                    return { label: typesData.objectiveTypeName, value: typesData.objectiveTypeValue };
                })}
                value={objectiveTypes}
                {...props.formik.getFieldProps(props.name)}
                onChange={(selectedOption) => {
                    props.formik.setFieldValue(props.name, selectedOption);
                }}
                {...props}
            />
            {
                props.formik.touched[props.name] && props.formik.errors[props.name] ? (
                    <div className="col-md-12 pl-0 text-danger small">{props.formik.errors[props.name]}</div>
                ) : null
            }
        </>
    );
}

export const CoverageStartDate = (props) => {
    let domRef = useRef();

    return (
        <div className='control-pane'>
            <div className='control-section row'>
                <div className='col-lg-12'>
                    <div className='datepicker-control-section'>
                        <DatePickerComponent
                            name={props.name}
                            format='dd-MMM-yyyy'
                            ref={calendar => domRef = calendar}
                            value={new Date()}
                            {...props.formik.getFieldProps(props.name)}
                            onChange={(e) => {
                                props.formik.setFieldValue(props.name, new Date(e.value));
                            }}

                        ></DatePickerComponent>
                    </div>
                </div>
            </div>
        </div>)
}

export const CoverageEndDate = (props) => {
    let domRef = useRef();

    return (
        <div className='control-pane'>
            <div className='control-section row'>
                <div className='col-lg-12'>
                    <div className='datepicker-control-section'>
                        <DatePickerComponent
                            name={props.name}
                            format='dd-MMM-yyyy'
                            ref={calendar => domRef = calendar}
                            value={new Date()}
                            {...props.formik.getFieldProps(props.name)}
                            onChange={(e) => {
                                props.formik.setFieldValue(props.name, new Date(e.value));
                            }}

                        ></DatePickerComponent>
                    </div>
                </div>
            </div>
        </div>)
}

export const ObjectiveStatus = (props) => {
    const [objectiveStatus, setObjectiveStatus] = useState([
        {
            label: "Scoping Phase",
            value: "under_discussion",
        },
        {
            label: "Active",
            value: "active",
        }, {
            label: "Closed",
            value: "closed",
        },
        {
            label: "Abeyance",
            value: "abeyance",
        },
    ]);
    return (
        <>
            <Select
                placeholder="Select objective status"
                minMenuHeight={100}
                maxMenuHeight={150}
                menuPlacement="auto"
                options={objectiveStatus}
                {...props.formik.getFieldProps(props.name)}
                onChange={(selectedOption) => {
                    props.formik.setFieldValue(props.name, selectedOption);
                }}
                {...props}
            />
            {
                props.formik.touched[props.name] && props.formik.errors[props.name] ? (
                    <div className="col-md-12 pl-0 text-danger small">{props.formik.errors[props.name]}</div>
                ) : null
            }
        </>
    );
}


export const RadioInput = (props) => {
    const handleOnChange = (e) => {
        props.formik.setFieldValue(props.name, e.value);
    }
    return (
        <span className="cust-label">
            {props.input.map((inputValue) => {
                return (<span style={{ padding: "5px" }}>
                    <RadioButtonComponent checked={props.formik.values[props.name] == inputValue.value ? true : inputValue.check} onChange={(e) => handleOnChange(e)} label={inputValue.label} name={props.name} value={inputValue.value}></RadioButtonComponent>
                </span>)
            })}
        </span>
    )
}