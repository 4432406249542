/**
 * @fileDescription News API
 * @author Aditya
 * @date 23/Aug/2020
 */

import { NEWS_API as API } from "../../config/apiConfig";
import { NEWS_URL } from "../../config";
import { columnSelectionComplete } from "@syncfusion/ej2-grids";

// export data to download excel
export const exportJsonData = (payload) => {
  return API({
    url: `/news/export`,
    method: "POST",
    data: payload,
  });
};
// Download File after export data
export const downloadFileUrl = (fileName) => {
  return `${NEWS_URL}/news/download/${fileName}`;
};

// all news
export const fetchNews = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/news/all",
    method: "GET",
    params: params,
  });
};

// Fetch Single News
export const fetchSingleNews = (newsId, params = "") => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/news/all/${newsId}`,
    method: "GET",
    params: params,
  });
};

export const fetchNewsPage = (newsId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/news/${newsId}/page`,
    method: "GET",
    params: params,
  });
};

// Fetch News Non Dat Categories
export const fetchNewsCategorization = (params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/news/category`,
    method: "GET",
    params: params,
  });
};

// Fetch News newsletter tags
export const fetchNewsletterTags = () => {
  return API({
    url: `/news/newsletter-tag`,
    method: "GET",
  });
};

// Product Taxonomy Routes

export const fetchNewsTaxonomyMedia = (newsId) => {
  return API({
    url: `/news/${newsId}/taxonomy/media`,
    method: "GET",
  });
};
export const fetchNewsTaxonomy = (newsId) => {
  return API({
    url: `/news/${newsId}/taxonomy/technology`,
    method: "GET",
  });
};

export const fetchNewsValueChain = (newsId) => {
  return API({
    url: `/news/${newsId}/taxonomy/industry-value-chain`,
    method: "GET",
  });
};

export const fetchNewsTrend = (newsId) => {
  return API({
    url: `/news/${newsId}/taxonomy/trend`,
    method: "GET",
  });
};

// save News (Overview) Info
export const saveOverview = (payload) => {
  return API({
    url: `/news/all`,
    method: "POST",
    data: payload,
  });
};

// save News (Overview) Info
export const updateOverview = (newsId, payload) => {
  return API({
    url: `/news/all/${newsId}`,
    method: "PUT",
    data: payload,
  });
};

// save News Taxonomy Media
export const saveNewsTaxonomyMedia = (newsId, payload) => {
  return API({
    url: `news/${newsId}/taxonomy/media`,
    method: "POST",
    data: payload,
  });
};
// save News Taxonomy
export const saveNewsTaxonomy = (newsId, payload) => {
  return API({
    url: `news/${newsId}/taxonomy/technology`,
    method: "POST",
    data: payload,
  });
};

// save News Content
export const saveNewsContent = (newsId, payload) => {
  return API({
    url: `news/${newsId}/page`,
    method: "POST",
    data: payload,
  });
};

export const updateNewsContent = (newsId, pageId, payload) => {
  return API({
    url: `news/${newsId}/page/${pageId}`,
    method: "PUT",
    data: payload,
  });
};

// save News Trend
export const saveNewsTrend = (newsId, payload) => {
  return API({
    url: `news/${newsId}/taxonomy/trend`,
    method: "POST",
    data: payload,
  });
};

// delete News Trend
export const deleteNewsTrend = (newsId, trendId) => {
  return API({
    url: `news/${newsId}/taxonomy/trend/${trendId}`,
    method: "DELETE",
  });
};

// save News Trend
export const saveNewsValueChain = (newsId, payload) => {
  return API({
    url: `news/${newsId}/taxonomy/industry-value-chain`,
    method: "POST",
    data: payload,
  });
};

// delete News Trend
export const deleteNewsValueChain = (newsId, trendId) => {
  return API({
    url: `news/${newsId}/taxonomy/industry-value-chain/${trendId}`,
    method: "DELETE",
  });
};

export const deleteNewsTaxonomy = (newsId, taxonomyId) => {
  return API({
    url: `news/${newsId}/taxonomy/technology/${taxonomyId}`,
    method: "DELETE",
  });
};
export const deleteNewsTaxonomyMedia = (newsId, taxonomyId) => {
  return API({
    url: `news/${newsId}/taxonomy/media/${taxonomyId}`,
    method: "DELETE",
  });
};

// save News Taxonomy
export const updateNews = (newsId, payload) => {
  return API({
    url: `news/all/${newsId}`,
    method: "PUT",
    data: payload,
  });
};

export const deleteNews = (newsId) => {
  return API({
    url: `/news/${newsId}`,
    method: "DELETE",
  });
};

export const deleteManyNews = (payload, params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/news",
    method: "DELETE",
    params: params,
    data: payload,
  });
};

export const fetchNewsImageUrl = (fileName) => {
  return `${NEWS_URL}/news/image/${fileName}`;
};

export const fetchSinglePage = (newsId, fileName) => {
  return API({
    url: `/news/${newsId}/page/${fileName}`,
    method: "GET",
  });
};

// tag objective
export const saveNewsObjective = (payload) => {
  return API({
    url: "/news/objective",
    method: "POST",
    data: payload,
  });
};

//tag objective taxonomy
export const saveNewsObjectiveTaxonomy = (payload) => {
  return API({
    url: "/news/objective/taxonomy",
    method: "POST",
    data: payload,
  });
};

export const fetchNewsObjectiveTaxonomy = (objectiveId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/news/objective/taxonomy/${objectiveId}`,
    method: "GET",
    params: params,
  });
};

export const deleteNewsObjective = (objectiveId, payload) => {
  return API({
    url: `/news/objective/${objectiveId}`,
    method: "DELETE",
    data: payload,
  });
};

//KIA Tagging APis

// Fetch KIA Tagging
export const fetchNewsKia = (newsId) => {
  return API({
    url: `/news/${newsId}/taxonomy/kia`,
    method: "GET",
  });
};
// Save KIA tags
export const saveNewsKiaTaxonomy = (newsId, payload) => {
  return API({
    url: `news/${newsId}/taxonomy/kia`,
    method: "POST",
    data: payload,
  });
};
// Delete Kia Te
export const deleteNewsKia = (newsId, payload) => {
  return API({
    url: `news/${newsId}/taxonomy/kia`,
    method: "DELETE",
    data: payload,
  });
};

// get upload documents
export const fetchNewsDocument = (actualFileName, fileName) => {
  return `${NEWS_URL}/news/document/${actualFileName}/${fileName}`;
};

//delete single document
export const deleteNewsDocument = (newsId, documentId, payload) => {
  return API({
    url: `/news/${newsId}/document/${documentId}`,
    method: "DELETE",
    data: payload,
  });
};

export const deleteManyNewsTrend = (newsId, taxonomyIds = []) => {
  return API({
    url: `/news/${newsId}/taxonomy/trend`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};

export const deleteManyNewsTechnology = (newsId, taxonomyIds = []) => {
  return API({
    url: `/news/${newsId}/taxonomy/technology`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};

export const deleteManyNewsValueChain = (newsId, payload) => {
  return API({
    url: `/news/${newsId}/taxonomy/industry-value-chain`,
    method: "DELETE",
    data: payload,
  });
};

export const deleteManyNewsTaxonomyMedia = (newsId, taxonomyIds = []) => {
  return API({
    url: `/news/${newsId}/taxonomy/media`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};

// Taxonomy Bulk tagging

// Post  taxonomy
export const saveNewsTaxonomyTags = (payload) => {
  return API({
    url: `/news/taxonomy/technology`,
    method: "PUT",
    data: payload,
  });
};

// Post  kia
export const saveNewsKiaTags = (payload) => {
  return API({
    url: `/news/taxonomy/kia`,
    method: "PUT",
    data: payload,
  });
};

// Post  value chain
export const saveValueChainKiaTags = (payload) => {
  return API({
    url: `/news/taxonomy/industry-value-chain`,
    method: "PUT",
    data: payload,
  });
};

// Post  trend
export const saveNewsTrendTags = (payload) => {
  return API({
    url: `/news/taxonomy/trend`,
    method: "PUT",
    data: payload,
  });
};

// Post  Media
export const saveNewsMediaTags = (payload) => {
  return API({
    url: `/news/taxonomy/media`,
    method: "PUT",
    data: payload,
  });
};

//tag objective taxonomy
export const saveNewsBusinessObjectiveTaxonomy = (payload) => {
  return API({
    url: "/news/objective/taxonomy",
    method: "PUT",
    data: payload,
  });
};

//Update content tags for news
export function updateContentTags(newsId, payload) {
  return API({
    url: `/news/all/${newsId}/tags`,
    method: "PUT",
    data: payload,
  });
}

//   add or update sequence of display to taxonomy
export const saveNewsTrendSequence = (newsId, payload) => {
  return API({
    url: `/news/${newsId}/taxonomy/trend/sort-order`,
    method: "PUT",
    data: payload,
  });
};
