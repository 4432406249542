/*
================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Company State
----------------------------------------------------------------
    Creation Details
    Date Created				: 20/Jan/2022
    Author						: SARVESH SAMANT
================================================================
*/
import {
    SET_STATE,
    ACTIVE_PAGE,
    RECORD_VISITED,
    RECORD_RESET,
    RESET_STATE,
    SKIP_RECORDS,
    SET_PAGE,
    SET_FILTERS,
    SORT_RECORDS,
    ROUTE_CHANGED,
    ROUTE_UNCHANGED,
    INTERNAL_GRID_PRESENT,
    INTERNAL_GRID_RECORD_UNVISITED,
    INTERNAL_GRID_STATE,
    INTERNAL_GRID_VISITED,
    SET_INTERNAL_SKIP,
    RESET_INTERNAL_GRID_STATE,
    SET_INTERNAL_FILTERS,
    SET_INTERNAL_ACTIVE_PAGE,
    SET_INTERNAL_SORT,
    SET_INTERNAL_RECORDS_PER,
    SET_TAB


} from "../../constants/actionConstants";
export const setMaintainedState = (skip, filter, sort) => {
    return {
        type: SET_STATE,
        payload: {
            skip, filter, sort
        }
    }
}
export const resetMaintainedState = () => ({
    type: RESET_STATE,
})

export const visitRecord = () => ({
    type: RECORD_VISITED
})
export const unVisitRecord = () => ({
    type: RECORD_RESET
})
export const skipRecords = (data) => ({
    type: SKIP_RECORDS,
    payload: data
})
export const setRecordsPerPage = (data) => ({
    type: SET_PAGE,
    payload: data
})
export const activePageInfo = (data) => ({
    type: ACTIVE_PAGE,
    payload: data
})
export const setFilterParams = (data) => ({
    type: SET_FILTERS,
    payload: data
})

export const setSortingData = (data) => ({
    type: SORT_RECORDS,
    payload: data
})

export const routeChanged = () => ({
    type: ROUTE_CHANGED
});

export const routeUnchanged = () => ({
    type: ROUTE_UNCHANGED
})

export const internalGridPresent = (data) => ({
    type: INTERNAL_GRID_PRESENT
})

export const setInternalGridState = (skip, sort, filter) => {
    return {
        type: INTERNAL_GRID_STATE,
        payload: {
            skip, sort, filter
        }
    }
}


export const internalGridVisited = () => ({
    type: INTERNAL_GRID_VISITED
})
export const internalGridUnvisited = () => ({
    type: INTERNAL_GRID_RECORD_UNVISITED
})

export const setInternalSkip = (data) => ({
    type: SET_INTERNAL_SKIP,
    payload: data
})

export const resetInternalGrid = () => ({
    type: RESET_INTERNAL_GRID_STATE
})
export const setInternalFilters = (data) => ({
    type: SET_INTERNAL_FILTERS,
    payload:data
})

export const setInternalActivePages = (data) => ({
    type: SET_INTERNAL_ACTIVE_PAGE,
    payload:data
})
export const setInternalSort = (data) => ({
    type: SET_INTERNAL_SORT,
    payload:data
})

export const setInternalRecordsPerPage = (data) => ({
    type: SET_INTERNAL_RECORDS_PER,
    payload:data
})

export const setActiveTabs = (data) => ({
    type: SET_TAB,
    payload:data
})