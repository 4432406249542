import React, { useEffect, useState, Suspense } from "react"
import { fetchPages } from "../../../../../../middleware/services/pagesApi";
import { actionError } from "../../../../../../middleware/actions/utilityAction";
import "./index.scss";
import { useDispatch } from "react-redux"
const PreviewComponent = React.lazy(() => import("../../../../../components/contentSideBar/PreviewComponent"));
const Preview = (props) => {
    const dispatch = useDispatch()
    const [pageData, setPageData] = useState([])
    const fetchAllPages = () => {
        const response = fetchPages(props.computedMatch.params.deliverableId);
        response.then(res => {
            if (res.status == 200) {
                setPageData(res.data.data)
            }
        }).catch((e) => {
            dispatch(actionError("Something went wrong"))
        })
    }
    useEffect(() => {
        fetchAllPages()
    }, [])
    return <>
        <Suspense fallback={<div>Loading Preview</div>}>
            <PreviewComponent pageData={pageData} heading="Deliverable Content" />
        </Suspense>
    </>
}

export default Preview;