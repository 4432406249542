/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : Survey API 
----------------------------------------------------------------
	Creation Details
	Date Created				: 17/Mar/2021
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import { SURVEY_API as API } from "../../config/apiConfig";
import { SURVEY_API_URL } from "../../config";

// fetch survey list
export const fetchSurveys = (params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: "/survey",
		method: "GET",
		params: params
	});
};

// fetch single survey
export const fetchSingleSurvey = (surveyId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (fields) params.fields = JSON.stringify(fields);
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: `/survey/${surveyId}`,
		method: "GET",
		params: params
	});
};

// Add new survey
export const addNewSurvey = (payload) => {
	return API({
		url: "/survey",
		method: "POST",
		data: payload
	});
};
// Edit existing survey
export const updateSurvey = (surveyId, payload) => {
	return API({
		url: `/survey/${surveyId}`,
		method: "PUT",
		data: payload
	});
};
// delete single survey
export const deleteSingleSurvey = (surveyId, payload) => {
	return API({
		url: `/survey/${surveyId}`,
		method: "DELETE",
		data: payload
	});
};
// Publish survey
export const publishSurvey = (surveyId, payload) => {
	return API({
		url: `/survey/${surveyId}/publish`,
		method: "PUT",
		data: payload
	});
};
// Send survey invitaion
export const sendSurveyInvitation = (surveyId, payload) => {
	return API({
		url: `/survey/${surveyId}/invitation`,
		method: "POST",
		data: payload
	});
};

// Get survey analytics
export const getSurveyAnalytics = (surveyId, params = {}) => {
	let { fields = undefined, filters = undefined } = params;
	if (fields) params.fields = JSON.stringify(fields);
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: `/survey/${surveyId}/analytics`,
		method: "GET",
		params: params
	});
};

// Get survey assets eg. image/file
export const getFileUrl = (fileName, originalFileName = "") => {
	return `${SURVEY_API_URL}/survey/file/${fileName}`;
};

// Add survey audience
export const addSurveyAudience = (surveyId, payload) => {
	return API({
		url: `/survey/${surveyId}/audience`,
		method: "POST",
		data: payload
	});
};

// Get Survey aduience 
export const getSurveyAudience = (surveyId, params = {}) => {
	let { fields = undefined, filters = undefined } = params;
	if (fields) params.fields = JSON.stringify(fields);
	if (filters) params.filters = JSON.stringify(filters);

	return API({
		url: `/survey/${surveyId}/audience`,
		method: "GET",
		params: params
	});
};
// Delete Survey aduience 
export const deleteSurveyAudience = (surveyId, payload) => {
	return API({
		url: `/survey/${surveyId}/audience`,
		method: "DELETE",
		data: payload
	});
};
// Download audience user template
export const fetchImportTemplateFileUrl = () => {
	return `${SURVEY_API_URL}/survey/template/audience-import`;
};
// Export Survey aduience in bulk using excel
export const exportSurveyAudience = (surveyId, params = {}) => {
	return API({
		url: `survey/${surveyId}/audience/export`,
		method: "GET",
		params: params
	});

};

// import excel data template for survey user audience
export const uploadTemplateData = (surveyId, payload) => {
	return API({
		url: `/survey/${surveyId}/audience/import`,
		method: "POST",
		data: payload
	});
};

// Get Survey Data
export const getSurveyDataForRender = (params = {}) => {
	return API({
		url: `/survey-render`,
		method: "GET",
		params: params
	});
};
// Submit Survey Response
export const submitSurveyResponse = (surveyId, payload) => {
	return API({
		url: `/survey/${surveyId}/audience/response`,
		method: "POST",
		data: payload
	});
};


//KIA Tagging APis

// Fetch KIA Tagging
export const fetchSurveyKia = (surveyId) => {
	return API({
		url: `/survey/${surveyId}/taxonomy/kia`,
		method: "GET",
	});
};
// Save KIA tags
export const saveSurveyKiaTaxonomy = (surveyId,payload) => {
	return API({
		url: `/survey/${surveyId}/taxonomy/kia`,
		method: "POST",
		data: payload
	});
};
// Delete Kia Tagging
export const deleteSurveyKia = (surveyId,payload) => {
	return API({
		url: `/survey/${surveyId}/taxonomy/kia`,
		method: "DELETE",
		data: payload
	});
};
