import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { isAdmin } from "../../../utilities";
import { fetchclientDetailsReport, fetchclientDetailsUsers, fetchGroupList } from "../../../middleware/services/reportApi";
import { Dropdown } from "../../components/CustomFormElements/syncFusionFormFields";
import CloseIcon from "../../../assets/images/closeIcon.svg?react";
import * as CONSTANTS from "../../../constants/index";
import "./style.scss";
import CustomSidebar from "./SideBar";
import { DropDownFilter, StringFilter } from "../../../app/components/dataGrid/fliters";
import { hideLoader, showLoader } from "../../../middleware/actions/utilityAction";
import { presets } from "utils";
import TreeDataGridNew from "app/components/treeDataGridNew";
import { ColumnDirective, ColumnsDirective } from "@syncfusion/ej2-react-grids";
import { fetchTaxonomyTrend } from "middleware/services/cmsApi";

// specify breadcrumb here
const breadCrumbLinks = [{ linkUrl: "/report/internal-user", linkName: "Internal User Report", linkActive: true }];
let userCompetencyIndustry =
  localStorage.getItem("userCompetencyIndustry") !== "null" ? JSON.parse(localStorage.getItem("userCompetencyIndustry")) : [];

const SiderBarGrid = ({ onclose, fetch }) => {
  const dispatch = useDispatch();

  const [rowData, setRowData] = useState([]);

  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const handleQueryCellInfo = (args) => {
    if (args.column.field && !args.data[args.column.field]) args.cell.innerHTML = "NA";
  };

  const headerDualCom = (argOne, argTwo) => {
    return (
      <div>
        <span className="header-cell">{argOne}</span>
        <span className="header-cell">{argTwo}</span>
      </div>
    );
  };

  const enabledisableFilter = (value) => {
    let sourceData = [
      { id: "enabled", text: "Enabled" },
      { id: "disabled", text: "Disabled" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  const yesNoFilter = (value) => {
    let sourceData = [
      { id: "YES", text: "YES" },
      { id: "NO", text: "NO" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  const activeInactiveFilter = (value) => {
    let sourceData = [
      { id: "YES", text: "Active" },
      { id: "NO", text: "Inactive" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const subscriptionStatusTemplate = (value) => {
    return value?.subscriptionIsActive === "YES" ? "Active" : "Inactive";
  };

  const columnFields = [
    {
      field: "userName",
      headerText: "Name",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "150px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("User", "Name"),
    },
    {
      field: "subscriptionBtbCompanyName",
      headerText: "Group",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "150px",
      allowFiltering: false,
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Group", "Name"),
    },
    {
      field: "userEmail",
      headerText: "Email",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "120x",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("User", "Email"),
    },
    {
      field: "userMobile",
      headerText: "Contact Number",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "110px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Contact", "Number"),
    },
    {
      field: "linkedInAddress",
      headerText: "LinkedIn Address",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "115px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("LinkedIn", "Address"),
    },
    {
      field: "subscriptionType",
      headerText: "Trial",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "90px",
      filter: { operator: "equal" },
      filterTemplate: yesNoFilter,
      headerTemplate: () => headerDualCom("Is", "Trial"),
    },
    {
      field: "roundUp",
      headerText: "Round Up",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "90px",
      filterTemplate: enabledisableFilter,
      filter: { operator: "equal" },
      headerTemplate: () => headerDualCom("Round", "Up"),
    },
    {
      field: "roundUpEmail",
      headerText: "Round Up - Email",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "115px",
      filterTemplate: enabledisableFilter,
      filter: { operator: "equal" },
      headerTemplate: () => headerDualCom("Round Up", "Email"),
    },
    {
      field: "roundUpWhatsApp",
      headerText: "Round Up - WhatsApp",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "120px",
      filterTemplate: enabledisableFilter,
      filter: { operator: "equal" },
      headerTemplate: () => headerDualCom("Round Up", "WhatsApp"),
    },
    {
      field: "myAlert",
      headerText: "My Alerts",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "90px",
      filterTemplate: enabledisableFilter,
      filter: { operator: "equal" },
      headerTemplate: () => headerDualCom("My", "Alerts"),
    },
    {
      field: "subscriptionStartDate",
      headerText: "Subscription Start Date",
      type: "Date",
      textAlign: "Center",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "125px",
      format: { type: "date", format: "dd-MMM-yyyy" },
      headerTemplate: () => headerDualCom("Subscription", "Start Date"),
    },
    {
      field: "subscriptionEndDate",
      headerText: "Subscription End Date",
      type: "Date",
      textAlign: "Center",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "125px",
      format: { type: "date", format: "dd-MMM-yyyy" },
      headerTemplate: () => headerDualCom("Subscription", "End Date"),
    },
    {
      field: "subscriptionIsActive",
      headerText: "Subscription Status",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "110px",
      filter: { operator: "equal" },
      filterTemplate: activeInactiveFilter,
      template: subscriptionStatusTemplate,
      headerTemplate: () => headerDualCom("Subscription", "Status"),
    },
    {
      field: "industryName",
      headerText: "Industry",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "110px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Industry", "Names"),
    },
    {
      field: "sgfName",
      headerText: "SGF",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: "90px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("SGF", "Names"),
    },
  ];

  const fetchUsersData = useCallback(async () => {
    try {
      showLoaderGrid();
      const response = await fetch();
      if (response?.data?.data.length > 0) setRowData(response?.data?.data);
      hideLoaderGrid();
    } catch (error) {
      hideLoaderGrid();
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchUsersData();
  }, []);

  return (
    <React.Fragment>
      <div className="gennx-grid-client-detail-report client-details-user-grid" style={{ height: "100%" }}>
        <header className="user-grid-header">
          <span className="header-text">Detail View</span>
          <span
            className="closeIcon"
            onClick={() => {
              onclose();
            }}
          >
            <CloseIcon />
          </span>
        </header>

        {rowData.length > 0 && (
          <TreeDataGridNew
            gridTitle="Client Activity Report"
            rowData={rowData}
            isPaging={false}
            showResetFilter={true}
            deleteMany={false}
            allowPagination={true}
            showExportButton={true}
            exportFileName={"User Detail Report - Detail View"}
            dropDownFilterFields={["subscriptionType", "roundUp", "roundUpEmail", "roundUpWhatsApp", "myAlert", "subscriptionIsActive"]}
            handleQueryCellInfo={handleQueryCellInfo}
          >
            <ColumnsDirective>
              {columnFields.map((column, index) => (
                <ColumnDirective key={index} {...column} />
              ))}
            </ColumnsDirective>
          </TreeDataGridNew>
        )}
      </div>
    </React.Fragment>
  );
};
// company dashboard component
const DataReport = (props) => {
  const [dateRange, setDateRange] = useState(null);
  const [startDate, setStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [groupDataSource, setGoupDataSource] = useState([]);
  const [sideBarFilters, setSideBarFilters] = useState([]);
  const [rowData, setRowData] = useState([0]);
  const [industryArray, setIndustryArray] = useState([]);
  const [isGnxUserFilter, setIsGnxUserFilter] = useState(true);
  const [gridRef, setGridRef] = useState(null);
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const industrySource = useMemo(() => {
    if (isAdmin()) {
      const filterArray = industryArray.map((industry) => ({ label: industry.trendName, value: industry.trendName }));
      filterArray.unshift({ label: "All", value: "All" });
      return filterArray;
    }
    const uniqueIndustryNames = new Set();
    const industryList = userCompetencyIndustry
      .filter((industry) => {
        const industryName = industry.industryName;
        return !uniqueIndustryNames.has(industryName) && uniqueIndustryNames.add(industryName);
      })
      .map(({ industryName }) => ({ label: industryName, value: industryName }));
    industryList.unshift({ label: "All", value: "All" });
    return industryList;
  }, [userCompetencyIndustry, industryArray]);

  const toggleSidebar = (fieldName, groupName, reportDateRange) => {
    let isGnxUserDetails = true;
    let filters = [];
    if (groupName) {
      filters.push(["subscription_btb_company_name", "eq", groupName]);
    }
    let industryFilters = [];
    if (formik.values.reportIndustry && formik.values.reportIndustry !== "All") {
      industryFilters.push(["industryName", "eq", formik.values.reportIndustry]);
    }
    if (formik.values.reportIndustry && formik.values.reportIndustry == "All") {
      let userIndustries = industrySource.filter((item) => item.label !== "All").map((item) => item.label);
      userIndustries.length == 0
        ? industryFilters.push(["industryName", "eq", "null"])
        : industryFilters.push(["industryName", "eq", userIndustries]);
    }
    switch (fieldName) {
      case "mobility":
        filters.push(["industryName", "eq", "Mobility"]);
        isGnxUserDetails = false;
        break;
      case "energy":
        filters.push(["industryName", "eq", "Energy"]);
        isGnxUserDetails = false;
        break;
      case "foodAndNutrition":
        filters.push(["industryName", "eq", "Food & Nutrition"]);
        isGnxUserDetails = false;
        break;
      case "personalCare":
        filters.push(["industryName", "eq", "Personal Care"]);
        isGnxUserDetails = false;
        break;
      case "industrial":
        filters.push(["industryName", "eq", "Industrial"]);
        isGnxUserDetails = false;
        break;
      case "activeUser":
        filters.push(["subscriptionUserIsActive", "eq", "YES"]);
        filters.push(...industryFilters);
        isGnxUserDetails = false;
        break;
      case "inActiveUser":
        filters.push(["subscriptionUserIsActive", "eq", "NO"]);
        filters.push(...industryFilters);
        isGnxUserDetails = false;
        break;
      case "totalActiveInActiveUser":
        filters.push(...industryFilters);
        isGnxUserDetails = false;
        break;
      case "subscription":
        isGnxUserDetails = false;
        filters.push(...industryFilters);
        filters.push(["subscriptionType", "eq", "Subscription"]);
        break;
      case "trial":
        isGnxUserDetails = false;
        filters.push(...industryFilters);
        filters.push(["subscriptionType", "eq", "Trial"]);

        break;
      case "contactAvailable":
        filters.push(...industryFilters);
        // filters.push(["userMobile", "neq", null]);
        filters.push(["userMobile", "neq", ""]);
        break;
      case "contactNotAvailable":
        filters.push(...industryFilters);
        filters.push(["userMobile", "nex", true]);
        break;
      case "myAlertActivated":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly My Alerts"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "Yes"]);
        break;
      case "myAlertDeActivated":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly My Alerts"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "No"]);
        break;
      case "mobilityRoundupActivated":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Mobility"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "Yes"]);
        break;
      case "mobilityRoundupDeactivated":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Mobility"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "No"]);
        break;
      case "mobilityRoundupEmail":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Mobility"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "Yes"]);
        filters.push(["userNotificationPreference.notificationCommunicationMode", "cn", "Email"]);
        break;
      case "mobilityRoundupWhatsApp":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Mobility"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "Yes"]);
        filters.push(["userNotificationPreference.notificationCommunicationMode", "cn", "WhatsApp"]);
        break;
      case "energyRoundupActivated":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Energy"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "Yes"]);
        break;
      case "energyRoundupDeactivated":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Energy"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "No"]);
        break;
      case "energyRoundupEmail":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Energy"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "Yes"]);
        filters.push(["userNotificationPreference.notificationCommunicationMode", "cn", "Email"]);
        break;
      case "energyRoundupWhatsApp":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Energy"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "Yes"]);
        filters.push(["userNotificationPreference.notificationCommunicationMode", "cn", "WhatsApp"]);
        break;
      case "foodAndNutritionRoundupActivated":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Food & Nutrition"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "Yes"]);
        break;
      case "foodAndNutritionRoundupDeactivated":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Food & Nutrition"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "No"]);
        break;
      case "foodAndNutritionRoundupEmail":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Food & Nutrition"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "Yes"]);
        filters.push(["userNotificationPreference.notificationCommunicationMode", "cn", "Email"]);
        break;
      case "foodAndNutritionRoundupWhatsApp":
        filters.push(...industryFilters);
        filters.push(["userNotificationPreference.notification_name", "eq", "Weekly Roundup - Food & Nutrition"]);
        filters.push(["userNotificationPreference.notificationStatus", "eq", "Yes"]);
        filters.push(["userNotificationPreference.notificationCommunicationMode", "cn", "WhatsApp"]);
        break;
      case "linkedinAvailable":
        filters.push(...industryFilters);
        filters.push(["user_social.linkedin", "ex", ""]);
        break;
      case "linkedinNotAvailable":
        filters.push(...industryFilters);
        filters.push(["user_social.linkedin", "nex", ""]);
        break;
      default:
        break;
    }
    setIsGnxUserFilter(isGnxUserDetails);
    setSideBarFilters([...filters]);

    if (sidebarRef.current) {
      sidebarRef.current.hide();
    }
    setShowSidePanel(!showSidePanel);
  };

  const fetchUsers = useCallback(
    (params = {}) => {
      const { filters = [] } = params;
      if (dateRange) {
        const [startDate, endDate] = dateRange;

        const gte = { d: moment(startDate).toISOString() };
        const lt = { d: moment(endDate).add(1, "day").toISOString() };

        filters.push(["subscriptionStartDate", "gte", gte.d]);
        filters.push(["subscriptionStartDate", "lt", lt.d]);
      }
      params.filters = [...sideBarFilters, ...filters];
      params.isGnxUserDetails = isGnxUserFilter;
      return fetchclientDetailsUsers({ ...params });
    },
    [isGnxUserFilter, sideBarFilters, dateRange]
  );

  const initialState = {
    reportGroup: "All",
    reportAccountStatus: "All",
    reportIndustry: "All",
  };
  const fnStartDate = () => {
    try {
      showLoaderGrid();
      let dateFrom = new Date();
      let dateTo = new Date();
      dateFrom.setDate(dateFrom.getDate() - CONSTANTS.CLIENT_DETAILS_DATE_RANGE);
      const dateStart = dateFrom
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, "-");
      dateTo.setDate(dateTo.getDate());
      const dateEnd = dateTo
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, "-");
      setStartDate(new Date(dateStart));
      setEndDate(new Date(dateEnd));
      setDateRange([dateFrom, dateTo]);
      hideLoaderGrid();
      return [dateFrom, dateTo];
    } catch (e) {
      hideLoaderGrid();
    }
  };

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    // validationSchema: Yup.object().shape(fundingValidationSchema)
  });

  const recordsTemplate = (value) => {
    const a = value[`${value.column.field}`];
    return a ? (
      <span className="span-link-color" onClick={() => toggleSidebar(value.column.field, value["groupName"])}>
        {a}
      </span>
    ) : (
      <span>{a}</span>
    );
  };

  // specify column names for column fields in datGrid here
  const columnFields = [
    {
      field: "groupName",
      headerText: "Group",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "150px",
      minWidth: "150px",
      filterTemplate: StringFilter,
      allowFiltering: false,
      allowSorting: false,
    },
    {
      textAlign: "Center",
      headerText: "Industry",
      columns: [
        {
          field: "mobility",
          headerText: "Mobility",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "95px" : "90px",
          minWidth: window.innerWidth < 1366 ? "95px" : "90px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "energy",
          headerText: "Energy",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "90px" : "85px",
          minWidth: window.innerWidth < 1366 ? "90px" : "85px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "foodAndNutrition",
          headerText: "Food and Nutrition",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "145px" : "140px",
          minWidth: window.innerWidth < 1366 ? "145px" : "140px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "personalCare",
          headerText: "Personal Care",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "120px" : "115px",
          minWidth: window.innerWidth < 1366 ? "120px" : "115px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "industrial",
          headerText: "Industrial",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "User Count (Subscription)",
      columns: [
        {
          field: "activeUser",
          headerText: "Active",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "80px",
          minWidth: "80px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "inActiveUser",
          headerText: "Inactive",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "85px",
          minWidth: "85px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "totalActiveInActiveUser",
          headerText: "Total",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "70px",
          minWidth: "70px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "User Added in The Period",
      columns: [
        {
          field: "subscription",
          headerText: "Subscription",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1440 ? "120px" : "110px",
          minWidth: "110px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "trial",
          headerText: "Trial",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: "70px",
          minWidth: window.innerWidth < 1440 ? "80px" : "70px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Contact Number",
      columns: [
        {
          field: "contactAvailable",
          headerText: "Available",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "95px" : "90px",
          minWidth: window.innerWidth < 1366 ? "95px" : "90px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "contactNotAvailable",
          headerText: "Not Available",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "115px" : "110px",
          minWidth: window.innerWidth < 1366 ? "115px" : "110px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "My Alerts",
      columns: [
        {
          field: "myAlertActivated",
          headerText: "Activated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "95px" : "90px",
          minWidth: window.innerWidth < 1366 ? "95px" : "90px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "myAlertDeActivated",
          headerText: "Deactivated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "LinkedIn Address",
      columns: [
        {
          field: "linkedinAvailable",
          headerText: "Available",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "95px" : "90px",
          minWidth: window.innerWidth < 1366 ? "95px" : "90px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "linkedinNotAvailable",
          headerText: "Not Available",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "120px" : "115px",
          minWidth: window.innerWidth < 1366 ? "120px" : "115px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Roundups (Mobility)",
      columns: [
        {
          field: "mobilityRoundupActivated",
          headerText: "Activated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "95px" : "90px",
          minWidth: window.innerWidth < 1366 ? "95px" : "90px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "mobilityRoundupEmail",
          headerText: "Email",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "80px" : "75px",
          minWidth: window.innerWidth < 1366 ? "80px" : "75px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "mobilityRoundupWhatsApp",
          headerText: "WhatsApp",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",

          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "mobilityRoundupDeactivated",
          headerText: "Deactivated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Roundups (Energy)",
      columns: [
        {
          field: "energyRoundupActivated",
          headerText: "Activated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "95px" : "90px",
          minWidth: window.innerWidth < 1366 ? "95px" : "90px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "energyRoundupEmail",
          headerText: "Email",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "80px" : "75px",
          minWidth: window.innerWidth < 1366 ? "80px" : "75px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "energyRoundupWhatsApp",
          headerText: "WhatsApp",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "energyRoundupDeactivated",
          headerText: "Deactivated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Roundups (Food and Nutrition)",
      columns: [
        {
          field: "foodAndNutritionRoundupActivated",
          headerText: "Activated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "95px" : "90px",
          minWidth: window.innerWidth < 1366 ? "95px" : "90px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "foodAndNutritionRoundupEmail",
          headerText: "Email",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "80px" : "75px",
          minWidth: window.innerWidth < 1366 ? "80px" : "75px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "foodAndNutritionRoundupWhatsApp",
          headerText: "WhatsApp",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "foodAndNutritionRoundupDeactivated",
          headerText: "Deactivated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Roundups (Personal Care)",
      columns: [
        {
          field: "personalCareRoundupActivated",
          headerText: "Activated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "95px" : "90px",
          minWidth: window.innerWidth < 1366 ? "95px" : "90px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "personalCareRoundupEmail",
          headerText: "Email",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "80px" : "75px",
          minWidth: window.innerWidth < 1366 ? "80px" : "75px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "personalCareRoundupWhatsApp",
          headerText: "WhatsApp",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "personalCareRoundupDeactivated",
          headerText: "Deactivated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Roundups (Industrial)",
      columns: [
        {
          field: "industrialRoundupActivated",
          headerText: "Activated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "95px" : "90px",
          minWidth: window.innerWidth < 1366 ? "95px" : "90px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "industrialRoundupEmail",
          headerText: "Email",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "80px" : "75px",
          minWidth: window.innerWidth < 1366 ? "80px" : "75px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "industrialRoundupWhatsApp",
          headerText: "WhatsApp",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
        {
          field: "industrialRoundupDeactivated",
          headerText: "Deactivated",
          type: "Number",
          textAlign: "Center",
          headerTextAlign: "Left",
          width: window.innerWidth < 1366 ? "105px" : "100px",
          minWidth: window.innerWidth < 1366 ? "105px" : "100px",
          template: recordsTemplate,
          allowSorting: false,
        },
      ],
    },
  ];

  const fetchUserDetailsReport = async (reportDateRange, params = {}) => {
    try {
      showLoaderGrid();
      let filters = [];
      const dateFilter = dateRange === null ? reportDateRange : dateRange;

      if (dateFilter) {
        const [startDate, endDate] = dateFilter;

        const gte = { d: moment(startDate).toISOString() };
        const lt = { d: moment(endDate).add(1, "day").toISOString() };

        filters.push(["subscriptionStartDate", "gte", gte.d]);
        filters.push(["subscriptionStartDate", "lt", lt.d]);
      }
      if (formik.values.reportGroup && formik.values.reportGroup !== "All") {
        filters.push(["subscription_btb_company_name", "eq", formik.values.reportGroup]);
      }
      if (formik.values.reportAccountStatus && formik.values.reportAccountStatus !== "All") {
        filters.push(["subscriptionUserIsActive", "eq", formik.values.reportAccountStatus]);
      }
      if (formik.values.reportIndustry && formik.values.reportIndustry !== "All") {
        filters.push(["industryName", "eq", formik.values.reportIndustry]);
      }
      if (formik.values.reportIndustry && formik.values.reportIndustry == "All") {
        let userIndustries = industrySource.filter((item) => item.label !== "All").map((item) => item.label);
        userIndustries.length == 0 ? filters.push(["industryName", "eq", "null"]) : filters.push(["industryName", "eq", userIndustries]);
      }
      params.filters = [...filters];
      let data = await fetchclientDetailsReport({ ...params });
      setRowData(data.data.data ?? []);
      hideLoaderGrid();
      return data;
    } catch (e) {
      console.error(e);
      hideLoaderGrid();
    }
  };

  const handleReset = () => {
    window.location.reload();
  };

  const groupsDataSource = useMemo(() => {
    let groupData = groupDataSource?.map((group) => {
      return { label: group.groupName, value: group.groupName };
    });
    groupData.unshift({ label: "All", value: "All" });
    return groupData;
  }, [groupDataSource]);
  const accountStatusDataSource = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Active",
      value: "YES",
    },
    {
      label: "Inactive",
      value: "NO",
    },
  ];

  const fetchGroups = useCallback(async () => {
    try {
      showLoaderGrid();
      const response = await fetchGroupList();
      setGoupDataSource(response.data.data);
      let params = {
        fields: ["id", "trendName"],
        filters: [["trendParentId", "eq", 0]],
        sort: "trendName:asc",
      };
      const result = await fetchTaxonomyTrend(params);
      // let productIndustryIds = result.data.data.map((item) => item.id);
      setIndustryArray(result.data.data);
    } catch (error) {
      hideLoaderGrid();
      console.error("Error fetching data:", error);
    }
  }, []);

  const getGridRef = (gridRefData) => {
    setGridRef(gridRefData);
  };

  // const columnChooserOpenedHandler = () => {
  //   let data = gridRef.current.columnChooserModule.parent.grid.columnChooserModule.dlgDiv;
  //   const element = data.querySelector(".e-ccul-ele");
  //   const createAndInsertHeader = (text, beforeElement) => {
  //     const elementHtml = document.createElement("span");
  //     elementHtml.style.fontSize = "12px";
  //     elementHtml.style.fontWeight = "400";
  //     elementHtml.textContent = text;
  //     element.insertBefore(elementHtml, beforeElement);
  //   };

  //   createAndInsertHeader("Industry", element.childNodes[1]);
  //   createAndInsertHeader("User Count", element.childNodes[6]);
  //   createAndInsertHeader("User Added Period", element.childNodes[10]);
  //   createAndInsertHeader("Contact", element.childNodes[13]);
  //   createAndInsertHeader("Alert", element.childNodes[16]);
  //   createAndInsertHeader("LinkedIn", element.childNodes[19]);
  //   createAndInsertHeader("Roundups (Mobility)", element.childNodes[22]);
  //   createAndInsertHeader("Roundups (Energy)", element.childNodes[27]);
  //   createAndInsertHeader("Roundups (food & Nutrition)", element.childNodes[32]);
  //   createAndInsertHeader("Personal Care", element.childNodes[37]);
  // };

  const handleCreated = () => {
    try {
      showLoaderGrid();
      if (gridRef?.current) {
        gridRef.current.grid.columnChooserSettings.operator = "contains";
        const resultArray = [
          "mobilityRoundupActivated",
          "mobilityRoundupEmail",
          "mobilityRoundupWhatsApp",
          "mobilityRoundupDeactivated",
          "energyRoundupActivated",
          "energyRoundupEmail",
          "energyRoundupWhatsApp",
          "energyRoundupDeactivated",
          "foodAndNutritionRoundupActivated",
          "foodAndNutritionRoundupEmail",
          "foodAndNutritionRoundupWhatsApp",
          "foodAndNutritionRoundupDeactivated",
          "personalCareRoundupActivated",
          "personalCareRoundupEmail",
          "personalCareRoundupWhatsApp",
          "personalCareRoundupDeactivated",
          "industrialRoundupActivated",
          "industrialRoundupEmail",
          "industrialRoundupWhatsApp",
          "industrialRoundupDeactivated",
        ];
        gridRef.current.grid.hideColumns(resultArray, "field");
        const userIndustryAllocated = new Set([...industrySource.map((industry) => industry.value)]);
        gridRef.current.columns = columnFields.filter((column) => {
          if (column.headerText === "Industry") {
            column.columns = column.columns?.filter(
              (childColumn) =>
                (userIndustryAllocated.has("Mobility") && childColumn.headerText === "Mobility") ||
                (userIndustryAllocated.has("Energy") && childColumn.headerText === "Energy") ||
                (userIndustryAllocated.has("Food & Nutrition") && childColumn.headerText === "Food and Nutrition") ||
                (userIndustryAllocated.has("Personal Care") && childColumn.headerText === "Personal Care") ||
                (userIndustryAllocated.has("Industrial") && childColumn.headerText === "Industrial")
            );
          }
          return !(
            (!userIndustryAllocated.has("Mobility") && column.headerText === "Roundups (Mobility)") ||
            (!userIndustryAllocated.has("Energy") && column.headerText === "Roundups (Energy)") ||
            (!userIndustryAllocated.has("Food & Nutrition") && column.headerText === "Roundups (Food and Nutrition)") ||
            (!userIndustryAllocated.has("Personal Care") && column.headerText === "Roundups (Personal Care)") ||
            (!userIndustryAllocated.has("Industrial") && column.headerText === "Roundups (Industrial)")
          );
        });
      }
    } catch (error) {
      hideLoaderGrid();
      console.log("Error", error);
    }
  };

  const searchHandler = async () => {
    try {
      showLoaderGrid();
      if (gridRef?.current) {
        const userIndustryAllocated = new Set([...industrySource.map((industry) => industry.value)]);
        const roundupSets = {
          Mobility: new Set([
            "Energy",
            "Roundups (Energy)",
            "Food and Nutrition",
            "Personal Care",
            "Industrial",
            "Roundups (Food and Nutrition)",
            "Roundups (Personal Care)",
            "Roundups (Industrial)",
          ]),
          Energy: new Set([
            "Mobility",
            "Food and Nutrition",
            "Industrial",
            "Personal Care",
            "Roundups (Mobility)",
            "Roundups (Food and Nutrition)",
            "Roundups (Personal Care)",
            "Roundups (Industrial)",
          ]),
          "Food & Nutrition": new Set([
            "Mobility",
            "Energy",
            "Industrial",
            "Personal Care",
            "Roundups (Mobility)",
            "Roundups (Energy)",
            "Roundups (Personal Care)",
            "Roundups (Industrial)",
          ]),
          "Personal Care": new Set([
            "Mobility",
            "Energy",
            "Food and Nutrition",
            "Industrial",
            "Roundups (Energy)",
            "Roundups (Mobility)",
            "Roundups (Food and Nutrition)",
            "Roundups (Industrial)",
          ]),
          Industrial: new Set([
            "Mobility",
            "Energy",
            "Food and Nutrition",
            "Personal Care",
            "Roundups (Energy)",
            "Roundups (Mobility)",
            "Roundups (Food and Nutrition)",
            "Roundups (Personal Care)",
          ]),
        };

        const selectedIndustry = formik.values.reportIndustry;
        const roundupSet = roundupSets[selectedIndustry] || new Set();

        gridRef.current.columns =
          selectedIndustry === "All"
            ? columnFields.filter((column) => {
                if (column.headerText === "Industry") {
                  column.columns = column.columns?.filter(
                    (childColumn) =>
                      (userIndustryAllocated.has("Mobility") && childColumn.headerText === "Mobility") ||
                      (userIndustryAllocated.has("Energy") && childColumn.headerText === "Energy") ||
                      (userIndustryAllocated.has("Food & Nutrition") && childColumn.headerText === "Food and Nutrition") ||
                      (userIndustryAllocated.has("Personal Care") && childColumn.headerText === "Personal Care") ||
                      (userIndustryAllocated.has("Industrial") && childColumn.headerText === "Industrial")
                  );
                }
                return !(
                  (!userIndustryAllocated.has("Mobility") && column.headerText === "Roundups (Mobility)") ||
                  (!userIndustryAllocated.has("Energy") && column.headerText === "Roundups (Energy)") ||
                  (!userIndustryAllocated.has("Food & Nutrition") && column.headerText === "Roundups (Food and Nutrition)") ||
                  (!userIndustryAllocated.has("Personal Care") && column.headerText === "Roundups (Personal Care)") ||
                  (!userIndustryAllocated.has("Industrial") && column.headerText === "Roundups (Industrial)")
                );
              })
            : columnFields
                .map((field) => {
                  const hasRoundup = roundupSet.has(field.headerText);
                  const newField = { ...field };
                  if (field?.columns) {
                    newField.columns = field.columns.filter((childColumn) => !roundupSet.has(childColumn.headerText)) || [];
                  }
                  return !hasRoundup ? newField : null;
                })
                .filter(Boolean);
      }
      // hideLoaderGrid();
      await fetchUserDetailsReport();
    } catch (error) {
      hideLoaderGrid();
      console.log("Error", error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchGroups();
    })();
  }, []);

  useEffect(() => {
    if (industryArray.length == 0) return;
    (async () => {
      const reportDateRange = fnStartDate();
      await fetchUserDetailsReport(reportDateRange);
      hideLoaderGrid();
    })();
  }, [industrySource]);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  return (
    <div className="px-3 grid-bg-white">
      <div className="col-md-12 rounded-bottom nopadding p-0 bg-white">
        <div className="row">
          <div className="col-md-2 mt-3 grid-container-report-filters pr-0 responsive-report-filters">
            <DateRangePickerComponent
              placeholder="Date Range"
              cssClass="e-outline user-details-datepicker"
              openOnFocus={true}
              showClearButton={false}
              format="dd MMM yyyy"
              separator="to"
              floatLabelType="Auto"
              startDate={startDate}
              endDate={endDate}
              onChange={(e) => {
                if (e.value) setDateRange(e.value);
              }}
              autoComplete="off"
              max={new Date()}
              strictMode={true}
              presets={presets}
            />
          </div>
          <div className="col-md-2 mt-3 pr-0">
            <Dropdown
              formik={formik}
              placeholder="Group"
              required="required"
              id="reportGroup"
              name="reportGroup"
              fields={{ text: "label", value: "value" }}
              value={formik.values.reportGroup}
              dataSource={groupsDataSource}
            />
          </div>
          <div className="col-md-2 mt-3 pr-0">
            <Dropdown
              formik={formik}
              placeholder="Account Status"
              required="required"
              id="reportAccountStatus"
              name="reportAccountStatus"
              fields={{ text: "label", value: "value" }}
              value={formik.values.reportAccountStatus}
              dataSource={accountStatusDataSource}
            />
          </div>
          <div className="col-md-2 mt-3 pr-0">
            <Dropdown
              formik={formik}
              placeholder="Industry"
              required="required"
              id="reportIndustry"
              name="reportIndustry"
              fields={{ text: "label", value: "value" }}
              value={formik.values.reportIndustry}
              dataSource={industrySource}
            />
          </div>
          <div className="col-md-3 mt-3">
            <button type="button" onClick={searchHandler} className="search-btn px-4 btn btn-primary mr-2">
              Search
            </button>
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 ml-2 ">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="gennx-grid-client-detail-report user-detail-report-grid">
        {industryArray.length > 0 && (
          <TreeDataGridNew
            gridTitle="Client Activity Report"
            rowData={rowData}
            getGridRef={getGridRef}
            isPaging={false}
            showResetFilter={true}
            deleteMany={false}
            allowPagination={true}
            showExportButton={true}
            exportFileName={"User Detail Report"}
            allowResizing={true}
            created={handleCreated}
          >
            <ColumnsDirective>
              {columnFields.map((column, index) => (
                <ColumnDirective key={index} {...column} />
              ))}
            </ColumnsDirective>
          </TreeDataGridNew>
        )}
      </div>
      {showSidePanel && (
        <CustomSidebar sidebarRef={sidebarRef} isOpen={showSidePanel}>
          <SiderBarGrid onclose={toggleSidebar} fetch={fetchUsers} />
        </CustomSidebar>
      )}
    </div>
  );
};

export default DataReport;
