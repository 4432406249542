/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Footer bar for objective Add | Edit Page
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 21/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataPublishingAction from "../../../../dataPublishing/news/actionButton";
import { accessRightActionCheck } from "../../../../../../utilities";
import { publishObjective } from "../../../../../../middleware/services/businessObjectiveApi";
import { actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import { useDispatch } from "react-redux";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const FooterBar = (props) => {
  const dispatch = useDispatch();

  const objectiveId = props.objectiveId || "";
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = Object.values(props?.hashArr)[props?.selectedIndex];
    let actionAccess = accessRightActionCheck(props?.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [props?.selectedIndex]);

  let interfaceAccessSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  let interfaceAccessPublish = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.PUBLISH);

  return (
    <div className="form-submit-box">
      <div className="row">
        <div className="container-fluid nopadding">
          <div className="float-left col-md-4 text-left">
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="pl-4" style={{ marginLeft: "-2.5em" }}>
                <span className="span-link">
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                    onClick={() => {
                      (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                        (props.selectedIndex < 3 && props.setCurrentTab(props.selectedIndex - 1));
                    }}
                  >
                    Previous
                  </button>
                </span>
              </span>
            ) : null}

            {objectiveId !== "" && props.selectedIndex > -1 && props.selectedIndex < 2 ? (
              <span className="pl-4" style={{ marginLeft: props.selectedIndex === 0 ? "-2.3em" : "0" }}>
                <span className="span-link">
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                    onClick={() => {
                      props.selectedIndex < 2 && props.setCurrentTab(props.selectedIndex + 1);
                    }}
                  >
                    Next
                  </button>
                </span>
              </span>
            ) : null}
          </div>

          <div className="float-right col-md-6 text-right " style={{ marginRight: "-10px" }}>
            <Link to="/business-objective/customer">
              <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 ">
                Cancel
              </button>
            </Link>
            {interfaceAccessPublish ? <DataPublishingAction objectiveId={props.objectiveId} {...props} /> : null}
            {interfaceAccessSave && props.activeStepper === "#basic" ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 ml-3"
                id="dev-objective-save"
                onClick={() => {
                  props.handleSave(true);
                }}
              >
                Save
              </button>
            ) : null}
            {interfaceAccessSave && props.activeStepper === "#content" ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 ml-3"
                id="dev-objective-save"
                onClick={() => {
                  props.handleSaveContent(true);
                }}
              >
                Save
              </button>
            ) : null}
            {interfaceAccessPublish && props.objectiveId && props.activeStepper === "#basic" ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 ml-3"
                id="dev-objective-save"
                onClick={() => {
                  dispatch(
                    showAlertBox({
                      okCallback: async () => {
                        await publishObjective(props.objectiveId)
                          .then((res) => {
                            if (res) {
                              dispatch(actionSuccess("Business Objective Published Successfully"));
                            } else {
                              dispatch(actionError("Something went wrong"));
                            }
                          })
                          .catch((err) => {
                            dispatch(actionError(err?.data?.message || "Something went wrong"));
                          });
                      },
                      content: " Are you sure you want to publish?",
                      okText: "Publish",
                      cancelText: "Cancel",
                      title: "dialogAlertCss",
                    })
                  );
                }}
              >
                Publish
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBar;
