/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : User Routes (Client, External, Internal, Vendor)
----------------------------------------------------------------
	Creation Details
	Date Created				: 30/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import ManageDeliverables from "../app/views/deliverables/manageDeliverables";
import AddDeliverable from "../app/views/deliverables/manageDeliverables/add";
import AddTask from "../app/views/deliverables/manageDeliverables/component/task/taskDetails";
import Task from "../app/views/deliverables/manageDeliverables/component/task/index";
import Preview from "../app/views/deliverables/manageDeliverables/component/preview/index";

const PROFILE = ["Profile:VIEW", "Profile:FULL"];
const TAXONOMY = ["Taxonomy:VIEW", "Taxonomy:FULL"];
const TASKS = ["Tasks:VIEW", "Tasks:FULL"];
const CONTENT = ["Design:VIEW", "Design:FULL"];

const deliverableRoutes = () => (
  <Suspense fallback={<div className="text-center">Loading...</div>}>
    <Switch>
      <AuthLayout
        exact
        path="/deliverable"
        component={ManageDeliverables}
        pageHeading="GenNx CMS - Deliverables"
        accessRights={{
          moduleName: "Deliverables",
          parentModuleName: "Deliverables",
          moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY, ...TASKS],
          interfaceName: "Listing",
        }}
      />
      <AuthLayout
        exact
        path="/deliverable/add"
        component={AddDeliverable}
        pageHeading="GenNx CMS - Deliverables"
        accessRights={{
          moduleName: "Deliverables",
          parentModuleName: "Deliverables",
          moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY, ...TASKS],
          interfaceName: "Basic",
        }}
      />
      <AuthLayout
        exact
        path="/deliverable/:deliverableId/edit"
        component={AddDeliverable}
        pageHeading="GenNx CMS - Deliverables"
        accessRights={{
          moduleName: "Deliverables",
          parentModuleName: "Deliverables",
          moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY, ...TASKS],
          interfaceName: "Basic",
        }}
      />
      <AuthLayout
        exact
        path="/deliverable/:deliverableId/page/:pageId"
        component={AddDeliverable}
        pageHeading="GenNx CMS - Deliverables"
        accessRights={{
          moduleName: "Deliverables",
          parentModuleName: "Deliverables",
          moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY, ...TASKS],
          interfaceName: "Basic",
        }}
      />
      <AuthLayout
        exact
        path="/deliverable/:deliverableId/page/:pageId/:templateId"
        component={AddDeliverable}
        pageHeading="GenNx CMS - Deliverables"
        accessRights={{
          moduleName: "Deliverables",
          parentModuleName: "Deliverables",
          moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY, ...TASKS],
          interfaceName: "Basic",
        }}
      />
      <AuthLayout
        exact
        path="/deliverable/:deliverableId/task/:taskId/edit"
        component={AddTask}
        pageHeading="GenNx CMS - Deliverables"
        accessRights={{
          moduleName: "Deliverables",
          parentModuleName: "Deliverables",
          moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY, ...TASKS],
          interfaceName: "Task",
        }}
      />
      <AuthLayout
        exact
        path="/deliverable/:deliverableId/task/add"
        component={AddTask}
        pageHeading="GenNx CMS - Deliverables"
        accessRights={{
          moduleName: "Deliverables",
          parentModuleName: "Deliverables",
          moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY, ...TASKS],
          interfaceName: "Task",
        }}
      />
      <AuthLayout
        exact
        path="/deliverable/:deliverableId/task"
        component={Task}
        pageHeading="GenNx CMS -  Deliverables"
        accessRights={{
          moduleName: "Deliverables",
          parentModuleName: "",
          moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY, ...TASKS],
          interfaceName: "Task",
        }}
      />
      <AuthLayout
        isNewWindow={true}
        exact
        path="/deliverable/:deliverableId/preview"
        component={Preview}
        pageHeading="GenNx CMS -  Preview"
        accessRights={{
          moduleName: "Deliverables",
          parentModuleName: "",
          moduleAttribute: [...PROFILE, ...CONTENT, ...TAXONOMY, ...TASKS],
          interfaceName: "Basic",
        }}
      />
    </Switch>
  </Suspense>
);

export default deliverableRoutes;
