import { UPLOAD_DATA_URL } from "../../config";
import { UPLOAD_DATA_API as API } from "../../config/apiConfig";

// Fetch all logs
export const fetchAllLogs = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/data-upload-logs",
    method: "GET",
    params: params,
  });
};

// Post call
export const saveIndustry = (payload) => {
  return API({
    url: `/utilities/data`,
    method: "POST",
    data: payload,
  });
};

export const fetchTemplateFileUrl = () => {
  let token = localStorage.getItem("token");
  let headers = new Headers();

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  return {
    url: `${UPLOAD_DATA_URL}/storage/upload_data_template.xlsx`,
    headers: headers,
  };
};
