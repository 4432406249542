/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News Tagging Component 
     Summary : - Parent news tagging component 
               - Inherit taxonomy and trend component as a child 
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React from "react";
import Taxonomy from "./taxonomy";
import Trend from "./trend";
import Media from "./media";
import { getProductVariant } from "../../../../../utilities";
import { PRODUCT_VARIANT } from "../../../../../constants";

const Tagging = (props) => {
  const newsId = props.newsId;
  const loadOtherTagInfo = (info) => {
    console.log(info);
    // setLoadTaggingInfo(info)
  };
  if (newsId == "") {
    return null;
  }
  const productVariant = getProductVariant();
  if (!productVariant || productVariant == "") return null;
  return (
    <div className="gennx-content-wrapper" style={{ overflowY: "scroll", width: "100%" }}>
      {productVariant === PRODUCT_VARIANT.INSIDER && <Trend {...props} loadOtherTagInfo={loadOtherTagInfo} />}
      {productVariant === PRODUCT_VARIANT.WHATNEXT && <Taxonomy {...props} loadOtherTagInfo={loadOtherTagInfo} />}
      {productVariant === PRODUCT_VARIANT.COGNITION && <Media {...props} loadOtherTagInfo={loadOtherTagInfo} />}
    </div>
  );
};
export default Tagging;
