/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Dynamic & Modular Pagination Component
----------------------------------------------------------------
	Creation Details
	Date Created				: 07/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import "./index.scss";
import React from "react";
import ReactPaginate from 'react-paginate';
import PropTypes from "prop-types";
import { PAGINATION_PAGE_RANGE } from "../../../config";
import { useSelector } from "react-redux";
const GridPagination = (props) => {
	const {
		onChange, activePage,
		pageRange = PAGINATION_PAGE_RANGE || 5, className = "paginate-div",
	} = props;
	return (
		<div className={` d-inline-block ${className} `}>
			<ReactPaginate
				nextLabel="Next"
				onPageChange={onChange}
				pageRangeDisplayed={3}
				marginPagesDisplayed={1}
				pageCount={pageRange}
				previousLabel="Previous"
				pageClassName="page-item"
				pageLinkClassName="page-link"
				previousClassName="page-item"
				previousLinkClassName="page-link"
				nextClassName="page-item"
				nextLinkClassName="page-link"
				breakLabel="..."
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName="pagination"
				activeClassName="active"
				renderOnZeroPageCount={null}
				// initialPage={initialPage-1}
				forcePage={activePage - 1}
			/>

		</div>
	);
};

export default GridPagination;

GridPagination.propTypes = {
	activePage: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	pageRange: PropTypes.number,
	className: PropTypes.string,
};
