/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Popup Actions (show / hide)
----------------------------------------------------------------
	Creation Details
	Date Created				: 02/Dec/2020
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import React from "react";
import { SHOW_POPUP, HIDE_POPUP } from "../../constants/actionConstants";

const defaultCallback = () => {
	return;
};
const defaultMessage = () =>{
	return "You can put your content here. Content may be string or html."	
};

export const showPopup = ({
	width="268px",
	title = "Popup Title Here",
	okText = "Yes",
	okCallback = defaultCallback,
	showCancelButton=true,
	cancelText = "No",
	cancelCallback = defaultCallback,
	content = "You can put your content here. Content may be string or html.",
	children = <></>,
	data = undefined
}) => ({
	type: SHOW_POPUP,
	payload: { width,title, okText, okCallback,showCancelButton, cancelText, cancelCallback, content, data,children }
});

export const hidePopup = () => ({
	type: HIDE_POPUP,
});
