import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader } from "../../../../../middleware/actions/utilityAction";
import { getSingleQuestion } from "../../../../../middleware/services/videoApi";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addQuestionValidationSchema } from "../validationSchema";
import { TextField } from "../formFields";

const PopupModal = (props) => {
  const [saveBtnText, setSaveBtnText] = useState("Save");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [editData, setEditData] = useState(null);

  const initialFormValues = {
    question: "",
    answer: "",
  };

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(addQuestionValidationSchema),
  });

  const dispatch = useDispatch();

  const handleReset = () => {
    formik.resetForm();
  };
  const saveDocumentData = async () => {
    const { values } = formik;
    const questionData = {
      question: values.question,
      answer: values.answer,
    };

    const response = (await editData) ? props.onUpdateData(props.questionId, questionData) : props.onSaveData(questionData);
    setSaveBtnText("SAVING...");
    setBtnDisabled(true);
    response.then((result) => {
      setSaveBtnText("SAVE");
      setBtnDisabled(false);
      handleReset();
    });
  };
  const handleSubmitDocument = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        saveDocumentData();
      }
    });
  };

  const fetchSingleQuestion = () => {
    let params = {};
    let defaultFilter = [];
    let fields = ["question", "answer"];
    params.fields = fields;
    if (props.productVariant) defaultFilter.push(["productVariant.productName", "eq", props.productVariant]);
    params.filters = [...defaultFilter];
    getSingleQuestion(props.videoId, props.questionId, params).then((res) => {
      let data = res.data.data || {};
      setEditData(data);
      data &&
        formik.setValues({
          question: data.videoQuestion.question,
          answer: data.videoQuestion.answer,
        });
      dispatch(hideLoader());
    });
  };
  useEffect(() => {
    props.questionId && fetchSingleQuestion();
  }, [props.questionId]);

  return (
    <Modal
      // size="lg"
      show={props.isOpen}
      onHide={() => {
        props.closeHandler();
      }}
      centered
    >
      <Modal.Header style={{ borderBottom: "none" }} className="">
        <Modal.Title>{props.popupTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <TextField formik={formik} placeholder="Question *" id="question" name="question" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <TextField
              formik={formik}
              multiline={true}
              numberCount={true}
              maxLength={1000}
              type="text"
              id="answer"
              placeholder="Answer *"
              name="answer"
            />
          </div>
        </div>

        <Modal.Footer style={{ borderTop: "none", "margin-right": "-16px" }}>
          <button type="button" onClick={() => props.closeHandler()} className="btn btn-outline-dark cy-btn mr-3">
            Cancel
          </button>
          <button type="button" onClick={handleSubmitDocument} disabled={btnDisabled} className="btn btn-primary cy-btn">
            {saveBtnText}
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default PopupModal;
PopupModal.propTypes = {
  videoId: PropTypes.string,
  onSaveData: PropTypes.func.isRequired,
  onUpdateData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  editData: PropTypes.object.isRequired,
};
