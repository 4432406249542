/* 
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Loader
----------------------------------------------------------------
	Creation Details
	Date Created				: 12/dec/2022
	Author						: Sarthak Jaiswal
================================================================
*/

import React from "react";


const Loader=()=> {
  return <div className="text-center"><h3>Loading...</h3></div>
}

export default Loader;
