/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Client User Listing
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
 ---------------------------------------------------------------
	Change Details
	Modified Date				: 26/Oct/2020
	Author						: Anand Kumar
	Description					: While fetching user instead of fetching it by current logged in user id, fetch it by relationship manager email 
================================================================
*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionStart, actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { swap, getLoggedInUser } from "../../../../utilities";
import CommonDataGrid from "../../../components/dataGrid";
import { StringFilter, BooleanFilter } from "../../../components/dataGrid/fliters";
import { fetchClientUser, deleteUser } from "../../../../middleware/services/userApi";
import ThreeDotMenu from "../../../components/threeDotMenu";
import { setMaintainedState, visitRecord } from "../../../../middleware/actions/gridAction";
import { resetRedux } from "../../../../middleware/actions/taxonomyFilterAction";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

// specify column names for column fields in datGrid here
const columnNames = {
  id: "id",
  Email: "userEmail",
  "First Name": "userFname",
  "Last Name": "userLname",
  Status: "userActive",
};

const userActiveTemplate = (value) => {
  return <span className="ml-n2">{value.Status ? <span>Active </span> : <span>Inactive</span>}</span>;
};

/**
 * @description formatRowData function required to format rowData for datGrid
 * @param {Array} rowData
 */
const formatRowData = (rowData) => {
  let formatedRowData = [];
  const columnNamesValues = swap(columnNames);
  formatedRowData = rowData.map((rD) => {
    const newRD = { Id: rD.id };
    Object.keys(rD).forEach((rDK) => {
      if (columnNamesValues[rDK]) {
        newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
      }
    });
    return newRD;
  });
  return formatedRowData;
};

// specify breadcrumb here

// user dashboard component
const UserDashBoard = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteId, setDeleteId] = useState(undefined);
  const gridState = useSelector((state) => state.gridState);

  /* Common access */
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionInactive = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.INACTIVE);
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);

  const breadCrumbLinks = [{ linkUrl: "/client-user", linkName: aR.moduleName, linkActive: true }];

  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const deleteSingleUser = (data) => {
    dispatch(actionStart());
    deleteUser(data.Id)
      .then((res) => {
        dispatch(actionSuccess("User status changed to inactive"));
        setDeleteId(data.Id);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "User status changed failed";
        dispatch(actionError(errMsg));
      });
  };
  const userNameTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(`/client-user/${value.id}/edit#profile`, { userId: value.id });
          dispatch(visitRecord());
        }}
      >
        {value["First Name"]}
      </span>
    );
  };

  /* 
		@Description : Template Component for action column
	*/
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                history.push(`/client-user/${value.id}/edit#profile`, { userId: value.id });
                dispatch(visitRecord());
              },
            }
          : {})}
        {...(value.Status && accessActionInactive
          ? {
              Inactive: () => {
                dispatch(
                  showAlertBox({
                    okCallback: deleteSingleUser,
                    data: value,
                    content: "Are you sure you want to mark user as Inactive?",
                    okText: "Inactive",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
      />
    );
  };
  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean
  const columnFields = [
    { field: "First Name", type: "String", template: userNameTemplate, filterTemplate: StringFilter, showInColumnChooser: false, textAlign: "Left" },
    { field: "Last Name", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left" },
    { field: "Email", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left" },
    { field: "Status", type: "Boolean", template: userActiveTemplate, filterTemplate: BooleanFilter, width: 140, textAlign: "Center" },
  ];
  if (accessActionEdit || accessActionInactive) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false, textAlign: "Center" });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  const fetchClientUserWithProductVariant = (params = {}) => {
    let defaultFilter = [];
    const loggedInUser = getLoggedInUser();
    if (loggedInUser?.user_role && loggedInUser?.user_role === "client_admin")
      defaultFilter.push(["userType", "eq", "Client"], ["userBtb~userRelationshipManager.email", "eq", loggedInUser.user_email]);
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];

    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchClientUser(params);
    }
    return fetchClientUser(params);
  };

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => dispatch(setBreadCrumb());
  }, [dispatch]);

  // Reset Redux for filter by taxonomy
  useEffect(() => {
    if (window.location.pathname == `/client-user`) {
      dispatch(resetRedux());
    }
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        <CommonDataGrid
          gridTitle="User Management List"
          fetch={fetchClientUserWithProductVariant}
          deleteOne={deleteUser}
          deleteMany={false}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          addNewRight={accessActionListingAddNew}
          addNew={() => history.push(`/client-user/add#profile`)}
          clearFilterByTaxonomy={true}
        />
      </div>
    </div>
  );
};

export default UserDashBoard;
