/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News Overview Validation - Add | Edit Overview
 ---------------------------------------------------------------------------------
    Creation Details
    Date Created				: 20/Aug/2020
    Author						: Aditya Tijare
================================================================
*/
import { DELIVERABLE_TYPE, CONTENT_TYPE } from "../../../../../../constants";
import * as Yup from "yup";

export const deliverableOverviewValidationSchema = {
  deliverableProductPlatform: Yup.string().required("Please select application platform."),
  deliverableContentType: Yup.string().when("deliverableProductPlatform", {
    is: (obj) => {
      return obj && (obj === "Insider V2" || obj === "Insider V1" || obj === "Insider V1,Insider V2" || obj === "Insider V2,Insider V1");
    },
    then: Yup.string().required("Please select deliverable content type"),
    otherwise: Yup.string().nullable(),
  }),
  deliverableType: Yup.string().required("Please select deliverable type"),
  deliverableName: Yup.string()
    .required("Please enter deliverable name")
    .max(125, "Deliverable name must be at most 125 characters")
    .matches(/[^\s]([a-zA-Z])/, "Please enter valid name"),
  // deliverableEstStartDate: Yup.date().min(new Date(Date.now() - 86400000), "Estimated Start Date cannot be in the past").nullable(),
  deliverableEstStartDate: Yup.date().nullable(),
  // deliverableEstEndDate: Yup.date().
  //     when("deliverableEstStartDate",
  //         (deliverableEstStartDate, yup) => deliverableEstStartDate && yup.min(deliverableEstStartDate, "Estimated End Date cannot be before Estimated Start Date"))
  //     .nullable(),

  deliverableEstEndDate: Yup.date()
    .min(Yup.ref("deliverableEstStartDate"), () => `End date cannot be before start date`)
    .nullable(),
  deliverableActStartDate: Yup.date()
    .min(Yup.ref("deliverableEstStartDate"), () => `Actual start date should be greater than estimated start date `)
    .nullable(),
  // deliverableActStartDate: Yup.date().when("deliverableEstStartDate",
  //     (deliverableEstStartDate, yup) => deliverableEstStartDate && yup.min(deliverableEstStartDate, "Actual Start Date should be greater than Estimated Start Date"))
  //     .nullable(),
  // deliverableActEndDate: Yup.date().when("deliverableActStartDate",
  //     (deliverableActStartDate, yup) => deliverableActStartDate && yup.min(deliverableActStartDate, "Actual End Date should be greater than Actual Start Date"))
  //     .nullable()
  // deliverableEstStartDate: Yup.date().min(new Date(Date.now() - 86400000), "Estimated Start Date cannot be in the past").nullable(),
  deliverableActEndDate: Yup.date()
    .min(Yup.ref("deliverableActStartDate"), () => `Actual end date should be greater than actual start date`)
    .nullable(),
  createReportFrom: Yup.object().test("create-report-validation", "Please choose an option", function (value) {
    const { deliverableProductPlatform, deliverableContentType, deliverableType, deliverableDocuments } = this.parent;
    if (
      (deliverableProductPlatform === "Insider V2" &&
        deliverableContentType !== CONTENT_TYPE.DASHBOARD &&
        deliverableType === DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE &&
        !deliverableDocuments) ||
      (deliverableProductPlatform === "Insider V2" &&
        deliverableContentType !== CONTENT_TYPE.DASHBOARD &&
        deliverableType !== DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE)
    ) {
      if (!value) {
        return this.createError({ path: this.path, message: "Please choose an option" });
      }
    }
    return true;
  }),

  existingTemplate: Yup.string().when("createReportFrom", {
    is: (obj) => {
      return obj && obj.value === "existing template";
    },
    then: Yup.string().required("Please select deliverable template"),
    otherwise: Yup.string().nullable(),
  }),

  deliverableSummary: Yup.string()
    .required("Please enter deliverable summary")
    .max(200, "Please enter deliverable summary of maximum 200 characters")
    .typeError("Please enter deliverable summary of maximum 200 characters."),

  deliverableDocuments: Yup.string().test("deliverable-documents-validation", "Please upload a file.", function (value) {
    const { deliverableProductPlatform, deliverableType, createReportFrom, deliverableContentType } = this.parent;

    if (
      deliverableProductPlatform &&
      (deliverableProductPlatform === "WhatNext V1" ||
        deliverableProductPlatform === "Insider V1" ||
        deliverableProductPlatform === "Insider V1,Insider V2" ||
        deliverableProductPlatform === "Insider V2,Insider V1" ||
        (deliverableProductPlatform === "Insider V2" &&
          deliverableContentType !== CONTENT_TYPE.DASHBOARD &&
          deliverableType === DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE &&
          createReportFrom?.label === "Choose a template option"))
    ) {
      if (!value) {
        return this.createError({ path: this.path, message: "Please upload a file." });
      }
    }
    return true;
  }),

  pdfText: Yup.string().when("exportAsPdf", {
    is: true,
    then: Yup.string().required("Please enter the watermark text.").trim().max(100, "Please enter maximum 100 characters."),
    otherwise: Yup.string().nullable(),
  }),

  pdfFontFamily: Yup.string().when("exportAsPdf", {
    is: true,
    then: Yup.string().required("Please select font family.").typeError("Please select font family."),
    otherwise: Yup.string().nullable(),
  }),

  pdfFontSize: Yup.number().when("exportAsPdf", {
    is: true,
    then: Yup.number().required("Please enter font size.").typeError("Please enter font size."),
    otherwise: Yup.number().nullable(),
  }),

  pdfColor: Yup.string().when("exportAsPdf", {
    is: true,
    then: Yup.string().required("Please select a color.").typeError("Please select a color."),
    otherwise: Yup.string().nullable(),
  }),

  pdfOpacity: Yup.number().when("exportAsPdf", {
    is: true,
    then: Yup.number().required("Please enter watermark opacity.").typeError("Please enter watermark opacity."),
    otherwise: Yup.number().nullable(),
  }),

  pdfTopPosition: Yup.number().when("exportAsPdf", {
    is: true,
    then: Yup.number().required("Please enter watermark top position.").typeError("Please enter watermark top position."),
    otherwise: Yup.number().nullable(),
  }),

  pdfLeftPosition: Yup.number().when("exportAsPdf", {
    is: true,
    then: Yup.number().required("Please enter watermark left position.").typeError("Please enter watermark left position."),
    otherwise: Yup.number().nullable(),
  }),

  pdfRotate: Yup.number().when("exportAsPdf", {
    is: true,
    then: Yup.number().required("Please select watermark angle.").typeError("Please select watermark angle."),
    otherwise: Yup.number().nullable(),
  }),

  excelText: Yup.string().when("exportAsExcel", {
    is: true,
    then: Yup.string().required("Please enter the watermark text.").max(100, "Please enter maximum 100 characters."),
    otherwise: Yup.string().nullable(),
  }),

  excelFontFamily: Yup.string().when("exportAsExcel", {
    is: true,
    then: Yup.string().required("Please select font family.").typeError("Please select font family."),
    otherwise: Yup.string().nullable(),
  }),

  excelFontSize: Yup.number().when("exportAsExcel", {
    is: true,
    then: Yup.number().required("Please enter font size.").typeError("Please enter font size."),
    otherwise: Yup.number().nullable(),
  }),

  excelColor: Yup.string().when("exportAsExcel", {
    is: true,
    then: Yup.string().required("Please select a color.").typeError("Please select a color."),
    otherwise: Yup.string().nullable(),
  }),

  excelOpacity: Yup.number().when("exportAsExcel", {
    is: true,
    then: Yup.number().required("Please enter watermark opacity.").typeError("Please enter watermark opacity."),
    otherwise: Yup.number().nullable(),
  }),

  excelTopPosition: Yup.number().when("exportAsExcel", {
    is: true,
    then: Yup.number().required("Please enter watermark top position.").typeError("Please enter watermark top position."),
    otherwise: Yup.number().nullable(),
  }),

  excelLeftPosition: Yup.number().when("exportAsExcel", {
    is: true,
    then: Yup.number().required("Please enter watermark left position.").typeError("Please enter watermark left position."),
    otherwise: Yup.number().nullable(),
  }),

  excelRotate: Yup.number().when("exportAsExcel", {
    is: true,
    then: Yup.number().required("Please select watermark angle.").typeError("Please select watermark angle."),
    otherwise: Yup.number().nullable(),
  }),

  powerPointText: Yup.string().when("exportAsPpt", {
    is: true,
    then: Yup.string().required("Please enter the watermark text.").max(100, "Please enter maximum 100 characters."),
    otherwise: Yup.string().nullable(),
  }),

  powerPointFontFamily: Yup.string().when("exportAsPpt", {
    is: true,
    then: Yup.string().required("Please select font family.").typeError("Please select font family."),
    otherwise: Yup.string().nullable(),
  }),

  powerPointFontSize: Yup.number().when("exportAsPpt", {
    is: true,
    then: Yup.number().required("Please enter font size.").typeError("Please enter font size."),
    otherwise: Yup.number().nullable(),
  }),

  powerPointColor: Yup.string().when("exportAsPpt", {
    is: true,
    then: Yup.string().required("Please select a color.").typeError("Please select a color."),
    otherwise: Yup.string().nullable(),
  }),

  powerPointOpacity: Yup.number().when("exportAsPpt", {
    is: true,
    then: Yup.number().required("Please enter watermark opacity.").typeError("Please enter watermark opacity."),
    otherwise: Yup.number().nullable(),
  }),

  powerPointTopPosition: Yup.number().when("exportAsPpt", {
    is: true,
    then: Yup.number().required("Please enter watermark top position.").typeError("Please enter watermark top position."),
    otherwise: Yup.number().nullable(),
  }),

  powerPointLeftPosition: Yup.number().when("exportAsPpt", {
    is: true,
    then: Yup.number().required("Please enter watermark left position.").typeError("Please enter watermark left position."),
    otherwise: Yup.number().nullable(),
  }),

  powerPointRotate: Yup.number().when("exportAsPpt", {
    is: true,
    then: Yup.number().required("Please select watermark angle.").typeError("Please select watermark angle."),
    otherwise: Yup.number().nullable(),
  }),

  sectionText: Yup.string().when("exportASection", {
    is: true,
    then: Yup.string().required("Please enter the watermark text.").max(100, "Please enter maximum 100 characters."),
    otherwise: Yup.string().nullable(),
  }),

  sectionFontFamily: Yup.string().when("exportASection", {
    is: true,
    then: Yup.string().required("Please select font family.").typeError("Please select font family."),
    otherwise: Yup.string().nullable(),
  }),

  sectionFontSize: Yup.number().when("exportASection", {
    is: true,
    then: Yup.number().required("Please enter font size.").typeError("Please enter font size."),
    otherwise: Yup.number().nullable(),
  }),

  sectionColor: Yup.string().when("exportASection", {
    is: true,
    then: Yup.string().required("Please select a color.").typeError("Please select a color."),
    otherwise: Yup.string().nullable(),
  }),

  sectionOpacity: Yup.number().when("exportASection", {
    is: true,
    then: Yup.number().required("Please enter watermark opacity.").typeError("Please enter watermark opacity."),
    otherwise: Yup.number().nullable(),
  }),

  sectionTopPosition: Yup.number().when("exportASection", {
    is: true,
    then: Yup.number().required("Please enter watermark top position.").typeError("Please enter watermark top position."),
    otherwise: Yup.number().nullable(),
  }),

  sectionLeftPosition: Yup.number().when("exportASection", {
    is: true,
    then: Yup.number().required("Please enter watermark left position.").typeError("Please enter watermark left position."),
    otherwise: Yup.number().nullable(),
  }),

  sectionRotate: Yup.number().when("exportASection", {
    is: true,
    then: Yup.number().required("Please select watermark angle.").typeError("Please select watermark angle."),
    otherwise: Yup.number().nullable(),
  }),

  chartsText: Yup.string().when("exportAsChart", {
    is: true,
    then: Yup.string().required("Please enter the watermark text.").max(100, "Please enter maximum 100 characters."),
    otherwise: Yup.string().nullable(),
  }),

  chartsFontFamily: Yup.string().when("exportAsChart", {
    is: true,
    then: Yup.string().required("Please select font family.").typeError("Please select font family."),
    otherwise: Yup.string().nullable(),
  }),

  chartsFontSize: Yup.number().when("exportAsChart", {
    is: true,
    then: Yup.number().required("Please enter font size.").typeError("Please enter font size."),
    otherwise: Yup.number().nullable(),
  }),

  chartsColor: Yup.string().when("exportAsChart", {
    is: true,
    then: Yup.string().required("Please select a color.").typeError("Please select a color."),
    otherwise: Yup.string().nullable(),
  }),

  chartsOpacity: Yup.number().when("exportAsChart", {
    is: true,
    then: Yup.number().required("Please enter watermark opacity.").typeError("Please enter watermark opacity."),
    otherwise: Yup.number().nullable(),
  }),

  chartsTopPosition: Yup.number().when("exportAsChart", {
    is: true,
    then: Yup.number().required("Please enter watermark top position.").typeError("Please enter watermark top position."),
    otherwise: Yup.number().nullable(),
  }),

  chartsLeftPosition: Yup.number().when("exportAsChart", {
    is: true,
    then: Yup.number().required("Please enter watermark left position.").typeError("Please enter watermark left position."),
    otherwise: Yup.number().nullable(),
  }),

  chartsRotate: Yup.number().when("exportAsChart", {
    is: true,
    then: Yup.number().required("Please select watermark angle.").typeError("Please select watermark angle."),
    otherwise: Yup.number().nullable(),
  }),
  deliverablePublishedDate: Yup.string().required("Please select the publish date").nullable(),
  deliverableIsComingSoon: Yup.string().when(["deliverableProductPlatform", "deliverableContentType"], {
    is: (deliverableProductPlatform, deliverableContentType) => {
      return deliverableProductPlatform && deliverableProductPlatform === "Insider V2" && deliverableContentType === CONTENT_TYPE.DASHBOARD;
    },
    then: Yup.string().required("Please select an option for coming soon"),
    otherwise: Yup.string().nullable(),
  }),
  deliverablePowerbiReportId: Yup.string().when(["deliverableProductPlatform", "deliverableContentType", "deliverableIsComingSoon"], {
    is: (deliverableProductPlatform, deliverableContentType, deliverableIsComingSoon) => {
      return (
        deliverableProductPlatform &&
        deliverableProductPlatform === "Insider V2" &&
        deliverableContentType === CONTENT_TYPE.DASHBOARD &&
        deliverableIsComingSoon === "NO"
      );
    },
    then: Yup.string().required("Please upload power bi report"),
    otherwise: Yup.string().nullable(),
  }),
  deliverablePowerbiReportName: Yup.string().when(["deliverableProductPlatform", "deliverableContentType", "deliverableIsComingSoon"], {
    is: (deliverableProductPlatform, deliverableContentType, deliverableIsComingSoon) => {
      return (
        deliverableProductPlatform &&
        deliverableProductPlatform === "Insider V2" &&
        deliverableContentType === CONTENT_TYPE.DASHBOARD &&
        deliverableIsComingSoon === "NO"
      );
    },
    then: Yup.string().required("Please upload power bi report"),
    otherwise: Yup.string().nullable(),
  }),
  deliverablePowerbiOriginalReportName: Yup.string().when(["deliverableProductPlatform", "deliverableContentType", "deliverableIsComingSoon"], {
    is: (deliverableProductPlatform, deliverableContentType, deliverableIsComingSoon) => {
      return (
        deliverableProductPlatform &&
        deliverableProductPlatform === "Insider V2" &&
        deliverableContentType === CONTENT_TYPE.DASHBOARD &&
        deliverableIsComingSoon === "NO"
      );
    },
    then: Yup.string().required("Please upload power bi report"),
    otherwise: Yup.string().nullable(),
  }),
  deliverablePowerbiReportUploadedDate: Yup.string().when(["deliverableProductPlatform", "deliverableContentType", "deliverableIsComingSoon"], {
    is: (deliverableProductPlatform, deliverableContentType, deliverableIsComingSoon) => {
      return (
        deliverableProductPlatform &&
        deliverableProductPlatform === "Insider V2" &&
        deliverableContentType === CONTENT_TYPE.DASHBOARD &&
        deliverableIsComingSoon === "NO"
      );
    },
    then: Yup.string().required("Please upload power bi report"),
    otherwise: Yup.string().nullable(),
  }),
  deliverableSignalIndicator: Yup.string().when(["deliverableContentType", "deliverableType"], {
    is: (deliverableContentType, deliverableType) => {
      return deliverableContentType === CONTENT_TYPE.SIGNALS && deliverableType === DELIVERABLE_TYPE.SIGNALS;
    },
    then: Yup.string()
      .max(6, "Please enter maximum 6 characters")
      .typeError("Please enter maximum 6 characters.")
      .matches(/^[+\-%.\d]+$/, {
        message: "Only +, -, %, ., and numbers are allowed in the deliverable signal indicator.",
      }),
    otherwise: Yup.string().nullable(),
  }),
  deliverableSignalTooltip: Yup.string()
    .max(100, "Please enter tooltip of maximum 100 characters")
    .matches(/\S/, "Please enter at least one non-space character")
    .typeError("Please enter tooltip of maximum 100 characters."),
  deliverableSignalRelatedContentType: Yup.string().when(["deliverableType", "deliverableContentType"], {
    is: (deliverableType, deliverableContentType) => {
      return deliverableType && deliverableType === CONTENT_TYPE.SIGNALS && deliverableContentType === DELIVERABLE_TYPE.SIGNALS;
    },
    then: Yup.string().required("Please select related content type"),
    otherwise: Yup.string().nullable(),
  }),
};

export const deliverableSignalExternalValidation = {
  deliverableSignalRelatedContentTitle: Yup.string()
    .required("Please enter title")
    .max(125, "Please enter title of maximum 125 characters")
    .typeError("Please enter title of maximum 125 characters.")
    .matches(/[^\s]([a-zA-Z])/, "Please enter valid title"),
  deliverableSignalRelatedContentSummary: Yup.string()
    .required("Please enter related content summary")
    .max(200, "Please enter related content summary of maximum 200 characters")
    .typeError("Please enter related content summary of maximum 200 characters.")
    .matches(/[^\s]([a-zA-Z])/, "Please enter valid title"),
  deliverableSignalRelatedContentUrl: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter valid URL")
    .typeError("Please enter a proper URL"),
};
