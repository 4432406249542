/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Content Block Actions for different action status
----------------------------------------------------------------
	Creation Details
	Date Created				: 20/JUL/2022
	Author						: LAWRENCE ANTHONY
================================================================
*/

// Maintain Properties details in Content Block
export const setPropertiesDetails = (data) => ({
	type: 'MAINTAIN_PROPERTIES_DETAILS',
	payload: data,
});

// Maintain Design details in Content Block
export const setDesignDetails = (data) => ({
	type: 'MAINTAIN_DESIGN_DETAILS',
	payload: data,
});

// Maintain Settings details in Content Block
export const setSettingsDetails = (data) => ({
	type: 'MAINTAIN_SETTINGS_DETAILS',
	payload: data,
});

// Maintain Taxonomy details in Content Block
export const setTaxonomyDetails = (data) => ({
	type: 'MAINTAIN_TAXONOMY_DETAILS',
	payload: data,
});


export const resetContentBlockRedux = () => ({
	type: 'RESET_CONTENT_BLOCK_REDUX',
})

export const setContentBlockRedux = (data) => ({
	type: 'SET_CONTENT_BLOCK_REDUX',
	payload: data,
})