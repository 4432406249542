// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description :  List Loader
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :22 Nov 23
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from "react";
import GridLoader from "react-spinners/GridLoader";
import { useSelector } from "react-redux";

const spinnerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const ListLoader = (props) => {
  return (
    <div style={spinnerStyle} className="listLoaderDiv">
      <GridLoader size={7} margin={2} color={"#f4505f"} loading={true} />
      <p>Loading More</p>
    </div>
  );
};

export default ListLoader;
