// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : search result routes
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :21 Nov 23
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import SearchResult from "../app/views/searchResult/searchResult";

const PROFILE = ["Search:FULL"];

const searchRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/search-result"
      component={SearchResult}
      pageHeading="Search Results"
      accessRights={{
        moduleName: "Site Search",
        parentModuleName: "",
        moduleAttribute: [...PROFILE],
        interfaceName: "Listing",
      }}
    />
  </Switch>
);

export default searchRoutes;
