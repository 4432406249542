/*================================================================
‘Copyright © 2024, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :   Scroll to Top Component
   Summary : Scroll to Top Component
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 10/Jan/2024 
  Author				      : Satya Prakash Mall
================================================================ 
*/
import React from "react";
import { MdKeyboardArrowUp } from "react-icons/md";
import classes from "./ScrollToTop.module.css";

const ScrollToTop = (props) => {
  const goTop = () => {
    const scrollableDiv = document.getElementById("scrollableDiv");
    scrollableDiv.scrollTop = 0;
  };

  return (
    <button className={` ${classes.btnScrollTop} ${props?.showScrollTopButton ? classes.visible : ""}`} onClick={goTop}>
      <MdKeyboardArrowUp fill="#fff" title="Back to top" className={classes.scrollIcon} />
    </button>
  );
};

export default ScrollToTop;
