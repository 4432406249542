/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Company Actions for different action status
----------------------------------------------------------------
	Creation Details
	Date Created				: 5/4/2022
	Author						: Prashant Wankhade
================================================================
*/

export const setBasicTab = (data) => ({
	type: 'MAINTAIN_BASIC_TAB',
	payload: data,
});
