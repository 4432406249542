/*
================================================================
	Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : KIA Routes 
----------------------------------------------------------------
	Creation Details
	Date Created				: 04/May/2021
	Author						: Sandeep K. Sharma
================================================================
*/
import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import AddKia from "../app/views/kia/component/add";
import KeyImpactAreaListing from "../app/views/kia";

const KIA = ["KIA:FULL"];

const kiaRoutes = () => (
  <Suspense
    fallback={
      <div className="text-center">
        <h3>Loading...</h3>
      </div>
    }
  >
    <Switch>
      <AuthLayout
        exact
        path="/kia/add"
        component={AddKia}
        pageHeading="GenNx CMS - Key Impact Areas"
        accessRights={{
          moduleName: "Key Impact Areas",
          parentModuleName: "",
          moduleAttribute: [...KIA],
          interfaceName: "Overview",
        }}
      />
      <AuthLayout
        exact
        path="/kia"
        component={KeyImpactAreaListing}
        pageHeading="GenNx CMS - Key Impact Areas"
        accessRights={{
          moduleName: "Key Impact Areas",
          parentModuleName: "",
          moduleAttribute: [...KIA],
          interfaceName: "Listing",
        }}
      />
      <AuthLayout
        exact
        path="/kia/:kiaId/edit"
        component={AddKia}
        pageHeading="GenNx CMS - Key Impact Areas"
        accessRights={{
          moduleName: "Key Impact Areas",
          parentModuleName: "",
          moduleAttribute: [...KIA],
          interfaceName: "Overview",
        }}
      />
    </Switch>
  </Suspense>
);

export default kiaRoutes;
