import SURVEY_CONSTANT from "../../app/views/survey/constants";
/*
================================================================
    Copyright � 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Survey State
----------------------------------------------------------------
    Creation Details
    Date Created				: 02/Dec/2022
    Author						: Ronak Jain
================================================================
*/
import { getProductVariant } from "../../utilities";
const SURVEY_STATE = {
  basicDetails: {
    pVariant: getProductVariant(),
    createSurveyFrom: "",
    existingTemplate: "",
    surveyType: {
      label: SURVEY_CONSTANT.SURVEY_TYPE.ONETIME,
      value: SURVEY_CONSTANT.SURVEY_TYPE.ONETIME,
    },
    teamName: "",
    npsSurveyType: "",
    surveyTitle: "",
    serialNumber: "",
    surveyShortName: "",
    surveyUrl:
      SURVEY_CONSTANT["SURVEY_DOMAIN_" + getProductVariant()?.toUpperCase()] +
      "/",
    surveyStartDate: "",
    surveyEndDate: "",
    validateEndDate: true,
    maskedSurveyResponse: "NO",
    saveAsTemplate: "NO",
    surveyAudience: "",
    surveyStatus: {
      label: SURVEY_CONSTANT.SURVEY_STATUS.DRAFT,
      value: SURVEY_CONSTANT.SURVEY_STATUS.DRAFT,
    },
    surveyReleaseArtifact: null,
    surveyFrequency: "Quarterly",
    surveyYear: "",
    surveyQuarter: "",
  },
  designDetails: {},
  invitationDetails: {},
  acknowledgementDetails: {},
  taxonomyDetails: [],
  taxonomySelected: [],
};

export default (state = SURVEY_STATE, action) => {
  switch (action.type) {
    /*
             @Description : Basic details 
        */

    // Maintain basic details from the survey basic tab
    case "MAINTAIN_BASIC_DETAILS": {
      return { ...state, basicDetails: action.payload };
    }
    case "UPDATE_SURVEY_TAXONOMY_INDUSTRY": {
      return {
        ...state,
        taxonomySelected: action.payload,
      };
    }

    // Maintain design details from the survey design tab
    case "MAINTAIN_DESIGN_DETAILS": {
      return { ...state, designDetails: action.payload };
    }

    // Maintain invitation details from the survey invitation tab
    case "MAINTAIN_INVITATION_DETAILS": {
      return { ...state, invitationDetails: action.payload };
    }

    // Maintain acknowledgement details from the survey acknowledgement tab
    case "MAINTAIN_ACKNOWLEDGEMENT_DETAILS": {
      return { ...state, acknowledgementDetails: action.payload };
    }

    // Maintain taxonomy details from the survey taxonomy tab
    case "MAINTAIN_TAXONOMY_DETAILS": {
      return { ...state, taxonomyDetails: action.payload };
    }

    //Reset redux
    case "RESET_REDUX": {
      return {
        basicDetails: SURVEY_STATE.basicDetails,
        designDetails: {},
        invitationDetails: {},
        acknowledgementDetails: {},
        taxonomyDetails: [],
      };
    }

    default:
      return state;
  }
};
