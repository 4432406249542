/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Auth Actions for User Signin, Signout
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { USER_SIGNIN, USER_SIGNOUT, USER_TOKEN_SET } from "../../constants/actionConstants";

export const userSignin = (payload) => ({
	type: USER_SIGNIN,
	payload,
});

export const userSignout = (payload) => ({
	type: USER_SIGNOUT,
	payload,
});

export const userTokenSet = (payload) => ({
	type: USER_TOKEN_SET,
	payload,
});
