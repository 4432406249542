/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Funding  info UI for  Listing, edit, add and delete and API wiring up
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 30/Jul/2020 
	Author						: Anand Kumar
================================================================ 
*/

import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { Modal } from "react-bootstrap";
import {
  fetchCompany,
  fetchFunding,
  deleteFunding,
  fetchSingleCompany,
  saveFunding,
  editFunding,
} from "../../../../../middleware/services/companyApi";
import Accordion from "../../../../components/accordion";
import fundingTypeOptions from "../../constants";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, TextEditor, MultiSelectNew, Dropdown, DatePicker } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { fundingValidationSchema } from "../../../../helper/validationSchema/companyValidationSchema";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { getProductVariant } from "../../../../../utilities";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import { ckEditorMinimalConfig } from "../../../../../config/ckEditorConfig";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const initialState = {
  fundingName: "",
  fundingAmount: "",
  fundingTypeRound: "",
  fundingInvestors: [],
  fundingDate: "",
  fundingComment: "",
};

const Fundings = (props) => {
  /* Common access */
  const accessPermissionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_FUNDINGS);
  const accessPermissionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_FUNDINGS);
  const accessPermissionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_FUNDINGS);

  const companyId = props.companyId;
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState("");
  const [fundings, setFundings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [investors, setInvestors] = useState([]);
  const [investorsForEdit, setInvestorsForEdit] = useState({});
  const [isEdit, setEdit] = useState(false);
  const [date, setDate] = useState();
  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    validationSchema: Yup.object().shape(fundingValidationSchema),
  });

  useEffect(() => {
    handleFetchFunding();
    getCompanyName();
  }, []);

  const getCompanyName = async () => {
    let fields = { fields: ["id", "companyName"] };
    const response = await fetchSingleCompany(companyId, { ...fields });
    setCompanyName(response.data.data.companyName);
  };
  const handleFetchFunding = async () => {
    const response = await fetchFunding(companyId);
    if (response && response.data.data) {
      let data = response.data.data.map((item) => {
        return {
          ...item,
          fundingDate: item.fundingDate ? moment(item.fundingDate).format("DD-MMM-yyyy") : "",
        };
      });
      setFundings(data);
    }
    setLoading(false);
  };
  // delete a funding
  const handleDeleteFunding = async (fundingId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await deleteFunding(fundingId);
          dispatch(actionSuccess("Funding deleted successfully"));
          await handleFetchFunding();
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to Delete ?",
        title: "dialogAlertCssWarning",
      })
    );
  };

  // save a funding
  const handleSaveFunding = async () => {
    const fundingOnCompanyId = [
      {
        companyId,
        companyName: companyName,
      },
    ];

    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = formik.values;
        let fundingInvestors = payload.fundingInvestors.map((c) => {
          if (c.label === c.value) {
            return { companyName: c.value };
          } else {
            return {
              companyName: c.label,
              companyId: c.value,
            };
          }
        });
        payload.fundingInvestors = fundingInvestors;
        payload.fundingOnCompanyId = fundingOnCompanyId;
        payload.fundingDate = moment(payload.fundingDate).format("DD-MMM-yyyy");
        payload.fundingAmount = payload.fundingAmount == "NA" ? payload.fundingAmount : +payload.fundingAmount;
        saveFunding(payload)
          .then((response) => {
            setShowModal(!showModal);
            formik.handleReset();
            dispatch(actionSuccess("Funding Added Successfully"));
            handleFetchFunding();
          })
          .catch((err) => {
            dispatch(actionError(err.data.message || "Something went wrong"));
          });
      }
    });
  };

  const searchTag = async (value) => {
    if (value.length > 2) {
      let filters = [];
      const productVariant = getProductVariant();
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["companyName", "cn", value]);
      let fields = { fields: ["companyId", "companyName"] };
      const response = await fetchCompany({ filters: filters, ...fields, sort: "companyName:asc", limit: 10 });
      if (response.data && response.data.data) {
        const resData = response.data.data.map((v) => {
          return { label: v.companyName, value: v.id };
        });
        if (resData) {
          setInvestors([...resData, ...investors]);
        } else {
          setInvestors([]);
        }
      }
    }
  };

  // handleEdit funding
  const handleEditFunding = async () => {
    const fundingOnCompanyId = [
      {
        companyId,
        companyName: companyName,
      },
    ];

    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = formik.values;
        let fundingInvestors = payload.fundingInvestors.map((c) => {
          if (c.label === c.value) {
            return { companyName: c.value };
          } else {
            return {
              companyName: c.label,
              companyId: c.value,
            };
          }
        });
        payload.fundingInvestors = fundingInvestors;
        payload.fundingOnCompanyId = fundingOnCompanyId;
        payload.fundingDate = payload.fundingDate.toString();
        payload.fundingAmount = payload.fundingAmount == "NA" ? payload.fundingAmount : +payload.fundingAmount;
        const fundingId = payload.id;
        delete payload.fundingCreatedDate;
        delete payload.fundingUpdatedDate;
        delete payload.id;
        editFunding(fundingId, payload)
          .then((response) => {
            setEdit(false);
            setShowModal(!showModal);
            formik.handleReset();
            dispatch(actionSuccess("Funding updated successfully"));
            handleFetchFunding();
          })
          .catch((err) => {
            dispatch(actionError(err.data ? err.data.message : "Something went wrong"));
          });
      }
    });
  };

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <>
      <Accordion heading="Fundings" stepCompleted={fundings && fundings.length > 0 ? true : false} step={3}>
        <div className="normal-table table-responsive pt-2">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Transaction Name</th>
                <th className="text-center">Date</th>
                <th>Funding Type</th>
                <th className="text-right" style={{ width: window.innerWidth < 1400 ? "20%" : "200px" }}>
                  Amount (Million USD)
                </th>
                <th>Company</th>
                <th>Investors</th>
                <th className="text-center internal-grid-action">Action</th>
              </tr>
            </thead>
            <tbody>
              {fundings.length
                ? fundings.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.fundingName || "--"}</td>
                        <td className="text-center">{item.fundingDate ? moment(item.fundingDate).format("DD MMM yyyy") : "--"}</td>
                        <td>{item.fundingTypeRound || "--"}</td>
                        <td align="right">{(item.fundingAmount > 0 && `$${item.fundingAmount}`) || "--"}</td>
                        {/* <td align="right"></td> */}
                        <td>{item.fundingOnCompanyId.map((company) => company.companyName).toString() || "--"}</td>
                        <td className="overflow">{item.fundingInvestors.map((investor) => investor.companyName).toString() || "--"}</td>
                        <td className="text-center">
                          {accessPermissionEdit || accessPermissionDelete ? (
                            <ThreeDotMenu
                              rowID={item.id}
                              Edit={
                                accessPermissionEdit
                                  ? () => {
                                      setEdit(true);
                                      let investorsEdit = item.fundingInvestors.map((company) => {
                                        return { label: company.companyName, value: company.companyId || company.companyName };
                                      });
                                      let updateValues = { ...item, fundingInvestors: investorsEdit };
                                      setInvestorsForEdit(investorsEdit);
                                      formik.setValues(updateValues);
                                      setDate(item.fundingDate);
                                      setShowModal(!showModal);
                                    }
                                  : undefined
                              }
                              Delete={
                                accessPermissionDelete
                                  ? () => {
                                      handleDeleteFunding(item.id);
                                    }
                                  : undefined
                              }
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
          <div className="text-right">
            {accessPermissionAdd ? (
              <button
                type="button"
                className="btn btn-dark cy-btn"
                onClick={() => {
                  setEdit(false);
                  setDate("");
                  setShowModal(!showModal);
                }}
              >
                Add New
              </button>
            ) : null}
          </div>
        </div>
      </Accordion>
      <Modal show={showModal} backdrop="static" className="modal fade md" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-header bg-none text-dark">
          <h5 className="modal-title" id="exampleModalLabel">
            {isEdit ? "Edit Company Funding" : "Add Company Funding"}
          </h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              setShowModal(!showModal);
              formik.handleReset();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <TextField
                formik={formik}
                className="form-control"
                id="fundingName"
                name="fundingName"
                placeholder="Transaction Name *"
                required="required"
              />
            </div>
            <div className="col-md-6">
              <DatePicker
                formik={formik}
                properties={{
                  placeholder: "Transaction Date *",
                  id: `fundingDate`,
                  name: `fundingDate`,
                  format: "dd MMM yyyy",
                  floatLabelType: "Auto",
                  allowEdit: false,
                  openOnFocus: true,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" data-tip="Enter NA if Amount not available">
              <TextField
                formik={formik}
                className="form-control"
                id="fundingAmount"
                name="fundingAmount"
                placeholder="Amount(Million USD) *"
                required="required"
              />
              <ReactTooltip />
            </div>
            <div className="col-md-6">
              <Dropdown
                formik={formik}
                id="fundingTypeRound"
                name="fundingTypeRound"
                placeholder="Funding Type *"
                dataSource={fundingTypeOptions}
                fields={{ text: "label", value: "value" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <MultiSelectNew
                formik={formik}
                mode="Box"
                allowCustomValue={true}
                allowFiltering={true}
                placeholder="Investors *"
                id="fundingInvestors"
                name="fundingInvestors"
                value={
                  isEdit &&
                  investorsForEdit.map((v) => {
                    return v.label;
                  })
                }
                fields={{ text: "label", value: "value" }}
                filtering={(e) => {
                  searchTag(e.text);
                }}
                noRecordsTemplate={() => <div className="norecord">Type min 3 characters to search investors</div>}
                dataSource={investors}
              />
            </div>
          </div>
          <label style={{ top: "20px", position: "relative" }}>Comment</label>
          <div className="row">
            <div className="col-md-12">
              <TextEditor
                formik={formik}
                properties={{
                  id: "fundingComment",
                  name: "fundingComment",
                  activeClass: "fundingComment",
                  // label: "About",
                  config: { ...ckEditorMinimalConfig, editorplaceholder: "Enter funding comment", height: "200px" },
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn cy-btn btn-outline-dark"
            onClick={() => {
              setShowModal(!showModal);
              formik.handleReset();
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn cy-btn btn-primary" onClick={isEdit ? handleEditFunding : handleSaveFunding}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Fundings;
