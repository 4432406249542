/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Popup State (to show / hide)
----------------------------------------------------------------
	Creation Details
	Date Created				: 03/Dec/2020
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/
import React from "react";
import { SHOW_POPUP, HIDE_POPUP } from "../../constants/actionConstants";

const defaultCallback = () => {
	return;
};

const DEFAULT_STATE = {
	width: "268px",
	showPopup: false,
	title: "Popup Title Here",
	okText: "Yes",
	okCallback: defaultCallback,
	showCancelButton:true,
	cancelText: "No",
	cancelCallback: defaultCallback,
	content: "You can put your content here. Content may be string or html.",
	data: undefined,
	children: <></>,
};

export default (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case SHOW_POPUP: {
			return {
				...state, showPopup: true, ...action.payload
			};
		}
		case HIDE_POPUP: {
			return {
				...state, showPopup: false
			};
		}
		default:
			return state;
	}
};
