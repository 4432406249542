/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company State
----------------------------------------------------------------
	Creation Details
	Date Created				: 18/Jul/2022
	Author						: LAWRENCE ANTHONY
================================================================
*/

const CONTENT_BLOCK_STATE = {	
	propertiesDetails: {},
	designDetails: {},
	settingsDetails: {},
	taxonomyDetails: {},
	commonState: {}
};

export default (state = CONTENT_BLOCK_STATE, action) => {
	switch (action.type) {
		// Maintain properties tab details 
		case 'MAINTAIN_PROPERTIES_DETAILS': {
			return { ...state, propertiesDetails: action.payload }
		}

		// Maintain design tab details 
		case 'MAINTAIN_DESIGN_DETAILS': {
			return { ...state, designDetails: action.payload }
		}

		// Maintain settings tab details 
		case 'MAINTAIN_SETTINGS_DETAILS': {
			return { ...state, settingsDetails: action.payload }
		}

		// Maintain taxonomy tab details 
		case 'MAINTAIN_TAXONOMY_DETAILS': {
			return { ...state, taxonomyDetails: action.payload }
		}

		case 'RESET_CONTENT_BLOCK_REDUX': {
			return { 
				...state,
				propertiesDetails: {},
				designDetails: {},
				settingsDetails: {},
				taxonomyDetails: {},
			}
		}

		case 'SET_CONTENT_BLOCK_REDUX': {
			return { 
				...state,
				commonState: action.payload
			}
		}

		// case 'MAINTAIN_RESET': {
		// 	return {
		// 		newsContentAdd: '',
		// 		newTaxonomyAdd: [],
		// 		newTaxonomyTreeAdd: '',
		// 		taxonomyTaggingData: [],
		// 		taxonomyTaggingData2: [],
		// 		technologyData: [],
		// 		checkedNodes: [],
		// 		flagPayload: false,
		// 	}
		// }
		default:
			return state;
	}
};
