export const setCombinedData = (data) => ({
  type: "CUSTOM_WIDGET_COMBINED_DATA",
  payload: data,
});

export const resetCustomWidget = () => ({
  type: "RESET_CUTOM_WIDGET_REDUX",
});

export const setSelectedWidgetData = (data) => ({
  type: "SELECTED_CUTOM_WIDGET_DATA",
  payload: data,
});

export const setRightSideWidgetList = (data) => ({
  type: "RIGHT_SIDE_WIDGET_LIST",
  payload: data,
});

export const setPreviewData = (data) => ({
  type: "PREVIEW_CONTENT",
  payload: data,
});

export const hideWidgetLoader = () => ({
  type: "WIDGET_HIDE_LOADER",
});