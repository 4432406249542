/*
================================================================
	Copyright © 2024, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Data Model Library
----------------------------------------------------------------
	Creation Details
	Date Created				: 19/July/2024
	Author						: Riddhesh Desai
================================================================
*/
import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import dataModelLibraryListing from "../app/views/dataModelLibrary";

const PROPERTIES = ["Properties:VIEW", "Properties:FULL"];

const dataModelLibraryRoutes = () => (
  <Suspense
    fallback={
      <div className="text-center">
        <h3>Loading...</h3>
      </div>
    }
  >
    <Switch>
      <AuthLayout
        exact
        path="/data-model-library"
        component={dataModelLibraryListing}
        pageHeading="GenNx CMS - Data Model Library"
        accessRights={{
          moduleName: "Data Model Library",
          parentModuleName: "",
          moduleAttribute: [...PROPERTIES],
          interfaceName: "Listing",
        }}
      />
    </Switch>
  </Suspense>
);

export default dataModelLibraryRoutes;
