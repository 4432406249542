/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  Survey form field options
 ---------------------------------------------------------------------------------
    Creation Details
    Date Created				: 17/Mar/2021
    Author						: Pankaj Kulshreshtha
================================================================
*/
// Survey create from options
export const VIDEO_TYPE_OPTIONS = [
    {
        label: "In Discussion With",
        value: "In Discussion With"
    },
    {
        label: "Interview",
        value: "Interview"
    },
    {
        label: "Podcast",
        value: "Podcast"
    },
    {
        label: "Start-up Spotlight",
        value: "Start-up Spotlight"
    },
    {
        label: "Top 5",
        value: "Top 5"
    },

    {
        label: "Webinar",
        value: "Webinar"
    },
];
// Status options
export const STATUS_OPTIONS = [
    {
        label: "Active",
        value: "Active",
        isDisabled: true
    },
    {
        label: "Inactive",
        value: "Inactive",
        isDisabled: true
    },
    {
        label: "Upcoming",
        value: "Upcoming",
        isDisabled: true
    }
];
// Status options
export const VIDEO_OPTIONS = [
    {
        label: "Vimeo",
        value: "Vimeo",
        // isDisabled: true
    },
    {
        label: "YouTube",
        value: "YouTube",
    }
];
// Timezone options
export const TIMEZONE_OPTIONS = [
    {
        label: "CET",
        value: "CET",
    },
    {
        label: "IST",
        value: "IST",
    },
    {
        label: "UK",
        value: "UK",
    }
];
// Commercial impact options
export const COMMERCIAL_IMPACT_OPTIONS = [
    {
        label: "less than 2 years",
        value: "less than 2 years",
    },
    {
        label: "2-5 years",
        value: "2-5 years",
    },
    {
        label: "5-10 years",
        value: "5-10 years",
    },
    {
        label: "10 years or above",
        value: "10 years or above",
    }
];
export const ADD_MORE_LIMIT = 3;

export const TABS_NAME = ["Video Details", "Related Questions", "Related Documents", "Hosted By"];