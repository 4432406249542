/*
================================================================
	Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : My Briefcase for different action status
----------------------------------------------------------------
	Creation Details
	Date Created				: 07/Dec/2023
	Author						: Satya Prakash Mall
================================================================
*/

export const getAllBriefcaseCategory = (data) => ({
  type: "SET_ALL_BRIEFCASE_CATEGORY",
  payload: data,
});

export const getCategoryListing = (data) => ({
  type: "SET_CATEGORY_LISTING",
  payload: data,
});

export const deleteBriefcaseCategory = (data) => ({
  type: "DELETE_CATEGORY",
  payload: data,
});

export const updateBriefcaseCategoryId = (data) => ({
  type: "SET_BRIEFCASE_CATEGORY_ID",
  payload: data,
});

export const updateBriefcaseCount = (data) => ({
  type: "SET_BRIEFCASE_COUNT",
  payload: data,
});

export const updateCategorySkipCount = (data) => ({
  type: "SET_CATEGORY_SKIP_COUNT",
  payload: data,
});

export const applyCategoryFilter = (data) => ({
  type: "SET_CATEGORY_FILTER",
  payload: data,
});

export const updateCategoryBriefcaseListing = (data) => ({
  type: "SET_CATEGORY_BRIEFCASE_LISTING",
  payload: data,
});

export const updateNewFolderId = (data) => ({
  type: "SET_NEW_FOLDER_ID",
  payload: data,
});

export const addNewFolder = (data) => ({
  type: "SET_NEW_FOLDER",
  payload: data,
});

export const renameOldFolder = (data) => ({
  type: "RENAME_FOLDER",
  payload: data,
});

export const resetBriefcaseListing = (data) => ({
  type: "RESET_BRIEFCASE_LISTING",
  payload: data,
});

export const resetNewFolderId = (data) => ({
  type: "RESET_NEW_FOLDER_ID",
  payload: data,
});

export const resetParams = (data) => ({
  type: "RESET_PARAMS",
  payload: data,
});
export const resetBriefcaseState = () => ({
  type: "RESET_STATE",
});
