/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : Customer Service API 
----------------------------------------------------------------
	Creation Details
	Date Created				: 07/July/2021
	Author						: Sandeep K Sharma
================================================================
*/

import { SERVICE_API as API } from "../../config/apiConfig";
import { SERVICE_URL } from "../../config";

// fetch Service list
export const fetchServices = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/customer-service",
    method: "GET",
    params: params,
  });
};

// fetch single Service details
export const fetchSingleService = (serviceId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/customer-service/${serviceId}`,
    method: "GET",
    params: params,
  });
};

// Add new Service
export const addService = (payload) => {
  return API({
    url: "/customer-service",
    method: "POST",
    data: payload,
  });
};
// Edit existing Service
export const updateService = (serviceId, payload) => {
  return API({
    url: `/customer-service/${serviceId}`,
    method: "PUT",
    data: payload,
  });
};
// delete single Service
export const deleteSingleService = (serviceId) => {
  return API({
    url: `/customer-service/${serviceId}`,
    method: "DELETE",
  });
};
// delete selected Services
export const deleteSelectedService = (payload, params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/customer-service",
    method: "DELETE",
    params: params,
    data: payload,
  });
};
//publish Service
export const publishService = (serviceId, payload) => {
  return API({
    url: `/customer-service/${serviceId}/publish`,
    method: "PUT",
    data: payload,
  });
};

/*
================================================================
 Service Documnet API Calls
================================================================
*/

// Get all Service Documents
export const getServiceDocuments = (serviceId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/customer-service/${serviceId}/document`,
    method: "GET",
    params: params,
  });
};
// Get single Service Document

export const getSingleServiceDocument = (serviceId, documentId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/customer-service/${serviceId}/document/${documentId}`,
    method: "GET",
    params: params,
  });
};
// Add new Service Document
export const addServiceDocument = (serviceId, payload) => {
  return API({
    url: `/customer-service/${serviceId}/document`,
    method: "POST",
    data: payload,
  });
};
// Update Service Document
export const updateServiceDocument = (serviceId, documentId, payload) => {
  return API({
    url: `/customer-service/${serviceId}/document/${documentId}`,
    method: "PUT",
    data: payload,
  });
};
// Delete Service Document
export const deleteSingleServiceDocument = (serviceId, documentId) => {
  return API({
    url: `/customer-service/${serviceId}/document/${documentId}`,
    method: "DELETE",
  });
};

export const fetchServiceDocUrl = (file, originalFileName) => {
  return API({
    url: `/customer-service/document/${file}/${originalFileName}`,
    method: "GET",
    responseType: "blob",
  });
};

/*
================================================================
 Service Video API Calls
================================================================
*/

// Get all Service Videos
export const getServiceVideos = (serviceId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/customer-service/${serviceId}/video`,
    method: "GET",
    params: params,
  });
};
// Get single Service Video

export const getSingleServiceVideo = (serviceId, videoId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/customer-service/${serviceId}/video/${videoId}`,
    method: "GET",
    params: params,
  });
};
// Add new Service Video
export const addServiceVideo = (serviceId, payload) => {
  return API({
    url: `/customer-service/${serviceId}/video`,
    method: "POST",
    data: payload,
  });
};
// Update Service Video
export const updateServiceVideo = (serviceId, videoId, payload) => {
  return API({
    url: `/customer-service/${serviceId}/video/${videoId}`,
    method: "PUT",
    data: payload,
  });
};
// Delete Service Video
export const deleteSingleServiceVideo = (serviceId, videoId) => {
  return API({
    url: `/customer-service/${serviceId}/video/${videoId}`,
    method: "DELETE",
  });
};

/*
================================================================
 Service Survey API Calls
================================================================
*/
// get Service Survey
export const getServiceSurvey = (serviceId) => {
  return API({
    url: `/customer-service/${serviceId}/survey`,
    method: "GET",
  });
};

// Add new Service Survey
export const addServiceSurvey = (serviceId, payload) => {
  return API({
    url: `/customer-service/${serviceId}/survey`,
    method: "POST",
    data: payload,
  });
};

//Service ThumbNail Image
export const fetchThumbnailUrl = (fileName) => {
  return `${SERVICE_URL}/customer-service/image/${fileName}`;
};

export const fetchAnalystQueryDocument = (analystQueryId, fileName, originalFileName) => {
  return API({
    url: `/customer-service/analyst-query/document/${analystQueryId}/file/${fileName}/${originalFileName}`,
    method: "GET",
    responseType: "blob",
  });
};
