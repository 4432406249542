/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Trend taxonomy Tree Component
     Require Props :
        handleSave : Save Tree Data
        loadOtherTagInfo : Switch between trend and tree tagging
        saveTreeData : Save Technology taxonomy data
        contentId : Content Id [news id | company id]
        fetchContentData : Fetch content taxonomy
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 08/June/2022 
    Author						: Lawrence Anthony
================================================================ 
*/
import "../index.scss";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DropdownList } from "react-widgets";
import { fetchTaxonomyTrend, getObjectiveSgfTag } from "../../../../middleware/services/cmsApi";
import noTaxonomyImage from "../../../../assets/images/no-taxonomy.svg";
import { actionSuccess, showLoader, hideLoader, actionError, showMessage } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import PropTypes from "prop-types";
import TreeUI from "../treeUI";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { CompareArrowsOutlined } from "@material-ui/icons";
import SortableTree from "../sortableTree";
const Taxonomy = (props) => {
    const dispatch = useDispatch();
    const [tagging, setTagging] = useState([]);
    const [technology, setTechnology] = useState([]);
    const [technologyData, setTechnologyData] = useState([]);
    const [selectedTechnology, setSelectedTechnology] = useState(undefined);
    const [selectedIndustry, setSelectedIndustry] = useState(undefined);
    const [taxonomy, setTaxonomy] = useState([]);
    const [checked, setChecked] = useState([]);
    const [contentTaggedIds, setContentTaggedIds] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [reRender, setReRender] = useState(false);
    const [sgfName, setSgfName] = useState(undefined);
    const [selectedSgfIndustry, setSelectedSgfIndustry] = useState("");
    const [contentTaggingData, setContentTaggingData] = useState([]);
    let taggingData = [];
    const [sgfTrendData, setSgfTrendData] = useState([]);
    const [checkedLeft, setCheckedLeft] = useState("");
    const [leftTreeCollapse, setLeftTreeCollapse] = useState(true);
    const [rightTreeCollapse, setRightTreeCollapse] = useState(true);

    /*
        @Description : Use only for avoiding trend change mechanism on save trend
    */
    const [selectedTrend, setSelectedTrend] = useState('');
    const [leftTreeChecked, setLeftTreeChecked] = useState([]);
    const [rightTreeChecked, setRightTreeChecked] = useState([]);
    const [sgfData, setSgfData] = useState({
        trend_id: "",
        trend_name: "",
        trend_parent_id: "",
        trend_is_sgf: 1,
        trend_sub_root: "",
        trend_root: ""
    });

    const fetchIndustry = (() => {
        dispatch(showLoader());
        fetchTaxonomyTrend({ fields: ["id", "trendName", "trendParentId", "trendParentName"], filters: [["trendParentId", "eq", 0]], limit: 2000 }).then((res) => {
            setSelectedSgfIndustry(res.data.data[0].id);
            setIndustry(res.data.data);
            fetchTrend(res.data.data[0].id);
            // setSgfData({
            //     ...sgfData,
            //     trend_parent_id: res.data.data[0].id,
            //     trend_root : res.data.data[0].id
            // })
        }).catch(err => {
            console.error("err");
        });
    });
    /*
       @Description : Fetch taxonomy tagging for respective content 
       1. fetchContentTagging received from parent component as a props
   */
    const fetchContentTagging = () => {
        dispatch(showLoader());
        const contentTaxonomy = props.fetchContentTagging(props.contentId);
        contentTaxonomy.then((res) => {
            let { data = [] } = res.data;
            data = [...new Map(data.map(v => [v.trendId, v])).values()];
            if (data.length) {
                setContentTaggingData(data);
                taggingData = data.map(values => values.trendId);
            }
            const selectedNodeData = buildSelectedTreeJson(data);
            const selectedSgfNodeData = buildSelectedSgfTreeJson(JSON.parse(JSON.stringify(selectedNodeData)));
            setTagging(selectedSgfNodeData.map((item) => { item["level"] = 2; return item; }));
            setContentTaggedIds(taggingData);
            setLeftTreeCollapse(true)
            setLeftTreeChecked([]);
            setTimeout(() => {
                setReRender(false);
            }, 100);
            if (industry?.length) dispatch(hideLoader());
        }).catch(err => {
            dispatch(hideLoader());
            console.error("err fetchProductTaxonomy :: ", err);
        });
    };

    const fetchSgfTrend = (sgfName) => {
        getObjectiveSgfTag({ filters: [["sgfName", "eq", sgfName]], limit: 2000 }).then((res) => {
            // dispatch(hideLoader());
        }).catch(err => {
            dispatch(hideLoader());
            console.error("err fetchTrend :: ", err);
        });
    };
    /*
        @Description : Fetch all trend 
    */
    const fetchTrend = (selectedIndustry) => {
        dispatch(showLoader());
        getObjectiveSgfTag({ filters: [["sector.sectorId", "eq", selectedIndustry]], sort: "trendName:asc", limit: 2000 }).then((res) => {
            dispatch(hideLoader());
            setSgfTrendData(res.data.data.filter((item) => { if (item.sgfName !== "Others") return item; }));
            setSgfName(res.data.data[0].id);
            setTechnologyData(res.data.data[0].trend);
            setSelectedTechnology(res.data.data[0].trend[0].trendId);
            // fetchTaxonomy(res.data.data[0].trend[0].trendId, contentTaggedIds)
            setSgfData({
                trend_parent_id: res.data.data[0].sector[0].sectorId,
                trend_parent_name: res.data.data[0].sector[0].sectorName,
                trend_id: res.data.data[0].id,
                trend_name: res.data.data[0].sgfName,
                trend_is_sgf: 1,
                trend_sub_root: "",
                trend_root: res.data.data[0].sector[0].sectorId
            });
        }).catch(err => {
            dispatch(hideLoader());
            console.error("err fetchTrend :: ", err);
        });

    };
    /*
        @Description : Fetch all taxonomy for selected trend
        @param 
        trendSubRoot : Trend Id
        taggingData : Content taxonomy tagged data [ Use for mark as default check ]
    */
    const fetchTaxonomy = (trendParentId) => {
        taggingData = [...new Set(contentTaggedIds)];
        dispatch(showLoader());
        fetchTaxonomyTrend({ fields: ["id", "trendName", "trendParentId", "trendParentName", "trendLevel"], filters: [["trendSubRoot", "eq", trendParentId]], limit: 2000 }).then(res => {
            dispatch(hideLoader());
            let { data = [] } = res.data;
            let checkNode = [];
            if (data.length) {
                const treeJosnArray = buildTreeJson(data, trendParentId);
                // setTaxonomy(data)
                let checkedNodes = undefined;
                data.forEach((element) => {
                    if (taggingData.includes(element.id)) {
                        checkNode.push(element.id);
                        element["isChecked"] = true;
                        checkedNodes = true;
                    }
                });
                setChecked(checkNode);
                setTaxonomy(treeJosnArray);
                setRightTreeCollapse(true)
                setLeftTreeChecked([]);
                checkedNodes && setRightTreeChecked(checkNode);
                // setReRenderRight(true)
            }
        }).catch(err => {
            dispatch(hideLoader());
            console.error("err fetchTaxonomy :: ", err);
        });

    };
    /*
        @Description : Build tree data
        @param : 
        data : Trend taxonomy data
        parentId : technology id
    */
    const buildTreeJson = (data, parentId) => {

        const result = [];
        data.forEach((element) => {
            element['value'] = element.id;
            element['label'] = element.trendName;
            element['level'] = element.trendLevel;
            element['showCheckbox'] = true;
            if (element['trendParentId'] == parentId) {
                const children = buildTreeJson(data, element['id']);
                if (children.length > 0) {
                    element['children'] = children;
                }
                result.push(element);
            }
        });
        return result;
    };
    /*
       @Description : Build tree data for tagged values
       @param : 
       data : taxonomy data
       parentId :industry id
   */
    const buildSelectedTreeJson = (data, parentId = 0) => {
        const result = [];
        data.forEach((element) => {
            element["id"] = element.trendId;
            element['value'] = element.trendId;
            element['label'] = element.trendName;
            if (element?.trendSgfParentId) {
                element["parentId"] = element.trendSgfParentId;
            }
            else {
                element["parentId"] = element.trendParentId;
            }
            if (element['trendParentId'] == parentId) {
                const children = buildSelectedTreeJson(data, element['trendId']);
                if (children.length > 0) {
                    element['children'] = children;
                }
                result.push(element);
            }

        });
        return result;
    };

    const buildSelectedSgfTreeJson = (data, parentId = 0) => {
        const result = [];
        data.forEach((element) => {
            let siblings = element?.children;
            if (siblings) {
                let sgfChilds = siblings.filter((item) => { if (item.trendIsSgf === 1) return item.trendId; });
                let sgfChild = sgfChilds.map((item) => {
                    let sgfChildren = siblings.filter((v) => {

                        if (item.trendId == v.trendSgfParentId) { return v; }
                    });
                    item["children"] = sgfChildren;
                    return item;
                });
                element.children = (sgfChild);
            }
            result.push(element);
        });
        return result;
    };
    /*
      @Description : Make bridge to send callback
      @param : 
      selectedTech : selected technology
      checkValues : Checked taxonomy
  */
    const handleSaveBridge = (selectedTech, checkValues) => {
        setSelectedTrend(selectedTech);
        props.handleSave(selectedTech, checkValues, fetchContentTagging, sgfData);

    };

    const deleteTrend = () => {
        if (props.isDelete) {
            dispatch(showAlertBox({
                okCallback: async () => {

                    props.deleteTrend([... new Set(leftTreeChecked)], fetchContentTagging, fetchTaxonomy, selectedTechnology);
                },
                okText: "Delete", cancelText: "Cancel", content: "Do you want to remove taxonomy tagging ? ", title: "dialogAlertCss"
            }));
        }
        else {
            dispatch(showMessage("Delete not allowed"));
        }
    };

    const leftTreeOnChecked = (checkedLeft) => {
        var result = [];
        checkedLeft.forEach(function (a, i) {
            result.push(a.id);
            if (Array.isArray(a.children)) {
                result = result.concat(leftTreeOnChecked(a.children));
            }
        });
        setLeftTreeChecked(result);
        return result;
    };
    useEffect(() => {
        fetchContentTagging();
    }, []);
    useEffect(() => {
        if (reRender) {

        }
    }, [reRender]);

    useEffect(() => {
        if (!selectedSgfIndustry) fetchIndustry();
    }, []);

    useEffect(() => {
        if (props.saveTreeData) {
            handleSaveBridge(selectedTechnology, rightTreeChecked);
        }
    }, [props.saveTreeData]);

    return (
        <>
            <div className="row" style={{ marginRight: "2px", marginLeft: "2px" }}>
                <div className="col-md-6 CollaborationTaxonomyNewTabLeftContent">

                    <div className="row" style={{ "paddingTop": "2.2vh", "paddingBottom": "7px" }}>
                        <div className="" ><h6 className="font-weight-bold headline">Trend</h6>
                        </div>
                        {tagging?.length > 0 && <div className="ml-auto">
                            {<button type="button" className={`btn  px-3  mr-3 ${leftTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                                onClick={() => deleteTrend()}
                            >Delete</button>}

                            {leftTreeCollapse ? <span className="expandButton btn px-3 mr-3"
                                style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                                onClick={() => setLeftTreeCollapse(false)}
                            >Expand All </span> :
                                <span className="expandButton btn px-3 mr-3"
                                    style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                                    onClick={() => setLeftTreeCollapse(true)}
                                >Collapse All</span>}
                        </div>}

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{ "paddingTop": "1em" }}>
                                {tagging?.length > 0 ?
                                    <SortableTree
                                        treeData={tagging}
                                        onClick={leftTreeOnChecked}
                                        treeCollapse={leftTreeCollapse}
                                        parentId={"parentId"}
                                        flatData={true}
                                        hieghtClass='collaboration-taxonomy-tree-container-wrapper'
                                    />
                                    :
                                    <div className="text-center">
                                        <img
                                            src={noTaxonomyImage}
                                            alt="No Tagging"
                                            width="200"
                                            height="200"
                                        />
                                        <div className="info-container"><p>Select Tags to create Taxonomy</p></div>
                                    </div>}
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className="col-md-6" style={{ background: "#FFFFFF", "border": "1px solid lightgrey", "border-radius": "4px" }}> */}
                <div className="col-md-6 CollaborationTaxonomyNewTabRightContent">
                    {sgfName && <div className="row" style={{ "justifyContent": "left", "paddingBottom": "0.5em", "paddingTop": "2.2vh" }}>
                        {/* <div className="rounded-top pl-2" style={{ "paddingTop": "2.2vh" }}><h6 className="font-weight-bold"></h6></div> */}
                        <div className="col-4" style={{ "marginLeft": "0em" }}>
                            {industry?.length && selectedSgfIndustry && <DropDownListComponent
                                value={selectedSgfIndustry}
                                change={(value) => {
                                    setSgfData({
                                        ...sgfData,
                                        trend_parent_id: value?.itemData.id,
                                        trend_root: value?.itemData.id
                                    });
                                    // setSelectedIndustry(value.itemData)
                                    // setSelectedSgfIndustry(value.itemData.id)
                                    fetchTrend(value.itemData.id);
                                    setSgfTrendData([]);
                                    setTechnologyData([]);
                                    setTaxonomy([]);

                                }}
                                cssClass={'customCss e-outline'}
                                floatLabelType="Auto"
                                popupHeight="250px"
                                dataSource={industry}
                                fields={{ text: 'trendName', value: 'id' }}
                                placeholder="Select Industry *"
                            />}
                        </div>

                        <div className="col-4">
                            {sgfTrendData?.length > 0 && <DropDownListComponent

                                value={sgfName}
                                change={(value) => {
                                    setSgfData({
                                        ...sgfData,
                                        trend_id: value?.value,
                                        trend_name: value?.itemData?.sgfName
                                    });
                                    setSgfName(value?.itemData.id);
                                    fetchSgfTrend(value?.itemData.sgfName);
                                    setTechnologyData(value?.itemData.trend);
                                    setSelectedTechnology(value?.itemData.trend[0].trendId);
                                    fetchTaxonomy(value?.itemData.trend[0].trendId);
                                    // setSelectedSgfIndustry(selectedSgfIndustry)
                                    setIndustry(industry);

                                }}
                                cssClass={'customCss e-outline'}
                                floatLabelType="Auto"
                                popupHeight="250px"
                                dataSource={sgfTrendData}
                                fields={{ text: 'sgfName', value: 'id' }}
                                placeholder="Select SGF"
                            />}
                        </div>

                        <div className="col-4">
                            {technologyData.length > 0 && <DropDownListComponent
                                value={selectedTechnology}
                                cssClass={'customCss e-outline'}
                                floatLabelType="Auto"
                                popupHeight="250px"
                                dataSource={technologyData}
                                fields={{ text: 'trendName', value: 'trendId' }}
                                placeholder="Select Trend"
                                change={(value) => {
                                    setSelectedTechnology(value?.itemData?.trendId);
                                    fetchTaxonomy(value?.itemData?.trendId);
                                    // setSelectedSgfIndustry(selectedSgfIndustry)
                                    setIndustry(industry);
                                }}
                            />}

                        </div>
                    </div>}
                    <div className="row" style={{ "paddingTop": "0.65em" }}>
                        <div className="col-12 p-0">
                            {taxonomy?.length > 0 && <div className="float-right">
                                {/* {<button type="button" className={`btn  px-3  mr-3 ${rightTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                                    onClick={() => {
                                        if (props?.isDelete) { props.setSaveTreeDataAction("save"); }
                                        else { dispatch(showMessage("Tagging not allowed")); }
                                    }}
                                >Add</button>} */}
                                {rightTreeCollapse ? <span className="expandButton btn px-3 mr-3"
                                    style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                                    onClick={() => setRightTreeCollapse(false)}
                                >Expand All </span> :
                                    <span className="expandButton btn px-3 mr-3"
                                        style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                                        onClick={() => setRightTreeCollapse(true)}
                                    >Collapse All</span>}
                            </div>}
                            <div>
                                {taxonomy.length > 0 ?
                                    <SortableTree
                                        treeData={taxonomy}
                                        onClick={
                                            (checkedRight) => {
                                                let ids = checkedRight.map((item) => { return item.id; });
                                                setRightTreeChecked(ids);
                                            }}
                                        treeCollapse={rightTreeCollapse}
                                        checked={checked}
                                        parentId={"trendParentId"}
                                        enablePersistance={true}
                                        expanded={checked}
                                        hieghtClass={'collaboration-taxonomy-tree-container-wrapper'}
                                    />
                                    :
                                    null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Taxonomy;
Taxonomy.propTypes = {
    fetchContentTagging: PropTypes.func.isRequired,
    loadOtherTagInfo: PropTypes.func,
    handleSave: PropTypes.func.isRequired,
    saveTreeData: PropTypes.bool,
    contentId: PropTypes.string.isRequired,
};
