/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News Overview Validation - Add | Edit Overview
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import * as Yup from "yup";
export const objectiveOverviewValidationSchema = {
  objectiveTitle: Yup.string()
    .trim()
    .required("Please enter objective title")
    .max(125, "Objective title must be at most 200 characters")
    .min(1, "Objective title must be at least 1 characters"),
  objectiveCategory: Yup.object().required("Please select objective category").typeError("Please select objective category"),
  objectiveType: Yup.string().required("Please select objective type").typeError("Please select objective type"),
  objectiveCompany: Yup.string().required("Please enter company.").typeError("Please enter company."),
  customForm: Yup.string().required("Please select objective form").typeError("Please select objective form"),
};
