/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News overview - Add | Edit Overview
     1. Import all news overview element from customFormFields
     2 Use Formik for validation
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Survey from "survey-react";
import "survey-creator/survey-creator.css";
import "./survey.css";
import { ObjectiveTextField, RadioInput } from "./customFormFields";
import { DatePicker, Dropdown } from "../../../../../components/CustomFormElements/syncFusionFormFields";
import { fetchSingleObjective, uploadObjectiveFiles, downloadObjectiveFiles } from "../../../../../../middleware/services/businessObjectiveApi";
import { actionError, hideLoader, showLoader } from "../../../../../../middleware/actions/utilityAction";
import { getProductVariant } from "../../../../../../utilities";
import { useDispatch } from "react-redux";
import DragAndDrop from "app/components/DragAndDrop";
import { BO_ACCEPTED_FILES_TYPE, BO_MAXIMUM_FILE_SIZE } from "../../../../../../constants";
import { MdDeleteForever, MdFileDownload } from "react-icons/md";
import FileThumbnail from "app/components/Thumbnail/FileThumbnail";
import { FiUpload } from "react-icons/fi";
import "./styles.css";
import parse from "html-react-parser";

// theme colors
const mainColor = "#343a40";
const mainHoverColor = "#000000"; // black
const textColor = "#4a4a4a";
const headerColor = "#343a40";
const headerBackgroundColor = "#4a4a4a";
const bodyContainerBackgroundColor = "#f8f8f8"; //gray

const OverviewForm = (props) => {
  const objectiveId = props.objectiveId;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [toBeDeletedFilesId, setToBeDeletedFilesId] = useState([]);
  const [objectiveDetails, setObjectiveDetails] = useState({});
  const [existingAttachmentFiles, setExistingAttachmentFiles] = useState([]);
  const [formJson, setFormJson] = useState([]);
  const [formData, setFormData] = useState({});
  const [contentValues, setContentValues] = useState({});
  const [formJsonValues, setFormJsonValues] = useState({});
  const [objectiveStatusDataSource, setObjectiveStatusDataSource] = useState([
    {
      label: "Draft",
      value: "draft",
    },
    {
      label: "Under Review",
      value: "active",
    },
    {
      label: "In Progress",
      value: "under_discussion",
    },
    {
      label: "Complete",
      value: "closed",
    },
    {
      label: "Abeyance",
      value: "abeyance",
    },
  ]);

  const productVariant = getProductVariant();
  const dispatch = useDispatch();
  let defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
  defaultThemeColorsSurvey["$main-color"] = mainColor;
  defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
  defaultThemeColorsSurvey["$text-color"] = textColor;
  defaultThemeColorsSurvey["$header-color"] = headerColor;
  defaultThemeColorsSurvey["$header-background-color"] = headerBackgroundColor;
  defaultThemeColorsSurvey["$body-container-background-color"] = bodyContainerBackgroundColor;
  Survey.StylesManager.applyTheme("default");
  /*
        @Description : Assign default value and bind validation schema
    */
  const formik = useFormik({
    initialValues: props.objectiveInformation,
    // validationSchema: Yup.object().shape(objectiveOverviewValidationSchema)
  });
  const style = {
    label: {
      fontWeight: 600,
    },
  };

  /*
        @Description : Set news data to fields 
    */
  useEffect(() => {
    formik.setValues(props.objectiveInformation);
  }, [props.objectiveInformation]);
  /*
        @Description : Save news data if everything is valid
    */
  useEffect(() => {
    if (props.saveObjectiveContent) {
      let overviewData = {
        objectiveCoverageStartFrom: formik.values.objectiveCoverageStartDate ? new Date(formik.values.objectiveCoverageStartDate) : "",
        objectiveCoverageEnd: formik.values.objectiveCoverageEndDate ? new Date(formik.values.objectiveCoverageEndDate).toISOString() : "",
        objectiveStatus: formik.values.objectiveStatus ? formik.values.objectiveStatus : "",
        objectiveSendUpdates: formik.values.objectiveSendUpdates ? formik.values.objectiveSendUpdates : "",
        objectiveVisibleToPartner: formik.values.objectiveVisibleToPartner ? formik.values.objectiveVisibleToPartner : "",
        objectiveTitleVisible: formik.values.objectiveTitleVisible
          ? {
              value: formik.values.objectiveTitleVisible,
              displayName: formik.values.displayName,
              withPermissionText: formik.values.withPermissionText,
            }
          : { value: "" },
      };

      formik.validateForm().then((res) => {
        if (Object.keys(res).length) {
          const touched = {};
          Object.keys(res).map((field) => {
            touched[field] = true;
            return touched;
          });
          const err =
            res &&
            Object.values(res).map((e) => {
              return e;
            });
          let errors = Object.keys(res);
          if (errors.length > 0) dispatch(actionError(res[errors[0]]));
          formik.setFormikState({ ...formik, touched: touched, errors: res });
        } else {
          setContentValues(overviewData);
          if (document.getElementsByClassName("sv_complete_btn")[0]) {
            document.getElementsByClassName("sv_complete_btn")[0].click();
          } else if (document.getElementsByClassName("sv_preview_btn")[0]) {
            document.getElementsByClassName("sv_preview_btn")[0].click();
          }
        }
      });
      props.handleSaveContent(false);
    }
  }, [props]); // [props.saveNews,nonDt] Removed | trigger on tree data sava

  const initSurvey = () => {
    const form = fetchSingleObjective(objectiveId, {
      fields: [
        "id",
        "objectiveApplicationPlatform",
        "objectiveCategory",
        "objectiveCoverageEnd",
        "objectiveCoverageStartFrom",
        "objectiveCreatedBy",
        "objectiveCreatedDate",
        "objectiveDescription",
        "objectiveForm",
        "objectiveFormAttachments",
        "objectiveIndustrySgfTaxonomy",
        "objectiveIsDeleted",
        "objectiveModifiedDate",
        "objectiveSendUpdates",
        "objectiveStatus",
        "objectiveTaxonomySortSeq",
        "objectiveTitle",
        "objectiveTitleVisible",
        "objectiveVisibleToPartner",
        "objectiveWithPermissionText",
        "objectiveUsers",
        "objectiveVisible",
        "productVariant",
        "uniqueNo",
        "objectiveUserNote",
      ],
    });
    form.then((res) => {
      setObjectiveDetails(res?.data?.data);
      if (res && res?.data?.data?.objectiveForm?.form_json) {
        if (res.data.data.objectiveForm?.form_json) {
          let formJsonData = res.data.data.objectiveForm?.form_json;
          if (formJsonData) {
            if (formJsonData.pages[0]) {
              if (formJsonData.pages[0].elements[0]) {
                if (formJsonData.pages[0].elements[0].name == "objective_title") {
                  formJsonData.pages[0].elements[0].readOnly = true;
                }
              }
            }
          }
          formJsonData.pages[0].elements.forEach((ele) => {
            if (ele.name == "objective_title") {
              ele.validators = [
                {
                  type: "text",
                  minLength: 10,
                  text: "Please enter minimum 10 characters",
                },
              ];
            }
          });
          setFormJson(formJsonData);
        }
        if (res.data.data.objectiveForm?.form_json_submitted) {
          setFormData(res.data.data.objectiveForm?.form_json_submitted);
        }
        if (document.getElementsByClassName("sv_complete_btn")[0]) document.getElementsByClassName("sv_complete_btn")[0].style.display = "none";
      }
    });
    form.catch((e) => {
      console.error(e);
    });
  };

  const getFormData = (data) => {
    data.clear(false, true);
    setFormJsonValues(data.valuesHash);
    saveSurvey(data.valuesHash);
  };

  async function handleUploadFiles() {
    try {
      if (selectedFiles.length === 0) return [];
      dispatch(showLoader());

      const totalSizeOfFiles = selectedFiles.reduce((acc, file) => acc + file.size, 0);

      if (totalSizeOfFiles > BO_MAXIMUM_FILE_SIZE) return dispatch(actionError("Total File size should be less than 50MB."));

      const formData = new FormData();

      selectedFiles.forEach((f, i) => {
        formData.append(`file[${i}]`, f);
      });
      const res = await uploadObjectiveFiles(formData);
      dispatch(hideLoader());

      return res.data;
    } catch (e) {
      dispatch(actionError("There some issue in file attachement."));
    } finally {
      dispatch(hideLoader());
    }
  }

  const saveSurvey = async (jsonValues) => {
    Object.keys(jsonValues).forEach((key) => {
      if (typeof jsonValues[key] === "string") {
        jsonValues[key] = jsonValues[key].trim();
      }
    });
    const data = await handleUploadFiles();
    const files = data?.data || [];

    if (selectedFiles.length > 0) {
      if (files.length === 0 || files.length !== selectedFiles.length)
        return dispatch(actionError("There some issue in uploading all file attachements."));
    }
    const overviewData = {
      formJsonValues: jsonValues,
      ...contentValues,
      objectiveFormAttachmentsDeleted: toBeDeletedFilesId,
      objectiveFormAttachments:
        files?.map((f) => ({
          name: f.docName,
          tempName: f.docFileName,
        })) || [],
    };
    props.handleSaveOverview(overviewData);
  };
  const onUploadFiles = (survey, options) => {
    let formData = new FormData();
    options.files.forEach(function (file) {
      formData.append(file.name, file);
    });
    const responseObj = uploadObjectiveFiles(formData);
    responseObj
      .then((response) => {
        const data = response.data?.data;
        if (data) {
          options.callback(
            "success",
            options.files.map(function (file) {
              const dataFile = data.find((o) => o.file === file.name);
              return {
                file: file,
                content: dataFile.docFileName,
              };
            })
          );
        }
      })
      .catch((e) => {
        dispatch(actionError("There some issue in file attachement."));
      });
  };
  const onDownloadFile = (survey, options) => {
    const fileName = options.content;
    const responseObj = downloadObjectiveFiles(fileName);

    responseObj
      .then((response) => {
        let file = new File([response.data], options.fileValue.name, {
          type: options.fileValue.type,
        });
        let reader = new FileReader();
        reader.onload = function (e) {
          options.callback("success", e.target.result);
        };
        reader.readAsDataURL(file);
      })
      .catch((e) => {
        console.error(e);
        dispatch(actionError("There some issue in file attachement."));
      });
  };
  useEffect(() => {
    initSurvey();
    // ** This is to check if product is Insider then we dont have to show draft option in objective status
    const productVariantIsInsider = productVariant?.startsWith("Insider");
    if (productVariantIsInsider) {
      setObjectiveStatusDataSource((prev) => prev.filter((item) => item.value !== "draft"));
    }
    dispatch(hideLoader());
  }, [objectiveId]);

  useEffect(() => {
    if (!objectiveDetails || !objectiveDetails.objectiveFormAttachments) return;
    setExistingAttachmentFiles(objectiveDetails.objectiveFormAttachments);
  }, [objectiveDetails]);

  return (
    <div className="mt-2 mb-4">
      <form>
        <div className="row" style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Survey.Survey
            json={formJson}
            showQuestionNumbers={"off"}
            data={formData}
            showCompletedPage={false}
            onComplete={getFormData}
            onUploadFiles={onUploadFiles}
            onDownloadFile={onDownloadFile}
          />

          <div style={{ display: "flex", flexDirection: "column", gap: "5px", padding: "0px 20px", margin: 0, marginBottom: "15px" }}>
            <p style={{ fontWeight: "bold", fontSize: "14px", color: "black", height: "fit-content", margin: 0 }}>Attachments</p>
            <p style={{ color: "rgb(107 114 128 / 1)", fontSize: "14px", height: "fit-content", margin: 0 }}>Max file size should be 50MB</p>

            <DragAndDrop
              acceptedFilesFormat={BO_ACCEPTED_FILES_TYPE}
              maxUploadFiles={999}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              multipleUpload={true}
              maxFileSize={BO_MAXIMUM_FILE_SIZE}
              content={
                <div className="dnd-btn">
                  <div className="pr-[10px] ">
                    <FiUpload size="20px" />
                  </div>

                  <button type="button" className="inside-btn">
                    Drag and Drop
                    <strong> or Browse</strong>
                  </button>
                </div>
              }
            />

            <div style={{ marginTop: "5px" }}>
              <p className="note-text" style={{ fontWeight: "bold", margin: 0 }}>
                Note :
              </p>
              <ul className="note-text" style={{ marginLeft: "17px", marginTop: "5px" }}>
                <li className="note-text">Accepts only {BO_ACCEPTED_FILES_TYPE.toString().toUpperCase()}. </li>
                <li className="note-text"> You can upload maximum 50 MB file(s).</li>
              </ul>
            </div>

            <div
              className="flex flex-row gap-2 flex-wrap"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "7px",
                flexWrap: "wrap",
                marginTop: "5px",
              }}
            >
              {[...existingAttachmentFiles, ...selectedFiles]?.map((f, i) => (
                <div
                  className="w-[264px] h-[51px] flex justify-between items-center flex-row gap-1 border border-gray-100 px-2 py-2 rounded-md"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "5px",
                    border: "1px solid #e5e7eb",
                    borderRadius: "5px",
                    padding: "5px 5px",
                    width: "264px",
                    height: "51px",
                  }}
                  key={f?.name || f?.docFileName}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: "5px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                  >
                    <FileThumbnail fileType={f?.name?.split(".")?.pop() || f?.docFileName?.split(".")?.pop()} />
                    <p
                      className=" text-sm truncate"
                      style={{
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {f.name || f?.docName}
                    </p>
                  </div>
                  <div
                    className="border border-gray-100 p-[2px] rounded-sm"
                    style={{
                      border: "1px solid #e5e7eb",
                      padding: "2px",
                      borderRadius: "3px",
                      marginLeft: "40px",
                    }}
                    onClick={(event) => {
                      try {
                        event.preventDefault();
                        const responseObj = downloadObjectiveFiles(f?.docFileName);

                        responseObj.then((response) => {
                          let file = new File([response.data], f?.docName, {
                            type: f?.docFileType,
                          });

                          let link = document.createElement("a");
                          link.href = window.URL.createObjectURL(file);
                          link.download = file.name;
                          link.click();
                        });
                      } catch (error) {
                        dispatch(actionError(error?.message));
                      }
                    }}
                  >
                    <MdFileDownload
                      className="text-xl cursor-pointer text-gray-400"
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        color: "rgb(107 114 128 / 1)",
                      }}
                    />
                  </div>
                  <div
                    className="border border-gray-100 p-[2px] rounded-sm"
                    style={{
                      border: "1px solid #e5e7eb",
                      padding: "2px",
                      borderRadius: "3px",
                    }}
                    onClick={() => {
                      if (objectiveId && f?.docFileName) {
                        setToBeDeletedFilesId((prev) => [...prev, f?.id]);
                        return setExistingAttachmentFiles((prev) => prev.filter((file) => file.docFileName !== f?.docFileName));
                      }
                      setSelectedFiles((prev) => prev.filter((file) => file.name !== f?.name));
                    }}
                  >
                    <MdDeleteForever
                      className="text-xl cursor-pointer text-gray-400"
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        color: "rgb(107 114 128 / 1)",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {objectiveDetails?.objectiveUserNote?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginBottom: "15px", padding: "0px 20px" }}>
              <p style={{ fontWeight: "bold", fontSize: "14px", color: "black", height: "fit-content", margin: 0 }}>Objective Note</p>

              <div
                style={{
                  padding: "10px",
                  // fontSize: "14px",
                  border: "1px solid #e5e7eb",
                  maxHeight: "200px",
                  minHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {parse(objectiveDetails.objectiveUserNote[0]?.noteContent || <></>)}
              </div>
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-8">
                  <DatePicker
                    formik={formik}
                    properties={{
                      placeholder: "Start Coverage From",
                      id: "objectiveCoverageStartDate",
                      name: "objectiveCoverageStartDate",
                      format: "dd MMM yyyy",
                      allowEdit: false,
                      floatLabelType: "Auto",
                      openOnFocus: true,
                    }}
                  />
                </div>
                <div className="col-md-8">
                  <DatePicker
                    formik={formik}
                    properties={{
                      placeholder: "Coverage Ends On",
                      id: "objectiveCoverageEndDate",
                      name: "objectiveCoverageEndDate",
                      format: "dd MMM yyyy",
                      allowEdit: false,
                      floatLabelType: "Auto",
                      openOnFocus: true,
                    }}
                  />
                </div>
                <div className="col-md-8">
                  {objectiveStatusDataSource && (
                    <Dropdown
                      formik={formik}
                      placeholder="Objective Status"
                      id="objectiveStatus"
                      name="objectiveStatus"
                      // value={formik.values.objectiveStatus.value}
                      value={props.objectiveInformation.objectiveStatus.value && formik.values && formik.values.objectiveStatus?.value}
                      fields={{ text: "label", value: "value" }}
                      dataSource={objectiveStatusDataSource}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-1">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label style={style.label} htmlFor="objectiveSendUpdates">
                      Send me periodic progress updates as you research my Objective
                    </label>
                    <br />
                    <div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center" }}>
                      <RadioInput
                        name="objectiveSendUpdates"
                        formik={formik}
                        input={[
                          {
                            label: "Yes",
                            value: "yes",
                            check: false,
                          },
                          {
                            label: "No",
                            value: "no",
                            check: false,
                          },
                        ]}
                        properties={{ required: true }}
                      />
                    </div>
                  </div>
                </div>

                {productVariant == "Insider" && (
                  <div className="col-md-12">
                    <div className="form-group">
                      <label style={style.label} htmlFor="objectiveVisibleToPartner">
                        Make my objective visible to my colleagues, who are also FutureBridge Platform members
                      </label>
                      <br />
                      <div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center" }}>
                        <RadioInput
                          name="objectiveVisibleToPartner"
                          formik={formik}
                          input={[
                            {
                              label: "Yes",
                              value: "yes",
                              check: false,
                            },
                            {
                              label: "No",
                              value: "no",
                              check: false,
                            },
                          ]}
                          properties={{ required: true }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-12">
                  <div className="form-group">
                    <label style={style.label} htmlFor="objectiveTitleVisible">
                      {productVariant == "Insider"
                        ? 'Make my "Objective Title" visible to all other FutureBridge Platform members'
                        : "Make objective visible to WhatNext Innovation Partners"}
                    </label>
                    <br />
                    <div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center" }}>
                      <RadioInput
                        formik={formik}
                        name="objectiveTitleVisible"
                        id="objectiveTitleVisible"
                        input={[
                          {
                            label: "Yes",
                            value: "yes",
                            check: false,
                          },
                          {
                            label: "No",
                            value: "no",
                            check: false,
                          },
                          {
                            label: "With Permission",
                            value: "with_permission",
                            check: false,
                          },
                        ]}
                        menuPlacement="top"
                        properties={{ required: true }}
                      />
                    </div>
                    {formik.values.objectiveTitleVisible == "yes" || formik.values.objectiveTitleVisible == "with_permission" ? (
                      <>
                        <br />
                        <RadioInput
                          formik={formik}
                          name="displayName"
                          id="displayName"
                          input={[
                            {
                              label: "Display Client Name",
                              value: "yes",
                              check: false,
                            },
                            {
                              label: "Keep Client Anonymous",
                              value: "keep_anonymous",
                              check: false,
                            },
                          ]}
                          menuPlacement="top"
                          properties={{ required: true }}
                        />
                      </>
                    ) : null}
                    {formik.values.objectiveTitleVisible == "with_permission" ? (
                      <ObjectiveTextField
                        name="withPermissionText"
                        id="withPermissionText"
                        formik={formik}
                        placeholder="Please specify any condition"
                        properties={{ required: true }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OverviewForm;
