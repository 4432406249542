// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : CK editor version 5 component index file
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created : 9 Jul 2024
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React, { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch } from "react-redux";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  FullPage,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  HtmlComment,
  HtmlEmbed,
  ImageBlock,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  ListProperties,
  PageBreak,
  Paragraph,
  RemoveFormat,
  SimpleUploadAdapter,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Style,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo,
  ImageStyle,
  ImageInline,
  ImageResize,
} from "ckeditor5";

import MyCustomUploadAdapterPlugin, { MyUploadAdapter } from "./uploadAdapter";
import "./ckeditor5.css";
import "./editor.css";
import { showLoader, hideLoader } from "middleware/actions/utilityAction";

export default function CKEditor5({ content, onChange, editorHeight, enableImageUpload = false }) {
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const onImageUploadStart = () => {
    dispatch(showLoader());
  };

  const onImageUploadComplete = () => {
    dispatch(hideLoader());
  };

  const handleEditorReady = (editor) => {
    // Custom upload adapter plugin
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      const uploadAdapter = new MyUploadAdapter(loader, onImageUploadStart, onImageUploadComplete);
      return uploadAdapter;
    };

    // Override the file input behavior to allow only one image at a time
    const fileInput = document?.querySelector("input[class='ck-hidden']");

    if (fileInput) {
      // Restrict to one file
      fileInput.multiple = false; // Disable multiple file selection
      fileInput.setAttribute("accept", "image/*");
    }

    editor.editing.view.change((writer) => {
      writer.setStyle("height", editorHeight || "400px", editor.editing.view.document.getRoot());
      writer.setStyle("overflow-x", "hidden", editor.editing.view.document.getRoot());
    });

    editor.editing.view.document.on("clipboardInput", async (event, data) => {
      const pastedData = data.dataTransfer.getData("text/html");
      const parser = new DOMParser();
      const doc = parser.parseFromString(pastedData, "text/html");

      // Get all images except those with "d111ymjht74ef.cloudfront.net" in the src
      const images = Array.from(doc.querySelectorAll("img")).filter((img) => !img.src.includes("d111ymjht74ef.cloudfront.net"));

      if (images.length > 0) {
        dispatch(showLoader());

        // Stop the default paste action to prevent duplication
        event.stop();

        // Loop through each image, process it, and update the src
        for (const img of images) {
          const src = img.src;
          let blob;

          if (src.startsWith("data:")) {
            const base64Image = src.split(",")[1];
            blob = base64ToBlob(base64Image);
          } else if (src.startsWith("http")) {
            const response = await fetch(src);
            blob = await response.blob();
          }

          if (blob) {
            // Create a file-like object from the blob
            const file = new File([blob], "pasted-image.jpg", { type: blob.type });

            // Use the existing MyUploadAdapter to upload the image
            const uploadAdapter = new MyUploadAdapter({
              file: Promise.resolve(file),
            });

            try {
              const uploadResponse = await uploadAdapter.upload();
              if (uploadResponse && uploadResponse.default) {
                img.src = uploadResponse.default;
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }

        // After processing all images, insert the updated content into the editor
        const viewFragment = editor.data.processor.toView(doc.body.innerHTML);
        const modelFragment = editor.data.toModel(viewFragment);
        editor.model.insertContent(modelFragment, editor.model.document.selection);

        dispatch(hideLoader());
      }
    });
  };

  // Utility function to convert base64 to Blob
  const base64ToBlob = (base64Data, contentType = "image/png") => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const editorConfig = {
    extraPlugins: enableImageUpload ? [MyCustomUploadAdapterPlugin] : [],
    toolbar: {
      initialData: content,
      items: [
        "sourceEditing",
        "|",
        "undo",
        "redo",
        "|",
        "findAndReplace",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "removeFormat",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "outdent",
        "indent",
        "|",
        "link",
        enableImageUpload && "insertImage",
        "insertTable",
        "|",
        "heading",
        "|",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      Autoformat,
      AutoLink,
      Autosave,
      BlockQuote,
      Bold,
      Code,
      CodeBlock,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      FullPage,
      GeneralHtmlSupport,
      Heading,
      Highlight,
      HorizontalLine,
      HtmlComment,
      HtmlEmbed,
      ImageBlock,
      ImageInline,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      List,
      ListProperties,
      PageBreak,
      Paragraph,
      RemoveFormat,
      SimpleUploadAdapter,
      SourceEditing,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Style,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
      Undo,
      ImageStyle,
      ImageResize,
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, 16, 18, 20, 22, 24],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: ["toggleImageCaption", "imageTextAlternative", "|", "imageStyle:inline", "imageStyle:wrapText", "imageStyle:breakText"],
      "upload.types": ["jpeg", "png", "gif", "bmp", "webp", "tiff"],
      panel: enableImageUpload ? true : false,
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    placeholder: "Type or paste your content here!",
    style: {
      definitions: [
        {
          name: "Article category",
          element: "h3",
          classes: ["category"],
        },
        {
          name: "Title",
          element: "h2",
          classes: ["document-title"],
        },
        {
          name: "Subtitle",
          element: "h3",
          classes: ["document-subtitle"],
        },
        {
          name: "Info box",
          element: "p",
          classes: ["info-box"],
        },
        {
          name: "Side quote",
          element: "blockquote",
          classes: ["side-quote"],
        },
        {
          name: "Marker",
          element: "span",
          classes: ["marker"],
        },
        {
          name: "Spoiler",
          element: "span",
          classes: ["spoiler"],
        },
        {
          name: "Code (dark)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-dark"],
        },
        {
          name: "Code (bright)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-bright"],
        },
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
    },
  };

  return (
    <div>
      <div className="main-container">
        <div className="editor-container editor-container_classic-editor editor-container_include-style" ref={editorContainerRef}>
          <div className="editor-container__editor">
            <div ref={editorRef}>
              {isLayoutReady && (
                <CKEditor editor={ClassicEditor} config={editorConfig} onReady={handleEditorReady} onChange={onChange} data={content} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
