import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import moment from "moment";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { getDateFromMongoDate } from "../../../utilities";
import { fetchBusinessObjectiveReport, fetchSingleBusinessObjective } from "../../../middleware/services/reportApi";
import CloseIcon from "../../../assets/images/closeIcon.svg?react";
import CustomSidebar from "./SideBar";
import { StringFilter, yesNoFilter } from "app/components/dataGrid/fliters";
import { actionError, hideLoader, showLoader } from "middleware/actions/utilityAction";
import * as CONSTANTS from "../../../constants/index";
import TreeDataGridNew from "app/components/treeDataGridNew";
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-treegrid";
import "./style.scss";
import { presets } from "utils";

// specify breadcrumb here
const breadCrumbLinks = [{ linkUrl: "/report/internal-user", linkName: "Internal User Report", linkActive: true }];

const LastPublishedDateTemplate = (value) => {
  const column = value.column.field;
  return ["objectiveCreatedDate", "objectiveCompletedDate"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
    <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
  ) : (
    ""
  );
};

const BusinessTaxonomyTemplate = (value) => {
  if (value["objectiveTaxonomyAvailable"] == "NA") return "NO";
  return "YES";
};

const contentAddedTemplate = (value) => {
  if (value["objectiveRelativeContentAdded"] == "NA") return "NO";
  return "YES";
};

const SiderBarGrid = ({ onclose, fetch }) => {
  const [rowData, setRowData] = useState([]);
  const dispatch = useDispatch();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const headerDualCom = (argOne, argTwo) => {
    return (
      <div>
        <span className="header-cell">{argOne}</span>
        <span className="header-cell">{argTwo}</span>
      </div>
    );
  };

  const columnFields = [
    {
      field: "objectiveTitle",
      headerText: "Objective Title",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: "200px",
      minWidth: "200px",
      isFrozen: true,
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Objective", "Title"),
    },
    {
      field: "objectiveDescription",
      headerText: "Description",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "120px",
      minWidth: "120px",
      showInColumnChooser: false,
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Objective", "Description"),
    },
    {
      field: "submittedBy",
      headerText: "Submitted By",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "100px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Submitted", "By"),
    },
    {
      field: "objectiveCompany",
      headerText: "Group",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "100px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Group", "Name"),
    },
    {
      field: "objectiveCreatedDate",
      headerText: "Date of Submission",
      type: "Date",
      headerTextAlign: "Left",
      width: window?.innerWidth <= 1600 ? 110 : "80px",
      minWidth: window?.innerWidth <= 1600 ? 110 : "80px",
      maxWidth: window?.innerWidth <= 1600 ? 110 : "80px",
      textAlign: "Center",
      template: LastPublishedDateTemplate,
      headerTemplate: () => headerDualCom("Date of", "Submission"),
      format: { type: "date", format: "dd-MMM-yyyy" },
    },
    {
      field: "objectiveAcknowledgementEmailSentTo",
      headerText: "Email Sent To",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "120px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Email", "Sent To"),
    },
    {
      field: "pointOfContact",
      headerText: "Point of Contact",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "120px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Point of", "Contact"),
    },
    {
      field: "objectiveStatus",
      headerText: "Status",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: window?.innerWidth < 1500 ? 120 : "80px",
      minWidth: window?.innerWidth < 1500 ? 120 : "80px",
      maxWidth: window?.innerWidth < 1500 ? 120 : "80px",
      // filterTemplate: status,
      headerTemplate: () => headerDualCom("Objective", "Status"),
    },
    {
      field: "objectiveCompletedDate",
      headerText: "Date of Completion",
      type: "Date",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: window?.innerWidth <= 1600 ? 110 : "80px",
      minWidth: window?.innerWidth <= 1600 ? 110 : "80px",
      maxWidth: window?.innerWidth <= 1600 ? 110 : "80px",
      // filterTemplate: DateFilter,
      template: LastPublishedDateTemplate,
      headerTemplate: () => headerDualCom("Date of", "Completion"),
      format: { type: "date", format: "dd-MMM-yyyy" },
    },
    {
      field: "objectiveTaxonomyAvailable",
      headerText: "Taxonomy Created",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: window?.innerWidth < 1500 ? 110 : "80px",
      minWidth: window?.innerWidth < 1500 ? 110 : "80px",
      maxWidth: window?.innerWidth < 1500 ? 110 : "80px",
      template: BusinessTaxonomyTemplate,
      headerTemplate: () => headerDualCom("Taxonomy", "Created"),
      filter: { operator: "equal" },
      filterTemplate: yesNoFilter,
    },
    {
      field: "objectiveRelativeContentAdded",
      headerText: "Related Content",
      type: "String",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: window?.innerWidth < 1500 ? 100 : "80px",
      minWidth: window?.innerWidth < 1500 ? 100 : "80px",
      maxWidth: window?.innerWidth < 1500 ? 100 : "80px",
      template: contentAddedTemplate,
      headerTemplate: () => headerDualCom("Related", "Content"),
      filter: { operator: "equal" },
      filterTemplate: yesNoFilter,
    },
    {
      field: "objectiveIndustry",
      headerText: "Industry",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "100px",
      filterTemplate: StringFilter,
      headerTemplate: () => headerDualCom("Objective", "Industry"),
    },
  ];

  const fetchObjectiveNestedData = useCallback(async () => {
    try {
      showLoaderGrid();
      const response = await fetch();
      if (response?.data?.data.length > 0) setRowData(response?.data?.data);
      hideLoaderGrid();
    } catch (error) {
      hideLoaderGrid();
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchObjectiveNestedData();
  }, []);

  return (
    <React.Fragment>
      <div className="gennx-grid-client-detail-report client-details-user-grid objective-report-grid" style={{ height: "100%" }}>
        <header className="user-grid-header">
          <span className="header-text">Detail View</span>
          <span className="closeIcon" onClick={() => onclose()}>
            <CloseIcon />
          </span>
        </header>
        {rowData?.length > 0 && (
          <TreeDataGridNew
            gridTitle="Objective Details"
            rowData={rowData}
            isPaging={false}
            showResetFilter={true}
            deleteMany={false}
            allowPagination={true}
            showExportButton={true}
            frozenColumns={1}
            exportFileName={"Business Objective Detail Report - Detail View"}
            dropDownFilterFields={["objectiveTaxonomyAvailable", "objectiveRelativeContentAdded"]}
          >
            <ColumnsDirective>
              {columnFields.map((column, index) => (
                <ColumnDirective key={index} {...column} />
              ))}
            </ColumnsDirective>
          </TreeDataGridNew>
        )}
      </div>
    </React.Fragment>
  );
};

const BusinessObjectiveReport = () => {
  const [dateofDelivery, setDateofDelivery] = useState(null);
  const [dateOfSubmission, setDateOfSubmission] = useState(null);
  const [functionClick, setFunctionOnclick] = useState(undefined);
  const [deliveryStartDate, setDeliveryStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [deliveryendDate, setDeliveryEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [submissionStartDate, setSubmissionStartDate] = useState(null);
  const [submissionEndDate, setSubmissionEndDate] = useState(null);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [groupDataSource, setGoupDataSource] = useState([]);
  const [sideBarFilters, setSideBarFilters] = useState([]);
  const [gridRef, setGridRef] = useState(null);
  const sidebarRef = useRef(null);
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const [rowData, setRowData] = useState([]);

  const handelSideBar = () => {
    if (sidebarRef?.current) {
      sidebarRef.current.hide();
    }
    setShowSidePanel(!showSidePanel);
  };

  const toggleSidebar = (rowDataInfo) => {
    const { industry, company } = rowDataInfo;
    let filters = [];

    if (industry && industry !== "Total") filters.push(["objectiveIndustry.industryName", "eq", industry]);
    if (company) filters.push(["objectiveCompany", "eq", company]);

    if (rowDataInfo.column.field == "inProgress") {
      filters.push(["objectiveStatus", "eq", "under_discussion"]);
    }
    if (rowDataInfo.column.field == "yetToStart") {
      filters.push(["objectiveStatus", "eq", "active"]);
    }
    if (rowDataInfo.column.field == "completed") {
      filters.push(["objectiveStatus", "eq", "closed"]);
    }

    setSideBarFilters([...filters]);
    handelSideBar();
  };

  const fetchObjectives = useCallback(
    (params = {}) => {
      const { filters = [] } = params;

      if (dateofDelivery) {
        const [delStartDate, delEndDate] = dateofDelivery;
        if (delEndDate && delStartDate) {
          filters.push(
            ["objectiveCompletedDate", "lte", moment(delEndDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD")],
            ["objectiveCompletedDate", "gte", moment(delStartDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD")]
          );
        }
      }

      if (dateOfSubmission) {
        const [subStartDate, subEndDate] = dateOfSubmission;
        if (subStartDate && subEndDate) {
          filters.push(
            ["objectiveCreatedDate", "lte", moment(subEndDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD")],
            ["objectiveCreatedDate", "gte", moment(subStartDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD")]
          );
        }
      }

      params.filters = [...sideBarFilters, ...filters];
      return fetchSingleBusinessObjective({ ...params });
    },
    [sideBarFilters, dateofDelivery, dateOfSubmission]
  );

  const fnStartDate = () => {
    let dateFrom = new Date();
    let dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - CONSTANTS.BOREPORTDEFAULTDATERANGE);
    const dateStart = dateFrom
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    dateTo.setDate(dateTo.getDate());
    const dateEnd = dateTo
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    setDeliveryStartDate(new Date(dateStart));
    setDeliveryEndDate(new Date(dateEnd));
    setSubmissionStartDate(new Date(dateStart));
    setSubmissionEndDate(new Date(dateEnd));
    setDateofDelivery([dateFrom, dateTo]);
    setDateOfSubmission([dateFrom, dateTo]);
  };

  const dispatch = useDispatch();

  const recordsTemplate = (value) => {
    const a = value[`${value.column.field}`];
    return a ? (
      <span className={value.company === "" ? "span-link-color business-objective-font" : "span-link-color"} onClick={() => toggleSidebar(value)}>
        {a}
      </span>
    ) : (
      <span className={value.company === "" ? " business-objective-font" : ""}>{a}</span>
    );
  };

  const industryTemplate = (value) => {
    return value.company === "" ? value.industry : <span></span>;
  };

  const columnFields = [
    {
      field: "industry",
      headerText: "Industry",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      minWidth: "150px",
      filterTemplate: StringFilter,
      template: industryTemplate,
      allowFiltering: false,
      allowSorting: false,
    },
    {
      field: "company",
      headerText: "Group",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      minWidth: "150px",
      filterTemplate: StringFilter,
      allowFiltering: false,
      allowSorting: false,
    },
    {
      field: "yetToStart",
      headerText: "Yet to Start",
      type: "Number",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "130px",
      minWidth: "130px",
      maxWidth: "130px",
      template: recordsTemplate,
      showInColumnChooser: true,
      allowFiltering: false,
      allowSorting: false,
    },
    {
      field: "inProgress",
      headerText: "In Progress",
      type: "Number",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "130px",
      minWidth: "130px",
      maxWidth: "130px",
      template: recordsTemplate,
      showInColumnChooser: true,
      allowFiltering: false,
      allowSorting: false,
    },
    {
      field: "completed",
      headerText: "Completed",
      type: "Number",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "130px",
      minWidth: "130px",
      maxWidth: "130px",
      template: recordsTemplate,
      showInColumnChooser: true,
      allowFiltering: false,
      allowSorting: false,
    },
    {
      field: "total",
      headerText: "Total",
      type: "Number",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "130px",
      minWidth: "130px",
      maxWidth: "130px",
      template: recordsTemplate,
      showInColumnChooser: true,
      allowFiltering: false,
      allowSorting: false,
    },
  ];

  async function fetchListingBO(params = {}) {
    let filters = [];

    if (dateofDelivery) {
      const [delStartDate, delEndDate] = dateofDelivery;
      if (delEndDate && delStartDate) {
        filters.push(
          ["objectiveCompletedDate", "lte", moment(delEndDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD")],
          ["objectiveCompletedDate", "gte", moment(delStartDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD")]
        );
      }
    }

    if (dateOfSubmission) {
      const [subStartDate, subEndDate] = dateOfSubmission;
      if (subStartDate && subEndDate) {
        filters.push(
          ["objectiveCreatedDate", "lte", moment(subEndDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD")],
          ["objectiveCreatedDate", "gte", moment(subStartDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("YYYY-MM-DD")]
        );
      }
    }
    params.filters = [...filters, ["objectiveApplicationPlatform", "eq", "Insider V2"], ["objectiveIsDeleted", "eq", "NO"]];

    try {
      showLoaderGrid();
      const response = await fetchBusinessObjectiveReport({ ...params });
      if (response?.data?.data) setRowData(response?.data?.data);
      hideLoaderGrid();
    } catch (error) {
      hideLoaderGrid();
      console.error("Error fetching data:", error);
    }
  }

  const handleReset = () => {
    window.location.reload();
  };

  const getGridRef = (gridRefData) => {
    setGridRef(gridRefData);
  };

  const handleCreated = () => {
    showLoaderGrid();
    if (gridRef?.current) {
      gridRef.current.grid.columnChooserSettings.operator = "contains";
    }
    hideLoaderGrid();
  };

  const handleSearch = () => {
    if (dateOfSubmission || dateofDelivery) {
      if ((deliveryStartDate && deliveryendDate) || (submissionEndDate && submissionStartDate)) {
        fetchListingBO();
      } else {
        fnStartDate();
      }
    } else {
      dispatch(actionError("Please choose date of submission or date of delivery."));
    }
  };

  const rowDataBound = (args) => {
    if (args.data.level == 0) {
      args.row.childNodes[0].style.fontWeight = "600";
    }
    (args.row?.childNodes[0]).style.fontSize = "14px";
    (args.row?.childNodes[0]).style.textAlign = "left";
  };

  useMemo(() => {
    if (!deliveryStartDate || !deliveryendDate || !submissionEndDate || !submissionStartDate) fnStartDate();
  }, [deliveryStartDate, submissionEndDate, submissionStartDate, deliveryendDate]);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  useMemo(() => {
    if (deliveryStartDate && deliveryendDate && submissionEndDate && submissionStartDate) fetchListingBO();
  }, [deliveryStartDate, submissionEndDate, submissionStartDate, deliveryendDate]);

  return (
    <div className="px-3 grid-bg-white">
      <div className="col-md-12 rounded-bottom nopadding p-0 bg-white">
        <div className="row mt-2 mb-2 align-items-center date-range-objective-container">
          <div className="busines-objective-date ml-3 mr-3 d-flex justify-content-center grid-container-report-filters pr-0">
            <DateRangePickerComponent
              placeholder="Date of Submission"
              cssClass="e-outline delivery-date-picker"
              openOnFocus={true}
              showClearButton={true}
              format="dd MMM yyyy"
              separator="to"
              floatLabelType="Auto"
              startDate={submissionStartDate}
              endDate={submissionEndDate}
              onChange={(e) => {
                setDateOfSubmission(e.value);
              }}
              autoComplete="off"
              max={new Date()}
              strictMode={true}
              className="delivery"
              presets={presets}
            />
          </div>
          <div className="busines-objective-date d-flex justify-content-center grid-container-report-filters pr-0">
            <DateRangePickerComponent
              placeholder="Date of Delivery"
              cssClass="e-outline submission-date-picker"
              openOnFocus={true}
              showClearButton={true}
              format="dd MMM yyyy"
              separator="to"
              floatLabelType="Auto"
              startDate={deliveryStartDate}
              endDate={submissionEndDate}
              onChange={(e) => {
                setDateofDelivery(e.value);
              }}
              autoComplete="off"
              max={new Date()}
              strictMode={true}
              presets={presets}
            />
          </div>
          <div className="col-md-3 pr-0 mb-1">
            <button type="button" onClick={handleSearch} className="search-btn px-4 btn btn-primary mr-2">
              Search
            </button>
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 ml-2 ">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div
        className={`gennx-grid-client-detail-report  pl-0 ml-0 business-objective-grid business-objective-report ${
          showSidePanel ? "objective-width" : ""
        }`}
      >
        <TreeDataGridNew
          gridTitle="Business Objective Report"
          rowData={rowData}
          isPaging={false}
          showResetFilter={true}
          deleteMany={false}
          functionClick={functionClick}
          showLoader={showLoaderGrid}
          hideLoader={hideLoaderGrid}
          allowPagination={true}
          showExportButton={true}
          exportFileName={"Business Objective Report"}
          rowDataBound={rowDataBound}
          showColumnChooser={!showSidePanel}
          getGridRef={getGridRef}
          // created={handleCreated}
        >
          <ColumnsDirective>
            {columnFields.map((column, index) => (
              <ColumnDirective key={index} {...column} />
            ))}
          </ColumnsDirective>
        </TreeDataGridNew>
      </div>
      {showSidePanel && (
        <CustomSidebar sidebarRef={sidebarRef} isOpen={showSidePanel} sidebarId="objective-report">
          <SiderBarGrid onclose={handelSideBar} fetch={fetchObjectives} />
        </CustomSidebar>
      )}
    </div>
  );
};

export default BusinessObjectiveReport;
