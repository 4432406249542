
export const CATEGORY_OPTIONS = [{
    label: "Client",
    value: "Client",
},
{
    label: "Internal Demo",
    value: "Internal Demo",
},
{
    label: "Client Demo",
    value: "Client Demo",
}]

export const WHATNEXT_OBJECTIVE_TYPE = [{
    label: "Knowledge Actionable Insights",
    value: "learning",
},
{
    label: "Strategy",
    value: "strategic",
},
{
    label: "Implementation",
    value: "execution",
}]

export const INSIDER_OBJECTIVE_TYPE = [{
    label: "Technology scouting and solution assessment",
    value: "Technology scouting and solution assessment",
    productVariant: "Insider"
},
{
    label: "Innovation hotspots and roadmap",
    value: "Innovation hotspots and roadmap",
},
{
    label: "Player scouting and partner assessment",
    value: "Player scouting and partner assessment",
},
{
    label: "Competitive and supplier intelligence",
    value: "Competitive and supplier intelligence",
},
{
    label: "Market and regulatory assessment",
    value: "Market and regulatory assessment",
},
{
    label: "Custom bulletins",
    value: "Custom bulletins",
},
{
    label: "Other",
    value: "Other",
}]

