// ‘Copyright ©2023, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description :Tags listing section used in Customizable Widgets
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :05 Oct 2023
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React from 'react';
import classes from './CommonTag.module.css';

// common tag section used in most of the components
const CommonTag = (props) => {
  return (
    <div>
      <p className={classes.key_report}>{props.label}</p>
    </div>
  );
};

export default CommonTag;
