/**
 * @fileoverview This file serves as drag and drop component.
 * @date 14/Mar/2023
 * @author Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
 */
import { useDropzone } from "react-dropzone";
import React from "react";
import { useDispatch } from "react-redux";
import { actionError } from "middleware/actions/utilityAction";
// import { toast } from "react-toastify";

export default function DragAndDrop(props) {
  const {
    acceptedFilesFormat,
    maxUploadFiles = 0,
    children,
    content,
    selectedFiles = [],
    setSelectedFiles,
    multipleUpload = false,
    handleDocument,
    objectKey = "",
    existingFiles = [],
    maxFileSize,
  } = props;
  const dispatch = useDispatch();
  const { getRootProps, getInputProps } = useDropzone({
    accept: { acceptedFilesFormat: [acceptedFilesFormat] },
    maxSize: maxFileSize,
    // maxFiles: maxUploadFiles, //comment this line if upload multiple File
    onDrop: (acceptedFiles, fileRejections) => {
      acceptedFiles.forEach((file) => (file.createdDate = new Date()));
      if (selectedFiles.length > 0) {
        // Existing files validation
        const fileValidation = selectedFiles.some((result) => acceptedFiles.some((r) => String(r.name) === String(result.name)));
        if (fileValidation) {
          dispatch(actionError("File with same name is already exists"));
          return setSelectedFiles(selectedFiles);
        }
      }
      if (existingFiles && existingFiles.length > 0) {
        let fileNameErrorCount = 0;
        existingFiles.forEach((result) => {
          const fileName = result.docName;
          acceptedFiles.forEach((resp) => {
            if (fileName === resp.name) fileNameErrorCount++;
          });
        });
        if (fileNameErrorCount > 0) {
          dispatch(actionError("File with same name is already exists"));
          return setSelectedFiles(selectedFiles);
        }
      }

      // For large files.
      if (fileRejections && fileRejections[0]?.errors[0].code === "file-too-large") return dispatch(actionError(`Attachments exceed 5 MB limit.`));

      if (fileRejections && fileRejections[0]?.errors[0]?.message === "Too many file(s)")
        return dispatch(actionError(`You can upload maximum ${maxUploadFiles} files.`));

      // for multiple files.
      if (fileRejections && fileRejections[0]?.errors[0].code === "file-invalid-type")
        return dispatch(actionError(`You can upload only ${acceptedFilesFormat} file(s).`));

      const totalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0);
      if (totalSize > maxFileSize) {
        return dispatch(actionError(`You can upload upto ${maxFileSize / (1024 * 1024)} MB.`));
      }

      if (objectKey) return handleDocument?.(objectKey, [...acceptedFiles, ...selectedFiles]);
      if (multipleUpload) return setSelectedFiles([...acceptedFiles, ...selectedFiles]);
      setSelectedFiles(acceptedFiles);
    },
  });
  return (
    <div style={{ display: "inline-block" }}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {content}
      </div>

      {selectedFiles?.length > 0 && children}
    </div>
  );
}
