/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  News Overview component - Add | Edit Overview
 ---------------------------------------------------------------------------------
	Creation Details
	Date Created				: 20/Aug/2020
	Author						: Aditya Tijare
================================================================
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showLoader } from "../../../../../../middleware/actions/utilityAction";
import OverviewForm from "./form";

const DeliverableOverview = (props) => {
  const { deliverableId, deliverableInformation, setDeliverableInformation, fetchDeliverable } = props;
  const dispatch = useDispatch();
  const [deliverableImage, setDeliverableImageSrc] = useState(undefined);
  useEffect(() => {
    if (deliverableId) {
      dispatch(showLoader());
      fetchDeliverable(deliverableId);
    }
  }, [deliverableId]);
  return (
    <div className="gennx-content-wrapper deliverable-wrapper mt-3 mr-2 ml-1">
      <div className="mx-2">
        {/* <Accordion heading="Overview" stepCompleted={deliverableId !== "" ? true : false} step={1} activeState={true}> */}
        <div className="gennx-single px-3 pt-2">
          <OverviewForm
            deliverableInformation={deliverableInformation}
            setDeliverableInformation={setDeliverableInformation}
            // handleSaveOverview={handleSaveOverview}
            deliverableImage={deliverableImage}
            {...props}
          />
          {/* </Accordion> */}
        </div>
      </div>
    </div>
  );
};

export default DeliverableOverview;
