import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import CustomFormsListing from "../app/views/customForm/index";
import CustomForm from "../app/views/customForm/customForm";

const CUSTOM_FORM = ["Form:FULL"];

const CustomFormRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/custom-forms"
      component={CustomFormsListing}
      pageHeading="GenNx CMS - Request Forms"
      accessRights={{
        moduleName: "Request Forms",
        parentModuleName: "Client Custom Services",
        moduleAttribute: [...CUSTOM_FORM],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path={"/custom-forms/:formId/basic-details"}
      component={CustomForm}
      pageHeading="GenNx CMS - Request Forms"
      accessRights={{
        moduleName: "Request Forms",
        parentModuleName: "Client Custom Services",
        moduleAttribute: [...CUSTOM_FORM],
        interfaceName: "Basic",
      }}
    />

    <AuthLayout
      exact
      path="/custom-forms/:formId/form-designer"
      component={CustomForm}
      pageHeading="GenNx CMS - Request Forms"
      accessRights={{
        moduleName: "Request Forms",
        parentModuleName: "Client Custom Services",
        moduleAttribute: [...CUSTOM_FORM],
        interfaceName: "Basic",
      }}
    />
    <AuthLayout
      exact
      path="/custom-forms/basic-details"
      component={CustomForm}
      pageHeading="GenNx CMS - Request Forms"
      accessRights={{
        moduleName: "Request Forms",
        parentModuleName: "Client Custom Services",
        moduleAttribute: [...CUSTOM_FORM],
        interfaceName: "Basic",
      }}
    />
  </Switch>
);

export default CustomFormRoutes;
