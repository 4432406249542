/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description :  Company Constants (Adderess type and funding  type constants)
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 25/Jul/2020 
	Author						: Anand Kumar
================================================================ 
*/

export default [{
	"label": "Pre-Seed",
	"value": "Pre-Seed"
},
{
	"label": "Seed",
	"value": "Seed"
},
{
	"label": "Series A",
	"value": "Series A"
},
{
	"label": "Series B",
	"value": "Series B"
},
{
	"label": "Series C",
	"value": "Series C"
},
{
	"label": "Series D",
	"value": "Series D"
},
{
	"label": "Series E",
	"value": "Series E"
},
{
	"label": "Series F",
	"value": "Series F"
},
{
	"label": "Series G",
	"value": "Series G"
},
{
	"label": "Series H",
	"value": "Series H"
},
{
	"label": "Series I",
	"value": "Series I"
},
{
	"label": "Series J",
	"value": "Series J"
},
{
	"label": "Venture - Series Unknown",
	"value": "Venture - Series Unknown"
},
{
	"label": "Angel",
	"value": "Angel"
},
{
	"label": "Private Equity",
	"value": "Private Equity"
},
{
	"label": "Debt Financing",
	"value": "Debt Financing"
},
{
	"label": "Convertible Note",
	"value": "Convertible Note"
},
{
	"label": "Grant",
	"value": "Grant"
},
{
	"label": "Corporate Round",
	"value": "Corporate Round"
},
{
	"label": "Equity Crowdfunding",
	"value": "Equity Crowdfunding"
},
{
	"label": "Product Crowdfunding",
	"value": "Product Crowdfunding"
},
{
	"label": "Secondary Market",
	"value": "Secondary Market"
},
{
	"label": "Post-IPO Equity",
	"value": "Post-IPO Equity"
},
{
	"label": "Post-IPO Debt",
	"value": "Post-IPO Debt"
},
{
	"label": "Post-IPO Secondary",
	"value": "Post-IPO Secondary"
},
{
	"label": "Non-equity Assistance",
	"value": "Non-equity Assistance"
},
{
	"label": "Initial Coin Offering",
	"value": "Initial Coin Offering"
},
{
	"label": "Funding Round",
	"value": "Funding Round"
},
{
	"label": "Venture Round",
	"value": "Venture Round"
},
{
	"label": "Non Equity Assistance",
	"value": "Non Equity Assistance"
},
{
	"label": "Seed Round",
	"value": "Seed Round"
},
{
	"label": "Other",
	"value": "Other"
}
];

export const addressType = [
	{ value: "Headquarter", label: "Headquarter" },
	{ value: "Plant", label: "Plant" },
	{ value: "R&D Centre", label: "R&D Centre" },
	{ value: "Branch", label: "Branch" },
	{ value: "Others", label: "Others" }
];
export const companyEmployeeSize = [
	{
		label: "1 - 10",
		value: "1 - 10"
	},
	{
		label: "11 - 50",
		value: "11 - 50"
	},
	{
		label: "51 - 100",
		value: "51 - 100"
	},
	{
		label: "101 - 500",
		value: "101 - 500"
	},
	{
		label: "501 - 1000",
		value: "501 - 1000"
	},
	{
		label: "1001 - 5000",
		value: "1001 - 5000"
	},
	{
		label: "5001 - 10000",
		value: "5001 - 10000"
	},
	{
		label: "10000+",
		value: "10000+"
	},
];

export const COLLABORATION_TYPES = {
    ACQUISITION:"Acquisition",
    TECHNOLOGY_CO_DEVELOPMENT:"Technology Co-development",
    TECHNOLOGY_ADOPTION:"Technology Adoption",
    FUNDING_INVESTMENT:"Funding/Investment",
    COMMERCIAL_PARTNERSHIP:"Commercial Partnership",
    IN_LICENSING:"In-Licensing",
    PARTNERSHIP_JOINT_VENTURE:"Partnership/Joint Venture"
}