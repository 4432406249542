/**
 * File Description: Constant declarations
 * Author: Suraj Varane
 * Date: 15/Mar/2021
 */
export default {
  supportedImageFormats: [
    //jpg or jpeg
    "image/jpeg",
    // png images
    "image/png",
  ],
  SURVEY_TYPE: {
    ONGOING: "Ongoing",
    ONETIME: "One Time",
  },
  SURVEY_STATUS: {
    DRAFT: "Draft",
    NOT_STARTED: "Not Started",
    OPEN: "Open",
    CLOSED: "Closed",
  },
  SURVEY_INVITATION_STATUS: {
    NOT_STARTED: "Not Started",
    SENDING: "Sending...",
    SENT: "Sent",
  },
  YES: "YES",
  NO: "NO",
  SURVEY_AUDIENCE: {
    PUBLIC: "Public",
    LIMITED: "Limited",
  },
  SURVEY_DOMAIN_WHATNEXT: import.meta.env.VITE_SURVEY_DOMAIN_WHATNEXT || "survey.whatnextglobal.com",
  SURVEY_DOMAIN_INSIDER: import.meta.env.VITE_SURVEY_DOMAIN_INSIDER || "survey.industryinsider.com",
  SURVEY_DOMAIN_COGNITION: import.meta.env.VITE_SURVEY_DOMAIN_COGNITION || "survey.cognitionsolutions.com",
};
