/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  People Education Component
     Summary : - Display Listing of Education Qualification of People
               - Add and Edit Education Links are availablein this file 
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 27/Apr/2021
    Author						: Sandeep Sharma
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import Accordion from "../../../../components/accordion";
import AddEducationModel from "./add";
import { fetchEducation, deleteEducation } from "../../../../../middleware/services/peopleApi";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";

const Education = (props) => {
  const peopleId = props.peopleId;
  const [showModal, setShowModal] = useState(false);
  const [eduData, setEduData] = useState([]);
  const [stepCompleted, setStepCompleted] = useState(false);
  const dispatch = useDispatch();
  const [eduId, setEduId] = useState("");

  const closeModal = () => {
    setEduId("");
    setShowModal(false);
  };
  /*
		@Description : BreadCrub Creation
	*/
  const breadCrumbLinks = [{ linkUrl: "/people", linkName: props?.moduleName || "People", linkActive: true }];

  /* 
		@Description : Use for setting breadcrumb values
	*/

  useEffect(() => {
    if (peopleId !== "") {
      formatRowData(peopleId);
    }
    localStorage.removeItem("selectedPeople");
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
      localStorage.removeItem("selectedPeople");
    };
  }, []);

  // formatRowData function required to format rowData for datGrid
  const formatRowData = async (peopleId) => {
    dispatch(showLoader());
    try {
      let formatedRowData = [];
      const response = await fetchEducation(peopleId);
      if (response.status == 200) {
        dispatch(hideLoader());
        let peopleData = response.data.data;
        let educations = peopleData.peopleEducation;
        if (educations.length > 0) {
          setStepCompleted(true);
          educations.forEach((edu) => {
            let data = {};
            data["eduId"] = edu.id;
            data["eduInstituteName"] = edu.eduInstituteName;
            data["eduQualification"] = edu.eduQualification;
            data["eduPassingYear"] = edu.eduPassingYear;
            formatedRowData.push(data);
          });
        }
      }
      setEduData(formatedRowData);
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  const removeEdu = (eduId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          deleteEducation(peopleId, eduId)
            .then((response) => {
              dispatch(actionSuccess("Education details deleted successfully"));
              formatRowData(peopleId);
            })
            .catch((err) => {
              dispatch(actionError(err.data?.message || "Fail to delete Education"));
            });
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Do you want to delete this item?",
        title: "dialogAlertCssWarning",
      })
    );
  };

  return (
    <>
      {showModal && (
        <AddEducationModel showModal={showModal} closeModal={closeModal} peopleId={peopleId} eduId={eduId} formatRowData={formatRowData} />
      )}
      <Accordion heading="Education" step={3} stepCompleted={eduData && eduData.length ? true : false}>
        <div className="card border-0" style={{ width: "100%" }}>
          <ul className="list-group list-group-flush">
            {eduData.map(function (x, i) {
              return (
                <li key={i} className="list-group-item">
                  <div className="col-md-12 pt-2">
                    <small className="text-muted">University</small>
                    {props?.accessActionDeleteEducation ? (
                      <a
                        className="btn btn-light cy-btn mr-3 float-right"
                        onClick={() => {
                          removeEdu(x["eduId"]);
                        }}
                      >
                        Delete
                      </a>
                    ) : null}
                    {props?.accessActionEditEducation ? (
                      <a
                        className="btn btn-light cy-btn mr-3 float-right"
                        onClick={() => {
                          showModal && setShowModal(false);
                          setShowModal(true);
                          setEduId(x["eduId"]);
                        }}
                      >
                        Edit
                      </a>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    <span className="font-bold-500">{x["eduInstituteName"]}</span>
                  </div>
                  <div className="col-md-12 pt-2">
                    <small className="text-muted">Qualification</small>
                  </div>
                  <div className="col-md-12">
                    <span className="font-bold-500"> {x["eduQualification"]}</span>
                  </div>
                  <div className="col-md-12 pt-2">
                    <small className="text-muted">Passing Out Year</small>
                  </div>
                  <div className="col-md-12">
                    <span className="font-bold-500"> {x["eduPassingYear"]}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        {props?.accessActionAddEducation ? (
          <div className="p-2 ml-2 border-0">
            <a className="add-link" onClick={() => setShowModal(true)}>
              {" "}
              + Add Education
            </a>
          </div>
        ) : null}
      </Accordion>
    </>
  );
};
export default Education;
