/**
 * @fileDescription Patent API
 * @author Yogesh
 * @date 21/Apr/2021
 */

import { PATENT_API as API } from "../../config/apiConfig";
import { PATENT_URL } from "../../config";

// export data to download excel
export const exportJsonData = (payload) => {
  return API({
    url: `/patent/export`,
    method: "POST",
    data: payload,
  });
};
// Download File after export data
export const downloadFileUrl = (fileName) => {
  return `${PATENT_URL}/patent/download/${fileName}`;
};

// all news
export const fetchPatent = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/patent/all",
    method: "GET",
    params: params,
  });
};

// Fetch Single News
export const fetchSinglePatent = (patentId, params = "") => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/patent/all/${patentId}`,
    method: "GET",
    params: params,
  });
};

export const fetchPatentPage = (patentId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/patent/${patentId}/page`,
    method: "GET",
    params: params,
  });
};

// Fetch News Non Dat Categories
export const fetchPatentCategorization = (params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/patent/category`,
    method: "GET",
    params: params,
  });
};

// Fetch Patent newsletter tags
export const fetchPatentletterTags = () => {
  return API({
    url: `/patent/newsletter-tag`,
    method: "GET",
  });
};

// Product Taxonomy Routes

export const fetchPatentTaxonomy = (patentId) => {
  return API({
    url: `/patent/${patentId}/taxonomy/technology`,
    method: "GET",
  });
};

export const fetchPatentValueChain = (patentId) => {
  return API({
    url: `/patent/${patentId}/taxonomy/industry-value-chain`,
    method: "GET",
  });
};

export const fetchPatentTrend = (patentId) => {
  return API({
    url: `/patent/${patentId}/taxonomy/trend`,
    method: "GET",
  });
};

// save News (Overview) Info
export const saveOverview = (payload) => {
  return API({
    url: `/patent/all`,
    method: "POST",
    data: payload,
  });
};

// save News (Overview) Info
export const updateOverview = (patentId, payload) => {
  return API({
    url: `/patent/all/${patentId}`,
    method: "PUT",
    data: payload,
  });
};

// save News Taxonomy
export const savePatentTaxonomy = (patentId, payload) => {
  return API({
    url: `patent/${patentId}/taxonomy/technology`,
    method: "POST",
    data: payload,
  });
};

// save News Content
export const savePatentContent = (patentId, payload) => {
  return API({
    url: `patent/${patentId}/page`,
    method: "POST",
    data: payload,
  });
};

export const updatePatentContent = (patentId, pageId, payload) => {
  return API({
    url: `patent/${patentId}/page/${pageId}`,
    method: "PUT",
    data: payload,
  });
};

// save News Trend
export const savePatentTrend = (patentId, payload) => {
  return API({
    url: `patent/${patentId}/taxonomy/trend`,
    method: "POST",
    data: payload,
  });
};

// delete News Trend
export const deletePatentTrend = (patentId, trendId) => {
  return API({
    url: `patent/${patentId}/taxonomy/trend/${trendId}`,
    method: "DELETE",
  });
};

// save News Trend
export const savePatentValueChain = (patentId, payload) => {
  return API({
    url: `patent/${patentId}/taxonomy/industry-value-chain`,
    method: "POST",
    data: payload,
  });
};

// delete News Trend
export const deletePatentValueChain = (patentId, trendId) => {
  return API({
    url: `patent/${patentId}/taxonomy/industry-value-chain/${trendId}`,
    method: "DELETE",
  });
};

export const deletePatentTaxonomy = (patentId, taxonomyId) => {
  return API({
    url: `patent/${patentId}/taxonomy/technology/${taxonomyId}`,
    method: "DELETE",
  });
};

// save News Taxonomy
export const updatePatent = (patentId, payload1) => {
  console.log("payload");
  console.log(payload1);
  return API({
    url: `patent/all/${patentId}`,
    method: "PUT",
    data: payload1,
  });
};

export const deletePatent = (patentId) => {
  return API({
    url: `/patent/${patentId}`,
    method: "DELETE",
  });
};

/* export const deleteManyPatent = (params = {}, payload) => {
	let { filters = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: "/patent",
		method: "DELETE",
		params: params,
		data: payload
	});
}; */
//delete People
export const deleteManyPatent = (payload, params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/patent",
    method: "DELETE",
    params: params,
    data: payload,
  });
};

export const fetchNewsImageUrl = (fileName) => {
  return `${PATENT_URL}/patent/image/${fileName}`;
};

export const fetchSinglePage = (patentId, fileName) => {
  return API({
    url: `/patent/${patentId}/page/${fileName}`,
    method: "GET",
  });
};

// tag objective
export const savePatentObjective = (payload) => {
  return API({
    url: "/patent/objective",
    method: "POST",
    data: payload,
  });
};

//tag objective taxonomy
export const savePatentObjectiveTaxonomy = (payload) => {
  return API({
    url: "/patent/objective/taxonomy",
    method: "POST",
    data: payload,
  });
};

export const fetchPatentObjectiveTaxonomy = (objectiveId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/patent/objective/taxonomy/${objectiveId}`,
    method: "GET",
    params: params,
  });
};

export const deletePatentObjective = (objectiveId, payload) => {
  return API({
    url: `/patent/objective/${objectiveId}`,
    method: "DELETE",
    data: payload,
  });
};

// Get Upload Patent
export const getUploadPatent = (surveyId, params = {}) => {
  let { fields = undefined, filters = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  if (filters) params.filters = JSON.stringify(filters);

  return API({
    url: `/survey/${surveyId}/audience`,
    method: "GET",
    params: params,
  });
};

// Add survey audience
export const addUploadPatent = (surveyId, payload) => {
  return API({
    url: `/survey/${surveyId}/audience`,
    method: "POST",
    data: payload,
  });
};
// get upload Patent template
export const fetchImportTemplateFileUrl = () => {
  return `${PATENT_URL}/patent/template/import`;
};

// get upload Patent Error filetemplate
export const fetchImportErrorFile = (fileName) => {
  return `${PATENT_URL}/patent/template/importerror/${fileName}`;
};

// import excel data template for patent
export const uploadTemplateData = (batchId, payload) => {
  return API({
    url: `/patent/${batchId}/import`,
    method: "POST",
    data: payload,
  });
};
