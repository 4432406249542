/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : External User Listing
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionStart, actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { swap, getProductVariant, fetchTechTrendData } from "../../../../utilities";
import CommonDataGrid from "../../../components/dataGrid";
import { StringFilter, BooleanFilter, DropDownFilter } from "../../../components/dataGrid/fliters";
import { fetchExternalUser, deleteUser, deleteManyUser, activateUser } from "../../../../middleware/services/userApi";
import { fetchLanguages, fetchFunctionalExpertiseData, fetchTaxonomyIndustry, fetchTaxonomyTrend } from "../../../../middleware/services/cmsApi";
import { COMMON_ACCESS_ACTION, PRODUCT_VARIANT } from "../../../../constants";
import ThreeDotMenu from "../../../components/threeDotMenu";
import ReactTooltip from "react-tooltip";
import { setMaintainedState, visitRecord } from "../../../../middleware/actions/gridAction";
import { resetRedux } from "../../../../middleware/actions/taxonomyFilterAction";

// specify column names for column fields in datGrid here
const columnNames = {
  id: "id",
  Email: "userEmail",
  "First Name": "userFname",
  "Last Name": "userLname",
  Status: "userActive",
  Vendor: "userVendorName",
  Language: "userLanguageExpertise",
  Functional: "userFunctionalExpertise",
  Industry: "userCompentencyIndustry",
  "Is Register": "userIsRegister",
};
if (getProductVariant() && getProductVariant() === PRODUCT_VARIANT.INSIDER) columnNames["Trend"] = "userCompentencyTechnology.trendId";
else columnNames["Technology"] = "userCompentencyTechnology.dtId";

const userActiveTemplate = (value) => {
  return <span className="ml-n2">{value.Status ? <span>Active</span> : <span>Inactive</span>}</span>;
};

// user dashboard component
const UserDashBoard = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteId, setDeleteId] = useState(undefined);
  const [techTrendData, setTechTrendData] = useState(undefined);
  const [languages, setLanguages] = useState([]);
  const [functionalExpertise, setFunctionalExpertise] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [trends, setTrends] = useState([]);
  const gridState = useSelector((state) => state.gridState);

  /* Common access */
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionInactive = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.INACTIVE);
  const accessActionReactivate = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.REACTIVATE);
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);

  // specify breadcrumb here
  const breadCrumbLinks = [{ linkUrl: "/external-user", linkName: aR.moduleName, linkActive: true }];

  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const deleteSingleUser = (data) => {
    dispatch(actionStart());
    deleteUser(data.Id)
      .then((res) => {
        dispatch(actionSuccess("User Deleted"));
        setDeleteId(new Date());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Delete User Failed";
        dispatch(actionError(errMsg));
      });
  };
  const activateSingleUser = (data) => {
    dispatch(actionStart());
    activateUser(data.Id)
      .then((res) => {
        dispatch(actionSuccess("User Activated"));
        history.push(`/external-user/${data.id}/edit#profile`, { userId: data.id });
        setDeleteId(new Date());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Activate User Failed";
        dispatch(actionError(errMsg));
      });
  };
  /* 
		@Description : delete many User on checkbox select
	*/
  const deleteManyFunc = (flag) => {
    const selectedExtUsers = localStorage.getItem("selectedExtUsers") ? JSON.parse(localStorage.getItem("selectedExtUsers")) : [];
    if (flag) {
      if (selectedExtUsers.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteManyUser({ userIds: selectedExtUsers })
                .then((response) => {
                  if (response.data.data) {
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete users"));
                });
            },
            content: "Are you sure, you want to delete this record?",
            okText: "Delete",
            cancelText: "Cancel",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };
  const userNameTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(`/external-user/${value.id}/edit#profile`, { userId: value.id });
          dispatch(visitRecord());
        }}
      >
        {value["First Name"]}
      </span>
    );
  };
  const techTemplate = (value) => {
    let val = getProductVariant() && getProductVariant() === PRODUCT_VARIANT.INSIDER ? value["Trend"] : value["Technology"];
    return (
      <>
        <span data-tip={val}>{val.length > 40 ? val.substring(0, 40) + "..." : val}</span>
        {val.length > 40 && <ReactTooltip />}
      </>
    );
  };
  const userLanguageTemplate = (value) => {
    let val = value["Language"];
    return (
      <>
        <span data-tip={val}>{val?.length > 40 ? val.substring(0, 40) + "..." : val}</span>
        {val?.length > 40 && <ReactTooltip />}
      </>
    );
  };
  const userFunctionalTemplate = (value) => {
    let val = value["Functional"];
    return (
      <>
        <span data-tip={val}>{val?.length > 40 ? val.substring(0, 40) + "..." : val}</span>
        {val?.length > 40 && <ReactTooltip />}
      </>
    );
  };

  // Dropdown Filter template
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Is Register":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Technology":
        sourceData = techTrendData;
        break;
      case "Trend":
        sourceData = techTrendData;
        break;
      case "Language":
        sourceData = languages;

        break;
      case "Functional":
        sourceData = functionalExpertise;
        break;
      case "Industry":
        sourceData = industry;
        break;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  /* 
		@Description : Template Component for action column
	*/
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                history.push(`/external-user/${value.id}/edit#profile`);
                dispatch(visitRecord());
              },
            }
          : {})}
        {...(value.Status && accessActionInactive
          ? {
              Inactive: () => {
                dispatch(
                  showAlertBox({
                    okCallback: deleteSingleUser,
                    data: value,
                    content: "Are you sure, you want to mark user as Inactive?",
                    okText: "Inactive",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
        {...(!value.Status && accessActionReactivate
          ? {
              Reactivate: () => {
                dispatch(
                  showAlertBox({
                    okCallback: activateSingleUser,
                    data: value,
                    content: "Are you sure, you want to mark user as Active?",
                    okText: "Active",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
      />
    );
  };
  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean
  const columnFields = [
    {
      field: "First Name",
      type: "String",
      template: userNameTemplate,
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 150,
    },
    { field: "Last Name", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 150 },
    { field: "Email", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 200 },
    { field: "Vendor", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 150 },
    {
      field: `${getProductVariant() && getProductVariant() === PRODUCT_VARIANT.INSIDER ? "Trend" : "Technology"}`,
      template: techTemplate,
      headerText: "Technology Comp.",
      type: "String",
      filter: { operator: "contains" },
      filterTemplate: ItemFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 200,
    },
    {
      field: "Language",
      headerText: "Language Comp.",
      type: "String",
      filter: { operator: "contains" },
      filterTemplate: ItemFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 200,
      template: userLanguageTemplate,
    },
    {
      field: "Functional",
      headerText: "Functional Comp.",
      type: "String",
      template: userFunctionalTemplate,
      filter: { operator: "contains" },
      filterTemplate: ItemFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 200,
    },

    { field: "Is Register", type: "String", filterTemplate: ItemFilter, width: 150, textAlign: "Center", headerTextAlign: "Center" },
    {
      field: "Status",
      type: "Boolean",
      template: userActiveTemplate,
      filterTemplate: BooleanFilter,
      width: 150,
      textAlign: "Center",
      headerTextAlign: "Center",
    },
  ];
  if (accessActionEdit || accessActionInactive || accessActionReactivate) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false, textAlign: "Center" });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  const fetchExternalUserWithProductVariant = (params = {}) => {
    let defaultFilter = [];
    const productVariant = getProductVariant();
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchExternalUser(params);
    }
    return fetchExternalUser(params);
  };
  const fetchTechData = async () => {
    setTechTrendData(await fetchTechTrendData(getProductVariant));
  };

  /**
   * @description formatRowData function required to format rowData for datGrid
   * @param {Array} rowData
   */
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
        if (rD.userCompentencyIndustry) {
          newRD.Industry = rD?.userCompentencyIndustry
            .map((item) => {
              return item.industryName;
            })
            .join(", ");
        }
        if (rD.userFunctionalExpertise) {
          newRD.Functional = rD?.userFunctionalExpertise
            .map((item) => {
              return item.experty;
            })
            .join(", ");
        }
        if (rD.userLanguageExpertise) {
          newRD.Language = rD?.userLanguageExpertise
            .map((item) => {
              return item;
            })
            .join(", ");
        }
        if (getProductVariant() && getProductVariant() === PRODUCT_VARIANT.INSIDER) {
          if (rD.userCompentencyTechnology && rD.userCompentencyTechnology.trendId && rD.userCompentencyTechnology.trendId.length) {
            const ids = rD.userCompentencyTechnology.trendId;

            const trendData = techTrendData.filter((item) => {
              return ids.includes(item.id);
            });
            newRD.Trend = trendData
              .map((item) => {
                return item.text;
              })
              .filter((trend, index, self) => trend !== "" && self.indexOf(trend) === index)
              .join(", ");
          } else newRD.Trend = "";
        } else {
          if (rD.userCompentencyTechnology && rD.userCompentencyTechnology.length) {
            const ids = rD?.userCompentencyTechnology.map((v) => {
              return v.dtId;
            });

            const techData = techTrendData.filter((item) => {
              return ids.includes(item.id);
            });
            newRD.Technology = techData
              .map((item) => {
                return item.text;
              })
              .filter((tech, index, self) => tech !== "" && self.indexOf(tech) === index)
              .join(", ");
          } else {
            newRD.Technology = "";
          }
        }
      });
      return newRD;
    });
    return formatedRowData;
  };

  /**
   * @description fetch language Expertise
   */
  const fetchLanguageExpertise = () => {
    fetchLanguages({ sort: "order:asc" })
      .then((res) => {
        const { data = [] } = res.data;
        setLanguages(
          data.map((d) => {
            return { id: d.language, text: d.language };
          })
        );
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Language Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch functional Expertise
   */
  const fetchFunctionalExpertise = () => {
    fetchFunctionalExpertiseData({ sort: "parent:asc,expertise:asc" })
      .then((res) => {
        const { data = [] } = res.data;
        setFunctionalExpertise(data.map((d) => ({ id: d.expertise, text: d.expertise })));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Functional Expertise Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch trends to tag with user
   */
  const fetchTrends = () => {
    fetchTaxonomyTrend({ filters: [["isTrendNode", "eq", 1]], sort: "trendParentName:asc", limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;

        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.trendParentId] = { id: sTT.trendParentName, text: sTT.trendParentName };
        });
        setTrends([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Trend Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch industry to tag with user
   */
  const fetchIndustry = () => {
    fetchTaxonomyIndustry({ fields: ["industryName"], sort: "industryName:asc", limit: 50 })
      .then((res) => {
        const { data = [] } = res.data;
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.id] = { id: sTT.industryName, text: sTT.industryName };
        });
        setIndustry([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch industry failed";
        dispatch(actionError(errMsg));
      });
  };

  useEffect(() => {
    if (!techTrendData) fetchTechData();
    fetchFunctionalExpertise();
    // if (getProductVariant() === PRODUCT_VARIANT.WHATNEXT || getProductVariant() === PRODUCT_VARIANT.CHEERSIN) {
    fetchIndustry();
    // } else if (getProductVariant() === PRODUCT_VARIANT.INSIDER) {
    // 	fetchTrends()
    // }
    fetchLanguageExpertise();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => dispatch(setBreadCrumb());
  }, [dispatch]);

  // Reset Redux for filter by taxonomy
  useEffect(() => {
    if (window.location.pathname == `/external-user`) {
      dispatch(resetRedux());
    }
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        {techTrendData &&
          languages &&
          languages.length > 0 &&
          functionalExpertise &&
          functionalExpertise.length > 0 &&
          industry &&
          industry.length > 0 && (
            <CommonDataGrid
              gridTitle="User Management List"
              fetch={fetchExternalUserWithProductVariant}
              deleteOne={deleteSingleUser}
              deleteMany={accessActionDelete ? deleteManyFunc : null}
              getMultiSelectedRows={(data) => {
                localStorage.setItem("selectedExtUsers", JSON.stringify([...data]));
              }}
              getMultiDeSelectedRows={(data) => {
                localStorage.setItem("selectedExtUsers", JSON.stringify([...data]));
              }}
              showLoader={showLoaderGrid}
              hideLoader={hideLoaderGrid}
              columnNames={columnNames}
              columnFields={columnFields}
              formatRowData={formatRowData}
              deleteId={deleteId}
              addNewRight={accessActionListingAddNew}
              addNew={() => history.push(`/external-user/add#profile`)}
              clearFilterByTaxonomy={true}
            />
          )}
      </div>
    </div>
  );
};

export default UserDashBoard;
