/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  KIA form field options
 ---------------------------------------------------------------------------------
    Creation Details
    Date Created				: 09/June/2021
    Author						: Sandeep Sharma
================================================================
*/
//Time scale options
export const TIME_SCALE = [
    {
        label: "0-2 Years",
        value: "0-2 Years"
    },
    {
        label: "2-5 Years",
        value: "2-5 Years"
    },
    {
        label: "5-10 Years",
        value: "5-10 Years"
    },
    {
        label: "10+ Years",
        value: "10+ Years"
    },

];
// Challenge Level options
export const STATUS_OPTIONS = [
    {
        label: "Low",
        value: "Low",
    },
    {
        label: "Medium",
        value: "Medium",
    },
    {
        label: "High",
        value: "High",
    }
];

// KIA Status Options
export const KIA_STATUS = [
    {
        label: "Draft",
        value: "Draft",
    },
    {
        label: "Under Review",
        value: "Active",
    },
    {
        label: "In Progress",
        value: "In-Progress",
    },
    {
        label: "Complete",
        value: "Closed",
    }
];

// KIA Status Options
export const KIA_FREQUENCY = [

    {
        label: "One Time",
        value: "One Time",
    },
    {
        label: "Monthly",
        value: "Monthly",
    },
    {
        label: "Quaterly",
        value: "Quaterly",
    },
    {
        label: "Half Yearly",
        value: "Half Yearly",
    }
    ,
    {
        label: "Annual",
        value: "Annual",
    }
];

