// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : all entities image url methods
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :5 Oct 23
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import { NEWS_URL, TV_URL, COMPANY_URL, DELIVERABLE_URL, PRODUCT_URL, USER_URL } from "../../config";

// news image api call
export const getNewsImageUrl = (fileName) => {
  return `${NEWS_URL}/news/image/${fileName}`;
};

// video image api call
export const getVideoImageUrl = (fileName) => {
  return `${TV_URL}/video/image/${fileName}`;
};

// company image api call
export const getCompanyImageUrl = (fileName) => {
  return `${COMPANY_URL}/company/image/${fileName}`;
};

// deliverable image api call
export const getDeliverableImageUrl = (fileName) => {
  return `${DELIVERABLE_URL}/deliverable/image/${fileName}`;
};

// product image api call
export const getProductImageUrl = (fileName) => {
  return `${PRODUCT_URL}/product/image/${fileName}`;
};

// product image api call
export const getProductVideoThumbUrl = (fileName) => {
  return `${PRODUCT_URL}/product/videoThumb/${fileName}`;
};

export const getVideoThumbnail = (videoId, videoSource) => {
  return videoSource === 'Youtube Premium' || videoSource === 'Youtube'
    ? `https://i1.ytimg.com/vi/${videoId}/hqdefault.jpg`
    : `https://vumbnail.com/${videoId}.jpg`;
};

export const getCompanyDocumentUrl = (fileName, docName) => {
  return `${COMPANY_URL}/company/document/${fileName}/${docName}`;
};

export const getProductDocumentUrl = (fileName, docName) => {
  return `${PRODUCT_URL}/product/document/${fileName}/${docName}`;
};

export const getUserImageUrl = (imageId) => {
  return `${USER_URL}/user/image/${imageId}`;
};

export const getPresentationImageUrl = (imageId) => {
  return `${USER_URL}/presentation/image/${imageId}`;
};
