/*
================================================================
    Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : product State
----------------------------------------------------------------
    Creation Details
    Date Created				: 14/09/2022
    Author						: PRASHANT WANKHADE
================================================================
*/

const PRODUCT_STATE = {
    taxonomyCheckedNodes: [],
    taxonomyUnCheckedNodes: [],
    kiaCheckedNodes: [],
    kiaUnCheckedNodes: [],
    productDetails: {},
    valuePreposition: {},
    productImage:''
};

export default (state = PRODUCT_STATE, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_TAXONOMY_CHECKED_NODES': {
            return { ...state, taxonomyCheckedNodes: action.payload };
        }
        case 'SET_PRODUCT_TAXONOMY_UNCHECKED_NODES': {
            return { ...state, taxonomyUnCheckedNodes: action.payload };
        }
        case 'SET_PRODUCT_KIA_CHECKED_NODES': {
            return { ...state, kiaCheckedNodes: action.payload };
        }
        case 'SET_PRODUCT_KIA_UNCHECKED_NODES': {
            return { ...state, kiaUnCheckedNodes: action.payload };
        }
        case 'SET_RESET_REDUX': {
            return {
                taxonomyCheckedNodes: [],
                taxonomyUnCheckedNodes: [],
                kiaCheckedNodes: [],
                kiaUnCheckedNodes: [],
            };
        }
        /* Common save for product module starts */
        case 'SET_PRODUCT_DETAILS': {
            return { ...state, productDetails: action.payload };
        }
        case 'SET_PRODUCT_IMAGE': {
            return { ...state, productImage: action.payload };
        }
        case 'SET_VALUE_PREPOSITION': {
            return { ...state, valuePreposition: action.payload };
        }
        case 'RESET_PRODUCT_REDUX': {
            return {
                productDetails: {},
                valuePreposition: {},
                productImage:''
            };
        }
        /* Common save for product module ends */
        default:
            return state;
    }
};