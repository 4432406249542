import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { fetchConfig, fetchProductSubscription } from "../../../../middleware/services/cmsApi";
import {
  addUserSubscription,
  fetchSingleSubscription,
  updateSubscription,
  fetchBtbIds,
  fetchUserSubscription,
} from "../../../../middleware/services/userApi";
import { actionSuccess, actionError } from "../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { userSubscriptionValidationSchema } from "../../../helper/validationSchema/userSubscriptionSchema";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  DatePicker,
  MemoziedDropdown,
  MemoziedDropdownNew,
  ProductVariantSelect,
  RadioGroup,
} from "../../../components/CustomFormElements/syncFusionFormFields";
import { COMMON_ACCESS_ACTION, PRODUCT_VARIANT } from "../../../../constants";
import BtbModal from "./viewBtbModal";
import { accessRightActionCheck } from "utilities";

const initialState = {
  relationshipType: null,
  productVariant: null,
  subscriptionType: null,
  validity: null,
  subscriptionStatus: "Active",
  subscriptionStartDate: new Date(),
  subscriptionEndDate: "",
  relationshipManagerName: "",
  relationshipManagerEmail: "",
  relationshipManagerEmployeeCode: "",
  btbId: "",
  subscriptionProducts: [],
};

const AddSubscription = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = props?.computedMatch?.params?.userId || "";
  const userName = props?.location?.state?.userName || "";
  const editSubscriptionId = props?.computedMatch?.params?.subscriptionId || "";
  const isEdit = editSubscriptionId ? true : false;
  const [BTBId, setBTBId] = useState(props?.location?.state?.btbId || "");
  const [editData, setEditData] = useState([]);
  const [relationshipTypes, setRelationshipTypes] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [isActiveSubscription, setIsActiveSubscription] = useState(false);
  const [subscriptionValidityData, setSubscriptionValidityData] = useState([]);
  const [subscriptionProductData, setSubscriptionProductData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [btbs, setBtbs] = useState(undefined);
  const [show, setShow] = useState(false);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);

  const breadCrumbLinks = [
    { linkUrl: "/client-user", linkName: aR.moduleName, linkActive: false },
    { linkUrl: userId ? `/client-user/${userId}/edit#profile` : "/client-user/edit#profile", linkName: userName || "", linkActive: false },
    { linkUrl: `/client-user/${userId}/edit#subscription`, linkName: "Relationship", linkActive: false },
    {
      linkUrl: BTBId ? `/client-user/${userId}/subscription/${editSubscriptionId}/edit` : "/client-user/${userId}/subscription/add",
      linkName: BTBId || "Add",
      linkActive: true,
    },
  ];

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    validationSchema: Yup.lazy((values) => {
      if (["Subscription", "Trial"].includes(values.relationshipType)) {
        if (values.productVariant) {
          const validity1 = typeof values.validity === "number" ? values.validity : values.validity && values.validity.value;
          validity1 === -2 && delete userSubscriptionValidationSchema.subscriptionEndDate;
          if (validity1 === -1)
            userSubscriptionValidationSchema.subscriptionEndDate = Yup.date()
              .typeError("Please select validity end date")
              .required("Please select validity end date");
          return Yup.object().shape({
            ...userSubscriptionValidationSchema,
            subscriptionProduct: Yup.string().required("Please select subscription").nullable(),
            validity: Yup.string().required("Please select subscription validity").nullable(),
          });
        }
        return Yup.object().shape({
          ...userSubscriptionValidationSchema,
          productVariant: Yup.string().required("Please select product variant").nullable(),
        });
      } else {
        delete userSubscriptionValidationSchema.productVariant;
        delete userSubscriptionValidationSchema.subscriptionProduct;
        delete userSubscriptionValidationSchema.validity;
        return Yup.object().shape(userSubscriptionValidationSchema);
      }
    }),
  });

  const fetchRelationshipType = () => {
    const params = { filters: [["config_name", "eq", "subscription-relation-type"]] };
    fetchConfig(params)
      .then((res) => {
        const data = res.data?.data ? res.data.data[0].configValue : [];
        const relationships = data
          .map((item) => {
            return item.value;
          })
          .filter((f) => !["Registered", "Client Engagement"].includes(f));
        setRelationshipTypes(relationships);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
      });
  };
  const fetchProductVariant = () => {
    const params = { filters: [["config_name", "eq", "product-variant"]] };
    fetchConfig(params)
      .then((res) => {
        const data = res.data?.data ? res.data.data[0].configValue : [];
        const variantData = data.map((item) => item.value).filter((f) => f !== PRODUCT_VARIANT.INSIDER);
        variantData && variantData.length && setProductVariants(variantData);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
      });
  };

  const fetchValidity = (productVariant) => {
    if ([PRODUCT_VARIANT.WHATNEXT, PRODUCT_VARIANT.COGNITION].includes(productVariant)) {
      const params = {};
      if (formik.values.relationshipType === "Trial") {
        params.filters = [["config_name", "eq", "whatnext-trial-validity"]];
      } else if (formik.values.relationshipType === "Subscription") {
        params.filters = [["config_name", "eq", "whatnext-subscription-validity"]];
      }

      fetchConfig(params)
        .then((res) => {
          if (res && res.data && res.data.data) {
            const data = res.data.data ? res.data.data[0].configValue : [];
            let arr = [];
            _.forOwn(data, function (v, k) {
              if (!isNaN(k)) {
                arr.push(
                  v.displayName === "Custom Date"
                    ? { label: v.displayName, value: -1 }
                    : v.displayName === "No limit"
                    ? { label: v.displayName, value: -2 }
                    : { label: v.displayName, value: v.value }
                );
              }
            });

            if (["Subscription", "Trial"].includes(formik.values.relationshipType)) {
              let v = arr.find((uR) => uR.label === editData.subscriptionValidity);
              v && formik.setFieldValue("validity", v && v.value);
              setIsDisabled(false);
            }

            arr && arr.length > 0 && setSubscriptionValidityData(arr);
          }
        })
        .catch((err) => {
          let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
          dispatch(actionError(errMsg));
        });
    }
  };
  // check if active subscription is exist for same product variant
  const isActiveSubscriptionExist = async (product_variant) => {
    !isEdit &&
      (await fetchUserSubscription(userId, {
        fields: ["subscriptionType", "subscriptionProduct"],
        filters: [
          ["subscriptionProductVariant", "eq", product_variant],
          ["subscriptionStatus", "eq", "Active"],
        ],
      })
        .then((res) => {
          if (res && res.data && res.data.totalCount) {
            const data = res.data.data;
            dispatch(actionError(`${data[0].subscriptionType} is already active for product variant ${product_variant}`));
            setIsActiveSubscription(null);
            setSubscriptionProductData([]);
            formik.setFieldValue("productVariant", null);
            formik.setFieldTouched("productVariant", true);
            formik.setFieldError("productVariant", `${data[0].subscriptionType} is already active for product variant ${product_variant}`);
          }
        })
        .catch((err) => {
          let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
          dispatch(actionError(errMsg));
        }));
  };

  // Fetch Subscription Product data to inject in to Select Subscription Dropdown
  const getSubscriptionProduct = async (product_variant) => {
    await fetchProductSubscription({
      fields: ["productId", "productName", "productSubscriptionType"],
      filters: [
        ["productVariant", "eq", product_variant],
        ["productType", "eq", "Industry"],
      ],
      limit: 200,
    })
      .then((res) => {
        if (res && res.data && res.data.data) {
          setSubscriptionProductData(res.data.data);
        }
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
      });
  };

  // Get Edit data
  const fetchSingleRelationship = async () => {
    editSubscriptionId &&
      (await fetchSingleSubscription(userId, editSubscriptionId).then((res) => {
        if (res && res.data && res.data.data) {
          const data = res.data.data;
          setEditData(data);
          formik.setValues({
            relationshipType: data.subscriptionType,
            // validity: data.subscriptionValidity,
            subscriptionStatus: data.subscriptionStatus,
            subscriptionStartDate: new Date(),
            subscriptionEndDate: data.subscriptionEndDate,
            relationshipManagerName: data.relationshipManagerName,
            relationshipManagerEmail: data.relationshipManagerEmail,
            relationshipManagerEmployeeCode: data.relationshipManagerEmployeeCode,
            btbId: data.btbId,
            subscriptionProduct:
              data.subscriptionType && (data.subscriptionType === "Trial" || data.subscriptionType === "Subscription")
                ? data.subscriptionProduct[0].productId
                : "",
            productVariant:
              data.subscriptionType && (data.subscriptionType === "Trial" || data.subscriptionType === "Subscription")
                ? data.subscriptionProductVariant
                : "",
            subscriptionProducts: data.subscriptionProduct,
          });

          if (["Subscription", "Trial"].includes(data.subscriptionType)) setIsDisabled(false);
          else setIsDisabled(true);
        }
      }));
  };

  const handleSaveSubscription = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const {
          relationshipType,
          productVariant,
          subscriptionProduct,
          validity,
          subscriptionStatus,
          subscriptionStartDate,
          subscriptionEndDate,
          relationshipManagerName,
          relationshipManagerEmail,
          relationshipManagerEmployeeCode,
          btbId,
        } = formik.values;

        setBTBId(btbId);
        const validity1 = typeof validity === "number" ? validity : validity.value;

        if (subscriptionProduct && subscriptionProductData && subscriptionProductData.length) {
          let s = subscriptionProductData.find((uR) => uR.productId === subscriptionProduct);
          payload.subscriptionProductType = s.productSubscriptionType;
          payload.subscriptionProduct = [
            {
              productId: subscriptionProduct,
              productName: s.productName,
            },
          ];
        }

        payload.subscriptionType = relationshipType;
        payload.subscriptionProductVariant = productVariant;
        payload.relationshipManagerName = relationshipManagerName;
        payload.relationshipManagerEmail = relationshipManagerEmail;
        payload.relationshipManagerEmployeeCode = relationshipManagerEmployeeCode;
        payload.btbId = btbId;
        payload.subscriptionStatus = subscriptionStatus ? "Active" : "Inactive";
        if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
          let subscriptionFormattedDate = moment(subscriptionStartDate).format("DD-MMM-yyyy");
          let subscriptionEndDateFormatted = subscriptionEndDate
            ? moment(subscriptionEndDate).format("DD-MMM-yyyy")
            : moment(subscriptionStartDate, "DD-MMM-yyyy").add(validity1, "days").format("DD-MMM-yyyy");
          payload.subscriptionStartDate = subscriptionFormattedDate;
          payload.subscriptionEndDate = validity1 !== -2 ? subscriptionEndDateFormatted : "";
          if (subscriptionProductData && subscriptionProductData.length) {
            let s = subscriptionProductData.find((uR) => uR.productId === subscriptionProduct);
            payload.userSubscription = subscriptionProduct
              ? {
                  industryId: subscriptionProduct,
                  industryName: s.productName,
                  subscriptionStartDate: payload.subscriptionStartDate,
                  subscriptionEndDate: (validity1 !== -2 && payload.subscriptionEndDate) || "",
                }
              : {};
          }
        }

        if (["Subscription", "Trial"].includes(relationshipType)) {
          const v = subscriptionValidityData.find((uR) => uR.value === validity1);
          payload.subscriptionValidity = v ? v.label : "";
        }

        isEdit
          ? dispatch(
              showAlertBox({
                okCallback: async () => {
                  payload.subscriptionStatus = subscriptionStatus;

                  updateSubscription(userId, editSubscriptionId, payload)
                    .then((res) => {
                      res ? dispatch(actionSuccess("Subscription Updated Successfully")) : dispatch(actionError("Something went wrong"));
                    })
                    .catch((err) => {
                      dispatch(actionError(err.data?.message || "Something went wrong"));
                    });
                },
                okText: "Update",
                cancelText: "Cancel",
                content: "Are you sure you want to update?",
                title: "dialogAlertCss",
              })
            )
          : dispatch(
              showAlertBox({
                okCallback: async () => {
                  addUserSubscription(userId, payload)
                    .then((res) => {
                      res ? dispatch(actionSuccess("Subscription Added Successfully")) : dispatch(actionError("Something went wrong"));
                      if (res && res.data.data) {
                        history.push(`/client-user/${userId}/subscription/${res.data.data.id}/edit`, { userName: userName, btbId: btbId });
                        window.location.reload();
                      }
                    })
                    .catch((err) => {
                      dispatch(actionError(err.data?.message || "Something went wrong"));
                    });
                },
                okText: "Update",
                cancelText: "Cancel",
                content: "Are you sure you want to update?",
                title: "dialogAlertCss",
              })
            );
      }
    });
  };

  const getBtbs = (empCode) => {
    empCode &&
      fetchBtbIds(userId, empCode).then((res) => {
        if (res && res.data && res.data.data) {
          setBtbs(res.data.data);
          res.data.data && res.data.data.length ? setShow(true) : dispatch(actionError("No BTB Id details found"));
        }
      });
  };
  useEffect(() => {
    editData && editData.length === 0 && fetchSingleRelationship();
    productVariants && productVariants.length === 0 && fetchProductVariant();
    relationshipTypes && relationshipTypes.length === 0 && fetchRelationshipType();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  useEffect(() => {
    ["Subscription", "Trial"].includes(formik.values.relationshipType) ? setIsDisabled(false) : setIsDisabled(true);
  }, [formik.values.relationshipType]);

  /* Interface actions */
  useEffect(() => {
    let actionAccess = accessRightActionCheck(aR.moduleName, "Add New Relationship");
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <div className="gennx-content-wrapper" style={{ height: "100%", background: "#ffffff" }}>
      <div className="p-4 ">
        <div>
          <div className="row p-1">
            <div className="col-md-6">
              <div className="customCss panel-title" style={{ width: "200px" }}>
                {isEdit ? "Edit" : "Add New"} Relationship
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              {relationshipTypes && relationshipTypes.length > 0 && (
                <MemoziedDropdown
                  formik={formik}
                  id="relationshipType"
                  name="relationshipType"
                  placeholder="Relationship Type *"
                  dataSource={relationshipTypes}
                  enabled={!isEdit}
                />
              )}
            </div>
            <div className="col-md-4">
              {productVariants && productVariants.length > 0 && (
                <ProductVariantSelect
                  formik={formik}
                  id="productVariant"
                  name="productVariant"
                  // placeholder={`Select Product Variant${["Subscription", "Trial"].includes(formik.values.relationshipType) && formik.values.productVariant ? " *" : ""}`}
                  placeholder={`Select Product Variant${["Subscription", "Trial"].includes(formik.values.relationshipType) ? " *" : ""}`}
                  // enabled={!isDisabled}
                  enabled={!isEdit}
                  value={isActiveSubscription === formik.values.productVariant ? null : formik.values.productVariant}
                  dataSource={productVariants}
                  fetchValidity={fetchValidity}
                  isActiveSubscriptionExist={isActiveSubscriptionExist}
                  isActiveSubscription={isActiveSubscription}
                  subscriptionProduct={getSubscriptionProduct}
                />
              )}
            </div>
            {/* Subscription Product Dopdown depends on Product Variant */}
            <div className="col-md-4">
              {((subscriptionProductData && subscriptionProductData.length > 0) || !isEdit) && (
                <MemoziedDropdown
                  formik={formik}
                  id="subscriptionProduct"
                  name="subscriptionProduct"
                  placeholder={`Select Subscription${
                    ["Subscription", "Trial"].includes(formik.values.relationshipType) && formik.values.productVariant ? " *" : ""
                  }`}
                  fields={{ text: "productName", value: "productId" }}
                  // dataSource={formik.values.productVariant === PRODUCT_VARIANT.WHATNEXT ? WNsubscriptionType : CognitionSubscriptionType}
                  dataSource={formik.values.productVariant ? subscriptionProductData : []}
                  enabled={!isEdit}
                />
              )}
            </div>
            <div className="col-md-4">
              {((subscriptionValidityData && subscriptionValidityData.length > 0) || !isEdit) && (
                <MemoziedDropdownNew
                  formik={formik}
                  id="validity"
                  name="validity"
                  placeholder={`Select Validity${
                    ["Subscription", "Trial"].includes(formik.values.relationshipType) && formik.values.productVariant ? " *" : ""
                  }`}
                  fields={{ text: "label", value: "value" }}
                  dataSource={subscriptionValidityData}
                  enabled={!isDisabled}
                  select={(selectedOption) => {
                    (selectedOption &&
                      ![-1, -2].includes(selectedOption?.itemData?.value) &&
                      formik.setFieldValue(
                        "subscriptionEndDate",
                        moment(formik.values.subscriptionStartDate, "DD-MMM-yyyy").add(selectedOption?.itemData?.value, "days").format("DD-MMM-yyyy")
                      )) ||
                      formik.setFieldValue("subscriptionEndDate", "");
                  }}
                />
              )}
            </div>
            <div className={`col-md-4`}>
              <DatePicker
                formik={formik}
                value={new Date()}
                properties={{
                  placeholder: `Trial/Subscription Start Date${["Subscription", "Trial"].includes(formik.values.relationshipType) ? " *" : ""}`,
                  id: `subscriptionStartDate`,
                  name: `subscriptionStartDate`,
                  format: "dd MMM yyyy",
                  floatLabelType: "Auto",
                  allowEdit: false,
                  openOnFocus: true,
                  min: new Date(),
                  enabled: !(isDisabled || formik.values.productVariant?.value == PRODUCT_VARIANT.INSIDER),
                  blur: (e) => {
                    (e.model.currentDate &&
                      ![-1, -2].includes(formik.values.validity.value) &&
                      formik.setFieldValue(
                        "subscriptionEndDate",
                        moment(e.model.currentDate, "DD-MMM-yyyy").add(formik.values.validity.value, "days").format("DD-MMM-yyyy")
                      )) ||
                      formik.setFieldValue("subscriptionEndDate", "");
                  },
                }}
              />
            </div>
            {
              <div className="col-md-4">
                <DatePicker
                  formik={formik}
                  properties={{
                    placeholder: `Select Validity End Date${
                      ["Subscription", "Trial"].includes(formik.values.relationshipType) &&
                      formik.values.validity &&
                      (formik.values.validity.value === -1 || formik.values.validity === -1)
                        ? " *"
                        : ""
                    }`,
                    id: `subscriptionEndDate`,
                    name: `subscriptionEndDate`,
                    format: "dd MMM yyyy",
                    floatLabelType: "Auto",
                    allowEdit: false,
                    openOnFocus: true,
                    // min: new Date(),
                    enabled: formik.values.validity && (formik.values.validity.value === -1 || formik.values.validity === -1) ? true : false,
                  }}
                />
              </div>
            }
          </div>

          <div className="row ">
            <div className="col-md-4">
              <TextField formik={formik} placeholder="Relationship Manager's Name *" name="relationshipManagerName" id="relationshipManagerName" />
            </div>
            <div className="col-md-4">
              <TextField
                formik={formik}
                type="email"
                placeholder="Relationship Manager's Email *"
                name="relationshipManagerEmail"
                id="relationshipManagerEmail"
              />
            </div>
            <div className="col-md-4">
              <TextField
                formik={formik}
                placeholder="Relationship Manager's Employee Code *"
                name="relationshipManagerEmployeeCode"
                id="relationshipManagerEmployeeCode"
              />
            </div>
          </div>
          <div className="row w-100 mt-3">
            <div className="col-md-2">
              <TextField
                autoComplete="off"
                placeholder="Enter BTB Id *"
                name="btbId"
                id="btbId"
                formik={formik}
                value={formik.values.btbId || ""}
                onChange={(event) => {
                  const value = event.target.value;
                  // setState({ ...state, btbId: value })
                  formik.setFieldValue("btbId", value);
                }}
              />
            </div>
            {formik.values.relationshipManagerEmployeeCode && (
              <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-primary pl-3 pr-3"
                  onClick={() => {
                    getBtbs(formik.values.relationshipManagerEmployeeCode);
                  }}
                >
                  Select BTB from list
                </button>
              </div>
            )}
          </div>
          <div className="row">
            {
              <div className="col-md-4">
                <RadioGroup
                  formik={formik}
                  type="radio"
                  optionArr={["Active", "Inactive"]}
                  properties={{
                    placeholder: "Relationship Status",
                    name: "subscriptionStatus",
                    label: "Relationship Status *",
                  }}
                />
              </div>
            }
          </div>
        </div>
      </div>

      <div className="form-submit-box">
        <div className="container-fluid nopadding">
          <div className="row">
            <div className="float-right col-md-12 text-right">
              <Link to={`/client-user/${userId}/edit#subscription` || history.location.pathname}>
                <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                  Cancel
                </button>
              </Link>
              {((editData && editData.subscriptionStatus === "Active" && isEdit) || !isEdit) && accessActionSave ? (
                <button
                  type="button"
                  className="btn btn-primary cy-btn pl-4 pr-4 "
                  onClick={() => {
                    handleSaveSubscription();
                  }}
                >
                  {isEdit ? "Update" : "Save"}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <BtbModal
          closeHandler={() => {
            setShow(false);
          }}
          show={show}
          setBTBId={(btb_id) => {
            formik.setFieldValue("btbId", btb_id);
            setShow(false);
          }}
          btbs={btbs}
        />
      )}
    </div>
  );
};
export default AddSubscription;
