/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Modal New Folder
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { addNewBriefcaseFolder, getAllBriefcaseCategoryList } from "../../../middleware/services/myBriefcase";
import ModalTextInput from "../ModalTextInput/ModalTextInput";
import ModalCancelSaveButtons from "../ModalCancelSaveButtons/ModalCancelSaveButtons";
import classes from "./ModalNewFolder.module.css";
import { applyCategoryFilter, getAllBriefcaseCategory, updateBriefcaseCategoryId } from "../../../middleware/actions/myBriefcaseAction";
import { getLoggedInUser } from "../../../utilities";
import { actionError, actionSuccess } from "../../../middleware/actions/utilityAction";

const ModalNewFolder = ({
  folderType,
  onHide,
  show,
  modalTitle,
  showNewFolderModal,
  setActivePage,
  setActivePPage,
  setCategorySortValue,
  getBriefcaseSortFolderList,
}) => {
  const dispatch = useDispatch();
  const [folderName, setFolderName] = useState("");
  const [showError, setShowError] = useState(false);
  const [folderExistError, setFolderExistError] = useState(false);
  const [existingFolders, setExistingFolders] = useState([]);
  const productVariant = localStorage.getItem("productVariant");
  const user = getLoggedInUser();
  const allBriefcaseCategoryListing = useSelector((state) => state["myBriefcaseState"]["allBriefcaseCategoryListing"]);

  const regex = /^[!@#$%^&*()_-]+$/;

  // check briefcase folder exist or not
  const checkFolderExist = (obj) => obj.toLowerCase() === folderName.trim().toLowerCase();

  // call create folder api with validation
  const createFolder = () => {
    if (Object.getOwnPropertyNames(user).length !== 0 && folderName.trim() !== "" && !/^\s/.test(folderName) && !regex.test(folderName)) {
      if (existingFolders.some(checkFolderExist)) {
        setFolderExistError(true);
      } else {
        if (folderType === "briefcase") {
          addNewBriefcaseFolder(user._id, folderName.trim(), productVariant)
            .then((res) => {
              dispatch(actionSuccess("Briefcase folder created successfully."));
              dispatch(applyCategoryFilter("categoryCreatedDate:desc"));
              getBriefcaseSortFolderList("categoryCreatedDate:desc");
              getAllCategory();
              dispatch(updateBriefcaseCategoryId(res?.data?.id));
            })
            .catch((error) => {
              if (error.response?.status === 409) {
                dispatch(actionError(`Folder is already exist with name ${folderName}`));
              } else {
                console.log("error >>>", error);
              }
            });
          dispatch(applyCategoryFilter("categoryCreatedDate:desc"));
        }
        onHide();
        setCategorySortValue("Newest");
        setShowError(false);
        setFolderExistError(false);
        setFolderName("");
        setActivePage(1);
        setActivePPage(1);
      }
    } else {
      setShowError(true);
    }
  };

  const getAllCategory = async () => {
    await getAllBriefcaseCategoryList(user?._id, productVariant)
      .then((res) => {
        dispatch(
          getAllBriefcaseCategory({
            res,
            totalCount: res.totalCount,
          })
        );
      })
      .catch((error) => {
        console.log("error  category listing >>>", error);
      });
  };

  // add new added folder to existing folder list
  useEffect(() => {
    let folderArray = [];
    if (folderType === "briefcase" && allBriefcaseCategoryListing.length !== 0) {
      allBriefcaseCategoryListing.forEach(function (item) {
        folderArray.push(item.categoryName);
      });
    }
    setExistingFolders(folderArray);
  }, [allBriefcaseCategoryListing, folderType]);

  // clear error messages when folder is not shown
  useEffect(() => {
    if (showNewFolderModal) {
      setShowError(false);
    }
  }, [showNewFolderModal]);

  return (
    <div>
      <Modal dialogClassName={classes.modalMain} contentClassName={classes.modalContent} show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header className={classes.modalHeader}>
          <Modal.Title className={classes.modalTitle}>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modalBody}>
          <ModalTextInput
            label="Folder Name"
            placeholderText="Enter Name"
            folderName={folderName}
            setFolderName={setFolderName}
            createFolder={createFolder}
          />
          {showError && folderName === "" && <p className={classes.errorText}>Enter folder name.</p>}

          {existingFolders.some(checkFolderExist) && folderExistError && <p className={classes.errorText}>Folder is already exists.</p>}

          {/^\s/.test(folderName) && <p className={classes.errorText}>Folder name should not be start with space.</p>}
          {regex.test(folderName) && showError && <p className={classes.errorText}>Folder name should not contain only special characters.</p>}
        </Modal.Body>

        <Modal.Footer className={classes.modalFooter}>
          <ModalCancelSaveButtons
            cancelButtonClick={() => {
              onHide();
              setFolderExistError(false);
              setShowError(false);
              setFolderName("");
            }}
            saveButtonText="Save"
            saveButtonClick={createFolder}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalNewFolder;
