/*
================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Content Block remark disposition list Component
----------------------------------------------------------------
    Creation Details
    Date Created				: 02/May/2022
    Author						: Suraj Yadav
================================================================
*/
import React, { useEffect, useState } from "react";
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from "@syncfusion/ej2-react-navigations";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import "./index.scss";
import { useDispatch } from "react-redux";
import { fetchAllDisposition, saveCustomDisposition, deleteCustomDisposition } from "../../../middleware/services/contentBlockApi";
import { actionError } from "../../../middleware/actions/utilityAction";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import CancelIcon from "@material-ui/icons/Cancel";
import { CUSTOM_DISPOSITION_NUMBER } from "../../../constants";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const RemarkDisposition = (props) => {
  const dispatch = useDispatch();
  const [viewDisposition, setViewDisposition] = useState(true);
  const [listOfDspositions, setListOfDspositions] = useState("");
  const [disposition, setDisposition] = useState({ ...props.selectedDisposition });
  const [fetchSelectedDisposition, setFetchSelectedDisposition] = useState(props.checkedBox);
  const [customDispositions, setCustomDispositions] = useState([]);
  const [customDisposition, setCustomDisposition] = useState("");
  const [showCustomDisposition, setShowCustomDisposition] = useState(false);
  const [otherDispositions, setOtherDispositions] = useState("");
  const [customAccordion, setCustomAccordion] = useState(false);
  /*
       Description : Fetch all dispositions
    */
  const selectDispositon = () => {
    fetchAllDisposition({ filters: [["dispositionCategory", "eq", "Standard"]] }).then((e) => {
      let dispositions = e.data?.data?.filter((item) => item.dispositionParentName !== "Other Disposition");
      let otherDispositions = e.data?.data?.filter((item) => item.dispositionParentName === "Other Disposition");
      let result = [...dispositions];
      setListOfDspositions(result);
      setOtherDispositions([...otherDispositions]);
    });
    fetchCustomDisposition();
  };

  const fetchCustomDisposition = () => {
    fetchAllDisposition({ filters: [["dispositionCategory", "eq", "Custom"]] }).then((e) => {
      setCustomDispositions(e.data.data || []);
    });
  };
  useEffect(() => {
    selectDispositon();
  }, []);

  /*
        Description : Handle selected dispositions
    */
  const selectedDispositions = function (item, value) {
    if (value) {
      let dublicateValue = fetchSelectedDisposition.length && fetchSelectedDisposition?.find((d) => d.dispositionId === item.id);
      if (dublicateValue?.dispositionName) return dispatch(actionError("Already added."));
      fetchSelectedDisposition.push({
        dispositionId: item.id,
        dispositionName: item.dispositionName,
        dispositionCategory: item?.dispositionCategory || "Standard",
      });
    } else {
      fetchSelectedDisposition.filter((val, key) => {
        if (item.id === val.dispositionId) fetchSelectedDisposition.splice(key, 1);
      });
    }
  };

  useEffect(() => {
    if (showCustomDisposition) loadMasterDisposition({ dispositionParentName: "Other Disposition" });
  }, [showCustomDisposition]);

  let customValue = fetchSelectedDisposition.filter((val) => val.dispositionCategory === "Custom");
  /*
        Description : Fetch parent dispositions
    */
  const loadMasterDisposition = (resp) => {
    let filterDsposition = listOfDspositions.filter(function (d) {
      return resp.id === d.dispositionParentId;
    });
    return filterDsposition.map((item) => {
      return (
        <ul key={item.id} className="disposition-ul">
          <li className="remark-content">
            <CheckBoxComponent
              label={item.dispositionName}
              checked={disposition[item.dispositionName]}
              change={(e) => {
                setDisposition({ ...disposition, [item.dispositionName]: e.checked });
                selectedDispositions(item, e.checked);
              }}
            />
          </li>
        </ul>
      );
    });
  };

  useEffect(() => {
    props.setSelectedDispositions(fetchSelectedDisposition);
  }, fetchSelectedDisposition);

  return (
    <>
      {viewDisposition && (
        <div>
          {/* <div className={`disposition-title ${props.remarkEntityType === ENTITY_TYPE.DELIVERABLE && props.customHeight ? "customTitleHeight" : ""}`}
                // style={{ top: (props.remarkEntityType === "deliverable" && props.titleHeight) ? props.titleHeight : "" }}
                >
                    <span className="dispositions">Select Disposition</span>
                </div> */}

          <div id="disposition_list">
            {listOfDspositions?.length > 0 &&
              listOfDspositions.map((result) => {
                return (
                  <>
                    {result.dispositionParentName && (
                      <AccordionComponent>
                        <AccordionItemsDirective>
                          <AccordionItemDirective header={result.dispositionParentName} content={() => loadMasterDisposition(result)} />
                        </AccordionItemsDirective>
                      </AccordionComponent>
                    )}
                  </>
                );
              })}

            {otherDispositions[0]?.dispositionParentName && (
              <>
                <div className="otherDisposition">
                  {otherDispositions[0]?.dispositionParentName}{" "}
                  <span style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.54)", cursor: "pointer" }}>
                    {!customAccordion ? (
                      <FaAngleDown onClick={() => setCustomAccordion(true)} />
                    ) : (
                      <FaAngleUp onClick={() => setCustomAccordion(false)} />
                    )}
                  </span>
                </div>
                {customAccordion && (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "81%", marginLeft: "16px" }}>
                        <ComboBoxComponent
                          cssClass={"customCss e-outline remarkOtherDispositionField"}
                          value={customDisposition}
                          id="comboelement"
                          fields={{ text: "dispositionName", value: "dispositionName" }}
                          ignoreAccent={true}
                          allowFiltering={true}
                          dataSource={customDispositions}
                          allowCustom={true}
                          placeholder="Add Disposition"
                          onChange={(e) => {
                            setCustomDisposition(e.value || "");
                          }}
                          immediateRender={true}
                          delayUpdate={false}
                        />
                      </div>
                      <div className="customAddCircle">
                        <button
                          className="btn btn-primary cb-btn"
                          onClick={() => {
                            if (customValue.length >= CUSTOM_DISPOSITION_NUMBER)
                              return dispatch(actionError(`You can't add more than ${CUSTOM_DISPOSITION_NUMBER} disposition.`));
                            if (customDisposition?.length > 100) return;
                            if (!customDisposition) return;
                            let dispositionId = "";
                            saveCustomDisposition({ dispositionCategory: "Custom", dispositionName: customDisposition.trim() })
                              .then((res) => {
                                dispositionId = res?.data?.data?.id;
                                selectedDispositions(
                                  { id: dispositionId, dispositionCategory: "Custom", dispositionName: customDisposition.trim() },
                                  customDisposition.trim()
                                );
                                setShowCustomDisposition(true);
                                setCustomDisposition("");
                                fetchCustomDisposition();
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }}
                          style={{ height: "38px" }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div style={{ width: "76%", marginLeft: "16px" }}>
                      <p style={{ float: "right", fontSize: "12px", marginBottom: "0px" }}>{customDisposition?.length}/100</p>
                      <div>
                        {customDisposition?.length > 100 && <p style={{ color: "#f94f5e", fontSize: "12px" }}>Please enter max 100 characters.</p>}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <ul className="custom-ul">
                        {customValue.length > 0 &&
                          customValue?.map((item) => {
                            return (
                              <li key={item.dispositionId}>
                                <div className="custom-li">
                                  <span style={{ fontSize: "12px", color: "#4b4b55" }}>{item.dispositionName}</span>
                                  <span
                                    className="custom-cross-icon"
                                    onClick={() => {
                                      let dispositionId = item.dispositionId;
                                      if (!dispositionId) return;
                                      deleteCustomDisposition(dispositionId)
                                        .then((res) => {
                                          fetchCustomDisposition();
                                        })
                                        .catch((err) => {
                                          console.log("Error in disposition delete: ", err);
                                        });
                                      selectedDispositions({
                                        id: dispositionId,
                                        dispositionCategory: "Custom",
                                        dispositionName: customDisposition,
                                      });
                                      setShowCustomDisposition(true);
                                    }}
                                  >
                                    <CancelIcon height={"20px"} />
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </>
                )}
                <div style={{ borderTop: "1px solid #dedede", marginTop: customAccordion ? "20px" : "0px" }}></div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default RemarkDisposition;
