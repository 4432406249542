/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Modal Delete
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React from "react";
import classes from "./ModalDelete.module.css";
import { Modal } from "react-bootstrap";
import ModalCancelSaveButtons from "../ModalCancelSaveButtons/ModalCancelSaveButtons";

const ModalDelete = (props) => {
  return (
    <div>
      <Modal
        className="CentredModal"
        dialogClassName={classes.modalMain}
        contentClassName={classes.modalContent}
        show={props.show}
        onHide={props.onHide}
        centered
        backdrop="static"
      >
        <Modal.Body className={classes.modalBody}>{props.text}</Modal.Body>
        <Modal.Footer className={classes.modalFooter}>
          <ModalCancelSaveButtons
            cancelButtonClick={props.onHide}
            pinkButtontext={props.pinkButtontext}
            saveButtonClick={props.onDeleteClick}
            saveButtonText={props.saveButtonText}
            cancelButtonText={props.cancelButtonText}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalDelete;
