import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import AddVideo from "../app/views/video/component/videoDetails";
import TvListing from "../app/views/video";

const VIDEO = ["Video:FULL"];

const VIDEO_DETAILS = ["Details:VIEW", "Details:FULL"];
const QUESTIONS = ["Questions:VIEW", "Questions:FULL"];
const DOCUMENTS = ["Documents:VIEW", "Documents:FULL"];
const HOST = ["Host:VIEW", "Host:FULL"];
const TAXONOMY = ["Taxonomy:VIEW", "Taxonomy:FULL"];

const tvRoutes = () => (
  // <Suspense fallback={<div className="text-center"><h3>Loading...</h3></div>}>
  <Switch>
    <AuthLayout
      exact
      path="/video/add"
      component={AddVideo}
      pageHeading="GenNx CMS - Video"
      accessRights={{
        moduleName: "Video",
        parentModuleName: "",
        moduleAttribute: [...VIDEO, ...VIDEO_DETAILS],
        interfaceName: "Video Details",
      }}
    />
    <AuthLayout
      exact
      path="/video"
      component={TvListing}
      pageHeading="GenNx CMS - Video"
      accessRights={{
        moduleName: "Video",
        parentModuleName: "",
        moduleAttribute: [...VIDEO, ...VIDEO_DETAILS, ...QUESTIONS, ...DOCUMENTS, ...HOST, ...TAXONOMY],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/video/:videoId/edit"
      component={AddVideo}
      pageHeading="GenNx CMS - Video"
      accessRights={{
        moduleName: "Video",
        parentModuleName: "",
        moduleAttribute: [...VIDEO, ...VIDEO_DETAILS, ...QUESTIONS, ...DOCUMENTS, ...HOST, ...TAXONOMY],
        interfaceName: "Video Details",
      }}
    />
  </Switch>
  // </Suspense>
);

export default tvRoutes;
