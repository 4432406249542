/*
================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Convert date and time to IST to GMT and GMT to IST and specific to TimeZone
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/Dec/2020
    Author						: Anand Kumar
================================================================
*/
import moment from "moment";

/**
 * 
 * @param {String} dateTime 
 * @param {String} timeZone
 * @returns date and time according to the time zone  
 */
const getParsedDate = (dateTime, timeZone) => {
    // date.tz('America/Los_Angeles').format('ha z');
    return moment(dateTime, 'YYYY-MM-DD HH:mm:ss A').tz(timeZone).format();
}

/**
 * @param {String} dateTime 
 * @param {String} optional date format
 * @returns GMT to IST conveted date with the format if specify 
 */
const formatDateGMTtoIST = (dateTime, format) => {
    // return new Date(dateTime).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
    return moment.utc(dateTime).local().format(format || 'YYYY-MM-DD HH:mm:ss A');
}

/**
 * @param {String} dateTime 
 * @returns GMT to IST conveted date 
 */
const formatISTtoGMT = (dateTime) => {
    return moment.utc(moment(dateTime)).format();

}
export { formatDateGMTtoIST, getParsedDate, formatISTtoGMT } 