import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import Listing from "../app/views/dataUpload/index";
import DataUpload from "../app/views/dataUpload/importData";
const DASHBOARD = ["Upload Signals Data:VIEW", "Upload Signals Data:FULL"];
const newsRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/data-upload"
      component={Listing}
      pageHeading="GenNx CMS - Upload Signals Data"
      accessRights={{
        moduleName: "Upload Signals Data",
        parentModuleName: "",
        moduleAttribute: [...DASHBOARD],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path="/data-upload/add"
      component={DataUpload}
      pageHeading="GenNx CMS - Upload Signals Data"
      accessRights={{
        moduleName: "Upload Signals Data",
        parentModuleName: "",
        moduleAttribute: [...DASHBOARD],
        interfaceName: "Basic",
      }}
    />
  </Switch>
);

export default newsRoutes;
