/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Content Block remark reply Component
----------------------------------------------------------------
	Creation Details
	Date Created				: 02/May/2022
	Author						: Suraj Yadav
================================================================
*/
import "./index.scss";
import React, { useEffect, useState } from "react";
import { fetchContentBlockRemark, saveRemarkReply, updateRemarkReply } from "../../../middleware/services/contentBlockApi";
import { getDateFromMongoDate } from "../../../utilities/index";
import { FaUser } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { actionSuccess, actionError } from "../../../middleware/actions/utilityAction";
import { getLoggedInUser } from "../../../utilities";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "../../components/CustomFormElements/syncFusionFormFields";
import { ENTITY_TYPE } from "../../../constants";

const RemarkReply = (props) => {
  let loggedInUser = getLoggedInUser();
  const [replyId, setReplyId] = useState("");
  const [replyBox, setReplyBox] = useState("");
  const dispatch = useDispatch();
  const remarkAccess = props.remarkEntityType === ENTITY_TYPE.DELIVERABLE ? props?.contentTabAccess : true;

  const formik = useFormik({
    initialValues: {
      remarkReply: "",
    },
    /*Description : Remark reply validation Schema */
    validationSchema: Yup.object().shape({
      remarkReply: Yup.string()
        .max(500, "Please enter within 500 characters.")
        .matches(/[^\s]([a-zA-Z])/, "Please enter valid name"),
    }),
  });

  /*
        Description : Fetch Remark
        params: remarkEntityId (deliverable or contentBlock Id)
        params: remarkId 
    */
  const loadRemarks = (remarkEntityId, remarkId) => {
    fetchContentBlockRemark(remarkEntityId).then((e) => {
      props.handleUpdateRemarkData(e.data.data);
      let remarks = e.data.data.filter((val) => {
        return val.id == remarkId;
      });
      props.handleSingleRemark(remarks[0]);
    });
  };

  /*
        Description : Handle save remark reply
    */
  const addRemarkReply = () => {
    formik.validateForm().then(async (res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        const err =
          res &&
          Object.values(res).map((e) => {
            return e;
          });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        if (formik.values.remarkReply !== "") {
          if (replyId === "") {
            let replyData = {
              replyText: formik.values.remarkReply,
              replyByUserId: loggedInUser._id,
            };
            await saveRemarkReply(props.remarkId, replyData);
            dispatch(actionSuccess("Reply added successfully"));
          } else {
            let replyData = {
              replyText: formik.values.remarkReply,
              replyByUserId: loggedInUser._id,
            };
            await updateRemarkReply(props.remarkId, replyId, replyData);
            dispatch(actionSuccess("Reply updated successfully"));
          }
          // setReplyBox("")
          formik.setValues({ ...formik.initialValues });

          setReplyId("");
          loadRemarks(props.remarkEntityId, props.remarkId);
        } else {
          dispatch(actionError("Reply is empty"));
        }
      }
    });
  };

  /*
        Description : Handle edit remark reply
    */
  const editRemarkReply = (value) => {
    formik.setValues({ remarkReply: value.replyText });
    setReplyBox(value.replyText);
    setReplyId(value.id);
  };
  useEffect(() => {
    // loadRemarks(props.remarkEntityId, props.remarkId)
  }, []);

  return (
    <>
      {/* render reply add */}
      <div style={{ width: "100%" }}>
        {props.singleRemark.remarkReply &&
          props.singleRemark.remarkReply.length > 0 &&
          props.singleRemark.remarkReply.map((data, j) => {
            let date = getDateFromMongoDate(data.replyDate);
            return (
              <React.Fragment key={`repl-${j}`}>
                <div className="row user-icon">
                  <div className="col-md-2 " style={{ paddingLeft: "25px", paddingTop: "8px" }}>
                    <FaUser size={16} color="#A1A1A1" />
                  </div>
                  <div className="col-md-8 reply-user-name">
                    <div>
                      <div className="comment-reply" title={`${data.replyByUserFname} ${data.replyByUserLname}`}>
                        {data.replyByUserFname}&nbsp;{data.replyByUserLname}
                      </div>
                      <div
                        className="datedata"
                        style={{
                          font: "normal normal normal 10px/25px Arial",
                          color: "#4B4B55",
                        }}
                      >
                        {date}
                      </div>
                      <br />
                    </div>
                  </div>

                  <div className="col-md-2" onClick={() => editRemarkReply(data)}>
                    {props.remarkStatus !== "Closed" && loggedInUser._id === data.replyByUserId && remarkAccess && (
                      <MdModeEdit size={15} style={{ color: "#757575", cursor: "pointer" }} />
                    )}
                  </div>
                </div>
                <div>
                  <span className="reply-text">{data.replyText}</span>
                </div>
              </React.Fragment>
            );
          })}

        {props.remarkStatus !== "Closed" && (
          <div className="text-box">
            {remarkAccess && (
              <TextField
                {...props}
                formik={formik}
                multiline={true}
                numberCount={true}
                maxLength={500}
                rows={3}
                name="remarkReply"
                className="remark-input"
                id="remarkReply"
                placeholder="Add a reply"
                value={formik.values.remarkReply}
              />
            )}
          </div>
        )}
        {props.remarkStatus !== "Closed" && remarkAccess && (
          <div className="col-md-12 text-right " style={{ width: "100%" }}>
            <button className="btn btn-outline-dark reply-action-btn" onClick={() => props.handleShowAllReply(false)}>
              Cancel
            </button>
            <button className="btn btn-primary ml-3 reply-action-btn" onClick={addRemarkReply}>
              Submit
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default RemarkReply;
