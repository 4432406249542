/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : TV API 
----------------------------------------------------------------
	Creation Details
	Date Created				: 17/Mar/2021
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import { TV_API as API } from "../../config/apiConfig";
import { TV_URL } from "../../config";

// fetch Video list
export const fetchVideos = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/video",
    method: "GET",
    params: params,
  });
};
// fetch Custom Tags
export const fetchCustomTags = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/video/custom-tags",
    method: "GET",
    params: params,
  });
};

// fetch single video details
export const fetchSingleVideo = (videoId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/video/${videoId}`,
    method: "GET",
    params: params,
  });
};

// Add new Video
export const addNewVideo = (payload) => {
  return API({
    url: "/video",
    method: "POST",
    data: payload,
  });
};
// Edit existing video
export const updateVideo = (videoId, payload) => {
  return API({
    url: `/video/${videoId}`,
    method: "PUT",
    data: payload,
  });
};
// delete single video
export const deleteSingleVideo = (videoId, payload) => {
  return API({
    url: `/video/${videoId}`,
    method: "DELETE",
    data: payload,
  });
};
// delete selected videos
export const deleteSelectedVideos = (payload, params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/video/all",
    method: "DELETE",
    params: params,
    data: payload,
  });
};

// Get all questions
export const getQuestions = (videoId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/video/${videoId}/question`,
    method: "GET",
    params: params,
  });
};
// Get single question

export const getSingleQuestion = (videoId, questionId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/video/${videoId}/question/${questionId}`,
    method: "GET",
    params: params,
  });
};
// Add new question
export const addQuestion = (videoId, payload) => {
  return API({
    url: `/video/${videoId}/question`,
    method: "POST",
    data: payload,
  });
};
// Update question
export const editQuestion = (videoId, payload, questionId) => {
  return API({
    url: `/video/${videoId}/question/${questionId}`,
    method: "PUT",
    data: payload,
  });
};
// Delete question
export const deleteQuestion = (videoId, questionId) => {
  return API({
    url: `/video/${videoId}/question/${questionId}`,
    method: "DELETE",
  });
};

// Get all documents
export const getDocuments = (videoId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/video/${videoId}/document`,
    method: "GET",
    params: params,
  });
};
// Get single document

export const getSingleDocument = (videoId, documentId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/video/${videoId}/document/${documentId}`,
    method: "GET",
    params: params,
  });
};
// Add new document
export const addDocument = (videoId, payload) => {
  return API({
    url: `/video/${videoId}/document`,
    method: "POST",
    data: payload,
  });
};
// Update document
export const editDocument = (videoId, payload, documentId) => {
  return API({
    url: `/video/${videoId}/document/${documentId}`,
    method: "PUT",
    data: payload,
  });
};
// Delete document
export const deleteDocument = (videoId, documentId) => {
  return API({
    url: `/video/${videoId}/document/${documentId}`,
    method: "DELETE",
  });
};

// Get all hosts
export const getHosts = (videoId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/video/${videoId}/host`,
    method: "GET",
    params: params,
  });
};
// Get single host

export const getSingleHost = (videoId, hostId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/video/${videoId}/host/${hostId}`,
    method: "GET",
    params: params,
  });
};
// Add new host
export const addHost = (videoId, payload) => {
  return API({
    url: `/video/${videoId}/host`,
    method: "POST",
    data: payload,
  });
};
// Update host
export const editHost = (videoId, payload, hostId) => {
  return API({
    url: `/video/${videoId}/host/${hostId}`,
    method: "PUT",
    data: payload,
  });
};
// Delete host
export const deleteHost = (videoId, hostId) => {
  return API({
    url: `/video/${videoId}/host/${hostId}`,
    method: "DELETE",
  });
};

// get all video texonomy
export const fetchVideoTaxonomy = (videoId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/video/${videoId}/taxonomy/technology`,
    method: "GET",
    params: params,
  });
};
// Get single video texonomy
export const fetchSingleVideoTaxonomy = (videoId, taxonomyId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/video/${videoId}/taxonomy/technology/${taxonomyId}`,
    method: "GET",
    params: params,
  });
};
// Save taxonomy
export const saveVideoTaxonomy = (videoId, taxonomyIds = []) => {
  return API({
    url: `/video/${videoId}/taxonomy/technology`,
    method: "POST",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};
// Update taxonomy
export const editTaxonomy = (videoId, payload, questionId) => {
  return API({
    url: `/video/${videoId}/taxonomy/${questionId}`,
    method: "PUT",
    data: payload,
  });
};
// Delete taxonomy
export const deleteSigleVideoTaxonomy = (videoId, taxonomyId) => {
  return API({
    url: `/video/${videoId}/taxonomy/technology/${taxonomyId}`,
    method: "DELETE",
  });
};
// Delete taxonomy
export const deleteVideoTaxonomy = (videoId, payload, params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: `/video/${videoId}/taxonomy/technology`,
    method: "DELETE",
    data: payload,
    params: params,
  });
};

export const fetchImageUrl = (fileName) => {
  return `${TV_URL}/video/image/${fileName}`;
};
export const fetchDocUrl = (file, originalFileName) => {
  return `${TV_URL}/video/document/${file}/${originalFileName}`;
};

// tag objective
export const saveVideoObjective = (payload) => {
  return API({
    url: "/video/objective",
    method: "POST",
    data: payload,
  });
};

//tag objective taxonomy
export const saveVideoObjectiveTaxonomy = (payload) => {
  return API({
    url: "/video/objective/taxonomy",
    method: "POST",
    data: payload,
  });
};

export const fetchVideoObjectiveTaxonomy = (objectiveId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/video/objective/taxonomy/${objectiveId}`,
    method: "GET",
    params: params,
  });
};

export const deleteVideoObjective = (objectiveId, payload) => {
  return API({
    url: `/video/objective/${objectiveId}`,
    method: "DELETE",
    data: payload,
  });
};

//KIA Tagging APis

// Fetch KIA Tagging
export const fetchVideoKia = (videoId) => {
  return API({
    url: `/video/${videoId}/taxonomy/kia`,
    method: "GET",
  });
};
// Save KIA tags
export const saveVideoKiaTaxonomy = (videoId, payload) => {
  return API({
    url: `/video/${videoId}/taxonomy/kia`,
    method: "POST",
    data: payload,
  });
};
// Delete Kia Te
export const deleteVideoKia = (videoId, payload) => {
  return API({
    url: `/video/${videoId}/taxonomy/kia`,
    method: "DELETE",
    data: payload,
  });
};

export const deleteManyVideoTechnology = (videoId, taxonomyIds) => {
  return API({
    url: `/video/${videoId}/taxonomy/technology`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};

// Taxonomy bulk tagging
export const saveVideoTaxonomyTags = (payload) => {
  return API({
    url: `/video/taxonomy/technology`,
    method: "PUT",
    data: payload,
  });
};
export const saveVideokiaTags = (payload) => {
  return API({
    url: `/video/taxonomy/kia`,
    method: "PUT",
    data: payload,
  });
};
//tag objective taxonomy
export const saveVideoBusinessObjectiveTaxonomy = (payload) => {
  return API({
    url: "/video/objective/taxonomy",
    method: "PUT",
    data: payload,
  });
};
//tag industry trend taxonomy
export const savevideoTrendTags = (payload) => {
  return API({
    url: `/video/taxonomy/trend`,
    method: "PUT",
    data: payload,
  });
};

export const deleteManyVideoTrend = (videoId, taxonomyIds = []) => {
  return API({
    url: `/video/${videoId}/taxonomy/trend`,
    method: "DELETE",
    data: { taxonomyIds: [...taxonomyIds] },
  });
};

// save video Trend
export const saveVideoTrend = (videoId, payload) => {
  return API({
    url: `/video/${videoId}/taxonomy/trend`,
    method: "POST",
    data: payload,
  });
};

// delete video Trend
export const deleteVideoTrend = (videoId, trendId) => {
  return API({
    url: `/video/${videoId}/taxonomy/trend/${trendId}`,
    method: "DELETE",
  });
};

export const fetchVideoTrend = (videoId) => {
  return API({
    url: `/video/${videoId}/taxonomy/trend`,
    method: "GET",
  });
};

//Update content tags for video
export function updateContentTags(videoId, payload) {
  return API({
    url: `/video/all/${videoId}/tags`,
    method: "PUT",
    data: payload,
  });
}

//   add or update sequence of display to taxonomy
export const saveVideoTrendSequence = (videoId, payload) => {
  return API({
    url: `/video/${videoId}/taxonomy/trend/sort-order`,
    method: "PUT",
    data: payload,
  });
};
