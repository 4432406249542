/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Assessment Listing Page
	 - Use common grid component for list
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 12/Jul/2021 
	Author						: Pankaj Kulshreshtha
================================================================ 
*/
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { fetchUserAssessment, deleteUserAssessment, fetchUserAssessmentDocUrl } from "../../../../../middleware/services/userApi";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../../../middleware/actions/utilityAction";
import AddAssessmentModel from "./add";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import "./assessment.scss";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

export default (props) => {
  const { accessRights: aR } = props;
  const editUser = props.path === "/external-user/add" ? false : true;
  const userId = props.userId;
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [assessmentData, setAssessmentData] = useState([]);
  const [stepCompleted, setStepCompleted] = useState(false);
  const [assessmentId, setAssessmentId] = useState("");

  /* Common access */
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionAddAssessment = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_ASSESSMENT);

  // specify breadcrumb here
  const breadCrumbLinksAddUser = [
    { linkUrl: "/external-user", linkName: aR.moduleName, linkActive: false },
    { linkUrl: "/external-user/add", linkName: "Add User", linkActive: true },
  ];
  const breadCrumbLinksEditUser = [
    { linkUrl: "/external-user", linkName: aR.moduleName, linkActive: false },
    { linkUrl: `/external-user/${userId}/edit#profile`, linkName: "Edit User", linkActive: true },
  ];

  const closeModal = () => {
    setAssessmentId("");
    setShowModal(false);
  };

  /* 
		@Description : Use for setting breadcrumb values
	*/
  useEffect(() => {
    const breadCrumbLinks = editUser ? [...breadCrumbLinksEditUser] : [...breadCrumbLinksAddUser];
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/external-user"));
    return () => {
      dispatch(setBreadCrumb());
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!showModal) {
      if (userId !== "") {
        formatRowData();
      }
      localStorage.removeItem("selectedUser");

      return () => {
        dispatch(setBreadCrumb());
        localStorage.removeItem("selectedUser");
      };
    }
  }, []);

  // formatRowData function
  const formatRowData = async () => {
    dispatch(showLoader());
    try {
      let formatedRowData = [];
      const response = await fetchUserAssessment(userId);
      if (response.status == 200) {
        dispatch(hideLoader());
        const userAssessmentData = response.data.data;
        let assessments = userAssessmentData && userAssessmentData.userAssessment;
        if (assessments.length > 0) {
          setStepCompleted(true);
          assessments.forEach((a) => {
            let data = {};
            data["assessmentId"] = a.assessmentId;
            data["assessmentName"] = a.assessmentName;
            data["assessmentStatus"] = a.assessmentStatus;
            data["assessmentTestResultFile"] = a.assessmentTestResultFile;
            formatedRowData.push(data);
          });
        }
      }
      setAssessmentData(formatedRowData);
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  const removeExp = (assessmentId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          deleteUserAssessment(userId, assessmentId)
            .then((response) => {
              dispatch(actionSuccess("Work experience deleted successfully"));
              formatRowData();
            })
            .catch((err) => {
              dispatch(actionError(err.data?.message || "Fail to delete Experiecnce"));
            });
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to delete?",
        title: "dialogAlertCssWarning",
      })
    );
  };

  return (
    <>
      {assessmentData && assessmentData.length > 8 && accessActionAddAssessment && (
        <>
          <div className="pr-3 float-right">
            <a className="add-link" onClick={() => setShowModal(true)}>
              {" "}
              + Add Assessment
            </a>
          </div>
          <br />
        </>
      )}
      {showModal && (
        <AddAssessmentModel showModal={showModal} closeModal={closeModal} userId={userId} assessmentId={assessmentId} formatRowData={formatRowData} />
      )}
      <div className="row">
        {assessmentData?.map(function (x, i) {
          return (
            <div className="col-md-3 pr-4 pl-4 pb-4 pt-3" key={`experience-${i}`} style={{ position: "relative" }}>
              <div style={{ display: `flex`, flexDirection: `column` }}>
                <div className="e-card e-card-horizontal" style={{ overflow: "unset" }}>
                  <div className="e-card-stacked" style={{}}>
                    <div style={{ position: "absolute", top: "5px", right: "0" }}>
                      {accessActionEdit || accessActionDelete ? (
                        <ThreeDotMenu
                          Edit={
                            accessActionEdit
                              ? () => {
                                  showModal && setShowModal(false);
                                  setShowModal(true);
                                  setAssessmentId(x["assessmentId"]);
                                }
                              : undefined
                          }
                          Delete={
                            accessActionDelete
                              ? () => {
                                  removeExp(x["assessmentId"]);
                                }
                              : undefined
                          }
                        />
                      ) : null}
                    </div>

                    <div className="e-card-header"></div>
                    <div className="row pl-4" style={{ position: "relative", top: "-10px" }}>
                      <div className="col-md-12">
                        <small className="text-muted">Assessment Name</small>
                      </div>
                      <div className="col-md-12 ">
                        <span style={{ fontFamily: "Roboto" }} className="font-bold-500">
                          {x["assessmentName"]}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <small className="text-muted">Status</small>
                      </div>
                      <div className="col-md-12">
                        <span style={{ fontFamily: "Roboto" }} className="font-bold-500">
                          {x["assessmentStatus"]}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <small className="text-muted">Test Result File</small>
                      </div>
                      <div className="col-md-12">
                        {x["assessmentTestResultFile"] && (
                          <span style={{ fontFamily: "Roboto" }} className="font-bold-500">
                            <a
                              data-tip={x["assessmentTestResultFile"]?.docName}
                              href={fetchUserAssessmentDocUrl(x["assessmentTestResultFile"]?.docFileName, x["assessmentTestResultFile"]?.docName)}
                            >
                              <div className="show-short-file-name" data-filetype={x["assessmentTestResultFile"]?.docName.split(".").pop()}>
                                <p>
                                  <i className="fas fa-download" data-tip="Download" /> {x["assessmentTestResultFile"]?.docName}
                                </p>
                              </div>
                            </a>
                            <ReactTooltip />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="p-2 ml-2 border-0">
        {accessActionAddAssessment && (
          <a className="add-link" onClick={() => setShowModal(true)}>
            {" "}
            + Add Assessment
          </a>
        )}
      </div>
      <div className="form-submit-box ml-2">
        <div className="container-fluid">
          <div className="row">
            <div className="float-left col-md-6 text-left">
              {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                <span className="pl-4" style={{ "margin-left": "-3em" }}>
                  <span className="span-link">
                    <button
                      type="button"
                      className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                      onClick={() => {
                        (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                          (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1));
                      }}
                    >
                      Previous
                    </button>
                  </span>
                </span>
              ) : null}

              {userId !== "" && props.selectedIndex > -1 && props.selectedIndex < 3 ? (
                <span className="pl-4" style={{ "margin-left": "0" }}>
                  <span className="span-link">
                    <button
                      type="button"
                      className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                      onClick={() => {
                        props.selectedIndex < 3 && props.setCurrentTab(props.selectedIndex + 1);
                      }}
                    >
                      Next
                    </button>
                  </span>
                </span>
              ) : null}
            </div>
            <div className="float-right col-md-6 text-right">
              <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3" onClick={() => history.push("/external-user")}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
