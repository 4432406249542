/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : KIA Listing Page 
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/June/2021
    Author						: Sandeep K. Sharma
================================================================
*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonDataGrid from "../../components/dataGrid";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { fetchKias, deleteSingleKia, deleteSelectedKias, updateKia, publishKia } from "../../../middleware/services/kiaApi";
import { swap, decodeHTML, getProductVariant, getDateFromMongoDate, fetchClientUsers } from "../../../utilities";
import { actionSuccess, actionError, hideLoader, showLoader } from "../../../middleware/actions/utilityAction";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import ThreeDotMenu from "../../components/threeDotMenu";
import "./index.scss";
import { visitRecord, setMaintainedState } from "../../../middleware/actions/gridAction";
import { resetRedux } from "../../../middleware/actions/taxonomyFilterAction";
import { COMMON_ACCESS_ACTION } from "../../../constants";

const KeyImpactAreaListing = (props) => {
  const [deleteId, setDeleteId] = useState(undefined);
  const [usersData, setUsersData] = useState(undefined);
  const history = useHistory();
  const dispatch = useDispatch();
  const productVariant = getProductVariant();
  const gridState = useSelector((state) => state.gridState);
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  /* Common access */
  const accessActionAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionPublish = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.PUBLISH);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  /*
       @Description : BreadCumb Defination
   */
  const breadCrumbLinks = [{ linkUrl: "/kia", linkName: "Key Impact Area Management", linkActive: true }];

  const fetchUsersData = async () => {
    setUsersData(await fetchClientUsers());
  };

  /*
       @Description : Load Navigation and Breadcrub on page load
   */
  useEffect(() => {
    if (!usersData) fetchUsersData();
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/kia"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  /*
       @Description : Three Dot Menu Action
   */
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        Edit={
          accessActionEdit
            ? () => {
                history.push(`/kia/${value.id}/edit`, { videoId: value.id });
                dispatch(visitRecord());
              }
            : undefined
        }
        Delete={
          accessActionDelete
            ? () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteSingleKia(value.id)
                        .then((response) => {
                          if (deleteId) setDeleteId(undefined);
                          setDeleteId(new Date());
                          dispatch(actionSuccess("Kia deleted successfully"));
                          const params = {};
                          params.limit = 100;
                          if (productVariant) params.filters = [["productVariant.productName", "eq", productVariant]];
                          fetchKias(params).then((v) => {
                            formatRowData(v.data.data);
                          });
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Failed to delete video"));
                        });
                    },
                  })
                );
              }
            : undefined
        }
        Publish={
          accessActionPublish
            ? () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      publishKia(value.id, { platform: productVariant })
                        .then((response) => {
                          if (deleteId) setDeleteId(undefined);
                          setDeleteId(new Date());
                          dispatch(actionSuccess("Impact Area Published successfully"));
                          const params = {};
                          params.limit = 100;
                          if (productVariant)
                            params.filters = [
                              ["productVariant.productName", "eq", productVariant],
                              ["kiaIsDelete", "eq", "NO"],
                            ];
                          fetchKias(params).then((v) => {
                            formatRowData(v.data.data);
                          });
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Failed to delete video"));
                        });
                    },
                  })
                );
              }
            : undefined
        }
      />
    );
  };
  /*
       @Description : Delete  Kia Name
   */

  const deleteManyFunc = (flag) => {
    const selectedKias = localStorage.getItem("selectedKias") ? JSON.parse(localStorage.getItem("selectedKias")) : [];
    if (flag) {
      if (selectedKias.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteSelectedKias({ kiaIds: selectedKias })
                .then((response) => {
                  if (response) {
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    const params = {};
                    params.limit = 100;
                    if (productVariant) params.filters = [["productVariant.productName", "eq", productVariant]];
                    fetchKias(params).then((v) => {
                      formatRowData(v.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete video"));
                });
            },
            content: "Are you sure to delete, selected record?",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };
  /*
       @Description : Template for Kia Name
   */
  const kiaTitleTemplate = (value) => {
    return (
      (
        <span
          className="span-link"
          onClick={() => {
            history.push(`/kia/${value.id}/edit`);
            dispatch(visitRecord());
          }}
        >
          {value["KIA Name"].length > 100 ? value["KIA Name"].substr(0, 100) + "\u2026" : value["KIA Name"]}
        </span>
      ) || <span>{value["KIA Name"]}</span>
    );
  };

  /*
      @Description : Template for Description 
  */
  const DescriptionTemplate = (value) => {
    if (value["Description"].length > 150) {
      return <span>{decodeHTML(value["Description"]).substr(0, 150) + "\u2026"}</span>;
    } else {
      return <span>{decodeHTML(value["Description"])}</span>;
    }
  };

  /* 
        @Description : Date Template 
    */
  const dateTemplate = (value) => {
    const column = value.column.field;
    return ["Published Date", "Created Date"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
      <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
    ) : (
      ""
    );
  };

  /* 
        @Description : Filters Template 
    */
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Published":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Custom KIA":
        sourceData = [
          { id: "Yes", text: "Yes" },
          { id: "No", text: "No" },
        ];
        break;
      case "Status":
        sourceData = [
          { id: "Active", text: "Under Review" },
          { id: "Closed", text: "Complete" },
          { id: "Draft", text: "Draft" },
          { id: "In-Progress", text: "In Progress" },
        ];
        break;
      case "Active":
        sourceData = [
          { id: "Yes", text: "Yes" },
          { id: "No", text: "No" },
        ];
        break;
      case "Client Users":
        sourceData = usersData;
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  /* 
        @Description : Template  for Yes No Button
    */

  const updateVisibilty = async (kiaId, payload) => {
    await updateKia(kiaId, payload)
      .then((res) => {
        if (res) {
          // console.log(payload)
          setDeleteId(new Date());
          payload.kiaIsActive === "Yes"
            ? dispatch(actionSuccess("Key Impact Area is activated"))
            : dispatch(actionSuccess("Key Impact Area is de-activated"));
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
    setDeleteId(new Date());
  };

  const statusTemplate = (value) => {
    return (
      <div className="text-center" style={{ marginLeft: "30px" }}>
        <label className="custom-switch3">
          <input
            type="checkbox"
            value={value["Active"]}
            checked={value["Active"] == "Yes" ? true : false}
            onChange={(e) => {
              let payload = {
                kiaIsActive: value["Active"] == "Yes" ? "No" : "Yes",
              };
              updateVisibilty(value.id, payload);
            }}
          />
          <div>
            <span className="on">Yes</span>
            <span className="off">No</span>
          </div>
          <i></i>
        </label>
      </div>
    );
  };

  const columnNames = {
    id: "id",
    "KIA Name": "kiaName",
    Industry: "kiaIndustryTag.industryName",
    "Client Users": "kiaClientUsers.userId",
    "Created Date": "kiaCreatedDate",
    "Published Date": "kiaPublishedDate",
    Status: "kiaStatus",
    Active: "kiaIsActive",
    Published: "kiaIsLive",
    "Custom KIA": "customKia",
  };
  if (productVariant) columnNames["Published"] = `productVariant~productName:${productVariant},isLive`;
  else columnNames["Published"] = "productVariant.isLive";

  const columnFields = [
    {
      field: "id",
      type: "string",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "KIA Name",
      type: "String",
      template: kiaTitleTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 200,
    },
    { field: "Industry", type: "String", textAlign: "Left", headerTextAlign: "Left", showInColumnChooser: false, width: 140 },
    {
      field: "Client Users",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "contains" },
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      disableHtmlEncode: false,
      width: 150,
    },
    {
      field: "Status",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      width: 130,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
    {
      field: "Active",
      type: "String",
      template: statusTemplate,
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      headerTextAlign: "Center",
      textAlign: "Center",
      width: 140,
      showInColumnChooser: true,
    },
    {
      field: "Custom KIA",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      width: 170,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
    {
      field: "Created Date",
      template: dateTemplate,
      type: "Date",
      textAlign: "Center",
      width: 180,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
    { field: "Published Date", type: "Date", template: dateTemplate, width: 190, textAlign: "Center", headerTextAlign: "Center" },
    {
      field: "Published",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 160,
    },
  ];
  if (accessActionEdit || accessActionPublish || accessActionDelete) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Center",
      headerTextAlign: "Center",
    });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }

        if (rD.productVariant?.length) {
          if (productVariant) {
            const rDPV = rD.productVariant.find((pV) => pV.productName === productVariant);
            newRD["Published"] = rDPV?.isLive ? rDPV.isLive : "NO";
          } else newRD["Published"] = rD.productVariant[0].isLive ? rD.productVariant[0].isLive : "NO";
        }
        return newRD;
      });
      if (rD?.kiaClientUsers) {
        const ids = rD?.kiaClientUsers.map((u) => {
          return u.userId;
        });

        const userData = usersData.filter((item) => {
          return ids.includes(item.id);
        });
        newRD["Client Users"] = userData
          .map((item) => {
            return item.text;
          })
          .filter((t, index, self) => t !== "" && self.indexOf(t) === index)
          .join(", ");
      } else {
        newRD["Client Users"] = "";
      }

      newRD["Industry"] = rD.kiaIndustryTag[0].industryName;
      if (rD.kiaStatus == "Active") {
        newRD["Status"] = "Under Review";
      } else if (rD.kiaStatus == "Closed") {
        newRD["Status"] = "Complete";
      } else if (rD.kiaStatus == "In-Progress") {
        newRD["Status"] = "In Progress";
      } else {
        newRD["Status"] = rD.kiaStatus;
      }
      newRD["Active"] = rD.kiaIsActive;
      newRD["Custom KIA"] = rD.customKia ? (rD.customKia === "Yes" ? "YES" : "NO") : "NO";
      newRD["Created Date"] = new Date(rD.kiaCreatedDate);
      newRD["Published Date"] = new Date(rD.kiaPublishedDate);
      return newRD;
    });
    return formatedRowData;
  };

  const getKeyImpactAreas = (params = {}) => {
    let defaultFilter = [["productVariant.productName", "eq", productVariant]];
    let fields = [
      "kiaName",
      "kiaIndustryTag",
      "kiaClientUsers",
      "kiaStatus",
      "kiaIsActive",
      "kiaCreatedDate",
      "kiaPublishedDate",
      "productVariant",
      "customKia",
    ];
    let { filters = [] } = params;
    if (!params.sort) params.sort = "kiaCreatedDate:desc";
    if (filters) {
      filters = filters.map((item) => {
        const tech = usersData.filter((f) => {
          return item[0] === "kiaClientUsers.userId" && f.text === item[2];
        });

        return item[0] === "kiaClientUsers.userId" ? [item[0], "in", [tech[0].id]] : item;

        // return item[0] === "companyInQueue.queueStatus";
      });
    }
    params.filters = [...filters, ...defaultFilter];
    params.fields = fields;
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchKias(params);
    }

    return fetchKias(params);
  };
  // Reset Redux
  useEffect(() => {
    const locationPath = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];
    if (locationPath != `/kia`) {
      dispatch(resetRedux());
      // localStorage.setItem("locationPath", window.location.pathname);
    }
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3 ">
      <div className="gennx-grid-container">
        {usersData && (
          <CommonDataGrid
            gridTitle="Key Impact Areas"
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            fetch={getKeyImpactAreas}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            deleteId={deleteId}
            getMultiSelectedRows={(data) => {
              localStorage.setItem("selectedKias", JSON.stringify([...data]));
            }}
            getMultiDeSelectedRows={(data) => {
              localStorage.setItem("selectedKias", JSON.stringify([...data]));
            }}
            addNew={() => history.push(`/kia/add`)}
            addNewRight={accessActionAddNew}
            deleteRight={accessActionDelete}
            deleteMany={accessActionDelete ? deleteManyFunc : false}
            clearFilterByTaxonomy={true}
          />
        )}
      </div>
    </div>
  );
};

export default KeyImpactAreaListing;
