/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Technology List Saga (Redux Middleware)
----------------------------------------------------------------
	Creation Details
	Date Created				: 28/Dec/2023
	Author						  : Lawrence Anthony
================================================================
*/

import { all, takeLatest, call, put } from "redux-saga/effects";
import { getTrendsList, toggleLoading } from "../actions/searchAction";
import http from "../services/http-common";
import { CMS_API_URL } from "../../config";
import { getObjectiveSgfTag } from "../../middleware/services/cmsApi";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";

function* fetchTrendsSaga() {
  try {
    // yield put(toggleLoading(true));
    // Arrays to store the extracted values
    const sgfIdsSearch = [];
    let subscribeSgfs = [];

    let allTrends = yield call(getObjectiveSgfTag, {
      filters: [
        [
          "sector.sectorId",
          "in",
          [
            "5b1e73b110ddc9e2482e832c",
            "5b1e73b110ddc9e2482e832b",
            "5b1e73b110ddc9e2482e832a",
            "63357c1a3a9be40d03da1127",
            "655dae568c5aab06f3f68235",
          ],
        ],
      ],
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
      sort: "trendName:asc",
    });

    let allTrendsArray = allTrends.data.data;

    // Iterate through each object in allTrendsArray
    allTrendsArray.forEach((obj) => {
      // Extract and push the id to sgfIdsSearch
      sgfIdsSearch.push(obj.id);

      // Extract trendIds and push them to subscribeSgfs
      if (obj.trend) {
        const trendIds = obj.trend.map((trendObj) => trendObj.trendId);

        subscribeSgfs.push(...trendIds);
      }
    });
    subscribeSgfs = [...new Set(subscribeSgfs)];
    yield put(getTrendsList({ sgfIdsSearch, subscribeSgfs }));
    // yield put(toggleLoading(false));
  } catch (error) {
    // yield put(toggleLoading(false));

    console.error("Error fetching technology list:", error);
    // Handle errors here if needed
  }
}

const trendsSaga = [takeLatest("FETCH_TRENDS_LIST", fetchTrendsSaga)];

export default trendsSaga;
