/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 24/Jun/2021
 * File Description: Training Video Routes
 */
import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";

import VideoListing from "../app/views/trainingVideo/index";
import Form from "../app/views/trainingVideo/videoDetails";

const TRAINING_VIDEO = ["Training Video:FULL"];

const ADD = ["Add:VIEW", "Add:FULL"];
const EDIT = ["Edit:VIEW", "Edit:FULL"];
const DELETE = ["Delete:VIEW", "Delete:FULL"];

const AssessmentRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/training-video"
      component={VideoListing}
      pageHeading="GenNx CMS - Training Video Management"
      accessRights={{
        moduleName: "Training Video",
        parentModuleName: "Outsourcing",
        moduleAttribute: [...TRAINING_VIDEO, ...ADD, ...EDIT, ...DELETE],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path={"/training-video/:videoId/edit"}
      component={Form}
      pageHeading="GenNx CMS - Training Video Management"
      accessRights={{
        moduleName: "Training Video",
        parentModuleName: "Outsourcing",
        moduleAttribute: [...TRAINING_VIDEO, ...EDIT],
        interfaceName: "Basic",
      }}
    />

    <AuthLayout
      exact
      path="/training-video/add"
      component={Form}
      pageHeading="GenNx CMS - Training Video Management"
      accessRights={{
        moduleName: "Training Video",
        parentModuleName: "Outsourcing",
        moduleAttribute: [...TRAINING_VIDEO, ...ADD],
        interfaceName: "Basic",
      }}
    />

    {/* <DefaultLayout exact
			path={["/survey-render/:surveyShortName/:surveyAudienceCode", "/survey-render/:surveyShortName"]} component={SurveyRender} pageHeading="Survey" /> */}
  </Switch>
);

export default AssessmentRoutes;
