/**
 * @author: Anand Kumar
 * @date : 04/Jan/2021
 * File Description: Business Objective Validation Schema
 */
import * as Yup from "yup";

export const businessObjectiveValidationSchema = {
    formTitle: Yup.string().trim("Please enter form title").max(30, "Form title must be at most 30 characters").required("Please enter form title"),
    formType: Yup.string().required("Please select form type").typeError("Please select form type"),
    formUniqueName: Yup.string().when('formType', {
        is: obj => {return obj === undefined || (obj && obj === "registration") },
        then: Yup.string().trim("Please enter form unique name").max(30, "Form unique name must be at most 30 characters").required("Please enter form unique name").nullable(),
        // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
    }),
    formUrl: Yup.string().when('formType', {
        is: obj => { return obj === undefined || (obj && obj === "registration") },
        then: Yup.string().trim("Please enter form url").required("Please enter form url").nullable(),
    }),
    formPlaceholder: Yup.string().when('formType', {
        is: obj => { return obj === undefined || (obj && obj === "sbo") },
        then: Yup.string().required("Please select placeholder").nullable(),
        // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
    }),
};