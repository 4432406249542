/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company State
----------------------------------------------------------------
	Creation Details
	Date Created				: 19/09/2022
	Author						: PRASHANT WANKHADE
================================================================
*/
const TAXONOMY_STATE = {
	taxonomyCheckedNodes: [],
	taxonomyUnCheckedNodes: [],
	kiaCheckedNodes: [],
	kiaUnCheckedNodes: [],
	valueChainCheckedNodes: [],
	valueChainUnCheckedNodes: [],
	trendCheckedNodes: [],
	trendUnCheckedNodes: [],
	mediaCheckedNodes: [],
	mediaUnCheckedNodes: [],
	transformingCheckedNodes: [],
	objectiveTaxonomyCheckedNodes: [],
	objectiveTaxonomyUnCheckedNodes: [],
	transformingUnCheckedNodes: [],
	initialTechnologyCheckedNodes: [],
	initialKiaCheckedNodes: [],
	initialValueChainCheckedNodes: [],
	initialTrendCheckedNodes: [],
	initialMediaCheckedNodes: [],
	initialObjectiveCheckedNodes: [],
	initialSgfCheckedNodes:[]
};

export default (state = TAXONOMY_STATE, action) => {
	switch (action.type) {
		case 'SET_COMMON_TAXONOMY_CHECKED_NODES': {
			return { ...state, taxonomyCheckedNodes: action.payload };
		}
		case 'SET_COMMON_TAXONOMY_UNCHECKED_NODES': {
			return { ...state, taxonomyUnCheckedNodes: action.payload };
		}
		case 'SET_COMMON_KIA_CHECKED_NODES': {
			return { ...state, kiaCheckedNodes: action.payload };
		}
		case 'SET_COMMON_KIA_UNCHECKED_NODES': {
			return { ...state, kiaUnCheckedNodes: action.payload };
		}
		case 'SET_COMMON_VALUE_CHAIN_CHECKED_NODES': {
			return { ...state, valueChainCheckedNodes: action.payload };
		}
		case 'SET_COMMON_VALUE_CHAIN_UNCHECKED_NODES': {
			return { ...state, valueChainUnCheckedNodes: action.payload };
		}
		case 'SET_COMMON_TREND_CHECKED_NODES': {
			return { ...state, trendCheckedNodes: action.payload };
		}
		case 'SET_COMMON_TREND_UNCHECKED_NODES': {
			return { ...state, trendUnCheckedNodes: action.payload };
		}
		case 'SET_COMMON_MEDIA_CHECKED_NODES': {
			return { ...state, mediaCheckedNodes: action.payload };
		}
		case 'SET_COMMON_MEDIA_UNCHECKED_NODES': {
			return { ...state, mediaUnCheckedNodes: action.payload };
		}
		case 'SET_COMMON_TRANSFORMING_CHECKED_NODES': {
			return { ...state, transformingCheckedNodes: action.payload };
		}
		case 'SET_COMMON_TRANSFORMING_UNCHECKED_NODES': {
			return { ...state, transformingUnCheckedNodes: action.payload };
		}
		case 'SET_COMMON_OBJECTIVE_TAXONOMY_CHECKED_NODES': {
			return { ...state, objectiveTaxonomyCheckedNodes: action.payload };
		}
		case 'SET_COMMON_OBJECTIVE_TAXONOMY_UNCHECKED_NODES': {
			return { ...state, objectiveTaxonomyUnCheckedNodes: action.payload };
		}
		case 'SET_TAXONOMY_INITIAL_CHECKED_NODES': {
			return { ...state, initialTechnologyCheckedNodes: action.payload };
		}
		case 'SET_KIA_INITIAL_CHECKED_NODES': {
			return { ...state, initialKiaCheckedNodes: action.payload };
		}
		case 'SET_VALUE_CHAIN_INITIAL_CHECKED_NODES': {
			return { ...state, initialValueChainCheckedNodes: action.payload };
		}
		case 'SET_TREND_INITIAL_CHECKED_NODES': {
			return { ...state, initialTrendCheckedNodes: action.payload };
		}
		case 'SET_MEDIA_INITIAL_CHECKED_NODES': {
			return { ...state, initialMediaCheckedNodes: action.payload };
		}
		case 'SET_OBJECTIVE_INITIAL_CHECKED_NODES': {
			return { ...state, initialObjectiveCheckedNodes: action.payload };
		}
		case 'SET_SGF_INITIAL_CHECKED_NODES': {
			return { ...state, initialSgfCheckedNodes: action.payload };
		}

		case 'SET_RESET_REDUX': {
			return {
				taxonomyCheckedNodes: [],
				taxonomyUnCheckedNodes: [],
				kiaCheckedNodes: [],
				kiaUnCheckedNodes: [],
				valueChainCheckedNodes: [],
				valueChainUnCheckedNodes: [],
				trendCheckedNodes: [],
				trendUnCheckedNodes: [],
				mediaCheckedNodes: [],
				mediaUnCheckedNodes: [],
				transformingCheckedNodes: [],
				objectiveTaxonomyCheckedNodes: [],
				objectiveTaxonomyUnCheckedNodes: [],
				transformingUnCheckedNodes: [],
				initialTechnologyCheckedNodes: [],
				initialKiaCheckedNodes: [],
				initialValueChainCheckedNodes: [],
				initialTrendCheckedNodes: [],
				initialMediaCheckedNodes: [],
				initialObjectiveCheckedNodes: [],
				initialSgfCheckedNodes:[]
			};
		}


		default:
			return state;
	}
};
