/**
 * @author: Suraj Varane | Cheers Interactive
 * @date : 16/May/22
 * File Description: Task Listing
 */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonDataGrid from "../../../../../components/dataGrid";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import { fetchDeliverableTasks, deleteDeliverableTask } from "../../../../../../middleware/services/deliverablesApi";
import { getLoggedInUser, getDateFromMongoDate, accessRightActionCheck } from "../../../../../../utilities";
import { actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";
import ThreeDotMenu from "../../../../../components/threeDotMenu";
import { StringFilter, DropDownFilter } from "../../../../../components/dataGrid/fliters";
import { internalGridVisited, setInternalGridState } from "../../../../../../middleware/actions/gridAction";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const TaskListing = (props) => {
  const [deliverableName, setDeliverableName] = useState(props.deliverableName);
  const [deleteId, setDeleteId] = useState(undefined);
  const history = useHistory();
  const dispatch = useDispatch();
  const gridState = useSelector((state) => state.gridState);
  const accessAdministrator = props?.accessAdministrator;
  const deliverableId = props.deliverableId ? props.deliverableId : "";
  let interfaceName = "Task";
  let actionAccess = accessRightActionCheck(props?.moduleName, interfaceName) || [];
  const accessActionTaskAdd = actionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionTaskEditTask = actionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_TASK);
  const accessActionTaskEditContent = actionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_CONTENT);
  const accessActionTaskDelete = actionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  const deleteRecord = (id) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          deleteDeliverableTask(deliverableId, id)
            .then((response) => {
              if (response) {
                dispatch(actionSuccess("Task deleted successfully"));
                const params = {};
                params.limit = 100;
                let fields = [];
                params.fields = fields;
                let { filters = [] } = params;
                if (!params.sort) params.sort = "_id:desc";
                params.filters = [...filters, ["deliverableId", "eq", deliverableId], ["taskIsDeleted", "eq", "NO"]];
                fetchDeliverableTasks(deliverableId, params).then((form) => {
                  formatRowData(form?.data?.data || []);
                });
                setDeleteId(new Date());
              } else {
                dispatch(actionError("Something went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "Failed to delete task"));
            });
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to delete ? ",
        title: "dialogAlertCssWarning",
      })
    );
  };
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionTaskEditTask
          ? {
              EditTask: () => {
                dispatch(internalGridVisited());
                history.push(`/deliverable/${deliverableId}/task/${value.id}/edit`, {
                  deliverableId: deliverableId,
                  taskId: value.id,
                  deliverableName: deliverableName,
                });
              },
            }
          : {})}
        {...(accessActionTaskDelete
          ? {
              Delete: () => {
                deleteRecord(value?.id);
              },
            }
          : {})}
        {...(accessActionTaskEditContent
          ? {
              EditContent: () => {
                props.handleTabRef(2);
                props.setTaskId(value.id);
              },
            }
          : {})}
      />
    );
  };

  const taskStatusFilter = (value) => {
    let sourceData = [
      { id: "To do", text: "To do" },
      { id: "Ongoing", text: "Ongoing" },
      { id: "Closed", text: "Closed" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  const taskTypeFilter = (value) => {
    let sourceData = [
      { id: "Writer", text: "Writer" },
      { id: "Reviewer", text: "Reviewer" },
      { id: "Publisher", text: "Publisher" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  const dateTemplate = (value) => {
    switch (value.column.field) {
      case "Est. Start Date":
        return !isNaN(Date.parse(value["Est. Start Date"])) ? <span>{getDateFromMongoDate(new Date(value["Est. Start Date"]))}</span> : "";
      case "Est. End Date":
        return !isNaN(Date.parse(value["Est. End Date"])) ? <span>{getDateFromMongoDate(new Date(value["Est. End Date"]))}</span> : "";
      case "Act. Start Date":
        return !isNaN(Date.parse(value["Act. Start Date"])) ? <span>{getDateFromMongoDate(new Date(value["Act. Start Date"]))}</span> : "";
      case "Act. End Date":
        return !isNaN(Date.parse(value["Act. End Date"])) ? <span>{getDateFromMongoDate(new Date(value["Act. End Date"]))}</span> : "";
      case "Last Updated Date":
        return !isNaN(Date.parse(value["Last Updated Date"])) ? <span>{getDateFromMongoDate(new Date(value["Last Updated Date"]))}</span> : "";
      default:
        return "";
    }
  };
  const taskNameTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(`/deliverable/${deliverableId}/task/${value.id}/edit`, {
            deliverableId: deliverableId,
            taskId: value.id,
            deliverableName: deliverableName,
          });
          dispatch(internalGridVisited());
        }}
      >
        {value["Task Type"]}
      </span>
    );
  };

  const columnNames = {
    id: "id",
    "Task Type": "taskType",
    "Assigned To": "taskAssignedTo.userName",
    Status: "taskStatus",
    "Est. Start Date": "taskEstStartDate",
    "Est. End Date": "taskEstEndDate",
    "Act. Start Date": "taskActStartDate",
    "Act. End Date": "taskActEndDate",
    "Last Updated Date": "taskModifiedDate",
  };

  const columnFields = [
    {
      field: "id",
      type: "string",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "Task Type",
      type: "String",
      template: taskNameTemplate,
      filterTemplate: taskTypeFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 100,
    },
    { field: "Assigned To", type: "String", textAlign: "Left", width: 100, showInColumnChooser: true, filterTemplate: StringFilter },
    {
      field: "Status",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 100,
      filterTemplate: taskStatusFilter,
    },
    {
      field: "Est. Start Date",
      headerText: "Est. Start Date",
      type: "Date",
      template: dateTemplate,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      width: 120,
    },
    {
      field: "Est. End Date",
      headerText: "Est. End Date",
      type: "Date",
      template: dateTemplate,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      width: 120,
    },
    {
      field: "Act. Start Date",
      headerText: "Act. Start Date",
      type: "Date",
      template: dateTemplate,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      width: 120,
    },
    {
      field: "Act. End Date",
      headerText: "Act. End Date",
      type: "Date",
      template: dateTemplate,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      width: 120,
    },
    {
      field: "Last Updated Date",
      headerText: "Last Updated Date",
      type: "Date",
      template: dateTemplate,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      width: 120,
    },
  ];

  if (accessActionTaskEditTask || accessActionTaskDelete || accessActionTaskEditContent) {
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  const formatRowData = (rowData) => {
    let formatedRowData = [];
    rowData.forEach((rD) => {
      let data = {};
      data.id = rD.id;
      data["Task Type"] = rD.taskType;
      data["Assigned To"] = rD.taskAssignedTo.userName;
      data["Status"] = rD.taskStatus;
      data["Est. Start Date"] = rD.taskEstStartDate;
      data["Est. End Date"] = rD.taskEstEndDate;
      data["Act. Start Date"] = rD.taskActStartDate;
      data["Act. End Date"] = rD.taskActEndDate;
      data["Last Updated Date"] = rD.taskModifiedDate;
      formatedRowData.push(data);
    });
    return formatedRowData;
  };

  const getDeliverableTasks = (params = {}) => {
    let fields = [];
    let { filters = [] } = params;
    if (accessAdministrator) {
      params.filters = [...filters, ["deliverableId", "eq", deliverableId], ["taskIsDeleted", "eq", "NO"]];
    } else {
      let user = getLoggedInUser();
      params.filters = [...filters, ["deliverableId", "eq", deliverableId], ["taskAssignedTo.userId", "eq", user._id], ["taskIsDeleted", "eq", "NO"]];
    }
    if (!gridState.internalGridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setInternalGridState(params.skip, params.sort, params.filters));
    }
    if (gridState.internalGridState.recordVisited) {
      params = {
        filters: gridState.internalGridState.filtersParams,
        sort: gridState.internalGridState.sortRecords,
        skip: gridState.internalGridState.skipRecords,
      };
      dispatch(setInternalGridState(params.skip, params.sort, params.filters));
    }
    params.sort = "taskCreatedDate:desc";
    params.fields = fields;
    return fetchDeliverableTasks(deliverableId, params);
  };

  return (
    <div className={` content-wrapper ${props.deliverableId ? "gennx-grid-container-custom mt-3 ml-2 mr-3" : "gennx-grid-container  px-3"}`}>
      <div className={`gennx-grid-container `}>
        <CommonDataGrid
          gridTitle="Tasks List"
          fetch={getDeliverableTasks}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          deleteOne={() => null}
          deleteMany={false}
          addNewRight={accessActionTaskAdd}
          addNew={() => {
            history.push(`/deliverable/${deliverableId}/task/add`);
          }}
          isChildGrid={true}
        />
      </div>
    </div>
  );
};

export default TaskListing;
