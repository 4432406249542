import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import MyBriefcase from "../app/views/myBriefcase";

const PROFILE = ["My Briefcase:VIEW", "My Briefcase:FULL"];

const MyBriefcaseRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/my-briefcase"
      component={MyBriefcase}
      pageHeading="GenNx CMS - My Briefcase"
      accessRights={{
        moduleName: "My Briefcase",
        parentModuleName: "",
        moduleAttribute: [...PROFILE],
        interfaceName: "Listing",
      }}
    />
  </Switch>
);

export default MyBriefcaseRoutes;
