/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  People Listing Page
	 - Use common grid component for list
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 21/Apr/2021 
	Author						: Sandeep K Sharma
================================================================ 
*/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getServiceDocuments,
  deleteSingleServiceDocument,
  fetchServiceDocUrl,
  getServiceVideos,
  getServiceSurvey,
  deleteSingleServiceVideo,
  addServiceSurvey,
  fetchThumbnailUrl,
} from "../../../../../middleware/services/servicesApi";
import { fetchSurveys } from "../../../../../middleware/services/surveyApi";
import { getProductVariant } from "../../../../../utilities";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../../../middleware/actions/utilityAction";
import DocumentModel from "./documentModel";
import VideoModel from "./videoModel";
import Accordion from "../../../../components/accordion";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { Dropdown } from "../formFields";
import { addServiceSurveyValidationSchema } from "../validationSchema";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "underscore";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import docImage from "../../../../../assets/images/news.jpg";
import AccessDenied from "../../../../components/accessDenied";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const initialState = {
  surveyTitle: "",
};
export default (props) => {
  const serviceId = props.serviceId;
  const dispatch = useDispatch();
  const productVariant = getProductVariant();
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [docuData, setDocuData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [stepCompleted, setStepCompleted] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [videoId, setVideoId] = useState("");
  const [surveyData, setSurveyData] = useState([]);
  const [surveyEditData, setSurveyEditData] = useState(null);

  /* Common access */
  const accessActionSaveSurvey = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SAVE_SURVEY);
  const accessActionAddDocument = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_DOCUMENT);
  const accessActionEditDocument = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_DOCUMENT);
  const accessActionDeleteDocument = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_DOCUMENT);
  const accessActionAddVideo = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_VIDEO);
  const accessActionEditVideo = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_VIDEO);
  const accessActionDeleteVideo = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_VIDEO);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(addServiceSurveyValidationSchema),
  });
  const closeDocumentModal = () => {
    setDocumentId("");
    setShowDocumentModal(false);
  };
  const closeVideoModal = () => {
    setVideoId("");
    setShowVideoModal(false);
  };
  useEffect(() => {
    if (serviceId !== "") {
      fetchDocumentData();
    }
  }, [showDocumentModal]);

  useEffect(() => {
    if (serviceId !== "") {
      fetchVideoData();
    }
  }, [showVideoModal]);
  useEffect(() => {
    fetchSurvey();
    if (serviceId !== "") {
      !surveyEditData && fetchServiceSurveyData();
    }
  }, [serviceId]);
  // function for getting Service Document Data
  const fetchDocumentData = async () => {
    dispatch(showLoader());
    try {
      let formatedRowData = [];
      const response = await getServiceDocuments(serviceId);
      if (response.status == 200) {
        dispatch(hideLoader());
        var documentData = response.data.data;
        let documents = documentData.serviceDeliverableDocuments;
        if (documents.length > 0) {
          setStepCompleted(true);
          documents.forEach((doc) => {
            let data = {};
            data["id"] = doc.id;
            data["docTitle"] = doc.docTitle;
            data["docName"] = doc.docName;
            data["docType"] = doc.docType;
            data["docFileName"] = doc.docFileName;
            data["docThumbnail"] = doc.docThumbnail;
            formatedRowData.push(data);
          });
        }
      }
      setDocuData(formatedRowData);
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  //  function for getting Service Videp Data
  const fetchVideoData = async () => {
    dispatch(showLoader());
    try {
      let formatedRowData = [];
      const response = await getServiceVideos(serviceId);
      if (response.status == 200) {
        dispatch(hideLoader());
        var documentData = response.data.data;
        let documents = documentData.serviceDeliverableVideos;
        if (documents.length > 0) {
          setStepCompleted(true);
          documents.forEach((doc) => {
            let data = {};
            data["id"] = doc.id;
            data["videoTitle"] = doc.videoTitle;
            data["videoType"] = doc.videoType;
            data["videoUrl"] = doc.videoUrl;
            data["videoThumbnail"] = doc.videoThumbnail;

            formatedRowData.push(data);
          });
        }
      }
      setVideoData(formatedRowData);
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  //  function for getting Service Survey Data
  const fetchServiceSurveyData = async () => {
    dispatch(showLoader());
    try {
      const response = await getServiceSurvey(serviceId);
      if (response.status == 200) {
        dispatch(hideLoader());
        var documentData = response.data.data;
        let survey = documentData && documentData.serviceDeliverableSurveys[0];
        if (survey) {
          setSurveyEditData(survey);
          setTimeout(() => {
            formik.setValues({
              surveyTitle: survey.surveyTitle,
            });
          }, 200);
        }
      }
    } catch (e) {
      dispatch(actionError("Something went wrong"));
      dispatch(hideLoader());
    }
  };
  // get Survey Data for selected Varient
  const fetchSurvey = () => {
    fetchSurveys({
      filters: [
        ["productVariant.productName", "eq", productVariant],
        ["surveyIsDeleted", "eq", "NO"],
        ["surveyIsDeleted", "eq", "NO"],
        ["surveyIsPublished", "eq", "YES"],
        ["surveyStatus", "eq", "Open"],
      ],
      fields: ["id", "surveyTitle"],
      sort: "surveyCreatedDate:asc",
      limit: 2000,
    })
      .then((res) => {
        const { data = [] } = res.data;
        const surveys = JSON.parse(JSON.stringify(data));
        let dropdownSurveys = {};
        surveys.forEach((sTT) => {
          dropdownSurveys[sTT.id] = { surveyId: sTT.id, surveyTitle: sTT.surveyTitle };
        });
        setSurveyData([...Object.values(dropdownSurveys)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Survey Data Failed";
        dispatch(actionError(errMsg));
      });
  };
  const saveServiceSurvey = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        const { surveyTitle } = formik.values;
        let selectedSurvey = _.where(surveyData, { surveyTitle: surveyTitle });
        addServiceSurvey(props.serviceId, { survey: selectedSurvey })
          .then((res) => {
            if (res) {
              dispatch(actionSuccess("Survey added successfully"));
            } else {
              dispatch(actionError("Something went wrong"));
            }
          })
          .catch((err) => {
            dispatch(actionError(err?.data?.message || "something went wrong"));
          });
      }
    });
  };

  const downloadFile = (docFileName, docName, docId) => {
    const responseObj = fetchServiceDocUrl(docFileName, docName);
    responseObj
      .then((response) => {
        let file = new File([response.data], docName, { type: response.data.type });
        var reader = new FileReader();
        reader.onload = (e) => {
          document.getElementById(docId).setAttribute("download", docName);
          document.getElementById(docId).setAttribute("href", e.target.result);
        };
        reader.readAsDataURL(file);
      })
      .catch((e) => {
        console.log(e);
        dispatch(actionError("There some issue in file attachement."));
      });
  };
  return (
    <>
      {props?.interfaceAccess ? (
        <div>
          {showDocumentModal && (
            <DocumentModel
              showDocumentModal={showDocumentModal}
              closeDocumentModal={closeDocumentModal}
              serviceId={serviceId}
              documentId={documentId}
              fetchDocumentData={fetchDocumentData}
            />
          )}
          {showVideoModal && (
            <VideoModel
              showVideoModal={showVideoModal}
              closeVideoModal={closeVideoModal}
              serviceId={serviceId}
              videoId={videoId}
              fetchVideoData={fetchVideoData}
            />
          )}
          <div className="pt-3 mx-2" style={{ width: "98.7%", "overflow-x": "hidden" }}>
            <Accordion heading="Documents" step={1} stepCompleted={docuData && docuData.length ? true : false}>
              <div className="row pt-4">
                {docuData.map(function (d, i) {
                  return (
                    <div className="col-md-4 pb-4" key={`doclist-${i}`}>
                      <div style={{ display: `flex`, flexDirection: `column` }}>
                        <div className="e-card e-card-horizontal" style={{ overflow: "unset" }}>
                          <div
                            className="col-md-2"
                            style={{
                              backgroundImage: `url(${d.docThumbnail ? fetchThumbnailUrl(d.docThumbnail) : docImage})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                            }}
                          ></div>
                          <div className="e-card-stacked">
                            <div className="e-card-header">
                              <div className="e-card-header-caption font-weight-bold">
                                {d.docTitle}
                                <div className="e-card-sub-title" style={{ position: "relative" }}>
                                  {d.docType}
                                </div>

                                <div style={{ cursor: "pointer", position: "absolute", right: "10px", color: "#bbbbbd" }}>
                                  <ThreeDotMenu
                                    Edit={
                                      accessActionEditDocument
                                        ? () => {
                                            setDocumentId(d.id);
                                            setShowDocumentModal(true);
                                          }
                                        : undefined
                                    }
                                    Delete={
                                      accessActionDeleteDocument
                                        ? () => {
                                            dispatch(
                                              showAlertBox({
                                                okCallback: async () => {
                                                  deleteSingleServiceDocument(serviceId, d.id)
                                                    .then((res) => {
                                                      if (res) {
                                                        dispatch(actionSuccess("Document deleted successfully"));
                                                        fetchDocumentData();
                                                      } else {
                                                        dispatch(actionError("Something went wrong"));
                                                      }
                                                    })
                                                    .catch((err) => {
                                                      dispatch(actionError(err?.data?.message || "Failed to delete video"));
                                                    });
                                                },
                                                content: "Are you sure, you want to delete?",
                                              })
                                            );
                                          }
                                        : undefined
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="e-card-content">
                              <a id={d.id} className="e-link" onClick={downloadFile(d.docFileName, d.docName, d.id)} href="#" download>
                                <i className="fas fa-download" data-tip="Download" /> {d.docName}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {accessActionAddDocument && (
                <div className="p-2 ml-2 border-0">
                  <a className="add-link" onClick={() => setShowDocumentModal(true)}>
                    {" "}
                    + Add Document
                  </a>
                </div>
              )}
            </Accordion>
            <Accordion heading="Videos" step={2} stepCompleted={videoData && videoData.length ? true : false}>
              <div className="row pt-4">
                {videoData.map(function (d, i) {
                  return (
                    <div className="col-md-4 pb-4" key={`doclist-${i}`}>
                      <div style={{ display: `flex`, flexDirection: `column` }}>
                        <div className="e-card e-card-horizontal" style={{ overflow: "unset" }}>
                          <div
                            className="col-md-2"
                            style={{
                              backgroundImage: `url(${d.videoThumbnail ? fetchThumbnailUrl(d.videoThumbnail) : docImage})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                            }}
                          ></div>

                          <div className="e-card-stacked">
                            <div className="e-card-header">
                              <div className="e-card-header-caption font-weight-bold">
                                {d.videoTitle}
                                <div className="e-card-sub-title" style={{ position: "relative" }}>
                                  {d.docType}
                                </div>

                                <div style={{ cursor: "pointer", position: "absolute", right: "10px", color: "#bbbbbd" }}>
                                  <ThreeDotMenu
                                    Edit={
                                      accessActionEditVideo
                                        ? () => {
                                            setVideoId(d.id);
                                            setShowVideoModal(true);
                                          }
                                        : undefined
                                    }
                                    Delete={
                                      accessActionDeleteVideo
                                        ? () => {
                                            dispatch(
                                              showAlertBox({
                                                okCallback: async () => {
                                                  deleteSingleServiceVideo(serviceId, d.id)
                                                    .then((res) => {
                                                      if (res) {
                                                        dispatch(actionSuccess("Video deleted successfully"));
                                                        fetchVideoData();
                                                      } else {
                                                        dispatch(actionError("Something went wrong"));
                                                      }
                                                    })
                                                    .catch((err) => {
                                                      dispatch(actionError(err?.data?.message || "Failed to delete video"));
                                                    });
                                                },
                                                content: "Are you sure, you want to delete?",
                                              })
                                            );
                                          }
                                        : undefined
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {accessActionAddVideo && (
                <div className="p-2 ml-2 border-0">
                  <a className="add-link" onClick={() => setShowVideoModal(true)}>
                    {" "}
                    + Add Video
                  </a>
                </div>
              )}
            </Accordion>
            <Accordion heading="Survey" step={3} stepCompleted={formik.values.surveyTitle ? true : false}>
              <div className="p-2 ml-2 border-0">
                <div className="row pt-1">
                  <div className="col-md-6">
                    <div className="form-group">
                      {surveyData && surveyData.length > 0 && (
                        <Dropdown
                          formik={formik}
                          sortOrder="Acending"
                          placeholder="Select Survey *"
                          id="surveyTitle"
                          name="surveyTitle"
                          value={formik.values.surveyTitle}
                          fields={{ text: "surveyTitle", value: "surveyTitle" }}
                          dataSource={surveyData}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {accessActionSaveSurvey && (
                  <button type="button" className="btn btn-primary cy-btn" style={{ marginLeft: "90em" }} onClick={() => saveServiceSurvey()}>
                    Save
                  </button>
                )}
              </div>
            </Accordion>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};
