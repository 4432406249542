/**
 * @author: Anand Kumar
 * @date : 04/Jan/2021
 * File Description: Business Objective APIs
 */
 import { OBJECTIVE_URL } from "../../config";
 import { OBJECTIVE_API as API } from "../../config/apiConfig";
 
 
 export const fetchObjective = (params = {}) => {
	 let { filters = undefined, fields = undefined } = params;
	 if (filters) params.filters = JSON.stringify(filters);
	 if (fields) params.fields = JSON.stringify(fields);
	 return API({
		 url: "/objective",
		 method: "GET",
		 params: params
	 });
 };
 
 // Fetch Single Objective
 export const fetchSingleObjective = (objectiveId, params = '') => {
	 let { filters = undefined, fields = undefined } = params;
	 if (filters) params.filters = JSON.stringify(filters);
	 if (fields) params.fields = JSON.stringify(fields);
	 return API({
		 url: `/objective/${objectiveId}`,
		 method: "GET",
		 params: params
	 });
 };
 
 
 export const saveObjective = (payload) => {
	 return API({
		 url: "/objective",
		 method: "POST",
		 data: payload
	 });
 };
 
 export const updateObjective = (objectiveId, payload) => {
	 return API({
		 url: `/objective/${objectiveId}`,
		 method: "PUT",
		 data: payload
	 })
 }
 
 export const fetchObjectiveTaxonomy = (objectiveId, params = {}) => {
	 let { filters = undefined, fields = undefined } = params;
	 if (filters) params.filters = JSON.stringify(filters);
	 if (fields) params.fields = JSON.stringify(fields);
	 return API({
		 url: `/objective/${objectiveId}/taxonomy`,
		 method: "GET",
		 params: params
	 });
 };
 
 export const saveTaxonomy = (objectiveId, payload) => {
	 return API({
		 url: `/objective/${objectiveId}/taxonomy`,
		 method: "POST",
		 data: payload
	 });
 };
 
 export const updateTaxonomy = (objectiveId, taxonomyId, payload) => {
	 return API({
		 url: `/objective/${objectiveId}/taxonomy/${taxonomyId}`,
		 method: "PUT",
		 data: payload
	 });
 };
 
 export const deleteTaxonomy = (objectiveId, taxonomyIds) => {
	 return API({
		 url: `/objective/${objectiveId}/taxonomy`,
		 method: "DELETE",
		 data: { taxonomyIds: [...taxonomyIds] }
	 });
 };
 
 export const publishObjective = (objectiveId, payload = {}) => {
	 return API({
		 url: `/objective/publish/${objectiveId}`,
		 method: "PUT",
		 data: payload
	 });
 }
 
 export const uploadObjectiveFiles = (payload = {}) => {
	 return API({
		 url: `/objective/upload-files`,
		 method: "POST",
		 data: payload
	 });
 }
 export const downloadObjectiveFiles = (fileName) => {
	 return API({
		 url: `/objective/files/${fileName}`,
		 method: "GET",
		 responseType : 'blob'
	 });
 }
 export const uploadObjectiveImage = (payload = {}) => {
	 return API({
		 url: `/objective/upload-image`,
		 method: "POST",
		 data: payload
	 });
 }
 
 
 export const fetchObjectiveImageUrl = (fileName) => {
	 return `${OBJECTIVE_URL}/objective/files/${fileName}`;
 };
 
 export const updateTaxonomiesObjective = (params = {}, payload) => {
	 let { filters = undefined, fields = undefined } = params;
	 if (filters) params.filters = JSON.stringify(filters);
	 if (fields) params.fields = JSON.stringify(fields);
	 return API({
		 url: `/taxonomy/objective`,
		 method: "PUT",
		 data: payload,
		 params: params
	 });
 }
 export const fetchBOTaxonomy =(params = {})=>{
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/objective/taxonomy`,
		method: "GET",
		params: params
	});
}

 
 