import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userSignin } from "../../../middleware/actions/authAction";
import logoFutureBridge from "../../../assets/images/logo.jpg";
import { getLandingPageRoute, getAuthorization, getLoggedIn } from "../../../utilities";
import { fetchCoreModules } from "../../../middleware/services/cmsApi";
import moment from "moment";
import { fetchTrendsList, fetchIndustriesList } from "../../../middleware/actions/searchAction";

const Login = () => {
  const history = useHistory();
  let userTokenDetails = getLoggedIn();
  if (userTokenDetails) {
    let beginningTime = moment(new Date());
    let endTime = moment(new Date(userTokenDetails.exp * 1000));
    if (beginningTime.isBefore(endTime)) {
      (async () => {
        const coreModules = await fetchCoreModules({ filters: [["moduleNavOrder", "ex", ""]], sort: "moduleNavOrder:asc", limit: 1000 });
        dispatch(fetchIndustriesList());
        dispatch(fetchTrendsList());
        history.push(getLandingPageRoute(getAuthorization(), coreModules.data.data) || "/company");
      })();
    }
  }
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const dispatch = useDispatch();

  /**
   * @description email & password validation
   */
  const validateEmailPassword = () => {
    const emailRegex = /[^@]+@[^\.]+\..+/g;
    let err = true;
    if (email === "") {
      errors.email = "Please enter email";
      err = false;
    } else if (!emailRegex.test(String(email).toLowerCase())) {
      errors.email = "Please enter valid email";
      err = false;
    } else errors.email = "";
    if (password === "") {
      errors.password = "Please enter password";
      err = false;
    } else errors.password = "";
    setErrors({ ...errors });
    return err;
  };

  /**
   * @description onKeyUp handler for email & password field
   * @param {Event} event
   */
  const handleLogin = (event) => {
    if (event.keyCode === 13) {
      let validation = validateEmailPassword();
      if (email !== "" && password !== "" && validation) dispatch(userSignin({ email: String(email).toLowerCase(), password }));
      // else dispatch(actionError("Incorrect email or password!"));
    }
  };
  return (
    <div className="container">
      <div className="justify-content-center row">
        <div className="col-md-8" style={{ marginTop: "15%" }}>
          <div className="card-group">
            <div className="card" style={{ maxWidth: "255px" }}>
              <div className="text-center">
                <img src={logoFutureBridge} height="326.8px" />
              </div>
            </div>
            <div className="card" style={{ maxWidth: "400px" }}>
              <div className="card-body m-4">
                <h4 className="pb-4">
                  Login - <b>GenNx CMS</b>
                </h4>
                <div className="input-group">
                  <input
                    name="email"
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    autoComplete="email"
                    onChange={(event) => {
                      setEmail(event.target.value);
                      errors.email = "";
                      setErrors({ ...errors });
                    }}
                    value={email}
                    onKeyUp={handleLogin}
                  />
                </div>
                <div className="text-danger form-input-alert" role="alert">
                  <small>{errors.email}</small>
                </div>
                <div className="input-group">
                  <input
                    name="password"
                    className="form-control"
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    onChange={(event) => {
                      setPassword(event.target.value);
                      errors.password = "";
                      setErrors({ ...errors });
                    }}
                    value={password}
                    onKeyUp={handleLogin}
                  />
                </div>
                <div className="text-danger form-input-alert" role="alert">
                  <small>{errors.password}</small>
                </div>
                <div className="row">
                  <div className="col-6">
                    <button
                      className="px-4 btn btn-primary cy-btn"
                      type="button"
                      onClick={() => {
                        let validation = validateEmailPassword();
                        if (email !== "" && password !== "" && validation) dispatch(userSignin({ email: String(email).toLowerCase(), password }));
                        // else dispatch(actionError("Incorrect email or password!"));
                      }}
                    >
                      Login
                    </button>
                  </div>
                  {/* <div className="text-right col-6">
										<Link to="/forgot-password" className="px-0 btn btn-link">Forgot password?</Link>
									</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
