// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : main axios method
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :28 Nov 2023
// Author:Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import axiosAPI from 'axios';
import {BASE_URL} from '../../config';

// Axios call to get authorized
const axios = axiosAPI.create({
  baseURL: BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

axios.interceptors.response.use((res) => res, responseHandler);

function responseHandler(err) {
  // console.log({ err });
  if (err?.response?.status === 401) {
    setTimeout(() => {
      if (window.location.pathname !== '/login') window.location.assign('/login');
    }, 3000);
    localStorage.clear();
    //   return Promise.reject(err);
  }
  // handleCatchErrors(err);
  return Promise.reject(err);
}

(function () {
  let authToken = localStorage.getItem('token');
  if (authToken !== null) {
    axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  }
})();

export default axios;
