/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase Sidebar Draggable Card
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/fontawesome-free-solid";

const style = {
  padding: "8px",
  borderBottom: "0.1px solid #dbdbdd",
  display: "flex",
  alignItems: "center",
  background: "white",
};

const DraggableSidebarCard = ({ id, itemCard, index, moveCard }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "PRESENTATION",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, "DropHover");
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "PRESENTATION",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveCard(item.index, index, "DragEnd");
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div style={{ background: isDragging ? "#FFB4BA" : "" }}>
      <div ref={preview} style={{ ...style, opacity }} data-handler-id={handlerId}>
        <div ref={ref} style={{ cursor: "move", display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon icon={faEllipsisV} width={20} height={20} color="#6C757D" />
          <FontAwesomeIcon icon={faEllipsisV} width={20} height={20} color="#6C757D" />
        </div>
        <span
          style={{
            paddingLeft: "10px",
            fontSize: "14px",
            fontWeight: "600",
            color: "#f94f5e",
            width: "35px",
            textAlign: "left",
          }}
        >
          {index < 9 ? "0" : ""}
          {index + 1}
        </span>
        <span
          style={{
            width: "95%",
            marginLeft: "10px",
            fontSize: "14px",
            wordBreak: "break-word",
            textAlign: "left",
            color: "#4B4B55",
          }}
        >
          {itemCard?.presentationTitle}
        </span>
      </div>
    </div>
  );
};

export default DraggableSidebarCard;
