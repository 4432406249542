/** External Dependencies */
import React, { useMemo, useState } from "react";
import Menu from "@scaleflex/ui/core/menu";
import PropTypes from "prop-types";
import { Crop as CropIcon } from "@scaleflex/icons/crop";

/** Internal Dependencies */
import { useStore } from "../../../hooks";
import ToolsBarItemButton from "../../ToolsBar/ToolsBarItemButton";
import { TOOLS_IDS } from "../../../utils/constants";
import { StyledToolsBarItemButtonLabel } from "../../ToolsBar/ToolsBar.styled";
import Custom from "@scaleflex/icons/custom";
import CropPresetItem from "./CropPresetItem";
import toPrecisedFloat from "app/components/react-filerobot-image-editor/utils/toPrecisedFloat";
import { StyledOpenMenuButton } from "./Resize.styled";

const ManualResize = ({ handleClick }) => {
  const { config, t } = useStore();
  const [anchorEl, setAnchorEl] = useState();
  const [ratioTitleKey, setRatioTitleKey] = useState();

  const selectToolAndShowPresets = (id, e) => {
    if(anchorEl) {
      setAnchorEl(null)
    }else{
      setAnchorEl(e.currentTarget);
    }
  };

  const closeCropPresets = () => {
    setAnchorEl(null);
  };
    const resizeConfig = config[TOOLS_IDS.RESIZE];
  const { imageContainer } = config;

  const allPresets = useMemo(() => {
    const { presetsItems = [] } = resizeConfig;
    return [...presetsItems];
  }, [resizeConfig]);

  const changeCropRatio = (e, cropProps) => {
    e.stopPropagation();
    handleClick(cropProps.width, cropProps.height);
    setRatioTitleKey(cropProps.ratioTitleKey);
    closeCropPresets();
  };

  const renderPreset = ({ titleKey, descriptionKey, ratio, width, height, icon: Icon, customCropSize = false }) => {
    return (
      <CropPresetItem
        key={ratio}
        ratio={ratio ?? toPrecisedFloat(width / height)}
        titleKey={`${titleKey} ${width === imageContainer?.width && height === imageContainer.height ? "- Recommended" : ""}`}
        t={t}
        description={t(descriptionKey)}
        Icon={Icon || Custom}
        // isActive={currentRatio === (ratio ?? toPrecisedFloat(width / height)) && !ratioFolderKey}
        width={width}
        height={height}
        onClick={changeCropRatio}
        customCropSize={customCropSize}
        // disableManualResize={disableManualResize}
      />
    );
  };
    const toolTitleKey = ratioTitleKey;
  return (
    <ToolsBarItemButton className="FIE_resize-tool" id={"mnualResize"} Icon={CropIcon} onClick={selectToolAndShowPresets} isSelected={true}>
      <StyledToolsBarItemButtonLabel className="FIE_resize-tool-label FIE_selected-resize-preset-label">
        {t(toolTitleKey) || "Select Size"}
      </StyledToolsBarItemButtonLabel>
      <StyledOpenMenuButton className="FIE_crop-presets-opener-button" color="link" size="lg">
        {/* BOTTOM ARROW HTML CODE : TOP ARROW HTML CODE */}
        {anchorEl ? <>&#9652;</> : <>&#9662;</>}
      </StyledOpenMenuButton>
      <Menu
        className="FIE_crop-presets-menu"
        anchorEl={anchorEl}
        enableOverlay={false}
        onClose={closeCropPresets}
        open={Boolean(anchorEl)}
        position="top"
        style={{ maxHeight: "200px", overflow: "auto" }}
      >
        {allPresets.map((record) =>
          (record.width <= imageContainer.width && record.height <= imageContainer.height) || record?.ratio ? renderPreset(record) : null
        )}
      </Menu>
    </ToolsBarItemButton>
  );
};

ManualResize.defaultProps = {
  isSelected: false,
};

ManualResize.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default ManualResize;
