export const leftPaneHtml = `
<div>
<p class=" " style="font-Family:montserrat,sans-serif;font-size:16px;font-weight:bold;color:#044972">“Our Organisations & Institutes are set-up to withstand the change from outside, rather than embrace them when the changes are useful.”</p>
Technology is disrupting traditional approaches in every aspect of a business. Companies focused on Linear Growth are being overturned by start-ups & innovators looking at Exponential Growth. Join us, as we aim to equip the “Workforce of The Future” with a unique experience of working for an exponential organisation that is striving to change the status quo. We aim to transform the current internship structure by providing internship opportunity to all students through-out the year - by empowering them, with the help of the right technology and training. ​ About Us WhatNext identifies, analyses, and connects digital exponential technologies and their ecosystems with enterprises to reimagine industries.
</div>
</br>
<div>
<p class=" " style="font-Family:montserrat,sans-serif;font-size:16px;font-weight:bold;color:#044972">Join us, as we aim to equip the “Workforce of The Future” with a unique experience of working for an exponential organisation that is striving to change the status quo.</p>
We aim to transform the current internship structure by providing internship opportunity to all students through-out the year - by empowering them, with the help of the right technology and training. 
</div>
</br>
<div>
<p class=" " style="font-Family:montserrat,sans-serif;font-size:16px;font-weight:bold;color:#044972"><span style="text-decoration:underline">About Us</span></p>
WhatNext identifies, analyses, and connects digital exponential technologies and their ecosystems with enterprises to reimagine industries.
</div>
`


export const leftPaneHtmlInsider = `
<div>
<p class=" " style="font-Family:montserrat,sans-serif;font-size:16px;font-weight:bold">“Our Organisations & Institutes are set-up to withstand the change from outside, rather than embrace them when the changes are useful.”</p>
Technology is disrupting traditional approaches in every aspect of a business. Companies focused on Linear Growth are being overturned by start-ups & innovators looking at Exponential Growth. Join us, as we aim to equip the “Workforce of The Future” with a unique experience of working for an exponential organisation that is striving to change the status quo. We aim to transform the current internship structure by providing internship opportunity to all students through-out the year - by empowering them, with the help of the right technology and training. ​ About Us WhatNext identifies, analyses, and connects digital exponential technologies and their ecosystems with enterprises to reimagine industries.
</div>

<div>
<p class=" " style="font-Family:"montserrat,sans-serif">Join us, as we aim to equip the “Workforce of The Future” with a unique experience of working for an exponential organisation that is striving to change the status quo.</p>
We aim to transform the current internship structure by providing internship opportunity to all students through-out the year - by empowering them, with the help of the right technology and training. 
</div>

<div>
<p class=" " style="font-Family:"montserrat,sans-serif"><span style="text-decoration:underline">About Us</span></p>
WhatNext identifies, analyses, and connects digital exponential technologies and their ecosystems with enterprises to reimagine industries.
</div>
`