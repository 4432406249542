/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Modal Cancel and Save Button
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React from "react";
import classes from "./ModalCancelSaveButtons.module.css";

const ModalCancelSaveButtons = (props) => {
  return (
    <div className={`${classes.button_group} ${props.saveCancelOuterDivClass}`}>
      <button onClick={props.cancelButtonClick} className={classes.bg_trans} type="button">
        {props.cancelButtonText ? props.cancelButtonText : "Cancel"}
      </button>
      <button type="submit" onClick={props.saveButtonClick} disabled={props.saveDisabled} onSubmit={props.saveButtonClick}>
        {props.saveButtonText}
      </button>
    </div>
  );
};

export default ModalCancelSaveButtons;
