/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase Folder Items
   Summary : My Briefcase Folder Items
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				    : Satya Prakash Mall
================================================================ 
*/
import React, { Fragment, useEffect, useRef } from "react";
import classes from "./myBriefcase.module.css";

function Items({
  folderList,
  categoryId,
  updateCategoryId,
  setSelectedDoc,
  setBriefcaseFilterValue,
  setBriefcaseSortValue,
  setBriefcaseCardsValue,
  renameDeleteId,
  showMenu,
  menuClickHandler,
  handleRenameFolderShow,
  handleDeleteShow,
  setShowMenu,
}) {
  const wrapperRef = useRef(null);

  // function to close the menu modal on clicking outside
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  return (
    <Fragment>
      {folderList &&
        folderList?.length !== 0 &&
        folderList?.map((item) => (
          <div id={`list${item.id}`} className={classes.FilterList} key={item.id}>
            <div className={`${classes.bookMarkListBox} ${item.id === categoryId ? classes.newListActive : ""}`}>
              <div className={classes.titleIconDiv}>
                <p>
                  <img src="/assets/folder.svg" alt="folder"></img>
                </p>
                <div className={classes.bookMarkListContent}>
                  <p
                    onClick={() => {
                      updateCategoryId(item.id);
                      setSelectedDoc([]);
                      setBriefcaseFilterValue("All");
                      setBriefcaseSortValue("SortBySequence");
                      setBriefcaseCardsValue([]);
                    }}
                    className={`${classes.bookmarkTitle} ${item.id === categoryId ? classes.clickActive : ""}`}
                  >
                    {item.categoryName}
                  </p>
                  <p className={classes.bookMarkView}>Files ({item.presentationCount})</p>
                </div>
              </div>
              <span className={classes.deleteRename}>
                <img
                  src={`${item.id === renameDeleteId && showMenu ? "/assets/active-dots-menu.svg" : "/assets/dots-menu.svg"}`}
                  alt="dots"
                  onClick={() => menuClickHandler(item.id)}
                ></img>
                {showMenu && renameDeleteId === item.id && (
                  <div className={classes.select_list_content} ref={wrapperRef}>
                    <div className={classes.select_btn}>
                      <p onClick={handleRenameFolderShow}>Rename</p>
                    </div>
                    <div className={classes.divider}></div>
                    <div className={classes.select_btn}>
                      <p onClick={handleDeleteShow}>Delete </p>
                    </div>
                  </div>
                )}
              </span>
            </div>
          </div>
        ))}
    </Fragment>
  );
}

export default Items;
