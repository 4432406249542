/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Display Form created in Services
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 09/JUL/2021
    Author						: Sandeep Sharma
================================================================ 
*/

import React, { useState, useEffect } from "react";
import * as Survey from "survey-react";
import "survey-creator/survey-creator.css";
import "./survey.css";
import { fetchSingleService, updateService } from "../../../../../middleware/services/servicesApi";
import { actionError, actionSuccess } from "../../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import AccessDenied from "../../../../components/accessDenied";
// theme colors
const mainColor = "#343a40";
const mainHoverColor = "#000000"; // black
const textColor = "#4a4a4a";
const headerColor = "#343a40";

const ServiceForm = (props) => {
  const serviceId = props.serviceId;
  const [formJson, setFormJson] = useState([]);
  const [formData, setFormData] = useState({});
  const [startupName, setStartupName] = useState(null);
  const [formId, setFormId] = useState(undefined);
  const [serviceType, setServiceType] = useState(undefined);
  const dispatch = useDispatch();
  let defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
  defaultThemeColorsSurvey["$main-color"] = mainColor;
  defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
  defaultThemeColorsSurvey["$text-color"] = textColor;
  defaultThemeColorsSurvey["$header-color"] = headerColor;
  Survey.StylesManager.applyTheme("default");

  /*
      @Description : Save Services Form
  */
  useEffect(() => {
    if (props.saveForm) {
      if (document.getElementsByClassName("sv_complete_btn")[0]) {
        document.getElementsByClassName("sv_complete_btn")[0].click();
      } else {
        document.getElementsByClassName("sv_preview_btn")[0].click();
      }
      props.handleSaveForm(false);
    }
  }, [props.saveForm]);

  const initSurvey = () => {
    const form = fetchSingleService(serviceId);
    form.then((res) => {
      if (res.data.data.serviceType) {
        setServiceType(res.data.data.serviceType);
      }
      if (res.data.data.serviceRequestDetails?.companyName) {
        setStartupName(res.data.data.serviceRequestDetails?.companyName);
      }
      if (res.data.data.serviceForm?.form_json) {
        if (res.data.data.serviceForm?.form_json) {
          let formJsonData = res.data.data.serviceForm?.form_json;
          formJsonData.pages[0].elements.forEach((ele) => {
            if (ele.name == "description") {
              ele.validators = [
                {
                  type: "text",
                  minLength: 50,
                  text: "Please enter minimum 50 characters",
                },
              ];
            }
          });
          setFormJson(formJsonData);
          setFormId(res.data.data.serviceForm.id);
        }
        if (res.data.data.serviceForm?.form_json_submitted) {
          setFormData(res.data.data.serviceForm?.form_json_submitted);
        }
        if (document.getElementsByClassName("sv_complete_btn")[0]) document.getElementsByClassName("sv_complete_btn")[0].style.display = "none";
      }
    });
    form.catch((e) => {
      console.error(e);
    });
  };

  const getFormData = (data) => {
    data.clear(false, true);
    let payload = { serviceForm: { formId: formId, formJsonSubmitted: data.valuesHash } };
    updateService(props.serviceId, payload)
      .then((res) => {
        dispatch(actionSuccess("Customer Service details updated successfully"));
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
  };

  useEffect(() => {
    initSurvey();
  }, []);

  return (
    <>
      {props?.interfaceAccess ? (
        <div className="pt-3 mx-2 footer-button-wrapper padding-bottom-100i" style={{ width: "98.7%" }}>
          <div className="mx-2">
            <div className="mx-2">
              {serviceType === "Startup Connect Request" && startupName ? <h5>Startup Name: {startupName}</h5> : null}
              <form>
                <div className="row">
                  <Survey.Survey json={formJson} showQuestionNumbers={"off"} data={formData} showCompletedPage={false} onComplete={getFormData} />
                </div>
              </form>

              <div className="row"></div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default ServiceForm;
