/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company State
----------------------------------------------------------------
	Creation Details
	Date Created				: 12/DEC/2022
	Author						: RONAK JAIN
================================================================
*/

const BUSINESS_OBJECTIVE_STATE = {	
	businessObjective: {},
};

export default (state = BUSINESS_OBJECTIVE_STATE, action) => {
	switch (action.type) {
		// Maintain business objective details 
		case 'SET_BUSINESS_OBJECTIVE_REDUX': {
			return { ...state, businessObjective: action.payload }
		}

		default:
			return state;
	}
};
