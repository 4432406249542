/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company investment  info UI for  Listing, edit, add and delete and API wirirng up 
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 04/Aug/2020 
	Author						: Anand Kumar
--------------------------------------------------------------------------------- 
	Change History #1 
	Date Modified : 20/Oct/2021
	Changed By : Sai
	Change Description : imported customformelements from syncFusionFormFields
	Ticket Ref Number  : #6050
	Reason for Change  : changing components to syncfusion react
	Updated Code       : Searchcompany Function
================================================================ 
*/
import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { Modal } from "react-bootstrap";
import Accordion from "../../../../components/accordion";
import {
  fetchInvestment,
  fetchSingleCompany,
  deleteInvestment,
  saveInvestment,
  editInvestment,
  fetchCompany,
} from "../../../../../middleware/services/companyApi";
import fundingTypeOptions from "../../constants";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { investmentValidationSchema } from "../../../../helper/validationSchema/companyValidationSchema";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { getProductVariant } from "../../../../../utilities";
import { TextField, Dropdown, DatePicker, AutoCompleteDropdown } from "../../../../components/CustomFormElements/syncFusionFormFields";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const initialState = {
  fundingName: "",
  fundingAmount: "",
  fundingTypeRound: "",
  companyInvestment: null,
  fundingDate: "",
  fundingComment: "",
};
const Investments = (props) => {
  /* Common access */
  const accessPermissionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_INVESTMENTS);
  const accessPermissionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_INVESTMENTS);
  const accessPermissionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_INVESTMENTS);

  const companyId = props.companyId;
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [date, setDate] = useState("");
  const dispatch = useDispatch();
  const [companyInvestment, setCompanyInvestment] = useState([]);

  const getCompanyName = async () => {
    let fields = { fields: ["id", "companyName"] };
    const response = await fetchSingleCompany(companyId, { ...fields });
    setCompanyName(response.data?.data?.companyName);
  };

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    validationSchema: Yup.object().shape(investmentValidationSchema),
  });

  useEffect(() => {
    handleFetchInvestments();
    getCompanyName();
  }, []);

  const handleFetchInvestments = async () => {
    const response = await fetchInvestment(companyId);
    if (response && response.data.data) {
      let data = response.data.data.map((item) => {
        return {
          ...item,
          fundingDate: item.fundingDate ? new Date(item.fundingDate) : "",
          fundingCreatedDate: item.fundingCreatedDate ? new Date(item.fundingCreatedDate) : "",
        };
      });
      setInvestments(data);
    }
    setLoading(false);
  };
  // delete a funding
  const handleDeleteInvestment = async (investmentId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await deleteInvestment(investmentId);
          dispatch(actionSuccess("Investment deleted successfully"));
          await handleFetchInvestments();
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to Delete ?",
        title: "dialogAlertCssWarning",
      })
    );
  };
  const handleEditInvestment = async () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = formik.values;
        payload.fundingAmount = payload.fundingAmount == "NA" ? payload.fundingAmount : +payload.fundingAmount;
        payload.fundingInvestors = [{ companyId, companyName }];
        let fundingOnCompanyId;
        if (payload.companyInvestment !== companyInvestment.label) {
          fundingOnCompanyId = [
            {
              companyName: payload.companyInvestment,
            },
          ];
        } else {
          fundingOnCompanyId = [
            {
              companyName: companyInvestment.label,
              companyId: companyInvestment.value,
            },
          ];
        }
        payload.fundingOnCompanyId = fundingOnCompanyId;
        delete payload.companyInvestment;
        // temporary fix
        payload.fundingId = Math.random().toString(36).substring(2, 15);
        const investmentId = payload.id;
        delete new Date(payload.fundingCreatedDate);
        delete new Date(payload.fundingUpdatedDate);
        delete payload.id;
        editInvestment(investmentId, payload)
          .then((response) => {
            setShowModal(!showModal);
            // formik.handleReset();
            dispatch(actionSuccess("Investment updated successfully"));
            handleFetchInvestments();
            setEdit(false);
          })
          .catch((err) => {
            console.log(err.data);
            dispatch(actionError(err.data.message || "Someting went wrong"));
          });
      }
    });
  };

  // save investment
  const handleSaveInvestment = async () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = formik.values;
        payload.fundingAmount = payload.fundingAmount == "NA" ? payload.fundingAmount : +payload.fundingAmount;
        payload.fundingInvestors = [{ companyId, companyName }];
        let fundingOnCompanyId;
        if (payload.companyInvestment !== companyInvestment.label) {
          fundingOnCompanyId = [
            {
              companyName: payload.companyInvestment,
            },
          ];
        } else {
          fundingOnCompanyId = [
            {
              companyName: companyInvestment.label,
              companyId: companyInvestment.value,
            },
          ];
        }
        payload.fundingOnCompanyId = fundingOnCompanyId;
        delete payload.companyInvestment;
        // temporary fix
        payload.fundingId = Math.random().toString(36).substring(2, 15);
        payload.fundingDate = moment(payload.fundingDate).format("DD-MMM-yyyy");
        saveInvestment(payload)
          .then((response) => {
            setShowModal(!showModal);
            dispatch(actionSuccess("Investment added Successfully"));
            handleFetchInvestments();
          })
          .catch((err) => {
            dispatch(actionError(err.data.message || "Someting went wrong"));
          });
      }
    });
  };

  const searchInvestors = useCallback(
    debounce(async (e) => {
      if (e.text.length > 2) {
        let filters = [];
        const productVariant = getProductVariant();
        if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
        filters.push(["companyName", "cn", e.text]);
        let fields = { fields: ["companyId", "companyName"] };
        const response = await fetchCompany({ filters: filters, ...fields });
        if (response.data && response.data.data) {
          const resData = response.data.data.map((v) => {
            return { label: v.companyName, value: v.id };
          });
          if (resData) {
            e.updateData(resData);
          } else {
            e.updateData([]);
          }
        }
      }
    }, 300),
    []
  );

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <>
      <Accordion heading="Investments" stepCompleted={investments && investments.length > 0 ? true : false} step={5}>
        <div className="pt-2">
          <table className="normal-table table table-bordered">
            <thead className="internal-grid">
              <tr>
                <th>Transaction Name</th>
                <th className="text-center">Date</th>
                <th>Funding Type</th>
                <th className="text-right" style={{ width: window.innerWidth < 1400 ? "20%" : "200px" }}>
                  Amount (Million USD)
                </th>
                <th>Company</th>
                <th>Investors</th>
                <th className="text-center internal-grid-action"> Action</th>
              </tr>
            </thead>
            <tbody>
              {investments.length
                ? investments.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.fundingName || "--"}</td>
                        <td className="text-center">{item.fundingDate ? moment(item.fundingDate).format("DD MMM yyyy") : "--"}</td>
                        <td>{item.fundingTypeRound || "--"}</td>
                        <td className="text-right">{item.fundingAmount || "--"}</td>
                        <td>{item.fundingOnCompanyId.map((fundingcCompany) => fundingcCompany.companyName).toString() || "--"}</td>
                        <td>{item.fundingInvestors.map((companyInvestor) => companyInvestor.companyName).toString() || "--"}</td>
                        <td className="text-center">
                          {accessPermissionEdit || accessPermissionDelete ? (
                            <ThreeDotMenu
                              rowID={item.id}
                              Edit={
                                accessPermissionEdit
                                  ? () => {
                                      setEdit(true);
                                      let investorsEdit = item.fundingOnCompanyId.map((fundingCompany) => {
                                        return fundingCompany.companyName;
                                      })[0];
                                      let updateValues = { ...item, companyInvestment: investorsEdit };
                                      setDate(item.fundingDate);
                                      formik.setValues(updateValues);
                                      setShowModal(!showModal);
                                    }
                                  : undefined
                              }
                              Delete={
                                accessPermissionDelete
                                  ? () => {
                                      handleDeleteInvestment(item.id);
                                    }
                                  : undefined
                              }
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        <div className="text-right">
          {accessPermissionAdd ? (
            <button
              type="button"
              className="btn btn-dark cy-btn"
              onClick={() => {
                setEdit(false);
                setDate("");
                setShowModal(!showModal);
              }}
            >
              Add New
            </button>
          ) : null}
        </div>
      </Accordion>
      <Modal show={showModal} backdrop="static" className="modal fade md" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-header bg-none text-dark">
          <h5 className="modal-title" id="exampleModalLabel">
            {isEdit ? "Edit Company Investment" : "Add Company Investment"}
          </h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              setShowModal(!showModal);
              formik.handleReset();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group required">
                <TextField
                  formik={formik}
                  type="text"
                  className="form-control"
                  id="fundingName"
                  placeholder="Enter transaction name *"
                  name="fundingName"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group required">
                <DatePicker
                  formik={formik}
                  properties={{
                    placeholder: "Enter transaction date *",
                    id: `fundingDate`,
                    name: `fundingDate`,
                    format: "dd MMM yyyy",
                    floatLabelType: "Auto",
                    allowEdit: false,
                    openOnFocus: true,
                    // max: new Date()
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group required">
                <TextField
                  formik={formik}
                  type="text"
                  className="form-control"
                  id="fundingAmount"
                  placeholder="Enter transaction amount (NA if amount not available) *"
                  name="fundingAmount"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group required">
                <Dropdown
                  formik={formik}
                  sortOrder="Acending"
                  placeholder="Select funding type *"
                  id="fundingTypeRound"
                  name="fundingTypeRound"
                  fields={{ text: "label", value: "value" }}
                  dataSource={fundingTypeOptions}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group required">
                {/* <label htmlFor="companyInvestment">Company</label> */}
                {/* <CompanyInvestors
									formik={formik}
									id="companyInvestment"
									name="companyInvestment"
									placeholder="Type min 3 chars to search company"
									minMenuHeight={100}
									maxMenuHeight={150}
									menuPlacement="auto"
									isSearchable
									isClearable
								/> */}
                <AutoCompleteDropdown
                  formik={formik}
                  id="companyInvestment"
                  name="companyInvestment"
                  placeholder="Company *"
                  fields={{ value: "label", id: "value" }}
                  filtering={searchInvestors}
                  // value={formik.values.companyInvestment}
                  select={(data) => {
                    data && data?.itemData && setCompanyInvestment(data?.itemData);
                    // data && data?.itemData && formik.setFieldValue('companyInvestment', data?.itemData)
                  }}
                  allowFiltering={true}
                  // dataSource={company}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <TextField
                  formik={formik}
                  type="text"
                  rows={3}
                  name="fundingComment"
                  className="form-control"
                  id="fundingComment"
                  placeholder="Enter funding comment"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-dark cy-btn mr-3"
            onClick={() => {
              setShowModal(!showModal);
              formik.handleReset();
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary cy-btn" onClick={isEdit ? handleEditInvestment : handleSaveInvestment}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Investments;
