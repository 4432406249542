import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userSignout } from "../../../middleware/actions/authAction";
import logoFutureBridge from "../../../assets/images/logo.jpg";
import { getLoggedInUser, getLandingPageRoute, getAuthorization, getLoggedIn } from "../../../utilities";
import { fetchCoreModules, fetchTeam } from "../../../middleware/services/cmsApi";
import moment from "moment";
import { resetMaintainedState, resetInternalGrid } from "../../../middleware/actions/gridAction";
import { resetRedux } from "../../../middleware/actions/taxonomyFilterAction";
import { fetchTrendsList, fetchIndustriesList } from "../../../middleware/actions/searchAction";

const ProductVariant = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userTokenDetails = getLoggedIn();
  const userDetails = getLoggedInUser();
  if (userTokenDetails) {
    let beginningTime = moment(new Date());
    let endTime = moment(new Date(userTokenDetails.exp * 1000));
    if (!beginningTime.isBefore(endTime)) dispatch(userSignout({}));
  } else dispatch(userSignout({}));

  const clearLocalGrid = () => {
    const arr = []; // Array to hold the keys
    // Iterate over localStorage and insert the keys that meet the condition into arr
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 8) == "gridgrid") {
        arr.push(localStorage.key(i));
      }
    }
    for (let i of arr) {
      localStorage.removeItem(i);
    }
  };
  const setProductVariant = async (productName) => {
    clearLocalGrid();
    dispatch(resetMaintainedState());
    dispatch(resetInternalGrid());
    localStorage.setItem("productVariant", productName);
    const coreModules = await fetchCoreModules({ filters: [["moduleNavOrder", "ex", ""]], sort: "moduleNavOrder:asc", limit: 1000 });
    dispatch(fetchIndustriesList());
    dispatch(fetchTrendsList());
    history.push(getLandingPageRoute(getAuthorization(), coreModules.data.data) || "/company");
  };

  if (userDetails?.product_variant?.length === 1) setProductVariant(userDetails?.product_variant[0].product_name);

  const [productVariants, setProductVariants] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await fetchTeam({ sort: "teamName:asc" });
      const data = res?.data?.data || [];
      let userProductVariant = [];
      if (userDetails?.product_variant?.length > 0) {
        userDetails.product_variant.forEach((pv) => {
          userProductVariant.push({ teamName: pv.product_name });
        });
      }
      if (userDetails?.product_variant?.length > 0) setProductVariants(userProductVariant || []);
      else if (data?.length > 0) setProductVariants([...data]);
    })();
  }, []);

  // Reset Redux for filter by taxonomy
  useEffect(() => {
    if (window.location.pathname === `/selector`) {
      dispatch(resetRedux());
    }
  }, []);

  return (
    <div className="container">
      <div className="justify-content-center row">
        <div className="col-md-8" style={{ marginTop: "15%" }}>
          <div className="card-group">
            <div className="card" style={{ maxWidth: "255px" }}>
              <div className="text-center">
                <img src={logoFutureBridge} />
              </div>
            </div>
            <div className="card" style={{ maxWidth: "400px" }}>
              <div className="card-body m-4 d-flex flex-column">
                {productVariants.length
                  ? productVariants.map((pV, i) => {
                      return (
                        <button key={i} className="px-4 btn btn-primary mb-3" type="button" onClick={() => setProductVariant(pV.teamName)}>
                          {pV.teamName}
                        </button>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductVariant;
