/*
================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Content Block remark disposition list Component
----------------------------------------------------------------
    Creation Details
    Date Created				: 02/May/2022
    Author						: Suraj Yadav
================================================================
*/
import { saveRemark, updateRemark } from "middleware/services/contentBlockApi";
import React, { ReactEventHandler, useEffect, useState } from "react";
import { getLoggedInUser } from "../../../utilities";
import { useDispatch } from "react-redux";
import { actionSuccess, actionError } from "../../../middleware/actions/utilityAction";

const ActionButton = (props) => {
  const dispatch = useDispatch();

  let loggedInUser = getLoggedInUser();

  /*
    Description : Handle cancel disposition
  */
  const closeDispositionSection = () => {
    props.setShowPageViewer && props.setShowPageViewer(true);
    props.setShowRemarkSection ? props.setShowRemarkSection(false) : props.setViewDispositionSection(false);
    props.setSelectedDisposition(props.dispositionList);
    props.formik.setValues({ ...props.formik.initialValues });
    props.setSelectedDispositions([]);
    props.setCheckedBox([]);
    props.setViewDispositionSection(false);
    props.setShowComment && props.setShowComment(false);
    props.setHideAccordion && props.setHideAccordion(true);
    props.setHeight && props.setHeight("72.4vh");
  };

  /*
        Description : Handle save remark
    */
  const saveDisposition = () => {
    props.formik.validateForm().then(async (res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        const err =
          res &&
          Object.values(res).map((e) => {
            return e;
          });
        props.formik.setFormikState({
          ...props.formik,
          touched: touched,
          errors: res,
        });
      } else {
        if (props.selectedDispositions.length > 0 && props.remarkComment !== "") {
          if (props.remarkId === "") {
            let remarkData = {
              remarkComment: props.remarkComment,
              remarkByUserId: loggedInUser._id,
              remarkDispositions: props.selectedDispositions,
              remarkEntityType: props.remarkEntityType,
              // remarkReportTitle: props.pageTitle,
              remarkReportId: props.remarkReportParentId,
            };

            saveRemark(props.remarkEntityId, remarkData);
            dispatch(actionSuccess("Remark added successfully"));
            props.setShowPageViewer && props.setShowPageViewer(true);

            props.loadRemarks(props.remarkEntityId);
          } else {
            let remarkData = {
              remarkComment: props.remarkComment,
              remarkDispositions: props.selectedDispositions,
              remarkRepotId: props.remarkRepotId,
              remarkByUserId: loggedInUser._id,
            };
            updateRemark(props.remarkId, remarkData);
            dispatch(actionSuccess("Remark updated successfully"));
            props.setShowPageViewer && props.setShowPageViewer(true);

            props.loadRemarks(props.remarkEntityId);
          }

          props.loadRemarksForReport && (await props.loadRemarksForReport(props.remarkEntityId));
          props.loadAllRemarks && props.loadAllRemarks(props.remarkEntityId);
          props.loadRemarks(props.remarkEntityId);
          //   setDisposition(props.dispositionList);
          props.setSelectedDisposition(props.dispositionList);
          props.formik.setValues({ ...props.formik.initialValues });
          props.setSelectedDispositions([]);
          props.setCheckedBox([]);
          //   setViewDisposition(false);
          props.setShowComment && props.setShowComment(false);
          props.setBlockTitle && props.setBlockTitle("");
          props.setPageTitle && props.setPageTitle("");
          props.setHeight && props.setHeight("72.4vh");
          props.setRemarkReportChildId && props.setRemarkReportChildId("");
          props.setRemarkReportParentId && props.setRemarkReportParentId("");
          props.setHideAccordion && props.setHideAccordion(true);
          props.setShowRemarkSection ? props.setShowRemarkSection(false) : props.setViewDispositionSection(false);
        } else {
          if (props.remarkComment === "") {
            dispatch(actionError("Comment is empty"));
          } else {
            dispatch(actionError("Disposition is empty"));
          }
        }
      }
    });
  };
  return (
    <>
      <div id="action_buttons" className="float-right col-md-12 text-right btn-position">
        <button className="btn btn-outline-dark cb-btn" onClick={closeDispositionSection}>
          Cancel
        </button>
        <button className="btn btn-primary ml-3 cb-btn" onClick={saveDisposition}>
          Submit
        </button>
      </div>
    </>
  );
};
export default ActionButton;
