/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 15/Mar/2021
 * File Description: Survey Routes
 */
import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import SurveyListing from "../app/views/survey/index";
import BaseComponent from "../app/views/survey/component/nav";
import SurveyRender from "../app/views/survey/component/render";
import DefaultLayout from "../app/layout/defaultLayout";

const SURVEY = ["Survey:FULL"];
const BASIC = ["Basic Details:VIEW", "Basic Details:FULL"];
const DESIGN = ["Design Survey:VIEW", "Design Survey:FULL"];
const AUDIENCE = ["Survey Audience:VIEW", "Survey Audience:FULL"];
const INVITATION = ["Survey Invitation:VIEW", "Survey Invitation:FULL"];
const ACKNOWLEDGEMENT = ["Survey Acknowledgment:VIEW", "Survey Acknowledgment:FULL"];

const SurveyRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/survey"
      component={SurveyListing}
      pageHeading="GenNx CMS - Survey"
      accessRights={{
        moduleName: "Survey",
        parentModuleName: "Survey",
        moduleAttribute: [...SURVEY, ...BASIC, ...DESIGN, ...AUDIENCE, ...INVITATION, ...ACKNOWLEDGEMENT],
        interfaceName: "Listing",
      }}
    />
    <AuthLayout
      exact
      path={"/survey/:surveyId/basic-details"}
      component={BaseComponent}
      pageHeading="GenNx CMS - Survey"
      accessRights={{
        moduleName: "Survey",
        parentModuleName: "Survey",
        moduleAttribute: [...SURVEY, ...BASIC],
        interfaceName: "Basic",
      }}
    />

    <AuthLayout
      exact
      path="/survey/basic-details"
      component={BaseComponent}
      pageHeading="GenNx CMS - Survey"
      accessRights={{
        moduleName: "Survey",
        parentModuleName: "Survey",
        moduleAttribute: [...SURVEY, ...BASIC],
        interfaceName: "Basic",
      }}
    />
    <DefaultLayout
      exact
      path={["/survey-render/:surveyShortName/:surveyAudienceCode", "/survey-render/:surveyShortName"]}
      component={SurveyRender}
      pageHeading="Survey"
    />
  </Switch>
);

export default SurveyRoutes;
