/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Auth State
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import {
	USER_SIGNUP, USER_SIGNIN, USER_SIGNOUT, USER_TOKEN_SET,
} from "../../constants/actionConstants";

const AUTH_STATE = {
	token: null,
	authUser: null,
};

export default (state = AUTH_STATE, action) => {
	switch (action.type) {
		case USER_SIGNUP: {
			return { ...state, token: null, authUser: null };
		}
		case USER_SIGNIN: {
			return { ...state };
		}
		case USER_SIGNOUT: {
			return { ...state, token: null, authUser: null };
		}
		case USER_TOKEN_SET: {
			return { ...state, token: action.payload };
		}
		default:
			return state;
	}
};
