/*
================================================================
	Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Personalization Routes 
----------------------------------------------------------------
	Creation Details
	Date Created				: 21/Mar/2024
	Author						: Suraj Yadav
================================================================
*/
import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import PersonalizationListing from "../app/views/platformPersonalization";

const PROPERTIES = ["Properties:VIEW", "Properties:FULL"];

const personalizationRoutes = () => (
  <Suspense
    fallback={
      <div className="text-center">
        <h3>Loading...</h3>
      </div>
    }
  >
    <Switch>
      <AuthLayout
        exact
        path="/personalization"
        component={PersonalizationListing}
        pageHeading="GenNx CMS - Personalized Views"
        accessRights={{
          moduleName: "Personalized Views",
          parentModuleName: "",
          moduleAttribute: [...PROPERTIES],
          interfaceName: "Listing",
        }}
      />
    </Switch>
  </Suspense>
);

export default personalizationRoutes;
