/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase Sidebar Container
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/
import update from "immutability-helper";
import React, { useCallback } from "react";
import DraggableSidebarCard from "./DraggableSidebarCard";

const ContainerSidebar = (props) => {
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      // Update local state
      props.setSelectedDoc((prevCards) => {
        const updatedCards = update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        });
        return updatedCards;
      });
    },
    [props?.selectedDoc, props?.setSelectedDoc]
  );

  return (
    <div style={{ width: "100%" }}>
      {props?.selectedDoc.map((card, i) => (
        <DraggableSidebarCard key={card.id} index={i} id={card.id} itemCard={card} moveCard={moveCard} {...props} />
      ))}
    </div>
  );
};

export default ContainerSidebar;
