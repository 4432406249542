/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 15/Mar/2021
 * File Description: Survey Form Basic Details
 */

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { getDateFromMongoDate, getProductVariant } from "../../../../../utilities";
import { actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { surveyBasicValidationSchema } from "../validationSchema/index";
import { fetchSurveys, fetchSingleSurvey } from "../../../../../middleware/services/surveyApi";
import { ToggleSwitchNew } from "../formFields";
import {
  CREATE_FORM_OPTIONS,
  SURVEY_TYPE_OPTIONS,
  SURVEY_TYPE_OPTIONS_NPS,
  SURVEY_AUDIENCE_OPTIONS,
  SURVEY_STATUS_OPTIONS,
  TEAM_NAMES_OPTIONS,
  NPS_SURVEY_TYPE_OPTIONS,
  SURVEY_FREQUENCY,
  SURVEY_QUARTER,
  SURVEY_YEARS,
} from "./formFieldOptions";
import SURVEY_CONSTANT from "../../constants";
import { PRODUCT_VARIANT } from "../../../../../constants";
import ActionButtons from "../actionButtons";
import { TextField, Dropdown, DatePicker } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { setBasicDetails } from "../../../../../middleware/actions/surveyAction";
import AccessDenied from "../../../../components/accessDenied";

const initialState = {
  pVariant: getProductVariant(),
  createSurveyFrom: "",
  existingTemplate: "",
  surveyType: {
    label: SURVEY_CONSTANT.SURVEY_TYPE.ONETIME,
    value: SURVEY_CONSTANT.SURVEY_TYPE.ONETIME,
  },
  teamName: "",
  npsSurveyType: "",
  surveyTitle: "",
  serialNumber: "",
  surveyShortName: "",
  surveyUrl: "",
  surveyStartDate: "",
  surveyEndDate: "",
  validateEndDate: true,
  maskedSurveyResponse: "NO",
  saveAsTemplate: "NO",
  surveyAudience: "",
  surveyStatus: {
    label: SURVEY_CONSTANT.SURVEY_STATUS.DRAFT,
    value: SURVEY_CONSTANT.SURVEY_STATUS.DRAFT,
  },
  surveyReleaseArtifact: "",
  surveyFrequency: "Quarterly",
  surveyYear: "",
  surveyQuarter: "",
};
const style = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
  }),
};
const BasicDetails = (props) => {
  const dispatch = useDispatch();
  const productVariant = getProductVariant();
  const surveyId = props.surveyId || "";
  const [editData, setEditData] = useState(null);
  const [openFlag, setOpenFlag] = useState(true);
  const [templateData, setTemplateData] = useState(null);
  const productDomain = "SURVEY_DOMAIN_" + productVariant.toUpperCase();
  initialState.surveyUrl = SURVEY_CONSTANT[productDomain] + "/";
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(surveyBasicValidationSchema),
  });
  const productVariantWiseURL = SURVEY_CONSTANT[productDomain] + "/";

  const breadCrumbLinks = [
    { linkUrl: "/survey", linkName: "Survey", linkActive: false },
    {
      linkUrl: surveyId ? `/survey/${surveyId}/basic-details#basic` : "/survey/basic-details#basic",
      linkName: editData?.surveySerialNo || "Add New",
      linkActive: false,
    },
    {
      linkUrl: `/survey/${surveyId}/edit${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  const shortURL = (e) => {
    const value = e.value;
    const defailtFilters = [["surveyUrl", "eq", productVariantWiseURL + value]];
    const filters = editData ? [...defailtFilters, [["_id", "neq", surveyId]]] : defailtFilters;
    value &&
      fetchSurveys({ filters: filters, fields: ["surveyUrl"] }).then((res) => {
        if (res && res.status === 200) {
          if (res.data.totalCount) {
            formik.setFieldValue("surveyShortName", value);
            formik.setFieldError("surveyShortName", `Survey short name '${value}' already exists`);
            formik.setFieldTouched("surveyShortName", true);
            dispatch(actionError(`Survey short name <b>${value}</b> already exists`));
          } else {
            formik.setFieldValue("surveyUrl", productVariantWiseURL + value, true);
          }
        }
      });
  };
  const fetchBasicDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "surveyReferenceId",
      "surveySerialNo",
      "surveyShortName",
      "surveyUrl",
      "surveyTitle",
      "surveyStartDate",
      "surveyEndDate",
      "surveyType",
      "surveyIsMasked",
      "productVariant",
      "surveyAudience",
      "surveyIsTemplate",
      "surveyStatus",
      "surveyInvitationStatus",
      "surveyReleaseArtifact",
      "teamName",
      "npsSurveyType",
      "surveyFrequency",
      "surveyYear",
      "surveyQuarter",
    ];
    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    surveyId &&
      fetchSingleSurvey(surveyId, params).then((res) => {
        let data = res.data.data || {};
        data &&
          data.surveyReferenceId &&
          fetchSurveys({
            filters: [["_id", "eq", data.surveyReferenceId]],
            fields: ["surveySerialNo"],
          }).then((r) => {
            let d = r.data.data || {};
            d &&
              d.length > 0 &&
              formik.setFieldValue("existingTemplate", {
                label: d[0].surveySerialNo,
                value: data.surveyReferenceId,
              });
          });
        localStorage.setItem("surveySerialNo", data?.surveySerialNo);
        setEditData(data);
        localStorage.setItem("surveyStatus", data.surveyStatus); // set survey status to all tabs eg. design, audience
        ["Open", "Closed"].includes(data.surveyStatus) && setOpenFlag(false);
        formik.setValues({
          createSurveyFrom: data.surveyReferenceId ? { label: "Existing Template", value: "existing template" } : { label: "Blank", value: "blank" },
          surveyType: { label: data.surveyType, value: data.surveyType } || {
              label: SURVEY_CONSTANT.SURVEY_TYPE.ONETIME,
              value: SURVEY_CONSTANT.SURVEY_TYPE.ONETIME,
            } || {
              label: SURVEY_CONSTANT.SURVEY_TYPE.NPS_SURVEY,
              value: SURVEY_CONSTANT.SURVEY_TYPE.NPS_SURVEY,
            },
          teamName: data.teamName || "",
          npsSurveyType: data.npsSurveyType || "",
          surveyTitle: data.surveyTitle || "",
          serialNumber: data.surveySerialNo || "",
          surveyShortName: data.surveyShortName || "",
          surveyUrl: data.surveyUrl || "",
          surveyStartDate: data.surveyStartDate || "",
          surveyEndDate: data.surveyEndDate || "",
          maskedSurveyResponse: data.surveyIsMasked || "NO",
          saveAsTemplate: data.surveyIsTemplate || "NO",
          surveyAudience: { label: data.surveyAudience, value: data.surveyAudience } || "",
          surveyStatus: { label: data.surveyStatus, value: data.surveyStatus },
          surveyReleaseArtifact: data.surveyReleaseArtifact || "",
          //   only applicable with WhatNext Variant
          pVariant: productVariant,
          surveyFrequency: data.surveyFrequency || "",
          // In Which year survey will be attempt
          surveyYear: data.surveyYear || "",
          // In Which quarter survey will be attempt
          surveyQuarter: data.surveyQuarter || "",
        });
        dispatch(hideLoader());
      });
  };
  const fetchTemplates = () => {
    let params = {};
    let defaultFilter = [];
    let filters = [
      ["surveyIsTemplate", "eq", "YES"],
      ["surveyIsDeleted", "eq", "NO"],
    ];
    filters.push(["surveyStatus", "neq", SURVEY_CONSTANT.SURVEY_STATUS.DRAFT]);
    if (surveyId) filters.push(["id", "neq", surveyId]);
    let fields = ["surveyReferenceId", "surveySerialNo", "surveyStatus"];
    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...filters, ...defaultFilter];
    fetchSurveys(params).then((res) => {
      const newData =
        res &&
        res.data.data.map((d) => {
          return { label: d.surveySerialNo, value: d.id };
        });
      setTemplateData(newData);

      dispatch(hideLoader());
    });
  };

  useEffect(() => {
    if (!surveyId) {
      if (formik.values.createSurveyFrom.value !== "existing template") {
        formik.values.existingTemplate && formik.setFieldValue("existingTemplate", []);
      } else {
        formik.setFieldValue("existingTemplate", "");
      }
    }
  }, [formik.values.createSurveyFrom.value]);

  useEffect(() => {
    dispatch(setBasicDetails(formik.values));
  }, [formik.values]);

  useEffect(() => {
    delete SURVEY_STATUS_OPTIONS[1];
    if (surveyId) {
      dispatch(showLoader());
      if (!editData) fetchBasicDetails();
    }
    fetchTemplates();
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/survey"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, props.location, editData, props.selectedIndex]);

  useEffect(() => {
    if (!props.setTabErrors) return;
    props.setTabErrors((prev) => {
      return {
        ...prev,
        basic: Object.keys(formik?.errors)?.length ? true : false,
      };
    });
  }, [formik.errors]);

  const saveBasicDetails = async () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        props.handleCommonSave(surveyId);
      }
    });
  };

  const onOpen = (e) => {
    e.popup.element.querySelectorAll(".e-list-item")[1].classList.add("e-disabled");
    e.popup.element.querySelectorAll(".e-list-item")[1].classList.add("e-overlay");
  };

  return (
    <>
      {props?.interfaceAccess ? (
        <div>
          <div
            className="pt-3 mx-2 gennx-content-wrapper padding-bottom-150i"
            style={{
              padding: "1%",
              marginTop: "1%",
              backgroundColor: "#ffffff",
              width: "98.7%",
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  {surveyId && (
                    <div className="col-md-4">
                      <input type="hidden" value={productVariant} name="pVariant" id="pVariant" />
                      <TextField
                        formik={formik}
                        readOnly={true}
                        required={true}
                        id="serialNumber"
                        placeholder="Survey Serial Number"
                        name="serialNumber"
                        showClearButton={false}
                      />
                    </div>
                  )}
                  <div className="col-md-4">
                    <div className={surveyId ? "disable-react-select" : ""}></div>
                    <Dropdown
                      formik={formik}
                      required={true}
                      value={formik.values.createSurveyFrom.value}
                      enabled={surveyId ? false : undefined}
                      sortOrder="Acending"
                      placeholder="Create Survey With *"
                      id="createSurveyFrom"
                      name="createSurveyFrom"
                      fields={{ text: "label", value: "value" }}
                      dataSource={CREATE_FORM_OPTIONS}
                      change={(selectedOption) => {
                        formik.setValues({
                          ...formik.values,
                          createSurveyFrom: selectedOption
                            ? {
                                label: selectedOption.itemData.label,
                                value: selectedOption.itemData.value,
                              }
                            : {},
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <Dropdown
                      formik={formik}
                      required={true}
                      enabled={
                        formik.values.createSurveyFrom.value === "blank" || formik.values.createSurveyFrom.value === undefined || surveyId
                          ? false
                          : true
                      }
                      sortOrder="Acending"
                      placeholder="Select Survey Template *"
                      id="existingTemplate"
                      name="existingTemplate"
                      fields={{ text: "label", value: "value" }}
                      dataSource={templateData}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className={surveyId && !openFlag ? "disable-react-select" : ""}></div>
                    <Dropdown
                      formik={formik}
                      required={true}
                      // enabled= {surveyId && formik.values.createSurveyFrom && formik.values.createSurveyFrom.value === "existing template" ? false : (formik.values.createSurveyFrom.value === undefined || formik.values.createSurveyFrom.value !== "existing template" ? false : undefined)}
                      sortOrder="Acending"
                      placeholder="Survey Type *"
                      value={formik.values.surveyType.value}
                      id="surveyType"
                      name="surveyType"
                      fields={{ text: "label", value: "value" }}
                      dataSource={productVariant && productVariant === "Cheersin" ? SURVEY_TYPE_OPTIONS_NPS : SURVEY_TYPE_OPTIONS}
                      // (productVariant && productVariant === "Cheersin" ? SURVEY_TYPE_OPTIONS_NPS : SURVEY_TYPE_OPTIONS )
                      change={(selectedOption) => {
                        formik.setValues({
                          ...formik.values,
                          surveyType: selectedOption
                            ? {
                                label: selectedOption.itemData.label,
                                value: selectedOption.itemData.value,
                              }
                            : {},

                          // props.formik.setFieldValue(selectedOption.value)
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      formik={formik}
                      readOnly={surveyId && !openFlag ? true : false}
                      id="surveyTitle"
                      placeholder="Survey Title *"
                      name="surveyTitle"
                    />
                  </div>
                  <div className="col-md-4 surveyShortName">
                    <TextField
                      formik={formik}
                      readOnly={surveyId ? true : false}
                      id="surveyShortName"
                      placeholder="Survey Short Name *"
                      name="surveyShortName"
                      blur={shortURL}
                      showClearButton={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 surveyUrl">
                    <TextField formik={formik} readOnly={true} id="surveyUrl" placeholder="Survey URL *" name="surveyUrl" showClearButton={false} />
                  </div>
                  <div className="col-md-4">
                    <DatePicker
                      formik={formik}
                      required="required"
                      properties={{
                        placeholder: "Survey Start Date *",
                        id: "surveyStartDate",
                        name: "surveyStartDate",
                        label: "Survey Start Date",
                        format: "dd MMM yyyy",
                        floatLabelType: "Auto",
                        allowEdit: false,
                        enabled: surveyId && !openFlag ? false : true,
                        openOnFocus: true,
                        //min: new Date(),
                        //  max: new Date(),
                        min: getDateFromMongoDate(new Date()),
                      }}
                    />
                  </div>
                  {
                    <div className="col-md-4">
                      <DatePicker
                        formik={formik}
                        // required="required"
                        properties={{
                          placeholder: "Survey End Date *",
                          id: "surveyEndDate",
                          name: "surveyEndDate",
                          label: "Survey End Date",
                          format: "dd MMM yyyy",
                          floatLabelType: "Auto",
                          allowEdit: false,
                          enabled: formik.values.surveyType && formik.values.surveyType.value === "Ongoing" ? false : true,
                          openOnFocus: true,
                          min: !surveyId
                            ? formik.values[`surveyStartDate`]
                              ? getDateFromMongoDate(formik.values[`surveyStartDate`])
                              : getDateFromMongoDate(new Date())
                            : getDateFromMongoDate(formik.values[`surveyStartDate`]),
                        }}
                      />
                    </div>
                  }
                </div>
                <div className="row">
                  <div className="col-md-4 cy-survery_audience">
                    <div className={surveyId && !openFlag ? "disable-react-select" : ""}></div>
                    <Dropdown
                      formik={formik}
                      required={true}
                      enabled={surveyId && !openFlag ? false : undefined}
                      sortOrder="Acending"
                      placeholder="Survey Audience *"
                      id="surveyAudience"
                      name="surveyAudience"
                      value={formik.values.surveyAudience.value}
                      fields={{ text: "label", value: "value" }}
                      dataSource={SURVEY_AUDIENCE_OPTIONS}
                      change={(selectedOption) => {
                        formik.setValues({
                          ...formik.values,
                          surveyAudience: selectedOption
                            ? {
                                label: selectedOption.itemData.label,
                                value: selectedOption.itemData.value,
                              }
                            : {},
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <div className={surveyId && !openFlag ? "disable-react-select" : ""}></div>
                    <Dropdown
                      formik={formik}
                      required={true}
                      enabled={surveyId && !openFlag ? false : undefined}
                      // disabled={true}
                      sortOrder="Acending"
                      placeholder="Survey Status"
                      id="surveyStatus"
                      name="surveyStatus"
                      value={formik.values.surveyStatus.value}
                      fields={{ text: "label", value: "value" }}
                      dataSource={SURVEY_STATUS_OPTIONS}
                      change={(selectedOption) => {
                        formik.setValues({
                          ...formik.values,
                          surveyStatus: selectedOption
                            ? {
                                label: selectedOption.itemData.label,
                                value: selectedOption.itemData.value,
                              }
                            : {},
                        });
                      }}
                      open={(e) => {
                        onOpen(e);
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <ToggleSwitchNew
                      formik={formik}
                      required="required"
                      properties={{
                        id: "saveAsTemplate",
                        name: "saveAsTemplate",
                        label: "Save this survey as template",
                      }}
                    />
                  </div>
                  {productVariant && productVariant === "Cheersin"}
                  <div className="col-md-4">
                    {formik.values.surveyType &&
                      formik.values.surveyType.value === "NPS Survey" &&
                      productVariant &&
                      productVariant === "Cheersin" && (
                        <div className="col-md-25 pl-0">
                          <TextField
                            formik={formik}
                            // readOnly={surveyId && !openFlag ? true : false}
                            required={true}
                            id="surveyReleaseArtifact"
                            placeholder="Release Artifact *"
                            name="surveyReleaseArtifact"
                            // value={formik.values.surveyReleaseArtifact.value}
                            // fields={{ text: "label", value: "value" }}
                            showClearButton={false}
                          />
                        </div>
                      )}
                  </div>
                  <div className="col-md-4">
                    {formik.values.surveyType.value === SURVEY_CONSTANT.SURVEY_TYPE.NPS_SURVEY &&
                      productVariant &&
                      productVariant === PRODUCT_VARIANT.CHEERSIN && (
                        <div className="col-md-25 pl-0">
                          <Dropdown
                            formik={formik}
                            required={true}
                            sortOrder="Acending"
                            placeholder="Team *"
                            value={formik.values.teamName}
                            id="teamName"
                            name="teamName"
                            fields={{ text: "label", value: "value" }}
                            dataSource={productVariant && productVariant === "Cheersin" ? TEAM_NAMES_OPTIONS : null}
                            change={(selectedOption) => {
                              formik.setValues({
                                ...formik.values,
                                teamName: selectedOption ? selectedOption.itemData.value : {},
                              });
                            }}
                          />
                        </div>
                      )}
                  </div>

                  <div className="col-md-4">
                    {formik.values.surveyType.value === SURVEY_CONSTANT.SURVEY_TYPE.NPS_SURVEY &&
                      productVariant &&
                      productVariant === PRODUCT_VARIANT.CHEERSIN && (
                        <div className="col-md-25 pl-0">
                          <Dropdown
                            formik={formik}
                            required={true}
                            sortOrder="Acending"
                            placeholder="NPS Survey Type *"
                            value={formik.values.npsSurveyType}
                            id="npsSurveyType"
                            name="npsSurveyType"
                            fields={{ text: "label", value: "value" }}
                            dataSource={productVariant && productVariant === PRODUCT_VARIANT.CHEERSIN ? NPS_SURVEY_TYPE_OPTIONS : null}
                            change={(selectedOption) => {
                              formik.setValues({
                                ...formik.values,
                                npsSurveyType: selectedOption ? selectedOption.itemData.value : "",
                              });
                            }}
                          />
                        </div>
                      )}
                  </div>
                </div>
                {productVariant &&
                  productVariant === PRODUCT_VARIANT.WHATNEXT &&
                  formik.values.surveyType.value === SURVEY_CONSTANT.SURVEY_TYPE.ONGOING && (
                    <div className="row">
                      <div className="col-md-4">
                        <Dropdown
                          formik={formik}
                          required={true}
                          sortOrder="Acending"
                          placeholder="Survey Frequency *"
                          id="surveyFrequency"
                          name="surveyFrequency"
                          dataSource={SURVEY_FREQUENCY}
                        />
                      </div>
                      <div className="col-md-4">
                        <Dropdown
                          formik={formik}
                          required={true}
                          sortOrder="Acending"
                          placeholder="Survey Year *"
                          id="surveyYear"
                          name="surveyYear"
                          allowFiltering={true}
                          handleSearch={() => {
                            return true;
                          }}
                          dataSource={SURVEY_YEARS}
                        />
                      </div>
                      <div className="col-md-4">
                        {SURVEY_QUARTER && (
                          <Dropdown
                            formik={formik}
                            required={true}
                            sortOrder="Acending"
                            placeholder="Survey Quarter *"
                            id="surveyQuarter"
                            name="surveyQuarter"
                            value={formik.values.surveyQuarter}
                            dataSource={SURVEY_QUARTER}
                          />
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <ActionButtons
            accessRights={props.accessRights}
            selectedIndex={props.selectedIndex}
            setCurrentTab={(selectedIndex) => {
              props.setCurrentTab(selectedIndex);
            }}
            surveyId={surveyId ? surveyId : null}
            handleSave={editData?.surveyInvitationStatus != SURVEY_CONSTANT.SURVEY_INVITATION_STATUS.SENDING || !surveyId ? saveBasicDetails : null}
            showPublished={surveyId && editData?.surveyStatus == SURVEY_CONSTANT.SURVEY_STATUS.DRAFT ? true : false}
            downloadAnalytics={true}
            shortName={formik.values.surveyShortName}
            surveyStatus={surveyId ? true : false}
            isDraft={surveyId && editData?.surveyStatus == SURVEY_CONSTANT.SURVEY_STATUS.DRAFT ? true : false}
            tabsName={props.tabsName}
            moduleName={props.moduleName}
            hashArr={props.hashArr}
          />
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default BasicDetails;
