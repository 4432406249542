import React, { useState } from "react";
import Taxonomy from "./taxonomy";
import { getProductVariant } from "../../../../../utilities";
import AccessDenied from "app/components/accessDenied";

const Tagging = (props) => {
  const surveyId = props.surveyId;
  const [loadTaggingInfo, setLoadTaggingInfo] = useState("technology");
  const loadOtherTagInfo = (info) => {
    setLoadTaggingInfo(info);
  };
  if (surveyId == "") {
    return null;
  }
  const productVariant = getProductVariant();
  if (!productVariant || productVariant == "") return null;
  return (
    <>
      {props?.interfaceAccess ? (
        <div className="gennx-content-wrapper content-wrapper pt-3 mx-2" style={{ height: "90vh", overflow: "hidden" }}>
          {productVariant == "WhatNext" ? <Taxonomy {...props} loadOtherTagInfo={loadOtherTagInfo} /> : null}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};
export default Tagging;
