/**
 * @fileDescription Product Form Landing page
 * @author Pankaj Kulshreshtha | Cheers Interactive
 * @date 20/Aug/2020 *
 */
import React, { useEffect, useState, useRef } from "react";
import Tabs from "../../../components/tabsComponent";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getLoggedInUser, getProductVariant, accessRightActionCheck, accessRightInterfaceCheck } from "../../../../utilities";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { actionError, showMessage, actionSuccess } from "../../../../middleware/actions/utilityAction";
import Overview from "./overview";
import TechSpecification from "./techSpecification";
import ValueProposition from "./valueProposition";
import Licensing from "./licensing";
import Taxonomy from "./tagging";
import AccessDenied from "../../../components/accessDenied";
import { fetchProductBasicById, addProductBasic, editProductBasic, editProductAdvanced } from "../../../../middleware/services/productApi";
import { fetchSingleCompany } from "../../../../middleware/services/companyApi";
import { processingSubmitProduct, processingFinalSubmitProduct } from "../../../../middleware/services/cmsApi";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { PRODUCT_VARIANT } from "../../../../constants";

const RenderProduct = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const tabRef = useRef();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const history = useHistory();
  const split_url = props.location.pathname.split("/");
  let productId = props.computedMatch?.params?.productId || "";
  const companyId = props.computedMatch?.params?.companyId || "";
  const editData = productId && productId.length === 24 ? true : false;
  const [productName, setProductName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  const productVariant = getProductVariant();
  let TABS_NAME = ["Overview", "Technical Specification", "Value Proposition", "Licensing/Co-Development"];

  productVariant === PRODUCT_VARIANT.INSIDER ? TABS_NAME.push("Taxonomy & Tags") : TABS_NAME.push("Taxonomy");

  split_url.pop();
  split_url.pop();
  if (editData) split_url.pop();
  const backPath = companyId && companyId.length === 24 ? split_url.join("/") + "/edit#product" : "/product";
  split_url.pop();
  const dataObj = {
    companyId: companyId,
    productId: productId,
    editData: editData,
    backPath: backPath,
  };
  // split_url.splice(0,-3)

  const loggedInUser = getLoggedInUser();

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (!productId) {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.enableTab(4, false);
      tabRef.current.select(0);
    }
  };
  useEffect(() => {
    getCompanyName();
    getProductName();
    companyName ? dispatch(setBreadCrumb(breadCrumbLinksCompany)) : dispatch(setBreadCrumb(breadCrumbLinksProduct));
    dispatch(setSideNavForcedActiveLink("/product"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, selectedIndex, companyName, productName, props.location.hash]);

  const hashArr = ["Overview", "Technical Specification", "Value Proposition", "Licensing/Co-Development", "Taxonomy"];

  const breadCrumbLinksCompany = [
    { linkUrl: split_url.join("/"), linkName: `Company`, linkActive: false },
    { linkUrl: backPath, linkName: companyName, linkActive: false },
    { linkUrl: backPath, linkName: aR.moduleName, linkActive: false },
    {
      linkUrl: `${props.location.pathname}#overview`,
      linkName: productName ? productName : "Add New",
      linkActive: false,
    },
    { linkUrl: ``, linkName: hashArr[selectedIndex], linkActive: true },
  ];

  const breadCrumbLinksProduct = [
    { linkUrl: backPath, linkName: aR.moduleName, linkActive: false },
    {
      linkUrl: `${props.location.pathname}#overview`,
      linkName: productName,
      linkActive: false,
    },
    { linkUrl: ``, linkName: hashArr[selectedIndex], linkActive: true },
  ];

  const onSaveData = async (data) => {
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const productVariant = getProductVariant();
    if (productVariant) formData.append("productVariant", JSON.stringify([{ productName: productVariant, isLive: "NO" }]));
    const response = addProductBasic(formData);
    return (
      response &&
      response
        .then((result) => {
          if (result.status === 200) {
            dispatch(actionSuccess("Product added successfully"));
            tabRef.current.enableTab(1, true);
            tabRef.current.enableTab(2, true);
            tabRef.current.enableTab(3, true);
            tabRef.current.enableTab(4, true);
            tabRef.current.select(0);
            return {
              status: "success",
              companyId: companyId,
              productId: result.data.data.id,
              editData: false,
            };
          } else if (result.statusCode === 409) {
            dispatch(showMessage("Product Already Exists", "warning"));
            return { status: "failed" };
          } else {
            dispatch(actionError("SOMETHING WENT WRONG."));
            return { status: "failed" };
          }
        })
        .catch((err) => {
          if (err.data.statusCode === 409) {
            dispatch(showMessage(`Product Already Exists`, "warning"));
          } else {
            dispatch(actionError(err ? `Error: ${err.statusCode}, ${err.message}` : "SOMETHING WENT WRONG."));
          }
          return { status: "failed" };
        })
    );
  };

  const onSaveProcessingData = (tab, payload = {}) => {
    payload.userId = loggedInUser._id;
    if (!productId) {
      productId = payload.productId;
      delete payload.productId;
    }

    processingSubmitProduct(productId, tab, payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(actionSuccess(`Product successfully Saved & Submit for ${tab} tab`));
          return { status: "success" };
        }
      })
      .catch((err) => {
        dispatch(actionError(err.data.message || "Something went wrong"));
      });
  };

  const onFinalSaveProcessingData = (payload = {}) => {
    payload.userId = loggedInUser._id;
    dispatch(
      showAlertBox({
        okCallback: async () => {
          if (productId) {
            processingFinalSubmitProduct(productId, payload)
              .then((response) => {
                if (response.status === 200) {
                  dispatch(actionSuccess(`Product successfully Final Submitted`));
                  history.push(backPath, { companyId: companyId });
                  return { status: "success" };
                }
              })
              .catch((err) => {
                dispatch(actionError(err.data.message || "Something went wrong"));
              });
          } else {
            dispatch(actionError("Please save or save & submit the product before Final Submit"));
          }
        },
        okText: "Submit",
        cancelText: "Cancel",
        content: "Are you sure you want to submit ?",
        title: "dialogAlertCss",
      })
    );
  };

  const getProductName = async () => {
    let fields = { fields: ["id", "productName"] };
    if (productId) {
      const response = await fetchProductBasicById(productId, { ...fields });
      setProductName(response.data.data.productName);
    }
  };
  const getCompanyName = async () => {
    let fields = { fields: ["id", "companyName"] };
    if (companyId) {
      const response = await fetchSingleCompany(companyId, { ...fields });
      setCompanyName(response.data.data.companyName);
    }
  };

  const onUpdateData = (data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = editProductBasic(data.id, formData);
    return (
      response &&
      response
        .then((result) => {
          if (result.status === 200) {
            dispatch(actionSuccess("Product updated successfully"));
            return {
              status: "success",
              companyId: companyId,
              productId: data.id,
              editData: true,
            };
          } else {
            dispatch(actionError("SOMETHING WENT WRONG."));
            return { status: "failed" };
          }
        })
        .catch((err) => {
          dispatch(actionError(err ? `Error: ${err.statusCode}, ${err.message}` : "SOMETHING WENT WRONG."));
          return { status: "failed" };
        })
    );
  };

  const onUpdateAdvancedData = (data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = editProductAdvanced(data.id, formData);
    return (
      response &&
      response
        .then((result) => {
          if (result.status === 200) {
            dispatch(actionSuccess("Product updated successfully"));
            return { status: "success" };
          } else {
            dispatch(actionError("SOMETHING WENT WRONG."));
            return { status: "failed" };
          }
        })
        .catch((err) => {
          dispatch(actionError(err ? `Error: ${err.statusCode}, ${err.message}` : "SOMETHING WENT WRONG."));
          return { status: "failed" };
        })
    );
  };

  /* Interface access  */
  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, hashArr);

  /* Interface functionality wise access  */
  useEffect(() => {
    let interfaceName = hashArr[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(hashArr[selectedIndex]);

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
      <Tabs
        tabsName={TABS_NAME}
        tabRef={tabRef}
        created={created}
        handleClick={() => {
          setSelectedIndex(tabRef.current.selectedItem);
        }}
        cssClass="newHeaderTabCss"
      >
        <div key="productOverview">
          {selectedIndex === 0 && interfaceAccess ? (
            <Overview
              onSaveProcessingData={onSaveProcessingData}
              onFinalSaveProcessingData={onFinalSaveProcessingData}
              onUpdateData={onUpdateData}
              onSaveData={onSaveData}
              {...props}
              {...dataObj}
              editData={editData}
              productId={productId}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              hashArr={hashArr}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="productSpecification">
          {productId !== "" && selectedIndex === 1 && interfaceAccess ? (
            <TechSpecification
              onSaveProcessingData={onSaveProcessingData}
              {...props}
              {...dataObj}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              hashArr={hashArr}
              interfaceActionAccess={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="productValueProposition">
          {productId !== "" && selectedIndex === 2 && interfaceAccess ? (
            <ValueProposition
              onSaveProcessingData={onSaveProcessingData}
              onUpdateAdvancedData={onUpdateAdvancedData}
              onUpdateData={onUpdateData}
              {...props}
              {...dataObj}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              hashArr={hashArr}
              interfaceActionAccess={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
        <div key="productLicensing">
          {productId !== "" && selectedIndex === 3 && interfaceAccess ? (
            <Licensing
              onSaveProcessingData={onSaveProcessingData}
              {...props}
              {...dataObj}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              hashArr={hashArr}
              interfaceActionAccess={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>

        <div key="productTaxonomy">
          {productId !== "" && selectedIndex === 4 && interfaceAccess ? (
            <Taxonomy
              onSaveProcessingData={onSaveProcessingData}
              {...props}
              {...dataObj}
              selectedIndex={selectedIndex}
              setCurrentTab={(selectedIndex) => {
                tabRef.current.select(selectedIndex);
                setSelectedIndex(selectedIndex);
              }}
              hashArr={hashArr}
              interfaceActionAccess={interfaceActionAccess}
            />
          ) : (
            <AccessDenied />
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default RenderProduct;
