import React, { useEffect, useState } from "react";
import { TextField, Dropdown, DatePicker } from "../../../../../components/CustomFormElements/syncFusionFormFields";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import "./index.css";
import * as Yup from "yup";
import { HiArrowLeft } from "react-icons/hi";
import { getProductVariant, getDateFromMongoDate, accessRightActionCheck } from "../../../../../../utilities";
import {
  fetchSingleDeliverable,
  fetchSingleTask,
  saveDeliverableTask,
  updateDeliverableTask,
} from "../../../../../../middleware/services/deliverablesApi";
import { setBreadCrumb } from "../../../../../../middleware/actions/breadCrumbAction";
import { setSideNavForcedActiveLink } from "../../../../../../middleware/actions/sideNavAction";
import { Link, useHistory } from "react-router-dom";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../../middleware/actions/utilityAction";
import { fetchAccessUser } from "../../../../../../middleware/services/userApi";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const TaskDetails = (props) => {
  const { accessRights: aR } = props;
  const deliverableId = props?.computedMatch?.params?.deliverableId;
  const taskId = props?.location.state?.taskId || "";
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [deliverableData, setDeliverableData] = useState(null);
  const [taskData, setTaskData] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const productVariant = getProductVariant();
  const [assignedToData, setAssignedToData] = useState([]);
  const [userIdData, setuserIdData] = useState([]);
  let addTaskStatus = ["To do"];
  let editTaskStatus = ["To do", "Ongoing", "Closed"];
  let [astreikState, setAsreikState] = useState("");
  let [asreikActualEndState, setAsreikActualEndState] = useState("");
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  const [listingActionAccess, setListingActionAccess] = useState([]);

  const accessAdministrator = listingActionAccess.includes(COMMON_ACCESS_ACTION.REPORT_ADMINISTRATOR);
  const accessActionTaskDetailSubmit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SUBMIT);

  const formik = useFormik({
    initialValues: {
      taskType: [],
      taskAssignedTo: "",
      taskStatus: [],
      taskEstStartDate: "",
      taskEstEndDate: "",
      taskActStartDate: "",
      taskActEndDate: "",
      taskSummary: "",
    },
    validationSchema: Yup.object().shape({
      taskType: Yup.string().required("Please select task type."),
      taskAssignedTo: Yup.string().required("Please select task assigned to."),
      taskStatus: Yup.string().required("Please select task status."),
      taskSummary: Yup.string().max(200, "Please enter task summary of maximum 200 characters."),
      taskEstStartDate: Yup.date().required("Please select estimated start date").typeError("Please select estimated start date").nullable(),
      // taskEstEndDate: Yup.date().min(Yup.ref('taskEstStartDate'),(taskEstStartDate) => `End date cannot be before start date`,).required('Please select estimated end date').nullable(),
      taskEstEndDate: Yup.date()
        .when("taskEstStartDate", (taskEstStartDate, schema) => {
          if (taskEstStartDate) {
            const dayAfter = new Date(taskEstStartDate.getTime() - 86400000);
            return schema.min(dayAfter, "End date cannot be before start date").required("Please select estimated end date");
          }
          return schema;
        })
        .required("Please select estimated end date")
        .nullable(),

      taskActStartDate: Yup.date().when("taskStatus", {
        is: (obj) => {
          return obj && (obj === "Ongoing" || obj === "Closed");
        },
        then: Yup.date().required("Please select Actual Start Date").nullable(),
        otherwise: Yup.date().nullable(),
      }),
      taskActEndDate: Yup.date().when("taskStatus", {
        is: (obj) => {
          return obj && obj === "Closed";
        },
        then: Yup.date()
          .min(Yup.ref("taskActStartDate"), (taskActStartDate) => `End date cannot be before start date`)
          .required("Please select actual end date")
          .nullable(),
        otherwise: Yup.date()
          .min(Yup.ref("taskActStartDate"), (taskActStartDate) => `End date cannot be before start date`)
          .nullable(),
      }),
    }),
  });

  const breadCrumbLinks = [
    { linkUrl: "/deliverable", linkName: aR.moduleName, linkActive: false },
    { linkUrl: `/deliverable/${deliverableId}/edit#task`, linkName: deliverableData?.deliverableName || "", linkActive: false },
    { linkUrl: `/deliverable/${deliverableId}/edit#task`, linkName: "Tasks", linkActive: false },
    {
      linkUrl: deliverableId ? `/deliverable/${deliverableId}/edit#task` : "/deliverable/edit#task",
      linkName: taskId ? "Edit" : "Add",
      linkActive: true,
    },
  ];

  const fetchAssignData = () => {
    dispatch(showLoader());
    let type = "";
    if (formik.values.taskType === "Writer") {
      type = "Report Creator";
    }
    if (formik.values.taskType === "Reviewer") {
      type = "Report Reviewer";
    }
    if (formik.values.taskType === "Publisher") {
      type = "Report Publisher";
    }

    let params = {};
    let userData = [];
    let userDataId = [];
    params.filters = [
      ["userIsEnable", "eq", "YES"],
      ["userAccess.moduleName", "eq", "Deliverables"],
      ["userAccess.appId", "eq", import.meta.env.VITE_ENTERPRISE_APP_ID],
      ["userAccess.interfaceName", "eq", "Listing"],
      ["userAccess.actionName", "eq", type],
    ];
    params.limit = 2000;
    fetchAccessUser(params)
      .then((res) => {
        userData = res.data.data.map((d) => {
          return d.userFname.concat(" ", d.userLname);
        });
        userDataId = res.data.data.map((d) => {
          return { userName: d.userFname.concat(" ", d.userLname), userId: d.id };
        });
        setAssignedToData(userData);
        setuserIdData(userDataId);
        setTimeout(() => {
          dispatch(hideLoader());
        }, [700]);
      })
      .catch(() => {
        dispatch(hideLoader());
        dispatch(actionError("Something went wrong...!"));
      });
  };

  const saveTaskDetails = async () => {
    let taskData = {
      deliverableId: deliverableId,
      taskType: formik.values.taskType,
      userId: formik.values.taskAssignedTo,
      taskStatus: formik.values.taskStatus,
      taskEstStartDate: formik.values.taskEstStartDate,
      taskEstEndDate: formik.values.taskEstEndDate,
      taskSummary: formik.values.taskSummary,
    };
    if (formik.values.taskActStartDate) taskData.taskActStartDate = formik.values.taskActStartDate;
    if (formik.values.taskActEndDate) taskData.taskActEndDate = formik.values.taskActEndDate;
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        const err =
          res &&
          Object.values(res).map((e) => {
            return e;
          });

        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        if (taskId) {
          updateDeliverableTask(deliverableId, taskId, taskData)
            .then((res) => {
              if (res.status === 200) {
                dispatch(actionSuccess("Task updated successfully"));
                history.push(`/deliverable/${deliverableId}/edit#task`, { deliverableId: deliverableId });
              }
            })
            .catch((e) => {
              dispatch(hideLoader());
              if (e.data.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something went wrong"));
              }
            });
        } else {
          saveDeliverableTask(deliverableId, taskData)
            .then((res) => {
              if (res.status === 200) {
                dispatch(actionSuccess("Task created successfully"));
                history.push(`/deliverable/${deliverableId}/edit#task`, { deliverableId: deliverableId });
              }
            })
            .catch((e) => {
              dispatch(hideLoader());
              if (e.data.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something went wrong"));
              }
            });
        }
      }
    });
  };

  useEffect(() => {
    fetchAssignData();
    setuserIdData([]);
  }, [formik.values.taskType]);

  useEffect(() => {
    if (deliverableId) {
      let params = {};
      let fields = ["deliverableName"];
      params.fields = fields;
      fetchSingleDeliverable(deliverableId, params).then((res) => {
        let data = res.data.data || {};
        setDeliverableData(data);
      });

      if (taskId) {
        let params = {};
        fetchSingleTask(deliverableId, taskId, params).then((res) => {
          if (res && res.data.data) {
            const data = res.data.data;
            formik.setValues({
              taskType: data.taskType,
              taskAssignedTo: data?.taskAssignedTo.userId,
              taskStatus: data?.taskStatus,
              taskEstStartDate: data ? new Date(data.taskEstStartDate) : "",
              taskEstEndDate: data?.taskEstEndDate ? new Date(data.taskEstEndDate) : "",
              taskActStartDate: data?.taskActStartDate ? new Date(data.taskActStartDate) : "",
              taskActEndDate: data?.taskActEndDate ? new Date(data.taskActEndDate) : "",
              taskSummary: data?.taskSummary,
            });
          }
        });
      }
    }

    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/deliverable"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, taskData]);
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/deliverable"));
  }, [deliverableData, taskData, dispatch]);

  useEffect(() => {
    if (["Ongoing", "Closed"].includes(formik.values.taskStatus)) {
      setAsreikState("*");
      setAsreikActualEndState("");
    }
    if (["Closed"].includes(formik.values.taskStatus)) {
      setAsreikState("*");
      setAsreikActualEndState("*");
    }
    if (["To do"].includes(formik.values.taskStatus)) {
      setAsreikState("");
      setAsreikActualEndState("");
    }
  }, [formik.values.taskStatus]);

  useEffect(() => {
    let interfaceName = "Add Task";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  useEffect(() => {
    let interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setListingActionAccess(actionAccess);
  }, []);

  return (
    <div className="gennx-content-wrapper padding-bottom-100i p-2 mt-3 mx-2 bg-white" style={{ width: "98.5%", height: "75vh" }}>
      <div className="design-color">
        <Link className="pl-1 ml-2" to={`/deliverable/${deliverableId}/edit#task` || history.location.pathname}>
          <HiArrowLeft color="white" size={20} />
        </Link>
        <span className="font-style ml-2">{taskId ? "Edit Task" : "Add Task"}</span>
      </div>
      <form className="mx-2">
        <div className="row mt-3">
          <div className="col-md-4">
            <Dropdown
              formik={formik}
              enabled={accessAdministrator}
              required="required"
              id="taskType"
              name="taskType"
              placeholder="Task Type *"
              value={formik.values.taskType}
              dataSource={["Writer", "Reviewer", "Publisher"]}
            />
          </div>
          <div className="col-md-4">
            {((userIdData && userIdData.length > 0) || !taskId) && (
              <Dropdown
                enabled={accessAdministrator}
                formik={formik}
                required="required"
                id="taskAssignedTo"
                name="taskAssignedTo"
                placeholder="Assign to *"
                fields={{ text: "userName", value: "userId" }}
                value={formik.values.taskAssignedTo}
                dataSource={userIdData}
              />
            )}
          </div>
          <div className="col-md-4">
            <Dropdown
              enabled={accessAdministrator}
              formik={formik}
              required="required"
              id="taskStatus"
              name="taskStatus"
              placeholder="Status *"
              value={formik.values.taskStatus}
              dataSource={taskId ? editTaskStatus : addTaskStatus}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex flex-row">
              <div className="col-md-6 pl-0 pr-2">
                <DatePicker
                  formik={formik}
                  properties={{
                    disabled: accessAdministrator ? true : false,
                    placeholder: "Task Estimated Start Date *",
                    id: `taskEstStartDate`,
                    name: `taskEstStartDate`,
                    format: "dd MMM yyyy",
                    floatLabelType: "Auto",
                    allowEdit: false,
                    openOnFocus: true,
                    min: taskId ? "" : new Date(),
                  }}
                />
              </div>
              <div className="col-md-6 pl-2 pr-0">
                <DatePicker
                  formik={formik}
                  properties={{
                    disabled: accessAdministrator ? true : false,
                    placeholder: `Task Estimated End Date *`,
                    id: `taskEstEndDate`,
                    name: `taskEstEndDate`,
                    format: "dd MMM yyyy",
                    floatLabelType: "Auto",
                    allowEdit: false,
                    openOnFocus: true,
                    min: getDateFromMongoDate(formik.values[`taskEstStartDate`]),
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="col-md-6 pl-0 pr-2">
                <DatePicker
                  formik={formik}
                  properties={{
                    disabled: taskId ? false : true,
                    placeholder: `Task Actual Start Date ${astreikState ? "*" : ""}`,
                    id: `taskActStartDate`,
                    name: `taskActStartDate`,
                    format: "dd MMM yyyy",
                    floatLabelType: "Auto",
                    allowEdit: false,
                    openOnFocus: true,
                    min: getDateFromMongoDate(formik.values[`taskEstStartDate`]),
                  }}
                />
              </div>
              <div className="col-md-6 pl-2 pr-0">
                <DatePicker
                  formik={formik}
                  properties={{
                    disabled: taskId ? false : true,
                    placeholder: `Task Actual End Date ${asreikActualEndState ? "*" : ""}`,
                    id: `taskActEndDate`,
                    name: `taskActEndDate`,
                    format: "dd MMM yyyy",
                    floatLabelType: "Auto",
                    allowEdit: false,
                    openOnFocus: true,
                    min: getDateFromMongoDate(formik.values[`taskActStartDate`]),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <TextField
              style={{ height: "120px" }}
              formik={formik}
              multiline={true}
              numberCount={true}
              maxLength={200}
              rows={4}
              name="taskSummary"
              className="form-control"
              id="taskSummary"
              placeholder="Task Summary"
              {...props}
              value={formik.values.taskSummary}
            />
          </div>
        </div>
      </form>
      <div className="form-submit-box">
        <div className="container-fluid nopadding">
          <div className="row">
            <div className="float-right col-md-12 text-right">
              <Link to={`/deliverable/${deliverableId}/edit#task` || history.location.pathname}>
                <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                  Cancel
                </button>
              </Link>
              {accessActionTaskDetailSubmit && (
                <button
                  type="button"
                  disabled={btnDisabled ? "disabled" : ""}
                  className="btn btn-primary cy-btn pl-4 pr-4 "
                  onClick={() => {
                    saveTaskDetails();
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetails;
