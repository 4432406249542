/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Modal Rename Folder
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import ModalTextInput from "../ModalTextInput/ModalTextInput";
import ModalCancelSaveButtons from "../ModalCancelSaveButtons/ModalCancelSaveButtons";
import { getAllBriefcaseCategoryList, renameBriefcaseFolderName } from "../../../middleware/services/myBriefcase";
import classes from "./ModalRenameFolder.module.css";
import { getLoggedInUser } from "../../../utilities";
import { actionError, actionSuccess } from "../../../middleware/actions/utilityAction";
import { getAllBriefcaseCategory, renameOldFolder } from "../../../middleware/actions/myBriefcaseAction";

const ModalRenameFolder = ({ renameType, modalTitle, show, onHide, renameDeleteId, getBriefcaseFolderList }) => {
  const dispatch = useDispatch();
  const [folderName, setFolderName] = useState("");
  const [showError, setShowError] = useState(false);
  const [folderExistError, setFolderExistError] = useState(false);
  const [existingFolders, setExistingFolders] = useState([]);
  const productVariant = localStorage.getItem("productVariant");
  const user = getLoggedInUser();
  const allBriefcaseCategoryListing = useSelector((state) => state["myBriefcaseState"]["allBriefcaseCategoryListing"]);

  const regex = /^[!@#$%^&*()_-]+$/;

  // check briefcase folder exist or not
  const checkFolderExist = (obj) => obj.toLowerCase() === folderName.trim().toLowerCase();

  // Folder rename handler
  const renameFolder = () => {
    if (Object.getOwnPropertyNames(user).length !== 0 && folderName.trim() !== "" && !/^\s/.test(folderName) && !regex.test(folderName)) {
      if (existingFolders.some(checkFolderExist)) {
        setFolderExistError(true);
      } else {
        if (renameType === "briefcase") {
          renameBriefcaseFolderName(user._id, renameDeleteId, folderName.trim())
            .then(() => {
              dispatch(actionSuccess("Briefcase folder renamed successfully."));
              getBriefcaseFolderList();
              dispatch(
                renameOldFolder({
                  id: renameDeleteId,
                  name: folderName,
                })
              );
              getAllCategory();
            })
            .catch((error) => {
              if (error.response?.status === 409) {
                dispatch(actionError(`Folder is already exist with name ${folderName}`));
              } else {
                console.log("error >>>", error);
              }
            });
        }
        onHide();
        setShowError(false);
        setFolderExistError(false);
        setFolderName("");
      }
    } else {
      setShowError(true);
    }
  };

  const onHideHandler = () => {
    onHide();
    setFolderName("");
    setShowError(false);
    setFolderExistError(false);
  };

  const getAllCategory = async () => {
    await getAllBriefcaseCategoryList(user?._id, productVariant)
      .then((res) => {
        dispatch(
          getAllBriefcaseCategory({
            res,
            totalCount: res.totalCount,
          })
        );
      })
      .catch((error) => {
        console.log("error  category listing >>>", error);
      });
  };

  // add new added folder to existing folder list
  useEffect(() => {
    let folderArray = [];
    if (allBriefcaseCategoryListing.length !== 0) {
      allBriefcaseCategoryListing.forEach(function (item) {
        folderArray.push(item.categoryName);
      });
    }
    setExistingFolders(folderArray);
  }, [allBriefcaseCategoryListing]);

  return (
    <div>
      <Modal
        className="CentredModal"
        dialogClassName={classes.modalMain}
        contentClassName={classes.modalContent}
        show={show}
        onHide={onHideHandler}
        centered
        backdrop="static"
      >
        <Modal.Header className={classes.modalHeader}>
          <Modal.Title className={classes.modalTitle}>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modalBody}>
          <ModalTextInput
            label="Folder Name"
            placeholderText="Enter Name"
            folderName={folderName}
            setFolderName={setFolderName}
            createFolder={renameFolder}
          />
          {showError && folderName === "" && <p className={classes.errorText}>Enter folder name.</p>}

          {existingFolders.some(checkFolderExist) && folderExistError && <p className={classes.errorText}>Folder is already exists.</p>}

          {/^\s/.test(folderName) && <p className={classes.errorText}>Folder name should not be start with space.</p>}
          {regex.test(folderName) && showError && <p className={classes.errorText}>Folder name should not contain only special characters.</p>}
        </Modal.Body>

        <Modal.Footer className={classes.modalFooter}>
          <ModalCancelSaveButtons cancelButtonClick={onHideHandler} saveButtonText="Save" saveButtonClick={renameFolder} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalRenameFolder;
