import { ACTIVITY_LOGGING_API as API } from "../../config/apiConfig";

export function addLog(payload) {
	return API({
		url: `/activity-logging`,
        method: "POST",
        data :payload
	});
}

export function updateLog(activityLogId,payload) {
	return API({
		url: `/activity-logging/${activityLogId}`,
		method: "PUT",
		data : payload
	});
}

