import React, { useState, useEffect } from "react";
import { qcGetProductRemark, getRemarkList } from "../../../middleware/services/cmsApi";
import groupBy from "lodash/groupBy";
import { Modal, ModalFooter } from "react-bootstrap";
import { actionError } from "../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";

const RemarkList = (props) => {
  const [remarkCount, setRemarkCount] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [remarks, setRemarks] = useState([]);
  const dispatch = useDispatch();
  const fetcQcRemarkSpecificToProduct = () => {
    qcGetProductRemark(props.productId)
      .then((response) => {
        if (response) {
          const seletedRemarks = response.data.data.map((item) => item.remarkId);
          setRemarkCount(seletedRemarks);
        }
      })
      .catch((err) => {
        dispatch(actionError(err.data?.message || "Something went wrong"));
      });
  };
  const remarkList = () => {
    const params = { filters: [["remarkEntityType", "eq", "product"]] };
    getRemarkList(params)
      .then((response) => {
        const data = response.data?.data || [];
        const remarkData = groupBy(data, "remarkAttributeDisplayName");
        setRemarks(remarkData);
        console.log(remarkData, "remarks data");
      })
      .catch((err) => {
        dispatch(actionError(err.data?.message || "Something went wrong"));
      });
  };
  useEffect(() => {
    fetcQcRemarkSpecificToProduct();
    remarkList();
  }, []);
  return (
    <>
      {remarkCount.length ? (
        <button
          type="button"
          className="btn btn-outline-dark pl-4 pr-4 mr-4"
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          Remarks{remarkCount.length ? `(${remarkCount.length})` : null}
        </button>
      ) : null}

      <Modal
        show={showModal}
        backdrop="static"
        className="modal fade lg"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <div className="modal-header bg-secondary text-white">
          <h5 className="modal-title" id="exampleModalLabel">
            Remarks
          </h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              setShowModal(!showModal);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          {Object.keys(remarks).map((item) => {
            return (
              <div key={item} className="mt-3">
                {remarks[item].map((value) => {
                  return (
                    <>
                      {remarkCount.indexOf(value.id) != -1 ? (
                        <>
                          <b>
                            {" "}
                            <label>{item}</label>
                          </b>
                          <div key={value.id}>
                            <label htmlFor={value.id}>
                              {/* <input
                                                        type="checkbox"
                                                        className="mr-1"
                                                        id={value.id}
                                                        name={value.id}
                                                        value={value.id}
                                                        checked={remarkCount.indexOf(value.id) != -1 ? true : false}
                                                    /> */}
                              {"- " + value.remarkTitle}
                            </label>
                          </div>
                        </>
                      ) : null}
                    </>
                  );
                })}
              </div>
            );
          })}
        </Modal.Body>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-outline-dark cy-btn"
            onClick={() => {
              setShowModal(!showModal);
            }}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RemarkList;
