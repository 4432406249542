/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Utility Actions for Loader, Toast Notification, Messages
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import {
	ACTION_START, ACTION_STOP, ACTION_SUCCESS, ACTION_ERROR, SHOW_MESSAGE, HIDE_MESSAGE,
	SHOW_LOADER, HIDE_LOADER
} from "../../constants/actionConstants";

export const actionStart = () => ({
	type: ACTION_START,
});

export const actionStop = () => ({
	type: ACTION_STOP,
});

export const actionSuccess = (message = "") => ({
	type: ACTION_SUCCESS,
	payload: {message: message, toast: "success"},
});

export const actionError = (error = "") => ({
	type: ACTION_ERROR,
	payload: {error: error, message: error, toast: "error"},
});

export const showMessage = (message = "", type = "information") => ({
	type: SHOW_MESSAGE,
	payload: {message: message, toast: type},
});

export const hideMessage = () => ({
	type: HIDE_MESSAGE,
});

export const showLoader = () => ({
	type: SHOW_LOADER,
});

export const hideLoader = () => ({
	type: HIDE_LOADER,
});
