export const presetsItems = [
  {
    titleKey: "120 x 120 (px)",
    width: 120,
    height: 120,
    customCropSize: true,
  },
  {
    titleKey: "175 x 175 (px)",
    width: 175,
    height: 175,
    customCropSize: true,
  },
  {
    titleKey: "225 x 225 (px)",
    width: 225,
    height: 225,
    customCropSize: true,
  },
  {
    titleKey: "300 x 250 (px)",
    width: 300,
    height: 250,
    customCropSize: true,
  },
  {
    titleKey: "450 x 300 (px)",
    width: 450,
    height: 300,
    customCropSize: true,
  },
  {
    titleKey: "450 x 450 (px)",
    width: 450,
    height: 450,
    customCropSize: true,
  },
  {
    titleKey: "900 x 450 (px)",
    width: 900,
    height: 450,
    customCropSize: true,
  },
  {
    titleKey: "900 x 600 (px)",
    width: 900,
    height: 600,
    customCropSize: true,
  },
  {
    titleKey: "1920 x 450 (px)",
    width: 1920,
    height: 450,
    customCropSize: true,
  },
  {
    titleKey: "1920 x 750 (px)",
    width: 1920,
    height: 750,
    customCropSize: true,
  },
];
