// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : Image Upload Adapter for CK editor version 5
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created : 11 Jul 2024
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import { saveImageToLocal } from "middleware/services/contentBlockApi";

export default function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

export class MyUploadAdapter {
  constructor(loader, onImageUploadStart, onImageUploadComplete) {
    this.loader = loader;
    this.onImageUploadStart = onImageUploadStart;
    this.onImageUploadComplete = onImageUploadComplete;
  }

  upload() {
    if (this.onImageUploadStart) {
      this.onImageUploadStart(); // Trigger when the image upload starts
    }

    const formData = new FormData();
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this.convertToBase64(file)
            .then((base64String) => {
              formData.append("base64", base64String);

              return saveImageToLocal(formData);
            })
            .then((res) => {
              if (res?.data?.data) {
                this.loader.uploaded = true;
                if (this.onImageUploadComplete) {
                  this.onImageUploadComplete(res?.data?.data); // Trigger when the image upload is complete
                }
                resolve({
                  default: res?.data?.data,
                });
              } else {
                reject(`Couldn't upload file: ${file.name}.`);
              }
            })
            .catch((error) => {
              reject(`Couldn't convert file to base64: ${error}`);
            });
        })
    );
  }

  convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
