/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  Survey form field options
 ---------------------------------------------------------------------------------
    Creation Details
    Date Created				: 17/Mar/2021
    Author						: Pankaj Kulshreshtha
================================================================
*/
import SURVEY_CONSTANT from "../../constants";
// Survey create from options
export const CREATE_FORM_OPTIONS = [
  {
    label: "Start with blank template",
    value: "blank",
  },
  {
    label: "Choose from existing templates",
    value: "existing template",
  },
];
// Survey type options
export const SURVEY_TYPE_OPTIONS = [
  {
    label: SURVEY_CONSTANT.SURVEY_TYPE.ONGOING,
    value: SURVEY_CONSTANT.SURVEY_TYPE.ONGOING,
  },
  {
    label: SURVEY_CONSTANT.SURVEY_TYPE.ONETIME,
    value: SURVEY_CONSTANT.SURVEY_TYPE.ONETIME,
  },
];

export const SURVEY_FREQUENCY = [
  // SURVEY_CONSTANT.SURVEY_FREQUENCY.MONTHLY,   //Don't remove
  SURVEY_CONSTANT.SURVEY_FREQUENCY.QUARTERLY,
  // SURVEY_CONSTANT.SURVEY_FREQUENCY.YEARLY  //Don't remove
];
let years = [];
for (let i = 2021; i < 2099; i++) {
  years.push(i);
}
export const SURVEY_YEARS = years;
export const SURVEY_QUARTER = [
  SURVEY_CONSTANT.SURVEY_QUARTER.JAN_MAR,
  SURVEY_CONSTANT.SURVEY_QUARTER.APR_JUN,
  SURVEY_CONSTANT.SURVEY_QUARTER.JUL_SEP,
  SURVEY_CONSTANT.SURVEY_QUARTER.OCT_DEC,
];
export const SURVEY_TYPE_OPTIONS_NPS = [
  {
    label: SURVEY_CONSTANT.SURVEY_TYPE.ONGOING,
    value: SURVEY_CONSTANT.SURVEY_TYPE.ONGOING,
  },
  {
    label: SURVEY_CONSTANT.SURVEY_TYPE.ONETIME,
    value: SURVEY_CONSTANT.SURVEY_TYPE.ONETIME,
  },
  {
    label: SURVEY_CONSTANT.SURVEY_TYPE.NPS_SURVEY,
    value: SURVEY_CONSTANT.SURVEY_TYPE.NPS_SURVEY,
  },
];
export const TEAM_NAMES_OPTIONS = [
  SURVEY_CONSTANT.TEAM_NAMES.PRODUCT_TEAM,
  SURVEY_CONSTANT.TEAM_NAMES.APPLICATION_TEAM,
  SURVEY_CONSTANT.TEAM_NAMES.MARTECH_TEAM,
  SURVEY_CONSTANT.TEAM_NAMES.CORE_TEAM,
];
export const NPS_SURVEY_TYPE_OPTIONS = [
  SURVEY_CONSTANT.NPS_SURVEY_TYPE.PROJECT_FEEDBACK
];

// Survey type options
export const SURVEY_AUDIENCE_OPTIONS = [
  {
    label: SURVEY_CONSTANT.SURVEY_AUDIENCE.PUBLIC,
    value: SURVEY_CONSTANT.SURVEY_AUDIENCE.PUBLIC,
  },
  {
    label: SURVEY_CONSTANT.SURVEY_AUDIENCE.LIMITED,
    value: SURVEY_CONSTANT.SURVEY_AUDIENCE.LIMITED,
  },
];
// Survey status options
export const SURVEY_STATUS_OPTIONS = [
  {
    label: SURVEY_CONSTANT.SURVEY_STATUS.DRAFT,
    value: SURVEY_CONSTANT.SURVEY_STATUS.DRAFT,
    isDisabled: true,
  },
  {
    label: SURVEY_CONSTANT.SURVEY_STATUS.NOT_STARTED,
    value: SURVEY_CONSTANT.SURVEY_STATUS.NOT_STARTED,
    isDisabled: true,
  },
  {
    label: SURVEY_CONSTANT.SURVEY_STATUS.OPEN,
    value: SURVEY_CONSTANT.SURVEY_STATUS.OPEN,
    disabled: true,
  },
  {
    label: SURVEY_CONSTANT.SURVEY_STATUS.CLOSED,
    value: SURVEY_CONSTANT.SURVEY_STATUS.CLOSED,
  },
];
