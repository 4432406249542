/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Actions for different action status
----------------------------------------------------------------
	Creation Details
	Date Created				: 20/NOV/2023
	Author						: LAWRENCE ANTHONY
================================================================
*/

import {
  SET_SEARCH_KEYWORDS,
  GET_TECHNOLOGY_LIST,
  SET_FIRST_TREE_CHECKED_NODES,
  SET_SECOND_TREE_CHECKED_NODES,
  SET_ALL_SEARCH_COUNT,
  SET_FIXED_ALL_SEARCH_COUNT,
  SET_SEARCH_SORT,
  SET_DATE_FILTER_VALUE,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_CONTENT_TYPE_COUNT,
  SET_RESULT_COUNT,
  SET_FIXED_CONTENT_TYPE_COUNT,
  SET_CURRENT_QUERY,
  SET_CURRENT_QUERY_NEW_SEARCH,
  SET_CONTENT_TYPE_FILTER_SEARCH,
  SET_SEARCH_SELECTED_FILTER_NODES,
  SET_TREND_FILTER_SEARCH,
  GET_SECTORS_SEARCH,
  SET_DATE_FILTER,
  TOGGLE_LOADING,
  SET_SGF_IDS_SEARCH,
  GET_CONTENT_TYPE_SEARCH,
  CLEAR_ALL_SEARCH_FILTER,
  GET_TRENDS_LIST,
  GET_INDUSTRIES_LIST,
  SET_STATUS_FILTER,
} from "../../constants/actionConstants";

// for top search box search
export const setKeywords = (data) => ({
  type: SET_SEARCH_KEYWORDS,
  payload: data,
});

export const getTechnologyList = (data) => ({
  type: GET_TECHNOLOGY_LIST,
  payload: data,
});

export const setFirstTreeCheckedNodes = (data) => ({
  type: SET_FIRST_TREE_CHECKED_NODES,
  payload: data,
});

export const clearAllSearchFilter = () => ({
  type: CLEAR_ALL_SEARCH_FILTER,
});

export const setSecondTreeCheckedNodes = (data) => ({
  type: SET_SECOND_TREE_CHECKED_NODES,
  payload: data,
});

export const setAllSearchCount = (data) => ({
  type: SET_ALL_SEARCH_COUNT,
  payload: data,
});

export const setFixedAllSearchCount = (data) => ({
  type: SET_FIXED_ALL_SEARCH_COUNT,
  payload: data,
});

export const setSearchSort = (data) => ({
  type: SET_SEARCH_SORT,
  payload: data,
});

export const setDateFilterValue = (data) => ({
  type: SET_DATE_FILTER_VALUE,
  payload: data,
});

export const setFromDate = (data) => ({
  type: SET_FROM_DATE,
  payload: data,
});

export const setToDate = (data) => ({
  type: SET_TO_DATE,
  payload: data,
});

export const setContentTypeCount = (data) => ({
  type: SET_CONTENT_TYPE_COUNT,
  payload: data,
});

export const setResultCount = (data) => ({
  type: SET_RESULT_COUNT,
  payload: data,
});

export const setFixedContentTypeCount = (data) => ({
  type: SET_FIXED_CONTENT_TYPE_COUNT,
  payload: data,
});

export const setCurrentQuery = (data) => ({
  type: SET_CURRENT_QUERY,
  payload: data,
});

export const setCurrentQueryNewSearch = (data) => ({
  type: SET_CURRENT_QUERY_NEW_SEARCH,
  payload: data,
});

export const fetchTechnologyList = () => {
  return {
    type: "FETCH_TECHNOLOGY_LIST",
  };
};

export const fetchTrendsList = () => {
  return {
    type: "FETCH_TRENDS_LIST",
  };
};

export const fetchIndustriesList = () => {
  return {
    type: "FETCH_INDUSTRIES_LIST",
  };
};


export const setContentTypeFilterSearch = (data) => ({
  type: SET_CONTENT_TYPE_FILTER_SEARCH,
  payload: data,
});

export const setSearchSelectedFilterNodes = (data) => ({
  type: SET_SEARCH_SELECTED_FILTER_NODES,
  payload: data,
});

export const setTrendFilterSearch = (data) => ({
  type: SET_TREND_FILTER_SEARCH,
  payload: data,
});

export const getSectorsSearch = (data) => ({
  type: GET_SECTORS_SEARCH,
  payload: data,
});

export const setDateFilter = (data) => ({
  type: SET_DATE_FILTER,
  payload: data,
});

export const toggleLoading = (data) => ({
  type: TOGGLE_LOADING,
  payload: data,
});

export const setSgfIdsSearch = (data) => ({
  type: SET_SGF_IDS_SEARCH,
  payload: data,
});

export const getContentTypesSearch = (data) => ({
  type: GET_CONTENT_TYPE_SEARCH,
  payload: data,
});

export const getSgfTypeSearch = () => {
  return {
    type: "GET_SGF_TYPE_SEARCH",
  };
};

export const getContentTypeSearch = () => {
  return {
    type: "GET_CONTENT_TYPE_SEARCH",
  };
};

export const getTrendsList = (data) => ({
  type: GET_TRENDS_LIST,
  payload: data,
});

export const getIndustriesList = (data) => ({
  type: GET_INDUSTRIES_LIST,
  payload: data,
});

export const setStatusFilter = (data) => ({
  type: SET_STATUS_FILTER,
  payload: data,
});
