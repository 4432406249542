import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Modal, ModalFooter } from "react-bootstrap";
import { actionSuccess, actionError } from "../../../../middleware/actions/utilityAction";
import { getRemarkList, qcRemarkSave } from "../../../../middleware/services/cmsApi";
import groupBy from "lodash/groupBy";

// remark modal
const RemarkModal = (props) => {
  const [state, setState] = useState([]);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([]);

  const remarkList = () => {
    const params = { filters: [["remarkEntityType", "eq", "company"]] };
    getRemarkList(params)
      .then((response) => {
        const data = response.data?.data || [];
        const remarkData = groupBy(data, "remarkAttributeDisplayName");
        setState(remarkData);
      })
      .catch((err) => {
        dispatch(actionError(err.data?.message || "Something went wrong"));
      });
  };
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleSaveRemark = () => {
    const payload = {
      userId: props.userId,
      remarks: [...checked],
    };
    qcRemarkSave(props.companyId, payload)
      .then((response) => {
        props.clicked();
        dispatch(actionSuccess("Qc remark saved successfully"));
      })
      .catch((err) => {
        dispatch(actionError(err.data.message || "Something went wrong"));
      });
  };

  useEffect(() => {
    const seletedRemarks = props.remarks.map((item) => item.remarkId);
    setChecked(seletedRemarks);
    remarkList();
  }, [props.remarks]);

  return (
    <>
      <Modal
        show={props.showModal}
        backdrop="static"
        className="modal fade lg"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <div className="modal-header bg-secondary text-white">
          <h5 className="modal-title" id="exampleModalLabel">
            Add/Edit Remarks
          </h5>
          <button type="button" className="close" aria-label="Close" onClick={props.clicked}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          {Object.keys(state).map((item) => {
            return (
              <div className="mt-3">
                <b>
                  {" "}
                  <label>{item}</label>
                </b>
                {state[item].map((value) => {
                  return (
                    <div key={value.id}>
                      <label htmlFor={value.id}>
                        <input
                          type="checkbox"
                          className="mr-1"
                          id={value.id}
                          name={value.id}
                          value={value.id}
                          checked={checked.indexOf(value.id) != -1 ? true : false}
                          key={value.id}
                          onChange={() => {
                            handleToggle(value.id);
                          }}
                        />
                        {value.remarkTitle}
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </Modal.Body>
        <ModalFooter>
          {/* <div className="modal-footer"> */}
          <button
            type="button"
            className="btn btn-outline-dark cy-btn"
            onClick={() => {
              setChecked([]);
              props.clicked();
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary cy-btn" onClick={handleSaveRemark}>
            Save
          </button>
          {/* </div> */}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RemarkModal;
RemarkModal.propTypes = {
  companyId: PropTypes.string.isRequired,
};
