import React, { memo } from 'react';
import { MdClose } from 'react-icons/md';
import './styles.css';

function PopupHeader({ setAnchorEl }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        padding: '15px 20px',
      }}
      className="popup-header-modal"
    >
      <MdClose onClick={() => setAnchorEl(null)} style={{ cursor: 'pointer' }} fontSize="20px" />
    </div>
  );
}

export default memo(PopupHeader);
