/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : User API
----------------------------------------------------------------
	Creation Details
	Date Created				: 23/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
	Change History				: #1
	Changed By					: Anand Kumar
	Changed Date				: 13/Oct/2020
	Change Description			: Added API to save and update the user subscription
================================================================
*/

import { USER_API as API } from "../../config/apiConfig";
import { USER_URL } from "../../config";

// all user
export const fetchUser = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/user",
    method: "GET",
    params: params,
  });
};

// client user
export const fetchClientUser = (params = {}) => {
  let { filters = [], fields = undefined } = params;
  let clientUserFilter = [["userType", "eq", "Client"]];
  filters = [...filters, ...clientUserFilter];
  params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/user",
    method: "GET",
    params: params,
  });
};

// external user
export const fetchExternalUser = (params = {}) => {
  let { filters = [], fields = undefined } = params;
  let externalUserFilter = [["userType", "eq", "External"]];
  filters = [...filters, ...externalUserFilter];
  params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/user",
    method: "GET",
    params: params,
  });
};

// internal user
export const fetchInternalUser = (params = {}) => {
  let { filters = [], fields = undefined } = params;
  let internalUserFilter = [["userType", "eq", "Internal"]];
  filters = [...filters, ...internalUserFilter];
  params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/user",
    method: "GET",
    params: params,
  });
};
// External user for Internship Registration
export const fetchInternshipUser = (params = {}) => {
  let { filters = [], fields = undefined } = params;
  let internalUserFilter = [["userType", "eq", "External"]];
  filters = [...filters, ...internalUserFilter];
  params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/internship-user",
    method: "GET",
    params: params,
  });
};

export const fetchSingleUser = (userId, params = {}) => {
  let { fields = undefined } = params;
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/user/${userId}`,
    method: "GET",
    params: params,
  });
};

export const fetchUserProfileImage = (fileName) => {
  return API({
    url: `/user/image/${fileName}`,
    method: "GET",
  });
};

export const fetchUserProfileImageUrl = (fileName) => {
  return `${USER_URL}/user/image/${fileName}`;
};

export const addUser = (payload) => {
  return API({
    headers: { "content-type": "multipart/form-data" },
    url: "/user",
    method: "POST",
    data: payload,
  });
};

export const updateUser = (userId, payload) => {
  return API({
    headers: { "content-type": "multipart/form-data" },
    url: `/user/${userId}`,
    method: "PUT",
    data: payload,
  });
};

export const deleteUser = (userId) => {
  return API({
    url: `/user/${userId}`,
    method: "DELETE",
  });
};
// Activate Inactive user
export const activateUser = (userId) => {
  return API({
    url: `/user/active/${userId}`,
    method: "PUT",
  });
};

export const deleteManyUser = (payload, params = {}) => {
  let { filters = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  return API({
    url: "/user",
    method: "DELETE",
    params: params,
    data: payload,
  });
};

// get user subscription
export const fetchUserSubscription = (userId, params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: `/user/${userId}/subscription`,
    method: "GET",
    params: params,
  });
};

// add user susbscription
export const addUserSubscription = (userId, payload) => {
  return API({
    url: `/user/${userId}/subscription`,
    method: "POST",
    data: payload,
  });
};
// delete a subscription
export const deleteUserSubscription = (userId, subscriptionId) => {
  return API({
    url: `/user/${userId}/subscription/${subscriptionId}`,
    method: "DELETE",
  });
};
// fetch btb ids
export const fetchBtbIds = (userId, empCode, params = {}) => {
  return API({
    url: `user/${userId}/btb/${empCode}`,
    method: "GET",
    params: params,
  });
};

// fetch single product
export const fetchSingleSubscription = (userId, subscriptionId, params) => {
  return API({
    url: `user/${userId}/subscription/${subscriptionId}`,
    method: "GET",
    params: params,
  });
};

// update subscription
export const updateSubscription = (userId, subscriptionId, payload) => {
  return API({
    url: `user/${userId}/subscription/${subscriptionId}`,
    method: "PUT",
    data: payload,
  });
};

// user/notification/5f9676360753983864b9074e

// send user login credentials
export const sendLoginCredentials = (userId, payload) => {
  return API({
    url: `/user/notification/${userId}`,
    method: "POST",
    data: payload,
  });
};
// send login credentials to registered user using Outsourcing
export const sendLoginCredentialsOutsourcing = (userId, payload) => {
  return API({
    url: `/user/registered-user/notification/${userId}`,
    method: "POST",
    data: payload,
  });
};

// Get User Experiences
export const fetchExperience = (userId) => {
  return API({
    url: `/user/${userId}/experience`,
    method: "GET",
  });
};

// Get User Single Education
export const fetchSingleExperience = (userId, expId) => {
  return API({
    url: `/user/${userId}/experience/${expId}`,
    method: "GET",
  });
};

// save User Experience
export const saveExperience = (userId, payload) => {
  return API({
    url: `/user/${userId}/experience`,
    method: "POST",
    data: payload,
  });
};

// update Prople Experience
export const updateExperience = (userId, expId, payload) => {
  return API({
    url: `/user/${userId}/experience/${expId}`,
    method: "PUT",
    data: payload,
  });
};

//Delete User Experience
export const deleteExperience = (userId, expId) => {
  return API({
    url: `/user/${userId}/experience/${expId}`,
    method: "DELETE",
  });
};
// Get User Education
export const fetchEducation = (userId) => {
  return API({
    url: `/user/${userId}/education`,
    method: "GET",
  });
};

// Get User Single Education
export const fetchSingleEducation = (userId, eduId) => {
  return API({
    url: `/user/${userId}/education/${eduId}`,
    method: "GET",
  });
};

//Delete User Experience
export const deleteEducation = (userId, expId) => {
  return API({
    url: `/user/${userId}/education/${expId}`,
    method: "DELETE",
  });
};

// save User Education
export const saveEducation = (userId, payload) => {
  return API({
    url: `/user/${userId}/education`,
    method: "POST",
    data: payload,
  });
};

// update user Education
export const updateEducation = (userId, expId, payload) => {
  return API({
    url: `/user/${userId}/education/${expId}`,
    method: "PUT",
    data: payload,
  });
};
// Get User Assessment
export const fetchUserAssessment = (userId) => {
  return API({
    url: `/user/${userId}/assessment`,
    method: "GET",
  });
};

// Get User's Single Assessment
export const fetchUserSingleAssessment = (userId, assessmentId) => {
  return API({
    url: `/user/${userId}/assessment/${assessmentId}`,
    method: "GET",
  });
};

//Delete User's Assessment
export const deleteUserAssessment = (userId, assessmentId) => {
  return API({
    url: `/user/${userId}/assessment/${assessmentId}`,
    method: "DELETE",
  });
};

// save User Assessment
export const saveUserAssessment = (userId, payload) => {
  return API({
    url: `/user/${userId}/assessment`,
    method: "POST",
    data: payload,
  });
};

// update user Assessment
export const updateUserAssessment = (userId, assessmentId, payload) => {
  return API({
    url: `/user/${userId}/assessment/${assessmentId}`,
    method: "PUT",
    data: payload,
  });
};

export const fetchUserAssessmentDocUrl = (fileName, originalFileName) => {
  return `${USER_URL}/user/document/${fileName}/${originalFileName}`;
};

// send user credentials
export const sendUserCredentials = (userId, payload) => {
  return API({
    url: `/user/login-credential-notification/${userId}`,
    method: "POST",
    data: payload,
  });
};

// all user
export const fetchAccessUser = (params = {}) => {
  let { filters = undefined, fields = undefined } = params;
  if (filters) params.filters = JSON.stringify(filters);
  if (fields) params.fields = JSON.stringify(fields);
  return API({
    url: "/user/access-users",
    method: "GET",
    params: params,
  });
};
