import React, { useEffect, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { enableRipple } from "@syncfusion/ej2-base";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { accessRightActionCheck, getProductVariant } from "../../../utilities";
import { fetchReportContent } from "../../../middleware/services/reportApi";
import { fetchConfig } from "../../../middleware/services/cmsApi";
import "./style.scss";
import { showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import TreeDataGrid from "../../components/treeDataGrid";
import { DropDownFilter, StringFilter } from "app/components/dataGrid/fliters";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { COMMON_ACCESS_ACTION } from "../../../constants";

enableRipple(true);
const ContentReport = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const productVariant = getProductVariant();
  const [dateRange, setDateRange] = useState(null);
  const [functionClick, setFunctionClick] = useState(0);
  const [startDate, setStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [reportFilter, setReportFilter] = useState("userReports");
  const [restrictedDays, setRestrictedDays] = useState(0);
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const [gridRef, setGridRef] = useState(null);
  const [allUsers, setAllUsers] = useState("YES");
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionExport = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EXPORT);
  const accessActionSearch = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SEARCH);

  const breadCrumbLinks = [{ linkUrl: "/report", linkName: aR.moduleName, linkActive: true }];
  const initialState = {
    selUsers: [],
  };

  const fnStartDate = async () => {
    let params = {};
    params.filters = [["configName", "eq", "client-activity-tracker"]];
    let restrictedDaycount;
    await fetchConfig(params).then((res) => {
      if (res.status === 200) {
        restrictedDaycount = res.data.data[0].configValue.restrictedDateCount;
        setRestrictedDays(res.data.data[0].configValue.restrictedDateCount);
      }
    });
    let dateFrom = new Date();
    let dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - (restrictedDaycount - 1));
    const dateStart = dateFrom
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    dateTo.setDate(dateTo.getDate());
    const dateEnd = dateTo
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    setStartDate(new Date(dateStart));
    setEndDate(new Date(dateEnd));
    setDateRange([dateFrom, dateTo]);
  };
  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
  });

  const headerSingleCom = (arg) => {
    return <span className="header-cell">{arg}</span>;
  };
  const headerDualCom = (argOne, argTwo) => {
    return (
      <div>
        <span className="header-cell">{argOne}</span>
        <span className="header-cell">{argTwo}</span>
      </div>
    );
  };

  // Dropdown Filter template
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "subscriptionProductType":
        sourceData = [
          { id: "Mobility", text: "Mobility" },
          { id: "Energy", text: "Energy" },
          { id: "Food & Nutrition", text: "Food & Nutrition" },
        ];
        break;
      case "subscriptionType":
        sourceData = [
          { id: "subscribed", text: "subscribed" },
          { id: "Trial", text: "Trial" },
        ];
        break;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  // specify column names for column fields in datGrid here
  let columnNames = {
    Email: "userEmail",
    Name: "userName",
    Company: "userCompany",
    userSubscription: "Subscription",
    "Relationship Manager": "userRmName",
    "Total Time Spent": "totalTimeSpent",
    "Average Active Time Per Visit": "avgTimePerVists",
    "Total Visits Count": "totalVistsCount",
    "Unique Visits Count": "uniqueVisitsCount",
    "Bookmarks Count": "bookmarkCount",
    "Site Searches": "siteSearchCount",
    "Facebook Share Count": "facebookShareCount",
    "Linkedin Share Count": "linkedinShareCount",
    "Twitter Share Count": "twitterShareCount",
    "Email Share Count": "emailShareCount",
    "No of Times Added to Presentation": "addPresentationCount",
    "No of Times Downloaded As PDF": "pdfDownloadCount",
    "No of Times Downloaded As PPT": "pptDownloadCount",
    "No of Request from Paywall": "paywallClickCount",
  };

  let columnFields = [
    {
      field: "userEmail",
      headerText: "Email",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      filterTemplate: StringFilter,
      allowFiltering: true,
      allowSorting: true,
      isFrozen: true,
      width: 170,
      // headerTemplate: () => headerSingleCom("Email"),
    },
    {
      field: "userName",
      headerText: "Name",
      type: "String",
      allowFiltering: true,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      isFrozen: true,
      width: 120,
      // headerTemplate: () => headerSingleCom("Name"),
    },
    {
      field: "userCompany",
      headerText: "Company",
      type: "String",
      allowFiltering: true,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 110,
      // headerTemplate: () => headerSingleCom("Company"),
    },
    {
      field: "userRmEmail",
      headerText: "Relationship Manager",
      type: "String",
      allowFiltering: true,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 170,
      // headerTemplate: () => headerDualCom("Relationship", "Manager"),
    },
    {
      field: "userSubscription",
      headerText: "Subscription",
      type: "String",
      allowFiltering: true,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 125,
      // headerTemplate: () => headerSingleCom("Subscription"),
    },
    {
      field: "subscriptionProductType",
      headerText: "Platform",
      type: "String",
      allowFiltering: true,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      filterTemplate: ItemFilter,
      filter: { operator: "contains" },
      allowSorting: true,
      width: 100,
      // headerTemplate: () => headerSingleCom("Subscription"),
    },
    {
      field: "subscriptionType",
      headerText: "User Type",
      type: "String",
      allowFiltering: true,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      filterTemplate: ItemFilter,
      filter: { operator: "contains" },
      allowSorting: true,
      width: 110,
      // headerTemplate: () => headerDualCom("Subscription", "Type"),
    },
    {
      field: "btbId",
      headerText: "BTB ID",
      type: "String",
      allowFiltering: true,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 90,
      // headerTemplate: () => headerSingleCom("Subscription"),
    },
    {
      field: "contentType",
      headerText: "Content Type",
      type: "String",
      allowFiltering: true,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 125,
      // headerTemplate: () => headerSingleCom("Content Type"),
    },
    {
      field: "totalTimeSpent",
      headerText: "Total Time Spent",
      type: "String",
      allowFiltering: false,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 100,
      headerTemplate: () => headerDualCom("Total Time", "Spent"),
    },
    {
      field: "avgTimePerVists",
      headerText: "Average Active Time Per Visit",
      type: "String",
      allowFiltering: false,
      textAlign: "Left",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      headerTextAlign: "Left",
      width: 100,
      headerTemplate: () => headerDualCom("Avg. Active", "Time Per Visit"),
    },
    {
      field: "daysSinceLastLogin",
      headerText: "Days Since Last Log In",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      headerTextAlign: "Center",
      width: 75,
      headerTemplate: () => headerDualCom("Days Since", "Last Log In"),
    },
    {
      field: "totalVistsCount",
      headerText: "Total Visits",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 60,
      headerTemplate: () => headerDualCom("Total", "Visits"),
    },
    {
      field: "helpdeskTotalTime",
      headerText: "Helpdesk Total Time",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 90,
      headerTemplate: () => headerDualCom("Helpdesk", "Total Time"),
    },
    {
      field: "helpDeskVisit",
      headerText: "Helpdesk Visits",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 75,
      headerTemplate: () => headerDualCom("Helpdesk", "Visits"),
    },
    {
      field: "addNewServiceRequestClick",
      headerText: "Tickets Created",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 65,
      headerTemplate: () => headerDualCom("Tickets", "Created"),
    },
    {
      field: "dashboardTotalTime",
      headerText: "Dashboard Total Time",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 90,
      headerTemplate: () => headerDualCom("Dashboard", "Total Time"),
    },
    {
      field: "dashboardVisit",
      headerText: "Dashboard Visits",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 70,
      headerTemplate: () => headerDualCom("Dashboard", "Visits"),
    },
    {
      field: "analystTotalTime",
      headerText: "BO Total Time",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 90,
      headerTemplate: () => headerDualCom("BO", "Total Time"),
    },
    {
      field: "businessObjectivesVisit",
      headerText: "BO Visits",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 55,
      headerTemplate: () => headerDualCom("BO", "Visits"),
    },
    {
      field: "askYourAnalystCount",
      headerText: "BO Created",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 55,
      headerTemplate: () => headerDualCom("BO", "Created"),
    },
    {
      field: "viewDeliverableClickCount",
      headerText: "Deliverable View",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 75,
      headerTemplate: () => headerDualCom("Deliverable", "View"),
    },
    {
      field: "likeCount",
      headerText: "Likes",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 55,
      headerTemplate: () => headerSingleCom("Likes"),
    },
    {
      field: "bookmarkCount",
      headerText: "Bookmarks",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 70,
      headerTemplate: () => headerSingleCom("Bookmarks"),
    },
    {
      field: "searchCount",
      headerText: "Site Searches",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 65,
      headerTemplate: () => headerDualCom("Site", "Searches"),
    },
    {
      field: "facebookShareCount",
      headerText: "Facebook Share",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 70,
      headerTemplate: () => headerDualCom("Facebook", "Share"),
    },
    {
      field: "linkedinShareCount",
      headerText: "Linkedin Share",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 65,
      headerTemplate: () => headerDualCom("Linkedin", "Share"),
    },
    {
      field: "twitterShareCount",
      headerText: "Twitter Share",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 65,
      headerTemplate: () => headerDualCom("Twitter", "Share"),
    },
    {
      field: "emailShareCount",
      headerText: "Email Share",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 60,
      headerTemplate: () => headerDualCom("Email", "Share"),
    },
    {
      field: "addPresentationCount",
      headerText: "Added to Presentation",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 80,
      headerTemplate: () => headerDualCom("Added to", "Presentation"),
    },
    {
      field: "pdfDownloadCount",
      headerText: "Downloaded As PDF",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 75,
      headerTemplate: () => headerDualCom("Download", "as PDF"),
    },
    {
      field: "pptDownloadCount",
      headerText: "Downloaded As PPT",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 75,
      headerTemplate: () => headerDualCom("Download", "as PPT"),
    },
    {
      field: "paywallClickCount",
      headerText: "Paywall Request",
      type: "String",
      allowFiltering: false,
      textAlign: "Center",
      headerTextAlign: "Center",
      showInColumnChooser: true,
      filterTemplate: StringFilter,
      allowSorting: true,
      width: 65,
      headerTemplate: () => headerDualCom("Paywall", "Request"),
    },
  ];

  const defaultSortingOptions = {
    columns: [{ field: "userName", direction: "Ascending" }],
  };

  const fetchReport = async (params = {}) => {
    params.productVariant = productVariant;
    params.reportFilter = reportFilter;
    params.allUsers = allUsers;
    if (params.sort) params.sort = params.sort.split(",").pop();
    if (dateRange) {
      let gte = { d: dateRange[0] };
      Date.prototype.toJSON = function () {
        return moment(this).format();
      };
      gte.d.toJSON = function () {
        return moment(this).format();
      };

      let lt = { d: moment(dateRange[1]) };
      lt.d.toJSON = function () {
        return moment(this).format();
      };

      params.dateRangeFrom = moment(gte.d).format("YYYY-MM-DD");
      params.dateRangeTo = moment(lt.d).format("YYYY-MM-DD");
    }
    let response = await fetchReportContent(params);
    return response;
  };
  const handleReset = () => {
    if (gridRef) {
      gridRef.current.clearFiltering();
      gridRef.current.clearSorting();
    }
    window.location.reload();
    formik.handleReset();
    setDateRange(null);
    fetchReport();
  };
  const getGridRef = (gridRefData) => {
    setGridRef(gridRefData);
  };
  const handleSearch = () => {
    let searchCount = gridRef.current.getColumnByField("searchCount");
    let helpdeskTotalTime = gridRef.current.getColumnByField("helpdeskTotalTime");
    let addNewServiceRequestClick = gridRef.current.getColumnByField("addNewServiceRequestClick");
    let analystTotalTime = gridRef.current.getColumnByField("analystTotalTime");
    let askYourAnalystCount = gridRef.current.getColumnByField("askYourAnalystCount");
    let daysSinceLastLogin = gridRef.current.getColumnByField("daysSinceLastLogin");
    let helpDeskVisit = gridRef.current.getColumnByField("helpDeskVisit");
    let dashboardTotalTime = gridRef.current.getColumnByField("dashboardTotalTime");
    let dashboardVisit = gridRef.current.getColumnByField("dashboardVisit");
    let businessObjectivesVisit = gridRef.current.getColumnByField("businessObjectivesVisit");
    if (reportFilter === "userReports") {
      gridRef.current.grid.showColumns(searchCount.headerText, "headerText");
      gridRef.current.grid.showColumns(helpdeskTotalTime.headerText, "headerText");
      gridRef.current.grid.showColumns(addNewServiceRequestClick.headerText, "headerText");
      gridRef.current.grid.showColumns(analystTotalTime.headerText, "headerText");
      gridRef.current.grid.showColumns(askYourAnalystCount.headerText, "headerText");
      gridRef.current.grid.showColumns(daysSinceLastLogin.headerText, "headerText");
      gridRef.current.grid.showColumns(helpDeskVisit.headerText, "headerText");
      gridRef.current.grid.showColumns(dashboardTotalTime.headerText, "headerText");
      gridRef.current.grid.showColumns(dashboardVisit.headerText, "headerText");
      gridRef.current.grid.showColumns(businessObjectivesVisit.headerText, "headerText");
    } else {
      gridRef.current.grid.hideColumns(searchCount.headerText, "headerText");
      gridRef.current.grid.hideColumns(helpdeskTotalTime.headerText, "headerText");
      gridRef.current.grid.hideColumns(addNewServiceRequestClick.headerText, "headerText");
      gridRef.current.grid.hideColumns(addNewServiceRequestClick.headerText, "headerText");
      gridRef.current.grid.hideColumns(analystTotalTime.headerText, "headerText");
      gridRef.current.grid.hideColumns(askYourAnalystCount.headerText, "headerText");
      gridRef.current.grid.hideColumns(daysSinceLastLogin.headerText, "headerText");
      gridRef.current.grid.hideColumns(helpDeskVisit.headerText, "headerText");
      gridRef.current.grid.hideColumns(dashboardTotalTime.headerText, "headerText");
      gridRef.current.grid.hideColumns(dashboardVisit.headerText, "headerText");
      gridRef.current.grid.hideColumns(businessObjectivesVisit.headerText, "headerText");
    }
    setAllUsers("NO");
    setFunctionClick(new Date());
  };

  // ** Row Data Bound For Tree grid
  const rowDataBound = (args) => {
    if (args.data.level == 0) {
      args.row.childNodes[0].style.fontWeight = "600";
    }
    (args.row?.childNodes[0]).style.fontSize = "14px";
    (args.row?.childNodes[0]).style.textAlign = "left";
  };

  const dataBound = () => {
    if (gridRef) {
      let headerText = gridRef.current.reactElement.querySelectorAll(".e-headertext");
      if (headerText) {
        headerText[0].innerHTML = reportFilter === "userReports" ? "Email" : "Content Title";
      }
    }
  };

  useEffect(() => {
    fnStartDate();
  }, []);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper  px-3">
      <div className="col-md-12 border rounded-bottom nopadding p-0 bg-white ">
        <div className="row ml-1">
          <div className="col-md-2 mt-4 grid-container-report-filters">
            <DateRangePickerComponent
              placeholder="Date Range"
              cssClass="e-outline"
              openOnFocus={true}
              showClearButton={false}
              format="dd MMM yyyy"
              separator="to"
              floatLabelType="Auto"
              startDate={startDate}
              endDate={endDate}
              onChange={(e) => {
                if (e.value) setDateRange(e.value);
              }}
              autoComplete="off"
              max={new Date()}
              maxDays={restrictedDays}
              strictMode={true}
            ></DateRangePickerComponent>
          </div>
          <div className="filter-radio-button">
            <RadioButtonComponent
              label="User Statistics"
              value="userReports"
              change={(e) => setReportFilter(e.value)}
              checked={reportFilter === "userReports"}
              name="peopleLocationType"
            />
            &nbsp; &nbsp; &nbsp; &nbsp;
            <RadioButtonComponent
              label="Content Statistics"
              value="contentReports"
              change={(e) => setReportFilter(e.value)}
              checked={reportFilter === "contentReports"}
              name="peopleLocationType"
            />
          </div>
          <div className="col-md-4">
            {accessActionSearch && (
              <button type="button" onClick={handleSearch} className="mt-4 search-btn px-4 btn btn-primary">
                Search
              </button>
            )}
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 mt-4 ml-2 ">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="gennx-grid-container gennx-grid-container-report gennx-treeGrid-container-report">
        {dateRange && (
          <TreeDataGrid
            gridTitle="Client Activity Report"
            fetch={fetchReport}
            columns={columnNames}
            columnFields={columnFields}
            childMapping="subContent"
            rowDataBound={rowDataBound}
            isPaging={false}
            freezeColumn={2}
            showResetFilter={true}
            deleteMany={false}
            functionClick={functionClick}
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            getGridRef={getGridRef}
            allowPagination={true}
            showExportButton={accessActionExport}
            dataBound={dataBound}
            defaultSortingOptions={defaultSortingOptions}
          />
        )}
      </div>
    </div>
  );
};

export default ContentReport;
