export default {
  name: "Name",
  save: "Save",
  saveAs: "Save as",
  back: "Back",
  loading: "Loading...",
  resetOperations: "Reset/delete all operations",
  changesLoseConfirmation: "All changes will be lost",
  changesLoseConfirmationHint: "Are you sure you want to reset?",
  cancel: "Cancel",
  continue: "Continue",
  undoTitle: "Undo last operation",
  redoTitle: "Redo last operation",
  showImageTitle: "Show original image",
  zoomInTitle: "Zoom in",
  zoomOutTitle: "Zoom out",
  toggleZoomMenuTitle: "Toggle zoom menu",
  cropTab: "Crop",
  finetuneTab: "Finetune",
  filtersTab: "Filters",
  watermarkTab: "Watermark",
  annotateTab: "Draw",
  resize: "Resize",
  resizeTab: "Resize",
  invalidImageError: "Invalid image provided.",
  uploadImageError: "Error while uploading the image.",
  areNotImages: "are not images",
  isNotImage: "is not image",
  toBeUploaded: "to be uploaded",
  cropTool: "Crop",
  resizeTool: "Select Size",
  original: "Original",
  custom: "Custom",
  square: "Square",
  landscape: "Landscape",
  portrait: "Portrait",
  ellipse: "Ellipse",
  classicTv: "Classic TV",
  cinemascope: "Cinemascope",
  arrowTool: "Arrow",
  blurTool: "Blur",
  brightnessTool: "Brightness",
  contrastTool: "Contrast",
  ellipseTool: "Ellipse",
  unFlipX: "Un-Flip X",
  flipX: "Flip X",
  unFlipY: "Un-Flip Y",
  flipY: "Flip Y",
  hsvTool: "HSV",
  hue: "Hue",
  saturation: "Saturation",
  value: "Value",
  imageTool: "Image",
  importing: "Importing...",
  addImage: "+ Add image",
  lineTool: "Line",
  penTool: "Pen",
  polygonTool: "Polygon",
  sides: "Sides",
  rectangleTool: "Rectangle",
  cornerRadius: "Corner Radius",
  resizeWidthTitle: "Width in pixels",
  resizeHeightTitle: "Height in pixels",
  toggleRatioLockTitle: "Toggle ratio lock",
  reset: "Reset",
  resetSize: "Reset to original image size",
  rotateTool: "Rotate",
  textTool: "Text",
  textSpacings: "Text spacings",
  textAlignment: "Text alignment",
  fontFamily: "Font family",
  size: "Size",
  letterSpacing: "Letter Spacing",
  lineHeight: "Line height",
  warmthTool: "Warmth",
  addWatermark: "+ Add watermark",
  addWatermarkTitle: "Choose the watermark type",
  uploadWatermark: "Add as image",
  addWatermarkAsText: "Add as text",
  padding: "Padding",
  shadow: "Shadow",
  horizontal: "Horizontal",
  vertical: "Vertical",
  blur: "Blur",
  opacity: "Opacity",
  position: "Position",
  stroke: "Stroke",
  saveAsModalLabel: "Save the image as",
  extension: "Extension",
  nameIsRequired: "Name is required.",
  quality: "Quality",
  imageDimensionsHoverTitle: "Saved image size (width x height)",
  cropSizeLowerThanResizedWarning: "Note, the selected crop area is lower than the applied resize which might cause quality decrease",
  cropSizeGreaterThanOriginal: "selcted crop area is greater than the original Image",
  actualSize: "Actual size (100%)",
  fitSize: "Fit size",
};
