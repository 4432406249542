import React, { useState, useEffect, useRef } from "react";
import TreeView from "../treeView/filterTaxonomy";
import { PRODUCT_VARIANT } from "../../../constants";
import { getProductVariant } from "../../../utilities";
import { useDispatch, useSelector } from "react-redux";
import { actionError, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import {
  fetchTaxonomyTechnology,
  fetchTaxonomyTrend,
  fetchTaxonomyIndustry,
  fetchTaxonomyValueChain,
  getObjectiveSgfTag,
  fetchTaxonomyMedia,
} from "../../../middleware/services/cmsApi";
import { fetchKias } from "../../../middleware/services/kiaApi";
import { hidePopup } from "../../../middleware/actions/popupAction";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import "./index.scss";
import Tabs from "../tabsComponent";
import { resetInternalGrid, setActiveTabs } from "../../../middleware/actions/gridAction";
import { IoIosArrowForward } from "react-icons/io";
import {
  setSelectedTechnology,
  setSelectedKia,
  setSelectedValueChain,
  setSelectedBOTaxonomy,
  setSelectedMediaTaxonomy,
  resetRedux,
  setSelectedTrendTaxonomy,
  setParentNodeName,
  setMaintainCheckedNodes,
} from "../../../middleware/actions/taxonomyFilterAction";
import { fetchObjective, fetchBOTaxonomy } from "../../../middleware/services/businessObjectiveApi";
import { setResetRedux } from "../../../middleware/actions/taxonomyTaggingAction";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

const FilterByTaxonomy = (props) => {
  const dispatch = useDispatch();
  let maskRefObj = useRef();
  const [taxonomy, setTaxonomy] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [businessObjectiveData, setBusinessObjectiveData] = useState([]);
  const [activeIndustry, setActiveIndustry] = useState(false);
  const [activeKeyIndex, setActiveKeyIndex] = useState(false);
  const [trendId, setTrendId] = useState("");
  const [trendData, setTrendData] = useState([]);
  const [sgfData, setSgfData] = useState([]);
  const [technologyData, setTechnologyData] = useState([]);
  const [kiaData, setKiaData] = useState([]);
  const [valueChainData, setValueChainData] = useState([]);
  // for technology
  const [selectedParentNode, setSelectedParentNode] = useState([]);

  const [checkedList, setCheckedList] = useState([]);
  const tabRef = useRef();
  const productVariant = getProductVariant();
  const [mediaTaxonomy, setMediaTaxonomy] = useState([]);
  const [bOTaxonomy, setBOTaxonomy] = useState([]);
  //test useState
  const [filterCheckNodes, setFilterCheckNodes] = useState([]);
  // redux
  const taxonomyFilterRedux = useSelector((state) => state.taxonomyFilterState);
  let checkedItem = [
    ...taxonomyFilterRedux.selectedTechnology,
    ...taxonomyFilterRedux.selectedValueChain,
    ...taxonomyFilterRedux.selectedKia,
    ...taxonomyFilterRedux.selectedBOTaxonomy,
    ...taxonomyFilterRedux.selectedMediaTaxonomy,
    ...taxonomyFilterRedux.selectedTrendTaxonomy,
    ...taxonomyFilterRedux.parentNodeName,
  ];
  const [valueChainTaxonomy, setValueChainTaxonomy] = useState([]);
  const [storeCheckedId, setStoreCheckedId] = useState([]);
  const [objectiveData, setObjectiveData] = useState([]);

  const [reRender, setReRender] = useState(false);

  let checkedNodes =
    props.selectedNode?.length > 0
      ? props.selectedNode.map((element) => {
          return element.id;
        })
      : [];
  let selectedTaxonomyNodes = checkedNodes;
  if (props.enableBulkTaxonomy) {
    selectedTaxonomyNodes = props.checked;
  }

  const [selectedNodeId, setSelectedNodeId] = useState(checkedNodes);

  useEffect(() => {
    setActiveIndustry(false);
    setActiveKeyIndex(false);
  }, [props.show, selectedIndex]);

  useEffect(() => {
    setReRender(false);
    setTaxonomy([]);
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      if (props.moduleName == "News" && productVariant === PRODUCT_VARIANT.COGNITION) {
        selectedIndex == 0 && fetchMediaTaxonomy();
        selectedIndex == 1 && _fetchObjective();
      } else if (props.moduleName == "Video" && productVariant === PRODUCT_VARIANT.WHATNEXT) {
        selectedIndex == 0 && fetchTechnologyTaxonomy();
        selectedIndex == 1 && fetchKiaTaxonomy();
        selectedIndex == 2 && _fetchObjective();
      } else if (productVariant == PRODUCT_VARIANT.WHATNEXT && props.moduleName == "Survey") {
        selectedIndex == 0 && fetchKiaTaxonomy();
      } else {
        if (selectedIndex == 0) {
          fetchTechnologyTaxonomy();
        }
        if (selectedIndex == 1 || props.moduleName == "Survey") {
          fetchKiaTaxonomy();
        }
        if (selectedIndex == 2) {
          if (props.moduleName == "Video" && productVariant === PRODUCT_VARIANT.COGNITION) {
          } else {
            fetchValueChainTaxonomy();
          }
        }
        if (selectedIndex == 3) {
          _fetchObjective();
        }
      }
    } else if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        fetchTrendTaxonomy();
        fetchSgfTaxonomy();
      }
      if (selectedIndex == 1) {
        _fetchObjective();
      }
    }
    if (Object.keys(props.checked)?.length > 0) {
      let checkedItems = [];
      if (
        props.checked?.media?.length > 0 ||
        props.checked?.technology?.length > 0 ||
        props.checked?.kia?.length > 0 ||
        props.checked?.valueChain?.length > 0 ||
        props.checked?.businessObjective?.length > 0 ||
        props.checked?.trend?.length > 0
      ) {
        checkedItems = [
          ...props.checked?.technology,
          ...props.checked?.kia,
          ...props.checked?.valueChain,
          ...props.checked?.media,
          ...props.checked?.businessObjective,
          ...props.checked?.trend,
        ];
      } else {
        checkedItems = props.checked;
        // props.checked.map((d) => {
        //     if (d?.id) {
        //         return checkedItems.push(d.id)
        //     } else {
        //         checkedItems = props.checked
        //     }
        // })
      }
      setStoreCheckedId(checkedItems);
    }
    if (props.enableBulkTaxonomy) {
      props.fetchTaxonomyTagging(selectedIndex);
    }
  }, [selectedIndex, reRender]);

  /*
       @Description : handle action button (cancel and submit)
   */
  const buttons = [
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat filterCancelBtn mr-2",
        isPrimary: false,
      },
      click: () => {
        props.closeHandler();
        if (props.enableBulkTaxonomy) {
          dispatch(setResetRedux());
        }
      },
    },
    {
      buttonModel: { content: "Submit", cssClass: "e-flat filterSubmitBtn" },
      click: () => {
        handleSubmit();
      },
    },
  ];
  const closeDialog = () => {
    dispatch(hidePopup());
  };

  /*
       @Description : handle submit for selected node
   */
  const handleSubmit = () => {
    if (props.enableBulkTaxonomy) {
      dispatch(resetRedux());
      props.handleSubmit(selectedIndex);
    } else {
      let mergedSelectedTaxonomy = [...checkedItem, ...selectedParentNode];
      // setCheckedId(mergedSelectedTaxonomy)
      let selectedTaxonomy = [];
      let checkedTechnology = [];
      let checkedValueChain = [];
      let checkedMedia = [];
      let checkedKia = [];
      let checkedTrend = [];
      let checkedBO = [];
      let checkedBOTaxonomy = [];

      mergedSelectedTaxonomy.map((v) => {
        trendData?.length > 0 &&
          trendData.filter((d) => {
            let trendObj = {};
            if (v == d.id) {
              trendObj["id"] = d.id;
              trendObj["name"] = d.trendName;
              trendObj["taxonomy"] = "trend";
              selectedTaxonomy.push(trendObj);
              checkedTrend.push(trendObj);
            }
          });
        sgfData?.length > 0 &&
          sgfData.filter((d) => {
            let sgfObj = {};
            if (v == d.id) {
              sgfObj["id"] = d.id;
              sgfObj["name"] = d.sgfName;
              sgfObj["taxonomy"] = "trend";
              selectedTaxonomy.push(sgfObj);
            }
          });
        technologyData?.length > 0 &&
          technologyData.filter((d) => {
            let technologyObj = {};
            if (v == d.id) {
              technologyObj["id"] = d.id;
              technologyObj["name"] = d.dtName;
              technologyObj["taxonomy"] = "technology";
              selectedTaxonomy.push(technologyObj);
              checkedTechnology.push(technologyObj);
            }
          });

        kiaData?.length > 0 &&
          kiaData.filter((d) => {
            let kiaObj = {};
            let KiaParentObj = {};
            if (v == d.id) {
              kiaObj["id"] = d.id;
              kiaObj["name"] = d.kiaName;
              kiaObj["taxonomy"] = "kia";
              selectedTaxonomy.push(kiaObj);
              checkedKia.push(kiaObj);
            }
            if (v == d.kiaIndustryTag[0].industryId) {
              KiaParentObj["id"] = d.kiaIndustryTag[0].industryId;
              KiaParentObj["name"] = d.kiaIndustryTag[0].industryName;
              KiaParentObj["taxonomy"] = "kia";
              selectedTaxonomy.push(KiaParentObj);
              checkedKia.push(KiaParentObj);
            }
          });
        valueChainData?.length > 0 &&
          valueChainData.filter((d) => {
            let valueChainObj = {};
            if (v == d.id) {
              valueChainObj["id"] = d.id;
              valueChainObj["name"] = d.industryName;
              valueChainObj["taxonomy"] = "valueChain";
              selectedTaxonomy.push(valueChainObj);
              checkedValueChain.push(valueChainObj);
            }
          });
        valueChainTaxonomy?.length > 0 &&
          valueChainTaxonomy.filter((d) => {
            let valueChainObj = {};
            if (v == d.id) {
              valueChainObj["id"] = d.id;
              valueChainObj["name"] = d.valueChainName;
              valueChainObj["taxonomy"] = "valueChain";
              selectedTaxonomy.push(valueChainObj);
              checkedValueChain.push(valueChainObj);
            }
            d.transformingTechnologies?.length > 0 &&
              d.transformingTechnologies.map((res) => {
                if (v == res.techId) {
                  valueChainObj["id"] = res.techId;
                  valueChainObj["name"] = res.techName;
                  valueChainObj["taxonomy"] = "valueChain";
                  selectedTaxonomy.push(valueChainObj);
                  checkedValueChain.push(valueChainObj);
                }
              });
          });
        mediaTaxonomy?.length > 0 &&
          mediaTaxonomy.filter((d) => {
            let mediaTaxonomyObj = {};
            if (v == d.id) {
              mediaTaxonomyObj["id"] = d.id;
              mediaTaxonomyObj["name"] = d.taxonomyName;
              mediaTaxonomyObj["taxonomy"] = "media";
              selectedTaxonomy.push(mediaTaxonomyObj);
              checkedMedia.push(mediaTaxonomyObj);
            }
          });
        bOTaxonomy?.length > 0 &&
          bOTaxonomy.filter((d) => {
            let taxonomyObj = {};
            if (v == d.id) {
              taxonomyObj["id"] = d.id;
              taxonomyObj["name"] = d.taxonomyName;
              taxonomyObj["taxonomy"] = "businessObjective";
              selectedTaxonomy.push(taxonomyObj);
              checkedBOTaxonomy.push(taxonomyObj);
            }
          });
        objectiveData?.length > 0 &&
          objectiveData.filter((d) => {
            let taxonomyObj = {};
            if (v == d.id) {
              taxonomyObj["id"] = d.id;
              taxonomyObj["name"] = d.objectiveTitle;
              taxonomyObj["taxonomy"] = "businessObjective";
              selectedTaxonomy.push(taxonomyObj);
              checkedBO.push(taxonomyObj);
            }
          });
        taxonomyFilterRedux.checkedNodes.length > 0 &&
          taxonomyFilterRedux.checkedNodes.filter((d) => {
            if (v == d.id) {
              selectedTaxonomy.push(d);
            }
          });
      });

      let mergeKiaNodes = [];
      mergeKiaNodes = checkedKia.length > 0 && [...new Map(checkedKia.map((item) => [item["id"], item])).values()];
      mergeKiaNodes =
        mergeKiaNodes.length > 0
          ? mergeKiaNodes.map((element) => {
              return element.id;
            })
          : [];
      let mergeTechnologyNodes = [];
      mergeTechnologyNodes =
        checkedTechnology.length > 0
          ? checkedTechnology.map((element) => {
              return element.id;
            })
          : [];
      let mergeTrendNodes =
        checkedTrend.length > 0
          ? checkedTrend.map((element) => {
              return element.id;
            })
          : [];
      let mergeMediaNodes =
        checkedMedia.length > 0
          ? checkedMedia.map((element) => {
              return element.id;
            })
          : [];
      let mergeBOTaxonomyNodes =
        checkedBOTaxonomy.length > 0
          ? checkedBOTaxonomy.map((element) => {
              return element.id;
            })
          : [];
      let mergeBONodes =
        checkedBO.length > 0
          ? checkedBO.map((element) => {
              return element.id;
            })
          : [];
      let mergeValueChainNodes =
        checkedValueChain.length > 0
          ? checkedValueChain.map((element) => {
              return element.id;
            })
          : [];
      let uniqueTaxonomyObject = [...new Map(selectedTaxonomy.map((item) => [item["id"], item])).values()];
      dispatch(setMaintainCheckedNodes(uniqueTaxonomyObject));

      let obj = {};
      obj["trend"] = [...new Set([...taxonomyFilterRedux.selectedTrendTaxonomy, ...mergeTrendNodes])];
      obj["technology"] = [...new Set([...taxonomyFilterRedux.selectedTechnology, ...mergeTechnologyNodes])];
      obj["valueChain"] = [...new Set([...taxonomyFilterRedux.selectedValueChain, ...mergeValueChainNodes])];
      obj["kia"] = [...new Set([...taxonomyFilterRedux.selectedKia, ...mergeKiaNodes])];
      obj["media"] = [...new Set([...taxonomyFilterRedux.selectedMediaTaxonomy, ...mergeMediaNodes])];
      obj["businessObjective"] = [...new Set([...mergeBONodes])];
      obj["boTaxonomy"] = [...new Set([...taxonomyFilterRedux.selectedBOTaxonomy, ...mergeBOTaxonomyNodes])];

      if (uniqueTaxonomyObject.length > 0) {
        props.handleSubmit(uniqueTaxonomyObject, obj);
      } else {
        //  please select a taxonomy from the list
        dispatch(actionError("Please select a taxonomy from the list."));
      }
    }
  };

  /*
       @Description : Fetch all Trend taxonomy 
   */
  const fetchTrendTaxonomy = () => {
    dispatch(showLoader());
    try {
      if (trendData?.length < 1) {
        const response = fetchTaxonomyTrend({
          fields: ["id", "trendName", "trendParentId", "trendParentName"],
          filters: ["trendParentId", "eq", 0],
          limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
        });
        response
          .then((res) => {
            if (res.status == 200) {
              setTrendData(res.data.data);
              const treeJosnArray = buildSgfTaxonomyTree(res.data.data);
              setTaxonomy(treeJosnArray);
              dispatch(hideLoader());
            } else {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            }
          })
          .catch((e) => {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
          });
      } else {
        const treeJosnArray = buildSgfTaxonomyTree(trendData);
        setTaxonomy(treeJosnArray);
        dispatch(hideLoader());
      }
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
        @Description : Fetch all SGF taxonomy 
    */
  const fetchSgfTaxonomy = async () => {
    dispatch(showLoader());
    try {
      const response = getObjectiveSgfTag({
        fields: ["sgfName"],
        filters: ["sgfEnable", "eq", "1"],
        limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
      });
      response
        .then(async (response) => {
          if (response.status == 200) {
            setSgfData(response.data.data);
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
        @Description : Build tree data
        data : sgf taxonomy data and trend taxonomy data
    */
  const buildSgfTaxonomyTree = (value) => {
    let result = [];
    value.filter((res, k) => {
      let taxonomyTeeData = {};

      if (res.trendParentId == 0) {
        taxonomyTeeData["id"] = res.id;
        taxonomyTeeData["name"] = res.trendName;
        taxonomyTeeData["hasChild"] = true;
        result.push(taxonomyTeeData);
      }
    });

    return result;
  };
  /*
       @Description : Fetch all Kia taxonomy 
   */
  const fetchKiaTaxonomy = () => {
    dispatch(showLoader());
    try {
      if (kiaData?.length < 1) {
        const response = fetchKias({
          fields: ["kiaName", "kiaDescription", "kiaIndustryTag"],
          sort: "kiaName:asc",
          limit: 1000,
        });
        response
          .then((res) => {
            if (res.status == 200) {
              setKiaData(res.data.data);
              const treeJosnArray = buildKiaTaxonomyTree(res.data.data);
              setTaxonomy(treeJosnArray);
              dispatch(hideLoader());
            } else {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            }
          })
          .catch((e) => {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
          });
      } else {
        const treeJosnArray = buildKiaTaxonomyTree(kiaData);
        setTaxonomy(treeJosnArray);
        dispatch(hideLoader());
      }
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
      @Description : Build tree data
      data : Kia taxonomy data
    */
  const buildKiaTaxonomyTree = (value) => {
    let result = [];
    value.filter((res, k) => {
      let taxonomyTeeData = {};
      if (res.kiaIndustryTag.length > 0) {
        taxonomyTeeData["id"] = res.kiaIndustryTag[0].industryId;
        taxonomyTeeData["name"] = res.kiaIndustryTag[0].industryName;
        // taxonomyTeeData["hasChild"] = true
        result.push(taxonomyTeeData);
      }
    });
    let mergedData = [...new Map(result.map((item) => [item["id"], item])).values()];

    return mergedData;
  };

  /*
         @Description : Fetch all Technology taxonomy 
    */
  const fetchTechnologyTaxonomy = () => {
    dispatch(showLoader());
    try {
      if (technologyData?.length < 1) {
        const response = fetchTaxonomyTechnology({
          fields: ["id", "dtName", "dtParentId", "dtParentName"],
          filters: [["dtEnable", "eq", 1]],
          sort: "dtName:asc",
          limit: 5000,
        });
        response
          .then((res) => {
            if (res.status == 200) {
              // props.setFilterData(checkedItems)
              setTechnologyData(res.data.data);
              const treeJosnArray = buildTechnologyTree(res.data.data);
              setTaxonomy(treeJosnArray);
              dispatch(hideLoader());
            } else {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            }
          })
          .catch((e) => {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
          });
      } else {
        const treeJosnArray = buildTechnologyTree(technologyData);
        setTaxonomy(treeJosnArray);
        dispatch(hideLoader());
      }
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
        @Description : Build tree data
        data : technology taxonomy data
    */
  const buildTechnologyTree = (res) => {
    dispatch(showLoader());
    const result = [];
    res.map((element) => {
      if (element.dtParentId == 0) {
        let taxonomyTeeData = {};
        taxonomyTeeData["name"] = element.dtName;
        taxonomyTeeData["id"] = element.id;
        result.push(taxonomyTeeData);
      }
    });
    dispatch(hideLoader());
    return result;
  };
  /*
        @Description : Fetch all Value chain taxonomy 
    */
  const fetchValueChainTaxonomy = () => {
    dispatch(showLoader());
    try {
      if (props.enableBulkTaxonomy) {
        if (props.valueChainData?.length > 0) {
          setTaxonomy(props.valueChainData);
          fetchValueChainTagging();
          dispatch(hideLoader());
        } else {
          dispatch(hideLoader());
          setTaxonomy("Application Industry is not tagged in technology. Application industry is required to add value chain.");
        }
      } else {
        if (valueChainData?.length < 1) {
          const response = fetchTaxonomyIndustry({
            sort: "dtName:asc",
            limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
          });
          response
            .then((res) => {
              if (res.status == 200) {
                setValueChainData(res.data.data);
                const treeJosnArray = buildValueChainTree(res.data.data);
                setTaxonomy(treeJosnArray);
                fetchValueChainTagging();
                dispatch(hideLoader());
              } else {
                dispatch(hideLoader());
                dispatch(actionError("Something Went Wrong...!"));
              }
            })
            .catch((e) => {
              // dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            });
        } else {
          const treeJosnArray = buildValueChainTree(valueChainData);
          setTaxonomy(treeJosnArray);
          dispatch(hideLoader());
        }
      }
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
        @Description : Build tree data
        data : Value chain taxonomy data
    */
  const buildValueChainTree = (res) => {
    const result = [];
    res.map((element) => {
      let taxonomyTeeData = {};
      taxonomyTeeData["id"] = element.id;
      taxonomyTeeData["name"] = element.industryName;
      result.push(taxonomyTeeData);
    });
    return result;
  };
  /*
       @Description : Fetch all Value chain taxonomy 
   */
  const fetchValueChainTagging = () => {
    dispatch(showLoader());
    try {
      const response = fetchTaxonomyValueChain({
        sort: "kiaName:asc",
        limit: 1000,
      });
      response
        .then((res) => {
          if (res.status == 200) {
            setValueChainTaxonomy(res.data.data);
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
      @Description : Fetch all Media taxonomy 
    */
  const fetchMediaTaxonomy = () => {
    dispatch(showLoader());
    try {
      if (mediaTaxonomy?.length < 1) {
        const response = fetchTaxonomyMedia({
          sort: "dtName:asc",
          limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
        });
        response
          .then((res) => {
            if (res.status == 200) {
              setMediaTaxonomy(res.data.data);
              const treeJosnArray = buildMediaTree(res.data.data);
              setTaxonomy(treeJosnArray);
              dispatch(hideLoader());
            } else {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            }
          })
          .catch((e) => {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
          });
      } else {
        const treeJosnArray = buildMediaTree(mediaTaxonomy);
        setTaxonomy(treeJosnArray);
        dispatch(hideLoader());
      }
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
       @Description : Build tree data
       data : Media taxonomy data
   */
  const buildMediaTree = (data) => {
    const result = [];
    data.map((element) => {
      let taxonomyTeeData = {};
      if (element.taxonomyParentId == 0) {
        taxonomyTeeData["id"] = element.id;
        taxonomyTeeData["name"] = element.taxonomyName;
        result.push(taxonomyTeeData);
      }
    });
    return result;
  };
  /*
        @Description : Fetch all Business objective taxonomy 
    */
  const _fetchObjective = () => {
    dispatch(showLoader());
    try {
      if (objectiveData?.length < 1) {
        fetchObjective({
          fields: ["id", "objectiveTitle"],
          filters: [
            ["productVariant.isLive", "eq", "YES"],
            ["objectiveIsDeleted", "eq", "NO"],
            ["productVariant.productName", "eq", productVariant],
          ],
          sort: "objectiveTitle:asc",
        })
          .then((res) => {
            const data = [...res.data.data];
            if (data.length > 0) {
              setObjectiveData(data);
              fetchObjTaxonomy();
              const selectedNodeData = buildSelectedTreeJson(data);
              setTaxonomy(selectedNodeData);
              setBusinessObjectiveData(selectedNodeData);
              dispatch(hideLoader());
            } else {
              dispatch(hideLoader());
              setTaxonomy("No business objective taxonomy available.");
            }
          })
          .catch((err) => {
            dispatch(hideLoader());
            console.error("err _fetchObjective :: ", err);
          });
      } else {
        const treeJosnArray = buildSelectedTreeJson(objectiveData);
        setTaxonomy(treeJosnArray);
        dispatch(hideLoader());
      }
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };
  /*
        @Description : Fetch all Business objective taxonomy 
    */
  const fetchObjTaxonomy = () => {
    dispatch(showLoader());
    fetchBOTaxonomy({ sort: "taxonomyName:asc", limit: 2000 })
      .then((res) => {
        let { data = [] } = res.data;
        if (data.length) {
          setBOTaxonomy(data);
        }
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchTaxonomy :: ", err);
      });
  };
  /*
       @Description : Build tree data
       data : Business objective taxonomy data
   */
  const buildSelectedTreeJson = (data) => {
    const result = [];
    data.map((element) => {
      let taxonomyTeeData = {};
      taxonomyTeeData["id"] = element.id;
      taxonomyTeeData["name"] = element.objectiveTitle;
      result.push(taxonomyTeeData);
    });
    return result;
  };

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    tabRef.current.enableTab(1, true);
    tabRef.current.enableTab(2, true);
    tabRef.current.enableTab(3, true);
    tabRef.current.select(0);
  };
  const tabSelected = useSelector((state) => state.gridState.internalGridState.tabSelected);
  /*
        @Description : handle selected technology
        data : checked node data
    */
  const handleSelectedNodes = (data) => {
    handleParentTaxonomy(data.event.target.id);
    // setState(e.checked)
    let resultArray = [];
    if (data.checked) {
      resultArray = checkedList?.filter((CheckedId) => CheckedId !== data.event.target.id);
      resultArray.push(data.event.target.id);
    } else {
      resultArray = checkedList?.filter((CheckedId) => CheckedId !== data.event.target.id);
    }

    setCheckedList(resultArray);

    setSelectedParentNode(resultArray);
  };
  const handleTaxonomyTagging = (args) => {
    if (props.enableBulkTaxonomy) {
      props.handleTaxonomyTagging(args, selectedIndex);
    }
  };
  /*
        @Description : handle checked and uncked for parent taxonomy node
        id: taxonomy id
    */
  const handleParentTaxonomy = (id) => {
    let taxonomyCheck = taxonomyFilterRedux.parentNodeName;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setParentNodeName(taxonomyCheck));
      return;
    } else {
      dispatch(setParentNodeName([...taxonomyFilterRedux.parentNodeName, id]));
    }
  };
  /*
       @Description : handle checked and unchecked for technology taxonomy
       id: taxonomy id
   */
  const handleTechnologyTaxonomy = (id) => {
    let taxonomyCheck = taxonomyFilterRedux.selectedTechnology;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedTechnology(taxonomyCheck));
      return;
    } else {
      dispatch(setSelectedTechnology([...taxonomyFilterRedux.selectedTechnology, id]));
    }
  };
  /*
      @Description : handle checked and unchecked for trend taxonomy
      id: taxonomy id
    */
  const handleTrendTaxonomy = (taxonomyId) => {
    let id = taxonomyId.split("-")[1];
    let taxonomyCheck = taxonomyFilterRedux.selectedTrendTaxonomy;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedTrendTaxonomy(taxonomyCheck));
      return;
    } else {
      dispatch(setSelectedTrendTaxonomy([...taxonomyFilterRedux.selectedTrendTaxonomy, id]));
    }
  };
  /*
      @Description : handle checked and unchecked for business objective taxonomy
      id: taxonomy id
    */
  const handleBOTaxonomy = (id) => {
    let taxonomyCheck = taxonomyFilterRedux.selectedBOTaxonomy;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedBOTaxonomy(taxonomyCheck));
      return;
    } else {
      dispatch(setSelectedBOTaxonomy([...taxonomyFilterRedux.selectedBOTaxonomy, id]));
    }
  };
  /*
      @Description : handle checked and unchecked for Kia taxonomy
      id: taxonomy id
    */
  const handleKiaTaxonomy = (id) => {
    let taxonomyCheck = taxonomyFilterRedux.selectedKia;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedKia(taxonomyCheck));
      return;
    } else {
      dispatch(setSelectedKia([...taxonomyFilterRedux.selectedKia, id]));
    }
  };
  /*
      @Description : handle checked and unchecked for value chain taxonomy
      id: taxonomy id
    */
  const handleValueChainTaxonomy = (id) => {
    let taxonomyCheck = taxonomyFilterRedux.selectedValueChain;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedValueChain(taxonomyCheck));
      return;
    } else {
      dispatch(setSelectedValueChain([...taxonomyFilterRedux.selectedValueChain, id]));
    }
  };
  /*
      @Description : handle checked and unchecked for media taxonomy
      id: taxonomy id
    */
  const handleMediaTaxonomy = (id) => {
    let taxonomyCheck = taxonomyFilterRedux.selectedMediaTaxonomy;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setSelectedMediaTaxonomy(taxonomyCheck));
      return;
    } else {
      dispatch(setSelectedMediaTaxonomy([...taxonomyFilterRedux.selectedMediaTaxonomy, id]));
    }
  };

  const handleCheckedNode = (val) => {
    if (productVariant == PRODUCT_VARIANT.INSIDER) {
      selectedIndex == 0 && handleTrendTaxonomy(val.data[0].id);
      selectedIndex == 1 && handleBOTaxonomy(val.data[0].id);
    } else if (productVariant == PRODUCT_VARIANT.COGNITION && props.moduleName == "News") {
      selectedIndex == 0 && handleMediaTaxonomy(val.data[0].id);
      selectedIndex == 1 && handleBOTaxonomy(val.data[0].id);
    } else if (productVariant == PRODUCT_VARIANT.WHATNEXT && props.moduleName == "Survey") {
      selectedIndex == 0 && handleKiaTaxonomy(val.data[0].id);
    } else if (productVariant == PRODUCT_VARIANT.WHATNEXT && props.moduleName == "Video") {
      selectedIndex == 0 && handleTechnologyTaxonomy(val.data[0].id);
      selectedIndex == 1 && handleKiaTaxonomy(val.data[0].id);
      selectedIndex == 2 && handleBOTaxonomy(val.data[0].id);
    } else {
      selectedIndex == 0 && handleTechnologyTaxonomy(val.data[0].id);
      // setSelectedId(val)
      selectedIndex == 1 && handleKiaTaxonomy(val.data[0].id);
      selectedIndex == 2 && handleValueChainTaxonomy(val.data[0].id);
      selectedIndex == 3 && handleBOTaxonomy(val.data[0].id);
    }
  };

  const handleBOSearch = () => {
    if (productVariant == PRODUCT_VARIANT.INSIDER && selectedIndex == 1) {
      return true;
    } else if (productVariant == PRODUCT_VARIANT.COGNITION && props.moduleName == "News" && selectedIndex == 1) {
      return true;
    } else if (productVariant == PRODUCT_VARIANT.WHATNEXT && props.moduleName == "Survey") {
      return false;
    } else if (productVariant == PRODUCT_VARIANT.WHATNEXT && props.moduleName == "Video" && selectedIndex == 2) {
      return true;
    } else if (selectedIndex == 3) {
      return true;
    }
    return false;
  };

  const createdSearch = () => {
    maskRefObj && maskRefObj.current.addIcon("prepend", "e-icons e-search");
  };

  function searchBONodes() {
    let _text = maskRefObj.current.element.value.trim().toLowerCase();
    if (_text == "") return setTaxonomy(businessObjectiveData);
    const nodes = JSON.parse(JSON.stringify(businessObjectiveData));
    let filterBOData = nodes?.filter((item) => {
      if (item.name.toLowerCase().includes(_text)) return item;
    });
    setTaxonomy(filterBOData);
  }

  const handleClearSearch = () => {
    setTaxonomy(businessObjectiveData);
    maskRefObj.current.element.value = "";
    setActiveIndustry(false);
  };

  return (
    <>
      <div id="dialog-target" style={{ position: "absolute !important" }}>
        <div className={props.customArrowClass}></div>
        <DialogComponent
          id="defaultDialog"
          target="#dialog-target"
          buttons={buttons}
          close={() => closeDialog()}
          cssClass={props.customDialogBoxClass}
          isModal={true}
        >
          {
            <Tabs
              tabsName={props.tabName}
              tabRef={tabRef}
              created={created}
              handleClick={() => {
                setSelectedIndex(tabRef.current.selectedItem);
                if (tabSelected != tabRef.current.selectedItem) {
                  dispatch(resetInternalGrid());
                }
                dispatch(setActiveTabs(tabRef.current.selectedItem));
              }}
              tabHeaderCss={{ background: "transparent" }}
              cssClass="tabCustomCss"
            >
              {typeof taxonomy == "string" ? (
                <div className="pt-4" style={{ textAlign: "center" }}>
                  {taxonomy}
                </div>
              ) : (
                <div>
                  {
                    <div className="taxonomy-modal">
                      <div>
                        {handleBOSearch() && (
                          <div className="searchDiv">
                            <TextBoxComponent
                              placeholder="Search"
                              ref={maskRefObj}
                              input={searchBONodes}
                              cssClass="searchBoxBO"
                              created={createdSearch}
                            />
                            <div onClick={handleClearSearch} className={maskRefObj.current?.element.value ? "active-clear-btn" : "clear-btn"}>
                              Clear
                            </div>
                          </div>
                        )}
                        <div className={props.enableBulkTaxonomy && props.listClass ? props.listClass : "select-lists"}>
                          {taxonomy?.length > 0 ? (
                            taxonomy.map((val, key) => {
                              return (
                                <>
                                  <span
                                    className="node-list"
                                    style={{
                                      backgroundColor: activeIndustry && activeKeyIndex == key ? "#F2F2F2" : "",
                                    }}
                                    onClick={() => {
                                      setTrendId(val.id);
                                      setActiveIndustry(true);
                                      setActiveKeyIndex(key);
                                      props.enableBulkTaxonomy && props.setBulkCheckedNode(selectedIndex);
                                    }}
                                  >
                                    <CheckBoxComponent
                                      id={val.id}
                                      name={val.name}
                                      value={val.id}
                                      // label={val.name}
                                      change={(e) => {
                                        handleSelectedNodes(e);
                                      }}
                                      checked={
                                        taxonomyFilterRedux.parentNodeName.includes(val.id) ||
                                        selectedNodeId.includes(val.id) ||
                                        selectedTaxonomyNodes.includes(val.id)
                                          ? true
                                          : false
                                      }
                                      // indeterminate={(selectedNodeId.includes(val.id) && !(selectedTaxonomyNodes.includes(val.id))) ? true : false}
                                    />
                                    <div className="taxonomy-name">
                                      <sapn style={{ fontSize: "14px" }}>{val.name}</sapn>
                                    </div>
                                    <span
                                      className="float-right "
                                      style={{
                                        paddingLeft: "16px",
                                        width: "15%",
                                      }}
                                    >
                                      <IoIosArrowForward size={18} role="button" color={"rgb(46 40 41)"} />
                                    </span>
                                  </span>
                                </>
                              );
                            })
                          ) : (
                            <div style={{ margin: "16px", fontSize: "14px" }}>No results found</div>
                          )}
                        </div>
                      </div>
                      {activeIndustry ? (
                        <div className="taxonomy-tree">
                          <TreeView
                            handleSubmit={(val) => handleCheckedNode(val)}
                            selectedIndex={selectedIndex}
                            isTrendTaxonomy={productVariant === PRODUCT_VARIANT.INSIDER ? true : false}
                            isTechnologyTaxonomy={productVariant !== PRODUCT_VARIANT.INSIDER ? true : false}
                            isMediaTaxonomy={productVariant === PRODUCT_VARIANT.COGNITION && props.moduleName == "News" ? true : false}
                            contentId={trendId}
                            checked={props.checked}
                            setFilterCheckNodes={setFilterCheckNodes}
                            filterCheckNodes={filterCheckNodes}
                            boTagging={props.boTagging}
                            moduleName={props.moduleName}
                            checkedItem={props.enableBulkTaxonomy ? props.checked : checkedItem}
                            enableBulkTaxonomy={props.enableBulkTaxonomy}
                            handleTaxonomyTagging={handleTaxonomyTagging}
                            setSelectedNodeId={setSelectedNodeId}
                            cssClass={props.cssClass}
                            customSearch={props.customSearch}
                            storeCheckedId={storeCheckedId}
                          />
                        </div>
                      ) : (
                        <div className="pt-4" style={{ textAlign: "center", marginLeft: "120px" }}>
                          Please select node from left panel to view taxonomy
                        </div>
                      )}
                    </div>
                  }
                </div>
              )}
            </Tabs>
          }

          <div className="footer-buttons">
            <button
              className={checkedList?.length > 0 || checkedItem?.length > 0 || props.checked?.length > 0 ? "active-clear-all-btn" : `filterClearAll`}
              onClick={() => {
                if (props.enableBulkTaxonomy) {
                  dispatch(setResetRedux());
                  props.resetAllTaxonnomy(selectedIndex);
                } else {
                  if (checkedList?.length > 0 || checkedItem?.length > 0 || props.checked?.length > 0) {
                    setSelectedParentNode([]);
                    props.setFilterData([]);
                    // setSelectedId([])
                    props.setSelectedId([]);
                    props.setSelectedNode([]);
                    setCheckedList([]);
                    setReRender(true);
                    dispatch(resetRedux());
                    setSelectedNodeId([]);
                  }
                }
              }}
            >
              {" "}
              {props.enableBulkTaxonomy ? "Reset All" : "Clear All"}
            </button>
          </div>
        </DialogComponent>
      </div>
    </>
  );
};
export default FilterByTaxonomy;
