/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : External User Registration Details
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 02/Jul/2021
	Author						: Pankaj Kulshreshtha
================================================================
*/

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import Accordion from "../../../components/accordion";
import { fetchSingleUser } from "../../../../middleware/services/userApi";
import * as Survey from "survey-react";
// theme colors
const mainColor = "#fa4f5f";
const mainHoverColor = "#fa4f5f"; // black
const textColor = "#4a4a4a";
const headerColor = "#343a40";
const bodyContainerBackgroundColor = "#f8f8f8"; //gray
const RegistrationDtls = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const editUser = props.path === "/external-user/add" ? false : true;
  const userId = editUser ? props.userId : undefined;

  // specify breadcrumb here
  const breadCrumbLinksAddUser = [
    { linkUrl: "/external-user", linkName: aR.moduleName, linkActive: false },
    { linkUrl: "/external-user/add", linkName: "Add User", linkActive: true },
  ];
  const breadCrumbLinksEditUser = [
    { linkUrl: "/external-user", linkName: aR.moduleName, linkActive: false },
    { linkUrl: `/external-user/${userId}/edit#profile`, linkName: "Edit User", linkActive: true },
  ];

  const [formData, setFormData] = useState(undefined);
  const [formJson, setFormJson] = useState(undefined);

  /**
   * @description fetch single user detail
   * @param {String} userId
   */
  const fetchUserDetail = (userId) => {
    dispatch(showLoader());
    fetchSingleUser(userId)
      .then((res) => {
        const userDetail = { ...res.data.data };

        if (userDetail.userForm) {
          let defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
          defaultThemeColorsSurvey["$main-color"] = mainColor;
          defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
          defaultThemeColorsSurvey["$text-color"] = textColor;
          defaultThemeColorsSurvey["$header-color"] = headerColor;
          // defaultThemeColorsSurvey["$header-background-color"] = headerBackgroundColor;
          defaultThemeColorsSurvey["$body-container-background-color"] = bodyContainerBackgroundColor;
          Survey.StylesManager.applyTheme("default");

          setFormJson(userDetail.userForm.formJson);
          setFormData(userDetail.userForm.formJsonSubmitted);
          if (document.getElementsByClassName("sv_complete_btn")[0]) document.getElementsByClassName("sv_complete_btn")[0].style.display = "none";
        }

        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch User Details Failed";
        dispatch(actionError(errMsg));
      });
  };

  useEffect(() => {
    const breadCrumbLinks = editUser ? [...breadCrumbLinksEditUser] : [...breadCrumbLinksAddUser];
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/external-user"));
    editUser && fetchUserDetail(userId);

    return () => {
      dispatch(setBreadCrumb());
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, editUser]);

  return (
    <>
      <div id="content-box">
        {userId && formData ? (
          <Accordion heading="User Registration Details" step={1} stepCompleted={props.stepCompleted} activeState={true}>
            <div className="row p-3">
              <Survey.Survey json={formJson} showQuestionNumbers={"off"} data={formData} showCompletedPage={false} />
            </div>
          </Accordion>
        ) : (
          <div className="col-md-12 text-center p-3 ">
            <h3>Registration details is not available</h3>
          </div>
        )}
      </div>
      <div className="form-submit-box ml-2">
        <div className="float-left col-md-6 text-left">
          {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
            <span className="pl-4" style={{ "margin-left": "-3em" }}>
              <span className="span-link">
                <button
                  type="button"
                  className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                  onClick={() => {
                    (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                      (props.selectedIndex < 3 && props.setCurrentTab(props.selectedIndex - 1));
                  }}
                >
                  Previous
                </button>
              </span>
            </span>
          ) : null}
          {userId !== "" && props.selectedIndex > -1 && props.selectedIndex < 3 ? (
            <span className="pl-4" style={{ "margin-left": "0" }}>
              <span className="span-link">
                <button
                  type="button"
                  className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                  onClick={() => {
                    props.selectedIndex < 3 && props.setCurrentTab(props.selectedIndex + 1);
                  }}
                >
                  Next
                </button>
              </span>
            </span>
          ) : null}
        </div>
        <div className="float-right col-md-6 text-right">
          <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3" onClick={() => history.push("/external-user")}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default RegistrationDtls;
