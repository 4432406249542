import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchSingleObjective } from "../../../../../../middleware/services/businessObjectiveApi";
import { getObjectiveSgfTag } from "../../../../../../middleware/services/cmsApi";
import { showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import SortableTree from "../../../../../components/treeView/sortableTree";

const Categorization = (props) => {
  const dispatch = useDispatch();
  const objectiveId = props.objectiveId;
  const [cat, setCat] = useState([]);
  const [checkedCat, setCheckedCat] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [treeCollapse, setTreeCollapse] = useState(true);
  const [selectedNodes, setSelectedNodes] = useState([]);

  useEffect(() => {
    getAllCat();
  }, []);

  let checkedNodes = [];
  const getAllCat = () => {
    if (objectiveId != "") {
      dispatch(showLoader());
      const response = fetchSingleObjective(objectiveId, {
        fields: ["objective_industry_sgf_taxonomy"],
      });
      response
        .then((response) => {
          dispatch(hideLoader());
          if (response.status == 200) {
            if (response.data.data.objectiveIndustrySgfTaxonomy) {
              if (Object.values(response.data.data.objectiveIndustrySgfTaxonomy).length) {
                const selectedDtData = [];
                Object.values(response.data.data.objectiveIndustrySgfTaxonomy).forEach((selectedDt) => {
                  let select = selectedDt.taxonomyId;
                  selectedDtData.push(select);
                });
                checkedNodes = selectedDtData;
                setCheckedCat(selectedDtData);
              }
            }
            fetchNewsCatWithProductVariant();
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    } else {
      fetchNewsCatWithProductVariant();
    }
  };

  const getCat = async (params) => {
    try {
      const response = await getObjectiveSgfTag(params);
      if (response.status == 200) {
        setCat(response.data.data);
        const treeJsonData = buildTreeJson(response.data.data);
        setTreeData(treeJsonData);
      } else {
        dispatch(actionError("Something Went Wrong...!"));
      }
      dispatch(hideLoader());
    } catch (e) {
      dispatch(hideLoader());
      dispatch(actionError("Something Went Wrong...!"));
    }
  };

  const fetchNewsCatWithProductVariant = (params = {}) => {
    params.filters = ["sgfEnable", "eq", "1"];
    return getCat(params);
  };
  const handleSaveBridge = (selectedTech = "", checkedNonDt) => {
    let result = [];

    if (checkedNonDt.length) {
      checkedNonDt.forEach((catValue) => {
        if (!catValue.children) {
          let myArray = catValue.value.split("_");
          let objectData = {
            taxonomyName: myArray[0],
            taxonomyId: myArray[1],
            taxonomyParentId: myArray[2],
          };
          result.push(objectData);
        }
      });
    }
    props.handleSaveCategorization(selectedTech, result);
  };
  const buildTreeJson = (data) => {
    let treeArray = [];
    data.map((element) => {
      let treeObject = {};
      let grandParentId = element.sector[0].sectorName;
      let findObject = undefined;
      findObject =
        treeArray.length > 0 &&
        treeArray.find((sector) => {
          return sector.label === grandParentId;
        });
      if (!findObject && element?.trend?.length > 0) {
        treeObject["id"] = element.sector[0].sectorId;
        treeObject["label"] = element.sector[0].sectorName;
        treeObject["value"] = element.sector[0].sectorId;
        treeObject["children"] = [];
        treeObject["level"] = 2;

        let trendData = element.trend.map((v) => {
          return {
            id: v.trendId,
            label: v.trendName,
            value: `${v.trendName}_${v.trendId}_${element.id}`,
            isChecked: checkedNodes.includes(v.trendId) && true,
            level: 4,
            parentId: element.id,
          };
        });
        let childObject = {
          id: element.id,
          children: trendData,
          label: element.sgfName,
          value: element.id,
          level: 3,
          parentId: element.sector[0].sectorId,
        };
        treeObject.children.push(childObject);
        treeArray.push(treeObject);
      } else {
        if (findObject?.children?.length && element?.trend?.length) {
          let trendData = element.trend.map((v) => {
            return {
              id: v.trendId,
              label: v.trendName,
              value: `${v.trendName}_${v.trendId}_${element.id}`,
              isChecked: checkedNodes.includes(v.trendId) && true,
              level: 4,
              parentId: element.id,
            };
          });
          let childObject = {
            id: element.id,
            children: trendData,
            label: element.sgfName,
            value: element.id,
            level: 3,
            parentId: element.sector[0].sectorId,
          };
          findObject.children.push(childObject);
        }
      }
    });
    return treeArray;
  };
  useEffect(() => {
    if (props.saveObjective) {
      handleSaveBridge("", selectedNodes);
    }
  }, [props.saveObjective]);
  return (
    <div>
      <SortableTree
        treeData={treeData}
        treeCollapse={treeCollapse}
        checked={checkedCat}
        onClick={(e) => {
          let checkedNodeId = e.map((v) => v);
          setSelectedNodes(checkedNodeId);
        }}
        hieghtClass="business-objective-taxonomy-tree-container-wrapper"
        autoCheck={true}
        parentId={"parentId"}
      />
      {treeCollapse ? (
        <div className="cursor-pointer expand-collapse-btn">
          <span className="btn  px-3 pt-1 pb-1 taxonomyCustom" onClick={() => setTreeCollapse(false)}>
            Expand All
          </span>
        </div>
      ) : (
        <div className="cursor-pointer expand-collapse-btn">
          <span className="btn  px-3 pt-1 pb-1 taxonomyCustom" onClick={() => setTreeCollapse(true)}>
            Collapse All
          </span>
        </div>
      )}
    </div>
  );
};

export default Categorization;
