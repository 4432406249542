/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Value Chain taxonomy Tree Component
     Require Props :
        handleSave : Save Tree Data
        contentId : Content Id [news id | company id]
        fetchContentData : Fetch content taxonomy
        handleSaveValueChain : Save Value chain data
        contentType : Content Type [News | Company | Product]
 ---------------------------------------------------------------------------------
    Creation Details
    Date Created				: 20/Aug/2020
    Author						: Aditya Tijare
================================================================
*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import noTaxonomyImage from "../../../../assets/images/no-taxonomy.svg";
import { fetchTaxonomyValueChain } from "../../../../middleware/services/cmsApi";
import { showLoader, hideLoader, actionError, showMessage } from "../../../../middleware/actions/utilityAction";
import SortableTree from "../sortableTree";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { Modal } from "react-bootstrap";

const ValueChain = (props) => {
  const dispatch = useDispatch();

  const [tagging, setTagging] = useState([]);
  const [taggedIndustryData, setTaggedIndustryData] = useState([]);
  const [contentValueChain, setContentValueChain] = useState([]);
  const [checkedValueChainIds, setCheckedValueChainIds] = useState([]);
  const [checkedValueChainIdsForSelectedIndustry, setcheckedValueChainIdsForSelectedIndustry] = useState([]);
  const [transformingValueChain, setTransformingValueChain] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [saveTreeData, setSaveTreeData] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [leftTreeCollapse, setLeftTreeCollapse] = useState(true);
  const [rightTreeCollapse, setRightTreeCollapse] = useState(true);
  const [leftTreeChecked, setLeftTreeChecked] = useState([]);
  const [rightTreeChecked, setRightTreeChecked] = useState([]);
  const commonState = useSelector((state) => state.treeState);
  const [transformingIds, setTransformingIds] = useState([]);
  const [contentTaggedIds, setContentTaggedIds] = useState([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  /*
        @Description : Use for retrieve content value chain tagged data
    */
  const contentConfig = {
    news: {
      industryTagFieldName: "newsIndustryTag",
      valueChainTagFieldName: "newsIndustryValuechainTags",
    },
    video: {
      industryTagFieldName: "videoIndustryTag",
      valueChainTagFieldName: "videoIndustryValuechainTags",
    },
    company: {
      industryTagFieldName: "companyIndustryTag",
      valueChainTagFieldName: "companyIndustryValuechainTags",
    },
    product: {
      industryTagFieldName: "productIndustryTag",
      valueChainTagFieldName: "productIndustryValuechainTags",
    },
    deliverable: {
      industryTagFieldName: "deliverableIndustryTag",
      valueChainTagFieldName: "deliverableIndustryValuechainTags",
    },
    contentBlock: {
      industryTagFieldName: "contentBlockIndustryTag",
      valueChainTagFieldName: "contentBlockIndustryValuechainTags",
    },
  };

  /*
        @Description : Build tree data for tagged values
        @param : 
        data : taxonomy data
        parentId :technology id
    */
  const buildSelectedTreeJson = (industryData, valueChainData) => {
    let result = [];
    industryData.forEach((element) => {
      element["id"] = element.industryId;
      element["value"] = element.industryId;
      element["label"] = element.industryName;
      element["showCheckbox"] = false;
      element["level"] = 2;
    });
    valueChainData.forEach((element) => {
      element["value"] = element.valueChainId;
      element["label"] = element.valueChainName;
      element["id"] = element.valueChainId;
      element["showCheckbox"] = false;
      if (element["transformingTechnologies"]?.length) {
        element["children"] = element["transformingTechnologies"].map((element2) => {
          element2["value"] = element2.techId;
          element2["label"] = element2.techName;
          element2["showCheckbox"] = false;
          element2["id"] = element2.techId;
          return element2;
        });
      }
    });
    result = industryData
      .map((v, i) => {
        v["children"] = [];
        valueChainData.map((b) => {
          if (b.industryName === v.industryName) {
            v["children"].push(b);
          }
        });
        if (v?.children?.length) return v;
      })
      .filter((b) => {
        return b;
      });

    return result;
  };

  /*
        @Description : fetch value chain for respective industry
    */
  function fetchValueChainTagging() {
    dispatch(showLoader());
    props
      .fetchContentData(props.contentId, {
        fields: ["id", contentConfig[props.contentType].industryTagFieldName, contentConfig[props.contentType].valueChainTagFieldName],
      })
      .then((response) => {
        const valueChainTaggedData = [];
        const valueChainTaggedIds = [];
        let industryData = [];
        let valueChain = [];
        let checkedChainTaggedIds = [];
        let checkedTransformingIds = [];
        if (response.data.data && response.data.data[contentConfig[props.contentType].industryTagFieldName]) {
          industryData = response.data.data[contentConfig[props.contentType].industryTagFieldName];
          valueChain = response.data.data[contentConfig[props.contentType].valueChainTagFieldName];
          response.data.data[contentConfig[props.contentType].industryTagFieldName].forEach((element) => {
            if (!valueChainTaggedIds.includes(element.industryId)) {
              valueChainTaggedIds.push(element.industryId);
              valueChainTaggedData.push({
                id: element.industryId,
                name: element.industryName,
              });
            }
          });
        }
        if (response.data.data && response.data.data[contentConfig[props.contentType].valueChainTagFieldName]) {
          checkedChainTaggedIds = [];
          response.data.data[contentConfig[props.contentType].valueChainTagFieldName].forEach((element) => {
            checkedChainTaggedIds.push(element.valueChainId);
            if (element["transformingTechnologies"].length) {
              element["transformingTechnologies"].forEach((transformingTechnologies) => {
                checkedTransformingIds.push(transformingTechnologies.techId);
              });
            }
          });
        }
        const selectedTreeJsonData = buildSelectedTreeJson(industryData, valueChain);
        setTagging(selectedTreeJsonData);
        setLeftTreeCollapse(true);

        setCheckedValueChainIds(checkedChainTaggedIds);
        setTransformingIds(checkedTransformingIds);

        setContentTaggedIds(checkedChainTaggedIds.concat(checkedTransformingIds));
        valueChainTaggedData && setTaggedIndustryData(valueChainTaggedData);

        if (valueChainTaggedData && valueChainTaggedData.length) {
          if (selectedIndustry == "") setSelectedIndustry(valueChainTaggedData[0].id);
          setContentValueChain(valueChainTaggedData);
        } else {
          dispatch(hideLoader());
        }
        setLeftTreeChecked([]);
        setReRender(true);
      })
      .catch((err) => {
        console.error(err, "error");
        dispatch(hideLoader());
        dispatch(actionError("Something went wrong...!"));
      });
  }
  /*
        @Description : fetch taxonomy for respective value chain
    */
  const fetchRespectiveTaxonomyValueChain = (selectedIndustry) => {
    if (selectedIndustry) {
      dispatch(showLoader());
      const valueChainData = fetchTaxonomyValueChain({
        filters: [["industryId", "eq", selectedIndustry]],
        limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
      });

      valueChainData
        .then((response) => {
          dispatch(hideLoader());
          let checked = [];
          response.data.data.forEach((element) => {
            if (contentTaggedIds.includes(element.id)) {
              element["isChecked"] = true;
              checked.push(element.id);
              if (element?.transformingTechnologies) {
                element.transformingTechnologies.forEach((v) => {
                  if (contentTaggedIds.includes(v.techId)) {
                    v["isChecked"] = true;
                    checked.push(v.techId);
                  }
                });
              }
            }
          });
          setRightTreeChecked(checked);
          const treeJsonData = buildTreeJson(response.data.data);
          setTreeData(treeJsonData);
          setRightTreeCollapse(true);
          dispatch(hideLoader());
        })
        .catch((err) => {
          console.error(err, "error");
          dispatch(hideLoader());
          dispatch(actionError("Something went wrong...!"));
        });
    }
  };
  /*
        @Description : Build tree data valid for up to two level tree
        @param : data : Value chain for respective industry
    */
  const buildTreeJson = (data) => {
    const result = [];
    const selectedIndustryValueChain = [];
    const transformingValue = [];
    data.forEach((element) => {
      const treeData = {};
      treeData["id"] = element.id;
      treeData["value"] = element.id;
      treeData["label"] = element.valueChainName;
      treeData["level"] = 2;
      treeData["isChecked"] = element?.isChecked ? element.isChecked : false;
      if (commonState.valueChainRightTree.includes(element.id)) {
        selectedIndustryValueChain.push(element.id);
      }
      if (element["transformingTechnologies"]?.length) {
        const children = [];
        element["transformingTechnologies"].forEach((transformingTechnologies) => {
          if (commonState.valueChainRightTree.includes(transformingTechnologies.techId)) {
            selectedIndustryValueChain.push(transformingTechnologies.techId);
          }
          transformingValue.push(transformingTechnologies.techId);
          const childData = {};
          childData["id"] = transformingTechnologies.techId;
          childData["value"] = transformingTechnologies.techId;
          childData["label"] = transformingTechnologies.techName;
          childData["parentId"] = element.id;
          childData["isChecked"] = transformingTechnologies?.isChecked ? transformingTechnologies.isChecked : false;
          children.push(childData);
        });
        treeData["children"] = children;
      } else {
        treeData["children"] = null;
      }

      result.push(treeData);
    });
    setTransformingValueChain(transformingValue);
    setcheckedValueChainIdsForSelectedIndustry(selectedIndustryValueChain);
    return result;
  };
  /*
        @Description : Make bridge to send callback
        @param :
        selectedTech : selected value chain
        checkValues : Checked taxonomy
    */
  const saveValueChainBridge = async (selectedTech, checkedValue) => {
    if (checkedValue?.length) {
      const valueChainIds = [];
      const transformingTechnologiesIds = [];
      checkedValue = checkedValue.filter((item) => item);
      checkedValue = [...new Set(checkedValue)];
      checkedValue.forEach((checkValue) => {
        if (transformingValueChain.includes(checkValue)) {
          transformingTechnologiesIds.push(checkValue);
        } else {
          valueChainIds.push(checkValue);
        }
      });
      const checkedValueObject = {
        industryId: selectedIndustry,
        valueChainIds: valueChainIds,
        transformingTechnologiesIds: transformingTechnologiesIds,
      };
      await props.handleSaveValueChain(selectedTech, checkedValueObject, fetchValueChainTagging);
    } else {
      dispatch(showMessage("Please select taxonomy..!"));
    }
  };

  const deleteValueChain = () => {
    if (props.isDelete) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            const valueChainIds = [];
            const transformingTechnologiesIds = [];
            let checkedValue = leftTreeChecked.filter((item) => item);
            checkedValue = [...new Set(checkedValue)];
            checkedValue.forEach((checkValue) => {
              if (transformingIds.includes(checkValue)) {
                transformingTechnologiesIds.push(checkValue);
              } else {
                valueChainIds.push(checkValue);
              }
            });
            const checkedValueObject = {
              valueChainIds: valueChainIds,
              transformingTechnologiesIds: transformingTechnologiesIds,
            };
            props.deleteValueChain(checkedValueObject, fetchValueChainTagging);
          },
          okText: "Delete",
          cancelText: "Cancel",
          content: "Do you want to remove taxonomy tagging ? ",
          title: "dialogAlertCss",
        })
      );
    } else {
      setShowModalDelete(true);
    }
  };

  const leftTreeOnChecked = (checkedLeft) => {
    var result = [];
    checkedLeft.forEach(function (a, i) {
      result.push(a.id);
      if (Array.isArray(a.children)) {
        result = result.concat(leftTreeOnChecked(a.children));
      }
    });
    setLeftTreeChecked(result);
    return result;
  };

  useEffect(() => {
    if (reRender) {
      fetchRespectiveTaxonomyValueChain(selectedIndustry);
      setReRender(false);
    }
  }, [contentTaggedIds, reRender]);

  useEffect(() => {
    if (saveTreeData) {
      setSaveTreeData(false);
    }
  }, [saveTreeData]);

  useEffect(() => {
    fetchValueChainTagging();
  }, []);

  useEffect(() => {
    if (props.saveTreeData && props.selectedTab == 2) {
      saveValueChainBridge(selectedIndustry, rightTreeChecked);
    }
  }, [props.saveTreeData]);

  useEffect(() => {
    if (props?.submitStatus && props.selectedTab == 2) {
      if (rightTreeChecked.length) {
        const valueChainIds = [];
        const transformingTechnologiesIds = [];
        let checkedValue = rightTreeChecked.filter((item) => item);
        checkedValue = [...new Set(checkedValue)];
        checkedValue.forEach((checkValue) => {
          if (transformingValueChain.includes(checkValue)) {
            transformingTechnologiesIds.push(checkValue);
          } else {
            valueChainIds.push(checkValue);
          }
        });
        const checkedValueObject = {
          industryId: selectedIndustry,
          valueChainIds: valueChainIds,
          transformingTechnologiesIds: transformingTechnologiesIds,
        };
        props.handleSubmit(selectedIndustry, checkedValueObject, "valueChain", fetchValueChainTagging);
      } else {
        dispatch(showMessage("Please select Taxonomy...!"));
        props.setSubmitStatus(false);
      }
    }
  }, [props.submitStatus]);

  return (
    <>
      {contentValueChain?.length ? (
        <div
          className="row"
          style={{
            height: "80vh",
            marginLeft: "0px",
            marginRight: "0px",
            paddingRight: "0.7em",
          }}
        >
          <div className="col-md-6 TaxonomyNewTabLeftContent">
            <div className="row" style={{ paddingTop: "2.2vh", paddingBottom: "7px" }}>
              <div>
                <h6 className="font-weight-bold headline">Value Chain</h6>
              </div>
              {tagging?.length > 0 ? (
                <div className="ml-auto">
                  {props?.accessPermissionTaxonomyDelete && (
                    <button
                      type="button"
                      className={`btn  px-3  mr-3 ${leftTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                      onClick={() => deleteValueChain()}
                    >
                      Delete
                    </button>
                  )}

                  {leftTreeCollapse ? (
                    <span
                      className="expandButton btn px-3 mr-3"
                      style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                      onClick={() => setLeftTreeCollapse(false)}
                    >
                      Expand All{" "}
                    </span>
                  ) : (
                    <span
                      className="expandButton btn px-3 mr-3"
                      style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                      onClick={() => setLeftTreeCollapse(true)}
                    >
                      Collapse All
                    </span>
                  )}
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div style={{ paddingTop: "1em" }}>
                  {tagging && tagging.length > 0 ? (
                    <SortableTree
                      treeData={tagging}
                      parentId={"industryId"}
                      treeCollapse={leftTreeCollapse}
                      onClick={leftTreeOnChecked}
                      hieghtClass={"custom-tagging-tree-container-wrapper"}
                    />
                  ) : (
                    <div className="text-center">
                      <img src={noTaxonomyImage} alt="No Tagging" width="250" height="250" />
                      <p>
                        Select tags to create <span>Value Chain Taxonomy</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 TaxonomyNewTabRightContent">
            {treeData?.length > 0 ? (
              <div className="row" style={{ justifyContent: "left", paddingLeft: "0.4em" }}>
                <div className="col-md-4" style={{ marginLeft: "1em", paddingTop: "2.2vh" }}>
                  <DropDownListComponent
                    value={selectedIndustry}
                    change={(value) => {
                      setSelectedIndustry(value.itemData?.id);
                      fetchRespectiveTaxonomyValueChain(value.itemData?.id);
                    }}
                    cssClass={"customCss e-outline"}
                    floatLabelType="Auto"
                    popupHeight="250px"
                    dataSource={[...contentValueChain]}
                    fields={{ text: "name", value: "id" }}
                    placeholder="Select Industry"
                  />
                </div>
              </div>
            ) : (
              <div className="mt-5 mx-auto font-weight-bold headline">
                Application Industry is not tagged in technology. Application industry is required to add value chain."
              </div>
            )}
            <div className="row" style={{ paddingTop: "1em" }}>
              <div className="col-md-12">
                {treeData?.length ? (
                  <div className="float-right">
                    {props?.accessPermissionTaxonomy && (
                      <button
                        type="button"
                        className={`btn  px-3  mr-3 ${rightTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                        onClick={() => {
                          if (props?.isDelete) {
                            props.setSaveTreeData("save");
                          } else {
                            setShowModalAdd(true);
                          }
                        }}
                      >
                        Add
                      </button>
                    )}
                    {rightTreeCollapse ? (
                      <span
                        className="expandButton btn px-3 mr-3"
                        style={{
                          border: "1px solid #F94F5E",
                          color: "#F94F5E",
                        }}
                        onClick={() => setRightTreeCollapse(false)}
                      >
                        Expand All{" "}
                      </span>
                    ) : (
                      <span
                        className="expandButton btn px-3 mr-3"
                        style={{
                          border: "1px solid #F94F5E",
                          color: "#F94F5E",
                        }}
                        onClick={() => setRightTreeCollapse(true)}
                      >
                        Collapse All
                      </span>
                    )}
                  </div>
                ) : null}
                {treeData.length > 0 ? (
                  <SortableTree
                    treeData={treeData}
                    parentId={"parentId"}
                    treeCollapse={rightTreeCollapse}
                    onClick={(checkedRight) => {
                      let ids = [];
                      checkedRight.map((item) => {
                        ids.push(item.id);
                        if (item?.parentId) ids.push(item.parentId);
                      });
                      setRightTreeChecked(ids);
                    }}
                    hieghtClass={"custom-tagging-tree-container-wrapper"}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white">
          <div className="font-weight-bold d-flex valueChainMessage">
            Application Industry is not tagged in technology. Application industry is required to add value chain."
          </div>
        </div>
      )}
      <Modal show={showModalAdd} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              Since this company has products, you can not add taxonomy on company level. You need to add taxonomy in product.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalAdd(!showModalAdd)}>
            Ok
          </button>
        </div>
      </Modal>

      <Modal show={showModalDelete} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              Since this company has products, you can not delete taxonomy from company. You need to delete taxonomy from product.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalDelete(!showModalDelete)}>
            Ok
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ValueChain;
