import React, { useEffect, useRef, useState } from "react";
import FilerobotImageEditor, { TABS, TOOLS } from "../react-filerobot-image-editor";
import "./fileImageEditor.css";
import { imageBase64, saveImageToLocal } from "middleware/services/contentBlockApi";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader, actionError } from "../../../middleware/actions/utilityAction";
import { presetsItems } from "./imageEditorConfig.jsx";
import { showAlertBox } from "middleware/actions/alertBoxAction";

const ImageEditor = (props) => {
  const {
    triggerSave,
    setTriggerSave,
    imgSrc,
    SetImgSrc,
    imageHeight,
    imageWidth,
    imageId,
    setToggleBuild,
    setShowModal,
    uploadTrigger,
    setUploadTrigger,
  } = props;
  const mainRef = useRef(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  async function handleSaveImage(context, canvas, image, editedImgWidth, editedImgHeight, imageData) {
    try {
      dispatch(showLoader());
      // Resize the canvas to match the image dimensions
      canvas.width = editedImgWidth;
      canvas.height = editedImgHeight;

      // Draw the image onto the canvas
      context.drawImage(image, 0, 0, editedImgWidth, editedImgHeight);
      const modifiedBase64Data = canvas.toDataURL(imageData.mimeType, 1.0);

      let saveImageData = new FormData();
      saveImageData.append("base64", modifiedBase64Data);

      let response = await saveImageToLocal(saveImageData);
      if (response) {
        updateImageElement(response.data.data);
      }
      dispatch(hideLoader());
      setToggleBuild(true);
      setShowModal();
    } catch (error) {
      dispatch(hideLoader());
      dispatch(actionError(error?.data?.message || "Unable to save image"));
    }
  }

  async function saveImage() {
    try {
      const { imageData, designState } = mainRef.current({}, false, false);
      // Create an image element
      const image = new Image();
      // Set the image source
      image.src = imageData.imageBase64;
      // Create a canvas element
      const editedImgWidth = designState.resize.width || imageData.width;
      const editedImgHeight = designState.resize.height || imageData.height;
      // Update the width and height
      image.width = editedImgWidth;
      image.height = editedImgHeight;
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      // Load the image
      image.onload = async () => {
        // Perform validation on the width and height as needed
        if (editedImgWidth < 100 || editedImgHeight < 100)
          return dispatch(actionError("Minimum image size should be width 100px and height 100px or more."));
        if (Number(imageHeight?.split("px")[0]) < editedImgHeight || Number(imageWidth?.split("px")[0]) < editedImgWidth) {
          return dispatch(
            actionError(
              `Selected image size is ${editedImgWidth}px X ${editedImgHeight}px (Width X Height). Recommended image size is ${imageWidth} X ${imageHeight} (Width X Height).`
            )
          );
        }
        if (Number(imageHeight?.split("px")[0]) > editedImgHeight || Number(imageWidth?.split("px")[0]) > editedImgWidth) {
          return dispatch(
            showAlertBox({
              okCallback: async () => handleSaveImage(context, canvas, image, editedImgWidth, editedImgHeight, imageData),
              okText: "Save",
              cancelText: "Cancel",
              content: "Selected image size is less than the recommended image size. Are you sure, you want to proceed?",
              title: "dialogAlertCssApprove",
            })
          );
        }
        handleSaveImage(context, canvas, image, editedImgWidth, editedImgHeight, imageData);
      };
    } catch (err) {
      dispatch(hideLoader());
      dispatch(actionError(err?.data?.message || "Unable to save image"));
    }
  }

  function updateImageElement(dataURL) {
    const contentBuilderContainer = document.querySelector(".content-builder-container");
    if (contentBuilderContainer) {
      const images = contentBuilderContainer.querySelectorAll("img");
      for (const image of images) {
        if (image.id === imageId) {
          image.src = dataURL;
          break;
        }
      }
    }
  }

  const getFileDimensions = (file, callback) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const image = new Image();
      image.onload = () => {
        callback(image.width, image.height);
      };
      image.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  async function handleInputChange() {
    if (!fileInputRef.current.files.length) return;
    const fileType = fileInputRef.current.files[0].type;
    const validExtensions = ["image/png", "image/jpeg", "image/jpg"];
    if (!validExtensions.includes(fileType)) {
      fileInputRef.current.value = null;
      return dispatch(actionError("Please upload images in either PNG,JPEG or JPG formats."));
    }
    getFileDimensions(fileInputRef.current.files[0], (width, height) => {
      if (width < 100 || height < 100) {
        fileInputRef.current.value = null;
        return dispatch(actionError("Please upload an image with minimum size of width 100px and height 100px."));
      }
      let imgSrcData = URL.createObjectURL(fileInputRef.current.files[0]);
      SetImgSrc(imgSrcData);
      fileInputRef.current.value = null;
    });
  }

  function dataURLToBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  function ExportImage() {
    try {
      dispatch(showLoader());
      const { imageData, designState } = mainRef.current({}, false, false);
      // Create an image element
      const image = new Image();
      // Set the image source
      image.src = imageData.imageBase64;
      // Create a canvas element
      const editedImgWidth = designState.resize.width || imageData.width;
      const editedImgHeight = designState.resize.height || imageData.height;
      // Update the width and height
      image.width = editedImgWidth;
      image.height = editedImgHeight;
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      // Load the image
      image.onload = async () => {
        canvas.width = editedImgWidth;
        canvas.height = editedImgHeight;
        // Draw the image onto the canvas
        context.drawImage(image, 0, 0, editedImgWidth, editedImgHeight);
        const dataURL = canvas.toDataURL("image/png"); // Adjust the type according to your image format
        const blob = dataURLToBlob(dataURL);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = imageData.fullName;
        a.click();
        dispatch(hideLoader());
      };
    } catch (err) {
      dispatch(hideLoader());
      dispatch(actionError(err?.data?.message || "Unable to download image"));
    }
  }

  useEffect(() => {
    if (!triggerSave) return;
    if (triggerSave === "saveImage" && mainRef.current) {
      saveImage();
    }
    if (triggerSave === "exportImage" && mainRef.current) {
      ExportImage();
    }
    setTriggerSave("");
  }, [triggerSave]);

  useEffect(() => {
    if (!uploadTrigger) return;
    fileInputRef.current.click();
    setUploadTrigger(false);
  }, [uploadTrigger]);

  return (
    <>
      {imgSrc && (
        <div>
          <input
            className="upload-input-hiddden"
            ref={fileInputRef}
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            onChange={handleInputChange}
          />
          <FilerobotImageEditor
            getCurrentImgDataFnRef={mainRef}
            source={imgSrc}
            theme={{
              palette: {
                "bg-primary-active": "#f94f5e",
                "borders-primary": "#f94f5e",
                "accent-primary-active": "#fff",
              },
              typography: {
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            }}
            annotationsCommon={{
              fill: "#ff0000",
            }}
            Text={{ text: "Text here" }}
            Rotate={{ angle: 90, componentType: "buttons" }}
            Crop={{
              autoResize: false,
              ratio: "original",
              // noPresets: true,
              presetsItems: presetsItems,
            }}
            Resize={{
              presetsItems: presetsItems,
            }}
            tabsIds={[TABS.RESIZE, TABS.ADJUST, TABS.FINETUNE, TABS.FILTERS, TABS.WATERMARK, TABS.ANNOTATE]}
            defaultTabId={TABS.RESIZE}
            defaultToolId={TOOLS.RESIZE}
            defaultSavedImageQuality={1}
            imageContainer={{
              width: Number(imageWidth?.split("px")[0]),
              height: Number(imageHeight?.split("px")[0]),
            }}
            onResize={(url) => SetImgSrc(url)}
          />
        </div>
      )}
    </>
  );
};

export default ImageEditor;
