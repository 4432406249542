/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company State
----------------------------------------------------------------
	Creation Details
	Date Created				: 14/Oct/2020
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import {
	SET_COMPANY_OVERVIEW_UPDATE_STATUS,
	SET_COMPANY_SAVE_SUBMIT_CLICK_STATUS
} from "../../constants/actionConstants";

const COMPANY_STATE = {
	updateStatus: false,
	clickStatus: false,
	taxonomyCheckedNodes: [],
	taxonomyUnCheckedNodes: [],
	kiaCheckedNodes: [],
	kiaUnCheckedNodes: [],
	basicDetails: {},
	advanceDetails: {}
};

export default (state = COMPANY_STATE, action) => {
	switch (action.type) {
		case SET_COMPANY_OVERVIEW_UPDATE_STATUS: {
			return { ...state, updateStatus: action.payload.updateStatus };
		}
		case SET_COMPANY_SAVE_SUBMIT_CLICK_STATUS: {
			return { ...state, clickStatus: action.payload.clickStatus };
		}
		case 'SET_COMPANY_TAXONOMY_CHECKED_NODES': {
			return { ...state, taxonomyCheckedNodes: action.payload };
		}
		case 'SET_COMPANY_TAXONOMY_UNCHECKED_NODES': {
			return { ...state, taxonomyUnCheckedNodes: action.payload };
		}
		case 'SET_COMPANY_KIA_CHECKED_NODES': {
			return { ...state, kiaCheckedNodes: action.payload };
		}
		case 'SET_COMPANY_KIA_UNCHECKED_NODES': {
			return { ...state, kiaUnCheckedNodes: action.payload };
		}
		case 'SET_RESET_REDUX': {
			return {
				taxonomyCheckedNodes: [],
				taxonomyUnCheckedNodes: [],
				kiaCheckedNodes: [],
				kiaUnCheckedNodes: [],
			};
		}
		case 'SET_BASIC_DETAILS_COMPANY': {
			return { ...state, basicDetails: action.payload };
		}

		case 'SET_ADVANCE_DETAILS': {
			return { ...state, advanceDetails: action.payload };
		}
			
		case 'RESET_REDUX_COMPANY': {
			return {
				basicDetails: {},
				advanceDetails: {}
			};
		}
		default:
			return state;
	}
};
