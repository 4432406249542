import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";

import NotificationListing from "../app/views/notificationManagement";
const PROPERTIES = ["Properties:VIEW", "Properties:FULL"];
const NotificationRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/notification"
      component={NotificationListing}
      pageHeading="GenNx CMS - Notification"
      accessRights={{
        moduleName: "Notification",
        parentModuleName: "Notification",
        moduleAttribute: [...PROPERTIES],
        interfaceName: "Listing",
      }}
    />
  </Switch>
);

export default NotificationRoutes;
