/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Media taxonomy Tree Component
     Require Props :
        handleSave : Save Tree Data
        loadOtherTagInfo : Switch between trend and tree tagging
        saveTreeData : Save Media taxonomy data
        contentId : Content Id [news id | company id]
        fetchContentData : Fetch content taxonomy
        handleSaveValueChain : Save Value chain data
        contentType : Content Type [News | Company | Product]
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 28/Jul/2021 
    Author						: Pankaj Kulshreshtha
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchTaxonomyMedia } from "../../../../middleware/services/cmsApi";
import SortableTree from "../sortableTree";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import noTaxonomyImage from "../../../../assets/images/no-taxonomy.svg";

const Taxonomy = (props) => {
  const dispatch = useDispatch();
  const [tagging, setTagging] = useState([]);
  const [media, setMedia] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(undefined);
  const [taxonomy, setTaxonomy] = useState([]);
  const [checked, setChecked] = useState([]);
  const [contentTaggedIds, setContentTaggedIds] = useState([]);
  const [reRender, setReRender] = useState(false);

  const [leftTreeCollapse, setLeftTreeCollapse] = useState(true);
  const [rightTreeCollapse, setRightTreeCollapse] = useState(true);
  const [leftTreeChecked, setLeftTreeChecked] = useState([]);
  const [rightTreeChecked, setRightTreeChecked] = useState([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  let contentTaggingData = [];
  let taggingData = [];
  /*
        @Description : Fetch taxonomy tagging for respective content 
        1. fetchContentTagging received from parent component as a props
    */
  const fetchContentTagging = () => {
    dispatch(showLoader());
    const contentTaxonomy = props.fetchContentTagging(props.contentId);
    contentTaxonomy
      .then((res) => {
        let { data = [] } = res.data;
        data = [...new Map(data.map((v) => [v.taxonomyId, v])).values()];
        if (data.length) {
          contentTaggingData = data;
          taggingData = data.map((values) => values.taxonomyId);
        }
        const selectedNodeData = buildSelectedTreeJson(data);
        setTagging(
          selectedNodeData.map((item) => {
            item.level = 2;
            return item;
          })
        );
        setLeftTreeCollapse(true);
        setContentTaggedIds(taggingData);

        setReRender(true);
        setLeftTreeChecked([]);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchProductTaxonomy :: ", err);
      });
  };

  /*
        @Description : Fetch all media 
    */
  const fetchMedia = () => {
    fetchTaxonomyMedia({
      filters: [["taxonomyParentId", "eq", 0]],
      sort: "taxonomyName:asc",
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
    })
      .then((res) => {
        const data = [...res.data.data];
        setMedia(data);
        if (selectedMedia) fetchTaxonomy(selectedMedia);
        else fetchTaxonomy(data[0].id);
        setLeftTreeCollapse(true);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchMedia :: ", err);
      });
  };
  /*
        @Description : Fetch all taxonomy for selected media
        @param 
        dtRoot : Media Id
        taggingData : Content taxonomy tagged data [ Use for mark as default check ]
    */
  const fetchTaxonomy = (taxonomyRoot) => {
    dispatch(showLoader());
    taggingData = JSON.parse(JSON.stringify(contentTaggedIds));
    fetchTaxonomyMedia({
      fields: ["id", "taxonomyName", "taxonomyParentId", "taxonomyLevel"],
      filters: [["taxonomyRoot", "eq", taxonomyRoot]],
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
    })
      .then((res) => {
        dispatch(hideLoader());
        let { data = [] } = res.data;
        let checkNode = [];
        if (data.length) {
          data.forEach((element) => {
            if (taggingData.includes(element.id)) {
              checkNode.push(element.id);
              element["isChecked"] = true;
            }
          });
        }
        const treeJosnArray = buildTreeJson(data, taxonomyRoot);
        setChecked(checkNode);
        setTaxonomy(treeJosnArray);
        setRightTreeCollapse(true);
        setRightTreeChecked(checkNode);
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error("err fetchTaxonomy :: ", err);
      });
  };
  /*
        @Description : Build tree data
        @param : 
        data : Media taxonomy data
        parentId : media id
    */
  const buildTreeJson = (data, parentId) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.taxonomyName;
      element["level"] = element.taxonomyLevel;
      if (element["taxonomyParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };
  /*
        @Description : Build tree data for tagged values
        @param : 
        data : taxonomy data
        parentId :media id
    */
  const buildSelectedTreeJson = (data, parentId = 0) => {
    const result = [];
    data.forEach((element) => {
      element["id"] = element.taxonomyId;
      element["value"] = element.taxonomyId;
      element["label"] = element.taxonomyName;
      element["showCheckbox"] = false;
      if (element["taxonomyParentId"] == parentId) {
        const children = buildSelectedTreeJson(data, element["taxonomyId"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };
  /*
        @Description : Make bridge to send callback
        @param : 
        selectedTech : selected media
        checkValues : Checked taxonomy
    */
  const handleSaveBridge = (selectedTech, checkValues) => {
    props.handleSave(selectedTech, checkValues, fetchContentTagging);
  };
  const deleteMedia = () => {
    if (props.isDelete) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            props.deleteMedia([...new Set(leftTreeChecked)], fetchContentTagging);
          },
          okText: "Delete",
          cancelText: "Cancel",
          content: "Do you want to remove taxonomy tagging ? ",
          title: "dialogAlertCss",
        })
      );
    } else {
      setShowModalDelete(true);
    }
  };

  const leftTreeOnChecked = (checkedLeft) => {
    let result = [];
    checkedLeft.forEach(function (a, i) {
      result.push(a.id);
      if (Array.isArray(a.children)) {
        result = result.concat(leftTreeOnChecked(a.children));
      }
    });
    setLeftTreeChecked(result);
    return result;
  };

  useEffect(() => {
    fetchContentTagging();
  }, []);

  useEffect(() => {
    if (props.saveTreeData) {
      handleSaveBridge(selectedMedia, rightTreeChecked);
    }
  }, [props.saveTreeData]);

  useEffect(() => {
    if (reRender) {
      fetchMedia();
      setReRender(false);
    }
  }, [contentTaggedIds, reRender]);

  return (
    <div
      className="row"
      style={{
        height: "74vh",
        marginLeft: "0.5em",
        marginRight: "0.3em",
        paddingRight: "0.7em",
        marginTop: "1.5em",
      }}
    >
      {/* <div className="row p-0 m-0 mx-2 "> */}

      <div className="col-6 p-0 m-0 TaxonomyNewTabLeftContent">
        {/* <div className="col-md-12 p-2 border bg-secondary text-white rounded-top">Taxonomy Tagging</div> */}
        <div className="col-md-12 pl-1 bg-white h-100">
          <div className="row" style={{ paddingTop: "2.2vh", paddingBottom: "7px" }}>
            <div className="">
              <h6 className="font-weight-bold headline">Media</h6>
            </div>
            {tagging?.length > 0 ? (
              <div className="ml-auto">
                {props?.accessPermissionTaxonomyDelete && (
                  <button
                    type="button"
                    className={`btn  px-3  mr-3 ${leftTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                    onClick={() => deleteMedia()}
                  >
                    Delete
                  </button>
                )}

                {leftTreeCollapse ? (
                  <span
                    className="expandButton btn px-3 mr-3"
                    style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                    onClick={() => setLeftTreeCollapse(false)}
                  >
                    Expand All{" "}
                  </span>
                ) : (
                  <span
                    className="expandButton btn px-3 mr-3"
                    style={{ border: "1px solid #F94F5E", color: "#F94F5E" }}
                    onClick={() => setLeftTreeCollapse(true)}
                  >
                    Collapse All
                  </span>
                )}
              </div>
            ) : null}
          </div>

          {tagging && tagging.length > 0 ? (
            <SortableTree
              treeData={tagging}
              onClick={leftTreeOnChecked}
              treeCollapse={leftTreeCollapse}
              parentId={"taxonomyParentId"}
              hieghtClass={"custom-tagging-tree-container-wrapper"}
            />
          ) : (
            <div className="text-center">
              <img src={noTaxonomyImage} alt="No Tagging" width="250" height="250" />
              <p>
                Select tags to create <span>Media Taxonomy</span>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="col-6 p-0 m-0 pr-2 TaxonomyNewTabRightContent">
        <div className="col-md-12 bg-white  pl-0">
          <div className="col-12 pt-3 pl-4">
            <div className="row g-0">
              <div className="col-6">
                {media.length > 0 ? (
                  <DropDownListComponent
                    value={selectedMedia || setSelectedMedia(media[0].id)}
                    change={(value) => {
                      setSelectedMedia(value?.itemData?.id);
                      fetchTaxonomy(value?.itemData?.id, contentTaggedIds);
                    }}
                    cssClass={"customCss e-outline"}
                    floatLabelType="Auto"
                    popupHeight="250px"
                    dataSource={[...media]}
                    fields={{ text: "taxonomyName", value: "id" }}
                    placeholder="Select Taxonomy"
                  />
                ) : null}
              </div>
              {taxonomy.length > 0 ? (
                <div className="col-6 pr-0">
                  <div className="float-right">
                    {props?.accessPermissionTaxonomy && (
                      <button
                        type="button"
                        className={`btn  px-3  mr-3 ${rightTreeChecked.length > 0 ? "btn-primary" : "btn-primary-disabled"}`}
                        onClick={() => {
                          if (props?.isDelete) {
                            props.setSaveTreeData(true);
                          } else {
                            setShowModalAdd(true);
                          }
                        }}
                      >
                        Add
                      </button>
                    )}
                    {rightTreeCollapse ? (
                      <span
                        className="expandButton btn px-3 mr-3"
                        style={{
                          border: "1px solid #F94F5E",
                          color: "#F94F5E",
                        }}
                        onClick={() => setRightTreeCollapse(false)}
                      >
                        Expand All{" "}
                      </span>
                    ) : (
                      <span
                        className="expandButton btn px-3 mr-3"
                        style={{
                          border: "1px solid #F94F5E",
                          color: "#F94F5E",
                        }}
                        onClick={() => setRightTreeCollapse(true)}
                      >
                        Collapse All
                      </span>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="pt-2">
            {taxonomy.length > 0 ? (
              <SortableTree
                treeData={taxonomy}
                parentId={"taxonomyParentId"}
                onClick={(checkedRight) => {
                  let ids = checkedRight.map((item) => {
                    return item.id;
                  });
                  setRightTreeChecked(ids);
                }}
                treeCollapse={rightTreeCollapse}
                hieghtClass={"custom-tagging-tree-container-wrapper"}
              />
            ) : null}
          </div>
        </div>
      </div>

      <Modal show={showModalAdd} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              Since this company has products, you can not add taxonomy on company level. You need to add taxonomy in product.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalAdd(!showModalAdd)}>
            Ok
          </button>
        </div>
      </Modal>

      <Modal show={showModalDelete} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              Since this company has products, you can not delete taxonomy from company. You need to delete taxonomy from product.
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalDelete(!showModalDelete)}>
            Ok
          </button>
        </div>
      </Modal>
    </div>
  );
};
export default Taxonomy;
Taxonomy.propTypes = {
  fetchContentTagging: PropTypes.func.isRequired,
  loadOtherTagInfo: PropTypes.func,
  handleSave: PropTypes.func.isRequired,
  saveTreeData: PropTypes.bool,
  contentId: PropTypes.string.isRequired,
};
