/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Industry Use case component nent for Add/Edit
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 07/June/2021
	Author						: Sandeep Sharma
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import { Modal } from "react-bootstrap";
import { addExponentialTechnologies, updateExponentialTechnology, getSingleExponentialTechnology } from "../../../../../middleware/services/kiaApi";
import { fetchTaxonomyTechnology } from "../../../../../middleware/services/cmsApi";
import { addExponentialTechValidationSchema } from "../validationSchema";
import { Dropdown, CkEditor, MultiSelect } from "../formFields";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TIME_SCALE } from "../formFieldOptions";
import _ from "underscore";

const initialState = {
  technologyName: "",
  subTechnology: [],
  technologyDescription: "",
  technologyPotentialTimescale: "",
};

const AddExponentialTechModel = (props) => {
  const [expTech, setExpTech] = useState(null);
  const [technologyData, setTechnologyData] = useState([]);
  const [subTechnologyData, setSubTechnologyData] = useState([]);
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(addExponentialTechValidationSchema),
  });
  /*
	   @Description : Save/Update Exponential Tech Details
   */
  const addExponentialTech = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const { technologyName, subTechnology, technologyDescription, technologyPotentialTimescale } = formik.values;
        const selectedTechnology =
          technologyData &&
          technologyData
            .map((f) => {
              return technologyName.includes(f.id) && { technologyId: f.id, technologyName: f.dtName };
            })
            .filter((v) => {
              return typeof v === "object";
            });
        const selectedSubTechnology =
          subTechnologyData &&
          subTechnologyData
            .map((f) => {
              return subTechnology.includes(f.id) && { subTechnologyId: f.id, subTechnologyName: f.dtName };
            })
            .filter((v) => {
              return typeof v === "object";
            });
        payload.technologyId = selectedTechnology[0].technologyId;
        payload.technologyName = selectedTechnology[0].technologyName;
        payload.technologyDescription = technologyDescription;
        payload.technologyPotentialTimescale = technologyPotentialTimescale;
        const formData = new FormData();
        for (let key in payload) {
          formData.append(key, payload[key]);
        }
        formData.append("subTechnology", JSON.stringify(selectedSubTechnology));

        if (props.expTechId) {
          updateExponentialTechnology(props.expTechId, formData)
            .then((res) => {
              dispatch(actionSuccess("Exponential Technology updated successfully"));
              props.closeModal();
              props.setDeleteId(new Date());
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "Something went wrong"));
            });
        } else {
          addExponentialTechnologies(props.kiaId, formData)
            .then((res) => {
              if (res) {
                dispatch(actionSuccess("Exponential Technology  saved successfully"));
                props.closeModal();
                props.setDeleteId(new Date());
              } else {
                dispatch(actionError("Something went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "something went wrong"));
            });
        }
      }
    });
  };
  /*
	   @Description : Fetch Single Exponential Tech Details
   */
  const fetchExpTech = () => {
    dispatch(showLoader());
    getSingleExponentialTechnology(props.expTechId).then((response) => {
      if (response.status === 200) {
        dispatch(hideLoader());
        const exponentialTech = response.data.data || {};
        setExpTech(exponentialTech);
        setTimeout(() => {
          formik.setValues({
            technologyName: exponentialTech.technologyId,
            subTechnology: exponentialTech.subTechnology && _.pluck(exponentialTech.subTechnology, "subTechnologyId"),
            technologyDescription: exponentialTech.technologyDescription,
            technologyPotentialTimescale: exponentialTech.technologyPotentialTimescale,
          });
        }, 200);
      }
    });
  };
  /*
	   @Description : Fetch Technology  data
   */
  const getTaxonomyTechnology = () => {
    fetchTaxonomyTechnology({ fields: ["dtName", "Id"], filters: [["dtParentId", "eq", "0"]], sort: "dtName:asc", limit: 200 })
      .then((res) => {
        const { data = [] } = res.data;
        setTechnologyData(data);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Technolgy failed";
        dispatch(actionError(errMsg));
      });
  };

  useEffect(() => {
    getTaxonomyTechnology();
    if (props.expTechId !== "") {
      fetchExpTech();
    }
  }, [props.showModal, props.expTechId]);
  useEffect(() => {
    if (formik.values.technologyName) {
      fetchTaxonomyTechnology({
        fields: ["dtName", "Id"],
        filters: [
          ["dt_root", "eq", formik.values.technologyName],
          ["dt_parent_name", "eq", "Technologies / Offering"],
        ],
        sort: "dtName:asc",
        limit: 200,
      })
        .then((res) => {
          const { data = [] } = res.data;
          data && data.length > 0 && setSubTechnologyData(data);
        })
        .catch((err) => {
          let errMsg = err?.data?.message ? err?.data?.message : "Fetch Sub Technology failed";
          dispatch(actionError(errMsg));
        });
      (expTech && formik.values.technologyName !== expTech.technologyId && formik.setFieldValue("subTechnology", [])) ||
        formik.setFieldValue("subTechnology", expTech && expTech.subTechnology && _.pluck(expTech.subTechnology, "subTechnologyId"));
    }
  }, [formik.values.technologyName]);

  return (
    <>
      <Modal
        show={props.showModal}
        size="lg"
        backdrop="static"
        className="modal fade people-mgmt"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form id="addExp" method="post">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.expTechId ? "Update" : "Add"} Exponential Technologies
            </h5>
          </div>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  {technologyData && (
                    <Dropdown
                      formik={formik}
                      required="required"
                      placeholder="Technlogy *"
                      id="technologyName"
                      name="technologyName"
                      value={formik.values.technologyName}
                      fields={{ text: "dtName", value: "id" }}
                      dataSource={technologyData}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  {subTechnologyData && subTechnologyData.length > 0 && (
                    <MultiSelect
                      formik={formik}
                      mode="Box"
                      sortOrder="Acending"
                      placeholder="Sub-Technlogy *"
                      id="subTechnology"
                      name="subTechnology"
                      allowCustomValue={true}
                      value={formik.values.subTechnology}
                      fields={{ text: "dtName", value: "id" }}
                      dataSource={subTechnologyData}
                      noRecordsTemplate={() => <div>Please type to search</div>}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group required">
                  <label className="font-weight-bold" htmlFor="technologyDescription">
                    Description
                  </label>
                  <CkEditor
                    name="technologyDescription"
                    formik={formik}
                    id="technologyDescription"
                    activeClass="editor"
                    charCountLimit={2500}
                    height="330px"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <div className="form-group">
                  <Dropdown
                    formik={formik}
                    required="required"
                    placeholder="Potential Time Scale *"
                    id="technologyPotentialTimescale"
                    name="technologyPotentialTimescale"
                    value={formik.values.technologyPotentialTimescale}
                    fields={{ text: "label", value: "value" }}
                    dataSource={TIME_SCALE}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-dark cy-btn mr-3"
              onClick={() => {
                props.closeModal();
              }}
            >
              Cancel
            </button>
            <button type="button" className="btn btn-primary cy-btn" onClick={addExponentialTech}>
              {props.expTechId ? "Update" : "Save"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddExponentialTechModel;
