/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Add Edit Documents
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 19/July/2021
	Author						: Sandeep Sharma
================================================================ 
*/
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import { Modal } from "react-bootstrap";
import {
  updateServiceDocument,
  getSingleServiceDocument,
  addServiceDocument,
  fetchThumbnailUrl,
} from "../../../../../middleware/services/servicesApi";
import { addServiceDocumentValidationSchema, editServiceDocumentValidationSchema } from "../validationSchema";
import { Dropdown, TextField } from "../formFields";
import UploadComponent from "../../../../components/uploadComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DOCUMENT_TYPE_OPTIONS } from "../formFieldOptions";
import docDefImage from "../../../../../assets/images/news.jpg";
import "../../index.scss";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";

const initialState = {
  docTitle: "",
  documentFile: "",
  docType: "",
  docThumbnail: "",
};
const AddDocumentModel = (props) => {
  const dispatch = useDispatch();
  const [editData, setEditData] = useState(null);
  const [docImageSrc, setDocImageSrc] = useState(undefined);
  const docDefaultImageRef = useRef(null);
  const docImageRef = useRef(null);
  const [imageError, setImageError] = useState("");
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const [showTrash, setShowTrash] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(editData ? editServiceDocumentValidationSchema : addServiceDocumentValidationSchema),
  });

  /*
	   @Description : Save/Update Service Document Details
   */
  useEffect(() => {
    setDocImageSrc(props.docDefImage);
    if (props.docDefImage) {
      setIsDefaultImage(false);
      setShowTrash(true);
    }
  }, [props.docDefImage, isDefaultImage]);
  useEffect(() => {
    setImageError("");
  }, []);

  const saveServiceDocument = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const { docTitle, documentFile, docType } = formik.values;
        payload.docTitle = docTitle;
        payload.docFile = documentFile && documentFile[0];
        payload.docType = docType;
        payload.docThumbnail = docDefaultImageRef.current.files[0];
        const formData = new FormData();
        for (let key in payload) {
          formData.append(key, payload[key]);
        }
        if (props.documentId) {
          updateServiceDocument(props.serviceId, props.documentId, formData)
            .then((res) => {
              dispatch(actionSuccess("Document updated successfully"));
              props.fetchDocumentData();
              props.closeDocumentModal();
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "Something went wrong"));
            });
        } else {
          addServiceDocument(props.serviceId, formData)
            .then((res) => {
              if (res) {
                dispatch(actionSuccess("Document added successfully"));
                props.fetchDocumentData();
                props.closeDocumentModal();
              } else {
                dispatch(actionError("Something went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "something went wrong"));
            });
        }
      }
    });
  };
  /*
	   @Description : Fetch Single Industry Application Details
   */
  const fetchSingleServiceDocument = () => {
    getSingleServiceDocument(props.serviceId, props.documentId).then((response) => {
      if (response.status == 200) {
        var serviceDoc = response.data.data;
        setEditData(serviceDoc);
        formik.setValues({
          docTitle: serviceDoc.serviceDeliverableDocuments.docTitle,
          docFile: serviceDoc.serviceDeliverableDocuments.docFile,
          docType: serviceDoc.serviceDeliverableDocuments.docType,
        });
        serviceDoc.serviceDeliverableDocuments.docThumbnail && setShowTrash(true);
        setDocImageSrc(
          serviceDoc.serviceDeliverableDocuments.docThumbnail ? fetchThumbnailUrl(serviceDoc.serviceDeliverableDocuments.docThumbnail) : docDefImage
        );
      }
    });
  };
  useEffect(() => {
    if (props.documentId !== "") {
      dispatch(showLoader());
      !editData && fetchSingleServiceDocument();
      dispatch(hideLoader());
    }
  }, [props.showDocumentModal]);
  /*
		@Description : Read Doc image
	*/
  const readDocImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      docImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  return (
    <Modal
      show={props.showDocumentModal}
      backdrop="static"
      className="modal fade service-mgmt"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.documentId ? "Update" : "Add"} Document
        </h5>
      </div>
      <Modal.Body>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <TextField formik={formik} placeholder="Document Title *" id="docTitle" name="docTitle" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="image-holder" style={{ paddingTop: "0px", position: "absolute", marginLeft: "10px" }}>
              <img width="100" height="100" src={docImageSrc || docDefImage} alt="Document" ref={docImageRef} />
              <div className="image-action" style={{ top: "5px" }}>
                <span
                  onClick={() => {
                    dispatch(
                      showAlertBox({
                        content: "Are you sure, you want to delete?",
                        okCallback: async () => {
                          dispatch(actionSuccess("Image deleted successfully"));
                          setShowTrash(false);

                          docImageRef.current.setAttribute("src", docDefImage);
                          docDefaultImageRef.current.value = "";
                        },
                      })
                    );
                  }}
                >
                  <i className={`fa fa-trash mr-1 ${!showTrash ? "d-none" : undefined}`} aria-hidden="true" />
                </span>
                <span onClick={() => docDefaultImageRef.current.click()}>
                  <i className="fa fa-pencil-alt " aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <input
              type="file"
              className="d-none"
              accept=".jpg, .png"
              id="docImage"
              name="docImage"
              ref={docDefaultImageRef}
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  if (
                    (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/jpeg") &&
                    e.target.files[0].size <= 1024000
                  ) {
                    readDocImage(e.target.files[0]);
                    docImageRef.current.setAttribute("src", e.target.files[0]);
                    setImageError("");
                    setShowTrash(true);
                    setIsDefaultImage(false);
                    formik.setErrors({ ...formik.errors, docImage: "" });
                  } else {
                    setImageError(`Only JPG, PNG files are allowed. Max file size should
												be 1000 Kb. Uploaded file size is ${(e.target.files[0].size / 1024).toFixed(2)} Kb`);
                  }
                }
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              {DOCUMENT_TYPE_OPTIONS && (
                <Dropdown
                  formik={formik}
                  sortOrder="Acending"
                  placeholder="Document Type *"
                  id="docType"
                  name="docType"
                  fields={{ text: "label", value: "value" }}
                  dataSource={DOCUMENT_TYPE_OPTIONS}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <UploadComponent
              editDocumentData={editData}
              fileUrl={editData && editData.serviceDeliverableDocuments.docFileName}
              docFileName={editData && editData.serviceDeliverableDocuments.docName}
              buttonText="Upload Document"
              validationText="pdf, xls, ppt and document files only"
              btnClass="btn btn-dark"
              acceptedFiles=".pdf,.xlsx,.xls,.doc,.docx,.ppt,.pptx"
              formik={formik}
            />
          </div>
          <div className="col-md-12">
            {formik.values.documentFile &&
              typeof formik.values.documentFile === "object" &&
              formik.values.documentFile.map((file, i) => <li key={i}>{`File:${file.name}`}</li>)}
          </div>
        </div>
        {imageError && (
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-danger" style={{ display: imageError ? "block" : "none" }} role="alert">
                <small>{imageError}</small>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-dark cy-btn"
          onClick={() => {
            props.closeDocumentModal();
          }}
        >
          Cancel
        </button>
        <button type="button" className="btn btn-primary cy-btn" onClick={saveServiceDocument}>
          {props.documentId ? "Update" : "Save"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddDocumentModel;
