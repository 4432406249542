import React from "react";
import moment from "moment";
import { HiDownload } from "react-icons/hi";
import { fetchAnalystQueryDocument } from "middleware/services/servicesApi";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";
import { useFormik } from "formik";
import { Dropdown, TextField, DatePicker } from "../../components/CustomFormElements/syncFusionFormFields";

const AnalystQueryDetailsSideBar = (props) => {
  const { QueryDetails, singleQueryData } = props;
  const dispatch = useDispatch();

  const initialState = {
    queryCategory: QueryDetails[0]?.queryCategory,
    querySubCategory: QueryDetails[0]?.querySubCategory,
    queryReferenceNumber: QueryDetails[0]?.queryReferenceNumber,
    querySubmittedBy: QueryDetails[0]?.querySubmittedBy,
    querySubmittedName: QueryDetails[0]?.querySubmittedName,
    queryCreatedDate: moment(QueryDetails[0]?.queryCreatedDate).format("DD-MMM-yyyy"),
  };
  const categoryDropdown = [{ label: QueryDetails[0]?.queryCategory, value: QueryDetails[0]?.queryCategory }];
  const subcategoryDropdown = [{ label: QueryDetails[0]?.querySubCategory, value: QueryDetails[0]?.querySubCategory }];

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
  });

  // ** download attachment
  const downloadAttachment = async (fileId, docFileName, fileName) => {
    try {
      dispatch(showLoader());
      const result = await fetchAnalystQueryDocument(fileId, docFileName, fileName);
      //** Create a Blob from the array buffer
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(result.data);
      link.download = fileName;
      link.click();
      dispatch(hideLoader());
    } catch (error) {
      console.log("err::::::", error);
      dispatch(hideLoader());
    }
  };

  const formatKey = (key) => {
    // Convert key to Pascal case with spaces
    return key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
  };
  const renderValue = (key, value) => {
    if (typeof value === "object" && !Array.isArray(value)) {
      return Object.entries(value).map(([keyVal, val]) => (
        <div key={keyVal} className="col-md-6">
          <TextField
            formik={formik}
            placeholder={formatKey(keyVal)}
            name={keyVal}
            id={keyVal}
            {...props}
            hideInitial={true}
            readonly={true}
            showClearButton={false}
            value={val}
          />
        </div>
      ));
    } else if (Array.isArray(value)) {
      return (
        <div className="px-3 row ml-0 w-full">
          {value?.map((file) => {
            let iconSource = "/assets/generic-file-icon.svg";
            if (file?.docName.toLowerCase().includes(".pdf")) iconSource = "/assets/pdf.svg";
            if (file?.docName.toLowerCase().includes(".ppt")) iconSource = "/assets/ppt.svg";
            if (
              file?.docName.toLowerCase().includes(".png") ||
              file?.docName.toLowerCase().includes(".jpg") ||
              file?.docName.toLowerCase().includes(".jpeg")
            )
              iconSource = "/assets/image-file-icon.svg";
            return (
              <div key={file?.docName} className="query-file">
                {console.log("idLLLLL", file)}
                <img className="query-file-icon" src={iconSource} alt="icon-download" />
                <span className="query-file-header ">
                  <span className="query-filename" data-toggle="tooltip" data-placement="bottom" title={file?.docName ? file.docName : ""}>
                    {file?.docName}
                  </span>
                  <span className="query-file-date">{moment(file?.docCreatedDate).format("DD MMM YYYY HH:mm")}</span>
                </span>
                <HiDownload
                  onClick={() => downloadAttachment(file?.id, file?.docFileName, file?.docName)}
                  className="query-download-icon"
                  size={24}
                />
              </div>
            );
          })}
        </div>
      );
    } else if (typeof value === "string" && value.includes("<")) {
      return (
        <div className="col-md-12">
          <div className="mb-3">
            <span className="query-details-header">{formatKey(key)}</span>
            <div className="query-header-div">
              <p className="query-details" dangerouslySetInnerHTML={{ __html: value }}></p>
            </div>
          </div>
          {/* <TextField
            style={{ height: "100px" }}
            formik={formik}
            multiline={true}
            numberCount={true}
            maxLength={200}
            rows={5}
            name={key}
            className="form-control"
            id={key}
            placeholder={formatKey(key)}
            {...props}
            value={value}
            readonly={true}
            showClearButton={false}
           
          /> */}
        </div>
      );
    } else if (key === "date") {
      return (
        <div className="col-md-6">
          <DatePicker
            formik={formik}
            properties={{
              placeholder: formatKey(key),
              id: key,
              name: key,
              format: "dd MMM yyyy",
              allowEdit: false,
              floatLabelType: "Auto",
              openOnFocus: true,
              readonly: true,
              showClearButton: false,
              value: moment(value).format("DD-MMM-yyyy"),
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="col-md-6">
          <TextField
            formik={formik}
            placeholder={formatKey(key)}
            name={key}
            id={key}
            {...props}
            hideInitial={true}
            readonly={true}
            showClearButton={false}
            value={value}
          />
        </div>
      );
    }
  };

  return (
    <>
      {QueryDetails && (
        <div className="px-3 analyst-query-content">
          <div className="row">
            <div className="col-md-6 mt-3 ">
              <Dropdown
                formik={formik}
                placeholder="Purpose of Contacting"
                required="required"
                id="queryCategory"
                name="queryCategory"
                fields={{ text: "label", value: "value" }}
                value={QueryDetails[0]?.queryCategory ? QueryDetails[0]?.queryCategory : formik.values.queryCategory}
                dataSource={categoryDropdown}
                // readonly={true}
                // showClearButton={false}
              />
            </div>
            {QueryDetails[0]?.querySubCategory && (
              <div className="col-md-6 mt-3">
                <Dropdown
                  formik={formik}
                  placeholder="Sub Type"
                  required="required"
                  id="querySubCategory"
                  name="querySubCategory"
                  fields={{ text: "label", value: "value" }}
                  value={QueryDetails[0]?.querySubCategory ? QueryDetails[0]?.querySubCategory : formik.values.querySubCategory}
                  dataSource={subcategoryDropdown}
                  // readonly={true}
                  // showClearButton={false}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <TextField
                formik={formik}
                placeholder="Reference Number "
                name="queryReferenceNumber"
                id="queryReferenceNumber"
                {...props}
                hideInitial={true}
                readonly={true}
                showClearButton={false}
              />
            </div>
            <div className="col-md-6">
              <DatePicker
                formik={formik}
                properties={{
                  placeholder: "Date of Submission",
                  id: "queryCreatedDate",
                  name: "queryCreatedDate",
                  format: "dd MMM yyyy",
                  allowEdit: false,
                  floatLabelType: "Auto",
                  openOnFocus: true,
                  // max: getDateFromMongoDate(new Date()),
                  readonly: true,
                  showClearButton: false,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <TextField
                formik={formik}
                placeholder="Customer Name"
                name="querySubmittedName"
                id="querySubmittedName"
                {...props}
                hideInitial={true}
                readonly={true}
                showClearButton={false}
              />
            </div>
            <div className="col-md-6">
              <TextField
                formik={formik}
                placeholder="Customer Email"
                name="querySubmittedBy"
                id="querySubmittedBy"
                {...props}
                hideInitial={true}
                readonly={true}
                showClearButton={false}
              />
            </div>
          </div>

          {/* <span className="px-3 query-sub-header">Query Details:</span> */}
          {console.log("1...........", singleQueryData)}
          <div className="row">{singleQueryData && Object.entries(singleQueryData).map(([key, value]) => renderValue(key, value))}</div>
        </div>
      )}
    </>
  );
};
export default AnalystQueryDetailsSideBar;
