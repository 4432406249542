// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : key reports api methods
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :12 Oct 22
// Author:Umesh
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import http from "./http-common";

export const downloadMethod = async (url, data) => {
  const response = await http.post(url, data, {
    responseType: "arraybuffer",
    timeout: 1000000,
  });
  if (response.status !== 200) {
    throw new Error("Something went wrong!");
  }
  return response.data;
};
