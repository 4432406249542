/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : External User Registration Details
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 02/Jul/2021
	Author						: Pankaj Kulshreshtha
================================================================
*/

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import Stepper from "../../../components/stepper";
import Accordion from "../../../components/accordion";
import { fetchSingleUser } from "../../../../middleware/services/userApi";
import { getLoggedInUser } from "../../../../utilities";
import * as Survey from "survey-react";
// theme colors
const mainColor = "#fa4f5f";
const mainHoverColor = "#fa4f5f"; // black
const textColor = "#4a4a4a";
const headerColor = "#343a40";
const bodyContainerBackgroundColor = "#f8f8f8"; //gray
const RegistrationDtls = (props) => {
  const accessPermission = true;
  const userTokenDetails = getLoggedInUser();
  const history = useHistory();
  const editUser = props.path === "/external-user/add" ? false : true;
  const sessonId = userTokenDetails._id;
  const profileAccessId = props?.computedMatch?.params?.userId;
  const pathArr = props?.path.split("/");
  const pathname = pathArr[pathArr.length - 1];

  const userId = editUser ? props.computedMatch?.params?.userId : undefined;
  // specify breadcrumb here

  const breadCrumbLinksEditUser = [{ linkUrl: `/my-profile/${userId}/edit#profile`, linkName: "My Profile", linkActive: true }];
  const stepperData = [
    {
      id: 1,
      path: editUser ? `/my-profile/${userId}/edit#profile` : "#profile",
      step: "1",
      name: "User Profile",
      isLine: true,
    },
    {
      id: 2,
      path: editUser ? `/my-profile/${userId}/edit/registration-detail#registration` : "#registration",
      step: "2",
      name: "Registration Details",
      isLine: true,
      linkDisabled: editUser ? false : true,
    },
  ];

  const dispatch = useDispatch();

  const [formData, setFormData] = useState(undefined);
  const [formJson, setFormJson] = useState(undefined);

  /**
   * @description fetch single user detail
   * @param {String} userId
   */
  const fetchUserDetail = (userId) => {
    dispatch(showLoader());
    fetchSingleUser(userId)
      .then((res) => {
        const userDetail = { ...res.data.data };

        if (userDetail.userForm) {
          let defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
          defaultThemeColorsSurvey["$main-color"] = mainColor;
          defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
          defaultThemeColorsSurvey["$text-color"] = textColor;
          defaultThemeColorsSurvey["$header-color"] = headerColor;
          // defaultThemeColorsSurvey["$header-background-color"] = headerBackgroundColor;
          defaultThemeColorsSurvey["$body-container-background-color"] = bodyContainerBackgroundColor;
          Survey.StylesManager.applyTheme("default");

          setFormJson(userDetail.userForm.formJson);
          setFormData(userDetail.userForm.formJsonSubmitted);
          if (document.getElementsByClassName("sv_complete_btn")[0]) document.getElementsByClassName("sv_complete_btn")[0].style.display = "none";
        }

        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch User Details Failed";
        dispatch(actionError(errMsg));
      });
  };

  useEffect(() => {
    if (!accessPermission && sessonId === profileAccessId && !["edit", "registration-detail"].includes(pathname)) {
      history.push(`/external-user/${userId}/edit/#profile`);
    }
    const breadCrumbLinks = [...breadCrumbLinksEditUser];
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/external-user"));
    editUser && fetchUserDetail(userId);

    return () => {
      dispatch(setBreadCrumb());
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch]);

  return (
    <>
      <Stepper data={stepperData} />
      <div className="gennx-content-wrapper padding-bottom-150i">
        <div id="content-box">
          {userId && formData ? (
            <Accordion heading="User Registration Details" step={1} stepCompleted={props.stepCompleted} activeState={true}>
              <div className="row p-3">
                <Survey.Survey json={formJson} showQuestionNumbers={"off"} data={formData} showCompletedPage={false} />
              </div>
            </Accordion>
          ) : (
            <div className="col-md-12 text-center p-3 ">
              <h3>Registration details is not available</h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RegistrationDtls;
