/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Actions for different action status
----------------------------------------------------------------
	Creation Details
	Date Created				: 14/OCT/2020
	Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import {
	SET_NEWS_BASIC_TAB,
	SET_NEWS_OVERVIEW_UPDATE_STATUS,
	SET_NEWS_PUBLISH_CLICK_STATUS
} from "../../constants/actionConstants";

export const setOverviewUpdateStatus = (updateStatus = false) => ({
	type: SET_NEWS_OVERVIEW_UPDATE_STATUS,
	payload: { updateStatus: updateStatus },
});
export const setIsPublishClickedState = (clickStatus = false) => ({
	type: SET_NEWS_PUBLISH_CLICK_STATUS,
	payload: { clickStatus: clickStatus },
});
export const setTaxonomyCheckedNodes = (data) => ({
	type: 'SET_NEWS_TAXONOMY_CHECKED_NODES',
	payload: data,
});

export const setTaxonomyUnCheckedNodes = (data) => ({
	type: 'SET_NEWS_TAXONOMY_UNCHECKED_NODES',
	payload: data,
});
export const setKiaCheckedNodes = (data) => ({
	type: 'SET_NEWS_KIA_CHECKED_NODES',
	payload: data,
});

export const setKiaUnCheckedNodes = (data) => ({
	type: 'SET_NEWS_KIA_UNCHECKED_NODES',
	payload: data,
});

export const setValueChainCheckedNodes = (data) => ({
	type: 'SET_NEWS_VALUE_CHAIN_CHECKED_NODES',
	payload: data,
});

export const setValueChainUnCheckedNodes = (data) => ({
	type: 'SET_NEWS_VALUE_CHAIN_UNCHECKED_NODES',
	payload: data,
});

export const setResetRedux = () => ({
	type: 'SET_RESET_REDUX',
});

// for common save
export const setBasicTab = (data) => ({
	type: SET_NEWS_BASIC_TAB,
	payload: data,
});

