import React, { useEffect, useState, useCallback } from "react";
import { JsonToTable } from "react-json-to-table";
import moment from "moment";
import { debounce } from "lodash";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import { enableRipple } from "@syncfusion/ej2-base";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { swap, getProductVariant, getLoggedInUser, isAdmin, accessRightActionCheck } from "../../../utilities";
import CommonDataGrid from "../../components/dataGrid";
import { fetchReportInternalUser, fetchActivityLog } from "../../../middleware/services/reportApi";
import { fetchProductAll } from "../../../middleware/services/productApi";
import { fetchInternalUser } from "../../../middleware/services/userApi";
import { showPopup } from "../../../middleware/actions/popupAction";
import "./style.scss";
import { formatDateGMTtoIST } from "../../../utilities/parseDateWithTimeZone";
import ReportHelp from "./reportHelp";
import { COMMON_ACCESS_ACTION } from "../../../constants";

enableRipple(true);
// company dashboard component
const DataReport = (props) => {
  const { accessRights: aR } = props;
  const accessPermission = true;
  const productVariant = getProductVariant();
  const [dateRange, setDateRange] = useState(null);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [isUpdated, setIsUpdated] = useState(undefined);
  const [startDate, setStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionSearch = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SEARCH);

  // specify breadcrumb here
  const breadCrumbLinks = [{ linkUrl: "/report/internal-user", linkName: aR.moduleName, linkActive: true }];

  const initialState = {
    selUsers: [],
  };
  const fnStartDate = () => {
    let date = new Date();
    let date2 = new Date();
    date.setDate(date.getDate() - 7);
    const lastDate = date2
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    date2.setDate(date2.getDate());
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    setStartDate(formattedDate);
    setEndDate(lastDate);
    setDateRange([date, date2]);
    localStorage.setItem("dateRange2", [date, date2]);
  };
  useEffect(() => {
    fnStartDate();
  }, []);
  useEffect(() => {
    setIsUpdated(Date.now());
  }, [dateRange]);

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
  });
  const dispatch = useDispatch();

  const UsersDropDown = (props) => {
    const [value, setValue] = useState([]);
    const [users, setUsers] = useState([]);
    useEffect(() => {
      let name = props.name;
      if (selectedUser !== undefined) setValue(props.formik.values[name]);
    }, [props.formik.values, props.name]);
    // debounce search
    const searchUsers = useCallback(
      debounce(async (value) => {
        if (value.length > 1) {
          let filters = [];

          if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
          filters.push(["userType", "eq", "Internal"]);
          let val = value.split(" ");
          filters.push(["userFname", "cn", val[0].trim()]);
          let fields = { fields: ["id", "userFname", "userLname"] };
          const response = await fetchInternalUser({ filters: filters, sort: "userFname:asc", ...fields });
          if (response.data && response.data.data) {
            setUsers(response.data.data);
          }
        }
      }, 300),
      []
    );

    return (
      <CreatableSelect
        {...props}
        isValidNewOption={() => false}
        noOptionsMessage={() => <div>Please type at least 2 characters</div>}
        options={users.map((option) => {
          return { label: `${option.userFname} ${option.userLname}`, value: option.id };
        })}
        value={value}
        onChange={(selectedOption) => {
          selectedOption ? setValue({ ...value, id: selectedOption }) : setValue([]);
          selectedOption
            ? setSelectedUser(
                selectedOption.map((v) => {
                  return v.value;
                })
              )
            : setSelectedUser(undefined);
          const selectedType = selectedOption ? selectedOption : [];
          setValue(selectedType);
          props.formik.setFieldValue("selUsers", selectedType);
        }}
        onInputChange={(inputValue) => {
          inputValue && searchUsers(inputValue);
        }}
      />
    );
  };

  // specify column names for column fields in datGrid here
  const columnNames = {
    "User Name": "user",
    Email: "userEmail",
    Total: "totalScreened",
    Selected: "selected",
    Hold: "hold",
    Rejected: "reject",
    Added: "add",
    Deleted: "delete",
    Processed: "processing",
    Reworked: "rework",
    "Processed ": "productProcessed",
    "Reworked ": "productReworked",
    "Total ": "totQc",
    Accepted: "qcAccepted",
    "Sent Back": "qcRework",
    "Rejected ": "qcReject",
    Pending: "totPendingToBeQc",
    Published: "totPublished",
    "Not Published": "totPendingToBePublished",
    "Companies Published": "totCompanyPublished",
    "Products Published": "totProductPublished",
    Fresh: "newsFresh",
    "Published ": "newsPublished",
  };

  const launchPopup = (recordIds, columnName, user) => {
    let params = {};
    let defaultFilter = [];
    let filters = [];
    if (dateRange) {
      const dt = localStorage.getItem("dateRange2").split(",");
      let gte = { d: new Date(dt[0]) };
      Date.prototype.toJSON = function () {
        return moment(this).format();
      };

      gte.d.toJSON = function () {
        return moment(this).format();
      };

      let lt = { d: moment(new Date(dt[1])).add(1, "day") };
      Date.prototype.toJSON = function () {
        return moment(this).format();
      };
      lt.d.toJSON = function () {
        return moment(this).format();
      };

      filters.push(["logDatetime", "gte", gte.d]);
      filters.push(["logDatetime", "lt", lt.d]);
    }
    filters.push(["recordDetails.recordId", "in", recordIds]);
    switch (columnName) {
      case "Selected":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "screening"]);
        filters.push(["recordDetails.recordActionType", "eq", "select"]);
        break;
      case "Hold":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "screening"]);
        filters.push(["recordDetails.recordActionType", "eq", "hold"]);
        break;
      case "Rejected":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "screening"]);
        filters.push(["recordDetails.recordActionType", "eq", "reject"]);
        break;
      case "Added":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordActionType", "eq", "add"]);
        break;
      case "Deleted":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordActionType", "eq", "delete"]);
        break;
      case "Processed":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "processing"]);
        filters.push(["recordDetails.recordActionType", "eq", "finalSubmit"]);
        break;
      case "Reworked":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "processing"]);
        filters.push(["recordDetails.recordActionType", "eq", "finalSubmit"]);
        filters.push(["recordDetails.recordQueueStatus", "eq", "PROCESSING_REWORK"]);
        break;
      case "Processed ":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "processing"]);
        filters.push(["recordDetails.recordActionType", "eq", "finalSubmit"]);
        break;
      case "Reworked ":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "rework"]);
        filters.push(["recordDetails.recordQueueStatus", "eq", "PROCESSING_REWORK"]);
        break;
      case "Accepted":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "qcComplete"]);
        break;
      case "Sent Back":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "eq", "rework"]);
        break;
      case "Rejected ":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "qc"]);
        filters.push(["recordDetails.recordActionType", "in", ["rejectAndReallocate", "reject"]]);
        break;
      case "Companies Published":
        filters.push(["recordType", "eq", "company"]);
        filters.push(["recordDetails.recordAction", "eq", "publishing"]);
        filters.push(["recordDetails.recordActionType", "eq", "publishing"]);

        break;
      case "Products Published":
        filters.push(["recordType", "eq", "product"]);
        filters.push(["recordDetails.recordAction", "eq", "publishing"]);
        filters.push(["recordDetails.recordActionType", "eq", "publishing"]);

        break;
      case "Fresh":
        filters.push(["recordType", "eq", "news"]);
        filters.push(["recordDetails.recordAction", "eq", "processing"]);
        filters.push(["recordDetails.recordActionType", "eq", "saveSubmit"]);
        break;
      case "Published ":
        filters.push(["recordType", "eq", "news"]);
        filters.push(["recordDetails.recordAction", "eq", "publishing"]);
        filters.push(["recordDetails.recordActionType", "eq", "publishing"]);
        break;

      default:
        break;
    }

    params.sort = "logDatetime:desc";
    params.fields = ["recordDetails.recordId", "recordDetails.recordName", "logDatetime", "recordType"];
    params.filters = [...filters, ...defaultFilter];
    params.sort = "logDatetime:desc";
    fetchActivityLog({ ...params })
      .then(async (res) => {
        if (res.data.data && res.data.data.length) {
          let recordName = res.data.data[0].recordType;
          let productsDetails = {};
          if (recordName === "product") {
            let c = await fetchProductAll({ filters: [["_id", "in", recordIds]], limit: recordIds.length, fields: ["productCompany"] });
            if (c && c.data.totalCount) productsDetails = c.data.data;
          }
          const objData = res.data.data.map((r) => {
            if (r.recordDetails.recordName) {
              const obj = {};
              obj[`${recordName.charAt(0).toUpperCase() + recordName.substr(1).toLowerCase()}`] = r.recordDetails.recordName;
              if (r.recordType === "product" && productsDetails && productsDetails.length) {
                let c = productsDetails.filter((p) => p.id.toString() === r.recordDetails.recordId.toString());
                if (c && c.length) obj[`Company`] = c[0].productCompany.companyName;
              }
              obj[`Date-Time (IST)`] = formatDateGMTtoIST(r.logDatetime, "DD MMM YYYY HH:mm");
              return obj;
            }
          });

          Promise.allSettled(objData).then((results) => {
            let json = [];
            let errorArr = [];
            results.forEach((res) => {
              if (res.status === "fulfilled") {
                if (res.value) json.push(res.value);
              } else {
                if (res.value) errorArr.push(JSON.parse(res.value));
              }
            });
            dispatch(
              showPopup({
                width: `40%`,
                title: `<h2 class="text-secondary" style="font-weight: 700">Activity Details of ${user} (${columnName})</h2>`,
                okText: "OK",
                showCancelButton: false,
                content: () => <JsonToTable json={json} />,
              })
            );
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userTemplate = (value) => {
    return (
      <div title={`${value.Email}`}>
        {value["User Name"]}
        {/* <br />{`(${value.Email})`} */}
      </div>
    );
  };
  const recordsTemplate = (value) => {
    const a = value[`${value.column.field}`];
    if (a) {
      if (a.length && typeof a === "object") {
        return (
          <span
            className="span-link-color"
            onClick={() => {
              launchPopup(a, value.column.field, value["User Name"]);
            }}
          >
            {a.length}
          </span>
        );
      } else if (typeof a === "number") {
        return <div>{a}</div>;
      } else {
        return <div>-</div>;
      }
    } else {
      return <div>-</div>;
    }
  };

  const customAttributes = () => {
    return { class: "orientationcss" };
  };
  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean
  const commonProperties = { allowFiltering: false, allowSorting: false, textAlign: "Center", headerTextAlign: "Center", type: "String" };
  const columnFields = [
    {
      field: "User Name",
      width: 150,
      type: "String",
      allowFiltering: false,
      allowSorting: false,
      template: userTemplate,
      textAlign: "Left",
      headerTextAlign: "Center",
      showInColumnChooser: false,
    },
    // { field: "Email", type: "String", allowFiltering: false, visible: false, showInColumnChooser: false },
    {
      textAlign: "Center",
      headerText: "Company Screening",
      columns: [
        { field: "Total", customAttributes: { customAttributes }, width: "45px", template: recordsTemplate, ...commonProperties },
        { field: "Selected", width: "73px", template: recordsTemplate, ...commonProperties },
        { field: "Hold", width: "55px", template: recordsTemplate, ...commonProperties },
        { field: "Rejected", width: "73px", template: recordsTemplate, ...commonProperties },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Company",
      columns: [
        { field: "Added", width: "65px", template: recordsTemplate, ...commonProperties },
        { field: "Deleted", width: "68px", template: recordsTemplate, ...commonProperties },
      ],
    },

    {
      textAlign: "Center",
      headerText: "Profiling",
      columns: [
        {
          textAlign: "Center",
          headerText: "Companies",
          columns: [
            { field: "Processed", width: "85px", template: recordsTemplate, ...commonProperties },
            { field: "Reworked", width: "85px", template: recordsTemplate, ...commonProperties },
          ],
        },
        {
          textAlign: "Center",
          headerText: "Products",
          columns: [
            { field: "Processed ", width: "85px", template: recordsTemplate, ...commonProperties },
            { field: "Reworked ", width: "85px", template: recordsTemplate, ...commonProperties },
          ],
        },
      ],
    },
    {
      textAlign: "Center",
      headerText: "Company QC",
      columns: [
        {
          textAlign: "Center",
          headerText: "QC-ed",
          columns: [
            { field: "Total ", width: "60px", template: recordsTemplate, ...commonProperties },
            { field: "Accepted", width: "80px", template: recordsTemplate, ...commonProperties },
            { field: "Sent Back", width: "70px", template: recordsTemplate, ...commonProperties },
            { field: "Rejected ", width: "80px", template: recordsTemplate, ...commonProperties },
          ],
        },
        { field: "Pending", width: "80px", template: recordsTemplate, ...commonProperties },
        { field: "Published", width: "90px", template: recordsTemplate, ...commonProperties },
        { field: "Not Published", width: "100px", template: recordsTemplate, ...commonProperties },
      ],
    },

    { field: "Companies Published", width: "90px", template: recordsTemplate, ...commonProperties },
    { field: "Products Published", width: "100px", template: recordsTemplate, ...commonProperties },
    {
      textAlign: "Center",
      headerText: "News",
      columns: [
        { field: "Fresh", width: "60px", template: recordsTemplate, ...commonProperties },
        { field: "Published ", width: "90px", template: recordsTemplate, ...commonProperties },
      ],
    },
    // { field: `${(productVariant && productVariant === "Insider") ? "Trend" : "Technology"}`, type: "String", filterTemplate: ItemFilter, textAlign: "Left", headerTextAlign: "Left" },
  ];

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = {};
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
      });
      return newRD;
    });
    return formatedRowData;
  };

  const fetchReportInternalUserWithProductVariant = (params = {}) => {
    let defaultFilter = [];

    const loggedInUser = getLoggedInUser();
    if (!isAdmin() && !accessPermission) defaultFilter.push(["userId", "in", [loggedInUser._id]]);
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let filters = [];
    if (selectedUser) filters.push(["userId", "in", selectedUser]);
    if (dateRange) {
      let gte = { d: dateRange[0] };
      Date.prototype.toJSON = function () {
        return moment(this).format();
      };

      gte.d.toJSON = function () {
        return moment(this).format();
      };

      let lt = { d: moment(dateRange[1]).add(1, "day") };
      Date.prototype.toJSON = function () {
        return moment(this).format();
      };
      lt.d.toJSON = function () {
        return moment(this).format();
      };

      filters.push(["logDatetime", "gte", gte.d]);
      filters.push(["logDatetime", "lt", lt.d]);
    }
    params.filters = [...filters, ...defaultFilter];

    return fetchReportInternalUser({ ...params });
  };

  const onQueryCellInfo = (args) => {
    if (args.column.field === "Action") {
      args.colSpan = 3;
    }
  };
  const handleReset = () => {
    window.location.reload();
    setSelectedUser(undefined);
    setDateRange(null);
    localStorage.removeItem("dateRange2");
    fetchReportInternalUserWithProductVariant().then((res) => {
      if (res.data) setIsUpdated(Date.now());
    });
  };
  const handleSearch = () => {
    fetchReportInternalUserWithProductVariant().then((res) => {
      if (res.data) setIsUpdated(Date.now());
    });
  };
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <div className="p-3" style={{ maxHeight: "95vh" }}>
      <div className="col-md-12 border rounded-bottom nopadding p-0 bg-white">
        <div className="row ml-2">
          <div className="col-3">
            <div className="form-group ">
              <label htmlFor="classification"></label>
              <UsersDropDown
                formik={formik}
                id="selUsers"
                name="selUsers"
                placeholder="Type min 2 chars to search users"
                minMenuHeight={100}
                maxMenuHeight={150}
                menuPlacement="auto"
                isSearchable
                isClearable
                isMulti
              />
            </div>
          </div>
          <div className="col-md-2 mt-4">
            <DateRangePickerComponent
              placeholder="Date Range"
              openOnFocus
              showClearButton
              format="dd-MMM-yyyy"
              separator="to"
              cssClass="e-outline"
              floatLabelType="Never"
              // value={dateRange ? dateRange : ""}
              startDate={startDate}
              endDate={endDate}
              onChange={(e) => {
                if (e.value) setDateRange(e.value);
                localStorage.setItem("dateRange2", e.value);
              }}
            ></DateRangePickerComponent>
          </div>
          <div className="col-md-4 ">
            {accessActionSearch && (
              <button type="button" onClick={handleSearch} className="mt-4 search-btn px-4 btn btn-primary">
                Search
              </button>
            )}
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 mt-4 ml-2 ">
              Reset
            </button>
            <ReportHelp name="Internal User" />
          </div>
        </div>
      </div>
      <div className="gennx-grid-container gennx-grid-container-report">
        {dateRange && (
          <CommonDataGrid
            gridTitle="Internal User Report"
            fetch={fetchReportInternalUserWithProductVariant}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            deleteId={isUpdated}
            isPaging={false}
            freezeColumn={1}
            showResetFilter={false}
            autoFitColumn={false}
            allowSelection={false}
            queryCellInfo={onQueryCellInfo}
            showColumnChooser={false}
            deleteMany={false}
            isReportGrid={true}
          />
        )}
      </div>
    </div>
  );
};

export default DataReport;
