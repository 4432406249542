/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : DefaultLayout Higher Level Component for rendering Components with Non-Authenticated Routes
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React from "react";
import PropTypes from "prop-types";
import Utility from "../../components/utility";

const DefaultLayout = (props) => {
	const { component: Component, pageHeading = "", ...rest } = props;
	document.title = pageHeading;
	return (
		<div className="wrapper">
			<Component pageHeading={pageHeading || ""} {...rest} />
			<Utility />
		</div>
	);
};

export default DefaultLayout;

DefaultLayout.propTypes = {
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
	children:PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	pageHeading: PropTypes.string,
};
