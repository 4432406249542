/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  User Assessment Popup Component for Add/Edit
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 13/Jul/2021 
    Author						: Pankaj Kulshreshtha
================================================================ 
*/
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";

import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import { userAssessmentValidationSchema } from "../../../../helper/validationSchema/externalUserValidationSchema";
import { Modal } from "react-bootstrap";
import UploadComponent from "../../../../components/uploadComponent";
import {
  saveUserAssessment,
  fetchUserSingleAssessment,
  updateUserAssessment,
  fetchUserAssessmentDocUrl,
} from "../../../../../middleware/services/userApi";
import { fetchAssessment, fetchAssessmentById } from "../../../../../middleware/services/cmsApi";
import { Dropdown, AutoCompleteDropdown, RadioGroup } from "../../../../components/CustomFormElements/syncFusionFormFields";

const initialFormValues = {
  assessmentName: "",
  assessmentCompetencyRating: [],
  documentFile: "",
  documentFileName: "",
  assessmentStatus: "",
};
const AssessmentModal = (props) => {
  const dispatch = useDispatch();
  const [fileUrl, setFileUrl] = useState("");
  const [functionalCompetancy, setFunctionalCompetancy] = useState([]);
  const [allowCustom, setAllowCustom] = useState(false);
  const [editData, setEditData] = useState({});
  const [id, setId] = useState("");

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(userAssessmentValidationSchema),
  });
  const searchVal = useCallback(
    debounce(async (e) => {
      let filters = [];
      filters.push(["assessmentName", "cn", e.text]);
      let fields = { fields: ["assessmentName"] };
      const response = e.text.length > 1 && (await fetchAssessment({ filters: filters, ...fields, sort: "assessmentName:asc", limit: 10 }));
      if (response.data && response.data.data) {
        const resData = response.data.data.map((v) => {
          return { label: v.assessmentName, value: v.id };
        });
        if (resData) {
          e.updateData(resData);
        } else {
          e.updateData([]);
        }
      }
    }, 300),
    []
  );

  const handleData = (data) => {
    data && setId(data.itemData.value);
    getFunctionalCompetency(data.itemData.value);
  };

  const getFunctionalCompetency = async (fid) => {
    let params = {};
    let defaultFilter = [];
    let fields = ["assessmentFunctionalCompetency", "assessmentEvaluationParams"];
    params.fields = fields;
    params.filters = [...defaultFilter];
    fid &&
      (await fetchAssessmentById(fid, params).then((res) => {
        let data = res.data.data || {};
        data && setFunctionalCompetancy(data.assessmentFunctionalCompetency);
        dispatch(hideLoader());
      }));
  };

  const FunctionalCompetancy = () => {
    return functionalCompetancy?.map((v, i) => {
      return (
        <React.Fragment key={`func_${i}`}>
          <div className="row">
            <div className="col-md-7">{v}</div>
            <div className="col-md-5">
              {
                <Dropdown
                  formik={formik}
                  id={`rating-${i}`}
                  name={`rating-${i}`}
                  floatLabelType="Auto"
                  placeholder="Rating (out of 5)"
                  dataSource={[1, 2, 3, 4, 5]}
                />
              }
              {/* <StarRating /> */}
            </div>
          </div>
        </React.Fragment>
      );
    });
  };
  const addAssessment = () => {
    //Submit Form and send data to API
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          // dispatch(actionError(res[field]));
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        // return
        const competancydata =
          functionalCompetancy &&
          functionalCompetancy
            .map((v, i) => {
              return formik.values[`rating-${i}`] && { competency: v, rating: formik.values[`rating-${i}`] };
            })
            .filter((f) => {
              return f !== undefined;
            });

        const { assessmentName, documentFile, assessmentStatus } = formik.values;
        payload.assessmentName = assessmentName;
        payload.assessmentStatus = assessmentStatus;
        payload.assessmentTestResultFile = documentFile && documentFile[0];
        if (editData) {
          if (!documentFile[0]) delete payload.assessmentTestResultFile;
        }
        if (competancydata) {
          payload.assessmentCompetencyRating = competancydata;
        }

        payload.assessmentId = id ? id : props.assessmentId;

        const formData = new FormData();
        for (let key in payload) {
          formData.append(key, payload[key]);
        }
        formData.delete("assessmentCompetencyRating");
        competancydata && formData.append("assessmentCompetencyRating", JSON.stringify(payload.assessmentCompetencyRating));

        if (props.assessmentId !== "") {
          updateUserAssessment(props.userId, props.assessmentId, formData)
            .then((response) => {
              props.closeModal();
              dispatch(actionSuccess("User assessment updated successfully"));
              props.formatRowData(props.userId);
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Someting went wrong"));
            });
        } else {
          saveUserAssessment(props.userId, formData)
            .then((response) => {
              props.closeModal();
              dispatch(actionSuccess("User assessment added successfully"));
              props.formatRowData(props.userId);
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Someting went wrong"));
            });
        }
      }
    });
  };

  useEffect(() => {
    if (props.userId === "") {
      props.closeModal();
      dispatch(actionError("Please add basic details first"));
    }
    props.assessmentId && getFunctionalCompetency(props.assessmentId);
  }, [props.showModal]);

  useEffect(() => {
    if (props.assessmentId && functionalCompetancy && functionalCompetancy.length > 0) {
      dispatch(showLoader());
      fetchUserSingleAssessment(props.userId, props.assessmentId).then((response) => {
        dispatch(hideLoader());
        if (response.status === 200) {
          var assessment = response.data.data;
          if (assessment) {
            setEditData(assessment);
            formik.setValues({
              assessmentName: assessment?.assessmentName,
              assessmentCompetencyRating: assessment?.assessmentCompetencyRating,
              documentFile: assessment?.assessmentTestResultFile?.docFileName,
              assessmentStatus: assessment?.assessmentStatus,
            });

            const competancy = assessment.assessmentCompetencyRating;
            functionalCompetancy.map((v, i) => {
              formik.setFieldValue(
                `rating-${i}`,
                Number(
                  competancy
                    .map((c) => {
                      return v === c.competency && c.rating;
                    })
                    .filter((f) => f !== false)
                    .join()
                )
              );
            });
            if (assessment?.assessmentTestResultFile) {
              const { docFileName = "", docName = "" } = assessment?.assessmentTestResultFile;
              setFileUrl(docFileName && fetchUserAssessmentDocUrl(docFileName, docName));
            }
          }

          id &&
            props.assessmentId !== id &&
            functionalCompetancy &&
            functionalCompetancy.map((v, i) => {
              formik.setFieldValue(`rating-${i}`, null);
            });
        }
      });
    }
  }, [functionalCompetancy]);

  return (
    <Modal show={props.showModal} backdrop="static" className="modal fade lg people-mgmt" aria-labelledby="contained-modal-title-vcenter" centered>
      <form id="addExp" method="post">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {props.assessmentId ? "Update" : "Add"} Assessment
          </h5>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <AutoCompleteDropdown
                formik={formik}
                id="assessmentName"
                name="assessmentName"
                placeholder="Assessment Name *"
                value={editData.assessmentName}
                fields={{ value: "label", id: "value" }}
                filtering={searchVal}
                select={handleData}
                allowFiltering={true}
                // allowCustom={false}
                allowCustom={(editData && !allowCustom) || allowCustom}
                // allowCustom={ editData && formik.values.assessmentName===editData.assessmentName?true:allowCustom}

                // noRecordsTemplate={() => <div>No data found</div>}
              />
            </div>
          </div>
          <span class="pb-4">
            <b>Functional Competency</b>
          </span>
          <FunctionalCompetancy assessmentId={props.assessmentId} />
          <div className="row">
            <div className="col-md-12">
              <RadioGroup
                formik={formik}
                type="radio"
                optionArr={["Pass", "Fail"]}
                properties={{
                  placeholder: "Status",
                  name: "assessmentStatus",
                  label: "Status",
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <UploadComponent
                editDocumentData={editData}
                fileUrl={fileUrl || ""}
                docFileName={editData && editData.assessmentTestResultFile && editData.assessmentTestResultFile.docName}
                buttonText="Upload Document"
                validationText="Only pdf, xls/xlsx, ppt/pptx files are allowed"
                btnClass="btn btn-dark"
                acceptedFiles=".pdf,.xls,.xlsx,.ppt,.pptx"
                formik={formik}
              />
            </div>
            <div className="col-md-12">
              {formik.values.documentFile &&
                typeof formik.values.documentFile === "object" &&
                formik.values.documentFile.map((file, i) => (
                  <li key={i}>
                    {`File:${file.name} 												
												`}
                  </li>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-dark cy-btn mr-3"
            onClick={() => {
              props.closeModal();
            }}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary cy-btn" onClick={addAssessment}>
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AssessmentModal;
