/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Bread Crumb State
----------------------------------------------------------------
	Creation Details
	Date Created				: 28/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { SET_BREAD_CRUMB } from "../../constants/actionConstants";

const DEFAULT_STATE = {
	breadCrumbsLinks: [],
};

export default (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case SET_BREAD_CRUMB: {
			return { ...state, breadCrumbsLinks: [...action.payload] };
		}
		default:
			return { ...state };
	}
};
