/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : User Validation Schema
----------------------------------------------------------------
	Creation Details
	Date Created				: 03/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import * as Yup from "yup";
import * as REGEX from "../../../config/regexValidation"
export const userValidationSchema = {
	userFname: Yup.string().min(2, "First name must be minimum 2 characters").required("First name is required"),
	userLname: Yup.string().min(2, "Last name must be minimum 2 characters").required("Last name is required"),
	userEmail: Yup.string().typeError("Please enter valid email").email("Please enter valid email").required("Email is required"),
	twitter: Yup.string().matches(REGEX.TWITTER, "Please enter valid twitter URL"),
	facebook: Yup.string().matches(REGEX.FACEBOOK, "Please enter valid facebook URL"),
	linkedin: Yup.string().matches(REGEX.LINKEDIN, "Please enter valid LinkedIn URL"),
	userPassword: Yup.string().required("New password is required").min(6).max(18),
	// .test("userPassword", "Must be minimum 6 characters", userPassword => userPassword && userPassword.length < 6)
	// .test("userPassword", "Must be maximum 18 characters", userPassword => userPassword && userPassword.length > 18),
	userConfirmPassword: Yup.string().oneOf([Yup.ref("userPassword"), null], "Your password and confirm password do not match").required("Confirm new password is required"),
	// .test("userConfirmPassword", "Must be minimum 6 characters", userConfirmPassword => userConfirmPassword && userConfirmPassword.length < 6)
	// .test("userConfirmPassword", "Must be maximum 18 characters", userConfirmPassword => userConfirmPassword && userConfirmPassword.length > 18),
	userDesignation: Yup.string(),
	userMobile: Yup.number().typeError("Please enter valid mobile number").required("Please enter mobile number"),
	userLoginId: Yup.string(),
	userCompany: Yup.string(),
	userActivationDate: Yup.date().max(new Date(), "User activation date must be less than or equal to present date").typeError("Please select user activation date"),
	userEmpCode: Yup.string().min(2).notOneOf([""]),
	country: Yup.string().required("Please select country").typeError("Please select country"),
	addressType: Yup.string().required("Please select address type"),
};
export const outSourcingUserValidationSchema = {
	showOTP: Yup.boolean(),
	userFname: Yup.string().matches(REGEX.ALPHABATES, 'special character, number and double space not allowed').min(2, "First name must be minimum 2 characters").required("Please enter first name"),
	userLname: Yup.string().matches(REGEX.ALPHABATES, 'special character,number and double space not allowed').min(2, "Last name must be minimum 2 characters").required("Please enter last name"),
	userEmail: Yup.string().typeError("Please enter valid email").email("Please enter valid email").required("Please enter email"),
	userMobile: Yup.string().matches(REGEX.PHONE, 'Please enter valid mobile number').min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits').required("Please enter mobile number"),
	institutionName: Yup.string(),
	educationYear: Yup.string(),
	cityName: Yup.string(),
	countryName: Yup.string().required("Please select a country"),
	address1: Yup.string(),
	address2: Yup.string(),
	postCode: Yup.string(),
	technologyInterest: Yup.array().required("Please select technology interest").nullable(),
	industryInterest: Yup.array().required("Please select industry interest").nullable(),
	equipedWithLaptop: Yup.string().oneOf(["yes", "no"], 'Field must be checked').required("Please choose a option"),
	userEmailOTP: Yup.string().when('showOTP', {
		is: true,
		then: Yup.string().required("Please enter email OTP").min(5, "Please enter 5 digits OTP").max(5, "Please enter 5 digits OTP"),
		otherwise: Yup.string().nullable().oneOf([null, undefined], 'Null'),
	}),
	userMobileOTP: Yup.string().when('showOTP', {
		is: true,
		then: Yup.string().required("Please enter mobile OTP").min(5, "Please enter 5 digits OTP").max(5, "Please enter 5 digits OTP"),
		otherwise: Yup.string().nullable().oneOf([null, undefined], 'Null'),
	}),
	userTncAccepected: Yup.bool().oneOf([true], "Please accept Terms & Conditions").required("Please accept Terms & Conditions")
};

export const addUserValidationSchema = {
	...userValidationSchema
};

export const editUserValidationSchema = {
	...userValidationSchema,
	userFname: Yup.string().min(2, "First name must be minimum 2 characters").required("Please enter first name").notOneOf([""]),
	userLname: Yup.string().min(2, "Last name must be minimum 2 characters").required("Please enter last name").notOneOf([""]),
	userEmail: Yup.string().typeError("Please enter email").email("Please enter valid email").required("Please enter email"),
	userPassword: Yup.string().min(6).max(18),
	// .test("userPassword", "Must be minimum 6 characters", userPassword => userPassword && userPassword.length < 6)
	// .test("userPassword", "Must be maximum 18 characters", userPassword => userPassword && userPassword.length > 18),
	// userConfirmPassword: Yup.string().oneOf([Yup.ref("userPassword"), null], "Passwords must match"),
	userConfirmPassword: Yup.string().oneOf([Yup.ref("userPassword"), null], "Your password and confirm password do not match").when("userPassword", {
		is: (val) => val !== undefined,
		then: Yup.string().required("Confirm new password is required"),
		// otherwise: yup.string().notRequired()
	})
	// .test("userConfirmPassword", "Must be minimum 6 characters", userConfirmPassword => userConfirmPassword && userConfirmPassword.length < 6)
	// .test("userConfirmPassword", "Must be maximum 18 characters", userConfirmPassword => userConfirmPassword && userConfirmPassword.length > 18)
};

export const myProfileValidationSchema = {
	userFname: Yup.string().min(2, "First name must be minimum 2 characters").required("First name is required").notOneOf([""]),
	userLname: Yup.string().min(2, "Last name must be minimum 2 characters").required("Last name is required").notOneOf([""]),
	userEmail: Yup.string().typeError("Please enter valid email").email("Please enter valid email").required("Email is required"),
	twitter: Yup.string().matches(REGEX.TWITTER, "Please enter valid twitter URL"),
	facebook: Yup.string().matches(REGEX.FACEBOOK, "Please enter valid facebook URL"),
	linkedin: Yup.string().matches(REGEX.LINKEDIN, "Please enter valid LinkedIn URL"),
	userPassword: Yup.string().min(6).max(18),
	// .test("userPassword", "Must be minimum 6 characters", userPassword => userPassword && userPassword.length < 6)
	// .test("userPassword", "Must be maximum 18 characters", userPassword => userPassword && userPassword.length > 18),
	userConfirmPassword: Yup.string().oneOf([Yup.ref("userPassword"), null], "Your password and confirm password do not match").when("userPassword", {
		is: (val) => val !== undefined,
		then: Yup.string().required("Confirm new password is required"),
		// otherwise: yup.string().notRequired()
	}),
	// .test("userConfirmPassword", "Must be minimum 6 characters", userConfirmPassword => userConfirmPassword && userConfirmPassword.length < 6)
	// .test("userConfirmPassword", "Must be maximum 18 characters", userConfirmPassword => userConfirmPassword && userConfirmPassword.length > 18),
	userDesignation: Yup.string(),
	userMobile: Yup.number().typeError("Please enter valid mobile number").required("Please enter mobile number"),
	userLoginId: Yup.string(),
	userCompany: Yup.string(),
	userActivationDate: Yup.date().max(new Date()).typeError("Please select user activation date"),
	userEmpCode: Yup.string().min(2).notOneOf([""]),
	country: Yup.string().required("Please select country").typeError("Please select country"),
	addressType: Yup.string().required("Please select address type"),
};
// work experiance validation schema
export const experianceValidationSchema = {
	workCompanyName: Yup.string().trim("Please enter company name").required("Please enter company name"),
	workRole: Yup.string().trim("Please select work role").required("Please select work role"),
	workStartDate: Yup.string().trim("Please enter start date").required("Please enter start date"),
	workDesignation: Yup.string().trim("Please enter designation").required("Please enter designation"),
	workEndDate: Yup.string().when("workCurrentCompany", {
		is: (val) => val === "No",
		then: Yup.string().required("Please enter end date"),
		// otherwise: yup.string().notRequired()
	})
};
// education validation schema
export const educationValidationSchema = {
	eduInstituteName: Yup.string().trim("Please enter university / institute name").required("Please enter university / institute name"),
	eduQualification: Yup.string().trim("Please enter qualification").required("Please enter qualification"),
	eduPassingYear: Yup.string().trim("Please select passing out year").required("Please select passing out year"),
	eduField: Yup.string().trim("Please select educational field / stream").required("Please select educational field / stream")
};

// User assessment validation schema
export const userAssessmentValidationSchema = {
	assessmentName: Yup.string().trim("Please enter assessment name").typeError("Please enter assessment name").required("Please enter assessment name"),
	assessmentStatus: Yup.string().trim("Please select assessment status").required("Please select assessment status"),
	documentFile: Yup.mixed().required("Please upload document"),
};

