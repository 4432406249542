import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader } from "../../../../../middleware/actions/utilityAction";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "../formFields";
import { addDocumentValidationSchema, editDocumentValidationSchema } from "../validationSchema";
import UploadComponent from "../../../../components/uploadComponent";
import { fetchDocUrl, getSingleDocument } from "../../../../../middleware/services/videoApi";

const PopupModal = (props) => {
  const [saveBtnText, setSaveBtnText] = useState("Save");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [editData, setEditData] = useState(null);

  const initialFormValues = {
    documentTitle: "",
    documentFile: "",
    documentFileName: "",
  };

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(editData ? editDocumentValidationSchema : addDocumentValidationSchema),
  });

  const dispatch = useDispatch();

  const handleReset = () => {
    formik.resetForm();
  };
  const saveDocumentData = async () => {
    const { values } = formik;
    const videoDocumentData = {
      docTitle: values.documentTitle,
      docFile: values.documentFile && values.documentFile[0],
    };
    if (editData) {
      if (!values.documentFile[0]) delete videoDocumentData.docFile;
    }
    const response = (await editData) ? props.onUpdateData(props.documentId, videoDocumentData) : props.onSaveData(videoDocumentData);
    setSaveBtnText("SAVING...");
    setBtnDisabled(true);
    response.then((result) => {
      setSaveBtnText("SAVE");
      setBtnDisabled(false);
      !editData && handleReset();
    });
  };
  const handleSubmitDocument = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        saveDocumentData();
      }
    });
  };
  const fetchSingleDocument = () => {
    let params = {};
    let defaultFilter = [];
    let fields = ["docTitle", "docPath", "docName", "docFileName"];
    params.fields = fields;
    if (props.productVariant) defaultFilter.push(["productVariant.productName", "eq", props.productVariant]);
    params.filters = [...defaultFilter];
    getSingleDocument(props.videoId, props.documentId, params).then((res) => {
      let data = res.data.data || {};
      setEditData(data);
      data &&
        formik.setValues({
          documentTitle: data.videoDocuments.docTitle,
          documentFile: "",
        });
      dispatch(hideLoader());
    });
  };
  useEffect(() => {
    props.documentId && fetchSingleDocument();
  }, [props.documentId]);

  return (
    <Modal
      // size="lg"
      show={props.isOpen}
      onHide={() => {
        props.closeHandler();
      }}
      centered
    >
      <Modal.Header className="" style={{ borderBottom: "none" }}>
        <Modal.Title>{props.popupTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <TextField formik={formik} placeholder="Document Title *" id="documentTitle" name="documentTitle" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <UploadComponent
              editDocumentData={editData}
              fileUrl={
                editData && editData?.videoDocuments?.docFileName && fetchDocUrl(editData.videoDocuments.docFileName, editData.videoDocuments.docName)
              }
              docFileName={editData && editData.videoDocuments?.docName}
              buttonText="Upload Document"
              validationText="PDF file only"
              btnClass="btn btn-dark"
              acceptedFiles=".pdf"
              formik={formik}
            />
            {formik.values.documentFile &&
              typeof formik.values.documentFile === "object" &&
              formik.values.documentFile.map((file, i) => (
                <li key={i}>
                  {`File:${file.name} 												
												`}
                </li>
              ))}
          </div>
        </div>

        <Modal.Footer style={{ borderTop: "none", "margin-right": "-16px" }}>
          <button type="button" onClick={() => props.closeHandler()} className="btn btn-outline-dark cy-btn mr-3">
            Cancel
          </button>
          <button type="button" onClick={handleSubmitDocument} disabled={btnDisabled} className="btn btn-primary cy-btn">
            {saveBtnText}
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default PopupModal;
PopupModal.propTypes = {
  history: PropTypes.object.isRequired,
  videoId: PropTypes.string,
  documentId: PropTypes.string,
  onSaveData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
