import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonDataGrid from "../../components/dataGrid";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { fetchAssessment, deleteAssessment, updateAssessment } from "../../../middleware/services/cmsApi";
import { swap } from "../../../utilities";
import { actionSuccess, actionError } from "../../../middleware/actions/utilityAction";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import ThreeDotMenu from "../../components/threeDotMenu";
import { setMaintainedState, visitRecord } from "../../../middleware/actions/gridAction";

const Assessment = (props) => {
  const { accessRights: aR } = props;
  const gridState = useSelector((state) => state.gridState);
  const [deleteId, setDeleteId] = useState(undefined);
  const history = useHistory();
  const dispatch = useDispatch();

  const accessPermissionAdd = true;
  const accessPermissionEdit = true;
  const accessPermissionDelete = true;

  const breadCrumbLinks = [{ linkUrl: "/assessment", linkName: aR.moduleName || "Assessment", linkActive: true }];
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/assessment"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  const updateVisibilty = async (assessmentId, payload) => {
    await updateAssessment(assessmentId, payload)
      .then((res) => {
        if (res) {
          setDeleteId(new Date());
          dispatch(actionSuccess("Is Active updated successfully"));
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
    setDeleteId(new Date());
  };

  const visibiltyTemplate = (value) => {
    return (
      <div className="text-center">
        <label className="custom-switch3">
          <input
            type="checkbox"
            value={value["Is Active"]}
            checked={value["Is Active"] == "Active" ? true : false}
            onChange={(e) => {
              let payload = {
                assessmentStatus: value["Is Active"] == "Active" ? "Inactive" : "Active",
              };
              updateVisibilty(value.id, payload);
            }}
          />
          <div>
            <span className="on">Yes</span>
            <span className="off">No</span>
          </div>
          <i></i>
        </label>
      </div>
    );
  };
  /* 
        @Description : Template Component for action column
    */
  const actionTemplate = (value) => {
    let edit = false;
    if (accessPermissionEdit) {
      edit = () => {
        history.push(`/assessment/${value.id}/edit`);
        dispatch(visitRecord());
      };
    }
    let deleteFlag = false;
    if (accessPermissionDelete) {
      deleteFlag = () => {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              deleteAssessment(value.id)
                .then((response) => {
                  if (response) {
                    dispatch(actionSuccess("Assessment deleted successfully"));
                    const params = {};
                    params.limit = 100;
                    fetchAssessment(params).then((form) => {
                      formatRowData(form?.data?.data || []);
                    });
                    setDeleteId(new Date());
                  } else {
                    dispatch(actionError("Something went wrong"));
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err?.data?.message || "Failed to delete assessment"));
                });
            },
            content: "Are you sure, you want to delete?",
          })
        );
      };
    }

    return <ThreeDotMenu rowID={value.id} Edit={edit} Delete={deleteFlag} />;
  };

  const assessmentNameTemplate = (value) => {
    return (
      <span
        className={`${accessPermissionEdit && "span-link"}`}
        onClick={() => {
          accessPermissionEdit && history.push(`/assessment/${value.id}/edit`);
          dispatch(visitRecord());
        }}
      >
        {value["Unique Code"]}
      </span>
    );
  };

  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Proficiency Level":
        sourceData = [
          { id: "Beginner", text: "Beginner" },
          { id: "Intermediate", text: "Intermediate" },
          { id: "Expert", text: "Expert" },
        ];
        break;
      case "Is Active":
        sourceData = [
          { id: "Active", text: "YES" },
          { id: "Inactive", text: "NO" },
        ];
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const columnNames = {
    id: "id",
    "Unique Code": "assessmentUniqueCode",
    "Assessment Name": "assessmentName",
    "Assessment Duration": "assessmentDuration",
    "Assessment Link": "assessmentLink",
    "Evaluation Params": "assessmentEvaluationParams~parameterName",
    "Functional Competency": "assessmentFunctionalCompetency",
    "Proficiency Level": "assessmentProficiencyLevel",
    "Is Active": "assessmentStatus",
  };
  const columnFields = [
    {
      field: "id",
      type: "string",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "Unique Code",
      type: "String",
      template: assessmentNameTemplate,
      filterTemplate: StringFilter,
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 170,
    },
    {
      field: "Assessment Name",
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 250,
    },
    { field: "Assessment Duration", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 300 },
    // { field: "Assessment Link", type: "String", filterTemplate: ItemFilter, filter: { operator: "equal" }, textAlign: "Left", headerTextAlign: "Left", width: 100 },
    // { field: "Evaluation Params",headerText: "Evaluation Parameters", type: "String", filterTemplate: ItemFilter, filter: { operator: "equal" }, textAlign: "Left", headerTextAlign: "Left", width: 150 },
    // { field: "Functional Competency", type: "String", filterTemplate: ItemFilter, filter: { operator: "equal" }, textAlign: "Left", headerTextAlign: "Left", width: 150 },
    {
      field: "Proficiency Level",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 200,
    },
    {
      field: "Is Active",
      type: "String",
      template: visibiltyTemplate,
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 130,
    },
    {
      field: "Action",
      type: "String",
      template: actionTemplate,
      filterTemplate: StringFilter,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    },
  ];
  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }

        if (rD.assessmentDuration) {
          newRD["Assessment Duration"] = rD.assessmentDuration + " Minutes";
        }
        if (rD.assessmentEvaluationParams) {
          newRD["Evaluation Params"] =
            rD.assessmentEvaluationParams && rD.assessmentEvaluationParams.length
              ? rD.assessmentEvaluationParams
                  .map((item) => {
                    return item.parameterName;
                  })
                  .filter((item, index, self) => item != "" && self.indexOf(item) === index)
                  .join(", ")
              : "";
        }
        if (rD.assessmentFunctionalCompetency) {
          newRD["Functional Competency"] =
            rD.assessmentFunctionalCompetency && rD.assessmentFunctionalCompetency.length
              ? rD.assessmentFunctionalCompetency
                  .map((item) => {
                    return item;
                  })
                  .filter((item, index, self) => item != "" && self.indexOf(item) === index)
                  .join(", ")
              : "";
        }
        return newRD;
      });
      return newRD;
    });
    return formatedRowData;
  };
  const getAssessment = (params = {}) => {
    params.sort = "_id:desc";
    let defaultFilter = [];
    let fields = [
      "assessmentName",
      "assessmentProficiencyLevel",
      "assessmentDuration",
      "assessmentLink",
      "assessmentEvaluationParams",
      "assessmentUniqueCode",
      "assessmentFunctionalCompetency",
      "assessmentStatus",
    ];
    let { filters = [] } = params;

    params.filters = [...filters, ...defaultFilter];
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    params.fields = fields;
    return fetchAssessment(params);
  };

  // useEffect(() => {
  // }, [formatRowData])

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        <CommonDataGrid
          gridTitle="Assessment"
          fetch={getAssessment}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          // getMultiSelectedRows={(data) => { localStorage.setItem("selectedCompanies", JSON.stringify([...data])); }}
          // getMultiDeSelectedRows={(data) => { localStorage.setItem("selectedCompanies", JSON.stringify([...data])); }}
          // deleteRight={accessPermissionBasic}
          deleteMany={false}
          addNewRight={accessPermissionAdd}
          addNew={() => {
            history.push("/assessment/add");
          }}
          clearFilterByTaxonomy={true}
        />
      </div>
    </div>
  );
};

export default Assessment;
