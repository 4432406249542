/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Action buttons 
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/June/2021
    Author						: Sandeep K. Sharma
================================================================
 */

import React from "react";
import { Link, useHistory } from "react-router-dom";
import { getLoggedInUser } from "../../../../utilities";
import { actionSuccess, actionError } from "../../../../middleware/actions/utilityAction";
import { useDispatch } from "react-redux";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { publishService } from "../../../../middleware/services/servicesApi";
import { COMMON_ACCESS_ACTION } from "../../../../constants";

const ActionButtons = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = getLoggedInUser();
  const serviceId = props.serviceId || "";

  /* Common access */
  const accessActionSave = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionPublish = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.PUBLISH);

  const publish = async () => {
    const serviceId = props.serviceId;
    let payload = {};
    payload.servicePublishedBy = { userId: loggedInUser._id, userFname: loggedInUser.userFname, userLname: loggedInUser.userLname };
    await await publishService(serviceId, payload)
      .then((res) => {
        if (res) {
          dispatch(actionSuccess("Service has been published successfully"));
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        let errMessage = "Service cannot published : <br>";
        const errMessageArr = err?.data?.message ? err.data.message.split(",") : ["Something went wrong"];
        errMessage += errMessageArr
          .map((value, index) => {
            return ++index + "." + value + "<br>";
          })
          .join("");
        dispatch(actionError(errMessage));
      });
  };

  return (
    <div className="form-submit-box ml-2">
      <div className="container-fluid nopadding">
        <div className="row">
          <div className="float-left col-md-6 text-left">
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="pl-4" style={{ marginLeft: "-3em" }}>
                <span
                  // to={props.nextPath}
                  className="span-link"
                >
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                    onClick={() => {
                      (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                        (props.selectedIndex < 3 && props.setCurrentTab(props.selectedIndex - 1));
                    }}
                    // disabled={props.nextPath ? false : true}
                  >
                    Previous
                  </button>
                </span>
              </span>
            ) : null}

            {serviceId !== "" && props.selectedIndex > -1 && props.selectedIndex < 2 ? (
              <span className="pl-4" style={{ marginLeft: props.selectedIndex === 0 ? "-3em" : "0" }}>
                <span
                  // to={props.nextPath}
                  className="span-link"
                >
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                    onClick={() => {
                      props.selectedIndex < 2 && props.setCurrentTab(props.selectedIndex + 1);
                    }}
                    // disabled={props.nextPath ? false : true}
                  >
                    Next
                  </button>
                </span>
              </span>
            ) : null}
          </div>

          <div className="float-right col-md-6 text-right">
            <Link to={"/services" || history.location.pathname}>
              <button type="button" className="btn btn-outline-dark pl-4 pr-4 cy-btn ">
                Cancel
              </button>
            </Link>

            {props.handleSave && props.showSave && accessActionSave ? (
              <button type="button" className="btn btn-primary pl-4 pr-4 ml-4 cy-btn ml-3" id="dev-objective-save" onClick={props.handleSave}>
                Save
              </button>
            ) : null}
            {props.handleSaveForm && props.showSaveForm && accessActionSave ? (
              <button type="button" className="btn btn-primary pl-4 pr-4 ml-4 cy-btn ml-3" id="dev-objective-save" onClick={props.handleSaveForm}>
                Save
              </button>
            ) : null}
            {props.showPublished && accessActionPublish ? (
              <button
                type="button"
                className="btn btn-primary pl-4 pr-4 ml-4 cy-btn ml-3"
                onClick={() => {
                  dispatch(
                    showAlertBox({
                      okCallback: async () => {
                        await publish();
                      },
                      content: "Are you sure, you want to publish Service?",
                    })
                  );
                }}
              >
                Publish
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
