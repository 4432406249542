import * as React from "react";
import PropTypes from "prop-types";
import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
  Selection,
  Inject,
  Sort,
  Filter,
  ColumnChooser,
  Toolbar,
  Edit,
  Resize,
  RowDD,
  Freeze,
  ExcelExport,
  ColumnMenu,
} from "@syncfusion/ej2-react-treegrid";
import { useRef, useState, useEffect } from "react";
import { IoMdFunnel } from "react-icons/io";
import "./index.css";
import { useSelector } from "react-redux";

import { DataUtil } from "@syncfusion/ej2-data";
import moment from "moment";

const FilterSettings = {
  type: "Menu",
  hierarchyMode: "Both",
  operators: {
    stringOperator: [
      {
        value: "startswith",
        text: "Starts With",
      },
      {
        value: "endswith",
        text: "Ends With",
      },
      {
        value: "contains",
        text: "Contains",
      },
      {
        value: "equal",
        text: "Equal",
      },
      {
        value: "notequal",
        text: "Not Equal",
      },
    ],
    numberOperator: [
      {
        value: "equal",
        text: "Equal",
      },
      {
        value: "greaterthan",
        text: "Greater Than",
      },
      {
        value: "greaterthanorequal",
        text: "Greater Than Or Equal",
      },
      {
        value: "lessthan",
        text: "Less Than",
      },
      {
        value: "lessthanorequal",
        text: "Less Than Or Equal",
      },
      {
        value: "notequal",
        text: "Not Equal",
      },
    ],
    dateOperator: [
      {
        value: "equal",
        text: "Equal",
      },
      {
        value: "greaterthan",
        text: "Greater Than",
      },
      {
        value: "greaterthanorequal",
        text: "Greater Than Or Equal",
      },
      {
        value: "lessthan",
        text: "Less Than",
      },
      {
        value: "lessthanorequal",
        text: "Less Than Or Equal",
      },
      {
        value: "notequal",
        text: "Not Equal",
      },
    ],
  },
};
function TreeDataGridNew(props) {
  const {
    children,
    defaultSortingOptions = {},
    rowDataBound = () => {},
    showGridToolBar = true,
    showExportButton = false,
    showResetFilter = false,
    allowExcelExport = true,
    showColumnChooser = true,
    allowResizing = false,
    height = "100%",
    showBottomBorder = false,
    childMapping = "",
    getGridRef,
    dataBound,
    rowData,
    allowPagination = false,
    exportFileName = "Client Activity Tracker",
    columnChooserHandler = undefined,
    handleQueryCellInfo = undefined,
    created = undefined,
    dropDownFilterFields = [],
    exportFunction = undefined,
  } = props;
  const gridState = useSelector((state) => state.gridState);
  const sideNavActiveState = useSelector((state) => state.sideNavState.active);
  const gridRef = useRef(null);
  const [pageSize, setPageSize] = useState(allowPagination ? gridState.recordsPerPage : 50000 || 2);
  const [currentPage, setCurrentPage] = useState(1);

  if (getGridRef) {
    getGridRef(gridRef);
  }

  const handleCreated = () => {
    if (gridRef?.current) {
      gridRef.current.grid.columnChooserSettings.operator = "contains";
    }
  };

  const handleColumnChooser = (args) => {
    const button = args.currentTarget;
    const buttonRect = button.getBoundingClientRect();
    const middleX = buttonRect.left + buttonRect.width / 2;
    const offsetX = middleX - (sideNavActiveState ? 300 : 280);
    gridRef.current.columnChooserModule.openColumnChooser(offsetX, 0);
    columnChooserHandler && columnChooserHandler();
  };

  const handleResetFilters = () => {
    gridRef.current.clearFiltering();
    gridRef.current.clearSelection();
    gridRef.current.clearSorting();
  };
  const handleExportData = () => {
    if (exportFunction) return exportFunction();
    let datePrefix = moment(new Date()).format("YYYYMMDDHHmm");
    let excelExportProperties = {
      fileName: `${datePrefix} ${exportFileName}.xlsx`,
      includeHiddenColumn: true
    };
    gridRef.current.excelExport(excelExportProperties);
  };

  const handleDataBound = () => {
    dataBound && dataBound();
    setIconAlignment();
    clearLocalGrid();
  };

  const clearLocalGrid = () => {
    // Iterate over localStorage and insert the keys that meet the condition into arr
    const arr = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 8) == "gridgrid") {
        arr.push(localStorage.key(i));
      }
    }
    for (let i = 0; i < arr.length; i++) {
      localStorage.removeItem(arr[i]);
    }
  };

  const handleActionComplete = (args) => {
    if (args.requestType === "refresh" || args.requestType === "filtering") {
      let dataResults = gridRef.current.dataResults.result;
      if (dataResults.length == 0) return showNoRecordFound(true);
      let movablescrollbar = gridRef.current.element?.querySelector(".e-movablescrollbar");
      let frozenContent = gridRef.current.element?.querySelector(".e-grid .e-frozencontent.e-frozen-left-content");
      let frozenborder = frozenContent?.querySelector(".e-table");
      if (dataResults.length > 0) {
        if (frozenContent) frozenContent.style.width = "min-content";
        if (frozenborder) frozenborder.style.borderRight = "2px solid #6c757d";
        if (movablescrollbar) movablescrollbar.style.display = "block";
      }
    }
    if (args.requestType === "filterafteropen" && dropDownFilterFields.includes(args.columnName)) {
      args.filterModel.dlgObj.element.children[0].firstElementChild.children[0].style.display = "none";
    }
    setIconAlignment();
  };

  function setIconAlignment() {
    const gridinstances = document.querySelector(".e-grid");
    const instancesNew = gridinstances && gridinstances.ej2_instances[0];
    const headercells = instancesNew && instancesNew.element.querySelectorAll(".e-headercell");
    for (let i = 0; i < headercells?.length; i++) {
      if (headercells[i].querySelector(".e-headertext")) {
        var headertextwidth = headercells[i].querySelector(".e-headertext").getBoundingClientRect().width;
        var headercelldivwidth = headercells[i].querySelector(".e-headercelldiv").getBoundingClientRect().width;
        let rightSortFiltermargin = 0;
        let distance = 10;
        let rightFilterMargin = 0;
        let sortIconWidth = 16;
        let headercellPaddingRight = 10;
        let headercellPaddingLeft = 10;
        if (headercells[i].classList.contains("e-leftalign")) {
          rightSortFiltermargin = headercelldivwidth - headertextwidth - (distance * 2 + headercellPaddingRight + headercellPaddingLeft);
          rightFilterMargin = headercelldivwidth - headertextwidth - (sortIconWidth + distance * 2 + headercellPaddingLeft + headercellPaddingLeft);
        }
        if (headercells[i].classList.contains("e-centeralign")) {
          rightSortFiltermargin = (headercelldivwidth - headertextwidth) / 2 - (sortIconWidth + distance);
          rightFilterMargin = (headercelldivwidth - headertextwidth) / 2 - (15 + sortIconWidth + distance);
        }
        if (headercells[i].classList.contains("e-rightalign")) {
          rightSortFiltermargin = headercelldivwidth - headertextwidth - (sortIconWidth + distance);
          rightFilterMargin = 2;
        }
        // change the filtericon margin

        if (headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter")) {
          // change the filter icon position
          if (headercells[i].classList.contains("e-rightalign")) {
            headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter").style.margin = "-21px " + 16 + "px" + " -18px 0px";
          } else if (headercells[i].classList.contains("e-leftalign")) {
            headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter").style.margin = "-21px " + rightFilterMargin + "px" + " -18px 0px";
          } else {
            headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter").style.margin = "-21px " + rightFilterMargin + "px" + " -18px 0px";
          }
          headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter").style.padding = "6px 0px 6px 0px";
          headercells[i].querySelector(".e-filtermenudiv.e-icons.e-icon-filter").style.display = "block";
        }

        // change the sorticon margin
        if (headercells[i].querySelector(".e-sortfilterdiv.e-icons")) {
          // change the Sort icon position
          headercells[i].querySelector(".e-sortfilterdiv.e-icons").style.margin = "-23px " + rightSortFiltermargin + "px" + " -15px 0px";
        }
        headercells[i].querySelector(".e-sortfilterdiv.e-icons").style.display = "block";
      }
    }
  }

  // Function to show hide No Record Found Text
  const showNoRecordFound = (showNoRecordText) => {
    setTimeout(() => {
      let emptyRow = gridRef?.current?.element.querySelector(".e-gridcontent .e-content .e-emptyrow");
      let frozenContent = gridRef.current.element?.querySelector(".e-grid .e-frozencontent.e-frozen-left-content");
      let frozenborder = frozenContent?.querySelector(".e-table");
      if (!showNoRecordText || !emptyRow) return;
      let emptyRowTD = emptyRow.querySelector("td");
      emptyRow.style.height = "106px";
      emptyRow.style.display = "table-row";
      emptyRowTD.style.display = "";
      emptyRowTD.style.textAlign = "center";
      emptyRowTD.innerText = "No Records Found";
      if (frozenborder) frozenborder.style.borderRight = "none";
      if (frozenContent) frozenContent.style.width = "max-content";
    }, 100);
  };

  const cleanUpFilters = () => {
    gridRef.current.clearFiltering();
    gridRef.current.clearSorting();
  };

  useEffect(() => {
    cleanUpFilters();
  }, []);

  return (
    <div className="gridPanel">
      {showGridToolBar && (
        <nav className="navbar navbar-dark bg-dark rounded-top ">
          <div className="form-inline my-2 my-lg-0  " style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div>
              {showExportButton && (
                <button
                  className="btn btn-outline-white active mr-sm-2 btn-sm action-button "
                  id="dataScreeningExport"
                  onClick={(e) => handleExportData()}
                >
                  <i className="fas fa-download pr-1"></i>Export
                </button>
              )}
            </div>
            <div>
              {showResetFilter && (
                <button className="btn btn-outline-white active mr-sm-2 btn-sm  btn-dark " id="clearFilter" onClick={() => handleResetFilters()}>
                  <i className=" pr-1">
                    <IoMdFunnel />
                  </i>
                  Reset Filters
                </button>
              )}
              <button className="btn btn-outline-white active  btn-sm btn btn-dark" id="openColumnChooser" onClick={(e) => handleColumnChooser(e)}>
                <i className="fa fa-columns pr-1"></i>Column Chooser
              </button>
            </div>
          </div>
        </nav>
      )}
      {rowData && (
        <div className="treegrid-root">
          <TreeGridComponent
            ref={gridRef}
            queryCellInfo={handleQueryCellInfo}
            dataSource={DataUtil.parse.parseJson(rowData)}
            gridLines="Both"
            treeColumnIndex={0}
            childMapping={childMapping}
            allowSorting={true}
            sortSettings={defaultSortingOptions}
            allowFiltering={true}
            allowSelection={false}
            allowExcelExport={allowExcelExport}
            showColumnChooser={showColumnChooser}
            filterSettings={FilterSettings}
            height={height || "100%"}
            rowDataBound={rowDataBound}
            enableCollapseAll={true}
            actionComplete={handleActionComplete}
            loadingIndicator={{ indicatorType: "Spinner" }}
            dataBound={handleDataBound}
            clipMode="EllipsisWithTooltip"
            allowResizing={allowResizing}
            created={created || handleCreated}
            style={{
              height: "100%",
              maxHeight: "100%",
              maxWidth: "100%",
              overflowY: "auto",
              overflowX: "auto",
              borderBottom: showBottomBorder ? "1px solid rgba(209,213,219,0.5)" : "none",
            }}
            pageSettings={{
              pageSize,
              currentPage,
            }}
            statelessTemplates={["directiveTemplates"]}
            css
          >
            {children}
            <Inject services={[Sort, Filter, Resize, Freeze, Edit, ColumnMenu, Selection, ColumnChooser, Toolbar, RowDD, ExcelExport]} />
          </TreeGridComponent>
        </div>
      )}
    </div>
  );
}

TreeDataGridNew.propTypes = {
  showGridToolBar: PropTypes.bool,
  showExportButton: PropTypes.bool,
  showResetFilter: PropTypes.bool,
  defaultSortingOptions: PropTypes.object,
  height: PropTypes.string,
  rowDataBound: PropTypes.func,
  showBottomBorder: PropTypes.bool,
  childMapping: PropTypes.string,
  getGridRef: PropTypes.func,
  dataBound: PropTypes.func,
  allowExcelExport: PropTypes.bool,
  showColumnChooser: PropTypes.bool,
  rowData: PropTypes.arrayOf(PropTypes.object),
};

TreeDataGridNew.defaultProps = {
  rowData: PropTypes.arrayOf(PropTypes.object),
  rowDataBound: () => {},
  defaultSortingOptions: {},
  height: "100%",
  showGridToolBar: true,
  showExportButton: false,
  showResetFilter: false,
  showBottomBorder: false,
  allowExcelExport: true,
  showColumnChooser: true,
};

export default TreeDataGridNew;
