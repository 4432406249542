/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Clear the cache of react app on build.
----------------------------------------------------------------
	Creation Details
	Date Created				: 22/Jul/2022
	Author						  : Mohammad Ali
================================================================
*/

import React, { useState, useEffect } from "react";
import packageJson from "../../package.json";
import moment from "moment";

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);
  return (momLatestDateTime.isAfter(momCurrentDateTime)) ?  true :  false
}

function clearCache(Component) {

  function ClearCacheComponent(props) {
  
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      fetch("/updateBuild.json", {
        headers: {
          'Cache-Control': 'no-cache'
        }
      })
        .then((response) => response.json())
        .then((updateBuild) => {
          const latestVersionDate = updateBuild.buildDate;
          const currentVersionDate = packageJson.buildDate;
console.log('date from package.json',currentVersionDate)
console.log('date from updateBuild.json',latestVersionDate)
const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
console.log('should refresh',shouldForceRefresh)
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    };

    return (
      <React.Fragment>
        {/* {isLatestBuildDate ? <Component {...props} /> : null} */}
        <Component {...props} />
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default clearCache;
