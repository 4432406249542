/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Application configuration
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

// service api url
export const AUTHENTICATION_URL = import.meta.env.VITE_AUTHENTICATION_URL || "http://localhost:8001/v1";
export const AUTHORIZATION_URL = import.meta.env.VITE_AUTHORIZATION_URL || "http://localhost:8002/v1";
export const CMS_API_URL = import.meta.env.VITE_CMS_API_URL || "http://localhost:8003/v1";
export const USER_URL = import.meta.env.VITE_USER_URL || "http://localhost:8004/v1";
export const COMPANY_URL = import.meta.env.VITE_COMPANY_URL || "http://localhost:8005/v1";
export const PRODUCT_URL = import.meta.env.VITE_PRODUCT_URL || "http://localhost:8006/v1";
export const NEWS_URL = import.meta.env.VITE_NEWS_URL || "http://localhost:8007/v1";
export const DELIVERABLE_URL = import.meta.env.VITE_DELIVERABLE_URL || "http://localhost:8008/v1";
export const TEMPLATE_URL = import.meta.env.VITE_TEMPLATE_URL || "http://localhost:8010/v1";
export const TV_URL = import.meta.env.VITE_VIDEO_URL || "http://localhost:8012/v1";
export const BDSYSTEM_URL = import.meta.env.VITE_BDSYSTEM_URL || "http://203.200.229.72/DemoServices/WebExSer.asmx?wsdl";
export const OBJECTIVE_URL = import.meta.env.VITE_OBJECTIVE_URL || "http://localhost:8009/v1";
export const SURVEY_API_URL = import.meta.env.VITE_SURVEY_URL || "http://localhost:8010/v1";
export const PEOPLE_URL = import.meta.env.VITE_PEOPLE_URL || "http://localhost:8011/v1";
export const PATENT_URL = import.meta.env.VITE_PATENT_URL || "http://localhost:8013/v1";
export const KIA_URL = import.meta.env.VITE_KIA_URL || "http://localhost:8014/v1";
export const SERVICE_URL = import.meta.env.VITE_SERVICE_URL || "http://localhost:8015/v1"; //CUSTOMER_SERVICE
export const SITESEARCH_URL = import.meta.env.VITE_SITESEARCH_URL || "http://localhost:8016/v1"; //SITE_SEARCH
export const NOTIFICATION_URL = import.meta.env.VITE_NOTIFICATION_URL || "http://localhost:8017/v1";
export const CONTENT_BUILDER_URL = import.meta.env.VITE_CONTENT_BUILDER_URL || "http://localhost:8018/v1";
export const ACTIVITY_LOGGING_URL = import.meta.env.VITE_ACTIVITY_LOGGING_URL || "http://localhost:8019/v1";
export const WIDGET_URL = import.meta.env.VITE_WIDGET_URL || "http://localhost:8021/v1";
export const UPLOAD_DATA_URL = import.meta.env.VITE_UPLOAD_DATA_URL || "http://localhost:8099";
export const BASE_URL = import.meta.env.VITE_API_ENDPOINT || "http://localhost";
export const HELPDESK_URL = import.meta.env.VITE_HELPDESK_URL || "http://localhost:8020/v1";
export const REPORT_URL = import.meta.env.VITE_REPORT_URL || "http://localhost:8024/v1";

// grid record & page
export const RECORD_LIMIT_RANGE = JSON.parse(import.meta.env.VITE_RECORD_LIMIT_RANGE) || [100, 500, 1000, 2000, 3000, 4000, 5000, 10000];
export const PAGE_SIZE_RANGE = JSON.parse(import.meta.env.VITE_PAGE_SIZE_RANGE) || [10, 20, 50, 100];
// export const PAGE_SIZE_RANGE = [1, 2, 20, 40];

// pagination
export const PAGINATION_PAGE_RANGE = parseFloat(import.meta.env.VITE_PAGINATION_PAGE_RANGE) || 7;
export const GRID_PAGE_SIZE = parseFloat(import.meta.env.VITE_GRID_PAGE_SIZE) || 15;

// utility
export const TOAST_TIME_OUT = parseFloat(import.meta.env.VITE_TOAST_TIME_OUT) || 3000;

//Internal Urls
export const INSIDER_FRONT_END = import.meta.env.VITE_INSIDER_FRONT_END;
export const INSIDER_CMS = import.meta.env.VITE_INSIDER_CMS;

//Internal Dir
export const INSIDER_NEWS_IMG_DIR = "/upload/images/news";

//Limit
export const LIMIT_INDUSTRY_DEVELOPMENT_IN_FOCUS = 160;

//News thumb image size
export const NEWS_THUMB_IMG_SIZE = 10485760; // 10MB

//Video thumb image size
export const VIDEO_THUMB_IMG_SIZE = 10485760; // 10MB

//Company max upload image size
export const COMPANY_MAX_IMG_SIZE = 5242880; // 5MB

//Product max upload image size
export const PRODUCT_MAX_IMG_SIZE = 5242880; // 5MB

//Deliverable thumb image size
export const DELIVERABLE_THUMB_IMG_SIZE = 10485760; // 10MB

//Content block thumb image size
export const CONTENT_BLOCK_THUMB_IMG_SIZE = 10485760; // 10MB

//Business objective thumb image size
export const BUSINESS_OBJECTIVE_THUMB_IMG_SIZE = 204800; // 200KB

//Company logo image size
export const COMPANY_LOGO_IMG_SIZE = 10485760; // 10MB

//Product logo image size
export const PRODUCT_LOGO_IMG_SIZE = 10485760; // 10MB
