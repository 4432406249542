import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import { fetchProductVideosByProductId, deleteProductVideo } from "../../../../../../middleware/services/productApi";
import VideoModal from "./videoModal";
import { accessRightActionCheck } from "../../../../../../utilities";
import Accordion from "../../../../../components/accordion";
import ThreeDotMenu from "../../../../../components/threeDotMenu";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const VideoComponent = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const [editVideoId, setEditVideoId] = useState(null);
  const [editDocumentData, setEditDocumentData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = "Overview";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  /* Common access */
  const accessPermissionAdd = interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_VIDEOS);
  const accessPermissionEdit = interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_VIDEOS);
  const accessPermissionDelete = interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_VIDEOS);

  const getProductVideos = async () => {
    const response = await fetchProductVideosByProductId(props.productId).catch((err) => {
      dispatch(actionError(err.data.message || "Something went wrong"));
    });
    setVideoList(response.data?.data?.productVideos || []);
  };
  useEffect(() => {
    getProductVideos();
  }, []);

  const handleDeleteVideo = async (id, props) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await deleteProductVideo(props.productId, id).catch((err) => {
            dispatch(actionError(err.data.message || "Something went wrong"));
          });
          dispatch(actionSuccess("Video deleted successfully"));
          getProductVideos();
        },
      })
    );
  };

  return (
    <Accordion heading="Videos" stepCompleted={videoList && videoList.length > 0 ? true : false} step={3}>
      <div className="row px-3">
        <div className="table-responsive pt-2">
          <table className="normal-table table table-bordered">
            <thead className="internal-grid">
              <tr>
                <th>Title</th>
                <th>Description</th>

                <th className="text-center internal-grid-action">Action</th>
              </tr>
            </thead>
            <tbody>
              {/* {documentListing} */}
              {videoList.map((item) => {
                return (
                  <React.Fragment key={`prod-video-${item.id}`}>
                    <tr>
                      <td>{item.videoTitle}</td>
                      <td>{item.videoDescription.replace(/(<([^>]+)>|&nbsp;)/gi, "")}</td>

                      <td className="text-center">
                        {accessPermissionEdit || accessPermissionDelete ? (
                          <ThreeDotMenu
                            rowID={item.id}
                            Edit={
                              accessPermissionEdit
                                ? () => {
                                    setEditVideoId(item.id);
                                    setShowModal(!showModal);
                                    setEditDocumentData(true);
                                  }
                                : undefined
                            }
                            Delete={
                              accessPermissionDelete
                                ? () => {
                                    handleDeleteVideo(item.id, props);
                                  }
                                : undefined
                            }
                          />
                        ) : null}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <div className="text-right">
            {accessPermissionAdd ? (
              <button
                type="button"
                className="btn btn-dark cy-btn"
                onClick={() => {
                  setEditVideoId(null);
                  setShowModal(!showModal);
                  setEditDocumentData(false);
                }}
              >
                Add New
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {showModal && (
        <VideoModal
          editDocumentData={editDocumentData}
          editVideoId={editVideoId}
          productId={props.productId}
          showModal={showModal}
          clicked={() => {
            setShowModal(!showModal);
            getProductVideos();
          }}
        />
      )}
    </Accordion>
  );
};
export default VideoComponent;
