import { SidebarComponent } from "@syncfusion/ej2-react-navigations";

import * as React from "react";
import { useRef, useEffect, useState } from "react";
import "./index.scss";
import { TextField, ToggleSwitchNew } from "../../../components/CustomFormElements/syncFusionFormFields";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CancelIcon from "@material-ui/icons/Cancel";

const SideBar = (props) => {
  const { selectedTaxonomy = undefined, handleCancel = undefined, handleSubmit = undefined, show } = props;
  const sidebarRef = useRef();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [sortedTaxonomy, setSortedTaxonomy] = useState(selectedTaxonomy);

  const handleSaveSequence = () => {
    props.handleSaveSequence(sortedTaxonomy);
  };

  const created = () => {
    sidebarRef.current.element.style.visibility = "";
  };
  const toggleClick = () => {
    sidebarRef.current.toggle();
    props.setShow(false);
  };

  useEffect(() => {
    sidebarRef.current.show();
  }, [props.toggle]);

  useEffect(() => {
    // if (selectedTaxonomy?.length == 1) {
    setSortedTaxonomy(selectedTaxonomy);
    // }
  }, [selectedTaxonomy]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    const newItems = Array.from(sortedTaxonomy);
    const [reorderedItem] = newItems.splice(sourceIndex, 1);
    newItems.splice(destIndex, 0, reorderedItem);
    newItems.forEach((element, i) => {
      element.trendSortOrder = i + 1;
    });
    setSortedTaxonomy(newItems);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "transparent" : "white",
    display: "flex",
    alignItems: "center",
    top: "auto !important",
    left: "auto !important",
    transform: isDragging ? "none" : draggableStyle.transform,
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "White" : "white",
  });

  const handleDelete = (Id) => {
    let newArray = sortedTaxonomy.filter((ele) => ele.trendId !== Id);
    newArray.map((e, i) => {
      return (e.trendSortOrder = i + 1);
    });
    setSortedTaxonomy(newArray);
  };

  return (
    <div>
      <SidebarComponent
        id="taxonomy-seq-sidebar"
        className="taxonomy-seq-sidebar"
        ref={sidebarRef}
        width={150}
        style={{ visibility: "hidden" }}
        created={created}
        type={"Over"}
        isOpen={show}
        position="Right"
        animate={false}
        enableGestures={false}
      >
        <div className="col-12 p-0 bg-white">
          <div className="row m-0">
            <div className="roundedTop pl-4 pr-4 " style={{ height: "56px" }}>
              <span className="font-weight-bold" style={{ paddingTop: "2.2vh" }}>
                Arrange the tags in sequence of display
              </span>
            </div>
            <hr style={{ borderBottom: "1px solid #DBDBDD", width: "100%" }} />
          </div>

          {
            <>
              <div className="col-md-12 p-4">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" type="droppablePage">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {sortedTaxonomy &&
                          sortedTaxonomy.map((data, index) => {
                            return (
                              <Draggable key={data.trendId} draggableId={data.trendId} index={index}>
                                {(provided, snapshot) => {
                                  if (snapshot.isDragging) {
                                    const offset = { x: 1450, y: 100 }; // your fixed container left/top position
                                    const x = provided.draggableProps.style.left - offset.x;
                                    const y = provided.draggableProps.style.top - offset.y;
                                    provided.draggableProps.style.left = x;
                                    provided.draggableProps.style.top = y;
                                  }
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <div className="sequence-chip px-3 py-1 mb-3">
                                        <span className="chip-avatar custom-chip1" {...provided.dragHandleProps}>
                                          {` ${data.trendName}`}
                                          <CancelIcon
                                            className="cancelIcon"
                                            fontSize="small"
                                            onClick={() => {
                                              handleDelete(data.trendId);
                                            }}
                                          />
                                        </span>
                                      </div>
                                      {provided.placeholder}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <hr className="taxonomy-seq-bottomHr" />
              <div className="pt-3 taxonomy-seq-bottomAction">
                <button
                  type="button"
                  className={`btn btn-outline-dark  mr-3 ${btnDisabled ? " disabled " : " "}`}
                  onClick={() => {
                    toggleClick();
                  }}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="button" className={`btn btn-primary ${btnDisabled ? " disabled " : " "}`} onClick={handleSaveSequence}>
                  Save
                </button>
              </div>
            </>
          }
        </div>
      </SidebarComponent>
    </div>
  );
};
export default SideBar;
