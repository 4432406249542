/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Bread Crumb Actions (setBreadCrumb)
----------------------------------------------------------------
	Creation Details
	Date Created				: 28/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { SET_BREAD_CRUMB } from "../../constants/actionConstants";

export const setBreadCrumb = (payload = []) => ({
	type: SET_BREAD_CRUMB,
	payload,
});
