/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Footer bar for content block Add | Edit Page
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 12/April/2022
    Author						  : Amrutesh Devadas
================================================================ 
*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { hideSnippetFunction } from "../../../../components/utility/hideSnippetFunction";
import { accessRightActionCheck } from "utilities";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const FooterBar = (props) => {
  const { selectedIndex, contentBlockStatus } = props;

  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  let interfaceAccessSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  let interfaceAccessSubmit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SUBMIT);
  let interfaceAccessApprove = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.APPROVE);
  let interfaceAccessPublish = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.PUBLISH);
  let interfaceAccessReject = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.REJECT);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = Object.values(props.hashArr)[props.selectedIndex];
    let actionAccess = accessRightActionCheck(props.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [props.selectedIndex]);

  return (
    <div className="form-submit-box" style={{ padding: "10px 28px 10px 20px" }}>
      <div className="container-fluid nopadding">
        <div className="row">
          <div
            className={`float-LEFT  text-left pl-0 ${props.selectedIndex > -1 && props.selectedIndex < 3 ? "col-md-3" : "col-md-2"}`}
            style={{ paddingRight: "0px" }}
          >
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="span-link mr-3">
                <button
                  type="button"
                  className="cy-prev-btn btn btn-outline-dark pl-4 pr-4 cy-btn my-1"
                  onClick={() => {
                    (props.selectedIndex === undefined && props.setCurrentTab(2)) ||
                      (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1));
                  }}
                >
                  Previous
                </button>
              </span>
            ) : null}
            {props.contentBlockId !== "" && props.selectedIndex > -1 && props.selectedIndex < 3 ? (
              <span className="span-link">
                <button
                  type="button"
                  className="cy-next-btn btn btn-outline-dark pl-4 pr-4 cy-btn my-1"
                  onClick={() => {
                    props.selectedIndex < 3 && props.setCurrentTab(props.selectedIndex + 1);
                  }}
                >
                  Next
                </button>
              </span>
            ) : null}
          </div>
          <div className={`float-right  text-right px-0 ${props.selectedIndex > -1 && props.selectedIndex < 3 ? "col-md-9" : "col-md-10"}`}>
            <Link to="/content-block">
              <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4">
                Cancel
              </button>
            </Link>
            {props.selectedIndex !== 3 && interfaceAccessSave && ["In Progress", "", "Rejected"].includes(contentBlockStatus) ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 ml-3 my-1"
                id="dev-news-save"
                onClick={() => {
                  props.handleSave(true);
                }}
              >
                Save
              </button>
            ) : null}
            {interfaceAccessSubmit && ["In Progress", "", "Rejected"].includes(contentBlockStatus) && (selectedIndex === 2 || selectedIndex === 1) ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 ml-3 my-1"
                id="dev-news-save"
                onClick={() => {
                  hideSnippetFunction();
                  props.setViewBlocks(false);
                  props.handleSubmit();
                }}
              >
                Submit
              </button>
            ) : null}
            {interfaceAccessReject && (selectedIndex === 2 || selectedIndex === 1) ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 ml-3 my-1 "
                id="dev-news-save"
                onClick={() => {
                  hideSnippetFunction();
                  props.setViewBlocks(false);
                  props.handleReject();
                }}
              >
                Reject
              </button>
            ) : null}
            {interfaceAccessApprove && (selectedIndex === 2 || selectedIndex === 1) ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 ml-3 my-1 "
                id="dev-news-save"
                onClick={() => {
                  hideSnippetFunction();
                  props.handleApprove();
                  props.setViewBlocks(false);
                }}
              >
                Approve
              </button>
            ) : null}
            {interfaceAccessPublish && (selectedIndex === 2 || selectedIndex === 1) ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 ml-3 my-1"
                id="dev-news-save"
                onClick={() => {
                  hideSnippetFunction();
                  props.setViewBlocks(false);
                  props.handlePublish();
                }}
              >
                Publish
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBar;
