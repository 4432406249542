/**
 * @author: Pankaj Kulshreshtha
 * @date : 17/Mar/2021
 * File Description: Survey module validation schema
 */
import * as Yup from "yup";
import * as REGEX from "../../../../../config/regexValidation"
const shortUrlPattern = /^[a-z0-9-_]+$/
export const surveyBasicValidationSchema = {
    createSurveyFrom: Yup.object().required("Please choose an option"),
    existingTemplate: Yup.string().when('createSurveyFrom', {
        is: obj => { return obj === undefined || (obj && obj.value === "existing template") },
        then: Yup.string().required("Please select survey template").nullable(),
        // otherwise: Yup.string().nullable().oneOf([null, undefined], 'Please select demo users'),
    }),
    surveyType: Yup.object().required("Please select survey type"),
    surveyTitle: Yup.string().trim("Please enter survey title").required("Please enter survey title")
        .max(150, "Survey title must be at most 150 characters"),
    surveyShortName: Yup.string()
        .matches(shortUrlPattern, "Only characters in lowercase, numbers and special characters '-','_' are allowed")
        .required("Please enter survey short name")
        .max(30, "Short name must be at most 30 characters"),
    surveyStartDate: Yup.date().typeError("Please select survey start date").required("Please enter survey start date"),
    surveyEndDate: Yup.date().when('surveyType', {
        is: (obj) => { return (obj && obj.value === "One Time") },
        then: Yup.date().required("Please select survey end date").typeError("Please select survey end date").min(Yup.ref('surveyStartDate'), "Survey end date shouldn't be before the start date"),
        otherwise: Yup.date().nullable(),
    }),
    surveyAudience: Yup.string().required("Please select survey target audience"),
    // summary: Yup.string().trim("Please enter survey summary").required("Please enter survey summary").max(150, "Survey summary must be at most 500 characters"),
    // surveyConfirmationMsg: Yup.string().trim("Please enter survey summary").required("Please enter survey summary").max(150, "Survey summary must be at most 500 characters"),
};

export const surveyAudienceExternalUserValidationSchema = {
    documentFile: Yup.mixed().required("Please upload data template"),

};
export const surveyAudienceInternalUserValidationSchema = {
    clientUsers: Yup.array().required("Please select client users").typeError("Please select client users"),

};
// validation schema for Survey Invitation

export const surveyInvitationValidationSchema = {
    senderMail: Yup.string().matches(REGEX.VALID_URLS_FOR_SURVEY, "Please enter valid email.").required("Please enter sender's email."),
    senderName: Yup.string().matches(REGEX.ALPHA_NUMERIC, "Only alphanumeric characters are allowed").required("Please enter sender's name"),
    mailSubject: Yup.string().required("Please enter email subject."),
    invitationMailBody: Yup.string().required("Please enter email content.")
}

// validation schema for Survey Acknowledgement
export const surveyAcknowledgementValidationSchema = {
    senderMail: Yup.string().matches(REGEX.VALID_URLS_FOR_SURVEY, "Please enter valid email.").required("Please enter sender's email."),
    senderName: Yup.string().matches(REGEX.ALPHA_NUMERIC, "Only alphanumeric characters are allowed").required("Please enter sender's name"),
    mailSubject: Yup.string().required("Please enter email subject."),
    mailBody: Yup.string().required("Please enter email content.")
}