/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Common tree component
     1. Design for re-render main tree component
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React, { useState, useEffect } from "react";
import Scrollbar from 'smooth-scrollbar';
import TreeView from "./taxonomyTree";
const TreeUI = (props) => {
    const [treeData, setTreeData] = useState([])
    /*
        @Description : If technology props change tree forcely re-render
    */
    useEffect(() => {
        setTreeData([]);
    }, [props.technology])
    /*
        @Description : If reRender props change tree forcely re-render
    */
    useEffect(() => {
        if (props.reRender)
            setTreeData([]);
    }, [props.reRender])
    useEffect(() => {
        setTreeData([...props.treeData]);
    }, [props.treeData])
    useEffect(() => {
        const options = {
            // damping:0.1,
            thumbMinSize: 20,
            alwaysShowTracks: true,
        }
        setTimeout(() => {
            let classExist = document.querySelector(".custom-is-searchable");
            classExist && Scrollbar.init(document.querySelector('.custom-is-searchable'), options);

        }, 2000);
    }, [])
    useEffect(() => {
        const options = {
            // damping:0.1,
            thumbMinSize: 20,
            alwaysShowTracks: true,
        }
        setTimeout(() => {
            let classExist = document.querySelector(".custom-taxonomy-tree-container");
            classExist && Scrollbar.init(document.querySelector('.custom-taxonomy-tree-container'), options);

        }, 2000);
    }, [])
    return (
        <>
            {treeData.length > 0 ?
                <TreeView
                    handleSubmit={props.handleSubmit}
                    showSearch={props.showSearch}
                    treeData={treeData}
                    showNodeIcon={false}
                    handleSave={props.handleSave}
                    saveTreeData={props.saveTreeData}
                    technology={props.technology}
                    checked={props.checked}
                    expanded={props.expanded}
                    isSearchable={props.isSearchable}
                    noCascade={props.noCascade || false}
                    onClick={props.onClick || undefined}
                    treeType={props.treeType || ''}
                    showExpandAll={(props.showExpandAll === false) ? false : true}
                    isCollaborationTaxonomy={props.isCollaborationTaxonomy}
                    disabled={props.disabled}
                    flag={props.flag}
                    setFilterCheckNodes={props.setFilterCheckNodes}
                    filterCheckNodes={props.filterCheckNodes}
                    preserveCheckedNode={props.preserveCheckedNode}
                />
                :
                null}
        </>
    )
}
export default TreeUI;