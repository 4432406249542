/**
 * @author: Anand Kumar
 * @date : 29-Jul-2020
 * File Description: Stepper UI
 */
import React, { Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

const Stepper = (props) => (
	<>
		<div className="stepwizard">
			<div className="container">
				<div className="wiz-steps">
					<ul className="steps">
						{props.data.map((item, i) => (
							<Fragment key={"st" + i}>
								<NavLink to={item.path} onClick={(e) => { if (item.linkDisabled) e.preventDefault(); }}
									className={` ${item.linkDisabled ? " cursor-no-drop " : " "}`} style={{ background: "none" }}>
									<li key={"st" + item.id} className={`${props.location.hash == `#${item.path.split("#")[1] ?
										item.path.split("#")[1] : item.path.split("#")[0]}` ? "active" : ""} st-one`}>
										<span className="count">{item.step}</span>
										<span className="text">{item.name}</span>
									</li>
								</NavLink>
							</Fragment>
						))}
					</ul>
				</div>
			</div>
		</div>
	</>

);
export default withRouter(Stepper);

Stepper.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	history: PropTypes.object.isRequired
};
