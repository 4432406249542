/**
 * @fileDescription Product API 
 * @author Pankaj Kulshreshtha | Cheers Interactive
 * @date 03/Aug/2020
 * =================================================
 * @changeHistory #1
 * @author Yogesh N. Gupta
 * @changeDescription Product Taxonomy Routes
 * @date 07/Aug/2020
 */

import { PRODUCT_API as API } from "../../config/apiConfig";
import { PRODUCT_URL } from "../../config";
import { multiDimensionalUnique } from "../../utilities";
import { element } from "prop-types";

export const addProductAll = (payload) => {
	return API({
		url: "/product/all",
		method: "POST",
		data: payload
	});
};
export const addProductBasic = (payload) => {
	return API({
		url: "/product/basic",
		method: "POST",
		data: payload
	});
};
export const addProductAdvanced = (payload) => {
	return API({
		url: "/product/advanced",
		method: "POST",
		data: payload
	});
};

export const addProductDocument = (productId, payload) => {
	return API({
		url: `/product/${productId}/document`,
		method: "POST",
		data: payload

	});
};
export const addProductVideo = (productId, payload) => {
	return API({
		url: `/product/${productId}/video`,
		method: "POST",
		data: payload

	});
};
export const addProductImages = (productId, payload) => {
	return API({
		url: `/product/${productId}/image`,
		method: "POST",
		data: payload

	});
};
export const editProductImage = (productId, documentId, payload) => {
	return API({
		url: `/product/${productId}/image/${documentId}`,
		method: "PUT",
		data: payload
	});
};
export const editProductDocument = (productId, documentId, payload) => {
	return API({
		url: `/product/${productId}/document/${documentId}`,
		method: "PUT",
		data: payload
	});
};
export const editProductVideo = (productId, videoId, payload) => {
	return API({
		url: `/product/${productId}/video/${videoId}`,
		method: "PUT",
		data: payload
	});
};
export const fetchVideoThumbUrl = (fileName) => {
	return `${PRODUCT_URL}/product/videoThumb/${fileName}`;
};
export const getProductImage = (imgFileName, imgName, payload) => {
	// console.log('ttrrr', imgName)
	return API({
		url: `/product/document/${imgFileName}/${imgName}`,
		method: "GET",
		data: payload

	});
};
export const fetchProductImageUrl = (fileName) => {
	return `${PRODUCT_URL}/product/image/${fileName}`;
};

export const fetchProductDocumentUrl = (fileName, docName) => {
	return `${PRODUCT_URL}/product/document/${fileName}/${docName}`;
};
export const fetchProductVideoUrl = (fileName) => {
	return `${PRODUCT_URL}/product/video/${fileName}`;
};

export const deleteProductImage = (productId, imageId, payload) => {
	return API({
		url: `/product/${productId}/image/${imageId}`,
		method: "DELETE",
		data: payload

	});
};
export const deleteProductDocument = (productId, documentId, payload) => {
	return API({
		url: `/product/${productId}/document/${documentId}`,
		method: "DELETE",
		data: payload

	});
};
export const deleteProductVideo = (productId, videoId, payload) => {
	return API({
		url: `/product/${productId}/video/${videoId}`,
		method: "DELETE",
		data: payload

	});
};

export const editProductBasic = (productId, payload = {}) => {
	return API({
		url: `/product/basic/${productId}`,
		method: "PUT",
		data: payload
	});
};
export const editProductAdvanced = (productId, payload = {}) => {
	return API({
		url: `/product/advanced/${productId}`,
		method: "PUT",
		data: payload
	});
};

export const fetchProductAll = (params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: "/product/all",
		method: "GET",
		params: params
	});
};
export const fetchProductBasic = (params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	let newFilter = [];
	if (filters) {
		filters = multiDimensionalUnique(filters);
		// console.log("PPPP", multiDimensionalUnique(filters))
		filters.map((data) => {
			data[0] === "productCompletionStatus.overview" && data[2] === "" ?
				newFilter.push(["productCompletionStatus.overview", "nex", ""]) : newFilter.push(data);

		});
		if (newFilter.length) {
			filters = newFilter;
		}
		if (filters) {
			params.filters = JSON.stringify(filters);
		}
	}
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: "/product/basic",
		method: "GET",
		params: params
	});
};

// fetch a specfic product 
export const fetchProductBasicById = (productId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/product/basic/${productId}`,
		method: "GET",
		params: params
	});
};



export const fetchSingleProduct = (productId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/product/all/${productId}`,
		method: "GET",
		params: params
	});
};


// save Product Value Chain
export const saveProductValueChain = (productId, payload) => {
	return API({
		url: `product/${productId}/taxonomy/industry-value-chain`,
		method: "POST",
		data: payload
	});
};


// fetch a specfic product Document by productId and documentId
export const fetchDocumentByDocumentId = (productId, documentId, params = {}) => {
	return API({
		url: `/product/${productId}/document/${documentId}`,
		method: "GET",
		params: params
	});
};
// fetch a specfic product Video by productId and videoId
export const fetchVideoByVideoId = (productId, videoId, params = {}) => {
	return API({
		url: `/product/${productId}/video/${videoId}`,
		method: "GET",
		params: params
	});
};
// fetch a specfic product Image by productId and imageId
export const fetchImageByImageId = (productId, imageId, params = {}) => {
	return API({
		url: `/product/${productId}/image/${imageId}`,
		method: "GET",
		params: params
	});
};
// fetch a specfic product Documents by productId
export const fetchProductDocumentsByProductId = (productId, params = {}) => {
	return API({
		url: `/product/${productId}/document`,
		method: "GET",
		params: params
	});
};
// fetch a specfic product video by productId
export const fetchProductVideosByProductId = (productId, params = {}) => {
	return API({
		url: `/product/${productId}/video`,
		method: "GET",
		params: params
	});
};
// fetch a specfic product Images by productId
export const fetchProductImagesByProductId = (productId, params = {}) => {
	return API({
		url: `/product/${productId}/image`,
		method: "GET",
		params: params
	});
};
export const fetchProductAdvanced = (params = {}) => {
	return API({
		url: "/product/all",
		method: "GET",
		params: params
	});
};

export const deleteProduct = (productId) => {
	return API({
		url: `/product/${productId}`,
		method: "DELETE"
	});
};

export const deleteManyProduct = (payload, params = {}) => {
	return API({
		url: "/product",
		method: "DELETE",
		params: params,
		data: payload
	});
};

export const fetchAddress = (productId, params = {}) => {
	return API({
		url: `/product/${productId}/address`,
		method: "GET",
		params: params,
	});
};

export const deleteAddress = (productId, addressId) => {
	return API({
		url: `/product/${productId}/address/${addressId}`,
		method: "DELETE",
	});
};

export const saveAddress = (productId, payload) => {
	return API({
		url: `/product/${productId}/address`,
		method: "POST",
		data: payload

	});
};

export const editAddress = (productId, addressId, payload) => {
	return API({
		url: `/product/${productId}/address/${addressId}`,
		method: "PUT",
		data: payload
	});
};

// get product specification
export const fetchProductSpecification = (productId, params = {}) => {
	return API({
		url: `/product/${productId}/specification`,
		method: "GET",
		params: params
	});
};

// add product specification
export const saveProductSpecification = (productId, payload) => {
	return API({
		url: `/product/${productId}/specification`,
		method: "POST",
		data: payload
	});

};

export const deleteProductSPecification = (productId, specifcationId) => {
	return API({
		url: `/product/${productId}/specification/${specifcationId}`,
		method: "DELETE",
	});
};

export const updateProductSpecification = (productId, specifcationId, payload) => {
	return API({
		url: `/product/${productId}/specification/${specifcationId}`,
		method: "PUT",
		data: payload
	})
}

// Product Taxonomy Routes

export const fetchProductTaxonomy = (productId) => {
	return API({
		url: `/product/${productId}/taxonomy/technology`,
		method: "GET",
	});
};

export const fetchProductTrend = (productId) => {
	return API({
		url: `/product/${productId}/taxonomy/trend`,
		method: "GET"
	});
};

export const saveProductTaxonomy = (productId, taxonomyIds = []) => {
	return API({
		url: `/product/${productId}/taxonomy/technology`,
		method: "POST",
		data: { taxonomyIds: [...taxonomyIds] }
	});
};

export const deleteProductTaxonomy = (productId, taxonomyId) => {
	return API({
		url: `/product/${productId}/taxonomy/technology/${taxonomyId}`,
		method: "DELETE"
	});
};

export const deleteProductTrend = (productId, trendId) => {
	return API({
		url: `/product/${productId}/taxonomy/trend/${trendId}`,
		method: "DELETE"
	});
};


export const saveProductTrend = (productId, taxonomyIds = [], sgfData = undefined) => {
	return API({
		url: `/product/${productId}/taxonomy/trend`,
		method: "POST",
		data: {
			taxonomyIds: [...taxonomyIds],
			sgfData: sgfData ? sgfData : null
		}
	});
};

// product collaboration api
// fetch a specfic product Collaborations by productId
export const fetchProductCollaborationsByProductId = (productId, params = {}) => {
	return API({
		url: `/product/${productId}/collaboration`,
		method: "GET",
		params: params
	});
};
// fetch a specfic product Collaboration by productId and collaborationId
export const fetchCollaborationByCollaborationId = (productId, collaborationId, params = {}) => {
	return API({
		url: `/product/${productId}/collaboration/${collaborationId}`,
		method: "GET",
		params: params
	});
};
// add a specfic product Collaboration by productId
export const addProductCollaboration = (productId, payload) => {
	return API({
		url: `/product/${productId}/collaboration`,
		method: "POST",
		data: payload
	});
};
// edit a specfic product Collaboration by productId and collaborationId
export const editProductCollaboration = (productId, collaborationId, payload) => {
	return API({
		url: `/product/${productId}/collaboration/${collaborationId}`,
		method: "PUT",
		data: payload
	});
};
// delete a specfic product Collaboration by productId and collaborationId
export const deleteProductCollaboration = (productId, collaborationId) => {
	return API({
		url: `/product/${productId}/collaboration/${collaborationId}`,
		method: "DELETE"
	});
};
// delete many product Collaboration by productId
export const deleteManyProductCollaboration = (productId, collaborationIds = []) => {
	return API({
		url: `/product/${productId}/collaboration`,
		method: "DELETE",
		data: [...collaborationIds]
	});
};


// tag objective
export const saveProductObjective = (payload) => {
	return API({
		url: "/product/objective",
		method: "POST",
		data: payload
	});
};

//tag objective taxonomy
export const saveProductObjectiveTaxonomy = (payload) => {
	return API({
		url: "/product/objective/taxonomy",
		method: "POST",
		data: payload
	});
};

export const fetchProductObjectiveTaxonomy = (objectiveId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/product/objective/taxonomy/${objectiveId}`,
		method: "GET",
		params: params
	});
};

export const deleteProductObjective = (objectiveId, payload) => {
	return API({
		url: `/product/objective/${objectiveId}`,
		method: "DELETE",
		data: payload
	});
};

//KIA Tagging APis

// Fetch KIA Tagging
export const fetchProductKia = (productId) => {
	return API({
		url: `/product/${productId}/taxonomy/kia`,
		method: "GET",
	});
};
// Save KIA tags
export const saveProductKiaTaxonomy = (productId, payload) => {
	return API({
		url: `product/${productId}/taxonomy/kia`,
		method: "POST",
		data: payload
	});
};
// Delete Kia Tagging
export const deleteProductKia = (productId, payload) => {
	return API({
		url: `product/${productId}/taxonomy/kia`,
		method: "DELETE",
		data: payload
	});
};

export const deleteManyProductTrend = (productId, taxonomyIds = []) => {
	return API({
		url: `/product/${productId}/taxonomy/trend`,
		method: "DELETE",
		data: { taxonomyIds: [...taxonomyIds] }
	})
}

export const deleteManyProductTechnology = (productId, taxonomyIds = []) => {

	return API({
		url: `/product/${productId}/taxonomy/technology`,
		method: "DELETE",
		data: { taxonomyIds: [...taxonomyIds] }
	})
}

export const deleteManyProductValueChain = (productId, payload) => {

	return API({
		url: `/product/${productId}/taxonomy/industry-value-chain`,
		method: "DELETE",
		data: payload
	})
}
// Taxonomy bulk tagging
export const saveProductTaxonomyTags = (payload) => {
	return API({
		url: `/product/taxonomy/technology`,
		method: "PUT",
		data: payload
	});
};

// Post  kia
export const saveProductKiaTags = (payload) => {
	return API({
		url: `/product/taxonomy/kia`,
		method: "PUT",
		data: payload
	});
};

// Post  value chain
export const saveValueChainTags = (payload) => {
	return API({
		url: `/product/taxonomy/industry-value-chain`,
		method: "PUT",
		data: payload
	});
};

// Post  trend
export const saveProductTrendTags = (payload) => {
	return API({
		url: `/product/taxonomy/trend`,
		method: "PUT",
		data: payload
	});
};

// Post  Media
export const saveProductMediaTags = (payload) => {
	return API({
		url: `/product/taxonomy/media`,
		method: "PUT",
		data: payload
	});
};

//tag objective taxonomy
export const saveProductBusinessObjectiveTaxonomy = (payload) => {
	return API({
		url: "/product/objective/taxonomy",
		method: "PUT",
		data: payload
	});
};

//   add or update sequence of display to taxonomy
export const saveProductTrendSequence = (productId, payload) => {
	return API({
		url: `/product/${productId}/taxonomy/trend/sort-order`,
		method: "PUT",
		data: payload
	});
}
//Update content tags for product
export function updateContentTags(productId, payload) {
	return API({
	  url: `/product/all/${productId}/tags`,
	  method: "PUT",
	  data: payload,
	});
  }
