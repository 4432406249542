/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
    File Description : Impact Area Validation Schema
----------------------------------------------------------------
    Creation Details
    Date Created				: 08/June/2021
    Author						: Sandeep K. Sharma
================================================================
*/
import * as Yup from "yup";
export const overviewValidationSchema = {
    kiaIndustryTag: Yup.string().required("Please select industry"),
    kiaName: Yup.string().trim("Please enter impact area name").required("Please enter impact area name").max(200, "Impact area name should not be more than 150 characters"),
    kiaDescription: Yup.string().trim("Please enter impact area description").required("Please enter impact area description")
        .min(75, "Impact area description must have at least 75 characters")
    // .max(5000, "Impact area description should not be more than 5000 characters")
    ,
    kiaCurrentResearchStartups: Yup.string().min(75, "Current research & start-ups must be at least 75 characters")
    // .max(5000, "Current research & start-ups  should not be more than 5000 characters")
    ,
    kiaScore: Yup.number().required("Please enter a number between 0 to 5")
        .typeError('Please enter a number between 0 to 5')
        .max(5, "Please enter number between 0 to 5"),
    kiaClientUsers: Yup.string().when('customKia', {
        is: obj => { return obj && obj === "Yes" },
        then: Yup.string().required("Please select Client User(s)"),
        otherwise: Yup.string().nullable(),
        // .oneOf([null, undefined], 'Please select demo users'),
    }),
    kiaExpertNetworkStrength: Yup.number().typeError("Please enter number only").required("Please enter expert network strength").nullable(),
    kiaImpactAreaSurveyContributors: Yup.number().typeError("Please enter number only").required("Please enter impact area survey contributions").nullable(),
    kiaClientExpertEngagements: Yup.number().typeError("Please enter number only").required("Please enter client expert engagements").nullable(),
    kiaNextUpdateDate: Yup.date().nullable(),
};

export const IndustryAppValidationSchema = {
    industryName: Yup.string().required("Please select industry"),
    applicationDescription: Yup.string().trim("Please enter application description").required("Please enter application description")
        .min(100, "Application description must be at least 100 characters").max(2500, "Application description should not be more than 2500 characters"),
    applicationCurrentStateOfResearch: Yup.string().max(2500, "Current state of research should not be more than 2500 characters"),
    applicationImpact: Yup.string().max(2500, "What impact will it have should not be more than 2500 characters"),
    applicationTimescale: Yup.string().required("Please select appliaction timescale"),

};
export const addExponentialTechValidationSchema = {
    technologyName: Yup.string().required("Please select technology"),
    subTechnology: Yup.string().required("Please select sub technology"),
    technologyDescription: Yup.string().trim("Please enter technology description").required("Please enter technology description")
        .min(100, "Technology description must be at least 100 characters").max(2500, "Technology description should not be more than 2500 characters"),
    technologyPotentialTimescale: Yup.string().required("Please select potential timescale"),
};

export const addCurrentChallengesValidationSchema = {
    challenge: Yup.string().trim("Please enter challenge").required("Please enter challenge")
        .min(100, "Challenge must be at least 100 characters").max(2500, "challenge should not be more than 2500 characters"),
    challangePotentialBenefit: Yup.string().trim("Please enter potential benefit").required("Please enter potential benefit")
        .min(100, "Potential benefit must be at least 100 characters").max(2500, "Potential benefit should not be more than 2500 characters"),
    challangeDescription: Yup.string().trim("Please enter challenge description").required("Please enter challenge description")
        .min(100, "challenge description must be at least 100 characters").max(2500, "challenge description should not be more than 2500 characters"),
    challengeLevel: Yup.string().required("Please select challenge Level"),
    challengeTimescale: Yup.string().required("Please select challenge timescale"),
};

export const customBriefValidationSchema = {
    topic: Yup.string().trim("Please enter impact area topic").required("Please enter impact area topic").max(200, "Impact area topic should not be more than 150 characters"),
    description: Yup.string().trim("Please enter impact area description").required("Please enter impact area description")
        .min(75, "Impact area description must be at least 75 characters").max(1000, "Impact area description should not be more than 1000 characters"),
    questions: Yup.string().trim("Please enter Important Questions").min(75, "Important Questions must be at least 75 characters"),
    profileOfExperts: Yup.string().trim("Please provide desired profile for Experts").min(50, "Profile of Experts must be at least 50 characters")
};