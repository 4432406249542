import { TEMPLATE_API as API } from "../../config/apiConfig";

export function fetchTemplates (params) {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: "/template",
		method: "GET",
		params: params
	});
}

export function fetchSingleTemplate (templateId, params = "") {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/template/${templateId}`,
		method: "GET",
		params: params
	});
}

export function updateTemplate(templateId, payload) {
	return API({
		url: `/template/${templateId}`,
		method: "PUT",
		data: payload
	});
}

export function deleteTemplate(templateId) {
	return API({
		url: `/template/${templateId}`,
		method: "PUT"
	});
}

export function deleteManyTemplate(params, payload) {
	const { filters } = params;
	if (filters) params.filters = JSON.stringfiy(filters);

	return API({
		url: "/deliverable",
		method: "DELETE",
		params: params,
		data: payload
	});
}

export const saveTemplateContent = (payload) => {
	return API({
		url: "/template",
		method: "POST",
		data: payload
	});
};

export const fetchSinglePageContent = (templateId,fileName) => {
	return API({
		url: `/template/${templateId}/page/${fileName}`,
		method: "GET"
	});
};