/*
================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Dynamic & Modular Sync Fusion Popup Dialouge
----------------------------------------------------------------
    Creation Details
    Date Created				: 03/Dec/2020
    Author						: PANKAJ KULSHRESHTHA
================================================================
*/

import "./index.scss";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { hidePopup } from "../../../middleware/actions/popupAction";

const defaultCallback = () => {
  return;
};

const Popup = (props) => {
  const dispatch = useDispatch();
  const {
    showPopup = false,
    title = "Popup Title Here",
    okText = "Yes",
    okCallback = defaultCallback,
    showCancelButton = true,
    cancelText = "No",
    cancelCallback = defaultCallback,
    content = "You can put your content here. Content may be string or html.",
    children = <></>,
    data = undefined,
    width = "300px",
  } = props;

  const buttons = [
    {
      buttonModel: {
        content: okText,
        cssClass: "e-flat ok-btn",
        isSecondary: false,
      },
      click: () => {
        okCallback(data);
        closeDialog();
      },
    },
    {
      buttonModel: { content: cancelText, cssClass: "e-flat" },
      click: () => {
        cancelCallback(data);
        closeDialog();
      },
    },
  ];
  if (!showCancelButton) buttons.pop();
  const closeDialog = () => {
    // setShowDialig(false);
    dispatch(hidePopup());
  };
  const formatHeader = (hTitle) => {
    return <div class="txt-danger bg-secondary">{hTitle}</div>;
  };
  return (
    <>
      <DialogComponent
        isModal={true}
        width={width}
        target="#root"
        visible={showPopup}
        close={() => closeDialog()}
        header={title}
        allowDragging={true}
        showCloseIcon={true}
        buttons={buttons}
        zIndex="999"
        animationSettings={{ delay: 200, effect: "FadeZoom", duration: 400 }}
        content={content}
        children={children}
      >
        {children && children}
      </DialogComponent>
    </>
  );
};

export default Popup;

Popup.propTypes = {
  width: PropTypes.string,
  showPopup: PropTypes.bool,
  title: PropTypes.string,
  okText: PropTypes.string,
  okCallback: PropTypes.func,
  showCancelButton: PropTypes.bool,
  cancelText: PropTypes.string,
  cancelCallback: PropTypes.func,
  content: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.any,
};
