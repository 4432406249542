/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  People Add | Edit Page
	 Summary : Render Utility component is responsible for rendering respective component 
	 [e.g Overview | Experience | Education]
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 13/Apr/2021 
	Author						: Sandeep K Sharma
================================================================ 
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { fetchSinglePeople } from "../../../middleware/services/peopleApi";
import { accessRightActionCheck } from "../../../utilities";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import Overview from "./component/overview";
import Experience from "./component/experience";
import Education from "./component/education";
import { COMMON_ACCESS_ACTION } from "../../../constants";

const Add = (props) => {
  const { accessRights: aR } = props;
  const peopleId = props.computedMatch?.params?.peopleId || "";
  const [peopleTitle, setPeopleTitle] = useState("");
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  /*
		@Description : Define breadcrumb for Add | Edit People
	*/
  const breadCrumbLinks = [
    { linkUrl: "/people", linkName: aR.moduleName, linkActive: false },

    {
      linkUrl: peopleId ? `/people/${peopleId}/edit` : `/people/add`,
      linkName: peopleId ? `${peopleTitle}` : "Add New",
      linkActive: true,
    },
  ];
  /* Common access */
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionAddExperience = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD_EXPERIENCE);
  const accessActionEditExperience = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT_EXPERIENCE);
  const accessActionDeleteExperience = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE_EXPERIENCE);
  const accessActionAddEducation = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD_EDUCATION);
  const accessActionEditEducation = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT_EDUCATION);
  const accessActionDeleteEducation = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE_EDUCATION);

  const dispatch = useDispatch();
  /*
		@Description : Set active link on sidebar for People
	*/
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/people"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, peopleTitle]);
  /*
		@Description : Retrieve respective news data 
	*/
  useEffect(() => {
    if (peopleId != "") {
      const response = fetchSinglePeople(peopleId, {
        fields: ["people_first_name"],
      });
      response.then((response) => {
        if (response.status == 200) {
          setPeopleTitle(response.data.data.peopleFirstName);
        }
      });
    }
  }, [peopleId]);

  /*Interface functionality wise access */
  useEffect(() => {
    let interfaceName = "Basic";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <div className="ppl-content-wrapper people-mgmt gennx-envelope" style={{ background: "#F2F2F2" }}>
      <div className="pt-3 pb-5" style={{ width: "99.5%", overflow: "auto", height: "100vh" }}>
        <div className="mx-3 ">
          <Overview peopleId={peopleId} accessActionSave={accessActionSave} />
          <Experience
            peopleId={peopleId}
            moduleName={aR.moduleName}
            accessActionAddExperience={accessActionAddExperience}
            accessActionEditExperience={accessActionEditExperience}
            accessActionDeleteExperience={accessActionDeleteExperience}
          />
          <Education
            peopleId={peopleId}
            moduleName={aR.moduleName}
            accessActionAddEducation={accessActionAddEducation}
            accessActionEditEducation={accessActionEditEducation}
            accessActionDeleteEducation={accessActionDeleteEducation}
          />
        </div>
      </div>
    </div>
  );
};

export default Add;
