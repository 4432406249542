/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Alert Box State (to show / hide)
----------------------------------------------------------------
	Creation Details
	Date Created				: 09/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { SHOW_ALERT_BOX, HIDE_ALERT_BOX } from "../../constants/actionConstants";

const defaultCallback = () => {
	return;
};

const DEFAULT_STATE = {
	showAlert: false,
	title: "Confirm",
	okText: "Yes",
	okCallback: defaultCallback,
	cancelText: "No",
	cancelCallback: defaultCallback,
	content: "Are you sure, you want to continue?",
	data: undefined,
	hideCancelButton: false
};

export default (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case SHOW_ALERT_BOX: {
			return {
				...state, showAlert: true, ...action.payload
			};
		}
		case HIDE_ALERT_BOX: {
			return {
				...state, showAlert: false
			};
		}
		default:
			return state;
	}
};
