// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description :Content card
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :21 Nov 2023
// Author:Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

// id title date label industryTag entityType infoText profileImage video videoDuration

import React, { useState, useEffect, useRef } from "react";
import CommonDateTagSection from "../CommonDateTagSection/CommonDateTagSection";
import BookmarkInfoPair from "../BookmarkInfoPair/BookmarkInfoPair";
import ImageFile from "./imageFile";
import classes from "./contentCard.module.css";
import Routes from "../../../routes";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getPages } from "../../../middleware/services/deliverablesApi";
import ModalVideo from "react-modal-video";
import { accessRightActionCheck } from "utilities";
import { COMMON_ACCESS_ACTION, CONTENT_TYPE } from "../../../constants";

const useStyles = makeStyles(() => ({
  root: {
    height: 5,
    borderRadius: 5,
    background: "#d3cdcd !important",
  },
  colorPrimary: {
    backgroundColor: 200,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#f94f5e !important",
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Box className="d-flex align-items-center" ref={props.tooltipTextRef}>
      <Box className="mr-1 pr-2" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} style={{ width: "33%" }}>
        <LinearProgress
          variant="determinate"
          classes={{
            root: classes.root,
            colorPrimary: classes.colorPrimary,
            bar: classes.bar,
          }}
          {...props}
        />
      </Box>
      <Box className="mr-1 d-flex" style={{ minWidth: "35px", width: "67%" }} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        <Typography color="#f94f5e" variant="body2" fontSize="12px">{`${props.value}%`}</Typography>
        <Typography color="#f94f5e" variant="body2" className="pl-1" fontSize="12px">{` match`}</Typography>
      </Box>
    </Box>
  );
}

const ContentCard = ({
  item,
  redirectToPage,
  contentCardClass,
  sgfFilters,
  imageClassName,
  imageStyles,
  titleStyles,
  alwaysShowFullCard = false,
  showProgressBar = false,
  isSiteSearch = false,
  showLikeSection = false,
  liked,
  count,
  handleCount,
}) => {
  const tooltipRef = useRef(null);
  const tooltipTextRef = useRef(null);
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [isOpenVideo, setOpenVideo] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [videoId, setVideoId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showBottomTooltip, setShowBottomTooltip] = useState(null);
  const [listingActionAccess, setListingActionAccess] = useState([]);

  const accessAdministrator = listingActionAccess.includes(COMMON_ACCESS_ACTION.REPORT_ADMINISTRATOR);
  /**
   * The function opens a registration link if the video status is "Upcoming" and opens a modal if the
   * status is anything else.
   */
  const getVideoStatus = (item) => {
    if (item.status === "Upcoming" && item.ribbon === "Webinar") {
      window.open(item.registrationLink, "_blank", "noreferrer");
    } else {
      setOpen(true);
    }
  };

  const redirectToNewPage = (id, entityType) => {
    // dispatch(
    //   uiAction.updateUrlParams({
    //     id: id,
    //     origin: '',
    //     page: '',
    //     sort: '',
    //     query: '',
    //   }),
    // );
    if (entityType === "news") {
      window.open(`/news-and-commentary/${id}?focusMode=true`, "_blank", "noreferrer");
    } else if (entityType === "company") {
      window.open(`/company-profile/${id}?focusMode=true`, "_blank", "noreferrer");
    } else if (entityType === "document") {
      window.open(`/key-reports/${id}?focusMode=true`, "_blank", "noreferrer");
    } else {
      history.replace(Routes.rootPath);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function mergeStrings(string1, string2) {
    const emMatches = string2.match(/<em>(.*?)<\/em>/g);
    if (emMatches) {
      emMatches.forEach((emMatch) => {
        const textWithinEm = emMatch.match(/<em>(.*?)<\/em>/)[1];
        const regex = new RegExp(`\\b${textWithinEm}\\b`, "g");
        string1 = string1.replace(regex, emMatch);
      });
    }
    const filteredString = string1.replace(/<em>(<em>)+/g, "<em>").replace(/(<\/em>)+<\/em>/g, "</em>");
    return filteredString;
  }

  const getHighlightedTitle = (item) => {
    if (!item.highlighting) return item.title;
    if (item.entityType === "company" && item.highlighting.name) return mergeStrings(item.title, item.highlighting.name[0]);
    if (item.highlighting.title) return mergeStrings(item.title, item.highlighting.title[0]);
    return item.title;
  };

  const toSentenceCase = (camelCase) => {
    if (camelCase) {
      const result = camelCase.replace(/([A-Z])/g, " $1");
      return result[0].toUpperCase() + result.substring(1).toLowerCase();
    }
    return "";
  };

  const getVideoContent = async (item) => {
    setVideoSource("");
    setVideoId("");
    setOpenVideo(false);
    let pages = await getPages(item.id);
    let videoSrc = [];
    if (!pages.length) return redirectToPage();
    pages.forEach((page) => {
      for (let block of page?.pages) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = block.pageContentHtml;
        const iframes = tempDiv.querySelectorAll("iframe");
        if (iframes?.length > 0) {
          for (const iframe of iframes) {
            const src = iframe.src;
            if (src.includes("youtube")) {
              // Use a regular expression to extract the video ID
              const match = src.match(/\/embed\/([a-zA-Z0-9_-]+)/);
              if (match) {
                const videoId = match[1];
                // Duration of Youtube
                if (videoId) {
                  videoSrc.push({ videoSource: "youtube", videoId: videoId });
                }
              }
            }
            if (src.includes("vimeo")) {
              // Use a regular expression to extract the video ID
              const match = src.match(/\/(\d+)(?:\?|#|$)/);
              if (match) {
                const videoId = match[1];
                // Duration of Vimeo
                if (videoId) {
                  videoSrc.push({ videoSource: "vimeo", videoId: videoId });
                }
              }
            }
          }
        }
      }
    });
    if (videoSrc.length) {
      setVideoSource(videoSrc[0]?.videoSource);
      setVideoId(videoSrc[0]?.videoId);
      setOpenVideo(true);
    } else {
      return redirectToPage();
    }
  };

  const handleMouseEnter = () => {
    const tooltip = tooltipRef.current.getBoundingClientRect();
    tooltipRef.current.style.display = "block";
    if (tooltip.y < 300) {
      setShowBottomTooltip(true);
    } else setShowBottomTooltip(false);
  };

  const handleMouseLeave = () => {
    setShowBottomTooltip(null);
    tooltipRef.current.style.display = "none";
  };
  useEffect(() => {
    const handleScroll = () => {
      setAnchorEl(null);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (tooltipTextRef.current) {
      tooltipTextRef.current.addEventListener("mouseenter", handleMouseEnter);
      tooltipTextRef.current.addEventListener("mouseleave", handleMouseLeave);
    }
  }, [tooltipTextRef.current]);

  const removeHtmlTags = (htmlString, highlighting) => {
    let html = htmlString.replace(/&nbsp;/g, " ").replace(/<(?!\/?em\b)[^>]*>/g, "");
    return html;
  };

  const calculateScore = (item) => {
    if (!item.highlighting) return {};
    const url = new URL(window.location.href);
    let keywords = url.searchParams.get("query")?.split(" | ");
    // Extract unique keywords from the provided data
    const uniqueKeywords = new Set();

    for (const key in item.highlighting) {
      if (item.highlighting.hasOwnProperty(key)) {
        item.highlighting[key].forEach((text) => {
          const matches = text.match(/<em>(.*?)<\/em>/g);
          if (matches) {
            matches.forEach((match) => {
              const keyword = match.replace(/<\/?em>/g, "").toLowerCase(); // Convert to lowercase
              uniqueKeywords.add(keyword);
            });
          }
        });
      }
    }
    const uniqueKeywordsArray = Array.from(uniqueKeywords);
    if (uniqueKeywordsArray.length === keywords.length) {
      return "100";
    } else return item.score;
  };

  useEffect(() => {
    let interfaceName = "Listing";
    let actionAccess = accessRightActionCheck("Deliverables", interfaceName);
    setListingActionAccess(actionAccess);
  }, []);

  return (
    <div className={`${classes.recent_content_card} ${!alwaysShowFullCard ? `recent_mobile_card` : ``}`} onClick={handleClose}>
      <div
        className={`${classes.recent_content_img} recent_content_img`}
        // onTouchStart={handleTouchStart}
        // onTouchEnd={handleTouchEnd}
        // onTouchMove={handleTouchEnd}
      >
        {/* {item.ribbon ? (
            <div id={`${classes.ribbon_container}`}>
              <div href="#" id={`${classes.ribbon}`}>
                {item.ribbon}
              </div>
            </div>
          ) : null} */}

        <BookmarkInfoPair
          className={`${classes.pairOuterDiv} bookmarkOuter`}
          item={item}
          entityType={item.label}
          entityTitle={item.title}
          infoText={item.infoText}
          showThreeDot={true}
          deliverableAccessUsers={[
            ...(item.deliverableTaskWriters || []), // Check if it's iterable or provide an empty array
            ...(item.deliverableTaskReviewers || []),
            ...(item.deliverableTaskPublishers || []),
            ...(item.deliverableCreatedBy || []),
          ]}
          accessAdministrator={accessAdministrator}
        />
        <ImageFile
          fileName={item.profileImage}
          entityType={item.entityType}
          className={imageClassName}
          imageStyle={imageStyles}
          redirectToPage={redirectToPage}
          getVideoStatus={getVideoStatus}
          item={item}
        />

        {item.video && item.entityType === "video" && (
          <>
            <img
              onClick={() => {
                getVideoStatus(item);
              }}
              className={classes.play_icon}
              src="/assets/icon-play.svg"
              alt="img"
            ></img>

            {item.video.videoSource === "YouTube" ? (
              <ModalVideo
                channel="youtube"
                youtube={{
                  autoplay: 1,
                  mute: 1,
                }}
                isOpen={isOpen}
                videoId={item.video.videoId}
                onClose={() => {
                  setOpen(false);
                }}
              />
            ) : (
              <ModalVideo
                channel="vimeo"
                vimeo={{
                  autoplay: 1,
                  mute: 1,
                }}
                isOpen={isOpen}
                videoId={item.video.videoId}
                onClose={() => {
                  setOpen(false);
                }}
              />
            )}
          </>
        )}
        {!item?.video && item.label === CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS && (
          <>
            <img
              onClick={() => {
                getVideoContent(item);
              }}
              className={classes.play_icon}
              src="/assets/icon-play.svg"
              alt="img"
            ></img>
            {videoSource === "youtube" && (
              <ModalVideo
                channel="youtube"
                youtube={{
                  autoplay: 1,
                  mute: 1,
                }}
                isOpen={isOpenVideo}
                videoId={videoId}
                onClose={() => {
                  setOpenVideo(false);
                }}
              />
            )}
            {videoSource === "vimeo" && (
              <ModalVideo
                channel="vimeo"
                vimeo={{
                  autoplay: 1,
                  mute: 1,
                }}
                isOpen={isOpenVideo}
                videoId={videoId}
                onClose={() => {
                  setOpenVideo(false);
                }}
              />
            )}
          </>
        )}
        <div className={`d-flex justify-content-end position-absolute ${classes.clock}`}>
          {item.readTime !== 0 && item.readTime && (
            <div className="d-flex align-items-center rounded bg-dark text-white py-1 px-2 ms-1 ms-md-2">
              <img src="/assets/clock-white.svg" alt="clockIcon" width={12}></img>
              <span className="ms-1 text-xs" style={{ marginLeft: "5px" }}>
                {item.readTime} min
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={`content-card-like ${classes.recent_content_desc} ${classes.recent_content_desc_site_search}`}>
        <h4 onClick={redirectToPage} id="RECENT">
          <div className={`${classes.recent_content_site_search_title} not-italic`}>{ReactHtmlParser(getHighlightedTitle(item))}</div>
        </h4>
        <CommonDateTagSection
          date={item.date}
          label={item.label}
          tags={item.industryTag}
          sgfFilters={sgfFilters}
          ribbon={item.ribbon}
          highlighting={item.highlighting}
        ></CommonDateTagSection>

        {item.isPublished ? (
          <span style={{ backgroundColor: `#D2EFE8`, padding: "2px 5px", borderRadius: "3px", color: "#0CB963", fontSize: "14px" }}>Published</span>
        ) : (
          <span
            style={{
              backgroundColor: `#FDD14133`,
              padding: "2px 5px",
              borderRadius: "3px",
              color: "#FDD141",
              fontSize: "14px",
            }}
          >
            In Progress
          </span>
        )}
        {item?.deliverableTaskWriters?.length ||
        item?.deliverableTaskReviewers?.length ||
        item?.deliverableTaskPublishers?.length ||
        item?.deliverableCreatedBy ||
        item?.newsCreatedBy ||
        item?.companyCreatedBy ||
        item?.videoCreatedBy ? (
          <div className={`${classes.date_summary} mobile_date_summary`}>
            {item?.deliverableTaskWriters?.length ? (
              <span className={`${classes.writer}`} title="Writer">
                <img src="/assets/search-creator-icon.svg" alt="creatorIcon" width={12}></img>
                <p style={{ paddingLeft: "1px", paddingRight: "1px" }}>{item.deliverableTaskWriters[0]?.split("|")[0]}</p>
              </span>
            ) : (
              item?.deliverableCreatedBy?.length && (
                <span className={`${classes.writer}`} title="Writer">
                  <img src="/assets/search-creator-icon.svg" alt="creatorIcon" width={12}></img>
                  <p style={{ paddingLeft: "1px", paddingRight: "1px" }}>{item.deliverableCreatedBy[0].split("|")[0]}</p>
                </span>
              )
            )}
            {item?.deliverableTaskReviewers?.length && (
              <span className={`${classes.reviewer}`} title="Reviewer">
                <img src="/assets/search-reviewer-icon.svg" alt="reviewerIcon" width={12}></img>
                <p style={{ paddingLeft: "1px", paddingRight: "1px" }}>{item.deliverableTaskReviewers[0]?.split("|")[0]}</p>
              </span>
            )}
            {item?.deliverableTaskPublishers?.length && (
              <span className={`${classes.publisher}`} title="Publisher">
                <img src="/assets/search-publisher-icon.svg" alt="publisherIcon" width={12}></img>
                <p style={{ paddingLeft: "1px", paddingRight: "1px" }}>
                  {item.deliverableTaskPublishers[0]?.split("|")[0]?.split(" ")[0]?.length > 7
                    ? `${item.deliverableTaskPublishers[0]?.split("|")[0].slice(0, 7)}...`
                    : item.deliverableTaskPublishers[0]?.split("|")[0]}
                </p>
              </span>
            )}
            {item?.newsCreatedBy?.length && (
              <span className={`${classes.writer}`} title="Writer">
                <img src="/assets/search-creator-icon.svg" alt="creatorIcon" width={12}></img>
                <p style={{ paddingLeft: "1px", paddingRight: "1px" }}>{item.newsCreatedBy[0]?.split("|")[0]}</p>
              </span>
            )}
            {item?.companyCreatedBy?.length && (
              <span className={`${classes.writer}`} title="Writer">
                <img src="/assets/search-creator-icon.svg" alt="creatorIcon" width={12}></img>
                <p style={{ paddingLeft: "1px", paddingRight: "1px" }}>{item.companyCreatedBy[0].split("|")[0]}</p>
              </span>
            )}
            {item?.videoCreatedBy?.length && (
              <span className={`${classes.writer}`} title="Writer">
                <img src="/assets/search-creator-icon.svg" alt="creatorIcon" width={12}></img>
                <p style={{ paddingLeft: "1px", paddingRight: "1px" }}>{item.videoCreatedBy[0].split("|")[0]}</p>
              </span>
            )}
          </div>
        ) : (
          <div style={{ height: "29px" }}></div>
        )}

        {showProgressBar && (
          <div className={`${classes.circular_progressImg} cursor-pointer position-absolute bottom-0 start-0 w-100 mt-2`}>
            <div style={{ height: "20px", marginTop: "10px" }}>
              <LinearProgressWithLabel
                variant="determinate"
                value={calculateScore(item)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                tooltipTextRef={tooltipTextRef}
                item={item}
              />
            </div>
            <div className={`${showBottomTooltip ? classes.bottom_info_content : classes.top_info_content}`} ref={tooltipRef}>
              {Object.keys(item.highlighting)?.map((i, index) => (
                <div
                  key={i + Math.random()}
                  className={`d-flex my-1 ${i?.includes("matchedKeywords") || i?.includes("relevanceScore") ? "flex-row" : "flex-column"}`}
                >
                  <div
                    className={`font-weight-bold text-capitalize mx-2`}
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: i?.includes("matchedKeywords") || i?.includes("relevanceScore") ? "40%" : "100%",
                    }}
                  >
                    {ReactHtmlParser(toSentenceCase(i))}:
                  </div>
                  <div
                    className={`${classes.circular_progressImg_vlaue} ${
                      i?.includes("matchedKeywords") || i?.includes("relevanceScore") ? "px-3" : "mx-2"
                    } ${i?.includes("matchedKeywords") || i?.includes("relevanceScore") ? "font-weight-bold" : ""}`}
                    style={{
                      fontSize: "12px",
                      fontWeight: i?.includes("matchedKeywords") || i?.includes("relevanceScore") ? "bold" : "",
                      width: i?.includes("matchedKeywords") || i?.includes("relevanceScore") ? "60%" : "100%",
                    }}
                  >
                    {ReactHtmlParser(
                      item.highlighting[i] && item.highlighting[i]?.length > 0
                        ? i?.includes("relevanceScore")
                          ? calculateScore(item) + "% "
                          : removeHtmlTags(item.highlighting[i][0], item.highlighting)
                        : ""
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentCard;
