/*================================================================
‘Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Component use for add actions in Grid 
     - Add three dot menu in common grid
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 22/Apr/2021 
    Author						: Sandeep K Sharma
================================================================ 
*/
import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import { startCase, camelCase } from "lodash";

export default function ThreeDotMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  /* 
        @Description : Open Menu Items
    */

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  /*     
        @Description : Close Menu Items
    */

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* 
        @Description : Handle all action passed in Threedot props and called its props method
    */
  const handleAction = (fn) => {
    fn(props.rowID);
    setAnchorEl(null);
  };
  return (
    <div>
      {!props.isSiteSearchThreeDot ? (
        <IconButton aria-owns={anchorEl ? "three-dot-menu" : undefined} aria-haspopup="true" onClick={handleClick} size="small">
          <MoreVertIcon fontSize="small" />
        </IconButton>
      ) : (
        <img src={"/assets/three-dot-icon.svg"} alt="info" onClick={handleClick} />
      )}
      <Popover
        id="three-dot-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        size="small"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {Object.keys(props).map((keyName, i) => {
          if (typeof props[keyName] === "function") {
            return (
              <MenuItem key={keyName} onClick={() => handleAction(props[keyName])}>
                {startCase(camelCase(keyName))}
              </MenuItem>
            );
          }
        })}
      </Popover>
    </div>
  );
}
