/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  All Form elements will use in making form 
 ---------------------------------------------------------------------------------
	Creation Details
	Date Created				: 16/Mar/2021
	Author						: Pankaj Kulshreshtha
================================================================
*/
import React, { useState, useEffect, useRef } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent, TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { CheckBoxSelection, Inject, MultiSelectComponent, DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import CKEditor5 from "app/components/ckEditor5";
import "./css/custom.scss";
import PropTypes from "prop-types";

const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    // You can also use state.isFocused to conditionally style based on the focus state
  }),
};
export const DatePicker = (props) => {
  const [value, setValue] = useState("");
  const { name, label } = props.properties;

  useEffect(() => {
    setValue(props.formik.values[name]);
  }, [props.formik.values, name]);

  return (
    <div className={`form-group ${(props.required && props.required) || ""}`}>
      <DatePickerComponent
        cssClass={"e-outline "}
        // floatLabelType="Auto"
        autoComplete="off"
        {...props.properties}
        {...props.formik.getFieldProps(name)}
      />
      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
      </div>
    </div>
  );
};
export const TimePicker = (props) => {
  const [value, setValue] = useState("");
  const { name, label } = props.properties;

  useEffect(() => {
    setValue(props.formik.values[name]);
  }, [props.formik.values, name]);

  return (
    <div className={`form-group ${(props.required && props.required) || ""}`}>
      <TimePickerComponent
        cssClass={"e-outline"}
        // floatLabelType="Auto"
        autoComplete="off"
        {...props.properties}
        {...props.formik.getFieldProps(name)}
      />
      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
      </div>
    </div>
  );
};
export const InputGroupText = (props) => {
  const [value, setValue] = useState("");

  const onInput = (e) => {
    let word;
    let addressCount;
    word = e.value;
    addressCount = word.length;
    document.getElementById(`${props.id}Count`).textContent = addressCount + "/" + props.maxLength;
  };
  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  return (
    <div className={`form-group ${(props.required && props.required) || ""}`}>
      <div className="e-float-input e-input-group" style={{ top: "-15px", borderBottom: 0 }}>
        <span className="e-input-group-text p-2" style={{ border: "1px solid darkgrey", width: "340px", height: "40px", fontSize: "14px" }}>
          {props.inputGroupText}
        </span>
        {/* <input required type="text" /> */}
        <TextBoxComponent
          // className="form-control"
          {...props.formik.getFieldProps(...props.name)}
          autoComplete="off"
          value={value}
          {...props}
          // enablePersistence={true}
          showClearButton={true}
          cssClass={"customCss e-outline"}
          floatLabelType="Auto"
          input={props.numberCount && onInput}
        />
        {/* <span class="e-float-line"></span> */}
        {/* <label class="e-float-text"> {props.placeholder} </label> */}
      </div>
      <div
        className="text-danger"
        style={{ display: props.formik.touched[props.name] === true ? "block" : "none", left: "220px", top: "-18px", position: "relative" }}
        role="alert"
      >
        <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
      </div>
    </div>
  );
};
export const TextField = (props) => {
  const [value, setValue] = useState("");
  const textAreaRef = useRef();
  const onInput = (e) => {
    let word;
    let addressCount;
    word = e.value;
    addressCount = word.length;
    document.getElementById(`${props.id}Count`).textContent = addressCount + "/" + props.maxLength;
  };

  useEffect(() => {
    let name = props.name;
    setValue(props.formik.values[name]);
    props.multiline && document.getElementsByTagName("textarea")[0].setAttribute("rows", props.rows || 0);
  }, [props.formik.values, props.name, props.rows]);

  return (
    <div className={`form-group ${(props.required && props.required) || ""}`}>
      {props.inputGroupText && (
        <div className="e-float-icon-left">
          {/* <span className="input-group-text mb-1 border-0">{props.inputGroupText}</span> */}

          <TextBoxComponent
            // className="form-control"
            ref={textAreaRef}
            {...props.formik.getFieldProps(...props.name)}
            autoComplete="off"
            value={value}
            {...props}
            // enablePersistence={true}
            showClearButton={true}
            cssClass={"customCss e-outline"}
            floatLabelType="Auto"
            input={props.numberCount && onInput}
          />
        </div>
      )}
      {!props.inputGroupText && (
        <TextBoxComponent
          // className="form-control"
          {...props.formik.getFieldProps(...props.name)}
          autoComplete="off"
          value={value}
          {...props}
          // enablePersistence={true}
          showClearButton={true}
          cssClass={"customCss e-outline"}
          floatLabelType="Auto"
          input={props.numberCount && onInput}
        />
      )}
      {props.numberCount && <span className="float-right" id={`${props.id}Count`}>{`${value.length || 0}/${props.maxLength}`}</span>}
      <div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
      </div>
    </div>
  );
};
export const Checkbox = (props) => {
  const [checked, setChecked] = useState(false);
  const { name, label } = props.properties;

  useEffect(() => {
    setChecked(props.formik.values[name] === "YES" ? true : false);
  }, [props.formik.values, name, checked]);

  return (
    <div className={`form-group ${(props.required && props.required) || ""}`}>
      <input
        // className="form-control"
        // cssClass={'e-outline'}
        autoComplete="off"
        {...props.properties}
        {...props.formik.getFieldProps(name)}
        checked={checked}
        onChange={(e) => {
          setChecked(!checked);
          props.formik.setFieldValue(name, e.target.checked ? "YES" : "NO", true);
        }}
      />
      <label>&nbsp;{label} </label>
      {
        <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      }
    </div>
  );
};
export const RadioGroup = (props) => {
  const { name, label } = props.properties;

  useEffect(() => {
    // props.formik.values.clientUsers && props.formik.values.clientUsers.length > 0 ? props.formik.setFieldValue(name, "Client User", true):
    // props.formik.setFieldValue(name, props.formik.values[name], true) ;
  }, [props.formik.values, name, props.formik.values.clientUsers]);

  return (
    <div className={`form-group ${(props.required && props.required) || ""}`}>
      <label>Choose Audience From</label>
      <br />
      <div className="row">
        {props.optionArr &&
          props.optionArr.map((v, i) => {
            return (
              <>
                <div className="col-md-1">
                  <input
                    type="radio"
                    className="form-control radio"
                    checked={
                      props.formik.values[name] === v ||
                      (props.formik.values.clientUsers && props.formik.values.clientUsers.length > 0 && v === "Client User")
                    }
                    onClick={(e) => {
                      if (e.target.checked) {
                        const s = e.target.id.split("_");
                        props.formik.setFieldValue(name, props.optionArr[s[1]], true);
                      }
                    }}
                    id={`${name}_${i}`}
                    {...props.properties}
                    // {...props.formik.getFieldProps(name)}
                  />
                </div>
                <span className="pt-1">{v} </span>
              </>
            );
          })}
      </div>
      {
        <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
          <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
        </div>
      }
    </div>
  );
};

export const TextArea = (props) => {
  const [value, setValue] = useState("");
  const [count, setCount] = useState(props.maxLength);
  const { name, label } = props.properties;

  useEffect(() => {
    setValue(props.formik.values[name]);
    props.formik.values[name] && setCount(props.maxLength - props.formik.values[name].length);
  }, [props.formik.values, name]);

  return (
    <div className={`form-group ${(props.required && props.required) || ""}`}>
      <label>{label} </label>
      <textarea
        className="form-control"
        autoComplete="off"
        value={value}
        {...props.properties}
        {...props.formik.getFieldProps(name)}
        onKeyUp={(e) => {
          document.getElementById(name + "_").innerHTML = props.maxLength - e.target.value.length;
        }}
      />
      <b style={{ fontWeight: 500, display: props.showCharCount ? "" : "none" }} htmlFor="objective">
        Characters left:&nbsp;
      </b>
      <span id={name + "_"}>{count}</span>
      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
      </div>
    </div>
  );
};
export const MultiSelect = (props) => {
  const [value, setValue] = useState("");
  const { name } = props;
  useEffect(() => {
    props.formik.values[name] && setValue(props.formik.values[name]);
  }, [
    // props.formik.values,
    name,
  ]);

  return (
    <div className="form-group required">
      <MultiSelectComponent
        // className="form-control e-customCss"
        autoComplete="off"
        // {...props.formik.getFieldProps(...props.name)}
        value={value}
        // dataSource={...props.options}
        {...props}
        // enablePersistence={true}
        // showClearButton={true}
        // cssClass={'e-outline'} floatLabelType="Auto"
        change={(e) => {
          props.formik.setFieldValue(name, e.value);
          setValue(e.value);
          if (e.value) {
            props.formik.setFieldError(name, "");
          }
        }}
        // enableRtl={true}
        cssClass={"customCss e-outline"}
        floatLabelType="Auto"
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        {/* <small> */}
        {props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}
        {/* </small> */}
      </div>
    </div>
  );
};

export const Dropdown = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    let name = props.name;

    setValue(props.formik.values[name]);
  }, [props.formik.values, props.name]);

  return (
    <div className={`form-group ${(props.required && props.required) || ""}`}>
      <DropDownListComponent
        // {...props.formik.getFieldProps(...props.name)}
        value={value}
        {...props}
        change={(selectedOption) => {
          props.formik.setFieldValue(props.name, selectedOption.value);
        }}
        cssClass={"customCss e-outline"}
        floatLabelType="Auto"
        // popupHeight="250px"
        // isClearable
      />
      <div
        className="text-danger"
        style={{
          display: (props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? "block" : "none",
        }}
        role="alert"
      >
        <small>
          {(props.formik.touched["react-select-2-input"] || props.formik.touched[props.name]) === true ? props.formik.errors[props.name] || "" : ""}
        </small>
      </div>
    </div>
  );
};

export const ToggleSwitch = (props) => {
  const [checked, setChecked] = useState(false);
  const { name, label } = props.properties;
  useEffect(() => {
    setChecked(props.formik.values[name] === "YES" ? true : false);
  }, [name, props.formik.values]);

  return (
    <div className={`form-group ${(props.required && props.required) || ""} row`}>
      <label className="col-md-7 mt-3">{label} </label>
      <div className="col-md-5" style={{ top: "6px" }}>
        <label className="custom-switch3">
          <input
            type="checkbox"
            {...props.properties}
            checked={checked ? true : false}
            onChange={(e) => {
              setChecked(e.target.checked);
              props.formik.setFieldValue(name, e.target.checked ? "YES" : "NO", true);
            }}
          />
          <div>
            <span className="on">Yes</span>
            <span className="off">No</span>
          </div>
          <i></i>
        </label>
      </div>

      <div className="text-danger" style={{ display: props.formik.touched[name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[name] === true ? props.formik.errors[name] || "" : ""}</small>
      </div>
    </div>
  );
};

// Form Input field component for CKEditor
export const TextEditor = (props) => {
  const [values, setFormik] = useState(props.formik.values);
  const [content, setContent] = useState("");
  const { name, label } = props.properties;
  const onChange = (evt, editor) => {
    const newContent = editor.getData();
    setContent(newContent);
    let changedData = {};
    changedData[name] = newContent;
    setFormik({ ...values, ...changedData });
    props.formik.setFieldValue(name, newContent, true);
  };

  useEffect(() => {
    setContent(props.formik.values[name] ? props.formik.values[name] : props.defaultContent ? props.defaultContent : "");
  }, [props.formik.values, name]);

  return (
    <div className={`form-group ${(props.required && props.required) || ""}`}>
      <label>{label} </label>
      <CKEditor5 content={content} onChange={onChange} editorHeight={props?.properties?.config?.height || props?.height || "600px"} />
      <div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
      </div>
    </div>
  );
};

TextEditor.propTypes = {
  properties: PropTypes.object,
  formik: PropTypes.object,
  height: PropTypes.string,
};
