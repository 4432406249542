import "./index.css";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";

function getIconName(inputString) {
  let lowerString = inputString.toLowerCase();
  //return lowerString.replace(/ /g, "-");
  return lowerString.replaceAll("_", "-");
}

export const WidgetCard = (props) => {
  const { title, description, icon, selectedWidgetId, id, isDisable, handleCardClick } = props;
  // if disable function need uncomment this
  // const cardClassName = isDisable ? "widget-card-disable" : selectedWidgetId === id ? "widget-selected-border widget-card" : "widget-border widget-card";
  const cardClassName = selectedWidgetId === id ? "widget-selected-border widget-card" : "widget-border widget-card";
  return (
    <div className={cardClassName} onClick={() => handleCardClick(id)}>
      {/* if disable function need uncomment this */}
      {/* {!isDisable && selectedWidgetId === id && ( */}
      {selectedWidgetId === id && (
        <div className="selected-check-box">
          <FaCheckCircle />
        </div>
      )}
      <div className="widget-header">
        {/* <img className="widget-icon" src={`assets/${getIconName(title)}-widget-icon.svg`}></img> */}
        <img className="widget-icon" src={`assets/${getIconName(icon)}-widget-icon.svg`}></img>
        <div className=".widget-add-card-title">{title || "-"}</div>
      </div>
      <div className="widget-description">{description || "-"}</div>
    </div>
  );
};
