/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 06/Aug/2021
 * File Description: Collaboration Listing
 */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonDataGrid from "../../../../components/dataGrid";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { fetchCollaborations, deleteCollaboration, deleteManyCollaboration, fetchSingleCompany } from "../../../../../middleware/services/companyApi";
import { swap, getDateFromMongoDate } from "../../../../../utilities";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import { StringFilter } from "../../../../components/dataGrid/fliters";
import { internalGridVisited, setInternalGridState } from "../../../../../middleware/actions/gridAction";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const CollaborationListing = (props) => {
  const [deleteId, setDeleteId] = useState(undefined);
  const [companyData, setCompanyData] = useState(null);
  const { accessRights: aR } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const gridState = useSelector((state) => state.gridState);

  /* Common access */
  const accessActionDelete = props?.interfaceActionAccessCollaboration.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionEdit = props?.interfaceActionAccessCollaboration.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionAddNew = props?.interfaceActionAccessCollaboration.includes(COMMON_ACCESS_ACTION.ADD_NEW);

  const companyId = props?.computedMatch?.params?.companyId;
  const breadCrumbLinks = [
    { linkUrl: "/company", linkName: aR.moduleName, linkActive: false },
    {
      linkUrl: companyId ? `/company/${companyId}/edit#collaboration` : "/company/edit#collaboration",
      linkName: companyData?.companyName || "",
      linkActive: false,
    },
    { linkUrl: `/company/${companyId}/edit#collaboration`, linkName: "Collaboration", linkActive: true },
  ];

  const deleteRecord = (id) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          deleteCollaboration(id)
            .then((response) => {
              if (response) {
                dispatch(actionSuccess("Collaboration deleted successfully"));
                const params = {};
                params.limit = 100;
                let fields = [
                  "collaborationName",
                  "collaborationType",
                  "collaborationDealValue",
                  "collaborationDate",
                  "collaborationPartners.companyName",
                ];
                params.fields = fields;
                let { filters = [] } = params;
                if (!params.sort) params.sort = "_id:desc";
                params.filters = [...filters, ["collaborationPartners.companyId", "eq", companyId]];

                fetchCollaborations(params).then((form) => {
                  formatRowData(form?.data?.data || []);
                });
                setDeleteId(new Date());
              } else {
                dispatch(actionError("Something went wrong"));
              }
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "Failed to delete collaboration"));
            });
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to Delete ?",
        title: "dialogAlertCssWarning",
      })
    );
  };
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                dispatch(internalGridVisited());
                history.push(`/company/${companyId}/collaboration/${value.id}/edit`, { videoId: value.id, videoTitle: value["Video Title"] });
              },
            }
          : {})}
        {...(accessActionDelete
          ? {
              Delete: () => {
                deleteRecord(value.id);
              },
            }
          : {})}
      />
    );
  };

  const deleteManyFunc = (flag) => {
    const selectedCollaboration = localStorage.getItem("selectedCollaboration") ? JSON.parse(localStorage.getItem("selectedCollaboration")) : [];
    if (flag) {
      if (selectedCollaboration.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteManyCollaboration({ collaborationIds: selectedCollaboration })
                .then((response) => {
                  if (response) {
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    const params = {};
                    params.limit = 100;
                    let fields = [
                      "collaborationName",
                      "collaborationType",
                      "collaborationDealValue",
                      "collaborationDate",
                      "collaborationPartners.companyName",
                    ];
                    params.fields = fields;
                    let { filters = [] } = params;
                    if (!params.sort) params.sort = "_id:desc";
                    params.filters = [...filters, ["collaborationPartners.companyId", "eq", companyId]];
                    fetchCollaborations(params).then((collaboration) => {
                      formatRowData(collaboration.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete company"));
                });
            },
            okText: "Delete",
            cancelText: "Cancel",
            content: "Are you sure you want to Delete ?",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };
  const dateTemplate = (value) => {
    switch (value.column.field) {
      case "Collaboration Date":
        return !isNaN(Date.parse(value["Collaboration Date"])) ? <span>{getDateFromMongoDate(new Date(value["Collaboration Date"]))}</span> : "";
      default:
        return "";
    }
  };
  const collaborationNameTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(`/company/${companyId}/collaboration/${value.id}/edit`, { companyId: companyId, collaborationId: value.id });
          dispatch(internalGridVisited());
        }}
      >
        {value["Collaboration Name"]}
      </span>
    );
  };
  const currencyTemplate = (value) => {
    return <span>{typeof value["Deal Value"] === "number" ? `$${value["Deal Value"]}` : value["Deal Value"]}</span>;
  };
  const columnNames = {
    id: "id",
    "Collaboration Name": "collaborationName",
    "Collaboration Type": "collaborationType",
    "Deal Value": "collaborationDealValue",
    "Collaboration Date": "collaborationDate",
    "Collaboration Partners": "collaborationPartners.companyName",
  };

  const columnFields = [
    {
      field: "id",
      type: "string",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "Collaboration Name",
      type: "String",
      template: collaborationNameTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 500,
    },
    { field: "Collaboration Partners", type: "String", showInColumnChooser: true, textAlign: "Left", width: 500 },
    {
      field: "Collaboration Type",
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: true,
      width: 250,
    },
    {
      field: "Deal Value",
      headerText: "Deal Value (In Millions)",
      filter: { operator: "equal" },
      template: currencyTemplate,
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Center",
      showInColumnChooser: true,
      width: 210,
      headerTextAlign: "Left",
    },
    {
      field: "Collaboration Date",
      headerText: "Deal Date",
      type: "Date",
      template: dateTemplate,
      textAlign: "Center",
      width: 150,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
  ];
  if (accessActionEdit || accessActionDelete) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      textAlign: "Center",
      headerTextAlign: "Center",
      allowFiltering: false,
      showInColumnChooser: false,
    });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }
  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }

        return newRD;
      });

      newRD["Collaboration Date"] = rD.collaborationDate ? new Date(rD.collaborationDate) : "";

      newRD["Collaboration Partners"] = [
        rD.collaborationPartners
          .map((obj) => {
            return obj.companyName;
          })
          .join(", "),
      ];

      return newRD;
    });
    return formatedRowData;
  };

  const getCollaboration = (params = {}) => {
    let fields = ["collaborationName", "collaborationType", "collaborationDealValue", "collaborationDate", "collaborationPartners.companyName"];
    let { filters = [] } = params;
    params.filters = [...filters, ["collaborationPartners.companyId", "eq", companyId]];
    if (!params.sort) params.sort = "_id:desc";
    if (!gridState.internalGridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setInternalGridState(params.skip, params.sort, params.filters));
    }
    if (gridState.internalGridState.recordVisited) {
      params = {
        filters: gridState.internalGridState.filtersParams,
        sort: gridState.internalGridState.sortRecords,
        skip: gridState.internalGridState.skipRecords,
      };
      dispatch(setInternalGridState(params.skip, params.sort, params.filters));
    }
    params.fields = fields;
    return fetchCollaborations(params);
  };

  const fetchCompany = () => {
    let params = {};
    let fields = ["companyName"];
    params.fields = fields;
    fetchSingleCompany(companyId, params).then((res) => {
      let data = res.data.data || {};
      setCompanyData(data);
    });
  };

  useEffect(() => {
    if (companyId) {
      if (!companyData) fetchCompany();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/company"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, companyData]);

  return (
    <div className={` content-wrapper ${props.companyId ? "gennx-grid-container-custom mt-3 ml-2 mr-3" : "gennx-grid-container  px-3"}`}>
      <div className={`gennx-grid-container `}>
        <CommonDataGrid
          gridTitle="Collaborations"
          fetch={getCollaboration}
          columnNames={columnNames}
          columnFields={columnFields}
          formatRowData={formatRowData}
          deleteId={deleteId}
          getMultiSelectedRows={(data) => {
            localStorage.setItem("selectedCollaboration", JSON.stringify([...data]));
          }}
          getMultiDeSelectedRows={(data) => {
            localStorage.setItem("selectedCollaboration", JSON.stringify([...data]));
          }}
          deleteRight={true}
          deleteMany={accessActionDelete ? deleteManyFunc : null}
          addNewRight={accessActionAddNew}
          addNew={() => {
            history.push(`/company/${companyId}/collaboration/add`);
          }}
          isChildGrid={true}
        />
      </div>
      <div className="mx-n4">
        <div className="form-submit-box" style={{ marginLeft: "1em" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="float-left col-md-6 text-left">
                {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                  <span className="pl-4" style={{ marginLeft: "-3em" }}>
                    <span className="span-link">
                      <button
                        type="button"
                        className="btn btn-outline-dark pl-4 pr-4 cy-btn "
                        onClick={() => {
                          (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                            (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1));
                        }}
                      >
                        Previous
                      </button>
                    </span>
                  </span>
                ) : null}
                {props.selectedIndex > -1 ? (
                  <span className="pl-4">
                    <span className="span-link">
                      <button
                        type="button"
                        className="cy-col-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                        onClick={() => {
                          props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                        }}
                      >
                        Next
                      </button>
                    </span>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaborationListing;
