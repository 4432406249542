/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Access Denied Component to show Access Denied
----------------------------------------------------------------
	Creation Details
	Date Created				: 19/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import "./index.scss";
import React from "react";

const AccessDenied = () => {
    return <div className="d-flex access-denied-container justify-content-center">
        <h4 className="text-danger align-self-center border border-danger rounded p-3">Access Denied</h4>
    </div>;
};

export default AccessDenied;
