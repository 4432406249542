/*
================================================================
	Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Product Actions for different action status
----------------------------------------------------------------
	Creation Details
	Date Created				: 14/09/2022
	Author						: PRASHANT WANKHADE
================================================================
*/

export const setTaxonomyCheckedNodes = (data) => ({
	type: 'SET_PRODUCT_TAXONOMY_CHECKED_NODES',
	payload: data,
});

export const setTaxonomyUnCheckedNodes = (data) => ({
	type: 'SET_PRODUCT_TAXONOMY_UNCHECKED_NODES',
	payload: data,
});

export const setKiaCheckedNodes = (data) => ({
	type: 'SET_PRODUCT_KIA_CHECKED_NODES',
	payload: data,
});

export const setKiaUnCheckedNodes = (data) => ({
	type: 'SET_PRODUCT_KIA_UNCHECKED_NODES',
	payload: data,
});

export const setResetRedux = () => ({
	type: 'SET_RESET_REDUX',
});

/* Common save for product module starts */

export const setProductDetails = (data) => ({
	type: 'SET_PRODUCT_DETAILS',
	payload: data,
});

export const setProductImage = (data) => ({
	type: 'SET_PRODUCT_IMAGE',
	payload: data,
});
export const setValuePreposition = (data) => ({
	type: 'SET_VALUE_PREPOSITION',
	payload: data,
});

export const resetProductRedux = () => ({
	type: 'RESET_PRODUCT_REDUX',
});
/* Common save for product module ends */
