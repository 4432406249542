/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Modal Text Input
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React from "react";
import classes from "./ModalTextInput.module.css";

const ModalTextInput = (props) => {
  return (
    <div className={classes.input_text_div}>
      <div className={classes.input_group}>
        <label>{props.label}</label>
        <input
          className={classes.input_control}
          placeholder={props.placeholderText}
          id="name"
          name="folderName"
          type="text"
          maxLength="100"
          pattern="^\S+$"
          value={props.folderName}
          autoComplete="off"
          onChange={(e) => {
            props.setFolderName(e.target.value);
            props?.setFolderExistError && props.setFolderExistError(false);
          }}
        ></input>
      </div>
    </div>
  );
};

export default ModalTextInput;
