/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Taxonomy Filter Action
----------------------------------------------------------------
	Creation Details
	Date Created				: 19/Aug/2022
	Author						: Suraj Yadav
================================================================
*/

// Maintain selected taxonomy from by technology tab b 
export const setSelectedTechnology = (data) =>

({
	type: 'MAINTAIN_SELECTED_TECHNOLOGY',
	payload: data,
})
	;

// Maintain selected taxonomy from by Key Impact Area tab 
export const setSelectedKia = (data) => ({
	type: 'MAINTAIN_SELECTED_KIA',
	payload: data,
});

// Maintain selected taxonomy from by value chain tab 
export const setSelectedValueChain = (data) => ({
	type: 'MAINTAIN_SELECTED_VALUE_CHAIN',
	payload: data,
});
// Maientain checked node 
export const setSelectedMediaTaxonomy = (data) => ({
	type: 'MAINTAIN_SELECTED_MEDIA_TAXONOMY',
	payload: data,
});
// Maientain checked node 
export const setSelectedBOTaxonomy = (data) => ({
	type: 'MAINTAIN_SELECTED_BUSINESS_OBJECTIVE',
	payload: data,
});
// Maientain checked node 
export const setSelectedTrendTaxonomy = (data) => ({
	type: 'MAINTAIN_SELECTED_TREND',
	payload: data,
});// Maientain checked node 

// Maientain checked node 
export const setMaintainCheckedNodes = (data) => ({
	type: 'MAINTAIN_CHECKED_NODES',
	payload: data,
});

// Maientain checked parent node 
export const setParentNodeName = (data) => ({
	type: 'MAINTAIN_CHECKED_PARENT_NODES',
	payload: data,
});

// Maientain checked parent node 
export const setTaxonomyNodes = (data) => ({
	type: 'MAINTAIN_CHECKED_TAXONOMY_NODES',
	payload: data,
});

// Maientain filtered taxonomy 
export const setFilteredTaxonomy = (data) => ({
	type: 'MAINTAIN_FILTERED_TAXONOMY',
	payload: data,
});

// Reset redux
export const resetRedux = () => ({
	type: 'RESET_REDUX',
});
