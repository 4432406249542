/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Regex Validation
----------------------------------------------------------------
	Creation Details
	Date Created				: 30/Jul/2021
	Author						: Pankaj Kulshreshtha
================================================================
*/
//Company Name
export const COMPANY = /[^\s]([a-zA-Z])/
// URL/Domain validation
export const URL = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,11}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
// LinkedIn validation
export const LINKEDIN = /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
// Twitter validation
export const TWITTER = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
// Facebook validation
export const FACEBOOK = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/
// Phone validation
export const PHONE = /^[0-9]+$/
// Alphabates validation
export const ALPHABATES = /^[a-zA-z.]+([\s][a-zA-Z]+)*$/
// Alphabanumeric validation
export const ALPHA_NUMERIC = /^[a-zA-Z\s-]+$/i
// 4 digit Year validation
export const YEAR = /^[0-9]{4}$/
// 4 digit Year validation
export const VALID_URLS_FOR_SURVEY = /.+@(futurebridge|cognition-solutions|cheersin|whatnextglobal)\.com$/
// MongoId validation
export const VALID_MONGO_ID = new RegExp("^[0-9a-fA-F]{24}$")
