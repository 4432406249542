/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description            :  Company Advanced info Action Buttons
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 05/Aug/2020 
    Author						: Anand Kumar 
================================================================ 
*/

import React, { useEffect, useState } from "react";
import { saveCompanyAdvanced } from "../../../../../middleware/services/companyApi";
import { processingSubmit } from "../../../../../middleware/services/cmsApi";
import { Link, useHistory } from "react-router-dom";
import { getLoggedInUser, accessRightActionCheck } from "../../../../../utilities";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { setClickSaveSubmitState, setOverviewUpdateStatus } from "../../../../../middleware/actions/companyAction";
import { useDispatch, useSelector } from "react-redux";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const ActionButtons = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = getLoggedInUser();
  const companyId = props.companyId;
  const formik = props.formik;
  const cancelPath = props.back;
  const updateStatus = useSelector((state) => state.companyState.updateStatus);
  const clickStatus = useSelector((state) => state.companyState.clickStatus);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = Object.values(props.hashArr)[props.selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [props.selectedIndex]);

  /* Common access */
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionSubmit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SUBMIT);

  useEffect(() => {
    if (clickStatus) handleSave();
  }, [clickStatus]);
  useEffect(() => {
    if (updateStatus) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            processingSubmit(companyId, "advanced", {
              userId: loggedInUser._id,
            })
              .then((response) => {
                dispatch(actionSuccess(`Company successfully Saved & Submitted`));
                dispatch(setClickSaveSubmitState());
                dispatch(setOverviewUpdateStatus(false));
              })
              .catch((err) => {
                console.log(err.data.message);
                dispatch(actionError(err.data.message || "Something went wrong"));
              });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to submit ? ",
          title: "dialogAlertCss",
        })
      );
    }
  }, [updateStatus]);
  //handle save advanced info
  const handleSave = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        dispatch(actionError(res.companyAbout || res.companyWhatsUnique));

        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        const formData = new FormData();
        const data = Object.keys(formik.values).reduce((formData, key) => {
          formData.append(key, formik.values[key]);
          return formData;
        }, new FormData());
        saveCompanyAdvanced(companyId, data)
          .then((res) => {
            props.handleCommonSave(companyId);
            // dispatch(actionSuccess("Company advanced info saved successfully"));

            if (clickStatus) {
              dispatch(setOverviewUpdateStatus(true));
              dispatch(setClickSaveSubmitState());
            }
          })
          .catch((err) => {
            dispatch(actionError(err.data.message || "Something went wrong"));
          });
      }
    });
  };

  return (
    <div className="form-submit-box">
      <div className="container-fluid nopadding">
        <div className="row">
          <div className="float-LEFT col-md-6 text-left">
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="pl-4" style={{ marginLeft: "-3em" }}>
                <span className="span-link">
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn "
                    onClick={() => {
                      (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                        (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1));
                    }}
                  >
                    Previous
                  </button>
                </span>
              </span>
            ) : null}

            {props.selectedIndex > -1 ? (
              <span className="pl-4">
                <span className="span-link">
                  <button
                    type="button"
                    className="cy-adv-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                    onClick={() => {
                      props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                    }}
                  >
                    Next
                  </button>
                </span>
              </span>
            ) : null}
          </div>
          <div className="float-right col-md-6 text-right ">
            <Link to={cancelPath}>
              <button
                type="button"
                className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3"
                onClick={() => {
                  history.push(`${cancelPath}`);
                }}
              >
                Cancel
              </button>
            </Link>
            {accessActionSave ? (
              <button type="button" className="btn btn-primary cy-btn pl-4 pr-4 mr-3" onClick={handleSave}>
                Save
              </button>
            ) : null}
            {accessActionSubmit ? (
              <>
                {cancelPath === "/processing/company" ? (
                  <button
                    type="button"
                    className="btn btn-primary cy-btn pl-2 pr-2"
                    onClick={() => {
                      if (clickStatus) dispatch(setClickSaveSubmitState());
                      dispatch(setOverviewUpdateStatus(true));
                      dispatch(setClickSaveSubmitState(true));
                    }}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary cy-btn pl-2 pr-2"
                    onClick={() => {
                      if (clickStatus) dispatch(setClickSaveSubmitState());
                      dispatch(setOverviewUpdateStatus(true));
                      dispatch(setClickSaveSubmitState(true));
                    }}
                  >
                    {" "}
                    Submit
                  </button>
                )}{" "}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
