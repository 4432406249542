/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Helpdesk settings
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 18/12/2023
  Author						: Prashant Wankhade
================================================================ 
*/
import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import Tabs from "../../components/tabsComponent";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { TextField, Dropdown } from "../../components/CustomFormElements/syncFusionFormFields";
import { useFormik } from "formik";
import { Stack } from "@mui/material";
import { fetchConfig } from "middleware/services/cmsApi";
import { fetchCategories, fetchCategoriesFromJitBit, saveCategory, updateCategory } from "middleware/services/helpdeskApi";
import { getLoggedInUser, accessRightActionCheck } from "utilities";
import { actionError, actionSuccess, hideLoader, showLoader } from "middleware/actions/utilityAction";
import { useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import "./index.css";
import { COMMON_ACCESS_ACTION } from "../../../constants";

const Add = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = getLoggedInUser();
  const [flag, setFlag] = useState(false);
  const [ticketCategory, setTicketCategory] = useState([]);
  const [singleCategory, setSingleCategory] = useState([]);
  const [applicationPlatform, setApplicationPlatform] = useState([]);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);

  /**Only for insider application */
  const selectedApplication = applicationPlatform[0];

  const categoryId = props.computedMatch?.params?.categoryId || "";
  let TABS_NAME = ["Basic"];

  /** Data source for visiblity */
  let visibilityData = [
    { label: "On Platform After Login", value: "Platform" },
    { label: "On Login Page", value: "Login" },
    { label: "Everywhere", value: "Everywhere" },
  ];

  const [HelpdeskInformation, setHelpdeskInformation] = useState({
    applicationPlatform: "",
    ticketCategory: "",
    linkToIssueType: "",
    descriptionText: "",
    visibility: "",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: HelpdeskInformation,
    validationSchema: (values) => {
      let dynamicSchema = Yup.object().shape({
        linkToIssueType: Yup.string()
          .required("Please enter link to issue type")
          // .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&()_+=[\]{};':"|,.<>?\s-]*$/, "Issue type must contain only alphanumeric characters")
          .min(10, "Issue type must have at least 10 characters")
          .max(75, "Issue type can not be more than 75 characters"),
        descriptionText: Yup.string().max(250, "Description can not be more than 250 characters"),
        // .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&()_+=[\]{};':"|,.<>?\s-]*$/, "Description must contain only alphanumeric characters"),
      });

      if (!categoryId) {
        dynamicSchema = dynamicSchema.shape({
          applicationPlatform: Yup.string().required("Please select application platform"),
          ticketCategory: Yup.string().required("Please select ticket category"),
        });
      }
      return dynamicSchema;
    },
  });

  /** Sort the ticket category alphabetically */
  const sortedDataSource = useMemo(() => ticketCategory.slice().sort((a, b) => a.name.localeCompare(b.name)), [ticketCategory]);

  /** Match jitbit ticket category */
  const fieldValueToMatch = formik.values.ticketCategory;
  const matchObject = ticketCategory.find((obj) => obj.name === fieldValueToMatch);

  /** Match application Platfrom for dynamic ticket category */
  const fieldValueToMatchd = formik.values.applicationPlatform;
  const matchedApplicationPlatform = applicationPlatform.find((obj) => obj.sectionId == fieldValueToMatchd);

  /** Match Single category using sectionId*/
  const fieldValueProductPlatform = singleCategory?.categorySectionId;
  const matchProductPlatform = applicationPlatform.find((obj) => String(obj.sectionId) === fieldValueProductPlatform);

  const selectedPlatform = applicationPlatform.find((obj) => obj.sectionId == formik.values.applicationPlatform);

  /** Match Single category using categoryId*/
  const fieldValueTicketCategory = singleCategory?.categoryId;
  const matchTicketCategory = ticketCategory.find((obj) => String(obj.categoryId) === fieldValueTicketCategory);

  /** BreadCrumb */
  const breadCrumbLinks = [
    { linkUrl: "/helpdesk-settings", linkName: "Technical Help", linkActive: false },
    {
      linkUrl: `/helpdesk-settings/${categoryId}/edit#basic`,
      linkName: "Basic",
      linkActive: true,
    },
  ];

  /** Fetching the application platforms from Helpdesk_config databse (gnx_config) */
  const fetchConfigData = async () => {
    let params = {};
    params.filters = [["configName", "eq", "Helpdesk_config"]];
    try {
      await fetchConfig(params).then((res) => {
        if (res.status === 200) {
          let blockListArray = res.data.data[0].configValue;
          setApplicationPlatform(blockListArray);
        }
      });
    } catch (error) {
      dispatch(actionError(error?.data?.message));
    }
  };

  /** This function calls the jitbit for 3 times after late response from jitbit */
  async function callApiWithRetry(platform, params) {
    let attempts = 0;
    const maxAttempts = 3;

    while (attempts < maxAttempts) {
      try {
        const response = await fetchCategoriesFromJitBit(platform, params);
        if (response?.data) {
          return response?.data;
        }
      } catch (error) {
        console.error(`Error calling API: ${error}`);
      }
      attempts += 1;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    throw new Error(`Failed to call API after ${maxAttempts} attempts`);
  }

  /** Fetching jitbit categories based of the application platform */
  const fetchJitBitCategories = async () => {
    let params = {};
    let platform = applicationPlatform[0]?.sectionName;
    if (!platform) return;
    try {
      dispatch(showLoader());
      // const res = await fetchCategoriesFromJitBit(platform, params);
      const apiData = await callApiWithRetry(platform, params);
      if (!apiData?.data) {
        history.push(`/helpdesk-settings`);
        return dispatch(actionError("Unable to get ticket categories from Ticketing System. Please try again after sometime."));
      }
      setTicketCategory(apiData?.data);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(actionError("Unable to get ticket categories from Ticketing System. Please try again after sometime."));
    }
  };

  /** Fetching single category from database */
  const fetchSingleCategory = async () => {
    if (!categoryId) return;
    const params = {
      filters: [["_id", "eq", categoryId]],
    };
    dispatch(showLoader());
    let res = await fetchCategories(params);
    setSingleCategory(res.data.data[0]);
    setTimeout(() => {
      setFlag(true);
    }, 1000);
    dispatch(hideLoader());

    formik.setValues({
      // ticketCategory: res.data.data[0].ticketCategory,
      linkToIssueType: res.data.data[0].categoryName,
      descriptionText: res.data.data[0].categoryDescriptionPlaceholder,
      visibility: res.data.data[0].categoryVisibility,
    });
  };

  /** Handle save functionality on add and edit */
  async function handleSave() {
    try {
      formik.validateForm().then(async (res) => {
        if (Object.keys(res).length) {
          const touched = {};
          Object.keys(res).map((field) => {
            touched[field] = true;
            return touched;
          });
          const err =
            res &&
            Object.values(res).map((e) => {
              return e;
            });
          formik.setFormikState({ ...formik, touched: touched, errors: res });
        } else {
          const payload = {
            categoryId: matchObject?.categoryId,
            categoryName: formik.values.linkToIssueType.replace(/\s+/g, " ").trim(),
            categoryVisibility: formik.values.visibility,
            categoryDescriptionPlaceholder: formik.values.descriptionText,
            categoryCreatedBy: loggedInUser?._id,
            ...(!categoryId && { categoryProductPlatform: selectedApplication?.productPlatform }),
            categorySectionId: matchObject?.sectionId,
          };

          if (categoryId) {
            try {
              dispatch(showLoader());
              await updateCategory(payload, categoryId);
              dispatch(actionSuccess("Category updated successfully."));

              // history.push(`/helpdesk-settings`);
            } catch (error) {
              console.error("Error updating category:", error);
              dispatch(actionError(error?.data?.message));
            } finally {
              dispatch(hideLoader());
            }
          }

          if (!categoryId) {
            try {
              dispatch(showLoader());
              await saveCategory(payload);
              dispatch(actionSuccess("Category added successfully."));
              dispatch(hideLoader());
              // history.push(`/helpdesk-settings`);
            } catch (error) {
              console.error("Error updating category:", error);
              dispatch(actionError(error?.data?.message));
            } finally {
              dispatch(hideLoader());
            }
          }
        }
      });
    } catch (error) {
      dispatch(actionError("Something went wrong"));
      dispatch(hideLoader());
    }
  }

  useEffect(() => {
    fetchConfigData();
    // fetchJitBitCategories();
    fetchSingleCategory();
  }, []);

  /** Fetch the category */
  useEffect(() => {
    fetchSingleCategory();
  }, [flag]);

  /** UseEffect for add */
  useEffect(() => {
    fetchJitBitCategories();
  }, [formik.values.applicationPlatform, applicationPlatform, flag]);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
  }, [dispatch]);

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Basic";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
      <Tabs tabsName={TABS_NAME} cssClass="newHeaderTabCss">
        <div
          className="pt-3 mx-2 gennx-content-wrapper padding-bottom-150i footer-button-wrapper"
          style={{ padding: "1%", marginTop: "1%", "background-color": "#ffffff", width: "98.7%", "border-radius": "5px", height: "100vh" }}
        >
          <Stack spacing={2}>
            <Stack spacing={2} direction="row">
              {applicationPlatform?.length > 0 && (
                <div style={{ flex: 2 }}>
                  <Dropdown
                    formik={formik}
                    placeholder="Application Platform *"
                    id="applicationPlatform"
                    name="applicationPlatform"
                    value={matchProductPlatform?.sectionId}
                    fields={{ text: "productPlatform", value: "sectionId" }}
                    dataSource={[selectedApplication]}
                    required="required"
                    enabled={!categoryId}
                  />
                </div>
              )}

              <div style={{ flex: 5 }}>
                <Dropdown
                  formik={formik}
                  placeholder="Ticket Category *"
                  id="ticketCategory"
                  name="ticketCategory"
                  value={matchTicketCategory?.name}
                  fields={{ text: "name", value: "name" }}
                  dataSource={sortedDataSource}
                  enabled={!categoryId ? formik.values.applicationPlatform : true}
                />
              </div>

              <div style={{ flex: 5 }}>
                <TextField
                  formik={formik}
                  type="text"
                  id="linkToIssueType"
                  placeholder="Link to Issue Type *"
                  name="linkToIssueType"
                  required="required"
                  hideInitial={true}
                  numberCount={true}
                  maxLength={75}
                  characterLimitText={true}
                  value={formik.values.linkToIssueType}
                />
              </div>
            </Stack>

            <Stack spacing={2} direction="row">
              <div style={{ flex: 7.1 }}>
                <TextField
                  formik={formik}
                  type="text"
                  id="descriptionText"
                  placeholder="Placeholder Text For Description"
                  name="descriptionText"
                  required="required"
                  hideInitial={true}
                  numberCount={true}
                  maxLength={250}
                  characterLimitText={true}
                  value={formik.values.descriptionText}
                  rows={4}
                  multiline={true}
                  // style={{ fontSize: "46px !important" }}
                />
              </div>
              <div style={{ flex: 5 }}></div>
            </Stack>

            <Stack spacing={2} direction="row">
              <div style={{ flex: 2 }}>
                <Dropdown
                  formik={formik}
                  placeholder="Visibility"
                  id="visibility"
                  name="visibility"
                  value={formik.values.visibility.toString()}
                  fields={{ text: "label", value: "value" }}
                  dataSource={visibilityData}
                />
              </div>
              <div style={{ flex: 10 }}></div>
            </Stack>
          </Stack>
        </div>

        <div className="form-submit-box">
          <div className="container-fluid nopadding">
            <div className="float-right col-md-8 text-right =">
              <Link to="/helpdesk-settings">
                <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                  Cancel
                </button>
              </Link>

              {accessActionSave && (
                <button type="button" className="btn btn-primary cy-btn pl-4 pr-4 " id="dev-news-save" onClick={handleSave}>
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};

export default Add;
