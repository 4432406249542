/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  Content Block Trend Tagging Component 
	 Summary : - Import Trend tree view from common component
	  - Save selected trend data
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 01/July/2022 
	Author						  : Lawrence Anthony
================================================================ 
*/
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TreeView from "../../../../../components/treeView/trend";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import {
  saveContentBlockTrend,
  deleteContentBlockTrend,
  fetchContentBlockTrend,
  deleteManyContentBlockTrend,
} from "../../../../../../middleware/services/contentBlockApi";

const TaxomonyUI = (props) => {
  const dispatch = useDispatch();
  const contentBlockId = props.contentBlockId;
  const handleSave = (selectedTech, checkValues, callback, sgfData, callback2) => {
    saveTrend(selectedTech, checkValues, callback, sgfData, callback2);
  };
  useEffect(() => {
    if (props.saveContentBlock) {
      props.handleSave(false);
    }
  }, [props.saveContentBlock]);

  /*
	@param selectedTech : Selected trend to save their respective industry
	@param taxonomyIds : Selected taxonomy
	@param callback : use for re-render tree component

	@description : Save Content Block trend 
		1. Deleted all Content Block taxonomy for respecting Content Blocks trend
		2. Add Content Block taxonomy for respecting Content Blocks trend
	*/
  const saveTrend = (selectedTech, taxonomyIds, callback, sgfData, callback2) => {
    if (selectedTech) {
      dispatch(showLoader());
      deleteContentBlockTrend(contentBlockId, selectedTech)
        .then(() => {
          dispatch(hideLoader());
          if (taxonomyIds.length > 0) {
            dispatch(showLoader());
            try {
              const response = saveContentBlockTrend(contentBlockId, {
                taxonomyIds: taxonomyIds,
                sgfData: sgfData,
              });
              response
                .then((response) => {
                  dispatch(hideLoader());
                  dispatch(actionSuccess("Trend Tagged Successfully"));
                  callback();
                  // callback2(selectedTech)
                })
                .catch((e) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...!"));
                });
            } catch (e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            }
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    }
  };
  const deleteTrend = (checkedTrends, callback, callback2, selectedTech) => {
    deleteManyContentBlockTrend(contentBlockId, checkedTrends)
      .then((res) => {
        callback();

        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };
  return (
    <TreeView
      handleSave={handleSave}
      saveTreeData={props.saveContentBlock}
      contentId={contentBlockId}
      fetchContentTagging={fetchContentBlockTrend}
      loadOtherTagInfo={props.loadOtherTagInfo}
      setSaveTreeData={props.setSaveContentBlockFlag}
      deleteTrend={deleteTrend}
      isDelete={true}
      accessPermissionTaxonomyDelete={props?.accessPermissionTaxonomyDelete}
      accessPermissionTaxonomy={props?.accessPermissionTaxonomy}
      accessPermissionAddTags={props?.accessActionTaxonomyAddTags}
      accessActionTaxonomyShowInProduct={props?.accessActionTaxonomyShowInProduct}
    />
  );
};

export default TaxomonyUI;
