import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import GridTopToolBar from "../../../../components/gridTopToolBar";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import LicensingListing from "../modals/licensingListing";
import LicensingModal from "../modals/licensingListing/licensingModal";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

// product component
const Licensing = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [refreshState, setRefreshState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editCollaborationData, setEditCollaborationData] = useState(false);
  const productId = props.productId;

  /* Common access */
  const accessPermissionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessPermissionSubmit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SUBMIT);

  const addCallback = () => {
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(setSideNavForcedActiveLink("/product"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, []);

  return (
    <div className="gennx-content-wrapper mt-3" style={{ width: "99.5%" }}>
      <div className="mx-2">
        <div className="content-wrapper">
          <div className="col-md-12 border rounded-bottom nopadding p-0 bg-white">
            {accessPermissionAdd ? (
              <GridTopToolBar add={{ route: "#licensing", toolTip: "Add New Licensing/Co-Development", addCallback: addCallback }} />
            ) : null}
            <LicensingListing accessRights={aR} productId={productId} refreshState={refreshState} refreshStateFunc={() => setRefreshState(false)} />
            {showModal && (
              <LicensingModal
                clicked={() => {
                  setShowModal(!showModal);
                }}
                showModal={showModal}
                editCollaborationData={editCollaborationData}
                productId={productId}
                callbackFunc={() => setRefreshState(true)}
              />
            )}
            <div className="d-none">
              <button
                type="button"
                className="btn btn-secondary btn-sm pl-3 pr-3"
                onClick={() => {
                  setShowModal(!showModal);
                  setEditCollaborationData(false);
                }}
              >
                Add New Licensing/Co-Development
              </button>
            </div>
          </div>
        </div>
        {/* -- Footer Button box -*/}
        <div className="form-submit-box">
          <div className="container-fluid nopadding">
            <div className="row">
              <div className="float-LEFT col-md-6 text-left">
                {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                  <span className="pl-4" style={{ marginLeft: "-3.5em" }}>
                    <span className="span-link">
                      <button
                        type="button"
                        className="cy-licen btn btn-outline-dark pl-4 pr-4 cy-btn "
                        onClick={() => {
                          (props.selectedIndex === undefined && props.setCurrentTab(5)) ||
                            (props.selectedIndex < 6 && props.setCurrentTab(props.selectedIndex - 1));
                        }}
                      >
                        Previous
                      </button>
                    </span>
                  </span>
                ) : null}

                {props.selectedIndex > -1 ? (
                  <span className="pl-4">
                    <span className="span-link">
                      <button
                        type="button"
                        className="cy-licen-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                        onClick={() => {
                          props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                        }}
                      >
                        Next
                      </button>
                    </span>
                  </span>
                ) : null}
              </div>
              <div className="float-right col-md-6 text-right">
                <button
                  type="button"
                  className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(props.backPath, { companyId: props.companyId });
                  }}
                >
                  Cancel
                </button>
                {accessPermissionSubmit ? (
                  <button
                    type="button"
                    className="btn btn-primary cy-btn pl-2 pr-2 mr-1"
                    onClick={() => {
                      dispatch(
                        showAlertBox({
                          okCallback: async () => {
                            props.onSaveProcessingData("licensing");
                          },
                          okText: "Submit",
                          cancelText: "Cancel",
                          content: "Are you sure you want to submit ?",
                          title: "dialogAlertCss",
                        })
                      );
                    }}
                  >
                    Submit
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* End footer Button box */}
      </div>
    </div>
  );
};

export default Licensing;

Licensing.propTypes = {
  productId: PropTypes.string,
};
