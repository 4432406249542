/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : AuthLayout Higher Level Component for rendering Components with Authenticated Routes
----------------------------------------------------------------
	Creation Details
	Date Created				: 26/Jun/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import Utility from "../../components/utility";
import Header from "../../components/header";
import Breadcrumb from "../../components/breadcrumb";
import { getLoggedInUser, accessRightCheck, getProductVariant, isAdmin, getLoggedIn, accessRightActionCheck } from "../../../utilities";
import { userSignout } from "../../../middleware/actions/authAction";
import AccessDenied from "../../components/accessDenied";
import MenuNav from "../../components/menuNavigation";

const AuthLayout = (props) => {
  let { component: Component, pageHeading = "", path, accessRights: aR, ...rest } = props;
  document.title = pageHeading;
  pageHeading = pageHeading.replace("GenNx CMS - ", "");
  const dispatch = useDispatch();
  const history = useHistory();
  const userTokenDetails = getLoggedIn();
  const userDetails = getLoggedInUser();
  const sessonId = userDetails && userDetails._id;
  const profileAccessId = props?.computedMatch?.params?.userId;
  if (userTokenDetails) {
    let beginningTime = moment(new Date());
    let endTime = moment(new Date(userTokenDetails.exp * 1000));
    if (!beginningTime.isBefore(endTime)) dispatch(userSignout({}));
  } else dispatch(userSignout({}));
  if (!isAdmin()) {
    const comeBackPath = props?.location?.pathname ? props?.location?.pathname + props?.location?.hash : undefined;
    if (!getProductVariant) history.push("/selector", { path: comeBackPath });
  }
  const searchParams = new URLSearchParams(window.location.search);
  const reportStatusNotification = searchParams.get("reportStatusNotification");
  if (reportStatusNotification) {
    localStorage.setItem("redirectLocation", window.location.pathname);
  } else {
    localStorage.removeItem("redirectLocation");
  }

  return props.isNewWindow ? (
    <div></div>
  ) : (
    <div className="wrapper">
      {userDetails ? (
        <>
          {/* {isAdmin() || userDetails.product_variant?.length ? (
            <> */}
          {/* <SideNav activeLink={path} /> */}
          <MenuNav />
          <div id="content">
            <Header pageHeading={pageHeading} />

            {accessRightCheck(aR.moduleName, aR.interfaceName) || sessonId === profileAccessId ? (
              <>
                <Breadcrumb />
                <Component {...props} {...rest} interfaceActionAccess={accessRightActionCheck(aR.moduleName, aR.interfaceName)} />
              </>
            ) : (
              <AccessDenied />
            )}
          </div>
          <Utility />
          {/* </>
          ) : (
            <AccessDenied />
          )} */}
        </>
      ) : null}
    </div>
  );
};

export default AuthLayout;

AuthLayout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  pageHeading: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  accessRights: PropTypes.object.isRequired,
};
