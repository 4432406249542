import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TreeView from "../../../../../components/treeView/trend";
import { getLoggedInUser } from "../../../../../../utilities";
import { actionSuccess, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import {
  fetchProductTrend,
  saveProductTrend,
  deleteProductTrend,
  deleteManyProductTrend,
  updateContentTags,
  fetchSingleProduct,
  saveProductTrendSequence,
} from "../../../../../../middleware/services/productApi";
import { processingSubmitProduct } from "../../../../../../middleware/services/cmsApi";
import "../../../../../components/popup/index.scss";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const TaxomonyUI = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const productId = props.productId;
  const [clickSaveSubmit, setClickSaveSubmit] = useState(false);
  const [updateClick, setUpdateClick] = useState(false);
  const [saveTreeData, setSaveTreeData] = useState(false);
  const [saveTreeDataAction, setSaveTreeDataAction] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [backLink, setBackLink] = useState(
    props.editData
      ? props.companyId
        ? {
            path: `/company/${props.companyId}/edit#product`,
            data: { companyId: props.companyId },
          }
        : { path: `/product`, data: { companyId: undefined } }
      : undefined
  );

  /* Common access */
  const accessActionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionAddTags = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_TAGS);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionSubmit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SUBMIT);
  const accessActionTaxonomyShowInProduct = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SHOW_IN_PRODUCT);

  const handleSubmit = (selectedTech, checkValues, callback, sgfData = undefined, callback2) => {
    if (
      props.path === "/processing/company/:companyId/product/:productId/edit" ||
      props.path == "/company/:companyId/product/:productId/edit" ||
      props.path == "/product/:productId/edit" ||
      props.path == "/data-qc/company/:companyId/product/:productId/edit"
    ) {
      saveTrend(selectedTech, checkValues, callback, sgfData);
    }
  };

  const saveTrend = (selectedTech, taxonomyIds, callback, sgfData, callback2) => {
    if (selectedTech) {
      deleteProductTrend(productId, selectedTech)
        .then(() => {
          if (taxonomyIds.length > 0) {
            try {
              const response = saveProductTrend(productId, taxonomyIds, sgfData);
              response
                .then(() => {
                  if (submitStatus) {
                    setTimeout(() => {
                      saveTaxonomyProcessing(selectedTech, taxonomyIds, callback);
                    }, [2000]);
                  }
                  dispatch(actionSuccess("Trend Tagged Successfully"));
                  callback();
                })
                .catch((e) => {
                  dispatch(actionError("Something Went Wrong...!"));
                  setSubmitStatus(false);
                });
            } catch (e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
              setSubmitStatus(false);
            }
          } else {
            dispatch(actionError("Please select taxonomy"));
            setSaveTreeData(false);
            setSaveTreeDataAction(false);
            setClickSaveSubmit(false);
            setSubmitStatus(false);
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
          setSubmitStatus(false);
        });
    }
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
    setSubmitStatus(false);
  };

  const saveTaxonomyProcessing = (selectedTech, taxonomyIds, callback) => {
    const loggedInUser = getLoggedInUser();
    const payload = {
      userId: loggedInUser._id,
      trendId: selectedTech,
    };

    dispatch(
      showAlertBox({
        okCallback: async () => {
          processingSubmitProduct(productId, "trend", payload)
            .then((response) => {
              if (response.status === 200) {
                setClickSaveSubmit(false);
                setUpdateClick(false);
                dispatch(actionSuccess(`Product trend successfully submitted`));

                setSaveTreeData(false);
                return { status: "success" };
              }
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Something went wrong"));
              setSaveTreeData(false);
            });
        },
        okText: "Submit",
        cancelText: "Cancel",
        content: "Are you sure you want to submit ?",
        title: "dialogAlertCss",
      })
    );
  };

  const deleteTrend = (checkedTrends, callback, callback2, selectedTech) => {
    // let selectedTechnology = selectedTech
    deleteManyProductTrend(productId, checkedTrends)
      .then((res) => {
        callback();

        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something went wrong"));
      });
  };

  const handleSaveTagSequence = (sortedTrends, callback) => {
    saveProductTrendSequence(productId, sortedTrends)
      .then((res) => {
        callback();
        dispatch(actionSuccess("Taxonomy sequence added Successfully."));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  return (
    <>
      <TreeView
        handleSave={saveTrend}
        saveTreeData={saveTreeData}
        contentId={productId}
        fetchContentTagging={fetchProductTrend}
        loadOtherTagInfo={props.loadOtherTagInfo}
        isDelete={true}
        setSaveTreeData={setSaveTreeData}
        deleteTrend={deleteTrend}
        accessPermissionTaxonomyDelete={accessActionDelete}
        accessPermissionTaxonomy={accessActionAdd}
        accessPermissionAddTags={accessActionAddTags}
        accessActionTaxonomyShowInProduct={accessActionTaxonomyShowInProduct}
        handleSubmit={handleSubmit}
        submitStatus={submitStatus}
        fetchTags={fetchSingleProduct}
        saveTags={updateContentTags}
        contentModule={"Product"}
        handleSaveTagSequence={handleSaveTagSequence}
      />

      <div className="ml-n4">
        <div className="form-submit-box pr-0">
          <div className="container-fluid" style={{ paddingRight: "6px" }}>
            <div className="d-flex justify-content-between">
              <div>
                {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                  <span className="" style={{ paddingLeft: "-0.5em" }}>
                    <span className="span-link">
                      <button
                        type="button"
                        className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                        onClick={() => {
                          (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                            (props.selectedIndex < 5 && props.setCurrentTab(props.selectedIndex - 1));
                        }}
                      >
                        Previous
                      </button>
                    </span>
                  </span>
                ) : null}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-dark cy-btn pl-3 pr-3 mr-3"
                  onClick={(e) => {
                    e.preventDefault();
                    backLink === undefined ? history.goBack() : history.push(backLink.path, backLink.data);
                  }}
                >
                  Cancel
                </button>
                {accessActionSubmit ? (
                  <>
                    {(props.path === "/processing/company/:companyId/product/:productId/edit" ||
                      props.path == "/company/:companyId/product/:productId/edit" ||
                      props.path == "/product/:productId/edit" ||
                      props.path == "/data-qc/company/:companyId/product/:productId/edit") &&
                    productId ? (
                      <button
                        type="button"
                        className="btn btn-primary cy-btn pl-2 pr-2"
                        onClick={() => {
                          setSubmitStatus(true);
                        }}
                      >
                        Submit
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxomonyUI;
