import React from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";

import SubscriptionListing from "../app/views/subscriptionManagement";
const PROPERTIES = ["Properties:VIEW", "Properties:FULL"];
const SubscriptionRoutes = () => (
  <Switch>
    <AuthLayout
      exact
      path="/subscription"
      component={SubscriptionListing}
      pageHeading="GenNx CMS - Subscription"
      accessRights={{
        moduleName: "Subscription",
        parentModuleName: "",
        moduleAttribute: [...PROPERTIES],
        interfaceName: "Listing",
      }}
    />
  </Switch>
);

export default SubscriptionRoutes;
