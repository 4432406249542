/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : KIA Add/Edit Page 
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/June/2021
    Author						: Sandeep K. Sharma
================================================================
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { getProductVariant, getDateFromMongoDate } from "../../../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { fetchSingleKia, updateKia } from "../../../../../middleware/services/kiaApi";
import { TextField, Dropdown, DatePicker } from "../formFields";
import { KIA_FREQUENCY } from "../formFieldOptions";
import * as Yup from "yup";
import { customBriefValidationSchema } from "../validationSchema/index";
import AccessDenied from "../../../../components/accessDenied";

const initialState = {
  topic: "",
  description: "",
  questions: "",
  profileOfExperts: "",
  frequencyOfUpdates: "One Time",
  goLiveDate: "",
};
const CustomKia = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const kiaId = props.kiaId;
  const productVariant = getProductVariant();
  const [editData, setEditData] = useState(null);

  /*
       @Description : Formik Initialization
   */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(customBriefValidationSchema),
  });

  /*
       @Description : BreadCumb Defination
   */
  const breadCrumbLinks = [
    { linkUrl: "/kia", linkName: aR.moduleName, linkActive: false },
    { linkUrl: kiaId ? `/kia/${kiaId}/edit` : "//kia/add", linkName: (editData && editData?.kiaName) || "", linkActive: false },
    {
      linkUrl: `/kia/ ${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  /*
       @Description : Fetch Single KIA Details
   */
  const fetchKiaDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = ["kiaName", "kiaDescription", "kiaClientRequest"];
    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    fetchSingleKia(kiaId, params).then((res) => {
      if (res.data.data) {
        setEditData(res.data.data);
        let clientRequest = res.data.data.kiaClientRequest;
        if (clientRequest) {
          formik.setValues({
            topic: clientRequest.topic,
            description: clientRequest.description,
            questions: clientRequest.questions,
            profileOfExperts: clientRequest.profileOfExperts,
            frequencyOfUpdates: clientRequest.frequencyOfUpdates,
            goLiveDate: getDateFromMongoDate(new Date(clientRequest.goLiveDate)),
          });
        }
      }
    });
  };

  /*
      @Description : Load KIA Overview Details on Edit and 
  */

  useEffect(() => {
    if (kiaId) {
      dispatch(showLoader());
      !editData && fetchKiaDetails();
      dispatch(hideLoader());
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/kia"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, props.kiaId, props.selectedIndex, editData, props.location.hash]);

  /*
      @Description : Save KIA Overview Details
  */
  useEffect(() => {
    if (props.saveCustomBrief) {
      formik.validateForm().then((res) => {
        if (Object.keys(res).length) {
          const touched = {};
          Object.keys(res).map((field) => {
            touched[field] = true;
            return touched;
          });

          formik.setFormikState({ ...formik, touched: touched, errors: res });
        } else {
          let payload = {};
          const { topic, description, questions, profileOfExperts, frequencyOfUpdates, goLiveDate } = formik.values;
          payload.kiaName = editData && editData.kiaName;
          payload.kiaDescription = editData && editData.kiaDescription;
          payload.kiaClientRequest = {
            topic: topic,
            description: description,
            questions: questions,
            profileOfExperts: profileOfExperts,
            frequencyOfUpdates: frequencyOfUpdates,
            goLiveDate: goLiveDate,
          };
          if (kiaId) {
            updateKia(kiaId, payload)
              .then((res) => {
                dispatch(actionSuccess("Brief details updated successfully"));
              })
              .catch((err) => {
                dispatch(actionError(err?.data?.message || "Something went wrong"));
              });
          }
        }
      });
      props.handleSaveBrief(false);
    }
  }, [props.saveCustomBrief]);

  return (
    <>
      {props?.interfaceAccess ? (
        <div className="e-content pt-4 customkia mx-2 pt-3" style={{ padding: "1%", marginTop: "1%", backgroundColor: "#ffffff", width: "98.7%" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    formik={formik}
                    type="text"
                    id="topic"
                    name="topic"
                    placeholder="Impact Area Topic *"
                    required="required"
                    maxLength="150"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      formik={formik}
                      multiline={true}
                      numberCount={true}
                      maxLength={1000}
                      rows={10}
                      type="text"
                      id="description"
                      placeholder="Impact Area Description *"
                      name="description"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      formik={formik}
                      multiline={true}
                      numberCount={true}
                      maxLength={1000}
                      type="text"
                      id="questions"
                      placeholder="Important Questions"
                      name="questions"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    formik={formik}
                    multiline={true}
                    numberCount={true}
                    maxLength={1000}
                    type="text"
                    id="profileOfExperts"
                    placeholder="Profile of Experts"
                    name="profileOfExperts"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <Dropdown
                    formik={formik}
                    required="required"
                    placeholder="Frequency Of Updates *"
                    name="frequencyOfUpdates"
                    id="frequencyOfUpdates"
                    fields={{ text: "label", value: "value" }}
                    dataSource={KIA_FREQUENCY}
                  />
                </div>
                <div className="col-md-3">
                  <DatePicker
                    formik={formik}
                    properties={{
                      placeholder: "By When Would you like this to Go Live",
                      id: "goLiveDate",
                      name: "goLiveDate",
                      format: "dd MMM yyyy",
                      allowEdit: false,
                      openOnFocus: true,
                      min: getDateFromMongoDate(new Date()),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default CustomKia;
