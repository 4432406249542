/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : KIA Listing Page for Industry Use Cases
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/June/2021
    Author						: Sandeep K. Sharma
================================================================
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CommonDataGrid from "../../../../components/dataGrid";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import {
  fetchSingleKia,
  getIndustryApplications,
  deleteSingleIndustryApplication,
  deleteSelectedIndustryApplication,
} from "../../../../../middleware/services/kiaApi";
import { swap, decodeHTML, getDateFromMongoDate } from "../../../../../utilities";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { StringFilter } from "../../../../components/dataGrid/fliters";
import ThreeDotMenu from "../../../../components/threeDotMenu";
import AddIndustryAppModel from "./add";
import "../../../kia/index.scss";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";
import AccessDenied from "../../../../components/accessDenied";

const IndustryApplicationsListing = (props) => {
  const { accessRights: aR } = props;
  const [deleteId, setDeleteId] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [appId, setAppId] = useState("");
  const dispatch = useDispatch();
  const kiaId = props.kiaId;

  /* Common access */
  const accessActionAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  /*
       @Description : BreadCumb Defination
   */
  const breadCrumbLinks = [
    { linkUrl: "/kia", linkName: aR.moduleName, linkActive: false },
    { linkUrl: kiaId ? `/kia/${kiaId}/edit` : "/kia/add", linkName: (editData && editData?.kiaName) || "Add New", linkActive: false },
    {
      linkUrl: `/kia/ ${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];
  /*
       @Description : Used for Closing Add/Update Model
   */
  const closeModal = () => {
    setAppId("");
    setShowModal(false);
  };

  /*
       @Description : Fetch Single KIA Details
   */
  const fetchKiaDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "kiaName",
      "kiaDescription",
      "kiaCurrentResearchStartups",
      "customKia",
      "kiaStatus",
      "kiaNextUpdateDate",
      "kiaClientUsers",
      "kiaIndustryTag",
      "kiaScore",
      "kiaExpert",
      "kiaExpertNetworkStrength",
      "kiaImpactAreaSurveyContributors",
      "kiaClientExpertEngagements",
    ];
    params.fields = fields;
    if (props.productVariant) defaultFilter.push(["productVariant.productName", "eq", props.productVariant]);
    params.filters = [...defaultFilter];

    fetchSingleKia(kiaId, params).then((res) => {
      let data = res.data.data || {};
      if (data) {
        setTimeout(() => {
          setEditData(data);
        }, 200);
      }
    });
  };
  /*
       @Description : Load Navigation and Breadcrub on page load
    */
  useEffect(() => {
    if (kiaId) {
      !editData && fetchKiaDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/kia"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, kiaId, props.selectedIndex, editData, props.location.hash]);

  /*
       @Description : Template for Three Dot Menu
    */
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        Edit={
          accessActionEdit
            ? () => {
                showModal && setShowModal(false);
                setShowModal(true);
                setAppId(value.id);
              }
            : undefined
        }
        Delete={
          accessActionDelete
            ? () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      deleteSingleIndustryApplication(value.id)
                        .then((response) => {
                          if (deleteId) setDeleteId(undefined);
                          setDeleteId(new Date());
                          dispatch(actionSuccess("Industry Application deleted successfully"));
                          const params = {};
                          params.limit = 100;
                          getIndustryApplications(kiaId, params).then((v) => {
                            formatRowData(v.data.data);
                          });
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Failed to delete Industry Application"));
                        });
                    },
                    okText: "Delete",
                    cancelText: "Cancel",
                    content: "Are you sure you want to delete?",
                    title: "dialogAlertCssWarning",
                  })
                );
              }
            : undefined
        }
      />
    );
  };

  /*
      @Description : Delete Multiple Industry Applications
   */
  const deleteManyFunc = (flag) => {
    const selectedIndApp = localStorage.getItem("selectedIndApp") ? JSON.parse(localStorage.getItem("selectedIndApp")) : [];
    if (flag) {
      if (selectedIndApp.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteSelectedIndustryApplication({ appIds: selectedIndApp })
                .then((response) => {
                  if (response) {
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    const params = {};
                    params.limit = 100;
                    getIndustryApplications(params).then((v) => {
                      formatRowData(v.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete industry Application"));
                });
            },
            okText: "Delete",
            cancelText: "Cancel",
            content: "Are you sure you want to delete?",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };

  /* 
        @Description : Date Template 
    */
  const dateTemplate = (value) => {
    const column = value.column.field;
    return ["Published Date", "Created Date"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
      <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
    ) : (
      ""
    );
  };
  /*
       @Description : Template for Description 
    */
  const DescriptionTemplate = (value) => {
    var colDescValue = value["Description"];
    if (value.column.field === "Current State of Research") colDescValue = value["Current State of Research"];
    else if (value.column.field === "What Impact will it have?") colDescValue = value["What Impact will it have?"];
    let desc = decodeHTML(colDescValue);
    if (desc) {
      return <span>{desc.substr(0, 30) + "\u2026"}</span>;
    }
    return null;
  };
  /*
      @Description : Columns Defination 
   */
  const columnNames = {
    id: "id",
    Industry: "industryName",
    Description: "applicationDescription",
    "Current State of Research": "applicationCurrentStateOfResearch",
    "What Impact will it have?": "applicationImpact",
    "Time Scale": "applicationTimescale",
    "Created Date": "applicationCreatedDate",
  };
  const columnFields = [
    {
      field: "id",
      type: "String",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    { field: "Industry", type: "String", textAlign: "Left", headerTextAlign: "Left", showInColumnChooser: false },
    {
      field: "Description",
      template: DescriptionTemplate,
      type: "String",
      filter: { operator: "equal" },
      textAlign: "Left",
      showInColumnChooser: true,
    },
    { field: "Current State of Research", template: DescriptionTemplate, type: "String", textAlign: "Left", showInColumnChooser: true, width: 300 },
    { field: "What Impact will it have?", template: DescriptionTemplate, type: "String", textAlign: "Left", showInColumnChooser: true, width: 220 },
    { field: "Time Scale", type: "String", textAlign: "Center", width: 150, showInColumnChooser: true },
    {
      field: "Created Date",
      template: dateTemplate,
      type: "Date",
      textAlign: "Center",
      width: 170,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
  ];
  if (accessActionEdit || accessActionDelete) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      filterTemplate: StringFilter,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }
  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
        return newRD;
      });
      newRD["Created Date"] = new Date(rD.applicationCreatedDate);
      newRD["Published Date"] = new Date(rD.videoPublishedDate);
      return newRD;
    });
    return formatedRowData;
  };

  const fetchIndustryApplications = () => {
    return getIndustryApplications(props.kiaId);
  };
  return (
    <>
      {props?.interfaceAccess ? (
        <>
          {showModal && <AddIndustryAppModel showModal={showModal} kiaId={kiaId} closeModal={closeModal} appId={appId} setDeleteId={setDeleteId} />}
          <div className="gennx-content-wrapper content-wrapper pt-3 mx-2 pr-2">
            <div className="gennx-grid-container-custom">
              <CommonDataGrid
                gridTitle="Industry Applications"
                fetch={fetchIndustryApplications}
                columnNames={columnNames}
                columnFields={columnFields}
                formatRowData={formatRowData}
                deleteId={deleteId}
                getMultiSelectedRows={(data) => {
                  localStorage.setItem("selectedIndApp", JSON.stringify([...data]));
                }}
                getMultiDeSelectedRows={(data) => {
                  localStorage.setItem("selectedIndApp", JSON.stringify([...data]));
                }}
                addNew={() => setShowModal(true)}
                addNewRight={accessActionAddNew}
                deleteRight={accessActionDelete}
                deleteMany={accessActionDelete ? deleteManyFunc : null}
                isChildGrid={true}
              />
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default IndustryApplicationsListing;
