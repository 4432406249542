import React from "react"
import { showPopup } from "../../../middleware/actions/popupAction";
import { useDispatch } from "react-redux";

const ReportHelp = (props) => {
    const dispatch = useDispatch();
    const launchPopup = () => {
        const tableDivVendor = `<tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Vendor Name</div>
                    <div class="col-md-6">Name of the vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">User Name</div>
                    <div class="col-md-6">User name of the vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-12 pl-1 font-weight-bold">Company Profiling</div>				
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Allocated</div>
                    <div class="col-md-6">Total no. of companies allocated to the respective user within the selected date range</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Delivered (Total)</div>
                    <div class="col-md-6">Total no. of companies user has delivered within the selected date range</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Delivered (Updated)</div>
                    <div class="col-md-6">Total no. of companies user has updated within the selected date range</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Accepted (Total)</div>
                    <div class="col-md-6">Total no. of companies accepted by QC</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Accepted (With Rework)</div>
                    <div class="col-md-6">Total no. of companies accepted by QC post vendor rework</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Accepted (Without Rework)</div>
                    <div class="col-md-6">Total no. of companies accepted by QC without vendor rework</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Sent Back</div>
                    <div class="col-md-6">Total no. of companies QC sent back to vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Rejected</div>
                    <div class="col-md-6">Total no. of companies QC rejected</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Added</div>
                    <div class="col-md-6">Total no. of new companies added by vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Deleted</div>
                    <div class="col-md-6">Total no. of companies deleted by vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Delivery Pending - Total</div>
                    <div class="col-md-6">Total no. of companies pending to be delivered by vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Delivery Pending - Fresh</div>
                    <div class="col-md-6">Total no. of fresh companies pending to be delivered by vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Delivery Pending - Rework</div>
                    <div class="col-md-6">Total no. of companies pending for rework by vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Effectiveness %</div>
                    <div class="col-md-6">Companies Accepted (Without Rework)/Companies Accepted (Total)</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-12 pl-1 font-weight-bold">Product Profiling</div>				
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Allocated</div>
                    <div class="col-md-6">Total no. of products allocated to the respective user within the selected date range</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Delivered (Total)</div>
                    <div class="col-md-6">Total no. of products user has delivered within the selected date range</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Delivered (Updated)</div>
                    <div class="col-md-6">Total no. of products user has updated within the selected date range</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Accepted (Total)</div>
                    <div class="col-md-6">Total no. of products accepted by QC</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Accepted (With Rework)</div>
                    <div class="col-md-6">Total no. of products accepted by QC post vendor rework</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Accepted (Without Rework)</div>
                    <div class="col-md-6">Total no. of products accepted by QC without vendor rework</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Sent Back</div>
                    <div class="col-md-6">Total no. of products QC sent back to vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Rejected</div>
                    <div class="col-md-6">Total no. of products QC rejected</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Added</div>
                    <div class="col-md-6">Total no. of products added by vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Deleted</div>
                    <div class="col-md-6">Total no. of products deleted by vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Delivery Pending - Total</div>
                    <div class="col-md-6">Total no. of products pending to be delivered by vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Delivery Pending - Fresh</div>
                    <div class="col-md-6">Total no. of products pending to be delivered by vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Delivery Pending - Rework</div>
                    <div class="col-md-6">Total no. of products pending for rework by vendor</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Effectiveness %</div>
                    <div class="col-md-6">Products Accepted (Without Rework)/Products Accepted (Total)</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-12 pl-1 font-weight-bold">Company QC</div>				
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">QC-ed - Total</div>
                    <div class="col-md-6">Total no. of companies QCed within the selected daterange</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">QC-ed - Accepted</div>
                    <div class="col-md-6">Total no. of companies QC has accepted within the selected daterange</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">QC-ed - Sent Back</div>
                    <div class="col-md-6">Total no. of companies QC has sent back within the selected daterange</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">QC-ed - Rejected</div>
                    <div class="col-md-6">Total no. of companies QC has rejected within the selected daterange</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Pending</div>
                    <div class="col-md-6">Total no. of companies pending for QC</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Published</div>
                    <div class="col-md-6">Total no. of companies pending for publishing</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Not Published</div>
                    <div class="col-md-6">Total no. of companies not yet published on production</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-12 pl-1 font-weight-bold">Product QC</div>				
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">QC-ed - Total</div>
                    <div class="col-md-6">Total no. of products QCed within the selected daterange</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">QC-ed - Accepted</div>
                    <div class="col-md-6">Total no. of products QC has accepted within the selected daterange</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">QC-ed - Sent Back</div>
                    <div class="col-md-6">Total no. of products QC has sent back within the selected daterange</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">QC-ed - Rejected</div>
                    <div class="col-md-6">Total no. of products QC has rejected within the selected daterange</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Pending</div>
                    <div class="col-md-6">Total no. of products pending for QC</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Published</div>
                    <div class="col-md-6">Total no. of products pending for publishing</div>
            </div>
        </td>                
    </tr>
    <tr>
        <td scope="col">
            <div class="row">
                    <div class="col-md-6 pl-1">Not Published</div>
                    <div class="col-md-6">Total no. of products not yet published on production</div>
            </div>
        </td>                
    </tr>`

        const tableDivInternal = `<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">User Name</div>
				<div class="col-md-6">Name of the team member</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-12 pl-1 font-weight-bold">Company Screening</div>				
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Total</div>
				<div class="col-md-6">Total no. of companies screended within the selected date range</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Selected</div>
				<div class="col-md-6">Total no. of companies selected for processing from screening</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Hold</div>
				<div class="col-md-6">Total no. of companies kept on hold on screening</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Rejected</div>
				<div class="col-md-6">Total no. of companies rejected in screening</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-12 pl-1 font-weight-bold">Company Profiling</div>				
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Companies - Added</div>
				<div class="col-md-6">Total no. of companies added within the selected date range</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Companies - Deleted</div>
				<div class="col-md-6">Total no. of companies deleted from processing</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Companies - Processed</div>
				<div class="col-md-6">Total no. of companies processed</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Companies - Reworked</div>
				<div class="col-md-6">Total no. of companies reworked</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Products - Processed</div>
				<div class="col-md-6">Total no. of products processed</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Products - Reworked</div>
				<div class="col-md-6">Total no. of products reworked</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-12 pl-1 font-weight-bold">Company QC</div>				
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Total</div>
				<div class="col-md-6">Total no. of companies QCed within the selected date range</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Accepted</div>
				<div class="col-md-6">Total no. of companies accepted after QC</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Sent Back</div>
				<div class="col-md-6">Total no. of companies sent back from QC</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Rejected</div>
				<div class="col-md-6">Total no. of companies rejected in QC</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Pending</div>
				<div class="col-md-6">Total no. of companies allocated - Total no. of companies Qced</div>
		</div>
	</td>                
</tr>
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Published</div>
				<div class="col-md-6">Total no. of companies published out of the total no. of companies accepted</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Not Published</div>
				<div class="col-md-6">Total no. of companies accepted - Total no. of companies published</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Companies Published</div>
				<div class="col-md-6">Total no. of companies published within the selected date range</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Products Published</div>
				<div class="col-md-6">Total no. of products published within selected date range</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-12 pl-1 font-weight-bold">News</div>				
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Fresh</div>
				<div class="col-md-6">Total no. of news created/added</div>
		</div>
	</td>                
</tr>
<tr>
	<td scope="col">
		<div class="row">
				<div class="col-md-6 pl-1">Published</div>
				<div class="col-md-6">Total no. of news published on production</div>
		</div>
	</td>                
</tr>`
        dispatch(showPopup({
            width: `40%`,
            title: `<h2 class="text-secondary" style="font-weight: 700">${props.name} Activity Report </h2>`,
            okText: "OK",
            showCancelButton: false,
            content: `<div class="table-responsive">
                <table class="table header-fixed">
                    <thead>
                        <tr>
                            <th scope="col"><div class="row"><div class="col-md-6 ">
                            Field Name
                            </div>
                            <div class="col-md-6">Description</div></div></th>
                        </tr>
                    </thead></table></div>
                    <table class="table mt-4" ><tbody>${props.name === "VEndor" ? tableDivVendor : tableDivInternal}</tbody>`
        }))
    }
    return <>
        <button onClick={() => launchPopup()} className="btn btn-dark px-4 mt-4 ml-2">Help</button>
    </>

}

export default ReportHelp;