/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Internal User Access Rights
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { actionStart, actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import Accordion from "../../../components/accordion";
import { fetchSingleUser } from "../../../../middleware/services/userApi";
import { fetchCoreModules } from "../../../../middleware/services/cmsApi";
import { fetchAuthorization, saveAuthorization } from "../../../../middleware/services/authorizationApi";

const AccessRights = (props) => {
  const { accessRights: aR } = props;
  const [isActiveUser, setIsActiveUser] = useState(false);
  const accessPermission = aR.moduleName ? true : false;
  const history = useHistory();
  const editUser = props.path === "/internal-user/add" ? false : true;
  const userId = editUser ? props.userId : undefined;
  const dispatch = useDispatch();
  const [coreModules, setCoreModules] = useState([]);
  const [accessRights, setAccessRights] = useState([]);
  const [userRights, setUserRights] = useState([]);

  /**
   * @description fetch core modules access rights
   */
  const fetchCoreModulesAccessRights = () => {
    fetchCoreModules({ limit: 1000 })
      .then((res) => {
        let { data = [] } = res.data;
        data.sort((dA, dB) => {
          const dAModuleName = String(dA.moduleName).toUpperCase();
          const dBModuleName = String(dB.moduleName).toUpperCase();
          if (dAModuleName < dBModuleName) return -1;
          else if (dAModuleName > dBModuleName) return 1;
          else return 0;
        });
        setCoreModules(data);
        let accessRights = data.filter(
          (d) =>
            !d.parentModuleId ||
            d.parentModuleId === "" ||
            d.parentModuleId === "0" ||
            d.parentModuleId === undefined ||
            typeof d.parentModuleId === undefined ||
            !d.hasOwnProperty("parentModuleId")
        );
        accessRights.forEach((aR) => {
          let childModules = data.filter((d) => String(d.parentModuleId) === String(aR.id));
          if (childModules.length) aR.childModules = childModules;
        });
        let updatedAccessRights = accessRights.filter((value) => {
          return value.moduleName !== "Newsletter & Templates" && value.moduleName !== "Outsourcing" && value.moduleName !== "Project";
        });
        setAccessRights(updatedAccessRights);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Access Rights Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch user details
   * @param {String} userId
   */
  const fetchUserDetail = (userId) => {
    dispatch(showLoader());
    fetchSingleUser(userId)
      .then((res) => {
        const userDetail = { ...res.data.data };

        if (userDetail.userActive) setIsActiveUser(true);
        dispatch(hideLoader());
        // specify breadcrumb here
        const breadCrumbLinks = [
          { linkUrl: "/internal-user", linkName: "Internal User" },
          { linkUrl: `/internal-user/${userId}/edit#profile`, linkName: `${userDetail.userFname} ${userDetail.userLname}` },
          { linkUrl: `/internal-user/${userId}/edit/access#access`, linkName: "Access Rights", linkActive: true },
        ];
        dispatch(setBreadCrumb(breadCrumbLinks));
        fetchUserAuthorization(userId);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch User Details Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch user's access rights
   * @param {String} userId
   */
  const fetchUserAuthorization = (userId) => {
    dispatch(showLoader());
    fetchAuthorization(userId)
      .then((res) => {
        const { data = [] } = res.data;
        if (data.userAccess.length) {
          setUserRights(JSON.parse(JSON.stringify(data.userAccess)));
        }
        dispatch(hideLoader());
      })
      .catch((error) => {
        let errMsg = error?.data?.message ? error?.data?.message : "Fetch User Authorization Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description update user's access rights
   * @param {Array} selectedRights
   */
  const updateUserAccessRights = (selectedRights) => {
    dispatch(actionStart());
    saveAuthorization(userId, [...selectedRights])
      .then((res) => {
        dispatch(actionSuccess("User Access Rights Updated"));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Update Access Rights Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description save user's access rights
   */
  const saveAccessRights = () => {
    const checkedNodes = divRef.current.querySelectorAll('input[type="radio"]:not([value="NO"]):checked');
    if (checkedNodes.length < 1) dispatch(actionError("No Access Rights!"));
    else {
      let checkedAccessRights = [],
        coreModulesIdsValues = [],
        processedModulesIds = {};
      const coreModulesClone = JSON.parse(JSON.stringify(coreModules));
      coreModulesClone.forEach((cM) => (coreModulesIdsValues[cM.id] = cM));
      for (const cN of checkedNodes) {
        const name = String(cN.name).split(":");
        if (processedModulesIds[name[0]]) processedModulesIds[name[0]].moduleAttribute.push(`${name[1]}:${cN.value}`);
        else {
          processedModulesIds[name[0]] = coreModulesIdsValues[name[0]];
          processedModulesIds[name[0]].moduleAttribute = [`${name[1]}:${cN.value}`];
        }
      }
      checkedAccessRights = [
        ...Object.values(processedModulesIds).map((pM) => {
          pM.moduleId = pM.id;
          delete pM.id;
          delete pM.moduleAccess;
          delete pM.moduleStatus;
          delete pM.childModules;
          delete pM.parentModuleId;
          delete pM.parentModuleName;
          delete pM.moduleName;
          delete pM.moduleType;
          delete pM.moduleNavOrder;
          return pM;
        }),
      ];
      dispatch(
        showAlertBox({
          okCallback: updateUserAccessRights,
          data: checkedAccessRights,
          okText: "Save",
          cancelText: "Cancel",
          content: "Do you want to save?",
          title: "dialogAlertCss",
        })
      );
    }
  };

  /**
   * @description reset user's access rights
   */
  const resetAccessRights = async () => {
    fetchUserAuthorization(userId);
  };

  /**
   * @description access rights radio button check handler
   * @param {String} moduleId
   * @param {String} attribute
   * @param {String} attributeToRemove1
   * @param {String} attributeToRemove2
   */
  const radioCheckHandle = (moduleId, attribute, attributeToRemove1, attributeToRemove2) => {
    const accessModule = userRights.find((uR) => uR.moduleId === moduleId);
    if (accessModule) {
      if (accessModule.moduleAttribute.includes(attributeToRemove1))
        accessModule.moduleAttribute.splice(accessModule.moduleAttribute.indexOf(attributeToRemove1), 1);
      if (accessModule.moduleAttribute.includes(attributeToRemove2))
        accessModule.moduleAttribute.splice(accessModule.moduleAttribute.indexOf(attributeToRemove2), 1);
      accessModule.moduleAttribute.push(attribute);
      setUserRights([...userRights]);
    } else {
      let userRts = [...userRights];
      userRts.push({
        moduleId: moduleId,
        moduleAttribute: [attribute],
      });
      setUserRights([...userRts]);
    }
  };

  /**
   * @description function to identify checked status of access rights radio button
   * @param {String} moduleId
   * @param {String} attribute
   */
  const checkUserRights = (moduleId, attribute) => {
    const accessModule = userRights.find((uR) => uR.moduleId === moduleId);
    if (accessModule) return accessModule.moduleAttribute.includes(attribute);
    return false;
  };

  /**
   * @description render child module
   * @param {Object} childModule
   */
  const renderChildModule = (childModule) => {
    return (
      <>
        <div className="col-md-12 border-bottom font-weight-bold text-center bg-light text-uppercase p-2">{childModule.moduleName}</div>
        {renderAttribute(childModule.id, childModule.moduleAttribute, childModule.moduleAccess)}
      </>
    );
  };

  /**
   * @description render module attribute
   * @param {String} moduleId
   * @param {Array} attribute
   */
  const renderAttribute = (moduleId, attribute, access) => {
    return attribute.map((attr) => {
      return (
        <div className="col-md-12 border-bottom p-2" key={attr}>
          <div className="row">
            <div className="col-md-4 font-weight-bold">{attr}</div>
            <div className="col-md-2">
              {access.includes("NO") ? (
                <label className="form-check-label font-weight-bold">
                  <input
                    type="radio"
                    className="form-check-input"
                    name={`${moduleId}:${attr}`}
                    value="NO"
                    onChange={() => radioCheckHandle(moduleId, `${attr}:NO`, `${attr}:FULL`, `${attr}:VIEW`)}
                    checked={checkUserRights(moduleId, `${attr}:NO`)}
                    disabled={!accessPermission}
                  />
                  No Access
                </label>
              ) : null}
            </div>
            <div className="col-md-2">
              {access.includes("VIEW") ? (
                <label className="form-check-label font-weight-bold">
                  <input
                    type="radio"
                    className="form-check-input"
                    name={`${moduleId}:${attr}`}
                    value="VIEW"
                    onChange={() => radioCheckHandle(moduleId, `${attr}:VIEW`, `${attr}:FULL`, `${attr}:NO`)}
                    checked={checkUserRights(moduleId, `${attr}:VIEW`)}
                    disabled={!accessPermission}
                  />
                  View only Access
                </label>
              ) : null}
            </div>
            <div className="col-md-2">
              {access.includes("FULL") ? (
                <label className="form-check-label  font-weight-bold">
                  <input
                    type="radio"
                    className="form-check-input"
                    name={`${moduleId}:${attr}`}
                    value="FULL"
                    onChange={() => radioCheckHandle(moduleId, `${attr}:FULL`, `${attr}:VIEW`, `${attr}:NO`)}
                    checked={checkUserRights(moduleId, `${attr}:FULL`)}
                    disabled={!accessPermission}
                  />
                  Full Access
                </label>
              ) : null}
            </div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    dispatch(setSideNavForcedActiveLink("/internal-user"));
    fetchCoreModulesAccessRights();
    editUser && fetchUserDetail(userId);
    return () => {
      dispatch(setBreadCrumb());
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch]);
  const divRef = useRef(null);
  return (
    <div className="gennx-content-wrapper padding-bottom-50i">
      <div className="" id="content-box">
        <div className="col-md-12 p-0" style={{ width: "99.5%" }}>
          <div className="wrapper center-block pt-3">
            <div className="panel-group news-panel usermanagement mx-2" id="accordion" role="tablist" aria-multiselectable="true" ref={divRef}>
              {accessRights.map((cM, i) => {
                if (cM.childModules || (cM.moduleAttribute && cM.moduleAttribute.length)) {
                  return (
                    <Accordion heading={cM.moduleName} activeState={true} step={i + 1} key={cM.id}>
                      <div className="row">
                        <div className="col-md-12 pt-1 pl-4 pr-4">
                          {cM.childModules && cM.childModules.length ? (
                            cM.childModules.map((childM) => {
                              if (childM.moduleAttribute && childM.moduleAttribute.length)
                                return (
                                  <div className="row" key={childM.id}>
                                    {renderChildModule(childM)}
                                  </div>
                                );
                              else return null;
                            })
                          ) : (
                            <div className="row">{renderAttribute(cM.id, cM.moduleAttribute, cM.moduleAccess)}</div>
                          )}
                        </div>
                      </div>
                    </Accordion>
                  );
                } else return null;
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="form-submit-box ml-1">
        <div className="container-fluid">
          <div className="row">
            <div className="float-left col-md-6 text-left">
              {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                <span className="pl-4" style={{ "margin-left": "-3em" }}>
                  <span
                    // to={props.nextPath}
                    className="span-link"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                      onClick={() => {
                        (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                          (props.selectedIndex < 2 && props.setCurrentTab(props.selectedIndex - 1));
                      }}
                      // disabled={props.nextPath ? false : true}
                    >
                      Previous
                    </button>
                  </span>
                </span>
              ) : null}

              {userId !== "" && props.selectedIndex > -1 && props.selectedIndex < 1 ? (
                <span className="pl-4" style={{ "margin-left": "0" }}>
                  <span
                    // to={props.nextPath}
                    className="span-link"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                      onClick={() => {
                        props.selectedIndex < 1 && props.setCurrentTab(props.selectedIndex + 1);
                      }}
                      // disabled={props.nextPath ? false : true}
                    >
                      Next
                    </button>
                  </span>
                </span>
              ) : null}
            </div>
            <div className="float-right col-md-6 text-right">
              <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3 " onClick={() => history.push(`/internal-user`)}>
                Cancel
              </button>
              {accessPermission ? (
                isActiveUser ? (
                  <>
                    <button type="submit" className="btn btn-primary cy-btn pl-3 pr-3 ml-3 mr-3" onClick={() => resetAccessRights()}>
                      Reset
                    </button>
                    <button type="button" className="btn btn-primary cy-btn pl-3 pr-3 " onClick={() => saveAccessRights()}>
                      Save
                    </button>
                  </>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessRights;
