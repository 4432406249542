/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Application Utility functions
----------------------------------------------------------------
	Creation Details
	Date Created				: 03/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import { fetchTaxonomyTechnology, fetchTaxonomyTrend, fetchTaxonomyMedia } from "../middleware/services/cmsApi";
import { fetchClientUser } from "../middleware/services/userApi";
import * as sha1 from "sha1";
import { SALT, PROJECT_MANAGEMENT_URL, PRODUCT_VARIANT, COMMON_ACCESS_ACTION_ADMIN } from "../constants";

export const fetchClientUsers = async () => {
  let params = {};
  params.filters = [["userActive", "eq", 1]];
  let fields = ["id", "userFname", "userLname"];
  params.fields = fields;
  params.limit = 2000;
  params.sort = "userFname:asc";
  const response = await fetchClientUser(params);
  if (response.data && response.data.data) {
    return (
      response?.data &&
      response?.data?.data?.map((item) => {
        return { id: item.id, text: `${item.userFname} ${item.userLname}` };
      })
    );
  }
};
export const fetchTechTrendData = (productVariant) => {
  if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) {
    return fetchTaxonomyTrend({
      filters: [["isTrendNode", "eq", 1]],
      fields: ["trendId", "trendName"],
      sort: "trendName:asc",
      limit: 1000,
    }).then((res) => {
      if (res?.status === 200) {
        let { data = [] } = res.data;
        return (
          data &&
          data.map((item) => {
            return { id: item.id, text: item.trendName };
          })
        );
      }
    });
  } else if (productVariant && productVariant === PRODUCT_VARIANT.COGNITION) {
    return fetchTaxonomyMedia({
      filters: [["taxonomyParentId", "eq", 0]],
      fields: ["taxonomyId", "taxonomyName"],
      sort: "taxonomyName:asc",
      limit: 1000,
    }).then((res) => {
      if (res?.status === 200) {
        let { data = [] } = res.data;
        return (
          data &&
          data.map((item) => {
            return { id: item.id, text: item.taxonomyName };
          })
        );
      }
    });
  } else {
    return fetchTaxonomyTechnology({
      filters: [["dtParentId", "eq", 0]],
      fields: ["dtId", "dtName"],
      sort: "dtName:asc",
      limit: 1000,
    }).then((res) => {
      if (res?.status === 200) {
        let { data = [] } = res.data;
        return (
          data &&
          data.map((item) => {
            return { id: item.id, text: item.dtName };
          })
        );
      }
    });
  }
};

export const getDateFromMongoDate = (date) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  let newDate = new Date(date);

  newDate = `${newDate.getDate()}-${months[newDate.getMonth()]}-${newDate.getFullYear()}`;

  return newDate;
};

export const getCurrentDate = () => {
  let date = new Date();
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear()
  );
};

// swap json key value
export const swap = (json) => {
  const ret = {};
  for (const key in json) {
    ret[json[key]] = key;
  }
  return ret;
};

/**
 * @description get user details from token
 * @author Yogesh N. Gupta
 */
export const getLoggedIn = () => {
  let token = localStorage.getItem("token");
  if (token) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    let kyData = JSON.parse(jsonPayload);
    return kyData;
  } else return undefined;
};

/**
 * @description get user details from token
 * @author Yogesh N. Gupta
 */
export const getLoggedInUser = () => {
  const genxUserId = localStorage.getItem("genxUserId");
  const userEmail = localStorage.getItem("userEmail");
  const genxUserProductVariant = JSON.parse(localStorage.getItem("genxUserProductVariant"));
  let userProductVariant = [];
  if (genxUserProductVariant?.length > 0) {
    genxUserProductVariant.forEach((pv) => {
      userProductVariant.push({ product_name: pv.productName });
    });
  }
  const userType = localStorage.getItem("userType");
  const userFname = localStorage.getItem("userFname");
  const userLname = localStorage.getItem("userLname");
  return {
    _id: genxUserId,
    user_email: userEmail,
    product_variant: userProductVariant,
    user_type: userType || "",
    user_fname: userFname,
    user_lname: userLname,
    user_name: userFname + " " + userLname,
    user_role: userType || "",
  };
  // let token = localStorage.getItem("token");
  // if (token) {
  //   let buffer = new Buffer(token.split(".")[1], "base64");
  //   return JSON.parse(buffer.toString());
  // } else return undefined;
};

/**
 * @description get if user is admin
 * @author Yogesh N. Gupta
 */
export const isAdmin = () => {
  let userType = localStorage.getItem("userType");
  if (userType && userType === "Admin") return true;
  return false;
  // let token = localStorage.getItem("token");
  // if (token) {
  //   let buffer = new Buffer(token.split(".")[1], "base64");
  //   let user = JSON.parse(buffer.toString());
  //   return user.user_type === "Admin";
  // } else return false;
};

/**
 * @description get user authorization details
 * @author Yogesh N. Gupta
 */
export const getAuthorization = () => {
  let accessToken = localStorage.getItem("access-token");
  if (accessToken) {
    let decodedAccessToken = window.atob(accessToken);
    accessToken = JSON.parse(decodedAccessToken);
    return accessToken;
  } else return undefined;
};

/**
 * @description get access rights checked
 * @author Yogesh N. Gupta
 */
export const accessRightCheck = (moduleName, interfaceName) => {
  const appName = "GenNx";
  const isUserAdmin = isAdmin();
  const userAccessRights = getAuthorization();
  if (isUserAdmin) return true;
  if (userAccessRights) {
    return userAccessRights?.userAccess.find((uAR) => uAR.moduleName === moduleName && uAR.interfaceName === interfaceName && uAR.appName === appName)
      ? true
      : false;
  } else return false;
};

/**
 * @description get actions access rights checked
 * @author Satya Prakash Mall
 */
export const accessRightInterfaceCheck = (moduleName, interfaces) => {
  const appName = "GenNx";
  const isUserAdmin = isAdmin();
  const userAccessRights = getAuthorization();
  const interfaceAccess = [];
  if (isUserAdmin) return interfaces;
  if (userAccessRights) {
    interfaces.map((interfaceName) => {
      userAccessRights.userAccess.map((uAR) => {
        if (uAR.moduleName === moduleName && uAR.interfaceName === interfaceName && uAR.appName === appName) {
          interfaceAccess.push(interfaceName.trim());
        }
      });
    });
  }
  return interfaceAccess;
};
/**
 * @description get actions access rights checked
 * @author Satya Prakash Mall
 */
export const accessRightActionCheck = (moduleName, interfaceName) => {
  const appName = "GenNx";
  const isUserAdmin = isAdmin();
  const userAccessRights = getAuthorization();
  const actionAccess = [];
  if (isUserAdmin) return COMMON_ACCESS_ACTION_ADMIN;
  if (userAccessRights) {
    userAccessRights.userAccess.map((uAR) => {
      if (uAR.moduleName === moduleName && uAR.interfaceName === interfaceName && uAR.appName === appName) {
        actionAccess.push(uAR.actionName.trim());
      }
    });
  }
  return actionAccess;
};

/**
 * @description get access rights checked for sidenav
 * @author Yogesh N. Gupta
 */
export const accessRightCheckSideNav = (moduleName, parentModuleName) => {
  const isUserAdmin = isAdmin();
  const userAccessRights = getAuthorization();
  if (isUserAdmin) return true;
  // if (userAccessRights) return userAccessRights.find(uAR => uAR.moduleName === moduleName && uAR.parentModuleName === parentModuleName);
  if (userAccessRights) return userAccessRights.userAccess.find((uAR) => uAR.moduleName === moduleName && uAR.parentModuleName === parentModuleName);
  else return false;
};

/**
 * @description get landing page based on access rights
 * @author Yogesh N. Gupta
 */
export const getLandingPageRoute = (accessRights = [], coreModules = []) => {
  let routePath = "/company";
  let redirectLocation = localStorage.getItem("redirectLocation");
  if (redirectLocation) {
    localStorage.removeItem("redirectLocation");
    return redirectLocation;
  }

  if (accessRights?.dynamicMenu[0]?.menuChildren[0]?.subMenu[0]?.subMenu[0]?.menuLink) {
    routePath = accessRights.dynamicMenu[0].menuChildren[0].subMenu[0].subMenu[0].menuLink;
  } else {
    routePath = accessRights?.dynamicMenu[0]?.menuChildren[0]?.subMenu[0]?.menuLink;
  }
  return routePath;
};

/**
 * @description get product variant
 * @author Yogesh N. Gupta
 */
export const getProductVariant = () => {
  return localStorage.getItem("productVariant") ? localStorage.getItem("productVariant") : undefined;
};

/**
 * @description get decoded HTML
 * @author Yogesh N. Gupta
 */
export const decodeHTML = (html) => {
  let txt = document.createElement("textarea");
  txt.innerHTML = html.replace(/\<br\s*[\/]?>/gi, "");
  return txt.value;
};

/**
 * @description Convert a base64 string in a Blob according to the data and contentType.
 * @author Yogesh N. Gupta
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = escape(window.atob(b64Data));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};
export const toTitleCase = (str) => {
  let sentence = str.toLowerCase().split(" ");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
};
// Will return Unique array elements from multidimentional array
export const multiDimensionalUnique = (arr) => {
  var uniques = [];
  var itemsFound = {};
  for (var i = 0, l = arr.length; i < l; i++) {
    var stringified = JSON.stringify(arr[i]);
    if (itemsFound[stringified]) {
      continue;
    }
    uniques.push(arr[i]);
    itemsFound[stringified] = true;
  }
  return uniques;
};

export const shorten = (str, maxLen, separator = " ") => {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
};

export const getArrayCount = (arr) => {
  var compressed = [];
  // make a copy of the input array
  var copy = arr.slice(0);

  // first loop goes over every element
  for (var i = 0; i < arr.length; i++) {
    var myCount = 0;
    // loop over every element in the copy and see if it's the same
    for (var w = 0; w < copy.length; w++) {
      if (arr[i] == copy[w]) {
        // increase amount of times duplicate is found
        myCount++;
        // sets item to undefined
        delete copy[w];
      }
    }

    if (myCount > 0) {
      var a = new Object();
      a.value = arr[i];
      a.count = myCount;
      compressed.push(a);
    }
  }

  return compressed;
};
// Redirect to Project Managment
export const projectManagmentLogin = (e) => {
  var visitortime = new Date();
  var visitortimezone = -visitortime.getTimezoneOffset() / 60;
  const loggedInUser = getLoggedInUser();

  var fields = [
    {
      name: "data[User][timezone_id]",
      value: visitortimezone,
    }, // visiters timezone
    {
      name: "data[User][email]",
      value: loggedInUser.user_email,
    },
    {
      name: "data[User][key]",
      value: sha1(loggedInUser.user_email + "::" + SALT),
    },
    {
      name: "data[App][url]",
      value: window.location.href,
    },
  ];

  var form = document.createElement("form");
  form.method = "POST";
  form.action = PROJECT_MANAGEMENT_URL;
  // fields

  for (var i = 0; i < fields.length; i++) {
    var element = document.createElement("input");
    element.value = fields[i].value;
    element.name = fields[i].name;
    element.type = "hidden";
    form.appendChild(element);
  }
  document.body.appendChild(form);
  form.submit();
};

/*###################### For CKEditor character count with or without HTML tag #####################*/
/**
 * Implement filter to add or remove before counting
 * @param html
 * @returns string
 */
//  function filter(html) {
// 	if (config.filter instanceof CKEDITOR.htmlParser.filter) {
// 		var fragment = CKEDITOR.htmlParser.fragment.fromHtml(html),
// 			writer = new CKEDITOR.htmlParser.basicWriter();
// 		config.filter.applyTo(fragment);
// 		fragment.writeHtml(writer);
// 		return writer.getHtml();
// 	}
// 	return html;
// }

// export const countCharacters=(text)=> {
// 	// if (config.countHTML) {
// 	// 	return config.countBytesAsChars ? countBytes(filter(text)) : filter(text).length;
// 	// }

// 	var normalizedText;

// 	// strip body tags
// 	// if (editor.config.fullPage) {
// 		var i = text.search(new RegExp("<body>", "i"));
// 		if (i != -1) {
// 			var j = text.search(new RegExp("</body>", "i"));
// 			text = text.substring(i + 6, j);
// 		}

// 	// }

// 	normalizedText = text;

// 	// if (!config.countSpacesAsChars) {
// 		normalizedText = text.replace(/\s/g, "").replace(/&nbsp;/g, "");
// 	// }

// 	// if (config.countLineBreaks) {
// 	// 	normalizedText = normalizedText.replace(/(\r\n|\n|\r)/gm, " ");
// 	// } else {
// 		normalizedText = normalizedText.replace(/(\r\n|\n|\r)/gm, "").replace(/&nbsp;/gi, " ");
// 	// }

// 	normalizedText = strip(normalizedText).replace(/^([\t\r\n]*)$/, "");

// 	return  normalizedText.length;
// 	// config.countBytesAsChars ? countBytes(normalizedText) :
// 	//  normalizedText.length;
// }

// function countBytes(text) {
// 	var count = 0, stringLength = text.length, i;
// 	text = String(text || "");
// 	for (i = 0; i < stringLength; i++) {
// 		var partCount = encodeURI(text[i]).split("%").length;
// 		count += partCount == 1 ? 1 : partCount - 1;
// 	}
// 	return count;
// }
// function strip(html) {
// 	if (bbcodePluginLoaded) {
// 		// stripping out BBCode tags [...][/...]
// 		return html.replace(/\[.*?\]/gi, "");
// 	}

// 	var tmp = document.createElement("div");

// 	// Add filter before strip
// 	html = filter(html);

// 	tmp.innerHTML = html;

// 	if (tmp.textContent == "" && typeof tmp.innerText == "undefined") {
// 		return "";
// 	}

// 	return tmp.textContent || tmp.innerText;
// }

export const removeTags = (val) => {
  var rex = /(<([^>]+)>)/gi;
  val = val
    .replace(/[\t\n\r]+/g, " ")
    .split("&nbsp;")
    .join("");
  return val.replace(rex, "");
};
/*###################### End For CKEditor character count with or without HTML tag ####################*/

// add days to date
export const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
