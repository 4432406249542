/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News overview - Add | Edit Overview
     1. Import all news overview element from customFormFields
     2 Use Formik for validation
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/

import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { objectiveOverviewValidationSchema } from "./validationSchema";
import { actionSuccess, actionError, hideLoader, showLoader } from "../../../../../../middleware/actions/utilityAction";
import { getProductVariant, getDateFromMongoDate } from "../../../../../../utilities";
import {
  fetchTaxonomyTrend,
  fetchProgramManager,
  fetchCmsUser,
  fetchTaxonomyTechnology,
  fetchCustomForms,
  fetchClientUser,
} from "../../../../../../middleware/services/cmsApi";
import { useDispatch } from "react-redux";
import { CATEGORY_OPTIONS, WHATNEXT_OBJECTIVE_TYPE, INSIDER_OBJECTIVE_TYPE } from "./formFieldOptions";
import { MultiSelectNew, DropdownNew, DatePicker, TextField } from "../../../../../components/CustomFormElements/syncFusionFormFields";
import { PRODUCT_VARIANT } from "../../../../../../constants";
import newsImage from "../../../../../../assets/images/news.jpg";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";

// import Taxanomy from "../../../../../components/treeView/objectiveSgfTagging";
import Categorization from "./categorization";
import { fetchObjectiveImageUrl } from "../../../../../../middleware/services/businessObjectiveApi";
import { BUSINESS_OBJECTIVE_THUMB_IMG_SIZE } from "config";

const OverviewForm = (props) => {
  const [industryId, setIndustryId] = useState(undefined);
  const [industryName, setIndustryName] = useState(undefined);
  const [objectiveClientUsers, setObjectiveClientUsers] = useState(null);
  const [formOptions, setFormOptions] = useState(null);
  const [technologyOptions, setTechnologyOptions] = useState(null);
  const [industryOptions, setIndustryOptions] = useState(null);
  const [objectiveDemoUsers, setObjectiveDemoUsers] = useState(null);
  const [managerToggleFlag, setManagerToggleFlag] = useState(false);
  const [objectiveProgramManagers, setObjectiveProgramManagers] = useState(null);
  const productVariant = getProductVariant();
  const dispatch = useDispatch();
  const [nonDt, setNonDt] = useState([]);
  const [objectiveThumbnailImageSrc, setObjectiveThumbnailImageSrc] = useState(undefined);
  const objectiveThumbnailDefaultImageRef = useRef(null);
  const objectiveThumbnailImageRef = useRef(null);
  const [imageError, setImageError] = useState("");
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const [showTrash, setShowTrash] = useState(false);
  const [imageBlob, setImageBlob] = useState(null);
  const [imageTypeError, setImageTypeError] = useState("");
  const [imageDelete, setImageDelete] = useState(false);
  let saveOverviewData = props?.imageData;

  const style = {
    label: {
      fontWeight: 600,
    },
  };
  /*
        @Description : Assign default value and bind validation schema
    */
  if (productVariant === PRODUCT_VARIANT.INSIDER) {
    objectiveOverviewValidationSchema.industry = Yup.string().required("Please select industry").typeError("Please select industry");
    objectiveOverviewValidationSchema.objectiveApplicationPlatform = Yup.string()
      .required("Please select applicable platform")
      .typeError("Please select applicable platform");
  } else {
    delete objectiveOverviewValidationSchema.industry;
    delete objectiveOverviewValidationSchema.objectiveApplicationPlatform;
  }
  const formik = useFormik({
    initialValues: props.objectiveInformation,
    validationSchema: Yup.object().shape(objectiveOverviewValidationSchema),
  });

  /**
   * @description get client users
   */
  const getObjectiveClientUsers = async function () {
    const response = await fetchClientUser({ productVariant: productVariant });
    if (response.data && response.data.data) {
      const userData = response.data.data.map((typesData) => {
        return {
          value: typesData.id["$id"],
          label: `${typesData["userFname"]} ${typesData["userLname"]}`,
        };
      });
      setObjectiveClientUsers(userData);
    }
  };

  /**
   * @description get Demo users
   */
  const getObjectiveDemoUsers = async function () {
    let params = {};
    params.filters = [["userActive", "eq", 1]];
    let fields = ["id", "userFname", "userLname"];
    params.fields = fields;
    params.limit = 2000;
    params.sort = "userFname:asc";
    const response = await fetchCmsUser({ productVariant: productVariant });
    if (response.data && response.data.data) {
      const userData =
        response &&
        response.data &&
        response.data.data.map((typesData) => {
          return {
            value: typesData["id"].$id,
            label: `${typesData["userFname"]} ${typesData["userLname"]}`,
          };
        });
      setObjectiveDemoUsers(userData);
    }
  };

  /**
   * @description get Program Managers
   */
  const getProgramManagers = async function (selectedIndustryId) {
    try {
      const response = await fetchProgramManager({
        productVariant: productVariant,
      });
      dispatch(showLoader());
      let selectedIndustry = [];
      let industry;
      if (industryOptions) {
        selectedIndustry = industryOptions.filter((val) => {
          return val.id === selectedIndustryId;
        });
        industry = selectedIndustry[0].label;
      }

      if (response.data && response.data.data) {
        let userData = response.data.data;
        if (productVariant == PRODUCT_VARIANT.INSIDER) {
          userData = response.data.data.filter((typesData) => {
            return typesData["industry"] == industry;
          });
        }

        userData = userData.map((typesData) => {
          return {
            value: typesData.userId["$id"],
            label: `${typesData["userFName"]} ${typesData["userLName"]}`,
          };
        });
        setObjectiveProgramManagers(userData);
      }
    } catch (e) {
      dispatch(hideLoader());
    } finally {
      dispatch(hideLoader());
    }
  };

  /**
   * @description get Form Options
   */
  const fetchForm = () => {
    let params = {};
    if (props.isDisabled) params.filters = [["productVariant.productName", "eq", productVariant]];
    else
      params.filters = [
        ["productVariant.isLive", "eq", "YES"],
        ["productVariant.productName", "eq", productVariant],
        ["formIsDeleted", "eq", "NO"],
        ["formIsActive", "eq", "YES"],
      ];

    fetchCustomForms(params).then((res) => {
      let { data = [] } = res.data;
      setFormOptions(
        data.map((formData) => {
          return { value: formData.id, label: formData.formTitle };
        })
      );
    });
  };

  /**
   * @description get Technology Options
   */
  const fetchTechnology = () => {
    fetchTaxonomyTechnology({
      filters: [["dtParentId", "eq", 0]],
      sort: "dtName:asc",
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
    }).then((res) => {
      let { data = [] } = res.data;
      data = data.map((tech) => {
        return { value: tech.id, label: tech.dtName };
      });
      setTechnologyOptions(data);
    });
  };

  /**
   * @description get Industry Options
   */
  const fetchIndustry = async function () {
    fetchTaxonomyTrend({
      filters: [["isTrendNode", "eq", 1]],
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
    }).then((res) => {
      let { data = [] } = res.data;
      const setIndustryTags = JSON.parse(JSON.stringify(data));
      let industryDropdownValues = [];
      let uniqueIndex = [];
      setIndustryTags.forEach((sTT) => {
        if (!uniqueIndex.includes(sTT.trendParentId)) {
          const industryDropdownValue = {};
          // industryDropdownValue['value'] = sTT.trendParentId;
          industryDropdownValue["value"] = sTT.trendParentId;
          industryDropdownValue["label"] = sTT.trendParentName;
          industryDropdownValue["id"] = sTT.trendParentId;

          if (formik.values.industry != "") {
            if (formik.values.industry.value == sTT.trendParentId) {
              setIndustryId(sTT.trendParentId);
              setIndustryName(sTT.trendParentName);
            }
          }
          uniqueIndex.push(sTT.trendParentId);
          industryDropdownValues.push(industryDropdownValue);
        }
      });
      setIndustryOptions(industryDropdownValues);
    });
  };
  const getDefaultImage = () => {
    fetch(newsImage)
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((e) => {
        setImageBlob(e);
      });
  };
  const readProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      objectiveThumbnailImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    getObjectiveClientUsers();
    getObjectiveDemoUsers();
    fetchTechnology();
    fetchIndustry();
    fetchForm();
  }, []);

  const handleSaveCategorization = (selectedTech = "", checkedNonDt) => {
    setNonDt(checkedNonDt);
    formik.values["objectiveIndustrySgfTaxonomy"] = checkedNonDt;
  };
  useEffect(() => {
    if (formik?.values?.industry?.value) {
      if (managerToggleFlag) {
        formik.values.objectiveProgramManagers = [];
      }
      setManagerToggleFlag(true);
    }
    if (formik.values?.objectiveProgramManagers?.length) {
      formik.setValues({
        objectiveProgramManagers: [],
      });
      formik.setFormikState({
        ...formik,
        touched: {
          // objectiveProgramManagers: true,
          //   contentBlockVariant: true
        },
        errors: {
          // objectiveProgramManagers: "please select managers",
          // contentBlockVariant: "please select variant/brand"
        },
      });
    }
    if (formik?.values?.industry?.value) getProgramManagers(formik.values?.industry?.value);
  }, [formik.values?.industry?.value]);
  /*
        @Description : Set news data to fields 
    */
  useEffect(() => {
    formik.setValues(props.objectiveInformation);
  }, [props.objectiveInformation]);
  /*
        @Description : Save news data if everything is valid
    */
  useEffect(() => {
    getDefaultImage();

    if (props?.objectiveInformation?.objectiveProfileImage) {
      setObjectiveThumbnailImageSrc(fetchObjectiveImageUrl(props?.objectiveInformation?.objectiveProfileImage));
      setShowTrash(true);
    }
    if (props.saveObjective) {
      formik.validateForm().then((res) => {
        if (Object.keys(res).length) {
          productVariant !== PRODUCT_VARIANT.INSIDER && delete res.industry;
          const touched = {};
          Object.keys(res).map((field) => {
            touched[field] = true;
            return touched;
          });
          let errors = Object.keys(res);
          if (errors.length > 0) dispatch(actionError(res[errors[0]]));
          formik.setFormikState({ ...formik, touched: touched, errors: res });
        } else {
          if (formik.values.objectiveCategory.value == "Client" || formik.values.objectiveCategory.value == "Client Demo") {
            if (!formik.values.objectiveClientUsers) {
              formik.setFormikState({
                ...formik,
                touched: { objectiveClientUsers: true },
                errors: { objectiveClientUsers: "Please select client user." },
              });
              return false;
            }
            if (!formik.values.objectiveClientUsers.length && !formik.values.objectiveCategory.value == "Client Demo") {
              formik.setFormikState({
                ...formik,
                touched: { objectiveClientUsers: true },
                errors: { objectiveClientUsers: "Please select client user." },
              });
              return false;
            }
          }
          if (formik.values.objectiveCategory.value == "Internal Demo") {
            if (!formik.values.objectiveDemoUsers) {
              formik.setFormikState({
                ...formik,
                touched: { objectiveDemoUsers: true },
                errors: { objectiveDemoUsers: "Please select demo user." },
              });
              return false;
            }
            if (!formik.values.objectiveDemoUsers.length) {
              formik.setFormikState({
                ...formik,
                touched: { objectiveDemoUsers: true },
                errors: { objectiveDemoUsers: "Please select demo user." },
              });
              return false;
            }
          }
          let objectiveUsers = [];
          if (formik.values.objectiveCategory.value === "Client" || formik.values.objectiveCategory.value === "Client Demo") {
            if (formik.values.objectiveClientUsers.length) {
              formik.values.objectiveClientUsers.forEach((user) => {
                objectiveUsers.push({
                  user_id: user.value,
                  user_f_name: user.label.split(" ")[0],
                  user_l_name: user.label.split(" ")[1],
                  user_type: "client",
                });
              });
            }
          } else {
            if (formik.values.objectiveDemoUsers.length) {
              formik.values.objectiveDemoUsers.forEach((user) => {
                objectiveUsers.push({
                  user_id: user.value,
                  user_f_name: user.label.split(" ")[0],
                  user_l_name: user.label.split(" ")[1],
                  user_type: "demo",
                });
              });
            }
          }
          if (formik.values.objectiveProgramManagers.length) {
            formik.values.objectiveProgramManagers.forEach((user) => {
              objectiveUsers.push({
                user_id: user.value,
                user_f_name: user.label.split(" ")[0],
                user_l_name: user.label.split(" ")[1],
                user_type: "program_managers",
              });
            });
          }
          let overviewData = {
            objectiveTitle: formik.values.objectiveTitle,
            objectiveCategory: formik.values.objectiveCategory.value ? formik.values.objectiveCategory.value : "",
            objectiveBtbId: formik.values.btbId,
            objectiveCompany: formik.values.objectiveCompany,
            objectiveIndustry: formik.values.industry?.value
              ? {
                  industryId: formik.values.industry?.value,
                  industryName: formik.values.industry?.label,
                }
              : {},
            objectiveIndustryTrend: formik.values.trend?.value
              ? {
                  trendId: formik.values.trend?.value,
                  trendName: formik.values.trend?.label,
                }
              : {},
            objectiveTechnology: formik.values.technology?.value
              ? {
                  dtId: formik.values.technology?.value,
                  dtName: formik.values.technology?.label,
                }
              : {},
            objectiveUsers: objectiveUsers,
            objectiveType: formik.values.objectiveType ? formik.values.objectiveType.value : "",
            objectiveFormId: formik.values.customForm ? formik.values.customForm.value : "",
            objectiveCompletedDate: formik.values.objectiveCompletedDate,
            objectiveIndustrySgfTaxonomy: formik.values.objectiveIndustrySgfTaxonomy.length > 0 ? formik.values.objectiveIndustrySgfTaxonomy : [],
          };
          if (productVariant === PRODUCT_VARIANT.INSIDER)
            overviewData.objectiveApplicationPlatform =
              formik.values.objectiveApplicationPlatform.length > 0
                ? formik.values.objectiveApplicationPlatform.map((v) => {
                    return v.label;
                  })
                : "";

          if (
            objectiveThumbnailDefaultImageRef.current?.files.length &&
            (objectiveThumbnailDefaultImageRef.current?.files[0].type === "image/jpeg" ||
              objectiveThumbnailDefaultImageRef.current?.files[0].type === "image/png")
          ) {
            saveOverviewData.set("objectiveProfileImage", objectiveThumbnailDefaultImageRef.current.files[0]);
            setImageError("");
          } else if (props.objectiveId !== "" && objectiveThumbnailImageSrc === undefined) {
          } else if (
            objectiveThumbnailDefaultImageRef.current?.files.length === 0 &&
            !objectiveThumbnailImageSrc &&
            productVariant !== PRODUCT_VARIANT.INSIDER
          ) {
            setImageError("Please upload image");
          }
          if (Object.keys(saveOverviewData).length === 0 && !imageDelete && props.objectiveId) {
            saveOverviewData.set("objectiveId", props.objectiveId);
          }
          if (objectiveThumbnailDefaultImageRef.current?.files.length === 0 && imageDelete && props.objectiveId) {
            saveOverviewData.set("imageDelete", "yes");
            saveOverviewData.set("objectiveId", props.objectiveId);
          }
          props.handleSaveOverview(overviewData);
        }
      });
      props.handleSave(false);
    }
  }, [props]); // [props.saveNews,nonDt] Removed | trigger on tree data sava

  useEffect(() => {
    if (formik?.values?.industry?.value) getProgramManagers(formik.values.industry.value);
  }, [industryOptions]);

  useEffect(() => {
    if (objectiveProgramManagers?.length) {
      dispatch(hideLoader());
    }
  }, [objectiveProgramManagers]);

  return (
    <div className="mt-2">
      <form>
        <div className="row">
          <div className="col-md-12">
            <TextField name="objectiveTitle" formik={formik} id="objectiveTitle" placeholder="Objective Title *" hideInitial={true} />

            {/* <div className="col-md-6"> */}
            <div className="row">
              <div className="col-md-4">
                {CATEGORY_OPTIONS && (
                  <DropdownNew
                    formik={formik}
                    required="required"
                    enabled={!props.objectiveId ? true : false}
                    name="objectiveCategory"
                    id="objectiveCategory"
                    placeholder="Category *"
                    value={props.objectiveInformation.objectiveCategory.value}
                    fields={{ text: "label", value: "value" }}
                    dataSource={CATEGORY_OPTIONS}
                  />
                )}
              </div>
              <div className="col-md-4">
                {productVariant === PRODUCT_VARIANT.INSIDER
                  ? INSIDER_OBJECTIVE_TYPE && (
                      <DropdownNew
                        formik={formik}
                        required="required"
                        name="objectiveType"
                        id="objectiveType"
                        placeholder="Objective Type *"
                        value={props.objectiveInformation.objectiveType.value}
                        fields={{ text: "label", value: "value" }}
                        dataSource={INSIDER_OBJECTIVE_TYPE}
                      />
                    )
                  : WHATNEXT_OBJECTIVE_TYPE && (
                      <DropdownNew
                        formik={formik}
                        required="required"
                        name="objectiveType"
                        id="objectiveType"
                        placeholder="Objective Type *"
                        value={props.objectiveInformation.objectiveType.value}
                        fields={{ text: "label", value: "value" }}
                        dataSource={WHATNEXT_OBJECTIVE_TYPE}
                      />
                    )}
              </div>
              <div className="col-md-4">
                {formOptions && (
                  <DropdownNew
                    formik={formik}
                    required="required"
                    placeholder="Objective Form *"
                    id="customForm"
                    name="customForm"
                    value={formik.values?.customForm?.value}
                    fields={{ text: "label", value: "value" }}
                    dataSource={formOptions}
                  />
                )}
              </div>
              {productVariant === PRODUCT_VARIANT.INSIDER ? (
                <div className="col-md-4">
                  {industryOptions && (
                    <DropdownNew
                      formik={formik}
                      required="required"
                      placeholder="Industry *"
                      id="industry"
                      name="industry"
                      value={formik.values?.industry?.value}
                      fields={{ text: "label", value: "value" }}
                      dataSource={industryOptions}
                    />
                  )}
                </div>
              ) : (
                <div className="col-md-4">
                  {technologyOptions && (
                    <DropdownNew
                      formik={formik}
                      placeholder="Technology"
                      id="technology"
                      name="technology"
                      value={formik.values?.technology?.value}
                      fields={{ text: "label", value: "value" }}
                      dataSource={technologyOptions}
                    />
                  )}
                </div>
              )}
              {formik.values?.objectiveCategory?.value == "Client" || formik.values?.objectiveCategory?.value == "Client Demo" ? (
                <div className="col-md-4">
                  {objectiveClientUsers?.length > 0 && (
                    <MultiSelectNew
                      formik={formik}
                      mode="CheckBox"
                      required="required"
                      sortOrder="Ascending"
                      showSelectAll={true}
                      placeholder={`Select Client Users ${formik.values.objectiveCategory.value === "Client Demo" ? "" : " *"}`}
                      id="objectiveClientUsers"
                      name="objectiveClientUsers"
                      // allowCustomValue={false}
                      allowCustomValue={true}
                      // value={props.objectiveInformation.objectiveClientUsers.map(v => { return v.label })}
                      value={
                        formik.values.objectiveClientUsers?.length
                          ? formik.values.objectiveClientUsers.map((v) => {
                              return v.value;
                            })
                          : []
                      }
                      fields={{ text: "label", value: "value" }}
                      dataSource={objectiveClientUsers}

                      // noRecordsTemplate={() => <div>Please type to search</div>}
                    />
                  )}
                </div>
              ) : (
                <div className="col-md-4">
                  {objectiveDemoUsers?.length > 0 && (
                    <MultiSelectNew
                      formik={formik}
                      mode="CheckBox"
                      required="required"
                      sortOrder="Acending"
                      showSelectAll={true}
                      placeholder="Select Demo Users *"
                      id="objectiveDemoUsers"
                      name="objectiveDemoUsers"
                      allowCustomValue={true}
                      // value={props.objectiveInformation.objectiveDemoUsers.map(v => { return v.label })}
                      value={
                        formik?.values?.objectiveDemoUsers?.length > 0
                          ? formik.values.objectiveDemoUsers.map((v) => {
                              return v.value;
                            })
                          : []
                      }
                      fields={{ text: "label", value: "value" }}
                      dataSource={objectiveDemoUsers}
                      // noRecordsTemplate={() => <div>Please type to search</div>}
                    />
                  )}
                </div>
              )}

              <div className="col-md-4">
                <div className="form-group required">
                  {objectiveProgramManagers?.length > 0 && (
                    <MultiSelectNew
                      formik={formik}
                      mode="CheckBox"
                      required="required"
                      sortOrder="Acending"
                      showSelectAll={true}
                      placeholder="Select Program Managers *"
                      id="objectiveProgramManagers"
                      name="objectiveProgramManagers"
                      allowCustomValue={true}
                      // value={props.objectiveInformation.objectiveProgramManagers.map(v => { return v.label })}
                      value={
                        formik.values.objectiveProgramManagers?.length
                          ? formik.values.objectiveProgramManagers.map((v) => {
                              return v.value;
                            })
                          : []
                      }
                      fields={{ text: "label", value: "value" }}
                      dataSource={objectiveProgramManagers}
                      // noRecordsTemplate={() => <div>Please type to search</div>}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-2">
                <TextField name="btbId" formik={formik} id="btbId" placeholder="BTB ID" />
              </div>
              <div className="col-md-2">
                <TextField name="objectiveCompany" required="required" formik={formik} id="objectiveCompany" placeholder="Group Name *" />
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <DatePicker
                    formik={formik}
                    properties={{
                      placeholder: "Objective Completed Date",
                      id: "objectiveCompletedDate",
                      name: "objectiveCompletedDate",
                      format: "dd MMM yyyy",
                      floatLabelType: "Auto",
                      allowEdit: false,
                      openOnFocus: true,
                      max: getDateFromMongoDate(new Date()),
                    }}
                  />
                </div>
              </div>
              {props.objectiveId ? (
                <div className="col-md-4">
                  <TextField name="uniqueNo" id="uniqueNo" formik={formik} placeholder="Unique No." disabled={true} />
                </div>
              ) : null}
              {productVariant && productVariant === PRODUCT_VARIANT.INSIDER ? (
                <div className="col-md-4">
                  <div className="form-group required">
                    <MultiSelectNew
                      formik={formik}
                      mode="Box"
                      required="required"
                      sortOrder="Acending"
                      showSelectAll={false}
                      placeholder="Applicable Platform *"
                      id="objectiveApplicationPlatform"
                      name="objectiveApplicationPlatform"
                      allowCustomValue={true}
                      value={props.objectiveInformation.objectiveApplicationPlatform.map((v) => {
                        return v.label;
                      })}
                      fields={{ text: "label", value: "value" }}
                      dataSource={[
                        { label: "Insider V1", value: "Insider V1" },
                        { label: "Insider V2", value: "Insider V2" },
                      ]}
                      showDropDownIcon={true}
                      // noRecordsTemplate={() => <div>Please type to search</div>}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* <div className="col-md-4">
                                <div className="form-group">
                                    <label style={style.label} htmlFor="uniqueId">Unique Id</label>
                                    <ObjectiveTextField
                                        name="uniqueId"
                                        formik={formik}
                                        properties={{}}
                                    />
                                </div>
                            </div> */}
            </div>
          </div>
          {/* </div> */}
          <div className="col-md-12">
            <div className="row">
              {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label style={style.label} htmlFor="objectiveCoverageStartDate">Start Coverage From</label>
                                    <CoverageDate
                                        formik={formik}
                                        name='objectiveCoverageStartDate'
                                        id="objectiveCoverageStartDate"
                                        menuPlacement="top"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div>
                                        <label style={style.label} htmlFor="objectiveCoverageEndDate">Coverage Ends On</label>
                                        <CoverageDate
                                            formik={formik}
                                            name='objectiveCoverageEndDate'
                                            id="objectiveCoverageEndDate"
                                            menuPlacement="top"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group required">
                                    <label style={style.label} htmlFor="objectiveStatus">Objective Status</label>
                                    <ObjectiveStatus
                                        formik={formik}
                                        name='objectiveStatus'
                                        id="objectiveStatus"
                                        menuPlacement="top"
                                        properties={{required:true}}
                                    />
                                </div>
                            </div> */}
              {productVariant && productVariant === PRODUCT_VARIANT.INSIDER ? (
                <div className="col-md-4 ml-1">
                  <label htmlFor="objectiveThumbnail">Objective Thumbnail</label>
                  <div className="image-holder" style={{ padding: "unset", width: "200px" }}>
                    {!objectiveThumbnailImageRef ? (
                      <>
                        <span style={{ color: "red" }}>Choose a file to import</span> <br />
                        <span>or Drag and drop it here</span>
                      </>
                    ) : (
                      ""
                    )}
                    <img
                      width="200"
                      height="200"
                      src={objectiveThumbnailImageSrc || newsImage}
                      alt="Objective Thumbnail"
                      ref={objectiveThumbnailImageRef}
                    />
                    <div className="image-action newsThumbnail_fa_icons" style={{ top: "5px" }}>
                      <span
                        onClick={() => {
                          dispatch(
                            showAlertBox({
                              okText: "Delete",
                              cancelText: "Cancel",
                              content: "Are you sure you want to delete?",
                              title: "dialogAlertCssWarning",
                              okCallback: async () => {
                                dispatch(actionSuccess("Image deleted successfully"));
                                setShowTrash(false);
                                setImageError("");
                                setImageDelete(true);
                                objectiveThumbnailImageRef.current.setAttribute("src", newsImage);
                                objectiveThumbnailDefaultImageRef.current.value = "";
                              },
                            })
                          );
                        }}
                      >
                        <i className={`fa fa-trash mr-1 ${!showTrash ? "d-none" : undefined}`} aria-hidden="true" />
                      </span>
                      <span onClick={() => objectiveThumbnailDefaultImageRef.current.click()}>
                        <i className="fa fa-pencil-alt" aria-hidden="true" />
                      </span>
                    </div>
                  </div>
                  <input
                    type="file"
                    className="d-none"
                    accept=".jpg, .png"
                    id="objectiveThumbnail"
                    name="objectiveThumbnail"
                    ref={objectiveThumbnailDefaultImageRef}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        if (!["image/png", "image/jpg", "image/jpeg"].includes(e.target.files[0].type)) {
                          setImageTypeError("Please upload image type either png or jpg or jpeg");
                          objectiveThumbnailImageRef.current.setAttribute("src", newsImage);
                          objectiveThumbnailDefaultImageRef.current.value = "";
                          setShowTrash(false);
                        } else if (e.target.files[0]?.size > BUSINESS_OBJECTIVE_THUMB_IMG_SIZE) {
                          setImageError(
                            `Max file size should be ${parseInt(BUSINESS_OBJECTIVE_THUMB_IMG_SIZE) / 1024} KB while Uploaded file size is ${(
                              e.target.files[0].size /
                              (1024 * 1024)
                            ).toFixed(2)} MB`
                          );
                        } else if (
                          (e.target.files[0].type == "image/png" ||
                            e.target.files[0].type == "image/jpg" ||
                            e.target.files[0].type == "image/jpeg") &&
                          e.target.files[0].size <= BUSINESS_OBJECTIVE_THUMB_IMG_SIZE
                        ) {
                          //1000kb == 1000000 byte
                          readProfileImage(e.target.files[0]);
                          objectiveThumbnailImageRef.current.setAttribute("src", e.target.files[0]);
                          setImageError("");
                          setImageTypeError("");
                          setShowTrash(true);
                          setIsDefaultImage(false);
                          formik.setErrors({
                            ...formik.errors,
                            objectiveThumbnail: "",
                          });
                        }
                        // else {
                        //   setImageError(`Max file size should be ${parseInt(import.meta.env.VITE_DELIVERABLE_FILE_SIZE) / 1024} KB while Uploaded file size is ${(e.target.files[0].size / (1024 * 1024)).toFixed(2)} MB`);
                        // }
                      }
                    }}
                  />
                  <div className="row">
                    <small>
                      <div className="col-md-12 pt-2">
                        <span className="font-weight-bold">Note : </span>
                      </div>
                      <div className="col-md-12">
                        <li>Only JPG, PNG files are allowed.</li>
                      </div>
                      <div className="col-md-12">
                        <li>Max file size allowed is 200KB</li>
                      </div>
                    </small>
                  </div>
                  <div className="text-danger" style={{ display: imageError ? "block" : "none" }} role="alert">
                    {imageError &&
                      imageError.split("while").map((e) => (
                        <small key={e.length}>
                          {e} <br />{" "}
                        </small>
                      ))}
                  </div>
                  <div className="text-danger" style={{ display: imageTypeError ? "block" : "none" }} role="alert">
                    {imageTypeError &&
                      imageTypeError.split("while").map((e) => (
                        <small key={e.length}>
                          {e} <br />{" "}
                        </small>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {productVariant && productVariant === PRODUCT_VARIANT.INSIDER ? (
                <div className="col-md-4 newsCategories">
                  <div className="form-group">
                    <label style={style.label}>SGF Taxonomy</label>
                    <Categorization {...props} handleSaveCategorization={handleSaveCategorization} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OverviewForm;
