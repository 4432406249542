/*
================================================================
	Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : People Routes 
----------------------------------------------------------------
	Creation Details
	Date Created				: 13/Apr/2020
	Author						: Sandeep K. Sharma
================================================================
*/

import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AuthLayout from "../app/layout/authLayout";
import AddPeople from "../app/views/people/add";
import PeopleListing from "../app/views/people";

const PEOPLE = ["People:VIEW", "People:FULL"];
const peopleRoutes = () => (
  <Suspense
    fallback={
      <div className="text-center">
        <h3>Loading...</h3>
      </div>
    }
  >
    <Switch>
      <AuthLayout
        exact
        path="/people/add"
        component={AddPeople}
        pageHeading="GenNx CMS - People"
        accessRights={{
          moduleName: "People",
          parentModuleName: "",
          moduleAttribute: [...PEOPLE],
          interfaceName: "Basic",
        }}
      />
      <AuthLayout
        exact
        path="/people"
        component={PeopleListing}
        pageHeading="GenNx CMS - People"
        accessRights={{
          moduleName: "People",
          parentModuleName: "",
          moduleAttribute: [...PEOPLE],
          interfaceName: "Listing",
        }}
      />
      <AuthLayout
        exact
        path="/people/:peopleId/edit"
        component={AddPeople}
        pageHeading="GenNx CMS - People"
        accessRights={{
          moduleName: "People",
          parentModuleName: "",
          moduleAttribute: [...PEOPLE],
          interfaceName: "Basic",
        }}
      />
    </Switch>
  </Suspense>
);

export default peopleRoutes;
