/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  Render Utility component is responsible for rendering respective component 
     [e.g Overview | Content Management | Tagging]
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 21/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React from "react";
import Overview from "../overview";
import Content from "../content";
import Tagging from "../tagging";
import AccessDenied from "../../../../components/accessDenied";
import Setting from "../setting";

const RenderUtility = (props) => {
  let interfaceAction = props?.accessRightInterfaceRights?.includes(Object.values(props?.hashArr)[props?.selectedIndex]);
  return (
    <>
      <div key="newsBasic">
        {interfaceAction ? (
          <Overview
            {...props}
            selectedIndex={props.selectedIndex}
            setCurrentTab={props.setCurrentTab}
            accessRightInterfaceRights={props.accessRightInterfaceRights}
          />
        ) : (
          <AccessDenied />
        )}
      </div>
      <div key="newsContent">
        {props.selectedIndex === 1 && interfaceAction ? (
          <Content {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
      <div key="newsTaxonomy">
        {props.selectedIndex === 2 && interfaceAction ? (
          <Tagging {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
      <div key="newsSetting">
        {props.selectedIndex === 3 && interfaceAction ? (
          <Setting {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
    </>
  );
};

export default RenderUtility;
