/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
 	File Description : Side Nav State (Sidebar Active, Forced Active, TreeMenu with open / close state)
----------------------------------------------------------------
	Creation Details
	Date Created				: 03/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import {
	SET_SIDEBAR_ACTIVE, SET_SIDE_NAV_ACTIVE_LINK, SET_SIDE_NAV_FORCED_ACTIVE_LINK, SET_SIDE_NAV_TREE_MENU_OPEN,
	SET_SIDE_NAV_TREE_MENU_CLOSE, SET_SIDE_NAV_OPENED_TREE_MENU_STATE, SET_SIDE_NAV_TREE_MENU_WITH_STATE
} from "../../constants/actionConstants";

const DEFAULT_STATE = {
	active: true,
	activeLink: "",
	forcedActiveLink: "",
	treeMenuOpen: {},
	openedTreeMenuState: {}
};

export default (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case SET_SIDEBAR_ACTIVE: {
			return { ...state, active: action.payload };
		}
		case SET_SIDE_NAV_ACTIVE_LINK: {
			return { ...state, activeLink: action.payload };
		}
		case SET_SIDE_NAV_FORCED_ACTIVE_LINK: {
			return { ...state, forcedActiveLink: action.payload };
		}
		case SET_SIDE_NAV_TREE_MENU_OPEN: {
			return { ...state, treeMenuOpen: {
				...state.treeMenuOpen,
				...{ [action.payload.treeMenu]: action.payload.open } 
			}};
		}
		case SET_SIDE_NAV_TREE_MENU_CLOSE: {
			return { ...state, treeMenuOpen: {} };
		}
		case SET_SIDE_NAV_OPENED_TREE_MENU_STATE: {
			return { ...state, openedTreeMenuState: {
				...state.openedTreeMenuState,
				...{ [action.payload.treeMenu]: action.payload.open } 
			}};
		}
		case SET_SIDE_NAV_TREE_MENU_WITH_STATE: {
			return { ...state, treeMenuOpen: { ...action.payload } };
		}
		default:
			return { ...state };
	}
};
