/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Actions for different action status
----------------------------------------------------------------
	Creation Details
	Date Created				: 19/09/2022
	Author						: PRASHANT WANKHADE 
================================================================
*/

export const setTaxonomyCheckedNodes = (data) => ({
	type: 'SET_COMMON_TAXONOMY_CHECKED_NODES',
	payload: data,
});

export const setTaxonomyUnCheckedNodes = (data) => ({
	type: 'SET_COMMON_TAXONOMY_UNCHECKED_NODES',
	payload: data,
});
export const setKiaCheckedNodes = (data) => ({
	type: 'SET_COMMON_KIA_CHECKED_NODES',
	payload: data,
});

export const setKiaUnCheckedNodes = (data) => ({
	type: 'SET_COMMON_KIA_UNCHECKED_NODES',
	payload: data,
});

export const setValueChainCheckedNodes = (data) => ({
	type: 'SET_COMMON_VALUE_CHAIN_CHECKED_NODES',
	payload: data,
});

export const setValueChainUnCheckedNodes = (data) => ({
	type: 'SET_COMMON_VALUE_CHAIN_UNCHECKED_NODES',
	payload: data,
});

export const setTransformingCheckedNodes = (data) => ({
	type: 'SET_COMMON_TRANSFORMING_CHECKED_NODES',
	payload: data,
});

export const setTransformingUnCheckedNodes = (data) => ({
	type: 'SET_COMMON_TRANSFORMING_UNCHECKED_NODES',
	payload: data,
});

export const setTrendCheckedNodes = (data) => ({
	type: 'SET_COMMON_TREND_CHECKED_NODES',
	payload: data,
});

export const setTrendUnCheckedNodes = (data) => ({
	type: 'SET_COMMON_TREND_UNCHECKED_NODES',
	payload: data,
});

export const setMediaCheckedNodes = (data) => ({
	type: 'SET_COMMON_MEDIA_CHECKED_NODES',
	payload: data,
});

export const setMediaUnCheckedNodes = (data) => ({
	type: 'SET_COMMON_MEDIA_UNCHECKED_NODES',
	payload: data,
});

export const setObjectiveTaxonomyCheckedNodes = (data) => ({
	type: 'SET_COMMON_OBJECTIVE_TAXONOMY_CHECKED_NODES',
	payload: data,
});

export const setObjectiveTaxonomyUnCheckedNodes = (data) => ({
	type: 'SET_COMMON_OBJECTIVE_TAXONOMY_UNCHECKED_NODES',
	payload: data,
});

export const setInitialCheckedTechnologyNodes = (data) => ({
    type: 'SET_TAXONOMY_INITIAL_CHECKED_NODES',
    payload: data,
});

export const setInitialCheckedKiaNodes = (data) => ({
    type: 'SET_KIA_INITIAL_CHECKED_NODES',
    payload: data,
});

export const setInitialCheckedValueChainNodes = (data) => ({
    type: 'SET_VALUE_CHAIN_INITIAL_CHECKED_NODES',
    payload: data,
});

export const setInitialCheckedTrendNodes = (data) => ({
    type: 'SET_TREND_INITIAL_CHECKED_NODES',
    payload: data,
});

export const setInitialCheckedMediaNodes = (data) => ({
    type: 'SET_MEDIA_INITIAL_CHECKED_NODES',
    payload: data,
});

export const setInitialCheckedObjectiveNodes = (data) => ({
    type: 'SET_OBJECTIVE_INITIAL_CHECKED_NODES',
    payload: data,
});

export const setResetRedux = () => ({
	type: 'SET_RESET_REDUX',
});
export const setInitialCheckedSgfNodes = (data) => ({
    type: 'SET_SGF_INITIAL_CHECKED_NODES',
    payload: data,
});

