import React, { useRef, useState } from "react";
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";

const CustomSidebar = ({ children, sidebarRef, isOpen, sidebarId = "client-report-sidebar-grid" }) => {
  return (
    <>
      <SidebarComponent
        id={sidebarId}
        ref={sidebarRef}
        type="Over"
        position="Right"
        animate={false}
        enableGestures={false}
        isOpen={isOpen}
        target=".gennx-grid-container-report"
      >
        {children}
      </SidebarComponent>
      <div className="client-report-sidebar-overlay"></div>
    </>
  );
};

export default CustomSidebar;
