/**
 * @author: Pankaj
 * @date : 14-Jan-2021
 * File Description: Customize Multi Stepper
 */
import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "react-bootstrap"
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import { fetchImportErrorFileUrl } from "../../../middleware/services/companyApi";

const MultiStepper = (props) => {
	const [show, setShow] = useState(false);
	const [disabled, setDisabled] = useState(false);
	useEffect(() => {
		props.show ? setShow(true) : setShow(false);
	}, [props.show ])
	return <> <Modal size="lg" show={show} onHide={() => { props.closeHandler(); setShow(false); }}
		scrollable
		centered
	>
		<Modal.Header
			closeButton
			className="bg-secondary text-white">
			<Modal.Title >Upload Screening Rejection Data</Modal.Title>
		</Modal.Header>
		<Modal.Body>

			<div className="row ">
				<div className="col-md-12 p-3">
					{props.steps[0].component}					
				</div>
			</div>
		</Modal.Body>
		<Modal.Footer>
			<div className="col-12">
				
				<div className="float-right">					
					{["success", "error"].includes(props.formik.errors.status) && props.formik.errors.fileName &&
						<a href={fetchImportErrorFileUrl(props.formik.errors.fileName)} className="btn btn-danger  mr-2">Download Report</a>

					}
					<button type="button" onClick={() => {setShow(false); props.closeHandler()}} className="btn btn-outline-dark mr-3 cy-btn" >Cancel</button>
					<button type="button"  onClick={async (e) => {
							e.preventDefault(); 
							props.steps[0].handleSubmitDocument()
							// props.steps[2].handleFinalSubmit(); setDisabled(true)
						}} className="btn btn-primary cy-btn">Save</button>

				</div>
			</div>

		</Modal.Footer>
	</Modal>
	</>

};
export default MultiStepper;

MultiStepper.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.object).isRequired,
	formik: PropTypes.object.isRequired,
	closeHandler: PropTypes.func.isRequired,
};
