/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Dynamic BreadCrumb Component
----------------------------------------------------------------
	Creation Details
	Date Created				: 28/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const BreadCrumbUI = () => {
	const breadCrumbState = useSelector((state) => state.breadCrumbState);
	const breadCrumbsLinks = breadCrumbState?.breadCrumbsLinks ? breadCrumbState.breadCrumbsLinks : [];
	return (
		breadCrumbsLinks && breadCrumbsLinks.length
			? (
				<div className="breadcrumbs">
					<div className="container-fluid">
						<ul>
							{
								breadCrumbsLinks.map((bCL, i) => (
									!bCL.linkActive ?
										(
											<li key={i} className="mr-2">
												<Link to={bCL.linkUrl}>{bCL.linkName}</Link>
												<i className="fas fa-angle-right ml-2" aria-hidden="true" />
											</li>
										)
										:
										(breadCrumbsLinks.length > 1) ? <li key={i}>{bCL.linkName}</li> : ""
								))
							}
						</ul>
					</div>
				</div>
			)
			: null
	);
};

export default BreadCrumbUI;
