/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description : Company API
----------------------------------------------------------------
	Creation Details
	Date Created				: 03/Jul/2020
	Author						: YOGESH N. GUPTA
================================================================
	Change History				: #1
	Changed By					: Anand Kumar
	Changed Date				: 31/Jul/2020
	Change Description			: Company Funding, Investments, Address API Added
================================================================
	Change History				: #1
	Changed By					: Anand Kumar
	Changed Date				: 11/Aug/2020
	Change Description			: Added company video and images API
================================================================
*/

import { COMPANY_API as API, CMS_API } from "../../config/apiConfig";
import { COMPANY_URL } from "../../config";
// company api

// Fetch Import Error File Url
export const fetchImportErrorFileUrl = (fileName) => {
	return `${COMPANY_URL}/company/import/error/${fileName}`;
};
// Fetch Import Template File Url
export const fetchImportTemplateFileUrl = () => {
	return `${COMPANY_URL}/company/import/template`;
};
// Fetch Import Screening Rejection Template File Url
export const fetchScreeningTemplateFileUrl = () => {
	return `${COMPANY_URL}/company/import/template-screening`;
};
export const fetchCompany = (params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: "/company/all",
		method: "GET",
		params: params
	});
};

export const fetchOverview = (companyId) => {
	return API({
		url: `/company/basic/${companyId}`,
		method: "GET",
	});
}

export const fetchCompanySource = (params = {}) => {
	return CMS_API({
		url: `/data-source/company`,
		method: "GET",
		params: { filters: params }
	});
};


export const fetchCompanyLogoUrl = (companyId, filename) => {
	return `${COMPANY_URL}/company/image/${filename}`;
	// return API({
	// 	url: `company/image/${filename}`,
	// 	method: "GET",
	// });
};

export const fetchSingleCompany = (companyId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/company/all/${companyId}`,
		method: "GET",
		params: params
	});
};

export const deleteCompany = (companyId) => {
	return API({
		url: `/company/all/${companyId}`,
		method: "DELETE"
	});
};

export const deleteManyCompany = (payload, params = {}) => {
	let { filters = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: "/company/all",
		method: "DELETE",
		params: params,
		data: payload
	});
};

export const saveCompanyAdvanced = (companyId, payload) => {
	return API({
		url: `/company/advanced/${companyId}`,
		method: "PUT",
		data: payload
	});
};

// import excel data template for company screening
export const uploadTemplateData = (payload) => {
	return API({
		url: `/company/import`,
		method: "POST",
		data: payload

	});
};
// import excel data template for company screening rejection
export const uploadScreeningTemplateData = (payload) => {
	return API({
		url: `/company/import-screening-rejection`,
		method: "POST",
		data: payload

	});
};

// export data to download excel
export const exportJsonData = (payload) => {
	return API({
		url: `/company/export`,
		method: "POST",
		data: payload

	});
};
// Download File after export data
export const downloadFileUrl = (fileName) => {
	return `${COMPANY_URL}/company/download/${fileName}`;
};

// export data to download excel
export const exportScreeningJsonData = (payload) => {
	return API({
		url: `/company/export/screening`,
		method: "POST",
		data: payload

	});
};
// Download File after export Screening data
export const downloadFileUrlScreening = (fileName) => {
	return `${COMPANY_URL}/company/download/${fileName}`;
};
export const fetchSingleCompanyAdvanced = (companyId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/company/advanced/${companyId}`,
		method: "GET",
		params: params
	});
};

// company texonomy
export const fetchCompanyTaxonomy = (companyId) => {
	return API({
		url: `/company/${companyId}/taxonomy/technology`,
		method: "GET"
	});
};

export const fetchCompanyTrend = (companyId) => {
	return API({
		url: `/company/${companyId}/taxonomy/trend`,
		method: "GET"
	});
};


export const saveCompanyTaxonomy = (companyId, taxonomyIds = []) => {
	return API({
		url: `/company/${companyId}/taxonomy/technology`,
		method: "POST",
		data: { taxonomyIds: [...taxonomyIds] }
	});
};

// save Company Value Chain
export const saveCompanyValueChain = (companyId, payload) => {
	return API({
		url: `company/${companyId}/taxonomy/industry-value-chain`,
		method: "POST",
		data: payload
	});
};

export const deleteCompanyTaxonomy = (companyId, taxonomyId) => {
	return API({
		url: `/company/${companyId}/taxonomy/technology/${taxonomyId}`,
		method: "DELETE"
	});
};

export const deleteCompanyTrend = (companyId, trendId) => {
	return API({
		url: `/company/${companyId}/taxonomy/trend/${trendId}`,
		method: "DELETE"
	});
};


export const saveCompanyTrend = (companyId, taxonomyIds = [], sgfData = undefined) => {
	return API({
		url: `/company/${companyId}/taxonomy/trend`,
		method: "POST",
		data: {
			taxonomyIds: [...taxonomyIds],
			sgfData: sgfData ? sgfData : null
		}
	});
};

// company address api
// fetch address specific to company
export const fetchAddress = (companyId, params = {}) => {
	return API({
		url: `/company/${companyId}/address`,
		method: "GET",
		params: params,
	});
};

// delete address specific to company
export const deleteAddress = (companyId, addressId) => {
	return API({
		url: `/company/${companyId}/address/${addressId}`,
		method: "DELETE",
	});
};

// save address specific to company
export const saveAddress = (companyId, payload) => {
	return API({
		url: `/company/${companyId}/address`,
		method: "POST",
		data: payload

	});
};

// save Basic (Overview) Info specific to company
export const updateOverview = (companyId, payload) => {
	return API({
		url: `/company/basic/${companyId}`,
		method: "PUT",
		data: payload
	});
};

// save Basic (Overview) Info specific to company
export const saveOverview = (companyId, payload) => {
	return API({
		url: `/company/basic`,
		method: "POST",
		data: payload
	});
};
// edit address specific to company
export const editAddress = (companyId, addressId, payload) => {
	return API({
		url: `/company/${companyId}/address/${addressId}`,
		method: "PUT",
		data: payload
	});
};

// company funding api

// fetch fundings specific to company
export const fetchFunding = (companyId, params = {}) => {
	let { filters = [], fields = undefined } = params;
	let fundingFilter = [["fundingOnCompanyId.companyId", "eq", companyId]];
	filters = [...filters, ...fundingFilter];
	params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: "/company/funding",
		method: "GET",
		params: params,
	});
};

// delete funding specific to company
export const deleteFunding = (fundingId) => {
	return API({
		url: `/company/funding/${fundingId}`,
		method: "DELETE",
	});
};
// save funding
export const saveFunding = (payload) => {
	return API({
		url: "/company/funding",
		method: "POST",
		data: payload

	});
};
// edit funding
export const editFunding = (fundingId, payload) => {
	return API({
		url: `/company/funding/${fundingId}`,
		method: "PUT",
		data: payload
	});
};
// company revenue api

// fetch revenues specific to company
export const fetchRevenue = (companyId, params = {}) => {
	let { filters = [], fields = undefined } = params;
	params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/company/${companyId}/revenue`,
		method: "GET",
		params: params,
	});
};
// fetch single revenue specific to company
export const fetchSingleRevenue = (companyId, revenueId, params = {}) => {
	let { filters = [], fields = undefined } = params;
	params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/company/${companyId}/revenue/${revenueId}`,
		method: "GET",
		params: params,
	});
};

// delete revenue specific to company
export const deleteRevenue = (companyId, revenueId) => {
	return API({
		url: `/company/${companyId}/revenue/${revenueId}`,
		method: "DELETE",
	});
};
// save revenue
export const saveRevenue = (companyId, payload) => {
	return API({
		url: `/company/${companyId}/revenue`,
		method: "POST",
		data: payload

	});
};
// edit revenue
export const editRevenue = (companyId, revenueId, payload) => {
	return API({
		url: `/company/${companyId}/revenue/${revenueId}`,
		method: "PUT",
		data: payload
	});
};

// company investment api
// fetch all inivestment
export const fetchInvestment = (companyId, params = {}) => {
	let { filters = [], fields = undefined } = params;
	let fundingFilter = [["fundingInvestors.companyId", "eq", companyId]];
	filters = [...filters, ...fundingFilter];
	params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	// console.log(params);
	return API({
		url: "/company/investment",
		method: "GET",
		params: params,
	});
};

// delete investment specific to company
export const deleteInvestment = (investmentId) => {
	return API({
		url: `/company/investment/${investmentId}`,
		method: "DELETE",
	});
};

// save investment
export const saveInvestment = (payload) => {
	return API({
		url: "/company/investment",
		method: "POST",
		data: payload

	});
};
// edit funding
export const editInvestment = (investmentId, payload) => {
	return API({
		url: `/company/investment/${investmentId}`,
		method: "PUT",
		data: payload
	});
};

/***************************************************company Video API ************************************************************* */

// fetch all company video
export const fetchVideo = (companyId, params = {}) => {
	// let { filters = [], fields = undefined } = params;
	// filters = [...filters,...fields];
	// params.filters = JSON.stringify(filters);
	// if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `company/${companyId}/video`,
		method: "GET",
		params: params,
	});
};

export const fetchVideoById = (companyId, videoId, params = {}) => {
	return API({
		url: `company/${companyId}/video/${videoId}`,
		method: "GET",
		params: params,
	});

}

// delete a company video
export const deleteVideo = (companyId, videoId) => {
	return API({
		url: `company/${companyId}/video/${videoId}`,
		method: "DELETE",
	});
};

// save a company video
export const saveVideo = (companyId, payload) => {
	return API({
		url: `company/${companyId}/video`,
		method: "POST",
		data: payload

	});
};
// edit a company video
export const editVideo = (companyId, videoId, payload) => {
	return API({
		url: `company/${companyId}/video/${videoId}`,
		method: "PUT",
		data: payload
	});
};
export const fetchVideoThumbUrl = (fileName) => {
	return `${COMPANY_URL}/company/videoThumb/${fileName}`;
};

/*****************************************company Images************************************************************************************************** */
// fetch all company Images
export const fetchImage = (companyId, params = {}) => {

	// console.log(params);
	return API({
		url: `company/${companyId}/image`,
		method: "GET"
	});
};

// delete a company Images
export const deleteImage = (companyId, imageId) => {
	return API({
		url: `company/${companyId}/image/${imageId}`,
		method: "DELETE",
	});
};

// save a company Images
export const saveImage = (companyId, payload) => {
	return API({
		url: `company/${companyId}/image`,
		method: "POST",
		data: payload

	});
};
// edit a company Images
export const editImage = (companyId, imageId, payload) => {
	return API({
		url: `company/${companyId}/image/${imageId}`,
		method: "PUT",
		data: payload
	});
};

// fetch image blob

export const fetchImageURL = (imgFileName) => {
	return `${COMPANY_URL}/company/image/${imgFileName}`;
}


// tag objective
export const saveCompanyObjective = (payload) => {
	return API({
		url: "/company/objective",
		method: "POST",
		data: payload
	});
};

//tag objective taxonomy
export const saveCompanyObjectiveTaxonomy = (payload) => {
	return API({
		url: "/company/objective/taxonomy",
		method: "POST",
		data: payload
	});
};

export const fetchCompanyObjectiveTaxonomy = (objectiveId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/company/objective/taxonomy/${objectiveId}`,
		method: "GET",
		params: params
	});
};

export const deleteCompanyObjective = (objectiveId, payload) => {
	return API({
		url: `/company/objective/${objectiveId}`,
		method: "DELETE",
		data: payload
	});
};
//KIA Tagging APis

// Fetch KIA Tagging
export const fetchCompanyKia = (companyId) => {
	return API({
		url: `/company/${companyId}/taxonomy/kia`,
		method: "GET",
	});
};
// Save KIA tags
export const saveCompanyKiaTaxonomy = (companyId, payload) => {
	return API({
		url: `company/${companyId}/taxonomy/kia`,
		method: "POST",
		data: payload
	});
};
// Delete Kia Te
export const deleteCompanyKia = (companyId, payload) => {
	return API({
		url: `company/${companyId}/taxonomy/kia`,
		method: "DELETE",
		data: payload
	});
};

// fetch a specfic company Documents by companyId
export const fetchCompanyDocumentsByCompanyId = (companyId, params = {}) => {
	return API({
		url: `/company/${companyId}/document`,
		method: "GET",
		params: params
	});
};

export const deleteCompanyDocument = (companyId, documentId, payload) => {
	return API({
		url: `/company/${companyId}/document/${documentId}`,
		method: "DELETE",
		data: payload

	});
};
export const fetchCompanyDocumentUrl = (fileName, docName) => {
	return `${COMPANY_URL}/company/document/${fileName}/${docName}`;
};

export const editCompanyDocument = (companyId, documentId, payload) => {
	return API({
		url: `/company/${companyId}/document/${documentId}`,
		method: "PUT",
		data: payload
	});
};

export const addCompanyDocument = (companyId, payload) => {
	return API({
		url: `/company/${companyId}/document`,
		method: "POST",
		data: payload

	});
};
// fetch a specfic company Document by companyId and documentId
export const fetchDocumentByDocumentId = (companyId, documentId, params = {}) => {
	return API({
		url: `/company/${companyId}/document/${documentId}`,
		method: "GET",
		params: params
	});
};


// Fetch company collaboration list
export const fetchCollaborations = (params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: "/company/collaboration",
		method: "GET",
		params: params
	});
};

// Fetch single company collaboration 
export const fetchSingleCollaboration = (collaborationId, params = {}) => {
	let { filters = undefined, fields = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	if (fields) params.fields = JSON.stringify(fields);
	return API({
		url: `/company/collaboration/${collaborationId}`,
		method: "GET",
		params: params
	});
};

// Delete single company collaboration 
export const deleteCollaboration = (collaborationId) => {
	return API({
		url: `/company/collaboration/${collaborationId}`,
		method: "DELETE"
	});
};

// Delete many company collaboration 
export const deleteManyCollaboration = (payload, params = {}) => {
	let { filters = undefined } = params;
	if (filters) params.filters = JSON.stringify(filters);
	return API({
		url: "/company/collaboration",
		method: "DELETE",
		params: params,
		data: payload
	});
};

// Save company collaboration
export const saveCollaboration = (payload) => {
	return API({
		url: `/company/collaboration`,
		method: "POST",
		data: payload
	});
};

// Update company collaboration
export const updateCollaboration = (collaborationId, payload) => {
	return API({
		url: `/company/collaboration/${collaborationId}`,
		method: "PUT",
		data: payload
	});
};

// Get single collaboration trend taxonomy
export const fetchCollaborationTrend = (collaborationId) => {
	return API({
		url: `/company/collaboration/${collaborationId}/taxonomy/trend`,
		method: "GET"
	});
};

// Delete single collaboration trend taxonomy
export const deleteCollaborationTrend = (collaborationId, trendId) => {
	return API({
		url: `/company/collaboration/${collaborationId}/taxonomy/trend/${trendId}`,
		method: "DELETE"
	});
};

// Save single collaboration trend taxonomy
export const saveCollaborationTrend = (collaborationId, payload) => {
	return API({
		url: `/company/collaboration/${collaborationId}/taxonomy/trend`,
		method: "POST",
		data: payload
	});
};

export const deleteManyCompanyTrend = (companyId, taxonomyIds = []) => {
	return API({
		url: `/company/${companyId}/taxonomy/trend`,
		method: "DELETE",
		data: { taxonomyIds: [...taxonomyIds] }
	})
}

export const deleteManyCompanyTechnology = (companyId, taxonomyIds = []) => {
	return API({
		url: `/company/${companyId}/taxonomy/technology`,
		method: "DELETE",
		data: { taxonomyIds: [...taxonomyIds] }
	})
}

export const deleteManyCompanyKia = (companyId, taxonomyIds = []) => {
	return API({
		url: `/company/${companyId}/taxonomy/kia`,
		method: "DELETE",
		data: { taxonomyIds: [...taxonomyIds] }
	})
}

export const deleteManyCompanyValueChain = (companyId, payload) => {
	return API({
		url: `company/${companyId}/taxonomy/industry-value-chain`,
		method: "DELETE",
		data: payload
	});
};

// Taxonomy bulk tagging
export const saveCompanyTaxonomyTags = (payload) => {
	return API({
		url: `/company/taxonomy/technology`,
		method: "PUT",
		data: payload
	});
};

// Post  kia
export const saveCompanyKiaTags = (payload) => {
	return API({
		url: `/company/taxonomy/kia`,
		method: "PUT",
		data: payload
	});
};

// Post  value chain
export const saveValueChainTags = (payload) => {
	return API({
		url: `/company/taxonomy/industry-value-chain`,
		method: "PUT",
		data: payload
	});
};

// Post  trend
export const saveCompanyTrendTags = (payload) => {
	return API({
		url: `/company/taxonomy/trend`,
		method: "PUT",
		data: payload
	});
};

// Post  Media
export const saveCompanyMediaTags = (payload) => {
	return API({
		url: `/company/taxonomy/media`,
		method: "PUT",
		data: payload
	});
};

//tag objective taxonomy
export const saveCompanyBusinessObjectiveTaxonomy = (payload) => {
	return API({
		url: "/company/objective/taxonomy",
		method: "PUT",
		data: payload
	});
};

// delete collaboration taxonomy
export const deleteManycollaborationTrend = (collaborationId, taxonomyIds = []) => {
	return API({
		url: `/company/collaboration/${collaborationId}/taxonomy/trend`,
		method: "DELETE",
		data: { taxonomyIds: [...taxonomyIds] }
	})
}

export function updateContentTags(companyId, payload) {
	return API({
	  url: `/company/all/${companyId}/tags`,
	  method: "PUT",
	  data: payload,
	});
  }

//   add or update sequence of display to taxonomy
  export const saveCompanyTrendSequence = (companyId, payload) => {
	return API({
		url: `/company/${companyId}/taxonomy/trend/sort-order`,
		method: "PUT",
		data: payload
	});
}

export const saveCompanyDisplay = (companyId, payload) => {
	return API({
		url: `/company/${companyId}/display`,
		method: "PUT",
		data: payload
	});
};

export const getCompanyDisplay = (companyId, payload) => {
	return API({
		url: `/company/${companyId}/display`,
		method: "GET",
	});
};