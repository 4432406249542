import React from "react";
import { useDropzone } from "react-dropzone";
import ReactTooltip from "react-tooltip";
const UploadComponent = props => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        // accept: "image/*",
        accept: props.acceptedFiles,
        maxFiles: 1,//comment this line if upload multiple File 
        onDrop: acceptedFiles => {
            props.formik.setValues({ ...props.formik.values, documentFile: acceptedFiles });
        }
    });
    return (
        <>
            <div className="form-group bg-light p-3 border-dotted mb-1"
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <button type="button" className={props.btnClass}>{props.buttonText}</button>          
                {" "}
                or drop file here
            </div>
            <small className="float-right">
                <b className="text-danger">Note: </b>{props.validationText}

            </small>
            {props.fileUrl ?
                <><br /><a style={{lineHeight:2}}
                href={props.fileUrl
                }

                ><i className="fas fa-download" data-tip="Download" /> {props.docFileName}</a>
                    <ReactTooltip /></>
                : ""
            }

            <div className="text-danger" style={{ display: props.formik.touched.documentFile === true ? "block" : "none" }} role="alert">
                <small>
                    {props.formik.touched.documentFile === true ? props.formik.errors.documentFile || "" : ""}
                </small>
            </div>
        </>
    );
};
export default UploadComponent;
