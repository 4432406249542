/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  Render Utility component is responsible for rendering respective component 
     [e.g Overview | Content Management | Tagging]
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 21/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React from "react";
import Overview from "../overview";
import Content from "../content";
import Tagging from "../taxonomy";
import AccessDenied from "../../../../../components/accessDenied";

const RenderUtility = (props) => {
  return (
    <>
      <div
        key={`objectiveBasic`}
        style={{
          display: props.activeStepper === "#basic" && props.selectedIndex === 0 ? "block" : "none",
        }}
      >
        {props?.accessRightInterfaceRights?.includes(Object.values(props?.hashArr)[0]) ? (
          <Overview {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
      <div
        key="objectiveContent"
        style={{
          display: props.activeStepper === "#content" && props.selectedIndex === 1 ? "block" : "none",
        }}
      >
        {props?.accessRightInterfaceRights?.includes(Object.values(props?.hashArr)[1]) ? (
          <Content {...props} selectedIndex={props.selectedIndex} setCurrentTab={props.setCurrentTab} />
        ) : (
          <AccessDenied />
        )}
      </div>
      <div
        key="objectiveTaxonomy"
        style={{
          display: props?.activeStepper === "#taxonomy" && props?.selectedIndex === 2 ? "block" : "none",
        }}
      >
        {props?.accessRightInterfaceRights?.includes(Object.values(props?.hashArr)[2]) ? (
          <Tagging
            {...props}
            selectedIndex={props.selectedIndex}
            setCurrentTab={props.setCurrentTab}
            interfaceActionAccess={props.interfaceActionAccess}
          />
        ) : (
          <AccessDenied />
        )}
      </div>
    </>
  );
};

export default RenderUtility;
