/**
 * @author: Lawrence Anthony Kumar
 * @date : 20-Feb-2023
 * File Description: Image Editor specification Modal handle content block image edit
 */

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./imageEditorModal.css";
import ImageEditor from "../../../ImageEditor";
import { FiUpload ,FiDownload} from 'react-icons/fi';
import { useDispatch } from "react-redux";
import { imageBase64 } from "middleware/services/contentBlockApi";
import { actionError, hideLoader, showLoader } from "middleware/actions/utilityAction";


const ImageEditorModal = (props) => {
  const { showModal, setShowModal, imageURL, imageHeight, imageWidth, imageId } = props;
  const [triggerSave, setTriggerSave] = useState(false);
  const [uploadTrigger, setUploadTrigger] = useState(false);
  const [imgSrc, SetImgSrc] = useState("");

  const dispatch = useDispatch()

  const getFileDimensions = (file, callback) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const image = new Image();
      image.onload = () => {
        callback(image.width, image.height);
      };
      image.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  function b64toBlob(b64Data) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays);
  }

  async function loadImgSrc() {
    try {
      dispatch(showLoader());
      if (!imageURL) return;
      if (!imageURL.includes("cloudfront.net")) {
        SetImgSrc(imageURL)
      }else{
        const imageName = imageURL.substring(imageURL.lastIndexOf("/") + 1);
        let base64ImageRes = await imageBase64({ imageURL: imageURL });
        const base64Data = base64ImageRes.data.data.split(";base64,").pop();
  
        // Convert the Base64 data to a Blob;
        const blob = b64toBlob(base64Data);
  
        // Create a File object from the Blob
        const file = new File([blob], imageName, {
          lastModified: new Date().getTime(),
          lastModifiedDate: new Date(),
          type: "image/jpeg",
        });
  
        getFileDimensions(file, (width, height) => {
        if (width < 100 || height < 100) {
          setShowModal()
          return dispatch(actionError("Please select an image with minimum size of width 100px and height 100px to edit."));
        }
        let imgSrcData = URL.createObjectURL(file);
        SetImgSrc(imgSrcData);
        });
      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      dispatch(actionError(err?.data?.message || "Unable to load image"));
    }
  }

  useEffect(() => {
    loadImgSrc()
    let wrapper = document.querySelector(".content-builder-container");
    wrapper.click(); 
  },[]);

  return (
    <>
    {imgSrc &&
      (<Modal show={showModal} backdrop="static" className="modal fade md modal-z-index image-editor-modal" size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-header bg-none text-dark img-editor-header">
          <h5 className="modal-title img-editor-title" id="exampleModalLabel">
            Upload Image
          </h5>
        </div>
        <Modal.Body className="img-editor-body">
            <ImageEditor
            triggerSave={triggerSave}
            setTriggerSave={setTriggerSave}
            imageId={imageId}
            imgSrc={imgSrc}
            SetImgSrc={SetImgSrc}
            setShowModal={setShowModal}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            setToggleBuild={props.setToggleBuild}
            uploadTrigger={uploadTrigger}
            setUploadTrigger={setUploadTrigger}
            />
        </Modal.Body>
        <Modal.Footer className="img-editor-footer">
          <div>
          <div className="upload-container" onClick={() => setUploadTrigger("saveImage")}>
            <div className="icon-container">
              <FiUpload size="20px" />
            </div>
            <button type="button">
              Upload Image
            </button>
          </div>
          <div className="upload-container ml-2" onClick={() => setTriggerSave("exportImage")}>
            <div className="icon-container">
              <FiDownload size="20px" />
            </div>
            <button type="button">
              Download Image
            </button>
          </div>
          </div>
          <div>
          <button onClick={() => setShowModal()} type="button" className="btn btn-outline-dark editor-btn pl-2 pr-2">
            Cancel
          </button>
          <button onClick={() => setTriggerSave('saveImage')} type="button" className="btn btn-primary editor-btn pl-2 pr-2 ml-2 my-1">  
            Save
          </button>
          </div>
        </Modal.Footer>
      </Modal>)}
    </>
  );
};

export default ImageEditorModal;
