
/**
 * Author: Anand Kumar
 * Date: 16/Oct/2020
 * File Description: Validation for user Subscription
 */
import * as Yup from "yup";

export const userSubscriptionValidationSchema = {
    relationshipType: Yup.string().required("Please select relationship type").nullable(),
    productVariant: Yup.string().required("Please select product variant").nullable(),
    subscriptionProduct: Yup.string().required("Please select subscription").nullable(),
    validity: Yup.string().required("Please select subscription validity").nullable(),
    // subscriptionProducts: Yup.array().required("Please select atleast one Product"),
    subscriptionStatus: Yup.string().required("Please select relationship status"),
    subscriptionStartDate: Yup.date().required("Please select trial/subscription start date").typeError("Please select trial/subscription start date"),
    subscriptionEndDate: Yup.date().typeError("Please select validity end date"),
    relationshipManagerName: Yup.string().required("Please enter relationship manager's name"),
    relationshipManagerEmail: Yup.string().email("Please enter valid email").required("Please enter relationship manager's email"),
    relationshipManagerEmployeeCode: Yup.string().required("Please enter relationship manager's employee code"),
    btbId: Yup.number().required("Please enter BTB id").typeError("BTB id should be number"),
}
